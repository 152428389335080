import { http } from '../config/vue-axios';

/**
 *
 * @param {Number} packTagId
 * @param {String} description
 * @param {Array<Number>} shotTemplateTags
 *
 */
export async function createShotTemplate(body) {
  const response = await http.post('/shot_templates.json', {
    shot_template: body,
  });

  return response.data;
}

/**
 *
 * @param {Number} packTagId
 * @param {String} description
 * @param {Array<Number>} shotTemplateTags
 *
 */
export async function deleteShotTemplate(shotTemplateId) {
  const response = await http.delete(`/shot_templates/${shotTemplateId}.json`);
  return response.data;
}

/**
 *
 * @param {Number} packTagId
 * @param {String} description
 * @param {Array<Number>} shotTemplateTags
 *
 */
export async function updateShotTemplate(shotTemplate) {
  const response = await http.put(`/shot_templates/${shotTemplate.id}.json`, {
    shot_template: shotTemplate,
  });

  return response.data;
}

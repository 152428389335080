import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useAssignAssetsToShot(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ shotId, rdaIds }) => {
      const response = await http.post(
        `/reservations/${toValue(
          reservationId
        )}/shots/${shotId}/assign_to_shot`,
        { reservation_digital_asset_ids: rdaIds }
      );

      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationDigitalAssets(),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationShots(reservationId),
        }),
      ]);
    },
  });
}

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaDonutChart from '@/components/ui_library/SoonaDonutChart.vue';
import useListingRank from '@/components/user/anytime/competitive_analysis/useListingRank';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import {
  PeriwinkBlue20,
  PeriwinkBlue70,
  Gray20,
  Gray30,
} from '@/variables.module.scss';
import { useIntegrations } from '@/composables/useIntegrations';
import ListingPrice from './ListingPrice.vue';

const props = defineProps({
  competitor: {
    type: Object,
    required: true,
  },
});

const competitor = computed(() => props.competitor);
const accountId = computed(() => competitor.value.account_id);
const integrationId = computed(() => competitor.value.integration_id);
const listingTags = computed(() => competitor.value.listing_tags);

const { currentPlatformName } = useIntegrations(accountId, integrationId);

const competitorUrl = computed(
  () => `https://www.amazon.com/dp/${competitor.value.catalog_item_info.asin}`
);

const competitorData = computed(() => {
  return [
    {
      label: competitor.value.listing_score.visual_average.score.label,
      value: competitor.value.listing_score.visual_average.score.value,
    },
  ];
});

const { getDisplayGroupRanks } = useListingRank();
</script>

<template>
  <div class="competitor-overview">
    <div class="competitor-overview__inner">
      <div class="competitor-overview__header">
        <h3 class="competitor-overview__title u-subheader--heavy">
          {{ competitor.name }}
        </h3>
        <a
          :href="competitorUrl"
          target="_blank"
          class="competitor-overview__link u-label--regular"
        >
          {{ getDisplayGroupRanks(competitor) }}
          <SoonaIcon
            class="competitor-overview__link--icon"
            name="arrow-up-right-from-square"
            size="small"
          />
        </a>
      </div>
      <ListingPrice :item="competitor" />
      <div class="competitor-overview__score">
        <div class="competitor-overview__visual-performance">
          <SoonaDonutChart
            class="competitor-overview__score-donut"
            accessible-title="listing score"
            :data="competitorData"
            :display-value="
              competitor.listing_score.visual_average.display_value
            "
            size="large"
            label-position="bottom"
          >
            <template #label>
              <p
                class="competitor-overview__visual-performance--label u-label--heavy"
              >
                soona score
              </p>
            </template>
          </SoonaDonutChart>
        </div>
      </div>

      <div
        v-if="competitor.staff_pick"
        class="competitor-overview__staff_picks"
      >
        <SoonaFlag
          title="example competitor"
          font-size="0.75rem"
          font-weight="bolder"
          :text-color="PeriwinkBlue70"
          :background-color="PeriwinkBlue20"
        >
          <template #icon-left>
            <SoonaIcon name="lightbulb-alt" size="small" />
          </template>
        </SoonaFlag>
        <p class="u-label--heavy">
          what makes this great {{ currentPlatformName }} content?
        </p>
        <div
          v-if="listingTags.length > 0"
          class="competitor-overview__staff_picks__tags"
        >
          <SoonaFlag
            v-for="(tag, i) in listingTags"
            :key="i"
            class="competitor-overview__staff_picks__tag"
            :title="tag"
            :color="Gray30"
            :border-color="Gray30"
            :background-color="Gray20"
            font-weight="normal"
            type="pill"
            padding-size="0.1875rem 0.375rem 0.1875rem 0.375rem"
            lowercase
          />
        </div>
      </div>

      <div class="competitor-overview__assets">
        <img
          v-for="asset in competitor.assets"
          :key="asset.id"
          :src="asset.image_url"
          class="competitor-overview__image"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
.competitor-overview {
  container-type: inline-size;

  &__staff_picks {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    &__tag {
      white-space: nowrap;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &--icon {
      flex-shrink: 0;
    }
  }

  &__visual-performance {
    &--denominator {
      color: variables.$gray-60;
    }
  }

  &__score-donut {
    align-items: center;
    margin-top: 2rem;
    max-width: 10.5rem;
    padding-bottom: 1rem;
  }

  &__visual-performance--label {
    margin-top: 0.625rem;
  }

  &__assets {
    display: grid;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  &__image {
    max-width: 100%;
    max-height: 9.75rem;
    border-radius: 0.3125rem;
    align-self: center;
  }

  @container (min-width: 20.125rem) {
    &__assets {
      grid-template-columns: repeat(
        auto-fill,
        minmax(min(10.75rem, 100%), 1fr)
      );
      justify-items: start;
    }
  }
}
</style>

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 * @returns UseMutationReturnType<*>
 */
export function useDeleteAlbumCollectionDigitalAsset() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ collectionId, cdaId }) => {
      const response = await http.delete(
        `/collections/${toValue(
          collectionId
        )}/album_collection_digital_assets/${toValue(cdaId)}`
      );

      return response.data;
    },
    onSuccess: async (data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collectionAssets(variables.collectionId),
        }),
      ]);
    },
  });
}

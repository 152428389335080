<script setup>
import { ref, watchEffect } from 'vue';

const posts = ref([]);

watchEffect(onCleanup => {
  const controller = new AbortController();

  fetch('/api/blog_rss.xml', { signal: controller.signal })
    .then(response => response.text())
    .then(text => {
      const data = new window.DOMParser().parseFromString(text, 'text/xml');
      if (!data) return;

      const items = Array.from(data.querySelectorAll('item')).slice(0, 3);
      posts.value = items.map(item => {
        return {
          title: item.querySelector('title').innerHTML,
          link: item.querySelector('link').innerHTML,
          image: item.querySelector('thumbnail')?.getAttribute('url'),
        };
      });
    })
    .catch(error => {
      console.error('Error fetching and parsing RSS feed:', error);
    });

  onCleanup(() => controller.abort());
});

function decodeChars(str) {
  const escapeChars = { lt: '<', gt: '>', quot: '"', apos: "'", amp: '&' };

  return str.replace(/&([^;]+);/g, function (entity, entityCode) {
    let match;

    if (entityCode in escapeChars) {
      return escapeChars[entityCode];
    } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16));
    } else if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1]);
    } else {
      return entity;
    }
  });
}
</script>

<template>
  <div class="pro-tips">
    <lottie-player autoplay loop src="@images/anim/lottie-we-cant-wait.lott" />
    <h2 class="section-header has-text-centered mb-l">
      <template v-if="posts.length">while you wait…check out:</template>
    </h2>
    <div class="cards-container">
      <div v-for="(post, index) in posts" :key="'p' + index" class="card">
        <a
          :href="post.link"
          target="blank"
          rel="noreferrer nofollow"
          data-cypress="pro-tips-link"
        >
          <article class="card-content">
            <img
              v-if="post.image"
              :src="post.image"
              :alt="`image for ` + post.title + ` post`"
              class="post-img"
            />
            <img v-else src="@images/anytime-summary.png" alt="" />
            <p class="u-label--heavy post-title">
              {{ decodeChars(post.title) }}
            </p>
          </article>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.pro-tips {
  width: 100%;
  height: max-content;
  padding: 1rem;
  background-image: url('~images/pro-tips-blobs.png');
  background-size: cover;
}

.cards-container {
  flex: 1 1 0.0625rem;
  min-height: 15.625rem;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: variables.$screen-xs-max) {
    max-height: 18.75rem;
  }
}

.card {
  width: 220px;
  height: 240px;
  box-shadow: 0px 3px 6px #e2e2e2;
  margin: 12px;
  border-radius: 7px;
  position: relative;
}

.card-content {
  padding: 1.5rem;
}

.post-img {
  height: 125px;
  width: 100%;
  object-fit: cover;
}

.post-title {
  margin-top: 8px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.section-header {
  margin: 1.25rem 0rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
}
</style>

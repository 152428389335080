<template>
  <div class="admin-trend-set-filter-name">
    <div
      :id="`admin-trend-set-collapse-toggle-${filterName}`"
      class="admin-trend-set-filter-name__header"
      :data-cypress="`component-admin-trend-set-filter-name-${dataCypressFilterName}`"
      aria-controls="admin-trend-set-collapse-toggle-flex"
      :aria-expanded="!isAccordionOpen"
      role="button"
      type="button"
      @click="isAccordionOpen = !isAccordionOpen"
    >
      <span>{{ filterName }}</span>
      <SoonaIcon
        v-if="tags?.length > 0"
        :name="isAccordionOpen ? 'chevron-up' : 'chevron-down'"
      />
    </div>
    <div
      v-show="isAccordionOpen"
      :data-cypress="`component-admin-trend-set-filter-name-${dataCypressFilterName}-trend-sets`"
      class="admin-trend-set-filter-name__trend-sets"
      :aria-labelledby="`admin-trend-set-collapse-toggle-${filterName}`"
    >
      <AdminTrendSet v-for="t in tags" :key="t.id" :trend-set="t" />
    </div>
  </div>
</template>
<script>
import AdminTrendSet from './AdminTrendSet.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'AdminTrendSetFilterName',
  components: { AdminTrendSet, SoonaIcon },
  props: {
    filterName: String,
    tags: Array,
  },
  data() {
    return {
      isAccordionOpen: false,
    };
  },
  computed: {
    dataCypressFilterName() {
      return `${this.filterName.toLowerCase().replace(/\s+/g, '-')}`;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@use '@/variables_fonts';
@use '@/variables';

.admin-trend-set-filter-name {
  width: 100%;

  &__header {
    @include variables_fonts.u-subheader--heavy;

    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 41px 21px 41px 41px;
    width: 100%;
    border-top: 1px solid variables.$gray-30;
    cursor: pointer;

    svg {
      color: variables.$gray-60;
    }
  }

  &__trend-sets {
    padding: 0 41px;

    .admin-trend-set:last-child {
      margin-bottom: 41px;
    }
  }
}
</style>

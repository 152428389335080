import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<number, string>} reservationId
 * @param {number | Ref<number, string>} orderId
 * @param {string | Ref<number, string>} digitalAssetId
 */
export function useReservationLineItems(
  reservationId,
  orderId,
  digitalAssetId
) {
  return useQuery({
    queryKey: queryKeys.reservationLineItems(
      reservationId,
      orderId,
      digitalAssetId
    ),
    queryFn: async ({ signal }) => {
      let params = {};

      if (orderId) params.order_id = toValue(orderId);
      if (digitalAssetId) params.digital_asset_id = toValue(digitalAssetId);

      const response = await http.get(
        `/reservations/${toValue(reservationId)}/reservation_line_items`,
        {
          params,
          signal,
        }
      );

      return response.data;
    },
  });
}

<script setup>
import { computed, toRef } from 'vue';
import { useExampleListing } from '@/queries/useExampleListings';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import ExampleListingDetailSummary from './ExampleListingDetailSummary.vue';
import ListingOptimize from '../ListingOptimize.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import PageContainerOffsetTop from '@/components/platform-layout/PageContainerOffsetTop.vue';

const props = defineProps({
  exampleListingId: {
    type: String,
    required: true,
  },
});

const {
  data: listingData,
  error: listingError,
  isLoading,
  isSuccess,
} = useExampleListing(toRef(() => props.exampleListingId));

const listing = computed(() => listingData.value ?? {});

const platformName = computed(() => {
  switch (listing.value.integration_type) {
    case 'AmazonIntegration':
      return 'Amazon';
    case 'ShopifyIntegration':
      return 'Shopify';
    default:
      return '';
  }
});

const priorityError = usePriorityError(listingError);
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <PageContainerOffsetTop v-if="!isLoading && isSuccess">
    <PageContainerOffset class="listing-detail__summary">
      <ExampleListingDetailSummary
        :listing="listing"
        :platform="platformName"
      />
    </PageContainerOffset>
    <ListingOptimize
      :listing-data="listing"
      :platform="platformName"
      :is-example="true"
    />
  </PageContainerOffsetTop>
</template>

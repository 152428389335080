<script setup>
import { computed, ref, watch } from 'vue';
import { useWindowVirtualizer } from '@tanstack/vue-virtual';

const props = defineProps({
  height: {
    type: Number,
    required: true,
  },
  initialScrollPosition: {
    type: Number,
    required: false,
  },
  rows: {
    type: Array,
    required: true,
  },
  gap: {
    type: Number,
    required: true,
  },
  offsetTop: {
    type: Number,
    default: 0,
  },
});

const initialScrollPosition = computed(() => props.initialScrollPosition);

const parentRef = ref(null);

const getItemKey = ix => {
  return computed(() => props.rows.at(ix)?.at(0)?.id);
};

// use with useWindowVirtualizer see
// https://github.com/TanStack/virtual/blob/main/examples/react/window/src/main.tsx
const rowVirtualizerOptions = computed(() => {
  const sizeEstimate = props.height;
  return {
    count: props.rows.length,
    estimateSize: () => sizeEstimate,
    overscan: 5,
    getItemKey,
    scrollMargin: props.offsetTop,
    gap: props.gap,
  };
});

const rowVirtualizer = useWindowVirtualizer(rowVirtualizerOptions);
const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());
const totalSize = computed(() => `${rowVirtualizer.value?.getTotalSize()}px`);
const gapSize = computed(() => `${props.gap}px`);

watch(initialScrollPosition, newPosition => {
  if (!newPosition) return;

  rowVirtualizer.value?.scrollToIndex(newPosition, {
    align: 'start',
  });
});
</script>

<template>
  <div ref="parentRef" class="scroller">
    <div class="scroller__liner">
      <div
        v-for="virtualRow in virtualRows"
        :key="virtualRow.key"
        class="scroller__row"
        :style="{
          height: `${virtualRow.size}px`,
          transform: `translateY(${virtualRow.start - offsetTop}px)`,
        }"
      >
        <slot :data="rows[virtualRow.index]" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.scroller {
  &__liner {
    height: v-bind(totalSize);
    width: 100%;
    position: relative;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: v-bind(gapSize);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>

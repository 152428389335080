import * as types from 'src/store/mutation-types';
import debounce from 'lodash/debounce';
import { http } from '@/config/vue-axios';

const loadAccountSearchDebounced = debounce(
  function (commit, query, page = 1) {
    return http
      .get(`accounts/search.json`, {
        params: { query: query || '', page: page },
      })
      .then(
        response => {
          commit(types.SET_ACCOUNTS, response.data);
        },
        error => {
          commit(`errors/${types.SET_ERRORS}`, error.response.data, {
            root: true,
          });
        }
      );
  },
  1000,
  { leading: true, trailing: true }
);

const state = {
  liveReservations: [],
  completedReservations: [],
  pendingSelectsReservations: [],
  previousReservations: [],
  readyToEditReservations: [],
  crewReservations: [],
  pastAssignments: [],
  crewEditsReservations: [],
  kpis: [],
  crewReservationsLoaded: false,
  crewEditsReservationsLoaded: false,
  shopMoreFiles: [],
  recentEditFiles: [],
  accounts: [],
  currentAccountId: undefined,
};

const getters = {};

const mutations = {
  [types.ADD_LIVE_RESERVATIONS](state, reservations) {
    state.liveReservations = state.liveReservations.concat(reservations);
  },
  [types.RESET_LIVE_RESERVATIONS](state) {
    state.liveReservations = [];
  },
  [types.ADD_COMPLETED_RESERVATIONS](state, reservations) {
    state.completedReservations =
      state.completedReservations.concat(reservations);
  },
  [types.RESET_COMPLETED_RESERVATIONS](state) {
    state.completedReservations = [];
  },
  [types.ADD_PENDING_SELECTS_RESERVATIONS](state, reservations) {
    state.pendingSelectsReservations =
      state.pendingSelectsReservations.concat(reservations);
  },
  [types.RESET_PENDING_SELECTS_RESERVATIONS](state) {
    state.pendingSelectsReservations = [];
  },
  [types.ADD_PREVIOUS_RESERVATIONS](state, reservations) {
    state.previousReservations =
      state.previousReservations.concat(reservations);
  },
  [types.ADD_READY_TO_EDIT_RESERVATIONS](state, reservations) {
    state.readyToEditReservations =
      state.readyToEditReservations.concat(reservations);
  },
  [types.RESET_READY_TO_EDIT_RESERVATIONS](state) {
    state.readyToEditReservations = [];
  },
  [types.RESET_PAST_ASSIGNMENTS](state) {
    state.pastAssignments = [];
  },
  [types.ADD_PAST_ASSIGNMENTS](state, reservations) {
    state.pastAssignments = state.pastAssignments.concat(reservations);
  },
  [types.SET_CREW_EDITS_RESERVATIONS](state, crewEditsReservations) {
    state.crewEditsReservations = crewEditsReservations;
    state.crewEditsReservationsLoaded = true;
  },
  [types.SET_KPIS](state, kpis) {
    state.kpis = kpis;
  },
  [types.SET_ACCOUNTS](state, accounts) {
    state.accounts = accounts;
  },
  [types.RESET_ACCOUNTS](state) {
    state.accounts = [];
  },
};

const actions = {
  loadLiveReservations(
    { commit },
    { location, name, shareable = false, page = 1 }
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `reservations/live.json?location=${location || ''}&name=${
            name || ''
          }&shareable=${shareable}&page=${page}`
        )
        .then(
          response => {
            if (page === 1) {
              commit(types.RESET_LIVE_RESERVATIONS);
            }
            commit(types.ADD_LIVE_RESERVATIONS, response.data.reservations);
            resolve(response.data.last_page);
          },
          error => {
            commit(`errors/${types.SET_ERRORS}`, error.response.data, {
              root: true,
            });
            reject(error);
          }
        );
    });
  },
  loadCompletedReservations(
    { commit },
    { location, name, shareable = false, page = 1 }
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `reservations/completed.json?location=${location || ''}&name=${
            name || ''
          }&shareable=${shareable}&page=${page}`
        )
        .then(
          response => {
            if (page === 1) {
              commit(types.RESET_COMPLETED_RESERVATIONS);
            }
            commit(
              types.ADD_COMPLETED_RESERVATIONS,
              response.data.reservations
            );
            resolve(response.data.last_page);
          },
          error => {
            commit(`errors/${types.SET_ERRORS}`, error.response.data, {
              root: true,
            });
            reject(error);
          }
        );
    });
  },
  loadPendingSelectsReservations(
    { commit },
    { location, name, shareable = false, page = 1 }
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `reservations/pending_selects.json?location=${location || ''}&name=${
            name || ''
          }&shareable=${shareable}&page=${page}`
        )
        .then(
          response => {
            if (page === 1) {
              commit(types.RESET_PENDING_SELECTS_RESERVATIONS);
            }
            commit(
              types.ADD_PENDING_SELECTS_RESERVATIONS,
              response.data.reservations
            );
            resolve(response.data.last_page);
          },
          error => {
            commit(`errors/${types.SET_ERRORS}`, error.response.data, {
              root: true,
            });
            reject(error);
          }
        );
    });
  },
  loadReadyToEditReservations(
    { commit },
    { location, name, shareable = false, page = 1 }
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `reservations/ready_to_edit.json?location=${location || ''}&name=${
            name || ''
          }&shareable=${shareable}&page=${page}`
        )
        .then(
          response => {
            if (page === 1) {
              commit(types.RESET_READY_TO_EDIT_RESERVATIONS);
            }
            commit(
              types.ADD_READY_TO_EDIT_RESERVATIONS,
              response.data.reservations
            );
            resolve(response.data.last_page);
          },
          error => {
            commit(`errors/${types.SET_ERRORS}`, error.response.data, {
              root: true,
            });
            reject(error);
          }
        );
    });
  },
  loadPastAssignments({ commit }, { userId, page = 1 }) {
    return new Promise((resolve, reject) => {
      this.http.get(`users/${userId}/past_assignments.json?page=${page}`).then(
        response => {
          if (page === 1) {
            commit(types.RESET_PAST_ASSIGNMENTS);
          }
          commit(types.ADD_PAST_ASSIGNMENTS, response.data.reservations);
          resolve(response.data.last_page);
        },
        error => {
          commit(`errors/${types.SET_ERRORS}`, error.response.data, {
            root: true,
          });
          reject(error);
        }
      );
    });
  },
  loadCrewEditsReservations({ commit }, userId) {
    return new Promise((resolve, reject) => {
      this.http.get(`users/${userId}/assigned_edits_reservations.json`).then(
        response => {
          commit(types.SET_CREW_EDITS_RESERVATIONS, response.data);
          resolve();
        },
        error => {
          commit(`errors/${types.SET_ERRORS}`, error.response.data, {
            root: true,
          });
          reject(error);
        }
      );
    });
  },
  loadKPIs({ commit }, userId) {
    return new Promise((resolve, reject) => {
      this.http.get(`users/${userId}/kpis.json`).then(
        response => {
          commit(types.SET_KPIS, response.data);
          resolve();
        },
        error => {
          commit(`errors/${types.SET_ERRORS}`, error.response.data, {
            root: true,
          });
          reject(error);
        }
      );
    });
  },
  loadAccounts({ commit }, { query, page = 1 }) {
    if (debounce) {
      loadAccountSearchDebounced(commit, query, page);
    }
  },
  resetAccounts({ commit }) {
    commit(types.RESET_ACCOUNTS);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<script setup>
import { computed, toRef } from 'vue';
import BookingLayout from '../BookingLayout.vue';
import { faqData } from './faq.js';
import PhotoSegmentCard from 'src/components/booking/v3/p2/number-of-photos/PhotoSegmentCard.vue';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaLink from 'src/components/ui_library/SoonaLink.vue';
import { useReservation } from 'src/queries/useReservation';
import { useReservation as useReservationComposable } from '@/composables/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useRoute } from 'vue-router';
import { useTagCategories } from '@/queries/useTagCategories';
import { useFlag } from '@/composables/useFlag';

// props and data
const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const { itemSelected, buttonClicked } = useBaseEvents();
const route = useRoute();
const reservationId = computed(() => props.reservationId);

// api calls
const {
  data: numberOfPhotoData,
  isLoading,
  status,
  error: resTagsError,
} = useReservationTags(reservationId, toRef(props, 'stepName'));

const numberOfPhotoOptions = computed(() => {
  return numberOfPhotoData.value?.options || [];
});
const { data: reservation, error } = useReservation(reservationId);
const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId, {
  // skip invalidation so that we only update the reservation after the add has finished
  skipInvalidate: true,
});

const { proServiceSlugForSize } = useReservationComposable(
  computed(() => !!route.query?.pro_service && props.reservationId)
);

const {
  data: proServiceTagCategory,
  isLoading: isLoadingProServiceTagCategory,
} = useTagCategories(
  computed(() => ({ slug: proServiceSlugForSize.value, include_tags: true })),
  { enabled: computed(() => !!proServiceSlugForSize.value) }
);

const mostPopularPhotoQuantityFlag = useFlag(
  'apollo_most_popular_photo_quantity'
);

// computed
const isBusy = computed(() => {
  return (
    isLoading.value ||
    isLoadingProServiceTagCategory.value ||
    isAdding.value ||
    isRemoving.value
  );
});
let currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});
let isStepValid = computed(() => {
  return !isBusy.value && currentStep.value?.complete;
});

const priorityError = usePriorityError(
  removeError,
  addError,
  resTagsError,
  error
);

// methods
function onBackClick() {
  props.onBack();
}

function onNextClick() {
  if (route.query?.pro_service) {
    const proServiceTagId = proServiceTagCategory.value?.[0].tags?.find(
      t =>
        t.title.replace(/\s+/g, '_').replace(/&/g, 'and') ===
        route.query.pro_service
    )?.id;

    const hasThisProService = reservation.value?.tags?.find(
      t =>
        t.title.replace(/\s+/g, '_').replace(/&/g, 'and') ===
        route.query.pro_service
    );

    if (!hasThisProService) {
      addReservationTag(
        { tagId: proServiceTagId },
        {
          onSuccess: () => {
            props.onNext();
          },
        }
      );
    } else {
      props.onNext();
    }
  } else {
    props.onNext();
  }
}

function addSelectedOption(selectedOption) {
  addReservationTag(
    { tagId: selectedOption.tag.id },
    {
      onSuccess: () => {
        itemSelected({
          context: 'booking',
          subContext: 'photo quantity',
          itemLabel: selectedOption.tag.title,
          itemPrice: 0,
          itemQuantity: 1,
        });
      },
    }
  );
}

function onSelectionChange(selectedOption) {
  if (selectedOption.selected || isBusy.value) return;

  const existingSelected = numberOfPhotoOptions.value.find(
    option => option.selected && option.tag.id !== selectedOption.tag.id
  );
  if (existingSelected) {
    removeReservationTag(
      {
        reservationTagId: existingSelected.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSuccess: () => {
          addSelectedOption(selectedOption);
        },
      }
    );
  } else {
    addSelectedOption(selectedOption);
  }
}

function captureSalesLinkClick() {
  buttonClicked({
    context: 'booking',
    subContext: 'photo quantity',
    buttonLabel: `schedule time to talk with us`,
    buttonAction: `link to hubspot sales intake form`,
  });
}
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="number-of-photos prebooking-transition__content">
        <SoonaLoading
          v-if="!reservation"
          :is-loading="!reservation"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading>
          how many photos do you need?
          <template #subtitle>
            an estimate is just fine! this helps us determine the length of your
            shoot. photos are $39 each and you’ll only pay for what you love
            after your shoot wraps.
          </template>
        </SoonaHeading>
        <div v-if="status === 'success'" class="number-of-photos__segments">
          <PhotoSegmentCard
            v-for="(option, i) in numberOfPhotoOptions"
            :key="i"
            :is-disabled="isBusy"
            :is-selected="option.selected"
            :is-most-popular="
              mostPopularPhotoQuantityFlag
                ? option.tag.title === '11 - 15'
                : option.tag.title === '6 - 10'
            "
            :option="option"
            @on-selection-change="onSelectionChange"
          />
        </div>
        <h2 class="u-headline--heavy">need more than 25 photos?</h2>
        <SoonaLink
          variation="black"
          to="https://soona.co/contact-sales"
          target="_blank"
          @click="captureSalesLinkClick"
        >
          connect with a soona expert
        </SoonaLink>
        <SoonaFaq :faq-tab-list="faqData" />
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.number-of-photos {
  &__segments {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  &__not-sure {
    @include variables_fonts.u-body--regular;

    text-decoration: underline;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }

  &__recommendation {
    display: flex;
    align-items: center;

    svg {
      flex: 0 0 1.75rem;
      margin-right: 0.5rem;
    }
  }

  :deep(.booking-checkbox) {
    label {
      @include variables_fonts.u-body--regular;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__segments {
      gap: 2rem;
    }
  }
}
</style>

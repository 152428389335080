<script setup>
import { computed } from 'vue';
import trySoonaModalImage from '@images/subscriptions/subx_information_step@2x.png';
import FeatureList from '@/components/subscriptions/FeatureList.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import StepLayout from '@/components/modal-payment-flows/subscriptions/StepLayout.vue';

const props = defineProps({
  context: {
    default: null,
    type: String,
  },
  headingId: {
    default: 'subscriptions-dialog-label',
    type: String,
  },
  isLoading: {
    default: false,
    type: Boolean,
  },
  selectedTier: {
    default: () => ({}),
    type: Object,
  },
  subContext: {
    required: true,
    type: String,
  },
});

const emits = defineEmits(['close']);

const isLoading = computed(() => props.isLoading);
const tierFeatureList = [
  {
    icon: 'wand-magic-sparkles',
    details: 'make',
    description:
      'access to our full suite of AI editing and scene generation tools. (250 monthly scene generations)',
  },
  {
    icon: 'shapes',
    details: 'manage',
    description:
      'upload your brand assets, publish to Amazon & Shopify, and organize all your products.',
  },
  {
    icon: 'chart-simple',
    details: 'measure',
    description:
      'get personalized Amazon/Shopify suggestions, competitive watchlists, industry advantages.',
  },
];

const tierTitle = computed(() => props.selectedTier?.name);
const tierIcon = computed(() => props.selectedTier?.icon_name);
const tierSlug = computed(() => props.selectedTier?.slug);
</script>

<template>
  <StepLayout
    class="information-step"
    :heading-id="headingId"
    :show-back-button="false"
    :tier-icon="tierIcon"
    :tier-slug="tierSlug"
    :tier-title="tierTitle"
    :no-image-padding="true"
    @close="emits('close')"
  >
    <template #heading>try soona basic for free</template>
    <template #subheading>
      make, manage and measure all of your content. yearly subscribers get 3
      free studio photos
    </template>
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <div class="information-step__features">
      <FeatureList :feature-list="tierFeatureList" />
    </div>
    <div class="information-step__action-btns">
      <SoonaButton
        variation="solid-black"
        data-cypress="button-subscriptions-dialog-continue"
        @on-click="emits('close')"
      >
        close
      </SoonaButton>
    </div>
    <template #main-image>
      <img
        class="information-step__star-top"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img
        class="information-step__star-bottom"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img
        :src="trySoonaModalImage"
        alt="make manage measure all of your content"
      />
    </template>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.information-step {
  &__includes-heading {
    padding-bottom: 1rem;
  }

  &__features {
    flex-grow: 1;
  }

  &__action-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: auto;
    padding-top: 2rem;
  }

  &__star-top {
    position: absolute;
    left: 1rem;
    top: 4rem;
    height: 4.9375rem !important;
    width: 4.9375rem !important;
  }

  &__star-bottom {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    height: 5.625rem !important;
    width: 5.625rem !important;
  }
}
</style>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param {string | Ref<string>} accountId
 * @param {string | Ref<string>} collectionId
 * @param {string | Ref<string>} reservationId
 *
 * @returns UseMutationReturnType<*>
 */
export function useCreateReEditsCollectionDigitalAsset(
  accountId,
  collectionId,
  reservationId
) {
  const queryClient = useQueryClient();
  const createCollection = async () => {
    const createResponse = await http.post(
      `/accounts/${toValue(accountId)}/re_edits_collections`,
      {
        re_edits_collection: {
          account_id: toValue(accountId),
          reservation_id: toValue(reservationId),
          status: 'draft',
          category: '',
        },
      }
    );
    return createResponse.data.id;
  };

  return useMutation({
    mutationFn: async body => {
      const collectionIdVal =
        toValue(collectionId) ?? (await createCollection());

      const response = await http.post(
        `/collections/${collectionIdVal}/re_edits_collection_digital_assets`,
        { collection_digital_asset: body }
      );
      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationDigitalAssets(),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservation(reservationId),
        }),
      ]);
    },
  });
}

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<Number>} orderId
 * @param {number | Ref<Number>} addOnLineItemId
 */
export function useGetOrderAddOn({ orderId, addOnLineItemId }) {
  return useQuery({
    queryKey: queryKeys.orderAddOn(orderId, addOnLineItemId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/orders/${unref(orderId)}/add_ons/${unref(addOnLineItemId)}.json`,
        {
          signal,
        }
      );

      return response.data;
    },
  });
}

<template>
  <CompleteProfileCard
    class="brand-style"
    :show-next="false"
    :show-skip="false"
  >
    <template #heading>what’s your brand’s visual style? 🕵️‍♀️</template>
    <div class="btn-wrapper">
      <SoonaButton
        v-if="!styleQuizResults"
        class="quiz-btn"
        element="router-link"
        :to="{ name: 'style-quiz' }"
      >
        take the style quiz
      </SoonaButton>
      <p v-else class="u-body--heavy style-result">
        <span>
          {{ styleQuizResults.icon }}
        </span>
        {{ styleQuizResults.title }}
      </p>
    </div>
    <template v-if="account.current_quiz_style" #action-btns>
      <SoonaButton @on-click="finishProfileQuiz">done</SoonaButton>
    </template>
  </CompleteProfileCard>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

export default {
  name: 'VisualStyle',
  components: { CompleteProfileCard, SoonaButton },
  computed: {
    ...mapState({ account: state => state.account }),
    styleQuizResults() {
      return this.account.current_quiz_style;
    },
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    async finishProfileQuiz() {
      let info = {
        style_quiz: {
          complete: true,
        },
      };
      try {
        await this.updateAccount({ accountParams: info });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(new Error('Finishing profile quiz failed'), {
            extra: { error },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.brand-style {
  .btn-wrapper {
    align-items: center;
    background: variables.$gray-10;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 1rem;
    width: 100%;
  }

  :deep(.quiz-btn) {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }

  .style-result {
    display: flex;
    gap: 0.5rem;
  }
}
</style>

<script setup>
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';

const props = defineProps({
  submitButtonText: {
    type: String,
    required: true,
  },
  errors: {
    type: Array,
    default: null,
  },
});
const emits = defineEmits(['cancel-clicked', 'submit-clicked']);
const handleSubmit = event => {
  event.preventDefault();
  emits('submit-clicked');
};

const handleCancel = event => {
  event.preventDefault();
  emits('cancel-clicked');
};
</script>

<template>
  <div class="form-submission">
    <div class="form-submission__save-button-container">
      <SoonaButton
        class="form-submission__cancel-button"
        :on-click="handleCancel"
        variation="tertiary"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        class="form-submission__save-button"
        :on-click="handleSubmit"
        variation="primary"
      >
        {{ props.submitButtonText }}
      </SoonaButton>
    </div>

    <div v-if="errors" class="form-submission__divider" />

    <div class="form-submission__errors">
      <SoonaError v-for="(error, index) in errors" :key="index">
        <p>{{ error[0] }}</p>
      </SoonaError>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';
.form-submission {
  &__save-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
  }
  &__divider {
    width: 100%;
    height: 0.0625rem;
    background: variables.$gray-30;
    margin: 0.5rem 0;
  }
}
</style>

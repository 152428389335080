import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { putInventoryPackagesPackageId } from '@/api/inventoryPackages';
import { unref } from 'vue';
import { queryKeys } from '../query-keys';
import * as Sentry from '@sentry/vue';

/**
 *
 * @param packageId {Ref<number> | number}
 * @returns {UseMutationReturnType<AxiosResponse<*>, unknown, void, unknown>}
 */
export function useUpdateInventoryPackage(packageId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: packageAttributes =>
      putInventoryPackagesPackageId(unref(packageId), {
        packageAttributes,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.inventoryPackages(),
      });
    },
    onError: error => {
      Sentry.captureException(
        new Error(`Updating package (id: ${unref(packageId)}) failed`),
        {
          extra: { error },
        }
      );
    },
  });
}

<script setup>
import { computed } from 'vue';
import BaseSubscription from '@/components/subscriptions/BaseSubscription.vue';
import ListingBreakdownCard from '@/components/user/anytime/listing_insights/ListingBreakdownCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDonutChart from '@/components/ui_library/SoonaDonutChart.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaMediumImageBanner from '@/components/ui_library/SoonaMediumImageBanner.vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useGetListingInsights } from '@/queries/useListings';
import { useIntegrations } from '@/composables/useIntegrations';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import { Tangerine30, Tangerine20 } from 'src/variables.module.scss';

const props = defineProps({
  accountId: { type: [Number, String], required: true },
});

const route = useRoute();
const { linkClicked } = useBaseEvents();

function openLink(linkLabel, linkHref, linkLocation) {
  linkClicked({
    context: route.meta.context,
    subContext: linkLocation,
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
}

const accountId = computed(() => props.accountId);
const {
  actionableIntegrations,
  primaryIntegration,
  primaryPlatformName,
  integrationsSuccess: integrationsLoaded,
  integrationsError,
  hasActiveAmazonOrShopifyIntegration,
} = useIntegrations(accountId);

const integrationId = computed(() => primaryIntegration.value?.id);
const iconName = computed(
  () => `${primaryPlatformName.value?.toLowerCase()}-logo`
);

// listings data
const {
  data: listingInsightsData,
  isSuccess: listingInsightsLoaded,
  error: listingInsightsError,
} = useGetListingInsights(accountId, integrationId, {
  enabled: computed(() => hasActiveAmazonOrShopifyIntegration.value),
});

const listingIsCalculating = computed(() => {
  return (
    listingInsightsLoaded.value &&
    !listingInsightsData.value?.all_listings_scored
  );
});

const totalListings = computed(
  () => listingInsightsData.value?.listing_breakdown?.total_listings
);

// format chart data for breakdown donut
const formattedRatingsChartData = computed(() => {
  return listingInsightsData.value?.listing_breakdown?.ratings.reduce(
    (acc, curr) => {
      let newObj = {
        label: curr.label,
        value:
          listingInsightsData.value?.listing_breakdown?.total_listings === 0
            ? 0
            : curr.value /
              listingInsightsData.value?.listing_breakdown?.total_listings,
      };

      acc.push(newObj);
      return acc;
    },
    []
  );
});

const priorityError = usePriorityError(integrationsError, listingInsightsError);
</script>

<template>
  <div class="listing-insights-banner">
    <SoonaError v-if="priorityError" class="listing-insights-banner__error">
      {{ priorityError }}
    </SoonaError>
    <BaseSubscription v-if="integrationsLoaded" :account-id="accountId">
      <template #loading>
        <SoonaSkeleton
          class="listing-insights-banner__skeleton"
          :custom-color-background="Tangerine30"
          :custom-color-gradient="Tangerine20"
        />
      </template>
      <template #success>
        <span v-if="hasActiveAmazonOrShopifyIntegration">
          <div
            v-if="!listingInsightsLoaded || listingIsCalculating"
            class="listing-insights-banner__skeleton--container"
          >
            <div class="listing-insights-banner__skeleton--text">
              <SoonaIcon name="wand-magic-sparkles-monochrome" size="large" />
              <h4 class="u-title--heavy">analyzing your listings...</h4>
              <p class="u-body--regular">
                this may take a few minutes as we work our magic.
              </p>
            </div>
            <SoonaSkeleton
              class="listing-insights-banner__skeleton"
              :custom-color-background="Tangerine30"
              :custom-color-gradient="Tangerine20"
            />
          </div>

          <div v-else class="listing-insights-banner__subscribed">
            <div class="listing-insights-banner__subscribed--header">
              <h4 class="u-title--heavy">listing insights</h4>
              <SoonaButton
                class="listing-insights-banner__subscribed--header__all-listings"
                variation="tertiary"
                element="a"
                :href="`/user/anytime#/account/${accountId}/listing-insights/integration/${integrationId}/store-list`"
                @click="
                  openLink(
                    'see all',
                    'user/anytime#/account/listing-insights/integration/store-list',
                    'listing insights banner'
                  )
                "
              >
                see all
                <SoonaIcon
                  class="listing-insights-banner__subscribed--header__all-listings--icon"
                  name="arrow-right"
                  size="small"
                />
              </SoonaButton>
            </div>
            <div class="listing-insights-banner__subscribed--subheader">
              <div class="logo">
                <SoonaIcon :name="iconName" size="small" />
                <span class="u-label--heavy">{{ primaryPlatformName }}</span>
              </div>

              <p class="u-label--regular">
                {{ totalListings }}
                {{ totalListings === 1 ? 'listing' : 'listings' }}
              </p>
            </div>
            <div class="listing-insights-banner__subscribed--data">
              <SoonaDonutChart
                class="listing-insights-banner__subscribed--data-donut"
                accessible-title="listing breakdown"
                :data="formattedRatingsChartData"
                size="large"
              />
              <div class="listing-insights-banner__subscribed--data-cards">
                <ListingBreakdownCard
                  v-for="ratings in listingInsightsData?.listing_breakdown
                    ?.ratings"
                  :key="ratings.label"
                  :account-id="accountId"
                  :listing-label="ratings.label"
                  :listing-count="ratings.value"
                  :integration-id="integrationId"
                  :has-valid-integration="actionableIntegrations.length > 0"
                  size="small"
                />
              </div>
            </div>
          </div>
        </span>

        <SoonaMediumImageBanner
          v-else
          class="listing-insights-banner__unsubscribed"
          :background-color="Tangerine20"
        >
          <template #title>fix your listings</template>
          <template #content>
            get your Amazon content score and learn how to improve it.
          </template>
          <template #image>
            <img src="@images/fix_your_listings@2x.png" alt="" />
          </template>
          <template #button>
            <SoonaButton
              variation="secondary-black"
              element="router-link"
              :href="`user/anytime#/account/${accountId}/listing-insights`"
              :to="{
                name: 'listing-insights-dashboard',
                params: { accountId },
              }"
              @click="
                openLink(
                  'get my score',
                  `user/anytime#/account/listing-insights`,
                  'fix your listings'
                )
              "
            >
              get my score
            </SoonaButton>
          </template>
        </SoonaMediumImageBanner>
      </template>
    </BaseSubscription>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.listing-insights-banner {
  &__error {
    margin-top: 0;
  }

  &__skeleton {
    border-radius: 0.75rem;
    height: 100%;
    min-height: 12.5rem;

    &--container {
      height: 100%;
      position: relative;
    }

    &--text {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  &__subscribed {
    background-color: variables.$tangerine-20;
    border-radius: 0.75rem;
    padding: 1.5rem;

    @media (min-width: variables.$screen-md-min) {
      padding: 2rem;
    }

    &--header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 1rem;

      &__all-listings {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        &--icon {
          transition: transform 0.1s ease-out;
        }

        &:hover,
        &:focus-visible {
          text-decoration: underline;
          color: variables.$black-default;
          .header__all-listings-button--icon {
            transform: translateX(0.1875rem);
          }
        }
      }
    }

    &--subheader {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 2rem;
      margin-bottom: 1rem;

      .logo {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }

    &--data {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;

      &-donut {
        max-height: 10.5rem;
        max-width: 10.5rem;
      }

      &-cards {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  &__unsubscribed {
    height: 100%;
  }
}
</style>

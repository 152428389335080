<script setup>
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

const chatWithExpertUrl = import.meta.env.VITE_TREND_CHAT_WITH_EXPERT_URL;

const route = useRoute();
const { linkClicked } = useBaseEvents();
</script>

<template>
  <p class="chat-with-expert u-headline--heavy">
    have questions?
    <a
      :href="chatWithExpertUrl"
      target="_blank"
      class="chat-with-expert__link"
      @click="
        linkClicked({
          linkHref: chatWithExpertUrl,
          linkLabel: 'chat with an expert',
          context: route.meta.context,
          subContext: 'trend - chat with an expert',
        })
      "
    >
      chat with an expert<SoonaIcon name="phone" />
    </a>
  </p>
</template>

<style lang="scss" scoped>
.chat-with-expert {
  text-align: center;

  &__link {
    display: inline-block;
    text-decoration: underline;

    svg {
      margin-left: 0.25rem;
      margin-bottom: -0.25rem;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaItemCard from 'src/components/ui_library/SoonaItemCard.vue';

const props = defineProps({
  id: {
    type: [String, Number],
    required: true,
  },
  imageUrl: {
    type: String,
    required: false,
  },
  isLowercase: {
    type: Boolean,
    default: true,
  },
});

const elementId = computed(() => `scene-item-${props.id}`);
</script>

<template>
  <SoonaItemCard
    :aria-labelledby="`${elementId}-heading`"
    :image-url="imageUrl"
  >
    <template #content>
      <div class="scene-item__wrapper">
        <div class="scene-item__content">
          <h2
            :id="`${elementId}-heading`"
            class="scene-item__content__heading"
            :class="{
              'scene-item__content__heading-lowercase': isLowercase,
            }"
          >
            <slot name="heading" />
          </h2>
        </div>
        <div class="scene-item__actions">
          <slot name="actions" />
        </div>
      </div>
    </template>
  </SoonaItemCard>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';

.scene-item {
  &__wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem 0.75rem;
    height: 100%;
  }

  &__content {
    flex: 1 1 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__heading {
      @include variables_fonts.u-body--heavy;

      &-lowercase {
        text-transform: lowercase;
      }
    }
  }
}
</style>

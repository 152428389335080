<script setup>
import { computed, ref } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import ProductChip from '@/components/user/anytime/products/ProductChip.vue';
import { usePriorityError } from '@/composables/usePriorityError';

import {
  useCreateDigitalAssetCatalogItems,
  useDeleteDigitalAssetCatalogItem,
  useDigitalAssetCatalogItems,
} from '@/queries/digital_assets/useDigitalAssetCatalogItems';
import ProductPickerGroupTitle from '@/components/user/anytime/products/picker/ProductPickerGroupTitle.vue';
import PickerProductMultiselectOption from '@/components/user/anytime/products/picker/PickerProductMultiselectOption.vue';
import ProductMultiPicker from '@/components/user/anytime/products/picker/ProductMultiPicker.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId } = storeToRefs(mediaEditorStore);

const { reservationId } = useMediaEditorDigitalAsset(assetAccountId, assetId);

const { data: digitalAssetCatalogItems, error: digitalAssetCatalogItemsError } =
  useDigitalAssetCatalogItems(assetAccountId, assetId, {
    enabled: computed(() => !!assetAccountId.value && !!assetId.value),
  });

const existingCatalogItemIds = computed(
  () => digitalAssetCatalogItems.value?.map(item => item.id) ?? []
);

const isProductPickerOpen = ref(false);

const { mutate: createDigitalAssetCatalogItems, error: createError } =
  useCreateDigitalAssetCatalogItems(assetAccountId, assetId);

const { mutate: deleteDigitalAssetCatalogItem, error: deleteError } =
  useDeleteDigitalAssetCatalogItem(assetAccountId, assetId);

const selectCatalogItems = catalogItems => {
  createDigitalAssetCatalogItems({
    digital_asset_id: assetId.value,
    catalog_item_ids: catalogItems.map(item => item.id),
  });
  isProductPickerOpen.value = false;
};

// todo - how do we want to handle DAs with RDAs when viewing from DAM?
const groupProducts = catalogItems => {
  return catalogItems.reduce((itemMap, item) => {
    const itemCopy = { ...item };
    const group =
      reservationId.value &&
      itemCopy.attached_to_reservation_id === Number(reservationId.value)
        ? 'this booking'
        : 'more products';

    itemMap[group] ??= [];
    itemMap[group].push(itemCopy);
    return itemMap;
  }, {});
};

const removeProduct = catalogItemId => {
  deleteDigitalAssetCatalogItem(catalogItemId);
};

const priorityError = usePriorityError(
  digitalAssetCatalogItemsError,
  createError,
  deleteError
);
</script>

<template>
  <div class="products-section">
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <div
      v-if="digitalAssetCatalogItems && digitalAssetCatalogItems.length > 0"
      class="products-section__products-container"
    >
      <ProductChip
        v-for="item in digitalAssetCatalogItems"
        :key="item.id"
        :item="item"
        :account-id="assetAccountId"
        removable
        @remove="removeProduct(item.id)"
      />
    </div>
    <div class="products-section__actions">
      <SoonaButton
        variation="tertiary"
        data-cypress="products-action-tag-asset"
        title="tag products in this asset"
        @click="isProductPickerOpen = true"
      >
        <SoonaIcon name="plus-large" />
        tag products
      </SoonaButton>
    </div>
    <ProductMultiPicker
      v-if="isProductPickerOpen"
      :account-id="assetAccountId"
      :attached-to-reservation-id="reservationId"
      heading="tag products"
      :order-by="`${reservationId ? 'attached_to_reservation_id' : 'name'}`"
      :existing-selected-items="existingCatalogItemIds"
      @cancel="isProductPickerOpen = false"
      @select="selectCatalogItems"
    >
      <template
        #product-options="{
          catalogItems,
          selectedCatalogItemIdsString,
          multiSelectChange,
        }"
      >
        <template
          v-for="(items, group) in groupProducts(catalogItems)"
          :key="group"
        >
          <ProductPickerGroupTitle>{{ group }}</ProductPickerGroupTitle>
          <PickerProductMultiselectOption
            v-for="product in items"
            :key="product.id"
            :product="product"
            :checked="
              selectedCatalogItemIdsString.includes(product.id.toString()) ||
              existingCatalogItemIds.includes(product.id)
            "
            :disabled="existingCatalogItemIds.includes(product.id)"
            @change="multiSelectChange($event, product)"
          />
        </template>
      </template>
    </ProductMultiPicker>
  </div>
</template>

<style lang="scss" scoped>
.products-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__products-container {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;

    &--column {
      flex-direction: column;
      order: 1;
    }
  }
}
</style>

<template>
  <div class="admin-trend-sets">
    <div class="admin-trend-sets__header">
      <h2 class="u-display--regular">all trend sets</h2>
      <AdminCreateTrendSetButton />
    </div>
    <div v-if="display" class="admin-trend-sets__all-trend-sets">
      <AdminTrendSetFilterName
        v-for="(tags, filterName) in allTrendSets"
        :key="filterName"
        :filter-name="filterName"
        :tags="tags"
      />
    </div>
    <AdminDeletedTrendSets />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AdminCreateTrendSetButton from './AdminCreateTrendSetButton.vue';
import AdminTrendSetFilterName from './AdminTrendSetFilterName.vue';
import AdminDeletedTrendSets from './AdminDeletedTrendSets.vue';

export default {
  name: 'AdminTrendSets',
  components: {
    AdminTrendSetFilterName,
    AdminCreateTrendSetButton,
    AdminDeletedTrendSets,
  },
  data() {
    return {
      display: false,
    };
  },
  computed: {
    ...mapState({
      allTrendSets: state => state.trendSetBuilder.allTrendSets,
    }),
  },
  async beforeMount() {
    // Wait until state is set before mounting child components,
    //  otherwise they'll run with outdated data
    await this.loadTrendSets();
    this.display = true;
  },
  methods: {
    ...mapActions('trendSetBuilder', ['loadTrendSets']),
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.admin-trend-sets {
  &__header {
    display: flex;
    padding-bottom: 72px;
    padding-left: 40px;

    h2 {
      padding-right: 32px;
    }
  }

  &__all-trend-sets {
    border-bottom: 1px solid variables.$gray-30;
    margin-bottom: 41px;
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import ProfileCard from 'src/components/directory/ProfileCard.vue';
import PaginatorFull from 'src/components/directory/PaginatorFull.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import { useFlag } from 'src/composables/useFlag';
import BookModelDialog from 'src/components/directory/BookModelDialog.vue';

defineProps({
  sort: {
    type: String,
    required: true,
    validator: value => {
      return ['asc', 'desc', 'avl'].includes(value);
    },
  },
  proServiceProfiles: {
    type: Object,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  itemsPerPage: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
  totalCount: {
    type: Number,
    required: true,
  },
});
defineEmits(['update:currentPage', 'update:sort', 'update:itemsPerPage']);

const availabilityFlag = useFlag('apollo_availability_sort');

const sortOptions = computed(() => {
  const options = [
    { value: 'asc', label: 'names a-z' },
    { value: 'desc', label: 'names z-a' },
  ];
  if (availabilityFlag.value) {
    options.push({ value: 'avl', label: 'availability' });
  }
  return options;
});

const bookModelDialogOpen = ref(false);
const profileToBook = ref(null);

const openBookModelDialog = profile => {
  profileToBook.value = profile;
  bookModelDialogOpen.value = true;
};
</script>
<template>
  <div class="directory-profiles">
    <div class="directory-header">
      <p class="provider-count">{{ totalCount?.toLocaleString() }} providers</p>
      <label class="sort-label">
        sort by&nbsp;
        <SoonaSelect
          class="sort-select"
          :model-value="sort"
          :options="sortOptions"
          :searchable="false"
          @update:model-value="$emit('update:sort', $event)"
        />
      </label>
    </div>
    <ProfileCard
      v-for="profile in proServiceProfiles"
      :key="profile.id"
      :profile="profile"
      :data-cypress="profile.id + '-profile-card'"
      @open-book-model-dialog="openBookModelDialog"
    />
    <PaginatorFull
      :page="currentPage"
      :records-per-page="itemsPerPage"
      :total-pages="totalPages"
      :total-count="totalCount"
      :page-count="proServiceProfiles.length"
      @update:records-per-page="$emit('update:itemsPerPage', $event)"
      @update:page="$emit('update:currentPage', $event)"
    />
    <BookModelDialog
      v-if="bookModelDialogOpen"
      :profile="profileToBook"
      @close="() => (bookModelDialogOpen = false)"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
.directory-profiles {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 100%;
  background-color: variables.$periwink-blue-20;
  padding: 1rem;

  .directory-header {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;

    .provider-count {
      font-size: 0.75rem;
      font-weight: 800;
    }

    .sort-label {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      font-weight: 800;

      .sort-select {
        border-radius: 0.3125rem;
        background-color: variables.$white-default;
      }
    }
  }
}
</style>

import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { editsCollectionDigitalAssets } from '@/api/editsCollectionDigitalAssets';

/**
 *
 * @param {string | number} collectionId
 * @param {Object} [params]
 * @param {number} [params.itemsPerPage]
 */
export function useInfiniteEditsCollectionDigitalAssets(
  collectionId,
  { itemsPerPage, filters } = {}
) {
  return useInfiniteQuery({
    queryKey: queryKeys.collectionAssets(collectionId, {
      itemsPerPage,
      filters,
      infinite: true,
    }),
    queryFn: async ({ signal, pageParam }) => {
      return editsCollectionDigitalAssets(
        toValue(collectionId),
        {
          currentPage: pageParam.currentPage,
          itemsPerPage: toValue(itemsPerPage),
          filters: toValue(filters),
        },
        signal
      );
    },
    initialPageParam: { currentPage: 1, itemsPerPage },
    getNextPageParam: lastPage => {
      if (
        lastPage.pagination.currentPage + 1 >
        lastPage.pagination.totalPages
      ) {
        return null;
      } else {
        return {
          currentPage: lastPage.pagination.currentPage + 1,
          itemsPerPage: lastPage.pagination.itemsPerPage,
        };
      }
    },
  });
}

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getSuggestedReservationLocation } from '../api/reservation_locations';

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useGetSuggestedReservationLocation(reservationId) {
  return useQuery({
    queryKey: queryKeys.reservationSuggestedLocation(reservationId),
    queryFn: ({ signal }) =>
      getSuggestedReservationLocation(unref(reservationId), signal),
  });
}

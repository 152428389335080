import { toValue } from 'vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import {
  getDigitalAssetCatalogItems,
  createDigitalAssetCatalogItems,
  deleteDigitalAssetCatalogItem,
} from 'src/api/catalog';
import { queryKeys } from 'src/queries/query-keys';

/**
 * @param { ref } accountId
 * @param { ref } digitalAssetId
 * @param { ref } queryOptions
 */
export function useDigitalAssetCatalogItems(
  accountId,
  digitalAssetId,
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.digitalAssetCatalogItems(accountId, digitalAssetId),
    queryFn: ({ signal }) =>
      getDigitalAssetCatalogItems(
        toValue(accountId),
        toValue(digitalAssetId),
        signal
      ),
    ...queryOptions,
  });
}

/**
 * @param { ref } accountId
 * @param { ref } digitalAssetId
 */
export function useCreateDigitalAssetCatalogItems(accountId, digitalAssetId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body =>
      createDigitalAssetCatalogItems(
        toValue(accountId),
        toValue(digitalAssetId),
        body
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.digitalAssetCatalogItems(accountId, digitalAssetId),
      });
    },
  });
}

/**
 * @param { ref } accountId
 * @param { ref } digitalAssetId
 */
export function useDeleteDigitalAssetCatalogItem(accountId, digitalAssetId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: catalogItemId =>
      deleteDigitalAssetCatalogItem(
        toValue(accountId),
        toValue(digitalAssetId),
        catalogItemId
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.digitalAssetCatalogItems(accountId, digitalAssetId),
      });
    },
  });
}

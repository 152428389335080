<script setup>
import { inject, toRefs, computed, useTemplateRef } from 'vue';
import SoonaIcon from '../soona_icon/SoonaIcon.vue';
const props = defineProps({
  value: {
    type: [Number, String],
    default: null,
  },
});

const { value } = toRefs(props);
const optionRef = useTemplateRef('comboboxOption');

const { modelValue, onChange, highlightedElementID } =
  inject('comboboxContext');

const isActive = computed(() => {
  const mValue = modelValue?.value;

  if (!mValue) return false;
  return mValue == value.value || mValue == optionRef.value?.textContent;
});

const optionId = computed(
  () => `combobox-option-${value.value ?? optionRef.value?.textContent}`
);
</script>

<template>
  <li
    :id="optionId"
    ref="comboboxOption"
    role="option"
    :aria-selected="isActive"
    :data-value="value ?? optionRef?.textContent"
    :data-highlighted="highlightedElementID == optionId"
    @click.capture="onChange(value ?? optionRef?.textContent)"
    @mouseenter="highlightedElementID = optionId"
  >
    <slot />
    <SoonaIcon
      v-if="isActive"
      name="check"
      size="small"
      class="selected-icon"
    />
  </li>
</template>

<style lang="scss" scoped>
@use '@/variables';

[role='option'] {
  cursor: pointer;
  position: relative;
  list-style-type: none;
  padding: 0.5rem 2.25rem 0.5rem 0.75rem;

  & .selected-icon {
    top: 50%;
    right: 0.75rem;
    position: absolute;
    margin-top: 0.125rem;
    transform: translateY(-50%);
    color: variables.$periwink-blue-60;
  }
}

[data-highlighted='true'] {
  background-color: variables.$gray-20;
}

[aria-selected='true'] {
  background-color: variables.$periwink-blue-20;

  &:hover {
    background-color: variables.$periwink-blue-20;
  }
}
</style>

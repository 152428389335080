<script setup>
import { ref } from 'vue';
import SoonaDialog from 'src/components/ui_library/SoonaDialog.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

const props = defineProps({
  hasGoogle: {
    default: false,
  },
});

const showConnectionModal = ref(false);
</script>
<template>
  <div class="third-party-connections">
    <h2 class="third-party-connections__section-header">connections</h2>
    <div class="third-party-connections__connected-accounts-container">
      <SoonaButton
        v-if="props.hasGoogle"
        type="button"
        :on-click="() => (showConnectionModal = true)"
        variation="secondary-gray"
        size="medium"
      >
        <img src="@images/google-icon.svg" alt="google logo" /> Google
      </SoonaButton>
    </div>

    <SoonaDialog
      v-if="showConnectionModal"
      @close="showConnectionModal = false"
    >
      <template #heading>want to disconnect your Google account?</template>
      <template #default>
        <p>
          once you disconnect your account you’ll be signed out and receive an
          email to reset your password.
        </p>

        <div class="third-party-connections__disconnect-btn">
          <SoonaButton
            element="a"
            href="/users/disconnect_provider?provider=google_oauth2"
            variation="secondary-gray"
            size="medium"
          >
            <img src="@images/google-icon.svg" alt="google logo" /> disconnect
            my Google account
          </SoonaButton>
        </div>
      </template>
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @click="close">cancel</SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.third-party-connections {
  &__section-header {
    @include variables_fonts.u-label--heavy;

    align-items: center;
    color: variables.$black-default;
    display: flex;
    margin-bottom: 0.4375rem;

    @media (min-width: variables.$screen-md-min) {
      &--chonky {
        @include variables_fonts.u-body--heavy;
      }
    }
  }

  &__connected-accounts-container {
    margin-bottom: 1rem;
    padding: 0.625rem;
    background: variables.$gray-10;
  }

  &__disconnect-btn {
    margin-top: 1rem;
  }
}
</style>

<script setup>
import { ref } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { track } from '@/lib/segment/segment';
import { useScriptTag } from '@vueuse/core';

const emit = defineEmits(['close', 'shootRequestSubmitted']);

const isLoading = ref(true);

useScriptTag('https://js.hsforms.net/forms/embed/v2.js', () => {
  if (window.hbspt) {
    window.hbspt.forms.create({
      region: 'na1',
      portalId: '23618366',
      formId: 'b60b9f07-97c5-4059-8324-ea0a840ab2ec',
      target: '#hubspotForm',
      onFormReady: function () {
        isLoading.value = false;
      },
      onFormSubmit: function (form) {
        const formData = new FormData(form);
        track('Form submitted', {
          context: 'listing insights',
          linkLabel: 'submit',
          linkURL: form.action,
          subContext: 'missing content hubspot_form',
          properties: {
            form_fields: {
              email: formData.get('email'),
              phone: formData.get('phone'),
              goals: formData.get('please_share_more_about_your_goals'),
            },
          },
        });
        emit('shootRequestSubmitted');
      },
    });
  }
});
</script>

<template>
  <SoonaDialog aria-label="submit shoot request" @close="$emit('close')">
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <div v-once id="hubspotForm"></div>
  </SoonaDialog>
</template>

<script setup>
import { useMediaQuery } from '@vueuse/core';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaCarousel from 'src/components/ui_library/SoonaCarousel.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  disabled: {
    default: false,
    required: false,
    type: Boolean,
  },
  images: {
    default: function () {
      return [];
    },
    validator(images) {
      return images?.length > 0;
    },
    required: true,
    type: Array,
  },
  isLoading: {
    default: false,
    required: false,
    type: Boolean,
  },
  isSelected: {
    default: false,
    required: true,
    type: Boolean,
  },
  isUnclickable: {
    default: false,
    required: false,
    type: Boolean,
  },
  onClick: {
    default: () => undefined,
    required: true,
    type: Function,
  },
  scene: {
    default: () => {
      return {
        title: undefined,
      };
    },
    required: true,
    type: Object,
  },
});
const matchMediaIsWide = useMediaQuery('(min-width: 48rem)');

function handleButtonClick() {
  if (props.scene && props.onClick) props.onClick(props.scene);
}
</script>

<template>
  <div class="scene-card">
    <figure
      v-if="(images && images.length === 1) || !matchMediaIsWide"
      class="scene-card__figure"
    >
      <img
        class="scene-card__image"
        :src="images[0].src"
        :alt="images[0].alt"
      />
    </figure>
    <SoonaCarousel
      v-else
      class="scene-card__carousel"
      :images="images.slice(0, 4)"
      :image-pips="true"
      :wrap-around="false"
      height="100%"
      width="100%"
    />
    <SoonaButton
      class="scene-card__add-btn"
      :aria-pressed="isSelected"
      :class="{
        'scene-card__add-btn--added': isSelected,
        'scene-card__add-btn--selected': isUnclickable,
      }"
      :loading="isLoading"
      loader-color="#b4b7bd"
      :on-click="handleButtonClick"
      :size="matchMediaIsWide ? 'large' : 'medium'"
      variation="secondary-gray"
      :data-cypress="`button-${scene.tag.title
        .toLowerCase()
        .replace(/\s+/g, '-')}`"
      :disabled="disabled"
    >
      <SoonaIcon
        v-if="isSelected"
        name="circle-check-solid"
        class="check-icon"
      />
      <SoonaIcon v-else name="plus-large" class="plus-icon" />
      <span v-if="scene.tag" class="scene-card__add-btn-copy">
        {{ scene.tag.title.toLowerCase() }}
        <span
          v-if="scene.tag && scene.tag.title.toLowerCase() === 'bedroom'"
          class="scene-card__add-btn__additional-info"
        >
          (stylist required)
        </span>
        <span
          v-if="
            scene.tag &&
            (scene.tag.title === 'showoff' || scene.tag.title === 'Unboxing')
          "
          class="scene-card__add-btn__additional-info"
        >
          (hand model required)
        </span>
      </span>
    </SoonaButton>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.scene-card {
  display: flex;
  flex-direction: column;

  :deep(.image-pips-wrapper) {
    height: 100%;
    width: 100%;
  }

  &__figure,
  &__carousel {
    flex-grow: 1;
  }

  &__image,
  :deep(.image-pips-wrapper > img) {
    border-radius: 0.625rem;
    display: block;
    height: 100%;
    object-fit: cover;
    max-height: 8.625rem;
    transition: max-height 0.3s;
    width: 100%;

    @media (min-width: variables.$screen-sm-min) {
      max-height: 12.8125rem;
    }
  }

  &__add-btn {
    margin-top: 0.5rem;
    transition: box-shadow 0.1s ease-out;
    width: 100%;

    .check-icon {
      color: variables.$success-graphic;
    }

    .plus-icon {
      color: variables.$gray-60;
    }

    &-copy {
      white-space: normal;
    }

    &__additional-info {
      font-weight: normal;
    }

    &:not(:disabled):hover {
      background-color: variables.$white-default;
      box-shadow: variables.$elevation-3;
    }

    @media (min-width: variables.$screen-sm-min) {
      margin-top: 0.625rem;
    }

    &--added {
      background-color: variables.$avo-toast-10 !important;
      border-color: variables.$black-default;
    }

    &--selected {
      pointer-events: none;
    }

    &-copy {
      @include variables_fonts.u-label--heavy;
      flex: 0 1 auto;

      @media (min-width: variables.$screen-sm-min) {
        @include variables_fonts.u-body--heavy;
      }
    }
  }
}
</style>

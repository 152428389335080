import { useCapability as useCapabilityQuery } from '../queries/useCapability';
import { computed, unref } from 'vue';

/**
 * @typedef CapabilityResult
 * @type {object}
 * @property {Ref<'success' | 'pending' | 'error'>} status
 * @property {Ref<boolean>} hasCapability
 */

/**
 *
 * @description the properties can all be a primitive or a ref, supporting
 * hardcoded value or dynamic ones, for example, a prop or piece of state.
 * this returns an object with computed ref properties, so it can be
 * destructured at the top level.
 *
 * @param {string | Ref<string>} capability
 * @param {string | Ref<string>} [subjectType]
 * @param {string | number | Ref<string | number>} [subjectId]
 * @param {Object} queryOptions
 * @returns CapabilityResult
 */
export function useCapability(
  { capability, subjectType, subjectId },
  queryOptions = undefined
) {
  const { data, status } = useCapabilityQuery(
    {
      subjectType,
      subjectId,
    },
    queryOptions
  );

  return {
    status,
    hasCapability: computed(
      () => data.value?.includes(unref(capability)) || false
    ),
  };
}

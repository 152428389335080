import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { unref } from 'vue';
import { updateShotListStatus } from '@/api/shot_list';

export function useUpdateShotListStatus(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => updateShotListStatus(unref(reservationId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservation(reservationId),
      });
    },
  });
}

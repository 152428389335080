<script setup>
import CheckoutAsset from '@/components/checkout/CheckoutAsset.vue';
import { computed } from 'vue';
import { useBagCollection } from '@/queries/bag_collection/useBagCollection';

const props = defineProps({
  accountId: {
    required: true,
    type: Number,
  },
  lineItems: {
    required: true,
    type: Object,
  },
  allSelected: {
    required: true,
    type: Boolean,
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
});

defineEmits(['asset-selected']);

const allSelected = computed(() => props.allSelected);
const accountId = computed(() => props.accountId);

const assetSelected = asset => {
  if (!asset.is_photo) return false;

  return allSelected.value || props.selectedAssets?.includes(asset.id);
};

const { data: bagCollection } = useBagCollection(accountId);
</script>
<template>
  <div class="asset-section">
    <CheckoutAsset
      v-for="lineItem in lineItems"
      :key="lineItem.id"
      :account-id="accountId"
      :bag-collection-id="bagCollection?.id"
      :line-item="lineItem"
      :is-selected="assetSelected(lineItem)"
      @selected="(checked, item) => $emit('asset-selected', checked, item)"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.asset-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: variables.$screen-sm-min) {
    gap: 2.5rem;
  }
}
</style>

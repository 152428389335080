import { useListingRuleResults } from './useListingRuleResults';
import { useCompetitorRuleResults } from './useCompetitorRuleResults';

export const useRuleResults = (listing, competitor) => {
  const {
    listingImageQualityRuleResults,
    listingGalleryCountRuleResult,
    listingVisualMixRuleResults,
  } = useListingRuleResults(listing);

  const {
    competitorImageQualityRuleResults,
    competitorGalleryCountRuleResult,
    competitorVisualMixRuleResults,
  } = useCompetitorRuleResults(competitor);

  return {
    listingImageQualityRuleResults,
    listingGalleryCountRuleResult,
    listingVisualMixRuleResults,
    competitorImageQualityRuleResults,
    competitorGalleryCountRuleResult,
    competitorVisualMixRuleResults,
  };
};

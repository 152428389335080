import { toValue } from 'vue';
import { useStore } from 'vuex';
import { useMutation } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<Object>} order
 */
export function useBulkPendRejectedDigitalAssets(order) {
  const store = useStore();

  return useMutation({
    mutationFn: async () => {
      const response = await http.post(
        `/orders/${toValue(order).id}/add_ons/bulk_pend_rejected.json`
      );

      return response.data;
    },
    onSuccess: async () => {
      await store.dispatch(
        'reservation/loadOrders',
        toValue(order).reservation_id
      );
    },
  });
}

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { reservationDigitalAssets } from '@/api/reservationDigitalAssets';

/**
 *
 * @param {number} [reservationId]
 * @param {Object} [params]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.facets]
 * @param {object} [params.filters]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 * */
export function useReservationDigitalAssets(
  reservationId,
  { activeAssetId, currentPage = 1, itemsPerPage = 10, facets, filters },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.reservationDigitalAssets(reservationId, {
      activeAssetId,
      currentPage,
      itemsPerPage,
      facets,
      filters,
    }),
    queryFn: ({ signal }) => {
      return reservationDigitalAssets(
        toValue(reservationId),
        {
          activeAssetId: toValue(activeAssetId),
          currentPage: toValue(currentPage),
          itemsPerPage: toValue(itemsPerPage),
          facets: toValue(facets),
          filters: toValue(filters),
        },
        signal
      );
    },
    ...queryOptions,
  });
}

<script setup>
import { computed } from 'vue';
import { useCreateReservationLineItem } from '@/queries/reservationLineItem/useCreateReservationLineItem';
import { useDeleteReservationLineItem } from '@/queries/reservationLineItem/useDeleteReservationLineItem';
import { toCurrency } from '@/lib/currency';
import BeatLoader from '@/components/shared/BeatLoader.vue';
import { useFlag } from '@/composables/useFlag';
import { useReservation } from '@/composables/useReservation';
import { useSubscription } from '@/composables/subscription/useSubscription';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const props = defineProps({
  addOn: {
    type: Object,
    required: true,
  },

  inputDisabled: {
    type: Boolean,
    default: false,
  },
});

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId } = storeToRefs(mediaEditorStore);

const { reservationId, isPhoto } = useMediaEditorDigitalAsset(
  assetAccountId,
  assetId
);

const addOn = computed(() => props.addOn);
const inputDisabled = computed(() => props.inputDisabled);

const { reservation } = useReservation(reservationId);

const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');

const { hasTierTwoSubscription, hasTierThreeSubscription } = useSubscription(
  reservation.value.account_id
);

const hasMembershipDiscount = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return hasTierTwoSubscription.value || hasTierThreeSubscription.value;
  } else {
    return false;
  }
});

const showAddOnPrice = computed(() => {
  if (!isPhoto.value) return true;
  if (addOn.value.price === 0) return false;
  return !hasMembershipDiscount.value;
});

const { mutate: addAddOn, isPending: addIsLoading } =
  useCreateReservationLineItem(reservationId);

const { mutate: deleteAddOn, isPending: deleteIsLoading } =
  useDeleteReservationLineItem(
    reservationId,
    computed(() => addOn.value.id)
  );

const isLoading = computed(() => addIsLoading.value || deleteIsLoading.value);

const toggleAddOn = () => {
  if (addOn.value.selected) {
    deleteAddOn();
  } else {
    addAddOn({
      product_id: addOn.value.product?.id,
      quantity: 1,
      digital_asset_id: assetId.value,
    });
  }
};
</script>

<template>
  <div v-if="isLoading" class="premium-edits__loader">
    <BeatLoader size="1em" />
  </div>
  <label>
    <input
      type="checkbox"
      :aria-label="addOn.product.name"
      :checked="addOn.selected"
      :disabled="inputDisabled || isLoading"
      @change="() => toggleAddOn()"
    />
    <span class="premium-edits__label">
      {{ addOn.product.name }}
    </span>
    <span v-if="showAddOnPrice">
      {{ toCurrency(addOn.price, 'USD', 0) }}
    </span>
  </label>
  <p class="u-label--regular premium-edits__description">
    {{ addOn.product.description }}
  </p>
</template>

<style scoped lang="scss">
@use '@/variables';

.premium-edits {
  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: variables.$white-translucent-50;
  }

  &__label {
    padding-left: 0.5rem;
    padding-right: 0.25rem;
  }

  &__description {
    color: variables.$gray-60;
  }
}
</style>

import { computed, toValue } from 'vue';

export const useVisualMixHeaderIcon = ruleSlug => {
  return computed(() => {
    switch (toValue(ruleSlug).toLowerCase()) {
      case 'product-on-white':
      case 'product on white':
        return 'shapes';
      case 'lifestyle':
        return 'house-tree';
      case 'model':
        return 'user-heart-alt-1';
      case 'close-up-detail':
      case 'close-up detail':
        return 'closeup';
      case 'scale-measurement':
      case 'scale measurement':
        return 'ruler-triangle';
      case 'infographic':
        return 'chart-simple';
      case 'multiple-angles':
      case 'multiple angles':
        return 'object-rotate';
      case 'consistent-color-scheme':
      case 'consistent color scheme':
        return 'colors';
      case 'ugc':
        return 'ugc';
      case 'inconsistent-aspect-ratio':
      case 'inconsistent aspect ratio':
        return 'resize';
      case 'slow-web-performance':
      case 'slow web performance':
        return 'hourglass-start';
      case 'swatch-or-texture':
      case 'swatch or texture':
        return 'droplet-alt';
      case 'model-wearing-or-using':
      case 'model wearing or using':
        return 'user-heart-alt-1';
      case 'model-holding-product':
      case 'model holding product':
        return 'hand-holding';
      default:
        return 'status-dot-small';
    }
  });
};

<script setup>
import { toRefs } from 'vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useUpdateNote } from '@/queries/notes/useUpdateNote';
import NoteComposer from './NoteComposer.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  initialContent: {
    type: String,
    required: true,
  },
  noteId: {
    type: Number,
    required: true,
  },
  subjectType: {
    type: String,
    required: true,
  },
  subjectId: {
    type: [String, Number],
    required: true,
  },
});

const emit = defineEmits(['close']);

const { noteId, subjectType, subjectId } = toRefs(props);

const {
  mutate: updateNote,
  isPending: isUpdatingNote,
  error: updateNoteError,
} = useUpdateNote(subjectType, subjectId);

const priorityErrors = usePriorityErrors(updateNoteError);

function submitNote(e) {
  const form = e.target;
  const data = new FormData(form);
  const note = data.get('note')?.trim();

  if (!note) {
    return;
  }

  updateNote(
    {
      noteId: noteId.value,
      content: note,
    },
    {
      onSuccess: () => {
        form?.reset();
        emit('close');
      },
    }
  );
}
</script>

<template>
  <NoteComposer
    :initial-content="initialContent"
    :is-loading="isUpdatingNote"
    :priority-errors="priorityErrors"
    :auto-focus="true"
    placeholder="edit note"
    @submit="submitNote"
  >
    <template #actions="{ isLoading, submitDisabled }">
      <SoonaButton
        type="button"
        variation="tertiary"
        size="medium"
        :disabled="isLoading"
        @click="$emit('close')"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        type="submit"
        variation="primary"
        size="medium"
        :loading="isLoading"
        :disabled="submitDisabled"
      >
        save
      </SoonaButton>
    </template>
  </NoteComposer>
</template>

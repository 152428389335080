import { computed, ref, toValue, watch } from 'vue';
import { useMediaQuery } from '@vueuse/core';

export function useSideNavState(routeLayoutOptions) {
  const isDesktop = useMediaQuery('screen and (min-width: 64rem)');
  const isDesktopSidebarCollapsed = computed(
    () => isDesktop.value && toValue(routeLayoutOptions)?.sidebarCollapsed
  );

  const isOpen = ref(isDesktop.value && !isDesktopSidebarCollapsed.value);

  watch(
    [isDesktop, isDesktopSidebarCollapsed],
    ([newIsDesktop, newIsDesktopSidebarCollapsed]) => {
      if (isOpen.value && (!newIsDesktop || newIsDesktopSidebarCollapsed)) {
        isOpen.value = false;
      } else if (
        !isOpen.value &&
        newIsDesktop &&
        !newIsDesktopSidebarCollapsed
      ) {
        isOpen.value = true;
      }
    },
    {
      immediate: true,
    }
  );

  const isSidebarFloatOpen = computed(() => !isDesktop.value && isOpen.value);
  const isSidebarPushOpen = computed(() => isDesktop.value && isOpen.value);

  function setSidebarOpen(callback) {
    isOpen.value = callback(isOpen.value);
  }

  return {
    isSidebarFloatOpen,
    isSidebarPushOpen,
    setSidebarOpen,
  };
}

import { QueryClient } from '@tanstack/vue-query';

/*
 * valid errors from a request do not need to be retried and should be shown to the user immediately
 * 401: Unauthorized
 * 403: Forbidden
 * 404: Not Found
 */
const skipRetriesStatuses = [401, 403, 404];

// this should only be imported as a last resort.
// use `useQueryClient` in your component
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: (failureCount, error) => {
        // Show auth error immediately in UI
        if (skipRetriesStatuses.includes(error?.request?.status)) return false;
        return failureCount < 2;
      },
    },
  },
});

<script setup>
import DownloadDigitalAssetMenuItem from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAssetMenuItem.vue';
import { computed, toRefs } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const props = defineProps({
  clickCapture: {
    type: Function,
    required: true,
  },
  keydown: {
    type: Function,
    required: true,
  },
  mouseover: {
    type: Function,
    required: true,
  },
});

const emits = defineEmits(['click']);

const { clickCapture, keydown, mouseover } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId } = storeToRefs(mediaEditorStore);

const { mediaWidth, mediaHeight, previewUrl } = useMediaEditorDigitalAsset(
  assetAccountId,
  assetId
);

const subtitle = computed(() => {
  if (mediaWidth.value && mediaHeight.value) {
    return `${mediaWidth.value} x ${mediaHeight.value}`;
  }
  return '';
});
</script>

<template>
  <DownloadDigitalAssetMenuItem
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="low res preview"
    subtitle="with soona watermark"
    @click="emits('click', { url: previewUrl, label: 'low res preview' })"
  >
    <template #right-col>
      <span class="u-label--regular free">free</span>
    </template>
  </DownloadDigitalAssetMenuItem>
  <DownloadDigitalAssetMenuItem
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="full resolution"
    :subtitle="subtitle"
    pizzazz-border
    @click="() => mediaEditorStore.setShowPaywallDialog(true)"
  >
    <template #right-col>
      <SoonaFlag background-color="transparent" type="pill" is-pizzazz>
        <template #icon-left>
          <SoonaIcon name="crown" size="small" class="crown" />
        </template>
      </SoonaFlag>
    </template>
  </DownloadDigitalAssetMenuItem>
</template>

<style scoped lang="scss">
@use '@/variables';

.free {
  text-transform: uppercase;
  color: variables.$gray-60;
}

.crown {
  color: variables.$white-default;
}
</style>

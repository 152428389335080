import { http } from '../config/vue-axios';

/**
 * @param {int} accountId
 * @param {AbortSignal} [signal]
 */
export async function getExternalIdentifierTypes(accountId, signal) {
  const response = await http.get(
    `/accounts/${accountId}/external_identifier_types.json`,
    {
      signal,
    }
  );
  return response.data;
}

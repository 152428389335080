<script setup>
import { computed, provide, ref, onUnmounted } from 'vue';
import MediaEditor from '@/components/user/anytime/gallery/media-editor/MediaEditor.vue';
import { useDigitalAsset } from '@/queries/digital_assets/useDigitalAsset';

import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
  MEDIA_POPOVER_BACK_TEXT,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useRoute, useRouter } from 'vue-router';
import { usePreloadDigitalAsset } from '@/queries/digital_assets/usePreloadDigitalAsset';
import { useMediaQuery } from '@vueuse/core';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import AccountGalleryAssetCarousel from './AccountGalleryAssetCarousel.vue';
import { useMediaEditorStore } from '../user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [Number, String],
    required: true,
  },
});
const route = useRoute();
const router = useRouter();
const accountId = computed(() => props.accountId);
const digitalAssetId = computed(() => props.digitalAssetId);

const params = computed(() => ({
  visibility: 'all',
  collection_id: route.query.collection_id,
  origin: route.query.origin,
  ownership: route.query.ownership,
  media_type: route.query.media_type,
  order_by: route.query.order_by,
  direction: route.query.direction,
}));

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const {
  data: file,
  isLoading: isFileLoading,
  error: fileLoadingError,
} = useDigitalAsset({ accountId, assetId: digitalAssetId, params: params }, {});

// routes and preloading
const mediaEditorStore = useMediaEditorStore();
const {
  carouselDigitalAssetIds,
  carouselPreviousPageLastAssetId,
  carouselNextPageFirstAssetId,
} = storeToRefs(mediaEditorStore);
const indexOfCurrentAsset = computed(() =>
  carouselDigitalAssetIds.value?.findIndex(
    assetId => assetId == digitalAssetId.value
  )
);
const nextId = computed(
  () =>
    carouselDigitalAssetIds.value[indexOfCurrentAsset.value + 1] ||
    carouselNextPageFirstAssetId.value
);
const prevId = computed(
  () =>
    carouselDigitalAssetIds.value[indexOfCurrentAsset.value - 1] ||
    carouselPreviousPageLastAssetId.value
);

const accountName = computed(() => file.value?.account_name);

usePreloadDigitalAsset(accountId, nextId, params);
usePreloadDigitalAsset(accountId, prevId, params);

const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'account-gallery-media-view',
    params: {
      accountId: accountId.value,
      digitalAssetId: id,
    },
    query: route.query,
  };
};

const prevRoute = computed(() => createRouteLocation(prevId.value));
const nextRoute = computed(() => createRouteLocation(nextId.value));

const urlParamsString = computed(() => {
  if (Object.keys(route.query).length === 0) return '';

  const urlSearchParams = new URLSearchParams(route.query);

  return '?' + urlSearchParams.toString();
});

const backUrl = computed(() => {
  return `/account/${accountId.value}/gallery` + urlParamsString.value;
});

const backText = computed(() => {
  if (accountName.value) return `${accountName.value}’s gallery`;
  return 'gallery';
});

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, prevRoute);
provide(MEDIA_POPOVER_NEXT_KEY, nextRoute);
provide(MEDIA_POPOVER_BACK_TEXT, backText);

const isRedirecting = ref(false);
const handleClose = event => {
  if (event?.delivered_asset_id) {
    router.push(createRouteLocation(event.delivered_asset_id));
  } else {
    isRedirecting.value = true;
    router.push({ path: backUrl.value, query: route.query });
  }
};

const priorityError = usePriorityError(fileLoadingError);

onUnmounted(() => {
  mediaEditorStore.$reset();
});
</script>

<template>
  <MediaEditor
    v-if="!isRedirecting"
    :asset="file"
    :is-asset-loading="isFileLoading"
    @close="handleClose"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #carousel>
      <AccountGalleryAssetCarousel
        v-if="matchMediaIsWide"
        :account-id="accountId"
        :active-asset-id="file?.id"
        :back-text="backText"
        :filters="params"
      />
    </template>
  </MediaEditor>
</template>

import * as types from 'src/store/mutation-types';
const state = {
  locations: [],
  currentLocation: {},
  currentLocationProducts: [],
  currentLocationSpaces: [],
  locationLoaded: false,
  supportedLocations: [],
  crew: [],
};

const getters = {
  locations(state) {
    return state.locations;
  },
};

const mutations = {
  [types.SET_LOCATIONS](state, locations) {
    const assignedLocations = locations.filter(
      f => f.location_type !== 'unassigned'
    );
    state.locations = assignedLocations;
  },
  [types.SET_CURRENT_LOCATION](state, location) {
    state.currentLocation = location;
  },
  [types.SET_CURRENT_LOCATION_PRODUCTS_AND_SPACES](
    state,
    { location, products, spaces }
  ) {
    state.currentLocation = location;
    state.currentLocationProducts = products;
    state.currentLocationSpaces = spaces;
  },
  [types.SET_LOCATION_LOADED](state, val) {
    state.locationLoaded = val;
  },
  [types.SET_SUPPORTED_LOCATIONS](state, supportedLocations) {
    state.supportedLocations = supportedLocations;
  },
  [types.SET_CURRENT_LOCATION_CREW](state, currentLocationCrew) {
    state.crew = currentLocationCrew;
  },
};

const actions = {
  loadLocationEmployees({ commit }, { id, start, end }) {
    return new Promise(resolve => {
      var crew;
      Promise.all([
        new Promise(resolve =>
          this.http
            .get(`locations/${id}/crew.json?start=${start}&end=${end}`)
            .then(response => {
              crew = response.data;
              resolve();
            })
        ),
      ]).then(() => {
        commit(types.SET_CURRENT_LOCATION_CREW, crew);
        resolve();
      });
    });
  },
  loadLocationByName({ state, commit }, { name }) {
    commit(types.SET_LOCATION_LOADED, false);
    return new Promise(resolve => {
      var location = state.locations.filter(
        location => location.name.toLowerCase() == name.toLowerCase()
      )[0];
      var products, spaces;
      Promise.all([
        new Promise(resolve =>
          this.http
            .get(`locations/${location.id}/bookable_spaces.json`)
            .then(response => {
              spaces = response.data;
              resolve();
            })
        ),
        new Promise(resolve =>
          this.http
            .get(`products/search?location_id=${location.id}`)
            .then(response => {
              products = response.data;
              resolve();
            })
        ),
      ]).then(() => {
        commit(types.SET_CURRENT_LOCATION_PRODUCTS_AND_SPACES, {
          location,
          products,
          spaces,
        });
        commit(types.SET_PRODUCTS, products, { root: true });
        commit(types.SET_LOCATION_LOADED, true);
        resolve();
      });
    });
  },
  loadLocations({ commit }, locationTypes = []) {
    let params = { location_types: locationTypes };

    return new Promise(resolve => {
      this.http.get('locations.json', { params: params }).then(response => {
        commit(types.SET_LOCATIONS, response.data);
        resolve();
      });
    });
  },
  loadSupportedLocations({ commit }, reservationTags) {
    let reservationTagIds = reservationTags.map(t => t.id);
    let params = { reservation_tag_ids: reservationTagIds };

    return this.http
      .get('locations/supported_locations.json', { params: params })
      .then(response => {
        commit(types.SET_SUPPORTED_LOCATIONS, response.data);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import SchedulingPreferences from '@/components/user/anytime/customer_shoot_readiness/scheduling_preferences/SchedulingPreferences.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  reservationId: {
    type: Number,
    required: true,
  },
  slug: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close-modal', 'submit']);

const iconNames = {
  'scheduling-preferences': 'calendar-day',
};
const iconName = computed(() => iconNames[props.slug]);

const formId = {
  'scheduling-preferences': 'scheduling-preferences-form',
};

const accountSchedulingPreferencesFlag = useFlag(
  'toaster_account_scheduling_preferences'
);

const setAccountPreference = ref(false);

watchEffect(() => {
  if (accountSchedulingPreferencesFlag.value) {
    setAccountPreference.value = true;
  }
});
</script>

<template>
  <SoonaDialog @close="() => emit('close-modal')">
    <template #heading>
      <div class="csr-checklist-dialog__heading">
        <div
          class="csr-checklist-dialog__icon"
          :class="`csr-checklist-dialog__icon--${iconName}`"
        >
          <SoonaIcon :name="iconName" />
        </div>
        <h3 class="u-title--heavy">
          {{ title }}
        </h3>
      </div>
    </template>
    <template v-if="slug === 'scheduling-preferences'">
      <div class="scheduling-preferences__sub-heading">
        <p>we will do our best to accommodate your preferences.</p>
        <strong>
          we will not be able to schedule your shoot until we have received your
          shipments.
        </strong>
      </div>

      <hr />
      <SchedulingPreferences
        class="availability__preferences--form"
        :edit-mode="true"
        :form-id="formId[slug]"
        :account-id="accountId"
        :reservation-id="reservationId"
        :set-account-preference="setAccountPreference"
        :is-dialog="true"
        @close="emit('close-modal')"
      />
    </template>
    <template #footer="{ close }">
      <div
        v-if="
          slug === 'scheduling-preferences' && accountSchedulingPreferencesFlag
        "
        class="scheduling-preferences__account-preferences"
      >
        <SoonaToggle
          v-model="setAccountPreference"
          type="checkbox"
          label="apply to all future bookings"
          name="set-account-preference"
        />
        <p class="scheduling-preferences__account-preferences--subtext">
          you can update anytime
        </p>
      </div>
      <SoonaButton
        type="button"
        variation="tertiary"
        size="medium"
        @click="close"
      >
        cancel
      </SoonaButton>
      <SoonaButton type="submit" :form="formId[slug]" variation="primary">
        save
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.csr-checklist-dialog {
  &__heading {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.75rem;
    width: 2.75rem;
    border-radius: 50%;
    padding: 0.625rem;

    &--calendar-day {
      color: variables.$green-apple-40;
      background-color: variables.$green-apple-10;
    }
  }
}

.scheduling-preferences {
  &__sub-heading {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__account-preferences {
    align-items: center;
    margin-right: auto;

    &--subtext {
      @include variables_fonts.u-label--regular;

      color: variables.$gray-60;
      margin-left: 1.5rem;
    }
  }
}
</style>

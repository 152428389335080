<script setup>
import { useSoonaIconData } from '@/components/ui_library/soona_icon/useSoonaIconData';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed } from 'vue';
import { useCopy } from '@/composables/useCopy';

// head over to /soona-icon in dev mode to see!
const soonaIconData = useSoonaIconData();

const icons = computed(() => Object.keys(soonaIconData.value ?? []).sort());
const count = computed(() => icons.value.length.toLocaleString());

const { copyText, copySuccess, copiedText } = useCopy();
</script>

<template>
  <div class="u-container soona-icon-test-page">
    <h1 class="u-headline--heavy">SoonaIcon test page</h1>

    <p>there are {{ count }} icons. click the icon name to copy it.</p>

    <ul>
      <li v-for="icon in icons" :key="icon">
        <SoonaIcon :name="icon" class="black" />
        <SoonaIcon :name="icon" class="gray-dark" />
        <SoonaIcon :name="icon" class="friendly-red" />
        <SoonaIcon :name="icon" class="periwink" />
        <SoonaIcon :name="icon" class="white-on-black" />
        <SoonaIcon :name="icon" class="white-on-periwink" />
        <SoonaIcon :name="icon" class="white-on-friendly-red" />
        <button
          class="copy-button"
          title="copy to clipboard"
          @click="copyText(icon)"
        >
          {{ icon }}
        </button>
        <span v-if="copySuccess && copiedText === icon" class="copied">
          copied 🎉
        </span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-icon-test-page {
  color: variables.$black-default;

  h1,
  p {
    margin-bottom: 1.5rem;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    margin-bottom: 0.25rem;

    svg {
      flex: 0 0 24px;
    }
  }

  .black {
    color: variables.$black-default;
    background-color: variables.$white-default;
  }

  .gray-dark {
    color: variables.$gray-60;
    background-color: variables.$white-default;
  }

  .friendly-red {
    color: variables.$friendly-red-50;
    background-color: variables.$white-default;
  }

  .periwink {
    color: variables.$periwink-blue-40;
    background-color: variables.$white-default;
  }

  .white-on-black {
    color: variables.$white-default;
    background-color: variables.$black-default;
  }

  .white-on-periwink {
    color: variables.$white-default;
    background-color: variables.$periwink-blue-40;
  }

  .white-on-friendly-red {
    color: variables.$white-default;
    background-color: variables.$friendly-red-50;
  }

  .copy-button {
    font-family: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo,
      Consolas, 'DejaVu Sans Mono', monospace;
    background: variables.$gray-10;
    color: variables.$black-default;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;

    &:hover {
      background: variables.$gray-20;
    }

    &:active {
      background: variables.$gray-30;
    }
  }

  .copied {
    @include variables_fonts.u-label--heavy;
    color: variables.$avo-toast-40;
  }
}
</style>

import { http } from '../config/vue-axios';

/**
 *
 * @param {number | string} accountId
 * @param {Object} params
 * @param {string | number} [params.catalogItemId]
 * @param {AbortSignal} [signal]
 * @returns {Promise<any>}
 */
export async function getAccountInventoryItems(
  accountId,
  { catalogItemId },
  signal
) {
  let params = {};
  if (catalogItemId) params['catalog_item_id'] = catalogItemId;
  const response = await http.get(`/accounts/${accountId}/inventory_items`, {
    params,
    signal,
  });

  return response.data;
}

/**
 *
 * @param {number | string} accountId
 * @param {AbortSignal} [signal]
 * @returns {Promise<any>}
 */
export async function getUnidentifiedAccountInventoryItemsCount(
  accountId,
  signal
) {
  const response = await http.get(
    `/accounts/${accountId}/inventory_items/unidentified_count`,
    {
      signal,
    }
  );

  return response.data;
}

/**
 *
 * @param {string | number} itemId
 * @param {Object} body
 * @param {Object} body.inventoryItemDetails
 * @returns
 */
export async function updateInventoryItem(itemId, { inventoryItemDetails }) {
  return await http.patch(`/inventory_items/${itemId}`, {
    inventory_item: inventoryItemDetails,
  });
}

import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import { AI_ASSISTANT_STORE_ID } from '@/stores/keys';

export const useAssistantStore = defineStore(AI_ASSISTANT_STORE_ID, () => {
  const messages = ref([]);
  const userInput = ref('');
  const threadId = ref(null);
  const isWaiting = ref(false);
  const eventSource = ref(null);
  const suspendedToolOutputs = ref([]);
  const scrollContainerRef = ref(null);
  const isWaitingForInitialResponse = ref(false);

  const hasMessages = computed(() => !!messages.value.length);

  function appendMessage(message) {
    messages.value = [...messages.value, message];
  }

  function appendToLastMessage(text) {
    const oldMessages = messages.value;
    const lastMessage = oldMessages[oldMessages.length - 1];
    const updatedLastMessage = {
      ...lastMessage,
      text: lastMessage.text + text,
    };
    messages.value = [...oldMessages.slice(0, -1), updatedLastMessage];
  }

  function handleTextDelta(delta) {
    if (delta != null) {
      appendToLastMessage(delta);
    }
  }

  const handleToolCallDelta = delta => {
    if (delta.type != 'code_interpreter') return;
    if (!delta.code_interpreter.input) return;
    appendToLastMessage(delta.code_interpreter.input);
  };

  const handleToolCallCreated = toolCall => {
    if (toolCall.type != 'code_interpreter') return;
    appendMessage({ role: 'code', text: '' });
  };

  const scrollToBottom = () => {
    if (!scrollContainerRef.value) return;
    scrollContainerRef.value.scrollTop = scrollContainerRef.value.scrollHeight;
  };

  function $reset() {
    messages.value = [];
    userInput.value = '';
    threadId.value = null;
    isWaiting.value = false;
    eventSource.value = null;
    scrollContainerRef.value = null;
    isWaitingForInitialResponse.value = false;
  }

  return {
    userInput,
    setUserInput: input => (userInput.value = input),

    eventSource,
    setEventSource: source => (eventSource.value = source),

    threadId,
    setThreadId: id => (threadId.value = id),

    messages,
    hasMessages,
    appendMessage,
    appendToLastMessage,

    isWaiting,
    setIsWaiting: waiting => (isWaiting.value = waiting),

    isWaitingForInitialResponse,
    setIsWaitingForInitialResponse: waiting =>
      (isWaitingForInitialResponse.value = waiting),

    suspendedToolOutputs,
    setSuspendedToolOutputs: outputs => (suspendedToolOutputs.value = outputs),

    scrollContainerRef,
    scrollToBottom,

    $reset,
    handleTextDelta,
    handleToolCallDelta,
    handleToolCallCreated,
  };
});

<script setup>
import { computed, toRef } from 'vue';
import { useCatalogItem } from '@/queries/useCatalog';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useProductIntegrationDetails } from '../useProductIntegrationDetails';

const props = defineProps({
  accountId: { type: String, required: true },
  catalogItem: { type: Object, required: true },
});

const {
  data: parentProductData,
  isSuccess: isParentSuccess,
  isLoading: isParentLoading,
  error,
} = useCatalogItem(
  toRef(() => props.accountId),
  toRef(() => props.catalogItem.parent_id)
);

const parentProduct = computed(() => parentProductData.value ?? { name: '' });

const { isAmazonProduct, isShopifyProduct } = useProductIntegrationDetails(
  toRef(() => props.catalogItem)
);
</script>

<template>
  <div v-if="isParentSuccess && !isParentLoading" class="parent-product">
    <h2 class="parent-product__heading u-title--heavy">parent product</h2>
    <div class="parent-product__content">
      <SoonaItemCard
        :aria-labelledby="`parent-product-${parentProduct.id}-heading`"
        :image-url="parentProduct.preview_url ?? PlaceholderImage"
        class="parent-product-card"
      >
        <template #image-badge>
          <SoonaIcon
            v-if="isAmazonProduct"
            name="amazon-logo"
            class="parent-product-card__badge"
            size="large"
          />
          <SoonaIcon
            v-if="isShopifyProduct"
            name="shopify-logo"
            class="parent-product-card__badge"
            size="large"
          />
        </template>
        <template #content>
          <div class="parent-product-card__content">
            <router-link
              :to="{
                path: `/account/${accountId}/products/${parentProduct.id}`,
              }"
              class="parent-product-card__name u-body--heavy"
            >
              {{ parentProduct.name }}
            </router-link>
            <div class="parent-product-card__variant-count">
              <SoonaIcon name="diagram-subtask" size="small" />
              <span
                class="parent-product-card__variant-count-text u-badge--small"
              >
                {{ parentProduct.variant_count }} variants
              </span>
            </div>
          </div>
        </template>
      </SoonaItemCard>
    </div>
  </div>
  <SoonaError v-if="error">{{ error.message }}</SoonaError>
</template>

<style lang="scss" scoped>
@use '@/variables';
.parent-product {
  &__heading {
    margin-bottom: 0.75rem;
  }
}

.parent-product-card {
  position: relative;
  width: 100%;

  &:hover {
    background-color: variables.$gray-10;
  }

  &__badge {
    background: variables.$white-default;
    border-radius: 50%;
    padding: 0.125rem;
  }

  &__content {
    padding: 0.75rem 1rem;
  }

  &__name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  }

  &__variant-count {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-top: 0.5rem;
  }
}
</style>

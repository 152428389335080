<script setup>
import { computed } from 'vue';

const props = defineProps({
  isSelected: {
    default: true,
    type: Boolean,
  },
  optionOne: {
    default: 'yes',
    type: String,
  },
  optionTwo: {
    default: 'no',
    type: String,
  },
});

const emits = defineEmits(['change']);

const selectedValue = computed({
  get() {
    return props.isSelected;
  },
  set(value) {
    emits('change', value);
  },
});
</script>

<template>
  <div class="soona-toggle-with-text" data-cypress="div-soona-toggle-with-text">
    <label class="switch">
      <input v-model="selectedValue" type="checkbox" />
      <span class="slider round" :class="{ inactive: !selectedValue }">
        <span class="option-one">{{ optionOne }}</span>
        <span class="option-two">{{ optionTwo }}</span>
      </span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.switch {
  position: relative;
  display: inline-block;
  width: 5.625rem;
  height: 2.125rem;
}

.switch input {
  display: none;
}

.slider.inactive {
  background-color: variables.$periwink-blue-30;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: variables.$periwink-blue-60;
  transition: 0.4s;
  border-radius: 2.125rem;
}

.slider::before {
  position: absolute;
  content: '';
  height: 1.625rem;
  width: 2.625rem;
  left: 0.25rem;
  bottom: 0.25rem;
  background-color: variables.$white-default;
  transition: 0.4s;
  border-radius: 1.375rem;
}

.option-two,
.option-one {
  font-size: 0.875rem;
  position: absolute;
  top: 0.4375rem;
  transition: color 0.4s;
  line-height: 1.125rem;
  font-weight: bold;
}

.option-two {
  left: 0.625rem;
  color: variables.$periwink-blue-80;
  padding-left: 0.4375rem;
}

.option-one {
  right: 0.625rem;
  color: variables.$periwink-blue-60;
  padding-right: 0.1875rem;
}

input:checked + .slider::before {
  transform: translateX(2.875rem);
}

input:checked + .slider .option-two {
  color: variables.$white-default;
}

input:checked + .slider .option-one {
  color: variables.$periwink-blue-60;
}

input:checked + .slider::before {
  transform: translateX(2.5rem);
}
</style>

<script setup>
import { computed, ref, useId, toRefs } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'search content',
  },
});

const { placeholder } = toRefs(props);

const searchText = defineModel({
  type: [Number, String],
  default: '',
});

const isFocused = ref(false);
const placeholderText = computed(() =>
  isFocused.value ? 'what would you like to see?' : placeholder.value
);

const id = useId();
</script>

<template>
  <div class="soona-search-simple">
    <SoonaIcon
      name="search"
      size="medium"
      class="soona-search-simple__input--icon"
    />
    <label :for="id" class="u-visually-hidden">search</label>
    <input
      :id="id"
      v-model="searchText"
      :disabled="disabled"
      type="search"
      class="soona-search-simple__input"
      :placeholder="placeholderText"
      @blur="isFocused = false"
    />
    <SoonaButton
      v-if="searchText"
      size="medium"
      variation="icon-plain-gray"
      class="soona-search-simple__input--icon soona-search-simple__input--icon--close"
      @click="searchText = ''"
    >
      <SoonaIcon name="xmark" />
      <span class="u-visually-hidden">clear search</span>
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-search-simple {
  max-width: 36rem;
  position: relative;

  input[type='search']::-webkit-search-decoration {
    display: none;
  }
  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
  }

  &__input {
    @include variables_fonts.u-body--regular;

    width: 100%;
    height: 3rem;
    border: unset;
    border-radius: 2.5rem;
    padding: 0.875rem 3rem;
    color: variables.$gray-60;
    background: variables.$periwink-blue-10;
    transition:
      box-shadow 0.075s ease-in-out,
      border-color 0.075s ease-in-out,
      color 0.075s ease-in-out;

    &::placeholder {
      transition: color 0.075s ease-in-out;
    }

    &--icon {
      top: 50%;
      left: 1.0625rem;
      position: absolute;
      margin-top: 0.0625rem;
      color: variables.$gray-70;
      transform: translateY(-50%);
      transition: color 0.075s ease-in-out;
      &--close {
        right: 0.5rem;
        left: auto;
      }
    }

    &:not(:disabled):hover {
      color: variables.$black-default;
      box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.1);

      &::placeholder {
        color: variables.$black-default;
      }
    }

    &:not(:disabled):active {
      box-shadow: none;
      color: variables.$black-default;
      border-color: variables.$periwink-blue-60;

      &::placeholder {
        color: variables.$black-default;
      }
    }

    &:not(:placeholder-shown) {
      color: variables.$black-default;
    }

    &:disabled {
      cursor: not-allowed;
      background: variables.$gray-20;
    }
  }

  &:has(input:not(:disabled):hover),
  &:has(input:not(:disabled):active) {
    .soona-search-simple__input--icon {
      color: variables.$black-default;
    }
  }

  &:has(input:disabled) {
    cursor: not-allowed;
  }
}
</style>

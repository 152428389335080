import faqNumberOfPhotosBefore from 'images/booking/faq/faq_number_of_photos_before@2x.jpg';
import faqNumberOfPhotosAfter from 'images/booking/faq/faq_number_of_photos_after@2x.jpg';

export const faqData = [
  {
    titleCopy: 'what if I need more than 25 photos?',
    bodyCopy:
      "<p>if you need more than 25 photos, you can make multiple bookings or reach out to our team for a recommendation <a href='https://soona.co/contact-sales' target='_blank' rel='noopener noreferrer'>here</a>. we'll ensure your project is set up for success.</p>",
  },
  {
    titleCopy: 'how does soona’s pricing work?',
    bodyCopy:
      "<p><strong>due before your shoot</strong></p><p>you'll pay your down payment which includes studio fees, models, styling & other upgrades.</p><p><strong>after your shoot buy only the content you love!</strong></p><p>we'll take many angles during your live shoot and you buy the ones you want. photos are $39 each. video clips and GIFs are $93 each.</p>",
  },
  {
    titleCopy: 'what’s included in the $39 photo cost?',
    bodyCopy:
      '<p>every photo you purchase from soona includes a complimentary touch-up of the image. an example of this standard edit is shown below. photos are delivered in 2 formats: a full-resolution JPEG and a web-ready JPEG.</p>',
    imageList: [
      {
        src: faqNumberOfPhotosBefore,
        caption: 'before standard edits',
        alt: '',
      },
      {
        src: faqNumberOfPhotosAfter,
        caption: 'after standard edits',
        alt: '',
      },
    ],
  },
  {
    titleCopy: 'what if I want more editing than what is complimentary?',
    bodyCopy:
      "<p>we offer these premium edits $9 each. chat with our team of content experts to learn how to get free editing <a href='https://soona.co/contact-sales' target='_blank' rel='noopener noreferrer'>here</a></p>",
    listItems: [
      'change background color or pure white',
      'product touch-up (such as removing barcodes or scratches)',
      'transparent background PNG export',
      'buy the RAW uncompressed file',
      'smooth skin',
      'create a pattern using your product',
      'Photoshop it',
    ],
  },
];

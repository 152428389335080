import { unref } from 'vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import {
  getCatalogItems,
  getCatalogItem,
  getCatalogItemVariants,
  createCatalogItem,
  getCatalogItemsAmazonListing,
  getCatalogItemsShopifyProducts,
  updateCatalogItemsAmazonListing,
  updateCatalogItemShopifyProduct,
} from 'src/api/catalog';
import { queryKeys } from 'src/queries/query-keys';

/**
 *
 * @param {string | number} accountId
 * @param {Object} params
 * @param {string | null} [params.query]
 * @param {string | null} [params.filter]
 * @param {string} [params.orderBy]
 * @param {string} [params.direction]
 * @param {boolean} [params.includeVariants]
 * @param {boolean} [params.showBookingCount]
 * @param {boolean} [params.showInventoryCount]
 * @param {boolean} [params.showVariantCount]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {number} [params.attachedToReservationId]
 * @param {UseQueryOptions} queryOptions
 */
export function useCatalog(
  accountId,
  {
    query,
    filter,
    status,
    orderBy,
    direction,
    includeVariants,
    showBookingCount,
    showInventoryCount,
    showVariantCount,
    itemsPerPage,
    currentPage,
    attachedToDigitalAssetId,
    attachedToReservationId,
  } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.catalog(accountId, {
      query,
      filter,
      status,
      orderBy,
      direction,
      includeVariants,
      showBookingCount,
      showInventoryCount,
      showVariantCount,
      itemsPerPage,
      currentPage,
      attachedToReservationId,
    }),
    queryFn: ({ signal }) =>
      getCatalogItems(
        unref(accountId),
        {
          query: unref(query),
          filter: unref(filter),
          status: unref(status),
          orderBy: unref(orderBy),
          direction: unref(direction),
          includeVariants: unref(includeVariants),
          showBookingCount: unref(showBookingCount),
          showInventoryCount: unref(showInventoryCount),
          showVariantCount: unref(showVariantCount),
          itemsPerPage: unref(itemsPerPage),
          currentPage: unref(currentPage),
          attachedToDigitalAssetId: unref(attachedToDigitalAssetId),
          attachedToReservationId: unref(attachedToReservationId),
        },
        signal
      ),
    ...queryOptions,
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 */
export function useCatalogItem(accountId, itemId, queryOptions = null) {
  return useQuery({
    queryKey: queryKeys.catalogItem(accountId, itemId),
    queryFn: ({ signal }) =>
      getCatalogItem(unref(accountId), unref(itemId), signal),
    ...queryOptions,
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {Object} params
 * @param {string | null} [params.query]
 * @param {string | null} [params.filter]
 * @param {string} [params.orderBy]
 * @param {string} [params.direction]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {number} [params.attachedToReservationId]
 * @param {UseQueryOptions} queryOptions
 */
export function useCatalogItemVariants(
  accountId,
  catalogItemId,
  {
    query,
    filter,
    status,
    orderBy,
    direction,
    itemsPerPage,
    currentPage,
    attachedToReservationId,
  } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.catalogItemVariants(accountId, catalogItemId, {
      query,
      filter,
      status,
      orderBy,
      direction,
      itemsPerPage,
      currentPage,
      attachedToReservationId,
    }),
    queryFn: ({ signal }) =>
      getCatalogItemVariants(
        unref(accountId),
        unref(catalogItemId),
        {
          query: unref(query),
          filter: unref(filter),
          status: unref(status),
          orderBy: unref(orderBy),
          direction: unref(direction),
          itemsPerPage: unref(itemsPerPage),
          currentPage: unref(currentPage),
          attachedToReservationId: unref(attachedToReservationId),
        },
        signal
      ),
    ...queryOptions,
  });
}

export function useCreateProduct(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => createCatalogItem(unref(accountId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.catalog(accountId),
      });
    },
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 */
export function useGetCatalogItemAmazonListing(accountId, itemId) {
  return useQuery({
    queryKey: queryKeys.catalogItemAmazonListing(accountId, itemId),
    queryFn: ({ signal }) =>
      getCatalogItemsAmazonListing(unref(accountId), unref(itemId), signal),
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 */
export function useGetCatalogItemShopifyProducts(accountId, itemId) {
  return useQuery({
    queryKey: queryKeys.catalogItemShopifyProducts(accountId, itemId),
    queryFn: ({ signal }) =>
      getCatalogItemsShopifyProducts(unref(accountId), unref(itemId), signal),
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 */
export function useUpdateCatalogItemAmazonListing(accountId, itemId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body =>
      updateCatalogItemsAmazonListing(unref(accountId), unref(itemId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.catalogItemAmazonListing(accountId, itemId),
      });
    },
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 */
export function useUpdateCatalogItemShopifyProduct(accountId, itemId) {
  return useMutation({
    mutationFn: body =>
      updateCatalogItemShopifyProduct(unref(accountId), unref(itemId), body),
  });
}

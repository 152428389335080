<script setup>
import { computed, ref, watchEffect } from 'vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useRoute } from 'vue-router';
import { useReservation } from 'src/queries/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const props = defineProps({
  isPreview: {
    type: Boolean,
    required: false,
    default: false,
  },
  pack: {
    type: Object,
    required: true,
  },
});

const isEditing = ref(false);
const noteToCrew = ref('');

const route = useRoute();
const reservationId = computed(() => {
  return route.params.reservationId;
});

const packType = computed(() => props.pack.category);

const shotListDescription = computed(() =>
  props.pack.description?.shotlist_header?.length
    ? props.pack.description.shotlist_header[0]
    : ''
);

const { data: reservation, error: reservationLoadingError } = useReservation(
  reservationId.value,
  {
    enabled: computed(() => !props.isPreview),
  }
);

const { mutate: updateReservation, error: updateError } = useUpdateReservation(
  reservationId.value
);

const priorityError = usePriorityError(updateError, reservationLoadingError);

watchEffect(() => {
  if (!props.isPreview) {
    noteToCrew.value = reservation.value?.shot_list_note;
  }
});

const toggleIsEditing = value => {
  isEditing.value = value;
};
const saveNote = () => {
  updateReservation(
    {
      shot_list_note: noteToCrew.value,
    },
    {
      onSuccess: () => {
        toggleIsEditing(false);
      },
    }
  );
};
const cancelNote = () => {
  toggleIsEditing(false);
  noteToCrew.value = reservation.value?.shot_list_note;
};
</script>
<template>
  <div class="pick-a-pack-shotlist-header">
    <p class="pick-a-pack-shotlist-header__title">{{ pack.name }}</p>
    <p>
      {{ shotListDescription }}
    </p>
    <p>
      {{
        `looking for more custom ${
          packType === 'video' ? packType : 'shots'
        }? book another shoot`
      }}
      <a v-if="isPreview" to="/" class="pick-a-pack-shotlist-header__link"
        >here</a
      >
      <router-link
        v-else
        class="pick-a-pack-shotlist-header__link"
        :to="{
          name: 'createReservation',
        }"
        target="_blank"
        rel="noopener noreferrer"
        >here</router-link
      >.
    </p>
    <div class="pick-a-pack-shotlist-header__quantity">
      <span>quantity purchased:</span> {{ isPreview ? '1' : pack.quantity }}
    </div>
    <SoonaTextfield
      v-model="noteToCrew"
      label="note to the crew"
      placeholder="help us prepare! is there anything we should know about your product?"
      type="text"
      name="shotlist-note"
      element="textarea"
      rows="4"
      :maxlength="170"
      :disabled="!isEditing"
    />
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <div class="pick-a-pack-shotlist-header__actions">
      <SoonaButton
        v-if="!isEditing"
        :variation="'secondary-gray'"
        :disabled="isPreview"
        data-cypress="button-edit-shotlist-header"
        @click="toggleIsEditing(true)"
      >
        <SoonaIcon name="pen-square" size="medium" /> edit
      </SoonaButton>
      <SoonaButton
        v-if="isEditing"
        :variation="'secondary-black'"
        @click="cancelNote()"
      >
        cancel
      </SoonaButton>
      <SoonaButton v-if="isEditing" :variation="'primary'" @click="saveNote()">
        save
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';
@use '@/variables';

.pick-a-pack-shotlist-header {
  background: variables.$white-default;
  border-radius: 1.625rem;
  box-shadow: 0 0.1875rem 0.375rem #00000029;
  color: variables.$black-default;
  padding: 2.1875rem;

  > * {
    margin-bottom: 1rem;
  }
  &__title {
    @include variables_fonts.u-headline--heavy;
  }
  &__link {
    text-decoration: underline;
  }
  &__quantity {
    span {
      @include variables_fonts.u-body--heavy;
    }
  }
  :deep(.soona-textfield__label) {
    font-size: 1rem;
  }
  &__actions {
    display: flex;
    gap: 0.25rem;
    justify-content: flex-end;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';

const props = defineProps({
  showPrice: {
    required: false,
    type: Boolean,
    default: true,
  },
});

const showPrice = computed(() => props.showPrice);
</script>

<template>
  <dl class="standard-edit-copy">
    <dt class="standard-edit-copy__product">
      standard edit (included)
      <SoonaHelperText title="standard edit">
        the standard edit includes light color correction and polish of photos +
        trimming videos to make them ready to post.
      </SoonaHelperText>
    </dt>
    <dd
      v-if="showPrice"
      class="standard-edit-copy__price"
      aria-title="standard edit price"
    >
      $0
    </dd>
  </dl>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.standard-edit-copy {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  justify-content: space-between;

  &__product {
    @include variables_fonts.u-label--regular;

    display: flex;
    gap: 0.5rem;
    line-height: unset;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-body--regular;
    }
  }

  &__price {
    @include variables_fonts.u-body--heavy;
  }
}
</style>

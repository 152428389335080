<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  additionalParams: {
    type: String,
    default: '',
  },
  showCloseX: {
    type: Boolean,
    default: true,
  },
  vimeoId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);

const additionalParams = computed(() => props.additionalParams);
const showCloseX = computed(() => props.showCloseX);
const vimeoId = computed(() => props.vimeoId);
</script>

<template>
  <div class="vimeo-container">
    <iframe
      :src="`https://player.vimeo.com/video/${vimeoId}?autoplay=1&title=0&byline=0&portrait=0${additionalParams}`"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
    <button
      v-if="showCloseX"
      class="u-button-reset preview-close-btn"
      @click="emit('close')"
    >
      <SoonaIcon name="xmark" />
      <span class="u-visually-hidden">Close Video</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.vimeo-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
  background-color: variables.$black-default;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.preview-close-btn {
  color: variables.$white-default;
  background-color: #303030;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0.5rem;
  right: 0.5rem;
  transition: 0.1s background-color ease-out;
  width: 2.5rem;
  height: 2.5rem;

  &:hover,
  &:focus-visible {
    background-color: variables.$black-default;
  }

  @media (min-width: variables.$screen-sm-min) {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
}
</style>

export default {
  methods: {
    $error(text, options = {}) {
      this.$store.dispatch(
        'flash/addFlashMessage',
        Object.assign({ type: 'error', text: text, timeout: true }, options)
      );
    },

    $warn(text, options = {}) {
      this.$store.dispatch(
        'flash/addFlashMessage',
        Object.assign({ type: 'warning', text: text, timeout: true }, options)
      );
    },

    $info(text, options = {}) {
      this.$store.dispatch(
        'flash/addFlashMessage',
        Object.assign({ type: 'info', text: text, timeout: true }, options)
      );
    },

    $success(text, options = {}) {
      this.$store.dispatch(
        'flash/addFlashMessage',
        Object.assign({ type: 'success', text: text, timeout: true }, options)
      );
    },

    $clearFlash() {
      this.$store.dispatch('flash/clearFlashMessages');
    },
  },
};

import { computed, toValue } from 'vue';
import { useMe } from '@/composables/user/useMe';
import { useCapability } from '@/composables/useCapability';
import { useGetCrewAssignments } from '@/queries/useGetCrewAssignments';
import { useReservation } from '@/queries/useReservation';

/**
 *
 * @param {import('vue').MaybeRefOrGetter<number | string>} reservationId
 * @returns {import('vue').ComputedRef<boolean>}
 */
export function useReservationAuthorizedToViewClientDetails(reservationId) {
  const reservationIdRef = computed(() => toValue(reservationId));

  const { data: reservation } = useReservation(reservationId);

  const reservationAccountId = computed(() => reservation.value?.account_id);
  const { currentUserId, currentAccountId } = useMe();

  const { hasCapability: canViewAnyClientDetails } = useCapability({
    capability: 'view_any_client_details',
  });
  const { hasCapability: canViewAssignedClientDetails } = useCapability({
    capability: 'view_assigned_client_details',
  });

  const isOwnReservation = computed(
    () => reservationAccountId.value === currentAccountId.value
  );

  const { data: crewAssignments } = useGetCrewAssignments(
    reservationIdRef,
    {
      without_declined: true,
    },
    {
      // optimize and only enable query when we know we need to check assignments
      enabled: computed(() => !isOwnReservation.value),
    }
  );

  const isAssigned = computed(
    () =>
      crewAssignments.value?.some(
        crew => crew.user_id === currentUserId.value
      ) ?? false
  );

  return computed(() => {
    return (
      isOwnReservation.value ||
      canViewAnyClientDetails.value ||
      (canViewAssignedClientDetails.value && isAssigned.value)
    );
  });
}

<template>
  <div>
    <p v-if="totalPackages > 1" class="soona-title-a1 mb-s">
      <b>Package {{ index + 1 }} of {{ totalPackages }}</b>
    </p>

    <p class="soona-title-a1 mb-s"><b>PACKAGE DESCRIPTION:</b></p>

    <div class="pl-m mb-m">
      <p>{{ inventoryPackage.description }}</p>
    </div>

    <p class="soona-title-a1 mb-s"><b>PACKAGE DETAILS:</b></p>

    <div class="pl-m mb-m">
      <p>
        1 package x {{ inventoryPackage.length }}" x
        {{ inventoryPackage.width }}" x {{ inventoryPackage.height }}" ({{
          inventoryPackage.weight
        }}
        lbs)
      </p>
    </div>

    <p class="soona-title-a1 mb-s"><b>CARRIER:</b></p>

    <div class="pl-m">
      <p>
        <b class="soona-title-a1">
          {{ rate.provider }} {{ rate.service_level }}
        </b>
      </p>
      <p class="is-lowercase">
        {{ overrideDurationTerms(rate.duration_terms) }}
      </p>
      <p>${{ rate.amount }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    rate: Object,
    totalPackages: Number,
    inventoryPackage: Object,
  },
  methods: {
    overrideDurationTerms(terms) {
      if (
        terms ===
        'Delivery within 1, 2, or 3 days based on where your package started and where it’s being sent.'
      ) {
        return 'delivery guaranteed within 3 business days based on your location';
      } else {
        return terms;
      }
    },
  },
};
</script>

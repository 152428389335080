<script setup>
import { computed, toRefs } from 'vue';
import { useReservation } from '@/composables/useReservation';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import ShowAccount from './ShowAccount.vue';
import CreateAccount from './CreateAccount.vue';
import EditAccount from './EditAccount.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const {
  reservation,
  error: reservationError,
  isConcierge,
} = useReservation(reservationId);

const previousReservationName = computed(
  () => reservation.value?.previous?.name
);

const { stepIsIncomplete } = useIncompleteSteps(reservation, slug);

const priorityError = usePriorityError(reservationError, stepIsIncomplete);
</script>

<template>
  <CreateAccount v-if="!reservation.account" :reservation-id="reservationId" />
  <EditAccount
    v-else-if="isConcierge"
    :account-id="reservation.account.id"
    :user-id="reservation.account.owner_user_id"
  />
  <ShowAccount
    v-else
    :account-id="reservation.account.id"
    :previous-reservation-name="previousReservationName"
  />

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  asset: Object,
});

const mediaAddOns = computed(() => {
  return props.asset?.media_add_on_line_items?.filter(a => a.selected) ?? [];
});
</script>

<template>
  <div>
    <h4 class="u-label--heavy">add-ons</h4>
    <ul>
      <li v-for="mediaAddOn in mediaAddOns" :key="mediaAddOn.product.id">
        {{ mediaAddOn.product.name }}
      </li>
    </ul>
  </div>
</template>

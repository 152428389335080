<template>
  <div class="shipping">
    <div class="column is-full soona-container">
      <div class="columns is-centered">
        <div class="column is-three-fifths">
          <h2 class="title has-text-centered is-size-4-mobile">
            let's ship it!
          </h2>

          <!-- soona address -->

          <h3 class="u-body--heavy">SHIP TO:</h3>

          <div class="location-address-container">
            <p class="u-small--regular">soona</p>
            <p class="u-small--regular">
              {{ [location.address1, location.address2].join(' ') }},
              {{ location.city }}, {{ location.state }} {{ location.zip }}
            </p>
          </div>

          <!-- address -->

          <form
            id="shipment-form"
            @submit.prevent="continueClicked"
            @keydown.enter.prevent
          >
            <h3 class="u-body--heavy">FROM:</h3>
            <SoonaTextfield
              v-model:model-value="shipment.address_from.name"
              cypress-name="shipment-name"
              label="name or company"
              placeholder="Company Name"
              required
            />
            <SoonaTextfield
              v-model:model-value="shipment.address_from.street1"
              cypress-name="shipment-address"
              label="street address"
              placeholder="123 Main St."
              required
            />
            <SoonaTextfield
              v-model:model-value="shipment.address_from.street2"
              cypress-name="shipment-suite"
              label="suite or apartment #"
              placeholder="PO Box 456"
            >
              <template #helper>optional</template>
            </SoonaTextfield>
            <SoonaTextfield
              v-model:model-value="shipment.address_from.city"
              cypress-name="shipment-city"
              label="city"
              placeholder="city"
              required
            />

            <div class="shipment__state-zip-wrapper">
              <SoonaTextfield
                v-model:model-value="shipment.address_from.state"
                class="shipment__state-zip-input"
                cypress-name="shipment-state"
                label="state"
                maxlength="2"
                placeholder="state"
                required
              />
              <SoonaTextfield
                v-model:model-value="shipment.address_from.zip"
                class="shipment__state-zip-input"
                cypress-name="shipment-zip"
                label="zip code"
                pattern="[0-9]{5}"
                placeholder="00000"
                required
              />
            </div>

            <SoonaTelephoneField
              v-model="shipment.address_from.phone"
              class="shipment__phone-input"
              label="phone number"
              cypress-name="shipment-phone"
              :required="true"
            />
            <SoonaTextfield
              v-model:model-value="userEmail"
              cypress-name="shipment-email"
              label="email"
              readonly
            />

            <!-- package details -->

            <div class="mt-l">
              <h3 class="soona-title-a1">PACKAGE DETAILS:</h3>
              <p class="shipment__subheading">
                select a pre-defined flat rate option or input custom
                dimensions.
              </p>
              <!-- TODO: marign bottom -->
              <SoonaSelect
                v-if="!freeShipping"
                v-model:model-value="selectedFlatRateOption"
                :options="flatRateOptions"
                :clearable="true"
                class="flat-rate-option-select"
              >
                <template #label>flat rate options</template>
              </SoonaSelect>
            </div>

            <div class="shipment__dimensions-wrapper">
              <SoonaTextfield
                v-if="flatRateTemplate"
                v-model:model-value="flatRateTemplate.dimensions.length"
                class="shipment__dimensions-input"
                cypress-name="shipment-length"
                label="length (inches)"
                disabled
              />
              <SoonaTextfield
                v-else
                v-model:model-value="shipment.parcels.length"
                class="shipment__dimensions-input"
                cypress-name="shipment-length"
                label="length (inches)"
                placeholder="0"
                type="number"
                required
              />
              <SoonaTextfield
                v-if="flatRateTemplate"
                v-model:model-value="flatRateTemplate.dimensions.width"
                class="shipment__dimensions-input"
                cypress-name="shipment-width"
                label="width (inches)"
                disabled
              />
              <SoonaTextfield
                v-else
                v-model:model-value="shipment.parcels.width"
                class="shipment__dimensions-input"
                cypress-name="shipment-width"
                label="width (inches)"
                placeholder="0"
                type="number"
                required
              />
              <SoonaTextfield
                v-if="flatRateTemplate"
                v-model:model-value="flatRateTemplate.dimensions.height"
                class="shipment__dimensions-input"
                cypress-name="shipment-height"
                label="height (inches)"
                disabled
              />
              <SoonaTextfield
                v-else
                v-model:model-value="shipment.parcels.height"
                class="shipment__dimensions-input"
                cypress-name="shipment-height"
                label="height (inches)"
                placeholder="0"
                type="number"
                required
              />
            </div>

            <SoonaTextfield
              v-model:model-value="shipment.parcels.weight"
              cypress-name="shipment-weight"
              label="weight (lbs)"
              placeholder="0"
              max="70"
              type="number"
              required
            >
              <template #helper>max. weight of 70 lbs</template>
            </SoonaTextfield>

            <!-- submit shipment -->
            <div class="shipment__action-btns">
              <SoonaButton :is-loading="isSubmitting" type="submit">
                continue
              </SoonaButton>
              <SoonaButton variation="tertiary" @on-click="onCancel">
                cancel
              </SoonaButton>
            </div>
          </form>

          <!-- invalid address and address verification modals -->
          <SoonaDialog
            v-if="errorMessage.verify == false && showInvalidAddressModal"
            @close="
              () => {
                showInvalidAddressModal = false;
                isSubmitting = false;
              }
            "
          >
            <template #heading>ope! please try again.</template>
            <p class="mt-m">{{ errorMessage.text }}</p>
            <template #footer="{ close }">
              <SoonaButton
                data-cypress="button-dialog-confirm"
                @on-click="close"
              >
                try again
              </SoonaButton>
            </template>
          </SoonaDialog>

          <SoonaDialog
            v-if="errorMessage.verify == true && showVerifyAddressModal"
            @close="
              () => {
                requestShipment();
                showVerifyAddressModal = false;
              }
            "
          >
            <template #heading>did you mean?</template>
            <div class="location-address-container mt-m u-small--regular">
              <p>{{ errorMessage.address.name }}</p>
              <p>
                {{ errorMessage.address.street1 }}
                {{ errorMessage.address.street2 }}
              </p>
              <p>
                {{ errorMessage.address.city }},
                {{ errorMessage.address.state }}
                {{ errorMessage.address.zip }}
              </p>
            </div>

            <template #footer="{ close }">
              <SoonaButton
                variation="tertiary"
                data-cypress="button-dialog-cancel"
                @on-click="close"
              >
                no thanks
              </SoonaButton>
              <SoonaButton
                data-cypress="button-dialog-confirm"
                @on-click="
                  () => {
                    updateAddressFromAndRequestShipment();
                    showVerifyAddressModal = false;
                  }
                "
              >
                yes, please
              </SoonaButton>
            </template>

            <template #confirm-button>yes, please</template>
            <template #cancel-button>no thanks</template>
          </SoonaDialog>
        </div>

        <div
          class="ml-l shipping-directions-container column is-two-fifths has-text-centered"
        >
          <img
            src="@images/anim/shipment.svg"
            alt=""
            class="lottie-container"
          />
          <div class="has-text-centered">
            <p class="u-body--heavy mb-m">
              <span v-if="flatRateTemplate">
                your USPS flat rate box should contain:
              </span>
              <span v-else> please send us: </span>
            </p>
            <p class="u-body--regular">
              items for your shoot! purchase your label and send them in the
              mail.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { mapActions, mapState } from 'vuex';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import { useStore } from 'vuex';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from '../../../ui_library/SoonaSelect.vue';
import SoonaTelephoneField from 'src/components/ui_library/SoonaTelephoneField.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'Shipment',
  components: {
    SoonaButton,
    SoonaDialog,
    SoonaSelect,
    SoonaTelephoneField,
    SoonaTextfield,
  },
  setup() {
    const { buttonClicked, linkClicked, pageViewed } = useBaseEvents();
    const route = useRoute();
    const title = useTitle();

    const store = useStore();
    const userEmail = computed(() => store?.state?.currentUser?.email);

    onMounted(() => {
      title.value = `${route.meta.page_title} | soona`;

      pageViewed();
    });

    return {
      buttonClicked,
      linkClicked,
      userEmail,
    };
  },
  data() {
    return {
      shipment: {
        address_from: {
          name: '',
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: '',
          country: 'US',
          phone: '',
          email: this.userEmail,
        },
        parcels: {
          length: '',
          width: '',
          height: '',
          distance_unit: 'in',
          weight: '',
          mass_unit: 'lb',
        },
      },
      isSubmitting: false,
      validatedAddress: undefined,
      showVerifyAddressModal: false,
      showInvalidAddressModal: false,
      errorMessage: {
        verify: undefined,
        address: {
          name: '',
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: '',
          country: 'US',
        },
        text: '',
      },
      usps_templates: [
        {
          name: 'Flat Rate Padded Envelope',
          token: 'USPS_FlatRatePaddedEnvelope',
          dimensions: {
            length: '12.5',
            width: '9.5',
            height: '1',
          },
        },
        {
          name: 'Small Flat Rate Box',
          token: 'USPS_SmallFlatRateBox',
          dimensions: {
            length: '8.625',
            width: '5.375',
            height: '1.625',
          },
        },
        {
          name: 'Medium Flat Rate Box 1 (top-loading)',
          token: 'USPS_MediumFlatRateBox1',
          dimensions: {
            length: '11',
            width: '8.5',
            height: '5.5',
          },
        },
        {
          name: 'Medium Flat Rate Box 2 (side-loading)',
          token: 'USPS_MediumFlatRateBox2',
          dimensions: {
            length: '13.625',
            width: '11.875',
            height: '3.375',
          },
        },
        {
          name: 'Large Flat Rate Box',
          token: 'USPS_LargeFlatRateBox',
          dimensions: {
            length: '12',
            width: '12',
            height: '5.5',
          },
        },
        {
          name: 'Large Flat Rate Board Game Box',
          token: 'USPS_LargeFlatRateBoardGameBox',
          dimensions: {
            length: '23.6875',
            width: '11.75',
            height: '3',
          },
        },
      ],
      flatRateTemplate: undefined,
      selectedFlatRateOption: undefined,
    };
  },
  computed: {
    ...mapState({
      currentReservation: state => state.reservation.currentReservation,
      location: state => state.reservation.currentReservation.location,
      freeShipping: state => state.reservation.freeShipping,
      account: state => state.account,
    }),
    invalidOrIncompleteValidateAddress() {
      return (
        this.validatedAddress.is_complete == false ||
        this.validatedAddress.validation_results.is_valid == false
      );
    },
    suggestedChangesInValidateAddress() {
      return this.validatedAddress.validation_results.messages.length > 0;
    },
    largeFlatRateBox() {
      return this.usps_templates.find(
        template => template.token === 'USPS_LargeFlatRateBox'
      );
    },
    flatRateOptions() {
      return this.usps_templates.map(template => ({
        value: template.token,
        label: template.name,
      }));
    },
  },
  watch: {
    selectedFlatRateOption(option) {
      const template = this.usps_templates.find(temp => temp.token === option);
      if (template) {
        this.selectedTemplate(template);
      } else {
        this.clearFlatRateTemplate();
      }
    },
  },
  mounted() {
    if (this.freeShipping) {
      this.selectedTemplate(this.largeFlatRateBox);
    }
  },
  methods: {
    ...mapActions('reservation', [
      'createShipment',
      'setFlatRate',
      'validateAddress',
    ]),
    async continueClicked() {
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: 'shipping details',
        buttonLabel: 'continue',
        buttonAction: 'advance shipping flow',
      });

      if (this.checkValidityOfRequiredInputs() && !this.isSubmitting) {
        this.isSubmitting = true;

        let response = await this.validateAddress({
          reservationId: this.currentReservation.id,
          address: this.shipment.address_from,
        });

        this.validatedAddress = response.data;

        if (this.invalidOrIncompleteValidateAddress) {
          this.invalidAddress();
        } else if (this.suggestedChangesInValidateAddress) {
          this.verificationNeeded();
        } else {
          this.requestShipment();
        }
      }
    },
    checkValidityOfRequiredInputs() {
      let validity = document.getElementById('shipment-form');
      return validity.checkValidity();
    },
    invalidAddress() {
      this.showInvalidAddressModal = true;

      // Note that Shippo currently returns only one (1) message on failure
      // even if multiple fields are invalid
      this.errorMessage = {
        verify: false,
        text: this.validatedAddress.validation_results.messages[0].text,
      };

      this.isSubmitting = false;
    },
    verificationNeeded() {
      this.showVerifyAddressModal = true;

      this.errorMessage = {
        verify: true,
        address: {
          name: this.validatedAddress.name,
          street1: this.validatedAddress.street1,
          street2: this.validatedAddress.street2,
          city: this.validatedAddress.city,
          state: this.validatedAddress.state,
          zip: this.validatedAddress.zip,
          country: this.validatedAddress.country,
        },
      };
    },
    updateAddressFromAndRequestShipment() {
      let updatedAddressFrom = {
        name: this.validatedAddress.name,
        street1: this.validatedAddress.street1,
        street2: this.validatedAddress.street2,
        city: this.validatedAddress.city,
        state: this.validatedAddress.state,
        zip: this.validatedAddress.zip,
        country: this.validatedAddress.country,
        phone: this.shipment.address_from.phone,
        email: this.userEmail,
      };

      this.shipment.address_from = updatedAddressFrom;
      this.requestShipment();
    },
    requestShipment() {
      this.createShipment({
        address_to: {
          name: `soona REF( ${this.currentReservation.id} )`,
          street1: this.location.address1,
          street2: this.location.address2,
          city: this.location.city,
          state: this.location.state,
          zip: this.location.zip,
          country: 'US',
        },
        address_from: this.shipment.address_from,
        parcels: this.shipment.parcels,
        packagesRemaining: 0,
      });

      if (this.flatRateTemplate) {
        this.setFlatRate();
      }
    },
    selectedTemplate(template) {
      //this.$track('Shipping Template Selected', {
      //   reservation: this.currentReservation,
      //   shipment_template: template,
      // });
      this.shipment.parcels.length = template.dimensions.length;
      this.shipment.parcels.width = template.dimensions.width;
      this.shipment.parcels.height = template.dimensions.height;
      this.shipment.parcels.template = template.token;

      this.flatRateTemplate = template;
    },
    clearFlatRateTemplate() {
      //this.$track('Shipping Template Cleared', {
      //   reservation: this.currentReservation,
      // });
      this.shipment.parcels.length = '';
      this.shipment.parcels.width = '';
      this.shipment.parcels.height = '';
      this.shipment.parcels.template = '';

      this.flatRateTemplate = undefined;
    },
    onCancel() {
      this.linkClicked({
        context: this.$route.meta.context,
        subContext: 'shipping details',
        linkLabel: 'cancel',
        linkHref: '/user/anytime#/reservation/info',
      });

      this.$router.push({ name: 'info' });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.shipment {
  &__subheading {
    padding-bottom: 0.75rem;
  }

  &__state-zip-wrapper {
    display: flex;
    gap: 1rem;
  }

  &__state-zip-input {
    &:last-child {
      flex-grow: 1;
    }
  }

  &__phone-input {
    margin-bottom: 1.9375rem;
  }

  &__dimensions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1.25rem;

    @media (min-width: variables.$screen-sm-min) {
      flex-direction: row;
    }
  }

  &__dimensions-input {
    @media (min-width: variables.$screen-sm-min) {
      width: 33.3333%;
    }
  }

  &__action-btns {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }
}

.flat-rate-option-select :deep(.select-label-wrapper) {
  color: #4a4a4a; //default body color
  font-weight: 400;
}

.required-usps-fields {
  &-input {
    cursor: not-allowed;
  }
}

.location-address-container {
  margin-bottom: 0.75rem;
}

.shipping-directions-container {
  align-content: center;
  background-color: variables.$tangerine-20;
  display: flex;
  flex-wrap: wrap;
  height: 43.75rem;
  justify-content: center;
  padding: 6%;
  text-align: center;

  img {
    height: 18.75rem;
    width: 12.5rem;
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import BaseProductPickerDialog from '@/components/user/anytime/products/picker/BaseProductPickerDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  existingSelectedItems: {
    type: Array,
    default: () => [],
  },
  heading: {
    type: String,
    required: true,
  },
  attachedToReservationId: {
    type: [Number, String],
    default: null,
  },
  orderBy: {
    type: String,
    default: 'name',
  },
});

const emit = defineEmits(['cancel', 'select']);

function cancel() {
  emit('cancel');
}

const selectedCatalogItems = ref([]);
const selectedCatalogItemIdsString = computed(() =>
  selectedCatalogItems.value.map(ci => ci.id.toString())
);

function multiSelectChange(e, catalogItem) {
  if (e.target.checked) {
    selectedCatalogItems.value = selectedCatalogItems.value.concat([
      catalogItem,
    ]);
  } else {
    selectedCatalogItems.value = selectedCatalogItems.value.filter(
      item => item.id !== catalogItem.id
    );
  }
}

function select() {
  emit('select', selectedCatalogItems.value);
}

function createAndSelect(catalogItemId, catalogItem) {
  selectedCatalogItems.value = selectedCatalogItems.value.concat([catalogItem]);
}
</script>

<template>
  <BaseProductPickerDialog
    :account-id="accountId"
    show-create
    multiselect
    :attached-to-reservation-id="attachedToReservationId"
    :order-by="orderBy"
    @cancel="cancel"
    @select="select"
    @create-and-select="createAndSelect"
  >
    <template #heading>{{ heading }}</template>
    <template v-if="$slots.header" #header>
      <slot name="header" />
    </template>
    <template #product-options="{ catalogItems }">
      <slot
        name="product-options"
        :catalog-items="catalogItems"
        :selected-catalog-item-ids-string="selectedCatalogItemIdsString"
        :multi-select-change="multiSelectChange"
      />
    </template>
    <template #footer="{ close }">
      <div class="product-multi-picker__footer">
        <div class="product-multi-picker__footer--left">
          <span class="u-label--heavy">
            {{ selectedCatalogItems.length }} selected
          </span>
          <SoonaButton
            class="product-multi-picker__footer--clear-button"
            size="medium"
            variation="tertiary"
            @click="selectedCatalogItems = []"
          >
            clear
          </SoonaButton>
        </div>
        <div class="product-multi-picker__footer--actions">
          <SoonaButton
            class="product-multi-picker__footer--cancel-button"
            size="medium"
            variation="tertiary"
            @click="close"
          >
            cancel
          </SoonaButton>
          <SoonaButton
            class="product-multi-picker__footer--select-button"
            size="medium"
            :disabled="selectedCatalogItems.length === 0"
            @click="select"
          >
            select
          </SoonaButton>
        </div>
      </div>
    </template>
  </BaseProductPickerDialog>
</template>

<style lang="scss" scoped>
.product-multi-picker {
  &__footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

    &--left,
    &--actions {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }
}
</style>

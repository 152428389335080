import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { useStore } from 'vuex';

/**
 *
 * @param {string | Ref<string>} [locationId]
 * @param {string | Ref<string>} [shootType]
 * @param {string | Ref<string>} [discountId]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
// todo: merge this with getTagCategoryProducts, keeping separate for now b/c call that uses this requires different data structure
export function useTagCategoriesProducts(
  { locationId, shootType, discountId = null },
  queryOptions
) {
  const store = useStore();

  return useQuery({
    queryKey: queryKeys.tagCategoriesProducts(
      locationId,
      shootType,
      discountId
    ),
    queryFn: async ({ signal }) => {
      const params = {
        location_id: toValue(locationId),
        shoot_type: toValue(shootType),
      };

      if (discountId) {
        params.discount_id = toValue(discountId);
      }

      const response = await http.get('tag_categories_products.json', {
        params,
        signal,
      });

      store.commit(
        'reservation/SET_ADDITIONAL_CHARGE_TAG_CATEGORY_PRODUCTS',
        response.data.products
      );
      return response.data.products;
    },
    ...queryOptions,
  });
}

import * as Sentry from '@sentry/vue';
import * as types from 'src/store/mutation-types';

const state = {
  events: [],
};

const mutations = {
  [types.SET_EVENTS](state, { events }) {
    state.events = events;
  },
};

const actions = {
  createUserEvent(_, { userId, event }) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`events.json`, {
          event: { ...event, subject_id: userId, subject_type: 'User' },
        })
        .then(() => {
          resolve();
        })
        .catch(error => {
          Sentry.captureException('failed to create event', {
            extra: error,
          });
          reject(error);
        });
    });
  },
  updateEvent(_, { eventId, event }) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`events/${eventId}.json`, {
          event: {
            start: event.start,
            end: event.end,
          },
        })
        .then(() => {
          resolve();
        })
        .catch(error => {
          Sentry.captureException('failed to update event', {
            extra: error,
          });
          reject(error);
        });
    });
  },
  deleteEvent(_, { eventId }) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(`events/${eventId}.json`)
        .then(() => {
          resolve();
        })
        .catch(error => {
          Sentry.captureException('failed to delete event', {
            extra: error,
          });
          reject(error);
        });
    });
  },
  loadUnavailabilityEvents({ commit }, params) {
    let queryParams = `start=${params.start}&end=${params.end}&type=${
      params.type
    }&crew_user_ids=${params.crewUserIds}&time_zone=${encodeURIComponent(
      params.timeZone
    )}`;
    return new Promise((resolve, reject) => {
      this.http.get(`events.json?${queryParams}`).then(
        response => {
          commit(types.SET_EVENTS, {
            events: response.data,
          });
          resolve();
        },
        error => {
          console.error(error, 'in error');
          reject(error);
        }
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

<script setup>
import { ref } from 'vue';
import ManageIntegrationsButton from '@/components/user/anytime/products/ManageIntegrationsButton.vue';
import ManageIntegrationsDialog from '@/components/user/anytime/products/ManageIntegrationsDialog.vue';
import RemoveAmazonAccountDialog from '@/components/user/anytime/products/RemoveAmazonAccountDialog.vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const router = useRouter();

// manage integrations module
const displayManageIntegrationsDialog = ref(false);

function toggleIntegrationsDialog() {
  displayManageIntegrationsDialog.value =
    !displayManageIntegrationsDialog.value;
}

const integrationsToDisplay = ['AmazonIntegration', 'ShopifyIntegration'];

const selectedIntegrationId = ref(null);
const displayRemoveAmazonAccountDialog = ref(false);

const displayRemoveAmazonAccountDialogHandler = integrationId => {
  selectedIntegrationId.value = integrationId;
  displayManageIntegrationsDialog.value = false;
  displayRemoveAmazonAccountDialog.value = true;
};

const closeRemoveAmazonAccountDialog = () => {
  displayManageIntegrationsDialog.value = true;
  displayRemoveAmazonAccountDialog.value = false;
};

const handleRemovingIntegration = () => {
  router.push(`/account/${props.accountId}/listing-insights`);
};
</script>

<template>
  <header class="listing-insights-dashboard__header">
    <h1 class="u-headline--heavy">listing insights</h1>
    <ManageIntegrationsButton
      :account-id="accountId"
      variation="secondary-gray"
      @on-click="toggleIntegrationsDialog"
    />
  </header>
  <ManageIntegrationsDialog
    v-if="displayManageIntegrationsDialog"
    :account-id="accountId"
    :integrations-to-display="integrationsToDisplay"
    @close="toggleIntegrationsDialog"
    @open-remove-amazon-data-dialog="displayRemoveAmazonAccountDialogHandler"
  />
  <RemoveAmazonAccountDialog
    v-if="displayRemoveAmazonAccountDialog && selectedIntegrationId"
    :account-id="accountId"
    :integration-id="selectedIntegrationId"
    @close="closeRemoveAmazonAccountDialog"
    @removing-integration="handleRemovingIntegration"
  />
</template>

<style lang="scss" scoped>
.listing-insights-dashboard {
  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
}
</style>

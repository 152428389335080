<script setup>
import { computed, toRef, watchEffect, ref } from 'vue';
import BookingLayout from '../BookingLayout.vue';
import SoonaClickableCard from 'src/components/booking/v3/p2/SoonaClickableCard.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useReservation } from 'src/queries/useReservation';
import { useReservationLocations } from 'src/queries/useReservationLocations';
import { useUpdateReservationLocation } from 'src/queries/useUpdateReservation';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useFlag } from '@/composables/useFlag';

// props and data
const props = defineProps({
  nextButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const dfsH4a = useFlag('dfs_h4a');

// Segment events
const { itemSelected } = useBaseEvents();

// api calls
const {
  data: locations,
  status,
  error: locationsError,
} = useReservationLocations(toRef(props, 'reservationId'), ref(['studio']));

const {
  data: reservation,
  isLoading,
  error,
} = useReservation(toRef(props, 'reservationId'));

const {
  mutate,
  isPending: isMutating,
  error: updateError,
} = useUpdateReservationLocation(toRef(props, 'reservationId'));

// computed
const currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});
const isBusy = computed(() => {
  return isLoading.value || isMutating.value;
});
const isStepValid = computed(() => {
  return !isBusy.value && currentStep.value?.complete;
});
const locationOptions = computed(() => {
  if (dfsH4a.value) {
    return locations.value || [];
  } else {
    return locations.value.filter(l => l.location.slug !== 'dfs-mpls') || [];
  }
});

// methods
const onBackClick = () => props.onBack();
const onNextClick = () => props.onNext();
const priorityError = usePriorityError(updateError, locationsError, error);

function onOptionChange(selectedOption) {
  if (isBusy.value) return;

  mutate(
    { locationId: selectedOption.location.id },
    {
      onSuccess: () => {
        itemSelected({
          context: 'booking',
          subContext: 'studios',
          itemLabel: selectedOption.location.name,
          itemPrice: 0,
          itemQuantity: 1,
        });
      },
    }
  );
}

const hasCompleted = ref(false);
watchEffect(() => {
  if (currentStep.value?.complete && !hasCompleted.value)
    hasCompleted.value = true;
});

const imageList = tag => {
  const imgs = [{ src: tag.image_url || tag.simple_space_image_url, alt: '' }];
  if (tag.image_secondary_urls) {
    if (tag.image_secondary_urls[0])
      imgs.push({ src: tag.image_secondary_urls[0], alt: '' });
    if (tag.image_secondary_urls[1])
      imgs.push({ src: tag.image_secondary_urls[1], alt: '' });
  }
  return imgs;
};
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
      :no-sidecart="true"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div
        v-if="status === 'success'"
        class="booking-studios prebooking-transition__content"
      >
        <SoonaLoading
          v-if="!locationOptions"
          :is-loading="!locationOptions"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading>what city are you in?</SoonaHeading>
        <div class="booking-studios__grid">
          <SoonaClickableCard
            v-for="l in locationOptions"
            :key="l.location.id"
            :card-data="l"
            :is-disabled="isBusy"
            :image-list="imageList(l.location)"
            :is-selected="l.selected"
            :on-card-click="onOptionChange"
            :title="l.location.display_name"
          />
        </div>
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.booking-studios {
  &__grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    max-width: 50rem;

    @media (min-width: variables.$screen-xs-min) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: variables.$screen-md-min) {
      gap: 2rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>

<script setup>
import { onMounted, ref } from 'vue';
import { useAuth } from '@/composables/useAuth';
import { useStore } from 'vuex';
import { useTitle } from '@vueuse/core';
import AllInOneConsent from 'src/components/shared/AllInOneConsent.vue';
import ResultsLoader from 'src/components/quiz/ResultsLoader.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

const store = useStore();
useTitle('style quiz | soona');

const email = ref(null);
const name = ref(null);
const showLoader = ref(false);

const { authenticationErrors, createUser } = useAuth({
  signUpSuccess: async accountId => {
    await store.dispatch('styleQuiz/createQuizResult', accountId);
    showLoader.value = true;
  },
});

const handleSubmit = () => {
  const attributes = {
    email: email.value,
    name: name.value,
    password: undefined,
    soft_sign_up: true,
    username: email.value,
  };

  createUser(attributes);
};

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<template>
  <transition name="fade" mode="out-in">
    <results-loader v-if="showLoader" />
    <SoonaForm
      v-else
      id="sign-up-form"
      v-slot="{ hasErrors }"
      class="sign-up"
      @submit="handleSubmit"
    >
      <div class="sign-up__left">
        <div class="sign-up__left-inner">
          <h1 class="u-display--heavy">what’s your email? 💌</h1>
          <p class="u-label--heavy">
            let’s start your sweet sweet soona profile.
          </p>
          <SoonaButton
            :disabled="hasErrors || !email"
            form="sign-up-form"
            size="medium"
            type="submit"
          >
            get my results!
          </SoonaButton>
          <p class="u-label--heavy">have an account?</p>
          <SoonaButton
            element="router-link"
            variation="tertiary"
            :to="{
              name: 'style-quiz-sign-in',
              query: { redirect: '/style-quiz/pick-words' },
            }"
          >
            sign in
          </SoonaButton>
        </div>
      </div>
      <div class="sign-up__right">
        <div class="sign-up__right-inner">
          <SoonaTextfield
            v-model:model-value="name"
            label="name"
            type="text"
            placeholder="type your name..."
            class="sign-up-name"
            autocomplete="name"
            :rules="['required']"
            required
          />
          <SoonaTextfield
            v-model:model-value.trim="email"
            label="email"
            type="email"
            name="email"
            placeholder="type your email..."
            autocomplete="email"
            :rules="['required', 'email']"
            required
          />
          <template v-if="authenticationErrors">
            <SoonaError v-for="(error, i) in authenticationErrors" :key="i">
              {{ error }}
            </SoonaError>
          </template>
          <AllInOneConsent />
        </div>
      </div>
    </SoonaForm>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.sign-up {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;

  &__left {
    padding: 1rem;
    width: 100%;
  }

  &__left-inner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    max-width: 20rem;

    button {
      margin-left: auto;
    }
  }

  &__right {
    align-items: center;
    align-self: stretch;
    background-color: variables.$bubbletape-pink-10;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 1rem;
    width: 100%;
  }

  &__right-inner {
    max-width: 20rem;
  }

  @media (min-width: variables.$screen-md-min) {
    flex-direction: row;

    &__left {
      flex-grow: 1;
      padding: 3rem;
    }

    &__left-inner {
      margin: 0;
      margin-left: auto;
    }

    &__right {
      padding: 3rem;
    }
  }
}
</style>

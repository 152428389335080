<script setup>
import { computed, ref } from 'vue';
import SoonaUploadProgressModal from './SoonaUploadProgressModal.vue';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import BonusContentModal from 'src/components/user/anytime/gallery/BonusContentModal.vue';
import { useFlag } from 'src/composables/useFlag';
import { useUploader } from 'src/composables/useUploader.js';
import { useReservation } from '@/composables/useReservation';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaUploadForm from './SoonaUploadForm.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useCreateReservationDigitalAsset } from '@/queries/reservation_digital_assets/useCreateReservationDigitalAsset';
import { useAttachOrCreateRaw } from '@/queries/digital_assets/useAttachOrCreateRaw';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import { useMe } from '@/composables/user/useMe';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

defineOptions({ inheritAttrs: false });

const videoWatermarkFlag = useFlag('video_watermark');

const { currentUserId: authedUserId } = useMe();

const reservationId = computed(() => props.reservationId);
const { currentLocationId: locationId, reservation } =
  useReservation(reservationId);
const reservationAccountId = computed(() => reservation.value?.account_id);

const { addToast } = useSoonaToast();

const { mutate: createReservationDigitalAsset } =
  useCreateReservationDigitalAsset(reservationId);

// used for edits only
const { mutate: createDigitalAsset } =
  useCreateDigitalAsset(reservationAccountId);

const { mutate: attachOrCreateRaw } = useAttachOrCreateRaw(reservationId);

const uploadedBonusContent = ref([]);
const dragAndDropModalVisible = ref(false);
const bonusContentModalVisible = ref(false);
const maxConcurrent = ref(5);
const uploadInterval = ref(100);
const fileSizeLimit = ref('');
const label = ref('');
const accept = ref('');
const uploadType = ref('');
const handleUploadFunc = ref(null);
const startUploadFunc = ref(null);
const handleDropFunc = ref(null);
const handleCancelFunc = ref(null);
const activeUploadsLocal = ref([]);

function validateNotCR2(file) {
  if (file.name.toLowerCase().endsWith('.cr2'))
    return 'cannot upload RAW (CR2) files here';
}

function hideDragAndDropModal() {
  dragAndDropModalVisible.value = false;
}

function hideBonusContentModal() {
  bonusContentModalVisible.value = false;
  uploadedBonusContent.value = [];
}

const mediaType = blob => {
  if (blob.content_type === 'image/gif') {
    return 'animation';
  } else if (blob.content_type.startsWith('image')) {
    return 'photo';
  }
  return 'video';
};

// todo: active shot - handle in scene selector PR
const { data: editsCollection } = useEditsCollection(reservationAccountId, {
  enabled: computed(() => !!reservationAccountId.value),
});
function handleUploadEditsComplete(blob) {
  hideDragAndDropModal();
  if (reservationAccountId.value) {
    createDigitalAsset({
      account_id: reservationAccountId.value,
      file: blob.signed_id,
      asset_type: 'purchased',
      media_type: mediaType(blob),
      origin: 'soona',
      origin_source: 'manual_upload',
      ownership: 'customer',
      title: blob.filename,
      visibility: 'crew',
      reservation_digital_asset_attributes: {
        reservation_id: reservationId.value,
      },
      edits_collection_digital_asset_attributes: {
        added_by_user_id: authedUserId.value,
        collection_id: editsCollection.value?.id,
        edit_status: 'pending',
      },
    });
  }
}

function handleUploadPurchasableComplete(blob) {
  hideDragAndDropModal();
  if (reservationAccountId.value) {
    createReservationDigitalAsset({
      digital_asset_attributes: {
        account_id: reservationAccountId.value,
        asset_type: 'original',
        file: blob.signed_id,
        media_type: mediaType(blob),
        origin: 'soona',
        origin_source: 'manual_upload',
        ownership: 'soona',
        title: blob.filename,
        visibility: 'all',
      },
    });
  }
}

function handleUploadRawComplete(blob) {
  hideDragAndDropModal();

  attachOrCreateRaw({
    raw: blob.signed_id,
  });
}

function submitBonusContent(contentType) {
  if (reservationAccountId.value) {
    uploadedBonusContent.value.forEach(blob => {
      createReservationDigitalAsset({
        content_category: contentType.content_category,
        digital_asset_attributes: {
          account_id: reservationAccountId.value,
          asset_type: 'original',
          file: blob.signed_id,
          media_type: mediaType(blob),
          origin: 'soona',
          origin_source: 'manual_upload',
          ownership: 'soona',
          title: blob.filename,
          visibility: 'all',
        },
      });
    });
    hideBonusContentModal();
  }
}

function onUploadComplete(blob) {
  if (uploadType.value === 'purchasable') {
    handleUploadPurchasableComplete(blob);
  } else if (uploadType.value === 'edit') {
    handleUploadEditsComplete(blob);
  } else if (uploadType.value === 'raw') {
    handleUploadRawComplete(blob);
  } else if (uploadType.value === 'bonus content') {
    uploadedBonusContent.value.push(blob);
  }
  addToast('file successfully uploaded', {
    variation: 'success',
  });
}

function showDragAndDropModalPurchasable() {
  label.value = videoWatermarkFlag.value
    ? 'unpurchased content'
    : 'unpurchased images';
  accept.value = videoWatermarkFlag.value
    ? '.jpeg, .jpg, .png, .mov, .mp4'
    : '.jpeg, .jpg, .png,';
  fileSizeLimit.value = '2000';
  const {
    handleUpload,
    handleDrop,
    activeUploads,
    cancelUploads,
    startUpload,
  } = useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    validateNotCR2,
    null,
    onUploadComplete
  );
  startUploadFunc.value = startUpload;
  handleUploadFunc.value = handleUpload;
  handleDropFunc.value = handleDrop;
  handleCancelFunc.value = cancelUploads;
  activeUploadsLocal.value = activeUploads.value;
  uploadType.value = 'purchasable';
  dragAndDropModalVisible.value = true;
}

function showDragAndDropModalEdit() {
  label.value = 'edits';
  accept.value = '.mp4, .jpeg, .jpg, .png, .gif';
  fileSizeLimit.value = '';
  const {
    handleUpload,
    handleDrop,
    activeUploads,
    cancelUploads,
    startUpload,
  } = useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    validateNotCR2,
    null,
    onUploadComplete
  );
  startUploadFunc.value = startUpload;
  handleUploadFunc.value = handleUpload;
  handleDropFunc.value = handleDrop;
  handleCancelFunc.value = cancelUploads;
  activeUploadsLocal.value = activeUploads.value;
  uploadType.value = 'edit';
  dragAndDropModalVisible.value = true;
}

function showDragAndDropModalRaw() {
  label.value = 'raw files';
  accept.value = '.cr2, .cr3';
  fileSizeLimit.value = '';
  const {
    handleUpload,
    handleDrop,
    activeUploads,
    cancelUploads,
    startUpload,
  } = useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    null,
    null,
    onUploadComplete
  );
  startUploadFunc.value = startUpload;
  handleUploadFunc.value = handleUpload;
  handleDropFunc.value = handleDrop;
  handleCancelFunc.value = cancelUploads;
  activeUploadsLocal.value = activeUploads.value;
  uploadType.value = 'raw';
  dragAndDropModalVisible.value = true;
}

function showBonusContentModal() {
  label.value = 'bonus content';
  accept.value = '.mov, .mp4';
  fileSizeLimit.value = '';
  const {
    handleUpload,
    handleDrop,
    activeUploads,
    cancelUploads,
    startUpload,
  } = useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    validateNotCR2,
    null,
    onUploadComplete
  );
  startUploadFunc.value = startUpload;
  handleUploadFunc.value = handleUpload;
  handleDropFunc.value = handleDrop;
  handleCancelFunc.value = cancelUploads;
  activeUploadsLocal.value = activeUploads.value;
  uploadType.value = 'bonus content';
  bonusContentModalVisible.value = true;
}
</script>
<template>
  <SoonaDropdownMenu copy="upload" variation="secondary-black">
    <template #default="{ keydown, mouseover, clickCapture }">
      <SoonaDropdownMenuItem>
        <button
          role="menuitem"
          @click="showDragAndDropModalPurchasable()"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          unpurchased content
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem>
        <button
          role="menuitem"
          @click="showDragAndDropModalEdit()"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          edits
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem>
        <button
          role="menuitem"
          @click="showDragAndDropModalRaw()"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          raw files
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem>
        <button
          role="menuitem"
          @click="showBonusContentModal()"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          bonus content
        </button>
      </SoonaDropdownMenuItem>
    </template>
  </SoonaDropdownMenu>
  <SoonaDialog v-if="dragAndDropModalVisible" @close="hideDragAndDropModal">
    <template #heading>{{ label }}</template>
    <SoonaUploadForm
      :accept="accept"
      :active-uploads="activeUploadsLocal"
      is-multiple
      @drop="handleDropFunc"
      @upload="handleUploadFunc"
    />
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @click="close">cancel</SoonaButton>
    </template>
  </SoonaDialog>
  <BonusContentModal
    v-if="bonusContentModalVisible"
    :is-multiple="true"
    :accept="accept"
    :active-uploads="activeUploadsLocal"
    :location-id="locationId"
    :uploaded-files="uploadedBonusContent"
    @close="hideBonusContentModal"
    @handle-drop="handleDropFunc"
    @handle-upload="handleUploadFunc"
    @add-content="submitBonusContent"
  />
  <SoonaUploadProgressModal
    v-if="activeUploadsLocal.length > 0"
    :is-multiple="true"
    :active-uploads="activeUploadsLocal"
    :label="label"
    :hide-label="false"
    @cancel-uploads="handleCancelFunc"
    @start-upload="startUploadFunc"
  />
</template>

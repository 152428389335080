<script setup>
import { computed, ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import { useIntervalFn } from '@vueuse/core';
import {
  timeFormat,
  weekdayFormat,
  dateFormat,
  dateYearFormat,
  fullDateFormatter,
  relativeTimeFormatter,
} from './relative-time-formatters';

const props = defineProps({
  isoDateTime: {
    type: String,
    required: true,
  },
});

function relativeFormat() {
  const now = dayjs();
  try {
    const dayjsDate = dayjs(props.isoDateTime);
    const date = dayjsDate.toDate();

    // just in case there's a date in the future
    if (now.isBefore(dayjsDate)) {
      return `${dateYearFormat.format(date)} at ${timeFormat.format(date)}`;
    }

    if (now.diff(dayjsDate, 'minute') < 1) {
      return 'Just now';
    }

    const minutesDiff = now.diff(dayjsDate, 'minutes');
    if (minutesDiff < 60) {
      return relativeTimeFormatter.format(-1 * minutesDiff, 'minutes');
    }

    if (now.isSame(dayjsDate, 'day')) {
      return timeFormat.format(date);
    }

    if (now.subtract(1, 'day').isSame(dayjsDate, 'day')) {
      const yesterday = relativeTimeFormatter.format(-1, 'day');
      return `${yesterday.charAt(0).toLocaleUpperCase() + yesterday.slice(1)} at ${timeFormat.format(date)}`;
    }

    // be sure to keep this in sync with the interval logic
    if (now.subtract(7, 'days').endOf('day').isBefore(dayjsDate)) {
      return `${weekdayFormat.format(date)} at ${timeFormat.format(date)}`;
    }

    if (now.diff(dayjsDate, 'year') < 1) {
      return `${dateFormat.format(date)} at ${timeFormat.format(date)}`;
    }

    return dateYearFormat.format(date);
  } catch (error) {
    return '';
  }
}

const relativeTimeDisplay = ref('');

// handle if the time changes
watchEffect(() => {
  relativeTimeDisplay.value = relativeFormat();
});

const { resume, pause } = useIntervalFn(
  () => {
    relativeTimeDisplay.value = relativeFormat();
  },
  30_000,
  { immediate: false }
);

function isInLastSevenDays() {
  try {
    const now = dayjs();
    const dayjsDate = dayjs(props.isoDateTime);
    // be sure to keep this in sync with the formatting logic
    return now.subtract(7, 'days').endOf('day').isBefore(dayjsDate);
  } catch (error) {
    return false;
  }
}

// start an interval if the time is in the last 7 days
watchEffect(() => {
  if (isInLastSevenDays()) {
    resume();
  } else {
    pause();
  }
});

const absoluteTimeDisplay = computed(() => {
  return fullDateFormatter.format(new Date(props.isoDateTime));
});
</script>

<template>
  <time :datetime="isoDateTime" :title="absoluteTimeDisplay">
    {{ relativeTimeDisplay }}
  </time>
</template>

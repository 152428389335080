<script setup>
import { computed } from 'vue';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';
import SoonaItemCardSelectable from '@/components/ui_library/SoonaItemCardSelectable.vue';

const props = defineProps({
  targetPlatforms: {
    type: Array,
    required: true,
    default: () => [],
  },
  reservationId: { type: String, required: true },
  accountId: { type: [String, Number], required: true },
  editView: { type: Boolean, default: false },
});

const emit = defineEmits(['update:targetPlatforms']);

const platformList = ['Amazon', 'Shopify', 'Instagram', 'Etsy', 'other'];

const targetPlatforms = computed(() => props.targetPlatforms);

const orderedTargetPlatforms = computed(() =>
  platformList.filter(platform => props.targetPlatforms.includes(platform))
);

const platformLogos = {
  Amazon: 'amazon-logo',
  Shopify: 'shopify-logo',
  Instagram: 'instagram-logo',
  Etsy: 'etsy-logo',
  other: 'globe',
};

function selectTargetPlatform(event) {
  if (event.target.checked) {
    emit(
      'update:targetPlatforms',
      targetPlatforms.value.concat(event.target.value)
    );
  } else {
    let newTargetPlatforms = targetPlatforms.value.filter(platform => {
      return platform !== event.target.value;
    });
    emit('update:targetPlatforms', newTargetPlatforms);
  }
}
</script>

<template>
  <h2
    class="target-platforms__heading"
    :class="{ 'target-platforms__heading--bold': editView }"
  >
    {{ editView ? 'where will this content be used?' : 'content destination' }}
  </h2>
  <span v-if="editView" class="target-platforms__sub-heading"
    >choose all that apply</span
  >
  <template v-if="editView">
    <div class="target-platforms__item-cards">
      <SoonaItemCardSelectable
        v-for="platform in platformList"
        :id="platform"
        :key="platform"
        name="target-platforms"
        :soona-icon="platformLogos[platform]"
        :checked="targetPlatforms.includes(platform)"
        :value="platform"
        @change="selectTargetPlatform"
      >
        <span class="target-platforms__item-text">
          {{ platform }}
        </span>
      </SoonaItemCardSelectable>
    </div>
  </template>
  <template v-else-if="targetPlatforms.length > 0">
    <div
      class="target-platforms__content"
      :class="{ 'target-platforms__content--edit-view': editView }"
    >
      <SoonaChip
        v-for="targetPlatform in orderedTargetPlatforms"
        :key="targetPlatform"
        :soona-icon="platformLogos[targetPlatform]"
        size="medium"
        class="target-platforms__destination"
      >
        <span class="target-platforms__destination-text">{{
          targetPlatform
        }}</span>
      </SoonaChip>
    </div>
  </template>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.target-platforms {
  &__heading {
    @include variables_fonts.u-label--regular;
    padding: 1.125rem 0 0.5rem;
    font-weight: 800;
    font-size: 0.875rem;
    color: variables.$black-default;
  }
  &__sub-heading {
    @include variables_fonts.u-label--regular;
    color: variables.$gray-60;
    margin: 0 0 0.5rem 0;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  &__item-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1.9375rem;

    div {
      color: variables.$gray-60;
    }
  }
  &__item-text {
    padding: 0.75rem 2.75rem 0.75rem 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 800;
  }
  &__destination {
    color: variables.$gray-60;
  }

  &__item-text,
  &__destination-text {
    color: variables.$black-default;
  }
}
</style>

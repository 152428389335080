<script setup>
import { computed } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';

import {
  BlackDefault,
  FriendlyRed60,
  FriendlyRed30,
  FriendlyRed20,
  Tangerine30,
  Tangerine20,
  AvoToast60,
  AvoToast30,
  AvoToast20,
} from '@/variables.module.scss';

const props = defineProps({
  orderStatus: {
    required: true,
    type: String,
  },
});

const config = computed(() => {
  switch (props.orderStatus) {
    case 'confirmed':
      return {
        backgroundColor: Tangerine20,
        borderColor: Tangerine30,
        color: BlackDefault,
      };
    case 'cancelled':
      return {
        backgroundColor: FriendlyRed20,
        borderColor: FriendlyRed30,
        color: FriendlyRed60,
      };
    case 'completed':
      return {
        backgroundColor: AvoToast20,
        borderColor: AvoToast30,
        color: AvoToast60,
      };
    case 'paid':
      return {
        backgroundColor: Tangerine20,
        borderColor: Tangerine30,
        color: BlackDefault,
      };
    case 'voided':
      return {
        backgroundColor: FriendlyRed20,
        borderColor: FriendlyRed30,
        color: FriendlyRed60,
      };
    default:
      return false;
  }
});
</script>

<template>
  <SoonaFlag
    v-if="config"
    :background-color="config.backgroundColor"
    :border-color="config.borderColor"
    :text-color="config.color"
    :title="orderStatus"
  />
</template>

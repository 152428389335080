<script setup>
import { computed, ref } from 'vue';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import { useReEditsCollection } from '@/composables/re_edits_collection/useReEditsCollection';
import { useInfiniteReEditsCollectionDigitalAssets } from '@/queries/re_edits_collection_digital_assets/useInfiniteReEditsCollectionDigitalAssets';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import BaseReEdits from '@/components/user/anytime/reservation/re_edits/BaseReEdits.vue';
import SmallInfiniteGallery from '@/components/infinite_asset_gallery/SmallInfiniteGallery.vue';
import ReEditsDigitalAssetCard from '@/components/user/anytime/reservation/re_edits/ReEditsDigitalAssetCard.vue';
import { useUpdateReEditsCollection } from '@/queries/re_edits_collections/useUpdateReEditsCollection';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  collectionId: {
    type: [Number, String],
    required: true,
  },
});

const accountId = computed(() => props.accountId);
const collectionId = computed(() => props.collectionId);

const showConfirmReEditsModal = ref(false);

const wrapper = ref();
const itemsPerPage = ref(30);

const { gutter, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 14,
  gapRem: 0.75,
});

const {
  isLoading: isLoadingReEditsCollection,
  isSubmitted,
  submittedTimeAndDate,
} = useReEditsCollection(accountId, collectionId);

const {
  assetRows,
  fetchPage,
  isLoading: isLoadingAssets,
} = useInfiniteDigitalAssets(collectionId, {
  rowWidth,
  itemsPerPage,
  gutter,
  height: rowHeight,
  query: useInfiniteReEditsCollectionDigitalAssets,
});

const toggleModal = val => {
  showConfirmReEditsModal.value = val;
};

const submitButtonText = computed(() => {
  return isSubmitted.value ? 're-edit order submitted' : 'submit re-edit order';
});

const { mutate: submitReEdit } = useUpdateReEditsCollection(accountId);

const submit = async close => {
  submitReEdit(
    {
      collectionId,
      body: {
        status: 'submitted',
      },
    },
    {
      onSuccess: () => {
        close();
      },
    }
  );
};

const isLoading = computed(() => {
  return isLoadingReEditsCollection.value || isLoadingAssets.value;
});
</script>
<template>
  <div>
    <BaseReEdits v-if="!isLoading && assetRows?.length > 0">
      <template #header>your re-edit requests:</template>
      <template #subheader>
        <template v-if="isSubmitted">
          your re-edit request was submitted at {{ submittedTimeAndDate }}.
          additional re-edits not available until current request is complete.
        </template>
        <template v-else>
          once you have identified all of your re-edit requests you can submit
          them in a batch. all un-submitted orders will be automatically
          submitted after 6 hours. our team will start once your order is
          submitted.
        </template>
      </template>
      <template #header_buttons>
        <SoonaTooltip>
          <template
            #default="{
              setRef,
              mouseenter,
              focus,
              mouseleave,
              blur,
              ariaDescribedby,
            }"
          >
            <SoonaButton
              :ref="el => setRef(el)"
              size="medium"
              variation="primary"
              :disabled="isSubmitted"
              :aria-describedby="ariaDescribedby"
              @on-click="() => toggleModal(true)"
              @mouseenter="mouseenter"
              @focus="focus"
              @mouseleave="mouseleave"
              @blur="blur"
            >
              {{ submitButtonText }}
            </SoonaButton>
          </template>
          <template v-if="isSubmitted" #tooltip-content>
            there is a re-edit request in progress. when complete you can submit
            additional re-edit requests
          </template>
        </SoonaTooltip>
      </template>
      <template #re_edits_content>
        <div v-if="isLoadingAssets">loading re-edits</div>
        <div v-else ref="wrapper">
          <SmallInfiniteGallery
            v-slot="{ data }"
            :rows="assetRows"
            :initial-scroll-position="0"
            :height="rowHeight"
            :gap="gutter"
          >
            <ReEditsDigitalAssetCard
              v-for="recda in data.assets"
              :key="recda.id"
              :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
              :re-edits-collection-digital-asset="recda"
              @request-page="pageNumber => fetchPage(pageNumber)"
            />
          </SmallInfiniteGallery>
        </div>
      </template>
    </BaseReEdits>
    <SoonaDialog
      v-if="showConfirmReEditsModal"
      @close="() => toggleModal(false)"
    >
      <template #heading>
        are you sure you want to submit your re-edits?
      </template>
      <p>
        once you submit your re-edit request you will not be able to add more
        until we have completed this round.
      </p>
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
        <SoonaButton variation="primary" @on-click="submit(close)">
          submit re-edit request
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<template>
  <div class="schedule">
    <div class="schedule__layout">
      <div class="schedule__layout__left">
        <nav class="panel">
          <p class="panel-heading has-text-weight-bold">unscheduled bookings</p>
          <div class="panel-block">
            <p class="control has-icons-left">
              <input
                v-model="search"
                class="input"
                type="text"
                placeholder="search by name, ref or tracking"
              />
              <span class="icon is-small is-left">
                <SoonaIcon name="search" size="medium" />
              </span>
            </p>
          </div>
          <div class="panel-block">
            <div class="control is-expanded">
              <label class="label is-small">schedule status</label>
              <div class="select soona-select is-fullwidth">
                <select v-model="scheduleStatus">
                  <option :value="null">all</option>
                  <option
                    v-for="(status, value) in scheduleStatuses"
                    :key="status"
                    :value="value"
                    :disabled="status === 'ready to go'"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="panel-block">
            <div class="control is-expanded">
              <label class="label is-small">package status</label>
              <div class="select soona-select is-fullwidth">
                <select v-model="packageStatus">
                  <option :value="null">all</option>
                  <option
                    v-for="(status, value) in getPackageStatuses"
                    :key="status"
                    :value="value"
                  >
                    {{ status.replace(/[_-]/g, ' ') }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="panel-block">
            <div class="control is-expanded">
              <span
                class="u-button--small-caps"
                data-cypress="unscheduled-bookings"
                >{{ unscheduledCount }}
                {{
                  unscheduledCount == 1
                    ? 'unscheduled booking'
                    : 'unscheduled bookings'
                }}</span
              >
            </div>
          </div>
          <a
            v-for="booking in unscheduledReservations"
            :key="booking.id"
            class="panel-block unschedule-card"
            data-cypress="unscheduled-booking-card"
            @click="unscheduledSelected(booking)"
          >
            <UnscheduleBookingCard :booking="booking" />
          </a>
          <PaginatorSmall
            :pages="unscheduledPagination.pages"
            :page="unscheduledPagination.page"
            @page-change="handlePageChange"
          />
        </nav>
      </div>
      <div class="schedule__layout__right">
        <flash />
        <schedule-calendar
          :package-status="packageStatus"
          :schedule-status="scheduleStatus"
          :search="search"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import * as types from 'src/store/mutation-types';
import debounce from 'lodash/debounce';
import UnscheduleBookingCard from 'src/components/schedule/UnscheduleBookingCard.vue';
import ScheduleCalendar from 'src/components/shared/scheduleCalendar.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import Flash from 'src/components/Flash.vue';
import PaginatorSmall from 'src/components/PaginatorSmall.vue';
import { packageStatuses } from 'src/lib/inventory/constants';

export default {
  components: {
    Flash,
    PaginatorSmall,
    UnscheduleBookingCard,
    ScheduleCalendar,
    SoonaIcon,
  },
  data() {
    return {
      search: '',
      scheduleStatuses: {
        duplicate: 'duplicate',
        needs_pre_pro: 'needs pre pro',
        no_response: 'no response',
        on_hold: 'on hold',
        pro_service: 'pro service',
        ready_to_go: 'ready to go',
        waiting_on_product: 'waiting on product',
      },
      scheduleStatus: null,
      packageStatus: null,
    };
  },
  computed: {
    ...mapGetters('schedule', ['unscheduledPagination']),
    ...mapState({
      unscheduledReservations: state => state.schedule.unscheduledReservations,
      unscheduledCount: state => state.schedule.unscheduledCount,
    }),
    getPackageStatuses() {
      return packageStatuses;
    },
  },
  watch: {
    search: function () {
      this.debouncedLoadUnscheduledBySearchQuery();
    },
    scheduleStatus: function () {
      this.debouncedLoadUnscheduledBySearchQuery();
    },
    packageStatus: function () {
      this.debouncedLoadUnscheduledBySearchQuery();
    },
  },
  methods: {
    ...mapActions('schedule', ['loadUnscheduledForLocation']),
    ...mapActions(['loadBookingInfo']),
    ...mapActions('soonaGenie', ['loadSuggestedSlots']),
    ...mapMutations('schedule', [types.TOGGLE_UPDATE_RESERVATION_MODAL]),
    unscheduledSelected(reservation) {
      this.loadBookingInfo({ reservationId: reservation.id }).then(() => {
        this.TOGGLE_UPDATE_RESERVATION_MODAL();
        this.loadSuggestedSlots(reservation.id);
      });
    },
    handlePageChange(page) {
      this.loadUnscheduledForLocation({
        page,
        q: this.search,
        scheduleStatus: this.scheduleStatus,
        packageStatus: this.packageStatus,
      });
    },
    debouncedLoadUnscheduledBySearchQuery: debounce(function () {
      this.loadUnscheduledForLocation({
        q: this.search,
        scheduleStatus: this.scheduleStatus,
        packageStatus: this.packageStatus,
      });
    }, 170),
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.schedule {
  padding: 2rem 1rem;

  container-type: inline-size;

  &__layout {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @container (min-width: 45rem) {
      flex-direction: row;
    }

    &__left {
      flex: 1 1 20rem;
    }

    &__right {
      flex: 1 1 70%;
    }
  }

  .select {
    &.pad-left {
      margin-left: 10px;
    }
  }

  .unschedule-card {
    padding: 0.5em 0 !important;
  }

  .search-icon {
    width: 1em;
  }
}
</style>

<script setup>
import { ref, computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import UploadMediaDialog from '@/components/account_gallery/UploadMediaDialog.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
  showUploadButton: {
    default: true,
    type: Boolean,
  },
  showCallToActionButtons: {
    default: true,
    type: Boolean,
  },
});

const showDialog = ref(false);

const bodyCopy = computed(() => {
  if (props.showUploadButton) {
    return 'upload your own media or build a studio shoot to get pro content fast.';
  }

  return 'build a studio shoot to get started.';
});

const headingCopy = computed(() => {
  if (props.showUploadButton) {
    return 'your images and videos will appear here!';
  }

  return 'your unpurchased images and videos will appear here!';
});
</script>

<template>
  <SoonaNoResults>
    <template #image><div class="bg-image"></div></template>
    <template #header>{{ headingCopy }}</template>
    <template #body>{{ bodyCopy }}</template>
    <template v-if="showCallToActionButtons" #button>
      <SoonaButton
        v-if="showUploadButton"
        variation="secondary-gray"
        @on-click="showDialog = true"
      >
        upload media
      </SoonaButton>
      <SoonaButton element="a" href="/#/booking/new" variation="solid-black">
        <SoonaIcon name="camera-alt-1" />
        build a shoot
      </SoonaButton>
    </template>
  </SoonaNoResults>
  <UploadMediaDialog
    v-if="showDialog"
    :account-id="accountId"
    @close="showDialog = false"
  />
</template>

<style lang="scss" scoped>
.bg-image {
  background-image: url('~images/account-gallery/image_row@2x.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: auto 5rem;
  height: 5rem;
  width: 100%;

  @media (min-width: 40rem) {
    background-size: auto 7.5rem;
    height: 7.5rem;
  }
}
</style>

import { computed } from 'vue';

export function usePlatformName(listingAction) {
  const platform = computed(() => {
    switch (listingAction.value.integration_type) {
      case 'AmazonIntegration':
        return 'Amazon';
      case 'ShopifyIntegration':
        return 'Shopify';
      default:
        return null;
    }
  });
  return { platform };
}

<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import FeatureList from 'src/components/subscriptions/FeatureList.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

const props = defineProps({
  context: {
    type: String,
  },
  plan: {
    type: Object,
  },
  showLearnMoreButton: {
    default: false,
    type: Boolean,
  },
  subContext: {
    type: String,
  },
});

const emits = defineEmits(['next']);

const { linkClicked } = useBaseEvents();

const plan = computed(() => props.plan);
const showLearnMoreButton = computed(() => props.showLearnMoreButton);

const borderColor = computed(() => {
  return plan.value?.border_color ? plan.value?.border_color : '#FFFFFF';
});

const planFeatures = computed(() =>
  plan.value?.features.filter(item => !item.first_time_only)
);

const handleLearnMoreBtnClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'learn more',
    linkHref: 'https://soona.co/preferred',
  });
};

const handleSelectBtnClick = label => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: label,
    linkHref: null,
  });

  emits('next');
};
</script>

<template>
  <div class="pricing-card">
    <div class="pricing-card__header">
      <div class="pricing-card__header-left">
        <h4 class="u-headline--heavy">{{ plan?.bulk_credits_title }}</h4>
        <p>save {{ plan?.avg_savings }} on photos</p>
      </div>
      <div class="pricing-card__header-right">
        <p v-if="plan?.photo_price" class="pricing-card__price">
          <span class="u-subheader--heavy">$</span>
          <span class="u-headline--heavy">{{ plan?.photo_price }}</span>
          <span class="u-badge--small">/photo</span>
        </p>
      </div>
    </div>
    <FeatureList :feature-list="planFeatures" :has-no-gap="true" />
    <div class="pricing-card__action-btns">
      <SoonaButton
        variation="solid-black"
        :data-cypress="`button-bulk-credits-modal-select-${plan?.bulk_credits_title.replace(
          /\s+/g,
          '-'
        )}`"
        @on-click="() => handleSelectBtnClick(plan?.bulk_credits_title)"
      >
        select
      </SoonaButton>
      <SoonaButton
        v-if="showLearnMoreButton"
        element="a"
        variation="secondary-black"
        href="https://soona.co/preferred"
        rel="noopener noreferrer"
        target="_blank"
        @on-click="handleLearnMoreBtnClick"
      >
        learn more
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.pricing-card {
  --border-color: v-bind('borderColor');

  border: 0.063rem solid variables.$gray-30;
  border-top: 1rem solid var(--border-color);
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  max-width: 26.25rem;
  width: 100%;
  padding: 1rem;
  padding-top: 1.5rem;

  @media (min-width: variables.$screen-sm-min) {
    height: 100%;
    max-width: 49%;
    min-width: 16.875rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }

  &__prices {
    display: flex;
    gap: 2rem;
    padding-bottom: 1.25rem;
  }

  &__price {
    align-items: baseline;
    display: flex;
    gap: 0.125rem;
  }

  &__benefits {
    margin-top: auto;
    padding-bottom: 1.5rem;
  }

  &__action-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>

<template>
  <div>
    <div class="heading-wrapper">
      <a
        :href="`/user/anytime#/shipping_summary?location=${currentLocation.id}`"
        class="button is-employee u-small--regular margin-right-auto"
      >
        view shipping summary
      </a>
      <div>
        <v-select
          v-model:model-value="currentLocation"
          :get-option-label="location => `${location.name}`"
          :options="locations"
          :reduce="locations => locations"
          :clearable="false"
          :searchable="false"
          data-cypress="select-location"
        ></v-select>
      </div>
      <div>
        <v-select
          v-model:model-value="crewFilter"
          multiple
          :get-option-label="crewMember => `${crewMember.name}`"
          :options="currentLocationCrew"
          :reduce="currentLocationCrew => currentLocationCrew.id"
          :clearable="true"
          :searchable="true"
          :placeholder="`all crew members`"
        >
          <template #option="crewMember">
            <div :class="crewMember.status == 'inactive' ? 'is-inactive' : ''">
              <span
                ><input
                  type="checkbox"
                  :checked="crewFilter.includes(crewMember.id)"
                  class="mr-xs"
                />
                <b>{{ crewMember.name }}</b></span
              >
              <br />
              <p class="ml-l">{{ crewMember.title }}</p>
            </div>
          </template>
        </v-select>
      </div>
      <div>
        <v-select
          v-model:model-value="bookableSpaceFilter"
          multiple
          :get-option-label="bs => `${bs.name}`"
          :options="bookableSpaces"
          :reduce="bookableSpaces => bookableSpaces.id"
          :clearable="false"
          :searchable="false"
          :placeholder="`all ${currentLocation.name} bays`"
        >
          <template #option="bs">
            <div :class="bs.status == 'inactive' ? 'is-inactive' : ''">
              <span
                ><input
                  type="checkbox"
                  :checked="bookableSpaceFilter.includes(bs.id)"
                  class="mr-xs"
                />
                <b>{{ bs.name }}</b></span
              >
              <br />
              <p class="ml-l">{{ bs.description }}</p>
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <div class="extra-settings-header">
      <div class="current-time">
        <RealTimeClock
          v-if="currentLocation && currentLocation.timezone"
          :timezone="currentLocation.timezone"
        />
      </div>
      <div class="toggle-wrapper">
        <SoonaToggle
          :key="showOnlyPreferred"
          v-model:model-value="showOnlyPreferred"
          type="checkbox"
          label="view preferred shoots"
        />
        <span class="tooltip">
          <span v-if="crewFilterEmpty" class="tooltiptext">
            select a crew member to enable unavailability
          </span>
          <SoonaToggle
            :key="showUnavailabilities"
            v-model:model-value="showUnavailabilities"
            type="switch"
            :label="unavailabilityLabel"
            :disabled="crewFilterEmpty"
          />
        </span>
      </div>
    </div>

    <div>
      <FullCalendar ref="calendar" :options="calendarOptions" />
    </div>
    <UpdateReservationModal @refresh-events="refreshEvents()" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import * as types from 'src/store/mutation-types';
import UpdateReservationModal from 'src/components/schedule/UpdateReservationModal.vue';
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import styles from 'src/variables.module.scss';
import RealTimeClock from 'src/components/shared/RealTimeClock.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import { useFlag } from '@/composables/useFlag';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: {
    FullCalendar,
    UpdateReservationModal,
    SoonaToggle,
    RealTimeClock,
  },
  props: [
    'user',
    'location',
    'search',
    'assignedReservations',
    'scheduleStatus',
    'packageStatus',
    'viewCurrentUserOnly',
  ],
  setup() {
    const shootComplexityFlag = useFlag('toaster_shoot_complexity');

    return {
      shootComplexityFlag,
    };
  },
  data() {
    return {
      calendarApi: null,
      calendarOptions: {
        plugins: [timeGridPlugin, dayGridPlugin],
        initialView: 'timeGridWeek',
        slotMinTime: '07:00:00',
        slotMaxTime: '22:00:00',
        height: 'auto',
        timeZone: 'local',
        nowIndicator: false,
        headerToolbar: {
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        eventSources: [
          {
            events: (info, successCallback, failureCallback) => {
              let start = info.startStr.substring(0, 10);
              let end = info.endStr.substring(0, 10);
              this.setCalendarStartAndEnd({
                start: start,
                end: end,
              });
              if (this.currentLocation?.name) {
                if (info.start !== null && info.end !== null) {
                  this.loadLocationEmployees({
                    id: this.currentLocation.id,
                    start: this.calendarStart,
                    end: this.calendarEnd,
                  });
                }
                this.loadScheduleForLocation({
                  start: start,
                  end: end,
                  q: this.search,
                  scheduleStatus: this.scheduleStatus,
                  packageStatus: this.packageStatus,
                  bookableSpaceIds: this.bookableSpaceFilter,
                  crewIds: this.determineCrewFilter,
                  preferredOnly: this.showOnlyPreferred,
                }).then(() => {
                  successCallback(this.reservations);
                });
              } else {
                failureCallback([]);
              }
            },
            eventDataTransform: originalEventData => {
              let eventData = { ...originalEventData };
              return {
                title: eventData.title,
                start: eventData.formatted_time.start,
                end: eventData.formatted_time.end,
                extendedProps: { ...eventData },
                borderColor: this.setBorder(
                  eventData.reservation_type,
                  eventData.color
                ),
                textColor: styles.BlackDefault,
                color: eventData.color,
                display: 'block',
              };
            },
          },
          {
            events: (info, successCallback) => {
              if (this.currentLocation?.name && this.showUnavailabilities) {
                this.loadUnavailabilityEvents({
                  start: info.startStr.substring(0, 10),
                  end: info.endStr.substring(0, 10),
                  crewUserIds: [this.determineCrewFilter],
                  type: 'UnavailabilityEvent',
                  timeZone:
                    this.calendarOptions.timeZone === 'local'
                      ? this.currentLocation.timezone
                      : this.calendarOptions.timeZone,
                }).then(() => {
                  successCallback(this.events);
                });
              } else {
                // removes unavailability events from calendar
                successCallback([]);
              }
            },
            textColor: 'white',
            backgroundColor: '#6b6b6b',
            borderColor: '#6b6b6b',
            eventDataTransform: originalEventData => {
              let eventData = { ...originalEventData };
              return {
                title: eventData.title,
                start: eventData.formatted_time.start,
                end: eventData.formatted_time.end,
                extendedProps: { ...eventData },
                display: 'block',
              };
            },
          },
        ],
        eventContent: arg => {
          if (Object.keys(arg.event._def.extendedProps).length === 0) {
            return;
          }
          let event = { ...arg.event._def.extendedProps };
          let view = { ...arg.view };
          if (event.type === 'UnavailabilityEvent') {
            let eventName = event.user.name;
            if (view.type === 'dayGridMonth') {
              let content = `<p class="u-label--small">${event.formatted_time.start_readable} ${eventName}</p>`;
              return {
                html: `<div class="fc-content">${content}</div>`,
              };
            } else {
              let duration = this.calculateDuration(event.start, event.end);
              let content = '';
              if (duration < 0.5) {
                content = `<p class="u-label--small">${event.formatted_time.start_readable} ${eventName}</p>`;
              } else {
                content = `<p class="u-label--small">${event.formatted_time.start_readable}</p>
                <p class="u-label--regular">${eventName}</p>
                <p class="u-label--regular">Unavailable</p>`;
              }
              return {
                html: `<div class="fc-content">${content}</div>`,
              };
            }
          } else {
            let passed = event.order_status === 'completed';
            let shootComplexity = `<span class="shoot-readiness-icon shoot-readiness-icon__${event?.shoot_complexity}"></span>`;
            let eventName =
              this.shootComplexityFlag && event.shoot_complexity
                ? shootComplexity + event.account_name
                : event.account_name;

            if (event.shoot_type == 'video') {
              if (passed) {
                eventName =
                  '<span class="completed-icons">🎥 </span>' + eventName;
              } else {
                eventName = '🎥 ' + eventName;
              }
            }
            let crewIcon = '';
            if (event.crew.length > 0) {
              if (passed) {
                crewIcon = '<span class="completed-icons">👤</span>';
              } else {
                crewIcon = '👤';
              }
            }
            if (view.type === 'dayGridMonth') {
              let content = `<p class="u-label--small">${event.formatted_time.start_readable} ${eventName} ${crewIcon}</p>`;
              if (passed) {
                content = `<div class="completed-schedule-text">${content}</div>`;
              }
              return {
                html: `<div class="fc-content">${content} </div>`,
              };
            } else {
              let duration = this.calculateDuration(event.start, event.end);
              let bayAssignmentNames = event.bookable_spaces.map(bay => {
                return `<p>${bay.name}</p>`;
              });
              let content = '';
              if (duration < 0.5) {
                content = `<p class="u-label--small">${event.formatted_time.start_readable} ${eventName} ${crewIcon}</p>`;
              } else {
                content = `<p class="u-label--small">${
                  event.formatted_time.start_readable
                }</p>
                  <p class="u-label--regular">${eventName}</p>
                  <div class="u-label--small">
                    <i>
                      ${bayAssignmentNames.join('')}
                    </i>
                  </div>
                  <div>
                    ${crewIcon}
                  </div>`;
              }
              if (passed) {
                content = `<div class="completed-schedule-text">${content}</div>`;
              }
              return {
                html: `<div class="fc-content">${content}</div>`,
              };
            }
          }
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
        },
        displayEventEnd: false,
        eventClick: info => {
          this.eventSelected(info.event._def.extendedProps);
        },
      },
      bookableSpaceFilter: [],
      crewFilter: [],
      showUnavailabilities: false,
      showOnlyPreferred: false,
      currentEvent: null,
      hover: false,
    };
  },
  computed: {
    ...mapGetters('reservations', ['bookableSpaceIds']),
    ...mapState({
      reservations: state => state.schedule.reservations,
      selectedLocation: state => state.locations.currentLocation,
      locations: state => state.locations.locations,
      bookableSpaces: state => state.locations.currentLocationSpaces,
      currentLocationCrew: state => state.locations.crew,
      currentUserId: state => state.currentUser.userID,
      account: state => state.account,
      events: state => state.events.events,
      calendarStart: state => state.schedule.calendarStart,
      calendarEnd: state => state.schedule.calendarEnd,
      suggestedAppointments: state => state.reservations.suggestedSlots,
    }),
    currentLocation: {
      get() {
        return this.selectedLocation;
      },
      set(value) {
        this.loadLocationByName({ name: value.name }).then(() => {
          localStorage.setItem(
            'defaultLocation',
            JSON.stringify(this.selectedLocation)
          );
          this.bookableSpaceFilter = [];
          this.crewFilter = [];
          this.refreshEvents();
        });
      },
    },
    crewFilterEmpty() {
      return this.crewFilter.length === 0;
    },
    determineCrewFilter() {
      if (this.viewCurrentUserOnly) {
        return [this.account.owner.id];
      } else {
        return this.crewFilter;
      }
    },
    unavailabilityLabel() {
      return this.showUnavailabilities
        ? 'hide unavailability'
        : 'show unavailability';
    },
  },
  watch: {
    bookableSpaceFilter: {
      handler() {
        this.refreshEvents();
      },
      deep: true,
    },
    crewFilterEmpty: function () {
      this.showUnavailabilities = false;
    },
    crewFilter: {
      handler() {
        this.refreshEvents();
      },
      deep: true,
    },
    currentLocation: function (location) {
      this.updateCalendar(location);
      this.loadLocationEmployees({
        id: location.id,
        start: this.calendarStart,
        end: this.calendarEnd,
      });
    },
    showUnavailabilities() {
      this.refreshEvents();
    },
    showOnlyPreferred() {
      this.refreshEvents();
    },
  },
  mounted() {
    this.loadLocations().then(() => {
      if (localStorage.getItem('defaultLocation')) {
        let defaultLocation = JSON.parse(
          localStorage.getItem('defaultLocation')
        );

        if (defaultLocation.name === 'Minneapolis') {
          localStorage.removeItem('defaultLocation');
          defaultLocation = this.locations[0];
        }

        this.currentLocation = defaultLocation;
      } else {
        this.currentLocation = this.locations[0];
      }
    });
    this.calendarApi = this.$refs.calendar.getApi();
  },
  methods: {
    ...mapActions('schedule', [
      'loadScheduleForLocation',
      'setCalendarStartAndEnd',
    ]),
    ...mapActions('locations', [
      'loadLocationByName',
      'loadLocations',
      'loadLocationEmployees',
    ]),
    ...mapActions(['loadBookingInfo']),
    ...mapActions('events', ['loadUnavailabilityEvents']),
    ...mapMutations('schedule', [types.TOGGLE_UPDATE_RESERVATION_MODAL]),
    ...mapMutations([types.RESET_SUGGESTED_SLOTS]),
    eventSelected(event) {
      if (event.type !== 'UnavailabilityEvent') {
        this.loadBookingInfo({ reservationId: event.id }).then(() => {
          if (this.suggestedAppointments?.length) {
            this.RESET_SUGGESTED_SLOTS();
          }
          this.TOGGLE_UPDATE_RESERVATION_MODAL();
        });
      }
    },
    refreshEvents() {
      this.calendarApi.refetchEvents();
    },
    setBorder(type, color) {
      return type === 'in_studio' ? color : styles.Black;
    },
    calculateDuration(start, end) {
      start = new Date(start);
      end = new Date(end);
      return (end.getTime() - start.getTime()) / 1000 / 60 / 60;
    },
    updateCalendar(location) {
      this.calendarOptions.timeZone = location.timezone;
      this.calendarOptions.now = dayjs().tz(location.timezone).format();
    },
  },
};
</script>

<style lang="scss">
@use '@/variables';

.is-inactive {
  color: gray;
}
.heading-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .margin-right-auto {
    margin-right: auto;
  }

  .vs__dropdown-toggle {
    width: 11.875rem;
  }
}
.extra-settings-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-top: 1rem;
}
.toggle-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: flex-end;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  font-size: 0.75rem;
  font-weight: 100;
  visibility: visible;
  width: 120px;
  background-color: variables.$white-default;
  color: variables.$gray-90;
  border: 0.0625rem solid variables.$periwink-blue-60;
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.25rem;
  position: absolute;
  z-index: 1;
  bottom: -1.875rem;
  left: -4.375rem;
  margin-left: -3.75rem;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.toggle-text {
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
.fc-event .fc-content {
  white-space: nowrap;
  overflow: hidden;
}
.fc-timegrid-event .fc-content {
  overflow: hidden;
}
.fc-event-main {
  overflow: hidden;
}
.fc-toolbar-chunk {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.fc .fc-toolbar {
  gap: 0.5rem;
}

.shoot-readiness-icon {
  border-radius: 50%;
  display: inline-flex;
  height: 0.75rem;
  margin-right: 0.25rem;
  width: 0.75rem;

  &__low {
    background-color: variables.$green-apple-50;
  }

  &__basic {
    background: variables.$daisy-50;
  }
  &__medium {
    background: variables.$tangerine-50;
  }
  &__advanced {
    background: variables.$roses-80;
  }
  &__high {
    background: variables.$bubbletape-pink-70;
  }
  &__complex {
    background: variables.$periwink-blue-60;
  }
}

@media screen and (max-width: variables.$screen-sm-max) {
  .heading-wrapper {
    flex-direction: column;
    .vs__dropdown-toggle {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 975px) {
  .fc-header-toolbar {
    .fc-toolbar-chunk {
      .fc-toolbar-title {
        font-size: 100%;
      }
    }
  }
}
@media only screen and (max-width: 534px) {
  .fc-header-toolbar {
    display: flex;
    flex-flow: row wrap;
    .fc-toolbar-chunk:nth-child(2) {
      order: 3;
      margin: auto;
    }
  }
}
</style>

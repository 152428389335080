<script setup>
import { computed } from 'vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import { convertToMMDDYYYY } from '@/lib/date-formatters';
import {
  useUpdatePromotion,
  useDeletePromotion,
} from 'src/queries/usePromotions';
import { useCapability } from 'src/composables/useCapability';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  promotion: {
    type: Object,
    required: true,
  },
});
const promotionId = computed(() => props.promotion.id);

const updatePromotionMutation = useUpdatePromotion(promotionId);
const deletePromotionMutation = useDeletePromotion(promotionId);

const { hasCapability: canActivatePromotions } = useCapability({
  capability: 'activate_promotions',
});

const handleToggleActive = val => {
  updatePromotionMutation.mutate({
    promotion: { active: val },
  });
};

const handleToggleArchive = () => {
  let currentDateTime = null;

  if (props.promotion.archived_at === null) {
    currentDateTime = new Date().toUTCString();
  }

  updatePromotionMutation.mutate({
    promotion: { archived_at: currentDateTime },
  });
};

const deletePromotion = () => {
  deletePromotionMutation.mutate();
};
</script>

<template>
  <div
    class="promotion-item"
    :class="{
      'promotion-item--archived': props.promotion.status === 'archived',
    }"
  >
    <p class="promotion-item__title" :title="props.promotion.title">
      {{ props.promotion.title }}
    </p>
    <div class="promotion-item__type">
      <div
        class="promotion-item__type-inner"
        :class="`promotion-item__type--${props.promotion.promo_type}`"
      >
        <p>
          {{ props.promotion.promo_type.replace(/_/g, ' ') }}
        </p>
      </div>
    </div>
    <p class="promotion-item__date-range">
      {{ convertToMMDDYYYY(props.promotion.start_at) }} -
      {{ convertToMMDDYYYY(props.promotion.end_at) }}
    </p>
    <div class="promotion-item__last-editor">
      <div
        v-if="props.promotion.last_editor.avatar_url_url"
        class="promotion-item__last-editor-avatar"
      >
        <img :src="props.promotion.last_editor.avatar_url" alt="user avatar" />
      </div>
      <div v-else class="promotion-item__last-editor-placeholder">
        <p>
          {{
            props.promotion.last_editor.name
              .split(' ')
              .map(word => word[0])
              .join('')
          }}
        </p>
      </div>
      <p :title="props.promotion.last_editor">
        {{ props.promotion.last_editor.name }}
      </p>
    </div>
    <div class="promotion-item__status">
      <div
        class="promotion-item__status-inner"
        :class="`promotion-item__status--${props.promotion.status}`"
      >
        <p>
          {{ props.promotion.status }}
        </p>
      </div>
    </div>
    <div class="promotion-item__active">
      <SoonaToggle
        v-if="props.promotion.archived_at === null"
        :type="`switch`"
        :model-value="props.promotion.active"
        :disabled="!canActivatePromotions"
        data-cypress="promo-toggle"
        label=""
        align="left"
        @update:model-value="handleToggleActive($event)"
      />
    </div>

    <div class="promotion-item__actions">
      <SoonaDropdownMenu
        title="promotion actions"
        variation="icon-plain-gray"
        size="large"
      >
        <template #trigger-content>
          <SoonaIcon name="dots-vertical" />
        </template>
        <template #default="{ keydown, mouseover }">
          <SoonaDropdownMenuItem>
            <router-link
              v-if="props.promotion.archived_at === null"
              class="promotion-item__dropdown-btn"
              role="menuitem"
              :to="`/admin/promotions/${promotion.id}/edit`"
              data-cypress="edit-promo-btn"
              @keydown="keydown"
              @mouseover="mouseover"
            >
              <SoonaIcon name="pen-square" size="medium" /> edit promotion
            </router-link>
          </SoonaDropdownMenuItem>
          <SoonaDropdownMenuItem>
            <button
              v-if="props.promotion.archived_at !== null"
              class="promotion-item__dropdown-btn"
              role="menuitem"
              title="delete"
              @click.prevent="() => deletePromotion()"
              @keydown="keydown"
              @mouseover="mouseover"
            >
              <SoonaIcon name="trash" size="medium" /> delete
            </button>
          </SoonaDropdownMenuItem>
          <SoonaDropdownMenuItem>
            <button
              role="menuitem"
              class="promotion-item__archive"
              @click.prevent="() => handleToggleArchive()"
              @keydown="keydown"
              @mouseover="mouseover"
            >
              <span
                v-if="props.promotion.archived_at === null"
                class="promotion-item__dropdown-btn"
                ><SoonaIcon name="archive-action" size="medium" /> archive</span
              >
              <span v-else class="promotion-item__dropdown-btn"
                ><SoonaIcon name="unarchive-action" size="medium" />
                unarchive</span
              >
            </button>
          </SoonaDropdownMenuItem>
        </template>
      </SoonaDropdownMenu>
    </div>
  </div>
</template>
<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.promotion-item {
  align-items: center;
  background: variables.$white-default;
  border: 1px solid variables.$gray-30;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  padding: 20px;
  width: 100%;

  &__title {
    @include variables_fonts.u-subheader--heavy;

    transition: 0.3s;
    width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: variables.$periwink-blue-60;
    }

    @media (max-width: variables.$screen-xs-max) {
      width: 30%;
      margin-right: 10px;
    }
  }

  &__type {
    display: flex;
    width: 15%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: variables.$screen-xs-max) {
      display: none;
    }

    &-inner {
      align-items: center;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      padding: 5px 10px;
      padding-top: 7px;
      width: max-content;
    }

    &--announcement {
      background: variables.$tangerine-20;
    }

    &--discount_code {
      background-color: variables.$green-apple-20;
    }

    &--sale {
      background-color: variables.$bubbletape-pink-20;
    }

    p {
      color: variables.$black-default;
      font-size: 11px;
      font-weight: 800;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }

  &__date-range {
    @include variables_fonts.u-label--regular;
    color: variables.$gray-50;
    width: 18%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: variables.$screen-xs-max) {
      display: none;
    }
  }

  &__last-editor {
    @include variables_fonts.u-label--regular;
    color: variables.$gray-50;
    width: 18%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;

    @media (max-width: variables.$screen-xs-max) {
      display: none;
    }

    &-avatar {
      border-radius: 50%;
      height: 30px;
      margin-right: 8px;
      width: 30px;
    }

    &-placeholder {
      align-items: center;
      background-color: variables.$periwink-blue-30;
      border-radius: 50%;
      display: flex;
      height: 30px;
      justify-content: center;
      margin-right: 8px;
      width: 30px;

      p {
        color: variables.$white-default;
        font-size: 12px;
        font-weight: 800;
      }
    }
  }

  &__status {
    display: flex;
    width: 10%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: variables.$screen-xs-max) {
      width: 25%;
      margin-right: 10px;
    }

    &-inner {
      justify-content: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;
      width: max-content;
      height: 24px;
      border-radius: 1px;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    &--draft {
      color: variables.$gray-60;
      border: 1px solid variables.$gray-30;
      background: variables.$gray-20;
    }

    &--ready {
      color: variables.$gray-60;
      border: 1px solid variables.$gray-30;
      background-color: variables.$gray-20;
    }

    &--published {
      color: variables.$avo-toast-40;
      border: 1px solid variables.$avo-toast-30;
      background-color: variables.$avo-toast-20;
    }

    &--expired {
      color: variables.$tangerine-70;
      border: 1px solid variables.$tangerine-30;
      background-color: variables.$tangerine-20;
    }

    &--archived {
      color: variables.$roses-60;
      border: 1px solid variables.$roses-30;
      background-color: variables.$roses-20;
    }

    p {
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      align-items: center;
      text-align: center;
    }
  }

  &__active {
    width: 7%;
    display: flex;
    align-items: center;

    :deep(.soona-toggle-wrapper) {
      display: block;
    }
    :deep(.soona-toggle-label) {
      display: inline !important;
    }

    @media (max-width: variables.$screen-xs-max) {
      width: 10%;
    }
  }

  &__actions {
    display: flex;
    width: 5%;
    min-width: 80px;
    display: flex;
    align-items: center;
    > * {
      margin-right: 8px;
      display: flex;
    }
  }

  &__dropdown-btn {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  &__archive {
    &--closed {
      background-color: variables.$white-default;
    }

    &--opened {
      background-color: variables.$gray-30;
    }
  }

  &__delete {
    background-color: variables.$gray-30;
  }

  &--archived {
    background-color: variables.$gray-30;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaStackedBarChart from '@/components/ui_library/SoonaStackedBarChart.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import blankListingInsightsData from '../blankListingInsightsData.json';
import ListingInsightsHelpDialog from '../ListingInsightsHelpDialog.vue';
import { useGetListingInsights } from '@/queries/useListings';
import { useRoute, useRouter } from 'vue-router';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import ManageIntegrationsMenu from '@/components/user/anytime/listing_insights/ManageIntegrationsMenu.vue';
import SoonaGauge from '@/components/ui_library/SoonaGauge.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  accountId: { type: String, required: true },
});

const route = useRoute();
const router = useRouter();

const accountId = computed(() => props.accountId);
const integrationId = computed(() => route.query.integration_id);

const setIntegrationId = id => {
  let query = { ...route.query };
  if (id) {
    query.integration_id = id;
  } else {
    delete query.integration_id;
  }
  router.replace({
    query: query,
  });
};

// to do: replace with combined integrations
const {
  data: listingInsightsData,
  isLoading: listingInsightsLoading,
  error: listingInsightsError,
} = useGetListingInsights(accountId, integrationId);

const listingInsights = computed(
  () => listingInsightsData.value ?? blankListingInsightsData
);

// segmented bar chart module
const formattedStackedBarChartData = computed(() => {
  return {
    total: listingInsights.value?.listing_breakdown?.total_listings ?? 0,
    segments: listingInsights.value?.listing_breakdown?.ratings ?? [],
  };
});

const legendKeyTo = label => {
  let query = { ...route.query, score_category: label };
  if (integrationId.value) {
    query.integration_id = integrationId.value;
  }
  return {
    name: 'listing-insights-store-list',
    query: query,
  };
};

// gauge module
const formattedMeterData = computed(() => {
  return {
    value: listingInsights.value?.visual_average?.score?.value ?? 0,
    label: listingInsights.value?.visual_average?.score?.label ?? null,
    minValue: 0,
    maxValue: 1,
    low: 0.33,
    high: 0.66,
  };
});

const priorityError = usePriorityError(listingInsightsError);
</script>

<template>
  <div class="soona-score">
    <div class="soona-score__heading-and-gauge-wrapper">
      <div class="soona-score__heading--wrapper">
        <div class="soona-score__heading">
          <h2 class="soona-score__title u-title--heavy">soona score</h2>
          <ListingInsightsHelpDialog :show-label-text="false" />
        </div>
        <ManageIntegrationsMenu
          :account-id="accountId"
          :integration-id="integrationId"
          @select="setIntegrationId"
        />
      </div>
      <div class="soona-score__gauge-wrapper">
        <SoonaGauge
          accessible-title="overall score"
          :data="formattedMeterData"
          :is-loading="listingInsightsLoading"
        />
      </div>
    </div>

    <div class="soona-score__content">
      <SoonaError v-if="priorityError" no-margin>
        {{ priorityError }}
      </SoonaError>
      <SoonaStackedBarChart
        v-if="!priorityError"
        :data="formattedStackedBarChartData"
        :is-loading="listingInsightsLoading"
        accessible-title="score breakdown"
      >
        <template #figcaption>
          <SoonaButton
            v-for="segment in formattedStackedBarChartData.segments"
            :key="segment.label"
            element="router-link"
            variation="icon-plain-gray"
            :to="legendKeyTo(segment.label)"
            class="soona-stacked-bar-chart__legend--key"
          >
            <SoonaFlag
              :class="`rating--${segment.label}`"
              type="pill"
              padding-size="0.125rem 0.25rem"
            >
              <template #title>{{ segment.label }}</template>
            </SoonaFlag>
            <span class="u-label--heavy">
              {{ segment.value }} listing{{ segment.value === 1 ? '' : 's' }}
            </span>
          </SoonaButton>
        </template>
      </SoonaStackedBarChart>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-score {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__heading-and-gauge-wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    &--wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.soona-stacked-bar-chart__legend {
  &--key {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
  }
}

.rating {
  &--poor {
    background: linear-gradient(
      360deg,
      variables.$gradient-red-start 0%,
      variables.$gradient-red-end 100%
    );
  }

  &--okay {
    background: linear-gradient(
      360deg,
      variables.$gradient-gold-start 0%,
      variables.$gradient-gold-end 100%
    );
  }

  &--great {
    background: linear-gradient(
      360deg,
      variables.$gradient-green-start 0%,
      variables.$gradient-green-end 100%
    );
  }
}
</style>

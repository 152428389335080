<script setup>
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { computed } from 'vue';
import { useCreateInvitation } from '@/queries/invitations/useCreateInvitation';
import { usePriorityError } from '@/composables/usePriorityError';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  inviteEmail: {
    type: String,
    required: true,
  },
  inviteRoleId: {
    type: Number,
    required: true,
  },
});

defineEmits(['close']);

const accountId = computed(() => Number(props.accountId));

const {
  mutate,
  isPending: isCreateInvitationLoading,
  error: createInvitationError,
} = useCreateInvitation(accountId);

const priorityError = usePriorityError(createInvitationError);

function sendInvite(close) {
  if (!props.inviteEmail.trim() || !props.inviteRoleId) return;

  mutate(
    {
      accountId: accountId.value,
      email: props.inviteEmail.toLowerCase().trim(),
      role_id: props.inviteRoleId,
    },
    { onSuccess: () => close() }
  );
}
</script>

<template>
  <SoonaDialog type="alertdialog" @close="$emit('close')">
    <template #heading>resend invitation</template>
    <p>resend invite link to the following email address?</p>
    <p>{{ inviteEmail }}</p>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        variation="primary"
        :loading="isCreateInvitationLoading"
        @on-click="sendInvite(close)"
      >
        send
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

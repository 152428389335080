<script setup>
import { computed, ref, watch } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import ListingCard from '@/components/user/anytime/listing_insights/listing_card/ListingCard.vue';
import { useRouter, useRoute } from 'vue-router';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useListings } from '@/queries/useListings';
import { keepPreviousData } from '@tanstack/vue-query'; // needed?
import { usePriorityError } from 'src/composables/usePriorityError';
import { useIntegrations } from '@/composables/useIntegrations';
import ListingsListLoadingState from '../ListingsListLoadingState.vue';
import { useListingsListMinHeight } from '@/components/user/anytime/listing_insights/useListingsListMinHeight.js';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import QuestHelper from '@/components/quest/QuestHelper.vue';

const props = defineProps({
  accountId: { type: String, required: true },
});

const router = useRouter();
const route = useRoute();
const { filterSelected } = useBaseEvents();

const accountId = computed(() => props.accountId);

const {
  hasActionableAmazonIntegration,
  integrationsSuccess,
  integrationsError,
} = useIntegrations(accountId);

const toListingsList = () => {
  // to do: segment events
  //   linkClicked({
  //     context: route.meta.context,
  //     subContext: 'store overview',
  //     linkLabel: 'all listings',
  //     linkHref: '/user/anytime#/account/listing-insights/integration/store-list',
  //   });

  const params = {
    accountId: accountId.value,
  };

  router.push({
    name: 'listing-insights-store-list',
    params,
    query: { ...route.query },
  });
};

// filter module
const filterOptions = computed(() => {
  let result = ['recommended', 'low scores', 'high price'];

  if (integrationsSuccess.value && hasActionableAmazonIntegration.value) {
    result.splice(1, 0, 'high Amazon rank');
  }

  return result;
});

const filter = ref('recommended');

watch(filter, newFilter => {
  filterSelected({
    context: 'listing insights',
    subContext: 'integrated dashboard',
    filterCategory: 'priority listings',
    filterLabel: newFilter,
  });
});

// listings module
const sortBy = computed(() => {
  let orderBy, direction;

  switch (filter.value) {
    case 'recommended':
      orderBy = 'priority';
      direction = 'asc';
      break;
    case 'high Amazon rank':
      orderBy = 'display_group_rank';
      direction = 'asc';
      break;
    case 'low scores':
      orderBy = 'score';
      direction = 'asc';
      break;
    case 'high price':
      orderBy = 'price';
      direction = 'desc';
      break;
    default:
      orderBy = 'priority';
      direction = 'asc';
  }

  return { orderBy, direction };
});

const orderBy = computed(() => sortBy.value.orderBy);
const direction = computed(() => sortBy.value.direction);

const {
  data: listingsData,
  error: listingsError,
  isLoading,
  //   isSuccess,
} = useListings(
  accountId,
  {
    itemsPerPage: 10,
    orderBy,
    direction,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const listings = computed(() => listingsData.value?.listings ?? []);

const { minHeight } = useListingsListMinHeight(listings);

const priorityError = usePriorityError(listingsError, integrationsError);
</script>

<template>
  <div class="priority-listings">
    <div class="priority-listings__heading">
      <h2 class="priority-listings__title u-title--heavy">priority listings</h2>
      <QuestHelper quest-task-slug="listing_insights">
        <SoonaButton
          class="priority-listings__all-listings-button"
          variation="tertiary"
          @click="toListingsList"
        >
          all listings
          <SoonaIcon
            class="priority-listings__all-listings-button--icon"
            name="arrow-right"
            size="small"
          />
        </SoonaButton>
      </QuestHelper>
    </div>

    <SoonaFilterSingle v-model="filter" :options="filterOptions" />

    <SoonaError v-if="priorityError" no-margin>
      {{ priorityError }}
    </SoonaError>

    <div class="priority-listings__listings">
      <div v-if="isLoading" class="priority-listings__listings--loading">
        <ListingsListLoadingState />
      </div>
      <TransitionGroup name="listing" tag="div" class="priority-listings__list">
        <ListingCard
          v-for="listing in listings"
          :key="listing.listing_id"
          :account-id="accountId"
          :listing="listing"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.priority-listings {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: v-bind(minHeight);

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__all-listings-button {
    &--icon {
      transition: transform 0.1s ease-out;
    }

    &:hover,
    &:focus-visible {
      .priority-listings__all-listings-button--icon {
        transform: translateX(0.1875rem);
      }
    }
  }

  &__listings {
    position: relative;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
  }
}

// transitions

/* base */
.listing {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.listing-move {
  transition: all 0.4s ease;
}

/* appearing */
.listing-enter-active {
  transition: all 0.3s ease-out;
}

/* disappearing */
.listing-leave-active {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disppear to */
.listing-enter-from,
.listing-leave-to {
  opacity: 0;
}
</style>

import { computed, inject, ref, toValue, watch, watchEffect } from 'vue';
import { CometChat } from '@cometchat-pro/chat';
import {
  captureChatException,
  COMET_CHAT_PROVIDER_NAME,
  getGroupMembers,
  getGroupMessages,
  getPreviousMessages,
  getUnreadMessageCountForGroup,
  sendGroupMediaMessage,
  sendGroupMessage,
  sendGroupQuickReaction,
  sortedAndUniq,
} from './utils';
import { useCapability } from '@/composables/useCapability';
import * as Sentry from '@sentry/vue';
import { uuidv4 } from '@/lib/uuid';

/**
 *
 * @callback onPlayReceivedMessageSound
 * @returns {void}
 */

/**
 * @typedef {import('vue').MaybeRef} MaybeRef
 * @param {Object} config
 * @param {MaybeRef<string>} config.calUuid
 * @param {MaybeRef<Array<number> | undefined>} config.visibleUserIds
 * @param {onPlayReceivedMessageSound} config.onPlayReceivedMessageSound
 */
export function useReservationChat({
  calUuid,
  visibleUserIds,
  onPlayReceivedMessageSound,
}) {
  const {
    state: cometChatState,
    StateType: CometChatStateType,
    error: cometChatError,
    user,
    connectionStatus,
    connect,
    reset: cometChatReset,
  } = inject(COMET_CHAT_PROVIDER_NAME);

  const { hasCapability: isSoonaStaff, status: isSoonaStaffStatus } =
    useCapability({
      capability: 'soona_staff',
    });

  const ChatState = Object.freeze({
    Disabled: 'disabled',
    Pending: 'pending',
    Ready: 'ready',
    // for when there is a largely irrecoverable error
    Error: 'error',
    WaitingForCrewJoinType: 'waiting-for-crew-join-type',
  });

  const state = ref(ChatState.Pending);
  // messaging to the user
  const error = ref(null);
  const group = ref(null);

  const allowedUids = computed(() =>
    toValue(visibleUserIds).map(u => u.toString())
  );
  const isGhostUser = computed(() => {
    if (isSoonaStaff.value) {
      return !visibleUserIds.value?.find(
        userId => userId.toString() === user.value?.uid
      );
    }
    return false;
  });

  /*
   * sync states from top-level comet chat initializer and user behavior
   */
  watchEffect(() => {
    if (cometChatState.value === CometChatStateType.Disabled) {
      state.value = ChatState.Disabled;
    } else if (cometChatState.value === CometChatStateType.Error) {
      state.value = ChatState.Error;
      error.value = cometChatError.value;
    } else if (cometChatState.value === CometChatStateType.Pending) {
      state.value = ChatState.Pending;
    }
    // ready state means we take over with group and messaging logic
  });

  watchEffect(() => {
    if (state.value !== ChatState.Error) {
      error.value = null;
    }
  });

  const messages = ref([]);
  const unreadCount = ref(0);
  const members = ref([]);

  async function joinChatGroup(groupId, autoJoin = true) {
    try {
      const fetchedGroup = await CometChat.getGroup(groupId);
      const hasJoined = fetchedGroup.getHasJoined();

      Sentry.addBreadcrumb({
        category: 'chat',
        message: `fetched group: ${groupId}`,
        level: 'info',
      });

      if (hasJoined) {
        group.value = fetchedGroup;
      } else if (autoJoin) {
        const joinedGroup = await CometChat.joinGroup(
          groupId,
          CometChat.GROUP_TYPE.PUBLIC
        );
        Sentry.addBreadcrumb({
          category: 'chat',
          message: `joined group: ${groupId}`,
          level: 'info',
        });

        group.value = joinedGroup;
      } else {
        state.value = ChatState.WaitingForCrewJoinType;
      }
    } catch (e) {
      group.value = null;
      state.value = ChatState.Error;
      error.value = captureChatException(e, 'failed to join comet chat group');
    }
  }

  // auto-join group if user, or wait for crew to join
  watch(
    [cometChatState, state, group, isSoonaStaffStatus, isSoonaStaff, calUuid],
    ([
      chatState,
      reservationChatStatus,
      chatGroup,
      status,
      isStaff,
      cal_uuid,
    ]) => {
      if (
        chatState === CometChatStateType.Ready &&
        status === 'success' &&
        !chatGroup &&
        cal_uuid
      ) {
        if (reservationChatStatus === ChatState.Pending && !isStaff) {
          joinChatGroup(cal_uuid);
        } else if (
          (reservationChatStatus === ChatState.Pending ||
            reservationChatStatus === ChatState.Ready) &&
          isStaff
        ) {
          // only join if already in group
          joinChatGroup(cal_uuid, false);
        }
      }
    },
    { immediate: true }
  );

  async function markMessagesAsRead(guid) {
    const length = messages.value.length;

    if (length > 0) {
      const message = messages.value[length - 1];

      try {
        CometChat.markAsRead(message);
        Sentry.addBreadcrumb({
          category: 'chat',
          message: `marked message with id ${message.id} as read`,
          level: 'info',
        });

        unreadCount.value = await getUnreadMessageCountForGroup(guid);
      } catch (e) {
        /*
         * this is a lower priority error. we want to preserve a message about
         * messages failing to send over failing to mark as read
         */
        if (!error.value) {
          error.value = e;
        }
      }
    }
  }

  async function publicMarkMessagesAsRead() {
    if (!group.value) return;
    const guid = group.value.getGuid();
    await markMessagesAsRead(guid);
  }

  // reload messages and members if chat reconnects
  watch(connectionStatus, async newStatus => {
    if (!group.value) return;
    const guid = group.value.getGuid();

    if (newStatus === CometChat.CONNECTION_STATUS.CONNECTED && guid) {
      try {
        const [groupMembers, groupMessages] = await Promise.all([
          getGroupMembers(guid),
          getGroupMessages(guid),
        ]);
        members.value = groupMembers;
        messages.value = sortedAndUniq(groupMessages);
        Sentry.addBreadcrumb({
          category: 'chat',
          message: 'connection reconnected, reloading messages and members',
          level: 'info',
        });
      } catch (e) {
        error.value = e;
      }
    }
  });

  // connect to a group when it is set
  watchEffect(async onCleanup => {
    if (!group.value) return;

    const guid = group.value.getGuid();

    const chatInstanceUuid = `${guid}---${uuidv4()}`;

    onCleanup(() => {
      unreadCount.value = 0;
      members.value = [];
      messages.value = [];
      CometChat.removeMessageListener(chatInstanceUuid);
      CometChat.removeGroupListener(chatInstanceUuid);
      state.value = ChatState.Pending;
    });

    try {
      const [groupMembers, groupMessages] = await Promise.all([
        getGroupMembers(guid),
        getGroupMessages(guid),
      ]);
      members.value = groupMembers;
      messages.value = sortedAndUniq(groupMessages);

      markMessagesAsRead(guid);
    } catch (e) {
      state.value = ChatState.Error;
      error.value = captureChatException(e, 'failed to initialize group');
      return;
    }

    CometChat.addMessageListener(
      chatInstanceUuid,
      new CometChat.MessageListener({
        onTextMessageReceived: textMessage => {
          if (textMessage.receiverId === guid) {
            messages.value = sortedAndUniq([...messages.value, textMessage]);
            Sentry.addBreadcrumb({
              category: 'chat',
              message: 'new message received',
              level: 'info',
            });
            getUnreadMessageCountForGroup(guid)
              .then(count => {
                unreadCount.value = count;
              })
              .catch(e => {
                error.value = e;
              });
            if (textMessage.getSender().getUid() !== user.value.getUid()) {
              onPlayReceivedMessageSound();
            }
          }
        },
        onCustomMessageReceived: customMessage => {
          if (customMessage.receiverId === guid) {
            messages.value = sortedAndUniq([...messages.value, customMessage]);
            Sentry.addBreadcrumb({
              category: 'chat',
              message: 'custom message received',
              level: 'info',
            });
            getUnreadMessageCountForGroup(guid)
              .then(count => {
                unreadCount.value = count;
              })
              .catch(e => {
                error.value = e;
              });
            // don't play sound for staff pick messages
            if (
              customMessage.getSender().getUid() !== user.value.getUid() &&
              customMessage.type !== 'staffPickAdded'
            ) {
              onPlayReceivedMessageSound();
            }
          }
        },
        onMediaMessageReceived: mediaMessage => {
          if (mediaMessage.receiverId === guid) {
            messages.value = sortedAndUniq([...messages.value, mediaMessage]);
            Sentry.addBreadcrumb({
              category: 'chat',
              message: 'media message received',
              level: 'info',
            });
            getUnreadMessageCountForGroup(guid)
              .then(count => {
                unreadCount.value = count;
              })
              .catch(e => {
                error.value = e;
              });
            if (mediaMessage.getSender().getUid() !== user.value.getUid()) {
              onPlayReceivedMessageSound();
            }
          }
        },
      })
    );

    CometChat.addGroupListener(
      chatInstanceUuid,
      new CometChat.GroupListener({
        onGroupMemberJoined: (message, joinedUser, joinedGroup) => {
          if (joinedGroup.conversationId === guid) {
            messages.value = sortedAndUniq([...messages.value, message]);
            members.value = [...members.value, joinedUser];
          }
          Sentry.addBreadcrumb({
            category: 'chat',
            message: `user ${joinedUser.getUid()} joined`,
            level: 'info',
          });
        },
        onGroupMemberLeft: (message, leftUser, leftGroup) => {
          if (leftGroup.conversationId === guid) {
            messages.value = sortedAndUniq([...messages.value, message]);
            members.value = members.value.filter(
              member => member.getUid() === leftUser.getUid()
            );
          }
          Sentry.addBreadcrumb({
            category: 'chat',
            message: `user ${leftUser.getUid()} left`,
            level: 'info',
          });
        },
      })
    );

    state.value = ChatState.Ready;
  });

  async function joinChat() {
    await joinChatGroup(toValue(calUuid));
  }

  async function sendMessage(message) {
    if (!group.value) return;
    const guid = group.value.getGuid();

    try {
      const textMessage = await sendGroupMessage(guid, message);
      messages.value = sortedAndUniq([...messages.value, textMessage]);
    } catch (e) {
      error.value = e;
    }
  }

  async function sendQuickReaction(emoji) {
    if (!group.value) return;
    const guid = group.value.getGuid();

    try {
      const customMessage = await sendGroupQuickReaction(guid, emoji);
      messages.value = sortedAndUniq([...messages.value, customMessage]);
    } catch (e) {
      error.value = e;
    }
  }

  async function sendMediaMessage(files) {
    if (!group.value) return;
    const guid = group.value.getGuid();

    try {
      const mediaMessages = await sendGroupMediaMessage(guid, files);
      messages.value = sortedAndUniq([...messages.value, ...mediaMessages]);
    } catch (e) {
      error.value = e;
    }
  }

  async function loadPreviousMessages(limit) {
    if (!group.value) return;
    const guid = group.value.getGuid();

    try {
      const earliestMessageId = messages.value[0]?.id ?? null;
      const newMessages = await getPreviousMessages(
        guid,
        earliestMessageId,
        limit
      );

      const newList = sortedAndUniq([...newMessages, ...messages.value]);
      const diff = newList.length - messages.value.length;
      messages.value = newList;

      return diff;
    } catch (e) {
      error.value = e;
    }
  }

  const filteredMessages = computed(() => {
    // filter out groupMember join/leave messages from not allowed uids
    return messages.value.filter(
      m =>
        !(
          m.type === 'groupMember' &&
          !allowedUids.value.includes(m.actionBy.uid)
        )
    );
  });

  const filteredMembers = computed(() => {
    return members.value.filter(m => allowedUids.value.includes(m.uid));
  });

  function refresh() {
    // try a bunch of stuff to refresh things
    if (connectionStatus.value !== CometChat.CONNECTION_STATUS.CONNECTED) {
      connect();
    }
    if (group.value) {
      group.value = null;
    }
    if (cometChatState.value === CometChatStateType.Error) {
      cometChatReset();
    } else if (state.value === ChatState.Error) {
      state.value = ChatState.Pending;
    }
  }

  return {
    connectionStatus,
    disabled: computed(() => state.value !== ChatState.Ready || !group.value),
    displayRefresh: computed(
      () =>
        state.value === ChatState.Error ||
        connectionStatus.value !== CometChat.CONNECTION_STATUS.CONNECTED ||
        !!error.value
    ),
    error,
    markMessagesAsRead: publicMarkMessagesAsRead,
    messages: filteredMessages,
    members: filteredMembers,
    isChatOn: computed(() => state.value !== ChatState.Disabled),
    isGhostUser,
    joinChat,
    loadPreviousMessages,
    promptJoinGroup: computed(
      () => state.value === ChatState.WaitingForCrewJoinType
    ),
    refresh,
    sendMediaMessage,
    sendMessage,
    sendQuickReaction,
    unreadCount,
  };
}

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  photographer: {
    type: Object,
  },
  variation: {
    default: 'staff',
    type: String,
    validator: function (value) {
      return ['staff', 'trophy'].includes(value);
    },
  },
});
const emit = defineEmits(['selectStaffPicks']);

const { filterSelected } = useBaseEvents();

const route = useRoute();
const context = route.meta.context;
const photographer = computed(() => props.photographer);
const variation = computed(() => props.variation);

const heading = computed(() => {
  if (variation.value === 'staff') {
    return `${photographer.value?.name?.split(' ')[0] ?? 'staff'}’s picks`;
  }

  return 'too much good stuff?';
});

const handleClick = () => {
  filterSelected({
    context: context,
    subContext: 'tile filter',
    filterCategory: '',
    filterSubcategory: '',
    filterLabel: 'expert picks',
  });

  emit('selectStaffPicks');
};
</script>

<template>
  <div class="recommended-tile">
    <div class="recommended-tile__inner">
      <SoonaIcon
        v-if="variation === 'trophy'"
        name="trophy-duotone"
        size="large"
      />
      <img
        v-else-if="photographer?.avatar_url"
        class="recommended-tile__image"
        :src="photographer?.avatar_url"
      />
      <h3 class="recommended-tile__heading">{{ heading }}</h3>
      <p class="recommended-tile__content">
        we made our expert picks based on your goals. add them to your cart with
        a click.
      </p>
      <SoonaButton variation="secondary-black" @click="handleClick">
        see our expert picks
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.recommended-tile {
  flex-grow: 1;
  overflow: hidden;
  width: 100%;

  &__inner {
    align-items: center;
    animation: fade-in 0.6s ease-out;
    background-color: variables.$green-apple-20;
    border-bottom: 0.0625rem solid variables.$white-default;
    border-right: 0.0625rem solid variables.$white-default;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 1.25rem 1.5rem;
    text-align: center;
    width: 100%;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
        transform: translateY(100%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__image {
    border-radius: 50%;
    height: 3rem;
    margin-bottom: 0.25rem;
    width: 3rem;
    object-fit: cover;
  }

  &__heading {
    @include variables_fonts.u-headline--heavy;
  }

  &__content {
    @include variables_fonts.u-body--regular;

    padding-bottom: 0.5rem;
  }
}
</style>

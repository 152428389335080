import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { updateInventoryItem } from 'src/api/inventoryItem';
import { queryKeys } from '../query-keys';
import { unref } from 'vue';

/**
 *
 * @param {Ref<number> | number} itemId
 * @param {Ref<number> | number} accountId
 * @returns {UseMutationReturnType}
 */
export function useUpdateInventoryItem(itemId, accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: inventoryItemDetails =>
      updateInventoryItem(unref(itemId), {
        inventoryItemDetails,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.inventoryPackages(),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.inventoryItemsUnidentifiedCount(accountId),
      });
    },
  });
}

<script setup>
import { computed, ref } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useCollaborators } from '@/queries/collaborators/useCollaborators';
import { useShareDraftEmail } from 'src/queries/useShareDraftEmail';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';

const props = defineProps({
  account: {
    type: Object,
    required: true,
  },
  reservationId: {
    type: Number,
    required: true,
  },
});
const emit = defineEmits(['cancel', 'share-success']);

const { data: collaborators } = useCollaborators(
  computed(() => props.account?.id),
  {
    currentPage: 1,
  },
  { enabled: computed(() => !!props.account?.id) }
);
const { mutate: shareDraftEmail } = useShareDraftEmail(
  computed(() => props.reservationId)
);

const shareOrderWith = ref([]);

const filteredCollaborators = computed(() => {
  let index = collaborators.value?.collaborators.findIndex(
    x => x.email === props.account.email
  );
  if (index >= 0) {
    let newCollabs = collaborators.value?.collaborators.map(a => a);
    newCollabs.splice(index, 1);
    newCollabs.push(props.account.owner);
    return newCollabs;
  } else {
    return collaborators.value?.collaborators;
  }
});

const shareOrder = () => {
  shareDraftEmail(shareOrderWith.value, {
    onSuccess: () => {
      emit('share-success');
    },
  });
};

const userSelect = user => {
  let index = shareOrderWith.value.indexOf(user);
  if (index >= 0) {
    shareOrderWith.value.splice(index, 1);
  } else {
    shareOrderWith.value.push(user);
  }
};

const isSelected = user => {
  return shareOrderWith.value.includes(user);
};
</script>

<template>
  <SoonaDialog class="share-modal" @close="$emit('cancel')">
    <template #heading>share order</template>
    <div>
      <p class="share-modal__description">
        choose team members below, and they will receive an email with a link to
        this order
      </p>
      <ul class="share-modal__checkboxes">
        <li v-for="user in filteredCollaborators" :key="user.id">
          <SoonaToggle
            :model-value="isSelected(user)"
            type="checkbox"
            :label="`${user.name}, ${user.email}`"
            @update:model-value="userSelect(user)"
          />
        </li>
      </ul>
    </div>
    <template #footer="{ close }">
      <SoonaButton type="button" variation="tertiary" @on-click="close">
        cancel
      </SoonaButton>
      <SoonaButton type="button" variation="primary" @on-click="shareOrder">
        share order
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.share-modal {
  &__description {
    margin-bottom: 1rem;
  }

  &__checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.625rem;

    li {
      flex: 1 1 45%;
    }
  }
}
</style>

<script setup>
import DownloadDigitalAssetMenuItem from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAssetMenuItem.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { computed, toRefs } from 'vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { storeToRefs } from 'pinia';

const props = defineProps({
  clickCapture: {
    type: Function,
    required: true,
  },
  keydown: {
    type: Function,
    required: true,
  },
  mouseover: {
    type: Function,
    required: true,
  },
});

const { clickCapture, keydown, mouseover } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();
const {
  assetAccountId,
  assetId,
  hasSubscriptionDownloadAccess,
  canvasComponent,
} = storeToRefs(mediaEditorStore);

const { isRemoveBackgroundActive } = useMediaEditor();

const { mediaWidth, mediaHeight, foregroundUrl } = useMediaEditorDigitalAsset(
  assetAccountId,
  assetId
);

const subtitle = computed(() => {
  if (mediaWidth.value && mediaHeight.value) {
    return `${mediaWidth.value} x ${mediaHeight.value}`;
  }
  return '';
});

const { linkClicked } = useBaseEvents();
const route = useRoute();
const handleSave = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor header',
    linkLabel: 'save to gallery',
    linkHref: null,
  });

  mediaEditorStore.saveEdit();
};

const handleSaveLowResPreview = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor header',
    linkLabel: 'save low res preview',
    linkHref: null,
  });

  if (isRemoveBackgroundActive.value) {
    window.location.href = foregroundUrl.value;
  } else {
    canvasComponent.value?.saveLowRes();
  }
};
</script>

<template>
  <DownloadDigitalAssetMenuItem
    v-if="hasSubscriptionDownloadAccess"
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="save to gallery"
    subtitle="required before download"
    @click="handleSave"
  />
  <template v-else>
    <DownloadDigitalAssetMenuItem
      :mouseover="mouseover"
      :keydown="keydown"
      :click-capture="clickCapture"
      title="save to gallery"
      subtitle="save low res preview to gallery"
      @click="handleSave"
    />
    <DownloadDigitalAssetMenuItem
      :mouseover="mouseover"
      :keydown="keydown"
      :click-capture="clickCapture"
      title="low res preview"
      subtitle="with soona watermark"
      @click="handleSaveLowResPreview"
    >
      <template #right-col>
        <span class="u-label--regular free">free</span>
      </template>
    </DownloadDigitalAssetMenuItem>
    <DownloadDigitalAssetMenuItem
      :mouseover="mouseover"
      :keydown="keydown"
      :click-capture="clickCapture"
      title="full resolution"
      :subtitle="subtitle"
      pizzazz-border
      @click="() => mediaEditorStore.setShowPaywallDialog(true)"
    >
      <template #right-col>
        <SoonaFlag background-color="transparent" type="pill" is-pizzazz>
          <template #icon-left>
            <SoonaIcon name="crown" size="small" class="crown" />
          </template>
        </SoonaFlag>
      </template>
    </DownloadDigitalAssetMenuItem>
  </template>
</template>

<style scoped lang="scss">
@use '@/variables';

.free {
  text-transform: uppercase;
  color: variables.$gray-60;
}

.crown {
  color: variables.$white-default;
}
</style>

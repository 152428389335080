<script setup>
import { computed, ref, toRefs } from 'vue';
import ListingCard from '@/components/user/anytime/listing_insights/listing_card/ListingCard.vue';
import { useGetVariantListings } from '@/queries/useListings';
import ListingsListLoadingState from '@/components/user/anytime/listing_insights/ListingsListLoadingState.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';

const props = defineProps({
  listingData: {
    type: Object,
    required: true,
  },
  platform: { type: String, required: true },
});

defineEmits(['refreshListing']);

const { listingData } = toRefs(props);
const accountId = computed(() => listingData.value.account_id);
const listingId = computed(() => listingData.value.listing_id);

const itemsPerPage = ref(10);
const currentPage = ref(1);

const {
  data: listingsData,
  error: listingsError,
  isLoading,
} = useGetVariantListings(accountId, listingId, { itemsPerPage, currentPage });

const listings = computed(() => listingsData.value?.listings || []);

const listingsPagination = computed(() =>
  listingsData.value
    ? listingsData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

const priorityError = usePriorityError(listingsError);
</script>

<template>
  <div class="variant-listings">
    <div class="variant-listings__header">
      <h2 class="u-headline--heavy">variant listings</h2>
    </div>
    <SoonaError v-if="priorityError" no-margin>
      {{ priorityError }}
    </SoonaError>
    <div v-if="isLoading" class="variant-listings__loading">
      <ListingsListLoadingState />
    </div>
    <div class="variant-listings__list--wrapper">
      <TransitionGroup name="listing" tag="div" class="variant-listings__list">
        <template v-for="listing in listings" :key="listing.listing_id">
          <ListingCard
            :account-id="accountId"
            :integration-id="listing.integration_id"
            :listing="listing"
          />
        </template>
      </TransitionGroup>
      <PaginatorFull
        v-model:page="currentPage"
        v-model:records-per-page="itemsPerPage"
        class="variant-listings__paginator"
        :total-pages="listingsPagination.totalPages"
        :total-count="listingsPagination.totalCount"
        :page-count="listings.length"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.variant-listings {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  &__header {
    margin-top: 1rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;

    &--wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

// transitions

/* base */
.listing {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.listing-move {
  transition: all 0.4s ease;
}

/* appearing */
.listing-enter-active {
  transition: all 0.3s ease-out;
}

/* disappearing */
.listing-leave-active {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disppear to */
.listing-enter-from,
.listing-leave-to {
  opacity: 0;
}
</style>

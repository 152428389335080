import { http } from '@/config/vue-axios';
import { useMutation } from '@tanstack/vue-query';

/**
 * @returns {UseMutationReturnType<any, unknown, void, unknown>}
 */
export function useSaveThread() {
  return useMutation({
    mutationFn: async threadId => {
      const response = await http.post('/assistants/save_thread', {
        thread_id: threadId,
      });
      return response.data;
    },
  });
}

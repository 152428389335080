<script setup>
import { computed } from 'vue';
import SoonaSortable from '@/components/SoonaSortable.vue';
import SceneCard from '@/components/user/anytime/shotlist/SceneCard.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCapability } from '@/composables/useCapability';

const props = defineProps({
  isEditable: {
    type: Boolean,
    default: false,
  },
  isPack: {
    type: Boolean,
    default: false,
  },
  reservationAccountId: {
    required: true,
    type: [Number, String],
  },
  reservationId: {
    required: true,
    type: [Number, String],
  },
  submittedAt: {
    default: null,
    type: String,
  },
});

const emit = defineEmits(['duplicate']);

const scenes = defineModel('scenes', { type: Array, required: true });

const { hasCapability: canEditAnyClientShotList } = useCapability({
  capability: 'edit_any_client_shot_list',
});

const reorderEnabled = computed(() => {
  const crewEnabled = scenes.value.length > 1 && !props.isPack;
  const userEnabled = crewEnabled && props.isEditable;

  return canEditAnyClientShotList.value ? crewEnabled : userEnabled;
});

const dragOptions = computed(() => {
  return {
    // selector to the wrapper element in order to bind events to
    draggable: '.draggable-scene-card-wrapper',
    handle: '.draggable-scene-card-handle',
    animation: 150,
    disabled: !reorderEnabled.value,
    ghostClass: 'draggable-scene-card-ghost',
  };
});

function onDragUpdate(event) {
  const item = scenes.value.at(event.oldIndex);

  const newShotList = scenes.value.slice();
  newShotList.splice(event.oldIndex, 1);
  newShotList.splice(event.newIndex, 0, item);

  scenes.value = newShotList;
}
</script>

<template>
  <SoonaSortable
    :item-key="scene => scene.id"
    :list="scenes"
    :options="dragOptions"
    class="shot-list-scenes"
    data-cypress="shot-list-card"
    @update="onDragUpdate"
  >
    <template #item="{ element: scene }">
      <div :key="scene.id" class="draggable-scene-card-wrapper">
        <SceneCard
          :reservation-account-id="reservationAccountId"
          :reservation-id="reservationId"
          :scene="scene"
          :is-editable="isEditable"
          :submitted-at="submittedAt"
          :is-pack="isPack"
          @duplicate="emit('duplicate', scene)"
        >
          <template v-if="reorderEnabled" #heading-start>
            <SoonaIcon
              name="grip-dots-vertical"
              class="draggable-scene-card-handle"
              aria-hidden="false"
              aria-label="drag to reorder"
            />
          </template>
        </SceneCard>
      </div>
    </template>
  </SoonaSortable>
</template>

<style lang="scss" scoped>
@use '@/variables';

.shot-list-scenes {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.draggable-scene-card-ghost > * {
  background-color: variables.$bubbletape-pink-10;
}

.draggable-scene-card-handle {
  cursor: grab;
  color: variables.$gray-60;
}

[draggable='true'] .draggable-scene-card-handle {
  cursor: grabbing;
}

[data-sortable-disabled='true'] .draggable-scene-card-handle {
  cursor: not-allowed;
}
</style>

import { computed } from 'vue';
import { useReservation } from './useReservation';

export function usePackTypeforms(reservationId) {
  const testPacks = [
    {
      name: 'Amazon A+ premium pack',
      typeformUrl: 'https://aeoybcf2761.typeform.com/Aplusprepro',
    },
    {
      name: 'video ads pack',
      typeformUrl: 'https://aeoybcf2761.typeform.com/videoadspack',
    },
    {
      name: 'photo + video full body model pack',
      typeformUrl: 'https://aeoybcf2761.typeform.com/photovideopack',
    },
    {
      name: 'photo + video hand model pack',
      typeformUrl: 'https://aeoybcf2761.typeform.com/photovideopack',
    },
    {
      name: 'Meta ads UGC',
      typeformUrl: 'https://aeoybcf2761.typeform.com/METAUGC',
    },
    {
      name: 'Meta ads sales and promos',
      typeformUrl: 'https://aeoybcf2761.typeform.com/SALESPROMOS',
    },
    {
      name: 'Meta ads customer reviews',
      typeformUrl: 'https://aeoybcf2761.typeform.com/reviews',
    },
  ];

  const { packDetails } = useReservation(reservationId);

  const isPackTest = computed(() => {
    if (!packDetails.value) return false;

    return testPacks.some(pack => pack.name === packDetails.value?.name);
  });

  const typeformUrl = computed(() => {
    if (!packDetails.value) return null;

    return testPacks.find(pack => pack.name === packDetails.value?.name)
      ?.typeformUrl;
  });

  return {
    isPackTest,
    typeformUrl,
  };
}

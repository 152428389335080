<script setup>
import { ref, computed } from 'vue';
import SoonaSuccess from '@/components/ui_library/SoonaSuccess.vue';
import ShareDraft from 'src/components/shared/ShareDraft.vue';
import { useCollaborators } from '@/queries/collaborators/useCollaborators';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  handleModalClose: {
    type: Function,
    required: true,
  },
  reservation: {
    type: Object,
    required: true,
  },
});

const { data: collaborators } = useCollaborators(
  computed(() => props.reservation?.account?.id),
  {
    currentPage: 1,
  },
  { enabled: computed(() => !!props.reservation?.account?.id) }
);

const showShareModal = ref(false);
</script>
<template>
  <div class="saved-draft-success u-container">
    <SoonaSuccess class="saved-draft-success__alert">
      <p class="u-body--heavy">draft saved!</p>
      <p>
        your draft booking has been saved to your account.
        <a :href="`/user/anytime#/account/${props.reservation?.account?.id}`"
          >see my account</a
        >
        <span v-if="collaborators?.collaborators.length > 0">
          or
          <SoonaButton
            variation="tertiary"
            class="share-with-team-button"
            @on-click="() => (showShareModal = true)"
          >
            share this order with team members
          </SoonaButton></span
        >!
      </p>
      <button class="u-button-reset close-button" @click="handleModalClose">
        <SoonaIcon name="xmark" />
        <span class="u-visually-hidden">close</span>
      </button>
    </SoonaSuccess>

    <ShareDraft
      v-if="showShareModal"
      :account="reservation.account"
      :reservation-id="reservation.id"
      @cancel="showShareModal = false"
      @share-success="showShareModal = false"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.saved-draft-success {
  &__alert {
    position: relative;
  }

  .share-with-team-button:hover {
    color: variables.$black-default;
    text-decoration: none;
  }

  .close-button {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
  }
}
</style>

// explanation of params
// https://vimeo.com/908436947/9c11c24020
// https://vimeo.com/:videoId/:additionalParams

/**
 * Returns the videoId and additionalParams for the How It Works video
 * @param {string} section - accepts 'competitiveAnalysis', 'listingInsights', 'mediaEditor', 'sideNav'
 * @returns {object} videoId and additionalParams
 */
export function getHowItWorksVideoBySection(section) {
  switch (section) {
    case 'competitiveAnalysis':
      return {
        videoId: '905400894',
        additionalParams: '&h=41e230ce97',
      };
    case 'listingInsights':
      return {
        videoId: '905400557',
        additionalParams: '&h=1abe93a0be',
      };
    case 'mediaEditor':
      return {
        videoId: '905400105',
        additionalParams: '&h=5b7b36a1b9',
      };
    case 'subscriptionsDialog':
      return {
        videoId: '908436947',
        additionalParams: '&h=9c11c24020',
      };
    default:
      return {
        videoId: '908436947',
        additionalParams: '&h=9c11c24020',
      };
  }
}

export const trendSections = [
  {
    value: 'unboxing',
    display: 'unboxing videos',
    videos: [
      {
        vimeoId: '927283436',
        additionalParams: '&h=a806cdb7e6',
      },
      {
        vimeoId: '927283386',
        additionalParams: '&h=7320ab2a18',
      },
      {
        vimeoId: '927283356',
        additionalParams: '&h=6f009599ee',
      },
      {
        vimeoId: '927283283',
        additionalParams: '&h=2684900ff1',
      },
    ],
  },
  {
    value: 'testimonial',
    display: 'testimonial videos',
    videos: [
      {
        vimeoId: '927283988',
        additionalParams: '&h=91f02cc571',
      },
      {
        vimeoId: '927283954',
        additionalParams: '&h=050f22d9b5',
      },
      {
        vimeoId: '927283821',
        additionalParams: '&h=b4d6cf279d',
      },
      {
        vimeoId: '927283770',
        additionalParams: '&h=bef08eb5bc',
      },
    ],
  },
  {
    value: 'product-in-action',
    display: 'product in action videos',
    videos: [
      {
        vimeoId: '927282161',
        additionalParams: '&h=bfa167a016',
      },
      {
        vimeoId: '927282046',
        additionalParams: '&h=9b02145e26',
      },
      {
        vimeoId: '927282103',
        additionalParams: '&h=875f884e71',
      },
      {
        vimeoId: '927282202',
        additionalParams: '&h=cbe0374a35',
      },
    ],
  },
];

export const trendHeader1 = {
  vimeoId: '927617414',
  additionalParams: '&h=f5e042ed5f',
};

export const trendHeader2 = {
  vimeoId: '927617353',
  additionalParams: '&h=3ed62429ff',
};

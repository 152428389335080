<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useUpdateIntegration } from '@/queries/useUpdateIntegration';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useIntegrations } from '@/composables/useIntegrations';
import { useAccount } from '@/composables/useAccount';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  isSubscriptionCharge: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['setShopifyPaymentStoreDomain']);

const accountId = computed(() => props.accountId);
const { activeShopifyIntegrations } = useIntegrations(accountId);
const { mutate } = useUpdateIntegration(accountId);

const shopifyIntegrationOptions = computed(() => {
  return activeShopifyIntegrations.value.map(integration => ({
    label: integration.shop_name,
    value: integration.id,
  }));
});

const primaryShopifyIntegrationId = ref(undefined);

// shopify subx payment store
const { account } = useAccount(accountId);

const shopifyDomainLookup = integrationId => {
  return activeShopifyIntegrations.value.find(
    integration => integration.id === integrationId
  )?.external_provider_id;
};

const primaryShop = computed(() => {
  return activeShopifyIntegrations.value.find(
    integration => integration.primary
  )?.id;
});

const shopifyPaymentIntegrationId = computed(() => {
  return account.value?.subscription?.shopify?.integration_id ?? undefined;
});

watchEffect(() => {
  if (props.isSubscriptionCharge) {
    primaryShopifyIntegrationId.value =
      shopifyPaymentIntegrationId.value ?? primaryShop.value;
  } else {
    primaryShopifyIntegrationId.value = primaryShop.value;
  }
  emits(
    'setShopifyPaymentStoreDomain',
    shopifyDomainLookup(shopifyPaymentIntegrationId.value) ??
      shopifyDomainLookup(primaryShopifyIntegrationId.value)
  );
});

const updatePrimary = integrationId => {
  primaryShopifyIntegrationId.value = integrationId;

  mutate({
    id: integrationId,
    primary: true,
  });
  emits('setShopifyPaymentStoreDomain', shopifyDomainLookup(integrationId));
};
</script>
<template>
  <div
    v-if="activeShopifyIntegrations.length > 0"
    class="shopify-integration-select-container"
  >
    <SoonaIcon name="shopify-logo" size="3x-large" />
    <SoonaSelect
      v-model="primaryShopifyIntegrationId"
      class="shopify-integration-select"
      :options="shopifyIntegrationOptions"
      :disabled="isSubscriptionCharge && !!shopifyPaymentIntegrationId"
      @update:model-value="val => updatePrimary(val)"
    >
      <template #label>{{ label }}</template>
    </SoonaSelect>
  </div>
</template>
<style scoped lang="scss">
.shopify-integration-select-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
</style>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {Ref<string | number>} accountId
 * @param {Object} [params]
 * @param {Object} [queryOptions]
 */
export function useGetAccountsOrder(accountId, { orderId } = {}, queryOptions) {
  return useQuery({
    queryKey: queryKeys.accountOrder(accountId, { orderId }),
    queryFn: async ({ signal }) => {
      const params = { order_id: toValue(orderId) };
      const response = await http.get(`accounts/${toValue(accountId)}/order`, {
        params,
        signal,
      });

      return response.data;
    },
    ...queryOptions,
  });
}

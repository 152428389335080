<script setup>
import { computed, toRef } from 'vue';
import BookingLayout from '../BookingLayout.vue';
import SoonaClickableCard from 'src/components/booking/v3/p2/SoonaClickableCard.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useReservation } from '@/composables/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapability } from '@/composables/useCapability';

// props and data
const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const {
  data: studioBuyOutData,
  status,
  error: resTagsError,
} = useReservationTags(toRef(props, 'reservationId'), toRef(props, 'stepName'));

const studioBuyOutOptions = computed(() => {
  return studioBuyOutData.value?.options || [];
});

const { reservation, requiredTagIds, isLoading, error } = useReservation(
  toRef(props, 'reservationId')
);

const { itemSelected } = useBaseEvents();

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(toRef(props, 'reservationId'));

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(toRef(props, 'reservationId'), {
  // skip invalidation so that we only update the reservation after the add has finished
  skipInvalidate: true,
});

const priorityError = usePriorityError(
  removeError,
  addError,
  resTagsError,
  error
);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

// computed
const isBusy = computed(() => {
  return isLoading.value || isAdding.value || isRemoving.value;
});

const currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});

const isStepValid = computed(() => {
  return !isBusy.value && currentStep.value?.complete;
});

const buyOutRequired = computed(() => {
  const buyOutTagIds = studioBuyOutOptions.value.map(option => option.tag.id);
  return (
    requiredTagIds.value.some(rtId => buyOutTagIds.includes(rtId)) &&
    !isFtSoonaStaff.value
  );
});

// methods
function addSelectedOption(selectedOption) {
  addReservationTag(
    { tagId: selectedOption.tag.id },
    {
      onSuccess: () => {
        itemSelected({
          context: 'booking',
          subContext: 'audio',
          // product property only exists on studio buy-out option (e.g., 'no audio needed here' is not actually a product or line item)
          itemId: selectedOption.product?.id,
          itemLabel: selectedOption.tag.title,
          itemPrice: selectedOption.tag.price_description,
          itemQuantity: 1,
        });
      },
    }
  );
}

function onOptionChange(selectedOption) {
  const existingSelected = studioBuyOutOptions.value.find(
    option => option.selected && option.tag.id !== selectedOption.tag.id
  );

  if (existingSelected) {
    removeReservationTag(
      {
        reservationTagId: existingSelected.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSuccess: () => {
          addSelectedOption(selectedOption);
        },
      }
    );
  } else {
    addSelectedOption(selectedOption);
  }
}

function onBackClick() {
  props.onBack();
}

function onNextClick() {
  if (!isStepValid.value) {
    addReservationTag(
      { tagId: studioBuyOutData.value.skippable_tag_id },
      {
        onSuccess: () => {
          props.onNext();
        },
      }
    );
  } else {
    props.onNext();
  }
}
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="studio-buy-out prebooking-transition__content">
        <SoonaLoading
          v-if="!reservation"
          :is-loading="!reservation"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading>
          do you need audio recorded during your shoot?
          <template #subtitle>
            we require a studio buy-out for any video shoots with audio
            recordings. we’ll clear the studio so it’s distraction-free!
          </template>
        </SoonaHeading>
        <div v-if="status === 'success'" class="studio-buy-out__actions">
          <SoonaClickableCard
            v-for="option in studioBuyOutOptions"
            :key="option.id"
            :card-data="option"
            :is-selected="option.selected"
            :on-card-click="onOptionChange"
            :title="option.tag.description"
            :is-disabled="buyOutRequired"
          >
            <template #price-point>
              {{ option.tag.price_description }}
            </template>
          </SoonaClickableCard>
        </div>
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.studio-buy-out {
  &__actions {
    display: grid;
    gap: 1.0625rem;
    grid-template-rows: repeat(2, 1fr);

    @media (min-width: variables.$screen-sm-min) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
    }
  }
}
</style>

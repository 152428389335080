<template>
  <div class="shipping">
    <div class="column is-centered soona-container">
      <div class="columns is-centered">
        <div class="column is-half">
          <h2 class="title has-text-centered is-size-4-mobile">
            we’re almost ready!
          </h2>

          <!-- customer address -->

          <p class="soona-title-a1 mb-m"><b>SHIP TO:</b></p>

          <div class="location-address-container mb-l">
            <p class="is-size-7">{{ currentShipment.address_to.name }}</p>
            <p class="is-size-7">
              {{
                [
                  currentShipment.address_to.street1,
                  currentShipment.address_to.street2,
                ]
                  .join(' ')
                  .replace(/\s?$/, '')
              }}, {{ currentShipment.address_to.city }},
              {{ currentShipment.address_to.state }}
              {{ currentShipment.address_to.zip }}
            </p>
          </div>

          <!-- order summary -->

          <p class="soona-title-a1 mb-m"><b>ORDER CONFIRMATION:</b></p>

          <div
            v-for="(rate, index) in selectedRates"
            :key="rate.id"
            class="shipping-form-container is-size-7 mb-s"
          >
            <PackageSummary
              :rate="rate"
              :inventory-package="returnablePackages[index]"
              :index="index"
              :total-packages="returnablePackages.length"
            ></PackageSummary>
          </div>

          <!-- total cost of shipping -->

          <p class="mt-l is-size-7 mb-m">
            <b class="soona-title-a1">TOTAL:</b> ${{ totalAmountDue }}
          </p>

          <!-- payment info -->
          <div
            v-if="canChargeOnBehalfOfCustomer"
            class="checkout-btn-wrapper buttons"
          >
            <SoonaButton
              @on-click="() => submitPaymentClicked(null, null, false)"
            >
              checkout
            </SoonaButton>
          </div>
          <template v-else>
            <h2 class="u-title--heavy payment-info-title">payment info</h2>
            <SoonaPayment
              :on-payment-action="submitPaymentClicked"
              action-text="checkout"
              stripe-payment-required
            >
              <template #action-block>
                <div class="soona-refund-policy">
                  <p class="is-size-7">
                    shipping fees are non-refundable. please review our
                    <a
                      class="soona-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.soona.co/refund-policy"
                    >
                      cancellation and refund policy
                    </a>
                    for more information.
                  </p>
                </div>
              </template>
            </SoonaPayment>
          </template>
          <div v-if="errors" class="soona-errors" data-cypress="errors">
            <p
              v-for="(error, index) in getErrorMessages"
              :key="index"
              class="is-size-7"
            >
              {{ error }}
            </p>
          </div>

          <div class="buttons is-centered mt-m">
            <SoonaButton variation="tertiary" @on-click="onCancel">
              cancel
            </SoonaButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import PackageSummary from './PackageSummary.vue';
import SoonaPayment from 'src/components/SoonaPayment.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

export default {
  name: 'ReturnPayment',
  components: {
    SoonaPayment,
    PackageSummary,
    SoonaButton,
  },
  setup() {
    const { hasCapability: isFtSoonaStaff } = useCapability({
      capability: 'ft_soona_staff',
    });

    return { isFtSoonaStaff };
  },
  computed: {
    ...mapState({
      currentShipment: state => state.reservation.currentShipment,
      currentReservation: state => state.reservation.currentReservation,
      selectedRates: state => state.reservation.selectedRates,
      errors: state => state.errors.errors,
    }),
    ...mapGetters('errors', ['getErrorMessages']),
    ...mapGetters('inventoryPackages', ['returnablePackages']),
    ...mapGetters('currentUser', ['currentAccount']),
    canChargeOnBehalfOfCustomer() {
      // if a staff member for some reason has inventory on their account, they can
      // just pay for it. Otherwise, we let them charge on behalf of the customer.
      return (
        this.returnablePackages[0].account_id != this.currentAccount.id &&
        this.isFtSoonaStaff
      );
    },
    totalAmountDue() {
      var sum = this.selectedRates.reduce(function (total, rate) {
        return total + parseFloat(rate.amount);
      }, 0);

      return sum.toFixed(2);
    },
  },
  methods: {
    ...mapActions('reservation', ['submitPayment']),
    ...mapActions('inventoryPackages', ['updatePackage']),
    async submitPaymentClicked(stripe, card, saveCard) {
      let packagesRemaining = this.returnablePackages.length - 1;

      for (const [index, rate] of this.selectedRates.entries()) {
        let shippoParcel = {
          width: this.returnablePackages[index].width,
          height: this.returnablePackages[index].height,
          length: this.returnablePackages[index].length,
          weight: this.returnablePackages[index].weight,
          mass_unit: 'lb',
          distance_unit: 'in',
        };

        await this.submitPayment({
          stripe: stripe,
          card: card,
          save_card: saveCard,
          reservationId: this.returnablePackages[index].latest_reservation.id,
          shipping_amount: rate.amount,
          shipping_details: {
            rate_id: rate.rate_id,
            shipping_provider: rate.provider,
            customer_address: this.currentShipment.address_to,
            parcels: shippoParcel,
            inventory_package_id: this.returnablePackages[index].id,
            shipping_direction: 'outbound',
          },
          packagesRemaining: packagesRemaining - index,
        });
      }
    },
    overrideDurationTerms(terms) {
      if (
        terms ===
        'Delivery within 1, 2, or 3 days based on where your package started and where it’s being sent.'
      ) {
        return 'delivery guaranteed within 3 business days based on your location';
      } else {
        return terms;
      }
    },
    onCancel() {
      this.$router.push({
        name: 'inventory',
        params: { accountId: this.currentAccount.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.payment-info-title {
  margin-bottom: 1.5rem;
  text-align: center;
}

.soona-refund-policy {
  background-color: variables.$gray-10;
  border-radius: variables.$control-radius;
  padding: 1rem;
}

.checkout-btn-wrapper {
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}
</style>

<script setup>
import { computed, ref, toRef } from 'vue';
import faqData from './faq.json';
import BookingLayout from '../BookingLayout.vue';
import ScenesContainer from 'src/components/booking/v3/p2/ScenesContainer.vue';
import SelectedOptions from 'src/components/booking/v3/p2/photo-scenes/SelectedOptions.vue';
import TagRequirementDialog from '@/components/booking/v3/p2/TagRequirementDialog.vue';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import SoonaFilter from 'src/components/shared/SoonaFilter.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useReservation } from '@/composables/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useRoute } from 'vue-router';

// props and data
const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});
const route = useRoute();
const { linkClicked, itemSelected, filterSelected } = useBaseEvents();

const ALL_SCENES = 'all scenes';
const FLAT_BACKDROP = 'flat backdrop';
const LIFESTYLE = 'lifestyle';
const TREND_SETS = 'seasonal & trending';
const selectedFilter = ref(ALL_SCENES);
let buttonsLoading = ref([]);

const { reservation, isLoading, error } = useReservation(
  toRef(props, 'reservationId')
);

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(toRef(props, 'reservationId'));

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(toRef(props, 'reservationId'));

const { data: lifeStyleOptionsData } = useReservationTags(
  toRef(props, 'reservationId'),
  ref('scenes'),
  ref('lifestyle')
);

const lifeStyleOptions = computed(
  () => lifeStyleOptionsData.value?.options || []
);

// api calls
const { data: flatBackdropOptionsData, error: resTagsError } =
  useReservationTags(
    toRef(props, 'reservationId'),
    ref('scenes'),
    ref('flat_backdrop')
  );

const priorityError = usePriorityError(
  removeError,
  addError,
  resTagsError,
  error
);

// computed
const flatBackdropOptions = computed(
  () => flatBackdropOptionsData.value?.options || []
);

const { data: trendSetOptionsData } = useReservationTags(
  toRef(props, 'reservationId'),
  ref('trend-sets')
);

const trendSetOptions = computed(
  () => trendSetOptionsData.value?.options || []
);

const isBusy = computed(() => {
  return isLoading.value || isAdding.value || isRemoving.value;
});

const showFlatBackdrop = computed(() => {
  return (
    selectedFilter.value === ALL_SCENES ||
    selectedFilter.value === FLAT_BACKDROP
  );
});
const showLifeStyle = computed(() => {
  return (
    selectedFilter.value === ALL_SCENES || selectedFilter.value === LIFESTYLE
  );
});
const displayTrendSetOptions = computed(() => {
  if (selectedFilter.value === ALL_SCENES) {
    return trendSetOptions.value;
  } else {
    return trendSetOptions.value.filter(
      tso => tso.tag.filter_names === selectedFilter.value
    );
  }
});
const filters = computed(() => {
  const subfilter = new Set(
    trendSetOptions.value?.map(ts => ts.tag.filter_names).filter(ts => !!ts)
  );

  let filterList = [ALL_SCENES, FLAT_BACKDROP, LIFESTYLE];

  if (subfilter.size > 0) {
    filterList.push({ main: TREND_SETS, subfilter: subfilter });
  }

  return filterList;
});

const selectedOptions = computed(() => {
  return [
    ...flatBackdropOptions.value,
    ...lifeStyleOptions.value,
    ...trendSetOptions.value,
  ].filter(o => o.selected);
});
const hasReachedMaxSelected = computed(() => {
  return selectedOptions.value?.length >= 2;
});

let currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});

const isStepValid = computed(() => {
  return !isBusy.value && currentStep.value?.complete;
});

const isTagRequirementDialogOpen = ref(false);
const tagRequirement = ref({});
const currentOption = ref({});

function removeFromButtonList(tag_id) {
  buttonsLoading.value = buttonsLoading.value?.filter(o => o !== tag_id);
}

function addOrRemoveSelection({
  option,
  addRecommended = false,
  trackItemSelected = true,
}) {
  const beingSelected = !option.selected;
  if (beingSelected) {
    addReservationTag(
      { tagId: option.tag.id, addRecommended: addRecommended },
      {
        onSettled: () => removeFromButtonList(option.tag.id),
        onSuccess: () => {
          if (trackItemSelected) {
            itemSelected({
              context: 'booking',
              subContext: 'photo scenes page',
              itemLabel: option?.tag?.title,
              itemPrice: 0,
              itemQuantity: 1,
            });
          }
        },
      }
    );
  } else {
    removeReservationTag(
      {
        reservationTagId: option.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSettled: () => removeFromButtonList(option.tag.id),
      }
    );
  }
}

function handleOpenTagRequirementDialog(option, requirement) {
  tagRequirement.value = requirement;
  currentOption.value = option;
  isTagRequirementDialogOpen.value = true;
}

function handleCloseTagRequirementDialog() {
  removeFromButtonList(currentOption.value.tag.id);
  tagRequirement.value = {};
  currentOption.value = {};
  isTagRequirementDialogOpen.value = false;
}

function addTagsToReservation(addRecommended = true) {
  addOrRemoveSelection({
    option: currentOption.value,
    addRecommended: addRecommended,
    trackItemSelected: false,
  });
  isTagRequirementDialogOpen.value = false;
  currentOption.value = {};
}

function addSelectedTagToReservation() {
  addTagsToReservation(false);
}

// methods
function onSelectionChange(option) {
  const tagRequirement = option.tag.tag_requirements.length
    ? option.tag.tag_requirements[0]
    : false;

  if (tagRequirement && !option.selected) {
    linkClicked({
      context: 'booking',
      subContext: 'video scenes page',
      linkLabel: option?.tag?.title,
      linkHref: '#add-to-shoot',
    });
    handleOpenTagRequirementDialog(option, tagRequirement);
  } else {
    addOrRemoveSelection({ option: option });
  }
}

const handleFilterChange = option => {
  selectedFilter.value = option;

  filterSelected({
    context: 'booking',
    subContext: route.meta.page_title,
    filterCategory: '',
    filterSubcategory: '',
    filterLabel: option,
  });
};

const onBackClick = () => {
  props.onBack();
};

const onNextClick = () => {
  props.onNext();
};

const updateButtonList = id => {
  if (buttonsLoading.value?.includes(id) || isBusy.value) return;

  buttonsLoading.value = [...buttonsLoading.value, id];
};
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="booking-scenes prebooking-transition__content">
        <SoonaLoading
          v-if="!reservation"
          :is-loading="!reservation"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading title-element="h1" title-copy="scenes">
          choose your scenes
          <template #subtitle>
            pick 1 to 2 scenes for this shoot. you can customize the details
            after you complete your booking in our shot list builder.
          </template>
        </SoonaHeading>
        <SoonaFilter
          class="booking-scenes__filters"
          :options="filters"
          :selected-filter="selectedFilter"
          :on-change="handleFilterChange"
        />
        <SelectedOptions
          :selected-options="selectedOptions"
          :on-click="onSelectionChange"
        />
        <ScenesContainer
          v-if="showFlatBackdrop"
          :buttons-loading="buttonsLoading"
          title="flat backdrop"
          :options="flatBackdropOptions"
          :on-click="onSelectionChange"
          :is-mutating="isBusy"
          :has-reached-max-selected="hasReachedMaxSelected"
          @update-button-list="updateButtonList"
        />
        <ScenesContainer
          v-if="showLifeStyle"
          :buttons-loading="buttonsLoading"
          title="lifestyle"
          :options="lifeStyleOptions"
          :on-click="onSelectionChange"
          :is-mutating="isBusy"
          :has-reached-max-selected="hasReachedMaxSelected"
          @update-button-list="updateButtonList"
        />
        <ScenesContainer
          v-if="displayTrendSetOptions && displayTrendSetOptions.length > 0"
          :buttons-loading="buttonsLoading"
          title="seasonal & trending"
          :options="displayTrendSetOptions"
          :on-click="onSelectionChange"
          :is-mutating="isBusy"
          :has-reached-max-selected="hasReachedMaxSelected"
          @update-button-list="updateButtonList"
        />
        <SoonaFaq :faq-tab-list="faqData" />
        <TagRequirementDialog
          v-if="isTagRequirementDialogOpen"
          :reservation-id="reservationId"
          :tag-requirement="tagRequirement"
          segment-context="booking"
          segment-sub-context="photo scenes page"
          @cancel-clicked="handleCloseTagRequirementDialog"
          @confirm-clicked="addTagsToReservation"
          @secondary-confirm-clicked="addSelectedTagToReservation"
        ></TagRequirementDialog>
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.booking-scenes {
  color: variables.$black-default;

  &__filters {
    padding-bottom: 1rem;
  }

  &__scenes-wrapper {
    border-top: 0.0625rem solid variables.$gray-30;
    padding-top: 0.5rem;
    width: 100%;
  }

  &__category-title {
    @include variables_fonts.u-label--heavy;

    padding-bottom: 1.5rem;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-body--heavy;
    }
  }

  &__grid {
    display: grid;
    gap: 1.5rem 1rem;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 2rem;

    @media (min-width: variables.$screen-sm-min) {
      gap: 1.5rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__bed-stylist-required {
    display: flex;
    align-items: center;

    svg {
      flex: 0 0 1.75rem;
      margin-right: 0.5rem;
    }
  }
}
</style>

import { http } from '@/config/vue-axios';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { toValue } from 'vue';

export function useMokkerChildGenerations(
  assetUuid,
  { taskType = undefined },
  queryOptions
) {
  const params = {};
  if (taskType) {
    params['task_type'] = taskType;
  }

  return useQuery({
    queryKey: queryKeys.mokkerChildGenerations(assetUuid),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/mokker_digital_assets/${toValue(assetUuid)}/child_generations`,
        params,
        {
          signal,
        }
      );
      return response.data;
    },
    ...queryOptions,
  });
}

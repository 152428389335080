<script setup>
import { ref, toRefs } from 'vue';
import RequirementTypeDisplay from './RequirementTypeDisplay.vue';
import RequirementForm from './RequirementForm.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useCreateAccountRequirement } from '@/queries/account-requirements/useCreateAccountRequirement';
import { usePriorityErrors } from '@/composables/usePriorityErrors';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  requirementType: {
    type: String,
    required: true,
  },
});

const { accountId, requirementType } = toRefs(props);

const createMode = ref(false);

const {
  mutate: createMutate,
  isPending: createIsPending,
  error: createError,
} = useCreateAccountRequirement(accountId);

const priorityErrors = usePriorityErrors(createError);

function createSubmit(e) {
  const data = new FormData(e.target);

  createMutate(data);
}
</script>

<template>
  <div class="requirement-empty" :data-create-mode="createMode">
    <div class="requirement-empty__heading">
      <RequirementTypeDisplay :requirement-type="requirementType" />
      <SoonaButton
        v-if="!createMode"
        variation="solid-black"
        size="medium"
        @on-click="createMode = true"
      >
        add
      </SoonaButton>
    </div>

    <div v-if="createMode" class="requirement-empty__create-form">
      <RequirementForm
        :priority-errors="priorityErrors"
        :loading="createIsPending"
        :requirement-type="requirementType"
        @submit="createSubmit"
        @cancel="createMode = false"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.requirement-empty {
  background: variables.$gray-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;

  &[data-create-mode='true'] {
    background: variables.$white-default;

    .requirement-empty__heading {
      border-bottom: 0.0625rem solid variables.$gray-30;
    }
  }

  &__heading {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    padding: 1.25rem;
  }

  &__create-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
  }
}
</style>

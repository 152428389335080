import { useQuery } from '@tanstack/vue-query';
import { getQuest } from '@/api/quests';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

/**
 *
 * @param {number | Ref<number>} questId
 * @param {Object} [queryOptions]
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useGetQuest(questId, queryOptions = null) {
  return useQuery({
    queryKey: queryKeys.quest(questId),
    queryFn: () => getQuest(unref(questId)),
    ...queryOptions,
  });
}

<script setup>
import { computed, watchEffect, ref } from 'vue';
import { usePhoneHelper } from 'src/composables/usePhoneHelper';
const props = defineProps({
  phoneNumber: {
    default: undefined,
    required: true,
    validator: value => {
      return value === null || value === undefined || typeof value === 'string';
    },
  },
});
const { numberWithCountryCode } = usePhoneHelper();
const formattedPhoneNumber = ref('');

const label = computed(() => {
  return props.phoneNumber?.split('').join(' ');
});

watchEffect(async () => {
  if (props.phoneNumber) {
    formattedPhoneNumber.value = await numberWithCountryCode(props.phoneNumber);
  } else {
    formattedPhoneNumber.value = '';
  }
});
</script>
<template>
  <span v-if="formattedPhoneNumber" :aria-label="label">{{
    formattedPhoneNumber
  }}</span>
</template>

<script setup>
import { computed } from 'vue';
import uniqueId from 'lodash/uniqueId';
import { toCurrency } from '@/lib/currency';

const props = defineProps({
  cypressName: {
    default: 'plan-select',
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  label: String,
  loading: {
    default: false,
    type: Boolean,
  },
  modelValue: null, // null will match any prop type
  options: {
    required: true,
    type: Array,
  },
  placeholder: String,
  reduce: {
    type: Function,
    default: ({ value }) => value,
  },
  selectable: Function,
});

const emits = defineEmits([
  'update:modelValue',
  'option:selecting',
  'option:deselecting',
]);

const inputId = uniqueId('plan-select-');

const disabled = computed(() => props.disabled);
const loading = computed(() => props.loading);
const modelValue = computed(() => props.modelValue);

const selected = computed({
  get() {
    return modelValue.value;
  },
  set(val) {
    emits('update:modelValue', val);
  },
});
</script>

<template>
  <div class="plan-select-wrapper">
    <v-select
      v-model:model-value="selected"
      class="plan-select-input"
      :clearable="false"
      :data-cypress="cypressName"
      :disabled="disabled"
      :input-id="inputId"
      :label="label"
      :loading="loading"
      :options="options"
      :placeholder="placeholder"
      :reduce="reduce"
      :searchable="false"
      :selectable="selectable"
      append-to-body
      @option:selecting="emits('option:selecting', $event)"
      @option:deselecting="emits('option:deselecting', $event)"
    >
      <template #selected-option="option">
        <span class="selected-item">
          <div class="badge-left">
            <p v-if="option.value?.save_badge" class="u-label--heavy badge">
              save {{ option.value.save_badge }}
            </p>
            <p class="u-body--heavy label" data-cypress="plan-select-label">
              {{ label ? option[label] : option.label }}
            </p>
          </div>
          <div class="badge-right">
            <p class="u-badge--regular total-label">total</p>
            <p class="u-body--heavy total">
              {{
                option.value?.price
                  ? toCurrency(option.value.price, 'USD', 0)
                  : option.value?.recurring_price
              }}
            </p>
          </div>
        </span>
      </template>
      <template #option="option">
        <span class="selection-item">
          <div class="badge-left">
            <p v-if="option.value?.save_badge" class="u-label--heavy badge">
              save {{ option.value.save_badge }}
            </p>
            <p class="u-body--heavy label">
              {{ label ? option[label] : option.label }}
            </p>
          </div>
          <div class="badge-right">
            <p class="u-badge--regular total-label">total</p>
            <p class="u-body--heavy total">
              {{
                option.value?.price
                  ? toCurrency(option.value.price, 'USD', 0)
                  : option.value?.recurring_price
              }}
            </p>
          </div>
        </span>
      </template>
      <template #header>
        <div v-if="$slots.label" class="select-label-wrapper">
          <label :for="inputId">
            <slot name="label"></slot>
          </label>
        </div>
        <p v-if="$slots.subtext" class="u-label--regular select-subtext">
          <slot name="subtext" />
        </p>
      </template>
    </v-select>
  </div>
</template>

<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.vs__dropdown-menu {
  .vs__dropdown-option {
    white-space: normal;

    &--highlight {
      background: variables.$friendly-red-10;
      color: variables.$black-default;
    }
  }
}

.plan-select-input {
  .vs__selected {
    width: 100%;
  }

  .vs__search {
    width: max-content;
  }

  .vs__selected + .vs__search {
    position: absolute;
  }

  .selected-item {
    align-items: center;
    display: flex;
    gap: 4rem;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 0.25rem;
    padding-left: 0.75rem;
    padding-top: 0.5rem;
    width: 100%;

    .badge {
      background-color: variables.$periwink-blue-70;
      color: variables.$white-default;
      border-radius: 1rem;
      margin-bottom: 0.25rem;
      padding: 0.0625rem 0.5rem;
      padding-top: 0.1875rem;
      width: max-content;
      text-transform: lowercase;
    }

    .badge-right {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.25rem;
    }

    .total-label {
      color: variables.$black-default;
      text-transform: lowercase;
    }
  }
}

.selection-item {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;

  .badge {
    background-color: variables.$periwink-blue-70;
    color: variables.$white-default;
    border-radius: 1rem;
    margin-bottom: 0.25rem;
    padding: 0.0625rem 0.5rem;
    padding-top: 0.1875rem;
    width: max-content;
    text-transform: lowercase;
  }

  .badge-right {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }

  .total-label {
    color: variables.$black-default;
    text-transform: lowercase;
  }
}

.plan-select-wrapper {
  max-width: 100%;

  .v-select {
    .select-label-wrapper {
      @include variables_fonts.u-label--heavy;

      color: variables.$black-default;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 0.4375rem;

      label {
        padding-top: 0.1875rem;
      }
    }

    .select-subtext {
      color: variables.$gray-60;
      padding-bottom: 0.5rem;
    }

    .vs__dropdown-toggle {
      background-color: variables.$white-default;
      border: 0.0625rem solid variables.$gray-30;
      border-radius: 0.3125rem;
      min-height: 2.5rem;

      &:hover {
        cursor: pointer;
      }

      input {
        cursor: pointer;
      }
    }

    .vs__search::placeholder {
      color: variables.$gray-50;
    }

    .vs__open-indicator {
      color: variables.$black-default;
    }

    .vs__dropdown-menu {
      @include variables_fonts.u-body--regular;

      li {
        padding: 0.5rem 0.75rem;
      }
    }
    // focused state -- when user is interacting with select box
    &.vs--open {
      .vs__dropdown-toggle {
        border: 0.0625rem solid variables.$black-default;
        outline: 0.125rem solid variables.$periwink-blue-60;
      }
    }

    // single select
    &.vs--single.vs--open,
    &.vs--single.vs--loading {
      .vs__selected {
        position: relative;
      }
    }

    &.vs--disabled {
      .vs__dropdown-toggle {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &:not(.vs--disabled):not(.vs--open) .vs__dropdown-toggle:hover {
      border-color: variables.$gray-50;
    }
  }
}
</style>

<template>
  <div class="admin-create-trend-set-modal">
    <SoonaDialog @close="() => closeModal()">
      <template #heading>add a new trend set</template>
      <div class="admin-create-trend-set-modal__field">
        <label for="trend-set-title" class="admin-create-trend-set-modal__label"
          >title</label
        >
        <TextInput
          id="trend-set-title"
          v-model:model-value="trendSet.title"
          type="text"
          :class="{ 'admin-create-trend-set-modal__error': error }"
          placeholder="name this trend set"
          data-cypress="input-trend-set-title"
        />
        <p
          class="admin-create-trend-set-modal__character-limit"
          :class="trendSetTitleTooLong"
        >
          25 characters max, please
        </p>
        <span v-if="error">{{ error }}</span>
      </div>

      <div class="admin-create-trend-set-modal__field">
        <p class="admin-create-trend-set-modal__label">
          will this trend set live in an existing category filter or are you
          creating a new filter?
        </p>
        <div class="admin-create-trend-set-modal__filter-choices">
          <label for="trend-set-existing-filter">
            <input
              id="trend-set-existing-filter"
              v-model="trendSetFilterChoice"
              type="radio"
              name="trend-set-filter-choices"
              value="existing"
              data-cypress="radio-trend-set-existing-filter"
            />
            existing filter category
          </label>
          <label for="trend-set-new-filter">
            <input
              id="trend-set-new-filter"
              v-model="trendSetFilterChoice"
              type="radio"
              name="trend-set-filter-choices"
              value="new"
              data-cypress="radio-trend-set-new-filter"
            />
            create a new filter category
          </label>
        </div>
      </div>
      <div
        v-if="trendSetFilterChoice === 'existing'"
        class="admin-create-trend-set-modal__field"
      >
        <label
          for="trend-set-filter"
          class="admin-create-trend-set-modal__label"
          >choose category</label
        >
        <SoonaSelect
          id="trend-set-filter"
          v-model:model-value="trendSet.filterName"
          :options="trendSetFilterList"
          placeholder="select"
          :searchable="true"
          :multi="false"
          data-cypress="select-trend-set-filter-name"
        />
      </div>
      <div
        v-if="trendSetFilterChoice === 'new'"
        class="admin-create-trend-set-modal__field"
      >
        <label
          for="trend-set-filter"
          class="admin-create-trend-set-modal__label"
          >create new filter</label
        >
        <TextInput
          id="trend-set-filter"
          v-model:model-value="trendSet.filterName"
          type="text"
          placeholder="filter name"
          :multi="false"
          data-cypress="input-trend-set-filter-name"
        />
      </div>
      <template #footer="{ close }">
        <SoonaButton
          variation="tertiary"
          data-cypress="button-dialog-cancel"
          @on-click="close"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          :disabled="isDisabled"
          data-cypress="button-dialog-confirm"
          @on-click="() => handleSubmit()"
        >
          add
        </SoonaButton>
      </template>
    </SoonaDialog>
    <SoonaLoading
      loading-text="just a sec...creating your trend set builder fun"
      :is-loading="isSaving"
    />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import * as types from 'src/store/mutation-types';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import TextInput from 'src/components/shared/TextInput.vue';

export default {
  name: 'AdminCreateTrendSetModal',
  components: {
    SoonaButton,
    SoonaDialog,
    SoonaLoading,
    SoonaSelect,
    TextInput,
  },
  props: {
    closeModal: Function,
  },
  data() {
    return {
      error: '',
      isSaving: false,
      trendSet: {
        filterName: '',
        title: '',
      },
      trendSetFilterChoice: 'existing',
      trendSetFilterList: [],
    };
  },
  computed: {
    ...mapState({
      allTrendSets: state => state.trendSetBuilder.allTrendSets,
      trendSetCategoryId: state => state.trendSetBuilder.trendSetCategoryId,
      allTrendSetFilters: state => state.trendSetBuilder.allTrendSetFilters,
    }),
    trendSetTitleTooLong() {
      if (this.trendSet.title?.length > 25) {
        return 'admin-create-trend-set-modal__character-limit--error';
      }
      return '';
    },
    isDisabled() {
      return (
        !(this.trendSet.title.trim() && this.trendSet.filterName) ||
        this.trendSet.title?.length > 25 ||
        this.isSaving
      );
    },
  },
  watch: {
    allTrendSetFilters: {
      handler(val) {
        if (this.trendSetFilterList.length === 0) {
          this.setTrendSetFilterList(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('trendSetBuilder', ['createTrendSet']),
    ...mapMutations('trendSetBuilder', [types.SET_TREND_SET_CATEGORY_ID]),
    getCategoryId() {
      if (this.trendSetCategoryId !== 0) return this.trendSetCategoryId;
      else {
        // some handling just in case id isn't set in vuex store
        const keys = Object.keys(this.allTrendSets);
        let categoryId = 0;
        for (let k = 0; k < keys.length; k++) {
          if (
            Object.hasOwnProperty.call(this.allTrendSets, keys[k]) &&
            this.allTrendSets[keys[k]].length
          ) {
            categoryId = this.allTrendSets[keys[k]][0].tag_category_id;
            break;
          }
        }

        this.SET_TREND_SET_CATEGORY_ID(categoryId);
        return categoryId;
      }
    },
    handleSubmit() {
      this.isSaving = true;

      const info = {
        status: 'draft',
        filter_names: this.trendSet.filterName,
        tag_category: this.getCategoryId(),
        tag_type: 'global',
        title: this.trendSet.title,
      };

      this.createTrendSet(info).then(([response, error]) => {
        if (error) {
          this.error =
            error?.response?.data?.message ||
            `title ${error.response?.data?.title[0]}`;
          this.isSaving = false;
        } else {
          this.$router.push(
            `/admin/trend-sets/${response.tag_category_id}/${response.id}`
          );
        }
      });
    },
    setTrendSetFilterList(filters) {
      for (const filter of filters) {
        this.trendSetFilterList.push({
          value: filter.toLowerCase(),
          label: filter.toLowerCase(),
        });
      }
    },
  },
};
</script>
<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.admin-create-trend-set-modal {
  &__field {
    padding-bottom: 20px;
  }

  &__label {
    @include variables_fonts.u-label--heavy;

    padding-bottom: 4px;
  }

  &__character-limit {
    @include variables_fonts.u-label--regular;

    color: variables.$gray-60;

    &--error {
      color: variables.$roses-60;
    }
  }

  &__error {
    border: 1px solid variables.$roses-40;
    position: relative;

    ~ span {
      @include variables_fonts.u-label--regular;

      display: block;
      padding-left: 25px;
      position: relative;

      &:before {
        border-bottom: 18px solid variables.$roses-60;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        bottom: 1px;
        color: variables.$white-default;
        content: '';
        height: 0;
        left: 0;
        position: absolute;
        width: 0;
      }

      &:after {
        bottom: -1px;
        color: variables.$white-default;
        content: '!';
        font-size: 13px;
        font-weight: 800;
        left: 8px;
        position: absolute;
      }
    }
  }

  &__filter-choices {
    display: flex;
    justify-content: space-between;

    label {
      cursor: pointer;
    }

    input {
      margin-right: 5px;
    }
  }

  .vs__dropdown-menu {
    max-height: 100px;
  }
}
</style>

<template>
  <CompleteProfileCard
    class="connect-two"
    :disabled="disabled"
    @next="saveConnectInfo"
    @skip="skipCard"
  >
    <template #heading>👋 let’s connect!</template>
    <fieldset class="connect-wrapper">
      <legend class="u-visually-hidden">connect with us</legend>
      <SoonaTextfield
        v-model="twitter"
        class="connect-input"
        label="twitter"
        name="twitter"
        placeholder="@"
      />
      <SoonaTextfield
        v-model="pinterest"
        class="connect-input"
        label="pinterest"
        name="pinterest"
        placeholder="pinterest link"
      />
      <SoonaTextfield
        v-model="other"
        class="connect-input"
        label="other"
        name="other"
        placeholder="other links"
      />
    </fieldset>
  </CompleteProfileCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'LetsConnect2',
  components: { CompleteProfileCard, SoonaTextfield },
  data() {
    return {
      twitter: '',
      pinterest: '',
      other: '',
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    disabled() {
      return this.pinterest === '' && this.twitter === '' && this.other === '';
    },
  },
  watch: {
    account: function () {
      this.prefillExistingData();
    },
  },
  mounted() {
    this.prefillExistingData();
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    prefillExistingData() {
      this.twitter = this.account.profile_data.lets_connect_two?.data?.twitter;
      this.pinterest =
        this.account.profile_data.lets_connect_two?.data?.pinterest;
      this.other = this.account.profile_data.lets_connect_two?.data?.other;
    },
    async saveConnectInfo() {
      const info = {
        lets_connect_two: {
          data: {
            twitter: this.twitter,
            pinterest: this.pinterest,
            other: this.other,
          },
          complete: true,
        },
      };
      try {
        await this.updateAccount({ accountParams: info });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(
            new Error('Updating account referral source failed'),
            {
              extra: { error },
            }
          );
        }
      }
    },
    async skipCard() {
      let info = {
        lets_connect_two: { skip: true },
      };
      await this.updateAccount({ accountParams: info });
    },
  },
};
</script>

<style lang="scss" scoped>
.connect-two {
  .connect-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1rem;
  }

  .connect-input {
    padding-bottom: 0;
  }
}
</style>

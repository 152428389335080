<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { Roses30 } from '@/variables.module.scss';

const props = defineProps({
  listingAction: {
    type: Object,
    required: true,
  },
});

const ruleIcon = computed(() => {
  switch (props.listingAction.listing_action_slug) {
    case 'main-image-product-on-white':
      return 'shapes';
    case 'lifestyle':
      return 'house-tree';
    case 'low-resolution':
      return 'ruler';
    default:
      return 'status-dot-small';
  }
});
</script>

<template>
  <div class="listing-action-rule">
    <SoonaIcon :name="ruleIcon" />
    <div class="listing-action-rule__text">
      <div
        v-if="listingAction.listing_action_slug === 'lifestyle'"
        class="listing-action-rule__text--with-flag"
      >
        <p>lifestyle</p>
        <SoonaFlag
          title="add 1 or more"
          type="pill"
          :background-color="Roses30"
        />
      </div>

      <div
        v-else-if="
          listingAction.listing_action_slug === 'main-image-product-on-white'
        "
        class="listing-action-rule__text--with-flag"
      >
        <p>product on white</p>
        <SoonaFlag
          title="required for main image"
          type="pill"
          :background-color="Roses30"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listing-action-rule {
  display: flex;
  gap: 0.5rem;

  &__text {
    dl {
      div {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        dt {
          font-weight: bold;
        }
      }
    }

    &--with-flag {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;

      p {
        font-weight: bold;
      }
    }
  }
}
</style>

<script setup>
import { onMounted } from 'vue';
import { useTitle } from '@vueuse/core';

useTitle('style quiz | soona');

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<template>
  <div class="style-quiz-layout">
    <div class="style-quiz-layout__left">
      <div class="style-quiz-layout__left-inner">
        <slot name="left-column" />
      </div>
    </div>
    <div class="style-quiz-layout__right">
      <div class="style-quiz-layout__right-inner">
        <slot name="right-column" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.style-quiz-layout {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 120rem;
  min-height: 100%;

  &__left {
    padding: 1rem;
    width: 100%;
  }

  &__left-inner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    max-width: 26rem;
  }

  &__right {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    max-height: calc(100vh - var(--app-header-viewport-offset));
    overflow: hidden;
  }

  &__right-inner {
    max-height: 100%;
    width: 100%;
  }

  @media (min-width: variables.$screen-md-min) {
    flex-direction: row;

    &__left {
      flex-grow: 1;
      padding: 3rem;
    }

    &__left-inner {
      margin: 0;
      margin-left: auto;
    }

    &__right {
      align-items: center;
      align-self: stretch;
      overflow-y: auto;
      padding: 0;
    }
  }
}
</style>

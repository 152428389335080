<script setup>
import { computed, ref } from 'vue';
import { useCapability } from '@/composables/useCapability';
import AlbumPicker from '@/components/user/anytime/album_picker/AlbumPicker.vue';
import OverlayDropdown from '@/components/infinite_asset_gallery/OverlayDropdown.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCreateAlbumCollectionDigitalAsset } from '@/queries/album_collection_digital_assets/useCreateAlbumCollectionDigitalAsset';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useUpdateFavoritesCollection } from '@/queries/favorites_collection/useUpdateFavoritesCollection';
import { useRouter } from 'vue-router';
import { useMe } from '@/composables/user/useMe';

const props = defineProps({
  asset: {
    required: true,
    type: Object,
  },
});

const router = useRouter();
// also used in the template to proxy the asset loading from its empty initial state
const accountId = computed(() => props.asset.account_id ?? null);
const displayAlbumPicker = ref(false);

// album
const { mutate: createAlbumCollectionDigitalAsset } =
  useCreateAlbumCollectionDigitalAsset();

const addToAlbum = collectionId => {
  if (!props.asset.id || !accountId.value) return;

  createAlbumCollectionDigitalAsset(
    {
      collectionId: collectionId,
      body: {
        collection_digital_asset: {
          digital_asset_id: props.asset.id,
        },
      },
    },
    {
      onSuccess: () => {
        router.push({
          name: 'account-gallery',
          params: { accountId: accountId.value },
          query: { collection_id: collectionId },
        });
      },
    }
  );
};

// favorites
const { currentUserId } = useMe();

const { hasCapability: hasFavoriteCapability } = useCapability({
  capability: 'favorite_gallery',
  subjectType: 'account',
  subjectId: accountId,
});

const canFavorite = computed(
  () => props.asset.ownership === 'soona' && hasFavoriteCapability.value
);

const { data: favoriteCollection } = useFavoritesCollection(accountId, {
  enabled: computed(() => !!accountId.value && canFavorite.value),
});
const favoritesCollectionId = computed(() => favoriteCollection.value?.id);

const { mutate: updateFavoritesCollectionAssets } =
  useUpdateFavoritesCollection(accountId, favoritesCollectionId);

const addToFavorites = () => {
  if (!props.asset.id || !favoritesCollectionId.value) return;

  const body = [
    {
      added_by_user_id: currentUserId.value,
      digital_asset_id: props.asset.id,
    },
  ];

  updateFavoritesCollectionAssets(
    {
      favorites_collection_digital_assets_attributes: body,
    },
    {
      onSuccess: () => {
        // TODO: segment event
      },
    }
  );
};
</script>

<template>
  <template v-if="accountId">
    <OverlayDropdown icon-name="plus" visually-hidden-text="organize">
      <template #tooltip-content>organize</template>
      <template #default="{ clickCapture, keydown, mouseover }">
        <SoonaDropdownMenuItem>
          <button
            class="dropdown-button"
            role="menuitem"
            @click="displayAlbumPicker = true"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            <SoonaIcon name="album" />
            add to album
          </button>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem
          v-if="canFavorite && !asset.favorites_collection_digital_asset?.id"
        >
          <button
            class="dropdown-button"
            role="menuitem"
            @click="addToFavorites"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            <SoonaIcon name="heart" />
            add to favorites
          </button>
        </SoonaDropdownMenuItem>
      </template>
    </OverlayDropdown>

    <AlbumPicker
      v-if="displayAlbumPicker"
      :account-id="accountId"
      :selected-digital-assets="[asset]"
      @cancel="displayAlbumPicker = false"
      @select="addToAlbum"
    />
  </template>
</template>

<style lang="scss" scoped>
.dropdown-button {
  align-items: center;
  display: flex;
  gap: 0.25rem;
}
</style>

<template>
  <div
    :class="fastPassMember ? 'post-shoot-fast-pass' : ''"
    class="option-content"
  >
    <div class="price-section">
      <p class="emoji">{{ option.emoji }}</p>
      <p class="price">{{ option.price }}</p>
      <p class="price-note">{{ option.priceNote }}</p>
    </div>
    <div class="info-section">
      <p class="info-bold">{{ option.firstInfo }}</p>
      <p v-if="!fastPassMember" class="info">{{ option.secondInfo }}</p>
      <p v-else class="info">
        you're in the fast pass program! any items you send us for this shoot
        we'll store. don't want to store them? change your preferences
        <span class="link-style" @click="updateTosPreference"> here. </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostShootOptionsCard',
  props: {
    option: Object,
    fastPassMember: Boolean,
    updateTosPreference: Function,
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.option-content {
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.161);
  width: 100%;
  height: 100%;
  border-radius: 0.1875rem;
  display: flex;
  &:hover {
    box-shadow: 0 0 0 0.125rem variables.$gray-20;
  }
  .price-section {
    min-width: 4.375rem;
    width: 4.375rem;
    background-color: #94c42717;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.75rem 0.125rem;
    .emoji {
      font-size: 1.5rem;
    }
    .price {
      font-weight: 800;
      font-size: 0.875rem;
      line-height: 1.2;
      &-with-spacing {
        letter-spacing: 0.1rem;
      }
    }
    .price-note {
      font-size: 0.625rem;
      line-height: 1;
    }
  }
  .info-section {
    width: 100%;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.75rem;
    .info {
      font-size: 0.625rem;
    }
    .info-italic {
      font-style: italic;
    }
    .info-bold {
      font-weight: 800;
      font-size: 0.875rem;
    }
  }
}
.post-shoot-fast-pass {
  &.option-content {
    cursor: auto;
    .link-style {
      font-size: 0.625rem;
      background: none;
      border: none;
      text-decoration: underline;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
    &:hover {
      box-shadow: none;
    }
  }
}
</style>

import { http } from '../config/vue-axios';
import { mapPaginationHeaders } from '../lib/api-transforms';

/**
 *
 * @param {Object} [params]
 * @param {string | number} [params.accountId]
 * @param {boolean} [params.packBuilder]
 * @param {array} [params.status]
 * @param {string} [params.query]
 * @param {string} [params.filter]
 * @param {number} [params.limit]
 * @param {string} [params.orderBy]
 * @param {string} [params.direction]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {AbortSignal} [signal]
 */
export async function getPackConfigurations(
  {
    accountId,
    packBuilder,
    status,
    query,
    filter,
    limit,
    orderBy,
    direction,
    itemsPerPage,
    currentPage,
  } = {},
  signal
) {
  const params = new URLSearchParams();

  if (accountId) params.append('account_id', accountId);
  if (packBuilder) params.append('pack_builder', packBuilder);
  if (status) status.forEach(s => params.append('status[]', s));
  if (query) params.append('query', query);
  if (filter) params.append('filter', filter);
  if (limit) params.append('limit', limit);
  if (orderBy) params.append('order_by', orderBy);
  if (direction) params.append('direction', direction);
  if (itemsPerPage) params.append('items', itemsPerPage);
  if (currentPage) params.append('page', currentPage);

  const response = await http.get(`/pack_configurations`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    data: response.data,
  };
}

/**
 *
 * @param {Object} [params]
 * @param {string | number} [params.packId]
 * @param {AbortSignal} [signal]
 */
export async function getPackConfiguration({ packId } = {}, signal) {
  const response = await http.get(`/pack_configurations/${packId}.json`, {
    signal,
  });
  return response.data;
}

/**
 *
 * @param {Object} packConfiguration
 */
export async function putPackConfiguration(packConfiguration) {
  const response = await http.put(
    `/pack_configurations/${packConfiguration.id}.json`,
    { pack_configuration: packConfiguration }
  );
  return response.data;
}

/**
 *
 * @param {Object} packConfiguration
 */
export async function postPackConfiguration(packConfiguration) {
  return http.post('pack_configurations.json', {
    pack_configuration: packConfiguration,
  });
}

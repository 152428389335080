import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { createReservationDigitalAsset } from '@/api/reservationDigitalAssets';
import { toValue } from 'vue';

/**
 * @param {number | Ref<number>} reservationId
 * */
export function useCreateReservationDigitalAsset(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => {
      return createReservationDigitalAsset({
        reservationId: toValue(reservationId),
        body,
      });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationDigitalAssets(reservationId),
        }),
        queryClient.invalidateQueries({ queryKey: queryKeys.collectionsDA() }),
      ]);
    },
  });
}

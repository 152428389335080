import { useMutation } from '@tanstack/vue-query';
import { resetPassword } from '@/api/users';

/**
 */
export function usePostResetPassword() {
  return useMutation({
    mutationFn: attributes => resetPassword(attributes),
  });
}

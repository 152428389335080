/**
 * Truncate text to a given length
 * @param {string} text
 * @param {number} length
 * @param {boolean} middle - truncate from the middle of the string
 * @returns {*|string}
 */
export function truncate(text, length, middle) {
  if (!text || text.length <= length) {
    return text;
  }

  if (middle) {
    const first = text.slice(0, length / 2);
    const last = text.slice(-length / 2);
    return `${first}...${last}`;
  }
  return `${text.slice(0, length)}...`;
}

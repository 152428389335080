<template>
  <div class="best-brands">
    <p class="u-badge--small">loved by the best brands</p>
    <div class="best-brands__logos">
      <img
        class="best-brands__each-and-every"
        src="@images/sign-in/each-and-every-logo@2x.png"
        alt="Each & Every logo"
      />
      <img
        class="best-brands__subset"
        src="@images/sign-in/subset-logo@2x.png"
        alt="subset logo"
      />
      <img
        class="best-brands__crocs"
        src="@images/sign-in/crocs-logo.svg"
        alt="Crocs logo"
      />
      <img
        class="best-brands__humm"
        src="@images/sign-in/humm-logo@2x.png"
        alt="Humm logo"
      />
      <img
        class="best-brands__tb"
        src="@images/sign-in/tb12-logo@2x.png"
        alt="TB12 logo"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.best-brands {
  text-align: center;
  color: variables.$gray-60;

  &__logos {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.875rem;
    justify-content: center;
    margin-top: 0.875rem;
  }

  &__each-and-every {
    height: 0.9375rem;
  }

  &__subset {
    height: 0.875rem;
  }

  &__crocs {
    height: 1.25rem;
  }

  &__humm {
    height: 1.1875rem;
  }

  &__tb {
    height: 1.5rem;
  }
}
</style>

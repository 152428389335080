import * as types from 'src/store/mutation-types';

const state = {
  allQuizPhotos: [],
  allQuizWords: [],
  allShootRoles: [
    {
      id: 0,
      title: 'director',
      description: 'I know exactly what I want!',
      icon: '🎬',
    },
    {
      id: 1,
      title: 'producer',
      description: 'I have the list! the rest is up to the crew.',
      icon: '📋',
    },
    {
      id: 2,
      title: 'collaborator',
      description: 'I have a good start on my list. but open to ideas!',
      icon: '📓',
    },
    {
      id: 3,
      title: 'creative',
      description: 'I have ideas! but I want more!',
      icon: '✏️',
    },
    {
      id: 4,
      title: 'newbie',
      description: 'I need to be guided step by step.',
      icon: '🖍️',
    },
  ],
  currentQuiz: {
    words: [],
    photos: [],
    shootRole: null,
    brandCategory: null,
    brandCategorySlug: null,
  },
  quizResult: null,
  quizStyle: null,
};

const getters = {};

const mutations = {
  [types.SET_QUIZ_RESULT](state, result) {
    state.quizResult = result;
  },
  [types.SET_QUIZ_STYLE](state, style) {
    state.quizStyle = style;
  },
  [types.SET_QUIZ_PHOTOS](state, photos) {
    state.allQuizPhotos = photos;
  },
  [types.SET_QUIZ_WORDS](state, words) {
    state.allQuizWords = words;
  },
  [types.SET_SHOOT_ROLES](state, shoot_roles) {
    state.allShootRoles = shoot_roles;
  },
  [types.TOGGLE_QUIZ_PHOTO](state, photo) {
    if (state.currentQuiz.photos.find(p => p.id === photo.id)) {
      let index = state.currentQuiz.photos.findIndex(p => p.id === photo.id);
      state.currentQuiz.photos.splice(index, 1);
    } else {
      state.currentQuiz.photos.push(photo);
    }
  },
  [types.TOGGLE_QUIZ_WORD](state, word) {
    if (state.currentQuiz.words.find(w => w === word.id)) {
      let index = state.currentQuiz.words.indexOf(word.id);
      state.currentQuiz.words.splice(index, 1);
    } else {
      state.currentQuiz.words.push(word.id);
    }
  },
  [types.TOGGLE_BRAND_CATEGORY](state, category) {
    state.currentQuiz.brandCategory = category.id;
    state.currentQuiz.brandCategorySlug = category.slug;
  },
  [types.TOGGLE_SHOOT_ROLE](state, role) {
    state.currentQuiz.shootRole = role.id;
  },
};

const actions = {
  loadStyleQuizPhotos({ commit }) {
    return this.http.get(`quiz_photos.json`).then(
      response => {
        commit(types.SET_QUIZ_PHOTOS, response.data);
      },
      error => {
        console.error('error', error);
      }
    );
  },
  loadStyleQuizWords({ commit }) {
    return this.http.get(`quiz_words.json`).then(
      response => {
        commit(types.SET_QUIZ_WORDS, response.data);
      },
      error => {
        console.error('error', error);
      }
    );
  },
  toggleQuizWord({ commit }, word) {
    commit(types.TOGGLE_QUIZ_WORD, word);
  },
  toggleQuizPhoto({ commit }, photo) {
    commit(types.TOGGLE_QUIZ_PHOTO, photo);
  },
  toggleBrandCategory({ commit }, category) {
    commit(types.TOGGLE_BRAND_CATEGORY, category);
  },
  toggleShootRole({ commit }, role) {
    commit(types.TOGGLE_SHOOT_ROLE, role);
  },
  createQuizResult({ state, commit }, accountId) {
    let quizPhotoIds = state.currentQuiz.photos.map(photo => photo.id);

    return this.http
      .post(`quiz_results.json`, {
        quiz_result: {
          quiz_word_ids: state.currentQuiz.words,
          quiz_photo_ids: quizPhotoIds,
          account_id: accountId,
          account: {
            id: accountId,
            shoot_role: state.currentQuiz.shootRole,
            industry_id: state.currentQuiz.brandCategory,
            account_type:
              state.currentQuiz.brandCategorySlug === 'personal-brand' ? 0 : 1,
          },
        },
      })
      .then(
        response => {
          commit(types.SET_QUIZ_RESULT, response.data);
        },
        error => {
          console.error('error', error);
        }
      );
  },
  loadQuizStyle({ commit }, quizStyleId) {
    return this.http.get(`quiz_styles/${quizStyleId}.json`).then(
      response => {
        commit(types.SET_QUIZ_STYLE, response.data);
      },
      error => {
        console.error('error', error);
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

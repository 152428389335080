<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const props = defineProps({
  startAt: {
    type: String,
    default: '',
  },
  endAt: {
    type: String,
    default: '',
  },
});
const emits = defineEmits(['update:model-value']);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

const formatStartAt = computed({
  get() {
    let formattedStartAt = dayjs(props.startAt).tz('America/Chicago');
    return formattedStartAt.format('YYYY-MM-DDTHH:mm');
  },
  set(formatStartAt) {
    let formattedStartAt = dayjs(formatStartAt).tz('America/Chicago');
    formattedStartAt.format(); // 2019-03-06T08:00:00+08:00

    emits('update:model-value', 'start_at', formattedStartAt.utc().format());
  },
});

const formatEndAt = computed({
  get() {
    let formattedEndAt = dayjs(props.endAt).tz('America/Chicago');
    return formattedEndAt.format('YYYY-MM-DDTHH:mm');
  },
  set(formatEndAt) {
    let formattedEndAt = dayjs(formatEndAt).tz('America/Chicago');
    formattedEndAt.format(); // 2019-03-06T08:00:00+08:00

    emits('update:model-value', 'end_at', formattedEndAt.utc().format());
  },
});

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
</script>

<template>
  <section class="date-time-section">
    <div class="date-time-section__field">
      <label class="u-body--heavy" for="start"
        >start date*
        <span class="user-timezone">{{ userTimezone }}</span></label
      >
      <input
        id="start"
        v-model="formatStartAt"
        name="start"
        type="datetime-local"
        class="date-time-section__datetime"
      />
    </div>

    <div class="date-time-section__field">
      <label class="u-body--heavy" for="end"
        >end date* <span class="user-timezone">{{ userTimezone }}</span></label
      >
      <input
        id="start"
        v-model="formatEndAt"
        name="start"
        type="datetime-local"
        class="date-time-section__datetime"
      />
    </div>
  </section>
</template>

<style scoped lang="scss">
@use '@/variables';

.date-time-section {
  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  &__datetime {
    max-width: 304px;
    height: 40px;
    border: 1px solid variables.$gray-30;
    border-radius: 5px;
    padding: 16px;
  }
  .user-timezone {
    color: variables.$gray-40;
    text-transform: lowercase;
  }
}
</style>

import { http } from '../config/vue-axios';

/**
 *
 * @param {string} without_declined
 * @param {number} reservationId
 */
export async function getCrewAssignments(
  reservationId,
  { without_declined } = {},
  signal
) {
  const params = { reservation_id: reservationId };
  if (typeof without_declined !== 'undefined') {
    params.without_declined = without_declined;
  }
  const response = await http.get(`/crew_assignments`, {
    signal,
    params,
  });

  return response.data;
}

/**
 *
 * @param {number} userId
 */
export async function getCrewMember(userId, signal) {
  const params = { user_id: userId };
  const response = await http.get(`/crew_assignments/crew_member.json`, {
    signal,
    params,
  });

  return response.data;
}

<template>
  <div class="kpi-card-wrapper">
    <div
      v-for="kpi in kpis"
      :key="kpi.name"
      class="kpi-card drop-shadow-container"
      :class="kpi.name.replace('_', '-')"
    >
      <span class="stat-icon">
        {{ kpiIcon(kpi.name) }}
      </span>
      <span class="stat">
        {{ kpi.stat }}
      </span>
      <span class="name">
        {{ kpiDescription(kpi.name) }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { useCapability } from '@/composables/useCapability';

export default {
  name: 'KPIcards',
  props: {
    userId: Number,
  },
  setup() {
    const { hasCapability: isSoonaStaff } = useCapability({
      capability: 'soona_staff',
    });

    return { isSoonaStaff };
  },
  data() {
    return {
      kpiDecor: {
        cart_value: {
          description: 'average cart value',
          icon: '🛒',
        },
        delight: {
          description: 'delighted score',
          icon: '😘',
        },
        checkout: {
          description: '24 hour checkout rate',
          icon: '🎯',
        },
        hours: {
          description: 'hours assigned this week',
          icon: '🦸',
        },
      },
    };
  },
  computed: {
    ...mapState({ kpis: state => state.dashboard.kpis }),
  },
  mounted() {
    if (this.isSoonaStaff) {
      this.loadKPIs(this.userId);
    }
  },
  methods: {
    ...mapActions('dashboard', ['loadKPIs']),
    kpiDescription(name) {
      return this.kpiDecor[name]?.description?.toUpperCase();
    },
    kpiIcon(name) {
      return this.kpiDecor[name]?.icon;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.kpi-card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.kpi-card {
  border-radius: 12px;
  height: 84px;
  width: 100%;
  color: variables.$white-default;
  font-size: 12px;
  font-weight: 900;
  padding: 10px;
  display: flex;
  align-items: center;
}

.cart-value {
  background-color: variables.$avo-toast-60;
  .name {
    width: 43%;
  }
}

.delight {
  background-color: variables.$really-pink;
  .stat-icon {
    padding-top: 4px;
  }
}

.checkout {
  background-color: variables.$funky-orange;
}

.hours {
  background-color: variables.$periwink-blue-70;
  .stat-icon {
    padding-left: 7px;
  }
}

.stat-icon {
  height: 28px;
  width: 30px;
  font-size: 14px;
  border-radius: 50%;
  background-color: variables.$white-default;
  margin: 0 10px;
  padding-left: 8px;
  padding-top: 3px;
}

.stat {
  font-size: 24px;
  width: 45%;
  text-align: center;
}

.name {
  width: 45%;
  line-height: 1.4;
  letter-spacing: 0.1rem;
}
</style>

import { useMutationObserver } from '@vueuse/core';

/**
 * @param {Ref<Node>} focusTrappedRef
 */
export function useDialogSoonaSelect(focusTrappedRef) {
  const observerConfig = { attributes: false, childList: true, subtree: false };

  // TODO: eventually fork the vue-select repo and implement proper Teleport ability with configurable `to` prop
  // see: https://github.com/sagalbot/vue-select/pull/1745

  // If SoonaSelect is opened while focus-trapped ref is open, move listbox to within the focus-trapped ref
  // This prevents the focus trap from preventing clicking options
  useMutationObserver(
    document.body,
    mutationsList => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes?.length) {
          for (let addedNode of mutation.addedNodes) {
            if (
              // only run on an element node (ignore text nodes, among others)
              addedNode.nodeType === 1 &&
              addedNode.getAttribute('role') === 'listbox'
            ) {
              addedNode.style.marginTop = `-${window.scrollY}px`;
              focusTrappedRef.value?.appendChild(addedNode);
            }
          }
        }
      }
    },
    observerConfig
  );
}

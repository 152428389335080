<script setup>
import { computed } from 'vue';
import CompetitorCompareScoreCard from './CompetitorCompareScoreCard.vue';
import { useRuleIcon } from './useRuleIcon';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  listing: {
    type: Object,
    required: true,
  },
  opportunities: {
    type: Object,
    required: true,
  },
});

const listing = computed(() => props.listing);

const listingScoreData = computed(() => {
  return [
    {
      label: listing.value.visual_average.score.label,
      value: listing.value.visual_average.score.value,
    },
  ];
});
</script>

<template>
  <CompetitorCompareScoreCard
    :score-data="listingScoreData"
    :display-value="listing.visual_average.display_value"
  >
    <template #content>
      <div class="body">
        <template v-if="opportunities.length > 0">
          <h4 class="u-badge--large">top opportunities to win</h4>
          <ul class="results">
            <li
              v-for="(opportunity, i) in opportunities"
              :key="i"
              class="result"
            >
              <div class="result__icon">
                <SoonaIcon :name="useRuleIcon(opportunity.rule)" size="small" />
              </div>
              <p class="result__text">
                {{ opportunity.display_text }}
              </p>
            </li>
          </ul>
        </template>

        <div v-else class="no-results">
          <div class="result">
            <SoonaIcon
              name="circle-check-solid"
              size="small"
              class="result__icon"
            />
            <p class="result__text u-label--heavy">
              outstanding Amazon content
            </p>
          </div>
        </div>
      </div>
    </template>
  </CompetitorCompareScoreCard>
</template>

<style lang="scss" scoped>
@use '@/variables';

.body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.results {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.result {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  > svg {
    display: block;
    flex: 0 0 1rem;
  }

  &__text {
    line-height: 1.25rem;
  }
}

.no-results {
  display: flex;
  flex-direction: row;
  align-items: center;

  .result {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    &__icon {
      color: variables.$avo-toast-40;
    }
  }
}
</style>

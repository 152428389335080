<template>
  <div class="ProServiceProviderSelectionViewDetails">
    <SoonaDialog
      class="view-details-dialog"
      aria-label="pro service provider details"
      @close="closeModal"
    >
      <template #default>
        <div class="trait-left">
          <SoonaCarousel
            class="soona-carousel-component"
            :images="imageUrls"
            :wrap-around="false"
            :image-pips="true"
            height="346px"
            width="346px"
            container=".view-details-dialog"
          />
        </div>
        <div class="trait-right">
          <span class="name-section">
            <span class="name">{{ formatName }} </span>
            <p v-if="providerPronouns !== ''" class="pronoun">
              ({{ providerPronouns }})
            </p>
          </span>
          <hr />
          <div class="basic-info-section">
            <div class="section-title">
              <p>Basic Info</p>
            </div>
            <div class="section-body">
              <div
                v-for="item in basicInfoCategories"
                :key="item.traitId"
                class="trait-subsection"
              >
                <p class="trait-title">{{ item.traitTitle }}</p>
                <SoonaButton
                  v-if="item.isLink"
                  class="selected-trait-link"
                  element="a"
                  variation="tertiary"
                  target="_blank"
                  :href="sampleWorkUrl"
                >
                  {{ item.selectedTraits }}
                </SoonaButton>
                <p v-else class="selected-trait-title">
                  {{ item.selectedTraits }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="hasMeasurements" class="measurment-info-section">
            <hr />
            <div class="section-title">
              <p>Measurements</p>
            </div>
            <div class="section-body">
              <div
                v-for="item in filterMeasures"
                :key="item.key"
                class="trait-subsection"
              >
                <p class="trait-title">{{ item.key }}</p>
                <p class="selected-trait-title">
                  {{ formatServiceMeasurements(item) }}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div
            v-if="sizesTalentsCategories?.length > 0"
            class="size-and-talent-info-section"
          >
            <div class="section-title">
              <p>{{ sizesSectionTitle }}</p>
            </div>
            <div class="section-body">
              <div
                v-for="item in sizesTalentsCategories"
                :key="item.traitId"
                class="trait-subsection"
              >
                <p class="trait-title">{{ item.traitTitle }}</p>
                <p class="selected-trait-title">{{ item.selectedTraits }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="showModalActions" #footer>
        <SoonaButton
          class="mobile-cancel-btn"
          variation="tertiary"
          @click="closeModal"
        >
          back to browsing
        </SoonaButton>
        <AddModelDropdown
          :pro-service-provider="proServiceProfile"
          :pro-service-requirement-id="proServiceRequirementId"
          :reservation-id="reservationId"
          @close-modal="closeModal"
        />
      </template>
    </SoonaDialog>
  </div>
</template>

<script>
import { computed } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaCarousel from 'src/components/ui_library/SoonaCarousel.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import AddModelDropdown from './AddModelDropdown.vue';
import { useGetProServiceProfile } from 'src/queries/useGetProServiceProfile';

export default {
  name: 'ProServiceProviderSelectionViewDetails',
  components: {
    SoonaDialog,
    SoonaCarousel,
    AddModelDropdown,
    SoonaButton,
  },
  props: {
    proServiceProviderId: Number,
    showModalActions: {
      type: Boolean,
      default: true,
    },
    showModal: Boolean,
    closeModal: Function,
    proServiceRequirementId: Number,
    reservationId: Number || String,
  },
  setup(props) {
    const proServiceProfileId = computed(() => props.proServiceProviderId);
    const { data: proServiceProfileData } =
      useGetProServiceProfile(proServiceProfileId);

    const proServiceProfile = computed(() => proServiceProfileData.value);

    const providerPronouns = computed(() => {
      const pronounTC =
        proServiceProfile.value?.pro_service_profile_tags.filter(
          t => t.tag_category.title === 'pronoun'
        );
      return pronounTC?.map(t => t.title).join(', ') || '';
    });
    const basicInfoCategories = computed(() => {
      let categories = proServiceProfile.value?.pro_service_profile_tags
        .filter(
          t =>
            t.tag_category.special_category === 'psp_trait_basic_info' &&
            t.tag_category.title !== 'pronoun'
        )
        .sort((a, b) => a.tag_category.order - b.tag_category.order)
        .reduce((previousValue, currentValue) => {
          const index = previousValue.findIndex(
            p => p.traitId === currentValue.tag_category.id
          );
          if (index > -1) {
            previousValue[index].selectedTraits += ', ' + currentValue.title;
          } else {
            previousValue.push({
              traitTitle: currentValue.tag_category.title,
              selectedTraits: currentValue.title,
              traitId: currentValue.tag_category.id,
              isLink: false,
            });
          }
          return previousValue;
        }, []);
      if (proServiceProfile.value?.sample_work) {
        categories.push({
          traitTitle: 'sample work',
          selectedTraits: proServiceProfile.value?.sample_work,
          traitId: '',
          isLink: true,
        });
      }
      return categories;
    });
    const sizesTalentsCategories = computed(() => {
      return proServiceProfile.value?.pro_service_profile_tags
        .filter(
          t =>
            t.tag_category.special_category === 'psp_trait_size' ||
            t.tag_category.special_category === 'psp_trait_talent'
        )
        .sort((a, b) => a.tag_category.order - b.tag_category.order)
        .reduce((previousValue, currentValue) => {
          const index = previousValue.findIndex(
            p => p.traitId === currentValue.tag_category.id
          );
          if (index > -1) {
            previousValue[index].selectedTraits += ', ' + currentValue.title;
          } else {
            previousValue.push({
              traitTitle: currentValue.tag_category.title,
              selectedTraits: currentValue.title,
              traitId: currentValue.tag_category.id,
            });
          }
          return previousValue;
        }, []);
    });
    const hasSizeTraits = computed(() => {
      return proServiceProfile.value?.pro_service_profile_tags.some(
        t => t.tag_category.special_category === 'psp_trait_size'
      );
    });
    const hasTalentTraits = computed(() => {
      return proServiceProfile.value?.pro_service_profile_tags.some(
        t => t.tag_category.special_category === 'psp_trait_talent'
      );
    });
    const sizesSectionTitle = computed(() => {
      return hasSizeTraits.value && hasTalentTraits.value
        ? 'Sizes & Talents'
        : 'Sizes';
    });
    const filterMeasures = computed(() => {
      return proServiceProfile.value?.measurements
        ? proServiceProfile.value?.measurements.filter(
            meas => meas.value !== ''
          )
        : [];
    });
    const formatName = computed(() => {
      let name = proServiceProfile.value?.name
        ? proServiceProfile.value?.name
        : proServiceProfile.value?.user.profile_full_name
          ? proServiceProfile.value?.user.profile_full_name
          : proServiceProfile.value?.user.name;
      let nameArray = name ? name.split(' ') : [];
      return nameArray.length > 1
        ? nameArray[0] + ' ' + nameArray[1].slice(0, 1) + '.'
        : nameArray[0];
    });
    const sampleWorkUrl = computed(() => {
      if (proServiceProfile.value?.sample_work?.includes('http')) {
        return proServiceProfile.value?.sample_work;
      }

      return '//' + proServiceProfile.value?.sample_work;
    });

    const imageUrls = computed(() => {
      return proServiceProfile.value?.image_urls;
    });

    const hasMeasurements = computed(() => {
      return (
        proServiceProfile.value?.measurements &&
        proServiceProfile.value?.measurements.length > 0
      );
    });

    return {
      providerPronouns,
      basicInfoCategories,
      sizesTalentsCategories,
      hasMeasurements,
      hasSizeTraits,
      hasTalentTraits,
      imageUrls,
      sizesSectionTitle,
      filterMeasures,
      formatName,
      sampleWorkUrl,
      proServiceProfile,
    };
  },
  methods: {
    formatServiceMeasurements(item) {
      const measurment = this.proServiceProfile.required_measurements.find(
        m => m.measurement_key === item.key
      );
      if (item.key === 'height') {
        const feet = Math.floor(item.value / 12);
        const inches = item.value % 12;

        return `${feet}ft. ${inches}in.`;
      } else {
        return `${item.value}${measurment.format}.`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.ProServiceProviderSelectionViewDetails {
  :deep(.btn.cancel-btn) {
    display: none !important;
  }

  .soona-carousel-component {
    :deep(img) {
      border-radius: 0.3125rem;
    }
  }

  .trait-left {
    position: relative;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1.4375rem;
  }

  .trait-right {
    position: relative;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .section-body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(auto-fit, 1fr);
      grid-column-gap: 2.5rem;
      grid-row-gap: 1.4375rem;
    }

    .name {
      @include variables_fonts.u-subheader--all-caps-black;
    }

    .pronoun {
      @include variables_fonts.u-body--all-caps-black;
      color: variables.$gray-60;
      font-size: 0.8125rem;
    }

    .section-title {
      p {
        @include variables_fonts.u-body--all-caps-black;
        margin-bottom: 0.9375rem;
      }
    }

    .trait-subsection {
      .trait-title {
        @include variables_fonts.u-badge--small;
        font-size: 0.625rem;
      }

      .selected-trait-title {
        @include variables_fonts.u-label--regular;
        color: variables.$gray-60;
      }

      .selected-trait-link {
        @include variables_fonts.u-label--regular;
        color: variables.$gray-60;
        text-decoration: underline;
      }
    }
  }

  @media screen and (min-width: variables.$screen-sm-min) {
    .soona-carousel-component {
      :deep(img) {
        width: 25rem;
        height: 25rem;
      }
    }

    .trait-left {
      margin-left: 0.375rem;
    }

    .trait-right {
      margin: 0rem;
    }

    .mobile-cancel-btn {
      display: none;
    }

    :deep(.btn.cancel-btn) {
      display: inline !important;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { useTopOpportunities } from '@/queries/useListingActions';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import ListingActionCard from './ListingActionCard.vue';
import CarouselLoading from '@/components/platform-home/CarouselLoading.vue';
import BeatTheCompetitionCard from './BeatTheCompetitionCard.vue';

const props = defineProps({
  accountId: { type: String, required: true },
});

const accountId = computed(() => props.accountId);

const {
  data: topOpportunities,
  isLoading,
  error: topOpportunitiesError,
} = useTopOpportunities(accountId);

const priorityError = usePriorityError(topOpportunitiesError);
</script>

<template>
  <div class="top-opportunities">
    <div class="top-opportunities__heading">
      <h2 class="top-opportunities__title u-title--heavy">top opportunities</h2>
    </div>

    <SoonaError v-if="priorityError" no-margin>
      {{ priorityError }}
    </SoonaError>

    <div class="top-opportunities__content">
      <PageContainerOffset>
        <CarouselLoading v-if="isLoading" />
        <SoonaSlider v-if="topOpportunities" tag-name="ul">
          <ListingActionCard
            v-for="listingAction in topOpportunities"
            :key="listingAction.id"
            :listing-action="listingAction"
          />
          <BeatTheCompetitionCard :account-id="accountId" />
        </SoonaSlider>
      </PageContainerOffset>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-opportunities {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { editCollaborator } from '@/api/collaborators';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

/**
 * @param {Ref<string | number>} accountId
 * @param {Ref<string | number>} collaboratorId
 */
export function useUpdateCollaborator(accountId, collaboratorId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body =>
      editCollaborator(unref(accountId), unref(collaboratorId), body),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collaborators(accountId),
        }),
      ]);
    },
  });
}

<script setup>
import { ref, computed, provide, watchEffect } from 'vue';
import { useGetTemporaryListingScore } from '@/queries/temporary_listing_scores/useGetTemporaryListingScore';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import TemporaryListingInsightsScoreSection from '@/components/user/anytime/admin/temporary_listing_insights/TemporaryListingInsightsScoreSection.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const productUrl = ref('');
const images = ref([]);
const product = ref(null);

const getImageObject = image => {
  return {
    image_url: image,
    digital_asset_id: image,
    digital_asset_url: image,
  };
};

const imageObjects = computed(() => {
  return images.value.map(image => getImageObject(image));
});

const {
  data: temporaryListingScoreData,
  error: temporaryListingScoreDataError,
  isLoading: temporaryListingScoreIsLoading,
} = useGetTemporaryListingScore(images, 'shopify', {
  enabled: computed(() => images.value?.length > 0),
});

const temporaryListingScoreError = ref(null);

const temporaryListingScoreDataWithAssets = computed(() => {
  if (!temporaryListingScoreData.value) {
    return null;
  }
  const dataRulesWithAssets = temporaryListingScoreData.value?.rule_results.map(
    rule_group => {
      return {
        rule_group: rule_group.rule_group,
        rules: rule_group.rules.map(rule => {
          return {
            ...rule,
            assets: rule.images.map(image => {
              return getImageObject(image);
            }),
          };
        }),
      };
    }
  );
  const dataWithReplacedRuleGroups = {
    ...temporaryListingScoreData.value,
    rule_results: dataRulesWithAssets,
  };
  return {
    ...dataWithReplacedRuleGroups,
    assets: imageObjects.value,
  };
});

const clean_url = url => {
  return url.split('?')[0];
};

const productUrlAsJson = computed(() => {
  const cleanUrl = clean_url(productUrl.value);
  return cleanUrl.includes('json') ? cleanUrl : `${cleanUrl}.json`;
});

function processProductImages(product) {
  const images = product.images.reduce((acc, image) => {
    if (image.src.includes('gif')) {
      return acc;
    } else {
      return [...acc, image.src];
    }
  }, []);
  return images;
}

async function getTemporaryListingScore() {
  temporaryListingScoreError.value = null;
  try {
    const response = await fetch(productUrlAsJson.value);
    if (!response.ok) {
      throw new Error(`${response.status} - ${response.statusText}`);
    }
    const data = await response.json();
    product.value = data.product;
    images.value = processProductImages(product.value);
  } catch (error) {
    temporaryListingScoreError.value = error.message;
  }
}

watchEffect(() => {
  if (temporaryListingScoreDataError.value) {
    temporaryListingScoreError.value = temporaryListingScoreDataError.value;
  }
});

provide('listingAssets', imageObjects);
</script>

<template>
  <div class="temporary-listing-insights-dashboard">
    <section class="temporary-listing-insights-dashboard__header">
      <SoonaForm
        class="temporary-listing-insights-dashboard__header__form"
        @submit="getTemporaryListingScore"
      >
        <h1 class="u-headline--heavy">generate a temporary listing insight</h1>
        <p class="u-subheader--regular">
          enter a Shopify product URL to get a temporary listing score
        </p>
        <div class="temporary-listing-insights-dashboard__action-bar">
          <SoonaTextfield
            v-model="productUrl"
            class="temporary-listing-insights-dashboard__action-bar__text-field"
            placeholder="https://example.com/products/your-product-handle"
            label="Shopify product url"
          />
          <SoonaButton
            class="temporary-listing-insights-dashboard__action-bar__button"
            :disabled="!productUrl"
            type="submit"
            :loading="temporaryListingScoreIsLoading"
            >get score</SoonaButton
          >
        </div>
        <SoonaError v-if="temporaryListingScoreError">
          {{ temporaryListingScoreError }}
        </SoonaError>
      </SoonaForm>
    </section>
    <section class="score-section">
      <TemporaryListingInsightsScoreSection
        v-if="temporaryListingScoreDataWithAssets"
        :score="temporaryListingScoreDataWithAssets"
        :product="product"
      />
      <div v-if="temporaryListingScoreIsLoading" class="score-section__loading">
        <SoonaSkeleton class="score-section__loading__header" />
        <SoonaSkeleton class="score-section__loading__main-score" />
        <SoonaSkeleton class="score-section__loading__score-dropdowns" />
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.temporary-listing-insights-dashboard {
  padding: 2rem 1rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__action-bar {
    display: flex;
    flex-direction: column;
    &__button {
      min-width: fit-content;
    }
  }

  .score-section {
    height: 75%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__loading {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100%;

      &__header {
        width: 75%;
        height: 50%;
      }

      &__main-score {
        height: 25%;
        width: 100%;
      }

      &__score-dropdowns {
        display: flex;
        gap: 1rem;
        height: 25%;
        width: 50%;
      }
    }
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__action-bar {
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      &__text-field {
        width: 50%;
      }
    }
  }
}
</style>

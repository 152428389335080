<template>
  <div class="columns is-centered is-vcentered is-mobile">
    <div class="shipping-success-container">
      <div class="shotlist-header has-text-centered">
        <h2 class="title has-text-centered is-size-4-mobile">
          🎉 sweet success! 🎉
        </h2>
        <p class="is-italic has-text-centered ml-m mr-m mb-m">
          your package is ready to ship! click the links below to print your
          <span
            ><a
              class="soona-link"
              @click.prevent="
                onExternalLinkClick(
                  'shipping label',
                  currentShippingDetail.label_url
                )
              "
              >shipping label</a
            ></span
          >. need to
          <span>ship an additional package or </span>
          <span
            ><a
              class="soona-link"
              @click.prevent="
                onExternalLinkClick(
                  'track your package',
                  currentShippingDetail.tracking_url
                )
              "
              >track your package</a
            ></span
          >? we can help!
        </p>

        <p class="is-italic has-text-centered ml-m mr-m">
          your shoot will be scheduled once your package arrives at our studio.
        </p>

        <lottie-player
          autoplay
          loop
          src="@images/anim/lottie-shipping-completed.lott"
          style="width: 250px; display: inline-block"
        />
      </div>

      <div class="u-small--regular">
        <p class="soona-title-a1 mb-s"><b>PACKAGE DETAILS:</b></p>

        <div class="pl-m mb-m">
          <p v-if="currentShipment.parcels">
            1 package x {{ currentShipment.parcels.length }}" x
            {{ currentShipment.parcels.width }}" x
            {{ currentShipment.parcels.height }}" ({{
              currentShipment.parcels.weight
            }}
            lbs)
          </p>
        </div>

        <p class="soona-title-a1 mb-s"><b>CARRIER:</b></p>

        <div class="pl-m">
          <p>
            <b class="soona-title-a1">
              {{ currentRate.provider }} {{ currentRate.service_level }}
            </b>
          </p>
          <p class="is-lowercase">
            {{ overrideDurationTerms(currentRate.duration_terms) }}
          </p>

          <p class="mt-m">
            <b class="soona-title-a1">TRACKING NUMBER:</b>
            <br />
            <span>
              <a
                class="soona-link"
                @click.prevent="
                  onExternalLinkClick(
                    'tracking number',
                    currentShippingDetail.tracking_url
                  )
                "
              >
                {{ currentShippingDetail.tracking_number }}
              </a>
            </span>
          </p>
        </div>
      </div>

      <div class="action-btns">
        <SoonaButton
          element="a"
          variation="secondary-black"
          @click.prevent="
            onExternalLinkClick(
              'print shipping label',
              currentShippingDetail.label_url
            )
          "
        >
          print shipping label
        </SoonaButton>
        <SoonaButton @click="newShipmentClicked">
          ship another package
        </SoonaButton>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { mapActions, mapState } from 'vuex';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

export default {
  name: 'Completed',
  components: { SoonaButton },
  setup() {
    const { linkClicked, pageViewed } = useBaseEvents();
    const route = useRoute();
    const title = useTitle();

    const onExternalLinkClick = (linkLabel, linkHref) => {
      linkClicked({
        context: route.meta.context,
        subContext: 'shipping completed',
        linkLabel: linkLabel,
        linkHref: linkHref,
      });

      window.open(linkHref, '_blank', 'noopener noreferrer');
    };

    onMounted(() => {
      title.value = `${route.meta.page_title} | soona`;

      pageViewed();
    });

    return {
      linkClicked,
      onExternalLinkClick,
    };
  },
  data() {
    return {
      selectedRate: undefined,
    };
  },
  computed: {
    ...mapState({
      currentReservation: state => state.reservation.currentReservation,
      currentRate: state => state.reservation.currentRate,
      currentShipment: state => state.reservation.currentShipment,
      currentShippingDetail: state => state.reservation.currentShippingDetail,
    }),
  },
  methods: {
    ...mapActions('reservation', ['newShipment']),
    newShipmentClicked() {
      this.linkClicked({
        context: this.$route.meta.context,
        subContext: 'shipping completed',
        linkLabel: 'ship another package',
        linkHref: '/user/anytime#/reservation/shipping',
      });

      return this.newShipment();
    },
    overrideDurationTerms(terms) {
      if (
        terms ===
        'Delivery within 1, 2, or 3 days based on where your package started and where it’s being sent.'
      ) {
        return 'delivery guaranteed within 3 business days based on your location';
      } else {
        return terms;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.completed-meta {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.action-btns {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding-top: 2rem;
}
</style>

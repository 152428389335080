import { useQuery } from '@tanstack/vue-query';
import { unref } from 'vue';
import { queryKeys } from './query-keys';
import { getIntegrations } from '../api/integrations';

/**
 *
 * @param {Object} params
 * @param {number | Ref<string>} [params.accountId]
 * @param {Object} [queryOptions]
 */
export function useGetIntegrations(accountId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.integrations(accountId),
    queryFn: ({ signal }) => getIntegrations(unref(accountId), signal),
    ...queryOptions,
  });
}

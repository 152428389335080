<script setup>
import { computed, ref, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import { keepPreviousData } from '@tanstack/vue-query';
import CarouselBase from '@/components/user/anytime/gallery/media-editor/CarouselBase.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';
import { useDigitalAssets } from '@/queries/digital_assets/useDigitalAssets';
import { useMediaEditorStore } from '../user/anytime/gallery/media-editor/store/useMediaEditorStore';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  activeAssetId: {
    type: [String, Number],
    default: null,
  },
  backText: {
    type: String,
    required: true,
  },
  filters: {
    type: Object,
    default: () => ({
      visibility: 'all',
    }),
  },
});

const { accountId, activeAssetId, filters } = toRefs(props);
const currentPage = ref(null);
const userChangeablePage = ref(0);
const route = useRoute();

const orderBy = computed(() => route.query?.order_by);
const direction = computed(() => route.query?.direction);

watch([activeAssetId, accountId], () => {
  currentPage.value = null;
  userChangeablePage.value = 0;
});

const { data: filePages, isLoading } = useDigitalAssets(
  {
    accountId,
    activeAssetId: activeAssetId,
    currentPage,
    itemsPerPage: 12,
    filters: filters,
    sortBy: orderBy,
    sortDirection: direction,
  },
  {
    placeholderData: keepPreviousData,
    enabled: computed(() => !!activeAssetId.value),
  }
);
watch(userChangeablePage, newValue => {
  if (newValue > 0) {
    currentPage.value = newValue;
  }
});

const mediaEditorStore = useMediaEditorStore();
watch(
  filePages,
  newFilePages => {
    if (newFilePages) {
      mediaEditorStore.setCarouselDigitalAssetIds(
        newFilePages?.assets?.map(asset => asset.id)
      );
      mediaEditorStore.setCarouselPreviousPageLastAssetId(
        newFilePages?.previous_page_last_asset_id
      );
      mediaEditorStore.setCarouselNextPageFirstAssetId(
        newFilePages?.next_page_first_asset_id
      );
    }
  },
  { immediate: true }
);
</script>

<template>
  <CarouselBase
    v-if="filePages?.assets?.length > 0 && !isLoading"
    :file-pages="filePages"
    @update-page="pageNum => (userChangeablePage = pageNum)"
  >
    <template #title>{{ backText }}</template>
    <router-link
      v-for="da in filePages?.assets"
      :key="da.id"
      class="carousel-base__img-link"
      :class="{
        'carousel-base__img-link--active': activeAssetId === da?.id,
      }"
      :to="{
        name: 'account-gallery-media-view',
        params: {
          accountId: da?.account_id,
          digitalAssetId: da?.id,
        },
        query: route.query,
      }"
    >
      <DigitalAssetImage :src="da?.preview?.url" />
    </router-link>
  </CarouselBase>
</template>

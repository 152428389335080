<script setup>
import { ref } from 'vue';
import EmptyImg from 'images/shot-list/scenes/empty-reference-image.png';
import ReferenceImage from './ReferenceImage.vue';
import ReferenceImageDialog from './ReferenceImageDialog.vue';
import SectionLayout from './SectionLayout.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  selectedOptions: {
    default: () => [],
    type: Array,
  },
});

const emit = defineEmits(['handle-remove', 'handle-select']);

const showDialog = ref(false);

const handleRemove = optionId => {
  emit('handle-remove', 'reference_images', optionId);
};

const handleSelect = options => {
  emit('handle-select', 'reference_images', options);
  showDialog.value = false;
};
</script>

<template>
  <SectionLayout :empty-img="EmptyImg" :show-empty="!selectedOptions.length">
    <template #heading>reference images</template>
    <template #subheading>(optional)</template>
    <template #empty-heading>share your inspirations</template>
    <template #empty-btn>
      <SoonaButton variation="solid-black" @on-click="showDialog = true">
        <SoonaIcon name="plus-large" /> add images
      </SoonaButton>
    </template>
    <SoonaButton
      size="medium"
      variation="secondary-black"
      @on-click="showDialog = true"
    >
      <SoonaIcon name="plus-large" /> add images
    </SoonaButton>
    <ol>
      <ReferenceImage
        v-for="image in selectedOptions"
        :key="image.id"
        :image="image"
        @handle-remove="handleRemove"
      />
    </ol>
  </SectionLayout>
  <ReferenceImageDialog
    v-if="showDialog"
    @handle-select="handleSelect"
    @close="showDialog = false"
  />
</template>

<style lang="scss" scoped>
ol {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  list-style: none;
}
</style>

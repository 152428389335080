import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import {
  ADJUST_ACTION,
  AI_SCENES_ACTION,
  AI_PROPS_ACTION,
  ALBUMS_ACTION,
  BACKGROUND_COLOR_ACTION,
  BLUR_BACKGROUND_ACTION,
  MOBILE_EDIT_ACTION,
  NOTES_ACTION,
  PREMIUM_EDITS_ACTION,
  PRODUCTS_ACTION,
  REMOVE_BACKGROUND_ACTION,
  RESIZE_ACTION,
  RE_EDITS_ACTION,
  SHADOWS_ACTION,
  AI_EXPAND_ACTION,
} from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';

export function useMediaEditor() {
  const mediaEditorStore = useMediaEditorStore();
  const { activeAction, isIframeOpen, canvasData, showForeground } =
    storeToRefs(mediaEditorStore);

  const isAdjustActive = computed(() =>
    [ADJUST_ACTION, BACKGROUND_COLOR_ACTION, BLUR_BACKGROUND_ACTION].includes(
      activeAction?.value
    )
  );

  const isAdjustOpen = computed(() => activeAction?.value === ADJUST_ACTION);

  const isShiftBackgroundColorActive = computed(
    () => activeAction?.value === BACKGROUND_COLOR_ACTION
  );

  const isRemoveBackgroundActive = computed(
    () => activeAction?.value === REMOVE_BACKGROUND_ACTION
  );

  const isBlurBackgroundActive = computed(
    () => activeAction?.value === BLUR_BACKGROUND_ACTION
  );

  const isPremiumEditsActive = computed(
    () => activeAction?.value === PREMIUM_EDITS_ACTION
  );

  const isImageResizerActive = computed(
    () => activeAction?.value === RESIZE_ACTION
  );

  const isAIScenesActive = computed(
    () => activeAction?.value === AI_SCENES_ACTION
  );

  const isAIPropsActive = computed(
    () => activeAction?.value === AI_PROPS_ACTION
  );

  const isAIEditorActive = computed(
    () => isAIPropsActive.value || isAIScenesActive.value
  );

  const isAIExpandActive = computed(
    () => activeAction?.value === AI_EXPAND_ACTION
  );

  const isReEditRequestActive = computed(
    () => activeAction?.value === RE_EDITS_ACTION
  );

  const isAlbumsActive = computed(() => activeAction?.value === ALBUMS_ACTION);

  const isProductsActive = computed(
    () => activeAction?.value === PRODUCTS_ACTION
  );

  const isMobileEditPanelActive = computed(
    () =>
      activeAction?.value === MOBILE_EDIT_ACTION ||
      isAIPropsActive.value ||
      isAIScenesActive.value
  );

  const isShadowsActive = computed(
    () => activeAction?.value === SHADOWS_ACTION
  );

  const isNotesActive = computed(() => activeAction?.value === NOTES_ACTION);

  const currentlyEditing = computed(
    () =>
      !!canvasData.value ||
      isImageResizerActive.value ||
      showForeground.value ||
      isIframeOpen.value ||
      isAIExpandActive.value
  );

  const isLeftFlyoutOpen = computed(() => {
    return (
      isAdjustOpen.value ||
      isAIExpandActive.value ||
      isPremiumEditsActive.value ||
      isShiftBackgroundColorActive.value ||
      isRemoveBackgroundActive.value ||
      isBlurBackgroundActive.value ||
      isImageResizerActive.value ||
      isReEditRequestActive.value ||
      isShadowsActive.value
    );
  });

  const isRightFlyoutOpen = computed(() => {
    return (
      isAIExpandActive.value ||
      isAlbumsActive.value ||
      isProductsActive.value ||
      isNotesActive.value
    );
  });

  const showBackButton = computed(() => {
    return isShiftBackgroundColorActive.value || isBlurBackgroundActive.value;
  });

  const rightFlyoutHeading = computed(() => {
    if (isAlbumsActive.value) {
      return 'albums';
    } else if (isProductsActive.value) {
      return 'products';
    } else if (isNotesActive.value) {
      return 'notes';
    } else if (isAIExpandActive.value) {
      return 'results';
    }
    return '';
  });

  const leftFlyoutHeading = computed(() => {
    if (isAdjustOpen.value) {
      return 'adjust';
    } else if (isPremiumEditsActive.value) {
      return 'premium edits';
    } else if (isShiftBackgroundColorActive.value) {
      return 'background color';
    } else if (isRemoveBackgroundActive.value) {
      return 'remove background';
    } else if (isBlurBackgroundActive.value) {
      return 'blur background';
    } else if (isImageResizerActive.value) {
      return 'resize';
    } else if (isReEditRequestActive.value) {
      return 'requested re-edit';
    } else if (isMobileEditPanelActive.value) {
      return 'edit';
    } else if (isShadowsActive.value) {
      return 'shadows';
    } else if (isAIExpandActive.value) {
      return 'AI expand';
    }
    return '';
  });

  const leftFlyoutSubheading = computed(() => {
    if (isPremiumEditsActive.value) {
      return 'these edits are done by our talented post-production team and will be delivered in 24 hrs';
    } else if (isRemoveBackgroundActive.value) {
      return 'create a transparent background PNG file after you save';
    } else if (isBlurBackgroundActive.value) {
      return 'move the slider to adjust blur';
    } else if (isNotesActive.value) {
      return 'notes will be attached to this asset and visible to our crew.';
    } else if (isShadowsActive.value) {
      return 'remove background + add soft shadows';
    } else if (isAIExpandActive.value) {
      return 'select a direction to expand your image';
    }
    return '';
  });

  return {
    currentlyEditing,
    rightFlyoutHeading,
    leftFlyoutHeading,
    leftFlyoutSubheading,
    isAdjustActive,
    isAdjustOpen,
    isAIScenesActive,
    isAIPropsActive,
    isAlbumsActive,
    isBlurBackgroundActive,
    isImageResizerActive,
    isLeftFlyoutOpen,
    isMobileEditPanelActive,
    isAIEditorActive,
    isAIExpandActive,
    isNotesActive,
    isPremiumEditsActive,
    isProductsActive,
    isReEditRequestActive,
    isRemoveBackgroundActive,
    isRightFlyoutOpen,
    isShiftBackgroundColorActive,
    isShadowsActive,
    showBackButton,
  };
}

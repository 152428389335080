<script setup>
import { computed, toRefs } from 'vue';
import { convertToMMDDYYYY } from '@/lib/date-formatters';
import { toCurrency } from '@/lib/currency';
import { useGetEditHistory } from '@/queries/useGetEditHistory';
import { usePriorityError } from '@/composables/usePriorityError';
import { useReservation } from '@/composables/useReservation';
import {
  AvoToast20,
  AvoToast80,
  Roses20,
  Roses80,
  Tangerine20,
  Tangerine80,
} from '@/variables.module.scss';
import EditAssetDetails from '@/components/user/anytime/reservation/edit_history/EditAssetDetails.vue';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';

const props = defineProps({
  orderId: {
    default: null,
    type: [Number, String],
  },
  reEditId: {
    default: null,
    type: [Number, String],
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const { orderId, reservationId } = toRefs(props);
const editHistoryId = computed(() => orderId.value ?? props.reEditId);
const orderType = computed(() => (orderId.value ? 'order' : 're_edit'));

const {
  reservationAccountId,
  isLoading: isLoadingReservation,
  error: reservationError,
} = useReservation(reservationId);

const {
  data: order,
  isLoading: isLoadingOrders,
  error: ordersError,
} = useGetEditHistory(reservationId, editHistoryId, orderType);

const priorityError = usePriorityError(reservationError, ordersError);

const isLoading = computed(
  () => isLoadingReservation.value || isLoadingOrders.value
);

const isWaitingOnEdit = computed(
  () => order.value?.status === 'paid' || order.value?.status === 'submitted'
);

const flagData = computed(() => {
  if (!order.value) return null;

  switch (order.value?.status) {
    case 'completed':
      return {
        backgroundColor: AvoToast20,
        textColor: AvoToast80,
        title: 'completed',
      };
    case 'paid':
      return {
        backgroundColor: Tangerine20,
        textColor: Tangerine80,
        title: 'paid',
      };
    case 'submitted':
      return {
        backgroundColor: Tangerine20,
        textColor: Tangerine80,
        title: 'pending',
      };
    default:
      return {
        backgroundColor: Roses20,
        textColor: Roses80,
        title: 'canceled',
      };
  }
});
</script>

<template>
  <SoonaLoading v-if="isLoading" is-loading />
  <SoonaError v-else-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <div v-else class="editing-summary">
    <div class="editing-summary__header">
      <div
        class="editing-summary__header-top"
        :class="{ 'editing-summary__header-top--re-edit': !orderId }"
      >
        <SoonaButton
          element="router-link"
          :to="{
            name: 'gallery',
            params: {
              accountId: reservationAccountId,
              reservationId: reservationId,
            },
          }"
          variation="secondary-gray"
        >
          view booking
        </SoonaButton>
        <SoonaLink
          v-if="orderId"
          :to="{
            name: 'order-view',
            params: {
              accountId: reservationAccountId,
              orderId: order?.id,
            },
          }"
          variation="black"
        >
          view invoice
        </SoonaLink>
      </div>
      <h1 class="u-headline--heavy">editing summary</h1>
      <div v-if="orderId" class="editing-summary__order-num">
        <p class="u-body--regular">order #{{ order?.id }}</p>
        <SoonaFlag
          :background-color="flagData?.backgroundColor"
          :text-color="flagData?.textColor"
          :title="flagData?.title"
        />
      </div>
      <p class="u-body--regular">
        placed
        {{ convertToMMDDYYYY(order?.date_collected ?? order?.updated_at) }}
      </p>
    </div>
    <SoonaAlert v-if="isWaitingOnEdit">
      we’re working on it! edits will be added to your gallery when complete.
    </SoonaAlert>

    <ul class="editing-summary__asset-list">
      <li v-for="asset in order?.digital_assets" :key="asset.id">
        <EditAssetDetails
          :account-id="reservationAccountId"
          :add-ons="
            order?.reservation_line_items?.filter(
              rli => rli.digital_asset_id === asset.id
            )
          "
          :asset="asset"
          :is-order="!!orderId"
          :reservation-id="+reservationId"
        />
      </li>
    </ul>
    <div v-if="orderId" class="editing-summary__footer">
      <p class="u-body--heavy">
        <span>total discounts</span
        ><span>{{ toCurrency(order?.discount_amount) }}</span>
      </p>
      <p class="u-headline--heavy">
        <span>order total</span
        ><span>{{ toCurrency(order?.amount_collected) }}</span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.editing-summary {
  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding-bottom: 1rem;
  }

  &__header-top {
    align-items: left;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;

    &--re-edit {
      justify-content: flex-start;
    }
  }

  &__order-num {
    align-items: center;
    display: flex;
    gap: 1rem;
  }

  &__asset-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;

    > p {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
    }
  }

  &__btn-wrapper {
    align-self: flex-end;
    width: max-content;
  }
}
</style>

<script setup>
import { toRefs } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import ListingPickerDialog from '@/components/user/anytime/competitive_analysis/pickers/ListingPickerDialog.vue';
import PickerListingOption from '@/components/user/anytime/competitive_analysis/pickers/PickerListingOption.vue';
import CompetitorPickerDialog from '@/components/user/anytime/competitive_analysis/pickers/CompetitorPickerDialog.vue';
import PickerCompetitorOption from '@/components/user/anytime/competitive_analysis/pickers/PickerCompetitorOption.vue';
import ProductPickerGroupTitle from '@/components/user/anytime/products/picker/ProductPickerGroupTitle.vue';
import useSelectListing from '@/components/user/anytime/competitive_analysis/pickers/useSelectListing';
import useSelectCompetitor from '@/components/user/anytime/competitive_analysis/pickers/useSelectCompetitor';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  competitor: {
    type: Object,
    default: null,
  },
  integrationId: {
    type: [String, Number, null],
    default: null,
  },
  listingId: {
    type: [String, Number, null],
    default: null,
  },
});

const { competitor, integrationId, accountId, listingId } = toRefs(props);

// listing module
const { select: selectListing, pickerIsOpen: listingPickerIsOpen } =
  useSelectListing(competitor, integrationId, accountId);

// competitor module
const { select: selectCompetitor, pickerIsOpen: competitorPickerIsOpen } =
  useSelectCompetitor(listingId, integrationId, accountId);
</script>

<template>
  <div class="competitor-compare-subheader">
    <div class="competitor-compare-subheader__listing">
      <h2 class="u-title--heavy">my listing</h2>
      <SoonaButton
        variation="tertiary"
        size="medium"
        @click="listingPickerIsOpen = true"
      >
        choose
      </SoonaButton>
    </div>

    <div class="competitor-compare-subheader__competitor">
      <h2 class="u-title--heavy">competitor</h2>
      <SoonaButton
        variation="tertiary"
        size="medium"
        @click="competitorPickerIsOpen = true"
      >
        choose
      </SoonaButton>
    </div>
  </div>

  <ListingPickerDialog
    v-if="listingPickerIsOpen"
    :account-id="accountId"
    :integration-id="integrationId"
    @cancel="listingPickerIsOpen = false"
  >
    <template #heading>choose listing to compare</template>
    <template #listing-options="{ listings }">
      <PickerListingOption
        v-for="listing in listings"
        :key="listing.id"
        :listing="listing"
        @select="selectListing"
      />
    </template>
  </ListingPickerDialog>

  <CompetitorPickerDialog
    v-if="competitorPickerIsOpen"
    :account-id="accountId"
    :integration-id="integrationId"
    @cancel="competitorPickerIsOpen = false"
  >
    <template #heading>choose competitor</template>
    <template #competitor-options="{ competitors }">
      <ProductPickerGroupTitle>watchlist</ProductPickerGroupTitle>
      <PickerCompetitorOption
        v-for="competitorOption in competitors"
        :key="competitorOption.id"
        :competitor="competitorOption"
        @select="selectCompetitor"
      />
    </template>
  </CompetitorPickerDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.competitor-compare-subheader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: variables.$white-default;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &__listing,
  &__competitor {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: center;
  }

  &__listing {
    padding-left: 1rem;
  }

  &__competitor {
    padding-left: 1rem;
  }

  @media (min-width: 40rem) {
    &__listing {
      padding-left: 1.5rem;
    }

    &__competitor {
      padding-left: 1.5rem;
    }
  }
}
</style>

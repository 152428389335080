import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { createPackReservation } from '@/api/packReservation';

export function useCreatePackReservation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => createPackReservation(body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
  });
}

<script setup>
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  promoType: {
    type: String,
    default: '',
  },
  existingPromotion: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['update:model-value']);

const promoTypes = [
  {
    label: 'discount code',
    value: 'discount_code',
  },
  {
    label: 'announcement',
    value: 'announcement',
  },
  {
    label: 'sale',
    value: 'sale',
  },
];
</script>

<template>
  <div class="promo-title-and-type">
    <h3 class="u-title--heavy">title*</h3>

    <div class="promo-title-and-type__divider" />

    <input
      type="text"
      class="promo-title-and-type__input"
      placeholder="enter a title for this promo"
      :value="props.title"
      @change="emits('update:model-value', 'title', $event.target.value)"
    />
    <SoonaSelect
      class="promo-title-and-type__select"
      :options="promoTypes"
      :disabled="existingPromotion"
      placeholder="select"
      :model-value="props.promoType"
      @update:model-value="emits('update:model-value', 'promo_type', $event)"
    >
      <template #label>type*</template>
    </SoonaSelect>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';
.promo-title-and-type {
  &__divider {
    width: 100%;
    height: 1px;
    background: variables.$gray-30;
    margin: 8px 0;
  }
  &__input {
    width: 304px;
    height: 40px;
    border: 1px solid variables.$gray-30;
    border-radius: 5px;
    padding: 16px;
    margin: 16px 0;
  }
  &__select {
    margin-bottom: 16px;
    color: variables.$gray-50;
    max-width: 175px;
  }
}
</style>

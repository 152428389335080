import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {array | Ref<array>} [images]
 * @param {string | Ref<string>} [external_provider]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useGetTemporaryListingScore(
  images,
  external_provider,
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.temporaryListingScore(images),
    queryFn: async ({ signal }) => {
      const response = await http.post(`temporary_listing_scores`, {
        signal,
        temporary_listing_score: {
          images: toValue(images),
          external_provider: toValue(external_provider),
        },
      });
      return response.data;
    },
    ...queryOptions,
  });
}

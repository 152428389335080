import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { removeInvitation } from '@/api/invitations';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

/**
 *
 * @param {Ref<string | number>} accountId
 * @param {Ref<string | number>} invitationId
 */
export function useDeleteInvitation(accountId, invitationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => removeInvitation(unref(accountId), unref(invitationId)),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collaborators(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.invitations(accountId),
        }),
      ]);
    },
  });
}

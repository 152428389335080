<script setup>
import MissingAssetPreview from '@/components/dam/MissingAssetPreview.vue';
import { ref, watch } from 'vue';

const props = defineProps({
  src: {
    type: String,
    default: null,
  },
  alt: {
    type: String,
    default: '',
  },
});

const imageStatus = ref('loading');

watch(
  () => props.src,
  () => {
    imageStatus.value = 'loading';
  }
);
</script>

<template>
  <img
    v-if="src"
    :key="src"
    class="digital-asset-image"
    :class="{
      'digital-asset-image--loading': imageStatus === 'loading',
    }"
    :src="src"
    :alt="alt"
    @load="imageStatus = 'loaded'"
    @error="imageStatus = 'error'"
  />
  <MissingAssetPreview v-else />
</template>

<style lang="scss" scoped>
@use '@/variables';

@keyframes shimmer {
  60%,
  100% {
    background-position-x: 0;
  }
}

.digital-asset-image {
  display: block;
  user-select: none;
  border-radius: 0.3125rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: url(@images/checkertile.svg);

  &--loading {
    background: linear-gradient(
      90deg,
      variables.$gray-20 35%,
      variables.$gray-10 50%,
      variables.$gray-20 65%
    );
    background-size: 300%;
    background-position-x: 100%;
    animation: shimmer 2s infinite ease-in;
  }
}

@media (prefers-reduced-motion: reduce) {
  .digital-asset-image--loading {
    animation: none;
  }
}
</style>

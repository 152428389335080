<script setup>
import { computed } from 'vue';
import { useAccount } from '@/queries/account/useAccount';
import { useGetQuest } from '@/queries/quest/useGetQuest';
import { useMe } from '@/composables/user/useMe';

const props = defineProps({
  hasMargin: {
    default: false,
    type: Boolean,
  },
  hide: {
    default: false,
    type: Boolean,
  },
  questTaskSlug: {
    default: null,
    type: String,
  },
});

const { currentAccountId } = useMe();
const { data: account } = useAccount(currentAccountId);

const questId = computed(() =>
  account.value?.platform_quest_id ? account.value.platform_quest_id : null
);

const { data: quest } = useGetQuest(questId, {
  enabled: computed(() => !!questId.value),
});

const hide = computed(() => props.hide);

const isTaskComplete = computed(() => {
  const questTask = quest.value?.quest_tasks?.find(
    t => t.slug === props.questTaskSlug
  );

  return questTask?.complete || questTask?.dismissed;
});

const show = computed(
  () => !isTaskComplete.value && !hide.value && quest.value
);
</script>

<template>
  <div
    class="quest-helper"
    :class="{ 'quest-helper--active': show, 'quest-helper--margin': hasMargin }"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

@keyframes breathe {
  from {
    outline-offset: 0.125rem;
  }
  to {
    outline-offset: 0.25rem;
  }
}

.quest-helper {
  height: max-content;

  &--active {
    border-radius: 0.25rem;
    outline: 0.125rem solid variables.$black-default;
    outline-offset: 0.25rem;
    position: relative;

    @media (prefers-reduced-motion: no-preference) {
      animation: breathe 1s ease-in-out alternate infinite;
    }
  }

  &--margin {
    margin: 0.375rem;
  }
}
</style>

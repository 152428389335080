<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="header__menu-toggle__icon header__menu-toggle__icon--x"
  >
    <title>Close Menu</title>
    <g clip-path="url(#closex)" fill="#F0562E">
      <path
        d="M28.232 7.22s1.173-1.197 0-2.35c-1.173-1.152-2.392 0-2.392 0l-9.483 9.317L6.874 4.87s-1.23-1.138-2.392 0c-1.161 1.139 0 2.35 0 2.35l9.483 9.317-9.483 9.317s-1.199 1.172 0 2.35c1.2 1.178 2.392 0 2.392 0l9.483-9.317 9.483 9.317s1.144 1.227 2.393 0c1.249-1.228 0-2.35 0-2.35l-9.484-9.317 9.483-9.317Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .537)" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
</template>

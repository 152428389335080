import { unref, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getProductsSearch } from '../api/products';

/**
 *
 * @param {number | Ref<number>} locationId
 */
export function useProductsSearch({
  locationId,
  productType,
  selfServable,
  includesMediaAddOns,
  orderId,
}) {
  return useQuery({
    queryKey: queryKeys.products({
      locationId,
      productType,
      selfServable,
      includesMediaAddOns,
      orderId,
    }),
    queryFn: ({ signal }) =>
      getProductsSearch(
        {
          locationId: unref(locationId),
          productType: unref(productType),
          selfServable: unref(selfServable),
          includesMediaAddOns: unref(includesMediaAddOns),
          orderId: unref(orderId),
        },
        signal
      ),
    enabled: computed(() => locationId.value !== null),
  });
}

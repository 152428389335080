<script setup>
import { computed, inject, ref } from 'vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import AutoEditsFeedback from '@/components/crew/AutoEditsFeedback.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { component as Viewer } from 'v-viewer';
import { useUpdateShot } from '@/queries/useShotList';

const props = defineProps({
  reservationId: {
    type: [String, Number],
    required: true,
  },
  selectedScene: {
    type: Object,
    required: true,
  },
});

const reservationId = computed(() => props.reservationId);
const selectedScene = computed(() => props.selectedScene);

const selectedScenePreviewUrl = computed(() => {
  return selectedScene.value?.color_card?.preview?.url;
});

const showFeedback = ref(false);

const {
  colorCardSelectModeIsOn,
  toggleColorCardSelectMode,
  isPendingColorCard,
  setColorCardError,
} = inject('colorCardSelectMode');

const {
  mutate: updateShot,
  isPending: isUpdatingShot,
  error: updateShotError,
} = useUpdateShot(reservationId);

const autoEdits = computed(() => selectedScene.value?.auto_edit);

const hasColorCard = computed(() => !!selectedScene.value?.color_card);

const toggleSelectMode = () => {
  toggleColorCardSelectMode(selectedScene.value?.id);
};

const priorityError = usePriorityError(updateShotError, setColorCardError);

const toggleAutoEdits = () => {
  if (autoEdits.value) {
    showFeedback.value = true;
  } else {
    updateShot({
      id: selectedScene.value.id,
      shot_tags_attributes: selectedScene.value.shot_tags.map(tag => ({
        tag_id: tag.tag_id,
      })),
      auto_edit: true,
    });
  }
};

const confirmDisableAutoEdits = () => {
  showFeedback.value = false;
  updateShot({
    id: selectedScene.value.id,
    shot_tags_attributes: selectedScene.value.shot_tags.map(tag => ({
      tag_id: tag.tag_id,
    })),
    auto_edit: false,
  });
};
</script>

<template>
  <div class="auto-edit-actions">
    <AutoEditsFeedback
      v-if="showFeedback"
      category="auto_edits_gallery"
      subject-type="Shot"
      :subject-id="selectedScene.id"
      :open="showFeedback"
      options-type="creative"
      @close="() => (showFeedback = false)"
      @confirm="confirmDisableAutoEdits"
    />
    <SoonaToggle
      :model-value="autoEdits"
      :disabled="isUpdatingShot || showFeedback"
      label="auto edits"
      type="switch"
      @click="toggleAutoEdits"
    />
    <div class="auto-edit-actions__gray-card">
      <div
        v-if="isPendingColorCard"
        class="auto-edit-actions__gray-card--loading"
      >
        <SoonaSkeleton class="skeleton-button" />
        <SoonaSkeleton class="skeleton-image" />
      </div>
      <div
        v-else-if="colorCardSelectModeIsOn"
        class="auto-edit-actions__gray-card--action"
      >
        <SoonaButton
          size="medium"
          variation="tertiary"
          @on-click="toggleSelectMode()"
        >
          cancel
        </SoonaButton>
        <p>choose color card image</p>
      </div>
      <SoonaButton
        v-else-if="!hasColorCard"
        size="medium"
        @on-click="toggleSelectMode()"
      >
        choose color card
      </SoonaButton>
      <div v-else class="auto-edit-actions__gray-card--action">
        <SoonaButton
          size="medium"
          variation="tertiary"
          @on-click="toggleSelectMode()"
        >
          change color card
        </SoonaButton>
        <Viewer
          :options="{
            toolbar: false,
            navbar: false,
            title: false,
            backdrop: true,
            minZoomRatio: 0.01,
            maxZoomRatio: 2,
          }"
        >
          <img :src="selectedScenePreviewUrl" alt="color card thumbnail" />
        </Viewer>
      </div>
    </div>
    <SoonaError v-if="priorityError" class="auto-edit-actions__error">
      {{ priorityError }}
    </SoonaError>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.auto-edit-actions {
  margin-top: 1.5625rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  &__gray-card {
    &--action,
    &--loading {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }

    p {
      @include variables_fonts.u-body--heavy;
      padding: 0.25rem 0;
    }

    img {
      width: 2rem;
      height: 2rem;
      object-fit: cover;
      cursor: pointer;
    }
  }

  &__error {
    flex: 1 1 100%;
    margin-bottom: 0;
  }
}
</style>

<script setup>
import { computed, toRefs } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaSegmentedRadios from '@/components/ui_library/SoonaSegmentedRadios.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const {
  data: reservation,
  isLoading: isLoadingReservation,
  error: reservationError,
} = useReservation(reservationId);

const {
  data: studioBuyOutData,
  error: studioBuyOutError,
  isLoading: isLoadingStudioBuyOutData,
} = useReservationTags(reservationId, 'audio');

const studioBuyOutOptions = computed(() => {
  return (
    studioBuyOutData.value?.options.map(x => ({
      value: x.tag.id,
      label: x.tag.title,
    })) ?? []
  );
});

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId, { skipInvalidate: true });

const isBusy = computed(
  () =>
    isLoadingStudioBuyOutData.value ||
    isAdding.value ||
    isRemoving.value ||
    isLoadingReservation.value
);

const { stepIsIncomplete } = useIncompleteSteps(reservation, slug);

const priorityError = usePriorityError(
  studioBuyOutError,
  addError,
  removeError,
  reservationError,
  stepIsIncomplete
);

const selectedOption = computed(() => {
  return studioBuyOutData.value?.options.find(x => x.selected === true);
});
const selectedOptionValue = computed(
  () => selectedOption.value?.value ?? undefined
);

function onSelect(tagId) {
  if (selectedOption.value) {
    removeReservationTag(
      {
        reservationTagId: selectedOption.value.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSuccess: () => {
          addReservationTag({ tagId });
        },
      }
    );
  } else {
    addReservationTag({ tagId });
  }
}
</script>

<template>
  <SoonaSegmentedRadios
    label="studio buyout"
    name="studio-buyout"
    :disabled="isBusy"
    :options="studioBuyOutOptions"
    :model-value="selectedOptionValue"
    data-cypress="radio-studio-buyout"
    @update:model-value="onSelect"
  />

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useGetBestAutoApplyCoupon(
  reservationId,
  { products, orderType } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.bestAutoApplyCoupon(reservationId, products, orderType),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/reservations/${toValue(reservationId)}/best_auto_apply_discount`,
        {
          params: {
            coupon: {
              products: toValue(products),
              order_type: toValue(orderType),
            },
          },
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

import { tryOnScopeDispose } from '@vueuse/core';

// STOP! hello fellow dev, you're probably looking for useDialog.js!
export function useDocumentOverflowHidden(options = {}) {
  const { immediate = true } = options;

  function start() {
    document.documentElement.style.overflow = 'hidden';
  }

  function stop() {
    document.documentElement.style.overflow = '';
  }

  if (immediate) {
    start();
  }

  tryOnScopeDispose(stop);

  return { start, stop };
}

import { http } from '../config/vue-axios';

/**
 *
 * @param {number} accountId
 * @param {AbortSignal} [signal]
 */
export async function getShippingAddresses(accountId, signal) {
  const response = await http.get(`accounts/${accountId}/addresses.json`, {
    params: {
      address_type: 'shipping',
    },
    signal,
  });

  return response.data;
}

/**
 *
 * @param {number} accountId
 * @param {Object} body
 */
export async function createAddress(accountId, body) {
  const response = await http.post(
    `accounts/${accountId}/addresses.json`,
    body
  );

  return response.data;
}

/**
 *
 * @param {number} accountId
 * @param {Object} body
 */
export async function updateAddress(accountId, body) {
  const response = await http.put(
    `accounts/${accountId}/addresses/${body.id}`,
    body
  );

  return response.data;
}

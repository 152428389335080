import { useStore } from 'vuex';
import { computed } from 'vue';
import { getGlobalAttrs } from 'src/lib/user/globalUser';

export function useUserGlobalAttrs(parseEmptyFields = false) {
  const store = useStore();

  const userGlobalAttrs = computed(() => {
    const currentUser = store?.state?.currentUser;
    const userId = currentUser?.userULID;

    if (typeof userId === 'string') {
      return getGlobalAttrs(
        currentUser.globalAttrs.user,
        currentUser.globalAttrs.account,
        parseEmptyFields
      );
    } else {
      return getGlobalAttrs(null, null, parseEmptyFields);
    }
  });

  return { userGlobalAttrs };
}

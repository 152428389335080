import { http } from '../config/vue-axios';

export async function getDigitalAssetsBulkPublishManager(
  accountId,
  listingActionSlug,
  signal
) {
  const response = await http.get(
    `accounts/${accountId}/digital_assets_bulk_publish_managers/${listingActionSlug}.json`,
    {
      signal,
    }
  );

  return response.data;
}

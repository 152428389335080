export function useRuleIcon(rule) {
  switch (rule) {
    case 'image count':
    case 'image-count':
      return 'album';
    case 'lifestyle':
      return 'house-tree';
    case 'model':
      return 'user-heart-alt-1';
    case 'scale measurement':
    case 'scale-measurement':
      return 'ruler-triangle';
    case 'infographic':
      return 'chart-simple';
    case 'product on white':
    case 'product-on-white':
      return 'shapes';
    case 'main image product on white':
    case 'main-image-product-on-white':
      return 'shapes';
    case 'AI-generated':
    case 'ai-generated':
      return 'ghost';
    case 'low resolution':
    case 'low-resolution':
      return 'resolution';
    case 'poor quality':
    case 'poor-quality':
      return 'aperture';
    case 'close-up detail':
    case 'close-up-detail':
      return 'closeup';
    case 'poor composite':
    case 'poor-composite':
      return 'bandage';
    case 'video count':
    case 'video-count':
      return 'video-file-play';
    case 'multiple angles':
    case 'multiple-angles':
      return 'object-rotate';
    case 'missing-content':
      return 'camera-alt-1';
    case 'replace':
      return 'circle-slash';
    default:
      return null;
  }
}

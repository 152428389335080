import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useConfirmReservation(reservationId) {
  const queryClient = useQueryClient();

  const confirmReservation = async (reservationId, body) => {
    const { reservation_type, ...otherParams } = body;

    const params = {
      confirm: {
        ...otherParams,
      },
      reservation_type: reservation_type,
    };
    const response = await http.post(
      `/reservations/${reservationId}/confirm`,
      params
    );
    return response.data;
  };

  return useMutation({
    mutationFn: body => {
      return confirmReservation(unref(reservationId), body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservation(reservationId),
      });
    },
  });
}

import { http } from '../config/vue-axios';
import { mapPaginationHeaders } from '../lib/api-transforms';

export async function getHeroItems() {
  const response = await http.get(`/discovery/platform-discovery-hero-items`);

  return response.data;
}

export async function getInspiredItems() {
  const response = await http.get(`/discovery/platform-get-inspired`);

  return response.data;
}

export async function getBlogCategories() {
  const response = await http.get(`/discovery/blog-categories`);

  return response.data;
}

export async function getBlogPosts() {
  const response = await http.get(`/discovery/blog-posts`);

  return response.data;
}

/**
 *
 * @param {string} category
 * @param {string | number} [currentPage]
 * @param {AbortSignal} [signal]
 */
export async function getCategoryBlogPosts(
  category,
  currentPage = 1,
  signal = null
) {
  const params = {};

  if (currentPage) params.page = currentPage;

  const response = await http.get(`/discovery/blog-posts/${category}`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    items: response.data,
  };
}

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import {
  getReservation,
  getReservationAvailabilityErrors,
} from '../api/reservation';

/**
 *
 * @param {number | Ref<number>} reservationId
 * @param {UseQueryOptions} queryOptions
 */
export function useReservation(reservationId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.reservation(reservationId),
    queryFn: ({ signal }) => getReservation(unref(reservationId), signal),
    ...queryOptions,
  });
}

/**
 *
 * @param {number | Ref<number>} reservationId
 * @param {UseQueryOptions} queryOptions
 */
export function useReservationAvailabilityErrors(reservationId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.reservationAvailabilityErrors(reservationId),
    queryFn: ({ signal }) =>
      getReservationAvailabilityErrors(unref(reservationId), signal),
    ...queryOptions,
  });
}

import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { removeReservationTag } from '@/api/reservation_tags';

/**
 *
 * @param {number | Ref<number>} reservationId
 * @param options [Object]
 * @param options.skipInvalidate [Boolean] - if true, do not invalidate the reservation queries
 */
export function useRemoveReservationTag(
  reservationId,
  { skipInvalidate = false } = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ reservationTagId, deleteObsoleted }) =>
      removeReservationTag(
        unref(reservationId),
        reservationTagId,
        deleteObsoleted
      ),
    onSuccess: async () => {
      // some of our uses after deleting a tag immediately add a different one
      // this option lets us wait to invalidate the reservation until our modifications are complete
      if (!skipInvalidate) {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.reservations(),
        });
      }
    },
  });
}

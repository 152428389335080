<script setup>
import { ref, toRef, computed } from 'vue';
import { useReservation } from 'src/composables/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import {
  convertToHHmmss,
  convertToYYYYMMDDWithUTCTimeZone,
} from 'src/lib/date-formatters';
import BookingLayout from '../BookingLayout.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import AppointmentSelection from 'src/components/shared/AppointmentSelection.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useCapability } from 'src/composables/useCapability';

const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const { timeSelected } = useBaseEvents();

const onBackClick = () => {
  props.onBack();
};

const onNextClick = () => {
  props.onNext();
};

const { reservation, scheduledTime, lengthOfShoot } = useReservation(
  toRef(props, 'reservationId')
);

const {
  mutate,
  isPending: isMutating,
  error: updateError,
} = useUpdateReservation(toRef(props, 'reservationId'));

const priorityError = usePriorityError(updateError);

const minimumDate = new Date().toString();

const selectedTimeSlot = ref(reservation.value?.start);

const startTime = computed(() => {
  return selectedTimeSlot?.value
    ? convertToHHmmss(selectedTimeSlot.value, 'UTC')
    : null;
});

const start = computed(() => {
  return selectedTimeSlot.value
    ? `${convertToYYYYMMDDWithUTCTimeZone(selectedTimeSlot.value)} ${
        startTime.value
      }`
    : null;
});

const endDate = computed(() => {
  return new Date(
    new Date(selectedTimeSlot.value).getTime() +
      lengthOfShoot.value?.duration * 1000 -
      1
  );
});

const end = computed(() => {
  return startTime.value
    ? `${convertToYYYYMMDDWithUTCTimeZone(endDate.value)} ${convertToHHmmss(
        endDate.value,
        'UTC'
      )}`
    : null;
});

const isStepValid = computed(() => {
  if (reservation.value.start && reservation.value.end) {
    return true;
  }
  return false;
});

const handleTimeClick = event => {
  selectedTimeSlot.value = event;
  mutate({ start: start.value, end: end.value });
  timeSelected({
    context: 'booking',
    subContext: 'schedule',
    selectionType: 'time',
    UTC: startTime.value,
  });
  isStepValid.value = true;
};

const lastAvailableRescheduleDate = computed(() => {
  return reservation.value.status == 'draft'
    ? null
    : reservation.value.last_available_reschedule_date;
});

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const restrictedDays = computed(() => {
  let restrictedDaysOfWeek = [];

  if (!isFtSoonaStaff) restrictedDaysOfWeek = [...restrictedDaysOfWeek, 0, 6];

  return Array.from(new Set(restrictedDaysOfWeek));
});

const restrictedDates = computed(() => {
  return reservation.value?.location?.holidays;
});
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isMutating"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
      :no-sidecart="reservation.isHeadshotsForAll"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="in-studio-scheduling__content prebooking-transition__content">
        <SoonaHeading data-cypress="in-studio-step-heading">
          schedule your in-studio shoot
          <template #subtitle>
            choose the date and time for your in-studio shoot.
          </template>
        </SoonaHeading>
        <AppointmentSelection
          :reservation="reservation"
          :current-appointment="minimumDate"
          :last-available-date="lastAvailableRescheduleDate"
          :selected-time-slot="selectedTimeSlot"
          :scheduled-time="scheduledTime"
          :restricted-dates="restrictedDates"
          :restricted-days="restrictedDays"
          @time-selected="handleTimeClick"
        />
      </div>
    </BookingLayout>
  </transition>
</template>

<template>
  <div class="page-container-offset-top">
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.page-container-offset-top {
  margin-top: -2rem;

  @media screen and (min-width: 64rem) {
    margin-top: -2.5rem;
  }
}
</style>

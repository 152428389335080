<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import {
  Roses30,
  Tangerine30,
  AvoToast30,
  PeriwinkBlue30,
} from 'src/variables.module.scss';

const props = defineProps({
  iconName: {
    type: String,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
});

const iconBackgroundColor = computed(() => {
  if (props.status === 'poor') {
    return Roses30;
  }
  if (props.status === 'okay') {
    return Tangerine30;
  }
  if (props.status === 'indeterminate') {
    return PeriwinkBlue30;
  }
  return AvoToast30;
});
</script>

<template>
  <span class="accordion-icon">
    <SoonaIcon :name="iconName" />
  </span>
</template>

<style lang="scss" scoped>
.accordion-icon {
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  width: fit-content;
  background-color: v-bind('iconBackgroundColor');
}
</style>

<script setup>
import { computed, nextTick, onMounted, ref, watchEffect } from 'vue';
import { useAuth } from '@/composables/useAuth';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import AccountPicker from '@/components/booking/sign_in/AccountPicker.vue';
import PasswordStepActions from '@/components/authentication/PasswordStepActions.vue';
import SoonaAuthLayout from '@/components/authentication/SoonaAuthLayout.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useMe } from '@/composables/user/useMe';

const props = defineProps({
  basePath: {
    default: '/',
    type: String,
  },
  isExternalAuthPortal: {
    default: false,
    type: Boolean,
  },
  redirectToPath: {
    default() {
      return '/';
    },
    type: String,
  },
  showAccountPicker: {
    default: false,
    type: Boolean,
  },
});

const isExternalAuthPortal = computed(() => props.isExternalAuthPortal);

const {
  authenticationErrors,
  data,
  handleEmailCheck,
  handleUseDifferentAccount,
  isEmailStep,
  isGoogleSSOStep,
  isLoading,
  isPasswordStep,
  isPickAccountStep,
  sendAuthMessage,
  signIn,
  signInWithSelectedAccount,
  userAuthToken,
} = useAuth({ generateAuthToken: isExternalAuthPortal });
const { me } = useMe();

const { inputChanged, linkClicked, pageViewed } = useBaseEvents();
const route = useRoute();

const context = computed(() => route.meta.context);
const subContext = 'sign in';

const redirectToPath = computed(() => props.redirectToPath);

const emailRef = ref(null);
const passwordRef = ref(null);
const selectedAccountId = ref(null);

const submitBtnCopy = computed(() =>
  isEmailStep.value ? 'sign in with email' : 'sign in'
);

const handleAccountSelect = account => {
  selectedAccountId.value = account.id;
};

// external auth token signing in
const authTokenResponseMessage = computed(
  () =>
    `account_id:${me.value?.account_id},auth_token:${userAuthToken.value?.auth_token}`
);

const authTokenSignIn = () => {
  sendAuthMessage(authTokenResponseMessage.value);
  if (window.opener) window.close();
};

watchEffect(() => {
  if (userAuthToken.value) {
    authTokenSignIn();
  }
});
// end external auth token signing in

const handleSignIn = () => {
  const attributes = {
    password: data.password,
    remember_me: true,
    username: data.email,
  };

  signIn(attributes, redirectToPath.value, props.showAccountPicker);
};

const trackInputChange = (inputType, inputLabel) => {
  inputChanged({
    context: context.value,
    subContext: subContext,
    inputLabel: inputLabel,
    inputType: inputType,
    inputValue: null,
  });
};

const trackLinkClicked = () => {
  linkClicked({
    context: context.value,
    subContext: subContext,
    linkLabel: submitBtnCopy.value,
    linkHref: null,
  });
};

const handleSubmit = () => {
  trackLinkClicked();

  if (isEmailStep.value) {
    handleEmailCheck();
  } else if (isPasswordStep.value) {
    handleSignIn();
  } else if (isPickAccountStep.value) {
    signInWithSelectedAccount(selectedAccountId.value, redirectToPath.value);
  }
};

watchEffect(() => {
  if (isEmailStep.value) nextTick(() => emailRef.value?.focus());
  if (isPasswordStep.value) nextTick(() => passwordRef.value?.focus());
});

onMounted(() => {
  pageViewed();
  if (window.opener) {
    window.onbeforeunload = sendAuthMessage(authTokenResponseMessage.value);
  }
});
</script>

<template>
  <SoonaAuthLayout
    class="soona-sign-in"
    :base-path="basePath"
    :error-messages="authenticationErrors"
    :is-external-auth-portal="isExternalAuthPortal"
    :is-google-step="isGoogleSSOStep"
    :is-loading="isLoading"
    :is-pick-account-step="isPickAccountStep"
    type="sign-in"
    @handle-submit="handleSubmit"
  >
    <SoonaTextfield
      v-show="isEmailStep"
      ref="emailRef"
      v-model="data.email"
      autocomplete="email"
      autofocus
      label="email"
      name="email"
      placeholder="name@company.com"
      required
      :rules="['required', 'email']"
      type="email"
      cypress-name="input-sign-in-email"
      @on-blur="e => trackInputChange('email', 'email')"
    />
    <SoonaTextfield
      v-show="isPasswordStep"
      ref="passwordRef"
      v-model="data.password"
      class="soona-sign-in__password-input"
      label="password"
      name="password"
      autocomplete="current-password"
      placeholder="password"
      :required="isPasswordStep"
      :rules="['required']"
      show-password-icon
      type="password"
      cypress-name="input-sign-in-password"
      @on-blur="e => trackInputChange('password', 'password')"
    />
    <PasswordStepActions
      v-if="!isGoogleSSOStep && isPasswordStep"
      @handle-use-different-account="handleUseDifferentAccount"
    />
    <AccountPicker
      v-if="isPickAccountStep"
      :account-list="data.currentUser?.accounts"
      :selected-account-id="selectedAccountId"
      @on-click="handleAccountSelect"
    />
    <SoonaButton
      v-if="!isGoogleSSOStep"
      type="submit"
      data-cypress="button-sign-in-submit"
    >
      {{ submitBtnCopy }}
    </SoonaButton>
  </SoonaAuthLayout>
</template>

<style lang="scss" scoped>
.soona-sign-in {
  &__password-input {
    padding-bottom: 1rem;
  }
}
</style>

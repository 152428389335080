<script setup>
import { computed } from 'vue';

import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaComboboxRoot from '../ui_library/soona_combobox/SoonaComboboxRoot.vue';
import SoonaComboboxButton from '../ui_library/soona_combobox/SoonaComboboxButton.vue';
import SoonaComboboxOption from '../ui_library/soona_combobox/SoonaComboboxOption.vue';
import SoonaComboboxListbox from '../ui_library/soona_combobox/SoonaComboboxListbox.vue';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const selectedSort = defineModel('selectedSort');
const sortDirection = defineModel('sortDirection');

const sortOptions = [
  { label: 'creation date (newest)', value: 'date created-desc' },
  { label: 'creation date (oldest)', value: 'date created-asc' },
  { label: 'file name (a-z)', value: 'alphabetical-asc' },
  { label: 'file name (z-a)', value: 'alphabetical-desc' },
];

const combinedSort = computed(
  () => `${selectedSort.value}-${sortDirection.value}`
);

const selectedOption = computed(() => {
  return sortOptions.find(option => option.value === combinedSort.value);
});

const sortBy = computed({
  get() {
    return `${selectedSort.value}-${sortDirection.value}`;
  },
  set(sortBy) {
    selectedSort.value = sortBy.split('-')[0];
    sortDirection.value = sortBy.split('-')[1];
  },
});
</script>

<template>
  <div class="sort-by">
    <SoonaComboboxRoot v-model="sortBy">
      <template #trigger>
        <SoonaComboboxButton
          size="medium"
          class="sort-by__combobox-button"
          :disabled="disabled"
        >
          {{ selectedOption ? selectedOption.label : 'sort' }}

          <SoonaIcon
            size="small"
            name="chevron-down"
            class="sort-by__button-chevron"
          />
        </SoonaComboboxButton>
      </template>

      <template #popover>
        <SoonaComboboxListbox>
          <SoonaComboboxOption
            v-for="option in sortOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </SoonaComboboxOption>
        </SoonaComboboxListbox>
      </template>
    </SoonaComboboxRoot>
  </div>
</template>

<style lang="scss" scoped>
.sort-by {
  &__combobox-button {
    width: 100%;

    &[aria-expanded='true'] {
      .sort-by__button-chevron {
        transform: rotate(180deg);
      }
    }

    @media (prefers-reduced-motion: no-preference) {
      .sort-by__button-chevron {
        transition: transform 0.2s;
      }
    }
  }
}
</style>

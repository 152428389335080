import * as types from 'src/store/mutation-types';

const state = {
  reservations: [],
  unscheduledReservations: [],
  showUpdateReservationModal: false,
  currentUnscheduledPage: null,
  unscheduledPages: null,
  unscheduledCount: null,
  calendarStart: null,
  calendarEnd: null,
};

const getters = {
  unscheduledPagination(state) {
    return {
      page: state.currentUnscheduledPage,
      pages: state.unscheduledPages,
    };
  },
};

const mutations = {
  [types.SET_RESERVATIONS](state, reservations) {
    state.reservations = reservations;
  },
  [types.SET_UNSCHEDULED_RESERVATIONS](state, unscheduledReservations) {
    state.unscheduledReservations = unscheduledReservations;
  },
  [types.TOGGLE_UPDATE_RESERVATION_MODAL](state) {
    state.showUpdateReservationModal = !state.showUpdateReservationModal;
  },
  [types.REMOVE_RESERVATION_FROM_SCHEDULE](state, reservationId) {
    state.reservations = state.reservations.filter(reservation => {
      return reservation.id !== reservationId;
    });
  },
  [types.SET_UNSCHEDULED_RESERVATIONS_PAGINATION](
    state,
    { page, pages, count }
  ) {
    state.currentUnscheduledPage = page;
    state.unscheduledPages = pages;
    state.unscheduledCount = count;
  },
  [types.SET_CALENDAR_TIMES](state, { start, end }) {
    state.calendarStart = start;
    state.calendarEnd = end;
  },
};
const actions = {
  loadScheduleForLocation(
    { commit, rootState, dispatch },
    {
      start,
      end,
      page,
      q,
      scheduleStatus,
      packageStatus,
      bookableSpaceIds,
      crewIds,
      preferredOnly,
    }
  ) {
    return this.http
      .get(
        `locations/${rootState.locations.currentLocation.id}/calendar_reservations`,
        {
          params: {
            start: start,
            end: end,
            bookable_space_ids: bookableSpaceIds,
            crew_ids: crewIds,
            preferred_only: preferredOnly,
          },
        }
      )
      .then(response => {
        commit(types.SET_RESERVATIONS, response.data.reservations);
        dispatch('loadUnscheduledForLocation', {
          page,
          q,
          scheduleStatus,
          packageStatus,
        });
      });
  },
  loadUnscheduledForLocation(
    { commit, rootState },
    { page = 1, q, scheduleStatus, packageStatus }
  ) {
    return this.http
      .get(
        `locations/${rootState.locations.currentLocation.id}/unscheduled_reservations`,
        {
          params: {
            page,
            q,
            schedule_status: scheduleStatus,
            package_status: packageStatus,
          },
        }
      )
      .then(response => {
        commit(types.SET_UNSCHEDULED_RESERVATIONS, response.data.reservations);
        commit(types.SET_UNSCHEDULED_RESERVATIONS_PAGINATION, {
          page: response.data.page,
          pages: response.data.totalPages,
          count: response.data.totalCount,
        });
      });
  },
  toggleUpdateReservationModal({ commit }) {
    commit(types.TOGGLE_UPDATE_RESERVATION_MODAL);
  },
  setCalendarStartAndEnd({ commit }, { start, end }) {
    commit(types.SET_CALENDAR_TIMES, { start, end });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

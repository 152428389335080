<script setup>
import { computed } from 'vue';
import PackBuilderSidebarHeader from '@/components/crew/pack_builder/PackBuilderSidebarHeader.vue';
import PackSidecart from '@/components/crew/pack_builder/PackSidecart.vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import ShotlistHeader from '@/components/user/anytime/shotlist/PickAPackShotlistHeader.vue';
import SoonaPackDetail from '@/components/booking/SoonaPackDetail.vue';
import SoonaPackCard from '@/components/SoonaPackCard.vue';
import { component as Viewer } from 'v-viewer';

const props = defineProps({
  currentPage: {
    type: String,
    required: true,
  },
  pack: {
    type: Object,
    required: true,
  },
  hasProServiceChanges: {
    type: Boolean,
  },
});

const hasProServiceChanges = computed(() => props.hasProServiceChanges);

const displayPackCard = computed(() => props.currentPage === 'pack-info');

const displayReservationSummary = computed(
  () => props.currentPage === 'reservation-summary'
);

const displayShotList = computed(() => props.currentPage === 'shot-list');

const displayPreview = computed(() => props.currentPage === 'preview');

const shotListReferenceImages = computed(() =>
  props.pack.shotTemplates.reduce((acc, curr) => {
    let refImg = curr.shot_tags.find(
      x => x.tag?.tag_category_title === 'Reference Image'
    );
    acc.push(refImg?.tag);
    return acc;
  }, [])
);

const photoSegment = computed(() => props.pack?.photo_segment?.[0].title);
</script>

<template>
  <aside class="pack-builder-sidebar" aria-label="pack preview">
    <section v-if="displayPackCard" class="pack-builder-sidebar__wrapper">
      <PackBuilderSidebarHeader title="pack info" />
      <div class="pack-builder-sidebar__pack-card">
        <SoonaPackCard :pack="pack" />
      </div>
      <div class="pack-builder-sidebar__pack-detail">
        <SoonaPackDetail :pack="pack" :is-preview="true" />
      </div>
    </section>
    <section
      v-if="displayReservationSummary"
      class="pack-builder-sidebar__wrapper"
    >
      <PackBuilderSidebarHeader title="pack reservation summary" />
      <div class="pack-builder-sidebar__reservation-summary">
        <PackSidecart
          :pack-product="pack.pack_product"
          :photo-segment="photoSegment"
          :pro-service-products="pack.pro_service_products"
          :pack-price="pack.pack_price"
          :has-pro-service-changes="hasProServiceChanges"
        />
      </div>
    </section>
    <section v-if="displayShotList" class="pack-builder-sidebar__wrapper">
      <PackBuilderSidebarHeader title="header card preview" />
      <div class="pack-builder-sidebar__shotlist--content">
        <ShotlistHeader :pack="pack" :is-preview="true" />
      </div>
      <h2 class="pack-builder-sidebar__section-title">reference images</h2>
      <div
        v-if="shotListReferenceImages.length > 0"
        class="pack-builder-sidebar__shotlist--preview-images"
      >
        <Viewer
          v-for="(img, index) in shotListReferenceImages"
          :key="index"
          :options="{
            toolbar: false,
            navbar: false,
            title: false,
            backdrop: true,
            minZoomRatio: 0.01,
            maxZoomRatio: 2,
          }"
        >
          <h3 class="pack-builder-sidebar__shotlist--scene-header">
            scene {{ index + 1 }}
          </h3>
          <img
            v-if="img === undefined"
            :src="PlaceholderImage"
            class="inspo-img"
            alt="placeholder image"
          />
          <video
            v-else-if="img.image_content_type?.startsWith('video/')"
            autoplay
            loop
            playsinline
            muted
            class="inspo-video"
          >
            <source :src="img.image_url" :type="img.image_content_type" />
          </video>
          <img
            v-else
            :src="img.image_url"
            class="inspo-img"
            alt="reference image"
            data-cypress="shot-list-reference-image"
          />
        </Viewer>
      </div>
    </section>
    <div v-if="displayPreview" class="pack-builder-sidebar__wrapper">
      <PackBuilderSidebarHeader title="pack card" />
      <div class="pack-builder-sidebar__pack-card">
        <SoonaPackCard :pack="pack" />
      </div>
      <div class="pack-builder-sidebar__reservation-summary">
        <PackSidecart
          :pack-product="pack.pack_product"
          :photo-segment="photoSegment"
          :pro-service-products="pack.pro_service_products"
          :pack-price="pack.pack_price"
        />
      </div>
      <h2 class="pack-builder-sidebar__section-title">shotlist header card</h2>
      <div class="pack-builder-sidebar__shotlist--content">
        <ShotlistHeader :pack="pack" :is-preview="true" />
      </div>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.pack-builder-sidebar {
  background: variables.$periwink-blue-10;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__header,
  &__section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: variables.$periwink-blue-10;
    padding: 1rem 1.5rem;
    position: relative;
    margin-bottom: 1rem;

    &::after {
      content: '';
      position: absolute;
      left: 1.5rem;
      right: 1.5rem;
      bottom: 0;
      height: 0.0625rem;
      background-color: variables.$gray-40;
    }
  }

  &__header-title,
  &__section-title {
    @include variables_fonts.u-badge--big;
    color: variables.$black-default;
  }

  &__pack-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }

  &__pack-detail {
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    padding: 1rem;
    margin: 0 1.5rem 1rem 1.5rem;
  }

  &__reservation-summary {
    background-color: variables.$periwink-blue-10;
    border: 0.0625rem solid variables.$gray-30;
    padding: 0.5rem 0 0;
    margin: 0 1.5rem 1rem 1.5rem;
  }

  &__shotlist {
    &--content {
      margin: 0 1.5rem 0.5rem;
    }

    &--preview-images {
      display: flex;
      flex-flow: row wrap;
      gap: 0.75rem;
      margin: 0.5rem 1.5rem 1rem;

      .inspo-img,
      .inspo-video {
        border-radius: 0.625rem;
        width: 9.25rem;
        height: 9.25rem;
      }

      .inspo-img {
        object-fit: cover;
        cursor: pointer;
      }
    }

    &--scene-header {
      @include variables_fonts.u-label--heavy;
      margin-bottom: 0.25rem;
    }
  }
}
</style>

import { computed, watch } from 'vue';
import { refDebounced } from '@vueuse/core';
import { useMediaQuery } from '@vueuse/core';

import { useDigitalAsset } from '@/queries/digital_assets/useDigitalAsset';

function usePreloadAsset(fileRef) {
  const matchMediaSmIsWide = useMediaQuery('(min-width: 48rem)');
  const matchMediaMdIsWide = useMediaQuery('(min-width: 60rem)');

  const fileMediaSrc = computed(
    () => fileRef.value?.media_url || fileRef.value?.watermark_url
  );

  watch(fileMediaSrc, mediaUrl => {
    if (mediaUrl) {
      // screen width is a HORRIBLE proxy for bandwidth… and yet I’m using it
      // here to avoid preloading huge, full-res images on “mobile.”
      // (but honestly, the 800px preview image is high enough res for most phones)
      // responsive design elders, please forgive my transgressions
      if (matchMediaSmIsWide.value) {
        new Image().src = mediaUrl;
      }

      if (!matchMediaMdIsWide.value && fileRef.value?.preview_url) {
        // preload adjacent thumbs, as there is no carousel on <960px screens
        // to do the preloading for us
        new Image().src = fileRef.value?.preview_url;
      }
    }
  });
}

export function usePreloadDigitalAsset(accountId, assetId, params) {
  const accountIdRefDebounced = refDebounced(accountId, 600);
  const assetIdRefDebounced = refDebounced(assetId, 600);

  const { data: fileAdjacent } = useDigitalAsset(
    { accountId: accountIdRefDebounced, assetId: assetIdRefDebounced, params },
    {
      enabled: computed(
        () => !!accountIdRefDebounced.value && !!assetIdRefDebounced.value
      ),
    }
  );

  usePreloadAsset(fileAdjacent);
}

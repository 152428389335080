import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { saveDigitalAssetForeground } from '@/api/digitalAssets';
import { queryKeys } from '@/queries/query-keys';

/**
 * @param { Ref<any> } accountId
 * @param { Ref<any> } assetId
 * @returns {UseMutationReturnType<any, unknown, void, unknown>}
 */
export function useSaveDigitalAssetForeground(accountId, assetId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      saveDigitalAssetForeground(toValue(accountId), toValue(assetId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.digitalAssets(accountId),
      });
    },
  });
}

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<Number>} orderId
 * @param {Object} [params]
 * @param {Object} [queryOptions]
 */
export function useOrder(orderId, params, queryOptions = undefined) {
  return useQuery({
    queryKey: queryKeys.order(orderId, params),
    queryFn: async ({ signal }) => {
      const response = await http.get(`/orders/${toValue(orderId)}`, {
        params,
        signal,
      });

      return response.data;
    },
    ...queryOptions,
  });
}

<script setup>
import { computed } from 'vue';
import CompetitorCompareScoreCard from './CompetitorCompareScoreCard.vue';
import { useRuleIcon } from './useRuleIcon';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  competitor: {
    type: Object,
    required: true,
  },
  weaknesses: {
    type: Array,
    required: true,
  },
});

const competitor = computed(() => props.competitor);

const competitorScoreData = computed(() => {
  return [
    {
      label: competitor.value.listing_score.visual_average.score.label,
      value: competitor.value.listing_score.visual_average.score.value,
    },
  ];
});
</script>

<template>
  <CompetitorCompareScoreCard
    :score-data="competitorScoreData"
    :display-value="competitor.listing_score.visual_average.display_value"
  >
    <template #content>
      <div class="body">
        <template v-if="weaknesses.length > 0">
          <h4 class="u-badge--large">weaknesses</h4>
          <ul class="results">
            <li v-for="(weakness, i) in weaknesses" :key="i" class="result">
              <div class="result__icon">
                <SoonaIcon :name="useRuleIcon(weakness.rule)" size="small" />
              </div>
              <p class="result__text">
                {{ weakness.display_text }}
              </p>
            </li>
          </ul>
        </template>
      </div>
    </template>
  </CompetitorCompareScoreCard>
</template>

<style lang="scss" scoped>
@use '@/variables';

.body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.result {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.result {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  > svg {
    display: block;
    flex: 0 0 1rem;
  }

  &__text {
    line-height: 1.25rem;
  }
}
</style>

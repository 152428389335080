<script setup>
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useAccount } from '@/composables/useAccount';
import { useReservation } from '@/composables/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import PackageHandlingDialog from '@/components/user/anytime/PackageHandlingDialog.vue';
import ReturnAddressModal from '@/components/user/anytime/inventory/return-address/ReturnAddressModal.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  reservationId: {
    type: String,
    required: true,
  },
  showPackageHandlingDialog: {
    type: Boolean,
    required: true,
  },
  showReturnAddressDialog: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  'closePackageHandlingDialogOnly',
  'closeReturnAddressDialogOnly',
  'closeReturnAddressOpenPackageHandlingDialog',
  'openReturnAddressClosePackageHandlingDialog',
]);

const router = useRouter();

const reservationId = computed(() => props.reservationId);
const accountId = computed(() => props.accountId);

const { reservation } = useReservation(computed(() => props.reservationId));
const { hasSoonaStorage } = useAccount(accountId);

const { mutate: mutateReservation, error: updateError } = useUpdateReservation(
  toRef(props, 'reservationId')
);

const handleConfirm = packageOption => {
  if (packageOption === 'store_at_soona' && !hasSoonaStorage.value) {
    router.push({
      path: `/account/${accountId.value}/subscriptions/add-ons/fast-pass`,
      query: {
        reservation_id: reservationId.value,
      },
    });
  } else {
    mutateReservation({ package_option: packageOption });
  }

  if (packageOption === 'return_shipping') {
    emit('openReturnAddressClosePackageHandlingDialog');
  } else {
    emit('closePackageHandlingDialogOnly');
  }
};
</script>

<template>
  <div>
    <PackageHandlingDialog
      v-if="showPackageHandlingDialog"
      :has-soona-storage="hasSoonaStorage"
      :preselected-package-option="reservation.package_option"
      :update-error="updateError"
      @close="emit('closePackageHandlingDialogOnly')"
      @handle-confirm="handleConfirm"
    />
    <ReturnAddressModal
      v-if="showReturnAddressDialog"
      :account-id="accountId"
      @cancel="emit('closeReturnAddressOpenPackageHandlingDialog')"
      @success="emit('closeReturnAddressDialogOnly')"
    />
  </div>
</template>

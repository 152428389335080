<script setup>
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { Gray20 } from 'src/variables.module.scss';

defineProps({
  comingSoon: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  copy: {
    type: String,
    required: false,
    default: undefined,
  },
  isSelected: {
    type: Boolean,
    required: true,
  },
  removeBottomBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  to: {
    default: undefined,
    type: [String, Object],
  },
  replace: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onClick']);

const onRouterLinkClick = (event, navigate) => {
  emit('onClick', event);

  navigate(event);
};
</script>

<template>
  <router-link
    v-slot="{ href: slotHref, navigate }"
    :to="to"
    :custom="true"
    :replace="replace"
  >
    <a
      v-bind="$attrs"
      class="soona-tab"
      :class="[
        { 'soona-tab__no-bottom-border': removeBottomBorder },
        { 'soona-tab__disabled': disabled },
      ]"
      :aria-current="isSelected"
      :href="slotHref"
      @click="onRouterLinkClick($event, navigate)"
    >
      <template v-if="copy">{{ copy }}</template>
      <slot v-else name="copy" />
      <slot />
      <SoonaFlag
        v-if="comingSoon"
        class="soona-tab__flag"
        title="coming soon"
        type="pill"
        :background-color="Gray20"
      />
    </a>
  </router-link>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-tab {
  display: inline-block;
  padding: 1rem 0.75rem 0.75rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.125rem;
  font-weight: 800;
  white-space: nowrap;
  color: variables.$gray-60;
  border-radius: 0.3125rem 0.3125rem 0 0;

  &:focus-visible {
    outline: 0.125rem solid variables.$periwink-blue-50;
    /*
     * prevent focus outlines from clipping in scroll containers, common for
     * SoonaTab
     */
    outline-offset: -0.125rem;
  }

  &:not(&__no-bottom-border) {
    border-bottom: 0.0625rem solid variables.$gray-30;
  }

  &:hover {
    background-color: variables.$friendly-red-10;
  }

  &__no-bottom-border:hover {
    border-bottom: 0.1875rem solid variables.$friendly-red-10;
  }

  &[aria-current='true'] {
    color: variables.$black-default;
    border-bottom: 0.1875rem solid variables.$friendly-red-50;
  }

  &__disabled {
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }

  &__flag {
    position: relative;
    bottom: 0.25rem;
  }
}
</style>

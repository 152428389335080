<script setup>
import { useCapability } from '@/composables/useCapability';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

defineProps({
  isGhostUser: {
    type: Boolean,
    required: true,
  },
});

defineEmits(['join']);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});
const { hasCapability: chatAsGhost } = useCapability({
  capability: 'chat_as_ghost',
});
</script>

<template>
  <section class="JoinChat">
    <div class="JoinChat__buttons">
      <SoonaButton
        v-if="!isGhostUser && isFtSoonaStaff"
        variation="secondary-black"
        size="medium"
        @click="$emit('join')"
      >
        join chat
      </SoonaButton>
      <SoonaButton
        v-if="isGhostUser && chatAsGhost"
        variation="secondary-black"
        size="medium"
        @click="$emit('join')"
      >
        join as 👻
      </SoonaButton>
    </div>

    <template v-if="isGhostUser && chatAsGhost">
      <p>
        joining as ghost means that other chat users will not see your presence
        or activity unless you send a message, make an expert pick, or edit a
        note on an asset. any message you send will be visible to all and
        display your name.
      </p>
      <p>
        to join with full visibility, you must be assigned to the reservation.
      </p>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.JoinChat {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>

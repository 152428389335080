import { computed } from 'vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';

export const useSceneCardContent = (shot, isActive) => {
  const firstReferenceImage = computed(() => {
    return shot.value?.shot_tags?.find(
      x => x.tag.tag_category_title === 'Reference Image'
    )?.tag;
  });

  const contentType = computed(
    () =>
      shot.value?.shot_tags?.find(x => x.tag.tag_category_title === 'Content')
        ?.tag
  );

  const backgroundTag = computed(() => {
    const shotTags = shot.value?.shot_tags;
    if (!shotTags) return undefined;

    const findCatTitle = title => x => x.tag.tag_category_title === title;

    return (
      shotTags.find(findCatTitle('Backdrop Color'))?.tag ??
      shotTags.find(findCatTitle('Textures'))?.tag ??
      shotTags.find(findCatTitle('Environment'))?.tag
    );
  });

  const image = computed(() => {
    const tag =
      firstReferenceImage.value ?? backgroundTag.value ?? contentType.value;

    if (tag) {
      return {
        image_url: tag.image_url,
        image_content_type: tag.image_content_type ?? '',
      };
    }

    return {
      image_url: PlaceholderImage,
      image_content_type: 'image/jpeg',
    };
  });

  const imageSrc = computed(() => image.value.image_url);
  const imageContentType = computed(() => image.value.image_content_type);

  const isComplete = computed(() => shot.value?.status === 'complete');

  const iconName = computed(() => {
    if (isComplete.value) {
      return 'check';
    }
    if (contentType.value?.title === 'Photo' && isActive.value) {
      return 'camera-solid';
    }
    if (contentType.value?.title === 'Video') {
      return isActive.value ? 'video-solid' : 'video';
    }
    return 'camera';
  });

  return {
    contentType,
    imageSrc,
    imageContentType,
    isComplete,
    iconName,
  };
};

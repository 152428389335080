<script setup>
import { computed, ref } from 'vue';
import subCategories from '@/components/user/anytime/reservation/re_edits/sub_categories';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useBulkUpdateReEditsCollectionDigitalAsset } from '@/queries/re_edits_collection_digital_assets/useBulkUpdateReEditsCollectionDigitalAssets';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  bulkSelected: {
    type: String,
    default: null,
  },
  collectionId: {
    type: [Number, String],
    required: true,
  },
  selectedReEdits: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['close']);

const selectedSubCategories = ref([]);

const accountId = computed(() => props.accountId);
const bulkSelected = computed(() => props.bulkSelected);
const collectionId = computed(() => props.collectionId);
const selectedReEdits = computed(() => props.selectedReEdits);

const bulkApplyConfirmText = computed(() => {
  if (bulkSelected.value === 'all') {
    return 'apply to all images';
  }

  return selectedReEdits.value?.length > 1
    ? `apply to ${selectedReEdits.value?.length} images`
    : 'apply to 1 image';
});

const { mutate: bulkUpdateSubCategories } =
  useBulkUpdateReEditsCollectionDigitalAsset(accountId, collectionId);
const bulkApplySubCategory = () => {
  let body = { re_edit_categories: selectedSubCategories.value };
  if (bulkSelected.value === 'all') {
    body.all = 1;
  } else {
    body.re_edits_collection_digital_asset_ids = selectedReEdits.value.map(
      reEdit => reEdit.id
    );
  }
  bulkUpdateSubCategories(
    { body },
    {
      onSuccess: () => {
        emit('close', true);
      },
    }
  );
};

const updateSelectedSubCategories = val => {
  selectedSubCategories.value = val;
};
</script>
<template>
  <SoonaDialog @close="() => emit('close', false)">
    <template #heading> select sub category</template>
    <template #default>
      <h5 class="u-label--heavy">re-edit sub category</h5>
      <SoonaSelect
        class="category-dropdown"
        placeholder="select reason"
        :multi="true"
        :model-values="selectedSubCategories"
        :options="subCategories"
        :searchable="true"
        @update:model-values="val => updateSelectedSubCategories(val)"
      />
    </template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton variation="primary" @on-click="bulkApplySubCategory(close)">
        {{ bulkApplyConfirmText }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

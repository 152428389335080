<script setup>
import { computed, watchEffect, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useFlag } from '@/composables/useFlag';
import { usePackConfigurations } from '@/queries/pack-configurations/usePackConfigurations';
import BookingLayout from './BookingLayout.vue';
import SoonaPackCard from 'src/components/SoonaPackCard.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaFilter from '@/components/shared/SoonaFilter.vue';
import PackFilters from '@/components/user/anytime/products/PackFilters.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useListDataQueryParams } from 'src/composables/useListDataQueryParams';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import fireAnimationPath from 'images/anim/fire.lott';
import { keepPreviousData } from '@tanstack/vue-query';

const props = defineProps({
  backButtonCopy: String,
  onBack: Function,
  onNext: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

// TODO: PRIVATE PACKS
// const { data } = usePackConfigurations({ accountId: privatePacksAccountId });

const emit = defineEmits(['setNextTransition']);

const merchandisingV2Flag = useFlag('merchandising_v2');

const route = useRoute();
const { linkClicked, inputChanged, resultsReturned, filterSelected } =
  useBaseEvents();

const {
  searchText,
  searchDebounced,
  selectedFilter,
  sortBy,
  currentPage,
  itemsPerPage,
  resetFilters,
} = useListDataQueryParams();

const {
  data: allPackConfigurations,
  error,
  isLoading: isPackConfigLoading,
} = usePackConfigurations(
  {
    query: searchDebounced,
    currentPage,
    itemsPerPage,
    filter: selectedFilter,
    orderBy: computed(() => sortBy.value.orderBy),
    direction: computed(() => sortBy.value.direction),
  },
  {
    placeholderData: keepPreviousData,
  }
);
const priorityError = usePriorityError(error);

const packsPagination = computed(() =>
  allPackConfigurations.value
    ? allPackConfigurations.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

const filterOptions = computed(() => {
  let options = ['all', 'photo', 'video'];
  if (merchandisingV2Flag)
    options.push('shopify', 'amazon', 'meta', {
      title: 'trending',
      animation: fireAnimationPath,
    });

  if (
    allPackConfigurations.value?.data?.some(pack => pack.isStopMotion) &&
    !merchandisingV2Flag
  ) {
    options.push('stop motion');
  }

  return options;
});

function setFilter() {
  const filterQuery = route.query['filter'];
  const query = filterQuery || '';

  if (
    query.match(/stop-motion/) &&
    filterOptions?.value.includes('stop motion')
  ) {
    selectedFilter.value = query.replace(/-/g, ' ');
  } else if (query.match(/photo|video|amazon|shopify|meta|trending|social/)) {
    selectedFilter.value = query.replace(/-/g, ' ');
  } else selectedFilter.value = 'all';
}

watch(allPackConfigurations, () => {
  resultsReturned({
    context: 'booking',
    subContext: route.meta.page_title,
    quantityResults: packsPagination.value.totalCount,
    filtersSelected: {
      query: searchDebounced.value,
      order: sortBy.value,
      filter: selectedFilter.value,
    },
  });
});
watchEffect(() => {
  setFilter();
});

function handleSearchInputChange(content) {
  inputChanged({
    context: 'booking',
    subContext: route.meta.page_title,
    inputLabel: 'search products',
    inputType: 'text',
    inputValue: content,
  });
}

watchEffect(() => {
  if (searchDebounced.value) handleSearchInputChange(searchDebounced.value);
});

function handleFilterChange(option) {
  selectedFilter.value = option;
  filterSelected({
    context: 'booking',
    subContext: route.meta.page_title,
    filterCategory: '',
    filterSubcategory: '',
    filterLabel: option,
  });
}

function handleSortChange(option) {
  filterSelected({
    context: 'booking',
    subContext: route.meta.page_title,
    filterCategory: '',
    filterSubcategory: '',
    filterLabel: option,
  });
}

function onBackClick() {
  props.onBack('type');
}

function onNextClick() {
  props.onNext();
}

function packCategory(pack) {
  return pack.isStopMotion ? 'stop motion' : pack.category;
}

async function handlePackClick(pc) {
  emit('setNextTransition');
  linkClicked({
    context: 'booking',
    subContext: 'packs',
    linkLabel: pc.name,
    linkHref: `/booking/packs/${pc.id}`,
  });
}
</script>

<template>
  <transition :name="props.transitionName">
    <BookingLayout
      :back-button-copy="backButtonCopy"
      :on-back-click="onBackClick"
      :on-next-click="onNextClick"
      :reservation-id="props.reservationId"
      :show-next="false"
      no-sidecart
      is-full-width
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="all-packs prebooking-transition__content">
        <SoonaLoading
          v-if="isPackConfigLoading"
          :is-loading="true"
          :is-dark="false"
          is-contained
          loading-text="loading"
        />
        <SoonaHeading v-if="merchandisingV2Flag">all packs</SoonaHeading>
        <SoonaHeading v-else>
          all packs
          <template #subtitle>
            <p>
              pre-priced packs make it quick and easy to get assets that are
              common and meet the best practices of the places you will post.
            </p>
          </template>
        </SoonaHeading>
        <div class="all-packs__filter-container">
          <PackFilters
            v-if="merchandisingV2Flag"
            v-model:search-text="searchText"
            v-model:selected-filter="selectedFilter"
            v-model:sort-by="sortBy"
            :filter-options="filterOptions"
            @update:selected-filter="handleFilterChange"
            @update:sort-by="handleSortChange"
          />
          <SoonaFilter
            v-else
            :options="filterOptions"
            :selected-filter="selectedFilter"
            :on-change="handleFilterChange"
          />
        </div>

        <SoonaNoResults v-if="!allPackConfigurations?.data?.length">
          <template #header>oops we couldn't find a match</template>
          <template #body>
            try a different search or filter combination
          </template>
          <template #button>
            <SoonaButton variation="secondary-black" @on-click="resetFilters">
              reset filters
            </SoonaButton>
          </template>
        </SoonaNoResults>
        <div v-else class="all-packs__pack-cards">
          <template v-for="pc in allPackConfigurations?.data" :key="pc.id">
            <span v-if="merchandisingV2Flag">
              <SoonaPackCard
                :pack="pc"
                :to="`/booking/${props.reservationId}/packs/${pc.id}`"
                @click="handlePackClick(pc)"
              />
            </span>
            <span
              v-else-if="
                selectedFilter === packCategory(pc) || selectedFilter === 'all'
              "
            >
              <SoonaPackCard
                :pack="pc"
                :to="`/booking/${props.reservationId}/packs/${pc.id}`"
                @click="handlePackClick(pc)"
              />
            </span>
          </template>
        </div>

        <PaginatorFull
          v-if="merchandisingV2Flag"
          v-model:page="currentPage"
          v-model:records-per-page="itemsPerPage"
          class="all-packs__paginator-bottom"
          :total-pages="packsPagination.totalPages"
          :total-count="packsPagination.totalCount"
          :page-count="allPackConfigurations?.data?.length || 12"
          :records-per-page="12"
          :records-per-page-options="[6, 12, 18, 24]"
          :per-page-text="'per page'"
        />
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.all-packs {
  position: relative;

  &__pack-cards {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(min(14rem, 100%), 1fr));
    width: 100%;
  }

  &__filter-container {
    width: 100%;
    padding-bottom: 1.875rem;
  }

  &__byo-pack-card {
    :deep(.soona-clickable-card) {
      background-color: variables.$green-apple-10;

      ul {
        list-style: none;
        padding-left: 0;
      }
    }
  }
  &__mobile-byo-card {
    display: none;
    @media (max-width: variables.$screen-xxs-max) {
      display: flex;
      align-items: center;
      padding: 1rem;
      color: variables.$black-default;
      background-color: variables.$green-apple-10;
      border: 0.0625rem solid variables.$gray-30;
      border-radius: 0.625rem;
      transition: box-shadow 0.2s;
      cursor: pointer;

      &-image {
        width: 3.875rem;
        height: 3.625rem;
        object-fit: cover;
        border-radius: 0.625rem;
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1rem;
        width: 100%;
      }

      &-subtitle {
        display: flex;
        align-items: center;
      }

      &-arrow {
        display: block;
        margin-left: auto;
        transform: rotate(180deg);
      }
    }
  }

  &__paginator-bottom {
    margin: 1.25rem 0 0;
  }
}
</style>

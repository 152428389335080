import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { useStore } from 'vuex';
import { useAccountAdditionalOrderStore } from '@/components/user/anytime/billing_and_orders/store/useAccountAdditionalOrderStore';

/**
 *
 * @param {number | Ref<number>} locationId
 * @param {UseQueryOptions} queryOptions
 */
export function useProductsAndBundles(locationId, queryOptions) {
  const store = useStore();
  const accountAdditionalOrderStore = useAccountAdditionalOrderStore();

  return useQuery({
    queryKey: queryKeys.productsAndBundles(locationId),
    queryFn: async ({ signal }) => {
      const params = {
        location_id: toValue(locationId),
      };
      const response = await http.get(`/products/products_and_bundles.json`, {
        params,
        signal,
      });

      store.commit('account/SET_ADD_CREDITS_PRODUCTS', response.data.products);
      accountAdditionalOrderStore.setAdditionalChargeTagCategoryProducts(
        response.data.products
      );

      return response.data;
    },
    ...queryOptions,
  });
}

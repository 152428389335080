<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import GettingStarted from 'images/shot-list/getting-started@2x.png';

const emit = defineEmits(['close-dialog']);
</script>
<template>
  <SoonaDialog
    class="getting-started"
    max-width="40rem"
    @close="emit('close-dialog')"
  >
    <template #heading>getting started with a shot list</template>
    <img :src="GettingStarted" alt="dialog image" />

    <div class="getting-started__body">
      <h3 class="getting-started__subheading">what is a shot list?</h3>
      <div class="getting-started__section">
        <p class="u-body--regular">
          in a nutshell, the shot list helps the photographer understand your
          goals for the shoot by describing three things:
        </p>
        <ul class="getting-started__list">
          <li><strong>what</strong> you want to show (subject)</li>
          <li><strong>where</strong> you want to show it (environment)</li>
          <li><strong>how</strong> you want it photographed (style)</li>
        </ul>
      </div>
      <h3 class="u-subheader--heavy">what are scenes?</h3>
      <div class="getting-started__section">
        <p>
          <strong>each scene is a new setup</strong> -- a new combination of
          subject, environment, and style. during a scene, the photographer will
          shoot multiple angles and variations.
        </p>
        <strong>
          knowing a few things about each scene will help the creative team to
          prepare:
        </strong>
        <ul class="getting-started__list">
          <li><strong>which product(s)</strong> appear in the scene.</li>
          <li>
            <strong>backdrop</strong> (is it a solid color, a material or
            fabric, a lifestyle such as a living room, etc?)
          </li>
          <li><strong>additional props</strong> if needed.</li>
          <li>
            <strong>a reference image</strong> and a few words about what you
            like about it. (for example, is it the lighting and mood? the way
            the scene is arranged? a pose?)
          </li>
          <li>
            <strong>important features</strong> or angles you want to capture.
          </li>
          <li>
            any other info that will help the photographer understand your
            goals.
          </li>
        </ul>
      </div>
    </div>
    <template #footer="{ close }">
      <SoonaButton
        element="a"
        href="https://soona.co/blog/how-to-build-a-shotlist"
        target="_blank"
        variation="secondary-black"
      >
        learn more
      </SoonaButton>
      <SoonaButton @on-click="close">got it</SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.getting-started {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__subheading {
    @include variables_fonts.u-subheader--heavy;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__list {
    list-style-type: disc;
    margin-left: 1.5rem;
  }
}
</style>

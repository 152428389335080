<script>
import { h } from 'vue';

export default {
  setup(props, { slots }) {
    // wrap each slot element in a list item element
    return () =>
      h(
        'ul',
        { class: 'sidebar-list' },
        slots
          .default()
          .filter(vnode => vnode.children !== 'v-if')
          .map(vnode => h('li', { class: 'sidebar-list__item' }, vnode))
      );
  },
};
</script>

<style lang="scss" scoped>
.sidebar-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__item {
    display: block;
  }
}
</style>

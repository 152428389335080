import { http } from '../config/vue-axios';

/**
 *
 * @param {AbortSignal} signal
 * @returns {Promise<any>}
 */
export async function getExampleListings({ platform = null }, signal) {
  const params = {};
  if (platform) params['platform'] = platform;
  const response = await http.get(`/example_external_products/`, {
    params,
    signal,
  });

  return {
    listings: response.data.listings,
    all_listings_scored: response.data.all_listings_scored,
  };
}

/**
 *
 * @param {AbortSignal} signal
 * @returns {Promise<any>}
 */
export async function getExampleListing(exampleListingId, signal) {
  const response = await http.get(
    `/example_external_products/${exampleListingId}`,
    {
      signal,
    }
  );

  return response.data;
}

import { defineStore } from 'pinia';
import { computed, readonly, ref } from 'vue';
import { SALES_TAX_STORE_ID } from '@/stores/keys';
import { toCurrency } from '@/lib/currency';

// payments_todo: extract cart (pre checkout) and checkout logic
export const useSalesTaxStore = defineStore(SALES_TAX_STORE_ID, () => {
  const currency = ref('usd');
  const isCalculatingSalesTax = ref(false);
  const salesTaxBillingAddressId = ref(null);
  const standardRatedSalesTaxBreakdown = ref({});
  const standardRatedSalesTaxPercentage = computed(() => {
    return parseFloat(
      standardRatedSalesTaxBreakdown.value?.tax_rate_details?.percentage_decimal
    );
  });
  const stripeSalesTaxCalculationId = ref('');

  // tax amount exclusive
  const taxAmountExclusiveInCents = ref(0);
  const taxAmountExclusiveDisplay = computed(() => {
    return toCurrency(
      (parseFloat(taxAmountExclusiveInCents.value) ?? 0) / 100,
      currency.value
    );
  });

  // total w/ tax
  const preSalesTaxSubtotalInDollars = ref(0);
  const totalWithSurchargeInCents = ref(null);
  const totalWithSurchargeInDollars = computed(() => {
    return isNaN(parseInt(totalWithSurchargeInCents.value))
      ? parseFloat(preSalesTaxSubtotalInDollars.value)
      : (parseFloat(totalWithSurchargeInCents.value) ?? 0) / 100;
  });
  const totalWithSurchargeDisplay = computed(() => {
    return toCurrency(totalWithSurchargeInDollars.value ?? 0, currency.value);
  });

  const setStandardRatedSalesTaxBreakdown = breakdowns => {
    standardRatedSalesTaxBreakdown.value = breakdowns?.find(
      bd => bd.tax_rate_details.tax_type === 'sales_tax'
    );
  };

  const setStripeSalesTaxCalculationResponse = response => {
    currency.value = response.currency;
    setStandardRatedSalesTaxBreakdown(response.tax_breakdown);
    stripeSalesTaxCalculationId.value = response.id;
    totalWithSurchargeInCents.value = response.amount_total;
    taxAmountExclusiveInCents.value = response.tax_amount_exclusive ?? 0;
  };

  // use this every time component that calls this store unmounts
  function $reset() {
    currency.value = 'usd';
    isCalculatingSalesTax.value = false;
    salesTaxBillingAddressId.value = null;
    standardRatedSalesTaxBreakdown.value = {};
    stripeSalesTaxCalculationId.value = '';
    taxAmountExclusiveInCents.value = 0;
    totalWithSurchargeInCents.value = null;
  }

  return {
    currency: readonly(currency),
    isCalculatingSalesTax: readonly(isCalculatingSalesTax),
    setIsCalculatingSalesTax: value => (isCalculatingSalesTax.value = value),
    preSalesTaxSubtotalInDollars: readonly(preSalesTaxSubtotalInDollars),
    setPreSalesTaxSubtotalInDollars: value =>
      (preSalesTaxSubtotalInDollars.value = parseFloat(value)),
    setStripeSalesTaxCalculationResponse,
    salesTaxBillingAddressId: readonly(salesTaxBillingAddressId),
    setSalesTaxBillingAddressId: id => (salesTaxBillingAddressId.value = id),
    standardRatedSalesTaxBreakdown: readonly(standardRatedSalesTaxBreakdown),
    standardRatedSalesTaxPercentage: standardRatedSalesTaxPercentage,
    stripeSalesTaxCalculationId: readonly(stripeSalesTaxCalculationId),
    taxAmountExclusiveInCents: readonly(taxAmountExclusiveInCents),
    taxAmountExclusiveDisplay,
    totalWithSurchargeInCents: readonly(totalWithSurchargeInCents),
    totalWithSurchargeInDollars: readonly(totalWithSurchargeInDollars),
    totalWithSurchargeDisplay,
    $reset,
  };
});

<script setup>
import { computed } from 'vue';
import CompetitorsPopover from '../CompetitorsPopover.vue';
import { useRoute } from 'vue-router';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCompetitor } from '@/queries/useCompetitors';
import { useExampleCompetitorStaffPick } from '@/queries/useExampleCompetitorStaffPicks';
import { useListing } from '@/queries/useListings';
import { useExampleListing } from '@/queries/useExampleListings';
import CompetitorCompareSubheader from './CompetitorCompareSubheader.vue';
import CompetitorCompareBehind from './CompetitorCompareBehind.vue';
import CompetitorCompareAhead from './CompetitorCompareAhead.vue';
import CompetitorCompareTextContent from './CompetitorCompareTextContent.vue';
import MyListingOpportunities from './MyListingOpportunities.vue';
import CompetitorWeaknesses from './CompetitorWeaknesses.vue';
import { useCompetitorsComparison } from '@/queries/useCompetitorsComparison';
import useCompetitorsPopoverBackRoute from '@/components/user/anytime/competitive_analysis/useCompetitorsPopoverBackRoute';
import useListingRank from '@/components/user/anytime/competitive_analysis/useListingRank';
import ListingPrice from '../ListingPrice.vue';
import { usePriorityError } from '@/composables/usePriorityError';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const route = useRoute();

const accountId = computed(() => props.accountId);
const competitorId = computed(
  () => route.query.competitor ?? route.query.example_competitor
);
const listingId = computed(() => route.query.listing);
const integrationId = computed(() => route.query.integration);

const isExampleCompetitor = computed(() => !!route.query.example_competitor);

const { handleClose } = useCompetitorsPopoverBackRoute();

const useCompetitorEnabled = computed(() => {
  return (
    !!accountId.value && !!competitorId.value && !isExampleCompetitor.value
  );
});

const useExampleCompetitorEnabled = computed(() => !useCompetitorEnabled.value);

const {
  data: competitorData,
  isSuccess: realCompetitorIsSuccess,
  error: competitorError,
} = useCompetitor(accountId, competitorId, {
  enabled: useCompetitorEnabled,
});

const {
  data: exampleCompetitorData,
  isSuccess: exampleCompetitorIsSuccess,
  error: exampleCompetitorError,
} = useExampleCompetitorStaffPick(accountId, competitorId, {
  enabled: useExampleCompetitorEnabled,
});

const competitorIsSuccess = computed(
  () => realCompetitorIsSuccess.value || exampleCompetitorIsSuccess.value
);

const competitorPriorityError = usePriorityError(
  competitorError,
  exampleCompetitorError
);

const competitor = computed(
  () => competitorData.value ?? exampleCompetitorData.value ?? null
);

const useListingEnabled = computed(() => {
  return !!integrationId.value && !!listingId.value;
});

const {
  data: listingData,
  isSuccess: realListingIsSuccess,
  error: listingError,
} = useListing(accountId, listingId, {
  enabled: useListingEnabled,
});

const useExampleListingEnabled = computed(
  () => !integrationId.value && !!listingId.value
);

const {
  data: exampleListingData,
  isSuccess: exampleListingIsSuccess,
  error: exampleListingError,
} = useExampleListing(listingId, {
  enabled: useExampleListingEnabled,
});

const listingIsSuccess = computed(
  () => realListingIsSuccess.value || exampleListingIsSuccess.value
);

const listingPriorityError = usePriorityError(
  listingError,
  exampleListingError
);

const listing = computed(
  () => listingData.value ?? exampleListingData.value ?? null
);

const competitorsComparisonEnabled = computed(
  () => !!listingId.value && !!competitorId.value
);

const { data: comparisonData, isSuccess: comparisonIsSuccess } =
  useCompetitorsComparison(listingId, competitorId, isExampleCompetitor, {
    enabled: competitorsComparisonEnabled,
  });

const opportunities = computed(() => comparisonData.value?.opportunities ?? []);
const weaknesses = computed(() => comparisonData.value?.weaknesses ?? []);

const { getDisplayGroupRanks } = useListingRank();
</script>

<template>
  <CompetitorsPopover @close="handleClose">
    <template #header>
      <h1 class="competitor-compare__header u-headline--heavy">compare</h1>
    </template>
    <template #subheader>
      <CompetitorCompareSubheader
        :account-id="accountId"
        :listing-id="listingId"
        :competitor="competitor"
        :integration-id="integrationId"
      />
    </template>
    <template #content>
      <div class="competitor-compare__body--container">
        <div class="competitor-compare__body">
          <table class="competitor-compare__table">
            <tr v-if="listingPriorityError || competitorPriorityError">
              <td class="competitor-compare__error">
                <SoonaError v-if="listingPriorityError">
                  {{ listingPriorityError }}
                </SoonaError>
              </td>
              <td class="competitor-compare__error">
                <SoonaError v-if="competitorPriorityError">
                  {{ competitorPriorityError }}
                </SoonaError>
              </td>
            </tr>

            <tr>
              <td class="competitor-compare__header">
                <div v-if="listingIsSuccess">
                  <h3 class="u-subheader--heavy">{{ listing.name }}</h3>
                  <a
                    v-if="realListingIsSuccess"
                    href="/"
                    target="_blank"
                    class="competitor-compare__link u-label--regular"
                  >
                    {{ getDisplayGroupRanks(listing) }}
                    <SoonaIcon
                      class="competitor-compare__link--icon"
                      name="arrow-up-right-from-square"
                      size="small"
                    />
                  </a>
                </div>
              </td>
              <td class="competitor-compare__header">
                <div v-if="competitorIsSuccess">
                  <h3 class="u-subheader--heavy">{{ competitor.name }}</h3>
                  <a
                    :href="`https://www.amazon.com/dp/${competitor.catalog_item_info.asin}`"
                    target="_blank"
                    class="competitor-compare__link u-label--regular"
                  >
                    {{ getDisplayGroupRanks(competitor) }}
                    <SoonaIcon
                      class="competitor-compare__link--icon"
                      name="arrow-up-right-from-square"
                      size="small"
                    />
                  </a>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <ListingPrice
                  v-if="
                    listingIsSuccess && !listing.is_example_external_product
                  "
                  :item="listing"
                />
              </td>
              <td>
                <ListingPrice v-if="competitorIsSuccess" :item="competitor" />
              </td>
            </tr>

            <tr>
              <td>
                <MyListingOpportunities
                  v-if="listingIsSuccess && comparisonIsSuccess"
                  :listing="listing"
                  :opportunities="opportunities"
                />
              </td>
              <td>
                <div
                  v-if="competitorIsSuccess"
                  class="competitor-compare__visual-performance"
                >
                  <CompetitorWeaknesses
                    v-if="competitorIsSuccess && comparisonIsSuccess"
                    :competitor="competitor"
                    :weaknesses="weaknesses"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td class="competitor-compare__td--border-right">
                <div v-if="listingIsSuccess" class="competitor-compare__assets">
                  <img
                    v-for="asset in listing.assets"
                    :key="asset.id"
                    :src="asset.digital_asset_url"
                    class="competitor-compare__image"
                  />
                </div>
              </td>
              <td class="competitor-compare__td--border-left">
                <div
                  v-if="competitorIsSuccess"
                  class="competitor-compare__assets"
                >
                  <img
                    v-for="asset in competitor.assets"
                    :key="asset.id"
                    :src="asset.image_url"
                    class="competitor-compare__image"
                  />
                </div>
              </td>
            </tr>
          </table>

          <template v-if="listingIsSuccess && competitorIsSuccess">
            <CompetitorCompareBehind
              :listing="listing"
              :competitor="competitor"
            />
            <CompetitorCompareAhead
              :listing="listing"
              :competitor="competitor"
            />
            <CompetitorCompareTextContent
              :listing="listing"
              :competitor="competitor"
            />
          </template>
        </div>
      </div>
    </template>
  </CompetitorsPopover>
</template>

<style lang="scss" scoped>
@use '@/variables';

.competitor-compare {
  &__body {
    background-color: variables.$white-default;
  }

  &__table {
    width: 100%;

    td {
      width: 50%;
      padding: 1rem;
    }
  }

  &__td {
    &--border-right {
      border-right: 0.0625rem solid variables.$gray-30;
    }

    &--border-left {
      border-left: 0.0625rem solid variables.$gray-30;
    }
  }

  &__section {
    &--header {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 1rem;
      padding: 0 0.5rem;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &--icon {
      flex-shrink: 0;
    }
  }

  &__assets {
    display: grid;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  &__image {
    max-width: 100%;
    max-height: 9.75rem;
    border-radius: 0.3125rem;
    align-self: center;
  }

  @media (min-width: 40rem) {
    &__table {
      td {
        padding: 1.5rem;
      }
    }

    &__assets {
      grid-template-columns: repeat(
        auto-fill,
        minmax(min(10.75rem, 100%), 1fr)
      );
      justify-items: start;
    }
  }
}
</style>

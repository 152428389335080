<script setup>
import { computed } from 'vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import {
  convertToWeekdayMMDDYYYYmonthAbr,
  convertToYYYYMMDD,
  convertToHHMM12,
} from 'src/lib/date-formatters';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const props = defineProps({
  selectedDay: Date,
  currentMonth: Date,
  selectedTimeSlot: String,
  availableTimeSlots: Array,
  scheduledTime: String,
  scheduledTimeDisplay: String,
  selectedTimezone: {
    type: String,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  error: Object,
  isVertical: {
    type: Boolean,
    default: false,
  },
  dayNotSelectable: {
    type: Function,
    default: () => false,
  },
  timeNotSelectable: {
    type: Function,
    default: () => false,
  },
});
const emits = defineEmits([
  'update:currentMonth',
  'update:selectedDay',
  'update:selectedTimeSlot',
]);
dayjs.extend(utc);
dayjs.extend(timezone);

const selectedDay = computed(() => {
  return props.selectedDay;
});

const currentMonth = computed(() => {
  return props.currentMonth;
});

const selectedTimeSlot = computed(() => {
  return props.selectedTimeSlot;
});

const selectedTimezone = computed(() => {
  if (props.selectedTimezone) {
    return props.selectedTimezone;
  }
  return dayjs.tz.guess();
});

const isLoading = computed(() => {
  return props.isLoading;
});

const availableTimeSlots = computed(() => {
  if (props.availableTimeSlots) {
    return props.availableTimeSlots;
  } else if (props.selectedDay) {
    let curr = new Date(selectedDay.value);
    const timeSlots = [];
    for (let i = 7; i < 20; i++) {
      curr = dayjs(curr).utc(curr).tz(selectedTimezone.value, true).hour(i);
      timeSlots.push({
        slot: selectedTimezone.value
          ? convertToHHMM12(curr.toISOString(), selectedTimezone.value)
          : convertToHHMM12(curr.toISOString()),
        timeslot: curr.toISOString(),
      });
    }
    return timeSlots;
  } else {
    return [];
  }
});

const isSelectedDay = date => {
  if (selectedDay.value && !isLoading.value) {
    return convertToYYYYMMDD(date) === convertToYYYYMMDD(selectedDay.value);
  }
  return false;
};

const isSelectedTimeslot = timeSlot => {
  let slot = new Date(timeSlot.timeslot);
  let current = new Date(selectedTimeSlot.value);
  return slot.toString() === current.toString();
};

const daysInMonth = computed(() => {
  const month = currentMonth.value.getMonth();
  const date = new Date(currentMonth.value);
  const calendarMonth = new Array(date.getDay());
  while (date.getMonth() === month) {
    calendarMonth.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return calendarMonth;
});

const nextMonth = () => {
  const newMonth = new Date(currentMonth.value.getTime());
  newMonth.setMonth(currentMonth.value.getMonth() + 1);
  emits('update:currentMonth', newMonth);
};

const pastMonth = () => {
  const newMonth = new Date(currentMonth.value.getTime());
  newMonth.setMonth(currentMonth.value.getMonth() - 1);
  emits('update:currentMonth', newMonth);
};

const previousMonthIsInPast = computed(() => {
  const today = new Date();
  return (
    currentMonth.value.getMonth() - 1 < today.getMonth() &&
    currentMonth.value.getFullYear() <= today.getFullYear()
  );
});

const formattedDate = computed(() =>
  convertToWeekdayMMDDYYYYmonthAbr(selectedDay.value)
);

const formattedTime = time => {
  return time.replace(/\s+/g, '');
};
</script>
<template>
  <div
    class="appointment-selection"
    :class="{ 'appointment-selection--vertical': isVertical }"
  >
    <SoonaLoading
      v-if="isLoading"
      :is-contained="true"
      :is-loading="true"
      :is-dark="false"
      loading-text="loading"
    />
    <SoonaError v-if="error && error.message">
      {{ error.message }}
    </SoonaError>
    <TransitionGroup name="slide-fade">
      <div
        key="main-content"
        class="appointment-selection__date-picker animated"
      >
        <div class="appointment-selection__appt-month">
          <div class="appointment-selection__navigation-container">
            <button
              class="u-button-reset appointment-selection__month-navigation"
              data-cypress="button-month-previous"
              :disabled="previousMonthIsInPast"
              :aria-disabled="previousMonthIsInPast"
              title="previous month"
              @click="pastMonth"
            >
              <SoonaIcon name="chevron-left" size="medium" />
              <span class="u-visually-hidden">previous month</span>
            </button>
            <p class="has-text-weight-bold">
              {{
                currentMonth.toLocaleString('default', {
                  month: 'long',
                  year: 'numeric',
                })
              }}
            </p>
            <button
              class="u-button-reset appointment-selection__month-navigation"
              data-cypress="button-month-next"
              title="next month"
              @click="nextMonth"
            >
              <SoonaIcon name="chevron-right" size="medium" />
              <span class="u-visually-hidden">next month</span>
            </button>
          </div>
        </div>
        <div class="appointment-selection__calendar">
          <div class="appointment-selection__appt-week">
            <div>S<span class="u-visually-hidden">unday</span></div>
            <div>M<span class="u-visually-hidden">onday</span></div>
            <div>T<span class="u-visually-hidden">uesday</span></div>
            <div>W<span class="u-visually-hidden">ednesday</span></div>
            <div>T<span class="u-visually-hidden">hursday</span></div>
            <div>F<span class="u-visually-hidden">riday</span></div>
            <div>S<span class="u-visually-hidden">aturday</span></div>
          </div>
          <div class="appointment-selection__appt-days">
            <span
              v-for="(date, index) in daysInMonth"
              :key="index"
              class="calendar-date"
            >
              <button
                v-if="date"
                class="u-button-reset appointment-selection__appt-days-button"
                :aria-pressed="isSelectedDay(date)"
                :disabled="dayNotSelectable(date)"
                :data-cypress="`button-date-${date.getDay()}-${
                  dayNotSelectable(date) ? 'disabled' : 'enabled'
                }`"
                :title="dayNotSelectable(date) ? 'date not available' : null"
                @click="emits('update:selectedDay', date)"
              >
                <time :datetime="convertToYYYYMMDD(date)">
                  {{ date.getDate() }}
                </time>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="selectedDay"
        key="dynamic"
        class="appointment-selection__time-picker animated"
      >
        <p class="appointment-selection__current-date">
          <SoonaIcon name="calendar-solid" />
          <Transition name="slide-up" mode="out-in">
            <span
              :key="formattedDate"
              class="appointment-selection__current-date-day"
            >
              {{ formattedDate }}
            </span>
          </Transition>
          <Transition name="slide-up" mode="out-in">
            <span
              v-if="selectedTimeSlot && scheduledTime && scheduledTimeDisplay"
              :key="scheduledTime"
            >
              <span class="appointment-selection__current-date-time">
                {{ scheduledTimeDisplay }}
              </span>
            </span>
          </Transition>
        </p>
        <div class="appointment-selection__time-slot-wrapper">
          <SoonaButton
            v-for="timeSlot in availableTimeSlots"
            :key="timeSlot.timeslot"
            class="appointment-selection__time-slot"
            :aria-pressed="isSelectedTimeslot(timeSlot)"
            :disabled="timeNotSelectable(timeSlot.timeslot)"
            variation="secondary-gray"
            size="medium"
            data-cypress="button-timeslot"
            @click="emits('update:selectedTimeSlot', timeSlot.timeslot)"
          >
            <time>{{ formattedTime(timeSlot.slot) }}</time>
          </SoonaButton>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

@mixin u-calendar {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.625rem;

  @media only screen and (max-width: 768px) {
    grid-column-gap: 0.8rem;
  }

  .calendar-date {
    text-align: center;
  }
}

// default mobile styles
.appointment-selection {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  * {
    color: variables.$black-default;
  }

  &__date-picker {
    width: fit-content;
    margin: auto;
    margin-bottom: 1.25rem;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
  }

  &__appt-month {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.625rem;

    p {
      @include variables_fonts.u-body--heavy;
      text-transform: lowercase;
    }
  }

  &__navigation-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__month-navigation {
    display: flex;
    padding: 0.875rem;

    &:focus {
      box-shadow: none;
    }

    * {
      color: variables.$gray-60;
    }

    &:focus-visible,
    &:hover {
      * {
        color: variables.$black-default;
      }
    }

    &:disabled {
      cursor: not-allowed;

      * {
        color: variables.$gray-30;
      }
    }
  }

  &__calendar {
    padding: 0.625rem 1rem 1.25rem 1rem;
  }

  &__appt-week {
    margin-bottom: 0.5rem;
    @include u-calendar;

    div {
      @include variables_fonts.u-button--small-caps;

      text-align: center;
      color: variables.$gray-60;
    }
  }

  &__appt-days {
    @include u-calendar;

    &-button {
      @include variables_fonts.u-label--heavy;

      margin: auto;
      border-radius: 100%;
      padding: 0;
      width: 2rem;
      height: 2rem;
      transition:
        border-color 0.1s ease-out,
        background-color 0.1s ease-out;
      border: 0.0625rem solid transparent;

      time {
        transition: color 0.1s ease-out;
      }

      &:disabled {
        cursor: auto;

        time {
          color: variables.$gray-40;
        }
      }

      &:not(:disabled) {
        background-color: variables.$friendly-red-20;

        &:hover {
          border-color: variables.$friendly-red-50;
        }
      }

      &[aria-pressed='true'] {
        background-color: variables.$friendly-red-50 !important;

        time {
          color: variables.$white-default;
        }

        &:hover {
          border-color: transparent !important;
        }
      }

      &:focus {
        border-color: transparent;
      }
    }
  }

  &__time-picker {
    text-align: center;
    margin-bottom: 3.125rem;

    .appointment-selection--vertical & {
      margin-bottom: 1.5rem;
    }
  }

  &__time-slot-wrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0.375rem 0.5rem;
  }

  &__time-slot {
    width: 100%;

    &[aria-pressed='true'] {
      border: 0.0625rem solid variables.$black-default;
    }
  }

  &__current-date {
    @include variables_fonts.u-body--regular;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0.875rem;

    svg {
      margin-right: 0.5rem;
    }

    &-day {
      margin-right: 0.375rem;
      text-transform: lowercase;
    }

    &-time {
      border-left: 2px solid variables.$friendly-red-50;
      padding-left: 0.375rem;
    }
  }

  // desktop styles
  @media (min-width: 1290px) {
    &:not(.appointment-selection--vertical) {
      // To prevent vertical 'jumping' when calendars have 6 rows of dates
      min-height: 22.0625rem;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .appointment-selection__date-picker,
      .appointment-selection__time-picker {
        margin: 0;
      }

      .appointment-selection__date-picker {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: -2rem;
          width: 0.0625rem;
          background-color: transparent;
          transition: background-color 0.5s ease-out;
        }

        &:not(:last-child) {
          margin-right: 4rem;
          &::after {
            background-color: variables.$gray-30;
          }
        }
      }

      .appointment-selection__current-date {
        margin-bottom: 2rem;
      }
    }
  }
}

//  animations
.animated {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(1.25rem);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-1.25rem);
}
</style>

<script setup>
import CountdownBanner from './CountdownBanner.vue';
import SoonaButton from './ui_library/SoonaButton.vue';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';

defineProps({
  displayAction: {
    type: Boolean,
    default: true,
  },
  padStart: {
    type: Boolean,
    default: false,
  },
  countdownVariant: {
    type: String,
    default: 'small',
    validator: value => ['small', 'large'].includes(value),
  },
  bannerVariant: {
    type: String,
    default: 'large',
    validator: value => ['small', 'large', 'preferred'].includes(value),
  },
  isPreferred: {
    type: Boolean,
    default: false,
  },
});

const reshopOlderThanDate = import.meta.env.VITE_RESHOP_OLDER_THAN_DATE;
const checkoutDeadline = import.meta.env.VITE_RESHOP_PROMO_END_DATE;
const router = useRouter();
const sendToShopSaleContent = () => {
  router.push('/account/<accountId>/gallery/shop-more?filter=on-sale');
};
const formattedDeadlineDate = dayjs(checkoutDeadline)
  .tz('America/Chicago')
  .format('MMMM D');
const formattedreshopOlderThanDate = dayjs(reshopOlderThanDate)
  .tz('America/Chicago')
  .format('MMMM D, YYYY');
</script>

<template>
  <CountdownBanner
    v-if="bannerVariant === 'large'"
    class="banner"
    :pad-start="padStart"
    :target-date="checkoutDeadline"
    :countdown-variant="countdownVariant"
  >
    <template #content>
      <h2>all treats - no tricks: your content is on sale!</h2>

      <p>
        boo! your archived content is on sale through
        {{ formattedDeadlineDate }}. get <b>$19 photos</b> and
        <b>$73 video clips</b> on anything shot before
        {{ formattedreshopOlderThanDate }}.
      </p>

      <p v-if="isPreferred" class="preferred-warning">
        already a preferred member? reach out to
        <a class="halloween-email-link" href="mailto:sales@soona.co"
          >sales@soona.co</a
        >
        take advantage of this sale.
      </p>
    </template>

    <template v-if="displayAction" #action>
      <SoonaButton
        size="medium"
        class="banner__cta"
        variation="secondary-black"
        @click="sendToShopSaleContent"
      >
        shop sale content
      </SoonaButton>
    </template>

    <template #graphic>
      <img src="images/reshop-sale-ghost.svg" alt="halloween ghost" />
    </template>

    <template #time-suffix>left</template>
  </CountdownBanner>

  <article
    v-else-if="bannerVariant === 'preferred'"
    class="banner"
    :class="{ 'banner--preferred': bannerVariant === 'preferred' }"
  >
    <h2 class="u-title--preferred">
      👻 <b>boo!</b> want to take advantage of the content sale?
    </h2>
    <p class="u-label--regular">
      please reach out to
      <a class="halloween-email-link" href="mailto:sales@soona.co"
        >sales@soona.co</a
      >
      to get <b>$19 photos</b> and <b>$73 video clips</b> on your archived
      content!
    </p>
  </article>

  <article
    v-else
    class="banner"
    :class="{ 'banner--small': bannerVariant === 'small' }"
  >
    <p class="u-title--regular">
      👻 <b>boo!</b> your archived content is on sale through
      {{ formattedDeadlineDate }}.
    </p>

    <SoonaButton
      size="medium"
      variation="secondary-black"
      @click="sendToShopSaleContent"
    >
      shop sale content
    </SoonaButton>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.banner {
  h2 {
    @include variables_fonts.u-headline--heavy;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-display--heavy;

      font-size: 2.5rem;
    }
  }

  .u-title--preferred {
    font-size: 1rem;
  }

  p {
    max-width: 43rem;
  }

  .halloween-email-link {
    color: variables.$white-default;
    text-decoration: underline;
  }

  &__cta :deep(.soona-button__content) {
    @include variables_fonts.u-button--small-caps;
  }

  &--small,
  &--preferred {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.75rem 1rem;
    border-radius: 0.625rem;
    justify-content: space-between;
    background: variables.$gray-90;
    color: variables.$white-default;
  }

  .preferred-warning {
    margin-top: 1rem;
  }
}
</style>

import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';

/**
 *
 * @param {string | Ref<string>} [reservationId]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useReservationOrders(reservationId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.reservationOrders(reservationId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/orders?reservation_id=${toValue(reservationId)}`,
        {
          signal,
        }
      );
      return response.data;
    },
    ...queryOptions,
  });
}

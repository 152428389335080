<script setup>
import { computed, ref } from 'vue';
import AutoEditsFeedback from '@/components/crew/AutoEditsFeedback.vue';
import MoreOptionsDropdown from '@/components/crew/orders/AddOnLineItem/MoreOptionsDropdown.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  orderId: {
    required: true,
    type: [Number, String],
  },
  digitalAsset: {
    required: true,
    type: Object,
  },
  enableReview: {
    required: true,
    type: Boolean,
  },
});

const accountId = computed(() => props.accountId);
const orderId = computed(() => props.orderId);
const digitalAsset = computed(() => props.digitalAsset);

const showFeedback = ref(false);
</script>

<template>
  <div>
    <AutoEditsFeedback
      v-if="showFeedback"
      category="auto_edits_post_production"
      subject-type="DigitalAsset"
      :subject-id="digitalAsset.id"
      :open="showFeedback"
      options-type="post"
      @close="() => (showFeedback = false)"
      @confirm="confirmReject"
    />
    <MoreOptionsDropdown
      :account-id="accountId"
      :asset="digitalAsset"
      :enable-review="enableReview"
      :order-id="orderId"
      @hide-feedback="() => (showFeedback = false)"
      @show-feedback="() => (showFeedback = true)"
    />
  </div>
</template>

import {
  page,
  reservationProperties,
  structuredTags,
  track,
  userAndAccountTraits,
} from '../lib/segment/segment';

export default {
  methods: {
    $page(pageName, pageProperties = {}) {
      return page(pageName, pageProperties);
    },
    $track(eventName, eventProperties = {}) {
      return track(eventName, eventProperties);
    },
    $userAndAccountTraits(user) {
      return userAndAccountTraits(user);
    },
    $reservationProperties(properties) {
      return reservationProperties(properties);
    },
    $structuredTags(tags, tagCategories) {
      return structuredTags(tags, tagCategories);
    },
  },
};

<script setup>
import AddOnFormCategory from '@/components/user/anytime/self_serve_purchases/AddOnFormCategory.vue';
import { computed } from 'vue';

const props = defineProps({
  reservationId: {
    required: true,
    type: String,
  },
  products: {
    required: true,
    type: Array,
  },
  discountObj: {
    type: Object,
    required: false,
    default: null,
  },
});

const emits = defineEmits(['select', 'deselect', 'productPriceInfoUpdate']);

const discount = computed(() => {
  return props.discountObj;
});

const modelCopy =
  'help your customers visualize themselves with your product. add personality. movement. and increase their purchase confidence by showing a person or pet falling in love with your brand.';

const upgradeCopy =
  'add an upgrade for effortless visual creation. stylists. grocery shopping. + more will take your brand content to the next level.';
</script>

<template>
  <section class="order-builder">
    <hr />
    <AddOnFormCategory
      heading="models"
      :reservation-id="reservationId"
      :copy="modelCopy"
      :products="products"
      :discount-obj="discount"
      add-on-category="pro_service_model"
      @select="emits('select', $event)"
      @deselect="emits('deselect', $event)"
      @product-price-info-update="emits('productPriceInfoUpdate', $event)"
    ></AddOnFormCategory>
    <hr />
    <AddOnFormCategory
      heading="upgrades"
      :reservation-id="reservationId"
      :copy="upgradeCopy"
      :products="products"
      :discount-obj="discount"
      add-on-category="pro_service_upgrade"
      @select="emits('select', $event)"
      @deselect="emits('deselect', $event)"
      @product-price-info-update="emits('productPriceInfoUpdate', $event)"
    ></AddOnFormCategory>
  </section>
</template>
<style scoped lang="scss">
.order-builder {
  padding: 1rem;
}
</style>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import DownloadUploadedOrCreated from '@/components/user/anytime/gallery/media-editor/download/DownloadUploadedOrCreated.vue';
import DownloadCreatedWithPaywall from '@/components/user/anytime/gallery/media-editor/download/DownloadCreatedWithPaywall.vue';
import DownloadDeliveredAsset from '@/components/user/anytime/gallery/media-editor/download/DownloadDeliveredAsset.vue';
import SaveEdit from '@/components/user/anytime/gallery/media-editor/download/SaveEdit.vue';
import CrewDownloadUnpurchased from '@/components/user/anytime/gallery/media-editor/download/CrewDownloadUnpurchased.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { useCapability } from '@/composables/useCapability';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId, hasSubscriptionDownloadAccess } =
  storeToRefs(mediaEditorStore);

const { ownedByCustomer, createdInEditor, userUploaded } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);
const { currentlyEditing } = useMediaEditor();

// refs
const buttonCopy = computed(() =>
  currentlyEditing.value ? 'save' : 'download'
);

// use
const { linkClicked } = useBaseEvents();
const route = useRoute();

const { hasCapability: staffCanAdjustBackgroundOnClientAccount } =
  useCapability({
    capability: 'media_editor_adjust_bg_on_subscription_accounts',
  });

//click handlers
const downloadFile = ({ url, label }) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor header',
    linkLabel: label,
    linkHref: null,
  });
  window.location.href = url;
};

const clickSoonaDropDownMenu = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor header',
    linkLabel: buttonCopy.value,
    linkHref: null,
  });
};

const showSave = computed(() => {
  return currentlyEditing.value && ownedByCustomer;
});
</script>

<template>
  <SoonaDropdownMenu
    :copy="buttonCopy"
    class="download-digital-asset-menu"
    @button-action="clickSoonaDropDownMenu"
  >
    <template #default="{ clickCapture, keydown, mouseover }">
      <SaveEdit
        v-if="showSave"
        :click-capture="clickCapture"
        :keydown="keydown"
        :mouseover="mouseover"
      />
      <DownloadUploadedOrCreated
        v-else-if="
          userUploaded || (createdInEditor && hasSubscriptionDownloadAccess)
        "
        :click-capture="clickCapture"
        :keydown="keydown"
        :mouseover="mouseover"
        @click="downloadFile($event)"
      />
      <DownloadCreatedWithPaywall
        v-else-if="createdInEditor && !hasSubscriptionDownloadAccess"
        :click-capture="clickCapture"
        :keydown="keydown"
        :mouseover="mouseover"
        @click="downloadFile($event)"
      />
      <DownloadDeliveredAsset
        v-else-if="ownedByCustomer"
        :click-capture="clickCapture"
        :keydown="keydown"
        :mouseover="mouseover"
        @click="downloadFile($event)"
      />
      <CrewDownloadUnpurchased
        v-else-if="staffCanAdjustBackgroundOnClientAccount"
        :click-capture="clickCapture"
        :keydown="keydown"
        :mouseover="mouseover"
        @click="downloadFile($event)"
      />
    </template>
  </SoonaDropdownMenu>
</template>

<style lang="scss" scoped>
.download-digital-asset-menu {
  :deep(> [role='menu']) {
    padding-top: 0;
    padding-bottom: 0;

    > li:first-child > .download-option {
      border-radius: 0.3125rem 0.3125rem 0 0;
    }

    > li:last-child > .download-option {
      border-radius: 0 0 0.3125rem 0.3125rem;
    }
  }
}
</style>

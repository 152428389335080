<script setup>
import { useId, computed, nextTick, watchEffect } from 'vue';
import { useMediaQuery, useTextareaAutosize } from '@vueuse/core';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'tell us about your upcoming project',
  },
});

defineEmits(['submit']);

const input = defineModel();
const matchMediaIsWide = useMediaQuery('(min-width: 48rem)');

const id = useId();
const formId = computed(() => `${id}-form`);
const errorId = computed(() => `${id}-error`);
const textareaId = computed(() => `${id}-textarea`);
const { textarea } = useTextareaAutosize({
  input: input,
  watch: input,
  styleProp: 'minHeight',
});

const submitDisabled = computed(
  () => input.value?.trim().length > 0 && props.isLoading
);

function areaFocus() {
  if (textarea.value) {
    textarea.value.focus();
  }
}

function formReset() {
  input.value = '';
  nextTick(() => {
    textarea.value?.focus();
  });
}

watchEffect(() => {
  if (!props.isLoading) {
    nextTick(() => {
      areaFocus();
    });
  }
});

function handleKeyDown(event) {
  if (event.keyCode === 13) {
    if (event.shiftKey) {
      return;
    }

    event.preventDefault();
    const newEvent = new Event('submit', { cancelable: true });
    event.target.form.dispatchEvent(newEvent);
  }
}
</script>

<template>
  <div class="producer-textarea" tabindex="-1" @focus="areaFocus">
    <form
      :id="formId"
      :aria-label="placeholder"
      :aria-describedby="errorId"
      class="producer-textarea__form"
      @reset="formReset"
      @submit.prevent="$emit('submit', textarea.value)"
    >
      <label :for="textareaId" class="u-visually-hidden">
        {{ placeholder }}
      </label>

      <div class="producer-textarea__textarea-container">
        <textarea
          :id="textareaId"
          ref="textarea"
          v-model="input"
          :disabled="isLoading"
          :placeholder="placeholder"
          :rows="matchMediaIsWide ? 3 : 1"
          required
          name="comment"
          class="producer-textarea__textarea"
          @keydown="handleKeyDown"
        />
      </div>

      <div class="producer-textarea__actions-row">
        <div class="producer-textarea__actions-row__right">
          <slot
            name="actions"
            :submit-disabled="submitDisabled"
            :is-loading="isLoading"
          >
            <SoonaButton
              type="submit"
              size="medium"
              variation="solid-black"
              :loading="isLoading"
              :disabled="submitDisabled"
            >
              <span v-if="matchMediaIsWide">enter</span>
              <SoonaIcon v-else name="arrow-up" />
            </SoonaButton>
          </slot>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.producer-textarea {
  gap: 0.5rem;
  display: flex;
  padding: 0.125rem;
  position: relative;
  outline-offset: 0rem;
  border-radius: 0.5rem;
  box-shadow: variables.$elevation-2;
  transition: box-shadow 0.15s ease-in-out;

  background: linear-gradient(
    to right,
    variables.$periwink-blue-70,
    variables.$roses-60,
    variables.$periwink-blue-70
  );
  background-size: 200%;

  animation: shimmer 8s linear infinite;

  @keyframes shimmer {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: -200%;
    }
  }

  &:hover {
    box-shadow: variables.$elevation-4;
  }

  &__form {
    gap: 0.5rem;
    display: flex;
    flex: 1 1 auto;
    padding: 0.5rem;
    flex-direction: row;
    border-radius: 0.375rem;
    background: variables.$white-default;
  }

  &:focus-within {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  &__textarea-container {
    flex: 1;
    display: flex;
    overflow: auto;
    max-height: 8rem;
  }

  &__textarea {
    flex: 1;
    cursor: text;
    resize: none;
    border: none;
    outline: none;
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 0.1875rem 0.5rem;

    &::placeholder {
      color: variables.$gray-80;
    }

    &:disabled {
      background: variables.$white-default;
    }
  }

  &__actions-row {
    gap: 0.25rem;
    display: flex;
    align-items: end;
    flex-flow: row wrap;
    justify-content: flex-end;

    &__right {
      gap: 0.75rem;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__form {
      flex-direction: column;
    }
    &__textarea {
      font-size: 1rem;
    }
    &__actions-row {
      gap: 0.5rem;
    }
  }
}
</style>

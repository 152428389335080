<script setup>
import { computed } from 'vue';
import { useReservation } from '@/composables/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useAddSkippableTag } from '@/components/crew/booking/useAddSkippableTag';

const props = defineProps({
  reservationId: String,
  slug: String,
});

const reservationId = computed(() => props.reservationId);

const {
  proServiceSlugForSize,
  reservation,
  isLoading: isLoadingReservation,
  error,
} = useReservation(reservationId);

const {
  data: modelData,
  isLoading: isLoadingOptions,
  error: resTagsError,
} = useReservationTags(
  reservationId,
  proServiceSlugForSize,
  'pro_service_model'
);

const modelOptions = computed(() => {
  return modelData.value?.options ?? [];
});

const selectedOptions = computed(() => {
  return modelOptions.value?.filter(x => x.selected === true) ?? [];
});

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId);

useAddSkippableTag(modelData, reservation, 'models', addReservationTag);

function onSelect(option) {
  addReservationTag({ tagId: option.tag.id });
}
const onDeselect = option => {
  removeReservationTag({
    reservationTagId: option.reservation_tag.id,
    deleteObsoleted: true,
  });
};

const priorityError = usePriorityError(
  error,
  addError,
  removeError,
  resTagsError
);

const isBusy = computed(
  () =>
    isLoadingReservation.value ||
    isLoadingOptions.value ||
    isAdding.value ||
    isRemoving.value
);
</script>

<template>
  <SoonaSelect
    :model-values="selectedOptions"
    :options="modelOptions"
    :disabled="isBusy"
    :loading="isBusy"
    :selectable="option => !option.selected"
    placeholder="optional"
    multi
    data-cypress="select-models"
    @option:selecting="onSelect"
    @option:deselecting="onDeselect"
  >
    <template #label>models</template>
  </SoonaSelect>

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

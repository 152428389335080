<script setup>
import { ref, computed, watchEffect } from 'vue';
import { useStore } from 'vuex';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

const store = useStore();

const isEditing = ref(false);
const facebook = ref('');
const instagram = ref('');
const twitter = ref('');
const pinterest = ref('');
const other = ref('');
const referrer = ref('');
const referrerCustom = ref('');
const referrerList = ref([
  'search engine',
  'Instagram ad',
  'social media post',
  'shopify',
  'an article/blog',
  'Facebook ad',
  'LinkedIn',
  'event/conference',
  'TikTok',
  'from a friend',
  'other',
]);
const account = computed(() => store.state.account);

const prefillExistingData = () => {
  let referred_by =
    account.value.profile_data.lets_connect_one?.data
      ?.how_did_you_hear_about_us;
  const referrerIsCustom =
    referred_by && !referrerList.value.includes(referred_by);

  facebook.value = account.value.profile_data.lets_connect_one?.data?.facebook;
  instagram.value =
    account.value.profile_data.lets_connect_one?.data?.instagram;
  referrer.value = referrerIsCustom ? 'other' : referred_by;
  referrerCustom.value = referrerIsCustom ? referred_by : '';
  twitter.value = account.value.profile_data.lets_connect_two?.data?.twitter;
  pinterest.value =
    account.value.profile_data.lets_connect_two?.data?.pinterest;
  other.value = account.value.profile_data.lets_connect_two?.data?.other;
};

const toggleEdit = () => {
  return (isEditing.value = !isEditing.value);
};

const save = async () => {
  const referrerToShow =
    referrer.value === 'other' ? referrerCustom.value : referrer.value;

  const info = {
    lets_connect_one: {
      data: {
        facebook: facebook.value,
        instagram: instagram.value,
        how_did_you_hear_about_us: referrerToShow,
      },
      complete: true,
    },
    lets_connect_two: {
      data: {
        twitter: twitter.value,
        pinterest: pinterest.value,
        other: other.value,
      },
      complete: true,
    },
  };
  await store.dispatch('account/updateAccount', { accountParams: info });
  toggleEdit();
};

watchEffect(() => {
  if (account.value) {
    prefillExistingData();
  }
});
</script>
<template>
  <div class="social-info">
    <h3 class="u-title--heavy mb-l">social stuff</h3>
    <fieldset class="field-wrapper">
      <div class="social-info__dropdown">
        <label class="u-label--heavy" for="how_did_you_hear_about_us"
          >how did you hear about us?</label
        >
        <select
          v-if="isEditing"
          id="how_did_you_hear_about_us"
          v-model="referrer"
          class="social-select"
          placeholder="please select one"
          name="how_did_you_hear_about_us"
          expanded
        >
          <option
            v-for="(option, index) in referrerList"
            :key="index"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <p v-if="!isEditing" class="u-label--small field-read-mode">
          {{ referrer }}
        </p>
      </div>
      <SoonaTextfield
        v-if="referrer === 'other'"
        v-model="referrerCustom"
        :disabled="!isEditing"
        label="from where?"
        name="referrerCustom"
        :placeholder="isEditing ? 'from where?' : ''"
        type="text"
      />
      <SoonaTextfield
        v-model="facebook"
        :disabled="!isEditing"
        label="facebook"
        name="facebook"
        :placeholder="isEditing ? 'facebook link' : ''"
        type="text"
      />
      <SoonaTextfield
        v-model="instagram"
        :disabled="!isEditing"
        label="instagram"
        name="instagram"
        :placeholder="isEditing ? 'instagram link' : ''"
        type="text"
      />
      <SoonaTextfield
        v-model="twitter"
        :disabled="!isEditing"
        label="twitter"
        name="twitter"
        :placeholder="isEditing ? '@username' : ''"
        type="text"
      />
      <SoonaTextfield
        v-model="pinterest"
        :disabled="!isEditing"
        label="pinterest"
        name="pinterest"
        :placeholder="isEditing ? 'pinterest link' : ''"
        type="text"
      />
      <SoonaTextfield
        v-model="other"
        :disabled="!isEditing"
        label="other"
        name="other"
        :placeholder="isEditing ? 'other link' : ''"
        type="text"
      />
    </fieldset>
    <Capability
      capability-name="manage_account"
      subject-type="account"
      :subject-id="account.id"
    >
      <div class="action-btns">
        <SoonaButton v-if="!isEditing" size="medium" @on-click="toggleEdit">
          edit
        </SoonaButton>
        <SoonaButton v-else class="save-btn" size="medium" @on-click="save">
          save
        </SoonaButton>
      </div>
    </Capability>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.social-info {
  background-color: variables.$white-default;
  border-radius: 0.75rem;
  box-shadow: variables.$elevation-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;

  .field-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 0;
    width: 100%;
  }

  .social-select {
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$white-default;
    border-radius: 0.375rem;
    box-shadow: variables.$elevation-1;
    height: 1.875rem;
    padding: 0.5em 0.75em;
    width: 100%;

    &:focus {
      border-color: variables.$gray-90;
    }
  }

  .field-read-mode {
    background: variables.$gray-10;
    border: none;
    border-radius: 0.375rem;
    height: 1.875rem;
    overflow: hidden;
    padding: 0.5em 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__dropdown {
    margin: 1rem 0;
  }

  .action-btns {
    margin: auto;
    width: max-content;
  }

  .save-btn {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }
}
</style>

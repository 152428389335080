<template>
  <div
    class="card-grid"
    :class="{ upcoming: isUpcoming }"
    :data-cypress="`card-reservation-${reservation.id}`"
  >
    <div class="image-item">
      <img
        :src="reservation.location.space_image_url"
        alt="location"
        class="upcoming-location-image"
      />
    </div>

    <div class="date-item">
      <div class="item-row">
        <div class="gallery-card-badges">
          <GalleryCardBadge :reservation="reservation" :type="`bookingType`" />
          <GalleryCardBadge
            :reservation="reservation"
            :type="`scheduleStatus`"
          />
        </div>
        <SoonaButton
          v-if="displayTrashButton"
          :disabled="isRemovingDraft"
          title="remove booking"
          variation="icon-plain-gray"
          size="medium"
          @on-click="removeDraft"
        >
          <SoonaIcon name="trash" />
        </SoonaButton>
      </div>
      <div v-if="reservation.start" class="upcoming-card-text">
        <h2 class="reservation-date">
          <p class="day">{{ convertToDayLong(reservation.start) }}</p>
          <p class="date-divider">|</p>
          <p class="full-date">
            {{ convertToMMDDYYYYmonthAbr(reservation.start) }}
          </p>
        </h2>
        <h2 class="reservation-time">
          @ {{ convertToHHMM12(reservation.start) }} {{ browserTimeZone() }}
        </h2>
        <h2 class="reservation-name" data-cypress="card-reservation-name">
          {{ reservation.name }}
        </h2>
      </div>
      <div v-else-if="!unscheduledDraft" class="upcoming-card-text">
        <h2 class="reservation-date">
          <p class="reservation-time">@tbd</p>
        </h2>
        <h2 class="reservation-name" data-cypress="card-reservation-name">
          {{ reservation.name }}
        </h2>
      </div>
      <div v-else class="upcoming-card-text">
        <h2 class="reservation-name" data-cypress="card-reservation-name">
          {{ reservation.name }}
        </h2>
        <div v-if="type === 'suggested_draft'" class="reservation-details">
          <p>
            created by: <strong>{{ reservation.creator }}</strong>
          </p>
          <p v-if="!reservation.previous_id">
            expires on:
            <strong>{{ convertToMMDDYYYYlong(reservation.expiration) }}</strong>
          </p>
          <a
            v-if="reservation.previous_id"
            :href="`/user/anytime#/reservation/${reservation.previous_id}/info`"
          >
            view related shoot
          </a>
        </div>
      </div>
      <div class="reservation-shoot-type">
        <SoonaIcon v-if="shootType === 'photo'" name="camera" size="x-small" />
        <SoonaIcon v-if="shootType === 'video'" name="video" size="x-small" />
        {{ shootType }}
      </div>
    </div>
    <!-- only show action button on non-upcoming bookings -->
    <div v-if="type" class="action-button-item">
      <BookingActionButton
        :current-reservation="reservation"
        :is-dashboard="true"
      />
    </div>
    <SoonaIcon v-else class="chevron-button" name="chevron-right" />
  </div>
</template>

<script>
import BookingActionButton from 'src/components/user/anytime/dashboard/BookingActionButton.vue';
import GalleryCardBadge from 'src/components/user/anytime/dashboard/GalleryCardBadge.vue';
import dateTimeMixin from 'src/mixins/dateTimeMixin';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useRemoveDraft } from '@/queries/reservations/useRemoveDraft';
import { computed } from 'vue';
import { useCapability } from '@/composables/useCapability';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

export default {
  components: {
    SoonaButton,
    BookingActionButton,
    GalleryCardBadge,
    SoonaIcon,
  },
  mixins: [dateTimeMixin],
  props: {
    reservation: Object,
    ownAccount: Boolean,
    type: String,
  },
  setup(props) {
    const { mutate: removeDraft, isPending: isRemovingDraft } = useRemoveDraft(
      computed(() => props.reservation.id)
    );

    const { hasCapability: canRemoveReservationDraft } = useCapability({
      capability: 'remove_reservation_draft',
    });

    return {
      canRemoveReservationDraft,
      removeDraft,
      isRemovingDraft,
    };
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    unscheduledDraft() {
      return (
        !this.reservation.start &&
        (this.reservation.draft_type === 'client_draft' ||
          this.reservation.draft_type === 'suggested_draft')
      );
    },
    isUpcoming() {
      return this.reservation.status !== 'draft';
    },
    displayTrashButton() {
      return (
        (this.canRemoveReservationDraft || this.ownAccount) &&
        (this.type === 'client_draft' || this.type === 'suggested_draft')
      );
    },
    shootType() {
      return this.reservation.shoot_type;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.card-grid {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-auto-rows: auto;
  grid-gap: 0.5rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem;
  margin: 1rem 0.25rem;
  min-height: 12.5rem;
  position: relative;

  &.upcoming:hover {
    background-color: variables.$gray-10;
  }

  @media (min-width: variables.$screen-sm-min) {
    grid-template-columns: 0.75fr 0.5fr 0.75fr;
    grid-gap: 1rem;
  }
}

.upcoming-location-image {
  border-radius: 0.625rem 0 0 0.625rem;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.date-item {
  grid-area: 1 / 2 / 2 / 3;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-top: 1.5rem;

  @media (min-width: variables.$screen-sm-min) {
    grid-area: 1 / 2 / 2 / 4;
  }
}

.item-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;

  @media (min-width: variables.$screen-sm-min) {
    margin-bottom: 1.125rem;
  }
}

.image-item {
  grid-area: 1 / 1 / 4 / 2;
  min-width: 100%;

  @media (min-width: variables.$screen-sm-min) {
    grid-area: 1 / 1 / 3 / 2;
    min-width: inherit;
  }
}

.gallery-card-badges {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: -0.4375rem;

  @media (min-width: variables.$screen-sm-min) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
  }
}

.action-button-item {
  grid-area: 2 / 3 / 3 / 4;
  padding-bottom: 24px;

  @media (min-width: variables.$screen-sm-min) {
    grid-area: 3 / 2 / 4 / 3;
  }
}

.reservation-name,
.reservation-time {
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.25px;
  line-height: 26px;
  overflow: hidden;
  padding-right: 0.5rem;
  text-overflow: ellipsis;
}
.reservation-date {
  display: inline-flex;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.25px;
  font-weight: 800;
  padding-right: 1rem;
  text-transform: lowercase;
  .day {
    font-weight: 400;
  }
  .date-divider {
    color: variables.$friendly-red-50;
    margin: 0 5px;
  }
}

.reservation-details {
  font-size: 14px;
  line-height: 18px;

  a {
    text-decoration: underline;
  }
}
.reservation-shoot-type {
  @include variables_fonts.u-label--regular;

  align-items: center;
  color: variables.$gray-60;
  display: flex;
  gap: 0.25rem;
  margin-top: 0.5rem;
}
.chevron-button {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: variables.$gray-60;
}
@media only screen and (max-width: 480px) {
  .reservation-name,
  .reservation-time {
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
    letter-spacing: 0.25px;
  }
  .reservation-date {
    display: inline-flex;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    font-weight: 800;
    text-transform: lowercase;
    .day {
      font-weight: 400;
    }
    .date-divider {
      color: variables.$friendly-red-50;
      margin: 0 3px;
    }
  }
}
</style>

<template>
  <div class="EditInventoryPackage">
    <InventoryPackageForm
      :inventory-package="currentPackage"
      :on-save="handleSave"
      :errors="errors"
      :disabled="loading"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';

import InventoryPackageForm from './InventoryPackageForm.vue';

export default {
  name: 'EditInventoryPackage',
  components: {
    InventoryPackageForm,
  },
  data() {
    return {
      packageId: '',
      errors: undefined,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      currentPackage: state => state.inventoryPackages.currentPackage,
    }),
  },
  watch: {
    currentPackage: function (newData) {
      this.packageId = newData.id;
    },
  },
  mounted() {
    this.loadPackage({ packageId: this.$route.params.packageId });
  },
  methods: {
    ...mapActions('inventoryPackages', ['loadPackage', 'updatePackage']),
    async handleSave(params) {
      try {
        this.loading = true;
        this.errors = undefined;
        await this.updatePackage({
          packageId: this.packageId,
          packageAttributes: params,
        }).then(([, errors]) => {
          if (errors) {
            this.errors = errors;
          } else if (params.routeQuery) {
            this.$router.push(
              `/shipping_summary?location=${this.$route.query.location}`
            );
          } else {
            this.$router.go(-1);
          }
        });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(
            new Error(`Updating package (id: ${this.packageId} failed`),
            {
              extra: { error },
            }
          );
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.EditInventoryPackage {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

import { readonly, ref } from 'vue';

/**
 * @param {Ref<Symbol>} activeComponentRef
 */
export const useUnsavedChangesWarning = activeComponentRef => {
  const showWarning = ref(false);

  const continueConfirmed = ref(false);

  const warningParams = ref({
    message: '',
    route: null,
    action: null,
  });

  const handleShowWarning = ({ message, route, action }) => {
    showWarning.value = true;
    warningParams.value = {
      message,
      route,
      action,
    };
  };

  const resetWarning = () => {
    continueConfirmed.value = false;
    showWarning.value = false;
    warningParams.value = {
      message: '',
      route: null,
      action: null,
    };
  };

  const checkForUnsavedChanges = ({ action, route }) => {
    const { warn, message } = (typeof activeComponentRef.value
      ?.warnBeforeClose !== 'undefined' &&
      activeComponentRef.value?.warnBeforeClose()) || { warn: false };

    // user has already confirmed they want to continue
    // or there is not active component
    // or the active component does not have a warnBeforeClose method
    if (continueConfirmed.value || !activeComponentRef.value || !warn) {
      if (route) {
        return true;
      } else if (action) {
        resetWarning();
        return action;
      }
    } else {
      handleShowWarning({
        message: message || null,
        route: route,
        action: action,
      });
      showWarning.value = true;
      return false;
    }
  };

  return {
    continueConfirmed: readonly(continueConfirmed),
    setContinueConfirmed: value => (continueConfirmed.value = value),
    showWarning: readonly(showWarning),
    setShowWarning: value => (showWarning.value = value),
    warningParams: readonly(warningParams),
    handleShowWarning,
    resetWarning,
    checkForUnsavedChanges,
  };
};

<script setup>
import { computed } from 'vue';
import { useGetAllEditHistory } from '@/queries/useGetAllEditHistory';
import OrderPreviewCard from '@/components/user/anytime/reservation/OrderPreviewCard.vue';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const reservationId = computed(() => props.reservationId);

const { data: orders } = useGetAllEditHistory(reservationId, {
  order_status: 'paid',
  re_edit_status: 'submitted',
});
</script>

<template>
  <section v-if="orders?.length > 0" class="paid-order">
    <h2 class="paid-order__heading">recent editing orders:</h2>
    <ul class="paid-order__assets">
      <template v-for="order in orders" :key="order.id">
        <li>
          <OrderPreviewCard :order="order" />
        </li>
      </template>
    </ul>
  </section>
</template>

<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.paid-order {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  &__heading {
    @include variables_fonts.u-body--heavy;

    margin-bottom: 0.75rem;
  }

  &__assets {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  @media (min-width: variables.$screen-sm-min) {
    &__heading {
      @include variables_fonts.u-subheader--heavy;

      margin-bottom: 1rem;
    }

    &__assets {
      gap: 1rem;
    }
  }
}
</style>

<template>
  <CompleteProfileCard
    class="account-address"
    :disabled="disabled"
    :show-skip="false"
    @next="saveAddress"
  >
    <form class="account-form" @keydown.enter.prevent>
      <fieldset class="address-wrapper">
        <legend class="u-visually-hidden">address</legend>
        <div class="field-wrapper">
          <SoonaTextfield
            v-model="street1"
            class="address-input street1"
            label="street address"
            name="street address"
            placeholder="1235 S Broadway"
          />
          <SoonaTextfield
            v-model="street2"
            class="address-input street2"
            label="apt or ste"
            name="apt or ste"
            placeholder="#101"
          />
        </div>
        <SoonaTextfield
          v-model="city"
          class="address-input"
          label="city"
          name="city"
          placeholder="Denver"
        />
        <div class="field-wrapper">
          <SoonaTextfield
            v-model="state"
            class="address-input state"
            label="state"
            name="state"
            placeholder="CO"
          />
          <SoonaTextfield
            v-model="zip"
            class="address-input zip-code"
            label="zip code"
            name="zip code"
            placeholder="80210"
          >
            <template #tooltip>
              <SoonaHelperText>
                enter 00000 if you don’t have a zip code
              </SoonaHelperText>
            </template>
          </SoonaTextfield>
        </div>
        <SoonaSelect v-model="country" :options="formattedCountryList">
          <template #label>country</template>
        </SoonaSelect>
      </fieldset>
    </form>
  </CompleteProfileCard>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'AccountAddress',
  components: {
    CompleteProfileCard,
    SoonaHelperText,
    SoonaSelect,
    SoonaTextfield,
  },
  data() {
    return {
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    ...mapGetters('account', ['countriesList']),
    formattedCountryList() {
      return this.countriesList.map(country => ({
        label: `${country.emoji} ${country.name}`,
        value: country.code,
      }));
    },
    disabled() {
      return this.zip === undefined || this.zip === '' || !this.country;
    },
  },
  watch: {
    account: function () {
      this.prefillExistingData();
    },
  },
  mounted() {
    this.prefillExistingData();
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    prefillExistingData() {
      this.street1 = this.account.address?.street1;
      this.street2 = this.account.address?.street2;
      this.city = this.account.address?.city;
      this.state = this.account.address?.state;
      this.zip = this.account.address?.zip;
      this.country = this.account.address?.country;
    },
    async saveAddress() {
      const info = {
        address: {
          street1: this.street1,
          street2: this.street2,
          city: this.city,
          state: this.state,
          zip: this.zip,
          country: this.country,
        },
        address_card: {
          complete: true,
        },
      };
      try {
        await this.updateAccount({ accountParams: info });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(
            new Error('Updating account address failed'),
            {
              extra: { error },
            }
          );
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.account-address {
  .address-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1rem;
  }

  .address-input {
    padding-bottom: 0;
  }

  .field-wrapper {
    display: flex;
    gap: 0.25rem;
  }

  .street1 {
    width: 70%;
  }

  .street2 {
    width: 30%;
  }

  .state {
    width: 30%;
  }

  .zip-code {
    width: 70%;
  }
}
</style>

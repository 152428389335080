import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { updateUserAvatar } from '@/api/users';
import { useMe } from '@/composables/user/useMe';

/**
 *
 * @param {number | Ref<number>} userId
 */
export function useUpdateUserAvatar(userId) {
  const queryClient = useQueryClient();
  const { currentUserId, invalidateMe } = useMe();

  return useMutation({
    mutationFn: ({ avatarSignedId }) =>
      updateUserAvatar(unref(userId), avatarSignedId),
    onSuccess: async result => {
      const promises = [
        queryClient.invalidateQueries({
          queryKey: queryKeys.user(result.id),
        }),
      ];

      if (result.id === currentUserId.value) {
        promises.push(invalidateMe());
      }

      await Promise.all(promises);
    },
  });
}

import { toValue } from 'vue';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { mapReservationPaginationHeaders } from '@/lib/api-transforms';

const getNextPageParam = lastPage => {
  const pagination = lastPage?.pagination;

  if (!pagination) return null;
  if (pagination.currentPage >= pagination.totalPages) return null;

  return pagination.currentPage + 1;
};

const getPreviousPageParam = firstPage => {
  const pagination = firstPage?.pagination;

  if (!pagination) return null;
  if (pagination.currentPage <= 1) return null;

  return pagination.currentPage - 1;
};

/**
 *
 * @param {MaybeRef<string | number>} accountId
 * @param {Object} params
 * @param {MaybeRef<Object>} [params.filters]
 * @param {MaybeRef<number>} [params.itemsPerPage]
 * @param {MaybeRef<string>} [params.searchQuery]
 * @param {MaybeRef<string>} [params.sortBy]
 * @param {MaybeRef<string>} [params.sortDirection]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 * */
export function useInfiniteShopMore(
  accountId,
  { filters, itemsPerPage = 10, searchQuery, sortBy, sortDirection },
  queryOptions
) {
  return useInfiniteQuery({
    queryKey: queryKeys.shopMoreDigitalAssets(accountId, {
      filters,
      itemsPerPage,
      searchQuery,
      sortBy,
      sortDirection,
      infinite: true,
    }),
    queryFn: async ({ signal, pageParam }) => {
      const params = { page: pageParam };

      if (itemsPerPage) params['items'] = toValue(itemsPerPage);
      if (searchQuery) params['search_query'] = toValue(searchQuery);
      if (sortBy) params['order_by'] = toValue(sortBy).replace(/\s+/g, '_');
      if (sortDirection) params['direction'] = toValue(sortDirection);
      if (filters) {
        if (toValue(filters)['collection_type']) {
          params['collection_type'] = toValue(filters)['collection_type'];
        }
        if (toValue(filters)['created_before_datetime']) {
          params['created_before_datetime'] =
            toValue(filters)['created_before_datetime'];
        }
      }

      const response = await http.get(
        `/accounts/${toValue(accountId)}/shop_more.json`,
        { params, signal }
      );

      return {
        pagination: mapReservationPaginationHeaders(response.headers),
        assets: response.data || [],
      };
    },
    initialPageParam: 1,
    getNextPageParam,
    getPreviousPageParam,
    ...queryOptions,
  });
}

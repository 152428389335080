<template>
  <CompleteProfileCard
    class="company-logo"
    :disabled="disabled"
    @next="saveBrandAvatar"
    @skip="skipCard"
  >
    <template #heading>upload your company logo</template>
    <template #subheading>transparent .png files work best!</template>
    <div v-if="!account.logo_url" class="uploader-wrapper">
      <SoonaUpload
        class-overide="action-btn"
        :upload-complete="handleUploadComplete"
        :hide-icon="false"
        :is-multiple="false"
        label="UPLOAD FILE"
        accept=".jpeg, .jpg, .png, .gif"
      />
      <beat-loader
        v-if="loading"
        color="#FFFFFF"
        size="10px"
        class="avatar-loader"
      />
    </div>
    <div v-else class="uploader-wrapper">
      <div class="company-logo-wrapper">
        <img class="company-logo" :src="account.logo_url" alt="company logo" />
      </div>
    </div>
  </CompleteProfileCard>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';

export default {
  name: 'CompanyLogo',
  components: { CompleteProfileCard, SoonaUpload },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({ account: state => state.account }),
    disabled() {
      return !this.account.logo_url;
    },
  },
  methods: {
    ...mapActions('account', ['createCompanyLogoFromUpload', 'updateAccount']),
    handleUploadComplete: function (blob) {
      this.loading = true;
      this.createCompanyLogoFromUpload({
        accountId: this.account.id,
        blob: blob,
      });
    },
    ready() {
      this.loading = false;
    },
    async saveBrandAvatar() {
      const info = {
        company_logo: {
          complete: true,
        },
      };
      await this.updateAccount({ accountParams: info });
    },
    async skipCard() {
      let info = {
        company_logo: {
          skip: true,
        },
      };
      await this.updateAccount({ accountParams: info });
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';

.company-logo {
  .uploader-wrapper {
    align-items: center;
    background: variables.$gray-10;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 1rem;
    width: 100%;

    .soona-uploader {
      height: auto;
    }
  }

  .company-logo-wrapper {
    height: 6.875rem;
    width: 7.8125rem;
  }

  .company-logo {
    height: 100%;
    object-fit: contain;
  }

  .avatar-loader {
    left: 2.6875rem;
    position: absolute;
    top: 3.6875rem;
  }

  .action-btn {
    background: variables.$periwink-blue-50;
    border-radius: 0.375rem;
    box-shadow: variables.$elevation-1;
    color: variables.$white-default;
    display: flex;
    padding: 1rem;
  }
}
</style>

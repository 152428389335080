<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import BaseProductPicker from '@/components/user/anytime/products/picker/BaseProductPicker.vue';
import ShopifyPublishImageSelector from '@/components/user/anytime/products/shopify/ShopifyPublishImageSelector.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import PickerProductOption from '@/components/user/anytime/products/picker/PickerProductOption.vue';
import ProductPickerGroupTitle from '@/components/user/anytime/products/picker/ProductPickerGroupTitle.vue';
import { useUpdateCatalogItemShopifyProduct } from '@/queries/useCatalog';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  file: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close', 'asset-published']);
const accountId = computed(() => props.accountId);
const file = computed(() => props.file);
const fileId = computed(() => file.value?.id);
const searchRef = ref(null);
const selectedProduct = ref(null);
const imagesPayload = ref(false);

const { linkClicked } = useBaseEvents();
const route = useRoute();

const { mutate: updateShopifyProduct, isPending: isUpdating } =
  useUpdateCatalogItemShopifyProduct(accountId, selectedProduct);

watchEffect(() => {
  if (searchRef.value) {
    searchRef.value.focus();
  }
});

const search = ref('');

function closeDialog() {
  emit('close');
}

function select(productId) {
  selectedProduct.value = productId;
}

function groupProducts(catalogItems) {
  return catalogItems.reduce((acc, item) => {
    const itemCopy = { ...item };
    let group = itemCopy.attached_to_digital_asset_id
      ? 'this asset'
      : 'more products';

    itemCopy.buttonVariation =
      group === 'this asset' ? 'primary' : 'secondary-black';
    acc[group] ??= [];
    acc[group].push(itemCopy);
    return acc;
  }, {});
}

function goBackToProductSelect() {
  selectedProduct.value = null;
  imagesPayload.value = null;
}

function slotSelected(payload) {
  imagesPayload.value = payload;
}

function publishToShopify() {
  if (route.meta.context) {
    linkClicked({
      context: route.meta.context,
      subContext: 'shopify publish dialog',
      linkLabel: 'publish',
      linkHref: '#publish-to-shopify',
    });
  }
  updateShopifyProduct(
    { images: imagesPayload.value },
    {
      onSuccess: () => {
        emit('asset-published', {
          variation: 'success',
          message: 'successfully published to Shopify product',
          noDismissButton: true,
        });
      },
      onError: () => {
        emit('asset-published', {
          variation: 'error',
          message:
            'we are having trouble publishing to your Shopify product. please try again later.',
          noDismissButton: true,
        });
      },
    }
  );
}

const orderBy = computed(() => {
  return fileId.value ? 'attached_to_digital_asset_id' : 'name';
});
</script>
<template>
  <SoonaDialog max-width="48rem" top-affixed @close="closeDialog">
    <template #heading>publish to product or variant</template>
    <template #header>
      <SoonaTextfield
        v-if="!selectedProduct"
        ref="searchRef"
        v-model="search"
        type="search"
        label="search products"
        name="products-search"
        placeholder="product name or SKU"
        hide-label
        class="shopify-publish-dialog__search"
      >
        <template #icon-left>
          <SoonaIcon name="search" size="small" />
        </template>
      </SoonaTextfield>
    </template>
    <BaseProductPicker
      v-if="!selectedProduct"
      :account-id="accountId"
      :attached-to-digital-asset-id="fileId"
      :multiselect="false"
      :show-create="false"
      :search="search"
      :include-variants="true"
      filter="only_shopify_products_and_variants"
      :order-by="orderBy"
    >
      <template #product-options="{ catalogItems }">
        <template
          v-for="(items, group) in groupProducts(catalogItems)"
          :key="group"
        >
          <ProductPickerGroupTitle>{{ group }}</ProductPickerGroupTitle>
          <PickerProductOption
            v-for="product in items"
            :key="product.id"
            :product="product"
            :button-variation="product.buttonVariation"
            @select="select"
          />
        </template>
      </template>
    </BaseProductPicker>
    <ShopifyPublishImageSelector
      v-if="selectedProduct"
      :account-id="accountId"
      :catalog-item-id="selectedProduct"
      :file="file"
      :product-updating="isUpdating"
      @slot-selected="slotSelected"
    />
    <template v-if="selectedProduct" #footer>
      <SoonaButton
        type="button"
        variation="tertiary"
        @on-click="goBackToProductSelect()"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        variation="primary"
        :loading="isUpdating"
        :disabled="!imagesPayload || imagesPayload.length === 0"
        @on-click="publishToShopify()"
      >
        publish
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
.shopify-publish-dialog {
  &__search {
    margin-top: 1rem;
    padding-bottom: 0;
  }
}
</style>

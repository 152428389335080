import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getAuthToken } from '@/api/users';
import { queryKeys } from '@/queries/query-keys';

/**
 *
 * @param {number | Ref<number>} userId
 */
export function useGetAuthToken(userId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.userAuthToken(userId),
    queryFn: ({ signal }) => getAuthToken(unref(userId), signal),
    ...queryOptions,
  });
}

import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { getTierBySlug } from '@/api/tiers';
import { unref } from 'vue';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from '@/queries/query-client';

/**
 * @param {MaybeRef<string>} tierSlug
 * @param {Object} [queryOptions]
 */
export function useTierBySlug(tierSlug, queryOptions) {
  return useQuery({
    queryKey: queryKeys.tierBySlug(tierSlug),
    queryFn: async ({ signal }) => await getTierBySlug(unref(tierSlug), signal),
    // fresh for 3 seconds to use prefetch data
    staleTime: 3 * 1000,
    ...queryOptions,
  });
}

export async function prefetchTierBySlug(tierSlug) {
  await queryClient.prefetchQuery({
    queryKey: queryKeys.tierBySlug(tierSlug),
    queryFn: ({ signal }) => getTierBySlug(tierSlug, signal),
    retry: false,
  });
  return queryClient.getQueryData(queryKeys.tierBySlug(tierSlug));
}

<script setup>
import { provide, ref } from 'vue';
import { SOONA_UNSENT_RESERVATION_CHAT_PROVIDE_KEY } from './soona-unsent-reservation-chat-provide-key';

const userId = ref();
const reservationId = ref();
const unsentMessage = ref('');

function addUnsentMessage(
  message,
  { currentUserId, currentReservationId } = {}
) {
  unsentMessage.value = message;
  userId.value = currentUserId;
  reservationId.value = currentReservationId;
}

function clearUnsentMessage() {
  userId.value = undefined;
  reservationId.value = undefined;
  unsentMessage.value = '';
}

function getUnsentMessage({ currentUserId, currentReservationId } = {}) {
  const message = unsentMessage.value;
  if (
    Number(currentUserId) === Number(userId.value) &&
    Number(currentReservationId) === Number(reservationId.value)
  ) {
    clearUnsentMessage();
    return message;
  }

  clearUnsentMessage();
  return '';
}

provide(SOONA_UNSENT_RESERVATION_CHAT_PROVIDE_KEY, {
  addUnsentMessage,
  getUnsentMessage,
});
</script>

<template>
  <slot />
</template>

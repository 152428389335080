<script setup>
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaUploadProgressModal from 'src/components/uploader/SoonaUploadProgressModal.vue';
import { useUploader } from 'src/composables/useUploader.js';
import { ref, watch, computed } from 'vue';
const props = defineProps({
  uploadComplete: {
    type: Function,
  },
  uploadError: {
    type: Function,
  },
  validate: {
    type: Function,
  },
  isMultiple: {
    type: Boolean,
  },
  label: {
    type: String,
    default: '',
  },
  accept: {
    type: String,
  },
  classOveride: {
    type: String,
  },
  hideIcon: {
    type: Boolean,
  },
  hideLabel: {
    type: Boolean,
  },
  cameraIcon: {
    type: Boolean,
  },
  fileSizeLimit: {
    type: Number,
  },
  iconSizeOveride: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['percentage-complete']);
const maxConcurrent = ref(5);
const uploadInterval = ref(100);
const {
  handleUpload,
  percentComplete,
  startUpload,
  cancelUploads,
  activeUploads,
} = useUploader(
  maxConcurrent,
  uploadInterval,
  computed(() => props.fileSizeLimit),
  computed(() => props.validate),
  computed(() => props.uploadError),
  computed(() => props.uploadComplete)
);

watch(percentComplete, () => {
  emits('percentage-complete', percentComplete);
});
</script>
<template>
  <div class="soona-uploader">
    <div
      v-show="!activeUploads.length"
      class="clickable-area file is-danger justify-center"
    >
      <label class="file-label">
        <input
          class="file-input"
          type="file"
          name="resume"
          :accept="accept"
          :multiple="isMultiple"
          @change="handleUpload"
        />
        <span :class="classOveride || 'file-cta'">
          <span v-if="!hideIcon" :class="classOveride ? 'icon-wrapper' : ''">
            <SoonaIcon
              :name="cameraIcon ? 'camera-solid' : 'cloud-up-arrow'"
              :class="{ 'large-icon': iconSizeOveride }"
            />
          </span>
          <span v-if="!hideLabel" class="file-label" v-html="label"></span>
        </span>
      </label>
    </div>
    <progress
      v-if="activeUploads.length"
      class="progress"
      :value="percentComplete"
      max="100"
    >
      {{ percentComplete }}%
    </progress>
    <SoonaUploadProgressModal
      :active-uploads="activeUploads"
      :is-multiple="isMultiple"
      :label="label"
      :hide-label="hideLabel"
      @start-upload="startUpload"
      @cancel-uploads="cancelUploads"
    />
  </div>
</template>
<style lang="scss">
@use '@/variables';

.progress {
  top: 0.625rem;
  position: relative;
}
.clickable-area {
  height: 100%;
}
.upload-item-button {
  font-size: 1rem;
  .icon-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .file-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    svg {
      height: 1rem;
      width: 1rem;

      &.large-icon {
        height: 2rem;
        width: 2rem;
      }
    }
  }
}
.icon-wrapper {
  svg {
    display: flex;
    height: 1rem;
    margin: auto;
    width: 1rem;

    &.large-icon {
      height: 2rem;
      width: 2rem;
    }
  }
}
.retry {
  color: variables.$white-default;
  background-color: variables.$friendly-red-50;
}
</style>

<style lang="scss" scoped>
.soona-uploader {
  display: inline-block;
  font-size: 0.625rem;
  min-height: 1.75rem;
  letter-spacing: 0.0625rem;
  min-width: 6.25rem;
}

.file-label:not(:first-child) {
  margin-left: 0.5rem;
}
</style>

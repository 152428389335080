import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<number>} reservationId
 * @param {number | Ref<number>} reservationDigitalAssetId
 * @param {UseQueryOptions} queryOptions
 */
export function useGetReservationDigitalAssetMediaAddOns(
  reservationId,
  reservationDigitalAssetId,
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.reservationDigitalAssetMediaAddOns(
      reservationId,
      reservationDigitalAssetId
    ),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/reservations/${toValue(reservationId)}/reservation_digital_assets/${toValue(reservationDigitalAssetId)}/available_media_add_ons.json`,
        {
          signal,
        }
      );
      return response.data;
    },
    ...queryOptions,
  });
}

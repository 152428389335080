<script setup>
import { computed, ref } from 'vue';
import { useReservation } from '@/composables/useReservation';
import { useReEditsCollection } from '@/composables/re_edits_collection/useReEditsCollection';
import ClientReEdits from '@/components/user/anytime/reservation/re_edits/ClientReEdits.vue';
import EditorsReEdits from '@/components/user/anytime/reservation/re_edits/EditorsReEdits.vue';
import { useCapabilities } from '@/composables/useCapabilities';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const reservationId = computed(() => props.reservationId);

const { reservation, activeReEditsCollectionId } =
  useReservation(reservationId);

const accountId = computed(() => reservation.value?.account_id);

const { isSubmitted } = useReEditsCollection(
  accountId,
  activeReEditsCollectionId,
  {
    enabled: computed(
      () => !!accountId.value && !!activeReEditsCollectionId.value
    ),
  }
);

const capabilitiesRef = ref([{ capability: 'soona_staff' }]);

const capabilities = useCapabilities(capabilitiesRef);
</script>
<template>
  <div v-if="activeReEditsCollectionId">
    <EditorsReEdits
      v-if="isSubmitted && capabilities.soona_staff.hasCapability"
      :account-id="accountId"
      :collection-id="activeReEditsCollectionId"
    />
    <ClientReEdits
      v-else-if="!isSubmitted"
      :account-id="accountId"
      :collection-id="activeReEditsCollectionId"
    />
  </div>
</template>

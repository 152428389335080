<script setup>
import { computed } from 'vue';

const props = defineProps({
  pages: {
    type: Number,
    default: 0,
    required: false,
  },
  page: {
    type: Number,
    default: 0,
    required: false,
  },
});
const emit = defineEmits(['pageChange']);

const visiblePages = computed(() => {
  return [props.page - 1, props.page, props.page + 1].filter(
    page => ![0, props.pages + 1].includes(page)
  );
});

function handlePageChange(newPage) {
  emit('pageChange', newPage);
}
</script>

<template>
  <nav
    v-if="pages > 1 && page > 0"
    class="paginator-small"
    aria-label="pagination"
  >
    <button
      class="u-button-reset pagination-button"
      :disabled="page === 1"
      aria-label="go to first page"
      @click="() => handlePageChange(1)"
    >
      &lsaquo;&lsaquo;
    </button>
    <button
      v-if="page > 1"
      class="u-button-reset pagination-button"
      aria-label="go to previous page"
      @click="() => handlePageChange(page - 1)"
    >
      &lsaquo;
    </button>
    <button
      v-for="index in visiblePages"
      :key="index"
      class="u-button-reset pagination-button"
      :disabled="index === page"
      :aria-current="index === page ? 'page' : false"
      :aria-label="`go to page ${index}`"
      @click="() => handlePageChange(index)"
    >
      {{ index }}
    </button>
    <button
      v-if="page < pages"
      class="u-button-reset pagination-button"
      aria-label="go to next page"
      @click="() => handlePageChange(page + 1)"
    >
      &rsaquo;
    </button>
    <button
      class="u-button-reset pagination-button"
      :disabled="page === pages"
      aria-label="go to last page"
      @click="() => handlePageChange(pages)"
    >
      &rsaquo;&rsaquo;
    </button>
  </nav>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.paginator-small {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .pagination-button {
    @include variables_fonts.u-label--regular;

    color: variables.$black-default;
    border-bottom: 0.0625rem solid transparent;
    padding: 0.5em;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &[aria-current='page'] {
      border-bottom-color: variables.$black-default;
    }
  }
}
</style>

<script setup>
import { computed, ref, toRefs, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useReservation } from 'src/composables/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const store = useStore();
const currentUserName = computed(() => store.state.currentUser.name);

const {
  reservation: reservationData,
  isLoading: isLoadingReservation,
  error: reservationLoadingError,
  isConcierge,
} = useReservation(reservationId);

const customerName = computed(
  () => reservationData.value?.account?.name ?? 'customer name'
);

const resEmailBody = computed(
  () => reservationData.value?.custom_booking_email?.body ?? ''
);
const resEmailSubject = computed(
  () =>
    reservationData.value?.custom_booking_email?.subject ??
    `${currentUserName.value} from soona suggested a booking for you 🎉`
);
const resEmailPreheader = computed(
  () =>
    reservationData.value?.custom_booking_email?.preheader ??
    'see what we recommend based on your goals. book with a click.'
);
const resEmailClosingMessage = computed(
  () => reservationData.value?.custom_booking_email?.closing_message ?? ''
);

const body = ref(resEmailBody.value);
const subject = ref(resEmailSubject.value);
const preheader = ref(resEmailPreheader.value);
const closingMessage = ref(resEmailClosingMessage.value);

watchEffect(() => {
  body.value = resEmailBody.value;
  subject.value = resEmailSubject.value;
  preheader.value = resEmailPreheader.value;
  closingMessage.value = resEmailClosingMessage.value;
});

const isDirty = computed(
  () =>
    body.value !== resEmailBody.value ||
    subject.value !==
      (reservationData.value?.custom_booking_email?.subject ?? '') ||
    preheader.value !==
      (reservationData.value?.custom_booking_email?.preheader ?? '') ||
    closingMessage.value !== resEmailClosingMessage.value
);

const {
  mutate: updateReservation,
  isPending: isUpdatingReservation,
  error: updateReservationError,
} = useUpdateReservation(reservationId);

const isBusy = computed(() => {
  return isLoadingReservation.value || isUpdatingReservation.value;
});

const { stepIsIncomplete } = useIncompleteSteps(reservationData, slug);

const priorityError = usePriorityError(
  reservationLoadingError,
  updateReservationError,
  stepIsIncomplete
);

function update(event) {
  const data = new FormData(event.target);

  updateReservation({
    custom_booking_email: {
      body: data.get('custom-message'),
      subject: data.get('email-subject'),
      preheader: data.get('email-preheader'),
      closing_message: data.get('closing-message'),
    },
  });
}
</script>

<template>
  <SoonaForm
    v-slot="{ hasErrors }"
    class="custom-booking-email"
    @submit="update"
  >
    <SoonaTextfield
      v-model="subject"
      label="email subject"
      name="email-subject"
      :placeholder="`${currentUserName} from soona suggested a booking for you 🎉`"
      :disabled="isBusy"
      data-cypress="email-subject"
      :required="true"
      :rules="['required']"
    />

    <SoonaTextfield
      v-model="preheader"
      label="email preheader"
      name="email-preheader"
      placeholder="see what we recommend based on your goals. book with a click."
      :disabled="isBusy"
      data-cypress="email-preheader"
      :required="true"
      :rules="['required']"
    />

    <hr />

    <p>👋 {{ customerName }},</p>

    <p v-if="!isConcierge">
      your next steps are to review the booking details and finish checking out.
    </p>

    <SoonaTextfield
      v-model="body"
      label="custom message"
      name="custom-message"
      placeholder="custom message"
      element="textarea"
      :disabled="isBusy"
      data-cypress="email-custom-message"
    />

    <SoonaTextfield
      v-model="closingMessage"
      label="closing message"
      name="closing-message"
      placeholder="closing message"
      element="textarea"
      :disabled="isBusy"
      data-cypress="email-closing-message"
    />

    <div v-if="isConcierge">
      <strong>here are your next steps:</strong>

      <ol>
        <li>follow the link below to complete your soona account</li>
        <li>review the booking details and finish checking out</li>
        <li>
          after you complete the booking: we'll follow up with your next steps
        </li>
      </ol>
    </div>

    <div class="email-signature">
      <p class="email-signature-text">love and double taps,</p>
      <p class="email-signature-name">{{ currentUserName }}!</p>
    </div>

    <SoonaAlert v-if="isDirty" class="custom-booking-email__alert">
      you have unsaved changes. be sure to save to prevent them from being
      overwritten.
    </SoonaAlert>

    <SoonaButton
      variation="primary"
      type="submit"
      size="medium"
      :disabled="hasErrors || isBusy || !isDirty"
    >
      save email
    </SoonaButton>

    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
  </SoonaForm>
</template>

<style lang="scss" scoped>
@use '@/variables';

.custom-booking-email {
  hr {
    height: 0.0625rem;
    background-color: variables.$gray-30;
    margin: 0 0 1.5rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  &__alert {
    margin: 0 0 1rem;
  }
}
</style>

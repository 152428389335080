import { computed, toValue } from 'vue';

/*
 * @param {MaybeRef<string | Number | null | undefined>} accountId
 * */
export const useDisablePublishFlow = ({ accountId }) => {
  const thriveAccountId = Number(import.meta.env.VITE_THRIVE_ACCOUNT_ID ?? -1);

  const disablePublishFlow = computed(() => {
    return Number(toValue(accountId)) === thriveAccountId;
  });

  return {
    disablePublishFlow,
  };
};

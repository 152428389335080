import {
  convertToMMDDYYYY,
  convertToMMDDYY,
  convertToYYYYMMDD,
  convertToMMDDYYYYmonthAbr,
  convertTodddDDMMMYYY,
  convertToHHmmss,
  convertToMMDDYYYYlong,
  getYear,
  convertToHHMM12,
  convertToMMDD,
  convertToDayLong,
  dateToISO,
  timeZoneAbbr,
  browserTimeZone,
  workingDaysBetweenDates,
} from 'src/lib/date-formatters';

export default {
  methods: {
    convertToMMDDYYYY,
    convertToMMDDYY,
    convertToYYYYMMDD,
    convertToMMDDYYYYmonthAbr,
    convertTodddDDMMMYYY,
    convertToHHmmss,
    convertToMMDDYYYYlong,
    getYear,
    convertToHHMM12,
    convertToMMDD,
    convertToDayLong,
    dateToISO,
    timeZoneAbbr,
    browserTimeZone,
    workingDaysBetweenDates,
  },
};

import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {string | number} collectionId
 * @param {Object} [params]
 * @param {number} [params.itemsPerPage]
 * @param {Object} [params.filters]
 */
export function useInfiniteFavoritesCollectionDigitalAssets(
  collectionId,
  { itemsPerPage, filters } = {}
) {
  return useInfiniteQuery({
    queryKey: queryKeys.collectionAssets(collectionId, {
      itemsPerPage,
      filters,
      infinite: true,
    }),
    queryFn: async ({ signal, pageParam }) => {
      const params = { ...toValue(filters) };

      if (pageParam.currentPage) params['page'] = pageParam.currentPage;
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);

      const response = await http.get(
        `/collections/${toValue(collectionId)}/favorites_collection_digital_assets`,
        {
          params,
          signal,
        }
      );

      return {
        pagination: mapPaginationHeaders(response.headers),
        assets: response.data?.favorites_collection_digital_assets || [],
      };
    },
    initialPageParam: { currentPage: 1, itemsPerPage },
    getNextPageParam: lastPage => {
      if (
        lastPage.pagination.currentPage + 1 >
        lastPage.pagination.totalPages
      ) {
        return null;
      } else {
        return {
          currentPage: lastPage.pagination.currentPage + 1,
          itemsPerPage: lastPage.pagination.itemsPerPage,
        };
      }
    },
  });
}

<script setup>
import { computed } from 'vue';
import AuthHeading from '@/components/authentication/AuthHeading.vue';
import BestBrands from '@/components/authentication/BestBrands.vue';
import LeftColumn from '@/components/authentication/LeftColumn.vue';
import OtherActions from '@/components/authentication/OtherActions.vue';
import ReferralMessage from '@/components/authentication/ReferralMessage.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';

const props = defineProps({
  basePath: {
    default: '/',
    type: String,
  },
  errorMessages: {
    default: () => [],
    type: Array,
  },
  isExternalAuthPortal: {
    default: false,
    type: Boolean,
  },
  isGoogleStep: {
    default: false,
    type: Boolean,
  },
  isLoading: {
    default: false,
    type: Boolean,
  },
  isPickAccountStep: {
    default: false,
    type: Boolean,
  },
  referralName: {
    default: null,
    type: String,
  },
  type: {
    required: true,
    type: String,
    validator: val => {
      return ['sign-in', 'sign-up'].includes(val);
    },
  },
});

const emits = defineEmits(['handle-submit']);

const errorMessages = computed(() => props.errorMessages);
const isLoading = computed(() => props.isLoading);
const isPickAccountStep = computed(() => props.isPickAccountStep);
const isGoogleStep = computed(() => props.isGoogleStep);
</script>

<template>
  <article class="soona-auth-layout">
    <LeftColumn class="soona-auth-layout__left" />
    <div class="soona-auth-layout__right">
      <SoonaLoading v-if="isLoading" is-loading is-contained />
      <div v-else class="soona-auth-layout__content">
        <ReferralMessage
          v-if="referralName && type === 'sign-up'"
          :referral-name="referralName"
        />
        <AuthHeading
          :base-path="basePath"
          :show-sign-up-link="!referralName"
          :type="type"
        />
        <OtherActions
          v-if="!isPickAccountStep"
          :hide-shopify="isExternalAuthPortal || isGoogleStep"
          :is-google-step="isGoogleStep"
          :type="type"
        />
        <!-- TODO: eventually update SoonaForm to use Composition API & handle input validation/error messages -->
        <SoonaForm
          class="soona-auth-layout__form"
          @submit="emits('handle-submit')"
        >
          <slot />
          <template v-if="errorMessages">
            <SoonaError v-for="(error, i) in errorMessages" :key="i">
              {{ error }}
            </SoonaError>
          </template>
        </SoonaForm>
        <BestBrands />
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-auth-layout {
  container: soona-auth-layout / inline-size;
  display: flex;
  min-height: calc(100vh - var(--app-header-viewport-offset));

  &__left {
    display: none;
  }

  &__right {
    background-color: variables.$white-default;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: 0 auto;
    padding: 1rem;
    position: relative;
    max-width: 32.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 3rem;
  }

  :deep(.soona-button--primary[type='submit']) {
    margin-bottom: 1rem;
  }
}

@container soona-auth-layout (min-width: 60rem) {
  .soona-auth-layout {
    &__left {
      display: flex;
      flex: 0 1 93.75rem;
    }

    &__right {
      flex: 0 0 32.5rem;
      padding: 3.75rem;
    }

    &__content {
      justify-content: center;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  selectedMediaType: {
    default: '',
    type: String,
    validator: function (value) {
      return ['photo', 'video'].includes(value);
    },
  },
});

const emits = defineEmits(['change']);

const mediaTypeOptions = [
  { value: 'photo', label: 'book a photo shoot' },
  { value: 'video', label: 'book a video shoot' },
];

const selectedMediaType = computed(() => props.selectedMediaType);
</script>

<template>
  <div class="billing-cycle-toggle" data-cypress="div-billing-cycle-toggle">
    <template v-for="media in mediaTypeOptions" :key="media.value">
      <input
        :id="`radio-${media.value}`"
        class="u-visually-hidden"
        :checked="selectedMediaType === media.value"
        name="media-type"
        type="radio"
        :value="media"
        @change="emits('change', media.value)"
      />
      <label :for="`radio-${media.value}`" class="billing-cycle-toggle__item">
        <span class="u-body--heavy billing-cycle-toggle__item__label-text">{{
          media.label
        }}</span>
        <SoonaIcon
          class="billing-cycle-toggle__icon"
          :name="
            selectedMediaType === media.value
              ? 'circle-check-solid'
              : 'circle-check'
          "
        />
      </label>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.billing-cycle-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 0.625rem;
  gap: 0.5rem;

  &__icon {
    align-self: flex-start;
  }

  &__item {
    display: flex;
    gap: 0.5rem;
    box-sizing: content-box;
    height: 3.5rem;
    width: 11rem;
    min-width: max-content;
    flex-wrap: wrap;
    border: 0.0625rem solid variables.$gray-40;
    border-radius: 1rem;
    box-shadow: variables.$elevation-1;
    cursor: pointer;
    padding: 0.75rem 0.75rem;
    text-align: center;
    transition:
      background-color 0.2s,
      border-color 0.2s;

    &__label-text {
      align-self: flex-end;
    }

    input:focus-visible + & {
      background-color: variables.$white-translucent-80;
      border-color: variables.$white-translucent-80;
    }

    input:checked + & {
      background-color: variables.$periwink-blue-30;
      border-color: variables.$black-default;
    }

    &:hover,
    &:focus-visible {
      background-color: variables.$white-translucent-80;
      border-color: variables.$white-translucent-80;
    }

    input:focus-visible:hover + &,
    input:focus-visible:focus-visible + & {
      outline: 0.125rem solid variables.$periwink-blue-70;
    }
  }
}
</style>

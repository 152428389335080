import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';

/**
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useMokkerAITemplatesUrl(accountId, templateId) {
  return useQuery({
    queryKey: queryKeys.mokkerAITemplatesRedirect(accountId, templateId),
    queryFn: async () => {
      const response = await http.get(
        `/mokker_accounts/${toValue(accountId)}/ai_templates_url/${toValue(templateId)}`
      );
      return response.data;
    },
  });
}

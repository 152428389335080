<script setup>
import { nextTick, ref, useTemplateRef, watchEffect } from 'vue';
import { getMarkRange, getMarkType } from '@tiptap/core';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['submit']);

const hrefEl = useTemplateRef('href');
const labelEl = useTemplateRef('label');

const hrefState = ref('');
const labelState = ref('');

watchEffect(() => {
  const existingHref = props.editor.isActive('link')
    ? props.editor.getAttributes('link').href
    : '';
  const linkRange = getMarkRange(
    props.editor.state.selection.$from,
    getMarkType('link', props.editor.schema)
  );
  const linkText = linkRange
    ? props.editor.state.doc.textBetween(linkRange.from, linkRange.to)
    : '';

  const selectedText = props.editor.state.doc.textBetween(
    props.editor.state.selection.$from.pos,
    props.editor.state.selection.$to.pos
  );

  // If we're on a link, we'll use the full link text, otherwise we'll fall back
  // to the selected text
  const initialText = linkText || selectedText;

  // If there's already a link where the user has clicked, they're "editing",
  // otherwise the menu has been brought up to add a new link
  const isNewLink = !existingHref;

  hrefState.value = existingHref;
  labelState.value = initialText;

  const autoFocusOnTextInput = !isNewLink || !initialText;
  if (autoFocusOnTextInput) {
    nextTick(() => {
      labelEl.value?.focus();
    });
  } else {
    nextTick(() => {
      hrefEl.value?.focus();
    });
  }
});

function formatLink() {
  let currentHrefValue = hrefState.value.trim();
  if (
    currentHrefValue &&
    !/^(https?:\/\/|mailto:|tel:|sms:|\/|#)/.test(currentHrefValue)
  ) {
    currentHrefValue = `https://${currentHrefValue}`;
  }
  hrefState.value = currentHrefValue;
}

function submit() {
  // use the href for the label if it's blank
  if (!labelState.value.trim()) {
    labelState.value = hrefState.value;
  }

  emit('submit', { href: hrefState.value, label: labelState.value });
}
</script>

<template>
  <SoonaForm :id="id" @submit="submit">
    <SoonaTextfield
      ref="label"
      v-model="labelState"
      type="text"
      :required="false"
      label="display text (optional)"
      autocomplete="off"
    />
    <SoonaTextfield
      ref="href"
      v-model="hrefState"
      type="url"
      required
      label="link"
      :rules="['required', 'url']"
      autocomplete="off"
      @keydown.enter="formatLink"
    />
  </SoonaForm>
</template>

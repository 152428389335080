<template>
  <div class="ReferralLink">
    <section class="drop-shadow-container background">
      <div class="grow-even" />
      <div class="grow-even">
        <h3 class="large-title mb-s">invite your friends!</h3>
        <p class="text-s mb-m">
          share this link and receive one free photo whenever a friend makes a
          booking.
        </p>
      </div>
      <div class="grow-even">
        <div class="link-container mb-m" @click="copyText(referralLink)">
          <p>{{ referralLink }}</p>
          <b v-show="copySuccess" class="copied text-s">copied!</b>
        </div>
      </div>

      <div class="grow-even" />
      <div class="grow-even">
        <h3 class="small-title mb-m"><b>SHARE TO</b></h3>
        <div class="social-buttons mb-m">
          <ShareNetwork
            network="facebook"
            :url="referralLink"
            :quote="`Do you need FAST and EASY pro photos & videos for your ecomm store or marketing? You need to try soona! Their virtual shoots have made me a brand genius. $39 photos. $93 video clips. Edited and delivered in 24 hours. BOOM!
Use coupon code friendrequest for 1 free photo on me!`"
            :title="`check out soona!`"
            class="social-button"
          >
            <SoonaIcon name="facebook-logo" />
          </ShareNetwork>
          <ShareNetwork
            network="twitter"
            :url="referralLink"
            :title="`You really need to try @soonastudios for your ecomm store or marketing. It's been a game changer for me. $39 photos. $93 video clips. Delivered in 24 hours. BOOM!
Use coupon code: friendrequest for your 1 free photo on me!`"
            twitter-user="soonastudios"
            class="social-button"
          >
            <SoonaIcon name="x-logo" />
          </ShareNetwork>
          <ShareNetwork
            network="linkedin"
            :url="referralLink"
            class="social-button"
            :title="`check out soona!`"
          >
            <SoonaIcon name="linkedin-logo" />
          </ShareNetwork>
        </div>
        <!-- stats -->
        <p class="text-s">{{ referredUsersText }} {{ creditsText }}</p>
      </div>

      <div class="grow-even" />
    </section>
  </div>
</template>

<script>
import { ShareNetwork } from 'vue-social-sharing';
import { ref, computed } from 'vue';
import { useCopy } from 'src/composables/useCopy';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'ReferralLink',
  components: { ShareNetwork, SoonaIcon },
  props: {
    currentAccount: Object,
  },
  setup(props) {
    let hostname = ref(import.meta.env.VITE_HOSTNAME);
    let referralLink = computed(() => {
      return `${hostname.value}/r/${props.currentAccount.referral_code}`;
    });

    const { copyText, copySuccess } = useCopy();

    return {
      copyText,
      copySuccess,
      referralLink,
    };
  },
  computed: {
    numberOfCredits() {
      return this.currentAccount.referral_credits_remaining;
    },
    numberofReferredUsers() {
      return this.currentAccount.referral_credits_earned;
    },
    referredUsersText() {
      if (this.numberofReferredUsers === 1) {
        return `${this.numberofReferredUsers} friend has booked.`;
      } else {
        return `${this.numberofReferredUsers} friends have booked.`;
      }
    },
    creditsText() {
      if (
        this.numberofReferredUsers === 0 &&
        this.numberofReferredUsers === 0
      ) {
        return '';
      } else if (this.numberOfCredits > 1) {
        return `you have ${this.numberOfCredits} credits remaining.`;
      } else if (this.numberOfCredits === 1) {
        return `you have ${this.numberOfCredits} credit remaining.`;
      } else {
        return `you've used all of your credits.`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.ReferralLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .button-container {
    display: flex;
    justify-content: flex-end;
  }
  .toggle-button {
    background: transparent;
  }
  .background {
    background: variables.$tangerine-05;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    .grow-even {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
  }

  .link-container {
    border: 0.125rem solid variables.$gray-90;
    border-radius: 0.5rem;
    background: variables.$white-default;
    max-width: 31.25rem;
    margin: 0.5rem auto;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .link-container:hover {
    cursor: copy;
  }
  .small-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 2px;
  }
  .social-buttons {
    display: flex;
    justify-content: center;
  }
  .social-button {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: variables.$white-default;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.25rem;
  }
}
</style>

import { computed } from 'vue';
import AmazonBestPracticesImage from 'images/Amazon-best-practices-small.jpg';
import ShopifyVisualMixBlog from 'images/shopify-visual-mix-blog.jpg';
import AmazonImageQualitySmall from 'images/Amazon-image-quality-small.jpg';
import ShopifyImageQualityBlog from 'images/shopify-image-quality-blog.jpg';
import AmazonBestPracticesSmall from 'images/Amazon-best-practices-small.jpg';
import AmazonVideoSmall from 'images/Amazon-video-small.jpg';
import ShopifyVideoBlog from 'images/shopify-video-blog.jpg';

export function useListingDetailAccordion(insightData, platform) {
  const platformBlogs = {
    'visual mix': {
      Shopify: {
        link: 'https://soona.co/blog/ultimate-guide-to-shopify-listing-optimization',
        title: 'The Ultimate Guide to Shopify Listing Optimization',
        image: ShopifyVisualMixBlog,
      },
      Amazon: {
        link: 'https://soona.co/blog/10-amazon-best-practices-to-sell-more-in-2023',
        title: '10 Amazon Best Practices to Help Sell Your Products',
        image: AmazonBestPracticesImage,
      },
    },
    'image quality': {
      Shopify: {
        link: 'https://soona.co/blog/shopify-image-sizes',
        title: 'Shopify Image Sizes: What’s Recommended for 2024',
        image: ShopifyImageQualityBlog,
      },
      Amazon: {
        link: 'https://soona.co/blog/image-quality-on-amazon',
        title:
          'Image Quality on Amazon: What You Need to Be Compliant and Drive Sales',
        image: AmazonImageQualitySmall,
      },
    },
    'gallery count': {
      Shopify: {
        link: 'https://soona.co/blog/ultimate-guide-to-shopify-listing-optimization',
        title: 'The Ultimate Guide to Shopify Listing Optimization',
        image: ShopifyVisualMixBlog,
      },
      Amazon: {
        link: 'https://soona.co/blog/10-amazon-best-practices-to-sell-more-in-2023',
        title: '10 Amazon Best Practices to Help Sell Your Products',
        image: AmazonBestPracticesSmall,
      },
    },
    'video count': {
      Shopify: {
        link: 'https://soona.co/blog/how-videos-increase-conversion-rates-shopify',
        title: 'How Videos Increase Conversion Rates on Shopify',
        image: ShopifyVideoBlog,
      },
      Amazon: {
        link: 'https://soona.co/blog/amazon-product-videos-in-2023',
        title:
          'Amazon Product Videos: A Cheat Sheet for Successful Amazon Marketing in 2023',
        image: AmazonVideoSmall,
      },
    },
  };

  const hasBrokenRules = computed(() => {
    return insightData.value?.display_value < 100;
  });

  const linkTo = computed(
    () => platformBlogs[insightData.value?.rule][platform.value]?.link ?? ''
  );

  const linkTitle = computed(
    () => platformBlogs[insightData.value?.rule][platform.value]?.title ?? ''
  );

  const linkImage = computed(
    () => platformBlogs[insightData.value?.rule][platform.value]?.image ?? '/'
  );

  return {
    platformBlogs,
    hasBrokenRules,
    linkTo,
    linkTitle,
    linkImage,
  };
}

<script setup>
import { computed, ref } from 'vue';
import { useCreateProduct } from '@/queries/useCatalog';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useGetExternalIdentifierTypes } from '@/queries/useGetExternalIdentifierTypes';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  initialName: {
    type: String,
    default: '',
    required: false,
  },
  multiselect: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['cancel', 'select']);

const accountId = computed(() => props.accountId);

// we intentionally do not want reactivity here
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const productName = ref(props.initialName);
const productSku = ref('');
const productIsProp = ref(false);
const productIsLargerThanTwentyInches = ref(false);
const productNeedsAssembly = ref(false);
const productNeedsSteaming = ref(false);
const productSpecialHandlingInstructions = ref('');

const { data: externalIdentifierTypesData, error: typesError } =
  useGetExternalIdentifierTypes(accountId);

const skuExternalIdentifierType = computed(() =>
  externalIdentifierTypesData.value?.find(type => type.slug === 'sku')
);

const { mutate, isPending, error } = useCreateProduct(accountId);
const priorityError = usePriorityError(error, typesError);

const isDisabled = computed(() => {
  return isPending.value || productName.value.trim().length < 1;
});

function submit(event) {
  const data = new FormData(event.target);

  const sku = data.get('product-sku').trim();

  const product = {
    name: data.get('product-name').trim(),
    special_handling_instructions: data
      .get('product-special-handling-instructions')
      .trim(),
    type: data.has('product-is-prop') ? 'CatalogProp' : 'CatalogProduct',
    is_larger_than_twenty_inches: data.has(
      'product-is-larger-than-twenty-inches'
    ),
    needs_assembly: data.has('product-needs-assembly'),
    needs_steaming: data.has('product-needs-steaming'),
  };

  if (sku && skuExternalIdentifierType.value) {
    product.external_identifiers_attributes = [
      {
        external_identifier_type_id: skuExternalIdentifierType.value.id,
        value: sku,
      },
    ];
  }

  mutate(product, {
    onSuccess: data => {
      emit('select', data.id, data);
    },
  });
}
</script>

<template>
  <SoonaForm
    v-slot="{ hasErrors }"
    aria-labelledby="product-picker-new-form-heading"
    class="picker-create-product"
    @submit="submit"
  >
    <h3
      id="product-picker-new-form-heading"
      class="picker-create-product__heading"
    >
      new product
    </h3>
    <SoonaTextfield
      v-model="productName"
      name="product-name"
      type="text"
      label="name"
      required
      :rules="['required']"
    />
    <SoonaToggle
      v-model="productIsLargerThanTwentyInches"
      name="product-is-larger-than-twenty-inches"
      type="checkbox"
      label="larger than 20 in (50 cm) in diameter"
      class="picker-create-product__checkbox"
    />
    <SoonaToggle
      v-model="productNeedsAssembly"
      name="product-needs-assembly"
      type="checkbox"
      label="needs assembly"
      class="picker-create-product__checkbox"
    />
    <SoonaToggle
      v-model="productNeedsSteaming"
      name="product-needs-steaming"
      type="checkbox"
      label="needs steaming (recommended for apparel)"
      class="picker-create-product__checkbox"
    />
    <SoonaToggle
      v-model="productIsProp"
      name="product-is-prop"
      type="checkbox"
      label="is a prop"
      class="picker-create-product__checkbox"
    />
    <SoonaTextfield
      v-model="productSku"
      name="product-sku"
      type="text"
      label="SKU"
      placeholder="optional"
      :disabled="!skuExternalIdentifierType"
    />
    <SoonaTextfield
      v-model="productSpecialHandlingInstructions"
      name="product-special-handling-instructions"
      element="textarea"
      type="text"
      label="special handling instructions"
      placeholder="optional"
      rows="4"
    />

    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>

    <div class="picker-create-product__footer">
      <SoonaButton
        variation="tertiary"
        type="button"
        @on-click="$emit('cancel')"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        variation="primary"
        type="submit"
        :loading="isPending"
        :disabled="hasErrors || isDisabled"
      >
        {{ multiselect ? 'create and select' : 'create and choose' }}
      </SoonaButton>
    </div>
  </SoonaForm>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.picker-create-product {
  padding: 1rem;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  background-color: variables.$periwink-blue-10;

  &__heading {
    @include variables_fonts.u-subheader--heavy;
    color: variables.$black-default;
    margin-bottom: 1rem;
  }

  &__checkbox {
    margin-bottom: 1.5rem;
  }

  &__footer {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: flex-end;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import UpgradeCard from 'src/components/user/anytime/UpgradeCard.vue';
import { useReservation } from '@/composables/useReservation';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import {
  PeriwinkBlue30,
  BubbletapePink10,
  GreenApple10,
} from '@/variables.module.scss';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const { isPack, notStarted, isSuccess } = useReservation(
  computed(() => props.reservationId)
);

const showUpgradeCard = computed(() => {
  return isSuccess.value && notStarted.value && !isPack.value;
});
</script>
<template>
  <div class="pro-service-selection-upgrades">
    <UpgradeCard v-if="showUpgradeCard" :reservation-id="reservationId" />
    <div class="pro-service-selection-upgrades__imgs-outer">
      <div class="pro-service-selection-upgrades__img-wrapper">
        <img src="@images/booking-upgrades/full_body_model.jpg" alt="" />
        <SoonaFlag
          class="pro-service-selection-upgrades__img-label tall"
          title="full body model"
          :background-color="GreenApple10"
        />
      </div>
      <div class="pro-service-selection-upgrades__imgs-inner">
        <div class="pro-service-selection-upgrades__img-wrapper">
          <img src="@images/booking-upgrades/styling.jpg" alt="" />
          <SoonaFlag
            class="pro-service-selection-upgrades__img-label"
            title="styling"
            :background-color="PeriwinkBlue30"
          />
        </div>
        <div class="pro-service-selection-upgrades__img-wrapper">
          <img src="@images/booking-upgrades/hand_model.jpg" alt="" />
          <SoonaFlag
            class="pro-service-selection-upgrades__img-label"
            title="hand model"
            :background-color="BubbletapePink10"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
.pro-service-selection-upgrades {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  img {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 0.625rem;
    object-fit: cover;
  }

  &__img-wrapper {
    position: relative;
  }

  &__img-label {
    position: absolute;
    bottom: 10%;
    left: 10%;
    border-radius: 1rem;
    padding: 0.125rem 0.5rem 0.125rem 0.5rem;
    font-size: 0.75rem;
    &.tall {
      bottom: 5%;
    }
  }

  &__imgs-outer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__imgs-inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media (min-width: variables.$screen-sm-min) {
    &__imgs-outer {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__imgs-inner {
      justify-content: space-between;
      gap: 1rem;
    }
  }
}
</style>

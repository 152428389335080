import { computed, unref } from 'vue';

export function usePriorityError(...errors) {
  return computed(() => {
    for (let i = 0; i < errors.length; i++) {
      const err = unref(errors[i]);
      // if the error is an AxiosError and there is a message in the response data
      if (err?.response?.data?.message) return err.response.data.message;
      if (err?.message) return err.message;
    }
    return null;
  });
}

<script setup>
import { computed, ref, toRefs } from 'vue';
import SortBy from './SortBy.vue';
import FilterBy from './FilterBy.vue';
import SoonaButton from '../ui_library/SoonaButton.vue';
import SoonaDialog from '../ui_library/SoonaDialog.vue';
import SoonaIcon from '../ui_library/soona_icon/SoonaIcon.vue';
import SoonaDynamicMobileToolbar from '../ui_library/SoonaDynamicMobileToolbar.vue';

const props = defineProps({
  disableSort: {
    type: Boolean,
    default: false,
  },
  selectedFilters: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['filter-change']);

const selectedSort = defineModel('selectedSort');
const sortDirection = defineModel('sortDirection');

const { selectedFilters } = toRefs(props);

const filterDialogOpen = ref(false);
const activeFilterCount = computed(() => {
  return Object.entries(selectedFilters.value).filter(([key, value]) => {
    if (['media_type', 'origin', 'ownership'].includes(key)) {
      return !!value;
    }
  }).length;
});

const filters = [
  {
    type: { label: 'ownership', value: 'ownership' },
    options: [
      { label: 'owned', value: 'customer' },
      { label: 'unowned', value: 'soona' },
    ],
    selectionType: 'single',
  },
  {
    type: { label: 'media type', value: 'media_type' },
    options: [
      { label: 'photo', value: 'photo' },
      { label: 'video', value: 'video' },
      { label: 'GIF', value: 'animation' },
    ],
    selectionType: 'single',
  },
  {
    type: { label: 'source', value: 'origin' },
    options: [
      { label: 'soona', value: 'soona' },
      { label: 'my uploads', value: 'customer' },
    ],
    selectionType: 'single',
  },
];

const selectFilter = change => {
  emits('filter-change', change);
};

const clearFilters = () => {
  selectFilter({ media_type: null, ownership: null, origin: null });
  filterDialogOpen.value = false;
};
</script>

<template>
  <SoonaDynamicMobileToolbar class="filter-toolbar">
    <template #condensed>
      <SoonaButton
        size="medium"
        class="filter-toolbar__filters-toggle"
        variation="secondary-gray"
        @click="filterDialogOpen = true"
      >
        filters
        <SoonaIcon name="sliders" />
      </SoonaButton>

      <SoonaDialog
        v-if="filterDialogOpen"
        size="full-screen"
        @close="filterDialogOpen = false"
      >
        <template #heading>filters</template>

        <div class="filter-toolbar__dialog-body">
          <FilterBy
            v-for="filter in filters"
            :key="filter.type.value"
            :filter="filter"
            :active-option="selectedFilters[filter.type.value]"
            @filter-change="selectFilter"
          />

          <h3 class="u-subheader--heavy">sort</h3>

          <SortBy
            v-model:selected-sort="selectedSort"
            v-model:sort-direction="sortDirection"
          />
        </div>

        <template #footer="{ close }">
          <div class="filter-toolbar__dialog-footer">
            <div class="filter-toolbar__dialog-footer--left">
              <p class="u-label--heavy">{{ activeFilterCount }} selected</p>

              <SoonaButton variation="tertiary" @click="clearFilters">
                clear
              </SoonaButton>
            </div>

            <SoonaButton size="medium" variation="solid-black" @click="close">
              save & close
            </SoonaButton>
          </div>
        </template>
      </SoonaDialog>
    </template>

    <template #expanded>
      <div class="filter-toolbar__filters-wrapper">
        <FilterBy
          v-for="filter in filters"
          :key="filter.type.value"
          :filter="filter"
          :active-option="selectedFilters[filter.type.value]"
          @filter-change="selectFilter"
        />

        <SoonaButton
          v-if="activeFilterCount > 0"
          variation="tertiary"
          class="filter-toolbar__clear"
          @click="clearFilters"
        >
          clear
        </SoonaButton>

        <SortBy
          v-model:selected-sort="selectedSort"
          v-model:sort-direction="sortDirection"
          :disabled="disableSort"
          class="filter-toolbar__sort-by"
        />
      </div>
    </template>
  </SoonaDynamicMobileToolbar>
</template>

<style lang="scss" scoped>
@use '@/variables';

.filter-toolbar {
  &__filters-toggle {
    border-radius: 1.625rem;
  }

  &__filters-wrapper {
    gap: 0.5rem;
    display: flex;
  }

  &__dialog-body {
    gap: 1rem;
    display: flex;
    flex-direction: column;

    :deep(.soona-button) {
      width: 100%;

      .soona-button__content {
        flex: 1;
        justify-content: space-between;
      }
    }
  }

  &__dialog-footer {
    flex: 1;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--left {
      gap: 1rem;
      display: flex;
      align-items: center;
    }
  }

  &__clear {
    margin-left: 0.5rem;
  }

  &__sort-by {
    margin-left: auto;
  }
}
</style>

<script setup>
import { toRefs } from 'vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  clickCapture: {
    type: Function,
    required: true,
  },
  keydown: {
    type: Function,
    required: true,
  },
  mouseover: {
    type: Function,
    required: true,
  },
  pizzazzBorder: {
    type: Boolean,
    default: false,
  },
  subtitle: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
});

const emits = defineEmits(['click']);

const { clickCapture, keydown, mouseover, pizzazzBorder, subtitle, title } =
  toRefs(props);

// click handlers
</script>

<template>
  <SoonaDropdownMenuItem
    :class="{ 'menu-item--pizzazz-border': pizzazzBorder }"
  >
    <button
      role="menuitem"
      class="download-option"
      :class="{ 'download-option--pizzazz-border': pizzazzBorder }"
      @click.capture="clickCapture"
      @click="emits('click')"
      @keydown="keydown"
      @mouseover="mouseover"
    >
      <SoonaIcon
        name="arrow-down-to-bracket"
        size="medium"
        class="download-option__icon"
      />
      <span class="download-option__content">
        <span v-if="title" class="u-body--regular">{{ title }}</span>
        <span
          v-if="subtitle"
          class="u-label--regular download-option__subtitle"
        >
          {{ subtitle }}
        </span>
      </span>
      <span v-if="$slots['right-col']" class="download-option__right">
        <slot name="right-col" />
      </span>
    </button>
  </SoonaDropdownMenuItem>
</template>

<style scoped lang="scss">
@use '@/variables';

.menu-item--pizzazz-border {
  position: relative;
  padding: 0.125rem;
}

.download-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 17.5rem;

  &--pizzazz-border {
    padding: 0.375rem 0.625rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-radius: inherit;
      background-image: linear-gradient(
        110deg,
        #3c51f1 0%,
        #f2b5fa 55%,
        #6b7eed 100%
      );
    }

    &:focus-visible {
      outline-offset: 0.125rem;
    }
  }

  &__icon {
    flex-shrink: 0;
    color: variables.$gray-60;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    color: variables.$gray-60;
  }

  &__right {
    margin-left: auto;
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import { useAccountsRoles } from '@/queries/account/useAccountsRoles';
import { useCreateInvitation } from '@/queries/invitations/useCreateInvitation';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

defineEmits(['close']);

const accountId = computed(() => Number(props.accountId));

const inviteEmail = ref('');
const inviteRole = ref(null);

const {
  data: accountsRoles,
  isLoading: isAccountsRolesLoading,
  error: accountsRolesError,
} = useAccountsRoles();
const roles = computed(() =>
  accountsRoles.value?.map(role => ({
    ...role,
    name: role.name.replace('account_', ''),
  }))
);
const {
  mutate,
  isPending: isCreateInvitationLoading,
  error: createInvitationError,
} = useCreateInvitation(accountId);

const priorityError = usePriorityError(
  createInvitationError,
  accountsRolesError
);

function sendInvite(close) {
  if (!inviteEmail.value.trim() || !inviteRole.value) return;

  mutate(
    {
      accountId: accountId.value,
      email: inviteEmail.value.toLowerCase().trim(),
      role_id: inviteRole.value.id,
    },
    { onSuccess: () => close() }
  );
}
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>invite team member</template>
    <template #default="{ close }">
      <SoonaForm id="invite-collaborator-form" @submit="sendInvite(close)">
        <SoonaTextfield
          v-model="inviteEmail"
          label="email"
          placeholder="me@email.com"
          type="email"
          :required="true"
          :rules="['required', 'email']"
          cypress-name="input-invite-email"
        />
        <SoonaSelect
          v-model="inviteRole"
          :options="roles"
          :reduce="option => option"
          label="name"
          :selectable="option => option.id !== inviteRole?.id"
          :loading="isAccountsRolesLoading"
          placeholder="select one"
          data-cypress="select-invite-role"
        >
          <template #label>role</template>
        </SoonaSelect>
        <p v-if="inviteRole?.name === 'admin'" class="dialog-text-margin-top">
          admins can create and edit reservations. invite other collaborators.
          edit account details and manage default payment methods.
        </p>
        <p
          v-else-if="inviteRole?.name === 'collaborator'"
          class="dialog-text-margin-top"
        >
          collaborators can create and edit reservations and make purchases
          using the account's default payment method.
        </p>
        <SoonaError v-if="priorityError">
          {{ priorityError }}
        </SoonaError>
      </SoonaForm>
    </template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        variation="primary"
        type="submit"
        form="invite-collaborator-form"
        :disabled="!(inviteEmail && inviteRole)"
        :loading="isCreateInvitationLoading"
        data-cypress="button-send-invite"
      >
        send invite
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style scoped>
.dialog-text-margin-top {
  margin-top: 1rem;
}
</style>

<template>
  <div class="CustomerNote">
    <hr class="note-divider" />
    <!-- DESKTOP VIEW -->
    <div class="notes-details-view columns is-hidden-mobile">
      <div class="column is-2-desktop is-flex">
        <div class="note-avatar">
          <p class="note-user">{{ note.creator.name }}</p>
        </div>
      </div>
      <div class="column is-10-desktop">
        <div class="note-details-container">
          <div class="customer-note-container">
            <span class="customer-note-label">customer note</span>
          </div>
          <p>
            <span class="note-content" v-html="formatLink(note.content)" />
          </p>
          <div v-if="note.asset" class="mt-s">
            <img
              v-if="note.asset_is_image"
              :src="note.asset"
              alt="note asset"
              class="note-asset mb-m"
            />
            <a
              class="button is-warning is-small"
              target="_blank"
              :href="note.asset"
              >{{ note.asset_filename }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <!-- MOBILE VIEW -->
    <div
      class="columns notes notes-details-view is-hidden-desktop is-hidden-tablet"
    >
      <div class="column is-quarter is-flex">
        <div class="note-avatar has-text-centered">
          <p class="note-user">{{ note.creator.name }}</p>
        </div>
      </div>
      <div class="column is-three-quarters">
        <div class="note-details-container">
          <span class="customer-note-label">customer note</span>
          <p>
            <span class="note-content" v-html="formatLink(note.content)" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Autolinker from 'autolinker';

export default {
  name: 'CustomerNote',
  props: ['note'],
  methods: {
    formatLink(value) {
      let className = 'auto-link';

      return Autolinker.link(value, {
        mention: 'instagram',
        className: className,
      });
    },
  },
};
</script>

<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.CustomerNote {
  background: variables.$periwink-blue-10;

  .note-divider {
    height: 4px;
    margin: 0;
    background-color: variables.$gray-30;
  }

  .notes-details-view {
    margin-top: 0.5em;
    padding: 1em;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .notes-avatar-details {
    width: 50px;
    height: 50px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 290486px;
  }

  .note-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .note-content,
  .note-user {
    font-weight: bold;
  }

  .note-content {
    white-space: pre-line;
    overflow-wrap: anywhere;
  }

  .customer-note-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    margin-bottom: 0.625rem;
    position: static;
    width: max-content;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: variables.$white-default;
    border: 1px solid variables.$periwink-blue-30;
    box-sizing: border-box;
    border-radius: 1px;
  }

  .customer-note-label {
    @include variables_fonts.u-badge--big;

    display: flex;
    color: variables.$periwink-blue-60;
    align-items: center;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    background: variables.$white-default;
    box-sizing: border-box;
    border-radius: 1px;
  }

  .note-user {
    font-size: 10px;
  }

  .note-user {
    text-align: center;
  }

  .auto-link {
    color: variables.$gray-90;
    text-decoration: underline;
    word-break: break-all;
  }
}
</style>

<script setup>
import {
  ALBUMS_ACTION,
  NOTES_ACTION,
  PRODUCTS_ACTION,
} from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';

import ActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBar.vue';
import ActionBarButton from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarButton.vue';
import DigitalAssetCollections from '@/components/user/anytime/gallery/media-editor/DigitalAssetCollections.vue';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import DigitalAssetProducts from '@/components/user/anytime/gallery/media-editor/DigitalAssetProducts.vue';
import DigitalAssetNotesList from '@/components/notes/digital-asset-notes/DigitalAssetNotesList.vue';
import { useFlag } from '@/composables/useFlag';
import AIExpandGenerations from '@/components/user/anytime/gallery/media-editor/media-multiplier/uncrop/AIExpandGenerations.vue';
import MediaEditorNotes from '@/components/user/anytime/gallery/media-editor/MediaEditorNotes.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const hasToasterDigitalAssetCommentsFlag = useFlag(
  'toaster_digital_asset_comments'
);

const {
  isAIExpandActive,
  isAlbumsActive,
  isNotesActive,
  isProductsActive,
  isRightFlyoutOpen,
  rightFlyoutHeading,
} = useMediaEditor();

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId } = storeToRefs(mediaEditorStore);

const { isSuccess: assetLoaded, ownedBySoona } = useMediaEditorDigitalAsset(
  assetAccountId,
  assetId
);
</script>

<template>
  <ActionBar
    class="right-action-bar"
    :is-flyout-open="isRightFlyoutOpen"
    position="right"
  >
    <ActionBarButton
      column="right"
      icon-name="album"
      :is-selected="isAlbumsActive"
      label="albums"
      data-cypress="digital-asset-collections-action"
      @on-click="mediaEditorStore.setActiveAction(ALBUMS_ACTION)"
    />
    <template #flyout-heading> {{ rightFlyoutHeading }} </template>
    <ActionBarButton
      column="right"
      icon-name="shapes"
      :is-selected="isProductsActive"
      label="products"
      data-cypress="digital-asset-products-action"
      @on-click="mediaEditorStore.setActiveAction(PRODUCTS_ACTION)"
    />
    <ActionBarButton
      v-if="assetLoaded && ownedBySoona"
      column="right"
      :icon-name="
        hasToasterDigitalAssetCommentsFlag
          ? 'message-circle-lines-alt'
          : 'memo-pencil'
      "
      :is-selected="isNotesActive"
      label="notes"
      @on-click="mediaEditorStore.setActiveAction(NOTES_ACTION)"
    />

    <template #flyout-content>
      <DigitalAssetCollections v-if="isAlbumsActive" />
      <DigitalAssetProducts v-else-if="isProductsActive" />
      <MediaEditorNotes
        v-else-if="!hasToasterDigitalAssetCommentsFlag && isNotesActive"
      />
      <DigitalAssetNotesList
        v-else-if="hasToasterDigitalAssetCommentsFlag && isNotesActive"
        :digital-asset-id="assetId"
      />
      <AIExpandGenerations v-else-if="isAIExpandActive" />
    </template>
  </ActionBar>
</template>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { unref } from 'vue';

export function useCreatePaymentMethod(accountId) {
  const queryClient = useQueryClient();

  const createPaymentMethod = async body => {
    const params = {
      payment_method: {
        ...body,
        account_id: unref(accountId),
      },
    };
    const response = await http.post(`/payment_methods/`, params);

    return response.data;
  };

  return useMutation({
    mutationFn: body => {
      return createPaymentMethod(body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.account(accountId),
      });
    },
  });
}

<script setup>
import { computed } from 'vue';
import ProductConnectedInventoryCard from './ProductConnectedInventoryCard.vue';
import { useAccountInventoryItems } from '@/queries/inventory/useInventoryItems';

const props = defineProps({
  accountId: { type: String, required: true },
  catalogItemId: { type: String, required: true },
  productName: { type: String, required: true },
});

const accountId = computed(() => props.accountId);
const catalogItemId = computed(() => props.catalogItemId);

const { data: inventoryItemsData, isSuccess } = useAccountInventoryItems(
  accountId,
  {
    catalogItemId: catalogItemId,
  }
);

const inventoryItems = computed(() => inventoryItemsData.value ?? []);
</script>

<template>
  <div v-if="isSuccess && inventoryItems.length > 0" class="product-inventory">
    <h2 class="product-inventory__heading">inventory</h2>
    <div class="product-inventory__content">
      <ProductConnectedInventoryCard
        v-for="item in inventoryItems"
        :key="item.id"
        :item="item"
        :account-id="accountId"
        :product-name="productName"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-inventory {
  &__heading {
    @include variables_fonts.u-title--heavy;

    margin-bottom: 0.75rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}
</style>

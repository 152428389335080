<script setup>
import { computed, ref, toRefs, useId, useTemplateRef, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useReservation } from '@/queries/useReservation';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import {
  useReservationCopy,
  useReservationCopyable,
} from '@/queries/reservations/useReservationCopy';
import unescape from 'lodash/unescape';
import InfoCard from '@/components/user/anytime/reservation/InfoCard.vue';
import SoonaAlert from '../ui_library/SoonaAlert.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import { useCapability } from '@/composables/useCapability';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const props = defineProps({
  reservationId: {
    type: [String, Number],
    required: true,
  },
});

const emit = defineEmits(['close']);

const { reservationId } = toRefs(props);

const router = useRouter();
const route = useRoute();
const { hasCapability: canManageCrewBookingBuilder } = useCapability({
  capability: 'manage_crew_booking_builder',
});

const { linkClicked } = useBaseEvents();

const {
  data: reservation,
  isLoading: isReservationLoading,
  error: reservationError,
} = useReservation(reservationId);

const reservationName = ref('');
const reservationNameTextfield = useTemplateRef('name-textfield');
const formId = useId();

const isPack = computed(() => {
  return !!reservation.value?.isPickAPack;
});

watchEffect(() => {
  if (reservation.value?.name) {
    reservationName.value = `copy of ${unescape(reservation.value.name)}`;
  }
});

watchEffect(() => {
  if (reservationNameTextfield.value) {
    reservationNameTextfield.value.focus();
  }
});

const {
  error: copyableError,
  isLoading: isLoadingCopyable,
  isSuccess: isSuccessIsCopyable,
} = useReservationCopyable(reservationId);

const isCopyable = computed(() => {
  return !!(isSuccessIsCopyable.value && reservation.value);
});

const {
  mutate: copyReservationMutate,
  isPending: isCopyingReservation,
  error: copyReservationError,
} = useReservationCopy(reservationId);

const packIsNotCopyable = computed(() => {
  return !!copyableError.value && !!isPack.value;
});

const priorityErrors = usePriorityErrors(
  copyReservationError,
  copyableError,
  reservationError
);

function copyReservation(event) {
  const data = new FormData(event.target);
  const body = {
    include_products: data.has('include-products'),
    duplicate_shotlist: data.has('duplicate-shot-list'),
  };

  if (data.has('reservation-name')) {
    body.reservation_attributes = {
      name: data.get('reservation-name').trim(),
    };
  }

  copyReservationMutate(body, {
    onSuccess: async data => {
      const bookingRoute = canManageCrewBookingBuilder.value
        ? {
            name: 'crew-booking-edit',
            params: { reservationId: data.id },
          }
        : {
            name: 'newBook',
            params: { reservationId: data.id },
          };
      await router.push(bookingRoute);
      emit('close');
    },
  });

  linkClicked({
    context: route.meta?.context ?? 'unknown',
    subContext: 'duplicate reservation dialog',
    linkLabel: 'duplicate shoot',
    linkHref: null,
  });
}
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>duplicate this shoot</template>
    <div
      v-if="isReservationLoading || isLoadingCopyable"
      class="duplicate-reservation__loading-container"
    >
      <SoonaLoading is-loading is-contained />
    </div>

    <div
      v-if="!isLoadingCopyable && !isReservationLoading && priorityErrors"
      class="duplicate-reservation__info"
    >
      <InfoCard
        v-if="!isCopyable && reservation"
        :reservation-id="reservationId"
      />

      <SoonaAlert v-if="packIsNotCopyable">
        this pack is no longer available, but you can choose from one of our
        latest packs on the next screen
      </SoonaAlert>
      <SoonaError v-else :priority-errors="priorityErrors" no-margin />
    </div>

    <template v-if="isCopyable">
      <SoonaForm :id="formId" @submit="copyReservation">
        <div class="duplicate-reservation__info">
          <InfoCard :reservation-id="reservationId" />
          <SoonaToggle
            v-if="!isPack"
            :model-value="true"
            type="checkbox"
            label="duplicate shot list"
            name="duplicate-shot-list"
          />
          <SoonaToggle
            :model-value="true"
            type="checkbox"
            label="include the same products"
            name="include-products"
          />
        </div>

        <SoonaTextfield
          v-if="!isPack"
          ref="name-textfield"
          v-model="reservationName"
          label="new shoot title"
          name="reservation-name"
          class="duplicate-reservation__name-textfield"
          required
          :minlength="1"
          :rules="['required', 'minlength']"
        />
      </SoonaForm>
    </template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        v-if="isCopyable"
        variation="primary"
        :loading="isCopyingReservation"
        type="submit"
        :form="formId"
      >
        continue
      </SoonaButton>
      <SoonaButton
        v-else-if="packIsNotCopyable"
        element="router-link"
        to="/booking/new?page=packs"
        variation="primary"
      >
        continue
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.duplicate-reservation {
  &__loading-container {
    position: relative;
    min-height: 4rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }

  &__name-textfield {
    padding-bottom: 0;
  }
}
</style>

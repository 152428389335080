import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getCrewAssignments } from '../api/crewAssignments';

/**
 *
 * @param {number | Ref<number>} reservationId
 * @param {Object} [params]
 * @param {boolean} [params.without_declined]
 * @param {UseQueryOptions} [queryOptions]
 */
export function useGetCrewAssignments(
  reservationId,
  { without_declined } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.crewAssignments(reservationId, { without_declined }),
    queryFn: ({ signal }) =>
      getCrewAssignments(
        unref(reservationId),
        {
          without_declined: unref(without_declined),
        },
        signal
      ),
    ...queryOptions,
  });
}

<script setup>
import { computed, onMounted } from 'vue';
import { useTitle } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import AccountGalleryBreadcrumbs from '@/components/account_gallery/AccountGalleryBreadcrumbs.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import AccountInfoAuto from '@/components/user/anytime/dashboard/AccountInfoAuto.vue';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import FavoritesCollectionDigitalAssetsGallery from './FavoritesCollectionDigitalAssetsGallery.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

const accountId = computed(() => props.accountId);

const {
  data: favoritesCollection,
  error,
  isLoading,
} = useFavoritesCollection(accountId);

const priorityErrors = usePriorityErrors(error);

const title = computed(() => favoritesCollection.value?.title ?? 'favorites');

useTitle(title, { titleTemplate: '%s | soona' });
</script>

<template>
  <div class="favorites-collection-assets">
    <AccountInfoAuto :account-id="accountId" />
    <SoonaLoading v-if="isLoading" is-loading />
    <SoonaError v-else-if="priorityErrors" :priority-errors="priorityErrors" />
    <template v-else-if="favoritesCollection">
      <AccountGalleryBreadcrumbs :album-title="title" />
      <h1 class="u-title--heavy">
        {{ title }}
      </h1>
      <FavoritesCollectionDigitalAssetsGallery
        :account-id="accountId"
        :favorites-collection-id="favoritesCollection.id"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.favorites-collection-assets {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
</style>

<template>
  <div class="employee-type">
    <h1 class="u-title--heavy mb-l">employee type</h1>
    <h2 class="u-label--small employee-type-subtitle">
      create and update employees. for soona staff only.
    </h2>
    <fieldset class="field-wrapper">
      <div class="input-wrapper">
        <label class="u-label--heavy employee-label" for="employee_department">
          department
        </label>
        <v-select
          v-if="isEditing"
          v-model:model-value="employee.department"
          placeholder="please select one"
          name="employee_department"
          :selectable="disableTech"
          :options="employeeDepartment"
          :reduce="employeeDepartment => employeeDepartment"
          :clearable="false"
          :searchable="false"
          @option:selected="departmentSelect"
        >
        </v-select>
        <p v-else class="u-label--small field-read-mode">
          {{ employee.department }}
        </p>
      </div>
      <div class="input-wrapper">
        <label class="u-label--heavy employee-label" for="employee_role">
          employee role
        </label>
        <v-select
          v-if="isEditing"
          v-model:model-value="employee.employeeType"
          required
          :disabled="!canAddRole"
          placeholder="please select one"
          name="employee_title"
          :options="employeeRoles"
          :get-option-label="employeeRoles => `${employeeRoles.title}`"
          :clearable="false"
          :searchable="false"
        >
        </v-select>
        <p v-else class="u-label--small field-read-mode">
          {{ employee.employeeType.title }}
        </p>
      </div>
      <div class="input-wrapper">
        <label class="u-label--heavy employee-label" for="mpls"> studio </label>
        <div class="checkbox-wrapper ml-s">
          <div
            v-for="location in employeeLocations"
            :key="location.display"
            class="align-checkbox-and-label"
          >
            <input
              :id="location.display"
              v-model="employee.locations"
              :disabled="!isEditing"
              type="checkbox"
              :name="location.display"
              :value="location.id"
            />
            <label class="u-small--regular" :for="location.display">
              {{ location.short_name }}
            </label>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="action-btns">
      <SoonaButton v-if="!isEditing" size="medium" @on-click="toggleEdit">
        edit
      </SoonaButton>
      <template v-else>
        <template v-if="user.user_type === 'staff'">
          <SoonaButton
            class="remove-btn"
            size="medium"
            variation="tertiary"
            @on-click="removeCrewStatus"
          >
            remove from crew
          </SoonaButton>
          <SoonaButton
            size="medium"
            variation="tertiary"
            @on-click="toggleEdit"
          >
            cancel
          </SoonaButton>
        </template>
        <SoonaButton
          class="save-btn"
          :disabled="disableSaveBtn"
          size="medium"
          @on-click="save"
        >
          save
        </SoonaButton>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

export default {
  name: 'EmployeeType',
  components: { SoonaButton },
  setup() {
    const { hasCapability: canUpdateAllEmployeeType } = useCapability({
      capability: 'update_all_employee_type',
    });

    return { canUpdateAllEmployeeType };
  },
  data() {
    return {
      isEditing: false,
      employee: {
        department: '',
        title: '',
        employeeType: { title: '', id: null },
        locations: [],
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.account.owner,
      account: state => state.account,
      currentEmployeeType: state => state.employeeType.currentEmployeeType,
      employeeDepartment: state => state.employeeType.employeeDepartment,
      employeeRoles: state => state.employeeType.employeeRoles,
      employeeLocations: state => state.locations.locations,
      isMyAccount: state => state.currentUser.accountId === state.account.id,
    }),
    canAddRole() {
      return this.employee.department ? true : false;
    },
    disableSaveBtn() {
      if (this.employee.employeeType) {
        // TODO talk to Product about how to convey they need to enter a role
        return this.employee.employeeType.title && this.employee.department
          ? false
          : true;
      } else {
        return true;
      }
    },
    managersOwnAccount() {
      let isManagerTitle = this.currentEmployeeType.title === 'manager';
      return isManagerTitle && this.isMyAccount;
    },
  },
  mounted() {
    if (this.user.employee_type_id) {
      this.loadEmployeeType(this.user.employee_type_id).then(employeeType => {
        if (employeeType) {
          this.assignUserInfo();
        }
      });
    }
    this.loadLocations();
    this.assignLocationInfo();
  },
  methods: {
    ...mapActions('account', ['loadAccount']),
    ...mapActions('user', ['updateUser']),
    ...mapActions('employeeType', [
      'loadEmployeeTypesByDepartment',
      'loadEmployeeType',
    ]),
    ...mapActions('locations', ['loadLocations']),
    toggleEdit() {
      if (this.employee.department) {
        this.loadEmployeeTypesByDepartment(this.employee.department);
      }
      return (this.isEditing = !this.isEditing);
    },
    assignLocationInfo() {
      this.user.locations_users.map(item => {
        this.employee.locations.push(item.location_id);
      });
    },
    assignUserInfo() {
      if (this.currentEmployeeType) {
        this.employee.department = this.currentEmployeeType.department;
        this.employee.employeeType = this.currentEmployeeType;
      }
    },
    resetform() {
      this.employee.locations = [];
      this.employee.employeeType = {};
      this.employee.department = '';
    },
    locationsToDeleteUponCrewRemoval() {
      let locationsUsersToDelete = [];
      this.user.locations_users.forEach(locationsUsersObject => {
        let objectToDelete = {
          user_id: this.user.id,
          location_id: locationsUsersObject.location_id,
          _destroy: 1,
          id: locationsUsersObject.id,
        };
        locationsUsersToDelete.push(objectToDelete);
      });
      return locationsUsersToDelete;
    },
    async removeCrewStatus() {
      await this.updateUser({
        userId: this.user.id,
        userParams: {
          employee_type_id: null,
          locations_users_attributes: this.locationsToDeleteUponCrewRemoval(),
          user_type: 'customer',
        },
      })
        .then(this.loadAccount(this.account.id))
        .then(this.resetform());
      return (this.isEditing = !this.isEditing);
    },
    async save() {
      await this.updateUser({
        userId: this.user.id,
        userParams: {
          employee_type_id: this.employee.employeeType.id,
          locations_users_attributes: this.createAttributesArray(),
          user_type: 'staff',
        },
      }).then(this.loadAccount(this.account.id));
      return (this.isEditing = !this.isEditing);
    },
    createAttributesArray() {
      let locationInfoForRequest = [];
      this.user.locations_users.forEach(locationObject => {
        let statusNumber = this.employee.locations.indexOf(
          locationObject.location_id
        );
        if (statusNumber == -1) {
          let objectToDelete = {
            user_id: this.user.id,
            location_id: locationObject.location_id,
            _destroy: 1,
            id: locationObject.id,
          };
          locationInfoForRequest.push(objectToDelete);
        }
      });
      this.employee.locations.forEach(chosenLocationId => {
        let statusNumber = this.user.locations_users
          .map(function (e) {
            return e.location_id;
          })
          .indexOf(chosenLocationId);
        if (statusNumber == -1) {
          let objectToAdd = {
            user_id: this.user.id,
            location_id: chosenLocationId,
          };
          locationInfoForRequest.push(objectToAdd);
        }
      });
      return locationInfoForRequest;
    },
    departmentSelect() {
      this.loadEmployeeTypesByDepartment(this.employee.department);
      this.employee.employeeType = {
        title: '',
        id: null,
      };
    },
    disableTech(item) {
      return item !== 'tech' || this.canUpdateAllEmployeeType;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.employee-type {
  background-color: variables.$white-default;
  border-radius: 0.75rem;
  box-shadow: variables.$elevation-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
}

.checkbox-wrapper label,
.location-display {
  margin-right: 20px;
  margin-left: 5px;
}

.field-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
}

.field-read-mode {
  background: variables.$gray-10;
  border: none;
  border-radius: 0.375rem;
  height: 1.875rem;
  overflow: hidden;
  padding: 0.5em 0.75em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-wrapper {
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
}

.employee-label {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  left: 0.25rem;
  position: absolute;
  top: -1.125rem;
}

.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.align-checkbox-and-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  label {
    overflow-wrap: break;
  }
}

.employee-type-subtitle {
  margin: 0.25rem 0 1rem;
  font-weight: 900;
}

.action-btns {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.save-btn {
  background-color: variables.$periwink-blue-60;

  &:not(:disabled):hover {
    background-color: variables.$periwink-blue-70;
  }
}

.remove-btn {
  color: variables.$roses-60;
  margin-right: auto;
}
</style>

<script setup>
import { onMounted, onUnmounted, ref, watch, computed } from 'vue';
import { useMediaQuery } from '@vueuse/core';
// import { useEditsCollectionDigitalAssets } from '@/queries/edits_collection_digital_assets/useEditsCollectionDigitalAssets';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';

const props = defineProps({
  alwaysShowTitles: Boolean,
  editsCollectionId: [String, Number],
  reservationId: [String, Number],
  queryFilters: {
    type: Object,
    default: () => ({}),
  },
  facetData: Object,
});

const selectedFilter = defineModel({
  type: String,
  default: '',
});

const galleryFilterButtons = ref();
const galleryFilterButtonsPinned = ref(false);
const matchMediaIsWide = useMediaQuery('(min-width: 64rem)');
let observer;

const style = getComputedStyle(document.body);

const createObserver = topOffset => {
  const topOffsetNum = parseInt(topOffset, 10);

  observer = new IntersectionObserver(
    ([e]) => {
      galleryFilterButtonsPinned.value =
        e.intersectionRatio < 1 && e.intersectionRect?.top === topOffsetNum;
    },
    {
      threshold: [1],
      rootMargin: `${-Math.abs(topOffsetNum)}px 0px 0px 0px`,
    }
  );
};

const filterOptions = computed(() => {
  const options = [
    {
      label: 'all',
      value: 'all',
      count: props.facetData?.pagination?.totalCount,
    },
    {
      label: 'delivered',
      value: 'delivered',
      count: props.facetData?.delivered_count,
    },
    {
      label: 'pending',
      value: 'pending',
      count: props.facetData?.pending_count,
    },
    {
      label: 'editor rejected',
      value: 'rejected',
      count: props.facetData?.rejected_count,
    },
    {
      label: 're-edit',
      value: 're_edit',
      count: props.facetData?.re_edit_count,
    },
    {
      label: 'unlinked',
      value: 'unlinked',
      count: props.facetData?.unlinked_count,
    },
    {
      label: 'hidden',
      value: 'hidden',
      count: props.facetData?.hidden_count,
    },
  ];

  return options;
});

// lifecycle hooks
onMounted(() => {
  createObserver(
    matchMediaIsWide.value ? style.getPropertyValue('--app-header-height') : 0
  );
  observer.observe(galleryFilterButtons.value);
});

onUnmounted(() => {
  observer.disconnect();
});

watch(matchMediaIsWide, val => {
  observer.disconnect();
  createObserver(val ? style.getPropertyValue('--app-header-height') : 0);
  observer.observe(galleryFilterButtons.value);
});
</script>

<template>
  <div class="gallery-filter">
    <div
      ref="galleryFilterButtons"
      class="gallery-filter__buttons"
      :class="{ 'gallery-filter__buttons--pinned': galleryFilterButtonsPinned }"
    >
      <SoonaFilterSingle
        v-model="selectedFilter"
        :options="filterOptions"
        variation="friendly-red"
        size="large"
      >
        <template
          #option="{ count, iconName, label, selected, selectedIconName }"
        >
          <SoonaIcon
            v-if="iconName"
            size="medium"
            :name="selected && selectedIconName ? selectedIconName : iconName"
          />
          {{ label }}
          <span v-if="count" class="u-body--regular">({{ count }})</span>
        </template>
      </SoonaFilterSingle>
      <div class="gallery-filter__right">
        <slot name="gallery-filter-right" />
      </div>
    </div>
    <div class="gallery-filter__bottom">
      <slot name="gallery-filter-bottom" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.gallery-filter {
  display: unset;

  &__credits {
    align-items: center;
    display: flex;

    svg {
      color: variables.$black-default;
      margin-right: 0.25rem;
    }

    p {
      @include variables_fonts.u-label--regular;

      @media (min-width: variables.$screen-sm-min) {
        @include variables_fonts.u-body--regular;
      }
    }
  }

  &__buttons {
    position: sticky;
    top: calc(var(--app-header-viewport-offset) - 1px);
    z-index: 4;
    align-items: center;
    background: variables.$periwink-blue-10;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
    margin-bottom: 3rem;
    padding: 1rem;
    container-type: inline-size;

    &--pinned {
      box-shadow: variables.$elevation-3;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__right {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
</style>

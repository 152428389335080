<template>
  <span class="da-status u-badge--small">
    <slot />
  </span>
</template>

<style lang="scss" scoped>
@use '@/variables';

.da-status {
  align-self: flex-end;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  padding: 0.0625rem 0.25rem;
  border-radius: 3.125rem;
  background-color: variables.$black-translucent-60;
  color: variables.$white-default;
}

.da-status :slotted(svg) {
  height: 0.75rem;
  width: 0.75rem;
}
</style>

import { computed, ref } from 'vue';
import useSubscriptionActionPermissions from '@/components/user/anytime/listing_insights/listing_actions/useSubscriptionActionPermissions';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';
import { useCreateListingActionDigitalAsset } from '@/queries/useListingActionDigitalAssets';
import {
  useDeleteListingActionDigitalAssets,
  useUpdateListingAction,
} from '@/queries/useListingActions';
import { useCreatePublishTask } from '@/queries/useDigitalAssetPublishTasks';
import { useCreateBulkActionTask } from '@/queries/useDigitalAssetBulkActionTasks';
import { useDigitalAssetsBulkPublishManager } from '@/queries/useDigitalAssetsBulkPublishManager';

export function useOptimizeImageSizeActions(
  accountId,
  listingAction,
  { externalProductId = null, integrationId = null, includeSiblings = false }
) {
  const {
    canTakeAction,
    availableCreditsError,
    isBasicUserAndOutOfCredits,
    userCanUpgrade,
    subscriptionActionPermissionsSuccess,
    optimizeButtonVariation,
  } = useSubscriptionActionPermissions(accountId);

  const { data: managerData } = useDigitalAssetsBulkPublishManager(
    accountId,
    'low-resolution'
  );

  const mostRecentBulkPublishManager = computed(() => {
    return managerData?.value;
  });

  const showUploadDialog = ref(false);
  const isProcessing = ref(false);
  const optimizeTriggered = ref(false);
  const publishTriggered = ref(false);

  const {
    mutate: createListingActionDigitalAsset,
    error: createListingActionDigitalAssetError,
  } = useCreateListingActionDigitalAsset(accountId, {
    externalProductId,
    integrationId,
  });

  const listingActionId = computed(() => listingAction.value.id);
  const { mutate: updateListingAction } = useUpdateListingAction(
    accountId,
    listingActionId
  );
  const { mutate: createBulkActionTask } = useCreateBulkActionTask(accountId);
  const { mutate: createPublishTask } = useCreatePublishTask(accountId, {
    externalProductId,
    integrationId,
  });

  const linkUploadedAsset = digitalAssetId => {
    createListingActionDigitalAsset(
      {
        digital_asset_id: digitalAssetId,
        listing_action_id: listingActionId.value,
        include_siblings: includeSiblings,
      },
      {
        onSuccess: () => {
          isProcessing.value = false;
        },
        onError: () => {
          isProcessing.value = false;
        },
      }
    );
  };

  const processBulkAction = sourceAssetId => {
    createBulkActionTask(
      {
        digital_asset_ids: [sourceAssetId],
        bulk_action: 'upscale_resolution',
      },
      {
        onSuccess: () => {
          optimizeTriggered.value = false;
        },
      }
    );
  };
  const linkSourceAsset = digitalAssetId => {
    updateListingAction(
      {
        source_asset_id: digitalAssetId,
        include_siblings: includeSiblings,
      },
      {
        onSuccess: data => {
          processBulkAction(data.source_asset_id);
        },
      }
    );
  };

  const { mutate: createDigitalAsset, error: createDigitalAssetError } =
    useCreateDigitalAsset(accountId);

  const handleUploadComplete = blob => {
    isProcessing.value = true;
    showUploadDialog.value = false;

    const digitalAsset = {
      asset_type: 'original',
      file: blob.signed_id,
      title: blob.filename,
      media_type: blob.content_type.startsWith('image') ? 'photo' : 'video',
      ownership: 'customer',
      origin: 'customer',
      origin_source: 'manual_upload',
      visibility: 'all',
    };

    createDigitalAsset(digitalAsset, {
      onSuccess: data => {
        linkUploadedAsset(data.id);
      },
      onError: () => {
        isProcessing.value = false;
      },
    });
  };

  // undo changes module
  const {
    mutate: deleteListingDigitalAssets,
    error: deleteListingDigitalAssetsError,
  } = useDeleteListingActionDigitalAssets(accountId, {
    externalProductId,
    integrationId,
  });

  // publish module

  const resetListingAction = () => {
    updateListingAction({
      status: 'pending_approval',
    });
  };

  const downloadReplacementAsset = () => {
    if (listingAction.value?.replacement_asset?.file_url) {
      window.open(listingAction.value.replacement_asset.file_url, '_blank');
    }
  };

  return {
    canTakeAction,
    userCanUpgrade,
    availableCreditsError,
    isBasicUserAndOutOfCredits,
    subscriptionActionPermissionsSuccess,
    optimizeTriggered,
    publishTriggered,
    isProcessing,
    showUploadDialog,
    resetListingAction,
    linkSourceAsset,
    handleUploadComplete,
    createPublishTask,
    createDigitalAssetError,
    createListingActionDigitalAssetError,
    deleteListingDigitalAssetsError,
    deleteListingDigitalAssets,
    optimizeButtonVariation,
    downloadReplacementAsset,
    mostRecentBulkPublishManager,
  };
}

import { http } from '../config/vue-axios';
import { mapPaginationHeaders } from '../lib/api-transforms';

/**
 *
 * @param {string | number} accountId
 * @param {Object} params
 * @param {string | number} [params.integrationId]
 * @param {string | null} [params.query]
 * @param {string} [params.scoreCategory]
 * @param {string} [params.orderBy]
 * @param {string} [params.direction]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {AbortSignal} signal
 * @returns {Promise<any>}
 */
export async function getListings(
  accountId,
  {
    integrationId = null,
    query = null,
    scoreCategory = null,
    orderBy = 'name',
    direction = 'asc',
    itemsPerPage,
    currentPage,
    listingActionSlug = null,
    listingRuleSlug = null,
    listingRuleId = null,
    catalogItemId = null,
  },
  signal
) {
  const params = {};

  if (integrationId) params['integration_id'] = integrationId;
  if (query) params['query'] = query;
  if (scoreCategory) params['score_category'] = scoreCategory;
  if (orderBy) params['order_by'] = orderBy;
  if (direction) params['direction'] = direction;
  if (itemsPerPage) params.items = itemsPerPage;
  if (currentPage) params.page = currentPage;
  if (listingActionSlug) params.listing_action_slug = listingActionSlug;
  if (listingRuleSlug) params.listing_rule_slug = listingRuleSlug;
  if (listingRuleId) params.listing_rule_id = listingRuleId;
  if (catalogItemId) params.catalog_item_id = catalogItemId;

  const response = await http.get(`/accounts/${accountId}/listings`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    listings: response.data.listings,
    all_listings_scored: response.data.all_listings_scored,
  };
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} listingId
 * @param {AbortSignal} signal
 * @returns {Promise}
 */
export async function getListing(accountId, listingId, signal) {
  const response = await http.get(
    `/accounts/${accountId}/listings/${listingId}`,
    { signal }
  );

  return response.data;
}

/**
 *
 * @param {string | number} integrationId
 * @param {string | number} externalProductId
 * @param {AbortSignal} signal
 * @returns {Promise}
 */
export async function getActiveListing(
  integrationId,
  externalProductId,
  signal
) {
  const response = await http.get(
    `/integrations/${integrationId}/external_products/${externalProductId}/active_listing`,
    { signal }
  );

  return response.data;
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} integrationId
 * @param {AbortSignal} signal
 * @returns {Promise<any>}
 */
export async function getListingInsights(accountId, integrationId, signal) {
  const params = {};
  if (integrationId) params.integration_id = integrationId;
  const response = await http.get(`/accounts/${accountId}/listing_insights`, {
    params,
    signal,
  });

  return response.data;
}

/**
 *
 * @param {string | number} integrationId
 * @param {string | number} externalProductId
 * @returns
 */
export async function refreshListing(integrationId, externalProductId) {
  return http.post(
    `/integrations/${integrationId}/external_products/${externalProductId}/refresh_listing`
  );
}

<script setup>
import { ref, useId } from 'vue';
import { useRouter } from 'vue-router';
import { useCreateAccount } from '@/queries/account/useCreateAccount';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';

const emit = defineEmits(['close']);

const router = useRouter();
const id = useId();
const { addToast } = useSoonaToast();
const newAccountName = ref('');

const {
  mutate: createAccount,
  isPending: isCreating,
  error: createAccountError,
} = useCreateAccount({
  onSuccess: async () => {
    emit('close');
    addToast('account created', {
      variation: 'success',
    });
    await router.push('/');
  },
});

const priorityErrors = usePriorityErrors(createAccountError);

function handleCreateAccount() {
  const attributes = { account: { name: newAccountName.value } };

  createAccount(attributes);
}
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>create a new account</template>

    <SoonaForm :id="id" @submit="handleCreateAccount">
      <SoonaTextfield
        v-model="newAccountName"
        label="company or brand name"
        name="name"
        placeholder="enter the account name"
        :required="true"
        :rules="['required']"
        :disabled="isCreating"
        :maxlength="100"
        data-cypress="account-name"
      />

      <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    </SoonaForm>

    <template #footer="{ close }">
      <SoonaButton
        variation="tertiary"
        :disabled="isCreating"
        @on-click="close"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        :disabled="!newAccountName"
        :loading="isCreating"
        data-cypress="button-dialog-confirm"
        type="submit"
        :form="id"
      >
        create account
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

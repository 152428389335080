<script setup>
import { computed, toRef } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaExpansionPanel from '@/components/ui_library/SoonaExpansionPanel.vue';
import AccordionHeaderIcon from '@/components/user/anytime/listing_insights/listing_detail/accordions/AccordionHeaderIcon.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaBlogPostLink from '@/components/ui_library/SoonaBlogPostLink.vue';
import { useListingDetailAccordion } from '@/components/user/anytime/listing_insights/listing_detail/accordions/useListingDetailAccordion';
import AiDisclaimer from '@/components/user/anytime/listing_insights/listing_detail/accordions/AiDisclaimer.vue';

const props = defineProps({
  data: { type: Object, required: true },
  assets: { type: Array, required: true },
  platform: {
    type: String,
    required: true,
    validator: function (value) {
      return ['Amazon', 'Shopify'].includes(value);
    },
  },
});

const route = useRoute();

const { hasBrokenRules, linkTo, linkTitle, linkImage } =
  useListingDetailAccordion(
    toRef(() => props.data),
    toRef(() => props.platform)
  );

const { linkClicked } = useBaseEvents();

const trackLinkClicked = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'single listing overview - gallery count',
    linkLabel: `Learn More - ${linkTitle.value}`,
    linkHref: linkTo,
  });
};

const imageCountRule = computed(
  () =>
    props.data?.rules_result?.rules.find(x => x.rule === 'image count') ?? []
);

const numberOfImages = computed(
  () => imageCountRule.value?.assets?.length || 0
);
</script>

<template>
  <SoonaExpansionPanel>
    <template #header>
      <span class="gallery-count__header--wrapper">
        <AccordionHeaderIcon icon-name="album" :status="data.score.label" />
        <span class="gallery-count__header-content">
          <span class="gallery-count__header-title">gallery count</span>
          <span class="gallery-count__header-actions">
            <span v-if="hasBrokenRules" class="gallery-count__header-action">
              <SoonaIcon
                name="status-dot-small"
                size="small"
                class="gallery-count__header-action-icon--fail"
              />
              <span class="gallery-count__header-action-text">
                increase quantity to 6+
              </span>
            </span>
            <span v-else class="gallery-count__header-action">
              <SoonaIcon
                name="circle-check-solid"
                size="small"
                class="gallery-count__header-action-icon--pass"
              />
              <span class="gallery-count__header-action-text">6+ images</span>
            </span>
          </span>
        </span>
      </span>
    </template>

    <template #panel>
      <div class="gallery-count__panel--wrapper">
        <div class="gallery-count__panel-images">
          <div
            v-for="(image, i) in assets"
            :key="i"
            class="gallery-count__panel-image"
          >
            <img :src="image.digital_asset_url" alt="" />
          </div>
        </div>
        <div class="gallery-count__panel-content">
          <div class="rule-result__icon">
            <SoonaIcon name="album" />
          </div>
          <div class="rule-result__content">
            <div class="rule-result__title">
              <p class="u-body--heavy">
                {{ numberOfImages }} image{{
                  numberOfImages > 1 || numberOfImages === 0 ? 's' : ''
                }}
              </p>
              <div v-if="numberOfImages < 6" class="rule-result__title--fail">
                <SoonaIcon
                  name="status-dot-small"
                  size="small"
                  class="rule-result__title--fail-icon"
                />
                <p class="u-badge--small">6+ recommended</p>
              </div>
              <div v-else class="rule-result__title--pass">
                <SoonaIcon
                  name="circle-check-solid"
                  size="small"
                  class="rule-result__title--pass-icon"
                />
              </div>
            </div>

            <p class="rule-result__text">
              {{ imageCountRule?.rule_description }}
            </p>
          </div>
        </div>
        <AiDisclaimer />
        <div class="gallery-count__panel-blog-link">
          <p>learn more</p>
          <SoonaBlogPostLink
            :image-src="linkImage"
            :title="linkTitle"
            :to="linkTo"
            @link-clicked="trackLinkClicked"
          />
        </div>
      </div>
    </template>
  </SoonaExpansionPanel>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.gallery-count {
  &__header {
    &--wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &-title {
      @include variables_fonts.u-badge--small;

      color: variables.$gray-60;
    }

    &-action {
      @include variables_fonts.u-body--heavy;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;

      &-icon {
        &--fail {
          color: variables.$roses-60;
        }
        &--pass {
          color: variables.$avo-toast-40;
        }
      }
    }
  }

  &__panel {
    &--wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-images {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
      padding-bottom: 1rem;
    }

    &-image {
      width: 5rem;
      height: 5rem;
      flex: 0 0 5rem;

      img {
        border-radius: 0.3125rem;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &-content {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }

    &-blog-link {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      margin-top: 1rem;

      p {
        @include variables_fonts.u-badge--big;

        color: variables.$gray-60;
      }
    }
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__panel {
      &--wrapper {
        padding: 1rem;
      }
    }
  }
}

.rule-result {
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    &--fail {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;

      &-icon {
        color: variables.$friendly-red-50;
      }
    }

    &--pass {
      &-icon {
        color: variables.$avo-toast-40;
      }
    }
  }
}
</style>

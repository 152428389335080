<template>
  <div
    class="soona-tag-option-card"
    :class="[
      { 'soona-tag-option-card--shadow': hasShadow },
      { 'soona-tag-option-card--vertical': layout === 'vertical' },
    ]"
  >
    <SoonaTagOptionCardMainImage
      v-if="!primaryVideo"
      :has-carousel="hasCarousel"
      :image-list="imageList"
      :layout="layout"
    />
    <SoonaTagOptionCardMainVideo
      v-else
      ref="main-video"
      :poster-image="imageList.slice(0, 1)[0]"
      :video-src="primaryVideo"
      :video-alt="primaryVideoAlt"
      @video-played="$emit('video-played', tagId)"
    />
    <div class="soona-tag-option-card__right">
      <SoonaTagOptionCardMainCopy
        :title="title"
        :title-element="titleElement"
        :price-info="priceInfo"
        :description="description"
        :layout="layout"
        :show-description="showTagDescription"
        :has-more-details="hasMoreDetails"
        :handle-open-modal="handleOpenModal"
      />
      <div class="soona-tag-option-card__action-buttons">
        <QuantityIncrementor
          v-if="hasQuantityIncrementor && !inBag"
          class="soona-tag-option-card__quantity-incrementor"
          :max="maxQuantity"
          :min="minQuantity"
          :disable-decrement="decrementDisabled"
          :value="quantity"
          :validation-wraps="true"
          :disabled="buttonDisabled"
          @on-increment="incrementQuantity"
          @on-decrement="decrementQuantity"
        />
        <SoonaTagOptionCardSubmitButton
          :add-or-remove-item-from-cart="addOrRemoveItemFromCart"
          :button-copy="buttonCopy"
          :button-disabled="buttonDisabled"
          :show-feedback="showFeedback"
          :in-bag="inBag"
          :is-loading="isLoading"
          :title="title"
        />
      </div>
    </div>
    <SoonaDialog
      v-if="isModalOpen"
      max-width="55.75rem"
      @close="handleModalClose"
    >
      <div class="soona-tag-option-card__modal">
        <div class="soona-tag-option-card__modal-top">
          <SoonaTagOptionCardModalMainImage
            :has-carousel="hasCarousel"
            :image-list="imageList"
          />
          <SoonaTagOptionCardModalMainCopy
            :description="modalDescription"
            :price-info="priceInfo"
            :title="title"
            :title-element="modalTitleElement"
          />
        </div>
        <div class="soona-tag-option-card__modal-bottom">
          <SoonaTagOptionCardModalSubCopy
            :subcopy="modalSubcopy"
            :subtitle="modalSubtitle"
          />
          <SoonaTagOptionCardModalGrid
            :has-pro-service-comparison="hasProServiceComparison"
            :image-list="modalImageList"
            :pro-service-name="proServiceName"
          />
        </div>
      </div>
      <template #footer="{ close }">
        <SoonaButton type="button" @click="close">got it!</SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>
<script>
import uniqueId from 'lodash/uniqueId';
import SoonaDialog from 'src/components/ui_library/SoonaDialog.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaTagOptionCardMainCopy from './SoonaTagOptionCardMainCopy.vue';
import SoonaTagOptionCardModalGrid from './SoonaTagOptionCardModalGrid.vue';
import SoonaTagOptionCardModalMainCopy from './SoonaTagOptionCardModalMainCopy.vue';
import SoonaTagOptionCardModalMainImage from './SoonaTagOptionCardModalMainImage.vue';
import SoonaTagOptionCardModalSubCopy from './SoonaTagOptionCardModalSubCopy.vue';
import SoonaTagOptionCardSubmitButton from './SoonaTagOptionCardSubmitButton.vue';
import QuantityIncrementor from 'src/components/booking/QuantityIncrementor.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaTagOptionCardMainVideo from './SoonaTagOptionCardMainVideo.vue';
import SoonaTagOptionCardMainImage from './SoonaTagOptionCardMainImage.vue';

export default {
  components: {
    SoonaTagOptionCardMainImage,
    SoonaTagOptionCardMainVideo,
    SoonaDialog,
    SoonaButton,
    SoonaTagOptionCardMainCopy,
    SoonaTagOptionCardModalGrid,
    SoonaTagOptionCardModalMainCopy,
    SoonaTagOptionCardModalMainImage,
    SoonaTagOptionCardModalSubCopy,
    SoonaTagOptionCardSubmitButton,
    QuantityIncrementor,
  },
  expose: ['pauseVideo', 'tagId'],
  props: {
    buttonCopy: {
      default: null,
      required: false,
      type: String,
    },
    buttonDisabled: {
      default: false,
      required: false,
      type: Boolean,
    },
    displayModalBeforeRemoved: {
      default: false,
      required: false,
      type: Boolean,
    },
    description: {
      default: undefined,
      required: false,
      type: String,
    },
    hasCarousel: {
      default: false,
      required: false,
      type: Boolean,
    },
    // this is for the 'special' modal photo grid
    hasProServiceComparison: {
      default: false,
      required: false,
      type: Boolean,
    },
    hasMoreDetails: {
      default: false,
      required: false,
      type: Boolean,
    },
    hasQuantityIncrementor: {
      default: false,
      required: false,
      type: Boolean,
    },
    hasShadow: {
      default: false,
      required: false,
      type: Boolean,
    },
    inBag: {
      default: false,
      required: false,
      type: Boolean,
    },
    isLoading: {
      default: false,
      required: false,
      type: Boolean,
    },
    maxQuantity: {
      default: undefined,
      required: false,
      type: Number,
    },
    minQuantity: {
      default: undefined,
      required: false,
      type: Number,
    },
    modalDescription: {
      default: undefined,
      required: false,
      type: String,
    },
    modalSubcopy: {
      default: undefined,
      required: false,
      type: String,
    },
    modalSubtitle: {
      default: undefined,
      required: false,
      type: String,
    },
    modalTitleElement: {
      default: undefined,
      required: false,
      type: String,
      validator: function (value) {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value);
      },
    },
    layout: {
      default: 'horizontal',
      required: false,
      type: String,
      validator: function (value) {
        return ['horizontal', 'vertical'].includes(value);
      },
    },
    priceInfo: {
      default: null,
      required: false,
      type: Object,
    },
    primaryImage: {
      default: null,
      required: false,
      type: String,
    },
    primaryVideo: {
      default: null,
      required: false,
      type: String,
    },
    primaryVideoAlt: {
      default: null,
      required: false,
      type: String,
    },
    proServiceName: {
      default: undefined,
      required: false,
      type: String,
    },
    secondaryImages: {
      default: () => [],
      required: false,
      type: Array,
    },
    showTagDescription: {
      default: true,
      type: Boolean,
    },
    tagId: {
      type: Number,
      required: true,
    },
    tertiaryImages: {
      default: () => [],
      required: false,
      type: Array,
    },
    title: {
      default: undefined,
      required: false,
      type: String,
    },
    titleElement: {
      default: undefined,
      required: false,
      type: String,
      validator: function (value) {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value);
      },
    },
  },
  emits: ['action', 'video-played'],
  setup() {
    const { buttonClicked } = useBaseEvents();

    return {
      buttonClicked,
    };
  },
  data() {
    const baseId = uniqueId();
    return {
      decrementDisabled: false,
      feedbackTimer: undefined,
      imageId: `model-image-${baseId}`,
      isModalOpen: false,
      modalImageId: `model-modal-image-${baseId}`,
      quantity: 0,
      showFeedback: false,
      showFeedbackOnNextClick: undefined,
    };
  },
  computed: {
    imageList() {
      let imgs = [];
      if (this.primaryImage) imgs.push({ src: this.primaryImage, alt: '' });
      if (this.secondaryImages?.length > 0) {
        imgs = imgs.concat(
          this.secondaryImages.map(url => ({ src: url, alt: '' }))
        );
      }
      return imgs;
    },
    modalImageList() {
      if (this.tertiaryImages?.length > 0) {
        return this.tertiaryImages.map(url => ({ src: url, alt: '' }));
      }
      return [];
    },
  },
  watch: {
    // TODO: hook sidecart/reservation data up here
    // watch for this item's quantity to change, or if it was removed entirely from sidecart
    // TODO update this.decrementDisabled based on if item is in cart
  },
  mounted() {
    if (this.minQuantity) this.quantity = this.minQuantity;

    this.updateShowFeedbackOnNextClick();
  },
  beforeUnmount() {
    clearTimeout(this.feedbackTimer);
  },
  methods: {
    addOrRemoveItemFromCart() {
      this.handleFeedbackState();
      this.$emit('action', this.quantity);
    },
    decrementQuantity() {
      this.quantity--;
    },
    handleFeedbackState() {
      const removingWithModal = this.displayModalBeforeRemoved && this.inBag;
      if (this.showFeedbackOnNextClick && !removingWithModal) {
        this.showFeedback = true;
        if (this.hasQuantityIncrementor) {
          this.feedbackTimer = setTimeout(() => {
            this.showFeedback = false;
          }, 2000);
        }
      } else {
        this.showFeedback = false;
      }
    },
    incrementQuantity() {
      this.quantity++;
    },
    updateShowFeedbackOnNextClick() {
      this.showFeedbackOnNextClick = this.hasQuantityIncrementor;
    },
    handleOpenModal() {
      this.isModalOpen = true;
      this.buttonClicked({
        context: 'booking',
        subContext: this.$route.meta.page_title,
        buttonLabel: 'more details',
        buttonAction: 'open modal with additional details',
      });
    },
    handleModalClose() {
      this.isModalOpen = false;
      this.buttonClicked({
        context: 'booking',
        subContext: this.$route.meta.page_title,
        buttonLabel: 'got it!',
        buttonAction: 'close modal with additional details',
      });
    },
    pauseVideo() {
      this.$refs['main-video']?.pauseVideo();
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-tag-option-card {
  background-color: variables.$white-default;
  border-radius: 0.625rem;
  border-bottom: 0.0625rem solid variables.$gray-30;
  color: variables.$black-default;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  max-width: 24.625rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: variables.$screen-sm-min) {
    border-bottom: none;
    display: flex;
    padding-bottom: 0;
    width: 100%;
    min-height: 17.25rem;
    max-width: none;

    &--shadow {
      box-shadow: variables.$elevation-1;
    }
  }

  &--vertical {
    margin-left: 0;
    margin-right: 0;

    @media (min-width: variables.$screen-sm-min) {
      display: block;
      flex-basis: 48%;
    }

    @media (min-width: variables.$screen-lg-min) {
      flex-basis: 30%;
    }

    .soona-tag-option-card__right {
      display: flex;
      flex-direction: column;

      @media (min-width: variables.$screen-sm-min) {
        padding: 1rem;
        padding-top: 0;
        width: 100%;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;

    @media (min-width: variables.$screen-sm-min) {
      padding: 1rem;
      padding-left: 0.75rem;
      padding-top: 1.875rem;
      width: 50%;
    }
  }

  &__action-buttons {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-top: 1rem;
  }

  &__modal {
    &-top {
      border-bottom: 0.125rem solid variables.$black-default;
      margin-bottom: 1.5rem;
      padding-bottom: 2rem;

      @media (min-width: variables.$screen-sm-min) {
        display: flex;
        align-items: center;
        max-width: 100%;
      }
    }
  }
}
</style>

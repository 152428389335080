import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param { ref } accountId
 * @returns UseMutationReturnType<*>
 */
export function useCreateAnimatedCollection(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.post(
        `/accounts/${toValue(accountId)}/animated_collections`,
        {
          animated_collection: body,
        }
      );
      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collectionsDA(accountId, 'animated_collection'),
        }),
      ]);
    },
  });
}

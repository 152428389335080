<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  basePath: {
    default: '/',
    type: String,
  },
  showSignUpLink: {
    default: true,
    type: Boolean,
  },
  type: {
    required: true,
    type: String,
    validator: val => {
      return ['sign-in', 'sign-up'].includes(val);
    },
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const headingCopy = computed(() => {
  if (props.type === 'sign-in') return 'welcome back!';
  return 'create an account';
});

const headingEmoji = computed(() => {
  if (props.type === 'sign-in') return '👋';
  return '✌️';
});

const subheadingCopy = computed(() => {
  if (props.type === 'sign-in') return 'sign in to continue. new to soona?';
  return 'already have an account?';
});

const normalizedBasePath = computed(() => {
  if (props.basePath === '/') return '';
  if (props.basePath.startsWith('/')) return props.basePath;
  return `/${props.basePath}`;
});

const linkLabel = computed(() => {
  if (props.type === 'sign-in') return 'sign up';
  return 'sign in';
});

const linkHref = computed(() => {
  if (props.type === 'sign-in') return `${normalizedBasePath.value}/sign-up`;
  return `${normalizedBasePath.value}/sign-in`;
});

const trackLinkClicked = () => {
  linkClicked({
    context: route.meta.context,
    subContext: props.type?.replace('-', ' '),
    linkLabel: linkLabel.value,
    linkHref: linkHref.value,
  });
};
</script>

<template>
  <div class="auth-heading">
    <h1 class="u-display--heavy">
      {{ headingCopy }} <span aria-hidden="true">{{ headingEmoji }}</span>
    </h1>
    <p v-if="showSignUpLink">
      {{ subheadingCopy }}
      <SoonaButton
        element="router-link"
        :to="{ path: linkHref, query: route.query }"
        variation="tertiary"
        :data-cypress="`link-${linkLabel.replace(/\s+/g, '-')}`"
        @on-click="trackLinkClicked"
      >
        {{ linkLabel }}
      </SoonaButton>
    </p>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.auth-heading {
  padding-bottom: 2rem;
}
</style>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.334 8.333 8.334S18.333 14.6 18.333 10 14.6 1.667 10 1.667Zm.833 12.5H9.167v-5h1.666v5Zm0-6.667H9.167V5.834h1.666V7.5Z"
      fill="#da8301"
    />
  </svg>
</template>

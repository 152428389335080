<script setup>
import { computed, ref, toRefs } from 'vue';
import { useCatalogItem, useCatalogItemVariants } from 'src/queries/useCatalog';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import PaginatorFull from 'src/components/directory/PaginatorFull.vue';
import SoonaNoResults from 'src/components/ui_library/SoonaNoResults.vue';
import ProductCard from './ProductCard.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { refDebounced } from '@vueuse/core';
import ProductVariantFilters from './ProductVariantFilters.vue';
import { keepPreviousData } from '@tanstack/vue-query';
import { useTitle } from '@vueuse/core';

const props = defineProps({
  accountId: { type: String, required: true },
  catalogItemId: { type: String, required: true },
});

const { accountId, catalogItemId } = toRefs(props);

useTitle('product variants | soona');

// parent product module
const { data: parentProductData, error: parentProductError } = useCatalogItem(
  accountId,
  catalogItemId
);

const parentProduct = computed(() => parentProductData.value ?? { name: '' });

const parentProductName = computed(() => parentProduct.value.name);

// variants module
// TO DO: handle these as query params
const searchText = ref('');
const searchDebounced = refDebounced(searchText, 200);
const selectedFilter = ref('all');
const selectedStatusFilter = ref('active');
const sortBy = ref({
  orderBy: 'name',
  direction: 'asc',
});
const orderBy = computed(() => sortBy.value.orderBy);
const direction = computed(() => sortBy.value.direction);

function resetFilters() {
  searchText.value = '';
  selectedFilter.value = 'all';
}

const currentPage = ref(1);
const itemsPerPage = ref(10);

const {
  data: variantsData,
  error,
  isSuccess,
} = useCatalogItemVariants(
  accountId,
  catalogItemId,
  {
    query: searchDebounced,
    filter: selectedFilter,
    status: selectedStatusFilter,
    orderBy,
    direction,
    currentPage,
    itemsPerPage,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const catalogItemsPagination = computed(() =>
  variantsData.value
    ? variantsData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

const catalogItems = computed(
  () => variantsData.value?.catalogItemVariants ?? []
);

// selection module
const selectedProducts = ref([]);

const isSelected = id => selectedProducts.value.includes(id);

const selectProduct = catalogItemId => {
  if (selectedProducts.value.includes(catalogItemId)) {
    selectedProducts.value = selectedProducts.value.filter(
      x => x !== catalogItemId
    );
  } else {
    selectedProducts.value.push(catalogItemId);
  }
};

// error module
const priorityError = usePriorityError(error, parentProductError);
</script>

<template>
  <div class="product-variants">
    <div class="product-variants__filters u-container">
      <ProductVariantFilters
        v-model:search-text="searchText"
        v-model:selected-status-filter="selectedStatusFilter"
        v-model:sort-by="sortBy"
      />
    </div>

    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>

    <div v-if="isSuccess" class="product-variants__content u-container">
      <TransitionGroup
        name="product"
        tag="div"
        class="product-variants__product-list"
      >
        <SoonaNoResults
          v-if="catalogItems.length === 0"
          key="product-variants-empty-search-filter"
        >
          <template #header>oops, we couldn’t find a match!</template>
          <template #body>
            try a different search or filter combination
          </template>
          <template #button>
            <SoonaButton variation="secondary-black" :on-click="resetFilters">
              reset filters
            </SoonaButton>
          </template>
        </SoonaNoResults>
        <ProductCard
          v-for="product in catalogItems"
          :key="product.id"
          :product="product"
          :account-id="accountId"
          :parent-product-name="parentProductName"
          :is-selected="isSelected(product.id)"
          @handle-select="selectProduct(product.id)"
        />
      </TransitionGroup>

      <PaginatorFull
        v-model:page="currentPage"
        v-model:records-per-page="itemsPerPage"
        class="product-variants__paginator-bottom"
        :total-pages="catalogItemsPagination.totalPages"
        :total-count="catalogItemsPagination.totalCount"
        :page-count="catalogItems.length"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-variants {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 1rem;

  &__content {
    position: relative;
  }
}

// transitions

/* base */
.product {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.product-move {
  transition: all 0.4s ease;
}

/* appearing */
.product-enter-active {
  transition: all 0.3s ease-out;
}

/* disappearing */
.product-leave-active {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disppear to */
.product-enter-from,
.product-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="soona-grid">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.soona-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(18rem, 100%), 1fr));
  gap: 1rem;
}
/* matches gap to .u-container gutters */
@media (min-width: variables.$screen-sm-min) {
  .soona-grid {
    gap: 1.5rem;
  }
}

@media (min-width: variables.$screen-lg-min) {
  .soona-grid {
    gap: 2rem;
  }
}
</style>

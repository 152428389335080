<script setup>
import { computed, ref, watch } from 'vue';
import { useVirtualizer } from '@tanstack/vue-virtual';

const props = defineProps({
  height: {
    type: Number,
    required: true,
  },
  initialScrollPosition: {
    type: Number,
    required: false,
  },
  rows: {
    type: Array,
    required: true,
  },
  gap: {
    type: Number,
    required: true,
  },
});

const initialScrollPosition = computed(() => props.initialScrollPosition);
const rows = computed(() => props.rows);

const parentRef = ref(null);

const getItemKey = ix => {
  return computed(() => rows.value?.at(ix)?.at(0)?.id);
};

const rowVirtualizerOptions = computed(() => {
  const sizeEstimate = props.height;
  return {
    count: rows.value.length,
    estimateSize: () => sizeEstimate,
    overscan: 5,
    getItemKey,
    getScrollElement: () => parentRef.value,
    gap: props.gap,
  };
});

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions);
const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());
const totalSize = computed(() => `${rowVirtualizer.value?.getTotalSize()}px`);
const gapSize = computed(() => `${props.gap}px`);

watch([initialScrollPosition], ([, newPosition]) => {
  if (!newPosition) return;

  rowVirtualizer.value?.scrollToIndex(newPosition, {
    align: 'start',
  });
});
</script>
<template>
  <div ref="parentRef" class="scroller">
    <div class="scroller__liner">
      <div
        v-for="virtualRow in virtualRows"
        :key="virtualRow.key"
        class="scroller__row"
        :style="{
          height: `${virtualRow.size}px`,
          transform: `translateY(${virtualRow.start}px)`,
        }"
      >
        <slot :data="rows[virtualRow.index]" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.scroller {
  min-height: 250px;
  max-height: 500px;
  overflow: auto;
  width: 100%;

  &__liner {
    height: v-bind(totalSize);
    width: 100%;
    position: relative;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: v-bind(gapSize);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0.75rem;
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaUploadDragAndDropModal from '@/components/uploader/SoonaUploadDragAndDropModal.vue';
import SoonaUploadProgressModal from '@/components/uploader/SoonaUploadProgressModal.vue';

import { useUploader } from '@/composables/useUploader.js';
import { queryKeys } from '@/queries/query-keys';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import { useMe } from '@/composables/user/useMe';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  orderId: {
    type: [Number, String],
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
  originalDigitalAssetId: {
    type: [Number, String],
  },
  variation: {
    type: String,
    default: 'secondary-black',
  },
  size: {
    type: String,
    default: 'medium',
  },
});

const accountId = computed(() => props.accountId);
const orderId = computed(() => props.orderId);
const reservationId = computed(() => props.reservationId);
const originalDigitalAssetId = computed(() => props.originalDigitalAssetId);

const queryClient = useQueryClient();

const { currentUserId: authedUserId } = useMe();
const { data: editsCollection } = useEditsCollection(accountId, {
  enabled: computed(() => !!accountId.value),
});

const mediaType = blob => {
  if (blob.content_type === 'image/gif') {
    return 'animation';
  } else if (blob.content_type.startsWith('image')) {
    return 'photo';
  }
  return 'video';
};

const {
  mutate: createDigitalAsset,
  isPending,
  error: createDigitalAssetError,
} = useCreateDigitalAsset(accountId);

const createEditFromUpload = async blob => {
  createDigitalAsset(
    {
      account_id: accountId.value,
      file: blob.signed_id,
      asset_type: 'purchased',
      media_type: mediaType(blob),
      origin: 'soona',
      origin_source: 'manual_upload',
      ownership: 'customer',
      title: blob.filename,
      visibility: 'crew',
      original_id: originalDigitalAssetId.value,
      parent_id: originalDigitalAssetId.value,
      reservation_digital_asset_attributes: {
        reservation_id: reservationId.value,
      },
      edits_collection_digital_asset_attributes: {
        added_by_user_id: authedUserId.value,
        collection_id: editsCollection.value?.id,
        edit_status: 'pending',
      },
    },
    {
      onSuccess: async () => {
        const promises = [
          queryClient.invalidateQueries({
            queryKey: queryKeys.collectionAssets(editsCollection.value?.id),
          }),
        ];

        if (orderId.value) {
          promises.push(
            queryClient.invalidateQueries({
              queryKey: queryKeys.orderAddOns(orderId),
            })
          );
        }
        await Promise.all(promises);
      },
    }
  );
};

const priorityError = usePriorityError(createDigitalAssetError);

const dragAndDropModalVisible = ref(false);
const maxConcurrent = ref(5);
const uploadInterval = ref(100);
const fileSizeLimit = ref('');
const label = ref('');
const accept = ref('');
const uploadType = ref('');
const handleUploadFunc = ref(null);
const startUploadFunc = ref(null);
const handleDropFunc = ref(null);
const handleCancelFunc = ref(null);
const activeUploadsLocal = ref([]);

function validateNotCR2(file) {
  if (file.name.toLowerCase().endsWith('.cr2'))
    return 'cannot upload RAW (CR2) files here';
}

function hideDragAndDropModal() {
  dragAndDropModalVisible.value = false;
}

function onUploadComplete(blob) {
  createEditFromUpload(blob);
}

function showDragAndDropModalEdit() {
  label.value = 'edits';
  fileSizeLimit.value = '';
  const {
    handleUpload,
    handleDrop,
    activeUploads,
    cancelUploads,
    startUpload,
  } = useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    validateNotCR2,
    null,
    onUploadComplete
  );
  startUploadFunc.value = startUpload;
  handleUploadFunc.value = handleUpload;
  handleDropFunc.value = handleDrop;
  handleCancelFunc.value = cancelUploads;
  activeUploadsLocal.value = activeUploads.value;
  uploadType.value = 'edit';
  dragAndDropModalVisible.value = true;
}
</script>
<template>
  <SoonaButton
    :variation="variation"
    :size="size"
    :loading="isPending"
    @click="showDragAndDropModalEdit()"
  >
    upload edits
  </SoonaButton>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <SoonaUploadDragAndDropModal
    v-if="dragAndDropModalVisible"
    :accept="accept"
    :active-uploads="activeUploadsLocal"
    :is-multiple="true"
    :label="label"
    @close="hideDragAndDropModal"
    @handle-drop="handleDropFunc"
    @handle-upload="handleUploadFunc"
  />
  <SoonaUploadProgressModal
    v-if="activeUploadsLocal.length > 0"
    :is-multiple="true"
    :active-uploads="activeUploadsLocal"
    :label="label"
    :hide-label="false"
    @cancel-uploads="handleCancelFunc"
    @start-upload="startUploadFunc"
  />
</template>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {Object} [params]
 * @param {string | Ref<string>} [reservationId]
 * @param {string | Ref<string>} [rdaId]
 * @param {Object | Ref<string>} [params]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useReservationDigitalAsset(
  { reservationId, rdaId, filters = {} },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.reservationDigitalAsset(reservationId, rdaId, filters),
    queryFn: async ({ signal }) => {
      let params = {};
      if (filters['collection_type']) {
        params['collection_type'] = filters['collection_type'];
      }

      const response = await http.get(
        `/reservations/${toValue(reservationId)}/reservation_digital_assets/${toValue(rdaId)}`,
        {
          params,
          signal,
        }
      );
      return response.data;
    },
    ...queryOptions,
  });
}

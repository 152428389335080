import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {Object} [params]
 * @param {Number | Ref<Number>} [accountId]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useAccountSchedulingPreference(accountId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.schedulingPreference('Account', accountId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/accounts/${toValue(accountId)}/scheduling_preference`,
        {
          signal,
        }
      );
      return response.data;
    },
    ...queryOptions,
  });
}

<script setup>
import { computed, ref } from 'vue';
import { useRegexHelper } from 'src/composables/useRegexHelper';
import { useSoonaToast } from 'src/components/ui_library/soona_toast/useSoonaToast';
import { GreenApple20 } from 'src/variables.module.scss';
import BenefitList from 'src/components/modal-payment-flows/preferred/BenefitList.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import StepLayout from 'src/components/modal-payment-flows/preferred/StepLayout.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
  },
  showBackButton: {
    default: true,
    type: Boolean,
  },
});

const emits = defineEmits(['back', 'next', 'close']);

const benefitList = [
  {
    icon: 'chart-simple',
    title: 'co-create your content dreams.',
    description: 'teams can build projects & create in one place.',
  },
  {
    icon: 'wand-magic-sparkles',
    title: 'virtual live shoot collaboration.',
    description: 'share and favorite your best shots together.',
  },
];

const { addToast } = useSoonaToast();
const { emailRegex } = useRegexHelper();

const newCollaborators = ref([]);
const newCollaboratorInclude = ref();

const accountId = computed(() => props.accountId);
const showBackButton = computed(() => props.showBackButton);

// TODO: make vue-query to add collaborators to account
// const addAccountCollaborators = useAddAccountCollaborators(accountId);

const newCollaboratorsHasInvalidEmail = computed(() => {
  return !!newCollaborators.value.filter(c => !emailRegex.test(c)).length;
});

const newCollaboratorIncludeIsNotEmail = computed(() => {
  return !emailRegex.test(newCollaboratorInclude.value);
});

const newInviteEmails = computed(() => {
  if (!newCollaboratorIncludeIsNotEmail.value) {
    return newCollaborators.value.concat(newCollaboratorInclude.value);
  }

  return newCollaborators.value;
});

const emailCount = computed(() => newInviteEmails.value?.length);

const isSubmittable = computed(() => {
  if (newCollaboratorsHasInvalidEmail.value) return false;

  if (newCollaboratorInclude.value && newCollaboratorIncludeIsNotEmail.value) {
    return false;
  }

  if (!!newCollaborators.value?.length || newCollaboratorInclude.value) {
    return true;
  }

  return false;
});

const handleAddNewEmail = () => {
  if (newCollaboratorInclude.value) {
    newCollaborators.value = [
      ...(newCollaborators.value || []),
      newCollaboratorInclude.value,
    ];
    newCollaboratorInclude.value = undefined;
  }
};

const handleConfirmClick = () => {
  if (!isSubmittable.value) return;

  const params = {};

  if (newInviteEmails.value?.length) params.newInvites = newInviteEmails.value;

  // TODO: remove this linting placeholder
  addToast(`i'm a placeholder: ${emailCount.value} ${accountId.value}`);

  // TODO: make vue-query to add collaborators to account
  // addAccountCollaborators.mutate(params, {
  //   onSuccess: () => {
  //     addToast(`invitation email${emailCount.value !== 1 ? 's' : ''} sent!`, {
  //       subtext: `have your collaborator${
  //         emailCount.value !== 1 ? 's' : ''
  //       } check their inbox`,
  //       variation: 'success',
  //     });
  //     emits('next');
  //   },
  //   onError: err => {
  //     addToast(
  //       `error sending invitation email${emailCount.value !== 1 ? 's' : ''}`,
  //       {
  //         subtext: err.message,
  //         variation: 'error',
  //       }
  //     );
  //   },
  // });
};

const handleRemoveEmail = index => newCollaborators.value.splice(index, 1);
</script>

<template>
  <StepLayout
    class="invite-step"
    :right-column-bg-color="GreenApple20"
    :show-back-button="showBackButton"
    @back="emits('back')"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template #heading>invite your team</template>
    <template #subheading>add collaborator to account</template>
    <BenefitList class="invite-step__benefits" :benefit-list="benefitList" />
    <h4 class="u-label--heavy invite-step__headline">
      add collaborator to account
    </h4>
    <div
      v-for="(_, index) in newCollaborators"
      :key="index"
      class="invite-step__added-email"
    >
      <SoonaTextfield
        v-model:model-value.trim="newCollaborators[index]"
        required
        class="invite-step__added-email-input"
        placeholder="email address"
        type="email"
        :rules="['required', 'email']"
      />
      <SoonaButton
        variation="icon-plain-gray"
        size="medium"
        type="button"
        class="invite-step__remove-email"
        aria-label="remove this email"
        @click="() => handleRemoveEmail(index)"
      >
        <SoonaIcon name="xmark" />
      </SoonaButton>
    </div>
    <SoonaForm @submit.prevent="handleAddNewEmail">
      <SoonaTextfield
        v-model:model-value.trim="newCollaboratorInclude"
        required
        class="invite-step__new-email"
        type="email"
        placeholder="email address"
      />
      <button
        type="submit"
        class="u-button-reset u-label--regular invite-step__add-email"
      >
        <SoonaIcon name="plus" size="small" />
        add another email
      </button>
    </SoonaForm>
    <SoonaAlert class="invite-step__alert">
      <div class="u-label--regular">
        account collaborators can mark their favorites. create and edit
        reservations. make purchases. you can change their role in your account
        profile.
      </div>
    </SoonaAlert>
    <div class="invite-step__action-btns">
      <SoonaButton :disabled="!isSubmittable" @on-click="handleConfirmClick">
        invite my team
      </SoonaButton>
      <SoonaButton variation="secondary-black" @on-click="emits('next')">
        maybe later
      </SoonaButton>
    </div>
    <template #main-image>
      <img src="@images/bulk-credits/invite_team@2x.jpg" alt="" />
    </template>
    <template #right-col-content>
      <p class="u-title--heavy">
        seamless collaboration with your team. Like, comment, share, & download
        all in one platform.
      </p>
    </template>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.invite-step {
  &__benefits {
    padding-bottom: 2.75rem;
  }

  &__headline {
    display: block;
    margin-bottom: 1.25rem;
  }

  &__added-email {
    position: relative;
    padding-bottom: 0.75rem;
  }

  &__added-email-input {
    padding-bottom: 0 !important;

    &:deep(.soona-textfield__input) {
      padding-right: 2.25rem;
    }
  }

  &__remove-email {
    position: absolute;
    right: 0;
    top: 0.3125rem;
  }

  &__new-email {
    padding-bottom: 0 !important;
  }

  &__add-email {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: variables.$black-default;
    padding-top: 0.75rem;

    svg {
      flex: 0 0 1rem;
    }
  }

  &__action-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: auto;
    padding-top: 2rem;
  }
}
</style>

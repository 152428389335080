import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param { Ref<any> } accountId
 * @returns {UseMutationReturnType<any, unknown, void, unknown>}
 */
export function useCreateDigitalAsset(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.post(
        `/accounts/${toValue(accountId)}/digital_assets`,
        {
          digital_asset: body,
        }
      );
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.digitalAssets(accountId),
      });
    },
  });
}

import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getCapabilities } from '../api/capabilities';
import { unref } from 'vue';

/**
 * @typedef CapabilityParams
 * @type {object}
 * @property {string | Ref<string>} capability
 * @property {string | Ref<string>} [subjectType]
 * @property {string | number | Ref<string | number>} [subjectId]
 */

/**
 *
 * @param {CapabilityParams} capability
 */
export const capabilityQueryConfig = (capability, queryOptions) => {
  return {
    queryKey: queryKeys.capability({
      subjectType: capability.subjectType,
      subjectId: capability.subjectId,
    }),
    queryFn: ({ signal }) =>
      getCapabilities(
        {
          subjectType: unref(capability.subjectType),
          subjectId: unref(capability.subjectId),
        },
        signal
      ),
    gcTime: 60 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    ...queryOptions,
  };
};

/**
 *
 * @param {Object} [params]
 * @param {string | Ref<string>} [params.subjectType]
 * @param {string | number | Ref<string | number>} [params.subjectId]
 */
export function useCapability({ subjectType, subjectId } = {}, queryOptions) {
  return useQuery(
    capabilityQueryConfig({ subjectType, subjectId }, queryOptions)
  );
}

import { http } from '@/config/vue-axios';

/**
 * @param {object} body
 */
export async function createAutoEditTask(body) {
  const response = await http.post(`/auto_edit_tasks`, {
    auto_edit_task: body,
  });
  return response.data;
}

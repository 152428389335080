<script setup>
import { ref, computed, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

defineProps({
  isEditView: Boolean,
});

const store = useStore();

const promoteContent = ref(undefined);
const acceptTextMessages = ref(undefined);
const isEditing = ref(false);

const account = computed(() => store.state.account);
const accountOwner = computed(() => store.state.account.owner);
const currentUser = computed(() => store.state.currentUser);
const accountId = computed(() => account.value?.id);

const { hasCapability: canManageAccount } = useCapability({
  capability: 'manage_account',
  subjectType: 'account',
  subjectId: accountId,
});

const disabled = computed(
  () =>
    promoteContent.value === undefined || acceptTextMessages.value === undefined
);

const isAccountOwner = computed(
  () => accountOwner.value.id === currentUser.value.userID
);

const toggleEdit = () => {
  isEditing.value = !isEditing.value;
};

const isLoading = ref(false);

const savePromotionPermission = async () => {
  const info = {
    use_my_content: {
      data: {
        can_soona_promote_your_edited_content: promoteContent.value,
      },
      complete: true,
    },
  };

  isLoading.value = true;
  try {
    await store.dispatch('user/updateUser', {
      userId: accountOwner.value.id,
      userParams: { accept_text_messages: acceptTextMessages.value },
    });
    await store.dispatch('account/updateAccount', { accountParams: info });
    await store.dispatch('account/loadAccount', account.value.id);
    toggleEdit();
  } catch (error) {
    if (error.response?.status !== 422) {
      Sentry.captureException(
        new Error('Updating account promotion permissions failed'),
        {
          extra: { error },
        }
      );
    }
  }
  isLoading.value = false;
};

watchEffect(() => {
  if (!isLoading.value) {
    promoteContent.value =
      account.value?.profile_data.use_my_content?.data?.can_soona_promote_your_edited_content;
    acceptTextMessages.value = accountOwner.value?.accept_text_messages ?? true;
  }
});
</script>

<template>
  <CompleteProfileCard
    v-if="canManageAccount"
    class="use-my-content"
    :class="{ 'use-my-content--edit-view': isEditView }"
    :disabled="disabled"
    :show-next="!isEditView"
    :show-skip="false"
    @next="savePromotionPermission"
  >
    <fieldset>
      <legend class="u-visually-hidden">content use options</legend>
      <div class="question-container">
        <h3 class="u-title--heavy">
          can soona promote your edited content? 💌
        </h3>

        <label class="u-small--regular">
          <input
            v-model="promoteContent"
            type="radio"
            name="promote"
            :value="true"
            :disabled="isEditView && !isEditing"
          />
          yes! soona can use my edited content for marketing purposes!
        </label>
        <label class="u-small--regular">
          <input
            v-model="promoteContent"
            type="radio"
            name="promote"
            :value="false"
            :disabled="isEditView && !isEditing"
          />
          no. please do not promote my edited content.
        </label>
      </div>
      <div class="question-container">
        <h3 class="u-title--heavy">can soona text you?</h3>
        <label class="u-small--regular">
          <input
            v-model="acceptTextMessages"
            type="radio"
            name="text"
            :value="true"
            :disabled="(isEditView && !isEditing) || !isAccountOwner"
          />
          yes! soona can text me! I understand message and data rates may apply.
        </label>
        <label class="u-small--regular">
          <input
            v-model="acceptTextMessages"
            type="radio"
            name="text"
            :value="false"
            :disabled="(isEditView && !isEditing) || !isAccountOwner"
          />
          no. please don’t text me.
        </label>
      </div>
    </fieldset>
    <template v-if="isEditView" #action-btns>
      <SoonaButton v-if="!isEditing" size="medium" @on-click="toggleEdit">
        edit
      </SoonaButton>
      <SoonaButton
        v-else
        class="save-btn"
        size="medium"
        @on-click="savePromotionPermission"
      >
        save
      </SoonaButton>
    </template>
  </CompleteProfileCard>
</template>

<style lang="scss" scoped>
@use '@/variables';

.use-my-content {
  &--edit-view {
    max-width: none;
    min-height: auto;
  }

  fieldset {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .question-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    input:not(:disabled) {
      cursor: pointer;
    }

    label {
      text-align: left;
    }
  }

  .save-btn {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }
}
</style>

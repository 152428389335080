import { http } from '@/config/vue-axios';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { toValue } from 'vue';

export function useCreateOrderSalesTax({ accountId, orderId }, queryOptions) {
  return useQuery({
    queryKey: queryKeys.orderSalesTax(accountId, orderId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `accounts/${toValue(accountId)}/sales_tax/order/${toValue(orderId)}`,
        {
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

<script setup>
import DownloadDigitalAssetMenuItem from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAssetMenuItem.vue';
import { computed, toRefs } from 'vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { storeToRefs } from 'pinia';

const props = defineProps({
  clickCapture: {
    type: Function,
    required: true,
  },
  keydown: {
    type: Function,
    required: true,
  },
  mouseover: {
    type: Function,
    required: true,
  },
});

const emits = defineEmits(['click']);

const { clickCapture, keydown, mouseover } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId } = storeToRefs(mediaEditorStore);

const { mediaWidth, mediaHeight, mediaUrl } = useMediaEditorDigitalAsset(
  assetAccountId,
  assetId
);

const subtitle = computed(() => {
  if (mediaWidth.value && mediaHeight.value) {
    return `${mediaWidth.value} x ${mediaHeight.value}`;
  }
  return '';
});
</script>

<template>
  <DownloadDigitalAssetMenuItem
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="download"
    :subtitle="subtitle"
    @click="emits('click', { url: mediaUrl, label: 'download' })"
  />
</template>

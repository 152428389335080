<template>
  <div class="BrandInfo">
    <h3 class="u-title--heavy mb-l">brand info</h3>
    <div class="brand-info-wrapper">
      <section class="uploads-section">
        <!-- company logo section -->
        <p class="u-label--heavy">primary logo</p>
        <div class="upload-container">
          <div v-if="!account.logo_url" class="text-centered">
            <SoonaUpload
              class-overide="action-btn"
              :upload-complete="handleLogoUploadComplete"
              :hide-icon="false"
              :is-multiple="false"
              label="UPLOAD FILE"
              accept=".jpeg, .jpg, .png, .gif,"
            />
            <p class="text-s mt-s">transparent .png files work best!</p>
          </div>
          <img
            v-else
            class="company-logo"
            :src="account.logo_url"
            alt="company logo"
          />
        </div>
        <!-- brand guide section -->
        <p class="u-label--heavy">brand guidelines</p>
        <div class="upload-container">
          <SoonaUpload
            v-if="!account.guide_url"
            class-overide="action-btn"
            :upload-complete="handleGuideUploadComplete"
            :hide-icon="false"
            :is-multiple="false"
            label="UPLOAD FILE"
          />
          <a
            v-else
            :href="account.guide_url"
            class="brand-guide-link"
            target="_blank"
            >📄</a
          >
        </div>
      </section>
      <section class="aesthetics-section">
        <fieldset class="field-wrapper">
          <SoonaTextfield
            v-model="primaryFont"
            autocomplete="primaryFont"
            :disabled="!isEditing"
            label="primary font"
            name="primaryFont"
            :placeholder="isEditing ? 'Lato Heavy' : null"
            type="text"
          />
          <SoonaTextfield
            v-model="secondaryFont"
            autocomplete="secondaryFont"
            :disabled="!isEditing"
            label="secondary font"
            name="secondaryFont"
            :placeholder="isEditing ? 'Comic Sans' : null"
            type="text"
          />
          <SoonaTextfield
            v-model="alternativeFonts"
            autocomplete="alternativeFonts"
            :disabled="!isEditing"
            label="alternative font"
            name="alternativeFonts"
            :placeholder="isEditing ? 'Papyrus' : null"
            type="text"
          />
          <SoonaTextfield
            v-model="primaryColor"
            autocomplete="primaryColor"
            :disabled="!isEditing"
            label="primary color"
            name="primaryColor"
            :placeholder="isEditing ? '#f0562e' : null"
            type="text"
          />
          <SoonaTextfield
            v-model="secondaryColor"
            autocomplete="secondaryColor"
            :disabled="!isEditing"
            label="secondary color"
            name="secondaryColor"
            :placeholder="isEditing ? '#' : null"
            type="text"
          />
          <SoonaTextfield
            v-model="otherColors"
            autocomplete="otherColors"
            :disabled="!isEditing"
            label="add the rest separated by commas"
            name="otherColors"
            :placeholder="isEditing ? '#' : null"
            type="text"
          />
        </fieldset>
      </section>
    </div>
    <Capability
      capability-name="manage_account"
      subject-type="account"
      :subject-id="account.id"
    >
      <div class="action-btns">
        <SoonaButton v-if="!isEditing" size="medium" @on-click="toggleEdit">
          edit
        </SoonaButton>
        <SoonaButton v-else class="save-btn" size="medium" @on-click="save">
          save
        </SoonaButton>
      </div>
    </Capability>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';

export default {
  name: 'BrandInfo',
  components: { SoonaButton, SoonaTextfield, SoonaUpload },
  data() {
    return {
      isEditing: false,
      loading: false,
      primaryFont: '',
      secondaryFont: '',
      alternativeFonts: '',
      primaryColor: '',
      secondaryColor: '',
      otherColors: '',
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
  },
  watch: {
    account: function () {
      this.prefillExistingData();
    },
  },
  mounted() {
    this.prefillExistingData();
  },
  methods: {
    ...mapActions('account', [
      'createCompanyLogoFromUpload',
      'createBrandGuideFromUpload',
      'updateAccount',
    ]),
    prefillExistingData() {
      this.primaryFont = this.account.profile_data.brand_fonts.data?.primary;
      this.secondaryFont =
        this.account.profile_data.brand_fonts.data?.secondary;
      this.alternativeFonts =
        this.account.profile_data.brand_fonts.data?.alternative;
      this.primaryColor = this.account.profile_data.brand_colors.data?.primary;
      this.secondaryColor =
        this.account.profile_data.brand_colors.data?.secondary;
      this.otherColors = this.account.profile_data.brand_colors.data?.other;
    },
    ready() {
      this.loading = false;
    },
    handleLogoUploadComplete: function (blob) {
      this.loading = true;
      this.createCompanyLogoFromUpload({
        accountId: this.account.id,
        blob: blob,
      });
    },
    handleGuideUploadComplete: function (blob) {
      this.loading = true;
      this.createBrandGuideFromUpload({
        accountId: this.account.id,
        blob: blob,
      });
    },
    toggleEdit() {
      return (this.isEditing = !this.isEditing);
    },
    async save() {
      let info = {
        brand_colors: {
          data: {
            primary: this.primaryColor,
            secondary: this.secondaryColor,
            other: this.otherColors,
          },
          complete: true,
        },
        brand_fonts: {
          data: {
            primary: this.primaryFont,
            secondary: this.secondaryFont,
            alternative: this.alternativeFonts,
          },
          complete: true,
        },
        company_logo: {
          complete: true,
        },
        brand_guide: {
          complete: true,
        },
      };
      await this.updateAccount({ accountParams: info });
      this.toggleEdit();
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.BrandInfo {
  background-color: variables.$white-default;
  border-radius: 0.75rem;
  box-shadow: variables.$elevation-1;
  container: brand / inline-size;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;

  .brand-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    width: 100%;
  }

  .text-centered {
    text-align: center;
  }

  .uploads-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .upload-container {
    align-items: center;
    background: variables.$gray-10;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 1rem;

    .soona-uploader {
      height: auto;
    }
  }

  .company-logo-wrapper {
    position: relative;
    height: 75px;
    max-width: 150px;
  }

  .company-logo {
    height: 100%;
    object-fit: contain;
  }

  .brand-guide-link {
    font-size: 4rem;
  }

  .field-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 0;
    width: 100%;
  }

  .action-btn {
    background: variables.$periwink-blue-50;
    border-radius: 0.375rem;
    box-shadow: variables.$elevation-1;
    color: variables.$white-default;
    display: flex;
    padding: 1rem;
  }

  .action-btns {
    margin: auto;
    width: max-content;
  }

  .save-btn {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }

  @container brand (min-width: 40rem) {
    .brand-info-wrapper {
      flex-direction: row;
      gap: 3rem;
    }

    .uploads-section {
      width: 40%;
    }

    .aesthetics-section {
      width: 60%;
    }
  }
}
</style>

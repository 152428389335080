<script setup>
import DownloadDigitalAssetMenuItem from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAssetMenuItem.vue';
import { computed, toRefs } from 'vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const props = defineProps({
  clickCapture: {
    type: Function,
    required: true,
  },
  keydown: {
    type: Function,
    required: true,
  },
  mouseover: {
    type: Function,
    required: true,
  },
});

const emits = defineEmits(['click']);

const { clickCapture, keydown, mouseover } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId } = storeToRefs(mediaEditorStore);

const { mediaWidth, mediaHeight, mediaUrl, rawUrl, isGif, webUrl } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const subtitle = computed(() => {
  if (mediaWidth.value && mediaHeight.value) {
    return `${mediaWidth.value} x ${mediaHeight.value}`;
  }
  return '';
});

// maths
const calculateDimensionsWithAspectRatio = maxDimension => {
  const ratio = Math.min(
    maxDimension / mediaWidth.value,
    maxDimension / mediaHeight.value
  );
  if (ratio > 1) {
    return { width: mediaWidth.value, height: mediaHeight.value };
  }
  return {
    width: parseInt(mediaWidth.value * ratio),
    height: parseInt(mediaHeight.value * ratio),
  };
};

const calculatedWidth = maxDimension => {
  if (maxDimension) {
    return calculateDimensionsWithAspectRatio(maxDimension).width;
  }
  return mediaWidth.value;
};

const calculatedHeight = maxDimension => {
  if (maxDimension) {
    return calculateDimensionsWithAspectRatio(maxDimension).height;
  }
  return mediaHeight.value;
};

const webResolutionCopy = computed(() => {
  const width = calculatedWidth(2048);
  const height = calculatedHeight(2048);

  if (width && height) {
    return `${width} x ${height}`;
  }
  return '';
});
</script>

<template>
  <DownloadDigitalAssetMenuItem
    v-if="webUrl"
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="download web resolution"
    :subtitle="webResolutionCopy"
    @click="emits('click', { url: webUrl, label: 'download' })"
  />
  <DownloadDigitalAssetMenuItem
    v-if="mediaUrl && !isGif"
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="download full resolution"
    :subtitle="subtitle"
    @click="emits('click', { url: mediaUrl, label: 'download' })"
  />
  <DownloadDigitalAssetMenuItem
    v-if="rawUrl"
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="download raw"
    @click="emits('click', { url: rawUrl, label: 'download' })"
  />
</template>

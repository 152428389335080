<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';
import { convertToMMDDYYYYmonthAbr } from '@/lib/date-formatters';

const props = defineProps({
  reservation: {
    type: Object,
    required: true,
  },
});

const imageSrc = computed(
  () => props.reservation.preview_url ?? props.reservation.space_image_url
);

// segment events
const route = useRoute();
const { linkClicked } = useBaseEvents();

const trackLinkClicked = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'related bookings',
    linkLabel: props.reservation.name,
    linkHref: '/user/anytime#/reservation',
  });
};
</script>

<template>
  <SoonaItemCard
    :aria-labelledby="`related-booking-${reservation.id}-heading`"
    :image-url="imageSrc"
    class="related-booking-card"
  >
    <template #content>
      <div class="related-booking-card__content">
        <router-link
          :id="`related-booking-${reservation.id}-heading`"
          :to="{
            path: `/reservation/${reservation.id}`,
          }"
          class="related-booking-card__name"
          @click="trackLinkClicked"
        >
          {{ reservation.name }}
        </router-link>
        <p class="related-booking-card__date">
          {{ convertToMMDDYYYYmonthAbr(reservation.start) }}
        </p>
      </div>
    </template>
  </SoonaItemCard>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.related-booking-card {
  position: relative;
  width: 100%;

  &:hover {
    background-color: variables.$gray-10;
  }

  &__content {
    padding: 0.75rem 1rem;
  }

  &__name {
    @include variables_fonts.u-body--heavy;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  }

  &__date {
    @include variables_fonts.u-label--regular;
  }
}
</style>

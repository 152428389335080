import { unref } from 'vue';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { updateCustomerShippingStatus } from 'src/api/reservation';

export function useUpdateCustomerShippingStatus(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => updateCustomerShippingStatus(unref(reservationId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservation(reservationId),
      });
    },
  });
}

<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import { useCapability } from '@/composables/useCapability';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useMe } from '@/composables/user/useMe';
import { useFlag } from '@/composables/useFlag';
import EditHero from 'src/components/user/anytime/profile/edit/EditHero.vue';
import TheBasics from 'src/components/user/anytime/profile/edit/cards/TheBasics.vue';
import ContactInfo from 'src/components/user/anytime/profile/edit/cards/ContactInfo.vue';
import BillingInfo from 'src/components/user/anytime/profile/edit/cards/BillingInfo.vue';
import BrandInfo from 'src/components/user/anytime/profile/edit/cards/BrandInfo.vue';
import SocialInfo from 'src/components/user/anytime/profile/edit/cards/SocialInfo.vue';
import SoftDeleteAccount from 'src/components/user/anytime/profile/edit/cards/SoftDeleteAccount.vue';
import StyleQuizResults from 'src/components/user/anytime/dashboard/StyleQuizResults.vue';
import WhereDoYouSell from 'src/components/user/anytime/profile/WhereDoYouSell.vue';
import WhereDoYouAdvertise from 'src/components/user/anytime/profile/WhereDoYouAdvertise.vue';
import AlternateContentUse from 'src/components/user/anytime/profile/AlternateContentUse.vue';
import UseMyContent from 'src/components/user/anytime/profile/UseMyContent.vue';
import ReferralLink from 'src/components/user/anytime/referrals/ReferralLink.vue';
import AccountOwner from 'src/components/user/anytime/profile/edit/cards/AccountOwner.vue';
import EmployeeType from 'src/components/user/anytime/dashboard/EmployeeType.vue';
import BillingDetails from 'src/components/user/anytime/billing_and_orders/BillingDetails.vue';
import AvailableAccountCredits from 'src/components/user/anytime/credits/AvailableAccountCredits.vue';
import ContributorsCard from 'src/components/user/anytime/profile/edit/ContributorsCard.vue';
import SoonaBackButton from 'src/components/ui_library/SoonaBackButton.vue';
import SubscriptionCard from '@/components/user/anytime/profile/edit/cards/SubscriptionCard.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const route = useRoute();
useTitle(computed(() => `${route.meta.page_title} | soona`));

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});
const { hasCapability: canUpdateEmployeeType } = useCapability({
  capability: 'update_employee_type',
});

const store = useStore();
const { currentAccountId } = useMe();
const accountId = computed(() => Number(props.accountId));
const isMyAccount = computed(() => currentAccountId.value === accountId.value);
const account = computed(() => store.state.account);
const currentPage = computed(() => 'profilePage');
const authorized = computed(() => isMyAccount.value || isSoonaStaff.value);

const { pageViewed } = useBaseEvents();

const paymentsCompanyAddressFlag = useFlag('payments_company_address');

const accountLoaded = ref(false);
watchEffect(() => {
  pageViewed();
  store.dispatch('account/loadAccount', accountId.value).then(() => {
    accountLoaded.value = true;
  });
});
</script>

<template>
  <div v-if="accountLoaded && authorized" class="EditProfile">
    <SoonaBackButton
      v-if="isSoonaStaff"
      element="router-link"
      :to="`/account/${accountId}`"
      button-text="account"
    />
    <SoonaBackButton v-else element="router-link" to="/" button-text="home" />
    <div class="edit-profile-flexbox">
      <section class="edit-profile-item1">
        <EditHero />
      </section>
      <section class="edit-profile-item2">
        <div class="item2-column1">
          <TheBasics />
          <AccountOwner />
          <BillingDetails />
          <BillingInfo
            v-if="paymentsCompanyAddressFlag"
            :account-id="accountId"
          />
          <ContactInfo />
          <EmployeeType v-if="canUpdateEmployeeType" />
          <SoftDeleteAccount :account-id="accountId" />
        </div>
        <div class="item2-column2">
          <AvailableAccountCredits
            :account-id="accountId"
            :current-page="currentPage"
          />
          <SubscriptionCard :account-id="accountId" />
          <Capability
            capability-name="manage_collaborators"
            subject-type="account"
            :subject-id="accountId"
          >
            <ContributorsCard :account-id="accountId" />
          </Capability>
          <StyleQuizResults
            :account-style="account.current_quiz_style"
            is-edit-view
            title="your style"
          />
          <BrandInfo />
          <SocialInfo />
          <ReferralLink :current-account="account" />
          <UseMyContent is-edit-view />
          <WhereDoYouAdvertise is-edit-view />
          <WhereDoYouSell is-edit-view />
          <AlternateContentUse is-edit-view />
        </div>
      </section>
    </div>
  </div>
  <section
    v-else-if="accountLoaded && !authorized"
    class="container is-flex justify-center items-center"
  >
    <div class="notification w-75">
      <h1 class="title">sorry, something went wrong…</h1>
      <div>
        this profile is private. if someone gave you this link, you'll need to
        sign into an account which has access to this profile.
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.EditProfile {
  .edit-profile-flexbox {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .edit-profile-item2 {
    display: flex;
    position: relative;
    gap: 1.5rem;

    .item2-column1 {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
    .item2-column2 {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
  .account-credits-title {
    text-align: left;
    font-size: 1.25rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }
  .account-credits-content {
    display: flex;
  }
  .account-photo-credits {
    flex: 1;
  }
}
@media only screen and (max-width: 48rem) {
  .EditProfile {
    .edit-profile-item2 {
      flex-direction: column;
      .item2-column1 {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .item2-column2 {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>

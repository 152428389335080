<script setup>
const props = defineProps({
  promoCode: {
    type: String,
    default: '',
  },
});
const emits = defineEmits(['update:model-value']);
</script>

<template>
  <section class="copy-code-section">
    <div class="copy-code-section__form-container">
      <label class="u-body--heavy" for="banner-copy">discount code*</label>
      <input
        id="discount-code"
        name="discount-code"
        class="copy-code-section__textinput"
        placeholder="enter discount code to be applied"
        :value="props.promoCode"
        @change="emits('update:model-value', 'promo_code', $event.target.value)"
      />
    </div>
  </section>
</template>
<style scoped lang="scss">
@use '@/variables';

.copy-code-section {
  margin-bottom: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__divider {
    width: 100%;
    height: 1px;
    background: variables.$gray-30;
    margin: 8px 0;
  }
  &__form-container {
    display: flex;
    flex-direction: column;
  }
  label {
    margin-bottom: 3px;
  }
  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  &__textinput {
    height: 40px;
    border: 1px solid variables.$gray-30;
    border-radius: 5px;
    padding: 16px;
    margin-top: 3px;
    width: 304px;
  }
}
</style>

import { ref, watch, computed, watchEffect } from 'vue';
import { useFlag } from '@/composables/useFlag';
import { useAccountCreditInfo } from '@/queries/account/useAccountCreditInfo';
import { useBagCount } from '@/queries/bag/useBag';

export function useCheckoutPromoCredits(accountId) {
  const checkoutPromoFlag = useFlag('pegasus_checkout_promo');
  const alwaysShowCheckoutPromoFlag = useFlag(
    'pegasus_always_show_checkout_promo'
  );

  const checkoutPromoCreditThreshold = Number(
    import.meta.env.VITE_CHECKOUT_PROMO_CREDIT_THRESHOLD ?? 0
  );

  const { data: bagCount, isSuccess: bagIsSuccess } = useBagCount(accountId);

  const { data: creditInfo, isSuccess: creditInfoSuccess } =
    useAccountCreditInfo(accountId);

  const preferredCredits = computed(
    () => creditInfo.value?.available_preferred_photo_credits?.length || 0
  );

  const checkoutPromoCredits = computed(() =>
    parseInt(creditInfo.value?.available_checkout_promo_credits?.length || 0)
  );

  const reservedCheckoutPromoCredits = computed(
    () => creditInfo.value?.reserved_checkout_promo_credits?.length || 0
  );

  const unusedCheckoutPromoCredits = computed(
    () => checkoutPromoCredits.value - reservedCheckoutPromoCredits.value
  );

  const normalizedBagCount = computed(
    () => bagCount.value - reservedCheckoutPromoCredits.value
  );

  const assetsNeeded = computed(
    () =>
      checkoutPromoCreditThreshold -
      (normalizedBagCount.value % checkoutPromoCreditThreshold)
  );

  const meterValue = computed(() => {
    if (
      normalizedBagCount.value >= checkoutPromoCreditThreshold &&
      normalizedBagCount.value % checkoutPromoCreditThreshold === 0 &&
      // at a threshold but theres an unused promo credit so meter should be full not empty
      unusedCheckoutPromoCredits.value > 0
    ) {
      return 1;
    }
    return (
      (checkoutPromoCreditThreshold - assetsNeeded.value) /
      checkoutPromoCreditThreshold
    );
  });

  const assetRecentlyAdded = ref(false);

  watch(
    bagCount,
    (newVal, oldVal) => (assetRecentlyAdded.value = newVal > oldVal)
  );

  watchEffect(() => {
    if (assetRecentlyAdded.value) {
      setTimeout(() => {
        assetRecentlyAdded.value = false;
      }, 1000);
    }
  });

  const bagQualifiesForPromoCredits = computed(
    () => bagCount.value >= checkoutPromoCreditThreshold / 2
  );
  const enabled = computed(
    () =>
      checkoutPromoFlag.value &&
      checkoutPromoCreditThreshold > 0 &&
      creditInfoSuccess.value &&
      bagIsSuccess.value &&
      preferredCredits.value === 0 &&
      (bagQualifiesForPromoCredits.value ||
        (alwaysShowCheckoutPromoFlag.value && bagCount.value > 0))
  );

  const earnedCreditsText = computed(() => {
    const photo = checkoutPromoCredits.value === 1 ? 'photo' : 'photos';
    return `you've earned ${checkoutPromoCredits.value} free ${photo}! keep going to earn more`;
  });

  const preText = computed(() => {
    if (meterValue.value === 1) {
      return 'you made it';
    } else if (meterValue.value > 0.5) {
      return 'almost there';
    } else {
      return 'keep it going';
    }
  });

  const subText = computed(() => {
    let assetText = assetsNeeded.value === 1 ? 'asset' : 'assets';
    if (meterValue.value === 1) {
      return 'add another photo to your bag for free';
    } else if (checkoutPromoCredits.value > 0) {
      return `add ${assetsNeeded.value} more ${assetText} to your bag to get another free photo credit`;
    } else {
      return `add ${assetsNeeded.value} more ${assetText} to your bag to get a free photo credit!`;
    }
  });

  const addAssetsBtnVariation = computed(() => {
    return meterValue.value === 1 ? 'solid-black' : 'secondary-black';
  });

  const addAssetsBtnCopy = computed(() => {
    return meterValue.value === 1 ? 'redeem free photo' : `keep shopping`;
  });

  return {
    checkoutPromoCredits,
    earnedCreditsText,
    assetRecentlyAdded,
    enabled,
    meterValue,
    preText,
    subText,
    addAssetsBtnVariation,
    addAssetsBtnCopy,
    preferredCredits,
    creditInfoSuccess,
  };
}

<script setup>
import { computed, ref, watch, toRefs } from 'vue';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import ListingCard from '@/components/user/anytime/listing_insights/listing_card/ListingCard.vue';
import { useListings } from '@/queries/useListings';
import { keepPreviousData } from '@tanstack/vue-query'; // needed?
import { usePriorityError } from '@/composables/usePriorityError';
import ListingsListLoadingState from '@/components/user/anytime/listing_insights/ListingsListLoadingState.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import { useListingsListMinHeight } from '@/components/user/anytime/listing_insights/useListingsListMinHeight.js';

const props = defineProps({
  accountId: { type: String, required: true },
  catalogItemId: { type: String, default: null },
  filterOptions: { type: Array, required: true },
});

const selectedFilter = defineModel('selectedFilter', {
  type: String,
  required: true,
});

const { accountId, catalogItemId } = toRefs(props);

const listingActionSlug = computed(() =>
  selectedFilter.value === 'missing-content' ? 'missing-content' : null
);
const listingRuleSlug = computed(() =>
  selectedFilter.value !== 'missing-content' ? selectedFilter.value : null
);

const currentPage = ref(1);
const itemsPerPage = ref(10);

watch(selectedFilter, () => {
  currentPage.value = 1;
});

const {
  data: listingsData,
  error: listingsError,
  isLoading,
  //   isSuccess,
} = useListings(
  accountId,
  {
    itemsPerPage,
    currentPage,
    listingActionSlug,
    listingRuleSlug,
    catalogItemId,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const listings = computed(() => listingsData.value?.listings ?? []);

const listingsPagination = computed(() =>
  listingsData.value
    ? listingsData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

const { minHeight } = useListingsListMinHeight(listings);

const priorityError = usePriorityError(listingsError);
</script>

<template>
  <div id="missing-content-list" class="listings-list">
    <div class="listings-list__heading">
      <h2 class="listings-list__title u-title--heavy">
        listings with missing content
      </h2>
    </div>
    <SoonaFilterSingle v-model="selectedFilter" :options="filterOptions" />
    <SoonaError v-if="priorityError" no-margin>
      {{ priorityError }}
    </SoonaError>

    <div class="listings-list__listings">
      <div v-if="isLoading" class="listings-list__listings--loading">
        <ListingsListLoadingState />
      </div>
      <TransitionGroup name="listing" tag="div" class="listings-list__list">
        <ListingCard
          v-for="listing in listings"
          :key="listing.listing_id"
          :account-id="accountId"
          :listing="listing"
        />
      </TransitionGroup>
      <PaginatorFull
        v-model:page="currentPage"
        v-model:records-per-page="itemsPerPage"
        class="listings-list__paginator-top"
        :total-pages="listingsPagination.totalPages"
        :total-count="listingsPagination.totalCount"
        :page-count="listings.length"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listings-list {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: v-bind(minHeight);

  &__listings {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
  }
}

// transitions

/* base */
.listing {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.listing-move {
  transition: all 0.4s ease;
}

/* appearing */
.listing-enter-active {
  transition: all 0.3s ease-out;
}

/* disappearing */
.listing-leave-active {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disppear to */
.listing-enter-from,
.listing-leave-to {
  opacity: 0;
}
</style>

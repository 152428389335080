<script setup>
import { computed, provide, ref } from 'vue';
import MediaEditor from '@/components/user/anytime/gallery/media-editor/MediaEditor.vue';
import { useDigitalAsset } from '@/queries/digital_assets/useDigitalAsset';

import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
  MEDIA_POPOVER_BACK_TEXT,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useRouter } from 'vue-router';
import { usePreloadDigitalAsset } from '@/queries/digital_assets/usePreloadDigitalAsset';
import { useMediaQuery } from '@vueuse/core';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useMe } from '@/composables/user/useMe';
import UploadsAssetCarousel from './UploadsAssetCarousel.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [Number, String],
    required: true,
  },
});
const router = useRouter();
const { currentAccountId } = useMe();
const accountId = computed(() => props.accountId);
const digitalAssetId = computed(() => props.digitalAssetId);
const isMyAccount = computed(
  () => currentAccountId.value === Number(props.accountId)
);

const params = {
  origin: 'customer',
  ownership: 'customer',
  visibility: 'all',
};

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const {
  data: file,
  isLoading: isFileLoading,
  error: fileLoadingError,
} = useDigitalAsset({ accountId, assetId: digitalAssetId, params }, {});

// routes and preloading
const nextId = computed(() => file.value?.next_id);
const prevId = computed(() => file.value?.prev_id);
const accountName = computed(() => file.value?.account_name);

usePreloadDigitalAsset(accountId, nextId, params);
usePreloadDigitalAsset(accountId, prevId, params);

const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'uploads-assets-media-view',
    params: {
      accountId: accountId.value,
      digitalAssetId: id,
    },
  };
};

const prevRoute = computed(() => createRouteLocation(prevId.value));
const nextRoute = computed(() => createRouteLocation(nextId.value));

const backUrl = computed(() => {
  return `/account/${accountId.value}/gallery/uploads`;
});

const backText = computed(() => {
  if (isMyAccount.value) return 'my uploads';
  if (accountName.value) return `${accountName.value}’s uploads`;
  return 'uploads';
});

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, prevRoute);
provide(MEDIA_POPOVER_NEXT_KEY, nextRoute);
provide(MEDIA_POPOVER_BACK_TEXT, backText);

const isRedirecting = ref(false);
const handleClose = event => {
  if (event?.delivered_asset_id) {
    router.push(createRouteLocation(event.delivered_asset_id));
  } else {
    isRedirecting.value = true;
    router.push(backUrl.value);
  }
};

const priorityError = usePriorityError(fileLoadingError);
</script>

<template>
  <MediaEditor
    v-if="!isRedirecting"
    :asset="file"
    :is-asset-loading="isFileLoading"
    @close="handleClose($event)"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #carousel>
      <UploadsAssetCarousel
        v-if="matchMediaIsWide"
        :account-id="accountId"
        :active-asset-id="file?.id"
        :back-text="backText"
      />
    </template>
  </MediaEditor>
</template>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { mapPaginationHeaders } from 'src/lib/api-transforms';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<Number>} orderId
 * @param {number} [params.currentPage]
 */
export function useGetOrderAddOns(orderId, { currentPage } = {}) {
  return useQuery({
    queryKey: queryKeys.orderAddOns(orderId, { currentPage }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (unref(currentPage)) params.page = unref(currentPage);

      const response = await http.get(
        `/orders/${unref(orderId)}/add_ons.json`,
        {
          params,
          signal,
        }
      );

      return {
        pagination: mapPaginationHeaders(response.headers),
        data: response.data,
      };
    },
  });
}

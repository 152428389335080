<script setup>
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed } from 'vue';
import { requirementTypes } from './requirement-types';

const props = defineProps({
  requirementType: {
    type: String,
    required: true,
  },
});

const reqTypeData = computed(
  () =>
    requirementTypes[props.requirementType] ?? {
      value: props.requirementType,
      label: props.requirementType,
    }
);

const iconStyle = computed(() => ({
  backgroundColor: reqTypeData.value.iconBackground,
  borderColor: reqTypeData.value.iconBorder,
  color: reqTypeData.value.iconColor,
}));
</script>

<template>
  <span class="req-type-display">
    <span class="req-type-display__icon" :style="iconStyle">
      <SoonaIcon :name="reqTypeData.icon" />
    </span>
    <span class="u-body--heavy">{{ reqTypeData.label }}</span>
    <span v-if="$slots.meta" class="req-type-display__meta u-label--regular">
      <slot name="meta" />
    </span>
  </span>
</template>

<style lang="scss" scoped>
@use '@/variables';
.req-type-display {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem 1rem;
  align-items: center;

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0.0625rem solid variables.$gray-30;
    color: variables.$gray-60;
    background-color: variables.$gray-10;
    border-radius: 4rem;
    padding: 0.4375rem;
  }

  &__meta {
    color: variables.$gray-70;
  }
}
</style>

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {object | Ref<object>} accountId
 */
export function useUpdateDigitalAsset(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ assetId, body }) => {
      const response = await http.put(
        `/accounts/${toValue(accountId)}/digital_assets/${toValue(assetId)}`,
        {
          digital_asset: body,
        }
      );

      return response.data;
    },
    onSuccess: async () => {
      const promises = [
        queryClient.invalidateQueries({
          queryKey: queryKeys.digitalAssetsObliterateAllKeys(),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.order(),
        }),
      ];
      await Promise.all(promises);
    },
  });
}

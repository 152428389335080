import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { removeCollaborator } from '@/api/collaborators';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

/**
 *
 * @param {Ref<string | number>} accountId
 * @param {Ref<string | number>} collaboratorId
 */
export function useDeleteCollaborator(accountId, collaboratorId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      removeCollaborator(unref(accountId), unref(collaboratorId)),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collaborators(accountId),
        }),
      ]);
    },
  });
}

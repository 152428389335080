<script setup>
import ProServiceTypeButtons from '@/components/directory/ProServiceTypeButtons.vue';
import LocationDropdown from '@/components/directory/LocationDropdown.vue';
const selectedProServiceType = defineModel('selectedProServiceType', {
  type: String,
  default: '',
  required: true,
});

const locationIds = defineModel('locationIds', {
  type: Array,
  default: [],
  required: true,
});
</script>
<template>
  <div class="pro-service-type-header">
    <section class="pro-service-type-header__copy">
      <h3 class="u-headline--heavy">what content do you need today?</h3>
      <div class="pro-service-type-header__locations">
        <p>browse our services in</p>
        <LocationDropdown v-model:location-ids="locationIds" />
        <p>and create a shoot with any model you see.</p>
      </div>
    </section>
    <ProServiceTypeButtons
      :model-value="selectedProServiceType"
      @update:model-value="$event => (selectedProServiceType = $event)"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.pro-service-type-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  background-color: variables.$periwink-blue-10;
  padding: 2rem;

  &__copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  &__locations {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
}
</style>

import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';

import { AI_PRODUCER_STORE_ID } from '@/stores/keys';
import { useAssistantStore } from '@/components/ai_assistant/composables/useAssistantStore';

export const useAiProducerStore = defineStore(AI_PRODUCER_STORE_ID, () => {
  const store = useAssistantStore();
  const storeRefs = storeToRefs(store);

  const bookingState = ref({});
  const isSummaryOpen = ref(false);

  function $reset() {
    store.$reset();
    bookingState.value = {};
    isSummaryOpen.value = false;
  }

  return {
    ...storeRefs,
    $reset,

    isSummaryOpen,
    setIsSummaryOpen: open => (isSummaryOpen.value = open),

    bookingState,
    setBookingState: state => (bookingState.value = state),
    updateBookingState: state =>
      (bookingState.value = { ...bookingState.value, ...state }),
  };
});

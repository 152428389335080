<template>
  <SoonaDialog @close="closeModal">
    <template #heading>booking type</template>
    <p class="subheader-text">would you like to change the reservation type?</p>
    <SoonaSelect
      v-model:model-value="bookingType"
      :options="selectableReservationTypes"
      :selectable="option => option.value !== bookingType"
    />
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        :disabled="disableContinue"
        data-cypress="button-dialog-confirm"
        @on-click="handleConfirm"
      >
        continue
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';

export default {
  name: 'BookingTypeModal',
  components: {
    SoonaButton,
    SoonaDialog,
    SoonaSelect,
  },
  props: {
    closeModal: Function,
  },
  data() {
    return {
      bookingType: '',
    };
  },
  computed: {
    ...mapGetters(['reservationTypes']),
    ...mapGetters('reservation', ['currentReservation']),
    disableContinue() {
      return this.bookingType === '';
    },
    selectableReservationTypes() {
      let result = [];
      this.reservationTypes
        .filter(reservationType => reservationType.name !== 'headshots_for_all')
        .forEach(x =>
          result.push({
            value: x.name,
            label: x.name.replace(/_/g, ' '),
          })
        );
      return result;
    },
  },
  beforeMount() {
    this.bookingType = this.currentReservation.reservation_type;
  },
  methods: {
    ...mapActions('reservation', ['changeBookingType']),
    async handleConfirm() {
      await this.changeBookingType({ bookingType: this.bookingType });
      this.closeModal();
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.subheader-text {
  margin-bottom: 8px;
  font-weight: 800;
  font-size: 14px;
}

.label-text {
  position: relative;
  bottom: 30px;
  left: 16px;
}
</style>

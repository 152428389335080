import { computed, unref, watchEffect } from 'vue';
import { useInfiniteDigitalAssets } from '@/queries/digital_assets/useInfiniteDigitalAssets';

function useUseInfiniteDigitalAssets(
  queryId,
  {
    startPage,
    rowWidth,
    itemsPerPage,
    filters,
    gutter,
    height,
    query = useInfiniteDigitalAssets,
    searchQuery,
    sortBy,
    sortDirection,
    similarityText,
    similarityThreshold,
  },
  queryOptions = undefined
) {
  watchEffect(() => {
    if (
      import.meta.env.DEV &&
      [unref(height), unref(rowWidth), unref(itemsPerPage), unref(gutter)].some(
        v => typeof v !== 'number'
      )
    ) {
      console.error(
        '[useUseInfiniteDigitalAssets] height, rowWidth, gutter, and itemsPerPage are required'
      );
    }
  });

  const { data, fetchNextPage, isFetching, isLoading, error } = query(
    queryId,
    {
      startPage,
      itemsPerPage,
      filters,
      searchQuery,
      sortBy,
      sortDirection,
      similarityText,
      similarityThreshold,
    },
    queryOptions
  );

  const pagination = computed(() => {
    const initialPagination = {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };

    const rw = unref(rowWidth);
    const placeholderWidth = unref(height) + unref(gutter);
    const paginationData = data.value?.pages?.at(-1)?.pagination;

    // generate placeholder items for loading state
    if (isLoading.value && !paginationData) {
      return {
        ...initialPagination,
        // use two rows of placeholder items for loading state
        placeholderCount: rw ? Math.floor(rw / placeholderWidth) * 2 : 6,
      };
    }

    return paginationData ?? initialPagination;
  });

  const placeHolderListCount = computed(
    () => pagination.value.placeholderCount ?? pagination.value.totalCount
  );
  const placeHolderList = computed(() => {
    return Array.from(Array(placeHolderListCount.value), (x, i) => ({
      title: 'loading',
      id: `vue_scroller_placeholder_${i}`,
      placeholder: true,
      ix: i,
      page: Math.floor(i / unref(itemsPerPage)) + 1,
    }));
  });

  const getAssetWidth = asset => {
    const da = asset.digital_asset ?? asset;
    return (
      unref(height) * Math.min(da?.preview?.ratio ?? 1, 2.5) + unref(gutter)
    );
  };

  const assetRows = computed(() => {
    const list = unref(placeHolderList);

    if (unref(data)?.pages) {
      const pages = unref(data).pages;

      for (let pageIx = 0; pageIx < pages.length; pageIx++) {
        const page = pages[pageIx];
        const offset = (page.pagination.currentPage - 1) * unref(itemsPerPage);
        for (let assetIx = 0; assetIx < page.assets.length; assetIx++) {
          list[assetIx + offset] = page.assets[assetIx];
        }
      }
    }

    const width = unref(rowWidth);

    const rows = [];

    for (let listIx = 0; listIx < list.length; listIx++) {
      const asset = list[listIx];
      const assetWidth = getAssetWidth(asset);

      const row = rows.at(-1);
      if (row && row.width > 0 && assetWidth + row.width <= width) {
        rows.at(-1).assets.push(asset);
        row.width += assetWidth;
      } else {
        rows.push({
          id: asset.id,
          assets: [asset],
          // trying to prevent overflowing
          width: Math.min(assetWidth, width),
        });
      }
    }

    return rows;
  });

  function fetchPage(pageNumber) {
    if (
      unref(isFetching) ||
      unref(data)?.pages.find(
        page => page.pagination.currentPage === pageNumber
      )
    ) {
      return;
    }

    fetchNextPage({ pageParam: pageNumber });
  }

  return {
    assetRows,
    pagination,
    fetchPage,
    isFetching,
    isLoading,
    error,
    rawData: data,
  };
}

export { useUseInfiniteDigitalAssets as useInfiniteDigitalAssets };

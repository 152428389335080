import { createSubscription } from '@/api/subscriptions';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';

export function useCreateSubscription() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => createSubscription(body),
    onSuccess: async data => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(data.account_id),
        }),
        queryClient.invalidateQueries({ queryKey: queryKeys.capability() }),
      ]);
    },
  });
}

<script setup>
import { computed } from 'vue';
import ProfileQuiz from 'src/components/user/anytime/profile/ProfileQuiz.vue';
import { useStore } from 'vuex';

const store = useStore();

const account = computed(() => store.state.account);
const currentUser = computed(() => store.state.currentUser);
const isAccountOwner = computed(
  () => currentUser.value?.userID === account.value.owner?.id
);

const styleQuizCompleted = computed(
  () => store.getters['account/styleQuizCompleted']
);
</script>

<template>
  <ProfileQuiz
    v-if="isAccountOwner && !styleQuizCompleted"
    :account="account"
  />
</template>

<style lang="scss" scoped>
.EditHero {
  background: #d9e7ff;
  display: flex;
  justify-content: space-between;

  .edit-hero-copy {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .EditHero {
    display: flex;
    flex-direction: column;
    .edit-hero-copy {
      width: 100%;
    }
    .edit-hero-cards {
      width: 100%;
    }
  }
}
</style>

<script setup>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { triggerShippingCheckoutStartedEvent } from '@/api/shipping_detail';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute, useRouter } from 'vue-router';
import { useTitle } from '@vueuse/core';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SummaryPayment from '@/components/SummaryPayment.vue';
import { useFlag } from '@/composables/useFlag';

const { buttonClicked, inputChanged, linkClicked, pageViewed } =
  useBaseEvents();
const store = useStore();

const paymentsNewShippingCheckout = useFlag('payments_shipping_checkout');

const isSubmitting = ref(false);
const checkedAgreement = ref(false);
const reservationId = computed(
  () => store.state.reservation?.currentReservation?.id
);
const currentRate = computed(() => store.state.reservation?.currentRate);
const currentShipment = computed(
  () => store.state.reservation?.currentShipment || {}
);
const route = useRoute();
const router = useRouter();
const title = useTitle();

onMounted(() => {
  title.value = `${route.meta.page_title} | soona`;

  pageViewed();
  triggerShippingCheckoutStartedEvent(
    reservationId.value,
    currentRate.value,
    true
  );
});

const overrideDurationTerms = terms => {
  if (
    terms ===
    'Delivery within 1, 2, or 3 days based on where your package started and where it’s being sent.'
  ) {
    return 'delivery guaranteed within 3 business days based on your location';
  } else {
    return terms;
  }
};

const onCancel = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'shipping checkout',
    linkLabel: 'cancel',
    linkHref: '/user/anytime#/reservation/info',
  });

  router.push({ name: 'info' });
};

const toggleDisable = e => {
  inputChanged({
    context: route.meta.context,
    subContext: 'shipping checkout',
    inputLabel: 'I agree to follow all guidelines and restrictions',
    inputType: 'checkbox',
    inputValue: e.target.checked,
  });
  checkedAgreement.value = !!e.target.checked;
};

const confirmFreeShipmentClicked = () => {
  isSubmitting.value = true;
  buttonClicked({
    context: route.meta.context,
    subContext: 'shipping checkout',
    buttonLabel: 'confirm',
    buttonAction: 'submit payment',
  });

  return store.dispatch('reservation/confirmFreeShipment', {
    shipping_amount: currentRate.value.amount,
    shipping_details: {
      rate_id: currentRate.value.rate_id,
      shipping_provider: currentRate.value.provider,
      customer_address: currentShipment.value.address_from,
      parcels: currentShipment.value.parcels,
      shipping_direction: 'inbound',
    },
  });
};

const shipToAddress = computed(() => {
  if (!currentShipment.value?.address_to) return '';

  const street = [
    currentShipment.value.address_to.street1,
    currentShipment.value.address_to.street2,
  ].join(' ');

  return `${street}, ${currentShipment.value.address_to.city}, ${currentShipment.value.address_to.state} ${currentShipment.value.address_to.zip}`;
});

const shipFromAddress = computed(() => {
  if (!currentShipment.value?.address_from) return '';

  const street = [
    currentShipment.value.address_from.street1,
    currentShipment.value.address_from.street2,
  ].join(' ');

  return `${street}, ${currentShipment.value.address_from?.city}, ${currentShipment.value.address_from?.state} ${currentShipment.value.address_from?.zip}`;
});
</script>
<template>
  <div class="shipping">
    <div class="column is-full soona-container">
      <div class="columns is-centered">
        <div class="column is-half">
          <h2 class="title has-text-centered is-size-4-mobile">
            we're almost ready!
          </h2>

          <!-- order summary -->

          <p class="soona-title-a1 mb-m"><b>ORDER CONFIRMATION:</b></p>
          <SummaryPayment v-if="paymentsNewShippingCheckout" :total="0">
            <tbody class="summary-payment__itemized-body">
              <!-- ship to -->
              <template v-if="shipToAddress">
                <tr class="shipping-info-row">
                  <th colspan="2">
                    <p class="u-body--heavy">Ship to:</p>
                  </th>
                  <th class="faux-cell"></th>
                </tr>
                <tr class="shipping-info-row">
                  <td colspan="2">
                    <p>{{ currentShipment.address_to.name }}</p>
                    <p>
                      {{ shipToAddress }}
                    </p>
                  </td>
                  <td class="faux-cell"></td>
                </tr>
              </template>
              <!-- ship from -->
              <template v-if="shipFromAddress">
                <tr class="shipping-info-row">
                  <th colspan="2">
                    <p class="u-body--heavy">Ship from:</p>
                  </th>
                  <th class="faux-cell"></th>
                </tr>
                <tr class="shipping-info-row">
                  <td colspan="2">
                    <p>{{ currentShipment.address_from.name }}</p>
                    <p>
                      {{ shipFromAddress }}
                    </p>
                  </td>
                  <td class="faux-cell"></td>
                </tr>
              </template>
              <!-- package details -->
              <tr class="shipping-info-row">
                <th colspan="2">
                  <p class="u-body--heavy">Package details:</p>
                </th>
                <th class="faux-cell"></th>
              </tr>
              <tr class="shipping-info-row">
                <td colspan="2">
                  <p>
                    1 package x {{ currentShipment.parcels.length }}" x
                    {{ currentShipment.parcels.width }}" x
                    {{ currentShipment.parcels.height }}" ({{
                      currentShipment.parcels.weight
                    }}
                    lbs)
                  </p>
                </td>
                <td class="faux-cell"></td>
              </tr>
              <!-- carrier -->
              <tr class="shipping-info-row">
                <th colspan="2">
                  <p class="u-body--heavy">Carrier:</p>
                </th>
                <th class="faux-cell"></th>
              </tr>
              <tr class="shipping-info-row">
                <td colspan="2">
                  <p>
                    <b class="soona-title-a1">
                      {{ currentRate.provider }} {{ currentRate.service_level }}
                    </b>
                  </p>
                  <p class="is-lowercase">
                    {{ overrideDurationTerms(currentRate.duration_terms) }}
                  </p>
                </td>
                <td class="faux-cell"></td>
              </tr>
              <!-- subtotal -->
              <tr class="summary-payment__itemized-subtotal">
                <th>subtotal</th>
                <td>${{ currentRate.amount }}</td>
              </tr>
              <tr class="summary-payment__itemized-discounts">
                <th>discount</th>
                <td>-${{ currentRate.amount }}</td>
              </tr>
            </tbody>
            <template #disclaimer-above-payment>
              <div class="free-shipping-policy">
                <p class="u-small--regular mb-m">
                  <b>important!</b> to take advantage of soona's free shipping
                  offer you must use a
                  <b><i>large flat rate box from USPS</i></b
                  >. the branded boxes are available for free
                  <a
                    class="soona-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://store.usps.com/store/product/shipping-supplies/priority-mail-large-flat-rate-box-largefrb-P_LARGE_FRB"
                    >online or for pick up at your local post office</a
                  >. soona is not liable for issues with shipping. please ensure
                  your box is properly sealed and packaged with appropriate
                  materials.
                </p>

                <p class="u-small--regular mb-m">
                  <b>restrictions!</b> please note that your package must not
                  exceed 70 pounds in weight. all postal service restrictions
                  for shipping are applicable. please review the
                  <a
                    class="soona-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.usps.com/ship/shipping-restrictions.htm"
                    >USPS Shipping Restrictions</a
                  >
                  before confirming.
                </p>

                <label class="u-small--regular">
                  <input
                    id="understand"
                    class="mr-s"
                    type="checkbox"
                    name="understand"
                    required
                    autofocus
                    @click="toggleDisable"
                  />
                  I agree to follow all guidelines and restrictions</label
                >
              </div>
            </template>
            <template #payment>
              <div class="payment-buttons">
                <SoonaButton
                  :is-loading="isSubmitting"
                  size="large"
                  type="submit"
                  :disabled="!checkedAgreement"
                  @on-click="confirmFreeShipmentClicked"
                >
                  confirm
                </SoonaButton>
                <SoonaButton variation="tertiary" @on-click="onCancel">
                  cancel
                </SoonaButton>
              </div>
            </template>
          </SummaryPayment>
          <template v-else>
            <div class="shipping-form-container u-small--regular">
              <p class="soona-title-a1 mb-s"><b>SHIP TO:</b></p>

              <div class="pl-m mb-m">
                <p>{{ currentShipment.address_to.name }}</p>
                <p>
                  {{
                    [
                      currentShipment.address_to.street1,
                      currentShipment.address_to.street2,
                    ].join(' ')
                  }}, {{ currentShipment.address_to.city }},
                  {{ currentShipment.address_to.state }}
                  {{ currentShipment.address_to.zip }}
                </p>
              </div>

              <p class="soona-title-a1 mb-s"><b>FROM:</b></p>

              <div class="pl-m mb-m">
                <p>{{ currentShipment.address_from.name }}</p>
                <p>
                  {{
                    [
                      currentShipment.address_from.street1,
                      currentShipment.address_from.street2,
                    ].join(' ')
                  }}, {{ currentShipment.address_from.city }},
                  {{ currentShipment.address_from.state }}
                  {{ currentShipment.address_from.zip }}
                </p>
              </div>

              <p class="soona-title-a1 mb-s"><b>PACKAGE DETAILS:</b></p>

              <div class="pl-m mb-m">
                <p>
                  1 package x {{ currentShipment.parcels.length }}" x
                  {{ currentShipment.parcels.width }}" x
                  {{ currentShipment.parcels.height }}" ({{
                    currentShipment.parcels.weight
                  }}
                  lbs)
                </p>
              </div>

              <p class="soona-title-a1 mb-s"><b>CARRIER:</b></p>

              <div class="pl-m">
                <p>
                  <b class="soona-title-a1">
                    {{ currentRate.provider }} {{ currentRate.service_level }}
                  </b>
                </p>
                <p class="is-lowercase">
                  {{ overrideDurationTerms(currentRate.duration_terms) }}
                </p>
              </div>
            </div>

            <!-- total cost of shipping -->

            <div class="columns is-full is-mobile is-gapless">
              <div class="column is-four-fifths has-text-right">
                <p class="mt-l u-small--regular mb-s">
                  <b class="soona-title-a1">COST:</b>
                </p>
                <p class="u-small--regular mb-m">
                  <b class="soona-title-a1">DISCOUNT:</b>
                </p>
                <p class="u-small--regular">
                  <b class="soona-title-a1">TOTAL:</b>
                </p>
              </div>
              <div class="column is-one-fifth has-text-right">
                <p class="mt-l u-small--regular mb-s">
                  ${{ currentRate.amount }}
                </p>
                <p class="u-small--regular mb-m">- ${{ currentRate.amount }}</p>
                <p class="u-small--regular" data-cypress="free-shipping-total">
                  $0.00
                </p>
              </div>
            </div>

            <!-- confirm -->

            <div class="free-shipping-policy">
              <p class="u-small--regular mb-m">
                <b>important!</b> to take advantage of soona's free shipping
                offer you must use a <b><i>large flat rate box from USPS</i></b
                >. the branded boxes are available for free
                <a
                  class="soona-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://store.usps.com/store/product/shipping-supplies/priority-mail-large-flat-rate-box-largefrb-P_LARGE_FRB"
                  >online or for pick up at your local post office</a
                >. soona is not liable for issues with shipping. please ensure
                your box is properly sealed and packaged with appropriate
                materials.
              </p>

              <p class="u-small--regular mb-m">
                <b>restrictions!</b> please note that your package must not
                exceed 70 pounds in weight. all postal service restrictions for
                shipping are applicable. please review the
                <a
                  class="soona-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.usps.com/ship/shipping-restrictions.htm"
                  >USPS Shipping Restrictions</a
                >
                before confirming.
              </p>

              <label class="u-small--regular">
                <input
                  id="understand"
                  class="mr-s"
                  type="checkbox"
                  name="understand"
                  required
                  autofocus
                  @click="toggleDisable"
                />
                I agree to follow all guidelines and restrictions</label
              >
            </div>

            <div class="confirm__action-btns">
              <SoonaButton
                :is-loading="isSubmitting"
                type="submit"
                :disabled="!checkedAgreement"
                @on-click="confirmFreeShipmentClicked"
              >
                confirm
              </SoonaButton>
              <SoonaButton variation="tertiary" @on-click="onCancel">
                cancel
              </SoonaButton>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.confirm {
  &__action-btns {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }
}

.payment-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.summary-payment {
  .summary-payment__itemized-body {
    .shipping-info-row {
      td,
      th {
        text-transform: none;

        &.faux-cell {
          display: none;
        }
      }
    }
  }
}
</style>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import applyDigitalAssetFilters from '@/queries/digital_assets/applyDigitalAssetFilters';

/**
 *
 * @param {Object} [params]
 * @param {string | Ref<string>} [collectionId]
 * @param {string | Ref<string>} [digitalAssetId]
 * @param {Object | Ref<string>} [params]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useReEditsCollectionDigitalAssetByDigitalAssetId(
  collectionId,
  digitalAssetId,
  { filters = {} } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.collectionAssetByDigitalAsset(
      collectionId,
      're_edits_collection',
      digitalAssetId,
      filters
    ),
    queryFn: async ({ signal }) => {
      let params = {};
      const apiFilters = toValue(filters);
      applyDigitalAssetFilters(params, apiFilters);

      const response = await http.get(
        `/collections/${toValue(collectionId)}/re_edits_collection_digital_assets/by_digital_asset/${toValue(digitalAssetId)}`,
        {
          params,
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

import { unref } from 'vue';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import {
  getCompetitors,
  refreshCompetitors,
  getCompetitor,
  deleteCompetitor,
} from 'src/api/competitors';
import { queryKeys } from 'src/queries/query-keys';

/**
 *
 * @param {string | number} accountId
 * @param {Object} params
 * @param {string | null} [params.query]
 * @param {string | null} [params.filter]
 * @param {string} [params.orderBy]
 * @param {string} [params.direction]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {UseQueryOptions} queryOptions
 */
export function useCompetitors(
  accountId,
  { query, filter, orderBy, direction, itemsPerPage, currentPage } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.competitors(accountId, {
      query,
      filter,
      orderBy,
      direction,
      itemsPerPage,
      currentPage,
    }),
    queryFn: ({ signal }) =>
      getCompetitors(
        unref(accountId),
        {
          query: unref(query),
          filter: unref(filter),
          orderBy: unref(orderBy),
          direction: unref(direction),
          itemsPerPage: unref(itemsPerPage),
          currentPage: unref(currentPage),
        },
        signal
      ),
    ...queryOptions,
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} competitorId
 */
export function useRefreshCompetitors(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return refreshCompetitors(unref(accountId));
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.competitors(accountId),
        }),
      ]);
    },
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} competitorId
 */
export function useCompetitor(accountId, competitorId, queryOptions = null) {
  return useQuery({
    queryKey: queryKeys.competitor(accountId, competitorId),
    queryFn: ({ signal }) =>
      getCompetitor(unref(accountId), unref(competitorId), signal),
    ...queryOptions,
  });
}

/**
 * @param {number | Ref<number>} accountId
 * @param {number | Ref<number>} competitorId
 */
export function useDeleteCompetitor(accountId, competitorId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return deleteCompetitor(unref(accountId), unref(competitorId));
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.competitors(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(accountId),
        }),
      ]);
    },
  });
}

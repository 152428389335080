import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { bulkHideDigitalAssets } from '@/api/digitalAssets';
import { queryKeys } from '@/queries/query-keys';

/**
 * @param { Ref<any> } accountId
 * @returns {UseMutationReturnType<any, unknown, void, unknown>}
 */
export function useBulkHideDigitalAssets(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => bulkHideDigitalAssets(toValue(accountId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservationDigitalAssets(),
      });
    },
  });
}

<script setup>
import { computed, ref } from 'vue';
import ProServiceSelectionFilter from './ProServiceSelectionFilter.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import { useGetTagFollowUpCategories } from '@/queries/useGetTagFollowUpCategories';

const props = defineProps({
  mainTag: Object,
  modelValue: Array,
  confirmButtonCopy: String,
  workedWithAccountId: {
    default: null,
    type: Number,
  },
});

const emit = defineEmits([
  'update:modelValue',
  'clearFilters',
  'confirmClicked',
  'cancelClicked',
  'select-models-worked-with',
]);

const mainTag = computed(() => props.mainTag);
const modelValue = computed(() => props.modelValue);
const confirmButtonCopy = computed(() => props.confirmButtonCopy);

const { data: tagFollowUpCategories } = useGetTagFollowUpCategories(
  computed(() => props.mainTag.id),
  {
    enabled: computed(() => !!props.mainTag),
  }
);

const isSizesOpen = ref(false);

const filteredSortedTagCategories = computed(() =>
  tagFollowUpCategories.value?.filter(
    tc =>
      tc.title !== 'manicure' &&
      tc.title !== 'manicure color' &&
      tc.title !== 'pronoun'
  )
);

const basicInfoTagCategories = computed(() =>
  filteredSortedTagCategories.value.filter(
    tc => tc.special_category === 'psp_trait_basic_info'
  )
);

const sizeTagCategories = computed(() =>
  filteredSortedTagCategories.value.filter(
    tc => tc.special_category === 'psp_trait_size'
  )
);

const talentTagCategories = computed(() =>
  filteredSortedTagCategories.value.filter(
    tc => tc.special_category === 'psp_trait_talent'
  )
);

function toggleSizesOpen() {
  isSizesOpen.value = !isSizesOpen.value;
}

function clearFilters(event) {
  event.subContext = `${mainTag.value.title} filters modal`;
  emit('clearFilters', event);
}
</script>

<template>
  <div class="filter-modal">
    <SoonaDialog
      aria-label="pro service provider details"
      @close="event => emit('cancelClicked', event)"
    >
      <template #heading> {{ mainTag.title }}</template>
      <template #default>
        <SoonaButton
          :class="{ 'models--active': workedWithAccountId }"
          variation="filter"
          @on-click="emit('select-models-worked-with')"
        >
          <SoonaIcon name="heart" />
          <span class="u-label--heavy">models I’ve worked with</span>
        </SoonaButton>
        <hr />
        <div
          v-for="tagCategory in basicInfoTagCategories"
          :key="tagCategory.id"
        >
          <div>
            <ProServiceSelectionFilter
              :model-value="modelValue"
              :pro-service-provider-type="mainTag.title"
              :tag-category="tagCategory"
              :type="tagCategory.options.filter_style"
              @update:model-value="emit('update:modelValue', $event)"
            />
          </div>
          <hr />
        </div>
        <div v-if="sizeTagCategories.length > 1">
          <button
            id="pro-service-filter-expand-sizes"
            class="u-body--heavy sizes-header-button u-button-reset"
            :aria-expanded="isSizesOpen"
            aria-controls="pro-service-filter-expand-region-sizes"
            @click="toggleSizesOpen"
          >
            clothing sizes (top, bottom, shoe, bra)
            <SoonaIcon class="header-chevron" name="chevron-down" />
          </button>
          <hr />
          <div
            id="pro-service-filter-expand-region-sizes"
            class="sizes-container"
            aria-labelledby="pro-service-filter-expand-sizes"
            role="region"
          >
            <template v-if="isSizesOpen">
              <div
                v-for="tagCategory in sizeTagCategories"
                :key="tagCategory.id"
              >
                <div>
                  <ProServiceSelectionFilter
                    :model-value="modelValue"
                    :pro-service-provider-type="mainTag.title"
                    :tag-category="tagCategory"
                    :type="tagCategory.options.filter_style"
                    parent-category="clothing sizes (top, bottom, shoe, bra)"
                    @update:model-value="emit('update:modelValue', $event)"
                  />
                </div>
                <hr />
              </div>
            </template>
          </div>
        </div>
        <div v-else>
          <div v-for="tagCategory in sizeTagCategories" :key="tagCategory.id">
            <div>
              <ProServiceSelectionFilter
                :model-value="modelValue"
                :pro-service-provider-type="mainTag.title"
                :tag-category="tagCategory"
                :type="tagCategory.options.filter_style"
                @update:model-value="emit('update:modelValue', $event)"
              />
            </div>
            <hr />
          </div>
        </div>
        <div v-for="tagCategory in talentTagCategories" :key="tagCategory.id">
          <div>
            <ProServiceSelectionFilter
              :model-value="modelValue"
              :pro-service-provider-type="mainTag.title"
              :tag-category="tagCategory"
              :type="tagCategory.options.filter_style"
              @update:model-value="emit('update:modelValue', $event)"
            />
          </div>
          <hr />
        </div>
      </template>
      <template #footer>
        <SoonaButton
          variation="tertiary"
          :on-click="clearFilters"
          size="medium"
        >
          clear all
        </SoonaButton>
        <SoonaButton @on-click="event => emit('confirmClicked', event)">
          {{ confirmButtonCopy }}
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.filter-modal {
  :deep(.modal-background) {
    overflow: hidden;
  }

  hr {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    color: variables.$periwink-blue-30;
    height: 0.063rem;
    border: 0.031rem solid variables.$periwink-blue-30;
    max-width: 95%;
  }
}

.sizes-header-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding: 0.5rem 0;
  margin-bottom: 0.25rem;

  .header-chevron {
    margin-left: 5px;
    color: variables.$gray-90;
    transition: transform 300ms ease-out;
    cursor: pointer;
  }
}

.sizes-header-button[aria-expanded='true'] {
  .header-chevron {
    transform: rotate(180deg);
  }
}

.sizes-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 5%;
}

.sizes-header-button[aria-expanded='false'] ~ .sizes-container {
  display: none;
}

.models {
  &--active {
    border-color: variables.$friendly-red-40;
    background-color: variables.$friendly-red-20;

    svg {
      color: variables.$friendly-red-50;
    }
  }
}
</style>

<script setup>
import { ref, computed, watch, watchEffect, onMounted } from 'vue';
import { refDebounced } from '@vueuse/core';
import DirectoryProfiles from './DirectoryProfiles.vue';
import DirectorySideBar from './DirectorySideBar.vue';
import DirectoryHeader from './DirectoryHeader.vue';
import { useGetProServiceProfiles } from 'src/queries/useGetProServiceProfiles';
import { useCapability } from 'src/composables/useCapability';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useTitle } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { keepPreviousData } from '@tanstack/vue-query';
import { useFlag } from '@/composables/useFlag';
import { useMe } from '@/composables/user/useMe';

const title = useTitle();
const { pageViewed, resultsReturned } = useBaseEvents();
const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});
const apolloClientDirectoryHeader = useFlag('apollo_client_directory_header');
const route = useRoute();
const router = useRouter();

const allowedSorts = ['asc', 'desc', 'avl'];
const currentPage = ref(Number(route.query.page) || 1);
const itemsPerPage = ref(Number(route.query.per) || 10);
const sort = ref(
  allowedSorts.includes(route.query.sort) ? route.query.sort : 'asc'
);
const q = ref(isFtSoonaStaff && route.query.q ? route.query.q : '');
const proServiceType = ref(route.query.type ?? '');
const availability = ref(route.query.availability ?? { available_only: false });
const availableOnly = ref(availability.value?.['availableOnly'] ?? false);
const proServiceTypeId = ref(Number(route.query.typeId) || null);
const proServiceCategoryId = ref(Number(route.query.cId) || null);
const filters = ref(
  Array.isArray(route.query['f[]'])
    ? route.query['f[]'].map(Number)
    : route.query['f[]']
      ? [Number(route.query['f[]'])]
      : []
);
const availabilityStart = ref(null);
const availabilityEnd = ref(null);
const locationIds = ref(
  Array.isArray(route.query['lIds[]'])
    ? route.query['lIds[]'].map(Number)
    : route.query['lIds[]']
      ? [Number(route.query['lIds[]'])]
      : []
);
const queryDebounced = refDebounced(q, 200);
const isActive = computed(() => !isFtSoonaStaff.value || null);

// models worked with
const { currentAccountId } = useMe();

const workedWithAccountId = ref(null);

const selectModelsWorkedWith = () => {
  if (!workedWithAccountId.value) {
    workedWithAccountId.value = currentAccountId.value;
  } else {
    workedWithAccountId.value = null;
  }
};

const { data: proServiceProfilesData, error: getProServiceProfilesError } =
  useGetProServiceProfiles(
    {
      proServiceCategoryId: proServiceCategoryId,
      proServiceType: proServiceType,
      proServiceTypeId: proServiceTypeId,
      availability: availability,
      query: queryDebounced,
      filters: filters,
      workedWithAccountId: workedWithAccountId,
      locationIds: locationIds,
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      sort: sort,
      isActive: isActive,
    },
    { placeholderData: keepPreviousData }
  );

const proServiceProfiles = computed(
  () => proServiceProfilesData.value?.proServiceProfiles ?? []
);

const formattedFilters = computed(
  () => proServiceProfilesData.value?.formattedFilters ?? []
);

const proServiceProfilesPagination = computed(() => {
  if (proServiceProfilesData.value) {
    return proServiceProfilesData.value.pagination;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});

const showSidebar = computed(
  () => !apolloClientDirectoryHeader.value || isFtSoonaStaff.value
);

const priorityError = usePriorityError(getProServiceProfilesError);

watch(proServiceProfiles, () => {
  if (!getProServiceProfilesError.value) {
    resultsReturned({
      context: 'provider directory',
      subContext: 'filters sidebar',
      quantityResults: proServiceProfilesPagination.value.totalCount,
      filtersSelected: formattedFilters.value,
    });
  }
});

watch(
  [queryDebounced, filters, locationIds, sort, itemsPerPage, availableOnly],
  () => {
    currentPage.value = 1;
  }
);

watch([proServiceCategoryId, proServiceType, proServiceTypeId], () => {
  currentPage.value = 1;
  filters.value = [];
});

watchEffect(() => {
  if (availabilityStart.value && availabilityEnd.value) {
    availability.value = {
      available_only: true,
      start_time: availabilityStart.value,
      end_time: availabilityEnd.value,
    };
  } else if (availableOnly.value) {
    availability.value = {
      available_only: true,
    };
  } else {
    availability.value = {
      available_only: false,
    };
  }
});

watchEffect(() => {
  const searchParams = {};

  searchParams.sort = sort.value;
  searchParams.page = currentPage.value;
  searchParams.per = itemsPerPage.value;

  if (proServiceCategoryId.value) {
    searchParams.cId = proServiceCategoryId.value;
  }
  if (availableOnly.value) {
    searchParams.availableOnly = availableOnly.value;
  }
  if (proServiceType.value) {
    searchParams.type = proServiceType.value;
  }
  if (proServiceTypeId.value) {
    searchParams.typeId = proServiceTypeId.value;
  }
  if (queryDebounced.value && isFtSoonaStaff.value) {
    searchParams.q = queryDebounced.value;
  }
  if (filters.value.length > 0) {
    searchParams['f[]'] = filters.value;
  }
  if (locationIds.value.length > 0) {
    searchParams['lIds[]'] = locationIds.value;
  }

  router.replace({ query: searchParams });
});

onMounted(() => {
  title.value = 'directory | soona';
  pageViewed();
});
</script>
<template>
  <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
  <div class="directory" :class="{ 'directory--spacing': !showSidebar }">
    <DirectorySideBar
      v-if="showSidebar"
      v-model:pro-service-type="proServiceType"
      v-model:available-only="availableOnly"
      v-model:pro-service-type-id="proServiceTypeId"
      v-model:pro-service-category-id="proServiceCategoryId"
      v-model:query-text="q"
      v-model:location-ids="locationIds"
      v-model:filters="filters"
      v-model:availability-range-start="availabilityStart"
      v-model:availability-range-end="availabilityEnd"
      class="directory__left"
    />
    <DirectoryHeader
      v-else
      v-model:pro-service-type="proServiceType"
      v-model:pro-service-type-id="proServiceTypeId"
      v-model:pro-service-category-id="proServiceCategoryId"
      v-model:location-ids="locationIds"
      v-model:filters="filters"
      :num-providers="proServiceProfilesPagination.totalCount"
      :worked-with-account-id="workedWithAccountId"
      class="directory__top rounded"
      @select-models-worked-with="selectModelsWorkedWith"
    />
    <DirectoryProfiles
      v-model:items-per-page="itemsPerPage"
      v-model:current-page="currentPage"
      v-model:sort="sort"
      :total-count="proServiceProfilesPagination.totalCount"
      :total-pages="proServiceProfilesPagination.totalPages"
      :pro-service-profiles="proServiceProfiles"
      class="directory__right rounded"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.directory {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0rem 0.75rem 0rem 0.75rem;
  container-type: inline-size;

  &__left {
    flex: 1 1 18.75rem;

    @container (min-width: 48rem) {
      flex-grow: 0;
    }
  }

  &__top {
    flex: 1 1 100%;
    border-radius: 0.625rem;
  }

  &__right {
    flex: 1 1 29rem;
    border-radius: 0.625rem;
  }

  &--spacing {
    gap: 1.5rem;
  }
}
</style>

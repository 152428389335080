<script setup>
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import { computed, ref } from 'vue';
import { useReservation } from '@/composables/useReservation';
import { useStore } from 'vuex';
import { useCapabilities } from '@/composables/useCapabilities';
import { useCompleteEdits } from '@/queries/reservations/useCompleteEdits';
import { useUndoCompleteEdits } from '@/queries/reservations/useUndoCompleteEdits';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const store = useStore();

const reservationId = computed(() => props.reservationId);

const bookingType = ref('');
const shouldBeUnscheduled = ref(false);
const showChangeBookingModal = ref(false);
const showUnStartBookingModal = ref(false);
const showUpdateBookingConfirmModal = ref(false);
const showSkipShootModal = ref(false);
const showStartWrapModal = ref(false);
const unstartNote = ref(null);
const unstartReason = ref(null);
const unstartReasonOptions = ref([
  { value: 1, label: 'needs pro service' },
  { value: 2, label: 'missing package' },
  { value: 3, label: 'product not found' },
  { value: 4, label: 'client related' },
  { value: 5, label: 'scheduling conflict' },
  { value: 6, label: 'accidental start' },
  { value: 7, label: 'other' },
]);

const capabilitiesRef = ref([
  { capability: 'change_booking_type' },
  { capability: 'ft_soona_staff' },
  { capability: 'unstart' },
  { capability: 'reopen_booking' },
]);

const capabilities = useCapabilities(capabilitiesRef);

const {
  isInProgress,
  isPendingShotlist,
  isPreShoot,
  isCompleted,
  isEditsRequired,
} = useReservation(reservationId);

const bookingWrapped = computed(
  () => !isInProgress.value && !isPreShoot.value && !isPendingShotlist.value
);

const toggleChangeBookingModal = val => {
  showChangeBookingModal.value = val;
};

const toggleUnStartBookingModal = val => {
  showUnStartBookingModal.value = val;
};

const toggleSkipShootModal = val => {
  showSkipShootModal.value = val;
};

const toggleStartWrapModal = () => {
  showStartWrapModal.value = !showStartWrapModal.value;
};

const toggleUpdateBookingConfirmModal = val => {
  showUpdateBookingConfirmModal.value = val;
};

const reopenBookingAndCloseDropdown = () => {
  store.dispatch('reservation/reopenBooking', reservationId.value);
};

const toasterReservationCompleteEditsButton = useFlag(
  'toaster_reservation_complete_edits'
);

const { mutate: completeEdits, isPending: isCompletingEdits } =
  useCompleteEdits(reservationId);
const { mutate: undoCompleteEdits, isPending: isUndoingCompleteEdits } =
  useUndoCompleteEdits(reservationId);

const visibleDropdownOptions = computed(() => {
  let dropdownButtons = [
    {
      show: capabilities.value?.change_booking_type.hasCapability,
      click: () => toggleChangeBookingModal(true),
      text: 'change booking type',
      key: 'change-booking',
    },
    {
      show:
        capabilities.value?.ft_soona_staff.hasCapability &&
        (isPreShoot.value || isPendingShotlist.value),
      click: () => toggleSkipShootModal(true),
      text: 'skip shoot',
      key: 'skip',
    },
    {
      show:
        capabilities.value?.ft_soona_staff.hasCapability &&
        (isPreShoot.value || isPendingShotlist.value || isInProgress.value),
      click: toggleStartWrapModal,
      text:
        isPreShoot.value || isPendingShotlist.value
          ? 'start shoot'
          : 'wrap shoot',
      key: 'start-end',
    },
    {
      show: capabilities.value?.unstart.hasCapability && isInProgress.value,
      click: () => toggleUnStartBookingModal(true),
      text: 'un-start booking',
      key: 'un-start',
    },
    {
      show:
        capabilities.value?.reopen_booking.hasCapability &&
        bookingWrapped.value,
      click: reopenBookingAndCloseDropdown,
      text: 'reopen booking',
      key: 'reopen-booking',
    },
  ];
  return dropdownButtons.filter(button => button.show === true);
});

const toggleShoot = close => {
  if (isPreShoot.value || isPendingShotlist.value) {
    store.dispatch('reservation/startShoot', reservationId.value);
  } else {
    store.dispatch('reservation/wrapShoot', reservationId.value);
  }

  close();
};

const skipShoot = async close => {
  try {
    await store.dispatch('reservation/startShoot', reservationId.value);
    await store.dispatch('reservation/wrapShoot', reservationId.value);
  } catch (error) {
    console.error(error);
  }
  close();
};

const unStartBooking = async close => {
  await store.dispatch('reservation/unStart', reservationId.value);
  await store.dispatch('reservation/createShootException', {
    reservationId: reservationId.value,
    reason: unstartReasonOptions.value.find(
      x => x.value === unstartReason.value
    ).label,
    exceptionType: 0,
    note: unstartNote.value,
    unschedule: shouldBeUnscheduled.value,
  });

  shouldBeUnscheduled.value = false;
  unstartNote.value = null;
  unstartReason.value = null;

  close();
};

const updateBookingType = async () => {
  await store.dispatch('reservation/changeBookingType', {
    bookingType: bookingType.value,
  });
  showChangeBookingModal.value = !showChangeBookingModal.value;
  showUpdateBookingConfirmModal.value = true;
};

const reservationTypes = computed(() => store.getters['reservationTypes']);
const selectableReservationTypes = computed(() => {
  let result = [];
  reservationTypes.value
    ?.filter(reservationType => reservationType.name !== 'headshots_for_all')
    .forEach(x =>
      result.push({
        value: x.name,
        label: x.name.replace(/_/g, ' '),
      })
    );
  return result;
});

const disableDropdown = computed(() => {
  return visibleDropdownOptions.value?.length === 0;
});
</script>
<template>
  <div class="action-row">
    <SoonaButton
      v-if="isEditsRequired && toasterReservationCompleteEditsButton"
      :loading="isCompletingEdits"
      variation="secondary-gray"
      size="medium"
      @click="completeEdits"
    >
      complete edits
    </SoonaButton>
    <SoonaButton
      v-if="isCompleted && toasterReservationCompleteEditsButton"
      :loading="isUndoingCompleteEdits"
      variation="secondary-gray"
      size="medium"
      @click="undoCompleteEdits"
    >
      undo complete edits
    </SoonaButton>

    <SoonaDropdownMenu copy="shoot actions" :disabled="disableDropdown">
      <template #default="{ clickCapture, keydown, mouseover }">
        <SoonaDropdownMenuItem
          v-for="button in visibleDropdownOptions"
          :key="button.key"
        >
          <button
            role="menuitem"
            @click.capture="clickCapture"
            @click="button.click"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            {{ button.text }}
          </button>
        </SoonaDropdownMenuItem>
      </template>
    </SoonaDropdownMenu>

    <!-- Modal for starting/wrapping shoot -->
    <SoonaDialog
      v-if="showStartWrapModal"
      type="alertdialog"
      @close="toggleStartWrapModal"
    >
      <template #heading>are you sure?</template>
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
        <SoonaButton variation="primary" @on-click="toggleShoot(close)">
          yes!
          {{ isPreShoot || isPendingShotlist ? 'start it' : 'wrap it' }}
        </SoonaButton>
      </template>
    </SoonaDialog>

    <!-- Modal for changing booking type -->
    <SoonaDialog
      v-if="showChangeBookingModal"
      @close="() => toggleChangeBookingModal(false)"
    >
      <template #heading>change the booking type</template>
      <SoonaSelect
        v-model="bookingType"
        :options="selectableReservationTypes"
        :selectable="option => option.value !== bookingType"
      />
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
        <SoonaButton
          variation="primary"
          :disabled="!bookingType"
          @on-click="updateBookingType"
        >
          confirm
        </SoonaButton>
      </template>
    </SoonaDialog>

    <!-- Modal for un-start booking -->
    <SoonaDialog
      v-if="showUnStartBookingModal"
      @close="() => toggleUnStartBookingModal(false)"
    >
      <template #heading>unstart shoot</template>
      <p class="shoot-exception-description">
        select a reason and confirm you want to unstart this shoot.
      </p>
      <SoonaSelect
        v-model="unstartReason"
        placeholder="select reason"
        :options="unstartReasonOptions"
      >
        <template #label>unstart reason</template>
      </SoonaSelect>
      <div class="unstart-note-container">
        <SoonaTextfield
          v-model="unstartNote"
          label="notes"
          type="text"
          placeholder="details on unstart reason"
        />
      </div>
      <SoonaToggle
        v-model="shouldBeUnscheduled"
        class="is-size-6-mobile"
        type="checkbox"
        label="unschedule shoot and remove from calendar"
      />
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
        <SoonaButton
          variation="primary"
          :disabled="!unstartReason"
          @on-click="unStartBooking(close)"
        >
          unstart shoot
        </SoonaButton>
      </template>
    </SoonaDialog>

    <!-- confirm modal for shopify users -->
    <SoonaDialog
      v-if="showUpdateBookingConfirmModal"
      type="alertdialog"
      @close="() => toggleUpdateBookingConfirmModal(false)"
    >
      <template #heading>booking successfully changed</template>
      <p>
        yay! booking changed to <b>{{ bookingType.replace(/_/g, ' ') }}</b>
      </p>

      <template #footer="{ close }">
        <SoonaButton variation="primary" @on-click="close">okay</SoonaButton>
      </template>
    </SoonaDialog>

    <!-- confirm modal for skip shoot -->
    <SoonaDialog
      v-if="showSkipShootModal"
      type="alertdialog"
      @close="() => toggleSkipShootModal(false)"
    >
      <template #heading>do you want to skip this shoot?</template>
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
        <SoonaButton variation="primary" @on-click="skipShoot(close)">
          yes! skip shoot
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

label {
  @include variables_fonts.u-label--heavy;

  color: variables.$black-default;
  text-align: left;
  margin-bottom: 0;
  padding-bottom: 0.4375rem;
  display: block;
}

.unstart-note-container {
  display: flex;
  flex-direction: column;
}

.shoot-exception-description {
  margin-bottom: 1rem;
}

.action-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4375rem;
}
</style>

<script setup>
import SceneCard from 'src/components/booking/v3/p2/SceneCard.vue';

const props = defineProps({
  buttonsLoading: {
    default: () => [],
    required: false,
    type: Array,
  },
  hasBorder: {
    default: true,
    required: false,
    type: Boolean,
  },
  hasReachedMaxSelected: {
    default: false,
    required: false,
    type: Boolean,
  },
  isMutating: {
    default: false,
    required: true,
    type: Boolean,
  },
  onClick: {
    required: true,
    type: Function,
  },
  options: {
    default: () => [],
    required: true,
    type: Array,
  },
  title: {
    default: '',
    required: false,
    type: String,
  },
});

const emit = defineEmits(['updateButtonList']);

const generateImageList = option => {
  const mainImageSrc = option.tag.image_url;
  const secondaryImageSrcList = option.tag.image_secondary_urls;

  if (secondaryImageSrcList.length === 0)
    return [{ src: mainImageSrc, alt: option.tag.title }];

  const allImageSrcList = [mainImageSrc, ...secondaryImageSrcList];
  const imageList = allImageSrcList.map(s => {
    return { src: s, alt: option.tag.title };
  });

  return imageList;
};

function isLoading(id) {
  return props.buttonsLoading?.includes(id);
}

const onSceneClick = option => {
  emit('updateButtonList', option.tag.id);
  if (props.onClick) props.onClick(option);
};
</script>
<template>
  <div
    class="scenes-container"
    :class="{ 'scenes-container--border': hasBorder }"
  >
    <h2 class="scenes-container__category-title">
      {{ title }}
    </h2>
    <div class="scenes-container__grid">
      <SceneCard
        v-for="option in options"
        :key="option.tag.id"
        :is-loading="isLoading(option.tag.id)"
        :is-selected="option.selected"
        :images="generateImageList(option)"
        :scene="option"
        :on-click="onSceneClick"
        :disabled="
          isMutating ||
          (hasReachedMaxSelected && !option.selected) ||
          option.incompatible
        "
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.scenes-container {
  color: variables.$black-default;
  padding-top: 0.5rem;
  width: 100%;

  &--border {
    border-top: 0.0625rem solid variables.$gray-30;
  }

  &__category-title {
    @include variables_fonts.u-label--heavy;

    padding-bottom: 1.5rem;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-body--heavy;
    }
  }

  &__grid {
    display: grid;
    gap: 1.5rem 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-bottom: 2rem;

    @media (min-width: variables.$screen-lg-min) {
      gap: 1.5rem;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
</style>

<template>
  <form @submit.prevent="$emit('submit', $event)">
    <slot :has-errors="hasErrors" />
  </form>
</template>

<script>
export default {
  name: 'SoonaForm',
  emits: ['submit'],
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0;
    },
  },
  mounted() {
    // emitter package is recommended by Vue docs if using event bus pattern: https://v3-migration.vuejs.org/breaking-changes/events-api.html#root-component-events
    this.$emitter.on('set-validation-errors', newError => {
      this.setErrors(newError);
    });
  },
  methods: {
    setErrors(newError) {
      let foundIndex = this.errors.findIndex(
        error => error.childComponentId === newError.childComponentId
      );
      if (foundIndex >= 0 && newError.errors.length === 0) {
        this.errors.splice(foundIndex, 1);
        return;
      }
      if (foundIndex >= 0 && newError.errors.length > 0) {
        this.errors[foundIndex] = newError;
        return;
      }
      if (newError.errors.length > 0) {
        this.errors.push(newError);
      }
    },
  },
};
</script>

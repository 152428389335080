<script setup>
import { computed } from 'vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import ReservationCard from '@/components/bookings/ReservationCard.vue';
import SoonaGrid from '@/components/ui_library/SoonaGrid.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  heading: {
    type: String,
    required: true,
  },
  itemsPerPage: {
    type: Number,
    required: true,
  },
  perPageOptions: {
    type: Array,
    required: true,
  },
  priorityError: {
    type: [String, null],
    required: false,
  },
  reservations: {
    type: Array,
    required: true,
  },
  totalCount: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
});

defineEmits(['update:currentPage', 'update:itemsPerPage']);

const accountId = computed(() => props.accountId);

const headingId = computed(() => {
  return 'bookings-category-heading-' + props.heading.replace(/\W/g, '-');
});
</script>

<template>
  <section class="bookings-category" :aria-labelledby="headingId">
    <h2
      :id="headingId"
      class="bookings-category__heading"
      data-cypress="bookings-category-heading"
    >
      {{ heading }}
    </h2>
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <template v-if="reservations.length">
      <SoonaGrid>
        <ReservationCard
          v-for="reservation in reservations"
          :key="reservation.id"
          :reservation="reservation"
          :account-id="accountId"
        />
      </SoonaGrid>

      <PaginatorFull
        :page="currentPage"
        :records-per-page="itemsPerPage"
        :total-pages="totalPages"
        :total-count="totalCount"
        :page-count="reservations.length"
        :records-per-page-options="perPageOptions"
        @update:page="$emit('update:currentPage', $event)"
        @update:records-per-page="$emit('update:itemsPerPage', $event)"
      />
    </template>
  </section>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.bookings-category {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__heading {
    @include variables_fonts.u-subheader--heavy;
    color: variables.$black-default;
  }
}

@media (min-width: variables.$screen-lg-min) {
  .bookings-category {
    margin-bottom: 2.5rem;
    gap: 1.5rem;
  }
}
</style>

<template>
  <CompleteProfileCard
    v-if="canManageAccount"
    class="where-do-you-sell"
    :class="{ 'where-do-you-sell--edit-view': isEditView }"
    :disabled="disabled"
    :show-next="!isEditView"
    :show-skip="!isEditView"
    @next="saveSellContent"
    @skip="skipCard"
  >
    <template #heading>where do you sell?</template>
    <template #subheading>select all that apply</template>
    <fieldset>
      <div class="sell-column">
        <label v-for="item in columnOne" :key="item.value">
          <input
            v-model="sell[item.value]"
            type="checkbox"
            name="sell"
            :value="item.value"
            :disabled="isEditView && !isEditing"
          />
          {{ item.label }}
        </label>
      </div>
      <div class="sell-column">
        <label v-for="item in columnTwo" :key="item.value">
          <input
            v-model="sell[item.value]"
            type="checkbox"
            name="sell"
            :value="item.value"
            :disabled="isEditView && !isEditing"
          />
          {{ item.label }}
        </label>
      </div>
    </fieldset>
    <template v-if="isEditView" #action-btns>
      <SoonaButton v-if="!isEditing" size="medium" @on-click="toggleEdit">
        edit
      </SoonaButton>
      <SoonaButton
        v-else
        class="save-btn"
        size="medium"
        @on-click="saveSellContent"
      >
        save
      </SoonaButton>
    </template>
  </CompleteProfileCard>
</template>

<script>
import { computed } from 'vue';
import { mapActions, useStore } from 'vuex';
import * as Sentry from '@sentry/vue';
import { useIntegrations } from '@/composables/useIntegrations';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useCapability } from '@/composables/useCapability';

export default {
  name: 'WhereDoYouSell',
  components: { CompleteProfileCard, SoonaButton },
  props: {
    isEditView: Boolean,
  },
  setup() {
    const store = useStore();
    const account = computed(() => store.state.account);
    const accountId = computed(() => account.value.id);

    const { hasCapability: canManageAccount } = useCapability({
      capability: 'manage_account',
      subjectType: 'account',
      subjectId: accountId,
    });

    const { hasShopifyIntegration } = useIntegrations(accountId);

    const columnOne = [
      { label: 'Shopify', value: 'shopify' },
      { label: 'my website', value: 'my_website' },
      { label: 'Amazon', value: 'amazon' },
      { label: 'Target', value: 'target' },
      { label: 'Walmart', value: 'walmart' },
    ];

    const columnTwo = [
      { label: 'my retail shop', value: 'my_retail_shop' },
      { label: 'wholesale', value: 'wholesale' },
      { label: 'other', value: 'other' },
      { label: 'none', value: 'n_a' },
    ];

    return {
      account,
      accountId,
      canManageAccount,
      columnOne,
      columnTwo,
      hasShopifyIntegration,
    };
  },
  data() {
    return {
      sell: {
        shopify: false,
        my_website: false,
        amazon: false,
        target: false,
        walmart: false,
        my_retail_shop: false,
        wholesale: false,
        other: false,
        n_a: false,
      },
      isEditing: false,
    };
  },
  computed: {
    disabled() {
      return (
        !this.sell.shopify &&
        !this.sell.my_website &&
        !this.sell.amazon &&
        !this.sell.target &&
        !this.sell.walmart &&
        !this.sell.my_retail_shop &&
        !this.sell.wholesale &&
        !this.sell.other &&
        !this.sell.n_a
      );
    },
  },
  watch: {
    account: function () {
      this.prefillExistingData();
    },
  },
  mounted() {
    this.prefillExistingData();
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    toggleEdit() {
      return (this.isEditing = !this.isEditing);
    },
    prefillExistingData() {
      this.sell.shopify = this.hasShopifyIntegration;
      let data = this.account.profile_data.where_do_you_sell?.data;
      if (this.account.profile_data.where_do_you_sell?.skip === true) {
        return;
      } else if (data) {
        if (!this.sell.shopify) {
          this.sell.shopify = data.shopify;
        }
        this.sell.my_website = data.my_website;
        this.sell.amazon = data.amazon;
        this.sell.target = data.target;
        this.sell.walmart = data.walmart;
        this.sell.my_retail_shop = data.my_retail_shop;
        this.sell.wholesale = data.wholesale;
        this.sell.other = data.other;
        this.sell.n_a = data.n_a;
      }
    },
    async saveSellContent() {
      let info = {
        where_do_you_sell: {
          data: {
            shopify: this.sell.shopify,
            my_website: this.sell.my_website,
            amazon: this.sell.amazon,
            target: this.sell.target,
            walmart: this.sell.walmart,
            my_retail_shop: this.sell.my_retail_shop,
            wholesale: this.sell.wholesale,
            other: this.sell.other,
            n_a: this.sell.n_a,
          },
          complete: true,
        },
      };
      try {
        await this.updateAccount({ accountParams: info });
        this.toggleEdit();
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(
            new Error('Updating account selling locations failed'),
            {
              extra: { error },
            }
          );
        }
      }
    },
    async skipCard() {
      let info = {
        where_do_you_sell: {
          skip: true,
        },
      };
      await this.updateAccount({ accountParams: info });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.where-do-you-sell {
  &--edit-view {
    flex-grow: 1;
    max-width: none;
  }

  fieldset {
    display: flex;
    gap: 0.5rem;
    justify-content: space-around;
    padding: 1rem 0;

    input:not(:disabled) {
      cursor: pointer;
    }
  }

  .sell-column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .save-btn {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import ProductChip from '@/components/user/anytime/products/ProductChip.vue';
import ProductMultiPicker from '@/components/user/anytime/products/picker/ProductMultiPicker.vue';
import PickerProductMultiselectOption from '@/components/user/anytime/products/picker/PickerProductMultiselectOption.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCapability } from 'src/composables/useCapability';
import useGroupCatalogItemsByReservation from '@/composables/useGroupCatalogItemsByReservation';

const props = defineProps({
  catalogItems: {
    type: Array,
    required: true,
    default: () => [],
  },
  reservationId: { type: String, required: true },
  reservationStatus: { type: String, default: null },
  accountId: { type: [String, Number], required: true },
  editView: { type: Boolean, default: false },
});

const emit = defineEmits(['update:catalogItems']);

const accountId = computed(() => props.accountId);
const catalogItems = computed(() => props.catalogItems);
const reservationId = computed(() => props.reservationId);
const reservationStatus = computed(() => props.reservationStatus);
const editView = computed(() => props.editView);
const hasBookingStarted = computed(
  () => !['pending_shotlist', 'preshoot'].includes(reservationStatus.value)
);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const showAddProductsBtn = computed(
  () =>
    editView.value && (hasBookingStarted.value ? isFtSoonaStaff.value : true)
);

const existingCatalogItemIds = computed(() => {
  return catalogItems.value.map(item => item.id);
});

const removableCatalogItemIds = computed(() =>
  catalogItems.value.filter(item => item.removable).map(item => item.id)
);

function removeProduct(catalogItemId) {
  const newCatalogItems = catalogItems.value.filter(
    item => item.id !== catalogItemId
  );
  emit('update:catalogItems', newCatalogItems);
}

const isProductPickerOpen = ref(false);

function selectCatalogItems(selectedCatalogItems) {
  const newSelectedCatalogItems = selectedCatalogItems.map(item => ({
    ...item,
    removable: true,
  }));
  let newCatalogItems = catalogItems.value.concat(newSelectedCatalogItems);
  newCatalogItems = newCatalogItems.sort((a, b) => {
    return a.id - b.id;
  });
  isProductPickerOpen.value = false;
  emit('update:catalogItems', newCatalogItems);
}

function isProductRemovable(productId) {
  return (
    showAddProductsBtn.value &&
    removableCatalogItemIds.value?.includes(productId)
  );
}

const { groupProducts } = useGroupCatalogItemsByReservation(reservationId);
</script>

<template>
  <h2
    class="booking-products__heading"
    :class="{ 'booking-products__heading--bold': editView }"
  >
    {{ editView ? 'what products will we be shooting?' : 'products' }}
  </h2>
  <template v-if="showAddProductsBtn">
    <SoonaButton
      class="booking-products__add-products-btn"
      variation="secondary-black"
      type="button"
      size="medium"
      @click="isProductPickerOpen = true"
    >
      <SoonaIcon name="plus" />
      add products
    </SoonaButton>

    <ProductMultiPicker
      v-if="isProductPickerOpen"
      :account-id="accountId"
      heading="add products"
      :existing-selected-items="existingCatalogItemIds"
      :attached-to-reservation-id="reservationId"
      order-by="attached_to_reservation_id"
      @cancel="isProductPickerOpen = false"
      @select="items => selectCatalogItems(items)"
    >
      <template
        #product-options="{
          catalogItems: catalogProducts,
          selectedCatalogItemIdsString,
          multiSelectChange,
        }"
      >
        <template
          v-for="(items, group) in groupProducts(catalogProducts)"
          :key="group"
        >
          <h3 class="u-body--heavy">{{ group }}</h3>
          <PickerProductMultiselectOption
            v-for="product in items"
            :key="product.id"
            :product="product"
            :checked="
              selectedCatalogItemIdsString.includes(product.id.toString()) ||
              existingCatalogItemIds.includes(product.id)
            "
            :disabled="existingCatalogItemIds.includes(product.id)"
            @change="multiSelectChange($event, product)"
          />
        </template>
      </template>
    </ProductMultiPicker>
  </template>
  <div
    v-if="catalogItems.length > 0"
    class="booking-products__content"
    :class="{ 'booking-products__content--edit-view': editView }"
  >
    <ProductChip
      v-for="item in catalogItems"
      :key="item.id"
      :item="item"
      :account-id="accountId"
      :removable="isProductRemovable(item.id)"
      @remove="() => removeProduct(item.id)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.booking-products {
  &__heading {
    @include variables_fonts.u-label--regular;
    margin: 0 0 0.5rem 0;
    padding: 1.125rem 0 0.5rem;
    font-weight: 800;
    font-size: 0.875rem;
    color: variables.$black-default;
  }

  &__add-products-btn {
    align-self: flex-start;
    margin-bottom: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    margin: 0.125rem;
    flex-wrap: wrap;

    &--edit-view {
      padding-bottom: 1.9375rem;
    }

    &--card {
      position: relative;
      width: 100%;

      &:not(&-edit-mode):hover {
        background-color: variables.$gray-10;
      }

      &-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        padding: 1rem 0.75rem;
      }

      &-name {
        @include variables_fonts.u-body--heavy;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
        }
      }
    }
  }
}
</style>

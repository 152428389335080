import { ref, watchEffect, computed } from 'vue';
import { createReservationTagsQuery } from 'src/queries/reservation-tags/useReservationTags';
import { useQueries } from '@tanstack/vue-query';

function preloadTagImgs(tags, includeSecondaryImgs = true) {
  // do the actual image preloading
  const imageUrlsPrimary = tags.options
    .filter(o => o.tag.image_url && o.tag.display_rule !== 'false')
    .map(t => t.tag.image_url);

  let imageUrlsSecondary = [];
  if (includeSecondaryImgs) {
    imageUrlsSecondary = tags.options
      .filter(o => o.tag.image_secondary_urls && o.tag.display_rule !== 'false')
      .map(t => t.tag.image_secondary_urls)
      .flat();
  }

  // merge arrays, then preload
  imageUrlsPrimary
    .concat(imageUrlsSecondary)
    .forEach(imageUrl => (new Image().src = imageUrl));
}

export function usePreloadTagImgs(resId, slug, proServiceSlugForSize = null) {
  const tagCategories = ref([]);
  const includeSecondaryImgs = ref(true);

  watchEffect(() => {
    // custom logic to determine particular tag lookups (such as a subcategories)
    switch (slug.value) {
      case 'type':
        includeSecondaryImgs.value = true;
        tagCategories.value = [{ category: 'media-type' }];
        break;
      case 'photo-scenes':
        includeSecondaryImgs.value = false;
        tagCategories.value = [
          { category: 'scenes', subCategory: 'flat_backdrop' },
          { category: 'scenes', subCategory: 'lifestyle' },
          { category: 'trend-sets' },
        ];
        break;
      case 'video-scenes':
        includeSecondaryImgs.value = false;
        tagCategories.value = [
          { category: 'scenes', subCategory: 'flat_backdrop' },
          { category: 'scenes', subCategory: 'lifestyle' },
        ];
        break;
      case 'models':
        includeSecondaryImgs.value = true;
        tagCategories.value = [
          {
            category: proServiceSlugForSize?.value,
            subCategory: 'pro_service_model',
          },
        ];
        break;
      case 'upgrades':
        includeSecondaryImgs.value = true;
        tagCategories.value = [
          {
            category: proServiceSlugForSize?.value,
            subCategory: 'pro_service_upgrade',
          },
        ];
        break;
      default:
        // default/fallback to load in images tied to main category (if present)
        includeSecondaryImgs.value = true;
        tagCategories.value = [{ category: slug.value }];
    }
  });

  const queryConfigs = computed(() =>
    tagCategories.value.map(({ category, subCategory }) =>
      createReservationTagsQuery(resId, category, subCategory)
    )
  );
  const queries = useQueries({
    queries: queryConfigs,
  });

  watchEffect(() => {
    queries.value.forEach(query => {
      if (query.isSuccess && query.data) {
        preloadTagImgs(query.data, includeSecondaryImgs.value);
      }
    });
  });

  return queries;
}

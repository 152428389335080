<script setup>
import {
  computed,
  provide,
  readonly,
  ref,
  toValue,
  watch,
  watchEffect,
} from 'vue';
import { CometChat } from '@cometchat-pro/chat';
import { captureChatException, COMET_CHAT_PROVIDER_NAME } from './utils';
import * as Sentry from '@sentry/vue';
import { useCometChatConnectionStatus } from './useCometChatConnectionStatus';
import { useChatAuth } from './useChatAuth';

const props = defineProps({
  userId: {
    type: Number,
    required: false,
  },
});

const userId = computed(() => props.userId);

const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion('us')
  .build();

const ChatProviderState = Object.freeze({
  Disabled: '_disabled',
  Uninitialized: '_uninitialized',
  Initialized: '_initialized',
  ValidatingUserState: '_validating-user-state',
  PendingLogin: '_pending-login',
  LoggedIn: '_logged-in',
  Error: '_error',
});
const state = ref(
  userId.value ? ChatProviderState.Uninitialized : ChatProviderState.Disabled
);
const error = ref(null);
const user = ref(null);

watchEffect(() => {
  if (!userId.value || !import.meta.env.VITE_SOONA_CHAT_APP_ID) {
    // can't use chat if there is no user or chat app id!
    state.value = ChatProviderState.Disabled;
    user.value = null;
  } else {
    // if we log into a new user, reset things to allow it to re-initialize
    state.value = ChatProviderState.Uninitialized;
    user.value = null;
  }
});

const { data: chatAuth, error: chatAuthError } = useChatAuth(userId, {
  enabled: computed(() => !!toValue(userId)),
});

watchEffect(() => {
  if (chatAuthError.value) {
    state.value = ChatProviderState.Error;
    error.value = chatAuthError.value;
  }
});

watchEffect(() => {
  if (state.value !== ChatProviderState.Error) {
    error.value = null;
  }
});

const { connectionStatus, connect, disconnect } =
  useCometChatConnectionStatus();

watch(
  [state],
  async () => {
    if (state.value === ChatProviderState.Uninitialized) {
      if (CometChat.isInitialized()) {
        state.value = ChatProviderState.Initialized;
      } else {
        try {
          await CometChat.init(
            import.meta.env.VITE_SOONA_CHAT_APP_ID,
            appSetting
          );
          state.value = ChatProviderState.Initialized;
          Sentry.addBreadcrumb({
            category: 'chat',
            message: 'initialized comet chat',
            level: 'info',
          });
        } catch (e) {
          state.value = ChatProviderState.Error;
          error.value = captureChatException(e, 'initialization error');
        }
      }
    }
  },
  { immediate: true }
);

watch(
  [state, userId, chatAuth],
  async () => {
    if (state.value === ChatProviderState.Initialized && userId.value) {
      try {
        state.value = ChatProviderState.ValidatingUserState;
        const cometChatUser = await CometChat.getLoggedInUser();
        if (cometChatUser && cometChatUser.uid !== userId.value.toString()) {
          await CometChat.logout();
          state.value = ChatProviderState.PendingLogin;
        } else if (cometChatUser) {
          user.value = cometChatUser;
          state.value = ChatProviderState.LoggedIn;
        } else {
          state.value = ChatProviderState.PendingLogin;
        }
      } catch (e) {
        state.value = ChatProviderState.PendingLogin;
      }
    }
  },
  { immediate: true }
);

watch(
  [state, userId, chatAuth],
  async () => {
    if (state.value === ChatProviderState.PendingLogin && chatAuth.value) {
      const authToken = chatAuth.value.token;
      if (authToken === 'disabled') {
        state.value = ChatProviderState.Disabled;
      } else {
        try {
          user.value = await CometChat.login(authToken);
          state.value = ChatProviderState.LoggedIn;
        } catch (e) {
          state.value = ChatProviderState.Error;
          error.value = captureChatException(
            e,
            'error logging in user to chat'
          );
        }
      }
    }
  },
  { immediate: true }
);

const PublicState = Object.freeze({
  Disabled: 'CometChat disabled',
  Pending: 'CometChat pending',
  Error: 'CometChat error',
  Ready: 'CometChat ready',
});
const publicState = computed(() => {
  switch (state.value) {
    case ChatProviderState.Disabled:
      return PublicState.Disabled;
    case ChatProviderState.LoggedIn:
      return PublicState.Ready;
    case ChatProviderState.Error:
      return PublicState.Error;
    default:
      return PublicState.Pending;
  }
});

function reset() {
  state.value = ChatProviderState.Uninitialized;
  user.value = null;
  error.value = null;
}

provide(COMET_CHAT_PROVIDER_NAME, {
  state: publicState,
  StateType: PublicState,
  error: readonly(error),
  user: readonly(user),
  connectionStatus,
  connect,
  disconnect,
  reset,
});
</script>

<template>
  <slot />
</template>

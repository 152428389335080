<script setup>
import { computed } from 'vue';
import { useReservation } from '@/composables/useReservation';
import { useCompleteEdits } from '@/queries/reservations/useCompleteEdits';
import { useDeliverAllPendingEdits } from '@/queries/reservations/useDeliverAllPendingEdits';
import { useUndoCompleteEdits } from '@/queries/reservations/useUndoCompleteEdits';
import { useFlag } from '@/composables/useFlag';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import DownloadAll from '@/components/user/anytime/reservation/gallery/edits/DownloadAll.vue';
import UploadDigitalAssetEdits from '@/components/crew/orders/UploadDigitalAssetEdits.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  showDeliverAllButton: {
    type: Boolean,
    default: false,
  },
});

const accountId = computed(() => props.accountId);
const reservationId = computed(() => props.reservationId);

const toasterReservationCompleteEditsButton = useFlag(
  'toaster_reservation_complete_edits'
);

const { mutate: completeEdits, isPending: isCompletingEdits } =
  useCompleteEdits(reservationId);
const { mutate: undoCompleteEdits, isPending: isUndoingCompleteEdits } =
  useUndoCompleteEdits(reservationId);
const { mutate: deliverAllPendingEdits, isPending: isDeliveringAll } =
  useDeliverAllPendingEdits(accountId, reservationId);

const { isCompleted, isEditsRequired, isLoading } =
  useReservation(reservationId);
</script>
<template>
  <div class="action-row">
    <SoonaButton
      v-if="showDeliverAllButton"
      :loading="isDeliveringAll || isLoading"
      variation="secondary-black"
      size="large"
      @click="deliverAllPendingEdits"
    >
      deliver all pending edits
    </SoonaButton>
    <SoonaButton
      v-if="isEditsRequired && toasterReservationCompleteEditsButton"
      :loading="isCompletingEdits || isLoading"
      variation="secondary-black"
      size="large"
      @click="completeEdits"
    >
      complete open orders
    </SoonaButton>
    <SoonaButton
      v-if="isCompleted && toasterReservationCompleteEditsButton"
      :loading="isUndoingCompleteEdits || isLoading"
      variation="secondary-black"
      size="large"
      @click="undoCompleteEdits"
    >
      re-open last completed order
    </SoonaButton>
    <UploadDigitalAssetEdits
      :account-id="accountId"
      :reservation-id="reservationId"
      size="large"
      variation="secondary-black"
    />
    <DownloadAll
      copy="download delivered"
      :show-web="false"
      :account-id="accountId"
      :reservation-id="reservationId"
      size="large"
      variation="solid-black"
    />
  </div>
</template>
<style lang="scss" scoped>
.action-row {
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  gap: 0.4375rem;
  padding: 0.4375rem 0;
}
</style>

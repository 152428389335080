<template>
  <div class="more-content-empty">
    <div class="more-content-empty__icon-container">
      <slot name="icon"></slot>
    </div>
    <p class="u-subheader--heavy">
      <slot name="message"></slot>
    </p>
    <div v-if="$slots['cta']" class="more-content-empty__cta">
      <slot name="cta" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.more-content-empty {
  background-color: variables.$gray-10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;

  @media (min-width: variables.$screen-md-min) {
    padding: 3rem;
  }

  &__icon-container {
    display: flex;
    padding: 0.9375rem;
    background-color: variables.$friendly-red-20;
    border-radius: 50%;
    margin-bottom: 0.5rem;

    :slotted(svg) {
      color: variables.$friendly-red-50;
      height: 2.25rem;
      width: 2.25rem;
    }
  }

  &__cta {
    padding-top: 1.5rem;
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import {
  getExampleCompetitorStaffPick,
  getExampleCompetitorStaffPicks,
} from 'src/api/example_competitor_staff_picks';
import { queryKeys } from 'src/queries/query-keys';

/**
 *
 * @param {string | number} accountId
 * @param {UseQueryOptions} queryOptions
 */
export function useExampleCompetitorStaffPicks(accountId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.exampleCompetitorStaffPicks(accountId),
    queryFn: ({ signal }) =>
      getExampleCompetitorStaffPicks(unref(accountId), signal),
    ...queryOptions,
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} competitorId
 * @param {UseQueryOptions} queryOptions
 */
export function useExampleCompetitorStaffPick(
  accountId,
  competitorId,
  queryOptions = null
) {
  return useQuery({
    queryKey: queryKeys.exampleCompetitorStaffPick(accountId, competitorId),
    queryFn: ({ signal }) =>
      getExampleCompetitorStaffPick(
        unref(accountId),
        unref(competitorId),
        signal
      ),
    ...queryOptions,
  });
}

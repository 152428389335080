<script setup>
import { computed, watch, ref, provide, onUnmounted } from 'vue';
import {
  onKeyStroke,
  useMediaQuery,
  useTimeoutFn,
  useTitle,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import TopActionBarBase from '@/components/user/anytime/gallery/media-editor/action-bars/TopActionBarBase.vue';
import MediaAsset from 'src/components/user/anytime/gallery/media-editor/MediaAsset.vue';
import { TELEPORT_TO } from '@/composables/useTeleportTo';
import { truncate } from '@/lib/text';
import { useDialogContext } from '@/composables/useDialog';
import uniqueId from 'lodash/uniqueId';
import DownloadDigitalAsset from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAsset.vue';
import ActionBarBase from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarBase.vue';
import HideAssetButton from '@/components/user/anytime/gallery/media-editor/action-bars/staff-actions-bar/HideAssetButton.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useReservation } from '@/composables/useReservation';
import { storeToRefs } from 'pinia';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  isFileLoading: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['close']);

const asset = computed(() => props.asset);

const mediaEditorStore = useMediaEditorStore();
const { reservationId } = storeToRefs(mediaEditorStore);
const assetId = computed(() => asset.value?.id);
const assetAccountId = computed(() => asset.value?.account_id);

const { mediaUrl, title } = useMediaEditorDigitalAsset(assetAccountId, assetId);

const popoverRef = ref();
const id = uniqueId('media-editor-');

const { checkAllowEscapeClose } = useDialogContext({ id });

provide(TELEPORT_TO, popoverRef);

const { nameTruncated } = useReservation(reservationId);

const isAcceptedHiddenEdit = computed(
  () => asset.value?.edits_collection_digital_asset?.edit_status === 'accepted'
);

const { isPending: isClosing } = useTimeoutFn(
  e => {
    emits('close', e);
  },
  150,
  { immediate: false }
);

function closePopover(e) {
  emits('close', e);
}

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

useFocusTrap(popoverRef, { immediate: true });

const fileTitle = computed(() => truncate(title.value, 50, true));
const tabTitle = computed(() => `${fileTitle.value ?? 'AI studio'} | soona`);
useTitle(tabTitle);

const handleClose = () => {
  closePopover();
};

// lifecycle hooks
onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(popoverRef)) {
    handleClose();
  }
});

const { pageViewed } = useBaseEvents();

watch(
  assetId,
  () => {
    pageViewed();
    mediaEditorStore.setAssetId(assetId.value);
    mediaEditorStore.setAssetAccountId(assetAccountId.value);
  },
  { immediate: true }
);

onUnmounted(() => {
  mediaEditorStore.$reset();
});
</script>

<template>
  <Teleport to="body">
    <div
      ref="popoverRef"
      class="media-editor"
      :class="{
        'media-editor--closing': isClosing,
      }"
      role="dialog"
      aria-modal="true"
    >
      <TopActionBarBase :is-wide="matchMediaIsWide">
        {{ title }}
        <template #details-bottom>
          <SoonaButton
            size="medium"
            variation="tertiary"
            element="router-link"
            :to="`/reservation/${reservationId}`"
          >
            {{ nameTruncated }}
          </SoonaButton>
        </template>
        <template #actions>
          <DownloadDigitalAsset />
        </template>
      </TopActionBarBase>
      <div class="media-editor__editing-panel">
        <div class="media-editor__middle-column">
          <ActionBarBase v-if="isAcceptedHiddenEdit">
            <template #actions-right>
              <HideAssetButton />
            </template>
          </ActionBarBase>
          <slot name="error" />
          <div class="media-editor__img-wrapper">
            <MediaAsset
              v-if="asset"
              :is-file-loading="isFileLoading"
              :popover-ref="popoverRef"
              :src-url="mediaUrl"
            />
          </div>
        </div>
      </div>
      <slot name="carousel" />
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.media-editor {
  background-color: variables.$gray-10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  animation:
    0.25s ease-out both scale-up,
    0.15s ease-out both k-fade-in;

  @media (prefers-reduced-motion: reduce) {
    animation: 0.15s ease-out both k-fade-in;
  }

  &--closing {
    animation:
      0.15s ease-out both scale-down,
      0.15s ease-out both k-fade-out;

    @media (prefers-reduced-motion: reduce) {
      animation: 0.15s ease-out both k-fade-out;
    }
  }

  &__editing-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    @media (min-width: variables.$screen-md-min) {
      flex-direction: row;
    }
  }

  &__middle-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    transition: padding-right 0.3s cubic-bezier(0.22, 1, 0.36, 1);

    &--no-overflow {
      overflow-y: hidden;
    }
  }

  &__img-wrapper {
    align-items: center;
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    justify-items: center;
    min-height: 0;
    padding: 1rem 0 1rem 1rem;
    width: 100%;

    &--dense {
      gap: 0rem;
      padding: 0;
    }
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.95);
  }
}

@keyframes scale-down {
  to {
    transform: scale(0.95);
  }
}
</style>

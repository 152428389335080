<script setup>
import { computed, nextTick, ref, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SceneCard from './SceneCard.vue';

const props = defineProps({
  activeShot: {
    type: [Object, null],
    default: null,
  },
  selectedSceneId: {
    type: Number,
    required: true,
  },
  shotList: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['addScene', 'setSelectedScene']);

const isSelected = id => props.selectedSceneId === id;

const setSelectedSceneId = id => emit('setSelectedScene', id);

const addScene = () => emit('addScene');

const selectedSceneEl = ref(null);
watchEffect(async () => {
  await nextTick();
  selectedSceneEl.value = document.getElementById(
    `scene-card-${props.selectedSceneId}`
  );
});

const allScenesContainer = ref(null);
const selectedSceneIsVisible = computed(() => {
  if (selectedSceneEl.value) {
    const containerBounds = allScenesContainer.value?.getBoundingClientRect();
    const selectedSceneBounds = selectedSceneEl.value?.getBoundingClientRect();

    if (containerBounds && selectedSceneBounds) {
      return (
        selectedSceneBounds.left >= containerBounds.left &&
        selectedSceneBounds.right <= containerBounds.right
      );
    }
  }

  return null;
});

watchEffect(() => {
  if (selectedSceneIsVisible.value === false && selectedSceneEl.value) {
    allScenesContainer.value.scrollTo({
      left:
        selectedSceneEl.value.offsetLeft - allScenesContainer.value.offsetLeft,
      behavior: 'smooth',
    });
  }
});
</script>

<template>
  <div ref="allScenesContainer" class="scene-list">
    <SceneCard
      v-for="(shot, i) in shotList"
      :id="`scene-card-${shot.id}`"
      :key="shot.id"
      :shot="shot"
      :index="i"
      :is-selected="isSelected(shot.id)"
      :is-active="activeShot?.active_shot_id === shot.id"
      @click="setSelectedSceneId(shot.id)"
    />
    <div class="scene-list__btn_wrapper">
      <SoonaButton
        size="medium"
        variation="icon-gray-outline"
        title="create new scene"
        @on-click="addScene"
      >
        <SoonaIcon name="plus" />
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.scene-list {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  align-items: center;
  padding-bottom: 1rem;
  gap: 0.5rem;

  &__btn_wrapper {
    margin-left: 1.5rem;
  }
}
</style>

import { useMutation } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';

/**
 *
 */
export function useCreateFeedback() {
  return useMutation({
    mutationFn: async body => {
      const response = await http.post(`/feedbacks`, {
        feedback: body,
      });

      return response.data;
    },
  });
}

<script setup>
import { toRefs, computed, watch, onUnmounted } from 'vue';

import { useFlag } from '@/composables/useFlag';
import { useMokkerAITemplatesUrl } from '@/queries/mokker/useMokkerTemplateUrl';
import AIStudioIFrameContainer from '@/components/media-editor/AIStudioIFrameContainer.vue';
import { useMediaEditorStore } from '../user/anytime/gallery/media-editor/store/useMediaEditorStore';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  templateId: {
    type: String,
    required: true,
  },
});

const { accountId, templateId } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();
const { data: iframeUrlData } = useMokkerAITemplatesUrl(accountId, templateId);

const iframeUrl = computed(() => iframeUrlData.value?.result);

watch(
  iframeUrl,
  () => {
    if (iframeUrl.value) mediaEditorStore.setIframeUrl(iframeUrl.value);
  },
  { immediate: true }
);

const aiTemplatesFlag = useFlag('toaster_ai_templates');

const backUrl = computed(() => ({
  name: aiTemplatesFlag.value ? 'ai-studio-templates' : 'media-editor',
  params: {
    accountId: accountId.value,
  },
}));

const editorUrl = computed(() => ({
  name: 'all-assets-media-view',
  params: {
    accountId: accountId.value,
  },
}));

onUnmounted(() => {
  mediaEditorStore.$reset();
});
</script>

<template>
  <AIStudioIFrameContainer
    v-if="iframeUrl"
    :back-url="backUrl"
    :editor-url="editorUrl"
    :account-id="accountId"
  />
</template>

<style scoped lang="scss"></style>

import { computed } from 'vue';

export const useProductExternalIdentifiers = product => {
  const sku = computed(
    () =>
      product.value.external_identifiers.find(ei => {
        return ei.external_identifier_type.slug === 'sku';
      })?.value
  );

  const asin = computed(
    () =>
      product.value.external_identifiers.find(ei => {
        return ei.external_identifier_type.slug === 'asin';
      })?.value
  );

  return { sku, asin };
};

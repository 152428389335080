<template>
  <div class="soona-heading">
    <div class="soona-heading__title-wrapper">
      <component :is="titleElement" class="soona-heading__title">
        <slot />
      </component>
      <SoonaHelperText
        v-if="tooltipCopy"
        class="soona-heading__tooltip"
        :title="tooltipTitle"
        :no-tab-index="tooltipHasNoTabIndex"
      >
        {{ tooltipCopy }}
      </SoonaHelperText>
    </div>
    <p class="soona-heading__subtitle">
      <slot name="subtitle" />
    </p>
  </div>
</template>

<script>
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';

export default {
  components: {
    SoonaHelperText,
  },
  props: {
    titleElement: {
      default: 'h1',
      required: false,
      type: String,
      validator: function (value) {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value);
      },
    },
    tooltipCopy: {
      default: null,
      required: false,
      type: String,
    },
    tooltipTitle: {
      default: null,
      required: false,
      type: String,
    },
    tooltipHasNoTabIndex: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-heading {
  max-width: 43.75rem;
  margin-bottom: 1.5rem;
  text-align: center;

  @media (min-width: variables.$screen-md-min) {
    margin-bottom: 2rem;
  }

  &__title-wrapper {
    display: flex;
    justify-content: center;
  }

  &__title {
    @include variables_fonts.u-headline--heavy;

    color: variables.$black-default;

    @media (min-width: variables.$screen-md-min) {
      @include variables_fonts.u-display--heavy;
    }
  }

  &__tooltip {
    margin-left: 0.4375rem;
  }

  &__subtitle {
    @include variables_fonts.u-body--regular;

    color: variables.$black-default;
    padding-top: 0.25rem;

    a {
      color: variables.$periwink-blue-70;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>

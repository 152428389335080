<script setup>
import { computed, ref, toRefs } from 'vue';
import ListingActionTemplate from '../ListingActionTemplate.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import ComparisonViewer from '../ComparisonViewer.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePlatformName } from '../usePlatformName';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import LifestyleActionButtons from './LifestyleActionButtons.vue';
import { useLifestyleActions } from './useLifestyleActions';
import { useDeleteListingActionDigitalAssets } from '@/queries/useListingActions';
import { useRoute } from 'vue-router';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  listingAction: {
    type: Object,
    required: true,
  },
  externalProductId: {
    type: String,
    default: null,
  },
  includeSiblingActions: {
    type: Boolean,
    default: false,
  },
  integrationId: {
    type: String,
    default: null,
  },
  isProcessing: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['showSubscriptionsDialog']);

const { accountId, listingAction, externalProductId, integrationId } =
  toRefs(props);

const { buttonClicked } = useBaseEvents();

const { platform } = usePlatformName(listingAction);

const route = useRoute();
const subContext = computed(() =>
  route.name === 'listing-optimize'
    ? 'listing detail page'
    : 'create lifestyle content'
);

const hasReplacementAsset = computed(() => {
  return listingAction.value.replacement_asset_preview_url;
});

// loading state module
const isOptimizing = ref(false);

// comparison viewer module
const showComparison = ref(false);

const {
  canTakeAction,
  availableCreditsError,
  userCanUpgrade,
  linkSourceAsset,
  isProcessing: lifestyleIsProcessing,
  handleUploadComplete,
  createDigitalAssetError,
  createListingActionDigitalAssetError,
  updateListingActionError,
  resetListingAction,
  requiresManualImagePositionSelection,
  createPublishTaskError,
  isPublishing,
  publish,
  downloadReplacementAsset,
} = useLifestyleActions(accountId, listingAction, {
  externalProductId,
  integrationId,
});

const isActionProcessing = computed(() => {
  return props.isProcessing || lifestyleIsProcessing.value;
});

const captureSelectSourceImageEvent = () => {
  if (canTakeAction.value) {
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'create with AI',
      buttonAction: 'select source image',
    });
  } else {
    emit('showSubscriptionsDialog');
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'create with AI',
      buttonAction: 'upgrade',
    });
  }
};

const handleLinkSourceAsset = digitalAssetId => {
  linkSourceAsset(digitalAssetId);
  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel: 'continue',
    buttonAction: 'open AI studio',
  });
};

// undo changes module
const {
  mutate: deleteListingDigitalAssets,
  error: deleteListingDigitalAssetsError,
} = useDeleteListingActionDigitalAssets(accountId, {
  externalProductId,
  integrationId,
});

const undoChanges = () => {
  deleteListingDigitalAssets({
    listing_action_id: listingAction.value.id,
    include_siblings: true,
  });
  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel: 'start over',
    buttonAction: 'undo changes',
  });
};

// publish module
const handlePublish = () => {
  if (requiresManualImagePositionSelection.value) {
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel:
        listingAction.value === 'failed_to_publish'
          ? 'retry'
          : `publish to ${platform.value}`,
      buttonAction: 'open amazon publish image selector dialog',
    });
    return;
  } else {
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel:
        listingAction.value === 'failed_to_publish'
          ? 'retry'
          : `publish to ${platform.value}`,
      buttonAction: 'publish',
    });
    publish();
  }
};

const publishFromAmazonImageSelectorDialog = manuallySelectedSlot => {
  publish(manuallySelectedSlot);
  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel: `publish to ${platform.value}`,
    buttonAction: 'publish',
  });
};

// v-viewer in child
const listingActionTemplateRef = ref();

const viewUpdatedImage = () => {
  if (listingAction.value.source_asset_id) {
    showComparison.value = true;
  } else {
    listingActionTemplateRef.value.openVViewer();
  }
};

const handleUpgradeClick = () => {
  if (userCanUpgrade.value) {
    emit('showSubscriptionsDialog', 'tier-two');
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'upgrade for more',
      buttonAction: 'upgrade to standard',
    });
  } else {
    window.location.href =
      "mailto:hey@soona.co?subject=Upgrade Subscription&body=Hi, I'd like to upgrade my subscription!";
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'upgrade for more',
      buttonAction: 'send email to customer service',
    });
  }
};

const handleResetListingAction = () => {
  resetListingAction();
  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel: 'cancel',
    buttonAction: 'reset listing action',
  });
};

const handleDownload = () => {
  downloadReplacementAsset();
  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel: 'download',
    buttonAction: 'download replacement asset',
  });
};

// error handling
const priorityError = usePriorityErrors(
  createListingActionDigitalAssetError,
  createDigitalAssetError,
  createPublishTaskError,
  availableCreditsError,
  deleteListingDigitalAssetsError,
  updateListingActionError
);
</script>

<template>
  <ListingActionTemplate
    ref="listingActionTemplateRef"
    :is-optimizing="isOptimizing"
    :is-publishing="isPublishing"
    :is-processing="isActionProcessing"
    :listing-action="listingAction"
    @open-comparison-viewer="showComparison = true"
  >
    <template #error>
      <SoonaError
        v-if="priorityError"
        :priority-errors="priorityError"
        no-margin
      />
    </template>
    <template v-if="hasReplacementAsset" #listing-rule>
      <div class="listing-action-rule">
        <SoonaIcon name="image-square" />
        <div class="listing-action-rule__text">
          <div>
            <div class="listing-action-rule__text--replacement u-body--heavy">
              {{
                !!externalProductId ? 'new lifestyle image' : 'updated image'
              }}
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @click="viewUpdatedImage"
              >
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </div>
            <div
              v-if="listingAction.source_asset_id"
              class="listing-action-rule__text--original"
            >
              source image
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @click="showComparison = true"
              >
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template
      v-if="
        hasReplacementAsset &&
        !listingAction.replacement_asset.is_lifestyle_image
      "
      #additional-content
    >
      <SoonaAlert class="listing-action-template__alert" no-margin>
        our robots don’t think this is a lifestyle photo. you can still publish
        it, but we can’t guarantee it’ll fix the problem!
      </SoonaAlert>
    </template>
    <template #actions>
      <LifestyleActionButtons
        :account-id="accountId"
        :listing-action="listingAction"
        @capture-select-source-image-event="captureSelectSourceImageEvent"
        @handle-upgrade-click="handleUpgradeClick"
        @handle-link-source-asset="handleLinkSourceAsset($event)"
        @handle-upload-complete="handleUploadComplete($event)"
        @handle-reset-listing-action="handleResetListingAction"
        @handle-publish="handlePublish"
        @handle-publish-from-amazon-image-selector-dialog="
          publishFromAmazonImageSelectorDialog
        "
        @handle-download="handleDownload"
        @undo-changes="undoChanges"
      />
    </template>
  </ListingActionTemplate>
  <ComparisonViewer
    v-if="showComparison"
    :listing-action-slug="listingAction.listing_action_slug"
    :original-asset="listingAction.source_asset"
    :replacement-asset="listingAction.replacement_asset"
    @close="showComparison = false"
  />
</template>

<style lang="scss" scoped>
@use 'src/variables';

.listing-action-rule {
  display: flex;
  gap: 0.5rem;

  &__text {
    &--original {
      color: variables.$gray-50;
    }
  }
}
</style>

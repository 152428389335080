import { useMutation } from '@tanstack/vue-query';
import { getAccount } from '@/api/accounts';
import { useStore } from 'vuex';
import * as Sentry from '@sentry/vue';

/**
 */
export function useLoadAccount() {
  const store = useStore();

  return useMutation({
    mutationFn: async ({ accountId }) => {
      store.commit('account/SET_DESIRED_ACCOUNT_ID', accountId);
      return await getAccount(accountId);
    },
    onSuccess: async response => {
      /*
       * multiple load accounts may be in flight at the same time, and
       * response order is not guaranteed to match call order, so we can
       * verify and only set the account if the response account is the same
       * as the most recent action to load an account
       *
       * without this check, for example, if the first call to load an
       * account takes 3 seconds, but the second call to load a different
       * account takes 2 second, the app will have the second account loaded,
       * but then 2 seconds later be changed to the first account (not what
       * we want!)
       */
      if (store.state.account.__desiredAccountId === response.id) {
        store.commit('account/SET_ACCOUNT', response);
      }
    },
    onError: error =>
      Sentry.captureException(new Error('failed to load account'), {
        extra: { error },
      }),
  });
}

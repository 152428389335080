<template>
  <div
    v-if="!isSurprise && currentReservation.location"
    class="date-location drop-shadow-container"
  >
    <div class="date-location__item">
      <div v-if="currentReservation.start != null" class="date-info mb-l">
        <p class="text-xl">
          <strong>
            {{ convertToMMDDYYYYlong(currentReservation.start) }}
          </strong>
          <strong style="white-space: nowrap"
            >@ {{ convertToHHMM12(currentReservation.start) }}</strong
          >
        </p>
        <div class="date-location__calendar-btn-container">
          <SoonaButton
            class="date-location__calendar-btn"
            element="a"
            :href="`/reservations/${currentReservation.id}/download.ics`"
            size="medium"
            variation="secondary-gray"
          >
            <SoonaIcon name="calendar" size="medium" />
            add to calendar
          </SoonaButton>
          <SoonaButton
            class="date-location__calendar-btn"
            element="router-link"
            :to="`/reschedule/${currentReservation.id}`"
            size="medium"
            variation="secondary-gray"
          >
            reschedule
          </SoonaButton>
        </div>
        <p v-if="isReschedulable" class="text-s mt-2">
          you have until
          {{ convertToMMDDYYYYlong(rescheduleDeadline) }} @
          {{ convertToHHMM12(rescheduleDeadline) }} to change your date
        </p>
      </div>
      <div v-else-if="!hidePii">
        <p class="text-xl">
          <strong
            >keep an eye out for an email with your shoot date and time.</strong
          >
        </p>
      </div>
      <div v-else>
        <p class="text-xl">
          <strong>date TBD</strong>
        </p>
      </div>
      <div
        v-if="
          !currentReservation.override_location_name ||
          currentReservation.override_location_name.length == 0
        "
        class="studio-info"
      >
        <div v-if="isAnytime && !hidePii">
          <p class="text-s mb-s"><strong>ship to:</strong></p>
        </div>
        <div
          v-else-if="
            currentReservation.booking_flow !== 'headshots_for_all_event'
          "
        >
          <p class="date-location__location-name">
            soona {{ currentReservation.location.city }}
            <SoonaIcon name="location-pin" size="medium" />
          </p>
        </div>
        <div
          v-if="currentReservation.booking_flow !== 'headshots_for_all_event'"
        >
          <p>soona REF: {{ currentReservation.id }}</p>
          <p>
            {{ currentReservation.location.address1 }}
            {{ currentReservation.location.address2 }}
          </p>
          <p>
            {{ currentReservation.location.city }},
            {{ currentReservation.location.state }}
            {{ currentReservation.location.zip }}
          </p>
        </div>
      </div>
      <div v-else>
        <div>
          <p class="date-location__location-name">
            {{ currentReservation.override_location_name }}
            <SoonaIcon name="location-pin" size="medium" />
          </p>
        </div>
        <p>
          {{ currentReservation.override_location_address }}
        </p>
      </div>
    </div>
    <div
      v-if="
        !currentReservation.override_location_name ||
        currentReservation.override_location_name.length == 0
      "
      class="date-location__map-item"
    >
      <div class="date-location__contact-container">
        <SoonaButton
          class="date-location__contact-btn"
          element="a"
          :href="`mailto: ${currentReservation.location.email}`"
          size="medium"
          variation="secondary-gray"
        >
          <SoonaIcon name="mail" size="medium" />
          contact crew
        </SoonaButton>
        <SoonaButton
          class="date-location__contact-btn"
          element="a"
          :href="`https://www.google.com/maps/dir/?api=1&destination=${mapsAddress}`"
          size="medium"
          variation="secondary-gray"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SoonaIcon name="diamond-turn-right" size="medium" />
          get directions
        </SoonaButton>
      </div>
      <div class="date-location__map-container">
        <iframe
          class="map"
          frameborder="0"
          style="border: 0; width: 100%"
          :src="`https://www.google.com/maps/embed/v1/place?key=${mapsKey}&q=place_id:${currentReservation.location.google_maps_place_id}`"
          allowfullscreen
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import dateTimeMixin from 'src/mixins/dateTimeMixin';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'DateLocation',
  components: {
    SoonaButton,
    SoonaIcon,
  },
  mixins: [dateTimeMixin],
  props: {
    isPhoto: Boolean,
    isVideo: Boolean,
    currentReservation: Object,
    hidePii: Boolean,
  },
  data() {
    return {
      mapsAddress: '',
      mapsKey: import.meta.env.VITE_GOOGLE_MAPS_EMBED_API,
    };
  },
  computed: {
    ...mapState({
      currentUserId: state => state.currentUser.userID,
    }),
    ...mapGetters('reservation', [
      'isReschedulable',
      'isSurprise',
      'rescheduleDeadline',
    ]),
    isPendingShotlist: function () {
      return this.currentReservation
        ? this.currentReservation.status === 'pending_shotlist'
        : false;
    },
    isPreshoot: function () {
      return this.currentReservation
        ? this.currentReservation.status === 'preshoot'
        : false;
    },
    isShootDay: function () {
      return this.currentReservation.status === 'in_progress';
    },
    isOrder: function () {
      return this.currentReservation.status === 'pending_selects';
    },
    isEditing: function () {
      return this.currentReservation.status === 'edits_required';
    },
    isCompleted: function () {
      return this.currentReservation.status === 'completed';
    },
    isAnytime() {
      return this.currentReservation.reservation_type === 'anytime';
    },
  },
  mounted: function () {
    const address1 = this.currentReservation.location.address1.replace(
      /\s/g,
      '+'
    );
    const address2 = this.currentReservation.location.address2?.replace(
      /\s/g,
      '+'
    );
    const city = this.currentReservation.location.city.replace(/\s/g, '+');
    const state = this.currentReservation.location.state.replace(/\s/g, '+');

    this.mapsAddress = `${address1}${
      address2 ? `+${address2}` : null
    }+${city}+${state}`;
  },
  created() {
    if (!this.$route.path === '/') {
      this.loadReservation(this.currentReservation.id);
    }
  },
  methods: {
    ...mapActions('reservation', ['loadReservation']),
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.date-location {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  gap: 1rem;
  text-align: left;

  &__calendar-btn-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1.25rem;
  }

  &__location-name {
    @include variables_fonts.u-body--heavy;

    align-items: center;
    display: flex;
    gap: 0.25rem;

    svg {
      color: variables.$friendly-red-50;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    @media (min-width: variables.$screen-sm-min) {
      margin-bottom: 0;
    }
  }

  &__map-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__contact-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: max-content;
  }

  &__contact-btn {
    background: variables.$white-default;
    color: variables.$periwink-blue-50;
    box-shadow: variables.$elevation-2;
    border-radius: 0.25rem;
    border: none;
    margin: 0.5rem;

    @media (min-width: variables.$screen-sm-min) {
      margin: 0.25rem 1rem;
    }
  }

  &__map-container {
    margin: 0.5rem;

    @media (min-width: variables.$screen-sm-min) {
      margin: 0;
    }
  }

  .map {
    height: 100%;
  }
}

.text-xl {
  font-size: 1.5rem;

  strong {
    color: variables.$black-default;
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getActionItems } from '@/api/reservations';
import { queryKeys } from '@/queries/query-keys';

/**
 *
 * @param {Object} [params]
 * @param {Ref<string | number>} [params.accountId]
 * @param {Ref<string>} [params.timezone]
 * @param {UseQueryOptions} [queryOptions]
 */
export function useActionItems({ accountId, timezone }, queryOptions) {
  return useQuery({
    queryKey: queryKeys.actionItems(accountId, timezone),
    queryFn: ({ signal }) =>
      getActionItems(
        {
          accountId: unref(accountId),
          timezone: unref(timezone),
        },
        signal
      ),
    ...queryOptions,
  });
}

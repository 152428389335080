<template>
  <div
    data-cypress="style-quiz-card"
    :class="{
      'your-style': !isEditView,
      'drop-shadow-container': true,
      'style-quiz-card--edit-view': isEditView,
    }"
  >
    <div v-if="accountStyle">
      <h3
        :class="{
          'u-small--heavy': showStyleQuiz,
          'u-title--heavy': !showStyleQuiz,
        }"
      >
        {{ title }}
      </h3>
      <div :class="{ 'is-flex items-center justify-start': !showStyleQuiz }">
        <div v-if="!showStyleQuiz" class="user-style-icon">
          <div class="user-style-icon-background">
            <p class="u-display--regular">{{ accountStyle.icon }}</p>
          </div>
        </div>
        <div>
          <h3 class="text-m has-text-weight-semibold mt-s mb-s is-flex">
            <p v-if="showStyleQuiz">{{ accountStyle.icon }}&nbsp;&nbsp;</p>
            {{ accountStyle.title }}
          </h3>
          <p class="text-s mt-s mb-s">
            {{ accountStyle.description }}
          </p>
        </div>
      </div>
      <div class="style-actions">
        <router-link
          v-if="accountStyle.id"
          :to="{
            name: 'style-quiz-style',
            params: { quizStyleId: accountStyle.id },
          }"
          class="read-more-btn u-small--regular"
          data-cypress="link-quiz-read-more"
        >
          read more
        </router-link>
      </div>
    </div>
    <div v-else class="no-style">
      <SoonaButton
        element="router-link"
        class="quiz-btn"
        :to="{ name: 'style-quiz' }"
        data-cypress="link-style-quiz"
      >
        find your soona style
      </SoonaButton>
    </div>
  </div>
</template>

<script>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

export default {
  name: 'StyleQuizResults',
  components: { SoonaButton },
  props: {
    title: String,
    accountStyle: Object,
    showStyleQuiz: Boolean,
    isEditView: Boolean,
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';

.style-quiz-card {
  &--edit-view {
    align-items: center;
    background-color: variables.$white-default;
    border-radius: 0.75rem;
    box-shadow: variables.$elevation-1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
  }
}

.justify-start {
  justify-content: flex-start;
}

.style-actions {
  display: flex;
  justify-content: flex-end;
}

.no-style {
  align-items: center;
  display: flex;

  :deep(.quiz-btn) {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }
}

.read-more-btn {
  color: variables.$periwink-blue-50;
  margin-top: 0.25rem;
  text-decoration: underline;
}

.your-style {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 1rem;
}

.user-style-icon-background {
  align-items: center;
  background-color: variables.$tangerine-20;
  border-radius: 50%;
  display: flex;
  height: 5.3125rem;
  justify-content: center;
  margin-right: 1.5rem;
  text-align: center;
  width: 5.3125rem;
}
</style>

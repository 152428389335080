import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param { ref } accountId
 * @param { ref } collectionId
 * @param { ref } reservationId
 * @returns UseMutationReturnType<*>
 */
export function useAddCollectionAssetsToBag(
  accountId,
  collectionId,
  reservationId
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.post(
        `/accounts/${toValue(accountId)}/bag_collections/add_collection`,
        {
          collection_id: toValue(collectionId),
          reservation_id: toValue(reservationId),
        }
      );
      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collectionsDA(accountId, 'bag_collection'),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.bagCollection(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationDigitalAssets(reservationId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.unBaggedTotal(
            accountId,
            collectionId,
            reservationId
          ),
        }),
      ]);
    },
  });
}

<script setup>
import { ref, computed } from 'vue';
import { useFloating, autoUpdate, offset, shift, flip } from '@floating-ui/vue';
import uniqueId from 'lodash/uniqueId';

const props = defineProps({
  placement: {
    default: 'bottom',
    type: String,
  },
});

const placement = computed(() => props.placement);

const id = uniqueId('soona-tooltip-');
const triggerRef = ref(null);
const tooltip = ref(null);
const isShowing = ref(false);

const { floatingStyles, middlewareData } = useFloating(triggerRef, tooltip, {
  whileElementsMounted: autoUpdate,
  middleware: [offset(4), shift(), flip()],
  placement: placement,
});

const setRef = el => (triggerRef.value = el);

const showTooltip = () => (isShowing.value = true);
const hideTooltip = () => (isShowing.value = false);
</script>

<template>
  <slot
    :set-ref="setRef"
    name="default"
    :mouseenter="showTooltip"
    :focus="showTooltip"
    :mouseleave="hideTooltip"
    :blur="hideTooltip"
    :aria-describedby="id"
  />
  <Transition v-if="$slots['tooltip-content']" name="tooltip-transition">
    <div
      v-if="isShowing"
      :id="id"
      ref="tooltip"
      class="soona-tooltip"
      :style="floatingStyles"
      role="tooltip"
    >
      <div
        class="u-label--regular soona-tooltip__content"
        :class="[
          `soona-tooltip__content--${placement}`,
          { 'soona-tooltip__content--flipped': middlewareData.flip?.index },
        ]"
      >
        <slot name="tooltip-content" />
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-tooltip {
  width: max-content;
  max-width: 12.75rem;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  pointer-events: none;

  &__content {
    border-radius: 0.3125rem;
    background-color: variables.$black-default;
    color: variables.$white-default;
    padding: 0.375rem 0.5rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .tooltip-transition-enter-active,
  .tooltip-transition-leave-active {
    transition: opacity 0.15s ease-out 0.01s;

    .soona-tooltip__content {
      transition: transform 0.15s cubic-bezier(0.25, 1, 0.5, 1) 0.01s;
    }
  }

  .tooltip-transition-enter-from,
  .tooltip-transition-leave-to {
    opacity: 0;

    .soona-tooltip__content {
      &--bottom,
      &--top.soona-tooltip__content--flipped {
        transform: translateY(-0.25rem);
      }

      &--top,
      &--bottom.soona-tooltip__content--flipped {
        transform: translateY(0.25rem);
      }

      &--right,
      &--left.soona-tooltip__content--flipped {
        transform: translateX(-0.25rem);
      }

      &--left,
      &--right.soona-tooltip__content--flipped {
        transform: translateY(0.25rem);
      }
    }
  }
}
</style>

function getStepProgressPercent({ stepName, reservation }) {
  if (!reservation?.steps) {
    return 1;
  }

  // we're doing percent math, so 1 is the lowest, not 0 like an index
  const currentStep =
    (reservation.steps.findIndex(step => step.slug === stepName) ?? 0) + 1;
  // subtract 1 so that the bar is full before the final step
  const totalSteps = Math.max(1, reservation.steps.length - 1);

  const percent = Math.max((currentStep / totalSteps) * 100, 1);

  // using toFixed to limit the result to two decimal places
  return +percent.toFixed(2);
}

export function nextIncompleteStep({ reservation }) {
  if (!reservation?.steps) {
    return null;
  }

  return reservation.steps.find(step => !step.complete);
}

function nextStep({ currentStepName, reservation }) {
  if (!reservation?.steps) {
    return null;
  }

  const currentStepIndex = reservation.steps.findIndex(
    step => step.slug === currentStepName
  );

  if (
    currentStepIndex >= 0 &&
    currentStepIndex < reservation.steps.length - 1
  ) {
    return reservation.steps[currentStepIndex + 1];
  } else {
    return nextIncompleteStep({ reservation });
  }
}

function previousStep({ currentStepName, reservation }) {
  if (!reservation?.steps) {
    return null;
  }

  const currentStepIndex = reservation.steps.findIndex(
    step => step.slug === currentStepName
  );

  if (currentStepIndex >= 1) {
    return reservation.steps[currentStepIndex - 1];
  } else if (currentStepIndex === 0) {
    return { slug: '/' };
  } else {
    return null;
  }
}

function nextButtonText({ currentStepName, reservation }) {
  const next = nextStep({ currentStepName, reservation });

  return next?.button_text;
}

function backButtonText({ currentStepName, reservation }) {
  const previous = previousStep({ currentStepName, reservation });

  return previous?.button_text;
}

function requiresAuth({ stepName, reservation }) {
  if (!reservation?.steps) {
    return false;
  }

  return reservation.steps.find(step => step.slug === stepName)
    ?.requires_authentication;
}

export {
  requiresAuth,
  nextButtonText,
  backButtonText,
  getStepProgressPercent,
  nextStep,
  previousStep,
};

<script setup>
import { storeToRefs } from 'pinia';
import { nextTick, watch } from 'vue';

import LoadingLogo from '../LoadingLogo.vue';
import { useAssistantStore } from '../composables/useAssistantStore';

const store = useAssistantStore();
const { messages, isWaitingForInitialResponse } = storeToRefs(store);

watch(messages, () => {
  nextTick(() => {
    store.scrollToBottom();
  });
});
watch(isWaitingForInitialResponse, () => {
  nextTick(() => {
    store.scrollToBottom();
  });
});
</script>

<template>
  <div class="feed">
    <slot :messages="messages" />

    <div class="feed__message">
      <LoadingLogo v-if="isWaitingForInitialResponse" is-loading />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.feed {
  flex: 1;
  left: 0;
  right: 0;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem 10rem 1rem;

  @media (min-width: variables.$screen-xs-min) {
    gap: 1rem;
    padding: 2rem 1rem 10rem 1rem;
  }

  @media (min-width: variables.$screen-sm-min) {
    gap: 1rem;
    padding: 2rem 1rem 16rem 1rem;
  }

  @media (min-width: variables.$screen-lg-min) {
    padding: 10rem 0 16rem 0;
  }
}
</style>

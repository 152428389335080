import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import applyDigitalAssetFilters from '@/queries/digital_assets/applyDigitalAssetFilters';

/**
 *
 * @param {Object} [params]
 * @param {string | Ref<string>} [accountId]
 * @param {string | Ref<string>} [assetId]
 * @param {Object | Ref<string>} [params]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useDigitalAsset({ accountId, assetId, params }, queryOptions) {
  return useQuery({
    queryKey: queryKeys.digitalAsset(accountId, assetId, params),
    queryFn: async ({ signal }) => {
      const paramArgs = {};
      applyDigitalAssetFilters(paramArgs, toValue(params));

      const response = await http.get(
        `/accounts/${toValue(accountId)}/digital_assets/${toValue(assetId)}`,
        {
          params: paramArgs,
          signal,
        }
      );
      return response.data;
    },
    ...queryOptions,
  });
}

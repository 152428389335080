<script setup>
import { computed, ref } from 'vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCreateNote } from '@/queries/notes/useCreateNote';
import { useDeleteNote } from '@/queries/notes/useDeleteNote';
import { useNotes } from '@/queries/notes/useNotes';
import { convertToDateLong } from '@/lib/date-formatters';

const props = defineProps({
  reservationId: String,
  slug: String,
});

const reservationId = computed(() => props.reservationId);
const {
  data: reservationNotes,
  isLoading,
  error,
} = useNotes('reservations', reservationId);
const {
  mutate: createNote,
  error: createError,
  isPending: isCreating,
} = useCreateNote('reservations', reservationId);
const {
  mutate: deleteNote,
  error: deleteError,
  isPending: isDeleting,
} = useDeleteNote('reservations', reservationId);

const newNote = ref(undefined);

const notes = computed(() => reservationNotes.value?.notes ?? []);

const isDirty = computed(() => newNote.value);

const priorityError = usePriorityError(error, createError, deleteError);

const isBusy = computed(
  () => isLoading.value || isCreating.value || isDeleting.value
);

function save() {
  createNote(
    {
      content: newNote.value,
    },
    { onSuccess: () => (newNote.value = undefined) }
  );
}
</script>

<template>
  <div v-for="note in notes" :key="note.id" class="crew-note">
    <div class="crew-note__meta">
      <time class="crew-note__date" :datetime="note.created_at">
        {{ convertToDateLong(note.created_at) }}
      </time>
      <SoonaButton
        variation="icon-plain-gray"
        size="small"
        title="delete note"
        @on-click="deleteNote({ noteId: note.id })"
      >
        <SoonaIcon name="trash" />
      </SoonaButton>
    </div>
    <div class="crew-note__note">
      {{ note.content }}
    </div>
  </div>
  <SoonaTextfield
    v-model="newNote"
    label="add a note"
    name="note"
    placeholder="note details..."
    element="textarea"
    :disabled="isBusy"
    data-cypress="extra-crew-note"
  />

  <SoonaAlert v-if="isDirty" class="crew-notes-alert">
    you have unsaved changes. be sure to save to prevent them from being
    overwritten.
  </SoonaAlert>

  <SoonaButton
    variation="primary"
    size="medium"
    :disabled="isBusy || !isDirty"
    @click.prevent="save"
  >
    add a note
  </SoonaButton>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.crew-note {
  margin-bottom: 1rem;

  &__meta {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
    color: variables.$gray-60;
  }

  &__date {
    @include variables_fonts.u-label--regular;
  }
}

.crew-notes-alert {
  margin: 0 0 1rem;
}
</style>

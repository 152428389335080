<script setup>
import { storeToRefs } from 'pinia';
import { computed, toRefs } from 'vue';

import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { usePackConfiguration } from '@/queries/pack-configurations/usePackConfiguration';

import { Tangerine20, AvoToast20 } from 'src/variables.module.scss';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useAiProducerStore } from '../../Composables/useAiProducerStore';

const props = defineProps({
  packId: {
    type: [Number, String],
    required: true,
  },
});

const { packId } = toRefs(props);
const { linkClicked } = useBaseEvents();

const aiProducerStore = useAiProducerStore();
const { messages } = storeToRefs(aiProducerStore);

const {
  // todo
  //   error,
  //   isLoading,
  data: pack,
} = usePackConfiguration({
  packId,
});

const price = computed(() => pack.value?.pack_price ?? '$');

const description = computed(
  () => pack.value?.description?.body ?? ['[description paragraph]']
);

const includes = computed(
  () => pack.value?.description?.includes ?? ['[includes]']
);

const packHref = computed(() => `/book/pack/${packId.value}`);

function bookPack() {
  linkClicked({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    linkLabel: `book pack ${packId.value} with ${messages.value.length} messages`,
    linkHref: packHref.value,
  });
}
</script>

<template>
  <article v-if="pack" class="feed-pack">
    <ul
      class="feed-pack__image-list"
      :aria-title="`${pack.name} example photos`"
    >
      <li v-for="(img, index) in pack.reference_images" :key="index">
        <video
          v-if="img.image_content_type?.startsWith('video/')"
          loop
          muted
          autoplay
          playsinline
        >
          <source :src="img.image_url" :type="img.image_content_type" />
        </video>
        <img v-else :src="img.image_url" :alt="`${pack.name} example photo`" />
      </li>
    </ul>

    <section class="feed-pack__details">
      <div class="feed-pack__header">
        <h2 class="u-headline--heavy">{{ price }}</h2>

        <SoonaFlag
          type="pill"
          title="amazon"
          font-size="0.75rem"
          :background-color="Tangerine20"
        />
        <SoonaFlag
          type="pill"
          title="photo"
          font-size="0.75rem"
          :background-color="AvoToast20"
        />
      </div>

      <h3 class="u-subheader--heavy">{{ pack.name }}</h3>

      <p v-for="(paragraph, index) in description" :key="index">
        {{ paragraph }}
      </p>

      <h4 class="u-badge--small">includes:</h4>
      <ul class="feed-pack__includes-list u-label--regular">
        <li v-for="(detail, index) in includes" :key="index">
          {{ detail }}
        </li>
      </ul>
    </section>

    <footer>
      <SoonaButton
        element="a"
        size="medium"
        variation="solid-black"
        class="feed-pack__action"
        :href="packHref"
        @click="bookPack"
      >
        book this pack
        <SoonaIcon name="circle-plus-solid" size="small" />
      </SoonaButton>
    </footer>
  </article>
</template>

<style scoped lang="scss">
@use '@/variables';

.feed-pack {
  gap: 1rem;
  display: flex;
  border-radius: 0.5rem;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
  background: variables.$white-default;
  border: 0.0625rem solid variables.$periwink-blue-30;

  &__image-list {
    margin: 0;
    padding: 0;
    gap: 0.5rem;
    display: flex;
    list-style: none;
    overflow-x: auto;

    li {
      width: 160px;
      display: flex;
      min-width: 160px;
      aspect-ratio: 1 / 1;
      align-items: center;
      border-radius: 0.5rem;
      justify-content: center;
      border: 1px solid variables.$gray-30;

      img {
        width: 100%;
        border-radius: 0.5rem;
      }
    }
  }

  &__details {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  &__header {
    gap: 0.5rem;
    display: flex;
  }

  h2 {
    flex: 1;
    color: variables.$avo-toast-50;
  }

  &__includes-list {
    padding-left: 1rem;
    list-style-type: disc;
  }

  footer {
    a {
      width: 100%;
    }
  }
}
</style>

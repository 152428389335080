import * as Sentry from '@sentry/vue';
import * as types from 'src/store/mutation-types';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from 'src/queries/query-client';
import { queryKeys } from 'src/queries/query-keys';

const addErrorText = (errorCode, errors = []) => {
  let errorText;

  switch (errorCode) {
    case 'card_declined':
      errorText = 'Your card was declined.';
      break;
    case 'expired_card':
      errorText = 'Your card has expired.';
      break;
    case 'incorrect_cvc':
      errorText =
        'We could not verify your CVC. Please check your security code and try again.';
      break;
    case 'processing_error':
      errorText =
        'We could not process your card, if this continues to happen please give us a call.';
      break;
    case 'card_error':
      errorText = 'There was an error processing your payment.';
      break;
    case 'order_status_not_created':
      errorText = 'There was an error confirming your order.';
      break;
    case 'order_status_not_confirmed':
      errorText = 'The reservation could not be cancelled.';
      break;
    case 'validation_failed':
    case 'invalid_stripe_request':
      errorText = 'Unknown Error';
      errors.forEach(error => {
        console.error('error:', error);
      });
      break;
    default:
      errorText = 'Your card was declined.';
  }
  return errorText;
};

const state = {
  order: {},
  add_on_order: {},
  processing: false,
  errorText: undefined,
};

const getters = {};

const mutations = {
  [types.SET_ERRORS](state, errorText) {
    state.errorText = errorText;
  },
  [types.RESET_ERRORS](state) {
    state.errorText = '';
  },
  [types.RESET_ORDER](state) {
    state.order = {};
  },
  [types.SET_ORDER](state, order) {
    state.order = order;
  },
  [types.SET_ADD_ON_ORDER](state, add_on_order) {
    state.add_on_order = add_on_order;
  },
  [types.ORDER_FAILED](state) {
    state.processing = false;
  },
};
const actions = {
  createAddOnOrders({ commit, rootState }) {
    return this.http
      .post(`accounts/${rootState.currentUser.accountId}/create_orders.json`)
      .then(response => {
        commit(types.SET_ADD_ON_ORDER, response.data);
      });
  },
  createShopifyOrders({ rootState }) {
    return this.http
      .post(`accounts/${rootState.currentUser.accountId}/create_orders.json`)
      .then(response => {
        window.top.location.href = response.data.confirmation_url;
      });
  },
  resetErrors({ commit }) {
    commit(types.RESET_ERRORS);
  },
  async payAddOnOrders(
    { commit, rootState },
    { stripe, card, saveCard, activePromo = '' }
  ) {
    commit(types.RESET_ERRORS);
    let result;
    if (card) {
      result = await stripe.createToken(card);
    }
    if (result?.error) {
      Sentry.captureException(
        new Error(`stripe token error: ${result.error.message}`),
        { extra: { result } }
      );
      throw result.error;
    }

    return this.http
      .put(`accounts/${rootState.currentUser.accountId}/pay_orders.json`, {
        token: result ? result.token.id : null,
        save_card: saveCard,
        payment_method_attributes: {
          payment_method_id: null,
          payment_method_type: null,
          save_payment_method: null,
          ...(activePromo && { [activePromo]: true }),
        },
      })
      .then(
        () => {
          queryClient.invalidateQueries({ queryKey: queryKeys.bag() });
        },
        error => {
          Sentry.captureException(
            new Error(error.message || 'failed to process order'),
            { extra: { error } }
          );
          let errorText = addErrorText(
            error.response.data.code,
            error.response.data
          );
          if (error.response.status === 500) {
            errorText = 'unknown_error';
          }
          commit(types.SET_ERRORS, errorText);
          commit(types.ORDER_FAILED, error.response);
          throw new Error(errorText);
        }
      );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ResultsLoader from '@/components/quiz/ResultsLoader.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import StyleQuizLayout from '@/components/quiz/StyleQuizLayout.vue';

const router = useRouter();
const store = useStore();

const showLoader = ref(false);

const allQuizWords = computed(() => store.state.styleQuiz.allQuizWords);
const currentQuiz = computed(() => store.state.styleQuiz.currentQuiz);
const currentUser = computed(() => store.state.currentUser);

const hasWordSelected = computed(
  () => store.state.styleQuiz.currentQuiz.words.length > 0
);

const isAuthenticated = computed(
  () => store.getters['currentUser/isAuthenticated']
);

const quizWords = (columns, column) =>
  allQuizWords.value?.filter((val, i) => i % columns === column);

const toggleWord = word => {
  store.dispatch('styleQuiz/toggleQuizWord', word);
};

const finishQuiz = async () => {
  await store.dispatch(
    'styleQuiz/createQuizResult',
    currentUser.value.accountId
  );

  showLoader.value = true;
};
</script>
<template>
  <ResultsLoader v-if="showLoader" />
  <StyleQuizLayout v-else>
    <template #left-column>
      <h1 class="u-display--heavy">
        what words would you use to describe your brand? ✏️
      </h1>
      <p class="u-label--heavy">select all that apply.</p>
      <div class="next-btn-wrapper">
        <SoonaButton
          v-if="isAuthenticated"
          :disabled="!hasWordSelected"
          :on-click="finishQuiz"
          size="medium"
          data-cypress="button-complete"
        >
          get my results!
        </SoonaButton>
        <SoonaButton
          v-else
          :disabled="!hasWordSelected"
          size="medium"
          :on-click="() => router.push({ name: 'style-quiz-sign-up' })"
          data-cypress="button-next"
        >
          next
        </SoonaButton>
      </div>
    </template>
    <template #right-column>
      <div class="flex-wrapper">
        <SoonaLoading v-if="!allQuizWords.length > 0" is-contained is-loading />
        <div v-for="n in 3" v-else :key="`word-col-${n}`" class="flex-column">
          <button
            v-for="(word, i) in quizWords(3, n - 1)"
            :key="word.id"
            class="u-button-reset u-label--heavy word-btn"
            :class="{
              'word-btn--selected': currentQuiz.words.find(
                qw => qw === word.id
              ),
            }"
            :data-cypress="`button-word-${n}-${i}`"
            @click="toggleWord(word)"
          >
            {{ word.title }}
          </button>
        </div>
      </div>
    </template>
  </StyleQuizLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.next-btn-wrapper {
  margin-left: auto;
}

.flex-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 0 auto;
  max-width: 35rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 33.3333%;
}

.word-btn {
  align-items: center;
  background-color: variables.$bubbletape-pink-10;
  border: 0.0125rem solid variables.$white-default;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;

  &--selected {
    border-color: variables.$black-default;
  }
}
@media (min-width: variables.$screen-md-min) {
  .flex-wrapper {
    padding: 1rem 4rem;
  }

  .word-btn:nth-child(even) {
    margin-left: -2.5rem;
  }

  .word-btn:nth-child(odd) {
    margin-left: 2.5rem;
  }
}
</style>

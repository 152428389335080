<script setup>
import { computed } from 'vue';
import ProductChip from '@/components/user/anytime/products/ProductChip.vue';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  selectedScene: {
    type: Object,
    required: true,
  },
});

const catalogItems = computed(() => props.selectedScene.catalog_items ?? []);

const products = computed(() =>
  catalogItems.value.filter(ci => ci.type === 'CatalogProduct')
);
const customProps = computed(() =>
  catalogItems.value.filter(ci => ci.type === 'CatalogProp')
);

const soonaProps = computed(() =>
  props.selectedScene.shot_tags.filter(
    x => x.tag.tag_category_title === 'Props'
  )
);
</script>

<template>
  <div
    v-if="
      [products.length, customProps.length, soonaProps.length].some(
        count => count > 0
      )
    "
    class="scene-products"
  >
    <template v-if="products.length > 0">
      <h5 id="scene-products-products-heading">products</h5>
      <ul
        class="scene-products__product-chips"
        aria-labelledby="scene-products-products-heading"
      >
        <li v-for="item in products" :key="item.id">
          <ProductChip :item="item" :account-id="accountId" />
        </li>
      </ul>
    </template>

    <template v-if="customProps.length > 0">
      <h5 id="scene-products-custom-props-heading">custom props</h5>
      <ul
        class="scene-products__product-chips"
        aria-labelledby="scene-products-custom-props-heading"
      >
        <li v-for="item in customProps" :key="item.id">
          <ProductChip :item="item" :account-id="accountId" />
        </li>
      </ul>
    </template>

    <template v-if="soonaProps.length > 0">
      <h5 id="scene-products-soona-props-heading">soona props</h5>
      <ul
        class="scene-products__product-chips scene-products__soona-props"
        aria-labelledby="scene-products-soona-props-heading"
      >
        <li v-for="item in soonaProps" :key="item.id">
          <SoonaChip :image-src="item.tag.image_url" image-alt="prop preview">
            {{ item.tag.title }}
          </SoonaChip>
        </li>
      </ul>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';

.scene-products {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h5 {
    @include variables_fonts.u-small--heavy;
  }

  &__product-chips {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    list-style: none;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__soona-props {
    text-transform: lowercase;
  }
}
</style>

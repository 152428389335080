<script setup>
const props = defineProps({
  currentBackground: {
    type: String,
    required: true,
  },
});
const emits = defineEmits(['update:model-value']);
const promoStyles = [
  {
    name: 'blue',
    color: 'white',
    background_color: '#5566ea',
  },
  {
    name: 'pink',
    color: 'black',
    background_color: '#f2b5fa',
  },
  {
    name: 'green',
    color: 'black',
    background_color: '#ebedb8',
  },
  {
    name: 'gold',
    color: 'black',
    background_color: '#fdf8ee',
  },
];
</script>

<template>
  <section class="styles-section">
    <h3 class="u-body--heavy">style*</h3>
    <div class="styles-section__container">
      <button
        v-for="(style, index) in promoStyles"
        :key="index"
        class="styles-section__button"
        :class="{
          'styles-section__button-selected':
            props.currentBackground === style.background_color,
        }"
        :style="`color: ${style.color}; background-color: ${style.background_color};`"
        @click="emits('update:model-value', style)"
      >
        {{ style.name }}
      </button>
    </div>
  </section>
</template>

<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.styles-section {
  margin-bottom: 16px;

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px 16px;
    margin-top: 8px;
    @include variables_fonts.u-body--regular;

    &-selected {
      transform: scale(1.1);
    }
  }
}
</style>

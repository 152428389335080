<script setup>
import { computed } from 'vue';
import { useCapability } from '@/composables/useCapability';
import { convertToMMDDYYYYlong } from 'src/lib/date-formatters';
import SoonaFlag from 'src/components/ui_library/SoonaFlag.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTooltip from 'src/components/ui_library/SoonaTooltip.vue';
import {
  BlackDefault,
  FriendlyRed50,
  FriendlyRed60,
  Gray60,
  Gray30,
  AvoToast20,
  WhiteDefault,
} from '@/variables.module.scss';

const props = defineProps({
  invoice: {
    type: Object,
    required: true,
  },
});

const stripeDashboard = import.meta.env.VITE_STRIPE_DASHBOARD_URL;

const statusBadge = computed(() => {
  const text = props.invoice.status.replace('_', ' ');

  switch (props.invoice.status) {
    case 'draft':
      return { text, bg: Gray30, c: BlackDefault };
    case 'open':
      return { text, bg: Gray30, c: BlackDefault };
    case 'paid':
      return { text, bg: AvoToast20, c: BlackDefault };
    case 'payment_failed':
      return { text, bg: FriendlyRed50, c: WhiteDefault };
    case 'uncollectible':
      return { text, bg: FriendlyRed60, c: WhiteDefault };
    case 'voided':
      return { text, bg: Gray60, c: WhiteDefault };
    default:
      return { text, bg: BlackDefault, c: WhiteDefault };
  }
});

const showFlag = computed(() => {
  const validStatuses = [
    'draft',
    'open',
    'paid',
    'payment_failed',
    'uncollectible',
    'voided',
  ];
  return validStatuses.includes(props.invoice.status);
});

const styledAmountCollected = computed(() => {
  let amt = props.invoice.total;

  return Number(amt).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
});

const { hasCapability: canCrewManageSubscription } = useCapability({
  capability: 'crew_manage_subscription',
});
</script>

<template>
  <article class="invoice">
    <div class="invoice__item1">
      <SoonaFlag
        v-if="showFlag"
        class="invoice__status-flag"
        :background-color="statusBadge.bg"
        :text-color="statusBadge.c"
        :title="statusBadge.text"
      />
      <h2 class="invoice__date u-label--heavy">
        {{ convertToMMDDYYYYlong(invoice.created_at) }}
      </h2>
      <div class="invoice__number text-s">
        <p>invoice#: {{ invoice.id }}</p>
        <p>period start: {{ convertToMMDDYYYYlong(invoice.period_start) }}</p>
        <p>period end: {{ convertToMMDDYYYYlong(invoice.period_end) }} EOD</p>
      </div>
    </div>
    <div class="invoice__item2">
      <p class="invoice__amount">
        {{ styledAmountCollected }}
      </p>
      <div class="invoice__links">
        <SoonaTooltip placement="top">
          <template
            #default="{
              setRef,
              mouseenter,
              focus,
              mouseleave,
              blur,
              ariaDescribedby,
            }"
          >
            <SoonaButton
              v-if="
                invoice.external_invoice_id &&
                invoice.payment_provider === 'stripe' &&
                canCrewManageSubscription
              "
              :ref="el => setRef(el)"
              element="a"
              :href="`${stripeDashboard}/invoices/${invoice.external_invoice_id}`"
              target="_blank"
              variation="secondary-gray"
              size="large"
              :aria-describedby="ariaDescribedby"
              @mouseenter="mouseenter"
              @focus="focus"
              @mouseleave="mouseleave"
              @blur="blur"
            >
              <SoonaIcon name="arrow-up-right-from-square" />
            </SoonaButton>
          </template>
          <template #tooltip-content>view on stripe</template>
        </SoonaTooltip>
        <SoonaTooltip placement="top">
          <template
            #default="{
              setRef,
              mouseenter,
              focus,
              mouseleave,
              blur,
              ariaDescribedby,
            }"
          >
            <SoonaButton
              v-if="
                invoice.event_data &&
                invoice.payment_provider === 'stripe' &&
                invoice.event_data.invoice_pdf
              "
              :ref="el => setRef(el)"
              element="a"
              :href="invoice.event_data.invoice_pdf"
              target="_blank"
              variation="secondary-gray"
              size="large"
              :aria-describedby="ariaDescribedby"
              @mouseenter="mouseenter"
              @focus="focus"
              @mouseleave="mouseleave"
              @blur="blur"
            >
              <SoonaIcon name="arrow-down-to-bracket" />
            </SoonaButton>
          </template>
          <template #tooltip-content>download invoice</template>
        </SoonaTooltip>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.invoice {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;

  &:not(:last-of-type) {
    padding-bottom: 1rem;
    border-bottom: solid 0.0625rem variables.$gray-30;
  }

  &__status-flag {
    margin-bottom: 0.375rem;
  }
  &__links {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    & > * {
      display: flex;
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
    }
  }
  &__item1 {
    display: flex;
    flex-direction: column;
  }
  &__item2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
    align-items: center;
  }
  &__date {
    text-transform: uppercase;
  }
  &__amount {
    font-size: 1.5rem;
    font-weight: 800;
  }
  &__link-button {
    border-radius: 1rem;
    text-decoration: underline;
    color: variables.$periwink-blue-60;
    font-size: 0.625rem;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  src: {
    type: String,
    required: false,
    default: '',
  },
  icon: {
    type: String,
    required: false,
    default: '',
  },
  tabindex: {
    type: String,
    default: '0',
  },
});

const tabindex = computed(() => props.tabindex);
</script>

<template>
  <button
    class="u-button-reset u-label--heavy social-medias__item"
    :tabindex="tabindex"
  >
    <img v-if="src" :src="src" alt="" />
    <SoonaIcon v-if="icon" :name="icon" />
    <slot />
    <SoonaIcon name="arrow-right" />
  </button>
</template>

<style lang="scss" scoped>
@use '@/variables';

.social-medias__item {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.25rem 0.125rem;
  border-radius: 0.3125rem;
  outline-offset: -0.125rem;

  &:focus-visible {
    outline: solid 0.125rem variables.$periwink-blue-60;
  }

  &:focus {
    outline-offset: -0.125rem;
    outline: solid 0.125rem variables.$periwink-blue-70;
    border-radius: 0.625rem;
  }

  &:hover {
    background: variables.$gray-10;
  }

  span {
    display: flex;
    align-items: center;
  }

  img {
    flex: 0 0 1rem;
  }

  svg {
    flex: 0 0 1.125rem;
    width: 1.125rem;
    height: 1.125rem;
    color: variables.$gray-60;
  }
}
</style>

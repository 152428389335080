import { queryKeys } from '@/queries/query-keys';
import { getInvitations } from '@/api/invitations';
import { useQuery } from '@tanstack/vue-query';
import { unref } from 'vue';

/**
 * @param {Ref<string | number>} accountId
 * @param {Object} [params]
 * @param {Ref<number>} [params.currentPage]
 * @param {Ref<number>} [params.itemsPerPage]
 * @param {Ref<string>} [params.status]
 */
export function useInvitations(
  accountId,
  { currentPage, itemsPerPage, status } = {}
) {
  return useQuery({
    queryKey: queryKeys.invitations(accountId, {
      status,
      currentPage,
      itemsPerPage,
    }),
    queryFn: ({ signal }) =>
      getInvitations(
        unref(accountId),
        {
          status: unref(status),
          currentPage: unref(currentPage),
          itemsPerPage: unref(itemsPerPage),
        },
        signal
      ),
  });
}

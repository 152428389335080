import { computed, ref, unref, watch } from 'vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useReservations } from 'src/queries/reservations/useReservations';
import { keepPreviousData } from '@tanstack/vue-query';

/**
 * @param {Object} opts
 * @param {Ref<string|number>} opts.accountId
 * @param {string} opts.filter
 * @param {Ref<string>} opts.search
 * @param {Ref<boolean>} opts.enabled
 */
export function useBookingsCategory({
  accountId,
  filter,
  search = ref(null),
  enabled = true,
}) {
  const currentPage = ref(1);
  const itemsPerPage = ref(12);
  const perPageOptions = [12, 24, 36, 48];
  // go back to the first page when itemsPerPage or search change
  watch([itemsPerPage, search], () => {
    currentPage.value = 1;
  });

  const { data, error, isLoading } = useReservations(
    {
      accountId,
      itemsPerPage,
      currentPage,
      filter,
      query: search,
    },
    // mark as fresh for 1 minute to cache search results a little
    { placeholderData: keepPreviousData, staleTime: 60 * 1000, enabled }
  );

  const reservations = computed(() => data.value?.reservations ?? []);

  const reservationsPagination = computed(() => {
    if (data.value) {
      return data.value.pagination;
    } else {
      return {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      };
    }
  });

  const priorityError = usePriorityError(error);

  const isShown = computed(
    () =>
      unref(enabled) &&
      (reservationsPagination.value.totalCount > 0 || priorityError.value)
  );

  return {
    currentPage,
    // we are never in a loading state if the query is not enabled
    isLoading: computed(() => unref(enabled) && isLoading.value),
    isShown,
    itemsPerPage,
    perPageOptions,
    priorityError,
    reservations,
    reservationsPagination,
  };
}

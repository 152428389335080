<script setup>
import { toRefs } from 'vue';
import { toCurrency } from '@/lib/currency';
import { useReservationLineItems } from '@/queries/reservationLineItem/useReservationLineItems';

const props = defineProps({
  digitalAssetId: {
    default: null,
    type: [Number, String],
  },
  orderId: {
    default: null,
    type: [Number, String],
  },
  reservationId: {
    required: true,
    type: [Number, String],
  },
});

const { reservationId, orderId, digitalAssetId } = toRefs(props);

const { data } = useReservationLineItems(
  reservationId,
  orderId,
  digitalAssetId
);
</script>

<template>
  <div v-if="data?.length">
    <h3 class="u-label--heavy">add-ons</h3>
    <ul>
      <li
        v-for="mediaAddOn in data"
        :key="mediaAddOn.id"
        class="u-small--regular"
      >
        {{ mediaAddOn.name }} ({{ toCurrency(mediaAddOn.total) }})
      </li>
    </ul>
  </div>
</template>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {Object} [params]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.searchQuery]
 * @param {string} [params.sortBy]
 * @param {string} [params.sortDirection]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 * */
export function useShopMore(
  {
    accountId,
    currentPage,
    itemsPerPage = 10,
    searchQuery,
    sortBy,
    sortDirection,
  },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.shopMoreDigitalAssets(accountId, {
      currentPage,
      itemsPerPage,
      searchQuery,
      sortBy,
      sortDirection,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (currentPage) params['page'] = toValue(currentPage);
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);
      if (searchQuery) params['search_query'] = toValue(searchQuery);
      if (sortBy) params['order_by'] = toValue(sortBy);
      if (sortDirection) params['direction'] = toValue(sortDirection);

      const response = await http.get(
        `accounts/${toValue(accountId)}/shop_more.json`,
        { params, signal }
      );
      return response.data;
    },
    ...queryOptions,
  });
}

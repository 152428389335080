<script setup>
import { computed, ref } from 'vue';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import { useCreateUserAsStaff } from 'src/queries/users/useCreateUserAsStaff';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

const props = defineProps({
  reservationId: String,
});

const reservationId = computed(() => props.reservationId);

const name = ref('');
const email = ref('');

const {
  mutate: createUser,
  isPending: isCreating,
  error: createError,
} = useCreateUserAsStaff();

const {
  mutate: updateReservation,
  isPending: isUpdatingReservation,
  error: updateReservationError,
} = useUpdateReservation(reservationId);

const isBusy = computed(() => {
  return isCreating.value || isUpdatingReservation.value;
});

const priorityError = usePriorityError(updateReservationError, createError);

const errorDetails = computed(() =>
  createError.value?.response?.data?.errors?.map(
    e => `${e.field}: ${e.message}`
  )
);

function submit(event) {
  const data = new FormData(event.target);

  const attributes = {
    name: data.get('name'),
    email: data.get('email'),
    username: data.get('email'),
    soft_sign_up: true,
  };

  createUser(attributes, {
    onSuccess: response => {
      updateReservation({
        account_id: response.accounts[0].id,
        point_of_contact_user_id: response.id,
        down_payment_order_attributes: { account_id: response.accounts[0].id },
      });
    },
  });
}
</script>

<template>
  <SoonaForm v-slot="{ hasErrors }" @submit="submit">
    <SoonaTextfield
      v-model="name"
      label="customer name"
      name="name"
      placeholder="enter the customer’s name"
      :required="true"
      :rules="['required']"
      :disabled="isBusy"
      data-cypress="customer-name"
    />
    <SoonaTextfield
      v-model="email"
      label="customer email"
      name="email"
      placeholder="enter the customer’s email"
      type="email"
      :required="true"
      :rules="['email', 'required']"
      :disabled="isBusy"
      data-cypress="customer-email"
    />
    <SoonaButton
      :disabled="hasErrors || isBusy"
      type="submit"
      size="medium"
      variation="primary"
    >
      create customer account
    </SoonaButton>
  </SoonaForm>

  <SoonaError v-if="priorityError">
    {{ priorityError }}
    <ul v-if="errorDetails">
      <li v-for="error in errorDetails" :key="JSON.stringify(error)">
        {{ error }}
      </li>
    </ul>
  </SoonaError>
</template>

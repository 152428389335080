import { computed, inject, provide, watch } from 'vue';
import { useBulkSelection } from '@/composables/digital_assets/useBulkSelection';

const reservationGallerySelectionSymbol = Symbol(
  'reservation gallery selection'
);

/**
 * @param {Ref<Object | undefined>} rawData - raw data from an infinite query
 * @param {MaybeRef<number | string>} reservationId
 */
export function useReservationGallerySelectionProvider(rawData, reservationId) {
  const { onSelectionClick, resetSelection, isAssetSelected, selectedAssets } =
    useBulkSelection(rawData, rda => rda);

  const bulkSelectActionBarIsVisible = computed(
    () => selectedAssets.value.length > 0
  );

  // reset state when reservationId changes
  watch(reservationId, () => {
    resetSelection();
  });

  const state = {
    selectedReservationDigitalAssets: selectedAssets,
    bulkSelectActionBarIsVisible,
    resetSelection,
    isAssetSelected,
    onSelectionClick,
  };

  provide(reservationGallerySelectionSymbol, state);

  return state;
}

export function useReservationGallerySelection() {
  return inject(reservationGallerySelectionSymbol);
}

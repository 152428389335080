<script setup>
import { onMounted } from 'vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useTitle } from '@vueuse/core';
import { useBaseEvents } from 'src/composables/segment/useBaseEvents';

const { pageViewed, linkClicked } = useBaseEvents();

const title = useTitle(null);

onMounted(() => {
  title.value = 'free headshot received | soona';

  pageViewed();
});
</script>

<template>
  <div class="u-container invalid-h4a">
    <SoonaHeading data-cypress="h4a-already-received-title">
      looks like you’ve already received your one free headshot!
    </SoonaHeading>
    <figure class="invalid-h4a__photos">
      <div>
        <img
          src="@images/booking/h4a-invalid/h4a-1@2x.jpg"
          alt="Sample headshot"
        />
      </div>
      <div>
        <img
          src="@images/booking/h4a-invalid/h4a-2@2x.jpg"
          alt="Sample headshot"
        />
      </div>
      <div>
        <img
          src="@images/booking/h4a-invalid/h4a-3@2x.jpg"
          alt="Sample headshot"
        />
      </div>
    </figure>
    <p class="invalid-h4a__text">
      ready for another headshot booking? build a custom photo shoot by clicking
      the link below. be sure your shoot is in-studio at your preferred
      location! email us at
      <a
        href="mailto:hey@soona.co"
        @click="
          linkClicked({
            context: 'booking',
            subContext: 'free headshot already received',
            linkLabel: 'hey@soona.co',
            linkHref: 'mailto:hey@soona.co',
          })
        "
        >hey@soona.co</a
      >
      if you need support.
    </p>
    <SoonaButton
      variation="secondary-black"
      element="router-link"
      :to="'/booking/new'"
      @on-click="
        linkClicked({
          context: 'booking',
          subContext: 'free headshot already received',
          linkLabel: 'Build Your Shoot',
          linkHref: '/booking/new',
        })
      "
    >
      build your shoot
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

@keyframes image-fade-in-down {
  from {
    opacity: 0;
    transform: translateY(-3em);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes image-spin-front {
  to {
    transform: rotateY(-0.5turn);
  }
}

@keyframes image-spin-back {
  to {
    transform: rotateY(0turn);
  }
}

.invalid-h4a {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 47.75rem;
  text-align: center;

  &__photos {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    height: 8.9375rem;
    margin-bottom: 2rem;
    max-width: 18.125rem;

    > div {
      position: relative;
      border-radius: 0.5rem;
      max-width: 5.625rem;
      animation: image-fade-in-down 1s both cubic-bezier(0.25, 1, 0.5, 1);

      > img {
        display: block;
        border-radius: 0.5rem;
        transform: rotateY(0.5turn);
        backface-visibility: hidden;
        animation: 0.9s image-spin-back 1s both cubic-bezier(0.25, 1, 0.5, 1);
      }

      &::after {
        content: '';
        position: absolute;
        border-radius: 0.5rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #e5a520;
        backface-visibility: hidden;
        animation: 0.9s image-spin-front 1s both cubic-bezier(0.25, 1, 0.5, 1);
        background-image: url('~images/soona-wink-white.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 60%;
      }

      &:nth-child(2) {
        align-self: flex-start;
        animation-delay: 0.2s;

        &::after {
          background-color: variables.$periwink-blue-30;
        }

        > img,
        &::after {
          animation-delay: 1.1s;
        }
      }

      &:nth-child(3) {
        align-self: flex-end;
        animation-delay: 0.4s;

        &::after {
          background-color: #cb5f77;
        }

        > img,
        &::after {
          animation-delay: 1.3s;
        }
      }
    }
  }

  &__text {
    margin-bottom: 2rem;
    color: variables.$black-default;

    a {
      color: variables.$periwink-blue-60;
      text-decoration: underline;

      &:hover,
      &:focus-visible {
        text-decoration: none;
      }
    }
  }
}
</style>

import { computed, unref } from 'vue';

export function useImageQualityRulesData(sourceData) {
  const data = unref(sourceData);

  const ruleNames = [
    { name: 'low resolution replace', icon: 'resolution' },
    { name: 'low resolution', icon: 'resolution' },
    { name: 'poor composite', icon: 'bandage' },
    { name: 'poor quality', icon: 'aperture' },
    { name: 'AI-generated', icon: 'ghost' },
    { name: 'slow web performance', icon: 'hourglass-start' },
    { name: 'inconsistent aspect ratio', icon: 'resize' },
  ];

  function computeRuleData(ruleName) {
    const rule = computed(() => {
      return data?.rules_result.rules.find(rule => rule.rule === ruleName);
    });

    const assetsFailing = computed(() => rule.value?.assets ?? []);
    const ruleDescription = computed(() => rule.value?.rule_description ?? '');
    const ruleSlug = computed(() => rule.value?.rule_slug ?? '');

    return { assetsFailing, ruleDescription, ruleSlug };
  }

  const rulesData = ruleNames.map(rule => ({
    ...computeRuleData(rule.name),
    icon: rule.icon,
    ruleName: rule.name,
  }));

  const failingImages = computed(() => {
    const uniqueImages = new Map();
    rulesData.forEach(
      ({ assetsFailing, ruleDescription, ruleSlug, ruleName, icon }) => {
        assetsFailing.value.forEach(asset => {
          const { digital_asset_id, image_url } = asset;
          if (!uniqueImages.has(digital_asset_id)) {
            uniqueImages.set(digital_asset_id, {
              image_url,
              digital_asset_id,
              rules: [],
            });
          }
          uniqueImages.get(digital_asset_id).rules.push({
            rule: ruleName,
            description: ruleDescription.value,
            icon: icon,
            rule_slug: ruleSlug,
          });
        });
      }
    );

    return Array.from(uniqueImages.values());
  });
  const numberOfFailingImages = computed(() => failingImages.value.length);

  return { failingImages, numberOfFailingImages };
}

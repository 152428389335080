import { computed } from 'vue';

export const useBaggedAssetsSelected = selectedReservationDigitalAssets => {
  const baggedAssetsSelected = computed(() => {
    return selectedReservationDigitalAssets.value.some(
      rda => !!rda.digital_asset.bag_collection_digital_asset?.id
    );
  });
  return { baggedAssetsSelected };
};

<template>
  <div class="EmojiPicker" role="dialog" aria-labelledby="emojis-heading">
    <div ref="target" class="picker-modal">
      <h2 id="emojis-heading">
        <template v-if="!emojis">loading </template>emojis
      </h2>
      <div class="emoji-body">
        <ul class="emoji-list-wrapper">
          <li
            v-for="(category, index) in categories"
            :key="index"
            class="category"
          >
            {{ category }}
            <ul class="emojis">
              <li v-for="(emoji, e) in $data[category]" :key="e">
                <button
                  class="u-button-reset emoji"
                  @click="selectEmoji(emoji)"
                >
                  <span :aria-hidden="!!emoji.name">{{ emoji.emoji }}</span
                  ><span v-if="emoji.name" class="u-visually-hidden">{{
                    emoji.name
                  }}</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { onClickOutside, onKeyStroke } from '@vueuse/core';
import { useEmojiData } from './useEmojiData';
import uniqueId from 'lodash/uniqueId';
import { useDialogContext } from '@/composables/useDialog';

export default {
  name: 'EmojiPicker',
  emit: ['emoji-selected'],
  props: {
    closePicker: Function,
  },
  emits: ['emoji-selected'],
  setup(props) {
    const target = ref(null);
    const id = uniqueId('emoji-picker-dialog-');
    const { checkAllowEscapeClose } = useDialogContext({ id, modal: false });

    onKeyStroke('Escape', () => {
      if (checkAllowEscapeClose(target)) {
        props.closePicker();
      }
    });

    onClickOutside(target, e => {
      // prevent from firing if the toggle icon is clicked
      // otherwise the dialog will close and immediately re-open
      if (e.target.classList.contains('emoji-picker-button')) return;
      props.closePicker();
    });

    const emojis = useEmojiData();

    return { target, emojis };
  },
  data() {
    return {
      variation: 0,
      selected: null,
      categories: [],
      storedEmojis: [],
    };
  },
  computed: {
    mostUsedEmojis() {
      let obj = {};
      let result;

      this.storedEmojis.forEach(e => {
        obj[e.emoji] = obj[e.emoji] || 0;
        obj[e.emoji]++;
      });

      result = Object.entries(obj).map(e => ({
        emoji: e[0],
        count: e[1],
      }));

      result.sort((a, b) => b.count - a.count).splice(22);

      return result;
    },
  },
  watch: {
    mostUsedEmojis() {
      this.$data['frequently used'] = this.mostUsedEmojis;
    },
    emojis: {
      handler(newValue) {
        if (!newValue) {
          return;
        }
        this.categories = this.emojis?.categories?.slice() ?? [];

        // Create arrays of emojis in data sorted by category
        this.categories?.forEach(category => {
          const index = this.emojis.categories.findIndex(c => c === category);
          if (index >= 0) {
            this.$data[category] = this.emojis.list.filter(
              emoji => emoji.category === index
            );
          }
        });

        if (localStorage.storedEmojis) {
          this.storedEmojis = JSON.parse(localStorage.storedEmojis);
          this.categories?.unshift('frequently used');
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit('emoji-selected', emoji);
      this.storeEmoji(emoji);
    },
    storeEmoji(emoji) {
      // limit localStorage data to 50 emojis
      if (this.storedEmojis.length >= 50) {
        this.storedEmojis.shift();
      }

      // create frequently-used category in data if it doesn't exist
      if (!this.categories.includes('frequently used')) {
        this.categories.unshift('frequently used');
      }

      this.storedEmojis.push(emoji);
      localStorage.setItem('storedEmojis', JSON.stringify(this.storedEmojis));
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.EmojiPicker {
  .picker-modal {
    background: variables.$white-default;
    z-index: 20;
    padding: 1rem;
    box-shadow:
      0px 0px 0px 1px rgba(20, 20, 20, 0.04),
      0px 16px 32px rgba(20, 20, 20, 0.2);
    border-radius: 8px;
    height: 250px;
    max-width: 350px;
    overflow-y: scroll;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    margin: auto;

    h2 {
      font-weight: 800;
      padding: 0.25rem;
    }
    .emoji-body {
      display: flex;
      flex-direction: column;
    }
    .emoji-list-wrapper {
      display: flex;
      flex-direction: column;
    }
    .category {
      font-weight: 400;
      padding: 0.25rem;
    }
    .emojis {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex: 1 1 0;
    }
    .emoji {
      font-size: 1rem;
      padding: 0.25rem;
      border-radius: 0.5rem;

      & > span {
        margin: 0;
      }
    }
    .emoji:hover {
      background: variables.$gray-20;
    }
  }
  @media only screen and (max-device-width: 769px) {
    .emoji {
      font-size: 2.3125rem;
    }
  }
}
</style>

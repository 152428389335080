<script setup>
import { computed, watchEffect } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import ByoPhotoImage from 'images/booking/byo_main_image.jpg';
import ByoVideoImage from 'images/booking/byo_video_image.jpg';
import SoonaByoCard from './SoonaByoCard.vue';
import { useResetPack } from 'src/queries/reservation-packs/useResetPack';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  onNext: Function,
  reservationId: String,
});

const emits = defineEmits(['onReady']);

const reservationId = computed(() => props.reservationId);
const { itemSelected } = useBaseEvents();
const { mutate: resetPack, error: resetError } = useResetPack();
const { data, reservationError } = useReservation(reservationId);
const hasPack = computed(() => !!data.value?.isPickAPack);

const {
  mutate: mutateReservation,
  isPending,
  error,
} = useUpdateReservation(reservationId);
const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);
const {
  mutate: addReservationTagSkip,
  isPending: isAddingSkipped,
  error: addSkippedError,
} = useAddReservationTag(reservationId, { skipInvalidate: true });
const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId, { skipInvalidate: true });

const priorityError = usePriorityError(
  error,
  addError,
  addSkippedError,
  removeError,
  resetError,
  reservationError
);

const isPreferred = computed(
  () => data.value?.account?.preferred?.is_preferred ?? false
);
const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');

const tagCategorySlug = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return 'media-type-membership';
  } else if (isPreferred.value) {
    return 'media-type-preferred';
  } else {
    return 'media-type';
  }
});

const { data: mediaType } = useReservationTags(
  reservationId,
  computed(() => tagCategorySlug.value)
);

const isBusy = computed(
  () =>
    isPending.value ||
    isAdding.value ||
    isRemoving.value ||
    isAddingSkipped.value
);
const mediaTypeOptions = computed(() => {
  return mediaType?.value?.options || [];
});

function addSelectedOption(selectedOption, skip = false) {
  const mutationToCall = skip ? addReservationTagSkip : addReservationTag;
  mutationToCall(
    { tagId: selectedOption.tag.id },
    {
      onSuccess: () => {
        itemSelected({
          context: 'booking',
          subContext: 'type',
          itemId: selectedOption.product.id,
          itemLabel: selectedOption.tag.title,
          itemPrice: selectedOption.product.rate,
          itemQuantity: 1,
        });

        if (skip) {
          mutateReservation(
            { skipped_pack: true },
            {
              onSuccess: () => {
                emits('onReady', true);
              },
            }
          );
        } else {
          emits('onReady', true);
        }
      },
    }
  );
}

async function onOptionChange(selectedOption) {
  if (isAdding.value || isRemoving.value) return;

  const existingSelected = mediaTypeOptions.value.find(
    option => option.selected && option.tag.id !== selectedOption.tag.id
  );
  if (existingSelected) {
    removeReservationTag(
      {
        reservationTagId: existingSelected.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSuccess: () => {
          addSelectedOption(selectedOption);
        },
      }
    );
  } else {
    addSelectedOption(selectedOption, true);
  }
}

watchEffect(() => {
  if (hasPack.value) {
    resetPack(props.reservationId);
  }
});

const subtitleText = mt => {
  // accomodate $0/photo product, which is used for customers with preferred credits
  // TODO?: remove this if/when subscriptions makes it obsolete?
  if (
    Math.floor(mt.product?.rate) === 0 &&
    (mt.tag?.title === 'Photo' || mt.tag?.title === 'Video') &&
    mt.tag?.price_description
  ) {
    return mt.tag?.price_description;
  }

  return `$${Math.floor(mt.product.rate)}/${mt.tag.title.toLowerCase()}${
    mt.tag.title == 'Video' ? ' clip' : ''
  }`;
};
</script>

<template>
  <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
  <div class="build-your-own">
    <h4 class="u-body--heavy">build your own</h4>
    <p class="u-body--regular">
      know exactly what you’re looking for? our build-your-own option allows you
      to customize everything from photo to video to backgrounds to models and
      more!
    </p>

    <div class="build-your-own__wrapper">
      <div class="build-your-own__cards">
        <SoonaByoCard
          v-for="mt in mediaTypeOptions"
          :key="mt.tag.id"
          :card-data="mt"
          :is-selected="mt.selected"
          :image="mt.tag.title === 'Video' ? ByoVideoImage : ByoPhotoImage"
          :title="`${mt.tag.title.toLowerCase()}`"
          :subtitle="subtitleText(mt)"
          :is-disabled="isBusy"
          @byo-card-clicked="onOptionChange"
        />
      </div>
      <p class="build-your-own__copy u-small--regular">
        looking for both? select video to start!
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.build-your-own {
  text-align: left;
  border-top: 1px solid variables.$gray-30;
  border-bottom: 1px solid variables.$gray-30;
  padding: 0.25rem;
  padding-top: 0.75rem;
  margin-bottom: 1.25rem;

  &__wrapper {
    margin: 0.75rem 0 1.25rem 0;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin: 0.75rem 0 1.25rem 0;
    gap: 1.75rem;

    @media (min-width: variables.$screen-sm-min) {
      flex-direction: row;
    }
  }

  &__copy {
    text-align: center;
  }
}
</style>

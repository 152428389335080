<script setup>
import { computed, toRef, ref } from 'vue';
import SoonaDonutChart from '@/components/ui_library/SoonaDonutChart.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { PeriwinkBlue20, PeriwinkBlue70 } from '@/variables.module.scss';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useDeleteCompetitor } from '@/queries/useCompetitors';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import ListingPickerDialog from './pickers/ListingPickerDialog.vue';
import PickerListingOption from './pickers/PickerListingOption.vue';
import { Gray30, Gray20 } from '@/variables.module.scss';
import useListingRank from '@/components/user/anytime/competitive_analysis/useListingRank';
import IntegrateAccountsDialog from '@/components/shared/IntegrateAccountsDialog.vue';
import ListingPrice from './ListingPrice.vue';
import useSelectListing from '@/components/user/anytime/competitive_analysis/pickers/useSelectListing';
import useCompetitorsPopoverBackRoute from './useCompetitorsPopoverBackRoute';
import { useRoute } from 'vue-router';

const props = defineProps({
  competitor: {
    type: Object,
    default: null,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const competitor = computed(() => props.competitor ?? {});
const accountId = computed(() => competitor.value.account_id);
const integrationId = computed(() => competitor.value.integration_id ?? null);
const scoringInProgress = computed(() => competitor.value.scoring_in_progress);
const assets = computed(() => competitor.value.assets ?? []);
const listingScore = computed(() => competitor.value.listing_score);
const listingTags = computed(() => competitor.value.listing_tags);

const route = useRoute();

const { getDisplayGroupRanks } = useListingRank();

const competitorData = computed(() => {
  return [
    {
      label: listingScore.value?.visual_average?.score?.label ?? null,
      value: listingScore.value?.visual_average?.score?.value ?? null,
    },
  ];
});

const { mutate: deleteCompetitor } = useDeleteCompetitor(
  accountId,
  toRef(() => competitor.value.id)
);

const { setBackRoute } = useCompetitorsPopoverBackRoute();

const { select, pickerIsOpen } = useSelectListing(
  competitor,
  integrationId,
  accountId
);

const selectListing = listing => {
  setBackRoute(route);
  select(listing);
};

const detailPageLink = computed(() => {
  let path;
  if (competitor.value.example_staff_pick) {
    path = 'example_competitor';
  } else {
    path = 'competitor';
  }
  return `/account/${accountId.value}/${path}/${competitor.value.id}`;
});

const showIntegrationDialog = ref(false);
</script>

<template>
  <SoonaSkeleton
    v-if="assets.length === 0 || isLoading || !competitor"
    class="competitor-preview-card__skeleton"
  />
  <div v-else class="competitor-preview-card">
    <div class="competitor-preview-card__inner">
      <div class="competitor-preview-card__visuals">
        <SoonaDonutChart
          class="competitor-preview-card__score-donut"
          accessible-title="listing score"
          :data="competitorData"
          :display-value="listingScore?.visual_average?.display_value"
          :is-loading="!listingScore || scoringInProgress"
          size="large"
          label-position="none"
        />
        <SoonaSlider
          class="competitor-preview-card__assets"
          :override-u-container="true"
        >
          <img
            v-for="asset in competitor.assets"
            :key="asset.id"
            :src="asset.image_url"
            class="competitor-preview-card__image"
          />
        </SoonaSlider>
      </div>

      <div class="competitor-preview-card__clickable-area">
        <div
          v-if="competitor.staff_pick"
          class="competitor-preview-card__staff-pick"
        >
          <SoonaFlag
            title="example competitor"
            font-size="0.75rem"
            font-weight="bolder"
            :text-color="PeriwinkBlue70"
            :background-color="PeriwinkBlue20"
          >
            <template #icon-left>
              <SoonaIcon name="lightbulb-alt" size="small" />
            </template>
          </SoonaFlag>

          <div
            v-if="listingTags.length > 0"
            class="competitor-preview-card__staff-pick__tags"
          >
            <SoonaFlag
              v-for="(tag, i) in listingTags"
              :key="i"
              class="competitor-preview-card__staff-pick__tag"
              :title="tag"
              :color="Gray30"
              :border-color="Gray30"
              :background-color="Gray20"
              padding-size="0.1875rem 0.375rem 0.1875rem 0.375rem"
              font-weight="normal"
              type="pill"
              lowercase
            />
          </div>
        </div>

        <div class="competitor-preview-card__details">
          <component
            :is="scoringInProgress ? 'span' : 'router-link'"
            :to="detailPageLink"
            class="competitor-preview-card__title--wrapper"
            :class="{
              'competitor-preview-card__title--wrapper--disabled':
                scoringInProgress,
            }"
          >
            <h3 class="competitor-preview-card__title u-subheader--heavy">
              {{ competitor.name }}
            </h3>
            <div>
              <SoonaIcon
                name="arrow-right"
                class="competitor-preview-card__title--icon"
              />
            </div>
          </component>
          <p
            class="competitor-preview-card__ranking u-label--regular"
            :class="{
              'competitor-preview-card__ranking--loading': scoringInProgress,
            }"
          >
            {{ getDisplayGroupRanks(competitor) }}
          </p>
          <ListingPrice :item="competitor" :is-loading="scoringInProgress" />
        </div>
      </div>

      <div
        class="competitor-preview-card__actions"
        :class="{
          'competitor-preview-card__actions--staff-picks':
            competitor.staff_pick,
        }"
      >
        <SoonaButton
          v-if="!competitor.staff_pick"
          variation="tertiary"
          size="medium"
          @click="deleteCompetitor"
        >
          remove from watchlist
        </SoonaButton>
        <SoonaButton
          variation="secondary-black"
          size="medium"
          :disabled="scoringInProgress"
          @click="pickerIsOpen = true"
        >
          compare
        </SoonaButton>
      </div>
    </div>

    <IntegrateAccountsDialog
      v-if="showIntegrationDialog"
      amazon-only
      @close="showIntegrationDialog = false"
    />

    <ListingPickerDialog
      v-if="pickerIsOpen"
      :integration-id="integrationId"
      :account-id="accountId"
      @cancel="pickerIsOpen = false"
    >
      <template #heading>choose listing to compare</template>
      <template #listing-options="{ listings }">
        <PickerListingOption
          v-for="listing in listings"
          :key="listing.id"
          :listing="listing"
          @select="selectListing"
        />
      </template>
    </ListingPickerDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.competitor-preview-card {
  container-type: inline-size;

  &__skeleton {
    min-height: 21.5625rem;
    width: 100%;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
  }

  &__inner {
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    box-shadow: variables.$elevation-0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__visuals {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    padding: 0 1rem;
  }

  &__score-donut {
    min-width: 5rem;
    max-width: 5rem;
    max-height: 5rem;
  }

  &__assets {
    overflow-x: hidden;
  }

  &__image {
    max-width: 5rem;
    max-height: 5rem;
    object-fit: cover;
    border-radius: 0.3125rem;
  }

  &__clickable-area {
    position: relative;
    padding: 0 1rem;
  }

  &__ranking {
    &--loading {
      color: variables.$gray-30;
    }
  }

  &__staff-pick {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1rem;
    width: 100%;

    &__tags {
      display: none;

      @media (min-width: variables.$screen-sm-min) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
      }
    }

    &__tag {
      white-space: nowrap;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__title {
    &--wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0.625rem;

      &:hover,
      &:focus-visible {
        .competitor-preview-card__title {
          cursor: pointer;
          text-decoration: underline;
          color: variables.$black-default;
        }
        .competitor-preview-card__title--icon {
          transform: translateX(0.1875rem);
        }
      }

      &--disabled {
        color: variables.$gray-30;

        &:hover,
        &:focus-visible {
          .competitor-preview-card__title {
            cursor: wait;
            text-decoration: none;
            color: variables.$gray-30;
          }
        }
      }
    }

    &--icon {
      transition: transform 0.1s ease-out;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    &--staff-picks {
      justify-content: flex-end;
    }
  }

  @container (min-width: 37.5rem) {
    &__inner {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    &__visuals,
    &__clickable-area {
      padding: 0 1.5rem;
    }

    &__score-donut {
      min-width: 7.5rem;
      max-width: 7.5rem;
      max-height: 7.5rem;
    }
    &__image {
      max-width: 7.5rem;
      max-height: 7.5rem;
    }

    &__actions {
      padding: 0 1.5rem;
    }
  }
}
</style>

import { http } from '../config/vue-axios';

/**
 *
 * @param {Object} [params]
 * @param {AbortSignal} [signal]
 */
export async function getIndustries(signal) {
  const response = await http.get('industries', { signal });

  return response.data;
}

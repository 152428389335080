<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCompetitorBehindResults } from './useCompetitorBehindResults';
import { useRuleIcon } from './useRuleIcon';

const props = defineProps({
  listing: {
    type: Object,
    required: true,
  },
  competitor: {
    type: Object,
    required: true,
  },
});

const listing = computed(() => props.listing);
const competitor = computed(() => props.competitor);

const { failingResults } = useCompetitorBehindResults(listing, competitor);
</script>

<template>
  <div class="behind">
    <div class="behind__header--wrapper">
      <h4 class="behind__header u-subheader--heavy">where you’re behind</h4>
    </div>

    <table class="behind__body">
      <tr v-for="(result, i) in failingResults" :key="i" class="behind__result">
        <th scope="row">
          <div class="behind__description">
            <div class="behind__description--icon">
              <SoonaIcon :name="useRuleIcon(result.rule)" />
            </div>
            <div class="behind__description--content">
              <h5 class="u-body--heavy">{{ result.rule }}</h5>
              <p class="behind__description--text u-body--regular">
                {{ result.ruleDescription }}
              </p>
            </div>
          </div>
        </th>

        <td class="behind__result--listing">
          <p class="u-title--heavy">{{ result.listing }}</p>
        </td>
        <td class="behind__result--competitor">
          <p class="u-title--heavy">{{ result.competitor }}</p>
        </td>
      </tr>
    </table>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
.behind {
  padding: 1rem;

  &__header {
    padding: 1rem;

    &--wrapper {
      background-color: variables.$roses-20;
      border-radius: 0.625rem;
      // to do: make this a gradient
      border-left: 1rem solid variables.$roses-40;
    }
  }

  &__body {
    margin-top: 0.5rem;
  }

  table {
    width: 100%;
  }

  tr {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 1.5rem 0;
    border-bottom: 0.0625rem solid variables.$gray-30;

    th {
      width: 100%;
      padding-bottom: 1rem;
    }
    td {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    td:first-of-type {
      justify-content: flex-end;
    }
  }

  &__description {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    &--text {
      display: none;
    }
  }

  &__result {
    &--listing {
      border-right: 0.0625rem solid variables.$gray-30;
      text-align: end;
      padding-right: 1rem;

      p {
        background-color: variables.$roses-30;
        border-radius: 1rem;
        width: fit-content;
        padding: 0 0.5rem;
        justify-self: end;
      }
    }

    &--competitor {
      text-align: start;
      padding-left: 1rem;

      p {
        width: fit-content;
        padding: 0 0.5rem;
      }
    }
  }

  @media (min-width: 40rem) {
    padding: 1.5rem;

    &__description {
      &--text {
        display: block;
      }
    }

    tr {
      display: grid;
      grid-template-columns: 2fr 1fr 3fr;

      th {
        padding-bottom: 0;
      }
      td {
        width: 100%;
      }
    }
  }
}
</style>

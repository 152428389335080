<script setup>
import { computed, ref, toRefs, watch } from 'vue';
import { useAccountRequirementVersions } from '@/queries/account-requirements/useAccountRequirementVersions';
import { keepPreviousData } from '@tanstack/vue-query';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import {
  convertToHHMM12,
  convertToMMDDYYYYmonthAbr,
} from '@/lib/date-formatters';
import { useAccount } from '@/queries/account/useAccount';
import { requirementTypes } from '@/components/crew/account-requirements/requirement-types';
import PaginatorSmall from '@/components/PaginatorSmall.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  requirementType: {
    type: String,
    required: true,
  },
});

defineEmits(['close']);

const { accountId, requirementType } = toRefs(props);
const currentPage = ref(1);
const itemsPerPage = ref(10);
watch(itemsPerPage, () => {
  currentPage.value = 1;
});

const {
  data: account,
  error: accountError,
  isLoading: accountIsLoading,
} = useAccount(accountId);

const {
  data: versionsData,
  error: versionsError,
  isLoading: versionsIsLoading,
} = useAccountRequirementVersions(
  accountId,
  requirementType,
  {
    currentPage,
    itemsPerPage,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const pagination = computed(
  () =>
    versionsData.value?.pagination ?? {
      currentPage: 0,
      itemsPerPage: 0,
      totalCount: 0,
      totalPages: 0,
    }
);

const isLoading = computed(
  () => accountIsLoading.value || versionsIsLoading.value
);
const priorityErrors = usePriorityErrors(accountError, versionsError);
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>revision history</template>

    <p class="u-body--heavy version-info">
      {{ requirementTypes[requirementType]?.label ?? requirementType }} for
      {{ account?.name ?? 'account ' + accountId }}
    </p>

    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />

    <div
      v-for="version in versionsData?.versions"
      :key="version.id"
      class="version-row"
    >
      <time class="u-body--regular timestamp" :datetime="version.created_at"
        >{{ convertToMMDDYYYYmonthAbr(version.created_at) }} at
        {{ convertToHHMM12(version.created_at) }}</time
      >
      <p class="u-body--regular">
        {{ version.event }} by {{ version.user.name }}
      </p>
    </div>

    <PaginatorSmall
      :page="pagination.currentPage"
      :pages="pagination.totalPages"
      @page-change="currentPage = $event"
    />
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.version-info {
  margin-bottom: 1.5rem;
}

.version-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(12rem, 100%), 1fr));
  padding: 0.5rem 0;
  gap: 0.5rem;

  &:not(:last-child) {
    border-bottom: 0.0625rem solid variables.$gray-30;
  }
}

.timestamp {
  color: variables.$gray-70;
}
</style>

<script setup>
import { ref } from 'vue';
import { useAccountsRoles } from '@/queries/account/useAccountsRoles';
import BeatLoader from '@/components/shared/BeatLoader.vue';
import EditCollaboratorDialog from '@/components/user/anytime/dashboard/collaborators/EditCollaboratorDialog.vue';
import AccountOwnerDialog from '@/components/user/anytime/dashboard/collaborators/AccountOwnerDialog.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaDropdownMenuItemCheckbox from '@/components/ui_library/SoonaDropdownMenuItemCheckbox.vue';

defineProps({
  accountId: {
    required: true,
    type: String,
  },
  accountOwnerId: {
    required: true,
    type: Number,
  },
  collaborator: {
    required: true,
    type: Object,
  },
});

const newRole = ref(null);
const collaboratorToEdit = ref(null);
const newAccountOwner = ref(null);

const setRoleAndCollaborator = (role, collaborator) => {
  if (!!role && !!collaborator && role.id === collaborator.role.id) return;

  collaboratorToEdit.value = collaborator;
  newRole.value = role;
};

const formatRole = role => role.name.replace('account ', '');

const { data: accountsRoles, isLoading: accountsRolesLoading } =
  useAccountsRoles();
</script>

<template>
  <div class="collaborators-list">
    <div v-if="accountOwnerId === collaborator.user_id">
      <SoonaDropdownMenu copy="account owner" variation="tertiary" size="large">
        <template #default="{ clickCapture, keydown, mouseover }">
          <SoonaDropdownMenuItem>
            <button
              class="collaborator-role-menu-item"
              role="menuitem"
              @click="newAccountOwner = collaborator"
              @click.capture="clickCapture"
              @keydown="keydown"
              @mouseover="mouseover"
            >
              change account owner
            </button>
          </SoonaDropdownMenuItem>
        </template>
      </SoonaDropdownMenu>
    </div>
    <div v-else>
      <BeatLoader v-if="accountsRolesLoading" size="0.5rem" />
      <SoonaDropdownMenu
        v-else
        :copy="formatRole(collaborator.role)"
        variation="tertiary"
        size="large"
      >
        <template #default="{ clickCapture, keydown, mouseover }">
          <SoonaDropdownMenuItemCheckbox
            v-for="role in accountsRoles"
            :key="role.id"
            role="menuitemradio"
            :checked="collaborator.role.name === role.name"
            @click="setRoleAndCollaborator(role, collaborator)"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            <span>{{ formatRole(role) }}</span>
          </SoonaDropdownMenuItemCheckbox>
        </template>
      </SoonaDropdownMenu>
    </div>
    <EditCollaboratorDialog
      v-if="collaboratorToEdit && newRole"
      :account-id="accountId"
      :collaborator="collaboratorToEdit"
      :collaborator-role-id="newRole.id"
      :collaborator-role-name="newRole.name"
      @close="setRoleAndCollaborator(null, null)"
    />
    <AccountOwnerDialog
      v-if="newAccountOwner"
      :account-id="accountId"
      :collaborator="newAccountOwner"
      @close="newAccountOwner = null"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.collaborator-role-menu-item {
  display: flex;
  justify-content: space-between;
}
</style>

import * as types from 'src/store/mutation-types';

import { registerUser } from '../../config/sentry';
import { identifyUser } from '../../config/logrocket';
import { groupV2, identify } from 'src/lib/segment/segment';
import { getGlobalAttrs } from 'src/lib/user/globalUser';

const state = {
  paymentToken: undefined,
  userID: undefined,
  userULID: undefined,
  anytimeToken: undefined,
  email: undefined,
  employee_type_department: undefined,
  name: undefined,
  phone: undefined,
  acceptTextMessages: false,
  avatar: undefined,
  username: undefined,
  softSignUp: undefined,
  reservations: [],
  capabilities: [],
  unpaidAdditionalCharges: [],
  accountId: undefined,
  accountULID: undefined,
  accounts: [],
  shopMoreFiles: [],
  referralCode: undefined,
  globalAttrs: {},
  marketingEmailConsent: false,
  roles: [],
};

const getters = {
  userName(state) {
    return state.name;
  },
  userAvatar(state) {
    return state.avatar;
  },
  unpaidAdditionalCharges(state) {
    return state.unpaidAdditionalCharges;
  },
  isAuthenticated(state) {
    return !!state.userID;
  },
  isSoftSignUp(state) {
    return state.softSignUp;
  },
  roles(state) {
    return state.roles;
  },
  currentUserId(state) {
    return state.userID;
  },
  accounts(state) {
    return state.accounts;
  },
  currentAccount(state) {
    return state.accounts.find(account => account.id == state.accountId) || {};
  },
};

const mutations = {
  /**
   * @deprecated
   * @description DO NOT directly use. this is synchronized automatically from
   * the useMe query in app.vue. Setting this directly will cause a mismatch of
   * data in the app. to update current user, reset, invalidate, or set data for
   * the "me" query
   */
  [types.SET_CURRENT_USER](state, { currentUser }) {
    if (!!currentUser && currentUser.id) {
      registerUser(currentUser);

      if (import.meta.env.VITE_LOG_ROCKET_APP_ID) {
        identifyUser(currentUser);
      }

      const currentAccount = currentUser.accounts.find(
        account => account.id === currentUser.account_id
      );

      identify(
        currentUser.ulid,
        getGlobalAttrs(currentUser, currentAccount, true)
      );

      if (currentAccount) {
        groupV2(currentAccount.ulid, currentAccount);
      }

      // store so userGlobalAttrs composable will send api response format rather than vuex store
      // this will be more reflective of using vue-query so we don't have multiple checks for different formats
      state.globalAttrs = {
        user: {
          ulid: currentUser?.ulid,
          name: currentUser?.name,
          email: currentUser?.email,
          id: currentUser?.id,
          employee_type_id: currentUser?.employee_type_id,
          provider: currentUser?.provider,
        },
        account: {
          ulid: currentAccount?.ulid,
          id: currentAccount?.id,
          subscription: currentAccount?.subscription,
        },
      };
    }

    state.userID = currentUser ? currentUser.id : undefined;
    state.userULID = currentUser ? currentUser.ulid : undefined;
    state.anytimeToken = currentUser ? currentUser.anytime_token : undefined;
    state.email = currentUser ? currentUser.email : undefined;
    state.username = currentUser ? currentUser.username : undefined;
    state.employee_type_department = currentUser
      ? currentUser.employee_type_department
      : undefined;
    state.name = currentUser ? currentUser.name : undefined;
    state.phone = currentUser ? currentUser.phone : undefined;
    state.acceptTextMessages = currentUser
      ? currentUser.accept_text_messages
      : false;
    state.marketing_email_consent = currentUser
      ? currentUser.marketing_email_consent
      : false;
    state.softSignUp = currentUser ? currentUser.soft_sign_up : undefined;
    state.avatar = currentUser ? currentUser.avatar_url : undefined;
    state.account_avatar = currentUser
      ? currentUser.account_avatar_url
      : undefined;
    state.accountId = currentUser ? currentUser.account_id : undefined;
    state.accountULID = currentUser ? currentUser.account_ulid : undefined;
    state.referralCode = currentUser ? currentUser.referral_code : undefined;
    state.accounts = currentUser ? currentUser.accounts : [];
  },
  [types.SET_PAYMENT_TOKEN](state, paymentToken) {
    state.paymentToken = paymentToken;
  },
  [types.SET_UNPAID_ADDITIONAL_CHARGES](state, unpaidAdditionalCharges) {
    state.unpaidAdditionalCharges = unpaidAdditionalCharges;
  },
};

const actions = {
  loadUnpaidAdditionalCharges({ commit }, accountId) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `accounts/${accountId}/orders.json?order_status[]=created&order_status[]=confirmed&order_type[]=additional_charge`
        )
        .then(response => {
          commit(types.SET_UNPAID_ADDITIONAL_CHARGES, response.data);
          resolve();
        }),
        error => {
          commit(`errors/${types.SET_ERRORS}`, error.response.data, {
            root: true,
          });
          reject(error);
        };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

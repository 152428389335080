import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {string | number} collectionId
 * @param {Object} [params]
 * @param {MaybeRef<number>} [params.activeAssetId]
 * @param {MaybeRef<number>} [params.itemsPerPage]
 * @param {MaybeRef<number>} [params.currentPage]
 * @param {MaybeRef<number>} [params.visibility]
 * @param {Object} [queryOptions]
 */
export function useAlbumCollectionDigitalAssets(
  collectionId,
  { activeAssetId, itemsPerPage, currentPage = 1, visibility } = {},
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.collectionAssets(collectionId, {
      activeAssetId,
      itemsPerPage,
      currentPage,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (activeAssetId) params['active_asset_id'] = toValue(activeAssetId);
      if (currentPage) params['page'] = toValue(currentPage);
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);
      if (visibility) params['visibility'] = visibility;

      const response = await http.get(
        `/collections/${toValue(collectionId)}/album_collection_digital_assets`,
        {
          params,
          signal,
        }
      );

      return {
        pagination: mapPaginationHeaders(response.headers),
        assets: response.data?.album_collection_digital_assets || [],
      };
    },
    ...queryOptions,
  });
}

<script setup>
import { computed, ref, onBeforeUnmount } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';

const props = defineProps({
  timezone: {
    type: String,
    required: true,
  },
});
dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

const currentTime = ref(new Date());

const updateCurrentTime = () => {
  currentTime.value = new Date();
};

const formattedTime = computed(() =>
  dayjs(currentTime.value).tz(props.timezone).format('h:mm a z')
);

const updateTimeInterval = setInterval(updateCurrentTime, 1000);

onBeforeUnmount(() => {
  clearInterval(updateTimeInterval);
});
</script>

<template>🌐 {{ formattedTime }}</template>

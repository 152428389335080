<script setup>
import SoonaNoResults from 'src/components/ui_library/SoonaNoResults.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import photoArrangementImg from 'images/empty-states/photo-arrangement.jpg';
import IntegrationBanner from '@/components/ui_library/IntegrationBanner.vue';
import {
  amazonAuthorizationRedirectUrl,
  shopifyAppUrl,
} from '@/lib/integrations/constants';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useFlag } from '@/composables/useFlag';

defineProps({
  accountId: {
    type: String,
    required: true,
  },
  productIntegrations: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  isSuccess: {
    type: Boolean,
    required: true,
  },
  isIntegrationsLoading: {
    type: Boolean,
    required: true,
  },
  showNoData: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['resetFilters']);

const productIntegrationBannerFlag = useFlag(
  'apollo_product_catalog_integration_banner'
);

const route = useRoute();
const { linkClicked } = useBaseEvents();

const trackLinkClicked = (importFrom, href) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'empty catalog',
    linkLabel: `import from ${importFrom}`,
    linkHref: href,
  });
};

const resetFilters = () => {
  emit('resetFilters');
};
</script>

<template>
  <div>
    <IntegrationBanner
      v-if="
        !productIntegrations.length &&
        productIntegrationBannerFlag &&
        showNoData &&
        !isLoading &&
        isSuccess
      "
      section="products"
    >
      <template #headline>manage all of the content for your products</template>
      <template #details>
        organize your products, create new content, and build a workflow around
        the products you sell.
      </template>
    </IntegrationBanner>
    <SoonaNoResults
      v-else-if="!isIntegrationsLoading"
      key="product-catalog-empty-search-filter"
    >
      <template #header>oops, we couldn’t find a match!</template>
      <template #body> try a different search or filter combination </template>
      <template #button>
        <SoonaButton variation="secondary-black" :on-click="resetFilters">
          reset filters
        </SoonaButton>
      </template>
    </SoonaNoResults>
    <SoonaNoResults
      v-else-if="!productIntegrationBannerFlag && showNoData"
      key="product-catalog-empty"
      mask="blob-empty"
      :image-source="photoArrangementImg"
    >
      <template #header>it all starts with your product</template>
      <template #body>
        organize your products. create new content. and build a workflow around
        the products you sell.
      </template>
      <template #button>
        <SoonaButton
          element="router-link"
          :to="{
            path: `/account/${accountId}/products/new`,
          }"
          variation="secondary-gray"
        >
          add product
        </SoonaButton>
        <SoonaButton
          :href="amazonAuthorizationRedirectUrl"
          element="a"
          variation="secondary-gray"
          @on-click="trackLinkClicked('amazon', amazonAuthorizationRedirectUrl)"
        >
          <SoonaIcon name="amazon-logo" /> import from Amazon
        </SoonaButton>
        <SoonaButton
          element="a"
          :href="shopifyAppUrl"
          variation="secondary-gray"
          @on-click="trackLinkClicked('shopify', shopifyAppUrl)"
        >
          <SoonaIcon name="shopify-logo" /> import from Shopify
        </SoonaButton>
      </template>
      <template #footer>
        <div class="product-catalog__empty__instructions">
          <h3 class="product-catalog__empty__instructions-header">
            4 ways to get started
          </h3>
          <ul class="product-catalog__empty__instructions-list">
            <li>
              <strong>identify</strong> inventory items from past shoots to
              connect products to bookings
            </li>
            <li><strong>add</strong> products that need content</li>
            <li>
              <strong>import</strong> your Shopify or Amazon Seller catalog to
              soona
            </li>
            <li><strong>create</strong> a new booking and ship products</li>
          </ul>
        </div>
      </template>
    </SoonaNoResults>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-catalog {
  &__empty {
    &__instructions {
      max-width: 25rem;
      padding: 1rem;
      border-top: 0.0625rem solid variables.$gray-30;
      border-bottom: 0.0625rem solid variables.$gray-30;
      margin-top: 2rem;

      &-header {
        @include variables_fonts.u-button--small-caps;
        text-align: left;
        margin-bottom: 0.5rem;
      }

      ul {
        list-style-type: disc;

        li {
          text-align: left;
        }
      }
    }
  }
}
</style>

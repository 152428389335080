import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { createUserAsStaff } from '@/api/users';

/**
 */
export function useCreateUserAsStaff() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: attributes => createUserAsStaff(attributes),
    onSuccess: async () =>
      await queryClient.invalidateQueries({ queryKey: queryKeys.user() }),
  });
}

import AddModels from './steps/AddModels.vue';
import BookingDetails from './steps/BookingDetails.vue';
import BookingDiscount from './steps/BookingDiscount.vue';
import BookingLocation from './steps/BookingLocation.vue';
import BookingType from './steps/BookingType.vue';
import BookingUpgrades from './steps/BookingUpgrades.vue';
import ContentPurpose from './steps/ContentPurpose.vue';
import ContinuationEmail from './steps/ContinuationEmail.vue';
import CrewNotes from './steps/CrewNotes.vue';
import CustomBookingEmail from './steps/CustomBookingEmail.vue';
import CustomerBookingType from './steps/CustomerBookingType.vue';
import CustomerDetails from './steps/customer/CustomerDetails.vue';
import CustomerPointOfContact from './steps/CustomerPointOfContact.vue';
import InStudioScheduling from './steps/InStudioScheduling.vue';
import MediaType from './steps/MediaType.vue';
import NumberOfPhotos from './steps/NumberOfPhotos.vue';
import PackageOption from './steps/PackageOption.vue';
import PhotoScenes from './steps/PhotoScenes.vue';
import StudioBuyOut from './steps/StudioBuyOut.vue';
import VideoScenes from './steps/VideoScenes.vue';
import VideoType from './steps/VideoType.vue';

const steps = {
  audio: StudioBuyOut,
  'continuation-email': ContinuationEmail,
  'crew-notes': CrewNotes,
  'custom-booking-email': CustomBookingEmail,
  customer: CustomerDetails,
  'customer-booking-type': CustomerBookingType,
  'customer-point-of-contact': CustomerPointOfContact,
  details: BookingDetails,
  discount: BookingDiscount,
  location: BookingLocation,
  'media-type': MediaType,
  models: AddModels,
  'package-option': PackageOption,
  'photo-quantity': NumberOfPhotos,
  'photo-scenes': PhotoScenes,
  purpose: ContentPurpose,
  schedule: InStudioScheduling,
  type: BookingType,
  upgrades: BookingUpgrades,
  'video-scenes': VideoScenes,
  'video-type': VideoType,
};

export function stepComponentLookup(stepName) {
  return steps[stepName] ?? null;
}

// Brian really wanted to write unit tests for this, soooooon
export function createStepSections(steps) {
  if (!Array.isArray(steps)) return [];

  return Object.values(
    steps.reduce((acc, step) => {
      // skip steps we don't have a component for
      if (!stepComponentLookup(step.slug)) return acc;

      // if the display_section is not set, fall back to the slug
      const section = step.display_section ?? step.slug;

      if (acc[section]) {
        // add the current step to an existing section
        acc[section].steps.push(step);
      } else {
        // create a new section and add the step to it's steps
        acc[section] = {
          // if there is no section, use the first step's button text
          title: step.display_section ?? step.button_text,
          // replace all non-alphanumeric characters with a hyphen
          id: `step-section--${section.replace(/\W/g, '-')}`,
          steps: [step],
        };
      }
      return acc;
    }, {})
  );
}

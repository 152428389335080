<template>
  <div class="soona-text-input">
    <input
      v-model="inputValue"
      class="text-input input"
      :placeholder="placeholderText"
      :class="inputClass"
      :type="type"
      :disabled="disabled"
      :min="minValue"
      :max="maxValue"
      :style="dynamicStyle"
      @keyup.enter.prevent="handleEnter"
    />
    <SoonaIcon
      v-if="type === 'search' || inputClass === 'type-selection-search-empty'"
      class="search-icon"
      name="search"
    />
  </div>
</template>

<script>
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'TextInput',
  components: {
    SoonaIcon,
  },
  props: {
    type: {
      default: 'default',
      type: String,
      required: false,
    },
    modelValue: [String, Number],
    disabled: Boolean,
    onChange: Function,
    onEnter: Function,
    placeholder: String,
    placeholderColor: {
      default: '#6d6e71',
      type: String,
      required: false,
    },
    maxValue: {
      type: String,
      required: false,
    },
    minValue: {
      type: String,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        if (this.onChange) {
          this.onChange(val);
        } else {
          this.$emit('update:modelValue', val);
        }
      },
    },
    dynamicStyle() {
      return {
        '--placeholder-color': this.placeholderColor,
      };
    },
    placeholderText() {
      if (this.placeholder) return this.placeholder;
      switch (this.type) {
        case 'search':
          return 'search';
        default:
          return '';
      }
    },
    inputClass() {
      if (this.type.includes('selection-search')) {
        if (this.inputValue === '' && this.type === 'selection-search-empty') {
          return 'type-selection-search-empty';
        } else {
          return 'type-selection-search-full';
        }
      }
      return `type-${this.type}`;
    },
  },
  methods: {
    handleEnter(e) {
      if (this.onEnter) {
        this.onEnter(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.soona-text-input {
  width: 100%;
  position: relative;

  .search-icon {
    position: absolute;
    width: 1.125rem;
    height: 1.125rem;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: variables.$gray-60;
  }

  .text-input {
    border: 1px solid variables.$gray-40;
    box-shadow: none;
    padding: 10px 8px;
    color: variables.$gray-60;
    &:disabled {
      background-color: variables.$gray-10;
    }
    &.type-search {
      padding: 10px 8px 10px 3rem;
    }
    &.type-selection-search-empty {
      color: variables.$black-default;
      padding: 10px 8px 10px 3rem;
      &:focus {
        border: 1px solid variables.$black-default;
      }
      &::placeholder {
        color: var(--placeholder-color);
      }
    }
    &.type-selection-search-full {
      padding: 8px;
      color: variables.$black-default;
      &:focus {
        border: 1px solid variables.$black-default;
      }
      &::placeholder {
        color: var(--placeholder-color);
      }
    }
    &::placeholder {
      opacity: 1;
    }
  }
}
</style>

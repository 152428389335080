<script setup>
import { computed, reactive } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { useStore } from 'vuex';
import AddTrackingNumber from 'src/components/user/anytime/shipping/AddTrackingNumber.vue';
import InventoryPicker from 'src/components/user/anytime/inventory/InventoryPicker.vue';
import ReturnAddressModal from '@/components/user/anytime/inventory/return-address/ReturnAddressModal.vue';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';

const props = defineProps({
  accountId: {
    default: undefined,
    type: Number,
    required: false,
  },
  slug: {
    default: undefined,
    type: String,
    required: true,
  },
  index: {
    default: undefined,
    type: Number,
    required: false,
  },
  isDisabled: {
    default: false,
    type: Boolean,
  },
  reservationId: {
    default: undefined,
    type: Number,
    required: false,
  },
});

const store = useStore();
const queryClient = useQueryClient();

const accountId = computed(() => props.accountId);
const reservationId = computed(() => props.reservationId);

// pso & return address
const packageToUpdate = computed(
  () => store.state.inventoryPackages.currentPackage?.id
);

const dialogs = reactive({
  inventoryPicker: false,
  returnAddress: false,
  trackingNumber: false,
});

const openReturnAddressClosePackageHandlingDialog = () => {
  dialogs.trackingNumber = false;
  dialogs.returnAddress = true;
};

const closeReturnAddressAndPackageHandlingDialog = () => {
  dialogs.trackingNumber = false;
  dialogs.returnAddress = false;
};

const openAddTrackingNumberModal = () => {
  dialogs.trackingNumber = true;
};
const closeAddTrackingNumberModal = () => {
  dialogs.trackingNumber = false;
};

const openInventoryPickerModal = () => {
  dialogs.inventoryPicker = true;
};
const closeInventoryPickerModal = () => {
  dialogs.inventoryPicker = false;

  queryClient.invalidateQueries({
    queryKey: queryKeys.reservationReadinessSteps(reservationId),
  });
};
</script>

<template>
  <div>
    <SoonaDropdownMenu
      v-if="slug === 'ship-fast-pass' || slug === 'ship-non-fast-pass'"
      data-cypress="dropdown-csr-shipping"
      copy="shipping options"
      :disabled="isDisabled"
      :variation="index === 0 ? 'primary' : 'secondary-black'"
    >
      <template #default="{ clickCapture, keydown, mouseover }">
        <SoonaDropdownMenuItem>
          <router-link
            role="menuitem"
            to="shipping"
            data-cypress="csr-create-shipping-label"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
            >create shipping label</router-link
          >
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem>
          <button
            data-cypress="dropdown-choice-csr-tracking-num"
            role="menuitem"
            @click="openAddTrackingNumberModal"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            add a tracking number
          </button>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem>
          <button
            role="menuitem"
            @click="openInventoryPickerModal"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            use stored products
          </button>
        </SoonaDropdownMenuItem>
      </template>
    </SoonaDropdownMenu>
    <AddTrackingNumber
      v-if="dialogs.trackingNumber"
      :reservation-id="reservationId"
      @open-return-address-close-tracking-number-dialog="
        openReturnAddressClosePackageHandlingDialog
      "
      @close-tracking-number-modal="closeAddTrackingNumberModal"
    />
    <ReturnAddressModal
      v-if="dialogs.returnAddress"
      :account-id="accountId"
      :package-to-update="packageToUpdate"
      @cancel="closeReturnAddressAndPackageHandlingDialog"
      @success="dialogs.returnAddress = false"
    />
    <InventoryPicker
      :account-id="accountId"
      :close-picker="closeInventoryPickerModal"
      :picker-is-open="dialogs.inventoryPicker"
      :reservation-id="reservationId"
    />
  </div>
</template>

import { computed } from 'vue';

export const usePlatformName = listing => {
  const platform = computed(() => {
    if (listing.value.integration_type === 'AmazonIntegration') {
      return 'Amazon';
    }
    if (listing.value.integration_type === 'ShopifyIntegration') {
      return 'Shopify';
    }
    return null;
  });

  return {
    platform,
  };
};

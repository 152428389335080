<template>
  <div v-if="canManageAccount" class="ProfileQuiz drop-shadow-container">
    <div class="profile-quiz-copy" data-cypress="profile-quiz">
      <p class="large-title mb-l hello-name">hi {{ account?.name }}! 👋</p>
      <p class="large-title mb-l">let's complete your profile!</p>
      <p class="large-small mb-l">
        our crew wants to get to know you better!
        <br />
        tell us about yourself so we can make your soona experience custom to
        you.
      </p>
      <ProgressBar />
    </div>
    <div class="profile-quiz-cards">
      <ProfileQuizCards />
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useCapability } from '@/composables/useCapability';
import ProfileQuizCards from 'src/components/user/anytime/profile/ProfileQuizCards.vue';
import ProgressBar from 'src/components/user/anytime/profile/quiz-progress/ProgressBar.vue';

export default {
  name: 'ProfileQuiz',
  components: { ProfileQuizCards, ProgressBar },
  props: {
    account: Object,
  },
  setup(props) {
    const accountId = computed(() => props.account?.id);

    const { hasCapability: canManageAccount } = useCapability({
      capability: 'manage_account',
      subjectType: 'account',
      subjectId: accountId,
    });

    return { canManageAccount };
  },
};
</script>
<style lang="scss">
.ProfileQuiz {
  background: #d9e7ff;
  display: flex;
  justify-content: space-between;
  .hello-name {
    margin: 10px 0 2px 0;
  }
  .profile-quiz-copy {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .profile-quiz-cards {
    width: 35%;
  }
}

@media screen and (max-width: 768px) {
  .ProfileQuiz {
    display: flex;
    flex-direction: column;
    .profile-quiz-copy {
      width: 100%;
    }
    .profile-quiz-cards {
      width: 100%;
    }
  }
}
</style>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {Number | Ref<Number>} [reservationId]
 * @param {Number | Ref<Number>} [accountId]
 */
export function useCreateSchedulingPreference(reservationId, accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async params => {
      const response = await http.post('/scheduling_preferences', {
        scheduling_preference: params,
      });

      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.schedulingPreference(
            'Reservation',
            reservationId
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.schedulingPreference('Account', accountId),
        }),
      ]);
    },
  });
}

<script setup>
import { computed } from 'vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';

const props = defineProps({
  sortBy: {
    type: Object,
    required: true,
  },
  options: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['updateSortBy']);

const sortBy = computed(() => props.sortBy);
const orderBy = computed({
  get() {
    return sortBy.value?.orderBy;
  },
  set(orderBy) {
    emit('updateSortBy', {
      orderBy: orderBy,
      direction: sortBy.value?.direction,
    });
  },
});
const direction = computed({
  get() {
    return sortBy.value?.direction;
  },
  set(direction) {
    emit('updateSortBy', {
      orderBy: sortBy.value?.orderBy,
      direction: direction,
    });
  },
});

const toggleSortDirection = () => {
  if (direction.value === 'asc') {
    direction.value = 'desc';
  } else {
    direction.value = 'asc';
  }
};
</script>
<template>
  <div
    class="soona-sort-by-dropdown"
    :class="[
      {
        'soona-sort-by-dropdown--desc': direction === 'desc',
      },
    ]"
  >
    <SoonaTooltip>
      <template #default="{ setRef, mouseenter, focus, mouseleave, blur }">
        <SoonaButton
          :ref="el => setRef(el)"
          class="soona-sort-by-dropdown__icon"
          variation="icon-plain-gray"
          aria-label="reverse sort order"
          @click="toggleSortDirection"
          @mouseenter="mouseenter"
          @focus="focus"
          @mouseleave="mouseleave"
          @blur="blur"
        >
          <SoonaIcon name="arrow-up" />
        </SoonaButton>
      </template>
      <template #tooltip-content> reverse sort order </template>
    </SoonaTooltip>
    <SoonaSelect v-model="orderBy" :options="options" />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-sort-by-dropdown {
  display: flex;
  gap: 1rem;

  &--desc {
    .soona-sort-by-dropdown__icon {
      transform: rotate(-180deg);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    background: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    color: variables.$gray-60;
    border-radius: 1.563rem;
    justify-content: center;
    cursor: pointer;
  }

  @media (prefers-reduced-motion: no-preference) {
    &__icon {
      transition: transform 0.25s;
    }
  }
}
</style>

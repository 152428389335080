<script setup>
import { computed, reactive, ref, watchEffect } from 'vue';
import { useCreateShot, useShot, useUpdateShot } from '@/queries/useShotList';
import { useMe } from '@/composables/user/useMe';
import { usePriorityError } from '@/composables/usePriorityError';
import { useReservation } from '@/composables/useReservation';
import { useTagCategories } from '@/queries/useTagCategories';
import { useGetTags } from '@/queries/useGetTags';
import BackdropColorSection from './BackdropColorSection.vue';
import BackdropTextureSection from './BackdropTextureSection.vue';
import EnvironmentSection from './EnvironmentSection.vue';
import ProductsSection from './ProductsSection.vue';
import ReferenceImageSection from './ReferenceImageSection.vue';
import SceneDescription from './SceneDescription.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaPropsSection from './SoonaPropsSection.vue';

const props = defineProps({
  activeSceneId: {
    default: null,
    type: [Number, String],
  },
  previousSceneNumber: {
    type: Number,
    default: 0,
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const emit = defineEmits(['exit-scene-view']);

const activeSceneId = computed(() => props.activeSceneId);
const reservationId = computed(() => props.reservationId);
const { currentUserId } = useMe();
const { shootType, reservationAccountId } = useReservation(reservationId);

const {
  data: shotReferenceImageTagCategory,
  isLoading: isLoadingShotReferenceImageTagCategory,
  error: shotReferenceImageTagCategoryError,
} = useTagCategories({ slug: 'shot-reference-image' });

const {
  data: shotContentTagCategoryTags,
  isLoading: isLoadingShotContentTagCategoryTags,
  error: shotContentTagCategoryTagsError,
} = useGetTags({ tag_category_slug: 'shot-content' });

const {
  data: activeScene,
  isLoading: isLoadingGetShot,
  error: getShotError,
} = useShot(reservationId, activeSceneId, {
  enabled: computed(() => !!activeSceneId.value),
});

const {
  mutate: createShot,
  isPending: isLoadingCreateShot,
  error: createShotError,
} = useCreateShot(reservationId);

const {
  mutate: updateShot,
  isPending: isLoadingUpdateShot,
  error: updateShotError,
} = useUpdateShot(reservationId);

const priorityError = usePriorityError(
  createShotError,
  updateShotError,
  getShotError,
  shotContentTagCategoryTagsError,
  shotReferenceImageTagCategoryError
);

const isLoading = computed(
  () =>
    isLoadingCreateShot.value ||
    isLoadingGetShot.value ||
    isLoadingShotContentTagCategoryTags.value ||
    isLoadingShotReferenceImageTagCategory.value ||
    isLoadingUpdateShot.value
);

const shootTypeId = computed(() => {
  return shotContentTagCategoryTags.value?.find(
    tag => tag.title.toLowerCase() === shootType.value
  )?.id;
});

const orderNumber = computed(() =>
  activeSceneId.value ? activeScene.value?.order : props.previousSceneNumber + 1
);

const sceneData = reactive({
  products: [],
  environment: null,
  backdrop_color: null,
  backdrop_texture: null,
  props: [],
  reference_images: [],
  description: '',
});

const showProductsRequiredError = ref(false);
const showEnvironmentRequiredError = ref(false);

const handleCancel = () => {
  emit('exit-scene-view');
};

const handleRemove = (type, selectionId) => {
  sceneData[type] = sceneData[type].filter(s => s.id !== selectionId);
};

const handleSelect = (type, selection) => {
  if (Array.isArray(sceneData[type]) && type !== 'props') {
    sceneData[type] = [...sceneData[type], selection].flat();
  } else {
    sceneData[type] = selection;
  }
};

const handleSave = () => {
  if (sceneData.products.length > 0 && sceneData.environment) {
    const environmentId = [{ tag_id: sceneData.environment?.id }];
    const backdropColorId = sceneData.backdrop_color
      ? [{ tag_id: sceneData.backdrop_color.id }]
      : [];
    const backdropTextureId = sceneData.backdrop_texture
      ? [{ tag_id: sceneData.backdrop_texture.id }]
      : [];
    const propIds = sceneData.props.map(d => ({ tag_id: d.id }));
    const existingReferenceImages = sceneData.reference_images
      .filter(d => d.tag_category_id)
      .map(d => ({ tag_id: d.id }));
    const newReferenceImages = sceneData.reference_images
      .filter(d => d.signed_id)
      .map(d => ({
        tag_attributes: {
          tag_category_id: shotReferenceImageTagCategory.value[0].id,
          account_id: reservationAccountId.value,
          image: d.signed_id,
          title: d.filename,
        },
      }));

    const existingCatalogItemIds = new Set(
      activeScene.value?.catalog_items?.map(ci => ci.id) ?? []
    );
    const newCatalogItemIds = new Set(
      sceneData.products.map(ci => ci.id) ?? []
    );
    // copy over existing selection to make sure any deleted items are marked for deletion
    const catalog_items_shots_attributes =
      activeScene.value?.catalog_items?.map(ci => ({
        id: ci.catalog_items_shot_id,
        catalog_item_id: ci.id,
        _destroy: newCatalogItemIds.has(ci.id) ? 0 : 1,
      })) ?? [];
    // add in the newly selected items
    sceneData.products.forEach(ci => {
      if (!existingCatalogItemIds.has(ci.id)) {
        catalog_items_shots_attributes.push({
          catalog_item_id: ci.id,
        });
      }
    });

    const params = {
      description: sceneData.description,
      order: orderNumber.value,
      shot_tags_attributes: [
        { tag_id: shootTypeId.value },
        ...environmentId,
        ...backdropColorId,
        ...backdropTextureId,
        ...propIds,
        ...existingReferenceImages,
        ...newReferenceImages,
      ],
      tag_categories: [],
      created_by_user_id: currentUserId.value,
      catalog_items_shots_attributes,
    };

    if (activeSceneId.value) {
      params.id = activeSceneId.value;
      updateShot(params, {
        onSuccess: () => {
          emit('exit-scene-view');
        },
      });
    } else {
      createShot(params, {
        onSuccess: () => {
          emit('exit-scene-view');
        },
      });
    }
  }

  showProductsRequiredError.value = !sceneData.products.length;
  showEnvironmentRequiredError.value = !sceneData.environment;
};

const shootTypeIcon = computed(() =>
  shootType.value === 'photo' ? 'camera' : 'video'
);

const showBackdropColorSection = computed(
  () => sceneData.environment?.title === 'Color Backdrop'
);

const showBackdropTextureSection = computed(
  () => sceneData.environment?.title === 'Textured Surface'
);

watchEffect(() => {
  if (activeScene.value) {
    let environment = null;
    let backdropColor = null;
    let backdropTexture = null;
    const props = [];
    const referenceImages = [];

    for (const shot_tag of activeScene.value.shot_tags) {
      if (shot_tag.tag.tag_category_title === 'Environment') {
        environment = shot_tag.tag;
      } else if (shot_tag.tag.tag_category_title === 'Backdrop Color') {
        backdropColor = shot_tag.tag;
      } else if (shot_tag.tag.tag_category_title === 'Textures') {
        backdropTexture = shot_tag.tag;
      } else if (shot_tag.tag.tag_category_title === 'Props') {
        props.push(shot_tag.tag);
      } else if (shot_tag.tag.tag_category_title === 'Reference Image') {
        referenceImages.push(shot_tag.tag);
      }
    }

    sceneData.products = activeScene.value.catalog_items ?? [];
    sceneData.description = activeScene.value.description ?? '';
    sceneData.environment = environment;
    sceneData.backdrop_color = backdropColor;
    sceneData.backdrop_texture = backdropTexture;
    sceneData.props = props;
    sceneData.reference_images = referenceImages;
  }
});
</script>

<template>
  <div v-if="reservationAccountId" class="customer-scene">
    <SoonaLoading
      v-if="isLoading"
      is-loading
      loading-text="loading scene"
      is-contained
    />
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <div class="customer-scene__header">
      <div class="customer-scene__header-left">
        <h3 class="u-headline--heavy">
          {{ activeSceneId ? 'edit' : 'new' }} scene
        </h3>
        <p class="u-label--regular">
          <SoonaIcon :name="shootTypeIcon" size="small" /> {{ shootType }}
        </p>
      </div>
      <div class="customer-scene__action-btns">
        <SoonaButton variation="tertiary" @on-click="handleCancel">
          cancel
        </SoonaButton>
        <SoonaButton :loading="isLoading" @on-click="handleSave">
          save
        </SoonaButton>
      </div>
    </div>
    <ProductsSection
      v-model:selected-catalog-items="sceneData.products"
      :account-id="reservationAccountId"
      :reservation-id="reservationId"
      :show-save-error="showProductsRequiredError"
    />
    <EnvironmentSection
      :selected-option="sceneData.environment"
      :show-save-error="showEnvironmentRequiredError"
      @handle-select="handleSelect"
    />
    <BackdropColorSection
      v-if="showBackdropColorSection"
      :selected-option="sceneData.backdrop_color"
      @handle-select="handleSelect"
    />
    <BackdropTextureSection
      v-if="showBackdropTextureSection"
      :selected-option="sceneData.backdrop_texture"
      @handle-select="handleSelect"
    />
    <SoonaPropsSection
      :selected-options="sceneData.props"
      @handle-remove="handleRemove"
      @handle-select="handleSelect"
    />
    <ReferenceImageSection
      :selected-options="sceneData.reference_images"
      @handle-remove="handleRemove"
      @handle-select="handleSelect"
    />
    <SceneDescription v-model:description="sceneData.description" />
    <div class="customer-scene__action-btns">
      <SoonaButton variation="tertiary" @on-click="handleCancel">
        cancel
      </SoonaButton>
      <SoonaButton :loading="isLoading" @on-click="handleSave">
        save
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.customer-scene {
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  box-shadow: variables.$elevation-0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  padding: 1.5rem;
  position: relative;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__header-left {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    p {
      align-items: center;
      color: variables.$gray-70;
      display: flex;
      gap: 0.25rem;
    }
  }

  &__action-btns {
    display: flex;
    gap: 1rem;

    &:last-of-type {
      justify-content: flex-end;
    }
  }
}
</style>

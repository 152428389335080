<script setup>
import ProServiceAssignmentItem from 'src/components/user/anytime/pro_service/ProServiceAssignmentItem.vue';
import ProServiceSelectionSummaryCard from './ProServiceSelectionSummaryCard.vue';

const props = defineProps({
  currentReservation: Object,
  crewAssignments: Object,
  pspServiceRequirements: Array,
});

function matchingRequirement(assignment) {
  let matchingRequirement = props.pspServiceRequirements.find(requirement => {
    if (assignment.pro_service_requirement_id) {
      return requirement.id === assignment.pro_service_requirement_id;
    } else {
      return requirement.numbered_title === assignment.crew_role;
    }
  });
  return matchingRequirement;
}
</script>

<template>
  <div class="ProServiceProviderServiceInfo">
    <div class="non-select-view">
      <span class="info-wrapper">
        <div
          v-for="assignment in crewAssignments"
          :key="assignment.id"
          class="assignment"
        >
          <ProServiceAssignmentItem
            :assignment="assignment"
            :reservation="currentReservation"
            :show-image="true"
          />
          <span>
            <ProServiceSelectionSummaryCard
              v-if="
                assignment.pro_service_requirement_id || assignment.crew_role
              "
              :reservation="currentReservation"
              :pro-service-requirement="matchingRequirement(assignment)"
            />
          </span>
          <hr />
        </div>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.ProServiceProviderServiceInfo {
  color: variables.$black-default;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  max-width: 73.5rem;
  padding: 1.5625rem 1.5625rem;
}
@media only screen and (max-width: variables.$screen-xxs-max) {
  .ProServiceProviderServiceInfo {
    padding: 0;
  }
}
</style>

import { cancelSubscriptionItem } from '@/api/subscriptions';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';

export function useCancelSubscriptionItem() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => cancelSubscriptionItem(body),
    onSuccess: async data => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.account(data.account_id),
      });
      await queryClient.invalidateQueries({ queryKey: queryKeys.capability() });
    },
  });
}

<script setup>
import { ref, toRefs } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SelectSourceAsset from '../SelectSourceAsset.vue';
import { useLifestyleActions } from '@/components/user/anytime/listing_insights/listing_actions/actions/lifestyle/useLifestyleActions';
import ListingActionUploadDialog from '../ListingActionUploadDialog.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import AmazonPublishImageSelector from '@/components/user/anytime/products/AmazonPublishImageSelector.vue';
import { usePlatformName } from '../usePlatformName';
import { useDisablePublishFlow } from '@/composables/useDisablePublishFlow';

const props = defineProps({
  accountId: { type: [String, Number], required: true },
  externalProductId: { type: String, default: null },
  integrationId: { type: String, default: null },
  listingAction: { type: Object, required: true },
});

// any update to listing action needs to happen in parent.
// all errors must be surfaced in parent.
// this component is only responsible for displaying buttons & dialogs.
const emit = defineEmits([
  'undoChanges',
  'handleUploadComplete',
  'handleLinkSourceAsset',
  'captureSelectSourceImageEvent',
  'handleUpgradeClick',
  'handleResetListingAction',
  'handlePublish',
  'handlePublishFromAmazonImageSelectorDialog',
  'handleDownload',
]);

const { accountId, externalProductId, integrationId, listingAction } =
  toRefs(props);

const { disablePublishFlow } = useDisablePublishFlow({
  accountId: accountId,
});

const { platform } = usePlatformName(listingAction);

const {
  canTakeAction,
  catalogItemId,
  optimizeButtonVariation,
  isBasicUserAndOutOfCredits,
  showUploadDialog,
  requiresManualImagePositionSelection,
  isPublishing,
} = useLifestyleActions(accountId, listingAction, {
  externalProductId,
  integrationId,
});

// select source image module
const showSelectImageSourceDialog = ref(false);
const selectSourceImage = () => {
  if (canTakeAction.value) {
    showSelectImageSourceDialog.value = true;
  }
  // if canTakeAction is false, handle showing subx modal from parent.
  emit('captureSelectSourceImageEvent');
};
const handleAssetSelected = digitalAssetId => {
  showSelectImageSourceDialog.value = false;
  emit('handleLinkSourceAsset', digitalAssetId);
};

// upload image module
const handleUploadComplete = digitalAssetId => {
  showUploadDialog.value = false;
  emit('handleUploadComplete', digitalAssetId);
};

// publish module
const showAmazonPublishDialog = ref(false);
const manuallySelectedSlot = ref(null);

const handleSlotSelected = payload => {
  if (listingAction.value.integration_type === 'AmazonIntegration') {
    return (manuallySelectedSlot.value = Number(payload[0].slot.slice(-1)) + 1);
  }
};

const handlePublish = () => {
  if (requiresManualImagePositionSelection.value) {
    showAmazonPublishDialog.value = true;
  }
  emit('handlePublish');
};

const publishFromAmazonImageSelectorDialog = () => {
  showAmazonPublishDialog.value = false;
  emit(
    'handlePublishFromAmazonImageSelectorDialog',
    manuallySelectedSlot.value
  );
};
</script>

<template>
  <div class="listing-action-active__buttons">
    <template v-if="listingAction.status === 'active'">
      <SoonaButton
        v-if="isBasicUserAndOutOfCredits"
        variation="pizzazz"
        @click="$emit('handleUpgradeClick')"
      >
        upgrade for more
      </SoonaButton>
      <template v-else>
        <SoonaButton
          :variation="optimizeButtonVariation"
          size="medium"
          @click="selectSourceImage"
        >
          create with AI
        </SoonaButton>
        <SoonaButton
          v-if="!disablePublishFlow && canTakeAction"
          variation="tertiary"
          size="medium"
          @click="showUploadDialog = true"
        >
          upload new
        </SoonaButton>
      </template>
    </template>
    <template v-else-if="listingAction.status === 'failed_to_publish'">
      <SoonaButton variation="primary" size="medium" @click="handlePublish">
        retry
      </SoonaButton>
      <SoonaButton
        variation="tertiary"
        size="medium"
        @click="$emit('handleResetListingAction')"
      >
        cancel
      </SoonaButton>
    </template>
    <template v-else-if="disablePublishFlow">
      <SoonaButton
        :variation="optimizeButtonVariation"
        size="medium"
        @click="$emit('handleDownload')"
      >
        download
      </SoonaButton>
    </template>
    <template v-else>
      <SoonaButton variation="solid-black" size="medium" @click="handlePublish">
        publish to {{ platform }}
      </SoonaButton>
      <SoonaButton
        variation="tertiary"
        size="medium"
        @click="$emit('undoChanges')"
      >
        start over
      </SoonaButton>
    </template>
  </div>
  <SelectSourceAsset
    v-if="showSelectImageSourceDialog"
    :platform="platform"
    :account-id="accountId"
    :catalog-item-id="catalogItemId"
    @asset-selected="handleAssetSelected"
    @close="showSelectImageSourceDialog = false"
  />
  <ListingActionUploadDialog
    v-if="showUploadDialog"
    :account-id="accountId"
    :platform="platform"
    @upload-complete="handleUploadComplete"
    @close="showUploadDialog = false"
  />
  <SoonaDialog
    v-if="showAmazonPublishDialog"
    @close="showAmazonPublishDialog = false"
  >
    <AmazonPublishImageSelector
      :account-id="accountId"
      :catalog-item-id="listingAction.catalog_item_id"
      :file="listingAction.replacement_asset"
      :listing-updating="isPublishing"
      :disabled-slots="[0, 9]"
      @slot-selected="handleSlotSelected"
    />
    <template #footer>
      <SoonaButton
        variation="primary"
        :loading="isPublishing"
        :disabled="!manuallySelectedSlot"
        @click="publishFromAmazonImageSelectorDialog"
      >
        publish to {{ platform }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.listing-action-active {
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
  }
}
</style>

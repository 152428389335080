<script setup>
import { computed } from 'vue';
const props = defineProps({
  errorCode: {
    type: Number,
    required: true,
  },
});
const errorCode = computed(() => props.errorCode);
</script>
<template>
  <section>
    <br />
    <br />
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-8">
          <div class="notification is-8">
            <h1 class="title">sorry, something went wrong…</h1>
            <div class="columns is-mobile">
              <div v-if="errorCode === 403" class="column is-12">
                your bookings are private. if someone gave you this link, you’ll
                need to sign in to the account which owns the booking to view
                your content.
              </div>
              <div v-if="errorCode === 404" class="column is-12">
                we couldn’t find your booking, if this continues to happen
                please reach out!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { getBagCount } from '@/api/bag';
import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {string | number} accountId
 * @param {Object} [params]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {UseQueryOptions} queryOptions
 */
export function useBag(
  accountId,
  { currentPage, itemsPerPage } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.bag(accountId, { currentPage, itemsPerPage }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (currentPage) params['page'] = toValue(currentPage);
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);

      const response = await http.get(`accounts/${toValue(accountId)}/bag`, {
        params,
        signal,
      });

      return {
        pagination: mapPaginationHeaders(response.headers),
        data: response.data,
      };
    },
    gcTime: 30 * 60 * 1000,
    staleTime: 30 * 60 * 1000,
    ...queryOptions,
  });
}

export function useBagCount(accountId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.bagCount(accountId),
    queryFn: ({ signal }) => getBagCount(toValue(accountId), signal),
    gcTime: 30 * 60 * 1000,
    staleTime: 30 * 60 * 1000,
    ...queryOptions,
  });
}

<script setup>
import { computed, toRef, watchEffect, ref } from 'vue';
import BookingLayout from '../BookingLayout.vue';
import faqData from './faq.json';
import SoonaClickableCard from 'src/components/booking/v3/p2/SoonaClickableCard.vue';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useReservation } from 'src/queries/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { usePriorityError } from 'src/composables/usePriorityError';

import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';

// props and data
const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

// Segment events
const { itemSelected } = useBaseEvents();

// api calls
const {
  data: reservation,
  isLoading,
  error,
} = useReservation(toRef(props, 'reservationId'));

const {
  data: contentType,
  status,
  error: tagsError,
} = useReservationTags(toRef(props, 'reservationId'), toRef(props, 'stepName'));

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(toRef(props, 'reservationId'));

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(toRef(props, 'reservationId'), {
  // skip invalidation so that we only update the reservation after the add has finished
  skipInvalidate: true,
});

// computed
const isBusy = computed(() => {
  return isLoading.value || isAdding.value || isRemoving.value;
});
let currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});
const isStepValid = computed(() => {
  return !isBusy.value && currentStep.value?.complete;
});

const contentTypeOptions = computed(() => {
  return contentType.value.options || [];
});

const priorityError = usePriorityError(removeError, addError, tagsError, error);

// methods
function onBackClick() {
  props.onBack();
}

function onNextClick() {
  props.onNext();
}

function addSelectedOption(selectedOption) {
  addReservationTag(
    { tagId: selectedOption.tag.id },
    {
      onSuccess: () => {
        itemSelected({
          context: 'booking',
          subContext: 'media type',
          itemLabel: selectedOption.tag.title,
          itemPrice: 0,
          itemQuantity: 1,
        });
      },
    }
  );
}

function onOptionChange(selectedOption) {
  if (isBusy.value) return;

  const existingSelected = contentTypeOptions.value.find(
    option => option.selected && option.tag.id !== selectedOption.tag.id
  );
  if (existingSelected) {
    removeReservationTag(
      {
        reservationTagId: existingSelected.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSuccess: () => {
          addSelectedOption(selectedOption);
        },
      }
    );
  } else {
    addSelectedOption(selectedOption);
  }
}

const hasCompleted = ref(false);
watchEffect(() => {
  if (currentStep.value?.complete && !hasCompleted.value) {
    hasCompleted.value = true;
  }
});

const imageList = tag => {
  const imgs = [{ src: tag.image_url, alt: '' }];
  if (tag.image_secondary_urls) {
    if (tag.image_secondary_urls[0])
      imgs.push({ src: tag.image_secondary_urls[0], alt: '' });
    if (tag.image_secondary_urls[1])
      imgs.push({ src: tag.image_secondary_urls[1], alt: '' });
  }
  return imgs;
};
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
      :no-sidecart="!hasCompleted && !isAdding"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div
        v-if="status === 'success'"
        class="content-type prebooking-transition__content"
      >
        <SoonaLoading
          v-if="!contentTypeOptions"
          :is-loading="!contentTypeOptions"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading>create a photo or video shoot</SoonaHeading>
        <div class="content-type__grid">
          <SoonaClickableCard
            v-for="ct in contentTypeOptions"
            :key="ct.tag.id"
            :card-data="ct"
            :is-disabled="isBusy"
            :image-list="imageList(ct.tag)"
            :is-selected="ct.selected"
            :on-card-click="onOptionChange"
            :title="`${ct.tag.title.toLowerCase()} shoot`"
          />
        </div>
        <SoonaFaq :faq-tab-list="faqData" />
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss">
@use '@/variables';

// TODO: Revisit this approach to make photo shoot
// and video shoot icons same height on desktop
.content-type__grid {
  .soona-clickable-card__figure,
  .soona-clickable-card__image--primary {
    height: 6.625rem;

    @media (min-width: variables.$screen-sm-min) {
      height: 11rem;
    }
  }
}
</style>

<style lang="scss" scoped>
@use '@/variables';

.content-type {
  &__grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    max-width: 50rem;

    @media (min-width: variables.$screen-xxs-min) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: variables.$screen-xs-min) {
      gap: 2rem;
    }
  }
}
</style>

import { useQuery } from '@tanstack/vue-query';
import { getTiers } from '@/api/tiers';
import { queryKeys } from '@/queries/query-keys';

/**
 * @param {MaybeRef<string | number>} id
 * @param {Object} [queryOptions]
 */
export function useTiers(queryOptions) {
  return useQuery({
    queryKey: queryKeys.tiers(),
    queryFn: ({ signal }) => getTiers(signal),
    ...queryOptions,
  });
}

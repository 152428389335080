import * as Sentry from '@sentry/vue';

export const initSentry = ({ app, dsn, router }) => {
  const tracePropagationTargets = ['book.soona.co', /^\//];

  const ignoreErrors = [
    /Failed to execute 'send' on 'WebSocket': Still in CONNECTING state/,
    /null is not an object \(evaluating 'this\.socket\.send'\)/,
  ];

  if (import.meta.env.DEV) {
    tracePropagationTargets.push('localhost:3000');
    ignoreErrors.push(/Pinterest Tag Error/);
  }

  Sentry.init({
    app,
    dsn: dsn,
    release: import.meta.env.VITE_HEROKU_RELEASE_VERSION,
    environment: import.meta.env.VITE_SOONA_ENV,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
    ignoreErrors: ignoreErrors,
    tracePropagationTargets,
  });
};

export const registerUser = ({ email, id }) => {
  Sentry.getCurrentScope().setUser({ email, id });
};

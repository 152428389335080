import { ref, watch } from 'vue';

export const useListingsListMinHeight = listings => {
  const minHeight = ref('auto');
  watch(
    () => listings.value.length,
    (newValue, oldValue) => {
      if (oldValue > 0 && newValue === 0) {
        return;
      }
      if (oldValue === 0 && newValue > 0) {
        // listing card is 4.5rem min-height with gap of 1rem between each in the list
        minHeight.value = `${4.5 * newValue + 1 * (newValue - 1)}rem`;
      }
      if (oldValue > 0 && newValue > 0) {
        minHeight.value = `${4.5 * newValue + 1 * (newValue - 1)}rem`;
      }
    }
  );

  return { minHeight };
};

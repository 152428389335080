// import segmentMixin from 'src/mixins/segmentMixin';
import * as types from 'src/store/mutation-types';
import {
  deleteInventoryPackagesPackageId,
  getInventoryPackages,
  getInventoryPackagesPackageId,
  postInventoryPackages,
  putInventoryPackagesPackageId,
} from '@/api/inventoryPackages';
import {
  deleteInventoryPackagesReservations,
  postInventoryPackagesReservations,
} from '@/api/inventoryPackagesReservations';
import {
  getTransferPackageRequirements,
  putTransferPackageRequirementsTransferId,
} from '@/api/transferPackageRequirements';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from '@/queries/query-client';
import { queryKeys } from '../../queries/query-keys';

const state = {
  currentPackage: {},
  loadedPackages: [],
  pickablePackages: [],
  packageIdsToReturn: [],
  transferPackageRequirements: [],
};

const getters = {
  returnablePackages() {
    return state.loadedPackages.filter(ip =>
      state.packageIdsToReturn.includes(ip.id)
    );
  },
  packagesSelectedForReturn() {
    return state.packageIdsToReturn.length > 0;
  },
};

const mutations = {
  [types.SET_PACKAGES](state, newPackages) {
    state.loadedPackages = newPackages;
  },
  [types.SET_CURRENT_PACKAGE](state, newPackage) {
    state.currentPackage = newPackage;
  },
  [types.SET_PICKABLE_PACKAGES](state, newPackages) {
    state.pickablePackages = newPackages;
  },
  [types.SET_PACKAGE_IDS_TO_RETURN](state, packageIds) {
    state.packageIdsToReturn = packageIds;
  },
  [types.SET_TRANSFER_PACKAGE_REQUIREMENTS](state, transferPackages) {
    state.transferPackageRequirements = transferPackages;
  },
};

const actions = {
  addPackageToReservation(
    { dispatch },
    { accountId, packageId, reservationId }
  ) {
    // segmentMixin.methods.$track('Stored Package Selected', {
    //   reservation_id: reservationId,
    //   package_id: packageId,
    //   account_id: accountId,
    // });

    return postInventoryPackagesReservations({
      packageId,
      reservationId,
    }).then(
      () => {
        dispatch('loadPickablePackages', {
          accountId: accountId,
          skipReservationId: reservationId,
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.inventoryPackages(),
        });
        return dispatch('loadPackages', { reservationId: reservationId });
      },
      error => {
        return [null, error.response];
      }
    );
  },
  loadPackage({ commit }, { packageId }) {
    return getInventoryPackagesPackageId(packageId).then(
      response => {
        commit(types.SET_CURRENT_PACKAGE, response.data);
        return [response.data, null];
      },
      error => {
        return [null, error.response];
      }
    );
  },
  loadPackages({ commit }, { accountId, reservationId }) {
    return getInventoryPackages({ accountId, reservationId }).then(
      data => {
        commit(types.SET_PACKAGES, data.inventoryPackages);
        return [data.inventoryPackages, null];
      },
      error => {
        return [null, error.response];
      }
    );
  },
  loadPickablePackages({ commit }, { accountId, skipReservationId }) {
    return getInventoryPackages({
      accountId,
      pickable: 1,
      skipReservationId,
    }).then(
      data => {
        commit(types.SET_PICKABLE_PACKAGES, data.inventoryPackages);
        return [data.inventoryPackages, null];
      },
      error => {
        return [null, error.response];
      }
    );
  },
  loadTransferPackages({ commit }, { accountId, page, search }) {
    return getTransferPackageRequirements({ accountId, page, search }).then(
      data => {
        commit(types.SET_TRANSFER_PACKAGE_REQUIREMENTS, data);
        return [data, null];
      },
      error => {
        return [null, error.response];
      }
    );
  },
  createPackage({ commit, dispatch }, { packageAttributes }) {
    return postInventoryPackages(packageAttributes).then(
      response => {
        commit(types.SET_CURRENT_PACKAGE, response.data);
        dispatch('loadPackages', {
          reservationId: packageAttributes.reservation_id,
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.inventoryPackages(),
        });
        return [response.data, null];
      },
      error => {
        return [null, error.response];
      }
    );
  },
  async updatePackage({ commit }, { packageId, packageAttributes }) {
    try {
      const response = await putInventoryPackagesPackageId(packageId, {
        packageAttributes,
      });
      commit(types.SET_CURRENT_PACKAGE, response.data);
      await queryClient.invalidateQueries({
        queryKey: queryKeys.inventoryPackages(),
      });
      return [response.data, null];
    } catch (e) {
      return [null, e.response];
    }
  },
  loadAccountTransferPackage({ commit, rootState }) {
    return getTransferPackageRequirements({
      accountId: rootState.account.id,
    }).then(
      data => {
        commit(types.SET_TRANSFER_PACKAGE_REQUIREMENTS, data);
        return [data, null];
      },
      error => {
        return [null, error.response];
      }
    );
  },
  updateTransferPackage(
    { dispatch },
    { transferId, status, tracking_number, shipping_provider, notes }
  ) {
    return putTransferPackageRequirementsTransferId(transferId, {
      status,
      tracking_number,
      shipping_provider,
      notes,
    })
      .then(response => {
        dispatch('loadAccountTransferPackage');
        queryClient.invalidateQueries({
          queryKey: queryKeys.transferPackageRequirements(),
        });
        return response;
      })
      .catch(error => {
        console.error('error', error);
        return error;
      });
  },
  deletePackage({ dispatch }, { packageAttributes }) {
    return new Promise((resolve, reject) => {
      deleteInventoryPackagesPackageId(packageAttributes.inventoryPackageId)
        .then(() => {
          dispatch('loadPackages', {
            reservationId: packageAttributes.reservationId,
          });
          queryClient.invalidateQueries({
            queryKey: queryKeys.inventoryPackages(),
          });
          queryClient.invalidateQueries({
            queryKey: queryKeys.reservation(packageAttributes.reservationId),
          });
          resolve();
        })
        .catch(error => {
          console.error(error, 'failed to delete package');
          reject(error);
        });
    });
  },
  removePackage({ dispatch }, { inventoryPackageId, reservationId }) {
    return new Promise((resolve, reject) => {
      deleteInventoryPackagesReservations({ inventoryPackageId, reservationId })
        .then(() => {
          dispatch('loadPackages', {
            reservationId: reservationId,
          });
          queryClient.invalidateQueries({
            queryKey: queryKeys.inventoryPackages(),
          });
          queryClient.invalidateQueries({
            queryKey: queryKeys.reservation(reservationId),
          });
          resolve();
        })
        .catch(error => {
          console.error(error, 'failed to remove package');
          reject(error);
        });
    });
  },
  stagePackagesForReturn({ commit }, { inventoryPackageIds }) {
    return commit(types.SET_PACKAGE_IDS_TO_RETURN, inventoryPackageIds);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { prefetchTierBySlug } from '@/queries/tiers/useTierBySlug';

export const subscriptionsPaths = [
  {
    path: '/account/:accountId/subscriptions',
    component: () => import('@/components/subscriptions/SubscriptionsPage.vue'),
    props: route => ({
      accountId: route.params.accountId,
    }),
    meta: {
      context: 'subscription management',
      requires_auth: true,
      requires_capability: [
        {
          capability: 'view_account_subscription',
          subjectType: 'account',
          subjectId: to => to.params.accountId,
        },
        'crew_manage_subscription',
      ],
    },
  },
  {
    path: '/account/:accountId/subscriptions/add-ons/:tierSlug',
    component: () =>
      import('@/components/subscriptions/SubscriptionsAddOnPage.vue'),
    props: route => ({
      accountId: route.params.accountId,
      tierSlug: route.params.tierSlug,
    }),
    beforeEnter: async (to, from, next) => {
      const product = await prefetchTierBySlug(to.params.tierSlug);
      if (product) {
        next();
      } else {
        next({ path: `/account/${to.params.accountId}/subscriptions` });
      }
    },
    meta: {
      context: 'subscription add-ons',
      requires_auth: true,
      requires_capability: [
        {
          // todo, determine if this could be view, and move the manage to inside the page
          capability: 'manage_account_subscription',
          subjectType: 'account',
          subjectId: to => to.params.accountId,
        },
        'crew_manage_subscription',
      ],
    },
  },
];

<template>
  <div
    class="new-album-btn"
    :class="{ 'liked-gallery': active }"
    @click="changeCalendarView"
  >
    <img v-if="active" :src="lightIcon" :class="iconClass" />
    <img v-else :src="darkIcon" :class="iconClass" />
  </div>
</template>

<script>
export default {
  name: 'ChangeCalendarView',
  props: {
    active: Boolean,
    type: String,
    darkIcon: String,
    lightIcon: String,
    iconClass: String,
  },
  emits: ['changeCalendarView'],
  methods: {
    changeCalendarView() {
      this.$emit('changeCalendarView', this.type);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.new-album-btn {
  border: none;
  border-radius: 0.1875rem;
  box-shadow: 0 0.1875rem 0.375rem #00000029;
  cursor: pointer;
  display: inline-block;
  font-size: 1.25rem;
  height: 1.875rem;
  line-height: 0.9375rem;
  margin-left: 0.375rem;
  padding: 0.1875rem 0.25rem 0.1875rem 0.3125rem;
  text-decoration: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.liked-gallery {
  background: variables.$friendly-red-50;
  border-radius: 0.1875rem;
  color: variables.$white-default;
  max-width: inherit;
}
</style>

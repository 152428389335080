import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useCreateCredit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.post('/credits', { credit: body });
      return response.data;
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.accountCreditInfo(variables.account_id),
      });
    },
  });
}

import * as types from 'src/store/mutation-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import sortBy from 'lodash/sortBy';
import dateTimeMixin from 'src/mixins/dateTimeMixin';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from 'src/queries/query-client';
import { queryKeys } from 'src/queries/query-keys';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

const reservationTypes = [
  {
    name: 'in_studio',
    description: 'austin. minneapolis. denver. seattle.',
  },
  {
    name: 'anytime',
    description: 'join from anywhere!',
  },
  {
    name: 'internal',
    description: 'for soona use only.',
  },
  {
    name: 'surprise',
    description: 'for soona use only.',
  },
  {
    name: 'headshots_for_all',
    description: 'austin. minneapolis. denver. seattle.',
  },
];

const nonProServiceTags = [
  '1 - 3',
  '1 - 5', // in production these are different
  '3 - 15',
  '5 - 15', // in production these are different
  '15+',
  'in-studio',
  'online',
  'internal',
  'surprise',
  'h4all',
  'studio buy-out',
  'headshots_for_all',
];

const proServiceTypes = [
  'hand model',
  'full body model',
  'face model',
  'pet model',
  'styling',
  'prop shopping',
  'hair & makeup',
  'vip producer',
];

const getInitialState = () => ({
  products: [],
  availability: [
    { available: false, slot: '8:00 am' },
    { available: false, slot: '9:00 am' },
    { available: false, slot: '10:00 am' },
    { available: false, slot: '11:00 am' },
    { available: false, slot: '12:00 pm' },
    { available: false, slot: '1:00 pm' },
    { available: false, slot: '2:00 pm' },
    { available: false, slot: '3:00 pm' },
    { available: false, slot: '4:00 pm' },
    { available: false, slot: '5:00 pm' },
  ],
  shootType: undefined,
  serviceLevel: undefined,
  fullStudio: undefined,
  duration: undefined,
  startDate: undefined,
  startTime: undefined,
  description: undefined,
  discount: undefined,
  scheduleStatus: undefined,
  loadingSuggestedSlots: false,
  bookingStyle: undefined,
  draftType: null,
  suggestedBooking: false,

  // this is the object we PUT/POST
  reservation: {
    start: undefined,
    end: undefined,
    description: undefined,
    bookable_space_ids: [],
    crew: [],
    reservation_tags: [],
    reservation_line_items: [],
    reservation_type: undefined,
    name: undefined,
    website: undefined,
    social_media_tag: undefined,
    moodboard: undefined,
    product_size: undefined,
    referrer: undefined,
    industry: undefined,
    cal_uuid: undefined,
    user_id: undefined,
    account_id: undefined,
    schedule_status: undefined,
    point_of_contact_user_id: undefined,
    reschedulable: false,
    location_id: undefined,
    suggested_booking_email_body: undefined,
    draft_type: undefined,
    previous_id: undefined,
    notes_attributes: [],
    custom_booking_email: {},
  },
  shippingDetails: [],
});

const state = getInitialState();

const getters = {
  isPhoto(getters) {
    return getters.shootType === 'photo';
  },
  isVideo(getters) {
    return getters.shootType === 'video';
  },
  isInStudio(state) {
    return state.reservation.reservation_type === 'in_studio';
  },
  isAnytime(state) {
    return state.reservation.reservation_type === 'anytime';
  },
  isSurprise(state) {
    return state.reservation.reservation_type === 'surprise';
  },
  isHeadshotsForAll(state) {
    return state.reservation.reservation_type === 'headshots_for_all';
  },
  isFree(state) {
    return (
      state.reservation.reservation_type === 'headshots_for_all' ||
      state.reservation.reservation_type === 'internal' ||
      state.reservation.reservation_type === 'surprise'
    );
  },
  isDateSelected: state => date => {
    return (
      dateTimeMixin.methods.convertTodddDDMMMYYY(date) ==
      dateTimeMixin.methods.convertTodddDDMMMYYY(state.startDate)
    );
  },
  isReschedulable(state) {
    return !!state.reservation.reschedulable;
  },
  rescheduleUrl(state) {
    return state.reservation.reschedule_url;
  },
  isAnySlotSelected(state) {
    return state.startTime;
  },
  isSlotSelected: state => slot => {
    return state.startTime === slot;
  },
  isSlotUnavailable: state => slot => {
    return !state.startDate || !slot.available;
  },
  purchasedProServices: state => state.reservation.purchased_pro_services,
  purchasedProServicesNames: state => {
    return state.reservation.purchased_pro_services_names;
  },
  shootType(state) {
    var first_rli = state.reservation.reservation_line_items[0];
    if (first_rli) {
      let product = state.products.find(p => p.id == first_rli.product_id);
      if (product) {
        return product.shoot_type;
      }
    }
    return '';
  },
  duration(state) {
    return state.duration;
  },
  isPaid({ reservation }) {
    return reservation.order_status == 'paid';
  },
  isConfirmed({ reservation }) {
    return reservation.order_status == 'confirmed';
  },
  checkNoDownPayment({ reservation }) {
    return (
      reservation.order_status == 'confirmed' &&
      reservation.down_payment_order_provider == 'stripe'
    );
  },
  reservationTypes() {
    return reservationTypes;
  },
  nonProServiceTags() {
    return nonProServiceTags;
  },
  proServiceTypes() {
    return proServiceTypes;
  },
  bookableSpaceIds(state) {
    return state.reservation.bookable_space_ids;
  },
  reservationLineItems(state) {
    return sortBy(state.reservation.reservation_line_items, ['product_id']);
  },
  suggestedBookingProcess(state) {
    return state.suggestedBooking;
  },
  locationId(state) {
    return state.reservation.location_id;
  },
  canBeScheduledAtLocation(state) {
    return state.reservation.can_be_scheduled_at_location;
  },
};

const mutations = {
  [types.SET_RESCHEDULABLE_RESERVATION](state, reschedulable) {
    state.reservation = { ...state.reservation, reschedulable };
  },
  [types.SET_RESERVATION](state, { reservation, timezone }) {
    state.reservation = reservation;
    if (state.reservation.start === null) {
      state.duration = 2 * 60 * 60; //default duration
    } else {
      const start = dayjs(state.reservation.start);
      const end = dayjs(state.reservation.end);
      const duration = dayjs.duration(end.diff(start));
      const hours = duration.asHours();
      state.duration = (Math.round(hours * 2) / 2) * 60 * 60;
    }
    state.startDate = state.reservation.start;
    state.startTime = dayjs(state.startDate).tz(timezone).format('h:mm A');
    state.description = reservation.description;
    state.reservation.crew = reservation.crew_assignments_attributes;
    state.scheduleStatus = state.reservation.schedule_status;
    state.point_of_contact_user_id = state.reservation.point_of_contact_user_id;
    state.custom_booking_email = state.reservation.custom_booking_email;
  },
  [types.RESET_RESERVATION](state) {
    Object.assign(state, getInitialState());
  },
  [types.CANCEL_RESERVATION](state) {
    state.reservation.order_status = 'canceled';
  },
  [types.ADD_RESERVATION_TAG](state, tag) {
    if (!state.reservation.reservation_tags.some(t => t.tag_id === tag.id)) {
      state.reservation.reservation_tags.push({ tag_id: tag.id });
    }
  },
  [types.REMOVE_RESERVATION_TAG](state, tag) {
    let reservationTags = state.reservation.reservation_tags;
    let index = reservationTags.findIndex(rt => rt.tag_id == tag.id);
    if (index >= 0) {
      state.reservation.reservation_tags.splice(index, 1);
    }
  },
  [types.SET_BOOKABLE_SPACE_ID](state, spaceId) {
    state.reservation.bookable_space_ids = [spaceId];
  },
  [types.SET_PRODUCTS](state, products) {
    state.products = products;
  },
  [types.REMOVE_RESERVATION_LINE_ITEM](state, reservationLineItemId) {
    state.reservation.reservation_line_items =
      state.reservation.reservation_line_items.filter(reservation_line_item => {
        return reservation_line_item.id !== reservationLineItemId;
      });
  },
  [types.UPDATE_DURATION](state, duration) {
    // updating of duration not supported for non-fixed rate reservations (as determined by the studio rental)
    if (
      (state.reservation.order_status === 'paid' ||
        state.reservation.order_status === 'confirmed') &&
      state.duration !== duration
    ) {
      state.duration = duration;
      state.reservation.end = dayjs(state.reservation.start)
        .add(duration, 'seconds')
        .subtract(1, 'seconds');
    }
  },
  // THIS IS USED ONLY WHEN USING SOONA GENIE TO UPDATE A RESERVATION
  [types.SET_RESERVATION_START_AND_END](
    state,
    { startDate, startTime, timezone }
  ) {
    let start = dayjs.tz(
      `${startDate.date} ${startTime.time}`,
      'YYYY-M-D h:mm a',
      timezone
    );
    let end = dayjs(start)
      .add(state.duration, 'seconds')
      .subtract(1, 'seconds');

    state.reservation.start = start;
    state.reservation.end = end;
  },
  [types.SET_START_DATE](state, { startDate }) {
    if (state.startDate && dayjs(startDate).isSame(state.startDate, 'day')) {
      // if these are the same dates, then don't clear anything out.
      // was causing issues with UpdateReservationModal initial load of reservation start date.
      return;
    }

    // reset start time since availability can change based on new date
    state.reservation.start = undefined;
    state.startTime = undefined;
    state.reservation.end = undefined;
    state.availability = [];
    state.startDate = startDate;
  },
  [types.SET_START_TIME](state, { startTime, timezone }) {
    state.startTime = startTime;
    if (state.startDate) {
      let dayjsStart = dayjs(state.startDate).format('YYYY-MM-DD');
      state.reservation.start = dayjs.tz(
        `${dayjsStart} ${startTime}`,
        'YYYY-MM-DD h:mm a',
        timezone
      );
      state.reservation.end = dayjs(state.reservation.start)
        .add(state.duration, 'seconds')
        .subtract(1, 'seconds');
    }
  },
  [types.SET_SCHEDULE_STATUS](state, scheduleStatus) {
    state.scheduleStatus = scheduleStatus;
    state.reservation.schedule_status = scheduleStatus;
  },
  [types.SET_AVAILABILITY](state, availability) {
    state.availability = availability;
  },
  [types.ADD_RESERVATION_LINE_ITEMS](state, tag) {
    tag.tag_products.forEach(tp => {
      //if current location products includes this tag product
      if (state.products.find(p => p.id == tp.product_id)) {
        //create a new rli if we don't have one for the product or just increment the quantity
        let existingRli = state.reservation.reservation_line_items.find(
          rli => rli.product_id == tp.product_id
        );
        if (existingRli) {
          existingRli.quantity += tp.quantity;
        } else {
          state.reservation.reservation_line_items.push({
            product_id: tp.product_id,
            quantity: tp.quantity,
          });
        }
      }
    });
  },
  [types.REMOVE_RESERVATION_LINE_ITEMS](state, { tag, removeAll }) {
    tag.tag_products.forEach(tp => {
      //if current location products includes this tag product
      if (state.products.find(p => p.id == tp.product_id)) {
        //find the rli for this product
        let rli = state.reservation.reservation_line_items.find(
          rli => rli.product_id == tp.product_id
        );
        if (rli) {
          if (removeAll) {
            rli.quantity = 0;
          } else {
            rli.quantity -= tp.quantity;
          }
          if (rli.quantity <= 0) {
            //splice out the rli
            let index = state.reservation.reservation_line_items.indexOf(rli);

            state.reservation.reservation_line_items.splice(index, 1);
          }
        } else {
          console.error('error removing reservation line item');
        }
      }
    });
  },
  [types.SET_ACCOUNT_ID](state, accountId) {
    state.reservation.account_id = accountId;
  },
  [types.SET_SHIPPING_DETAILS](state, shippingDetails) {
    state.shippingDetails = shippingDetails;
  },
  [types.RESET_START_TIME](state) {
    state.reservation.start = null;
  },
  [types.RESET_END_TIME](state) {
    state.reservation.end = null;
  },
  [types.SET_CREW_ASSIGNMENTS](state, crew) {
    state.reservation.crew = crew;
  },
  [types.RESET_CREW_ASSIGNMENTS](state) {
    state.reservation.crew = [];
  },
  [types.ADD_CREW_ASSIGNMENT_TO_RESERVATION](state, crew) {
    //find out if the record has been marked for destroy (_destroy == 1) but is now being readded with identical crew role, user_id
    let deletedAndReaddedAssignment = state.reservation.crew.find(
      c =>
        c.crew_role == crew.crew_role &&
        c.user_id == crew.user_id &&
        c._destroy == 1
    );

    let alreadyAddedCrew = [];
    //if we send back a matching crew member, replace the one in state with the new one and push it to the alreadyAddedCrew array
    state.reservation.crew.forEach(function (value, index) {
      if (value.crew_role == crew.crew_role && value.user_id == crew.user_id) {
        state.reservation.crew.splice(index, 1, crew);
        alreadyAddedCrew.push(crew);
      }
    });

    if (deletedAndReaddedAssignment) {
      delete deletedAndReaddedAssignment._destroy;
      // remove that crew and replace with identical copy without _destroy flag so we don't destroy and then immediately create another identical crew assigment through nested attributes
      state.reservation = {
        ...state.reservation,
        crew: [
          ...state.reservation.crew.filter(c => c.user_id !== crew.user_id),
          deletedAndReaddedAssignment,
        ],
      };
    } else if (alreadyAddedCrew.length == 0) {
      state.reservation = {
        ...state.reservation,
        crew: [...state.reservation.crew, crew],
      };
    }
  },

  [types.REMOVE_CREW_ASSIGNMENT_FROM_RESERVATION](state, crew) {
    state.reservation = {
      ...state.reservation,
      crew: state.reservation.crew.reduce((acc, cur) => {
        if (cur.user_id !== crew.user_id || cur.crew_role !== crew.crew_role)
          return [...acc, cur];

        if (cur.id) {
          // if the matching user has already been added as a crew assignment,
          // mark that crew assignment for deletion through nested attributes
          cur.status = 'declined';
          cur.status_detail = 'removed';
          return [...acc, cur];
        } else {
          // otherwise, filter them out of the list
          return acc;
        }
      }, []),
    };
  },
};

const addErrorFlash = (dispatch, errorText) => {
  dispatch(
    'flash/addFlashMessage',
    Object.assign({ type: 'error', text: errorText, timeout: true }, {})
  );
};

const addErrorFlashWithTranslation = (dispatch, errorCode, error) => {
  let errorText = 'An unknown error occurred.';

  switch (errorCode) {
    case 'card_declined':
      errorText = 'Your card was declined.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'expired_card':
      errorText = 'Your card has expired.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'card_error':
      errorText = 'There was an error processing your payment.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'order_status_not_created':
      errorText = 'There was an error confirming your order.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'order_status_not_confirmed':
      errorText = 'The reservation could not be cancelled.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'incorrect_cvc':
      errorText =
        'We could not verify your CVC. Please check your security code and try again.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'processing_error':
      errorText =
        'We could not process your card, if this continues to happen please give us a call.';
      addErrorFlash(dispatch, errorText);
      break;
    case 'validation_failed':
    case 'invalid_stripe_request':
      addErrorFlash(dispatch, error);
      break;
    case 'post_shoot_option_not_chosen':
      addErrorFlash(
        dispatch,
        'select a post shoot option to confirm your booking.'
      );
      break;
    case 'phone_number_not_provided':
      addErrorFlash(dispatch, 'please enter a valid phone number.');
      break;
    default:
      break;
  }
  if (!error) {
    addErrorFlash(dispatch, errorText);
  }
};

const addStripeErrorFlash = (dispatch, error) => {
  addErrorFlash(dispatch, error.message);
};

function formatReservationCrewAssignments(reservation) {
  let formattedReservation = {
    ...reservation,
    crew_assignments_attributes: reservation.crew,
  };
  delete formattedReservation.crew;

  return formattedReservation;
}

const actions = {
  cancelReservation({ state, commit, dispatch, rootState }, refundStatus) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`reservations/${state.reservation.id}/cancel.json`, {
          refund: refundStatus,
        })
        .then(
          () => {
            commit(types.CANCEL_RESERVATION);
            commit(
              `schedule/${types.REMOVE_RESERVATION_FROM_SCHEDULE}`,
              state.reservation.id
            );
            queryClient.invalidateQueries({
              queryKey: queryKeys.reservations(),
            });
            resolve();
          },
          error => {
            console.error('cancel reservation error', error);
            if (error.response.status !== 500) {
              addErrorFlashWithTranslation(
                dispatch,
                error.response.data.code,
                error.response.data.message,
                rootState
              );
            } else {
              addErrorFlashWithTranslation(
                dispatch,
                'unknown_error',
                rootState
              );
            }
            reject(error);
          }
        );
    });
  },
  updateReservation({ state, rootState, dispatch }) {
    if (state.reservation.id) {
      //the reservation controller expects the key crew_assignment_attributes, not crew
      let formattedReservation = formatReservationCrewAssignments(
        state.reservation
      );

      return new Promise((resolve, reject) => {
        this.http
          .put(`reservations/${state.reservation.id}`, {
            reservation: formattedReservation,
          })
          .then(
            () => {
              queryClient.invalidateQueries({
                queryKey: queryKeys.reservations(),
              });
              resolve();
            },
            error => {
              console.error('update reservation error', error);
              if (error.response.status !== 500) {
                addErrorFlashWithTranslation(
                  dispatch,
                  error.response.data.code,
                  error.response.data.message,
                  rootState
                );
              } else {
                addErrorFlashWithTranslation(
                  dispatch,
                  'unknown_error',
                  rootState
                );
              }
              reject(error);
            }
          );
      });
    } else {
      console.error('no reservation id to update!');
    }
  },
  setIsReschedulable({ commit }, bool) {
    commit(types.SET_RESCHEDULABLE_RESERVATION, bool);
  },
  loadBookingInfo({ dispatch, commit }, { reservationId }) {
    commit(types.RESET_RESERVATION);
    return dispatch('loadReservation', { reservationId: reservationId });
  },
  loadReservation({ commit, dispatch, rootState }, { reservationId }) {
    if (!reservationId) {
      return;
    }
    return this.http.get(`reservations/${reservationId}`).then(response => {
      commit(types.SET_RESERVATION, {
        reservation: response.data,
        timezone: rootState.locations.currentLocation.timezone,
      });
      queryClient.invalidateQueries({ queryKey: queryKeys.reservations() });
      if (response.data.account_id) {
        dispatch('account/loadAccount', response.data.account_id, {
          root: true,
        });
      }
      if (response.data.start) {
        dispatch('loadAvailability');
      }
    });
  },
  setDurationAndLoadAvailability({ commit, dispatch }, duration) {
    if (!duration) {
      return;
    }
    commit(types.UPDATE_DURATION, duration);
    dispatch('loadAvailability');
  },
  setStartDateAndLoadAvailability({ commit, dispatch, rootState }, startDate) {
    commit(types.SET_START_DATE, {
      startDate,
      timeZone: rootState.locations.currentLocation.timezone,
    });
    return dispatch('loadAvailability');
  },
  loadAvailability({ state, commit, getters, rootState }) {
    if (!state.startDate || !state.duration) {
      return;
    }

    let has_studio_buy_out = rootState.tagSurvey.selectedTags.some(
      t => t.title === 'studio buy-out'
    );

    let url = `locations/${rootState.locations.currentLocation.id}/availability`;
    url = `${url}?start=${state.startDate}&duration=${getters.duration}`;
    url = `${url}&bookable_space_ids=${rootState.locations.currentLocationSpaces.map(
      bs => bs.id
    )}&scheduled_reservation_id=${
      state.reservation.id ? state.reservation.id : ''
    }`;
    url = `${url}&shoot_type=${getters.shootType}`;
    url = `${url}&reservation_type=${state.reservation.reservation_type}`;
    url = `${url}&studio_buy_out=${has_studio_buy_out}`;
    url = `${url}&location_id=${rootState.locations.currentLocation.id}`;
    url = `${url}&reservation_tag_ids=${state.reservation.reservation_tags.map(
      t => t.tag_id
    )}`;
    return this.http.get(url).then(response => {
      commit(types.SET_AVAILABILITY, response.data);
    });
  },
  createShopifyDownPaymentCharge(
    { state, dispatch, rootState },
    { reservationId, sales_tax }
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `reservations/${
            reservationId ?? state.reservation.id
          }/create_shopify_charge`,
          {
            sales_tax,
          }
        )
        .then(
          response => {
            resolve(response.data);
          },
          error => {
            console.error('confirm reservation error', error);
            if (error.response.status !== 500) {
              addErrorFlashWithTranslation(
                dispatch,
                error.response.data.code,
                error.response.data.message,
                rootState
              );
            } else {
              addErrorFlashWithTranslation(
                dispatch,
                'unknown_error',
                rootState
              );
            }
            reject(error);
          }
        );
    });
  },
  confirmReservation(
    { state, commit, dispatch, rootState },
    {
      stripe,
      card,
      saveCard,
      password,
      passwordConfirmation,
      reservationId,
      reservationType,
      noDownPayment = false,
      activePromo = '',
      salesTax = {},
    }
  ) {
    return new Promise((resolve, reject) => {
      if (stripe && card) {
        stripe.createToken(card).then(result => {
          if (result?.error) {
            addStripeErrorFlash(dispatch, result.error);
            return reject(result.error);
          }

          commit(`currentUser/${types.SET_PAYMENT_TOKEN}`, result.token.id);

          this.http
            .post(
              `reservations/${reservationId ?? state.reservation.id}/confirm`,
              {
                confirm: {
                  token: result.token.id,
                  name: rootState.currentUser.name,
                  email: rootState.currentUser.email,
                  phone: rootState.currentUser.phone,
                  password: password,
                  password_confirmation: passwordConfirmation,
                  save_card: saveCard,
                  no_down_payment: noDownPayment,
                  ...(activePromo && {
                    [activePromo]: true,
                  }),
                  sales_tax: salesTax,
                },
                reservation_type:
                  reservationType ?? state.reservation.reservation_type,
              }
            )
            .then(
              () => {
                resolve();
              },
              error => {
                console.error('confirm reservation error', error);
                if (error.response.status !== 500) {
                  addErrorFlashWithTranslation(
                    dispatch,
                    error.response.data.code,
                    error.response.data.message,
                    rootState
                  );
                } else {
                  addErrorFlashWithTranslation(
                    dispatch,
                    'unknown_error',
                    rootState
                  );
                }
                reject(error);
              }
            );
        });
      } else {
        this.http
          .post(
            `reservations/${reservationId ?? state.reservation.id}/confirm`,
            {
              confirm: {
                name: rootState.currentUser.name,
                email: rootState.currentUser.email,
                phone: rootState.currentUser.phone,
                password: password,
                password_confirmation: passwordConfirmation,
                save_card: saveCard,
                no_down_payment: noDownPayment,
                ...(activePromo && {
                  [activePromo]: true,
                }),
                sales_tax: salesTax,
              },
              reservation_type:
                reservationType ?? state.reservation.reservation_type,
            }
          )
          .then(
            () => {
              resolve();
            },
            error => {
              console.error('confirm reservation error', error);
              if (error.response.status !== 500) {
                addErrorFlashWithTranslation(
                  dispatch,
                  error.response.data.code,
                  error.response.data.message,
                  rootState
                );
              } else {
                addErrorFlashWithTranslation(
                  dispatch,
                  'unknown_error',
                  rootState
                );
              }
              reject(error);
            }
          );
      }
    });
  },
  updateLocation({ state }, { location, transferInventory }) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`reservations/${state.reservation.id}/update_location.json`, {
          location_id: location.id,
          ...(!!transferInventory && {
            initiate_package_transfer: transferInventory,
          }),
        })
        .then(response => {
          queryClient.invalidateQueries({ queryKey: queryKeys.reservations() });
          resolve(response.data);
        })
        .catch(error => {
          console.error('update location error', error);
          reject(error);
        });
    });
  },
  addReservationTag({ commit }, tag) {
    commit(types.ADD_RESERVATION_TAG, tag);
    commit(types.ADD_RESERVATION_LINE_ITEMS, tag);
  },
  removeReservationTag({ commit }, { tag, removeAll }) {
    commit(types.REMOVE_RESERVATION_TAG, tag);
    commit(types.REMOVE_RESERVATION_LINE_ITEMS, { tag, removeAll });
  },
  setReservationAccountId({ commit }, accountId) {
    commit(types.SET_ACCOUNT_ID, accountId);
  },
  loadShippingDetails(
    { commit },
    {
      query = null,
      page = 1,
      location = null,
      orderBy = 'id',
      direction = 'desc',
      filterBy = null,
      filterValue = null,
    }
  ) {
    let params = {
      query: query,
      page: page,
      location: location,
      order_by: orderBy,
      direction: direction,
    };
    if (filterBy && filterValue) {
      params['filter_by'] = filterBy;
      params['filter_value'] = filterValue;
    }

    return this.http
      .get(`shipping_details/summary.json`, {
        params: params,
      })
      .then(response => {
        commit(types.SET_SHIPPING_DETAILS, response.data);
      });
  },
  loadCrewAssignments({ commit }, reservationId) {
    return this.http
      .get(`reservations/${reservationId}/crew_assignments.json`)
      .then(response => {
        if (response.data.length > 0) {
          commit(types.SET_CREW_ASSIGNMENTS, response.data);
        } else {
          commit(types.RESET_CREW_ASSIGNMENTS, response.data);
        }
      });
  },
  addPhotographerToReservation({ commit }, user) {
    commit(types.ADD_CREW_ASSIGNMENT_TO_RESERVATION, {
      user_id: user.id,
      user_name: user.name,
      crew_role: 'photographer',
      status: 'accepted',
    });
  },
  addCrewAssignmentToReservation({ commit }, crewAssignment) {
    commit(types.ADD_CREW_ASSIGNMENT_TO_RESERVATION, crewAssignment);
  },
  removeCrewAssignmentFromReservation({ commit }, crew) {
    commit(types.REMOVE_CREW_ASSIGNMENT_FROM_RESERVATION, crew);
  },
  unScheduleReservation({ state }) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`reservations/${state.reservation.id}/unschedule`)
        .then(response => {
          queryClient.invalidateQueries({ queryKey: queryKeys.reservations() });
          resolve(response.data);
        })
        .catch(error => {
          console.error('unschedule reservation error', error);
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

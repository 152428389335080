<script setup>
import { computed, onMounted } from 'vue';
import { useTitle } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import AccountGalleryBreadcrumbs from '@/components/account_gallery/AccountGalleryBreadcrumbs.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import AccountInfoAuto from '@/components/user/anytime/dashboard/AccountInfoAuto.vue';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import EditsCollectionDigitalAssetsGallery from './EditsCollectionDigitalAssetsGallery.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

const accountId = computed(() => props.accountId);

const {
  data: editsCollection,
  error,
  isLoading,
} = useEditsCollection(accountId);

const priorityErrors = usePriorityErrors(error);

const title = computed(() => editsCollection.value?.title ?? 'edits');

useTitle(title, { titleTemplate: '%s | soona' });
</script>

<template>
  <div class="edits-collection-assets">
    <AccountInfoAuto :account-id="accountId" />
    <SoonaLoading v-if="isLoading" is-loading />
    <SoonaError v-else-if="priorityErrors" :priority-errors="priorityErrors" />
    <template v-else-if="editsCollection">
      <AccountGalleryBreadcrumbs :album-title="title" />
      <h1 class="u-title--heavy">
        {{ title }}
      </h1>
      <EditsCollectionDigitalAssetsGallery
        :account-id="accountId"
        :edits-collection-id="editsCollection.id"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.edits-collection-assets {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
</style>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {string | number} assetId
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useDigitalAssetDerivatives(assetId, params) {
  return useQuery({
    queryKey: queryKeys.digitalAssetDerivatives(assetId, params),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/digital_assets/${toValue(assetId)}/derivatives`,
        { params: toValue(params) },
        { signal }
      );

      return response.data;
    },
  });
}

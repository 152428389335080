import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useGetRequiredCatalogItems(reservationId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.requiredCatalogItems(reservationId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/reservations/${toValue(reservationId)}/required_catalog_items`,
        {
          signal,
        }
      );
      return response.data;
    },
    ...queryOptions,
  });
}

<script setup>
import { ref, watchEffect } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import PackageHandling from '@/components/booking/PackageHandling.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  isReservationPackage: {
    type: Boolean,
    default: true,
  },
  hasSoonaStorage: {
    type: Boolean,
    default: false,
  },
  preselectedPackageOption: {
    type: String,
    required: false,
  },
  updateError: {
    type: Object,
    required: false,
  },
});

const emit = defineEmits(['close', 'handleConfirm']);

const packageOption = ref('');

watchEffect(() => {
  packageOption.value = props.preselectedPackageOption;
});
</script>

<template>
  <SoonaDialog @close="emit('close')">
    <template #heading>choose product handling option</template>
    <template #default>
      <SoonaError v-if="updateError">
        {{ updateError.message }}
      </SoonaError>
      <PackageHandling
        :is-reservation-package="isReservationPackage"
        :has-soona-storage="hasSoonaStorage"
        :package-option="packageOption"
        :on-change="newPackageOption => (packageOption = newPackageOption)"
      />
    </template>
    <template #footer>
      <SoonaButton
        data-cypress="tag-requirement-cancel"
        variation="tertiary"
        @on-click="emit('close')"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        data-cypress="tag-requirement-confirm"
        :disabled="!packageOption"
        @on-click="emit('handleConfirm', packageOption)"
      >
        confirm
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.choose-original {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.image-card {
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
  }

  &__title {
    word-break: break-word;
  }
}
</style>

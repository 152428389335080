<script setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import MultiSelectActionBar from './MultiSelectActionBar.vue';
import ProductFilters from './ProductFilters.vue';
import ProductCard from './ProductCard.vue';
import PaginatorFull from 'src/components/directory/PaginatorFull.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCatalog } from 'src/queries/useCatalog';
import { useGetIntegrations } from '@/queries/useGetIntegrations';
import { useProductCatalogQueryParams } from './useProductCatalogQueryParams';
import { usePriorityError } from '@/composables/usePriorityError';
import { useElementBounding, useTitle } from '@vueuse/core';
import { keepPreviousData, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import ProductConnectButton from './ProductConnectButton.vue';
import IntegrationsLoading from '@/components/user/anytime/products/IntegrationsLoading.vue';
import RecommendedPacks from '@/components/user/anytime/listing_insights/listing_detail/RecommendedPacks.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import AmazonLogo from 'images/amazon-logo.svg';
import ShopifyLogo from 'images/shopify_glyph.svg';
import {
  isActiveAmazonIntegration,
  isAmazonIntegrationErrored,
  isAmazonIntegrationLoading,
  isShopifyIntegrationLoading,
} from './integration-utils';
import ManageIntegrationsDialog from './ManageIntegrationsDialog.vue';
import { useArchiveCatalogItems } from '@/queries/useUpdateCatalogItem';
import ProductCatalogEmptyState from './ProductCatalogEmptyState.vue';

const props = defineProps({
  accountId: { type: String, required: true },
});

const emit = defineEmits(['productQueryParams']);
useTitle('product catalog | soona');
const queryClient = useQueryClient();

const accountId = computed(() => props.accountId);

const showManageIntegrationsDialog = ref(false);
function toggleManageIntegrationsDialog() {
  showManageIntegrationsDialog.value = !showManageIntegrationsDialog.value;
}

const pageWrapper = ref(null);
const { left, width } = useElementBounding(pageWrapper);

// segment events module
const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

// catalog items module
const {
  searchText,
  searchDebounced,
  selectedFilter,
  selectedStatusFilter,
  includeVariants,
  sortBy,
  orderBy,
  direction,
  currentPage,
  itemsPerPage,
  queryObj,
  resetFilters,
} = useProductCatalogQueryParams();

const {
  data: productData,
  error,
  isSuccess,
  isLoading,
} = useCatalog(
  accountId,
  {
    query: searchDebounced,
    filter: selectedFilter,
    status: selectedStatusFilter,
    includeVariants,
    orderBy,
    showBookingCount: true,
    showInventoryCount: true,
    showVariantCount: true,
    direction,
    currentPage,
    itemsPerPage,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const priorityError = usePriorityError(error);

const catalogItemsPagination = computed(() =>
  productData.value
    ? productData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

const catalogItems = computed(() => productData.value?.catalogItems ?? []);

watchEffect(() => {
  emit('productQueryParams', queryObj.value);
});

const showNoData = computed(() => {
  return (
    searchDebounced.value === '' &&
    selectedFilter.value === 'all' &&
    catalogItems.value.length === 0
  );
});

// integrations module
const checkIfAnyIntegrationIsLoading = integration => {
  return (
    isAmazonIntegrationLoading(integration) ||
    isShopifyIntegrationLoading(integration)
  );
};

const { data: integrationsData, isSuccess: integrationsLoaded } =
  useGetIntegrations(accountId);

const integrations = computed(() => integrationsData.value ?? []);

const isIntegrationsLoading = computed(() => {
  return integrations.value.some(checkIfAnyIntegrationIsLoading);
});

watch(isIntegrationsLoading, newLoading => {
  if (!newLoading) {
    queryClient.invalidateQueries({ queryKey: queryKeys.catalog(accountId) });
  }
});

const currentIntegrationsImporting = computed(() => {
  return integrations.value.filter(integration => integration.is_importing);
});

const shopifyIntegrationCurrentlyImporting = computed(() =>
  currentIntegrationsImporting.value.find(
    integration => integration.type === 'ShopifyIntegration'
  )
);

const amazonIntegration = computed(() =>
  integrations.value.find(
    integration => integration.type === 'AmazonIntegration'
  )
);

const currentImportIsFirstEver = computed(() => {
  if (shopifyIntegrationCurrentlyImporting.value) {
    return shopifyIntegrationCurrentlyImporting.value?.most_recent_import
      ?.first_import_ever;
  }
  return amazonIntegration.value?.most_recent_import?.first_import_ever;
});

const productIntegrations = computed(() => {
  return integrations.value.filter(
    integration =>
      ['ShopifyIntegration', 'AmazonIntegration'].includes(integration.type) &&
      integration.status === 'active'
  );
});

const hasAmazonIntegration = computed(() =>
  integrations.value.some(isActiveAmazonIntegration)
);

const integrationIconLookup = {
  AmazonIntegration: {
    icon: AmazonLogo,
    altText: 'Amazon logo',
  },
  ShopifyIntegration: { icon: ShopifyLogo, altText: 'Shopify logo' },
};

const integrationImportErrors = computed(() => {
  return integrations.value.reduce((imports, integration) => {
    if (isAmazonIntegrationErrored(integration)) {
      imports.push(integration.most_recent_import);
    }

    return imports;
  }, []);
});

function userRefreshIntegrations() {
  queryClient.invalidateQueries({
    queryKey: queryKeys.integrations(accountId),
  });
}

// multiselect module
const {
  mutate: archiveCatalogItems,
  isPending: isUpdating,
  error: updateError,
  reset,
} = useArchiveCatalogItems(accountId);

const archiveCatalogError = usePriorityError(updateError);

const selectedProducts = ref([]);

const cancelMultiSelect = () => {
  reset();
  selectedProducts.value = [];
};

const archiveSelected = () => {
  archiveCatalogItems(selectedProducts.value, {
    onSuccess: () => {
      cancelMultiSelect();
    },
  });
};

const isSelected = id => selectedProducts.value.includes(id);

const selectProduct = productId => {
  if (selectedProducts.value.includes(productId)) {
    selectedProducts.value = selectedProducts.value.filter(
      x => x !== productId
    );
  } else {
    selectedProducts.value.push(productId);
  }
};
</script>

<template>
  <div ref="pageWrapper" class="product-catalog">
    <div class="product-catalog__filters">
      <ProductFilters
        v-model:search-text="searchText"
        v-model:selected-filter="selectedFilter"
        v-model:selected-status-filter="selectedStatusFilter"
        v-model:include-variants="includeVariants"
        v-model:sort-by="sortBy"
      />
    </div>

    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>

    <SoonaError
      v-for="importError of integrationImportErrors"
      :key="importError.id"
    >
      {{ importError.message }}
    </SoonaError>

    <IntegrationsLoading
      v-if="isIntegrationsLoading"
      :show-refresh-button="currentImportIsFirstEver"
      @refresh="userRefreshIntegrations"
    >
      <template #heading>
        <img
          v-for="integration in currentIntegrationsImporting"
          :key="integration.id"
          :src="integrationIconLookup[integration.type]?.icon"
          :alt="integrationIconLookup[integration.type]?.altText"
          class="product-catalog__integration-img"
        /><br />
        {{
          currentImportIsFirstEver
            ? 'import in progress!'
            : 'updating your products'
        }}
      </template>
      <template v-if="currentImportIsFirstEver" #body>
        you can leave this page and come back later, or refresh to check
        progress
      </template>
    </IntegrationsLoading>

    <div v-if="isSuccess" class="product-catalog__content">
      <PaginatorFull
        v-model:page="currentPage"
        v-model:records-per-page="itemsPerPage"
        class="product-catalog__paginator-top"
        :total-pages="catalogItemsPagination.totalPages"
        :total-count="catalogItemsPagination.totalCount"
        :page-count="catalogItems.length"
      />
      <TransitionGroup
        name="product"
        tag="div"
        class="product-catalog__product-list"
      >
        <ProductCatalogEmptyState
          v-if="!catalogItems.length"
          :account-id="accountId"
          :product-integrations="productIntegrations"
          :is-loading="isLoading"
          :is-success="isSuccess"
          :is-integrations-loading="isIntegrationsLoading"
          :show-no-data="showNoData"
          @reset-filters="resetFilters"
        />
        <ProductCard
          v-for="product in catalogItems"
          :key="product.id"
          :product="product"
          :account-id="accountId"
          :is-selected="isSelected(product.id)"
          @handle-select="selectProduct(product.id)"
        />
      </TransitionGroup>
      <PaginatorFull
        v-model:page="currentPage"
        v-model:records-per-page="itemsPerPage"
        class="product-catalog__paginator-bottom"
        :total-pages="catalogItemsPagination.totalPages"
        :total-count="catalogItemsPagination.totalCount"
        :page-count="catalogItems.length"
      />
      <MultiSelectActionBar
        :error="archiveCatalogError"
        :is-updating="isUpdating"
        :selected-products="selectedProducts"
        button-copy="archive"
        button-icon="archive-action"
        :page-bounding-rect-left="left"
        :page-bounding-rect-width="width"
        @action-selected="archiveSelected"
        @cancel-multi-select="cancelMultiSelect"
      />
    </div>

    <template v-if="integrationsLoaded">
      <RecommendedPacks :suggest-amazon="hasAmazonIntegration" />
      <ProductConnectButton
        v-if="!showNoData && !productIntegrations.length"
        @on-click="toggleManageIntegrationsDialog"
      />
      <ManageIntegrationsDialog
        v-if="showManageIntegrationsDialog"
        :account-id="accountId"
        :integrations="integrations"
        :page="2"
        @close="toggleManageIntegrationsDialog"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-catalog {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 1rem;

  &__paginator-top {
    margin-bottom: 1rem;
  }

  &__content {
    position: relative;
  }

  &__product-list {
    flex: 1 1 auto;
  }

  &__integration-img {
    border-radius: 50%;
    height: 2rem;
    background-color: variables.$white-default;
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__content {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }
  }
}

// transitions

/* base */
.product {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.product-move {
  transition: all 0.4s ease;
}

/* appearing */
.product-enter-active {
  transition: all 0.3s ease-out;
}

/* disappearing */
.product-leave-active {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disppear to */
.product-enter-from,
.product-leave-to {
  opacity: 0;
}
</style>

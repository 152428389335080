// h/t to https://codepen.io/pdanpdan/pen/ExPqZbK

import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  nextTick,
  watch,
  effectScope,
} from 'vue';
import {
  useDraggable,
  useResizeObserver,
  useDebounceFn,
  onClickOutside,
  onKeyStroke,
  useMediaQuery,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';

export const useDraggableDrawer = (drawerMinHeight, drawerRef) => {
  const drawerPos = ref(0);
  const animateTimeout = ref();
  const matchMediaIsWide = useMediaQuery('(min-width: 48rem)');
  const direction = ref('none');
  const dragStartY = ref(0);
  const dragEndY = ref(0);
  const diffY = computed(() => dragStartY.value - dragEndY.value);
  const bodyComputedStyle = window.getComputedStyle(document.body);
  let scope, contentObserver, stopClickOutside, draggable;

  // attempt to prevent some unintential "swipes"
  const isThresholdExceeded = computed(() => Math.abs(diffY.value) >= 5);

  const getTextZoomRatio = () =>
    parseInt(bodyComputedStyle.getPropertyValue('font-size'), 10) / 16;
  const lastTextZoomRatio = ref(getTextZoomRatio());

  const getAdjustedMinHeight = () => {
    // this to ensure that the collapsed heading is still visible when text zooming
    return drawerMinHeight * getTextZoomRatio();
  };

  const {
    hasFocus: focusTrapActive,
    activate: focusTrapActivate,
    deactivate: focusTrapDeactivate,
  } = useFocusTrap(drawerRef);

  const getDrawerMaxHeight = () => {
    // add 1 to account for top border of containing element
    const contentHeight = drawerRef.value?.clientHeight
      ? drawerRef.value.clientHeight + Math.ceil(1 * getTextZoomRatio())
      : window.innerHeight - 32;

    return Math.min(window.innerHeight - 32, contentHeight);
  };

  const drawerMaxHeight = ref(getDrawerMaxHeight());

  const drawerOpenRatio = computed(() => {
    return Math.round(
      (Math.max(0, drawerPos.value - getAdjustedMinHeight()) /
        Math.max(1, drawerMaxHeight.value - getAdjustedMinHeight())) *
        100
    );
  });

  const drawerMode = computed(() => {
    if (matchMediaIsWide.value) return 'inactive';
    if (drawerOpenRatio.value === 100) return 'open';
    if (drawerOpenRatio.value === 0) return 'closed';
    return 'transitioning';
  });

  const drawerStyle = computed(() => {
    if (drawerMode.value === 'inactive') return {};
    return {
      height: `${drawerMaxHeight.value}px`,
      transform: `translateY(${-drawerPos.value}px)`,
    };
  });

  watch(drawerMode, async mode => {
    switch (mode) {
      case 'transitioning':
        document.documentElement.style.overflow = 'hidden';
        break;
      case 'open':
        document.documentElement.style.overflow = 'hidden';
        await nextTick();
        if (!focusTrapActive.value) focusTrapActivate();
        break;
      case 'closed':
      case 'inactive':
        document.documentElement.style.overflow = '';
        if (focusTrapActive.value) focusTrapDeactivate();
        break;
    }
  });

  const animateDrawerTo = height => {
    clearTimeout(animateTimeout.value);
    const diff = height - drawerPos.value;

    if (diff !== 0) {
      drawerPos.value += Math.abs(diff) < 2 ? diff : Math.round(diff / 2);

      animateTimeout.value = setTimeout(() => {
        animateDrawerTo(height);
      }, 25);
    }
  };

  watch(drawerMaxHeight, () => {
    if (drawerMode.value !== 'closed' && !draggable?.isDragging.value) {
      if (
        drawerOpenRatio.value < 50 &&
        lastTextZoomRatio.value !== getTextZoomRatio()
      ) {
        animateDrawerTo(getAdjustedMinHeight());
      } else {
        animateDrawerTo(drawerMaxHeight.value);
      }
    }
    lastTextZoomRatio.value = getTextZoomRatio();
  });

  const slideStart = () => {
    dragStartY.value = dragEndY.value =
      drawerRef.value?.getBoundingClientRect().y;
  };

  const slideDrawer = e => {
    if (isThresholdExceeded.value) {
      direction.value = diffY.value < 0 ? 'down' : 'up';
      drawerPos.value = Math.max(
        getAdjustedMinHeight(),
        Math.min(
          drawerMaxHeight.value,
          drawerPos.value - (e.y - dragEndY.value)
        )
      );
    } else {
      direction.value = 'none';
    }
    dragEndY.value = e.y;
  };

  const slideEnd = async () => {
    await nextTick();

    let targetHeight;
    if (isThresholdExceeded.value) {
      targetHeight =
        direction.value === 'down'
          ? getAdjustedMinHeight()
          : drawerMaxHeight.value;
    }

    // snap to top/bottom
    if (drawerOpenRatio.value < 5 || drawerOpenRatio.value > 95) {
      targetHeight =
        drawerOpenRatio.value < 5
          ? getAdjustedMinHeight()
          : drawerMaxHeight.value;
    }

    if (targetHeight) animateDrawerTo(targetHeight);
  };

  const drawerOpenClose = openOrClose => {
    if (drawerMode.value === 'transitioning') return;

    animateDrawerTo(
      openOrClose === 'close' ? getAdjustedMinHeight() : drawerMaxHeight.value
    );
  };

  const debouncedResize = useDebounceFn(() => {
    drawerMaxHeight.value = getDrawerMaxHeight();
  }, 80);

  const enable = (shouldEnable = true) => {
    if (shouldEnable) {
      // activate draggable drawer
      contentObserver = useResizeObserver(drawerRef, debouncedResize);
      window.addEventListener('resize', debouncedResize);
      scope = effectScope();
      scope.run(() => {
        draggable = useDraggable(drawerRef, {
          axis: 'y',
          onStart: slideStart,
          onMove: slideDrawer,
          onEnd: slideEnd,
        });
        onKeyStroke('Escape', () => {
          drawerOpenClose('close');
        });
      });
      stopClickOutside = onClickOutside(drawerRef, () => {
        drawerOpenClose('close');
      });
      drawerPos.value = 0;
      animateDrawerTo(getAdjustedMinHeight());
      return;
    }

    // deactivate draggable drawer
    clearTimeout(animateTimeout.value);
    contentObserver?.stop();
    window.removeEventListener('resize', debouncedResize);
    scope.stop();
    stopClickOutside();
    document.documentElement.style.overflow = '';
  };

  watch(matchMediaIsWide, e => {
    enable(!e);
  });

  onMounted(() => {
    if (!matchMediaIsWide.value) enable(true);
  });

  onBeforeUnmount(() => {
    if (!matchMediaIsWide.value) enable(false);
    document.documentElement.style.overflow = '';
  });

  return {
    drawerMode,
    drawerStyle,
    drawerOpenClose,
    drawerOpenRatio,
  };
};

import { http } from '../config/vue-axios';

/**
 *
 * @param {number} reservationId
 * @param {array} locationTypes
 * @param {AbortSignal} [signal]
 */
export async function getReservationLocations(
  reservationId,
  locationTypes,
  signal
) {
  let url = `/reservations/${reservationId}/locations`;
  let params = {};

  if (locationTypes && locationTypes.length > 0) {
    params.location_type = locationTypes;
  }

  const response = await http.get(url, { params, signal });

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {AbortSignal} [signal]
 */
export async function getSuggestedReservationLocation(reservationId, signal) {
  const response = await http.get(
    `/reservations/${reservationId}/suggested_location.json`,
    { signal }
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {AbortSignal} [signal]
 */
export async function getLastSupportedLocation(reservationId, signal) {
  const response = await http.get(
    `/reservations/${reservationId}/last_supported_location.json`,
    { signal }
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 */
export async function updateReservationLocation(reservationId) {
  const response = await http.put(
    `/reservations/${reservationId}/locations.json`
  );

  return response.data;
}

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { http } from '@/config/vue-axios';

/**
 * Function to fetch tag category products
 * @param {object} params
 * @param {object} signal
 * @returns {Promise<object>}
 */
async function getTagCategoryProducts(params, signal) {
  const { locationId, shootType } = params;
  const requestUrl = `tag_categories_products.json?location_id=${locationId}&shoot_type=${shootType.value}`;

  const response = await http.get(requestUrl, { signal });
  const data = await response.data;

  const keywords = ['manicure', 'pedicure', 'hair'];

  const filteredProducts = data.products.filter(
    product =>
      product.product_type === 'reservation_service' &&
      keywords.some(keyword => product.name.toLowerCase().includes(keyword)) &&
      product.name.toLowerCase() !== 'manicure press on'
  );

  const getGroupingKey = str => {
    const words = str.split(' ');
    const keyword = keywords.find(kw => words[0].toLowerCase().startsWith(kw));
    return keyword ? keyword : str;
  };

  const groupedProducts = filteredProducts.reduce((acc, product) => {
    const groupingKey = getGroupingKey(product.name);
    if (!acc[groupingKey]) {
      acc[groupingKey] = [];
    }
    acc[groupingKey].push(product);
    return acc;
  }, {});

  return groupedProducts;
}

/**
 * Custom hook to use tag category products query
 * @param {object | Ref<object>} params
 */
export function useTagCategoryProducts(params) {
  return useQuery({
    queryKey: queryKeys.tagCategories(params),
    queryFn: ({ signal }) => getTagCategoryProducts(unref(params), signal),
  });
}

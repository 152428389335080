import { track } from '@/lib/segment/segment';
import { useCurrentReservationAndUser } from '@/composables/segment/useCurrentReservationAndUser';
import { reservationPropertiesV2 } from '@/lib/segment/segment';

export function useBookingEvents() {
  const { userTraits } = useCurrentReservationAndUser();

  /**
   * Booking Started
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} path the URL path of the screen the user sees first when booking
   */
  // we need to pass in the reservation as the part of the params because we are receiving the reservation as a response from the createReservation mutation in CreateReservation.vue
  const bookingStarted = ({
    context,
    subContext,
    path,
    reservation,
    experiments,
  } = {}) => {
    const payload = {
      context,
      subContext,
      path,
      ...{
        reservation: reservationPropertiesV2({
          reservation: reservation,
          location: reservation?.location,
          tags: reservation?.reservation_tags,
          tagCategories: reservation?.reservation_tags.map(
            tag => tag.tag_category
          ),
          pack: reservation?.pack_configuration,
        }),
        ...{
          filterExperiments: experiments,
        },
      },
      ...userTraits.value,
    };
    track('Booking Started', payload);
  };

  return {
    bookingStarted,
  };
}

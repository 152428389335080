import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getCompetitorsComparison } from 'src/api/competitorsComparison';
import { queryKeys } from 'src/queries/query-keys';

/**
 *
 * @param {string | number} listingId
 * @param {string | number} competitorId
 * @param {boolean} isExampleCompetitor
 */
export function useCompetitorsComparison(
  listingId,
  competitorId,
  isExampleCompetitor,
  queryOptions = null
) {
  return useQuery({
    queryKey: queryKeys.competitorsComparison(
      listingId,
      competitorId,
      isExampleCompetitor
    ),
    queryFn: ({ signal }) =>
      getCompetitorsComparison(
        unref(listingId),
        unref(competitorId),
        unref(isExampleCompetitor),
        signal
      ),
    ...queryOptions,
  });
}

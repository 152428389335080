import { useQuery } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';

/**
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useMokkerScenes() {
  return useQuery({
    queryKey: queryKeys.mokkerScenes(),
    queryFn: async ({ signal }) => {
      const response = await http.get(`/mokker/request_scenes`, { signal });
      return response.data;
    },
  });
}

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import TextInput from 'src/components/shared/TextInput.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import AlertIcon from 'src/components/svgs/AlertIcon.vue';

import {
  convertTodddDDMMMYYY,
  convertToHHmmss,
  workingDaysBetweenDates,
} from 'src/lib/date-formatters';
import { ADD_CREW_ASSIGNMENT_TO_RESERVATION } from 'src/store/mutation-types';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';

const props = defineProps({
  assignCrewDirectly: {
    required: true,
    type: Boolean,
    default: true,
  },
  editAssignment: {
    required: false,
    type: Boolean,
    default: false,
  },
  reservationStart: {
    type: [String, Object],
  },
  reservationEnd: {
    type: [String, Object],
  },
  reservationId: {
    required: true,
    type: Number,
  },
  modalVisible: {
    required: true,
    type: Boolean,
  },
  closeModal: {
    required: true,
    type: Function,
  },
  prefilledUser: {
    required: false,
    type: Object,
    default: null,
  },
  prefilledProServiceProfileId: {
    required: false,
    type: Number,
    default: null,
  },
  prefilledReservationService: {
    required: false,
    type: Object,
    default: null,
  },
  prefilledNote: {
    required: false,
    type: String,
    default: null,
  },
  prefilledCrewAssignmentId: {
    required: false,
    type: Number,
    default: null,
  },
});
const store = useStore();
const crewMembers = computed(() => store.state.crewAssignment.crewMembers);

const updateCrewAssignment = props =>
  store.dispatch('crewAssignment/updateCrewAssignment', props);

const resetCrewMembers = () =>
  store.dispatch('crewAssignment/resetCrewMembers');

const createCrewAssignment = props =>
  store.dispatch('crewAssignment/createCrewAssignment', props);

const addCrewAssignmentToReservation = props =>
  store.commit(`${ADD_CREW_ASSIGNMENT_TO_RESERVATION}`, props);

const loadCrewMembers = props =>
  store.dispatch('crewAssignment/loadCrewMembers', props);

const loadProServiceRequirements = () =>
  store.dispatch('proService/loadProServiceRequirements', {
    reservationId: props.reservationId,
  });

const proServiceRequirements = computed(
  () => store.state.proService.proServiceRequirements
);

const queryFilter = ref('');
const crewRole = ref(null);
const proServiceProfileId = ref(null);
const user = ref(null);
const userId = ref(null);
const accountId = ref(null);
const userName = ref('');
const note = ref('');
const requestConfirmation = ref(null);
const placeholderText = ref('search by crew member...');
const searchType = ref('selection-search-empty');
const reservationService = ref({ title: null, id: null, role: null });

const proServiceProfileOptions = computed(() => {
  let options = [];
  user.value?.pro_service_profiles?.forEach(profile => {
    options.push({
      value: profile.id,
      label: `${profile.provider_name} -- ${profile.service_type}`,
      disabled:
        reservationService.value.title &&
        reservationService.value.title !== 'other' &&
        !reservationService.value.title.includes(profile.service_type),
    });
  });
  return options;
});

const allowedRoles = computed(() => {
  let crewRoles = [
    { value: 'photographer', label: 'photographer/videographer' },
    { value: 'assistant', label: 'assistant' },
    { value: 'editor', label: 'editor' },
    { value: 'sidekick', label: 'sidekick' },
    { value: 'bonus_creator', label: 'bonus creator' },
    { value: 'onboarding_specialist', label: 'onboarding specialist' },
    { value: 'approver', label: 'approver' },
    { value: 'producer', label: 'producer' },
  ];
  let pspRoles = [
    { value: 'hand_model', label: 'hand model' },
    { value: 'foot_model', label: 'foot model' },
    { value: 'full_body_model', label: 'full body model' },
    { value: 'pet_model', label: 'pet model' },
    { value: 'content_creator', label: 'content creator' },
    { value: 'styling', label: 'styling' },
    { value: 'grocery_shopping', label: 'grocery shopping' },
    { value: 'hair_and_makeup', label: 'hair and makeup' },
    { value: 'steaming', label: 'steaming' },
    { value: 'mannequin_styling', label: 'mannequin styling' },
  ];
  if (!user.value) {
    return crewRoles.concat(pspRoles);
  } else if (user.value.employee_type === 'pro services') {
    return pspRoles;
  } else {
    return crewRoles.concat(pspRoles);
  }
});

const placeholderColor = computed(() => {
  if (!user.value) {
    return '#6d6e71';
  } else {
    return 'black';
  }
});

const isFormFilled = computed(() => {
  return (
    userId.value &&
    crewRole.value &&
    (user.value?.employee_type === 'pro services' &&
    user.value?.pro_service_profiles?.length > 0
      ? proServiceProfileId.value
      : true) &&
    (user.value?.employee_type === 'pro services'
      ? reservationService.value.role
      : true)
  );
});

const purchasedServices = computed(() => {
  let finalArray = [];
  proServiceRequirements.value.forEach(item => {
    finalArray.push({
      value: {
        id: item.id,
        title: item.numbered_title,
        role: item.selected_tags[0].title,
      },
      label: item.numbered_title,
    });
  });
  finalArray.push({
    value: {
      title: 'other',
      role: 'other',
    },
    label: 'other',
  });
  if (user.value?.employee_type !== 'pro services') {
    finalArray.unshift({
      value: { id: null, role: null, title: 'no service' },
      label: 'no service',
    });
  }
  return finalArray;
});

const isShortTermAssignment = computed(() => {
  if (props.reservationStart) {
    return (
      workingDaysBetweenDates(new Date(), new Date(props.reservationStart)) <= 2
    );
  } else {
    return false;
  }
});

function selectCrewMember(crewMember) {
  searchType.value = 'selection-search-full';
  user.value = crewMember;
  userId.value = crewMember.id;
  accountId.value = crewMember.account_id;
  userName.value = crewMember.name;
  queryFilter.value = '';
  if (props.prefilledReservationService) {
    crewRole.value = props.prefilledReservationService.role.replaceAll(
      ' ',
      '_'
    );
  } else {
    crewRole.value = null;
  }
  placeholderText.value = crewMember.name + ' - ' + crewMember.locations;
  resetCrewMembers();
  if (user.value.employee_type === 'pro services') {
    requestConfirmation.value = true;
  } else {
    requestConfirmation.value = null;
  }
}

function resetForm() {
  searchType.value = 'selection-search-empty';
  queryFilter.value = '';
  proServiceProfileId.value = null;
  if (!props.prefilledReservationService) {
    reservationService.value = { title: null, id: null, role: null };
  }
  crewRole.value = null;
  note.value = '';
  requestConfirmation.value = null;
  userName.value = '';
  userId.value = null;
  accountId.value = null;
  placeholderText.value = 'search by crew member...';
  user.value = null;
  resetCrewMembers();
}

function submitCrewAssignment() {
  //submits a crew assignment create request to the db
  createCrewAssignment({
    crewRole: crewRole.value,
    proServiceProfileId: proServiceProfileId.value,
    userId: userId.value,
    reservationId: props.reservationId,
    proServiceRequirementId: reservationService.value.id,
    note: note.value,
    status: requestConfirmation.value ? 'sent' : 'accepted',
  });
  props.closeModal();
  resetForm();
}

function addCrewAssignment() {
  //adds crew to the reservations store reservation.crew object
  addCrewAssignmentToReservation({
    crew_role: crewRole.value,
    pro_service_profile_id: proServiceProfileId.value,
    crew_avatar: '',
    user_id: userId.value,
    account_id: accountId.value,
    note: note.value,
    user_name: userName.value,
    status: requestConfirmation.value ? 'sent' : 'accepted',
  });
  props.closeModal();
  resetForm();
}
function updateReservationCrewAssignment() {
  // currently only updating notes, but could update more in the future
  updateCrewAssignment({
    reservationId: props.reservationId,
    crewAssignmentId: props.prefilledCrewAssignmentId,
    assignmentNote: note.value,
  });
  // add the update to the crew state in reservations.js
  addCrewAssignmentToReservation({
    id: props.prefilledCrewAssignmentId,
    crew_role: crewRole.value.replace(/_/g, ' '),
    pro_service_profile_id: proServiceProfileId.value,
    crew_avatar: '',
    user_id: userId.value,
    account_id: accountId.value,
    note: note.value,
    user_name: userName.value,
    status: requestConfirmation.value ? 'sent' : 'accepted',
  });
  props.closeModal();
  resetForm();
}

function updateCrewMemberSearch(query) {
  queryFilter.value = query;
  let searchParams = { reservationId: props.reservationId };

  if (props.reservationStart) {
    searchParams.startTime =
      convertTodddDDMMMYYY(props.reservationStart, 'UTC') +
      ' ' +
      convertToHHmmss(props.reservationStart, 'UTC');
  }

  if (props.reservationEnd) {
    searchParams.endTime =
      convertTodddDDMMMYYY(props.reservationEnd, 'UTC') +
      ' ' +
      convertToHHmmss(props.reservationEnd, 'UTC');
  }
  searchParams.query = queryFilter.value.length > 1 ? queryFilter.value : '';
  loadCrewMembers(searchParams);
  resetForm();
}

function handleSelectCrewRole(val) {
  crewRole.value = val;
}

function cancelCrewAssignmentModal() {
  props.closeModal();
  resetForm();
}
function handleSelectPSP(val) {
  proServiceProfileId.value = val;
}

function handleSelectService(val) {
  reservationService.value = val;
  if (user.value?.employee_type === 'pro services' || props.editAssignment) {
    if (val.role === 'other') {
      crewRole.value = null;
    } else {
      crewRole.value = val.role.replaceAll(' ', '_');
    }
  }
}
function handleAssignmentNote(val) {
  note.value = val;
}

//formatting functions

function handleMaxCharacters() {
  note.value = note.value.substring(0, 300);
}

function formattedTime(dateTime) {
  let date = new Date(dateTime);
  let options = {
    hour: 'numeric',
    minute: 'numeric',
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

function formattedEvent(start, end) {
  return ` ${formattedTime(start)} to ${formattedTime(end)}`;
}

function formattedUnavailabilities(events) {
  let formattedEvents = events.map(event =>
    formattedEvent(event.start, event.end)
  );
  return formattedEvents.join(', ');
}

onMounted(() => {
  loadProServiceRequirements();
  if (props.prefilledUser) {
    selectCrewMember(props.prefilledUser);
  }
  if (props.prefilledProServiceProfileId) {
    handleSelectPSP(props.prefilledProServiceProfileId);
  }
  if (props.prefilledReservationService) {
    handleSelectService(props.prefilledReservationService);
  }
  if (props.prefilledNote) {
    handleAssignmentNote(props.prefilledNote);
  }
});

const assignmentSubmit = computed(() => {
  if (!props.assignCrewDirectly && !props.editAssignment) {
    return addCrewAssignment;
  } else if (props.editAssignment) {
    return updateReservationCrewAssignment;
  } else {
    return submitCrewAssignment;
  }
});
</script>
<template>
  <SoonaDialog v-if="modalVisible" @close="cancelCrewAssignmentModal">
    <template #heading>crew assignment ✍️</template>
    <div class="column">
      <div class="mb-m">
        <div class="field">
          <label class="label">assign crew member*</label>
          <div class="control">
            <TextInput
              :model-value="queryFilter"
              :type="searchType"
              name="searchCrew"
              :placeholder="placeholderText"
              :placeholder-color="placeholderColor"
              :on-change="updateCrewMemberSearch"
              :disabled="editAssignment"
            />
          </div>
        </div>
        <div
          v-if="crewMembers && crewMembers.length > 0"
          class="mt-s header__nav__menu__sub"
        >
          <a
            v-for="crewMember in crewMembers"
            :key="crewMember.id"
            class="header__nav__menu__sub__item__link u-body--regular"
            @click.prevent="selectCrewMember(crewMember)"
          >
            <div
              class="columns search-crew-container is-mobile"
              :class="{ 'highlight-crew': userId == crewMember.id }"
            >
              <div class="column is-2 crew-avatar-container is-centered">
                <SoonaAvatar
                  :name="crewMember.name"
                  size="2.5rem"
                  :src="crewMember.avatar_url"
                  type="user"
                />
              </div>

              <div class="column is-10 crew-assignment-info-container">
                <p class="crew-assignment-info">
                  {{ crewMember.name }}
                </p>
                <p v-if="crewMember.employee_type" class="u-small--regular">
                  {{ crewMember.employee_type }}
                  {{ crewMember.locations.join('-') }}
                </p>
                <SoonaFlag
                  v-if="crewMember.existing_roles"
                  background-color="#ffdd57"
                >
                  <template #title>
                    currently booked as
                    {{ crewMember.existing_roles }}
                  </template>
                </SoonaFlag>
                <SoonaFlag
                  v-if="crewMember.current_unavailabilities"
                  background-color="#E6E6E6"
                >
                  <template #title>
                    unavailable from
                    {{
                      formattedUnavailabilities(
                        crewMember.current_unavailabilities
                      )
                    }}
                  </template>
                </SoonaFlag>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div
        v-if="user && user.employee_type === 'pro services'"
        class="field selection-area"
      >
        <div class="control">
          <label class="label has-text-weight-semibold"
            >select purchased pro service*</label
          >
          <label class="label extra-label-note"
            >only purchased pro services will appear here. select if
            applicable.</label
          >
          <SoonaSelect
            :model-value="reservationService.title"
            :disabled="!user || editAssignment"
            :options="purchasedServices"
            @update:model-value="val => handleSelectService(val)"
          >
          </SoonaSelect>
        </div>
      </div>

      <div
        v-if="
          user &&
          user.employee_type === 'pro services' &&
          proServiceProfileOptions.length > 0
        "
        class="field selection-area"
      >
        <div class="control">
          <label class="label has-text-weight-semibold">select provider*</label>
          <SoonaSelect
            :model-value="proServiceProfileId"
            :disabled="!user || editAssignment || !reservationService.title"
            :options="proServiceProfileOptions"
            :selectable="val => !val.disabled"
            @update:model-value="val => handleSelectPSP(val)"
          >
          </SoonaSelect>
        </div>
      </div>

      <div v-if="user" class="field selection-area">
        <div class="control">
          <label class="label has-text-weight-semibold"> assign role* </label>
          <SoonaSelect
            :model-value="crewRole"
            :disabled="!user || editAssignment"
            :options="allowedRoles"
            cypress-name="crew-assignment-role"
            @update:model-value="val => handleSelectCrewRole(val)"
          >
          </SoonaSelect>
        </div>
      </div>

      <div class="field selection-area">
        <label class="label">assignment note (only visible to assignee)</label>
        <div class="control">
          <textarea
            v-model="note"
            class="input note-input-textarea"
            name="note"
            placeholder="e.g. make sure you have clean, unpolished nails"
            @keydown="handleMaxCharacters"
          />
        </div>
      </div>

      <div class="field auto-accept">
        <div class="control">
          <input
            id="request-confirmation-input"
            v-model="requestConfirmation"
            class="checkbox"
            type="checkbox"
            name="auto-accept"
          />
          <span>
            <label for="request-confirmation-input" class="label">
              request assignment confirmation.
            </label>
            <label for="request-confirmation-input" class="label">
              when checked crew must accept or decline crew assignment.
            </label>
          </span>
        </div>
      </div>
      <div
        v-if="
          user && user.employee_type === 'pro services' && isShortTermAssignment
        "
        class="short-term-warning"
      >
        <AlertIcon />
        <p>
          shoot is less than 48hrs away. uncheck assignment confirmation to
          auto-accept for provider.
        </p>
      </div>
    </div>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        :disabled="!isFormFilled"
        data-cypress="button-dialog-confirm"
        @on-click="assignmentSubmit"
      >
        submit
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
@use '@/variables';

.crew-avatar-details {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 18155.375rem;
}

.note-input-textarea {
  min-height: 6.25rem;
}

.header__nav__menu__sub {
  overflow-y: auto;
  max-height: 15rem;
  padding: 0.5rem 0;
  background-color: variables.$white-default;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.header__nav__menu__sub__item__link {
  display: block;
  padding: 0.5rem 0.75rem;
  color: variables.$black-default;
  transition: background-color 0.1s ease-out;
  min-width: 12.5rem;

  &:hover,
  &:focus-visible {
    background-color: variables.$friendly-red-20;
  }
  .highlight-crew {
    background-color: variables.$friendly-red-10;
    &:hover,
    &:focus-visible {
      background-color: variables.$friendly-red-20;
    }
  }
}

.selection-area {
  margin-top: 1.25rem;
  .extra-label-note {
    font-weight: 100;
    font-size: 0.8rem;
  }
}

.short-term-warning {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  background-color: variables.$tangerine-10;
  border: solid 0.0625rem variables.$tangerine-30;
  border-radius: 0.375rem;
  padding: 0.625rem;
  margin-top: 0.625rem;
  svg {
    flex-basis: 2.25rem;
  }
}

.auto-accept {
  margin-top: 1.25rem;
}

.crew-assignment-info-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>

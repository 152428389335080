<template>
  <div v-if="showMenu" class="ChatAttachmentsMenu">
    <SoonaTooltip placement="top">
      <template
        #default="{
          setRef,
          mouseenter,
          focus,
          mouseleave,
          blur,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          :ref="el => setRef(el)"
          variation="icon-plain-gray"
          size="medium"
          class="attach-button"
          :aria-describedby="ariaDescribedby"
          @on-click="showUploader = !showUploader"
          @mouseenter="mouseenter"
          @focus="focus"
          @mouseleave="mouseleave"
          @blur="blur"
        >
          <SoonaIcon name="paperclip" />
        </SoonaButton>
      </template>
      <template #tooltip-content>attach a file</template>
    </SoonaTooltip>
    <!-- Attachment Uploader Modal -->
    <SoonaDialog v-if="showUploader" @close="closeModal">
      <template #heading>attach a file</template>
      <div
        class="upload-container"
        @drop.prevent="handleDrop"
        @dragover.prevent
      >
        <input
          id="hidden-input"
          ref="input"
          type="file"
          accept=".png, .jpg, .jpeg, .pdf, .gif, .mp4"
          multiple
          hidden
          @change="handleInput"
        />
        <SoonaIcon
          class="cloud-upload-arrow"
          name="cloud-up-arrow"
          size="large"
        />
        <label for="hidden-input">
          drag-and-drop file(s), or<br />
          <span class="upload-text">browse to upload</span>
        </label>
        <p class="upload-limits">
          *jpeg, png, pdf, gif, or mp4 only, 3 files max, max file size 3mb
        </p>
        <p v-if="error" class="error-message">{{ error }}</p>
      </div>
    </SoonaDialog>
  </div>
</template>

<script>
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

export default {
  name: 'ChatAttachmentsMenu',
  components: {
    SoonaDialog,
    SoonaButton,
    SoonaIcon,
    SoonaTooltip,
  },
  props: {
    showMenu: Boolean,
    attachFiles: Function,
    files: Array,
  },
  data() {
    return {
      showUploader: false,
      error: '',
      maxFiles: 3,
      fileSizeLimit: 3,
    };
  },
  methods: {
    closeModal() {
      this.error = '';
      this.showUploader = false;
    },
    handleDrop(e) {
      const files = e.dataTransfer.files;
      this.handleUpload(files);
    },
    handleInput(e) {
      this.error = '';
      const files = e.target.files;
      this.handleUpload(files);
      // clear input value because the input's on-change event won't fire if the same file is selected again
      this.$refs.input.value = '';
    },
    handleUpload(files) {
      this.checkFileLimits(files);

      if (this.error) {
        return;
      }

      const uploads = [...this.files, ...files];
      // send uploads to parent component
      this.attachFiles(uploads);
      this.showUploader = false;
    },

    checkFileLimits(files) {
      // check if too many files are being uploaded
      if (files.length + this.files.length > this.maxFiles) {
        this.error = `you can only upload ${this.maxFiles} files.`;
        return;
      }
      // check if file is too big
      for (const file of files) {
        if (file.size / 1000000 > this.fileSizeLimit) {
          this.error = `${file.name} is too large; must be less than 3mb`;
          return;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.ChatAttachmentsMenu {
  .attach-button {
    position: relative;
    overflow: visible;
  }
}
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 6rem;
  margin-top: 2rem;
  background: variables.$gray-10;
  border: 0.0625rem variables.$gray-40 dashed;
  border-radius: 0.25rem;
  text-align: center;
}
.upload-text {
  color: variables.$periwink-blue-50;
  cursor: pointer;
  font-weight: 800;
}
label {
  font-weight: 800;
}
.error-message {
  color: variables.$friendly-red-50;
}
.upload-limits {
  color: variables.$gray-60;
}
.cloud-upload-arrow {
  height: 2rem;
  width: 2rem;
  color: variables.$gray-40;
  margin-bottom: 2rem;
}
.attach-button-container {
  position: relative;
}

@media only screen and (max-device-width: variables.$screen-xs-max) {
  .upload-container {
    text-align: center;
    padding: 3rem;
  }
  .upload-text {
    margin-bottom: 0.75rem;
  }
  .cloud-upload-icon {
    margin-bottom: 0.75rem;
  }
}
</style>

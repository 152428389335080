import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {Object} [params]
 * @param {string | Ref<string>} [accountId]
 * @param {string | Ref<string>} [staffPicksCollectionId]
 * @param {Object | Ref<string>} [reservationId]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useGetUnBaggedStaffPicksTotal(
  { accountId, staffPicksCollectionId, reservationId },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.unBaggedTotal(
      accountId,
      staffPicksCollectionId,
      reservationId
    ),
    queryFn: async ({ signal }) => {
      let params = {};
      if (toValue(reservationId)) {
        params = { reservation_id: toValue(reservationId) };
      }
      const response = await http.get(
        `/accounts/${toValue(accountId)}/staff_picks_collections/${toValue(
          staffPicksCollectionId
        )}/un_bagged_total.json`,
        {
          params,
          signal,
        }
      );
      return response.data?.['un_bagged_total'] ?? 0;
    },
    ...queryOptions,
  });
}

<template>
  <div
    v-if="(!accountReferrer || displaySuccessMessage) && !isSoonaStaff"
    class="how-did-you-hear-about-us"
  >
    <div class="how-did-you-hear-about-us__inner">
      <div class="how-did-you-hear-about-us__top">
        <h3 class="how-did-you-hear-about-us__title">
          <span class="how-did-you-hear-about-us__title-emoji">🤔</span> we were
          wondering ... how did you hear about us?
        </h3>
        <p v-if="hasSubtitle" class="how-did-you-hear-about-us__subtitle">
          this won’t affect your check out! don't forget to confirm and pay
        </p>
      </div>
      <div class="how-did-you-hear-about-us__bottom">
        <!-- Keep this around for legacy error handling and migrate to using flash -->
        <!-- rip out & turn into its own component eventually -->
        <div v-if="displaySuccessMessage">
          <div class="soona-flash soona-flash--success">
            <SoonaIcon name="circle-check-solid" class="soona-flash__icon" />
            <p class="soona-flash__message">
              success! your response has been recorded.
            </p>
            <button
              class="soona-flash__close"
              @click="displaySuccessMessage = false"
            >
              &times;
            </button>
          </div>
        </div>
        <form v-else class="how-did-you-hear-about-us__form u-container">
          <div
            v-for="(option, i) in referrerList"
            :key="i"
            class="how-did-you-hear-about-us__option"
          >
            <label class="how-did-you-hear-about-us__label">
              <input
                class="how-did-you-hear-about-us__input"
                type="radio"
                name="place"
                :value="option"
                @click="onReferrerClick(option)"
              />
              {{ option }}
            </label>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as Sentry from '@sentry/browser';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapability } from '@/composables/useCapability';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  components: {
    SoonaIcon,
  },
  props: {
    hasSubtitle: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  setup() {
    const { inputChanged } = useBaseEvents();

    const { hasCapability: isSoonaStaff } = useCapability({
      capability: 'soona_staff',
    });

    return { inputChanged, isSoonaStaff };
  },
  data() {
    return {
      displaySuccessMessage: false,
      referrerList: [
        'search engine',
        'Instagram ad',
        'social media post',
        'Shopify',
        'an article/blog',
        'Facebook ad',
        'LinkedIn',
        'event/conference',
        'TikTok',
        'from a friend',
        'soona emailed me',
        'saw a soona studio',
        'other',
      ],
    };
  },
  computed: {
    ...mapState({
      accountReferrer: state =>
        state.account.profile_data.lets_connect_one?.data
          ?.how_did_you_hear_about_us,
    }),
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    onReferrerClick(val) {
      const info = {
        lets_connect_one: {
          data: {
            how_did_you_hear_about_us: val,
          },
        },
      };

      this.updateAccount({ accountParams: info })
        .then(() => {
          this.displaySuccessMessage = true;
          this.inputChanged({
            context: 'booking',
            subContext: 'down payment',
            inputLabel: `we were wondering ... how did you hear about us?`,
            inputType: 'radio',
            inputValue: val,
          });
        })
        .catch(err => {
          if (err.response.status !== 422) {
            Sentry.captureException(
              new Error('Updating account referrer source failed'),
              { extra: { err } }
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.how-did-you-hear-about-us {
  padding-top: 3rem;
  width: 100%;

  &__inner {
    background-color: variables.$tangerine-05;
  }

  &__top {
    border-bottom: 0.0625rem solid variables.$black-default;
    border-top: 0.0625rem solid variables.$black-default;
    padding: 1rem;
    padding-top: 1.25rem;

    @media (min-width: variables.$screen-sm-min) {
      padding-bottom: 1.125rem;
    }
  }

  &__title {
    @include variables_fonts.u-body--all-caps-black;

    align-items: center;
    display: flex;
    justify-content: center;

    &-emoji {
      font-size: 1.625rem;
      padding-right: 0.625rem;
    }
  }

  &__subtitle {
    @include variables_fonts.u-label--regular;

    padding-top: 0.5rem;
    text-align: center;
  }

  &__bottom {
    margin: 0 auto;
    min-height: 10rem;
    padding: 1.3125rem 1.125rem 1.875rem 1.125rem;

    @media (min-width: variables.$screen-sm-min) {
      max-width: 64.75rem;
      padding-bottom: 1.6875rem;
      padding-top: 1.5625rem;
    }
  }

  &__form {
    column-gap: 1.25rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, 100%);
    justify-content: center;
    row-gap: 1rem;

    @media (min-width: variables.$screen-xxs-min) {
      grid-template-columns: repeat(auto-fit, calc(50% - 1.25rem));
    }

    @media (min-width: variables.$screen-sm-min) {
      grid-template-columns: repeat(auto-fit, calc(33.3333% - 1.25rem));
      row-gap: 1.4375rem;
    }

    @media (min-width: variables.$screen-md-min) {
      grid-template-columns: repeat(auto-fit, calc(20% - 1.25rem));
    }
  }

  &__option {
    min-width: 10.9375rem;
  }

  &__label {
    @include variables_fonts.u-body--regular;

    cursor: pointer;
  }

  // rip out & turn into its own component eventually
  .soona-flash {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 0.3125rem;
    border: 0.0625rem solid transparent;
    margin: 0;
    padding: 0.625rem;
    position: relative;

    &--success {
      border-color: variables.$avo-toast-10;
      background-color: variables.$avo-toast-10;
    }

    &__icon {
      color: variables.$success-graphic;
      flex: 0 0 1.25rem;
      margin: 0.125rem 0.5rem 0 0;
    }

    &__message {
      @include variables_fonts.u-body--regular;

      padding-right: 1.875rem;
    }

    &__close {
      background: transparent;
      border: none;
      color: variables.$gray-60;
      cursor: pointer;
      font-size: 1.25rem;
      position: absolute;
      right: 0.625rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>

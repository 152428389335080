import { useAiProducerStore } from './useAiProducerStore';
import { useAssistantStore } from '@/components/ai_assistant/composables/useAssistantStore';

export const useFunctionHandler = () => {
  const aiProducerStore = useAiProducerStore();
  const assistantStore = useAssistantStore();

  const functionHandler = async functionCall => {
    const { name, arguments: rawArgs } = functionCall;
    const args = JSON.parse(rawArgs);

    switch (name) {
      case 'set_or_update_product_type':
      case 'set_or_update_shoot_type':
      case 'set_shoot_detail':
        aiProducerStore.updateBookingState({
          [args.label ?? args.type]: args.value,
        });
        return {
          response: {
            success: true,
            message: `shoot updated`,
          },
        };

      case 'show_pack_preview':
        assistantStore.appendMessage({
          role: 'pack_preview',
          text: '',
          packId: args.pack_id,
        });

        return {
          pack_id: args.pack_id,
          response: {
            success: true,
            message: 'Showing pack preview',
          },
        };

      case 'forward_to':
        assistantStore.appendMessage({
          role: 'forward_action',
          text: '',
          forwardData: {
            reason: args.reason,
            destination: args.destination,
          },
        });

        return {
          response: {
            success: true,
            message: `User forwarded to ${args.destination}`,
          },
        };

      default:
        return { success: false, message: 'Unknown function' };
    }
  };

  return { functionHandler };
};

<script setup>
import { computed } from 'vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  listing: {
    type: Object,
    required: true,
  },
  platform: {
    type: String,
    required: true,
  },
});

const imageSrc = computed(
  () => props.listing.main_image_url ?? PlaceholderImage
);

const isAmazonProduct = computed(() => props.platform === 'Amazon');
const isShopifyProduct = computed(() => props.platform === 'Shopify');
</script>

<template>
  <div class="listing-card__product-image-cell">
    <img :src="imageSrc" alt="" />
  </div>
  <div class="listing-card__logo-section">
    <SoonaIcon
      v-if="isAmazonProduct"
      name="amazon-logo"
      class="listing-card__logo"
      size="large"
    />
    <SoonaIcon
      v-if="isShopifyProduct"
      name="shopify-logo"
      class="listing-card__logo"
      size="large"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-card {
  &__product-image-cell {
    flex: 0 0 4.5rem;
    position: relative;
    background-color: variables.$gray-20;
    min-height: 4.5rem;

    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__logo-section {
    position: absolute;
    display: flex;
    gap: 0.25rem;
    top: 0.25rem;
    left: 0.25rem;
  }

  &__logo {
    background-color: variables.$white-default;
    border-radius: 50%;
    padding: 0.125rem;
  }
}
</style>

import { useMutation } from '@tanstack/vue-query';
import { createAutoEditTask } from '@/api/autoEditTasks';

/**
 * @returns {UseMutationReturnType}
 */
export function useCreateAutoEditTask() {
  return useMutation({
    mutationFn: body => createAutoEditTask(body),
  });
}

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '../config/vue-axios';

export function useCreatePublishTask(
  accountId,
  { externalProductId, integrationId } = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async params => {
      await http.post(
        `/accounts/${accountId.value}/digital_assets/bulk-publish`,
        params
      );
    },
    onSuccess: async () => {
      const promises = [
        queryClient.invalidateQueries({
          queryKey: queryKeys.listingActions(accountId),
        }),
      ];
      if (externalProductId?.value && integrationId?.value) {
        // used when accessing from listing detail page
        promises.push(
          queryClient.invalidateQueries({
            queryKey: queryKeys.activeListing(integrationId, externalProductId),
          })
        );
      }
      await Promise.all(promises);
    },
  });
}

import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import {
  assignAccountToReservationAndOrder,
  updateReservation,
  updateReservationLocation,
  updateReservationToAnytime,
} from '../api/reservation';
import { useRouter } from 'vue-router';

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useUpdateReservation(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => updateReservation(unref(reservationId), body),
    onSuccess: async data => {
      queryClient.setQueryData(queryKeys.reservation(reservationId), data);
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
  });
}

/**
 * @param {number | Ref<number>} reservationId
 */
export function useUpdateReservationLocation(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => updateReservationLocation(unref(reservationId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
  });
}

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useUpdateReservationToAnytime(reservationId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => updateReservationToAnytime(unref(reservationId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservation(unref(reservationId)),
      });
    },
  });
}

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useAssignAccountToReservationAndOrder(reservationId) {
  const queryClient = useQueryClient();
  const router = useRouter();

  return useMutation({
    mutationFn: () => assignAccountToReservationAndOrder(unref(reservationId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
    onError: error => {
      if (
        error.response.data?.code ===
        'Api::V1::ReservationsController::InvalidH4aError'
      ) {
        router.push({
          path: '/booking/free-headshot-received',
          replace: true,
        });
      }
    },
  });
}

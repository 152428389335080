<script setup>
import BigHeroButton from '@/components/user-generated-content/BigHeroButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

const emit = defineEmits(['create-brief-click']);

const route = useRoute();
const { linkClicked } = useBaseEvents();

function onCreateBriefClick() {
  linkClicked({
    context: route.meta.context,
    subContext: 'create a free brief',
    linkLabel: 'create a free brief',
    linkHref: null,
  });

  emit('create-brief-click');
}
</script>

<template>
  <div class="what-is-ugc">
    <div class="what-is-ugc__header">
      <h2 class="u-headline--heavy">what is user generated content?</h2>
      <p class="what-is-ugc__header__description u-body--regular">
        user generated content (UGC) fuels today’s digital world, offering
        authentic perspectives from everyday folks through reviews, posts, and
        videos. UGC goes beyond mere opinions; it provides a glimpse into
        people’s lives, revealing their loves and passions. customers who
        interact with UGC purchase at a rate that’s 102% higher than average -
        this speaks to UGC’s authenticity translating to customers.
      </p>
    </div>
    <!-- extra div wrapper to work around Safari grid quirk -->
    <div>
      <div class="what-is-ugc__image-grid">
        <img
          src="@images/trend/UGC_earring@2x.jpg"
          alt="modelling an earring"
          class="span-2-col"
        />
        <img
          src="@images/trend/UGC_ice_cream@2x.jpg"
          alt="a group with ice cream"
        />
        <img
          src="@images/trend/UGC_socks@2x.jpg"
          alt="person modelling socks"
          class="span-2-col hide-on-mobile"
        />
        <img
          src="@images/trend/UGC_vodka@2x.jpg"
          alt="opening bottle of vodka"
          class="hide-on-mobile"
        />
        <img
          src="@images/trend/UGC_yellow@2x.jpg"
          alt="jumping showing a shirt"
        />
        <BigHeroButton
          is="button"
          class="what-is-ugc__image-grid__brief-button"
          @click="onCreateBriefClick"
        >
          create a free brief
        </BigHeroButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.what-is-ugc {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    &__description {
      max-width: 54rem;
    }
  }

  &__image-grid {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);

    img {
      display: block;
      border-radius: 1rem;
      border: 0.0625rem solid variables.$black-default;
      background-color: variables.$gray-10;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .span-2-col {
      grid-row: span 2;
    }

    .hide-on-mobile {
      display: none;
    }

    &__brief-button {
      grid-column: span 2;
    }
  }
}

@mixin what-is-ugc-large-styles {
  .what-is-ugc__image-grid {
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);

    .hide-on-mobile {
      display: block;
    }

    &__brief-button {
      grid-column: span 1;
    }
  }
}

@container ugc-page (min-width: 46rem) {
  @include what-is-ugc-large-styles;
}

/* compat: remove when dropping Safari 15 */
@supports not (container-type: inline-size) {
  @media (min-width: 46rem) {
    @include what-is-ugc-large-styles;
  }
}
</style>

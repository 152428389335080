import { http } from '../config/vue-axios';

/**
 *
 * @param {number} reservationId
 */
export async function getTopChoicesOnReservation(reservationId, signal) {
  const response = await http.get(
    `reservations/${reservationId}/pro_service_requirements/top_choices_on_reservation.json`,
    {
      signal,
    }
  );

  return response.data;
}

/**
 *
 * @param {number} proServiceRequirementId
 * @param {number} reservationId
 */
export async function confirmProServiceRequirement(
  proServiceRequirementId,
  reservationId
) {
  const response = await http.put(
    `reservations/${reservationId}/pro_service_requirements/${proServiceRequirementId}/confirm`
  );

  return response.data;
}

<script setup>
import { computed } from 'vue';
import BaseInfiniteGalleryCard from '@/components/infinite_asset_gallery/BaseInfiniteGalleryCard.vue';
import OverlayButtonToggle from '@/components/infinite_asset_gallery/OverlayButtonToggle.vue';
import DigitalAssetStatusBadge from '@/components/infinite_asset_gallery/DigitalAssetStatusBadge.vue';

const props = defineProps({
  editsCollectionDigitalAsset: {
    type: Object,
    required: true,
  },
  flexGrow: {
    type: Number,
    default: 0,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  to: {
    type: [String, Object],
    required: true,
  },
});

defineEmits([
  'request-page',
  'open-assign-original-dialog',
  'accept',
  'reject',
]);

const editsCollectionDigitalAsset = computed(
  () => props.editsCollectionDigitalAsset
);

const asset = computed(() => props.editsCollectionDigitalAsset.digital_asset);
</script>
<template>
  <BaseInfiniteGalleryCard
    :digital-asset="asset"
    :paged-asset="editsCollectionDigitalAsset"
    :flex-grow="flexGrow"
    @request-page="page => $emit('request-page', page)"
  >
    <template #action-buttons>
      <OverlayButtonToggle
        :loading="loading"
        visually-hidden-text="assign edit to original"
        icon-name="broken-link"
        @click="$emit('open-assign-original-dialog')"
      >
        <template #tooltip-content>assign original</template>
      </OverlayButtonToggle>
      <OverlayButtonToggle
        :loading="loading"
        visually-hidden-text="hide"
        icon-name="circle-check"
        @click="$emit('accept')"
      >
        <template #tooltip-content>deliver immediately to customer</template>
      </OverlayButtonToggle>
      <OverlayButtonToggle
        :loading="loading"
        visually-hidden-text="hide"
        icon-name="circle-xmark"
        @click="$emit('reject')"
      >
        <template #tooltip-content>do not deliver</template>
      </OverlayButtonToggle>
    </template>
    <template #statuses>
      <DigitalAssetStatusBadge>unlinked</DigitalAssetStatusBadge>
    </template>
  </BaseInfiniteGalleryCard>
</template>

<script setup>
import { ref } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import IntegrateAccountsDialog from '@/components/shared/IntegrateAccountsDialog.vue';

const displayDialog = ref(false);
</script>

<template>
  <div class="example-listing-cta">
    <div class="example-listing-cta__icon">
      <span>
        <SoonaIcon name="chart-simple" />
      </span>
    </div>
    <div class="example-listing-cta__content">
      <div class="example-listing-cta__text">
        <p class="u-body--heavy">
          view insights customized to your product listings
        </p>
        <p class="u-body--regular">
          connect your store to receive your soona score
        </p>
      </div>
      <div class="example-listing-cta__action">
        <SoonaButton
          variation="solid-black"
          class="score-progress-bar--cta"
          @click="displayDialog = true"
        >
          get your score <SoonaIcon name="arrow-right" />
        </SoonaButton>
      </div>
    </div>
  </div>
  <IntegrateAccountsDialog
    v-if="displayDialog"
    @close="displayDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.example-listing-cta {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.625rem;
  background-color: variables.$bubbletape-pink-05;
  margin-top: 1.5rem;

  &__icon > span {
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    width: fit-content;
    background-color: variables.$bubbletape-pink-20;
    color: variables.$bubbletape-pink-60;
  }

  &__content {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
</style>

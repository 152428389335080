<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { PeriwinkBlue50 } from 'src/variables.module.scss';
import FeatureList from 'src/components/subscriptions/FeatureList.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import StepLayout from 'src/components/modal-payment-flows/preferred/StepLayout.vue';

const props = defineProps({
  allPlansButtonLabel: {
    type: String,
  },
  context: {
    type: String,
  },
  featureList: {
    type: Array,
  },
  featureSubtitle: {
    type: String,
  },
  showBackButton: {
    default: false,
    type: Boolean,
  },
  showLearnMoreButton: {
    default: false,
    type: Boolean,
  },
  subContext: {
    type: String,
  },
});

const emits = defineEmits(['back', 'close', 'next']);

const { linkClicked } = useBaseEvents();

const showBackButton = computed(() => props.showBackButton);
const showLearnMoreButton = computed(() => props.showLearnMoreButton);

const handleContinueBtnClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'continue',
    linkHref: null,
  });

  emits('next', 'pricing');
};

const handleLearnMoreBtnClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'learn more',
    linkHref: 'https://soona.co/preferred',
  });
};
</script>

<template>
  <StepLayout
    class="landing-step"
    :is-right-column-reversed="true"
    :right-column-bg-color="PeriwinkBlue50"
    :show-back-button="showBackButton"
    @back="emits('back')"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template #heading>
      <slot name="heading" />
    </template>
    <template #subheading>
      <slot name="subheading" />
    </template>
    <div v-if="featureList" class="landing-step__features">
      <p class="u-badge--small landing-step__includes-heading">
        {{ featureSubtitle }}
      </p>
      <FeatureList :feature-list="featureList" />
    </div>
    <div class="landing-step__action-btns">
      <SoonaButton
        variation="solid-black"
        data-cypress="button-bulk-credits-modal-continue"
        @on-click="handleContinueBtnClick"
      >
        continue
      </SoonaButton>
      <SoonaButton
        v-if="showLearnMoreButton"
        element="a"
        variation="secondary-black"
        href="https://soona.co/preferred"
        rel="noopener noreferrer"
        target="_blank"
        @on-click="handleLearnMoreBtnClick"
      >
        learn more
      </SoonaButton>
    </div>
    <template #main-image>
      <img src="@images/bulk-credits/photo_collage@2x.png" alt="" />
    </template>
    <template #right-col-content>
      <div class="landing-step__savings-img-container">
        <img
          class="landing-step__savings-img"
          src="@images/bulk-credits/content_for_all@2x.png"
          alt=""
        />
      </div>
    </template>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.landing-step {
  &__plan-btn {
    align-items: center;
    border: 0.063rem solid variables.$gray-30;
    border-radius: 0.625rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
    margin-bottom: 2rem;
    outline-color: variables.$periwink-blue-60;
    outline-width: 0.125rem;
    padding: 1rem 1.25rem;
    transition: background 0.2s;

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-20;
    }

    &:focus-visible {
      outline-style: solid;
    }

    &:active {
      background-color: variables.$gray-30;
    }
  }

  &__plan-btn-left {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  &__plan-btn-right {
    align-items: center;
    display: flex;
    gap: 0.25rem;
  }

  &__includes-heading {
    padding-bottom: 0.75rem;
  }

  &__first-time-benefits {
    font-size: 0.875rem;
    list-style: disc;
    padding-left: 2.75rem;
    padding-right: 3rem;

    li {
      padding-bottom: 0.75rem;
    }
  }

  &__action-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: auto;
    padding-top: 2rem;
  }

  &__savings-img-container {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 1.8125rem;
  }

  &__savings-img {
    max-height: 9.375rem;
  }
}
</style>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  selectedBillingInterval: {
    default: 'year',
    type: String,
    validator: function (value) {
      return ['month', 'year'].includes(value);
    },
  },
  prices: {
    default: () => [],
    type: Array,
  },
});

const emits = defineEmits(['change']);

const selectedBillingInterval = computed(() => props.selectedBillingInterval);
const prices = computed(() => props.prices);
</script>

<template>
  <div class="billing-cycle-toggle" data-cypress="div-billing-cycle-toggle">
    <div class="billing-cycle-toggle__inner">
      <template v-for="price in prices" :key="price.id">
        <input
          :id="`radio-${price.recurring_interval}`"
          class="u-visually-hidden"
          :checked="selectedBillingInterval === price.recurring_interval"
          name="billing-cycle"
          type="radio"
          :value="price"
          @change="emits('change', price)"
        />
        <label
          :for="`radio-${price.recurring_interval}`"
          class="u-small--heavy billing-cycle-toggle__item"
        >
          {{ price.recurring_interval }}ly<span
            v-if="price.recurring_interval === 'year'"
          >
            - 30% off 🎉</span
          >
        </label>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.billing-cycle-toggle {
  margin: 0 auto;
  order: 3;
  width: 100%;

  @media (min-width: variables.$screen-md-min) {
    flex-grow: 1;
    order: 0;
    width: max-content;
  }

  &__inner {
    align-items: center;
    background-color: variables.$white-default;
    border-radius: 0.625rem;
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    margin: 0 auto;
    padding: 0.375rem;
    width: max-content;

    @media (min-width: variables.$screen-md-min) {
      margin: 0;
      margin-left: auto;
    }
  }

  &__item {
    background-color: variables.$periwink-blue-30;
    border: 0.125rem solid transparent;
    border-radius: 0.3125rem;
    box-shadow: variables.$elevation-1;
    cursor: pointer;
    padding: 0.375rem 0.625rem;
    text-align: center;
    transition:
      background-color 0.2s,
      border-color 0.2s;
    width: 9.75rem;

    input:checked + & {
      background-color: variables.$periwink-blue-70;
      border-color: variables.$periwink-blue-70;
      color: variables.$white-default;
    }

    input:focus-visible + & {
      background-color: variables.$periwink-blue-20;
      border-color: variables.$periwink-blue-20;
    }

    &:hover,
    &:focus-visible {
      background-color: variables.$periwink-blue-20;
      border-color: variables.$periwink-blue-20;
    }
  }
}
</style>

<script setup>
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaProgress from '@/components/ui_library/SoonaProgress.vue';

defineProps({
  isMultiple: {
    type: Boolean,
    required: true,
  },
  activeUploads: {
    type: Array,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['startUpload', 'cancelUploads', 'removeUploadFile']);

const { addToast } = useSoonaToast();

function cancelUploads() {
  emit('cancelUploads');

  addToast('incomplete upload(s) canceled', {
    variation: 'info',
    iconName: 'xmark',
  });
}
</script>

<template>
  <SoonaDialog v-if="activeUploads?.length > 0" @close="cancelUploads">
    <template #heading>
      uploading file{{ activeUploads?.length > 1 ? 's' : undefined }}
      <template v-if="label && !hideLabel"> ({{ label }}) </template>
    </template>
    <div class="progress-modal__progresses">
      <SoonaProgress
        v-for="(upload, index) in activeUploads"
        :key="index"
        :can-retry="upload.canRetry"
        :error="upload.error"
        :file-name="upload.file?.name"
        :is-waiting="!upload.activated"
        :value="(upload.tracker?.loaded / upload.tracker?.total ?? 0) * 100"
        @retry="$emit('startUpload', upload, activeUploads)"
        @remove="$emit('removeUploadFile', upload)"
      />
    </div>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" size="medium" @click="close">
        cancel incomplete upload(s)
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.progress-modal {
  &__progresses {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}
</style>

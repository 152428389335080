<script setup>
import { computed, useId } from 'vue';
import { LinkMenuState } from '../custom_plugins/LinkDialogHandler';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaEditorLinkView from '@/components/ui_library/soona_editor/link-dialog/SoonaEditorLinkView.vue';
import SoonaEditorLinkEdit from '@/components/ui_library/soona_editor/link-dialog/SoonaEditorLinkEdit.vue';

//https://github.com/sjdemartini/mui-tiptap/blob/a8471aef095aff42cdadf5ca5d2542910a629d4b/src/LinkBubbleMenu/index.tsx
const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
});

const id = useId();

const isAllowed = computed(() => {
  const isEditable = props.editor.isEditable;
  const hasLinkDialogHandler = 'linkDialogHandler' in props.editor.storage;
  return isEditable && hasLinkDialogHandler;
});

// Update the menu step if the bubble menu state has changed
const menuState = computed(() => {
  return props.editor.storage?.linkDialogHandler?.state ?? LinkMenuState.HIDDEN;
});

function onRemove() {
  // Remove the link and place the cursor at the end of the link (which
  // requires "focus" to take effect)
  props.editor
    .chain()
    .unsetLink()
    .setTextSelection(props.editor.state.selection.to)
    .focus()
    .run();
}

function onSave({ href, label }) {
  props.editor
    .chain()
    // Make sure if we're updating a link, we update the link for the
    // full link "mark"
    .extendMarkRange('link')
    // Update the link href and its text content
    .insertContent({
      type: 'text',
      marks: [
        {
          type: 'link',
          attrs: {
            href: href,
          },
        },
      ],
      text: label,
    })
    // Note that as of "@tiptap/extension-link" 2.0.0-beta.37 when
    // `autolink` is on (which we want), adding the link mark directly
    // via `insertContent` above wasn't sufficient for the link mark to
    // be applied (though specifying it above is still necessary), so we
    // insert the content there and call `setLink` separately here.
    // Unclear why this separate command is necessary, but it does the
    // trick.
    .setLink({
      href: href,
    })
    // Place the cursor at the end of the link (which requires "focus"
    // to take effect)
    .focus()
    .run();

  props.editor.commands.closeLinkDialog;
}
</script>

<template>
  <SoonaDialog
    v-if="isAllowed && menuState !== LinkMenuState.HIDDEN"
    @close="editor.commands.closeLinkDialog"
  >
    <template #heading>edit link</template>
    <SoonaEditorLinkView
      v-if="menuState === LinkMenuState.VIEW_LINK_DETAILS"
      :editor="editor"
    />
    <SoonaEditorLinkEdit
      v-else-if="menuState === LinkMenuState.EDIT_LINK"
      :id="id"
      :editor="editor"
      @submit="onSave"
    />

    <template #footer="{ close }">
      <template v-if="menuState === LinkMenuState.VIEW_LINK_DETAILS">
        <SoonaButton variation="secondary-black" @on-click="onRemove">
          remove
        </SoonaButton>
        <SoonaButton
          variation="primary"
          @on-click="editor.commands.editLinkDialog"
        >
          edit
        </SoonaButton>
      </template>
      <template v-else-if="menuState === LinkMenuState.EDIT_LINK">
        <SoonaButton type="button" variation="tertiary" @on-click="close">
          cancel
        </SoonaButton>
        <SoonaButton type="submit" :form="id" variation="primary">
          save
        </SoonaButton>
      </template>
    </template>
  </SoonaDialog>
</template>

<template>
  <CompleteProfileCard
    class="referral-source"
    :disabled="disabled"
    @next="saveReferralInfo"
    @skip="skipCard"
  >
    <template #heading>what brings you to soona?</template>
    <div class="referral-text-container">
      <label class="u-label--heavy" for="i-am-a">I am a</label>
      <SoonaSelect
        id="i-am-a"
        v-model="iAmA"
        class="referral-select"
        :options="descriptionOptions"
      />
    </div>
    <div class="referral-text-container">
      <label class="u-label--heavy" for="looking-to">looking to</label>
      <SoonaSelect
        id="looking-to"
        v-model="lookingTo"
        class="referral-select"
        :options="purposeOptions"
      />
    </div>
  </CompleteProfileCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';

export default {
  name: 'WhatBringsYouToSoona',
  components: { CompleteProfileCard, SoonaSelect },
  data() {
    return {
      iAmA: '',
      lookingTo: '',
      descriptionOptions: [
        { label: 'brand', value: 'brand' },
        { label: 'marketing agency', value: 'marketing agency' },
        { label: 'business owner', value: 'business owner' },
      ],
      purposeOptions: [
        { label: 'refresh my website', value: 'refresh my website' },
        {
          label: 'launch into a new channel',
          value: 'launch into a new channel',
        },
        {
          label: 'build a new content library',
          value: 'build a new content library',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    disabled() {
      return this.iAmA === '' || this.lookingTo === '';
    },
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    async saveReferralInfo() {
      let info = {
        what_brings_you_to_soona: {
          data: {
            i_am_a: this.iAmA,
            looking_to: this.lookingTo,
          },
          complete: true,
        },
      };
      try {
        await this.updateAccount({ accountParams: info });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(
            new Error('Updating account referral source failed'),
            {
              extra: { error },
            }
          );
        }
      }
    },
    async skipCard() {
      let info = {
        what_brings_you_to_soona: {
          skip: true,
        },
      };
      await this.updateAccount({ accountParams: info });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.referral-source {
  .referral-text-container {
    align-items: center;
    display: flex;
    gap: 0.25rem;
    padding: 0.5rem;
  }

  .referral-select {
    flex-grow: 1;
  }
}
</style>

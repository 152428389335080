<template>
  <div>
    <router-view />

    <SoonaLoading
      :is-loading="isLoading"
      :is-dark="false"
      :loading-text="'please wait'"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import SoonaLoading from 'src/components/SoonaLoading.vue';

export default {
  name: 'Shipping',
  components: { SoonaLoading },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.name === 'shipping-options') {
        vm.newShipment();
        next();
      } else if (to.name != vm.mode) {
        next({ name: 'shipping-options' });
      } else {
        next();
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'shipping-options') {
      this.newShipment();
      next();
    } else if (to.name != this.mode) {
      next({ name: 'shipping-options' });
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters('reservation', ['isLoading']),
    ...mapState({
      mode: state => state.reservation.mode,
      currentReservation: state => state.reservation.currentReservation,
    }),
  },
  watch: {
    mode: function (val) {
      this.$router.push({ name: val });
    },
  },
  methods: {
    ...mapActions('reservation', ['newShipment']),
  },
};
</script>

<template>
  <div class="trait-option">
    <label class="select-label">
      <strong>
        {{ tagCategory.title
        }}<span v-if="tagCategory.skippable === false" class="required">*</span>
      </strong>
    </label>
    <SoonaSelect
      v-if="isMultilple"
      placeholder="select an option"
      :cypress-name="`psp-multiple-select-${tagCategory.title}`"
      :multi="true"
      :model-values="selection"
      :options="multiSelectOptions"
      :disabled="selectorDisabled"
      @update:model-values="val => handleMultiSelect(val)"
    />
    <SoonaSelect
      v-else
      placeholder="select an option"
      :cypress-name="`psp-single-select-${tagCategory.title}`"
      :multi="false"
      :model-value="selection"
      :options="multiSelectOptions"
      :clearable="true"
      :disabled="selectorDisabled"
      @update:model-value="val => handleMultiSelect(val)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import { useCapabilities } from 'src/composables/useCapabilities';

export default {
  name: 'ProServiceTraitSelector',
  components: {
    SoonaSelect,
  },
  props: {
    tagCategory: Object,
    modelValue: Array,
  },
  emits: ['update:modelValue'],
  setup() {
    const capabilitiesRef = ref([{ capability: 'ft_soona_staff' }]);
    const capabilities = useCapabilities(capabilitiesRef);
    return { capabilities };
  },
  data() {
    return {
      selection: undefined,
      selectedTagsProxy: [],
    };
  },
  computed: {
    ...mapState({
      tagCategories: state => state.proService.currentTagCategories,
    }),
    isMultilple() {
      return this.tagCategory.select_type == 'multiple';
    },
    categoriesToShow() {
      return this.tagCategories.filter(tc =>
        this.selectedTagsProxy.some(t =>
          t.tag_follow_up_categories.some(tfuc => tfuc.tag_category_id == tc.id)
        )
      );
    },
    selectorDisabled() {
      return (
        this.tagCategory.customizable === false &&
        !this.capabilities.ft_soona_staff.hasCapability
      );
    },
    multiSelectOptions() {
      return this.tagCategory.tags
        .filter(t => t.title !== 'no preference')
        .map(({ id, title }) => ({
          value: id,
          label: title,
        }));
    },
    filteredProServiceTags() {
      return this.tagCategory.tags.filter(t => t.title !== 'no preference');
    },
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        //update the local copy if a different selector changes the selected tags
        this.selectedTagsProxy = this.modelValue.map(a => {
          return { ...a };
        });

        // reset selection when age group has changed
        let oldAgeGroup = oldValue.find(
          x => x.tag_category_title === 'age group'
        );
        let newAgeGroup = newValue.find(
          x => x.tag_category_title === 'age group'
        );
        if (
          !!oldAgeGroup &&
          !!newAgeGroup &&
          oldAgeGroup.title !== newAgeGroup.title
        ) {
          if (
            this.tagCategory.title !== 'age group' &&
            this.tagCategory.title !== 'pro services'
          ) {
            this.selection = this.isMultilple ? [] : undefined;
          }
        }
      },
      deep: true,
    },
    selection(newValue, oldValue) {
      this.updateSelectedTags(oldValue, newValue);
    },
  },
  mounted() {
    //create a local copy on mount to avoid mutating a prop
    this.selectedTagsProxy = this.modelValue.map(a => {
      return { ...a };
    });

    this.preloadSelection();
  },
  methods: {
    isSelected(tag) {
      let isChecked = this.selectedTagsProxy.find(item => item.id == tag.id);
      return typeof isChecked != 'undefined';
    },
    handleMultiSelect(value) {
      this.selection = value;
    },
    preloadSelection() {
      if (this.isMultilple) {
        this.setInitialSelectionMultiple();
      } else {
        this.setInitialSelectionSingle();
      }
    },
    setInitialSelectionMultiple() {
      this.selection = this.selectedTagsProxy
        .filter(t => t.tag_category_id == this.tagCategory.id)
        .map(t => t.id);
    },

    setInitialSelectionSingle() {
      this.selection =
        this.selectedTagsProxy
          .filter(t => t.tag_category_id == this.tagCategory.id)
          .map(t => t.id)[0] || null;
    },
    updateSelectedTags(oldValue, newValue) {
      this.isMultilple
        ? this.updateSelectedTagsMulti(oldValue, newValue)
        : this.updateSelectedTagsSingle();
    },
    updateSelectedTagsSingle() {
      //remove any tags that are in this category
      this.selectedTagsProxy = this.selectedTagsProxy.filter(
        t => t.tag_category_id != this.tagCategory.id
      );

      //add the currently selected tag
      if (this.selection) {
        this.selectedTagsProxy.push(
          this.tagCategory.tags.find(t => t.id == this.selection)
        );
      }

      this.removeInvalidTags();
      //this is how we trigger the two way binding v-model behavior in Vue see: https://www.digitalocean.com/community/tutorials/vuejs-add-v-model-support
      this.$emit('update:modelValue', this.selectedTagsProxy);
    },
    updateSelectedTagsMulti(oldValue, newValue) {
      let removedTag =
        oldValue && newValue
          ? oldValue.filter(x => !newValue.includes(x))[0]
          : false;
      let addedTag =
        newValue && oldValue
          ? newValue.filter(x => !oldValue.includes(x))[0]
          : false;

      if (addedTag) {
        this.removeInvalidTags();
        let tag = this.tagCategory.tags.find(t => t.id == addedTag);
        this.$emit('update:modelValue', [...this.selectedTagsProxy, tag]);
      }

      if (removedTag) {
        let indexNumber = this.selectedTagsProxy.findIndex(
          tag => tag.id == removedTag
        );

        this.removeInvalidTags();
        if (indexNumber > 0) {
          this.selectedTagsProxy.splice(indexNumber, 1);
        }
        this.$emit('update:modelValue', this.selectedTagsProxy);
      }
    },
    removeInvalidTags() {
      //if the tags change and a tag category is no longer visible remove any tags in that category from the selected tag list
      this.selectedTagsProxy = this.selectedTagsProxy.filter(
        t =>
          this.categoriesToShow.some(tc => tc.id == t.tag_category_id) ||
          t.default
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.trait-option {
  width: 250px;
  margin: 10px 30px 10px 0;
  p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .required {
    color: variables.$friendly-red-50;
  }
  .select-label {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0.4375rem;
  }
}
</style>
<style lang="scss">
.trait-checkbox {
  margin-right: 5px;
  font-size: 14px;
  [type='checkbox'] + span {
    height: 16px;
    width: 16px;
    font-size: 12px !important;
  }
  [type='checkbox']:checked + span:before {
    font-size: 13px;
    font-weight: normal;
    bottom: 4px;
    left: 1px;
  }
}

.soona-select {
  .soona-select-input__multi {
    .vs__search {
      padding: 0;
    }

    .vs__selected-options {
      padding: 8px;
    }

    .vs__selected {
      font-size: 14px;
      font-weight: normal;

      .vs__deselect {
        border-radius: 50%;
        height: 1rem;
        width: 1rem;
        padding: 0;
      }
    }
  }
}
</style>

import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';
import { getDigitalAssetsBulkPublishManager } from '@/api/digitalAssetsBulkPublishManager';
import { unref } from 'vue';

export function useDigitalAssetsBulkPublishManager(
  accountId,
  listingActionSlug
) {
  return useQuery({
    queryKey: queryKeys.digitalAssetsBulkPublishManager(
      accountId,
      listingActionSlug
    ),
    queryFn: ({ signal }) =>
      getDigitalAssetsBulkPublishManager(
        unref(accountId),
        listingActionSlug,
        signal
      ),
  });
}

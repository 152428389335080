<script setup>
import { computed, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import ProductCardLogo from '@/components/user/anytime/products/ProductCardLogo.vue';
import ProductFlags from '@/components/user/anytime/products/product-page/ProductFlags.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCapability } from '@/composables/useCapability';
import { useCardContent } from './useCardContent';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  crewView: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isSelected: {
    type: Boolean,
    required: true,
  },
  parentProductName: {
    type: String,
    default: null,
  },
  product: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['handleSelect']);

const route = useRoute();

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const isSelected = computed({
  get() {
    return props.isSelected;
  },
  set() {
    emit('handleSelect');
  },
});

// card content module
const { parentProductName, product, disabled } = toRefs(props);

const {
  asin,
  bookingsCountMessage,
  imageSrc,
  inventoryAtSoona,
  inventoryCountMessage,
  inventoryPackage,
  location,
  productName,
  sku,
  totalLocationsWithInventory,
  variantName,
} = useCardContent(product, parentProductName);

const displayInventoryDetails = computed(() => {
  return inventoryAtSoona.value > 0 && props.crewView && isSoonaStaff.value;
});
</script>

<template>
  <div
    class="product-card"
    :class="[
      { 'product-card__selected': isSelected },
      { 'product-card__disabled': disabled },
    ]"
  >
    <div
      class="product-card__select"
      :class="{ 'product-card__select--selected': isSelected }"
    >
      <SoonaToggle
        v-model="isSelected"
        type="checkbox"
        label="select product"
        :disabled="disabled"
        :hide-visual-label="true"
      />
    </div>
    <div class="product-card__clickable-area">
      <div class="product-card__product-image-cell">
        <img :src="imageSrc" alt="" />
      </div>
      <ProductCardLogo :product="product" />
      <div class="product-card__product-info-wrapper">
        <div class="product-card__product-name-cell">
          <ProductFlags :product="product" />
          <router-link
            :to="{
              path: `/account/${accountId}/products/${product.id}`,
              query: route.query,
            }"
            class="product-card__product-name u-body--heavy"
          >
            {{ parentProductName ?? productName }}
          </router-link>
          <div v-if="parentProductName" class="product-card__variant-name">
            <SoonaIcon
              name="reply"
              size="small"
              class="product-card__variant-name--icon"
            />
            <span class="product-card__variant-name--text u-label--heavy">
              {{ variantName }}
            </span>
          </div>
          <div
            v-if="product.variant_count > 0"
            class="product-card__variant-count"
          >
            <SoonaIcon name="diagram-subtask" size="small" />
            <span class="product-card__variant-count-text u-badge--small">
              {{ product.variant_count }}
              variant{{ product.variant_count > 1 ? 's' : '' }}
            </span>
          </div>
        </div>

        <div class="product-card__product-sku-cell">
          <div v-if="sku" class="product-card__product-sku-cell-inner">
            <div class="product-card__product-sku-label">SKU</div>
            <div class="product-card__product-sku">{{ sku }}</div>
          </div>
        </div>
        <div class="product-card__product-asin-cell">
          <div v-if="asin" class="product-card__product-asin-cell-inner">
            <div class="product-card__product-asin-label">ASIN</div>
            <div class="product-card__product-asin">{{ asin }}</div>
          </div>
        </div>

        <div
          v-if="displayInventoryDetails"
          class="product-card__inventory-package-cell"
        >
          <div class="product-card__inventory-package-cell-inner">
            <div class="product-card__inventory-package-label">package ID</div>
            <div class="product-card__inventory-package">
              {{ inventoryPackage.id }}
              <span v-if="inventoryAtSoona > 1">
                (+{{ inventoryAtSoona - 1 }})</span
              >
            </div>
          </div>
        </div>

        <div
          v-else
          class="product-card__product-inventory-cell"
          :class="{
            'product-card__product-inventory-cell--empty':
              inventoryAtSoona === 0,
          }"
        >
          <div class="product-card__inventory-location-cell-inner">
            <SoonaIcon
              class="product-card__product-inventory-icon"
              name="box-archive"
            />
            <div class="product-card__inventory-location--count">
              <p
                class="product-card__inventory-location product-card__inventory-location--studio"
              >
                {{ inventoryCountMessage }} {{ location }}
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="displayInventoryDetails"
          class="product-card__inventory-package-cell"
        >
          <div class="product-card__inventory-package-cell-inner">
            <div class="product-card__inventory-package-label">
              storage location
            </div>
            <div class="product-card__inventory-package">
              {{ inventoryPackage.storage_location }} -
              {{ inventoryPackage.location_short_name }}
              <span v-if="totalLocationsWithInventory > 1">
                (+{{ totalLocationsWithInventory - 1 }})</span
              >
            </div>
          </div>
        </div>

        <div
          v-else
          class="product-card__bookings-wrapper"
          :class="{
            'product-card__bookings-wrapper--empty':
              product.reservation_count === 0,
          }"
        >
          <SoonaIcon
            class="product-card__bookings-wrapper-icon"
            name="calendar-user"
          />
          <p class="product-card__product-meta">
            {{ bookingsCountMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

// mobile first stylings
.product-card {
  position: relative;
  isolation: isolate;
  border-radius: 0.625rem;
  background-color: variables.$white-default;
  border-color: transparent;
  box-shadow: 0 0 0 0.0625rem variables.$gray-30;
  margin-bottom: 0.75rem;

  &:hover {
    background-color: variables.$gray-10;
  }

  &__selected {
    border-color: transparent;
    box-shadow: 0 0 0 0.125rem variables.$periwink-blue-60;
    background-color: variables.$periwink-blue-10;

    &:hover {
      background-color: variables.$periwink-blue-20 !important;
    }
  }

  &__disabled {
    border-color: transparent;
    cursor: not-allowed;
  }

  &__select {
    position: absolute;
    top: 0.25rem;
    right: 0.75rem;
    z-index: 2;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--selected {
      background-color: variables.$white-default;
    }

    :deep(.soona-toggle-wrapper) {
      display: block;
    }
  }

  &__clickable-area {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__product-image-cell {
    position: relative;
    width: 100%;
    height: 10.0625rem;

    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.3125rem 0.3125rem 0 0;
    }
  }

  &__product-info-wrapper {
    overflow: hidden;
    padding: 0.75rem;
  }

  &__product-name-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }

  &__product-name {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }

  &__variant-name {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &--icon {
      rotate: 180deg;
    }
  }

  &__variant-count {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__nested-click {
    position: relative;
    z-index: 2;
  }

  &__product-sku,
  &__product-asin,
  &__inventory-location,
  &__inventory-package {
    @include variables_fonts.u-label--regular;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &-cell {
      display: none;
    }

    &-label {
      @include variables_fonts.u-small--heavy;
    }
  }

  &__product-inventory {
    &-cell {
      display: none;
      color: variables.$black-default;

      &--empty {
        color: variables.$gray-60;
      }
    }

    &-icon {
      height: 1rem;
    }

    &-quantity {
      @include variables_fonts.u-small--heavy;
    }

    &-shipping {
      @include variables_fonts.u-small--regular;
    }
  }

  &__bookings-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin-top: 1.25rem;
    color: variables.$black-default;

    &--empty {
      color: variables.$gray-60;
    }

    &-icon {
      height: 1rem;
    }
  }

  &__product-meta {
    @include variables_fonts.u-label--regular;
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    display: grid;
    grid-template-columns:
      2rem
      1fr;
    align-items: center;

    &__select {
      position: initial;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: auto;
      background-color: transparent;

      &--selected {
        background-color: transparent;
      }
    }

    &__clickable-area {
      display: grid;
      grid-template-columns:
        4.5rem
        1fr;
      column-gap: 0.75rem;
      align-items: center;
    }

    &__product-image-cell {
      position: relative;
      width: 4.5rem;
      height: 100%;
      min-height: 4.5rem;

      img {
        border-radius: 0;
      }
    }

    &__product-info-wrapper {
      display: grid;
      grid-template-columns:
        minmax(12.5rem, 3fr)
        minmax(9.5rem, 1.75fr)
        minmax(9.5rem, 1.25fr);
      column-gap: 0.625rem;
      align-items: center;
    }

    &__product-inventory,
    &__inventory-package {
      &-cell {
        display: flex;
        gap: 0.25rem;
        align-items: center;
        margin-top: 0;
      }
    }

    &__bookings-wrapper {
      gap: 0.25rem;
      align-items: center;
      width: 100%;
      margin-top: 0;
    }

    &__inventory-location {
      &-cell-inner {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
      }
      &--studio {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  @media (min-width: 960px) {
    &__product-info-wrapper {
      grid-template-columns:
        minmax(12.5rem, 3fr)
        minmax(6.25rem, 1fr)
        minmax(6.25rem, 1fr)
        minmax(9.5rem, 1.5fr)
        minmax(9.5rem, 1.25fr);
    }

    &__product-sku,
    &__product-asin,
    &__inventory-location,
    &__inventory-package {
      &-cell {
        display: block;
        margin-top: 0;
      }

      &-cell-inner {
        display: block;
      }
    }

    &__inventory-location {
      &-cell-inner {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
      }

      &--meta {
        flex: 1 1 auto;
      }

      &--count {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
      }

      &--studio {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaItemCardSelectable from 'src/components/ui_library/SoonaItemCardSelectable.vue';
import { useProductExternalIdentifiers } from '@/components/user/anytime/products/useProductExternalIdentifiers';
import { useCardContent } from '@/components/user/anytime/products/useCardContent';
import ProductFlags from '@/components/user/anytime/products/product-page/ProductFlags.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  checked: {
    type: Boolean,
    required: true,
  },
  disabled: {
    Type: Boolean,
    default: false,
  },
  product: {
    type: Object,
    required: true,
  },
});

const product = computed(() => props.product);
const id = computed(() => `picker-product-option-${product.value.id}`);
const { sku, asin } = useProductExternalIdentifiers(product);
const inventoryCount = computed(() => product.value.inventory_at_soona_count);

const { mostRecentInventoryLocation } = useCardContent(product);

const inventoryCountMessage = computed(() => {
  let message = `${inventoryCount.value.toLocaleString()} at soona ${mostRecentInventoryLocation.value}`;

  if (inventoryCount.value > 1) {
    return message + ` (+${inventoryCount.value - 1})`;
  } else {
    return message;
  }
});
</script>

<template>
  <SoonaItemCardSelectable
    :id="id"
    :aria-labelledby="`${id}-heading`"
    :checked="checked"
    :image-url="product.preview_url"
    :value="product.id"
    :disabled="disabled"
    name="products"
  >
    <template #default>
      <div class="picker-product-option__wrapper">
        <div class="picker-product-option__content">
          <ProductFlags :product="product" />

          <h2 :id="`${id}-heading`">
            {{ product.name }}
          </h2>

          <dl>
            <div v-if="sku">
              <dt>SKU</dt>
              <dd>{{ sku }}</dd>
            </div>
            <div v-if="asin">
              <dt>ASIN</dt>
              <dd>{{ asin }}</dd>
            </div>
            <div
              v-if="inventoryCount > 0"
              class="picker-product-option__inventory_count_wrapper"
            >
              <SoonaIcon
                class="picker-product-option__inventory_icon"
                name="box-archive"
              />
              {{ inventoryCountMessage }}
            </div>
          </dl>
        </div>
      </div>
    </template>
  </SoonaItemCardSelectable>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.picker-product-option {
  &__wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 2.75rem;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      @include variables_fonts.u-body--heavy;
    }

    dl {
      line-height: 1;
    }

    dt {
      @include variables_fonts.u-small--heavy;
      display: inline-block;
      margin-right: 0.25rem;
    }

    dd {
      @include variables_fonts.u-small--regular;
      display: inline-block;
    }
  }

  &__badge {
    margin-top: 0.25rem;
  }

  &__actions {
    .choose-button {
      width: auto;
    }
  }

  &__inventory_count_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.25rem;
    font-size: 0.875rem;
  }

  &__inventory_icon {
    width: 1rem;
    height: 1rem;
    color: variables.$black-default;
    margin-right: 0.25rem;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';
import { component as Viewer } from 'v-viewer';

const props = defineProps({
  selectedScene: {
    type: Object,
    required: true,
  },
});

const referenceTagImageUrls = computed(() => {
  const shotTags = props.selectedScene?.shot_tags ?? [];

  return shotTags
    .filter(x => x.tag.tag_category_title === 'Reference Image')
    .map(x => x.tag);
});

const backgroundTag = computed(() => {
  const shotTags = props.selectedScene?.shot_tags;
  if (!shotTags) return undefined;

  const findCatTitle = title => x => x.tag.tag_category_title === title;

  return (
    shotTags.find(findCatTitle('Backdrop Color'))?.tag ??
    shotTags.find(findCatTitle('Textures'))?.tag ??
    shotTags.find(findCatTitle('Environment'))?.tag
  );
});
</script>

<template>
  <div class="scene-details">
    <div
      v-if="referenceTagImageUrls.length > 0 || backgroundTag"
      class="scene-details__preview-images"
    >
      <template v-for="(tag, i) in referenceTagImageUrls" :key="tag.id">
        <Viewer
          :options="{
            toolbar: false,
            navbar: false,
            title: false,
            backdrop: true,
            minZoomRatio: 0.01,
            maxZoomRatio: 2,
          }"
        >
          <video
            v-if="tag.image_content_type?.startsWith('video/')"
            autoplay
            loop
            playsinline
            muted
            class="inspo-video"
          >
            <source :src="tag.image_url" :type="tag.image_content_type" />
          </video>
          <img
            v-else
            :src="tag.image_url"
            class="inspo-img"
            :alt="`reference image ${i}`"
            data-cypress="shot-list-reference-image"
          />
        </Viewer>
      </template>
      <span v-if="backgroundTag" class="background-tag">
        <SoonaItemCard
          :image-url="backgroundTag.image_url"
          :aria-labelledby="`background-tag-${backgroundTag.id}-title`"
        >
          <template #content>
            <div class="background-tag__content">
              <p
                :id="`background-tag-${backgroundTag.id}-title`"
                class="background-tag__title"
              >
                {{ backgroundTag.title }}
              </p>
            </div>
          </template>
        </SoonaItemCard>
      </span>
    </div>
    <div v-if="selectedScene.description" class="scene-details__description">
      <h5>description</h5>
      <p>
        {{ selectedScene.description }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

@supports (overflow-wrap: anywhere) {
  .scene-details__description p {
    overflow-wrap: anywhere;
    word-break: normal;
  }
}

.scene-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__preview-images {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;

    .inspo-img,
    .inspo-video {
      border-radius: 0.3125rem;
      width: 4.5rem;
      height: 4.5rem;
    }

    .inspo-img {
      object-fit: cover;
      cursor: pointer;
    }
  }

  .background-tag {
    max-width: 18.75rem; // matches ShotTag
    width: 100%;

    .item-card {
      flex-flow: initial;
    }

    &__content {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0.75rem 1rem;
    }

    &__title {
      @include variables_fonts.u-body--heavy;

      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: lowercase;
    }
  }

  &__description {
    h5 {
      @include variables_fonts.u-small--heavy;
    }

    p {
      max-width: 100%;
      word-break: break-word;
    }
  }
}
</style>

<template>
  <div>
    <section class="dashboard-section">
      <BookingGallery
        title="pending selects"
        :reservations="pendingSelectsReservations"
        link-to="gallery"
        :load-more="loadMorePendingSelectsReservations"
        :last-page="lastPendingSelectsPage"
        :detailed-title="true"
        :include-tags="true"
        class="booking-gallery-buffer"
        ><template #empty-message
          >pending-selects bookings will appear here!</template
        ></BookingGallery
      >
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BookingGallery from 'src/components/user/anytime/BookingGallery.vue';

export default {
  components: { BookingGallery },
  props: ['nameFilter', 'locationFilter', 'shareableFilter'],
  data() {
    return {
      lastPendingSelectsPage: true,
    };
  },
  computed: {
    ...mapState({
      pendingSelectsReservations: state =>
        state.dashboard.pendingSelectsReservations,
    }),
  },
  watch: {
    nameFilter: function () {
      this.updateReservations();
    },
    locationFilter: function () {
      this.updateReservations();
    },
    shareableFilter: function () {
      this.updateReservations();
    },
  },
  mounted: function () {
    this.updateReservations();
  },
  methods: {
    ...mapActions('dashboard', ['loadPendingSelectsReservations']),
    updateReservations() {
      let args = {
        location: this.locationFilter,
        name: this.nameFilter,
        shareable: this.shareableFilter,
        page: 1,
      };

      this.loadPendingSelectsReservations(args).then(response => {
        this.lastPendingSelectsPage = response;
      });
    },
    loadMorePendingSelectsReservations(page) {
      let args = {
        location: this.locationFilter,
        name: this.nameFilter,
        shareable: this.shareableFilter,
        page: page,
      };

      this.loadPendingSelectsReservations(args).then(response => {
        this.lastPendingSelectsPage = response;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-gallery-buffer {
  margin-top: 30px;
}
</style>

import { readonly, ref } from 'vue';
import { defineStore } from 'pinia';
import { PAYMENT_METHODS_STORE_ID } from '@/stores/keys';

export const usePaymentMethodsStore = defineStore(
  PAYMENT_METHODS_STORE_ID,
  () => {
    const disablePaymentButton = ref(false);

    function $reset() {
      disablePaymentButton.value = false;
    }

    return {
      disablePaymentButton: readonly(disablePaymentButton),
      setDisablePaymentButton: val => (disablePaymentButton.value = val),
      $reset,
    };
  }
);

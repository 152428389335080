import { useMutation } from '@tanstack/vue-query';
import { toValue } from 'vue';
import { getSharedGalleryUrl } from '@/api/shared_gallery';

/**
 * @returns UseMutationReturnType<*>
 */
export function useGetSharedGalleryUrl() {
  return useMutation({
    mutationFn: async reservationId => {
      return await getSharedGalleryUrl(toValue(reservationId));
    },
  });
}

<script setup>
import { computed, toRef } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  allSelected: {
    required: true,
    type: Boolean,
  },
});

defineEmits(['toggle-selection']);

const allSelected = toRef(props, 'allSelected');

const selectAllCopy = computed(() => {
  if (allSelected.value) {
    return 'deselect all';
  }
  return 'select all photos';
});
</script>

<template>
  <aside class="select-all">
    <div class="select-all__content">
      <h2 class="u-body--heavy">
        select multiple photos + apply premium edits
      </h2>
      <p class="u-label--regular">not yet available for video and gifs.</p>
    </div>
    <SoonaButton
      variation="secondary-gray"
      size="medium"
      class="select-all__button"
      @click="() => $emit('toggle-selection')"
    >
      {{ selectAllCopy }}
    </SoonaButton>
  </aside>
</template>

<style scoped lang="scss">
@use '@/variables';

.select-all {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  background-color: variables.$tangerine-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;

  &__button {
    margin-left: auto;
  }
}
</style>

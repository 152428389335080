<script setup>
import {
  requiresAuth,
  nextButtonText,
  backButtonText,
  getStepProgressPercent,
  nextStep,
  previousStep,
} from 'src/lib/booking/processNavigation';
import { useReservation } from '@/composables/useReservation';
import {
  prefetchReservation,
  isDraft,
  findIncompleteStep,
} from '@/lib/booking/validateStep';
import {
  computed,
  nextTick,
  ref,
  toRef,
  watchEffect,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { onBeforeRouteUpdate, useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import {
  useTitle,
  useMediaQuery,
  useTimeoutFn,
  onKeyStroke,
} from '@vueuse/core';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import BookingSidecart from 'src/components/booking/v3/p2/BookingSidecart.vue';
import BookingFooter from 'src/components/booking/v3/p2/BookingFooter.vue';
import { useAssignAccountToReservationAndOrder } from 'src/queries/useUpdateReservation';
import { usePriorityError } from 'src/composables/usePriorityError';
import { usePreloadTagImgs } from './usePreloadTagImgs';
import BookingSaveDraft from 'src/components/shared/BookingSaveDraft.vue';
import SavedDraftSuccess from 'src/components/shared/SavedDraftSuccess.vue';

const props = defineProps({
  stepName: String,
  reservationId: String,
});
const router = useRouter();
const route = useRoute();
const store = useStore();
const reservationId = computed(() => props.reservationId);
const {
  reservation,
  error: reservationError,
  isLoading: isReservationLoading,
  proServiceSlugForSize,
} = useReservation(reservationId);
const { pageViewed } = useBaseEvents();
const title = useTitle(null);

const transitionName = ref('prebooking-transition');
const ensureBackTransition = ref(false);
const ensureNextTransition = ref(false);

const isAuthenticated = computed(() => !!store.state.currentUser.userID);

const authRequired = computed(() =>
  requiresAuth({ stepName: props.stepName, reservation: reservation.value })
);

const { start: scrollUpDelay, stop: clearScrollUpDelay } = useTimeoutFn(
  () => {
    window.scrollTo({ top: 0 });
  },
  350,
  { immediate: false }
);

const progressPercent = computed(() => {
  return getStepProgressPercent({
    stepName: props.stepName,
    reservation: reservation.value,
  });
});

watchEffect(() => {
  if (
    props.stepName === 'newBook' &&
    reservationId.value &&
    reservation.value
  ) {
    const nextStep = reservation.value.steps.find(s => !s.complete)?.slug;
    router.push(`/booking/${reservationId.value}/${nextStep}`);
  }
});

usePreloadTagImgs(
  reservationId,
  computed(
    () =>
      nextStep({
        currentStepName: props.stepName,
        reservation: reservation.value,
      })?.slug
  ),
  proServiceSlugForSize
);

watchEffect(() => {
  if (route.path) {
    pageViewed();
  }
});

onMounted(() => {
  title.value = route.meta?.page_title
    ? `${route.meta.page_title} | soona`
    : null;

  if (
    reservation.value?.booking_flow === 'headshots_for_all_event' &&
    authRequired.value &&
    !isAuthenticated.value
  ) {
    const redirect = `/booking/${reservation.value?.id}/down-payment`;
    const nextTo = { name: 'signUpPartTwo', query: { redirect } };

    if (nextTo) {
      router.push(nextTo);
    }
  }
});

// assign reservation
const {
  mutate: assignAccountToReservation,
  isPending: isAssigningAccount,
  error: assignAccountError,
} = useAssignAccountToReservationAndOrder(toRef(props, 'reservationId'));
watchEffect(() => {
  if (
    isAuthenticated.value &&
    !!reservation.value &&
    !reservation.value?.account_id
  ) {
    assignAccountToReservation();
  }
});

onBeforeUnmount(() => {
  document.documentElement.style.overflow = '';
});

const nextButtonCopy = computed(() => {
  return nextButtonText({
    currentStepName: props.stepName,
    reservation: reservation.value,
  });
});

const backButtonCopy = computed(() => {
  return backButtonText({
    currentStepName: props.stepName,
    reservation: reservation.value,
  });
});

function onBack(routePath) {
  ensureBackTransition.value = true;
  if (routePath) {
    // Override route path logic
    router.push({
      path: `/booking/${reservationId.value}/${routePath}`,
      query: route.query,
    });
    return;
  }

  const previous = previousStep({
    currentStepName: props.stepName,
    reservation: reservation.value,
  });

  if (previous?.slug)
    router.push({
      path: previous.slug,
      query: route.query,
    });
}

function onNext(routePath) {
  ensureNextTransition.value = true;

  if (routePath) {
    // Override route path logic
    router.push({
      path: routePath,
      query: route.query,
    });
    return;
  }

  const next = nextStep({
    currentStepName: props.stepName,
    reservation: reservation.value,
  });

  if (next?.slug)
    router.push({
      path: `/booking/${reservationId.value}/${next.slug}`,
      query: route.query,
    });
}

/** SIDECART JAZZ */
const sidecartOpen = ref(false);
const sidecartClosing = ref(false);
const { start: closeSidecart, stop: stopCloseSidecart } = useTimeoutFn(
  () => {
    sidecartOpen.value = !sidecartOpen.value;
    sidecartClosing.value = false;
  },
  300,
  { immediate: false }
);

const toggleSidecart = () => {
  if (!sidecartOpen.value || sidecartClosing.value) {
    sidecartClosing.value = false;
    stopCloseSidecart();
    sidecartOpen.value = !sidecartOpen.value;

    return;
  }

  sidecartClosing.value = true;
  closeSidecart();
};

const isWideScreen = useMediaQuery('(min-width: 60rem)');
watchEffect(() => {
  if (sidecartOpen.value && !isWideScreen.value) {
    document.documentElement.style.overflow = 'hidden';
  } else {
    document.documentElement.style.overflow = '';
  }
});

onKeyStroke('Escape', () => {
  if (sidecartOpen.value && !sidecartClosing.value) {
    toggleSidecart();
  }
});

onBeforeRouteUpdate(async (to, from, next) => {
  const toReservation = await prefetchReservation(to.params.reservationId);
  if (toReservation && !isDraft(toReservation)) {
    window.location.href = `/user/anytime#/reservation/${to.params.reservationId}/info`;
    return false;
  }

  const nextSlug = to.meta.validate_step;

  if (ensureNextTransition.value) {
    transitionName.value = 'prebooking-transition';
    scrollUpDelay();
  } else if (ensureBackTransition.value) {
    transitionName.value = 'prebooking-back-transition';
    scrollUpDelay();
  } else {
    transitionName.value = null;
    clearScrollUpDelay();
    window.scrollTo({ top: 0 });
  }

  ensureBackTransition.value = false;
  ensureNextTransition.value = false;

  title.value = to.meta?.page_title ? `${to.meta.page_title} | soona` : null;
  // Close sidecart if open
  if (sidecartOpen.value && !sidecartClosing.value) {
    toggleSidecart();
  }

  let nextTo = null;

  if (reservation.value) {
    const nextStep = reservation.value.steps.find(s => s.slug === nextSlug);
    if (nextStep?.requires_authentication && !isAuthenticated.value) {
      transitionName.value = null;
      clearScrollUpDelay();
      window.scrollTo({ top: 0 });

      const redirect = `/booking/${from.params.reservationId}/${nextSlug}`;
      nextTo = { name: 'signUpPartTwo', query: { redirect } };
    }
  }

  if (!nextTo) {
    const incompleteStep = findIncompleteStep({
      desiredSlug: to.meta.validate_step,
      reservation: toReservation,
    });

    if (incompleteStep) {
      const path = `/booking/${from.params.reservationId}/${incompleteStep}`;
      nextTo = { path: path };
    }
  }

  // next tick makes sure we wait until after transition name change takes effect
  await nextTick();

  // only pass a different location config into next if you're going to change the default behavior
  if (nextTo) {
    next(nextTo);
  } else {
    next();
  }
});

const sidecartFooterProps = ref({
  noSidecart: false,
  hideFooter: false,
  showNext: true,
  isLoading: false,
  isStepValid: true,
  onNextClick: () => {},
  onBackClick: () => {},
  nextButtonCopy: '',
  backButtonCopy: '',
});

const setSidecartFooterProps = props => {
  sidecartFooterProps.value = {
    noSidecart: props.noSidecart,
    hideFooter: props.hideFooter,
    showNext: props.showNext,
    isLoading: props.isLoading,
    isStepValid: props.isStepValid,
    onNextClick: props.onNextClick,
    onBackClick: props.onBackClick,
    nextButtonCopy: props.nextButtonCopy,
    backButtonCopy: props.backButtonCopy,
  };
};

// common
const priorityError = usePriorityError(assignAccountError, reservationError);
const isUnauthorized = computed(() => {
  return (
    assignAccountError.value?.request?.status === 403 ||
    reservationError.value?.request?.status === 403
  );
});
const showView = computed(() => {
  return (
    reservation.value?.id &&
    ((authRequired.value && isAuthenticated.value) || !authRequired.value) &&
    ((isAuthenticated.value && reservation.value?.account_id) ||
      (!isAuthenticated.value && !reservation.value?.account_id))
  );
});
const isBusy = computed(() => {
  return isAssigningAccount.value || isReservationLoading.value;
});

const showSavedDraftSuccess = ref(false);
</script>

<template>
  <div class="booking-process-wrapper">
    <SoonaLoading
      v-if="isBusy"
      :is-loading="isBusy"
      :is-dark="false"
      loading-text="loading"
    />
    <div v-if="priorityError" class="u-container">
      <SoonaError>
        <template v-if="isUnauthorized">
          Unauthorized to view this booking.<br />
          Need to
          <router-link :to="`../sign-in?redirect=${$route.fullPath}`"
            >sign in</router-link
          ><template v-if="isAuthenticated"> as a different user</template>?
        </template>
        <template v-else> There was an error loading your booking. </template>
      </SoonaError>
    </div>
    <router-view
      v-if="showView"
      v-slot="{ Component }"
      v-bind="$attrs"
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next="onNext"
      :on-back="onBack"
      :reservation-id="reservationId"
      :step-name="stepName"
      :transition-name="transitionName"
      @set-sidecart-footer-props="setSidecartFooterProps"
      @set-next-transition="() => (ensureNextTransition = true)"
    >
      <SavedDraftSuccess
        v-if="showSavedDraftSuccess"
        class="saved-draft-alert"
        :handle-modal-close="() => (showSavedDraftSuccess = false)"
        :reservation="reservation"
      />
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
    <Transition name="sidecart-slide-in">
      <BookingSidecart
        v-if="reservation && !sidecartFooterProps.noSidecart && !isBusy"
        class="process__sidecart"
        :class="{
          'process__sidecart--open': sidecartOpen,
          'process__sidecart--closing': sidecartClosing,
        }"
        :toggle-sidecart="toggleSidecart"
        :reservation-id="reservationId"
        :step-name="stepName"
        :customer-booking-flow="true"
        @click.self="toggleSidecart"
      >
        <template #actions>
          <BookingSaveDraft
            :reservation="reservation"
            :is-authenticated="isAuthenticated"
            @toggle-alert="showSavedDraftSuccess = !showSavedDraftSuccess"
          />
        </template>
      </BookingSidecart>
    </Transition>
    <Transition name="fade-in">
      <BookingFooter
        v-if="reservation && !sidecartFooterProps.hideFooter"
        :next-clicked="sidecartFooterProps.onNextClick"
        :back-clicked="sidecartFooterProps.onBackClick"
        :is-loading="sidecartFooterProps.isLoading"
        :is-step-valid="sidecartFooterProps.isStepValid"
        :show-next="sidecartFooterProps.showNext"
        :button-text="sidecartFooterProps.nextButtonCopy"
        :back-button-copy="sidecartFooterProps.backButtonCopy"
        cypress-name-next="booking-next-step"
        cypress-name-back="booking-prev-step"
        :z-index="3"
        :no-sidecart="sidecartFooterProps.noSidecart"
        :toggle-sidecart="toggleSidecart"
        :progress-percent="progressPercent"
        :reservation-id="reservationId"
      />
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

@keyframes fade-in-sidecart-bg {
  0% {
    background-color: rgba(63, 67, 75, 0);
  }
  to {
    background-color: rgba(63, 67, 75, 0.75);
  }
}

@keyframes fade-out-sidecart-bg {
  to {
    background-color: rgba(63, 67, 75, 0);
  }
}

.saved-draft-alert {
  z-index: 5;
  position: absolute;
  top: var(--app-header-viewport-offset);
  left: 0;
  right: 0;
}

.booking-process-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.process {
  &__sidecart {
    position: fixed;
    z-index: 4;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(63, 67, 75, 0.75);
    animation: 0.2s ease-out both fade-in-sidecart-bg;

    &--open {
      display: flex;
      overflow: hidden;
    }

    &--closing {
      animation: 0.3s ease-out both fade-out-sidecart-bg;
    }
  }

  @media (min-width: 48rem) {
    &__sidecart {
      top: var(--app-header-viewport-offset);
    }
  }

  @media (min-width: 60rem) {
    &__sidecart {
      background-color: transparent;
      max-width: 22.125rem;
      border-left: 1px solid variables.$gray-30;
      transition: transform 0.3s ease-out;
    }
  }

  @media (min-width: 75rem) {
    &__sidecart {
      max-width: 27.625rem;
    }
  }
}

// Footer and sidecart transitions
.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}

@media (min-width: 60rem) {
  .sidecart-slide-in-enter-from,
  .sidecart-slide-in-leave-to {
    transform: translateX(100%);
  }
}

@media (prefers-reduced-motion: reduce) {
  .fade-in-enter-from,
  .fade-in-leave-to {
    opacity: 1 !important;
  }

  .sidecart-slide-in-enter-from,
  .sidecart-slide-in-leave-to {
    transform: none !important;
  }
}
</style>

<style lang="scss">
@use '@/variables';

.prebooking-transition__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media (min-width: 48rem) {
    justify-content: center;
  }
}

.prebooking-transition,
.prebooking-back-transition {
  &-leave-active {
    transition-duration: 0.3s;
  }

  &-enter-active {
    transition-duration: 0.7s;
    animation: prebooking-parent-enter 0.32s;
  }
}

.prebooking-transition {
  &-leave-active {
    .prebooking-transition__content {
      animation: prebooking-leave 0.3s both ease-out;
    }
  }

  &-enter-active {
    .prebooking-transition__content {
      animation: prebooking-enter 0.7s both ease-out;
    }
  }
}

.prebooking-back-transition {
  &-leave-active {
    .prebooking-transition__content {
      animation: prebooking-leave-back 0.3s both ease-out;
    }
  }

  &-enter-active {
    .prebooking-transition__content {
      animation: prebooking-enter-back 0.7s both ease-out;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .prebooking-transition,
  .prebooking-back-transition {
    &-leave-active,
    &-enter-active {
      transition: none !important;
      transition-duration: 0s !important;
      animation: none !important;
    }
  }
}

@keyframes prebooking-parent-enter {
  0%,
  99.99% {
    opacity: 0;
    position: absolute;
  }

  100% {
    opacity: 1;
    position: static;
  }
}

@keyframes prebooking-leave {
  to {
    opacity: 0;
    transform: translateX(-3em);
  }
}

@keyframes prebooking-leave-back {
  to {
    opacity: 0;
    transform: translateX(3em);
  }
}

@keyframes prebooking-enter {
  0%,
  50% {
    opacity: 0;
    transform: translateX(3em);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes prebooking-enter-back {
  0%,
  50% {
    opacity: 0;
    transform: translateX(-3em);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>

import { useQuery } from '@tanstack/vue-query';
import { getAccountStorageUsage } from '@/api/accounts';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

/**
 *
 * @param {string | Ref<string>} accountId
 * @param {Object} [queryOptions]
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useStorageUsage(accountId, queryOptions = null) {
  return useQuery({
    queryKey: queryKeys.accountStorageUsage(accountId),
    queryFn: () => getAccountStorageUsage(unref(accountId)),
    ...queryOptions,
  });
}

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useProductIntegrationDetails } from './useProductIntegrationDetails';

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const product = computed(() => props.product);

const { isAmazonProduct, isShopifyProduct } =
  useProductIntegrationDetails(product);
</script>

<template>
  <div class="product-card-logo">
    <SoonaIcon
      v-if="isAmazonProduct"
      name="amazon-logo"
      class="product-card-logo__icon"
      size="large"
    />
    <SoonaIcon
      v-if="isShopifyProduct"
      name="shopify-logo"
      class="product-card-logo__icon"
      size="large"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.product-card-logo {
  position: absolute;
  display: flex;
  gap: 0.25rem;
  top: 0.25rem;
  left: 0.25rem;

  &__icon {
    background-color: variables.$white-default;
    border-radius: 50%;
    padding: 0.125rem;
  }
}
</style>

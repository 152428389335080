<script setup>
import { useInfiniteEditsCollectionDigitalAssets } from '@/queries/edits_collection_digital_assets/useInfiniteEditsCollectionDigitalAssets';
import { computed, ref } from 'vue';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import SmallInfiniteGallery from '@/components/infinite_asset_gallery/SmallInfiniteGallery.vue';
import UnlinkedPendingEditsCollectionDigitalAssetCard from '@/components/user/anytime/reservation/gallery/edits/UnlinkedPendingEditsCollectionDigitalAssetCard.vue';
import AssignOriginalDialog from '@/components/user/anytime/reservation/gallery/edits/AssignOriginalDialog.vue';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import { useUpdateEditsCollectionDigitalAsset } from '@/queries/edits_collection_digital_assets/useUpdateEditsCollectionDigitalAsset';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  collectionId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
});

const accountId = computed(() => props.accountId);
const collectionId = computed(() => props.collectionId);
const reservationId = computed(() => props.reservationId);

const wrapper = ref();
const itemsPerPage = ref(30);

const { gutter, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 12.5,
  gapRem: 0.75,
});

const filters = computed(() => ({
  reservation_id: reservationId.value,
  edit_status: 'pending',
  missing_original: 1,
}));

const {
  assetRows,
  fetchPage,
  isLoading: isLoadingAssets,
} = useInfiniteDigitalAssets(collectionId, {
  rowWidth,
  itemsPerPage,
  gutter,
  height: rowHeight,
  filters,
  query: useInfiniteEditsCollectionDigitalAssets,
});

// assign original
const showOriginalDialog = ref(false);
const assignedEdit = ref(null);

const toggleShowOriginalDialog = (val, ecda) => {
  showOriginalDialog.value = val;

  assignedEdit.value = showOriginalDialog.value ? ecda?.digital_asset : null;
};

// end assign original

function generateRouteLocation(editsCollectionDigitalAsset) {
  if (editsCollectionDigitalAsset.placeholder) return {};

  return {
    name: 'reservation-asset-edit-view',
    params: {
      reservationId: reservationId.value,
      digitalAssetId: editsCollectionDigitalAsset.digital_asset.id,
    },
  };
}

const { mutate, isPending } = useUpdateEditsCollectionDigitalAsset(
  accountId,
  collectionId
);

const accept = editsCollectionDigitalAsset => {
  mutate({
    cdaId: editsCollectionDigitalAsset.id,
    body: {
      edit_status: 'accepted',
      digital_asset_attributes: { visibility: 'all' },
    },
  });
};

const reject = editsCollectionDigitalAsset => {
  mutate({
    cdaId: editsCollectionDigitalAsset.id,
    body: { edit_status: 'rejected' },
  });
};
</script>
<template>
  <div v-if="!isLoadingAssets && assetRows.length > 0" ref="wrapper">
    <div class="unlinked-pending-edits-header">
      <h3 class="u-body--heavy">unlinked, undelivered edits</h3>
    </div>
    <SmallInfiniteGallery
      v-slot="{ data }"
      :rows="assetRows"
      :initial-scroll-position="0"
      :height="rowHeight"
      :gap="gutter"
    >
      <UnlinkedPendingEditsCollectionDigitalAssetCard
        v-for="ecda in data.assets"
        :key="ecda.id"
        :loading="isPending"
        :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
        :edits-collection-digital-asset="ecda"
        :to="generateRouteLocation(ecda)"
        @request-page="pageNumber => fetchPage(pageNumber)"
        @open-assign-original-dialog="toggleShowOriginalDialog(true, ecda)"
        @accept="accept(ecda)"
        @reject="reject(ecda)"
      />
    </SmallInfiniteGallery>
    <AssignOriginalDialog
      v-if="showOriginalDialog"
      :account-id="accountId"
      :reservation-id="reservationId"
      :digital-asset-id="assignedEdit?.id"
      @close="() => toggleShowOriginalDialog(false, null)"
    />
  </div>
</template>

<style lang="scss" scoped>
.unlinked-pending-edits-header {
  margin-bottom: 1.5rem;
}
</style>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {Object} [params]
 * @param {string | Ref<string>} [accountId]
 * @param {string | Ref<string>} [assetId]
 * @param {Object | Ref<string>} [params]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useDigitalAssetCollections(
  { accountId, assetId, params = {} },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.digitalAssetCollections(accountId, assetId, params),
    queryFn: async ({ signal }) => {
      let requestedParams = toValue(params);

      const response_params = {};
      if (requestedParams['collection_type'])
        response_params['collection_type'] = requestedParams['collection_type'];

      const response = await http.get(
        `/accounts/${toValue(accountId)}/digital_assets/${toValue(assetId)}/collections`,
        { params: response_params, signal }
      );
      return response.data;
    },
    ...queryOptions,
  });
}

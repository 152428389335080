<template>
  <CompleteProfileCard
    class="account-type"
    :disabled="disabled"
    :show-skip="false"
    @next="saveAccountType"
  >
    <template #heading>is this account just for you or for a brand?</template>
    <div class="flex-container">
      <div
        v-for="(type, index) in accountTypes"
        :key="index"
        class="account-type-buttons"
      >
        <button
          class="type-btn"
          :class="{ 'type-btn--selected': selection === type.name }"
          @click="setAccountType(type.name)"
        >
          {{ type.name === 'personal' ? `💁‍♀️` : `🏢` }}
        </button>
        <p class="u-label--heavy">
          {{ type.name }}
        </p>
      </div>
    </div>
  </CompleteProfileCard>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';

export default {
  name: 'AccountType',
  components: { CompleteProfileCard },
  data() {
    return {
      selection: '',
    };
  },
  computed: {
    ...mapGetters('account', ['accountTypes']),
    ...mapState({
      account: state => state.account,
      accountType: state => state.account.account_type,
    }),
    disabled() {
      return this.selection === '';
    },
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    setAccountType(value) {
      this.selection = value;
    },
    async saveAccountType() {
      let info = {
        account_type: this.selection,
        account_type_card: {
          complete: true,
        },
      };
      try {
        await this.updateAccount({ accountParams: info });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(new Error('Updating account type failed'), {
            extra: { error },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
@use '@/variables';

.account-type {
  .flex-container {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  .type-btn {
    padding: 0.25em;
    background: variables.$white-default;
    box-shadow: variables.$elevation-2;
    border-radius: 0.25rem;
    font-size: 4rem;
    border: 0.125rem solid transparent;
    border-radius: 0.4375rem;
    margin-bottom: 0.25rem;

    &--selected {
      border-color: variables.$gray-90;
    }
  }
}
</style>

<template>
  <div class="ChatTextArea" :class="{ disabled: disabled }">
    <div
      class="chat-attachment-container"
      :style="{
        paddingTop: files.length > 0 ? '0.75rem' : 0,
        paddingBottom: files.length > 0 ? '0.75rem' : 0,
      }"
      tabindex="0"
      @keydown.enter="submit"
    >
      <div v-for="(file, index) in files" :key="index" class="chat-attachment">
        <img v-if="isImage(file)" class="image-thumbnail" :src="file.url" />
        <div v-if="isPDF(file)" class="pdf-thumbnail">
          <img src="@images/pdf.svg" alt="pdf icon" />
        </div>
        <video v-if="isVideo(file)" class="video-thumbnail" muted>
          <source :src="file.url" type="video/mp4" />
        </video>
        <button class="delete-button" @click="deleteFile(file)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              fill="white"
              stroke="#989CA3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15 9L9 15"
              stroke="#989CA3"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9 9L15 15"
              stroke="#989CA3"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
    <textarea
      v-show="files.length === 0"
      ref="input"
      name=""
      :value="message"
      placeholder="type a reply…"
      rows="1"
      maxlength="250"
      :disabled="disabled"
      @input="onInput"
      @keydown.enter="submit"
    />
  </div>
</template>

<script>
export default {
  name: 'ChatTextArea',
  props: {
    message: String,
    files: Array,
    submit: Function,
    deleteFile: Function,
    disabled: Boolean,
  },
  emits: ['update'],
  watch: {
    message() {
      this.handleResizing();
    },
  },
  mounted: function () {
    this.$nextTick(this.handleResizing);
  },
  methods: {
    onInput(e) {
      this.$emit('update', e.target.value);
      this.handleResizing();
    },
    handleResizing() {
      if (!this.message) {
        return (this.$refs.input.style.height = '40px');
      }
      this.$refs.input.style.height = 'auto';
      this.$refs.input.style.height = this.$refs.input.scrollHeight + 'px';
    },
    isImage(file) {
      return file.type.includes('image');
    },
    isPDF(file) {
      return file.type.includes('pdf');
    },
    isVideo(file) {
      return file.type.includes('video');
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.ChatTextArea {
  padding: 0;
  background: variables.$white-default;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  border-radius: 5px;
  border: 1px solid #d6d8db;
}
.disabled {
  background: variables.$gray-20;
}
.chat-text-area:active,
.chat-text-area:focus {
  border: 1px solid variables.$gray-40;
}
.attachment-thumbnail {
  height: 70px;
  min-width: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin: 3px;
}
textarea {
  flex: auto;
  border: none;
  resize: none;
  padding: 0.6875rem 2.75rem 0.625rem 0.75rem;
  max-height: 104px;
  height: 2.5rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.2;
  background-color: transparent;
}
.chat-attachment-container {
  display: flex;
  justify-content: flex-start;
  padding-right: 30px;
  .chat-attachment {
    position: relative;
    width: 33%;
    &:not(:last-child) {
      margin-right: 10px;
    }
    .image-thumbnail,
    .pdf-thumbnail {
      height: 70px;
      width: 100%;
      border-radius: 5px;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .image-thumbnail {
      object-fit: cover;
    }
    .pdf-thumbnail {
      border: 0.5px solid #d6d8db;
      background: variables.$gray-10;
      &:not(:last-child) {
        margin-right: 3px;
      }
      .delete-button {
        background: transparent;
        position: absolute;
        top: -5px;
        right: -10px;
      }
    }
    .video-thumbnail {
      height: 70px;
      width: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
    .video-thumbnail {
      height: 70px;
      width: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
    .delete-button {
      background: transparent;
      position: absolute;
      top: -5px;
      right: -10px;
    }
  }
}
</style>

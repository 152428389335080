import { http } from '../config/vue-axios';

/**
 *
 * @param {number} reservationId
 * @param {AbortSignal} [signal]
 */
export async function getReservationReadinessSteps(reservationId, signal) {
  let url = `/reservations/${reservationId}/readiness_steps`;

  const response = await http.get(url, { signal });

  return response.data;
}

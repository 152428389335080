<script setup>
import { computed } from 'vue';
import BaseInfiniteGalleryCard from '@/components/infinite_asset_gallery/BaseInfiniteGalleryCard.vue';

const props = defineProps({
  reEditsCollectionDigitalAsset: {
    type: Object,
    required: true,
  },
  flexGrow: {
    type: Number,
    default: 0,
  },
});

defineEmits(['request-page']);

const asset = computed(() => props.reEditsCollectionDigitalAsset.digital_asset);

const to = computed(() => {
  if (props.reEditsCollectionDigitalAsset.placeholder) return {};
  if (
    !props.reEditsCollectionDigitalAsset.reservation_digital_asset
      ?.reservation_id
  )
    return {};

  return {
    name: 'reservation-asset-re-edit-view',
    params: {
      reservationId:
        props.reEditsCollectionDigitalAsset.reservation_digital_asset
          .reservation_id,
      digitalAssetId: asset.value.id,
    },
  };
});
</script>
<template>
  <BaseInfiniteGalleryCard
    :digital-asset="asset"
    :paged-asset="reEditsCollectionDigitalAsset"
    :flex-grow="flexGrow"
    :to="to"
    @request-page="page => $emit('request-page', page)"
  >
  </BaseInfiniteGalleryCard>
</template>

<script setup>
import { computed, ref } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { component as Viewer } from 'v-viewer';

const props = defineProps({
  listingActionSlug: {
    type: String,
    required: true,
  },
  originalAsset: {
    type: Object,
    required: true,
  },
  replacementAsset: {
    type: Object,
    required: true,
  },
});

const downloadFile = asset => {
  window.open(asset.file_url);
};

const replacementAssetText = computed(() => {
  switch (props.listingActionSlug) {
    case 'low-resolution':
      return 'optimized';
    default:
      return 'updated';
  }
});

const originalViewer = ref(null);
const replacementViewer = ref(null);

const originalInited = viewer => {
  originalViewer.value = viewer;
};

const replacementInited = viewer => {
  replacementViewer.value = viewer;
};

const triggerViewer = type => {
  if (type === 'original') {
    originalViewer.value.show();
  } else {
    replacementViewer.value.show();
  }
};
</script>

<template>
  <SoonaDialog size="full-screen" class="comparison-viewer-dialog">
    <template #heading>compare</template>
    <div class="comparison-viewer">
      <div class="comparison-viewer__asset">
        <div class="comparison-viewer__details">
          <div class="comparison-viewer__details--left">
            <SoonaIcon
              name="circle-information"
              class="comparison-viewer__details--light-text"
            />
            <div>
              <span class="u-body--heavy">original</span>
              <span class="comparison-viewer__details--light-text">
                {{ originalAsset.resolution }}
              </span>
              <div class="comparison-viewer__details--light-text">
                {{ originalAsset.title }}
              </div>
            </div>
          </div>
          <div class="comparison-viewer__details--right">
            <SoonaButton
              variation="icon-plain-gray"
              title="download original"
              @click="downloadFile(originalAsset)"
            >
              <SoonaIcon name="arrow-down-to-bracket" size="small" />
            </SoonaButton>
            <SoonaButton
              variation="tertiary"
              class="comparison-viewer__details--light-text"
              @click="triggerViewer('original')"
            >
              view full size
            </SoonaButton>
          </div>
        </div>
        <div class="comparison-viewer__image-wrapper">
          <Viewer
            id="originalViewer"
            :options="{
              toolbar: false,
              navbar: false,
              title: false,
              backdrop: true,
              minZoomRatio: 0.01,
              maxZoomRatio: 2,
              container: '.comparison-viewer-dialog',
            }"
            @inited="originalInited"
          >
            <img
              :src="originalAsset.file_url"
              :data-src="originalAsset.file_url"
              alt="original image"
            />
          </Viewer>
        </div>
      </div>
      <div class="comparison-viewer__asset">
        <div class="comparison-viewer__details">
          <div class="comparison-viewer__details--left">
            <SoonaIcon
              name="circle-check-solid"
              class="comparison-viewer__replacement-asset--icon"
            />
            <div>
              <span class="u-body--heavy">{{ replacementAssetText }}</span>
              <span class="comparison-viewer__details--light-text">
                {{ replacementAsset.resolution }}
              </span>
              <div class="comparison-viewer__details--light-text">
                {{ replacementAsset.title }}
              </div>
            </div>
          </div>
          <div
            class="comparison-viewer__details--right comparison-viewer__details--light-text"
          >
            <SoonaButton
              variation="icon-plain-gray"
              :title="`download ${replacementAssetText}`"
              @click="downloadFile(replacementAsset)"
            >
              <SoonaIcon name="arrow-down-to-bracket" size="small" />
            </SoonaButton>
            <SoonaButton
              variation="tertiary"
              @click="triggerViewer('replacement')"
            >
              view full size
            </SoonaButton>
          </div>
        </div>
        <div class="comparison-viewer__image-wrapper">
          <Viewer
            id="replacementViewer"
            :options="{
              toolbar: false,
              navbar: false,
              title: false,
              backdrop: true,
              minZoomRatio: 0.01,
              maxZoomRatio: 2,
              container: '.comparison-viewer-dialog',
            }"
            @inited="replacementInited"
          >
            <img :src="replacementAsset.file_url" alt="optimized image" />
          </Viewer>
        </div>
      </div>
    </div>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.comparison-viewer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__asset {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid variables.$gray-30;
    border-radius: 0.625rem;
    padding: 1rem;
  }

  &__image-wrapper {
    margin: auto auto;
    cursor: pointer;

    img {
      max-width: 100%;
    }
  }

  &__details {
    display: flex;
    gap: 1rem;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;

    &--left {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: flex-start;

      div {
        span {
          margin-right: 0.5rem;
        }
      }
    }

    &--right {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
    }

    &--light-text {
      color: variables.$gray-60;
    }
  }

  &__replacement-asset {
    &--icon {
      color: variables.$avo-toast-60;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    flex-flow: row wrap;
    // align-items: stretch;
  }
}
</style>

import { unref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { validateCoupon } from '@/api/reservation';

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useValidateCoupon(reservationId) {
  return useMutation({
    mutationFn: ({ coupon, products, orderType, currentDiscountId }) =>
      validateCoupon(
        unref(reservationId),
        coupon,
        products,
        orderType,
        currentDiscountId
      ),
  });
}

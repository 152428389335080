import { getTrendIntegrationUrl } from '@/api/trend';
import { useMe } from '@/composables/user/useMe';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useFlag } from '@/composables/useFlag';
import { useIntegrations } from '@/composables/useIntegrations';
import { useRoute } from 'vue-router';

export const useCreateABrief = ({ utmContent, subContext, buttonCopy }) => {
  const route = useRoute();
  const { currentAccountId } = useMe();
  const { linkClicked, buttonClicked } = useBaseEvents();
  const { hasActiveTrendIntegration } = useIntegrations(currentAccountId);
  const trendIntegrationUrlFlag = useFlag('toaster_trend_integration_url');

  const createBriefUrlParams =
    'utm_source=soona&utm_medium=referral&utm_campaign=create_a_brief_redirect_book_soona&utm_content=' +
    utmContent;
  const trendLoginUrlParams =
    'utm_source=soona&utm_medium=referral&utm_campaign=trend_log_in_book_soona&utm_content=' +
    utmContent;

  const handleCreateABrief = async args => {
    const elementType = args?.elementType ?? 'link';
    const context = route.meta.context;
    let linkUrl = '';

    if (trendIntegrationUrlFlag.value) {
      if (hasActiveTrendIntegration.value) {
        linkUrl =
          import.meta.env.VITE_TREND_APP_URL +
          '/campaigns/create?' +
          createBriefUrlParams;
      } else {
        const customTokenUrl = await getTrendIntegrationUrl();

        linkUrl = customTokenUrl + '&' + createBriefUrlParams;
      }
    } else {
      linkUrl =
        import.meta.env.VITE_TREND_APP_URL + 'sign-up?' + createBriefUrlParams;
    }
    const eventData = {
      context,
      subContext,
      linkLabel: buttonCopy,
      linkHref: linkUrl,
    };

    if (elementType === 'button') {
      buttonClicked(eventData);
    } else {
      linkClicked(eventData);
    }
    window.open(linkUrl, '_blank');
  };

  const handleLogin = async args => {
    const elementType = args?.elementType ?? 'link';
    const context = route.meta.context;
    let linkUrl = '';

    if (trendIntegrationUrlFlag.value) {
      if (hasActiveTrendIntegration.value) {
        linkUrl =
          import.meta.env.VITE_TREND_APP_URL + '?' + trendLoginUrlParams;
      } else {
        const customTokenUrl = await getTrendIntegrationUrl();

        linkUrl = customTokenUrl + '&' + trendLoginUrlParams;
      }
    } else {
      linkUrl = import.meta.env.VITE_TREND_APP_URL + '?' + trendLoginUrlParams;
    }
    const eventData = {
      context,
      subContext,
      linkLabel: 'log in',
      linkHref: linkUrl,
    };

    if (elementType === 'button') {
      buttonClicked(eventData);
    } else {
      linkClicked(eventData);
    }

    window.open(linkUrl, '_blank');
  };

  return {
    handleCreateABrief,
    handleLogin,
  };
};

<script setup>
import { computed } from 'vue';
import QuantityIncrementor from 'src/components/booking/QuantityIncrementor.vue';
import { useCapability } from 'src/composables/useCapability';
import { toCurrency } from '@/lib/currency';

const props = defineProps({
  product: {
    required: true,
    type: Object,
  },
  showLevel: {
    default: true,
    type: Boolean,
  },
});

const emits = defineEmits([
  'incrementProduct',
  'decrementProduct',
  'setProductCount',
]);

const product = computed(() => props.product);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

function handleIncrement() {
  emits('incrementProduct', product.value);
}
function handleDecrement() {
  emits('decrementProduct', product.value);
}
function handleInput(newVal) {
  emits('setProductCount', product.value, newVal);
}

const productMin = computed(() => {
  return product.value.min && !isFtSoonaStaff.value ? product.value.min : 1;
});

const productMax = computed(() => {
  return product.value.max ? product.value.max : 10;
});

const productRate = computed(() => {
  return product.value.price_info?.rate;
});

const productDiscountedRate = computed(() => {
  return product.value.price_info?.discounted_rate || null;
});
</script>
<template>
  <div class="product-line-item">
    <img :src="product?.thumbnail_url" class="product-line-item__img" alt="" />
    <div class="product-line-item__content">
      <div class="product-line-item__title-price">
        <strong class="product-line-item__title">
          {{ product.name }}
          <span v-if="product.level && showLevel">
            &nbsp;({{ product.level }})
          </span>
        </strong>
        <em class="product-line-item__price">
          <div
            :class="{ 'product-line-item__price__old': productDiscountedRate }"
          >
            {{ toCurrency(productRate * product.quantity) }}
          </div>
          <div
            v-if="productDiscountedRate"
            class="product-line-item__price__new"
          >
            {{ toCurrency(productDiscountedRate * product.quantity) }}
          </div>
        </em>
      </div>
      <div class="product-line-item__qty-remove">
        <QuantityIncrementor
          :value="product.quantity"
          :min="productMin"
          :max="productMax"
          :typeable="true"
          @on-increment="handleIncrement"
          @on-decrement="handleDecrement"
          @handle-input-value="handleInput"
        />
        <button
          type="button"
          class="u-button-reset product-line-item__remove"
          @click="handleInput(0)"
        >
          remove
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-line-item {
  display: flex;
  padding: 0 0 1rem 0;
  margin: 1rem 0;
  border-bottom: 1px solid variables.$gray-30;

  &:last-child {
    border-bottom: none;
  }

  &__img {
    width: 4rem;
    height: 4rem;
    border-radius: 0.3125rem;
    margin-right: 0.75rem;
    object-fit: cover;
  }

  &__content {
    flex-grow: 1;
    padding: 0;
  }

  &__title-price {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }

  &__title {
    display: flex;
    flex-wrap: wrap;
    color: variables.$black-default;
    text-transform: lowercase;
    overflow-wrap: break-word;

    > .tooltip {
      align-self: center;
      padding-left: 0;
      margin-left: 0.5rem;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    color: variables.$black-default;
    font-style: normal;
    margin-left: 0.5rem;
    text-align: center;
    gap: 0.25rem;

    &__old {
      text-decoration: line-through;
    }

    &__new {
      color: variables.$friendly-red-70;
      @include variables_fonts.u-body--heavy;
    }
  }

  &__math {
    color: variables.$gray-60;
    margin-right: 0.5rem;
    min-width: fit-content;
  }

  &__qty-remove {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__remove {
    @include variables_fonts.u-label--regular;

    text-decoration: underline;
    margin-left: auto;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }

  @media (min-width: 469px) and (max-width: 959px) {
    &__item {
      display: flex;
      align-items: flex-start;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &:not(.product-line-item--affixed) {
      .product-line-item {
        display: block;
      }
    }
  }

  @media (min-width: variables.$screen-md-min) {
    &:not(.product-line-item--affixed) {
      .product-line-item {
        display: flex;
        align-items: flex-start;
      }
    }
  }

  @media (min-width: variables.$screen-lg-min) {
    &__item {
      display: flex;
      align-items: flex-start;
    }
  }
}
</style>

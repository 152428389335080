<script setup>
import { computed, ref } from 'vue';
import SelectIntegrationDropdown from './SelectIntegrationDropdown.vue';
import ManageIntegrationsDialog from '@/components/user/anytime/products/ManageIntegrationsDialog.vue';
import RemoveAmazonAccountDialog from '@/components/user/anytime/products/RemoveAmazonAccountDialog.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  integrationId: {
    type: [String, Number, null],
    default: null,
  },
});

const emit = defineEmits(['select']);

const integrationId = computed(() => props.integrationId);

const selectedIntegrationId = computed({
  get() {
    return integrationId.value;
  },
  set(value) {
    emit('select', value);
  },
});

// manage integrations module
const displayManageIntegrationsDialog = ref(false);
const displayRemoveAmazonAccountDialog = ref(false);

function toggleIntegrationsDialog() {
  displayManageIntegrationsDialog.value =
    !displayManageIntegrationsDialog.value;
}

const displayRemoveAmazonAccountDialogHandler = integrationId => {
  selectedIntegrationId.value = integrationId;
  displayManageIntegrationsDialog.value = false;
  displayRemoveAmazonAccountDialog.value = true;
};

const closeRemoveAmazonAccountDialog = () => {
  displayManageIntegrationsDialog.value = true;
  displayRemoveAmazonAccountDialog.value = false;
};
</script>

<template>
  <SelectIntegrationDropdown
    :account-id="accountId"
    :open-manage-accounts="toggleIntegrationsDialog"
    :selected-integration-id="integrationId"
    @select="$emit('select', $event)"
  />
  <ManageIntegrationsDialog
    v-if="displayManageIntegrationsDialog"
    :account-id="accountId"
    @close="toggleIntegrationsDialog"
    @open-remove-amazon-data-dialog="displayRemoveAmazonAccountDialogHandler"
  />
  <RemoveAmazonAccountDialog
    v-if="displayRemoveAmazonAccountDialog && selectedIntegrationId"
    :account-id="accountId"
    :integration-id="selectedIntegrationId"
    @close="closeRemoveAmazonAccountDialog"
    @removing-integration="handleRemovingIntegration"
  />
</template>

<style lang="scss" scoped></style>

import { readonly, ref } from 'vue';

export const useMediaEditorCanvas = () => {
  const canvasData = ref(null);

  const showForeground = ref(false);

  const canvasComponent = ref(null);

  const registerCanvasComponent = handlers => {
    canvasComponent.value = handlers;
  };

  const selectorData = ref({});
  const selectionData = ref({});

  const resetCanvas = () => {
    canvasData.value = null;
    showForeground.value = false;
    canvasComponent.value = null;
    selectorData.value = {};
    selectionData.value = {};
  };

  return {
    canvasData: readonly(canvasData),
    setCanvasData: data => (canvasData.value = data),
    canvasComponent,
    registerCanvasComponent,
    showForeground: readonly(showForeground),
    setShowForeground: value => (showForeground.value = value),
    selectorData: readonly(selectorData),
    setSelectorData: data => (selectorData.value = data),
    selectionData: readonly(selectionData),
    setSelectionData: data => (selectionData.value = data),
    resetCanvas,
  };
};

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useUpdateShotList(reservationId, shotListId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async params => {
      await http.put(
        `/reservations/${toValue(reservationId)}/shot_lists/${toValue(shotListId)}`,
        {
          shot_list: params,
        }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservationShots(reservationId),
      });
    },
  });
}

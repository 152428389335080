<script setup>
import { ref } from 'vue';
import {
  AvoToast60,
  BubbletapePink40,
  BubbletapePink70,
  PeriwinkBlue60,
  Tangerine70,
} from 'src/variables.module.scss';
import ContentOption from './ContentOption.vue';

const optionList = [
  {
    icon_color: PeriwinkBlue60,
    icon_name: 'camera-alt-1',
    title: 'product photography',
  },
  {
    icon_color: BubbletapePink70,
    icon_name: 'video-file-play',
    title: 'professional video',
  },
  {
    icon_color: BubbletapePink40,
    icon_name: 'wand-magic-sparkles',
    title: 'AI photography',
  },
  {
    icon_color: AvoToast60,
    icon_name: 'ugc',
    title: 'UGC',
  },
  {
    icon_color: Tangerine70,
    icon_name: 'colors',
    title: 'post production',
  },
];

const selectedOption = ref(optionList[0].title);
</script>

<template>
  <div class="content-options">
    <h1 class="u-display--heavy">what content do you need?</h1>
    <div class="content-options__flex">
      <fieldset>
        <legend class="u-visually-hidden">content options</legend>
        <ContentOption
          v-for="option in optionList"
          :key="option.title.replace(/\s+/, '-')"
          v-model:selected="selectedOption"
          :option="option"
        />
      </fieldset>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.content-options {
  align-items: center;
  background-color: variables.$bubbletape-pink-10;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2.5rem 0;
  padding-bottom: 1.5rem;

  h1 {
    padding: 0 1rem;
    text-align: center;
  }

  &__flex {
    display: flex;
    overflow-x: auto;
    padding: 1rem;
    width: 100%;
  }

  fieldset {
    display: flex;
    gap: 1.25rem;
    width: 100%;
  }
}
</style>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue';

import {
  AI_SCENES_ACTION,
  AI_PROPS_ACTION,
  BACKGROUND_COLOR_ACTION,
  BLUR_BACKGROUND_ACTION,
  MOBILE_EDIT_ACTION,
  REMOVE_BACKGROUND_ACTION,
  SHADOWS_ACTION,
  RESIZE_ACTION,
  PRODUCTS_ACTION,
  ALBUMS_ACTION,
  NOTES_ACTION,
  RE_EDITS_ACTION,
  PREMIUM_EDITS_ACTION,
} from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useFlag } from '@/composables/useFlag';

import ActionBarButton from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarButton.vue';
import ActionBarBottom from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarBottom.vue';
import MediaMultiplierLoading from '@/components/user/anytime/gallery/media-editor/media-multiplier/MediaMultiplierLoading.vue';
import MediaMultiplierError from '@/components/user/anytime/gallery/media-editor/media-multiplier/MediaMultiplierError.vue';
import MediaActionFilter from '@/components/user/anytime/gallery/media-editor/MediaActionFilter.vue';
import RemoveBackground from '@/components/user/anytime/gallery/media-editor/media-multiplier/RemoveBackground.vue';
import ImageResizer from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/ImageResizer.vue';
import { BACKGROUND_COLOR_SYMBOLS } from '@/components/user/anytime/gallery/media-editor/background_color_filter_symbols';
import { RESIZE_SYMBOLS } from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/filter_symbols';
import DigitalAssetProducts from '@/components/user/anytime/gallery/media-editor/DigitalAssetProducts.vue';
import DigitalAssetCollections from '@/components/user/anytime/gallery/media-editor/DigitalAssetCollections.vue';
import PremiumEditsDigitalAsset from '@/components/user/anytime/gallery/media-editor/PremiumEditsDigitalAsset.vue';
import { useGetReservationDigitalAssetMediaAddOns } from '@/queries/reservation_digital_assets/useGetReservationDigitalAssetMediaAddOns';
import RequestedReEditRDA from '@/components/user/anytime/gallery/media-editor/media-multiplier/RequestedReEditRDA.vue';
import { useMokkerRedirect } from '@/queries/mokker/useMokkerRedirect';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import DigitalAssetNotesDialog from '@/components/notes/digital-asset-notes/DigitalAssetNotesDialog.vue';
import { useMe } from '@/composables/user/useMe';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import MediaEditorNotes from '@/components/user/anytime/gallery/media-editor/MediaEditorNotes.vue';

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId } = storeToRefs(mediaEditorStore);

const { currentAccountId } = useMe();

const {
  isSuccess: assetLoaded,
  assetUuid,
  foregroundUrl,
  mediaUrl,
  maskUrl,
  pendingEditorTasks,
  reservationId,
  rdaId,
  isPhoto,
  ownedByCustomer,
  ownedBySoona,
  inBag,
  reEditsCDAId,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const { activeAction, iframeUrl, hasSubscriptionDownloadAccess } =
  storeToRefs(mediaEditorStore);

const enableAIStudio = computed(
  () =>
    ownedByCustomer.value &&
    !!assetUuid.value &&
    currentAccountId.value === assetAccountId.value
);

const {
  flyoutHeading,
  flyoutSubheading,
  isAIScenesActive,
  isAIPropsActive,
  isBlurBackgroundActive,
  isImageResizerActive,
  isMobileEditPanelActive,
  isRemoveBackgroundActive,
  isShiftBackgroundColorActive,
  isShadowsActive,
  isProductsActive,
  isAlbumsActive,
  isNotesActive,
  isPremiumEditsActive,
  isReEditRequestActive,
} = useMediaEditor({
  activeAction,
});

const hasToasterDigitalAssetCommentsFlag = useFlag(
  'toaster_digital_asset_comments'
);

const pegasusPaywallRemoveBackgroundFlag = useFlag(
  'pegasus_paywall_remove_background'
);

const { linkClicked } = useBaseEvents();
const route = useRoute();
const trackSelectedFilter = ref(null);

const trackAndRefreshCropper = async filter => {
  trackSelectedFilter.value = filter;
  mediaEditorStore.canvasComponent.value?.refreshCropper();
};

const showBackBtn = computed(() => {
  switch (activeAction.value) {
    case BACKGROUND_COLOR_ACTION:
    case REMOVE_BACKGROUND_ACTION:
    case BLUR_BACKGROUND_ACTION:
    case SHADOWS_ACTION:
    case AI_SCENES_ACTION:
    case AI_PROPS_ACTION:
    case RESIZE_ACTION:
      return true;
    default:
      return false;
  }
});

const showOrganize = computed(() => {
  switch (activeAction.value) {
    case PRODUCTS_ACTION:
    case ALBUMS_ACTION:
      return true;
    default:
      return false;
  }
});

const handleBackClick = () => {
  trackSelectedFilter.value = null;
  // if we add more subnavs, we might want to make a static .json that we can
  // import and use to look up the 'ancestor' action, instead of hard coding to 'edit'
  mediaEditorStore.setActiveAction(MOBILE_EDIT_ACTION);
};

const AsyncShiftBackgroundColor = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/ShiftBackgroundColor.vue'
    ),
  loadingComponent: MediaMultiplierLoading,
  delay: 100,
  errorComponent: MediaMultiplierError,
});

const AsyncBlurBackground = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/BlurBackground.vue'
    ),
  loadingComponent: MediaMultiplierLoading,
  delay: 100,
  errorComponent: MediaMultiplierError,
});

const AsyncAddShadows = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/AddShadows.vue'
    ),
  loadingComponent: MediaMultiplierLoading,
  delay: 100,
  errorComponent: MediaMultiplierError,
});

const handleButtonClick = (subxActionText, subxActionSymbol) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor menu',
    linkLabel: subxActionText,
    linkHref: null,
  });
  mediaEditorStore.setActiveAction(subxActionSymbol);
};

const handlePaywallButtonClick = (actionText, actionSymbol) => {
  if (hasSubscriptionDownloadAccess.value) {
    handleButtonClick(actionText, actionSymbol);
  } else {
    mediaEditorStore.setShowPaywallDialog(true);
  }
};

const handleRemoveBackgroundButtonClick = () => {
  if (
    !pegasusPaywallRemoveBackgroundFlag.value ||
    hasSubscriptionDownloadAccess.value
  ) {
    handleButtonClick('remove background', REMOVE_BACKGROUND_ACTION);
  } else {
    mediaEditorStore.setShowPaywallDialog(true);
  }
};

// for premium edit filters
const {
  data: mediaAddOns,
  isLoading: mediaAddOnsLoading,
  error: mediaAddOnsError,
} = useGetReservationDigitalAssetMediaAddOns(reservationId, rdaId, {
  enabled: computed(
    () => !!assetId.value && !!reservationId.value && !!rdaId.value
  ),
});

const mediaAddOnLineItemNames = computed(() => {
  if (!mediaAddOns.value) return ['add editing notes'];
  const names = mediaAddOns.value.media_add_on_line_items.map(li => ({
    label: li.product.name,
    disabled: !inBag.value,
  }));
  names.push({ label: 'add editing notes', disabled: !inBag.value });
  return names;
});

const handleOrganizeFilterChange = filter => {
  const symbolMap = { products: PRODUCTS_ACTION, albums: ALBUMS_ACTION };
  // prevents closing the panel when clicking an already active filter
  if (activeAction.value !== filter) {
    mediaEditorStore.setActiveAction(symbolMap[filter]);
  }
};

const bottomBarSize = computed(() => {
  if (iframeUrl.value) {
    return 'closed';
  } else if (isImageResizerActive.value) {
    return !trackSelectedFilter.value ? 'short' : 'large';
  } else if (isShiftBackgroundColorActive.value) {
    return !trackSelectedFilter.value ? 'short' : 'medium';
  } else if (isPremiumEditsActive.value) {
    return 'large';
  }
  return '';
});

// AI studio iframe
const { mutate: getMokkerRedirect, isPending: aiStudioRedirectLoading } =
  useMokkerRedirect();

const { addToast } = useSoonaToast();

const handleAIStudioButtonClick = (actionText, actionSymbol) => {
  handleButtonClick(actionText, actionSymbol);

  const failureToast = () => {
    addToast(`Failed to open AI studio`, {
      variation: 'error',
    });
  };

  getMokkerRedirect(assetUuid.value, {
    onSuccess: response => {
      if (response.result) {
        const urlSuffix = isAIPropsActive.value ? '&photo_prop=1' : '';
        mediaEditorStore.setIframeUrl(`${response.result}${urlSuffix}`);
      } else {
        failureToast();
      }
    },
    onError: failureToast,
  });
};
</script>

<template>
  <ActionBarBottom
    class="bottom-action-bar"
    :size="bottomBarSize"
    :show-back-button="showBackBtn"
    @back="handleBackClick"
    @close="mediaEditorStore.setActiveAction(activeAction)"
  >
    <template #heading>{{ flyoutHeading }}</template>
    <template v-if="!activeAction" #primary-nav>
      <ActionBarButton
        v-if="isPhoto"
        icon-name="edit-image"
        label="edit"
        size="small"
        :is-selected="isMobileEditPanelActive"
        @on-click="mediaEditorStore.setActiveAction(MOBILE_EDIT_ACTION)"
      />
      <ActionBarButton
        v-if="ownedBySoona && rdaId"
        icon-name="pen-swirl"
        label="premium edits"
        size="small"
        :is-selected="isPremiumEditsActive"
        @on-click="mediaEditorStore.setActiveAction(PREMIUM_EDITS_ACTION)"
      />
      <ActionBarButton
        v-if="reEditsCDAId"
        icon-name="circle-exclamation"
        label="requested re-edit"
        size="small"
        :is-selected="isReEditRequestActive"
        @on-click="mediaEditorStore.setActiveAction(RE_EDITS_ACTION)"
      />
      <ActionBarButton
        icon-name="tag"
        label="organize"
        size="small"
        :is-selected="isProductsActive || isAlbumsActive"
        @on-click="mediaEditorStore.setActiveAction(PRODUCTS_ACTION)"
      />
      <ActionBarButton
        v-if="ownedBySoona"
        :icon-name="
          hasToasterDigitalAssetCommentsFlag
            ? 'message-circle-lines-alt'
            : 'memo-pencil'
        "
        label="notes"
        size="small"
        :is-selected="isNotesActive"
        @on-click="mediaEditorStore.setActiveAction(NOTES_ACTION)"
      />
    </template>
    <template
      v-else-if="isMobileEditPanelActive && isPhoto && !iframeUrl"
      #secondary-nav
    >
      <ActionBarButton
        icon-name="paint-roller"
        label="background color"
        :enabled="!!maskUrl && !pendingEditorTasks"
        :loading="pendingEditorTasks"
        :is-selected="isShiftBackgroundColorActive"
        @on-click="
          () => handleButtonClick('background color', BACKGROUND_COLOR_ACTION)
        "
      />
      <ActionBarButton
        v-if="isPhoto"
        icon-name="blur"
        label="blur background"
        :enabled="!!maskUrl && !pendingEditorTasks"
        :loading="pendingEditorTasks"
        :is-selected="isBlurBackgroundActive"
        @on-click="
          () => handleButtonClick('blur background', BLUR_BACKGROUND_ACTION)
        "
      />
      <ActionBarButton
        v-if="isPhoto"
        icon-name="shadows"
        label="shadows"
        :enabled="!!maskUrl && !pendingEditorTasks"
        :loading="pendingEditorTasks"
        :is-selected="isShadowsActive"
        is-paywalled
        @on-click="() => handlePaywallButtonClick('shadows', SHADOWS_ACTION)"
      />
      <ActionBarButton
        icon-name="scissors"
        label="remove background"
        :enabled="!!foregroundUrl && !pendingEditorTasks"
        :loading="pendingEditorTasks || !foregroundUrl"
        :is-selected="isRemoveBackgroundActive"
        :is-paywalled="!!pegasusPaywallRemoveBackgroundFlag"
        @on-click="handleRemoveBackgroundButtonClick"
      />
      <ActionBarButton
        v-if="isPhoto"
        icon-name="wand-magic-sparkles"
        label="AI scenes"
        :enabled="!!enableAIStudio"
        :is-selected="isAIScenesActive"
        :loading="aiStudioRedirectLoading && isAIScenesActive"
        @on-click="handleAIStudioButtonClick('AI scenes', AI_SCENES_ACTION)"
      >
        <template #content-bottom>
          <small class="u-badge--small action-bar-left__beta">beta</small>
        </template>
      </ActionBarButton>
      <ActionBarButton
        v-if="isPhoto"
        icon-name="hand-holding"
        label="AI props"
        :enabled="!!enableAIStudio"
        :is-selected="isAIPropsActive"
        :loading="aiStudioRedirectLoading && isAIPropsActive"
        @on-click="handleAIStudioButtonClick('AI props', AI_PROPS_ACTION)"
      >
        <template #content-bottom>
          <small class="u-badge--small action-bar-left__beta">beta</small>
        </template>
      </ActionBarButton>
      <ActionBarButton
        v-if="isPhoto"
        icon-name="resize"
        label="resize"
        :enabled="!!mediaUrl"
        :is-selected="isImageResizerActive"
        :show-quest-helper="true"
        @on-click="() => handleButtonClick('resize', RESIZE_ACTION)"
      />
    </template>
    <template v-if="flyoutSubheading" #subheading>
      {{ flyoutSubheading }}
    </template>
    <template v-if="isShiftBackgroundColorActive" #has-own-scroll>
      <MediaActionFilter
        v-if="isShiftBackgroundColorActive"
        :filter-options="BACKGROUND_COLOR_SYMBOLS"
        :show-paywall="!hasSubscriptionDownloadAccess"
        @filter-change="trackSelectedFilter = $event"
      >
        <template #default="{ selectedFilter }">
          <AsyncShiftBackgroundColor
            :selected-filter="selectedFilter"
            is-mobile-view
          />
        </template>
      </MediaActionFilter>
    </template>
    <template v-else-if="isImageResizerActive" #has-own-scroll>
      <MediaActionFilter
        :filter-options="RESIZE_SYMBOLS"
        :show-paywall="!hasSubscriptionDownloadAccess"
        @filter-change="trackAndRefreshCropper"
      >
        <template #default="{ selectedFilter }">
          <ImageResizer
            v-if="assetLoaded"
            :selected-filter="selectedFilter"
            is-mobile-view
          />
        </template>
      </MediaActionFilter>
    </template>
    <template v-else-if="showOrganize" #has-own-scroll>
      <MediaActionFilter
        default-filter="products"
        :filter-options="['products', 'albums']"
        @filter-change="handleOrganizeFilterChange"
      >
        <DigitalAssetCollections v-if="isAlbumsActive" />

        <DigitalAssetProducts v-if="isProductsActive" />
      </MediaActionFilter>
    </template>
    <template v-else-if="isPremiumEditsActive" #has-own-scroll>
      <MediaMultiplierError v-if="mediaAddOnsError">
        {{ mediaAddOnsError }}
      </MediaMultiplierError>
      <MediaMultiplierLoading v-else-if="mediaAddOnsLoading" />
      <MediaActionFilter
        v-else-if="mediaAddOnLineItemNames.length"
        :filter-options="mediaAddOnLineItemNames"
      >
        <template v-if="inBag" #subheading>
          these edits are done by our talented post-production team and will be
          delivered in 24 hrs.
        </template>
        <template #default="{ selectedFilter }">
          <PremiumEditsDigitalAsset
            v-if="isPremiumEditsActive"
            :selected-filter="selectedFilter"
            is-mobile-view
          />
        </template>
      </MediaActionFilter>
    </template>
    <template
      v-else-if="hasToasterDigitalAssetCommentsFlag && isNotesActive"
      #has-own-scroll
    >
      <DigitalAssetNotesDialog
        v-if="assetAccountId && assetId"
        :account-id="assetAccountId"
        :digital-asset-id="assetId"
        @close="mediaEditorStore.setActiveAction(activeAction)"
      />
    </template>
    <RemoveBackground v-if="isRemoveBackgroundActive" is-mobile-view />
    <AsyncBlurBackground v-if="isBlurBackgroundActive" is-mobile-view />
    <AsyncAddShadows v-if="isShadowsActive" is-mobile-view />
    <MediaEditorNotes v-if="isNotesActive" />
    <RequestedReEditRDA
      v-else-if="isReEditRequestActive"
      :key="rdaId"
      is-mobile-view
    />
  </ActionBarBottom>
</template>

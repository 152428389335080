<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed } from 'vue';

const props = defineProps({
  connectionStatus: {
    type: String,
    required: true,
  },
  displayRefresh: {
    type: Boolean,
    required: true,
  },
});
defineEmits(['refresh']);

// format the camel case strings to lowercase with spaces
const formattedStatus = computed(() =>
  props.connectionStatus.replace(/([A-Z])/g, ' $1').toLowerCase()
);
</script>

<template>
  <div class="chat-status">
    <p class="chat-status__status" :data-connection-status="connectionStatus">
      {{ formattedStatus }}
    </p>
    <SoonaButton
      v-if="displayRefresh"
      class="chat-status__refresh"
      variation="tertiary"
      size="small"
      @on-click="$emit('refresh')"
    >
      <SoonaIcon name="refresh-cw-alt" /> refresh
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.chat-status {
  background: variables.$white-default;
  padding: 0.5rem 1rem;
  border-bottom: 0.0625rem solid variables.$gray-30;
  display: flex;
  flex-flow: row wrap;
  gap: 0.25rem;
  justify-content: space-between;
  align-items: center;

  &__status {
    display: flex;
    flex-flow: row wrap;
    gap: 0.25rem;
    align-items: center;
    @include variables_fonts.u-small--heavy;

    &::before {
      content: '';
      border-radius: 10em;
      height: 0.625rem;
      width: 0.625rem;
      background: variables.$roses-60;
    }

    &[data-connection-status='connected']::before {
      background: variables.$avo-toast-40;
    }
    &[data-connection-status='connecting']::before {
      background: variables.$tangerine-40;
    }
  }

  &__refresh {
    border: none;
  }
}
</style>

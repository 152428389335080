<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, onMounted, ref, watchEffect } from 'vue';
import ShootDetails from 'src/components/user/anytime/ShootDetails.vue';
import CreateCrewAssignment from 'src/components/user/anytime/crew/CreateCrewAssignment.vue';
import ProServiceSelectionSection from './pro_service/ProServiceSelectionSection.vue';
import ProServiceSelectionUpgrades from './pro_service/ProServiceSelectionUpgrades.vue';
import { useGetCrewAssignments } from '@/queries/useGetCrewAssignments';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapabilities } from '@/composables/useCapabilities';
import { useMe } from '@/composables/user/useMe';

const store = useStore();
const router = useRouter();

const { currentAccountId, currentUserId } = useMe();

const currentReservation = computed(
  () => store.state.reservation.currentReservation
);

watchEffect(() => {
  store.dispatch('proService/loadProServiceCategories');
});

watchEffect(() => {
  if (currentReservation.value) {
    store.dispatch('proService/loadProServiceRequirements', {
      reservationId: currentReservation.value.id,
    });
  }
});

const proServiceRequirements = computed(
  () => store.state.proService.proServiceRequirements
);

const capabilitiesRef = ref([{ capability: 'ft_soona_staff' }]);

const capabilities = useCapabilities(capabilitiesRef);

const { data: crewAssignments, error: assignmentsError } =
  useGetCrewAssignments(
    computed(() => currentReservation.value.id),
    {
      without_declined: 'true',
    }
  );

watchEffect(() => {
  if (assignmentsError.value?.response.status === 403) {
    router.push({
      name: 'account',
      params: { accountId: currentAccountId.value },
    });
  }
});

const assignments = computed(() => {
  return crewAssignments.value
    ? crewAssignments.value.filter(crew => crew.user_id === currentUserId.value)
    : [];
});

const { pageViewed } = useBaseEvents();

const isAcceptedCrew = computed(() => {
  return assignments.value.some(
    assignment =>
      assignment.user_id === currentUserId.value &&
      assignment.status === 'accepted'
  );
});

const isAssignedCrew = computed(() => {
  return assignments.value.some(
    assignment =>
      assignment.user_id === currentUserId.value &&
      (assignment.status === 'accepted' || assignment.status === 'sent')
  );
});

const showSelectionSection = computed(
  () => proServiceRequirements.value && proServiceRequirements.value.length > 0
);

onMounted(() => {
  window.scrollTo(0, 0);
  pageViewed();
});
</script>
<template>
  <div class="traits-page">
    <div class="traits-page__content">
      <ProServiceSelectionSection
        v-if="showSelectionSection"
        :crew-assignments="crewAssignments"
        :is-assigned-crew="isAssignedCrew"
      />
      <ProServiceSelectionUpgrades
        v-else
        :reservation-id="currentReservation.id"
      />
    </div>
    <div
      v-if="capabilities.ft_soona_staff.hasCapability || isAssignedCrew"
      class="traits-page__sidebar"
    >
      <div class="shoot-details">
        <h3 class="level-left u-body--heavy">shoot details:</h3>
        <ShootDetails />
      </div>
      <div
        v-if="capabilities.ft_soona_staff.hasCapability || isAcceptedCrew"
        class="crew-assignments"
      >
        <h3 class="level-left u-body--heavy">crew members:</h3>
        <CreateCrewAssignment
          :reservation-id="currentReservation.id"
          :reservation-start="currentReservation.start"
          :reservation-end="currentReservation.end"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.traits-page {
  display: flex;
  flex-flow: row wrap;
  gap: 1.75rem;

  &__content {
    flex: 2 1 55%;
  }

  &__sidebar {
    flex: 1 1 17rem;
  }
}
</style>

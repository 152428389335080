<template>
  <div class="columns">
    <div class="column">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'Returns',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.packagesSelectedForReturn || vm.returnablePackages.length === 0) {
        next({
          name: 'inventory',
          params: { accountId: vm.accountId },
        });
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    next(vm => {
      if (!vm.packagesSelectedForReturn || vm.returnablePackages.length === 0) {
        next({
          name: 'inventory',
          params: { accountId: vm.accountId },
        });
      }
    });
  },
  computed: {
    ...mapGetters('inventoryPackages', [
      'returnablePackages',
      'packagesSelectedForReturn',
    ]),
    ...mapGetters('account', ['accountId']),
    ...mapState({
      mode: state => state.reservation.mode,
    }),
  },
  watch: {
    mode: function (val) {
      this.$router.push({ name: val });
    },
  },
  mounted() {
    this.newReturnOptions();
  },
  methods: {
    ...mapActions('reservation', ['newReturnOptions']),
  },
};
</script>

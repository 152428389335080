<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import ListingActionUpload from '@/components/user/anytime/listing_insights/listing_actions/actions/ListingActionUpload.vue';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaSelectableAssets from '@/components/ui_library/SoonaSelectableAssets.vue';
import { useDigitalAssets } from '@/queries/digital_assets/useDigitalAssets';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  catalogItemId: {
    type: [String, Number],
    required: true,
  },
  platform: {
    type: String,
    required: true,
    validator: function (value) {
      return ['Amazon', 'Shopify'].includes(value);
    },
  },
});

const emits = defineEmits(['close', 'asset-selected']);

const filter = ref('transform existing');
const selectedAsset = ref(null);
const uploadedAssets = ref([]);
const processing = ref(false);

const filterOptions = ['transform existing', 'upload new'];

const accountId = computed(() => props.accountId);
const catalogItemId = computed(() => props.catalogItemId);
const platform = computed(() => props.platform);

const originSourceLookup = {
  Amazon: 'amazon',
  Shopify: 'shopify',
};

const {
  data: amazonDigitalAssetsData,
  isSuccess: amazonDigitalAssetsSuccess,
  error: amazonDigitalAssetsError,
} = useDigitalAssets({
  accountId,
  itemsPerPage: 9,
  filters: {
    ownership: 'customer',
    media_type: 'photo',
    visibility: 'all',
    cv_tags: ['Product on Color', 'Product on White'],
    excluded_cv_tags: ['Infographic', 'Low Quality', 'Poor Composite'],
    catalog_item_id: catalogItemId.value,
    origin_source: originSourceLookup[platform.value],
    min_side_length: 1500,
  },
});

const amazonDigitalAssets = computed(
  () => amazonDigitalAssetsData.value?.assets
);

const {
  data: soonaDigitalAssetsData,
  isSuccess: soonaDigitalAssetsSuccess,
  error: soonaDigitalAssetsError,
} = useDigitalAssets({
  accountId,
  itemsPerPage: 9,
  filters: {
    ownership: 'customer',
    media_type: 'photo',
    visibility: 'all',
    cv_tags: ['Product on Color', 'Product on White'],
    excluded_cv_tags: ['Infographic', 'Low Quality', 'Poor Composite'],
    catalog_item_id: catalogItemId.value,
    origin_source: ['c2c', 'manual_upload'],
    min_side_length: 1500,
  },
});

const soonaDigitalAssets = computed(() => soonaDigitalAssetsData.value?.assets);

const { mutate: createDigitalAsset, error: createDigitalAssetError } =
  useCreateDigitalAsset(accountId);

const priorityError = usePriorityError(
  createDigitalAssetError,
  soonaDigitalAssetsError,
  amazonDigitalAssetsError
);

const handleAssetSelection = asset => {
  selectedAsset.value = asset;
};

const handleUploadComplete = blob => {
  if (uploadedAssets.value.length > 0) {
    uploadedAssets.value.pop();
  }

  uploadedAssets.value.push(blob);
};

const handleContinue = () => {
  processing.value = true;
  if (filter.value === 'upload new') {
    let blob = uploadedAssets.value[0];
    const digitalAsset = {
      asset_type: 'original',
      file: blob.signed_id,
      title: blob.filename,
      media_type: blob.content_type.startsWith('image') ? 'photo' : 'video',
      ownership: 'customer',
      origin: 'customer',
      origin_source: 'manual_upload',
      visibility: 'all',
    };

    createDigitalAsset(digitalAsset, {
      onSuccess: data => {
        emits('asset-selected', data.id);
      },
    });
  } else {
    emits('asset-selected', selectedAsset.value);
  }
};

const noAssetsFound = computed(
  () =>
    amazonDigitalAssets.value?.length === 0 &&
    soonaDigitalAssets.value?.length === 0
);

watchEffect(() => {
  if (noAssetsFound.value) {
    filter.value = 'upload new';
  }
});
</script>

<template>
  <SoonaDialog max-width="48rem" top-affixed @close="emits('close')">
    <template #heading> select a source image </template>
    <div class="select-source-asset__wrapper">
      <SoonaFilterSingle v-model="filter" :options="filterOptions" />
      <div class="select-source-asset__content">
        <SoonaError v-if="priorityError" no-margin>
          {{ priorityError }}
        </SoonaError>
        <template v-if="filter === 'upload new'">
          <ListingActionUpload
            :account-id="accountId"
            :uploaded-files="uploadedAssets"
            @upload-complete="handleUploadComplete"
          />
        </template>
        <template v-else>
          <template
            v-if="amazonDigitalAssetsSuccess && soonaDigitalAssetsSuccess"
          >
            <template v-if="noAssetsFound">
              <div class="select-source-asset__no-results">
                <p class="u-body--heavy">
                  no good candidates for background removal found. <br /><br />
                </p>
                try uploading a high quality image with: <br /><br />
                <ul class="select-source-asset__copy-list">
                  <li>neutral background</li>
                  <li>minimal additional props</li>
                  <li>no text or overlays</li>
                </ul>
              </div>
            </template>
            <template v-else>
              <p>
                for best results, choose an asset with a plain background and
                minimal additional props
              </p>
              <p class="u-subheader--heavy">assets from {{ platform }}</p>
              <SoonaSelectableAssets
                :selected-asset="selectedAsset"
                :assets="amazonDigitalAssets"
                @asset-selected="handleAssetSelection"
              >
                <template #no-results-text>
                  none of your {{ platform }} assets are good candidates for
                  background removal.
                </template>
              </SoonaSelectableAssets>
              <p class="u-subheader--heavy">more of this product</p>
              <SoonaSelectableAssets
                :selected-asset="selectedAsset"
                :assets="soonaDigitalAssets"
                @asset-selected="handleAssetSelection"
              >
                <template #no-results-text>
                  none of the assets tagged with this product are good
                  candidates for background removal.
                </template>
              </SoonaSelectableAssets>
            </template>
          </template>
        </template>
      </div>
    </div>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @click="close">cancel</SoonaButton>
      <SoonaButton
        :loading="processing"
        :disabled="!selectedAsset && uploadedAssets.length === 0"
        @click="handleContinue"
      >
        continue
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.select-source-asset__wrapper {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}
.select-source-asset {
  align-items: flex-start;
  display: flex;

  &__content {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
  }

  &__no-results {
    color: variables.$black-default;
    background-color: variables.$gray-10;
    border-radius: 0.3125rem;
    padding: 1rem;
  }

  &__copy-list {
    padding-left: 1rem;
    list-style: disc;
  }
}
</style>

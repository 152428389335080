<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

const emits = defineEmits(['close', 'confirm']);
</script>

<template>
  <SoonaDialog @close="emits('close')">
    <template #heading>delete service</template>
    <p>are you sure you want to delete this service?</p>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        data-cypress="button-dialog-confirm"
        @on-click="emits('confirm')"
      >
        yes, delete service
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

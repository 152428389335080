<template>
  <div class="DownPaymentOrder">
    <section class="columns">
      <div class="column is-two-fifths item-container is-flex justify-center">
        <div>
          <h2
            class="reservation-summary-header has-text-centered u-body--all-caps-black"
          >
            Reservation Summary:
          </h2>
          <img v-if="reservation.reservation_type" :src="image" alt="" />
        </div>
      </div>
      <div class="column is-three-fifths item-container">
        <div v-if="reservation.reservation_type">
          <div v-if="isHeadshotsForAll" class="has-text-left">
            <h2 class="u-body--regular">
              <b>{{ reservation.reservation_type.replace(/_/g, ' ') }}</b> - 15
              minutes with our professional crew
            </h2>
          </div>
          <div v-else-if="isAnytime" class="has-text-left">
            <h2 class="u-body--regular">
              <b>real-time virtual photoshoot</b> - full session with our
              professional crew
            </h2>
          </div>
          <div v-else class="has-text-left">
            <h2 class="u-body--regular">
              <b>{{ reservation.reservation_type.replace(/_/g, ' ') }}</b> -
              full session with our professional crew
            </h2>
          </div>
        </div>
        <div>
          <h2
            class="header-md u-body--all-caps-black has-text-left has-text-centered-mobile"
          >
            Includes:
          </h2>
          <ul>
            <li
              v-for="rli in reservationLineItems"
              :key="rli.id"
              class="columns is-mobile"
            >
              <div class="column is-3">
                <img :src="rli.product.thumbnail_url" alt="" />
              </div>
              <div class="column is-8">
                <p class="u-body--regular is-size-7-mobile has-text-grey-light">
                  {{ rli.quantity > 1 ? `${rli.quantity} x ` : '' }}
                  <span v-html="rli.product.description.toLowerCase()"></span>
                </p>
                <p
                  v-if="!isHeadshotsForAll && rli.effective_percent_discount"
                  class="u-body--heavy is-size-7-mobile has-text-grey-light"
                >
                  <span
                    v-if="
                      order.payment_style ===
                      'delayed_until_72_hours_before_start'
                    "
                  >
                    <span class="new-price">
                      {{
                        toCurrency(+rli.total - +rli.effective_percent_discount)
                      }}
                    </span>
                    <s class="old-price">{{ toCurrency(+rli.total) }}</s>
                  </span>
                  <span v-else>
                    <span class="new-price">
                      {{ toCurrency(+rli.amount_collected) }}
                    </span>
                    <s class="old-price">{{ toCurrency(+rli.total) }}</s>
                  </span>
                </p>
                <p
                  v-else-if="!isHeadshotsForAll"
                  class="u-body--heavy is-size-7-mobile has-text-grey-light"
                >
                  {{ toCurrency(+rli.total) }}
                </p>
                <p
                  v-else
                  class="u-body--heavy is-size-7-mobile has-text-grey-light"
                >
                  free
                </p>
              </div>
            </li>
          </ul>
        </div>
        <section v-if="isAnytime">
          <h2
            class="header-md has-text-left has-text-centered-mobile u-body--all-caps-black"
          >
            Shipping Location:
          </h2>
          <p class="has-text-centered-mobile">soona {{ location.name }}</p>
        </section>
        <section v-else>
          <h2 class="header-md has-text-left has-text-centered-mobile">
            Studio Location:
          </h2>
          <p class="u-body--regular is-size-7-mobile has-text-centered-mobile">
            soona {{ location.name }}
          </p>
          <p class="u-body--regular is-size-7-mobile has-text-centered-mobile">
            {{ location.address1 }}
          </p>
          <p class="u-body--regular is-size-7-mobile has-text-centered-mobile">
            {{ location.city }}, {{ location.state }}
            {{ location.zip }}
          </p>
        </section>
      </div>
    </section>
    <section class="total-promo-code-container">
      <div
        v-if="order.payment_style === 'delayed_until_72_hours_before_start'"
        class="totals-container"
      >
        <p class="u-headline is-size-5-mobile">due before shoot:</p>
        <p class="u-headline is-size-5-mobile">
          {{
            Number(reservation.down_payment_order_total).toLocaleString(
              'en-US',
              {
                style: 'currency',
                currency: 'USD',
              }
            )
          }}
        </p>
      </div>
      <div class="totals-container">
        <p class="u-headline--heavy is-size-5-mobile">total down payment:</p>
        <p class="u-headline--heavy is-size-5-mobile">
          {{
            Number(order.amount_collected).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
          }}
        </p>
      </div>
      <div v-if="order.discount_code" class="promo-code-container">
        <p class="u-body--regular is-size-7-mobile">
          promo code: {{ order.discount_code }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { toCurrency } from '@/lib/currency';
import anytimeImage from '@images/anytime.png';
import headshotsForAllImage from '@images/headshots_for_all.png';
import inStudioImage from '@images/in_studio.png';
import internalImage from '@images/internal.png';
import surpriseImage from '@images/surprise.png';

const reservationTypeImageMap = {
  anytime: anytimeImage,
  headshots_for_all: headshotsForAllImage,
  in_studio: inStudioImage,
  internal: internalImage,
  surprise: surpriseImage,
};

export default {
  name: 'DownPaymentOrder',
  props: {
    order: Object,
    reservation: Object,
  },
  computed: {
    reservationLineItems() {
      return this.order.reservation_line_items;
    },
    isHeadshotsForAll() {
      return this.reservation.reservation_type === 'headshots_for_all';
    },
    isAnytime() {
      return this.reservation.reservation_type === 'anytime';
    },
    location() {
      return this.reservation.location;
    },
    image() {
      return reservationTypeImageMap[this.reservation.reservation_type];
    },
  },
  methods: { toCurrency },
};
</script>

<style lang="scss">
@use '@/variables';

.DownPaymentOrder {
  .totals-container {
    padding-right: 1.5rem;
    display: flex;
    justify-content: space-between;
  }
  .promo-code-container {
    display: flex;
    justify-content: flex-start;
    font-weight: 800;
  }
  .total-promo-code-container {
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .old-price {
    color: variables.$gray-50;
    font-weight: 400;
  }

  .new-price {
    color: variables.$friendly-red-60;
  }

  .header-md {
    margin: 1.25rem 0;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    font-weight: 800;
  }

  .reservation-summary-header {
    margin-bottom: 1.25rem;
  }

  .item-container {
    padding: 2rem;
  }
}
</style>

import * as Sentry from '@sentry/vue';
import { CometChat } from '@cometchat-pro/chat';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

export function logChatWarn(...args) {
  console.warn('[comet chat]:', ...args);
}

// we're glob importing components here so that they will be available to a component that is using the `generateComponentFilename` function
const nameFromPath = new RegExp(/^.*\/([A-Z]\w*Message)\.vue$/);
const messageModules = Object.entries(
  import.meta.glob('./messages/*Message.vue', {
    import: 'default',
    eager: true,
  })
).reduce((acc, [path, module]) => {
  const name = nameFromPath.exec(path)[1];
  acc[name] = module;
  return acc;
}, {});

export function generateComponentFilename(type) {
  // Most message types are determined by CometChat (except for custom types).
  // We use those types to figure out which component we need to render for
  // each message, avoiding an ever-growing list of if/else-if/else. Thus,
  // the way we name our chat related components matters.
  // (i.e. message.type === 'text' would use the TextMessage.vue component).
  const titleizedType = type.charAt(0).toUpperCase() + type.substring(1);
  const module = messageModules[`${titleizedType}Message`];
  if (!module) {
    logChatWarn('unknown message type', type);
    return null;
  }
  return module;
}

export const COMET_CHAT_PROVIDER_NAME = Symbol('Comet Chat Provider');

export class CometChatError extends Error {
  constructor(message) {
    const newMessage = `[comet chat]: ${message}`;
    super(newMessage);
    this.name = 'CometChatError';
    this.display = message;
  }
}

/**
 * @description take an error and optional fallback message to convert it to a
 * CometChatError. then take the exception and capture it for Sentry and return
 * it for additional use.
 *
 * @param {Error} error
 * @param {string} [fallbackMessage]
 * @returns {CometChatError}
 */
export function captureChatException(error, fallbackMessage = undefined) {
  const err = new CometChatError(error.message ?? fallbackMessage);
  console.trace(err);
  Sentry.captureException(err, {
    extra: { error },
    tags: { comet_chat: 'true' },
  });

  return err;
}

// new messages can be received through listeners or fetching. listeners cannot
// guarantee that order received is the same as the order sent. It is also
// possible that the same messages can be received through both strategies.
// this will ensure that they appear in the correct order and are not duplicated.
export function sortedAndUniq(messages) {
  return orderBy(
    uniqBy(messages, 'id'),
    ['sendAt', message => Number(message.id)],
    ['asc', 'asc']
  );
}

export async function getGroupMembers(guid) {
  const groupMemberRequest = new CometChat.GroupMembersRequestBuilder(guid)
    .setLimit(30)
    .build();

  try {
    return await groupMemberRequest.fetchNext();
  } catch (e) {
    throw captureChatException(e, 'failed to get comet chat group members');
  }
}

export async function getUnreadMessageCountForGroup(guid) {
  try {
    const data = await CometChat.getUnreadMessageCountForGroup(guid);
    const unreadCount = data[guid];
    Sentry.addBreadcrumb({
      category: 'chat',
      message: `fetched unread count for ${guid}`,
      level: 'info',
    });

    return unreadCount;
  } catch (e) {
    throw captureChatException(e, 'failed to fetch unread count');
  }
}

export async function getGroupMessages(guid) {
  const messageRequest = new CometChat.MessagesRequestBuilder()
    .setGUID(guid)
    .setLimit(100)
    .build();

  try {
    const messages = await messageRequest.fetchPrevious();

    Sentry.addBreadcrumb({
      category: 'chat',
      message: 'loaded previous messages',
      level: 'info',
    });

    return messages;
  } catch (e) {
    throw captureChatException(e, 'failed to fetch comet chat messages');
  }
}

export async function getPreviousMessages(guid, earliestId = null, limit = 30) {
  try {
    const messagesRequest = earliestId
      ? new CometChat.MessagesRequestBuilder()
          .setGUID(guid)
          .setMessageId(earliestId)
          .setLimit(limit)
          .build()
      : new CometChat.MessagesRequestBuilder()
          .setGUID(guid)
          .setLimit(limit)
          .build();
    const messages = await messagesRequest.fetchPrevious();

    Sentry.addBreadcrumb({
      category: 'chat',
      message: 'loaded previous messages',
      level: 'info',
    });

    return messages;
  } catch (e) {
    throw captureChatException(e, 'failed to fetch comet chat messages');
  }
}

export async function sendGroupMessage(guid, message) {
  const textMessage = new CometChat.TextMessage(
    guid,
    message,
    CometChat.RECEIVER_TYPE.GROUP
  );
  try {
    const sentMessage = await CometChat.sendMessage(textMessage);
    Sentry.addBreadcrumb({
      category: 'chat',
      message: 'sent new message',
      level: 'info',
    });

    return sentMessage;
  } catch (e) {
    throw captureChatException(e, 'failed to send comet chat message');
  }
}

export async function sendGroupQuickReaction(guid, emoji) {
  const customMessage = new CometChat.CustomMessage(
    guid,
    CometChat.RECEIVER_TYPE.GROUP,
    'quickReactionAdded',
    {
      emoji: emoji,
    }
  );

  try {
    const sentMessage = CometChat.sendCustomMessage(customMessage);
    Sentry.addBreadcrumb({
      category: 'chat',
      message: 'added quick thumbs up reaction',
      level: 'info',
    });
    return sentMessage;
  } catch (e) {
    throw captureChatException(e, 'failed to send comet chat message');
  }
}

export async function sendGroupMediaMessage(guid, { files }) {
  const messages = [];
  for (const file of Array.from(files)) {
    let fileType;
    if (file.type.includes('image')) {
      fileType = CometChat.MESSAGE_TYPE.IMAGE;
    } else if (file.type.includes('pdf')) {
      fileType = CometChat.MESSAGE_TYPE.FILE;
    } else if (file.type.includes('video')) {
      fileType = CometChat.MESSAGE_TYPE.VIDEO;
    }

    const mediaMessage = new CometChat.MediaMessage(
      guid,
      file,
      fileType,
      CometChat.RECEIVER_TYPE.GROUP
    );

    try {
      const sentMessage = await CometChat.sendMediaMessage(mediaMessage);
      Sentry.addBreadcrumb({
        category: 'chat',
        message: `sent media message with ${file.name}`,
        level: 'info',
      });
      messages.push(sentMessage);
    } catch (e) {
      throw captureChatException(e, 'failed to send comet chat media message');
    }
  }

  return messages;
}

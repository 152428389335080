<script setup>
import { computed } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { amazonAuthorizationRedirectUrl } from '@/lib/integrations/constants';

const emit = defineEmits(['close']);

const amazonAuthRedirectUrl = computed(() => {
  let fullURL = window.location.href;
  let baseURL = window.location.origin;
  let relativeURL = fullURL.replace(baseURL, '');
  relativeURL = relativeURL.replace('/user/anytime#', '');
  return amazonAuthorizationRedirectUrl + `?soona_redirect_to=${relativeURL}`;
});
</script>
<template>
  <SoonaDialog @close="emit('close')">
    <template #heading> time to reconnect Amazon </template>
    <template #default>
      <div class="reconnect-amazon__wrapper">
        <div class="reconnect-amazon__section-wrapper">
          <div>
            <SoonaIcon name="lock-alt" class="reconnect-amazon__icon" />
          </div>
          <div class="reconnect-amazon__copy-section">
            <p class="reconnect-amazon__title u-body--heavy">
              what does this mean?
            </p>
            <p class="reconnect-amazon__copy u-body--regular">
              for your security, Amazon requires that you periodically re-enter
              your Seller account credentials. this is a normal procedure to
              re-authorize a third party app like soona.
            </p>
          </div>
        </div>
        <div class="reconnect-amazon__section-wrapper">
          <div>
            <SoonaIcon name="stars" />
          </div>
          <div class="reconnect-amazon__copy-section">
            <p class="reconnect-amazon__title u-body--heavy">
              why is this necessary?
            </p>
            <p class="reconnect-amazon__copy u-body--regular">
              an active connection gives you the benefits of our Amazon
              integration; features like publishing, listing insights,
              competitive analysis, and syncing your product catalog.
            </p>
          </div>
        </div>
        <p class="u-body--regular">
          to learn more about integrations
          <a
            class="reconnect-amazon__link"
            href="https://support.soona.co/integrate-with-soona"
            target="_blank"
          >
            visit our help page
          </a>
        </p>
      </div>
    </template>
    <template #footer>
      <SoonaButton
        element="a"
        variation="solid-black"
        class="reconnect-amazon__btn"
        :href="amazonAuthRedirectUrl"
      >
        reconnect Amazon <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.reconnect-amazon {
  &__title {
    @include variables_fonts.u-subheader--heavy;
    color: variables.$black-default;
    margin-bottom: 0.5rem;
  }

  &__copy {
    color: variables.$black-default;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__section-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &__link {
    border-bottom: 1px solid variables.$black-default;
  }

  &__btn {
    width: 100%;
  }
}
</style>

<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed } from 'vue';
import QuantityIncrementor from '@/components/booking/QuantityIncrementor.vue';
import { toCurrency } from '@/lib/currency';

const props = defineProps({
  isOrder: {
    required: true,
    type: Boolean,
  },
  isOrderProcessing: {
    type: Boolean,
    required: true,
  },
  discountObj: {
    type: Object,
    required: false,
    default: null,
  },
  product: {
    required: true,
    type: Object,
  },
  segment: {
    type: String,
    required: false,
  },
  isRemovable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emits = defineEmits([
  'incrementProduct',
  'decrementProduct',
  'setProductCount',
]);

const product = computed(() => props.product);
const discountObj = computed(() => props.discountObj);
const isOrderProcessing = computed(() => props.isOrderProcessing);

const pricingDescription = computed(() => {
  if (props.segment) {
    return product.value.pricing_copy;
  }
  return '';
});

const productMin = computed(() => {
  return product.value.min ? product.value.min : 1;
});

const productMax = computed(() => {
  return product.value.max ? product.value.max : 10;
});

const productStep = computed(() => {
  return product.value.step ? product.value.step : 1;
});

function handleIncrement() {
  emits('incrementProduct', product.value);
}
function handleDecrement() {
  emits('decrementProduct', product.value);
}
function handleInput(newVal) {
  emits('setProductCount', product.value, newVal);
}

const productRate = computed(() => {
  return product.value.price_info?.rate;
});

const productDiscountedRate = computed(() => {
  if (product.value.price_info?.discounted_rate) {
    return product.value.price_info?.discounted_rate;
  } else if (discountObj.value?.percent_off) {
    return (
      product.value?.price_info?.rate *
      (1 - discountObj.value.percent_off / 100)
    );
  }
  return null;
});
</script>
<template>
  <hr />
  <div class="product-section__inner">
    <SoonaButton
      v-if="!isOrder && isRemovable"
      class="product-section__delete-button"
      size="medium"
      type="button"
      variation="icon-gray-outline"
      @on-click="handleInput(0)"
    >
      <SoonaIcon name="trash" size="medium" />
      <span class="u-visually-hidden">Remove</span>
    </SoonaButton>
    <div class="product-section__image">
      <img
        :src="product?.thumbnail_url"
        class="product-line-item__img"
        alt=""
      />
    </div>
    <div class="product-section__right">
      <div class="product-section__heading">
        <p class="product-line-item__name">{{ product.name }}</p>
        <div class="product-line-item__price">
          <div
            :class="{ 'product-line-item__price__old': productDiscountedRate }"
          >
            {{ toCurrency(productRate * product.quantity) }}
          </div>
          <div
            v-if="productDiscountedRate"
            class="product-line-item__price__new"
          >
            {{ toCurrency(productDiscountedRate * product.quantity) }}
          </div>
        </div>
      </div>
      <div class="product-line-item__description">
        {{ pricingDescription }}
      </div>
      <div v-if="!isOrder">
        <QuantityIncrementor
          :value="product.quantity"
          :min="productMin"
          :max="productMax"
          :step="productStep"
          :typeable="true"
          :disabled="isOrderProcessing"
          @on-increment="handleIncrement"
          @on-decrement="handleDecrement"
          @handle-input-value="handleInput"
        />
      </div>
      <slot name="product-info" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-section {
  &__image {
    grid-area: 2 / 1 / 2 / 1;
    margin-right: 0.5rem;
    width: 6.25rem;

    @media (min-width: variables.$screen-md-min) {
      grid-area: 2 / 1 / 4 / 1;
      width: 10.25rem;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: 6.25rem auto;
    grid-template-rows: auto min-content 1fr auto;

    @media (min-width: variables.$screen-md-min) {
      grid-template-columns: 10.25rem auto;
    }
  }

  &__delete-button {
    grid-area: 1 / 2 / 1 / 2;
    margin-bottom: 0.25rem;
    margin-left: auto;
  }

  &__right {
    grid-area: 2 / 2 / 2 / 2;
    padding-left: 0.75rem;
  }

  &__heading {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    padding-bottom: 1rem;
    p {
      @include variables_fonts.u-body--heavy;
    }

    .product-line-item__name {
      text-transform: lowercase;
    }

    .product-line-item__price {
      text-transform: lowercase;
      display: flex;
      gap: 0.5rem;

      &__old {
        color: variables.$gray-60;
        text-decoration: line-through;
      }

      &__new {
        @include variables_fonts.u-body--heavy;
        color: variables.$friendly-red-70;
      }
    }
  }

  &__description {
    padding-bottom: 1rem;
  }

  &__bottom {
    grid-area: 3 / 1 / 3 / 3;

    @media (min-width: variables.$screen-md-min) {
      grid-area: 3 / 2 / 3 / 2;
      padding-left: 0.75rem;
    }
  }
}
</style>

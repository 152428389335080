<script setup>
import { computed, ref, watchEffect } from 'vue';
import PackSceneBuilder from '@/components/crew/pack_builder/PackSceneBuilder.vue';
import PackSceneDetails from '@/components/crew/pack_builder/PackSceneDetails.vue';
import PackSubSection from '@/components/crew/pack_builder/PackSubSection.vue';
import SceneList from '@/components/user/anytime/scene_selector/SceneList.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const props = defineProps({
  category: {
    type: String,
    required: true,
  },
  descriptionShotList: {
    type: Array,
    default: () => [],
    required: false,
  },
  packId: {
    type: String,
    required: true,
  },
  packTagId: {
    type: Number,
    required: true,
  },
  shotList: {
    type: Array,
    required: false,
  },
});

defineEmits(['update:descriptionShotList']);

const packCategory = computed(() => props.category);
const packId = computed(() => props.packId);
const packTagId = computed(() => props.packTagId);
const shotList = computed(() => props.shotList || []);
const sceneSelector = ref(null);

// selecting a scene module
const selectedSceneId = ref(shotList.value[0]?.id);
const setSelectedSceneId = id => {
  selectedSceneId.value = id;
};
const selectedScene = computed(() =>
  shotList.value?.find(x => x.id === selectedSceneId.value)
);

// create new scene module
const sceneBuilderMode = ref(false);
const addScene = () => {
  sceneBuilderMode.value = true;
};
const closeSceneBuilder = sceneId => {
  if (sceneId) {
    setSelectedSceneId(sceneId);
  }
  sceneBuilderMode.value = false;
  window.scrollTo({ top: sceneSelector.value.offsetTop });
};
const selectedSceneNumber = computed(
  () => shotList.value?.find(x => x.id === selectedSceneId.value)?.number
);

const shotListLength = computed(() => shotList.value?.length);
const lastOrder = computed(() => shotList.value?.findLast(x => x.order)?.order);

// edit/duplicate scene module
const sceneToCopy = ref(null);
const editMode = ref(false);

watchEffect(() => {
  if (sceneBuilderMode.value === false) {
    sceneToCopy.value = null;
    editMode.value = false;
  }
});
const duplicateScene = () => {
  sceneToCopy.value = selectedScene.value;
  sceneBuilderMode.value = true;
};
const editScene = () => {
  editMode.value = true;
  sceneToCopy.value = selectedScene.value;
  sceneBuilderMode.value = true;
};
</script>

<template>
  <div class="pack-shot-list">
    <PackSubSection heading="shot list header card">
      <SoonaTextfield
        :model-value="descriptionShotList[0]"
        label="header description"
        type="text"
        name="shot-list-description-body"
        element="textarea"
        rows="4"
        @update:model-value="$emit('update:descriptionShotList', [$event])"
      />
    </PackSubSection>

    <PackSubSection heading="shot list">
      <div ref="sceneSelector" class="scene-selector">
        <div
          v-if="shotListLength === 0 && !sceneBuilderMode"
          class="scene-selector__empty"
        >
          <h3 class="u-headline--heavy">add a scene to get started</h3>
          <SoonaButton
            size="medium"
            title="create new scene"
            @on-click="addScene"
          >
            new scene
          </SoonaButton>
        </div>
        <SceneList
          v-if="!sceneBuilderMode && shotListLength > 0"
          :selected-scene-id="selectedSceneId"
          :shot-list="shotList"
          @set-selected-scene="setSelectedSceneId"
          @add-scene="addScene"
        />
        <PackSceneBuilder
          v-if="sceneBuilderMode"
          :shot-list-length="shotListLength"
          :scene-to-copy="sceneToCopy"
          :edit-mode="editMode"
          :original-scene-number="selectedSceneNumber"
          :last-scene-order-number="lastOrder"
          :pack-tag-id="packTagId"
          :pack-id="packId"
          :pack-category="packCategory"
          @close-scene-builder="closeSceneBuilder"
        />
        <div
          v-if="selectedScene && !sceneBuilderMode"
          class="scene-selector__current-scene"
        >
          <div class="scene-selector__current-scene--content">
            <PackSceneDetails
              :selected-scene="selectedScene"
              :pack-id="packId"
              @duplicate-scene="duplicateScene"
              @edit-scene="editScene"
            />
          </div>
        </div>
      </div>
    </PackSubSection>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.scene-selector {
  border: 0.0625rem solid variables.$gray-30;
  box-shadow: variables.$elevation-0;
  border-radius: 0.625rem;
  background-color: variables.$periwink-blue-10;
  padding: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  /* anchor loading indicator */
  position: relative;
  overflow: hidden;

  &__error {
    flex: 1 1 100%;
    margin-top: 0;
  }

  &__empty {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    justify-items: center;
    align-items: center;
  }

  &__current-scene {
    padding-top: 1rem;
    width: 100%;
    border-top: solid 0.0625rem variables.$gray-30;

    &--header {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }

    &--actions {
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;
      justify-content: flex-end;
    }
  }
}
</style>

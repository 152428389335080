import * as types from 'src/store/mutation-types';

const state = {
  avatar_url: undefined,
  email: undefined,
  external_user_id: undefined,
  id: undefined,
  name: undefined,
  phone: undefined,
  shopify: undefined,
  roles: [],
  profile_first_name: '',
  profile_last_name: '',
  pro_service_provider_details: '',
  allergens: '',
};

const getters = {
  userName(state) {
    return state.name;
  },
  userEmail(state) {
    return state.email;
  },
  userId(state) {
    return state.id;
  },
};

const mutations = {
  [types.SET_USER](state, user) {
    Object.assign(state, user);
  },
};

const actions = {
  loadUser({ commit }, userId) {
    commit(types.SET_USER, { id: userId });
    return this.http.get(`users/${userId}.json`).then(
      response => {
        commit(types.SET_USER, response.data);
      },
      error => {
        commit(`errors/${types.SET_ERRORS}`, error.response.data, {
          root: true,
        });
      }
    );
  },
  updateUser({ commit }, { userId, userParams }) {
    return this.http.put(`users/${userId}.json`, { user: userParams }).then(
      response => {
        commit(types.SET_USER, response.data);
      },
      error => {
        commit(`errors/${types.SET_ERRORS}`, error.response.data, {
          root: true,
        });
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { updatePassword } from '@/api/users';

/**
 */
export function useUpdatePassword() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: attributes => updatePassword(attributes),
    onSuccess: async result => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.user(result.id),
      });
    },
  });
}

import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';

/**
 *
 * @param {number | Ref<number>} locationId
 * @param {UseQueryOptions} queryOptions
 */
export function useGetVideoTypes(locationId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.locationVideoTypes(locationId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/locations/${toValue(locationId)}/products/video_types.json`,
        {
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

<template>
  <div class="info">
    <SubscriptionWideBanner v-if="canSeeTrialOffer" />
    <SurpriseShootDescription v-if="isSurprise" data-cypress="surprise-shoot" />
    <template v-else>
      <AssignmentAcceptor
        v-if="isAssignedPsp"
        class="info__assignment-acceptor info__component"
        :crew-assignments="assignments"
        :reservation="currentReservation"
        :psp-service-requirements="pspServiceRequirements"
      />
      <BookingTimeline
        v-if="authorizedToViewClientDetails"
        :reservation-id="currentReservation.id"
      />
      <div class="info__wrapper" :class="{ 'info__wrapper--open': isChatOpen }">
        <CsrChecklist
          v-if="!isProServiceProvider || isCreatedByProServiceProvider"
          class="ReservationView__checklist info__checklist"
          :reservation-id="currentReservation.id"
          :current-reservation="currentReservation"
          @checklist-item-event="onChecklistItemEvent"
        />
        <BookingDetailsCard
          ref="bookingDetailsCard"
          class="info__details"
          :reservation-id="currentReservation.id"
        />
      </div>
      <UpgradesSection
        v-if="!isProServiceProvider || isCreatedByProServiceProvider"
        :account-id="currentReservation.account_id"
        :reservation-id="currentReservation.id"
      />
    </template>
    <DateLocation
      class="info__component"
      :current-reservation="currentReservation"
      :hide-pii="!authorizedToViewClientDetails"
    />
    <BaseSubscription :account-id="account?.id">
      <template #loading />
      <template #success="{ canEnrollFastPass, canCancelFastPass }">
        <template v-if="canEnrollFastPass || canCancelFastPass">
          <EnrollConfirm class="info__component" :account="account" />
        </template>
      </template>
    </BaseSubscription>
    <ShippingProgress />

    <ReservationPackageHandlingAndReturnAddress
      :account-id="queryCurrentReservation.account_id"
      :reservation-id="reservationId"
      :show-package-handling-dialog="dialogs.packageHandling"
      :show-return-address-dialog="dialogs.returnAddress"
      @close-package-handling-dialog-only="dialogs.packageHandling = false"
      @close-return-address-dialog-only="dialogs.returnAddress = false"
      @close-return-address-open-package-handling-dialog="
        closeReturnAddressOpenPackageHandlingDialog
      "
      @open-return-address-close-package-handling-dialog="
        openReturnAddressClosePackageHandlingDialog
      "
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations, useStore } from 'vuex';
import { computed, onMounted, ref, reactive, watchEffect } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import * as types from 'src/store/mutation-types';
import SurpriseShootDescription from 'src/components/shared/SurpriseShootDescription.vue';
import DateLocation from 'src/components/user/anytime/details/DateLocation.vue';
import BookingDetailsCard from 'src/components/user/anytime/details/BookingDetailsCard.vue';
import AssignmentAcceptor from 'src/components/user/anytime/details/AssignmentAcceptor.vue';
import ShippingProgress from 'src/components/user/anytime/details/shipping_progress/ShippingProgress.vue';
import EnrollConfirm from 'src/components/user/anytime/fast_pass/EnrollConfirm.vue';
import { useGetCrewAssignments } from '@/queries/useGetCrewAssignments';
import UpgradesSection from 'src/components/user/anytime/UpgradesSection.vue';
import CsrChecklist from 'src/components/user/anytime/customer_shoot_readiness/CsrChecklist.vue';
import BookingTimeline from 'src/components/user/anytime/details/BookingTimeline.vue';
import SubscriptionWideBanner from 'src/components/ui_library/SubscriptionWideBanner.vue';
import BaseSubscription from '@/components/subscriptions/BaseSubscription.vue';
import { useReservation } from '@/queries/useReservation';
import unescape from 'lodash/unescape';
import { useCapabilities } from '@/composables/useCapabilities';
import ReservationPackageHandlingAndReturnAddress from '@/components/user/anytime/ReservationPackageHandlingAndReturnAddress.vue';
import { useMe } from '@/composables/user/useMe';
import { useFlag } from '@/composables/useFlag';
import { useAccount } from '@/composables/useAccount';
import { useIntegrations } from '@/composables/useIntegrations';
import { useReservationAuthorizedToViewClientDetails } from '@/composables/useReservationAuthorizedToViewClientDetails';

export default {
  name: 'Info',
  components: {
    DateLocation,
    SurpriseShootDescription,
    BookingDetailsCard,
    ShippingProgress,
    AssignmentAcceptor,
    EnrollConfirm,
    UpgradesSection,
    CsrChecklist,
    BookingTimeline,
    ReservationPackageHandlingAndReturnAddress,
    BaseSubscription,
    SubscriptionWideBanner,
  },
  props: ['reservationId', 'isChatOpen'],
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { currentAccountId, currentUserId, me } = useMe();
    const currentReservation = computed(
      () => store.state.reservation.currentReservation
    );
    const reservationId = computed(() => currentReservation.value.id);
    const pageTitle = computed(
      () =>
        `${route.meta.page_title} ${unescape(currentReservation.value?.name)}`
    );

    useTitle(pageTitle, { titleTemplate: '%s | soona' });
    const { pageViewed } = useBaseEvents();

    const apolloReservationDetailsSubxBannerFlag = useFlag(
      'apollo_reservation_details_subx_upsell'
    );

    const { data: queryCurrentReservation } = useReservation(reservationId);
    const { hasShopifyIntegration } = useIntegrations(currentAccountId);
    const { eligibleForPlatformSubscriptionTrial } =
      useAccount(currentAccountId);

    const authorizedToViewClientDetails =
      useReservationAuthorizedToViewClientDetails(reservationId);

    const dialogs = reactive({
      packageHandling: false,
      returnAddress: false,
    });

    const capabilitiesRef = ref([
      { capability: 'soona_staff' },
      { capability: 'view_any_client_details' },
      { capability: 'view_assigned_client_details' },
    ]);

    const capabilities = useCapabilities(capabilitiesRef);

    const { data: crewAssignments, error: assignmentsError } =
      useGetCrewAssignments(
        computed(() => currentReservation.value.id),
        {
          without_declined: 'true',
        }
      );

    watchEffect(() => {
      if (assignmentsError.value?.response?.status === 403) {
        router.push({
          name: 'account',
          params: { accountId: currentAccountId.value },
        });
      }
    });

    const assignments = computed(() => {
      return crewAssignments.value
        ? crewAssignments.value.filter(
            crew => crew.user_id == currentUserId.value
          )
        : [];
    });

    const isProServiceProvider = computed(() => {
      return me.value?.employee_type_department === 'pro_services';
    });

    const isCreatedByProServiceProvider = computed(() => {
      if (!isProServiceProvider.value) return false;
      return currentReservation.value?.account_id === currentAccountId.value;
    });

    async function onChecklistItemEvent(item) {
      if (item.slug === 'complete-shoot-details') {
        router.push(
          `/reservation/${currentReservation.value?.id}/shoot-summary`
        );
      } else if (item.slug === 'choose-package-handling') {
        dialogs.packageHandling = true;
      }
    }

    // pso & return address
    const openReturnAddressClosePackageHandlingDialog = () => {
      dialogs.packageHandling = false;
      dialogs.returnAddress = true;
    };

    const closeReturnAddressOpenPackageHandlingDialog = () => {
      dialogs.packageHandling = true;
      dialogs.returnAddress = false;
    };

    onMounted(() => {
      pageViewed();
    });

    return {
      assignments,
      authorizedToViewClientDetails,
      capabilities,
      currentAccountId,
      currentUserId,
      currentReservation,
      isCreatedByProServiceProvider,
      isProServiceProvider,
      onChecklistItemEvent,
      dialogs,
      queryCurrentReservation,
      closeReturnAddressOpenPackageHandlingDialog,
      openReturnAddressClosePackageHandlingDialog,
      apolloReservationDetailsSubxBannerFlag,
      eligibleForPlatformSubscriptionTrial,
      hasShopifyIntegration,
    };
  },
  computed: {
    ...mapGetters('currentUser', ['currentAccount']),
    ...mapGetters('reservation', ['isSurprise']),
    ...mapState({
      loadedPackages: state => state.inventoryPackages.loadedPackages,
      account: state => state.account,
      proServiceRequirements: state => state.proService.proServiceRequirements,
    }),
    pspServiceRequirements() {
      let requirements = [];
      this.assignments.forEach(assignment => {
        return this.proServiceRequirements.forEach(requirement => {
          if (assignment.crew_role === requirement.selected_tags[0].title) {
            requirements.push(requirement.selected_tags[0]);
          }
        });
      });
      return requirements;
    },
    assignment() {
      return this.assignments.find(
        crewAssignment => crewAssignment.user_id === this.currentUserId
      );
    },
    isAssignedPsp() {
      return this.isProServiceProvider && this.assignment;
    },
    canSeeTrialOffer() {
      return (
        this.apolloReservationDetailsSubxBannerFlag &&
        this.eligibleForPlatformSubscriptionTrial &&
        !this.hasShopifyIntegration
      );
    },
  },
  created() {
    this.$watch(
      () => this.reservationId,
      () => {
        if (this.reservationId) this.loadReservation(this.reservationId);
      },
      { immediate: true }
    );
    this.RESET_SHIPPING_DETAILS();
    this.loadShippingDetails({ reservationId: this.currentReservation.id });
    this.loadPackages({ reservationId: this.currentReservation.id });
    this.createSurvey();
  },
  methods: {
    ...mapActions('inventoryPackages', ['loadPackages']),
    ...mapActions('reservation', ['loadReservation', 'loadShippingDetails']),
    ...mapMutations('reservation', [types.RESET_SHIPPING_DETAILS]),
    createSurvey() {
      let properties = {
        reservationId: this.currentReservation.id,
        reservationType: this.currentReservation.reservation_type,
        location: this.currentReservation.location?.name,
        industry: this.currentReservation.industry,
        referrer: this.currentReservation.referrer,
      };

      this.currentReservation.tags?.forEach(tag => {
        properties[tag.title] = true;
      });

      this.$survey('ZZpctI5qo5Qx4Otq', {
        email: this.currentAccount.email,
        name: this.currentAccount.name,
        properties: properties,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.info {
  > *:not(:last-child) {
    margin-bottom: 2.75rem;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-flow: row wrap;

    &--open {
      display: block;
    }
  }

  &__checklist {
    flex: 1 1 65%;
  }

  &__details {
    flex: 1 1 35%;
  }

  &__assignment-acceptor {
    margin-top: 2.75rem;
  }

  &__component {
    border: 0.0625rem solid variables.$gray-30;
    box-shadow: variables.$elevation-0;
    border-radius: 0.625rem;
    padding: 1rem;
  }

  @media (min-width: variables.$screen-sm-min) {
    > *:not(:last-child) {
      margin-bottom: 3rem;
    }

    &__account-info {
      flex-basis: 50%;
      flex-grow: 1;
    }

    &__assignment-acceptor {
      flex-basis: 50%;
      align-self: stretch;
      height: auto;
      margin-top: 0;
    }

    &__component,
    &__preferred {
      padding: 1.5rem;
    }
  }

  @media (min-width: variables.$screen-md-min) {
    &__wrapper {
      flex-wrap: nowrap;
    }

    &__account-info {
      flex-basis: 40%;
    }

    &__assignment-acceptor {
      flex-basis: 60%;
    }
  }
}
</style>

<script setup>
import { computed, ref, toRef, watch } from 'vue';
import InventoryCard from './InventoryCard.vue';
import PaginatorFull from 'src/components/directory/PaginatorFull.vue';
import { useInventoryPackages } from '@/queries/inventory/useInventoryPackages';
import { keepPreviousData } from '@tanstack/vue-query';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  filteredStatus: {
    type: String,
    required: true,
  },
  showReturnSelect: Boolean,
  selectForReturn: Function,
  markedForReturn: Function,
});

const currentPage = ref(1);
const itemsPerPage = ref(10);
// go back to the first page when itemsPerPage changes
watch(itemsPerPage, () => {
  currentPage.value = 1;
});

const { data: packagesData } = useInventoryPackages(
  {
    accountId: toRef(props, 'accountId'),
    currentPage,
    itemsPerPage,
    filteredStatus: toRef(props, 'filteredStatus'),
  },
  {
    placeholderData: keepPreviousData,
  }
);

const packages = computed(() => packagesData.value?.inventoryPackages || []);

const packagesPagination = computed(() => {
  if (packagesData.value) {
    return packagesData.value.pagination;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});
</script>

<template>
  <div v-if="packagesPagination.totalCount > 0">
    <slot name="heading"></slot>
    <div v-for="inventoryPackage in packages" :key="inventoryPackage.id">
      <InventoryCard
        :inventory-package="inventoryPackage"
        :account-id="accountId"
        :show-return-select="showReturnSelect"
        :select-for-return="selectForReturn"
        :marked-for-return="markedForReturn(inventoryPackage)"
      />
    </div>
    <PaginatorFull
      v-model:page="currentPage"
      v-model:records-per-page="itemsPerPage"
      :total-pages="packagesPagination.totalPages"
      :total-count="packagesPagination.totalCount"
      :page-count="packages.length"
    />
  </div>
</template>

<script setup>
import AssetNotes from '@/components/checkout/AssetNotes.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { ref } from 'vue';
import { NOTES_ACTION } from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';

const props = defineProps({
  label: {
    type: String,
    default: 'notes',
  },
});

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId } = storeToRefs(mediaEditorStore);
const { asset } = useMediaEditorDigitalAsset(assetAccountId, assetId);

const notesDirty = ref(false);

mediaEditorStore.registerComponent(NOTES_ACTION, {
  handleSave: () => {},
  handleReset: () => {},
  warnBeforeClose: () => {
    return {
      warn: notesDirty.value,
      message: 'You have unsaved notes. Are you sure you want to leave?',
    };
  },
});
</script>

<template>
  <AssetNotes
    class="right-action-bar__notes"
    :asset="asset"
    :label="props.label"
    @is-notes-dirty="v => (notesDirty = v)"
  />
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import PromotionForm from 'src/components/user/anytime/admin/promotions/PromotionForm.vue';
import PromotionTitleAndTypeForm from 'src/components/user/anytime/admin/promotions/PromotionTitleAndTypeForm.vue';
import BannerDescriptionForm from 'src/components/user/anytime/admin/promotions/BannerDescriptionForm.vue';
import PlatformBannerDescriptionForm from 'src/components/user/anytime/admin/promotions/PlatformBannerDescriptionForm.vue';
import RealTimePreview from 'src/components/user/anytime/admin/promotions/RealTimePreview.vue';
import PromoCodeForm from 'src/components/user/anytime/admin/promotions/PromoCodeForm.vue';
import LinkForm from 'src/components/user/anytime/admin/promotions/LinkForm.vue';
import StylesForm from 'src/components/user/anytime/admin/promotions/StylesForm.vue';
import DateTimeForm from 'src/components/user/anytime/admin/promotions/DateTimeForm.vue';
import FormSubmission from 'src/components/user/anytime/admin/promotions/FormSubmission.vue';
import { useCreateOrUpdatePromotion } from 'src/composables/useCreateOrUpdatePromotion';

const router = useRouter();
const {
  formData,
  setFormData,
  setPromotionWidgetsAttributes,
  setPlatformBannerAttributes,
  setStyles,
  setPlatformBannerStyles,
  createPromotion,
  isLoading,
  errors,
} = useCreateOrUpdatePromotion();

const handleCancelClicked = () => {
  router.push('/admin/promotions');
};

const bookingBanner = ref(null);
const platformBanner = ref(null);
watchEffect(() => {
  if (formData.value.promotion_widgets_attributes !== undefined) {
    bookingBanner.value = formData.value.promotion_widgets_attributes.find(
      pw => pw['type'] === 'BookingBanner'
    );

    platformBanner.value = formData.value.promotion_widgets_attributes.find(
      pw => pw['type'] === 'PlatformBanner'
    );
  }
});
</script>

<template>
  <div class="create-promotion">
    <PromotionForm
      :page-name="`new promo`"
      :loading="isLoading"
      :loading-text="`saving`"
    >
      <PromotionTitleAndTypeForm
        :title="formData.title"
        :promo-type="formData.promo_type"
        @update:model-value="setFormData"
      />
      <PromoCodeForm
        v-if="formData.promo_type === 'discount_code'"
        @update:model-value="setFormData"
      />
      <DateTimeForm @update:model-value="setFormData" />

      <div v-if="formData.promo_type !== null">
        <div class="booking-banner">
          <RealTimePreview
            v-if="bookingBanner.enabled"
            :promotion-widget-attributes="bookingBanner"
            :promo-code="formData.promo_code"
          />
          <BannerDescriptionForm
            :booking-banner-form-enabled="bookingBanner.enabled"
            :copy-code-toggle="formData.promo_type === 'discount_code'"
            :copy-code-enabled="bookingBanner.copy_code_enabled"
            @update:model-value="setPromotionWidgetsAttributes"
          />
          <div v-if="bookingBanner.enabled">
            <LinkForm
              :include-hyperlink="true"
              :promo-type="formData.promo_type"
              :link-enabled="bookingBanner.link_enabled"
              :link-cta="bookingBanner.link_cta"
              :link-url="bookingBanner.link_url"
              :link-modal-enabled="bookingBanner.link_modal_enabled"
              :link-modal-description="bookingBanner.link_modal_description"
              :link-modal-title="bookingBanner.link_modal_title"
              :link-icon-enabled="bookingBanner.link_icon_enabled"
              :link-icon="bookingBanner.link_icon"
              @update:model-value="setPromotionWidgetsAttributes"
            />
            <StylesForm
              :current-background="bookingBanner.background_color"
              @update:model-value="setStyles"
            />
          </div>
        </div>

        <div class="platform-banner">
          <RealTimePreview
            v-if="platformBanner.enabled"
            :promotion-widget-attributes="platformBanner"
            :promo-code="formData.promo_code"
          />
          <PlatformBannerDescriptionForm
            :platform-banner-form-enabled="platformBanner.enabled"
            :copy-code-toggle="formData.promo_type === 'discount_code'"
            :copy-code-enabled="platformBanner.copy_code_enabled"
            @update:model-value="setPlatformBannerAttributes"
          />
          <div v-if="platformBanner.enabled">
            <LinkForm
              :include-hyperlink="true"
              :promo-type="formData.promo_type"
              :link-enabled="platformBanner.link_enabled"
              :link-cta="platformBanner.link_cta"
              :link-url="platformBanner.link_url"
              :link-modal-enabled="platformBanner.link_modal_enabled"
              :link-modal-description="platformBanner.link_modal_description"
              :link-modal-title="platformBanner.link_modal_title"
              :link-icon-enabled="platformBanner.link_icon_enabled"
              :link-icon="platformBanner.link_icon"
              @update:model-value="setPlatformBannerAttributes"
            />
            <StylesForm
              :current-background="platformBanner.background_color"
              @update:model-value="setPlatformBannerStyles"
            />
          </div>
        </div>
      </div>

      <FormSubmission
        :submit-button-text="`save`"
        :errors="errors"
        @submit-clicked="createPromotion"
        @cancel-clicked="handleCancelClicked"
      />
    </PromotionForm>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import MoreContentEmpty from '@/components/user/anytime/gallery/MoreContentEmpty.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  albumName: {
    required: true,
    type: String,
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const emptyIconName = computed(() => {
  switch (props.albumName) {
    case 'edits':
      return 'pen-swirl';
    case 'favorites':
      return 'heart-duotone';
    case 'staff picks':
      return 'trophy-duotone';
    default:
      return 'image-square-duotone';
  }
});

const emptyMessage = computed(() => {
  switch (props.albumName) {
    case 'edits':
      return 'your pro edits will appear here!';
    case 'favorites':
      return 'favorite some content!';
    case 'staff picks':
      return 'your expert picks will appear here!';
    default:
      return 'your content will appear here!';
  }
});

const trackClick = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'empty album',
    linkLabel: 'build a shoot',
    linkHref: '/booking/new',
  });
};
</script>
<template>
  <MoreContentEmpty>
    <template #icon>
      <SoonaIcon :name="emptyIconName" />
    </template>
    <template #message>
      {{ emptyMessage }}
    </template>
    <template v-if="albumName === 'edits'" #cta>
      <SoonaButton
        element="router-link"
        to="/booking/new"
        variation="solid-black"
        @on-click="trackClick"
      >
        build a shoot
      </SoonaButton>
    </template>
  </MoreContentEmpty>
</template>

<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  removable: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'large',
    validator: function (value) {
      return ['large', 'medium'].includes(value);
    },
  },
  to: {
    type: Object,
    required: false,
  },
  variation: {
    type: String,
    default: 'white',
    validator: function (value) {
      return ['white', 'gray', 'black'].includes(value);
    },
  },
  imageSrc: {
    type: String,
    required: false,
  },
  imageAlt: {
    type: String,
    required: false,
  },
  soonaIcon: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['remove']);

const remove = () => {
  emit('remove');
};

const classNames = computed(() => {
  let classes = `soona-chip soona-chip--${props.variation} soona-chip--${props.size}`;

  if (props.removable) {
    classes += ' soona-chip--removable';
  }
  if (props.to) {
    classes += ' soona-chip--link';
  }
  return classes;
});
</script>

<template>
  <div :class="classNames">
    <component
      :is="to ? 'router-link' : 'div'"
      :to="to ? to : undefined"
      class="soona-chip__content"
      :class="to ? 'soona-chip__link' : ''"
    >
      <template v-if="imageSrc && imageAlt">
        <img class="soona-chip__image-img" :src="imageSrc" :alt="imageAlt" />
        <span class="soona-chip__image-text">
          <slot name="default" />
        </span>
      </template>
      <template v-else-if="soonaIcon">
        <SoonaIcon :name="soonaIcon" size="medium" />
        <span class="soona-chip__icon-text">
          <slot name="default" />
        </span>
      </template>
      <slot v-else name="default" />
    </component>

    <SoonaButton
      v-if="removable"
      class="soona-chip__remove-button"
      variation="icon-plain-gray"
      size="small"
      type="button"
      aria-label="remove"
      @click="remove"
    >
      <SoonaIcon name="xmark" size="small" />
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-chip {
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 3.125rem;
  max-width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    height: 100%;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 3.125rem;
  }

  &__image-img {
    position: absolute;
    border-radius: 50%;
    display: block;
    left: 0;
    object-fit: cover;
    /* clip any alt text */
    overflow: hidden;
  }

  &__image-text,
  &__icon-text {
    outline-offset: -0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12.5rem;
  }

  &--removable {
    > .soona-chip__content {
      border-radius: 3.125rem 0 0 3.125rem;
    }
  }

  &--large {
    @include variables_fonts.u-body--heavy;

    > .soona-chip__content {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    & .soona-chip__image-img {
      width: 2.5rem;
      height: 2.5rem;
    }

    & .soona-chip__image-text {
      margin-left: 2.375rem;
    }

    & .soona-chip__icon-text {
      margin-left: 0.3125rem;
    }

    & .soona-chip__remove-button {
      padding-top: 0.6875rem !important;
      padding-bottom: 0.6875rem !important;
    }
  }

  &--medium {
    @include variables_fonts.u-label--heavy;

    > .soona-chip__content {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }

    & .soona-chip__image-img {
      width: 1.625rem;
      height: 1.625rem;
    }

    & .soona-chip__image-text {
      margin-left: 1.5rem;
    }

    & .soona-chip__icon-text {
      margin-left: 0.1875rem;
    }

    & .soona-chip__remove-button {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
  }

  &--white {
    background-color: variables.$white-default;
    color: variables.$black-default;
  }

  &--gray {
    background-color: variables.$gray-20;
    color: variables.$black-default;
  }

  &--black {
    border-color: variables.$black-default;
    background-color: variables.$black-default;
    color: variables.$white-default;
  }

  &__link {
    &:focus-visible {
      outline: solid 0.125rem variables.$periwink-blue-60;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__remove-button {
    border-radius: 0 3.125rem 3.125rem 0 !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
</style>

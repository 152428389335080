import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { removeDraft } from '@/api/reservations';
import * as Sentry from '@sentry/vue';
import { queryKeys } from '@/queries/query-keys';

export function useRemoveDraft(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => removeDraft(unref(reservationId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
    onError: error => {
      Sentry.captureException(new Error('failed to remove draft'), {
        extra: error,
      });
    },
  });
}

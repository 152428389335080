<script setup>
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

const openHubSpotChat = () => {
  window.HubSpotConversations?.widget?.load();
};
</script>

<template>
  <button
    class="u-button-reset u-small--heavy hubspot-chat-button"
    type="button"
    @click="openHubSpotChat"
  >
    Chat
    <SoonaIcon name="message-circle-lines-alt" />
  </button>
</template>

<style lang="scss" scoped>
@use '@/variables';

.hubspot-chat-button {
  align-items: center;
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-right: 0;
  border-radius: 0;
  display: flex;
  letter-spacing: 1.5px;
  padding: 0.5rem 1rem;
  position: relative;
  text-transform: uppercase;
  transition: background-color 0.1s ease-out;

  > svg {
    margin-left: 0.5rem;
  }

  &:first-child {
    border-top-left-radius: 0.625rem;
  }

  &:hover,
  &:focus-visible {
    background-color: variables.$gray-20;
  }

  @media (min-width: variables.$screen-md-min) {
    border-top-left-radius: 0.625rem;
  }
}
</style>

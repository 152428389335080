<template>
  <button class="btn-back" @click="click(route)">Back</button>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    route: String,
  },
  methods: {
    click: function (route) {
      if (route) {
        this.$router.push('/' + route);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-back {
  border-radius: none;
  background: url(~images/back.svg) center center no-repeat;
  background-size: 50px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  text-indent: -999em;
}
</style>

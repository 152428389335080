import { computed } from 'vue';
import { useRuleResults } from './useRuleResults';

export const useCompetitorBehindResults = (listing, competitor) => {
  const {
    listingImageQualityRuleResults,
    listingGalleryCountRuleResult,
    listingVisualMixRuleResults,
    competitorImageQualityRuleResults,
    competitorGalleryCountRuleResult,
    competitorVisualMixRuleResults,
  } = useRuleResults(listing, competitor);

  const failingResults = computed(() => {
    let result = [];

    // image quality
    for (let lrr of listingImageQualityRuleResults.value) {
      let crr = competitorImageQualityRuleResults.value.find(
        x => x.rule === lrr.rule
      );
      if (lrr.score !== 1 && (crr.score === 1 || lrr.score < crr.score)) {
        result.push({
          rule: lrr.rule,
          ruleDescription: lrr.rule_description,
          listing: lrr.images.length,
          competitor: crr.images.length,
          ruleGroup: lrr.rule_group,
        });
      }
    }

    // gallery count
    if (
      listingGalleryCountRuleResult.value.images.length <
      competitorGalleryCountRuleResult.value.images.length
    ) {
      result.push({
        rule: listingGalleryCountRuleResult.value.rule,
        ruleDescription: listingGalleryCountRuleResult.value.rule_description,
        listing: listingGalleryCountRuleResult.value.images.length,
        competitor: competitorGalleryCountRuleResult.value.images.length,
        ruleGroup: listingGalleryCountRuleResult.value.rule_group,
      });
    }

    // visual mix
    for (let lrr of listingVisualMixRuleResults.value) {
      let crr = competitorVisualMixRuleResults.value.find(
        x => x.rule === lrr.rule
      );
      if (
        (lrr.score !== 1 && crr.score === 1) ||
        lrr.images.length < crr.images.length
      ) {
        result.push({
          rule: lrr.rule,
          ruleDescription: lrr.rule_description,
          listing: lrr.images.length,
          competitor: crr.images.length,
          ruleGroup: lrr.rule_group,
        });
      }
    }
    return result;
  });

  return {
    failingResults,
  };
};

<script setup>
import { computed, onMounted } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import FeatureList from '@/components/subscriptions/FeatureList.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import StepLayout from '@/components/modal-payment-flows/subscriptions/StepLayout.vue';

import mainImage from '@images/subscriptions/30_percent@2x.png';
import footerImage from '@images/subscriptions/modal_right_side_bottom@2x.png';

const props = defineProps({
  isLoading: {
    default: false,
    type: Boolean,
  },
  badgeText: {
    required: true,
    type: String,
  },
  selectedTier: {
    default: () => ({}),
    type: Object,
  },
  headingId: {
    default: 'subscriptions-dialog-label',
    type: String,
  },
  context: {
    default: null,
    type: String,
  },
  subContext: {
    default: null,
    type: String,
  },
});

const emits = defineEmits(['close', 'next', 'reset-tier']);

const { linkClicked } = useBaseEvents();

const isLoading = computed(() => props.isLoading);
const badgeText = computed(() => props.badgeText);
const tierIcon = computed(() => props.selectedTier?.icon_name);
const tierSlug = computed(() => props.selectedTier?.slug);
const headingCopy = computed(() => {
  return `try soona basic free`;
});
const subHeadingCopy = computed(() => {
  return 'trial perks are ready for immediate access.';
});

const tierFeatureList = [
  {
    details: 'create 30% more content with AI Studio',
    is_checked: true,
  },
  {
    details: 'adjust your assets for your platforms',
    is_checked: true,
  },
  {
    details: 'improve sales by 25% on Amazon with Listing Insights',
    is_checked: true,
  },
  {
    details: 'improve conversion rate by 27% on Shopify with Listing Insights',
    is_checked: true,
  },
  {
    details: 'reduce returns by 12% with AI powered recommendations',
    is_checked: true,
  },
];

onMounted(() => {
  if (props.selectedTier && props.selectedTier.slug !== 'tier-one')
    emits('reset-tier');
});

const handleContinueBtnClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'start my free trial',
    linkHref: null,
  });

  if (props.hasActiveSubscription) emits('next', 'checkout');
  else emits('next', 'billing-cycle');
};

const handleAllPlansBtnClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'see all plans',
    linkHref: null,
  });

  emits('next', 'pricing');
};
</script>

<template>
  <StepLayout
    class="platform-trial-landing-step"
    :heading-id="headingId"
    :show-back-button="false"
    :tier-icon="tierIcon"
    :tier-slug="tierSlug"
    :tier-title="badgeText"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template #heading>{{ headingCopy }}</template>
    <template #subheading>{{ subHeadingCopy }}</template>
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <div class="platform-trial-landing-step__features">
      <p class="u-badge--small">to test the platform:</p>
      <FeatureList :feature-list="tierFeatureList" />
    </div>
    <div class="platform-trial-landing-step__action-btns">
      <SoonaButton variation="solid-black" @on-click="handleContinueBtnClick">
        start my free trial
      </SoonaButton>
      <SoonaButton
        variation="secondary-black"
        @on-click="handleAllPlansBtnClick"
      >
        see all plans
      </SoonaButton>
    </div>
    <template #main-image>
      <img
        class="platform-trial-landing-step__star-left"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img
        class="platform-trial-landing-step__star-right"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img
        class="platform-trial-landing-step__main-image"
        :src="mainImage"
        alt=""
      />
      <img
        class="platform-trial-landing-step__footer-image"
        :src="footerImage"
        alt=""
      />
    </template>
  </StepLayout>
</template>

<style lang="scss">
@use '@/variables';

.platform-trial-landing-step {
  & .step-layout__right-top {
    flex-direction: column;
    justify-content: flex-end;
    padding: 0.8125rem;
    gap: 3.75rem;

    img {
      height: auto;

      &.platform-trial-landing-step__main-image {
        max-width: 14.75rem;
      }
    }
  }

  &__action-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: auto;
    padding-top: 2rem;
  }

  &__star-left {
    position: absolute;
    max-width: 4.1875rem;
    max-height: 4.1875rem;
    top: 4rem;
    left: 1.5625rem;
  }

  &__star-right {
    position: absolute;
    max-width: 5.625rem;
    max-height: 5.625rem;
    top: 1.4375rem;
    right: 1.4375rem;
  }

  &__features {
    p {
      color: variables.$gray-70;
      padding-bottom: 0.8675rem;
    }
  }
}
</style>

<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { useLocations } from 'src/queries/useLocation';
import ShippingSummaryTable from './ShippingSummaryTable.vue';
import TransferSummary from './TransferSummary.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaTab from 'src/components/ui_library/SoonaTab.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import { refDebounced, useStorage, useTitle } from '@vueuse/core';

const route = useRoute();
useTitle(computed(() => `${route.meta.page_title} | soona`));

const sessionStorage = useStorage(
  'shipping-session-storage',
  { query: '' },
  window.sessionStorage
);

// LOCATION
const transferView = ref(false);

const { data: locationsData } = useLocations();

const locations = computed(
  () =>
    locationsData.value
      ?.filter(x => x.slug !== 'unassigned')
      .sort((a, b) => a.name.localeCompare(b.name)) || []
);

const location = ref(undefined);

watchEffect(() => {
  if (locations.value.length > 0) {
    if (route.query.location && !route.query.transfer) {
      location.value = locations.value.find(
        x => x.id === parseInt(route.query.location)
      );
      transferView.value = false;
    }
    if (!route.query.location && !route.query.transfer) {
      location.value = locations.value.find(x => x.slug === 'dfs-mpls');
    }
    if (!route.query.location && route.query.transfer) {
      transferView.value = true;
      location.value = undefined;
    }
  }
});

const clickedLocation = selectedLocation => {
  location.value = selectedLocation;
  transferView.value = false;
};

const isSelectedLocation = selectedLocation => {
  if (locations.value.length > 0) {
    return location.value?.id === selectedLocation;
  }
  return false;
};

const toggleTransferView = () => {
  location.value = undefined;
  transferView.value = true;
};

// FILTERS
const refNumber = computed({
  get() {
    return sessionStorage.value.query;
  },
  set(value) {
    sessionStorage.value = { ...sessionStorage.value, query: value };
  },
});
const debouncedRefNumber = refDebounced(refNumber, 200);
const filterByReturnLabel = ref(false);

const selectedShippingStatuses = ref([]);
const shippingStatuses = [
  { label: 'pre transit', value: 'PRE_TRANSIT' },
  { label: 'transit', value: 'TRANSIT' },
  { label: 'delivered', value: 'DELIVERED' },
  { label: 'returned', value: 'RETURNED' },
  { label: 'failure', value: 'FAILURE' },
];

const selectedPackageStatuses = ref([]);
const packageStatuses = [
  { label: 'on the way', value: 'on_the_way' },
  { label: 'in studio', value: 'in_studio' },
  { label: 'stored', value: 'stored' },
  { label: 'returned', value: 'returned' },
  { label: 'discarded', value: 'discarded' },
];

const selectedRequiredActions = ref([]);
const requiredActions = [
  { label: 'needs discard', value: 'needs_discard' },
  { label: 'needs return', value: 'needs_return' },
  { label: 'activate membership', value: 'activate_membership' },
  { label: 'awaiting return preference', value: 'awaiting_return_preference' },
  { label: 'needs storage', value: 'needs_storage' },
  { label: 'on hold for discard', value: 'on_hold_for_discard' },
];

const inputPlaceholderText = computed(() => {
  if (transferView.value) {
    return 'search number';
  }
  return 'search number, ID, name, or email';
});
</script>

<template>
  <div class="shipping-summary__wrapper">
    <h2 class="shipping-summary__title u-headline--heavy">shipping summary</h2>
    <div class="shipping-summary__header">
      <div class="shipping-summary__locations">
        <SoonaTab
          copy="transfers"
          :to="{ query: { transfer: true } }"
          :is-selected="transferView === true"
          @click="toggleTransferView"
        />
        <SoonaTab
          v-for="studio in locations"
          :key="studio.id"
          :copy="studio.name"
          :to="{ query: { location: studio.id } }"
          :is-selected="isSelectedLocation(studio.id)"
          @click="clickedLocation(studio)"
        />
      </div>

      <div
        class="shipping-summary__filters"
        :class="{ 'shipping-summary__filters--transfer': transferView }"
      >
        <SoonaTextfield
          v-model="refNumber"
          :class="{
            'shipping-summary__filters-transfer-input': transferView,
          }"
          :placeholder="inputPlaceholderText"
          type="search"
          label="search shipments"
          hide-label
        >
          <template #icon-left>
            <SoonaIcon name="search" size="medium" />
          </template>
        </SoonaTextfield>
        <SoonaToggle
          v-if="!transferView"
          v-model="filterByReturnLabel"
          class="return-label"
          type="switch"
          label="return label present"
        />
        <template v-if="!transferView">
          <SoonaSelect
            v-model:model-values="selectedShippingStatuses"
            :options="shippingStatuses"
            :multi="true"
            :selected-options-checkbox="true"
            :clearable-btn="selectedShippingStatuses.length > 0"
            placeholder="all"
          >
            <template #label> shipping status </template>
          </SoonaSelect>
          <SoonaSelect
            v-model:model-values="selectedPackageStatuses"
            :options="packageStatuses"
            :multi="true"
            :selected-options-checkbox="true"
            :clearable-btn="selectedPackageStatuses.length > 0"
            placeholder="all"
          >
            <template #label> package status </template>
          </SoonaSelect>
          <SoonaSelect
            v-model:model-values="selectedRequiredActions"
            :options="requiredActions"
            :multi="true"
            :selected-options-checkbox="true"
            :clearable-btn="selectedRequiredActions.length > 0"
            placeholder="all"
          >
            <template #label> action required </template>
          </SoonaSelect>
        </template>
      </div>
    </div>

    <div class="shipping-summary__content">
      <ShippingSummaryTable
        v-if="!transferView && location"
        :search-value="debouncedRefNumber"
        :location="location"
        :return-label="filterByReturnLabel"
        :shipping-status="selectedShippingStatuses"
        :package-status="selectedPackageStatuses"
        :required-action="selectedRequiredActions"
      />
      <TransferSummary v-else-if="transferView" :search-value="refNumber" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.shipping-summary {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    container-type: inline-size;
  }
  &__title {
    margin-bottom: 1.5rem;
  }
  &__header {
    margin-bottom: 1rem;
    width: 100%;
  }
  &__locations {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: auto;
    margin-bottom: 1rem;
  }
  &__filters {
    .soona-textfield {
      padding-bottom: 0;
    }
  }

  &__content {
    overflow-x: auto;
    width: 100%;
  }

  // desktop styles
  @container (min-width: 60rem) {
    &__title {
      font-size: 2rem;
    }
    &__locations {
      justify-content: center;
      overflow: auto;
    }
    &__filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 1rem;
      * {
        flex: 1 1 auto;
      }
      &--transfer {
        margin: 0 auto;
        max-width: 31.25rem;
      }
      &-left {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 1rem;
        flex: 1 1 40%;
        margin-top: 1.6875rem;

        .soona-textfield {
          flex-grow: 4;
        }
      }
      &-right {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 1rem;
        flex: 1 1 60%;
        * {
          flex: 1 1 33%;
        }
      }
    }
  }
}
</style>

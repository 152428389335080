import { computed } from 'vue';
import { useAccount } from '@/composables/useAccount';
import { useSubscription } from '@/composables/subscription/useSubscription';
import { useTiers } from '@/composables/subscription/useTiers';

export function useSuggestedSubscription(accountId) {
  const { account } = useAccount(accountId);

  const totalCompetitors = computed(
    () => account.value?.total_competitors_count ?? null
  );

  const {
    hasLiveSubscription,
    hasTierOneSubscription,
    hasTierTwoSubscription,
    hasTierThreeSubscription,
  } = useSubscription(accountId);

  const { proTier } = useTiers();

  const suggestedListingsTier = computed(() => proTier.value);

  const suggestedCompetitorsTier = computed(() => proTier.value);

  const competitorQuotaReached = computed(
    () => totalCompetitors.value >= account.value?.max_competitors_count
  );

  const hasActiveStoreIntegration = computed(() => {
    return account.value?.has_active_store_integration ?? false;
  });

  return {
    hasActiveStoreIntegration,
    hasLiveSubscription,
    hasTierOneSubscription,
    hasTierTwoSubscription,
    hasTierThreeSubscription,
    suggestedListingsTier,
    suggestedCompetitorsTier,
    competitorQuotaReached,
  };
}

import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { showTag } from '@/api/tags';
import { unref } from 'vue';

/**
 *
 * @param {number} tagId
 */
export function useShowTag(tagId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.tag(tagId),
    queryFn: ({ signal }) => showTag(unref(tagId), signal),
    ...queryOptions,
  });
}

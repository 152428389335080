import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {string | number} collectionId
 * @param {Object} [params]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {number} [params.activeAssetId]
 * @param {UseQueryOptions} [queryOptions]
 */
export function useFavoritesCollectionDigitalAssets(
  collectionId,
  { itemsPerPage, currentPage, activeAssetId, visibility } = {},
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.collectionAssets(collectionId, {
      itemsPerPage,
      currentPage,
      activeAssetId,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (currentPage) params['page'] = toValue(currentPage);
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);
      if (activeAssetId) params['active_asset_id'] = toValue(activeAssetId);
      if (visibility) params['visibility'] = toValue(visibility);

      const response = await http.get(
        `/collections/${toValue(collectionId)}/favorites_collection_digital_assets`,
        {
          params,
          signal,
        }
      );

      return {
        pagination: mapPaginationHeaders(response.headers),
        assets: response.data?.favorites_collection_digital_assets || [],
      };
    },
    ...queryOptions,
  });
}

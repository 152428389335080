<script setup>
import { computed } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import {
  BubbletapePink10,
  Tangerine20,
  FriendlyRed30,
  PeriwinkBlue30,
} from 'src/variables.module.scss';

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});
const product = computed(() => props.product);
const isArchived = computed(() => product.value.status === 'archived');
const isDraft = computed(() => product.value.status === 'draft');
const isProp = computed(() => product.value.type === 'CatalogProp');
const isLarge = computed(() => product.value.is_larger_than_twenty_inches);
const needsAssembly = computed(() => product.value.needs_assembly);
const needsSteaming = computed(() => product.value.needs_steaming);

const activeFlags = computed(() => {
  return [
    {
      active: isArchived.value,
      color: FriendlyRed30,
      title: 'archived',
    },
    {
      active: isDraft.value,
      color: PeriwinkBlue30,
      title: 'draft',
    },
    {
      active: isProp.value,
      color: Tangerine20,
      title: 'prop',
    },
    {
      active: isLarge.value,
      color: BubbletapePink10,
      title: 'large',
    },
    {
      active: needsAssembly.value,
      color: BubbletapePink10,
      title: 'assembly',
    },
    {
      active: needsSteaming.value,
      color: BubbletapePink10,
      title: 'steaming',
    },
  ].filter(flag => flag.active);
});
</script>
<template>
  <div class="product-flags">
    <SoonaFlag
      v-for="flag in activeFlags"
      :key="flag"
      :background-color="flag.color"
      :title="flag.title"
    />
  </div>
</template>
<style lang="scss" scoped>
.product-flags {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}
</style>

<script setup>
import PromptCard from '../PromptCard.vue';
import { useAiProducer } from '../Composables/useAiProducer';
import LoadingLogo from '@/components/ai_assistant/LoadingLogo.vue';
import SoonaTransition from '@/components/ui_library/SoonaTransition.vue';

const { isCreatingThread } = useAiProducer();
</script>

<template>
  <div class="header">
    <div class="header__title">
      <LoadingLogo />

      <h2 class="u-display--heavy">let's create something new</h2>

      <p>start with a common project below or use your own prompt to begin</p>
    </div>

    <div class="header__prompt-cards">
      <SoonaTransition name="fade-down" appear :appear-delay="0">
        <PromptCard
          title="I want to improve my Shopify PDP or PLP imagery"
          caption="PDP enhancement"
          :disabled="isCreatingThread"
        />
      </SoonaTransition>
      <SoonaTransition name="fade-down" appear :appear-delay="50">
        <PromptCard
          title="I want to launch new products on Amazon"
          caption="product launch"
          :disabled="isCreatingThread"
        />
      </SoonaTransition>
      <SoonaTransition name="fade-down" :appear-delay="100">
        <PromptCard
          title="I need more ads for my Meta or social campaigns"
          caption="social ads"
          :disabled="isCreatingThread"
        />
      </SoonaTransition>
      <SoonaTransition name="fade-down" :appear-delay="150">
        <PromptCard
          title="I need to book a shoot with a model"
          caption="model shoot"
          :disabled="isCreatingThread"
        />
      </SoonaTransition>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.header {
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem 10rem 1rem;

  &__title {
    text-align: center;

    h2 {
      width: fit-content;
      color: transparent;
      font-size: 1.125rem;
      margin: 0.5rem auto 0 auto;
      background: linear-gradient(
        to right,
        variables.$periwink-blue-70,
        variables.$roses-60,
        variables.$periwink-blue-70
      );
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200%;

      animation: shimmer 5s linear infinite;
    }

    p {
      color: variables.$gray-70;
    }

    @keyframes shimmer {
      0% {
        background-position: 0%;
      }
      100% {
        background-position: -200%;
      }
    }
  }

  &__prompt-cards {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  @media (min-width: variables.$screen-xs-min) {
    &__title {
      text-align: left;

      h2 {
        margin: unset;
        font-size: 2.875rem;
      }
    }

    &__prompt-cards {
      gap: 1rem;
    }
  }

  @media (min-width: variables.$screen-lg-min) {
    padding: 10rem 0 12rem 0;
  }
}
</style>

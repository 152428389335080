<script setup>
import { ref, computed } from 'vue';
import { useGetVideoTypes } from 'src/queries/products/useGetVideoTypes';
import SoonaUploadForm from '@/components/uploader/SoonaUploadForm.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  isMultiple: {
    type: Boolean,
    required: true,
  },
  activeUploads: {
    type: Array,
    required: true,
  },
  accept: {
    type: String,
    required: true,
  },
  locationId: {
    type: Number,
    required: true,
  },
  uploadedFiles: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['handleDrop', 'handleUpload', 'close', 'addContent']);
const errorText = ref('');
const selectedContentType = ref(null);
const locationId = computed(() => props.locationId);
const uploadedFiles = computed(() => props.uploadedFiles);
const { data: videoTypes, isLoading } = useGetVideoTypes(locationId);

const videoTypesOptions = computed(() => {
  if (isLoading.value) {
    return [];
  }
  return videoTypes.value.reduce((acc, cur) => {
    if (cur.content_category !== 'default') {
      return [
        ...acc,
        {
          label: cur.title,
          value: cur.content_category,
        },
      ];
    }
    return acc;
  }, []);
});

function closeModal() {
  emit('close');
}

function addContent() {
  errorText.value = '';
  if (!selectedContentType.value) {
    errorText.value = 'must select a content type';
    return;
  }

  if (uploadedFiles.value.length === 0) {
    errorText.value = 'must upload files';
    return;
  }

  emit(
    'addContent',
    videoTypes.value.find(
      type => type.content_category === selectedContentType.value
    )
  );
}

function handleUpload(e) {
  emit('handleUpload', e);
}

function handleDrop(e) {
  emit('handleDrop', e);
}
</script>

<template>
  <SoonaDialog @close="closeModal">
    <template #heading>bonus content</template>
    <div class="bonus-content">
      <p>
        one type of content can be uploaded at a time. upload multiple batches
        to apply different content types.
      </p>
      <SoonaSelect
        v-model:model-value="selectedContentType"
        :disabled="isLoading"
        :options="videoTypesOptions"
        :placeholder="isLoading ? 'loading content types' : 'select type'"
      >
        <template #label>content type</template>
      </SoonaSelect>
      <SoonaError v-if="errorText" no-margin>
        {{ errorText }}
      </SoonaError>
      <SoonaUploadForm
        :accept="accept"
        :active-uploads="activeUploads"
        :is-multiple="isMultiple"
        @drop="handleDrop"
        @upload="handleUpload"
      >
        <template #content-bottom>
          <ul v-if="uploadedFiles" class="uploaded-files">
            <li v-for="file in uploadedFiles" :key="file.key">
              <img
                v-if="file.content_type.startsWith('image')"
                :src="`/rails/active_storage/blobs/${file.signed_id}/${file.filename}`"
                class="thumbnail-img"
              />
              {{ file.filename }}
            </li>
          </ul>
        </template>
      </SoonaUploadForm>
    </div>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @click="close">cancel</SoonaButton>
      <SoonaButton @on-click="addContent">add</SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.bonus-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.uploaded-files {
  list-style: disc;
}

.thumbnail-img {
  display: block;
  height: 2rem;
  width: 2rem;
  border-radius: 0.3125rem;
  object-fit: cover;
}
</style>

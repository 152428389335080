<script setup>
import { computed, ref } from 'vue';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaDropdownMenuItemCheckbox from 'src/components/ui_library/SoonaDropdownMenuItemCheckbox.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import ReturnAddressModal from './ReturnAddressModal.vue';
import { useAddresses } from 'src/queries/useAddresses';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  modelValue: {
    type: Number,
    default: undefined,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  packageToUpdate: {
    type: Number,
    required: false,
  },
});
defineEmits(['update:model-value']);

const newAddressModalOpen = ref(false);

function closeModal() {
  newAddressModalOpen.value = false;
}
function openModal() {
  newAddressModalOpen.value = true;
}

const { data, isSuccess, error } = useAddresses(
  computed(() => props.accountId)
);

const defaultPresent = computed(() => !!data.value?.default);
const addresses = computed(() => data.value?.addresses ?? []);

const isSelectedAddress = a => a.id === props.modelValue;

const displayText = computed(() => {
  const address = addresses.value.find(isSelectedAddress);

  if (address) {
    return `${address.address_1} ${address.address_2}`.trim();
  }
  return 'select return address';
});
</script>

<template>
  <SoonaDropdownMenu
    v-bind="$attrs"
    :disabled="disabled || !isSuccess"
    :copy="displayText"
    variation="secondary-gray"
    size="medium"
  >
    <template #default="{ clickCapture, keydown, mouseover }">
      <SoonaDropdownMenuItemCheckbox
        v-for="address in addresses"
        :key="address.id"
        role="menuitemradio"
        :checked="isSelectedAddress(address)"
        class="address-menuitem"
        @click="$emit('update:model-value', address.id)"
        @click.capture="clickCapture"
        @keydown="keydown"
        @mouseover="mouseover"
      >
        <span class="u-body--heavy">{{ address.name }}</span>
        <br />
        <span class="u-label--regular">
          {{ address.address_1 }}
          {{ address.address_2 }}
          {{ address.city }},
          {{ address.state }}
          {{ address.postal_code }}
        </span>
      </SoonaDropdownMenuItemCheckbox>
      <SoonaDropdownMenuItem v-if="addresses.length > 0">
        <div role="separator" />
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem>
        <button
          role="menuitem"
          class="u-body--heavy"
          @click="openModal"
          @click.capture="clickCapture"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          add new address
        </button>
      </SoonaDropdownMenuItem>
    </template>
  </SoonaDropdownMenu>
  <SoonaError v-if="error">
    {{ error.message }}
  </SoonaError>
  <ReturnAddressModal
    v-if="newAddressModalOpen"
    :account-id="accountId"
    :is-forced-default="!defaultPresent"
    :package-to-update="packageToUpdate"
    :is-new-address="true"
    @cancel="closeModal"
    @success="closeModal"
  />
</template>

<style lang="scss" scoped>
.address-menuitem {
  text-transform: none;

  span {
    margin: 0;
    display: inline;
  }
}
</style>

<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import { onKeyStroke, useTimeoutFn, useMediaQuery } from '@vueuse/core';
import { useLeftRightArrowKeys } from '@/composables/useLeftRightArrowKeys';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useRoute } from 'vue-router';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import uniqueId from 'lodash/uniqueId';
import { useDialogContext } from '@/composables/useDialog';

const props = defineProps({
  contentData: {
    type: Object,
  },
  subContext: {
    type: String,
  },
});

const emits = defineEmits(['close']);

const { linkClicked } = useBaseEvents();
const route = useRoute();

const modalRef = ref(null);

const id = uniqueId('dashboard-dialog-');
const { checkAllowEscapeClose } = useDialogContext({ id });

const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');
const { start: beginClose, isPending: isClosing } = useTimeoutFn(
  () => {
    emits('close');
  },
  prefersReducedMotion.value ? 0 : 150,
  { immediate: false }
);

useFocusTrap(modalRef, { immediate: true });

const activeImage = ref(null);

const contentData = computed(() => props.contentData);

const imageList = computed(() => {
  if (contentData.value?.reference_images)
    return contentData.value?.reference_images;

  const proServiceImages = [];

  if (contentData.value?.image_url)
    proServiceImages.push({ image_url: contentData.value?.image_url });

  return proServiceImages;
});

const contentTitle = computed(() => {
  return contentData.value?.title || contentData.value?.name;
});

const curImageIndex = computed(() =>
  imageList.value?.findIndex(i => i.image_url === activeImage.value)
);

const handleCloseClick = () => {
  if (isClosing.value) return;

  linkClicked({
    context: route.meta.context,
    subContext: props.subContext,
    linkLabel: 'close modal',
    linkHref: null,
  });

  beginClose();
};

onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(modalRef)) {
    handleCloseClick();
  }
});

const nextImg = () => {
  const imageListLength = imageList.value?.length;

  if (curImageIndex.value + 1 === imageListLength) return;

  return (activeImage.value =
    imageList.value[curImageIndex.value + 1]?.image_url);
};

const prevImg = () => {
  if (curImageIndex.value === 0) return;

  return (activeImage.value =
    imageList.value[curImageIndex.value - 1]?.image_url);
};

// lifecycle hooks
onMounted(() => {
  activeImage.value = imageList.value[0]?.image_url;
});

watchEffect(() => {
  if (imageList.value) {
    activeImage.value = imageList.value[0]?.image_url;
  }
});

useLeftRightArrowKeys({
  onLeftArrowKeyStroke: prevImg,
  onRightArrowKeyStroke: nextImg,
});
</script>

<template>
  <Teleport to="body">
    <div
      ref="modalRef"
      class="dashboard-dialog"
      :class="{
        'dashboard-dialog--closing': isClosing,
      }"
      @click.self="handleCloseClick"
    >
      <div class="dashboard-dialog__card">
        <SoonaButton
          class="dashboard-dialog__close-btn"
          variation="icon-gray-outline"
          data-cypress="button-close-dashboard-dialog"
          @on-click="handleCloseClick"
        >
          <SoonaIcon name="xmark" /><span class="u-visually-hidden"
            >close modal</span
          >
        </SoonaButton>
        <div class="dashboard-dialog__card-inner">
          <div class="dashboard-dialog__card-left">
            <div class="dashboard-dialog__heading-container">
              <div class="dashboard-dialog__heading-left">
                <h3 class="dashboard-dialog__heading">
                  {{ contentTitle }}
                </h3>
                <p
                  v-if="$slots['subheading']"
                  class="u-body--heavy dashboard-dialog__subheading"
                >
                  <slot name="subheading" />
                </p>
              </div>
            </div>
            <slot />
          </div>
          <div class="dashboard-dialog__card-right">
            <SoonaButton
              v-if="imageList?.length > 1"
              class="dashboard-dialog__carousel-nav-btn dashboard-dialog__carousel-nav-btn--prev"
              :disabled="curImageIndex === 0"
              variation="icon-gray-outline"
              data-cypress="button-dashboard-dialog-prev-img"
              @on-click="prevImg"
            >
              <SoonaIcon name="arrow-left" />
              <span class="u-visually-hidden">previous item</span>
            </SoonaButton>
            <div class="dashboard-dialog__asset">
              <video
                v-if="activeImage?.match(/\.mp4$/g)"
                class="dashboard-dialog__video"
                autoplay
                loop
                playsinline
                muted
              >
                <source :src="activeImage" type="video/mp4" />
              </video>
              <img v-else :key="activeImage" :src="activeImage" />
            </div>
            <SoonaButton
              v-if="imageList?.length > 1"
              class="dashboard-dialog__carousel-nav-btn dashboard-dialog__carousel-nav-btn--next"
              :disabled="curImageIndex + 1 === imageList?.length"
              variation="icon-gray-outline"
              data-cypress="button-dashboard-dialog-nexy-img"
              @on-click="nextImg"
            >
              <SoonaIcon name="arrow-right" />
              <span class="u-visually-hidden">next item</span>
            </SoonaButton>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.dashboard-dialog {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  &__card {
    background-color: variables.$white-default;
    border-radius: 0.625rem;
    height: 100%;
    max-width: 58.75rem;
    position: relative;
    width: 100%;
    box-shadow: variables.$elevation-1;
    animation:
      0.15s ease-out both scale-up,
      0.15s ease-out both k-fade-in;

    .dashboard-dialog--closing & {
      animation:
        0.15s ease-out both scale-down,
        0.15s ease-out both k-fade-out;
    }
  }

  &__close-btn {
    position: absolute;
    z-index: 1;
    right: 1rem;
    top: 1rem;
  }

  &__card-inner {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
  }

  &__card-left {
    height: 50%;
    overflow-y: auto;
    padding: 1.75rem;
    width: 100%;
  }

  &__heading-container {
    padding-bottom: 1.5rem;
  }

  &__heading {
    @include variables_fonts.u-headline--heavy;
  }

  &__card-right {
    border-radius: 0.625rem 0.625rem 0 0;
    height: 50%;
    overflow-y: auto;
    position: relative;
    width: 100%;
  }

  &__asset {
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    background-color: variables.$gray-20;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__video {
    height: 100%;
    object-fit: cover;
  }

  &__carousel-nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--next {
      right: 1.5rem;
    }

    &--prev {
      left: 1.5rem;
    }
  }

  @media (min-width: variables.$screen-md-min) {
    background-color: rgba(63, 67, 75, 0.7);
    animation: 0.15s ease-out both fade-in-bg;

    &--closing {
      animation: 0.15s ease-out both fade-out-bg;
    }

    &__card {
      margin-bottom: 0;
      margin-left: 2rem;
      margin-right: 5rem;
      max-height: min(45rem, calc(100% - 4rem));
      animation:
        0.3s cubic-bezier(0.34, 1.56, 0.64, 1) both scale-up,
        0.15s ease-out both k-fade-in;

      .dashboard-dialog--closing & {
        animation:
          0.15s ease-out both scale-down,
          0.15s ease-out both k-fade-out;
      }
    }

    &__close-btn {
      right: -3.75rem;
      top: 0;
    }

    &__card-inner {
      flex-direction: row;
    }

    &__card-left {
      height: 100%;
      padding: 3.5rem 2.5rem;
      width: 50%;
    }

    &__heading {
      @include variables_fonts.u-display--heavy;
    }

    &__card-right {
      height: 100%;
      width: 50%;
      border-radius: 0 0.625rem 0.625rem 0;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none !important;

    &__inner {
      animation: none !important;
    }
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.95);
  }
}

@keyframes scale-down {
  to {
    transform: scale(0.95);
  }
}

@keyframes fade-in-bg {
  from {
    background-color: rgba(63, 67, 75, 0);
  }
}

@keyframes fade-out-bg {
  to {
    background-color: rgba(63, 67, 75, 0);
  }
}
</style>

<script setup>
import { ref, computed } from 'vue';
import listingInsightsImage from 'images/integration-banner_listing-insights.jpg';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaVimeoPlayer from '@/components/SoonaVimeoPlayer.vue';
import { getHowItWorksVideoBySection } from '@/lib/vimeo-videos';
import competitiveAnalysisImage from 'images/integration-banner_competitive-analysis.jpg';

const props = defineProps({
  section: {
    type: String,
    required: true,
  },
});

const section = computed(() => props.section);

const videoThumb = computed(() => {
  if (props.section === 'listingInsights') return listingInsightsImage;

  return competitiveAnalysisImage;
});

const showVideoOverlay = ref(false);

const showWindowOverlay = () => {
  showVideoOverlay.value = true;
};

const videoDetails = getHowItWorksVideoBySection(section.value);
</script>

<template>
  <div class="freemium-banner">
    <div class="freemium-banner--static">
      <div class="freemium-banner--copy">
        <h1 class="u-display--heavy">
          <slot name="title" />
        </h1>
        <p class="u-body--regular">
          <slot name="description" />
        </p>
      </div>
      <div class="freemium-banner--image-wrapper">
        <button class="u-button-reset" @click="showWindowOverlay">
          <img :src="videoThumb" class="freemium-banner--image" />
        </button>
      </div>
    </div>
    <slot />
  </div>
  <SoonaDialog
    v-if="showVideoOverlay"
    max-width="48rem"
    @close="showVideoOverlay = false"
  >
    <template #heading>how it works</template>
    <SoonaVimeoPlayer
      :vimeo-id="videoDetails?.videoId"
      :additional-params="videoDetails?.additionalParams"
      :show-close-x="false"
    />
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use 'src/variables';

.freemium-banner {
  position: relative;
  margin-bottom: -3rem;

  &--static {
    display: flex;
    flex-direction: row;
    background-image: url('~images/soona-gradient-background.svg');
    background-size: cover;
    padding: 4rem 1rem 8rem 1rem;
    gap: 2rem;
    align-items: center;
  }

  &--copy {
    flex-basis: 100%;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0;
  }

  &--image-wrapper {
    display: none;
  }

  &--image {
    max-height: 9.375rem;
    border-radius: 0.5rem;
  }

  @media (min-width: 640px) {
    &--static {
      padding: 4rem 2rem 8rem 2rem;
    }

    &--copy {
      flex-basis: 60%;
    }

    &--image-wrapper {
      display: flex;
      justify-content: center;
      flex-basis: 30%;

      img {
        object-fit: fill;
        object-position: center;
      }
    }
  }

  @media (min-width: variables.$screen-md-min) {
    &--copy {
      padding: 0 4rem;
    }
  }
}
</style>

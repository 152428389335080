import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { autoApplyDiscount } from '@/api/order';

/**
 *
 * @param {number | Ref<number>} orderId
 */
export function useAutoApplyCoupon(orderId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => autoApplyDiscount(unref(orderId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
  });
}

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useFlag } from '@/composables/useFlag';
import { useReservation } from '@/composables/useReservation';
import UpgradeCard from '@/components/user/anytime/UpgradeCard.vue';
import UGCCard from '@/components/user/anytime/UGCCard.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: false,
  },
  reservationId: {
    type: [Number, String],
    required: false,
  },
});

const route = useRoute();

const toasterProServiceUpsellCardFlag = useFlag(
  'toaster_pro_service_upsell_card'
);

const { isPack, notStarted } = useReservation(
  computed(() => props.reservationId)
);

const showUpgradeCard = computed(
  () =>
    notStarted.value &&
    !isPack.value &&
    (route.name !== 'info' || !toasterProServiceUpsellCardFlag.value)
);
</script>
<template>
  <div class="upgrades-section">
    <UGCCard />
    <UpgradeCard v-if="showUpgradeCard" :reservation-id="reservationId" />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.upgrades-section {
  display: flex;
  flex-direction: column;
  gap: 0rem 1.625rem;

  @media (min-width: variables.$screen-sm-min) {
    flex-direction: row;
  }
}
</style>

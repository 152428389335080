<script setup>
import { computed } from 'vue';
import DialogOptionCard from './DialogOptionCard.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useGetShotTags } from '@/queries/useGetShotTags';

const emit = defineEmits(['close', 'select']);

const { data } = useGetShotTags({
  slug: 'shot-textures',
});

const shotTags = computed(() => data.value?.data ?? []);
</script>

<template>
  <SoonaDialog @close="emit('close')">
    <template #heading>backdrop textures</template>
    <ul>
      <DialogOptionCard
        v-for="option in shotTags"
        :key="option.id"
        :option="option"
        @handle-choose="emit('select', option)"
      />
    </ul>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
ul {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
</style>

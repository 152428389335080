import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { sendGalleryInvitations } from '@/api/invitations';
import { queryKeys } from '@/queries/query-keys';

export function useSendGalleryInvitations(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => sendGalleryInvitations(toValue(accountId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.invitations(accountId),
      });
    },
  });
}

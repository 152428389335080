import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { getAvailableDownPaymentCredits } from '@/api/reservation';

/**
 * @param {MaybeRef<string | number>} reservationId
 * @param {Object} [queryOptions]
 */
export function useGetAvailableDownPaymentCreditInfo(
  reservationId,
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.reservationDownPaymentCredits(reservationId),
    queryFn: ({ signal }) =>
      getAvailableDownPaymentCredits(toValue(reservationId), signal),
    ...queryOptions,
  });
}

import * as types from 'src/store/mutation-types';

const state = {
  currentEmployeeType: {},
  employeeDepartment: [
    'creative',
    'biz',
    'marketing',
    'ops',
    'sales',
    'tech',
    'pro_services',
  ],
  employeeRoles: [],
};
const mutations = {
  [types.SET_EMPLOYEE_ROLES](state, roles) {
    state.employeeRoles = roles;
  },
  [types.SET_CURRENT_EMPLOYEE_TYPE](state, currentEmployeeType) {
    state.currentEmployeeType = currentEmployeeType;
  },
};

const actions = {
  loadEmployeeTypesByDepartment({ commit }, department) {
    return new Promise(resolve => {
      this.http
        .get(`employee_types.json?department=${department}`)
        .then(response => {
          commit(types.SET_EMPLOYEE_ROLES, response.data);
          resolve();
        });
    });
  },
  loadEmployeeType({ commit }, employeeTypeId) {
    return new Promise(resolve => {
      this.http.get(`employee_types/${employeeTypeId}.json`).then(response => {
        commit(types.SET_CURRENT_EMPLOYEE_TYPE, response.data);
        resolve(response.data);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

<script setup>
import { computed } from 'vue';
import SoonaClickableCard from 'src/components/booking/v3/p2/SoonaClickableCard.vue';
import PlaceholderImage from 'images/placeholder.png';
import {
  PeriwinkBlue20,
  BlackDefault,
  Tangerine20,
  AvoToast10,
} from 'src/variables.module.scss';
import fireAnimationPath from 'images/anim/fire.lott';

const props = defineProps({
  pack: {
    type: Object,
    required: true,
  },
  to: {
    type: String,
    required: false,
  },
  href: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['onClick']);

const isLink = computed(() => !!(props.to || props.href));

const image = computed(
  () => props.pack.pack_main_image_url ?? PlaceholderImage
);

const price = computed(() => props.pack.pack_price ?? '$');

const includes = computed(
  () => props.pack.description?.includes ?? ['[includes]']
);

const imageTags = computed(() => {
  const tags = [];
  const tagsToAdd = [
    {
      title: 'new',
      textColor: BlackDefault,
      backgroundColor: PeriwinkBlue20,
      fontWeight: 'bolder',
    },
    {
      title: 'trending',
      animation: fireAnimationPath,
      textColor: BlackDefault,
      backgroundColor: Tangerine20,
      fontWeight: 'bolder',
    },
    {
      title: 'holiday',
      iconLeft: '🎁',
      textColor: BlackDefault,
      backgroundColor: AvoToast10,
      fontWeight: 'bolder',
    },
  ];
  tagsToAdd.forEach(tag => {
    if (props.pack.merchandising_tags?.includes(tag.title)) {
      tags.push(tag);
    }
  });
  return tags;
});
</script>

<template>
  <SoonaClickableCard
    :has-arrow="true"
    :has-small-body-text="true"
    :image="{ src: image, alt: '' }"
    :image-tags="imageTags"
    :title="pack.name"
    :is-link="isLink"
    :to="to"
    :href="href"
    @on-click="emit('onClick', $event)"
  >
    <template #price-point>{{ price }}</template>
    <template #body>
      <ul class="pack-card__includes">
        <li v-for="(item, i) in includes" :key="i">
          {{ item }}
        </li>
      </ul>
    </template>
  </SoonaClickableCard>
</template>

<style lang="scss" scoped>
@use '@/variables';
.pack-card {
  &__includes {
    list-style: disc;
    padding-left: 0.9375rem;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAccount } from '@/composables/useAccount';
import { useUpdateInventoryPackage } from '@/queries/inventory/useUpdateInventoryPackage';
import PackageHandlingDialog from '@/components/user/anytime/PackageHandlingDialog.vue';
import ReturnAddressModal from '@/components/user/anytime/inventory/return-address/ReturnAddressModal.vue';
import { useInventory } from '@/composables/useInventory';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  inventoryPackage: {
    type: Object,
    required: false,
  },
  showPackageHandlingDialog: {
    type: Boolean,
    required: true,
  },
  showReturnAddressDialog: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  'closePackageHandlingDialogOnly',
  'closeReturnAddressDialogOnly',
  'closeReturnAddressOpenPackageHandlingDialog',
  'openReturnAddressClosePackageHandlingDialog',
]);

const router = useRouter();
const route = useRoute();

const accountId = computed(() => props.accountId);
const inventoryPackageId = computed(() => props.inventoryPackage.id);

const { hasSoonaStorage } = useAccount(accountId);

// update inventory package here
const { mutate: updatePackage, error: updateError } =
  useUpdateInventoryPackage(inventoryPackageId);

const { postShootOptions } = useInventory();

const handleConfirm = packageOption => {
  if (packageOption === 'store_at_soona' && !hasSoonaStorage.value) {
    router.push({
      path: `/account/${accountId.value}/subscriptions/add-ons/fast-pass`,
      query: {
        package_id: inventoryPackageId.value,
        reservation_id: route.params?.reservationId,
      },
    });
  } else {
    let packageAttributes = {
      post_shoot_option: packageOption,
      // set required action if prev stored and new action is taken
      required_action:
        props.inventoryPackage.status === 'stored'
          ? (postShootOptions.value?.find(opt => packageOption === opt.value)
              ?.requiredAction ?? null)
          : null,
    };

    updatePackage(packageAttributes);
  }

  if (packageOption === 'return_shipping') {
    emit('openReturnAddressClosePackageHandlingDialog');
  } else {
    emit('closePackageHandlingDialogOnly');
  }
};
</script>

<template>
  <div>
    <PackageHandlingDialog
      v-if="showPackageHandlingDialog"
      :is-reservation-package="false"
      :has-soona-storage="hasSoonaStorage"
      :preselected-package-option="inventoryPackage.post_shoot_option"
      :update-error="updateError"
      @close="emit('closePackageHandlingDialogOnly')"
      @display-return-address-dialog="
        emit('openReturnAddressClosePackageHandlingDialog')
      "
      @handle-confirm="handleConfirm"
    />
    <ReturnAddressModal
      v-if="showReturnAddressDialog"
      :account-id="accountId"
      :package-to-update="inventoryPackageId"
      @cancel="emit('closeReturnAddressOpenPackageHandlingDialog')"
      @success="emit('closeReturnAddressDialogOnly')"
    />
  </div>
</template>

<template>
  <div
    class="admin-trend-set"
    :class="{ 'admin-trend-set--deleted': trendSetStatus === 'deleted' }"
    :data-cypress="`component-admin-trend-set-${dataCypressTrendSetName}`"
  >
    <div class="admin-trend-set__inner">
      <!-- TREND SET NAME -->
      <a
        v-if="trendSetStatus !== 'deleted'"
        class="admin-trend-set__name"
        :href="`/user/anytime#/admin/trend-sets/${trendSet.tag_category_id}/${trendSet.id}`"
        :data-cypress="`text-trend-set-name-${dataCypressTrendSetName}`"
      >
        {{ trendSet.title }}
      </a>
      <p
        v-else
        class="admin-trend-set__name"
        :data-cypress="`text-trend-set-name-${dataCypressTrendSetName}`"
      >
        {{ trendSet.title }}
      </p>
      <!-- INFO -->
      <div
        class="admin-trend-set__info"
        :class="{
          'admin-trend-set__info-deleted': trendSetStatus === 'deleted',
        }"
      >
        <div class="admin-trend-set__category">
          <div
            class="admin-trend-set__category-inner admin-trend-set__category--photo"
          >
            <p>photo</p>
          </div>
        </div>
        <p class="admin-trend-set__created">
          {{ formattedDate }}
        </p>
        <div class="admin-trend-set__status">
          <div
            class="admin-trend-set__status-inner"
            :class="trendSetStatusClass"
          >
            <p
              :data-cypress="`text-trend-set-status-${dataCypressTrendSetName}`"
            >
              {{ trendSetStatus }}
            </p>
          </div>
        </div>
        <label
          v-if="isNotDeleted"
          class="admin-trend-set__live"
          :data-cypress="`component-admin-trend-set-live-${dataCypressTrendSetName}`"
        >
          <input
            class="admin-trend-set__live-checkbox"
            type="checkbox"
            :checked="isChecked"
            :disabled="isLiveToggleDisabled"
            :data-cypress="`checkbox-update-trend-set-status-${dataCypressTrendSetName}`"
            @change="e => toggleStatus(e)"
          />
          <span
            class="admin-trend-set__live-slider"
            :data-cypress="`input-update-trend-set-status-${dataCypressTrendSetName}`"
          ></span>
        </label>
        <div v-if="isNotDeleted" class="admin-trend-set__delete">
          <button
            class="admin-trend-set__delete-btn"
            :disabled="isDeleteDisabled"
            :data-cypress="`button-delete-trend-set-${dataCypressTrendSetName}`"
            @click.prevent="deleteTrendSet"
          >
            <SoonaIcon name="trash" />
          </button>
        </div>
      </div>
    </div>
    <SoonaError
      v-if="showErrorMsg"
      class="admin-trend-set__error"
      :data-cypress="`component-admin-trend-set-error-${dataCypressTrendSetName}`"
    >
      whoops! we ran into an error. please try again later.
    </SoonaError>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import dateTimeMixin from '@/mixins/dateTimeMixin';
import * as Sentry from '@sentry/vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'AdminTrendSet',
  components: {
    SoonaError,
    SoonaIcon,
  },
  mixins: [dateTimeMixin],
  props: {
    trendSet: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { hasCapability: canManageTrendSetStatus } = useCapability({
      capability: 'manage_trend_set_status',
    });

    return { canManageTrendSetStatus };
  },
  data: () => ({
    trendSetStatus: '',
    showErrorMsg: false,
  }),
  computed: {
    dataCypressTrendSetName() {
      return `${this.trendSet.title.toLowerCase().replace(/\s+/g, '-')}`;
    },
    isNotDeleted() {
      return this.trendSetStatus !== 'deleted';
    },
    formattedDate() {
      return this.isNotDeleted
        ? this.convertToMMDDYYYY(this.trendSet.created_at)
        : this.convertToMMDDYYYY(this.trendSet.updated_at);
    },
    isChecked() {
      return this.trendSetStatus === 'published';
    },
    isDeleteDisabled() {
      return this.trendSetStatus === 'deleted' || !this.canManageTrendSetStatus;
    },
    isLiveToggleDisabled() {
      return (
        this.trendSetStatus === 'deleted' ||
        this.trendSetStatus === 'unfinished' ||
        !this.canManageTrendSetStatus
      );
    },
    trendSetStatusClass() {
      return `admin-trend-set__status--${this.trendSetStatus}`;
    },
  },
  mounted() {
    this.trendSetStatus = this.determineStatus();
  },
  methods: {
    ...mapActions('trendSetBuilder', ['updateTrendSetStatus', 'loadTrendSets']),
    isNotFinished() {
      // Handle edge case where user creates trend set but goes back to list page w/o saving required fields
      return (
        (!this.trendSet.description || !this.trendSet.description.length) &&
        (!this.trendSet.image_url || !this.trendSet.image_url.length)
      );
    },
    determineStatus() {
      if (this.isNotFinished() && this.trendSet.status !== 'discontinued') {
        return 'unfinished';
      }

      switch (this.trendSet.status) {
        case 'discontinued':
          return 'deleted';
        default:
          return this.trendSet.status;
      }
    },
    refreshTrendSetList() {
      this.showErrorMsg = false;
      this.loadTrendSets();
    },
    async deleteTrendSet() {
      const confirmDelete = confirm(
        `Are you sure you want to delete ${this.trendSet.title}?`
      );

      if (confirmDelete) {
        try {
          const [, err] = await this.updateTrendSetStatus({
            trendSetCategoryId: this.trendSet.tag_category_id,
            trendSetId: this.trendSet.id,
            params: { status: 'discontinued' },
          });

          if (err) {
            this.showErrMsg(err);
            return;
          }
          this.refreshTrendSetList();
        } catch (err) {
          this.showErrMsg(err);
        }
      }
    },
    async toggleStatus(e) {
      this.errorMessage = false;
      const oldTrendSetStatus = this.trendSetStatus;
      this.trendSetStatus = e.target.checked ? 'published' : 'draft';

      try {
        const [, err] = await this.updateTrendSetStatus({
          trendSetCategoryId: this.trendSet.tag_category_id,
          trendSetId: this.trendSet.id,
          params: { status: this.trendSetStatus },
        });

        if (err) {
          this.revertOnErr(oldTrendSetStatus);
          this.showErrMsg(err);
          return;
        }
        this.refreshTrendSetList();
      } catch (err) {
        this.revertOnErr(oldTrendSetStatus);
      }
    },
    revertOnErr(oldTrendSetStatus) {
      this.trendSetStatus = oldTrendSetStatus;
    },
    showErrMsg(err) {
      if (err.response.status >= 500) {
        Sentry.captureException(new Error('Failed to update trend set'), {
          extra: { error: err },
        });
      }

      this.showErrorMsg = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables_fonts';
@use '@/variables';

.admin-trend-set {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: variables.$white-default;
  border: 1px solid variables.$gray-30;
  padding: 41px;
  border-radius: 10px;
  margin-bottom: 18px;
  cursor: default;
  position: relative;

  &--deleted {
    background-color: variables.$gray-30;

    .admin-trend-set__name {
      color: variables.$gray-50;

      &:hover {
        color: variables.$gray-50;
      }
    }

    .admin-trend-set__created {
      color: variables.$gray-60;
    }
  }

  &__inner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__name {
    @include variables_fonts.u-subheader--heavy;
    transition: 0.3s;
    flex: 0.5;

    @media only screen and (max-width: 480px) {
      flex: 1;
      flex-basis: 100%;
    }

    &:hover {
      color: variables.$periwink-blue-60;
    }
  }

  // INFO
  &__info {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    &-deleted {
      .admin-trend-set__category {
        order: 1;
      }

      .admin-trend-set__created {
        order: 3;
      }

      .admin-trend-set__status {
        order: 2;
      }
    }
  }

  &__category {
    display: flex;
    max-width: 114px;

    &-inner {
      align-items: center;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      padding: 5px 10px;
      padding-top: 7px;
      width: max-content;
    }

    &--photo {
      background: variables.$tangerine-20;
    }

    &--video {
      background-color: variables.$green-apple-20;
    }

    &--stop-motion {
      background-color: variables.$bubbletape-pink-20;
    }

    p {
      color: variables.$black-default;
      font-size: 11px;
      font-weight: 800;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }

  &__created {
    @include variables_fonts.u-label--regular;
  }

  &__status {
    display: flex;
    width: 100px; // keep static width so elements stay in place

    &-inner {
      align-items: center;
      border-radius: 1px;
      display: flex;
      justify-content: center;
      padding: 4px 8px;
      width: max-content;
    }

    &--published {
      background-color: variables.$avo-toast-20;
      border: 1px solid variables.$avo-toast-30;

      p {
        color: variables.$avo-toast-40;
      }
    }

    &--draft {
      background-color: variables.$gray-20;
      border: 1px solid variables.$gray-30;

      p {
        color: variables.$gray-60;
      }
    }

    &--unfinished {
      background-color: variables.$tangerine-10;
      border: 1px solid variables.$tangerine-30;

      p {
        color: variables.$tangerine-40;
      }
    }

    &--off {
      background-color: variables.$tangerine-20;
      border: 1px solid variables.$tangerine-20;

      p {
        color: variables.$tangerine-70;
      }
    }

    &--deleted {
      background-color: variables.$roses-20;
      border: 1px solid variables.$roses-30;

      p {
        color: variables.$roses-60;
      }
    }

    p {
      @include variables_fonts.u-badge--big;
    }
  }

  &__live {
    display: inline-block;
    height: 22px;
    position: relative;
    width: 40px;

    &-checkbox {
      height: 0;
      opacity: 0;
      width: 0;

      &:checked + .admin-trend-set__live-slider {
        background-color: variables.$periwink-blue-50;

        &:before {
          transform: translateX(16px);
        }
      }

      &:focus + .admin-trend-set__live-slider {
        box-shadow: 0 0 1px variables.$periwink-blue-50;
      }

      &:disabled {
        pointer-events: none;

        + .admin-trend-set__live-slider {
          pointer-events: none;
        }
      }
    }

    &-slider {
      background-color: variables.$gray-50;
      border-radius: 70px;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.3s;

      &:before {
        background-color: variables.$white-default;
        border-radius: 50%;
        bottom: 2px;
        content: '';
        height: 18px;
        left: 3px;
        position: absolute;
        transition: 0.3s;
        width: 18px;
      }
    }
  }

  &__delete {
    &-btn {
      background: transparent;
      border: none;
      padding: 0 10px;

      &:hover {
        path {
          fill: variables.$periwink-blue-50;
        }
      }

      path {
        fill: variables.$gray-40;
        transition: 0.3s;
      }

      &:disabled {
        pointer-events: none;
      }
    }
  }

  &__error {
    margin-bottom: 0;
  }
}
</style>

<template>
  <div class="shipping">
    <div class="column is-full soona-container is-centered">
      <div class="shotlist-header has-text-centered">
        <h2 class="title has-text-centered is-size-4-mobile">let's ship it!</h2>

        <p class="shotlist-subtitle is-italic has-text-centered">
          your first package ships free with soona!
          <span>want to get it here faster? use our custom option.</span>
        </p>

        <div class="mt-l has-text-centered">
          <button
            class="button shipping-options"
            :disabled="!freeShippingAvailable"
            data-cypress="free-shipping"
            @click="shippingTypeSelected(true, 'free shipping')"
          >
            <div v-if="freeShippingAvailable" class="free-banner">
              <p class="u-small--heavy">limited time!</p>
              <p class="u-small--regular">first one is free</p>
            </div>
            <div v-else class="free-banner">
              <p class="u-small--heavy">redeemed</p>
            </div>
            <lottie-player
              ref="freeShippingAnimation"
              autoplay
              loop
              src="@images/anim/lottie-free-shipping.lott"
              style="width: 150px; height: 150px; display: inline-block"
            />
            <p class="u-title--heavy">free shipping</p>
            <p class="u-small--regular is-lowercase mt-m mb-s">
              ships in 1 - 3 business days.
            </p>
            <p class="u-small--regular is-lowercase mb-s">
              max package weight of 70 pounds
            </p>
            <p class="u-small--regular is-lowercase">
              must ship in a USPS flat rate box
            </p>
            <br />
          </button>

          <button
            class="button shipping-options"
            data-cypress="custom-shipping"
            @click="shippingTypeSelected(false, 'custom shipping')"
          >
            <lottie-player
              autoplay
              loop
              src="@images/anim/lottie-custom-shipping.lott"
              style="width: 150px; height: 150px; display: inline-block"
            />
            <p class="u-title--heavy">custom shipping</p>
            <p class="u-small--regular is-lowercase mt-m mb-s">
              choose speed - including next day!
            </p>
            <p class="u-small--regular is-lowercase mb-s">
              max package weight of 70 pounds
            </p>
            <p class="u-small--regular is-lowercase">choose your own box</p>
            <br />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { mapActions, mapState } from 'vuex';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useShippingEvents } from '@/composables/segment/useShippingEvents';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';

export default {
  name: 'Options',
  setup() {
    const { buttonClicked, pageViewed } = useBaseEvents();
    const { shippingStarted } = useShippingEvents();
    const route = useRoute();
    const title = useTitle(null);

    onMounted(() => {
      title.value = `${route.meta.page_title} | soona`;

      pageViewed();
      shippingStarted({
        context: route.meta.context,
        subContext: route.meta.page_title,
      });
    });

    return { buttonClicked };
  },
  data() {
    return {
      freeShippingAvailable: false,
      selectedShippingType: undefined,
    };
  },
  computed: {
    ...mapState({
      currentRates: state => state.reservation.currentRates,
    }),
  },
  mounted: function () {
    this.checkForFreeShipping();
  },
  methods: {
    ...mapActions('reservation', [
      'checkFreeShippingAvailability',
      'chooseShippingType',
    ]),
    shippingTypeSelected(freeShipping, buttonLabel) {
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: 'shipping options',
        buttonLabel: buttonLabel,
        buttonAction: 'advance shipping flow',
      });

      this.chooseShippingType({ freeShipping: freeShipping });
    },
    async checkForFreeShipping() {
      let response = await this.checkFreeShippingAvailability();
      this.freeShippingAvailable = response.data.free_shipping_available;

      if (!this.freeShippingAvailable) {
        this.$refs.freeShippingAnimation.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.free-banner {
  background-color: variables.$periwink-blue-70;
  position: absolute;
  top: 15px;
  right: -1px;
  color: variables.$white-default;
  padding: 4px 8px 4px 16px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
</style>

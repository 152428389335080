import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { toValue } from 'vue';

/**
 *
 * @param {import('vue').MaybeRefOrGetter<number>} creditId
 * @param {import('vue').MaybeRefOrGetter<number>} accountId
 */
export function useDeleteCredit(creditId, accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.delete(`/credits/${toValue(creditId)}`);
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.accountCreditInfo(accountId),
      });
    },
  });
}

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<number>} reservationId
 * @param {import('vue').MaybeRefOrGetter<Object>} params
 */
export function useGetAllEditHistory(reservationId, params) {
  return useQuery({
    queryKey: queryKeys.reservationEditHistory(reservationId, params),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/reservations/${toValue(reservationId)}/edit_history.json`,
        {
          params: toValue(params),
          signal,
        }
      );

      return response.data?.edit_history;
    },
  });
}

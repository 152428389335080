import { computed, toValue } from 'vue';

/*
 * @param {MaybeRef<string | Number | null | undefined>} accountId
 * */
export const useDisableBulkActions = ({ accountId }) => {
  const heyDudeAccountId = Number(
    import.meta.env.VITE_HEY_DUDE_ACCOUNT_ID ?? -1
  );

  const disableBulkActions = computed(() => {
    return Number(toValue(accountId)) === heyDudeAccountId;
  });

  return {
    disableBulkActions,
  };
};

export default () => {
  const getDisplayGroupRanks = subject => {
    if (subject.staff_pick) {
      let ranks;
      if (!subject.example_staff_pick) {
        ranks = subject.catalog_item_info.sales_ranks;
        ranks = ranks.map(x => ({
          ...x,
          number: x.rank.split('#')[1].split(' ')[0],
        }));
        ranks = ranks.sort((a, b) => a.number - b.number);
        ranks = ranks.filter(rank => rank.number < 50);
        if (ranks.length === 0) {
          return 'view on Amazon';
        }
        ranks = ranks.map(rank => rank.rank);
      } else {
        ranks = subject.display_ranks;
      }

      if (ranks.length > 1) {
        ranks = ranks.map((rank, i) => {
          if (i > 0) {
            rank = rank.replace("Amazon's", '');
          }
          return rank;
        });
      }
      if (subject.example_staff_pick) {
        ranks = ranks.join(' · ');
        return "Amazon's " + ranks;
      } else {
        return ranks.join(' · ');
      }
    }
    return (
      subject.catalog_item_info?.sales_ranks?.find(
        x => x.type === 'displayGroupRanks'
      )?.rank ??
      subject.catalog_item_info?.sales_ranks?.find(
        x => x.type === 'classificationRanks'
      )?.rank ??
      'view on Amazon'
    );
  };

  return {
    getDisplayGroupRanks,
  };
};

<script setup>
import { computed, ref, watch } from 'vue';
import { useIntervalFn } from '@vueuse/core';

const props = defineProps({
  collectionId: {
    type: Number,
  },
  dimension: {
    type: String,
    required: true,
    default: 'as_is',
  },
  playback: {
    type: String,
    required: true,
    default: 'loop',
  },
  speed: {
    type: Number,
    required: true,
    default: 666.66,
  },
  selectedAssets: {
    type: Array,
    required: true,
    default: () => [],
  },
});

const playback = computed(() => props.playback);
const speed = computed(() => props.speed);
const selectedAssets = computed(() => props.selectedAssets);

const currentFrameIdx = ref(0);
const playbackFrameIndices = computed(() => {
  const loop = selectedAssets.value.map((_, i) => i);

  if (playback.value === 'boomerang') {
    return loop.concat(loop.slice(1, -1).reverse());
  } else {
    return loop;
  }
});

const currentFrameUrl = computed(() => {
  const fileIdx = playbackFrameIndices.value[currentFrameIdx.value];
  if (!Number.isInteger(fileIdx)) return null;

  const currentAsset = selectedAssets.value[fileIdx];
  if (!currentAsset) return null;
  return currentAsset?.preview?.url ?? null;
});

useIntervalFn(() => {
  const nextFrame = currentFrameIdx.value + 1;
  currentFrameIdx.value = nextFrame % playbackFrameIndices.value.length;
}, speed);

watch(selectedAssets, () => {
  currentFrameIdx.value = 0;
});

const aspectRatio = computed(() => {
  switch (props.dimension) {
    case 'portrait':
      return 4 / 5;
    case 'square':
      return 1;
    case 'tall':
      return 9 / 16;
    default:
      return selectedAssets.value?.at(0)?.preview?.ratio ?? 1;
  }
});
</script>

<template>
  <figure
    class="gif-hero"
    :class="{
      'gif-hero--square': aspectRatio === 1,
      'gif-hero--tall': aspectRatio < 1,
      'gif-hero--wide': aspectRatio > 1,
    }"
  >
    <img
      v-if="currentFrameUrl"
      class="hero-image"
      :src="currentFrameUrl"
      alt="hero image representing the multiple images chosen to make up the gif"
    />
  </figure>
</template>

<style lang="scss" scoped>
.gif-hero {
  height: 17.875rem;
  width: 17.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;

  .hero-image {
    display: block;
    object-fit: cover;
    aspect-ratio: v-bind('aspectRatio');
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  &--tall {
    .hero-image {
      width: auto;
      height: 100%;
    }
  }

  &--wide {
    .hero-image {
      width: 100%;
      height: auto;
    }
  }
}
</style>

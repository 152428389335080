import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getShippingAddresses } from '../api/addresses';
import { queryKeys } from './query-keys';

export function useAddresses(accountId) {
  return useQuery({
    queryKey: queryKeys.addresses(accountId),
    queryFn: ({ signal }) => getShippingAddresses(unref(accountId), signal),
  });
}

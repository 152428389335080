import { computed } from 'vue';

export function useIntegrationLogo(listingAction) {
  const integrationLogo = computed(() => {
    switch (listingAction.value.integration_type) {
      case 'AmazonIntegration':
        return 'amazon-logo';
      case 'ShopifyIntegration':
        return 'shopify-logo';
      default:
        return null;
    }
  });
  return { integrationLogo };
}

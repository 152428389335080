<script setup>
import { computed } from 'vue';

const props = defineProps({
  mediaItem: {
    type: Object,
    required: true,
  },
  ariaLabelledby: {
    type: String,
    required: true,
  },
});

const mediaItem = computed(() => props.mediaItem);

const previewUrl = computed(() => {
  return mediaItem.value?.preview?.url || mediaItem.value?.preview_url;
});
</script>

<template>
  <article class="image-card" :aria-labelledby="ariaLabelledby">
    <img :src="previewUrl" alt="" />

    <div class="image-card__content">
      <slot name="content"></slot>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.image-card {
  display: block;
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  overflow: hidden;

  img {
    width: 100%;
    max-height: 20rem;
    object-fit: contain;
  }
}
</style>

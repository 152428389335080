<script setup>
import { computed } from 'vue';
import { useAccount } from '@/composables/useAccount';
import { useReservation } from '@/composables/useReservation';
import { usePriorityError } from '@/composables/usePriorityError';
import { useInventory } from '@/composables/useInventory';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useUpdateReservation } from '@/queries/useUpdateReservation';

const props = defineProps({
  reservationId: {
    type: String,
    required: true,
  },
  slug: String,
});

const reservationId = computed(() => props.reservationId);

const { reservation, isLoading, error } = useReservation(reservationId);
const {
  mutate: updateReservation,
  error: updateReservationError,
  isPending: isLoadingUpdate,
} = useUpdateReservation(reservationId);
const priorityError = usePriorityError(updateReservationError, error);
const isBusy = computed(() => isLoading.value || isLoadingUpdate.value);

const accountId = computed(() => reservation.value?.account?.id);
const { hasSoonaStorage } = useAccount(accountId);

const { packageHandlingOptions } = useInventory();

const selectedOption = computed(() => reservation.value?.package_option);

function onSelect(newPackageOption) {
  updateReservation({ package_option: newPackageOption });
}
</script>

<template>
  <SoonaSelect
    label="firstInfo"
    :reduce="({ value }) => value"
    :model-value="selectedOption"
    :options="packageHandlingOptions"
    :disabled="isBusy"
    :loading="isBusy"
    placeholder="optional (required before scheduling)"
    :clearable="true"
    :selectable="
      option => !(option.value === 'already_stored' && !hasSoonaStorage)
    "
    data-cypress="select-post-shoot-option"
    @update:model-value="onSelect"
  >
    <template #label>post shoot option</template>
  </SoonaSelect>

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

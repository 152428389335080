import { computed, ref } from 'vue';
import { useUpdateListingAction } from '@/queries/useListingActions';
import { useCreateBulkActionTask } from '@/queries/useDigitalAssetBulkActionTasks';
import { useDigitalAssetsBulkPublishManager } from '@/queries/useDigitalAssetsBulkPublishManager';

export function useMainProductOnWhiteActions(
  accountId,
  listingAction,
  { externalProductId = null, integrationId = null }
) {
  const optimizeTriggered = ref(false);

  const listingActionId = computed(() => listingAction.value.id);

  const { data: managerData } = useDigitalAssetsBulkPublishManager(
    accountId,
    'main-image-product-on-white'
  );

  const mostRecentBulkPublishManager = computed(() => {
    return managerData?.value;
  });

  const { mutate: updateListingAction, error: updateListingActionError } =
    useUpdateListingAction(accountId, listingActionId, {
      externalProductId,
      integrationId,
    });

  const { mutate: createBulkActionTask, error: createBulkActionError } =
    useCreateBulkActionTask(accountId, {
      externalProductId,
      integrationId,
    });

  const processBulkAction = sourceAssetId => {
    createBulkActionTask(
      {
        digital_asset_ids: [sourceAssetId],
        bulk_action: 'product_on_white',
      },
      {
        onSuccess: () => {
          optimizeTriggered.value = false;
        },
        onError: () => {
          optimizeTriggered.value = false;
        },
      }
    );
  };

  const linkSourceAsset = digitalAssetId => {
    optimizeTriggered.value = true;
    updateListingAction(
      {
        source_asset_id: digitalAssetId,
      },
      {
        onSuccess: data => {
          processBulkAction(data.source_asset_id);
        },
        onError: () => {
          optimizeTriggered.value = false;
        },
      }
    );
  };

  return {
    optimizeTriggered,
    linkSourceAsset,
    updateListingActionError,
    createBulkActionError,
    mostRecentBulkPublishManager,
  };
}

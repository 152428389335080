<script setup>
import { computed } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { Tangerine20, Tangerine80 } from '@/variables.module.scss';
import { useUpdateEditsCollectionDigitalAsset } from '@/queries/edits_collection_digital_assets/useUpdateEditsCollectionDigitalAsset';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
    default: undefined,
  },
  assetId: {
    type: [Number, String],
    required: true,
    default: undefined,
  },
  editsCollectionDigitalAssetId: {
    type: [Number, String],
    required: true,
    default: undefined,
  },
  editsCollectionId: {
    type: [Number, String],
    required: true,
    default: undefined,
  },
  visibility: {
    required: true,
    type: String,
    default: '',
  },
});

const accountId = computed(() => props.accountId);
const collectionId = computed(() => props.editsCollectionId);

const { mutate, isPending } = useUpdateEditsCollectionDigitalAsset(
  accountId,
  collectionId
);

const accept = () => {
  mutate({
    cdaId: props.editsCollectionDigitalAssetId,
    body: {
      edit_status: 'accepted',
      digital_asset_attributes: { visibility: 'all' },
    },
  });
};

const reject = () => {
  mutate({
    cdaId: props.editsCollectionDigitalAssetId,
    body: { edit_status: 'rejected' },
  });
};
</script>
<template>
  <div>
    <SoonaFlag
      :background-color="Tangerine20"
      :color="Tangerine80"
      title="pending"
    />
    <SoonaButton
      element="button"
      variation="secondary-black"
      :loading="isPending"
      :disabled="isPending"
      @on-click="reject"
    >
      reject
    </SoonaButton>
    <SoonaButton
      element="button"
      variation="solid-black"
      :loading="isPending"
      :disabled="isPending"
      @on-click="accept"
    >
      deliver
    </SoonaButton>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useDeleteNote } from '@/queries/notes/useDeleteNote';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import NoteEdit from '@/components/notes/NoteEdit.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';
import SoonaRelativeTime from '@/components/ui_library/soona_relative_time/SoonaRelativeTime.vue';
import { useMe } from '@/composables/user/useMe';
import Autolinker from 'autolinker';

const props = defineProps({
  nonCreatorEditingAllowed: {
    type: Boolean,
    default: false,
  },
  note: {
    type: Object,
    required: true,
    validator: value => {
      return (
        'id' in value &&
        'content' in value &&
        'created_at' in value &&
        'creator' in value &&
        'name' in value.creator
      );
    },
  },
  subjectType: {
    type: String,
    required: true,
  },
  subjectId: {
    type: [String, Number],
    required: true,
  },
});

const { currentUserId } = useMe();

const editMode = ref(false);

const {
  mutate: deleteNote,
  isPending: isDeletingNote,
  error: deleteNoteError,
} = useDeleteNote(
  computed(() => props.subjectType),
  computed(() => props.subjectId)
);

const priorityErrors = usePriorityErrors(deleteNoteError);

const showEditDropdown = computed(
  () =>
    props.nonCreatorEditingAllowed ||
    currentUserId.value === props.note?.creator?.id
);

const formattedNoteContent = computed(() => {
  return Autolinker.link(props.note.content, {
    target: '_blank',
    rel: 'noopener noreferrer',
  });
});
</script>

<template>
  <article v-if="!editMode" class="note-display">
    <SoonaAvatar
      :name="note.creator.name"
      :src="note.creator.avatar"
      size="1.5rem"
      type="user"
    />
    <div class="note-display__content">
      <div class="note-display__content__header">
        <div class="note-display__content__header__left">
          <h3 class="u-label--heavy">{{ note.creator.name }}</h3>
          <p
            v-if="note.creator.is_employee"
            title="soona employee"
            class="note-display__employee"
          >
            <SoonaIcon name="soona-logo-wink-circle" size="small" />
            <span class="u-visually-hidden">soona employee</span>
          </p>
          <SoonaRelativeTime
            :iso-date-time="note.created_at"
            class="u-small--regular note-display__content__time"
          />
        </div>
        <div class="note-display__content__header__right">
          <SoonaDropdownMenu
            v-if="showEditDropdown"
            title="update assignment"
            variation="icon-plain-gray"
            size="small"
          >
            <template #trigger-content>
              <SoonaIcon name="dots-horizontal" />
              <span class="u-visually-hidden">actions</span>
            </template>
            <template #default="{ keydown, mouseover, clickCapture }">
              <SoonaDropdownMenuItem>
                <button
                  role="menuitem"
                  class="note-display__menuitem"
                  :disabled="isDeletingNote"
                  @keydown="keydown"
                  @mouseover="mouseover"
                  @click.capture="clickCapture"
                  @click="editMode = true"
                >
                  <SoonaIcon name="pen-square" size="medium" /> edit
                </button>
              </SoonaDropdownMenuItem>
              <SoonaDropdownMenuItem>
                <button
                  role="menuitem"
                  class="note-display__menuitem"
                  :disabled="isDeletingNote"
                  @keydown="keydown"
                  @mouseover="mouseover"
                  @click.capture="clickCapture"
                  @click="deleteNote({ noteId: note.id })"
                >
                  <SoonaIcon name="trash" size="medium" /> delete
                </button>
              </SoonaDropdownMenuItem>
            </template>
          </SoonaDropdownMenu>
        </div>
      </div>
      <p
        class="u-label--regular note-display__content__body"
        v-html="formattedNoteContent"
      />
      <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    </div>
  </article>
  <NoteEdit
    v-else
    :initial-content="note.content"
    :note-id="note.id"
    :subject-type="subjectType"
    :subject-id="subjectId"
    @close="editMode = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.note-display {
  display: flex;
  padding: 0.5rem;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.625rem;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1 0 0;

    &__header {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      justify-content: space-between;

      &__left,
      &__right {
        display: flex;
        flex-flow: row wrap;
        gap: 0.25rem;
        align-items: center;
      }
    }

    &__time {
      color: variables.$gray-70;
    }

    &__body {
      white-space: pre-wrap;
      word-break: break-word;

      :deep(a) {
        color: variables.$periwink-blue-70;
        text-decoration: underline;
        overflow-wrap: anywhere;

        &:hover {
          color: variables.$black-default;
          transition: color 0.1s ease-out;
        }

        &:active {
          color: variables.$periwink-blue-80;
        }
      }
    }
  }

  &__employee {
    color: variables.$friendly-red-50;
    flex: 0 0 1rem;
    line-height: 0;
  }

  &__menuitem {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      color: variables.$gray-60;
    }
  }
}
</style>

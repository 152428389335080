<script setup>
import { storeToRefs } from 'pinia';

import { useAiProducer } from './Composables/useAiProducer';
import { useAiProducerStore } from './Composables/useAiProducerStore';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const aiProducerStore = useAiProducerStore();
const { bookingState } = storeToRefs(aiProducerStore);
const { resetProducer } = useAiProducer();

const { linkClicked } = useBaseEvents();

function reset() {
  resetProducer();
  linkClicked({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    linkLabel: 'reset producer',
    linkHref: null,
  });
}

function createManualBooking() {
  resetProducer();
  linkClicked({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    linkLabel: 'create booking manually',
    linkHref: '/#/book',
  });
}

function talkToExpert() {
  linkClicked({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    linkLabel: 'talk with us',
    linkHref: 'https://soona.co/contact-sales',
  });
}
</script>

<template>
  <div class="summary">
    <header v-if="$slots['header']" class="summary__header">
      <h3 class="u-subheader--heavy"><slot name="header" /></h3>
    </header>

    <section class="summary__content">
      <SoonaButton
        variation="secondary-gray"
        size="medium"
        class="summary__summary_button"
        @click="reset"
      >
        new chat <SoonaIcon name="message-circle-plus" />
      </SoonaButton>

      <p class="u-label--regular summary__description">
        tell us what you’re looking for and we’ll recommend a pack based on your
        product type and current goals.
      </p>

      <ul class="summary__item-list">
        <li v-for="(value, label) in bookingState" :key="label">
          <p class="u-badge--small">
            <b>{{ label.replace(/_/g, ' ') }}</b>
          </p>
          <p class="u-label--heavy summary__item-description">
            {{
              Array.isArray(value)
                ? value.join(', ')
                : value?.toString() || 'not set'
            }}
          </p>
        </li>
      </ul>

      <div class="u-label--regular summary__pre-footer">
        <SoonaButton
          element="a"
          size="medium"
          target="_blank"
          variation="tertiary"
          class="summary__expert-button"
          href="https://soona.co/contact-sales"
          @click="talkToExpert"
        >
          <img src="/images/pro-avatar.png" alt="expert avatar" />
          <b>talk with us</b>
        </SoonaButton>

        <p>
          we’re here for you if you’d like to discuss your needs in more detail.
        </p>
      </div>
    </section>

    <footer class="summary__footer">
      <SoonaButton
        element="a"
        href="/#/book"
        variation="secondary-gray"
        class="summary__summary_button"
        @click="createManualBooking"
      >
        create booking manually
      </SoonaButton>
    </footer>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.summary {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  box-shadow: variables.$elevation-2;
  background: variables.$white-default;

  &__header {
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: space-around;
    border-bottom: 0.0625rem solid variables.$gray-20;
  }

  &__content {
    flex: 1;
    gap: 1rem;
    display: flex;
    padding: 1rem;
    flex-direction: column;
  }

  &__description {
    color: variables.$gray-70;
  }

  &__item-list {
    margin: 0;
    padding: 0;
    gap: 1.25rem;
    display: flex;
    list-style: none;
    flex-direction: column;
  }

  &__item-description {
    margin-top: 0.375rem;
    color: variables.$periwink-blue-60;
  }

  &__pre-footer {
    margin-top: auto;
    color: variables.$gray-70;
  }

  &__expert-button {
    margin-bottom: 0.25rem;

    img {
      width: 2.75rem;
      height: 2.75rem;
      border-radius: 50%;
      margin-right: 0.25rem;
      justify-content: flex-start;
    }
  }

  &__footer {
    display: flex;
    padding: 1rem;
    position: relative;
    align-items: center;
    border-top: 0.0625rem solid variables.$gray-20;

    &::after {
      @include variables_fonts.u-label--regular;

      top: 0;
      left: 50%;
      content: 'or';
      padding: 0 1rem;
      position: absolute;
      text-align: center;
      color: variables.$gray-70;
      transform: translate(-50%, -60%);
      background: variables.$white-default;
    }
  }

  &__summary_button {
    width: 100%;
  }
}
</style>

import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { featureFlagsQueryConfig } from '@/queries/users/feature-flags';

/**
 *
 * @param name {string} - rollout feature flag name
 * @returns {ComputedRef<boolean>}
 */

export function useFlag(name) {
  const { data } = useQuery(featureFlagsQueryConfig);

  // false by default: while loading or for unknown flags
  return computed(() => data.value?.includes(name) ?? false);
}

import { computed } from 'vue';
import { useAccount as useAccountQuery } from '@/queries/account/useAccount';
import { useFlag } from '@/composables/useFlag';

/**
 *
 * @param {Ref<number|string|undefined>} accountId
 */
export function useSubscription(accountId) {
  const {
    data: account,
    error,
    isLoading,
    status,
    isSuccess,
  } = useAccountQuery(accountId, {
    enabled: computed(() => !!accountId.value),
  });

  const subscriptionTier = computed(() => account.value?.subscription?.tier);
  const fastPassOnTrialFlag = useFlag('pegasus_fast_pass_on_trial');
  const canUpgradeFlag = useFlag('apollo_subscription_upgrades');

  const hasLiveSubscription = computed(() => {
    return account.value?.subscription?.is_live ?? false;
  });

  const subscriptionStartDate = computed(() => {
    return account.value?.subscription?.start_date;
  });

  //subscription checks
  const hasTierOneSubscription = computed(() => {
    return (
      hasLiveSubscription.value && subscriptionTier.value?.slug === 'tier-one'
    );
  });

  const hasTierTwoSubscription = computed(() => {
    return (
      hasLiveSubscription.value && subscriptionTier.value?.slug === 'tier-two'
    );
  });

  const hasTierThreeSubscription = computed(() => {
    return (
      hasLiveSubscription.value && subscriptionTier.value?.slug === 'tier-three'
    );
  });

  const hasFastPassTierSubscription = computed(() => {
    //move fast_pass_removable to backend
    return (
      hasLiveSubscription.value && subscriptionTier.value?.slug === 'fast-pass'
    );
  });

  // fastpass subscription feature checks
  const canEnrollFastPass = computed(() => {
    if (fastPassOnTrialFlag.value) {
      return !account.value?.has_soona_storage;
    } else {
      return !account.value?.has_soona_storage && !account.value?.is_trialing;
    }
  });

  const canCancelFastPass = computed(() => {
    return !!account.value?.has_soona_storage;
  });

  const hasPremiumEditsAccess = computed(() => {
    // can be removed when subscription feb launch is solid
    return !!(hasTierOneSubscription.value || hasTierTwoSubscription.value);
  });

  const subscriptionShortName = computed(() => {
    if (
      account.value?.subscription?.tier &&
      account.value?.subscription?.is_live
    ) {
      return account.value.subscription.tier.short_name;
    } else {
      return null;
    }
  });

  const subscriptionItems = computed(() => {
    return account.value?.subscription?.subscription_items;
  });

  const userCanUpgrade = computed(() => {
    if (!hasLiveSubscription.value || canUpgradeFlag.value) {
      return true;
    }
    if (fastPassOnTrialFlag.value && account.value?.is_trialing) {
      return true;
    }
    return false;
  });

  return {
    canEnrollFastPass,
    canCancelFastPass,
    hasPremiumEditsAccess,
    hasLiveSubscription,
    hasTierOneSubscription,
    hasTierTwoSubscription,
    hasTierThreeSubscription,
    hasFastPassTierSubscription,
    subscriptionStartDate,
    error,
    isLoading,
    status,
    isSuccess,
    subscriptionShortName,
    subscriptionTier,
    subscriptionItems,
    userCanUpgrade,
  };
}

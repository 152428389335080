import { computed } from 'vue';

/**
 *
 * @param {Array} args
 * @param {Object} params
 * @param {number} [params.itemsPerPage]
 * @param {Object} [params.filterArgs]
 * @param {Object} [params.filters]
 * @param {Function} [params.query]
 * @param {UseQueryOptions} queryOptions
 * @returns {Object}
 */
export function useDownloadFacets(
  args,
  { filterArgs, itemsPerPage, filters, query },
  queryOptions
) {
  if (!query) return {};
  let queryArgs = [];
  if (!args?.length) {
    // func({ objArg1, objArg2 }, queryOptions)
    queryArgs = [
      {
        ...filterArgs,
        itemsPerPage,
        filters,
      },
      queryOptions,
    ];
  } else {
    // func(arg1, arg2, { objArg1, objArg2 }, queryOptions)
    queryArgs = [
      ...args,
      {
        ...filterArgs,
        itemsPerPage,
        filters,
      },
      queryOptions,
    ];
  }

  const { data: facets, isFetching, isLoading, error } = query(...queryArgs);

  const photoCount = computed(() => {
    return facets.value?.photo_count;
  });

  const gifCount = computed(() => {
    return facets.value?.gif_count;
  });

  const videoCount = computed(() => {
    return facets.value?.video_count;
  });

  const showWebDownload = computed(() => {
    return !!(photoCount.value || gifCount.value);
  });

  return {
    facets,
    photoCount,
    gifCount,
    videoCount,
    showWebDownload,
    isFetching,
    isLoading,
    error,
  };
}

<script setup>
const props = defineProps({
  authStep: String,
  firstName: String,
  switchForms: Function,
});
</script>
<template>
  <div class="auth-titles">
    <div
      v-if="props.authStep === 'sign_up'"
      class="auth-titles__heading-container"
    >
      <h1 class="auth-titles__heading">👋 create an account</h1>
      <p class="auth-titles__subtitle">
        booked with us before?
        <button
          class="auth-titles__link-btn"
          @click="props.switchForms('email')"
        >
          sign in
        </button>
      </p>
    </div>
    <div
      v-if="props.authStep === 'email' || props.authStep === 'password'"
      class="auth-titles__heading-container"
    >
      <h1 class="auth-titles__heading">👋 welcome back!</h1>
      <p class="auth-titles__subtitle">
        sign in to continue. new to soona?
        <button
          class="auth-titles__link-btn"
          @click="props.switchForms('sign_up')"
        >
          sign up
        </button>
      </p>
    </div>

    <div
      v-if="props.authStep === 'google_sso'"
      class="auth-titles__heading-container"
    >
      <h1 class="auth-titles__heading">
        👋 welcome back, {{ props.firstName }}
      </h1>
    </div>
  </div>
</template>
<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.auth-titles {
  &__heading-container {
    margin-bottom: 1rem;
  }

  &__heading {
    @include variables_fonts.u-headline--heavy;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
  }

  &__link-btn {
    background-color: transparent;
    color: variables.$black-default;
    padding: 0;
    text-decoration: underline;
  }
}
</style>

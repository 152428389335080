<script setup>
import { computed, ref, watch } from 'vue';
import { useReservationTags } from '@/queries/reservation-tags/useReservationTags';
import { useReservation } from '@/composables/useReservation';
import SoonaTagOptionCard from '@/components/ui_library/soona_tag_option_card/SoonaTagOptionCard.vue';

const props = defineProps({
  reservationId: {
    required: true,
    default: null,
    type: String,
  },
  addOnCategory: {
    required: true,
    type: String,
  },
  heading: {
    required: true,
    type: String,
  },
  copy: {
    required: false,
    type: String,
  },
  products: {
    required: true,
    type: Array,
  },
  discountObj: {
    type: Object,
    required: false,
    default: null,
  },
});

const emits = defineEmits(['select', 'deselect', 'productPriceInfoUpdate']);

const reservationId = computed(() => props.reservationId);
const addOnCategory = computed(() => props.addOnCategory);

// api calls
const { proServiceSlugForSize } = useReservation(reservationId);

const discountId = computed(() => props.discountObj?.id);

const { data: addOnData } = useReservationTags(
  reservationId,
  proServiceSlugForSize,
  addOnCategory,
  discountId
);

watch(addOnData, newVal => {
  if (!newVal) return;

  let newPriceObject = {};
  newVal?.options?.map(option => {
    if (option?.product)
      newPriceObject[option?.product?.id] = option?.product?.price_info;
  });
  emits('productPriceInfoUpdate', newPriceObject);
});

const addOnOptions = computed(() => {
  return addOnData.value?.options || [];
});

let buttonsLoading = ref([]);

function removeFromButtonList(option) {
  buttonsLoading.value = buttonsLoading.value?.filter(o => o !== option.tag.id);
}

function isLoading(id) {
  return buttonsLoading.value?.includes(id);
}

const isBusy = computed(() => {
  return isLoading.value;
});

// products
function productInCart(product) {
  return props.products.some(p => p.id === product?.id);
}

function onOptionChange(option, quantity) {
  if (buttonsLoading.value?.includes(option.tag.id) || isBusy.value) return;

  buttonsLoading.value = [...buttonsLoading.value, option.tag.id];

  const beingSelected = !productInCart(option.product);

  if (beingSelected) {
    emits('select', { product: option.product, quantity: quantity });
    removeFromButtonList(option);
  } else {
    emits('deselect', option.product);
    removeFromButtonList(option);
  }
}
</script>
<template>
  <h3 class="order-builder__selector-section__heading">
    {{ heading }}
  </h3>
  <div class="order-builder__selector-section__copy">{{ copy }}</div>
  <div class="options-container">
    <SoonaTagOptionCard
      v-for="option in addOnOptions"
      :key="option.tag.id"
      :description="option.tag.description"
      :show-tag-description="false"
      :modal-description="option.tag.description_long"
      :has-carousel="false"
      :has-more-details="true"
      :has-quantity-incrementor="true"
      :has-shadow="true"
      :primary-image="option.tag.image_url"
      :in-bag="productInCart(option.product)"
      :is-loading="isLoading(option.tag.id)"
      :max-quantity="10"
      :min-quantity="1"
      :price-info="option.product?.price_info"
      :tag-id="option.tag.id"
      :title="option.tag.title"
      title-element="h2"
      modal-title-element="h3"
      :has-pro-service-comparison="option.tag.has_comparison_image_tags"
      :pro-service-name="option.tag.title"
      :modal-subtitle="option.tag.sub_title_alt"
      :modal-subcopy="option.tag.summary"
      button-copy="add"
      layout="vertical"
      @action="onOptionChange(option, $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.options-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(14rem, 100%), 1fr));
  width: 100%;
  gap: 1rem;
}

.order-builder {
  &__selector-section {
    &__heading {
      @include variables_fonts.u-title--heavy;
      color: variables.$black-default;
      margin-bottom: 1.5rem;
    }

    &__copy {
      padding-bottom: 2rem;
    }
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { getSharedGallery } from '@/api/shared_gallery';

export function useSharedGallery(
  sharedGalleryHash,
  { currentPage, itemsPerPage } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.sharedGallery(sharedGalleryHash, {
      currentPage,
      itemsPerPage,
    }),
    queryFn: ({ signal }) =>
      getSharedGallery(
        unref(sharedGalleryHash),
        {
          currentPage: unref(currentPage),
          itemsPerPage: unref(itemsPerPage),
        },
        signal
      ),
    ...queryOptions,
  });
}

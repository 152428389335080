<script setup>
import { computed, watch } from 'vue';
import SoonaActionBar from '@/components/ui_library/SoonaActionBar.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBulkHideDigitalAssets } from '@/queries/digital_assets/useBulkHideDigitalAssets';
import useActionBarMediaQuery from '../digital_asset_multiselect/useActionBarMediaQuery';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
  isChatOpen: {
    type: Boolean,
    default: false,
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
  reservationId: {
    type: [String, Number],
    default: null,
  },
});

const emit = defineEmits(['close']);

const accountId = computed(() => props.accountId);

const selectedAssets = computed(() => props.selectedAssets);
const numberOfAssetsSelected = computed(() => selectedAssets.value.length);

// hide files module
const {
  mutate: hideDigitalAssets,
  isPending,
  error: hideDigitalAssetsError,
  reset: resetHideDigitalAssets,
} = useBulkHideDigitalAssets(accountId);

const bulkHideFiles = () => {
  const ids = selectedAssets.value.map(ecda => ecda.digital_asset.id);
  hideDigitalAssets(ids, {
    onSuccess: () => {
      emit('close');
    },
  });
};

// loading state, errors, display, etc.
const { actionBarIsMobile } = useActionBarMediaQuery();

const isIconButton = computed(
  () => actionBarIsMobile.value || props.isChatOpen
);

watch(numberOfAssetsSelected, newSelectedCount => {
  if (newSelectedCount === 0) {
    resetHideDigitalAssets();
  }
});

const hasError = computed(() => {
  return [hideDigitalAssetsError.value].some(err => !!err);
});

const invalidAssetsSelected = computed(() => {
  // hiding re-edits will break re-edit requests
  return selectedAssets.value.some(ecda => ecda.edit_status !== 'accepted');
});
</script>

<template>
  <SoonaActionBar
    class="digital-asset-edits-multi-select-action-bar"
    :display="numberOfAssetsSelected > 0"
    :page-bounding-rect-left="pageBoundingRectLeft"
    :page-bounding-rect-width="pageBoundingRectWidth"
  >
    <template #content>
      <SoonaError
        v-if="hasError"
        no-margin
        class="digital-asset-multi-select-action-bar__error"
      >
        something went wrong. please try again.
      </SoonaError>
      <p class="u-label--heavy">
        {{ numberOfAssetsSelected }}
        asset{{ numberOfAssetsSelected > 1 ? 's' : '' }}
        selected
      </p>
    </template>
    <template #actions>
      <SoonaButton
        size="medium"
        :variation="isIconButton ? 'icon-transparent' : 'secondary-transparent'"
        :disabled="isPending"
        @on-click="$emit('close')"
      >
        <SoonaIcon v-if="isIconButton" name="xmark" />
        <span :class="{ 'u-visually-hidden': isIconButton }">cancel</span>
      </SoonaButton>
      <div class="organize-menu">
        <SoonaDropdownMenu
          :variation="isIconButton ? 'icon-gray-outline' : 'secondary-black'"
          :display-caret="false"
        >
          <template #trigger-content>
            <SoonaIcon name="plus" />
            <span :class="{ 'u-visually-hidden': isIconButton }">organize</span>
          </template>
          <template #default="{ keydown, mouseover, clickCapture }">
            <SoonaDropdownMenuItem>
              <button
                role="menuitem"
                class="organize-menu__item"
                :disabled="invalidAssetsSelected"
                @click="bulkHideFiles"
                @keydown="keydown"
                @mouseover="mouseover"
                @click.capture="clickCapture"
              >
                <SoonaIcon name="eye-slash" />
                hide
              </button>
            </SoonaDropdownMenuItem>
          </template>
        </SoonaDropdownMenu>
      </div>
    </template>
  </SoonaActionBar>
</template>

<style lang="scss" scoped>
.digital-asset-edits-multi-select-action-bar {
  &__error {
    margin-bottom: 1rem;
  }
}

.organize-menu {
  margin-left: auto;
}
</style>

<script setup>
import { computed, ref, toRef } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import BookingLayout from '../BookingLayout.vue';
import faqData from './faq.json';
import ScenesContainer from 'src/components/booking/v3/p2/ScenesContainer.vue';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityError } from 'src/composables/usePriorityError';
import TagRequirementDialog from '@/components/booking/v3/p2/TagRequirementDialog.vue';

// data & props
const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const { linkClicked, itemSelected } = useBaseEvents();

let buttonsLoading = ref([]);

// api calls
const { data: flatBackdropOptionsData } = useReservationTags(
  toRef(props, 'reservationId'),
  ref('scenes'),
  ref('flat_backdrop')
);

const { data: lifeStyleOptionsData, error: resTagsError } = useReservationTags(
  toRef(props, 'reservationId'),
  ref('scenes'),
  ref('lifestyle')
);

const {
  data: reservation,
  isLoading,
  error,
} = useReservation(toRef(props, 'reservationId'));

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(toRef(props, 'reservationId'));

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(toRef(props, 'reservationId'));

const priorityError = usePriorityError(
  removeError,
  addError,
  resTagsError,
  error
);

// computed
const isBusy = computed(() => {
  return isLoading.value || isAdding.value || isRemoving.value;
});

const currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});

const flatBackdropOptions = computed(
  () => flatBackdropOptionsData.value?.options || []
);

const lifeStyleOptions = computed(
  () => lifeStyleOptionsData.value?.options || []
);

const selectedOptions = computed(() => {
  return [...flatBackdropOptions.value, ...lifeStyleOptions.value].filter(
    o => o.selected
  );
});

const hasReachedMaxSelected = computed(() => {
  return selectedOptions.value?.length >= 2;
});

const isStepValid = computed(() => {
  return !isBusy.value && currentStep.value?.complete;
});

// methods
function onBackClick() {
  props.onBack();
}

function onNextClick() {
  props.onNext();
}

function removeFromButtonList(tagId) {
  buttonsLoading.value = buttonsLoading.value?.filter(o => o !== tagId);
}

function addOrRemoveSelection({
  option,
  addRecommended = false,
  trackItemSelected = true,
}) {
  const beingSelected = !option.selected;

  if (beingSelected) {
    addReservationTag(
      { tagId: option.tag.id, addRecommended: addRecommended },
      {
        onSettled: () => removeFromButtonList(option.tag.id),
        onSuccess: () => {
          if (trackItemSelected) {
            itemSelected({
              context: 'booking',
              subContext: 'purpose',
              itemLabel: option?.tag?.title,
              itemPrice: 0,
              itemQuantity: 1,
            });
          }
        },
      }
    );
  } else {
    removeReservationTag(
      {
        reservationTagId: option.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSettled: () => removeFromButtonList(option.tag.id),
      }
    );
  }
}

const isTagRequirementDialogOpen = ref(false);
const tagRequirement = ref({});
const currentOption = ref({});

function handleOpenTagRequirementDialog(option, requirement) {
  tagRequirement.value = requirement;
  currentOption.value = option;
  isTagRequirementDialogOpen.value = true;
}

function handleCloseTagRequirementDialog() {
  removeFromButtonList(currentOption.value.tag.id);
  tagRequirement.value = {};
  currentOption.value = {};
  isTagRequirementDialogOpen.value = false;
}

function addTagsToReservation(addRecommended = true) {
  addOrRemoveSelection({
    option: currentOption.value,
    addRecommended: addRecommended,
    trackItemSelected: false,
  });
  isTagRequirementDialogOpen.value = false;
  currentOption.value = {};
}

function addSelectedTagToReservation() {
  addTagsToReservation(false);
}

function onSelectionChange(option) {
  const tagRequirement = option.tag.tag_requirements.length
    ? option.tag.tag_requirements[0]
    : false;

  if (tagRequirement && !option.selected) {
    linkClicked({
      context: 'booking',
      subContext: 'video scenes page',
      linkLabel: option?.tag?.title,
      linkHref: '#add-to-shoot',
    });
    handleOpenTagRequirementDialog(option, tagRequirement);
  } else {
    addOrRemoveSelection({ option: option });
  }
}

const updateButtonList = id => {
  if (buttonsLoading.value?.includes(id) || isBusy.value) return;

  buttonsLoading.value = [...buttonsLoading.value, id];
};
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="video-scenes prebooking-transition__content">
        <SoonaHeading title-element="h1">
          choose your scenes
          <template #subtitle>
            pick 1 to 2 scenes for this shoot. you can customize the details
            after you complete your booking in our shot list builder.
          </template>
        </SoonaHeading>
        <ScenesContainer
          :buttons-loading="buttonsLoading"
          :has-reached-max-selected="hasReachedMaxSelected"
          :is-mutating="isBusy"
          :on-click="onSelectionChange"
          :options="flatBackdropOptions"
          title="flat backdrop"
          @update-button-list="updateButtonList"
        />
        <ScenesContainer
          :buttons-loading="buttonsLoading"
          :has-reached-max-selected="hasReachedMaxSelected"
          :is-mutating="isBusy"
          :on-click="onSelectionChange"
          :options="lifeStyleOptions"
          title="lifestyle"
          @update-button-list="updateButtonList"
        />
        <SoonaFaq :faq-tab-list="faqData" />
        <TagRequirementDialog
          v-if="isTagRequirementDialogOpen"
          :reservation-id="reservationId"
          :tag-requirement="tagRequirement"
          segment-context="booking"
          segment-sub-context="video scenes page"
          @cancel-clicked="handleCloseTagRequirementDialog"
          @confirm-clicked="addTagsToReservation"
          @secondary-confirm-clicked="addSelectedTagToReservation"
        ></TagRequirementDialog>
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.video-scenes {
  color: variables.$black-default;

  &__scenes-wrapper {
    border-top: 0.0625rem solid variables.$gray-30;
    padding-top: 0.5rem;
    width: 100%;
  }

  &__category-title {
    @include variables_fonts.u-label--heavy;

    padding-bottom: 1.5rem;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-body--heavy;
    }
  }

  &__grid {
    display: grid;
    gap: 1.5rem 1rem;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 2rem;

    @media (min-width: variables.$screen-sm-min) {
      gap: 1.5rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__recommendation {
    align-items: center;
    display: flex;

    svg {
      flex: 0 0 1.75rem;
      margin-right: 0.5rem;
    }
  }
}
</style>

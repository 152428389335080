import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { toValue } from 'vue';

/**
 * @param {import('vue').MaybeRefOrGetter<number | string>} accountId
 */
export function useCreateAccountRequirement(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.post(
        `/accounts/${toValue(accountId)}/account_requirements`,
        body
      );

      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.accountRequirements(accountId),
      });
    },
  });
}

<script setup>
import { computed } from 'vue';
import BaseInfiniteGalleryCard from '@/components/infinite_asset_gallery/BaseInfiniteGalleryCard.vue';
import OverlayButtonToggle from '@/components/infinite_asset_gallery/OverlayButtonToggle.vue';
import DigitalAssetStatusBadge from '@/components/infinite_asset_gallery/DigitalAssetStatusBadge.vue';

const props = defineProps({
  alwaysShowTitles: {
    type: Boolean,
    default: false,
  },
  editsCollectionDigitalAsset: {
    type: Object,
    required: true,
  },
  filter: {
    type: String,
    required: false,
  },
  flexGrow: {
    type: Number,
    default: 0,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  reservationId: {
    type: [String, Number],
  },
  selectDisabled: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

defineEmits([
  'request-page',
  'open-assign-original-dialog',
  'accept',
  'pend',
  'reject',
  'hide',
  'show',
  'selection-click',
]);

const editsCollectionDigitalAsset = computed(
  () => props.editsCollectionDigitalAsset
);

const hasOriginal = computed(
  () => !!props.editsCollectionDigitalAsset.digital_asset.value?.original_id
);
const isAccepted = computed(
  () => editsCollectionDigitalAsset.value.edit_status === 'accepted'
);

const isPending = computed(
  () => editsCollectionDigitalAsset.value.edit_status === 'pending'
);

const isCustomerRejected = computed(
  () => editsCollectionDigitalAsset.value.edit_status === 'customer_rejected'
);

const isEditorRejected = computed(
  () => editsCollectionDigitalAsset.value.edit_status === 'rejected'
);

const isHidden = computed(
  () => editsCollectionDigitalAsset.value?.digital_asset?.visibility !== 'all'
);
</script>
<template>
  <BaseInfiniteGalleryCard
    :selected="selected"
    select-visible
    :select-disabled="selectDisabled"
    :digital-asset="editsCollectionDigitalAsset.digital_asset"
    :paged-asset="editsCollectionDigitalAsset"
    :flex-grow="flexGrow"
    :always-show-title="alwaysShowTitles"
    :show-action-buttons="true"
    :to="{
      name: 'crew-edits-asset-view',
      params: {
        reservationId: reservationId,
        digitalAssetId: editsCollectionDigitalAsset?.digital_asset?.id,
      },
      query: { filter },
    }"
    @request-page="page => $emit('request-page', page)"
    @selection-click="$emit('selection-click', $event)"
  >
    <template #action-buttons>
      <OverlayButtonToggle
        v-if="isHidden && isAccepted"
        visually-hidden-text="show hidden edit"
        icon-name="eye"
        @click="$emit('show')"
      >
        <template #tooltip-content>show hidden edit</template>
      </OverlayButtonToggle>
      <OverlayButtonToggle
        v-else-if="!isHidden && isAccepted"
        visually-hidden-text="hide delivered edit"
        icon-name="eye-slash"
        @click="$emit('hide')"
      >
        <template #tooltip-content>hide delivered edit</template>
      </OverlayButtonToggle>
      <OverlayButtonToggle
        v-if="!hasOriginal"
        visually-hidden-text="assign edit to original"
        icon-name="broken-link"
        @click="$emit('open-assign-original-dialog')"
      >
        <template #tooltip-content>assign original</template>
      </OverlayButtonToggle>
      <OverlayButtonToggle
        v-if="isPending"
        :loading="loading"
        visually-hidden-text="deliver immediately"
        icon-name="circle-check"
        @click="$emit('accept')"
      >
        <template #tooltip-content>deliver immediately to customer</template>
      </OverlayButtonToggle>
      <OverlayButtonToggle
        v-if="isPending"
        :loading="loading"
        visually-hidden-text="reject"
        icon-name="circle-xmark"
        @click="$emit('reject')"
      >
        <template #tooltip-content>reject</template>
      </OverlayButtonToggle>
      <OverlayButtonToggle
        v-if="isEditorRejected"
        :loading="loading"
        visually-hidden-text="make pending"
        icon-name="flip-backward"
        @click="$emit('pend')"
      >
        <template #tooltip-content>make pending</template>
      </OverlayButtonToggle>
    </template>
    <template #statuses>
      <DigitalAssetStatusBadge v-if="isPending">
        pending
      </DigitalAssetStatusBadge>
      <DigitalAssetStatusBadge v-else-if="isCustomerRejected">
        re-edit
      </DigitalAssetStatusBadge>
      <DigitalAssetStatusBadge v-else-if="isEditorRejected">
        editor rejected
      </DigitalAssetStatusBadge>
      <DigitalAssetStatusBadge v-else-if="isAccepted">
        delivered
      </DigitalAssetStatusBadge>
    </template>
  </BaseInfiniteGalleryCard>
</template>

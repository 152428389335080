import * as Sentry from '@sentry/vue';

export const PREFIX = '[VUE SOCKET MANAGER]: ';

const log = method => {
  return (...messages) => {
    if (method === 'error') {
      Sentry.captureException(
        new Error(PREFIX + messages.join(), { extra: { messages } })
      );
    }

    if (import.meta.env.DEV) {
      console[method](PREFIX, ...messages);
    }
  };
};

export const info = log('info');
export const debug = log('debug');
export const warn = log('warn');
export const error = log('error');

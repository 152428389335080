<template>
  <div class="EnrollConfirmFP fp-card">
    <span>
      <div class="fp-content">
        <lottie-player
          class="fp-icon"
          alt="fast pass ticket"
          autoplay
          loop
          src="@images/anim/lottie-fast-pass-tkt.lott"
        />
        <span class="right-content">
          <p class="subtitle">
            {{ copyObject.title }}
          </p>
          <p class="fp-copy text-s">
            {{ copyObject.body }}
          </p>
        </span>
      </div>
    </span>
    <div class="btns-wrapper">
      <span v-if="isSoonaStaff">
        <div v-if="soonaStoragePendingCancellation" class="fp-action">
          <button class="button is-text text-s pending-cancel" disabled>
            this membership will be canceled on {{ endDate }}
          </button>
        </div>
        <div v-else class="fp-action">
          <router-link
            class="button is-text text-s fp-btn see-admin-text margin-bottom"
            :to="{ path: `/account/${account.id}/crew-tools` }"
          >
            go to crew tools
          </router-link>
        </div>
      </span>
      <span v-else>
        <div v-if="canManageFastPass" class="fp-action">
          <button
            :class="enrollBtnClass"
            :disabled="soonaStoragePendingCancellation"
            @click="actionLinkClick"
            v-html="copyObject.linkMsg"
          ></button>
          <button
            v-if="soonaStoragePendingCancellation"
            class="blue-text"
            :class="enrollBtnClass"
            @click="actionLinkClick"
          >
            re-enroll in fast pass
          </button>
        </div>
        <div v-else class="fp-action">
          <span :class="'button enroll-btn mt-m disabled'">
            see account admin
          </span>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import dateTimeMixin from '@/mixins/dateTimeMixin';
import { useAccount } from '@/composables/useAccount';
import { computed } from 'vue';
import { useCapability } from '@/composables/useCapability';

export default {
  name: 'EnrollConfirmFP',
  mixins: [dateTimeMixin],
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const accountId = computed(() => props.account?.id);

    const { hasSoonaStorage, soonaStoragePendingCancellation } =
      useAccount(accountId);

    const { hasCapability: isSoonaStaff } = useCapability({
      capability: 'soona_staff',
    });

    const { hasCapability: canManageFastPass } = useCapability({
      capability: 'manage_fastpass',
      subjectType: 'account',
      subjectId: accountId,
    });

    return {
      canManageFastPass,
      hasSoonaStorage,
      isSoonaStaff,
      soonaStoragePendingCancellation,
    };
  },
  data() {
    return {
      copyObject: {
        title: '',
        body: '',
        linkMsg: '',
      },
    };
  },
  computed: {
    endDate() {
      return this.convertToMMDDYYYYlong(
        this.account.subscription.current_period_end
      );
    },
    enrollBtnClass() {
      if (this.soonaStoragePendingCancellation) {
        return 'button is-text text-s pending-cancel';
      } else {
        return 'button enroll-btn mt-m';
      }
    },
  },
  mounted() {
    this.setCopy();
  },
  methods: {
    setCopy() {
      if (this.hasSoonaStorage) {
        this.copyObject = {
          title: 'you’re on the fast track!',
          body: 'ship, store, shoot',
          linkMsg: this.soonaStoragePendingCancellation
            ? `pending cancellation on ${this.endDate}`
            : 'cancel membership',
        };
      } else {
        this.copyObject = {
          title: 'want to get on the fast track?',
          body: 'join soona fast pass to store your products at soona and unlock quicker scheduling',
          linkMsg: 'enroll in fast pass',
        };
      }
    },
    actionLinkClick() {
      if (this.hasSoonaStorage && this.soonaStoragePendingCancellation) {
        this.$router.push(`/account/${this.account.id}/subscriptions`);
      } else if (this.hasSoonaStorage) {
        this.$router.push(`/account/${this.account.id}/cancel-fast-pass`);
      } else {
        this.$router.push(
          `/account/${this.account.id}/subscriptions/add-ons/fast-pass`
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';
.EnrollConfirmFP {
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: variables.$white-default !important;
  .fp-btn {
    color: variables.$periwink-blue-60;
  }
  .pending-cancel {
    text-decoration: none;
    color: red;
  }
  .fp-action {
    display: flex;
    justify-content: flex-end;
    .blue-text {
      color: variables.$periwink-blue-60;
      text-decoration: underline;
    }
  }
  .fp-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .subtitle {
      font-size: 20px;
      margin-bottom: 10px;
      color: variables.$black-default;
    }
    .fp-copy {
      font-size: 14px;
      margin-top: -8px;
    }
  }
  .fp-icon {
    height: 120px;
    width: 120px;
    margin-right: 1.5rem;
    line-height: 48px;
  }
  .btns-wrapper {
    .enroll-btn {
      background: variables.$friendly-red-50;
      color: variables.$white-default;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1px;
      font-weight: 800;
      padding: 0.5em 1.5em 0.5em 1.5em;
      height: 3em;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .see-admin-text {
      opacity: 0.8;
      color: variables.$gray-90;
      text-decoration: none;
      &.margin-bottom {
        margin-top: -20px;
      }
    }
  }
}
</style>

<script setup>
import { computed, onMounted } from 'vue';
import FreemiumExperience from './FreemiumExperience.vue';
import AccountInfo from '@/components/user/anytime/dashboard/AccountInfo.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import DashboardHeader from './DashboardHeader.vue';
import SoonaScore from './SoonaScore.vue';
import TopOpportunities from './TopOpportunities.vue';
import PriorityListings from './PriorityListings.vue';
import { useCapability } from '@/composables/useCapability';
import { useIntegrations } from '@/composables/useIntegrations';
import { useAccount } from '@/queries/account/useAccount';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useTitle } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const props = defineProps({
  accountId: { type: String, required: true },
});

useTitle('listing insights | soona');

const { pageViewed } = useBaseEvents();
onMounted(() => {
  pageViewed();
});

const accountId = computed(() => props.accountId);

const { hasActionableIntegrations, integrationsError, integrationsSuccess } =
  useIntegrations(accountId);

const displayMarketingInfo = computed(
  () => integrationsSuccess.value && !hasActionableIntegrations.value
);

// account info
const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});
const { data: account, error: accountInfoError } = useAccount(accountId, {
  enabled: isFtSoonaStaff,
});

const priorityError = usePriorityError(integrationsError, accountInfoError);
</script>

<template>
  <FreemiumExperience v-if="displayMarketingInfo" :account-id="accountId" />
  <div v-else class="listing-insights-dashboard">
    <AccountInfo
      v-if="isFtSoonaStaff && account"
      :account="account"
      :link-to-profile="true"
    />

    <DashboardHeader :account-id="accountId" />
    <SoonaError v-if="priorityError" no-margin>
      {{ priorityError }}
    </SoonaError>

    <div class="listing-insights-dashboard__sections">
      <SoonaScore :account-id="accountId" />
      <TopOpportunities :account-id="accountId" />
      <PriorityListings :account-id="accountId" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-insights-dashboard {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  &__sections {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
}
</style>

import { http } from '@/config/vue-axios';

export async function acceptTrialSubscription(params) {
  const body = { subscription: params };
  let endpoint = '';

  if (params.accepting_same_tier) {
    endpoint = `accept_trial`;
  } else {
    endpoint = `update_trial`;
  }

  const response = await http.post(
    `/subscriptions/${params.subscription_id}/${endpoint}.json`,
    body
  );

  return response.data;
}

export async function addBusinessSubscriptionItem(params) {
  const body = { subscription: {} };

  if (Object.hasOwn(params, 'accountId')) {
    body.subscription.account_id = params.accountId;
  }

  const response = await http.post(
    '/subscriptions/add_business_subscription.json',
    body
  );

  return response.data;
}

export async function removeBusinessSubscriptionItem(params) {
  const body = { subscription: {} };

  if (Object.hasOwn(params, 'accountId')) {
    body.subscription.account_id = params.accountId;
  }

  const response = await http.patch(
    '/subscriptions/remove_business_subscription.json',
    body
  );

  return response.data;
}

export async function createInvoicedSubscription(params) {
  const body = { subscription: {} };

  if (Object.hasOwn(params, 'accountId')) {
    body.subscription.account_id = params.accountId;
  }
  if (Object.hasOwn(params, 'billingCycleAnchor')) {
    body.subscription.billing_cycle_anchor = params.billingCycleAnchor;
  }
  if (Object.hasOwn(params, 'priceIds')) {
    body.subscription.price_ids = params.priceIds;
  }
  if (Object.hasOwn(params, 'slug')) {
    body.slug = params.slug;
  }
  if (Object.hasOwn(params, 'startDate')) {
    body.subscription.start_date = params.startDate;
  }

  const response = await http.post('/subscriptions/create_invoiced.json', body);

  return response.data;
}

export async function createSubscription(params) {
  const body = { subscription: {} };

  if (Object.hasOwn(params, 'accountId')) {
    body.subscription.account_id = params.accountId;
  }
  if (Object.hasOwn(params, 'billingCycleAnchor')) {
    body.subscription.billing_cycle_anchor = params.billingCycleAnchor;
  }
  if (Object.hasOwn(params, 'card')) {
    body.card = params.card;
  }
  if (Object.hasOwn(params, 'isInternal')) {
    body.is_internal = params.isInternal;
  }
  if (Object.hasOwn(params, 'isTrial')) {
    body.is_trial = params.isTrial;
  }
  if (Object.hasOwn(params, 'priceIds')) {
    body.subscription.price_ids = params.priceIds;
  }
  if (Object.hasOwn(params, 'saveCard')) {
    body.save_card = params.saveCard;
  }
  if (Object.hasOwn(params, 'slug')) {
    body.slug = params.slug;
  }
  if (Object.hasOwn(params, 'startDate')) {
    body.subscription.start_date = params.startDate;
  }
  if (Object.hasOwn(params, 'termsAccepted')) {
    body.terms_accepted = params.termsAccepted;
  }
  if (Object.hasOwn(params, 'token')) {
    body.token = params.token;
  }
  if (Object.hasOwn(params, 'useDefaultMethod')) {
    body.use_default_method = params.useDefaultMethod;
  }
  if (Object.hasOwn(params, 'shopifyPaymentShopDomain')) {
    body.subscription.shopify_payment_shop_domain =
      params.shopifyPaymentShopDomain;
  }
  if (Object.hasOwn(params, 'discount')) {
    body.discount = params.discount;
  }
  if (Object.hasOwn(params, 'reservationId')) {
    body.reservation_id = params.reservationId;
  }
  if (Object.hasOwn(params, 'taxCalculationId')) {
    body.subscription.tax_calculation_id = params.taxCalculationId;
  }

  const response = await http.post('/subscriptions.json', body);

  return response.data;
}

export async function createSubscriptionItem(params) {
  const body = { subscription_item: params };

  const response = await http.post(`/subscription_items.json`, body);

  return response.data;
}

export async function cancelSubscriptionItem(params) {
  const body = { subscription_item: params };

  const response = await http.post(
    `/subscription_items/${params.remove_item_id}/cancel.json`,
    body
  );

  return response.data;
}

export async function cancelSubscription(subscriptionId, params) {
  const body = {};

  if (Object.hasOwn(params, 'isInternal')) {
    body.is_internal = params.isInternal;
  }
  if (Object.hasOwn(params, 'cancellationReason')) {
    body.cancellation_reason = params.cancellationReason;
  }
  if (Object.hasOwn(params, 'cancellationDetails')) {
    body.cancellation_details = params.cancellationDetails;
  }

  const response = await http.post(
    `/subscriptions/${subscriptionId}/cancel.json`,
    body
  );

  return response.data;
}

export async function renewSubscription(subscriptionId, params) {
  const body = {};

  if (Object.hasOwn(params, 'isInternal')) {
    body.is_internal = params.isInternal;
  }

  const response = await http.put(
    `/subscriptions/${subscriptionId}/renew.json`,
    body
  );

  return response.data;
}

export async function getCancellationReasons(signal) {
  const response = await http.get('/subscriptions/cancellation_reasons.json', {
    signal,
  });

  return response.data;
}

export async function getProratedAmount(subscriptionId, priceId, signal) {
  const params = {
    price_id: priceId,
  };

  const response = await http.get(
    `/subscriptions/${subscriptionId}/prorated_items.json`,
    {
      params,
      signal,
    }
  );

  return response.data;
}

export async function getDefaultSubscriptionPaymentMethod(
  subscriptionId,
  signal
) {
  if (!subscriptionId) {
    return null;
  }
  const response = await http.get(
    `/subscriptions/${subscriptionId}/default_payment_method.json`,
    {
      signal,
    }
  );
  return response.data;
}

export async function updateDefaultSubscriptionPaymentMethod(
  subscriptionId,
  paymentMethodId
) {
  const response = await http.post(
    `/subscriptions/${subscriptionId}/update_default_payment_method.json`,
    {
      payment_method_id: paymentMethodId,
    }
  );

  return response.data;
}

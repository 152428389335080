import { unref, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { getPackConfiguration } from '@/api/packConfigurations';

/**
 *
 * @param {number | Ref<number>} packId
 */
export function usePackConfiguration({ packId } = {}) {
  return useQuery({
    queryKey: queryKeys.packConfiguration(packId),
    queryFn: ({ signal }) =>
      getPackConfiguration({ packId: unref(packId) }, signal),
    enabled: computed(() => !!packId.value),
  });
}

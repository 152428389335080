<script setup>
import { computed } from 'vue';
import ShowAccount from 'src/components/crew/booking/steps/customer/ShowAccount.vue';

const props = defineProps({
  accountId: {
    type: Number,
    required: false,
    default: null,
  },
});

const accountId = computed(() => props.accountId);
</script>

<template>
  <ShowAccount :account-id="accountId" />
</template>

<template>
  <div class="u-container reschedule-confirmation">
    <SoonaLoading v-if="!currentReservation.id" is-loading />
    <h1
      class="reschedule-confirmation__heading"
      data-cypress="header-appointment-confirmation"
    >
      we can’t wait to create with you on
      <span>{{ formattedStartTime }}</span>
    </h1>
    <div class="reschedule-confirmation__summary">
      <img src="@images/studio-banner.png" />
      <p class="u-body--heavy">
        {{ currentReservation.account.name }}
      </p>
      <p class="reschedule-confirmation__name">{{ currentReservation.name }}</p>
      <p class="reschedule-confirmation__description">
        {{ currentReservation.description }}
      </p>
    </div>
    <SoonaButton
      element="a"
      :href="`/reservations/${currentReservation.id}/download.ics`"
    >
      add to calendar
    </SoonaButton>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';

export default {
  name: 'RescheduleConfirmation',
  components: {
    SoonaButton,
    SoonaLoading,
  },
  computed: {
    ...mapGetters('reservation', ['currentReservation']),
    dateFormatter() {
      return new Intl.DateTimeFormat('default', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    timeFormatter() {
      return new Intl.DateTimeFormat('default', {
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      });
    },
    formattedStartTime() {
      if (!this.currentReservation.start) return null;

      const start = new Date(this.currentReservation.start);
      return `${this.dateFormatter.format(start)} @ ${this.timeFormatter.format(
        start
      )}`;
    },
  },
  mounted() {
    this.loadReservation(this.$route.params.reservationId);
  },
  methods: {
    ...mapActions('reservation', ['loadReservation']),
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.reschedule-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__heading {
    @include variables_fonts.u-title--heavy;

    margin-bottom: 2rem;
    text-align: center;
    width: 100%;

    @media (min-width: variables.$screen-md-min) {
      @include variables_fonts.u-display--heavy;
    }

    span {
      display: block;
    }
  }

  &__summary {
    margin-bottom: 2rem;
    max-width: 25rem;
    width: 100%;
  }

  &__name {
    @include variables_fonts.u-title--heavy;

    @media (min-width: variables.$screen-md-min) {
      @include variables_fonts.u-headline--heavy;
    }
  }

  &__description {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}
</style>

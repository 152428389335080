<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="$attrs.fill || '#000'"
      d="m8 2.667-.94.94 3.72 3.727H2.667v1.333h8.113l-3.72 3.727.94.94L13.333 8 8 2.667Z"
    />
  </svg>
</template>

import { computed, inject } from 'vue';

export const VALIDATE_INCOMPLETE_STEPS = Symbol('validate incomplete steps');

export function useIncompleteSteps(reservation, stepName = undefined) {
  const validateIncompleteSteps = inject(VALIDATE_INCOMPLETE_STEPS);

  const incompleteSteps = computed(
    () =>
      reservation?.value?.steps?.filter(
        x => x.complete !== true && x.required === true
      ) || []
  );

  const hasIncompleteSteps = computed(() => incompleteSteps.value?.length > 0);

  const missingStepsError = computed(() =>
    validateIncompleteSteps.value && hasIncompleteSteps.value
      ? new Error(
          'please complete the following: ' +
            incompleteSteps.value.map(x => x.button_text).join(', ')
        )
      : null
  );

  const stepIsIncomplete = computed(() => {
    if (
      validateIncompleteSteps.value &&
      stepName?.value &&
      incompleteSteps.value.find(x => x.slug === stepName.value)
    ) {
      return new Error('this step is required');
    }
    return null;
  });

  return {
    validateIncompleteSteps,
    hasIncompleteSteps,
    missingStepsError,
    incompleteSteps,
    stepIsIncomplete,
  };
}

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param { string | number } accountId
 * @param { string | number } collectionId
 */
export function useUpdateReEditsCollectionDigitalAsset(
  accountId,
  collectionId
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ cdaId, body }) => {
      const response = await http.put(
        `/collections/${toValue(
          collectionId
        )}/re_edits_collection_digital_assets/${toValue(cdaId)}`,
        {
          collection_digital_asset: body,
        }
      );
      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collectionsDA(accountId, 're_edits_collection'),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.collectionAssets(collectionId),
        }),
      ]);
    },
  });
}

<script setup>
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import UploadAssetCard from '@/components/account_gallery/UploadAssetCard.vue';

defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
  albumId: {
    default: null,
    type: [String, Number],
  },
  albumType: {
    default: null,
    type: String,
  },
});

const emits = defineEmits(['close']);
</script>

<template>
  <SoonaDialog @close="emits('close')">
    <template #heading>
      <h2>upload media</h2>
    </template>
    <UploadAssetCard
      :account-id="accountId"
      :album-id="albumId"
      :album-type="albumType"
      label="upload from device"
      sub-context="add media modal"
    />
  </SoonaDialog>
</template>

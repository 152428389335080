import { onKeyStroke } from '@vueuse/core';
import throttle from 'lodash/throttle';

export function useLeftRightArrowKeys(callbacks) {
  const { onLeftArrowKeyStroke, onRightArrowKeyStroke } = callbacks;

  const suppressedTargetTypes = [
    'textarea',
    'text',
    'search',
    'tel',
    'email',
    'range',
    'number',
    'password',
    'date',
    'url',
    'time',
  ];

  const modifierKeys = ['altKey', 'ctrlKey', 'metaKey', 'shiftKey'];

  const handleKeyStroke = throttle(e => {
    if (modifierKeys.some(prop => e[prop])) return;
    if (suppressedTargetTypes.includes(e.target.type)) return;
    if (
      Object.prototype.hasOwnProperty.call(
        e.target.attributes,
        'contenteditable'
      )
    )
      return;
    if (e.key === 'ArrowRight') {
      onRightArrowKeyStroke?.(e);
      return;
    }
    onLeftArrowKeyStroke?.(e);
  }, 120);

  onKeyStroke(['ArrowLeft', 'ArrowRight'], e => handleKeyStroke(e));
}

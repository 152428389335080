<script setup>
import { computed, nextTick, ref, toRef, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useReservationChat } from './useReservationChat';
import { generateComponentFilename } from './utils';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import NextStepsMessage from './messages/NextStepsMessage.vue';
import WelcomeMessage from './messages/WelcomeMessage.vue';
import StatusMessage from './messages/StatusMessage.vue';
import ChatActions from './ChatActions.vue';
import JoinChat from './JoinChat.vue';
import ChatStatus from './ChatStatus.vue';
import ChatError from './ChatError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import {
  useDebounceFn,
  useIntersectionObserver,
  useMediaQuery,
} from '@vueuse/core';
import receivedMessageAudio from 'audio/received-message.mp3';
import useActionBarMediaQuery from '@/components/user/anytime/reservation/gallery/digital_asset_multiselect/useActionBarMediaQuery';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

const props = defineProps({
  currentUserId: {
    type: Number,
    required: true,
  },
  isChatOpen: {
    default: false,
    type: Boolean,
  },
  isMediaView: {
    default: false,
    type: Boolean,
  },
  reservationCalUuid: {
    type: String,
    required: false,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  reservationStatus: {
    type: String,
    required: true,
  },
  reservationUserIds: {
    type: Array,
    required: true,
    validator: value =>
      Array.isArray(value) &&
      value.every(val => Number.isInteger(val) && val > 0),
  },
  userHasScrolledPastBreakpoint: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:isChatOpen']);

const chatModalCard = ref(null);
const chatScrollContainer = ref(null);
const startOfMessagesEl = ref(null);
const endOfMessagesEl = ref(null);
const isChatOpen = computed(() => props.isChatOpen);
const reservationId = computed(() => props.reservationId);
const currentUserId = computed(() => props.currentUserId);

const matchMediaIsWide = useMediaQuery('(min-width: 45rem)');

const inProgress = computed(() => props.reservationStatus === 'in_progress');
const inPendingSelects = computed(
  () => props.reservationStatus === 'pending_selects'
);

const notificationsDisabled = ref(false);
function toggleNotificationSounds() {
  notificationsDisabled.value = !notificationsDisabled.value;
}

function playReceivedMessageSound() {
  if (!notificationsDisabled.value) {
    const audio = new Audio(receivedMessageAudio);
    audio.play().catch(() => {
      /* empty */
    });
  }
}

const {
  connectionStatus,
  disabled,
  displayRefresh,
  error,
  isChatOn,
  isGhostUser,
  joinChat,
  loadPreviousMessages,
  markMessagesAsRead,
  members,
  messages,
  promptJoinGroup,
  refresh,
  sendMediaMessage,
  sendMessage,
  sendQuickReaction,
  unreadCount,
} = useReservationChat({
  calUuid: toRef(() => props.reservationCalUuid),
  visibleUserIds: toRef(() => props.reservationUserIds),
  onPlayReceivedMessageSound: playReceivedMessageSound,
});

const showUnreadButton = computed(() => unreadCount.value > 0);
const route = useRoute();
const isGallery = computed(() => route.meta?.chatIsInGallery);

function scrollToBottomOfChat() {
  if (chatScrollContainer.value) {
    chatScrollContainer.value.scrollTop =
      chatScrollContainer.value.scrollHeight;
    markMessagesAsRead();
  }
}

watch(messages, () => {
  if (!chatScrollContainer.value || !isChatOpen.value) return;
  // a watcher runs before the DOM is updated, so we can check the DOM now for the state before a message is added
  const isAtBottom =
    chatScrollContainer.value.scrollTop +
      chatScrollContainer.value.clientHeight +
      1 >=
    chatScrollContainer.value.scrollHeight;

  if (isAtBottom) {
    // run in nextTick so we can be sure the DOM has the new message as we can scroll to it
    nextTick(() => {
      scrollToBottomOfChat();
    });
  }
});

function toggleChat() {
  emit('update:isChatOpen', !isChatOpen.value);
}

watch(isChatOpen, newIsChatOpen => {
  if (newIsChatOpen) {
    nextTick(() => {
      scrollToBottomOfChat();
    });
  }
});

onMounted(() => {
  if (inProgress.value && !isChatOpen.value && matchMediaIsWide.value) {
    toggleChat();
  }
});

const paginationLoading = ref(false);
// wait 500ms between pagination loads to prevent UI thrashing
const debouncedSetPaginationLoadingFalse = useDebounceFn(() => {
  paginationLoading.value = false;
}, 500);

const { resume: resumeScrollTop, pause: pauseScrollTop } =
  useIntersectionObserver(
    startOfMessagesEl,
    async ([entry]) => {
      if (entry.isIntersecting && !paginationLoading.value) {
        // prevent additional calls while messages are loading
        pauseScrollTop();
        paginationLoading.value = true;
        const prevFirstMessage = messages.value[0];
        const limit = 30;
        const count = await loadPreviousMessages(limit);
        const messageEl = prevFirstMessage
          ? document.getElementById(
              `${prevFirstMessage.conversationId}-${prevFirstMessage.id}`
            )
          : null;

        if (messageEl && chatScrollContainer.value) {
          await nextTick(() => {
            chatScrollContainer.value.scroll({
              top: messageEl.offsetTop - messageEl.offsetHeight,
            });
            debouncedSetPaginationLoadingFalse();
          });
        } else {
          debouncedSetPaginationLoadingFalse();
        }

        if (count >= limit) {
          await nextTick(() => {
            resumeScrollTop();
          });
        }
      }
    },
    { threshold: 1, immediate: false, root: chatScrollContainer }
  );

watch(disabled, newDisabled => {
  if (newDisabled) {
    pauseScrollTop();
  } else {
    resumeScrollTop();
  }
});

const { resume: resumeScrollBottom, pause: pauseScrollBottom } =
  useIntersectionObserver(
    endOfMessagesEl,
    ([entry]) => {
      if (entry.isIntersecting) {
        markMessagesAsRead();
      }
    },
    { threshold: 1, root: chatScrollContainer }
  );

watch(unreadCount, newUnreadCount => {
  if (newUnreadCount > 0) {
    resumeScrollBottom();
  } else {
    pauseScrollBottom();
  }
});

function goToUnreadMessages() {
  const firstUnreadMessage =
    messages.value[messages.value.length - unreadCount.value];

  const messageEl = firstUnreadMessage
    ? document.getElementById(
        `${firstUnreadMessage.conversationId}-${firstUnreadMessage.id}`
      )
    : null;

  if (messageEl && chatScrollContainer.value) {
    chatScrollContainer.value.scroll({
      top: messageEl.offsetTop - messageEl.offsetHeight,
      behavior: 'smooth',
    });
  }

  markMessagesAsRead();
}

function handleTyping() {
  if (showUnreadButton.value) {
    markMessagesAsRead();
  }
}
function disableScrollIfEmojiPickerOpen(value) {
  const scrollContainer = chatScrollContainer.value;

  if (value) {
    scrollContainer.style.overflow = 'hidden';
  } else {
    scrollContainer.style.overflow = 'auto';
  }
}

// bulk select action bar offset module
const { actionBarIsMobile } = useActionBarMediaQuery();

const bottomOffset = computed(() => {
  if (
    isGallery.value &&
    props.userHasScrolledPastBreakpoint &&
    actionBarIsMobile.value
  ) {
    return '12.5rem'; // 200px
  } else if (
    isGallery.value &&
    !props.userHasScrolledPastBreakpoint &&
    actionBarIsMobile.value
  ) {
    return '9.25rem';
  } else if (isGallery.value && props.userHasScrolledPastBreakpoint) {
    return '10rem'; // 160px
  }
  return '6.75rem'; // 108px
});
</script>

<template>
  <div class="ReservationChat">
    <button
      v-show="!isChatOpen"
      class="chat-button"
      :class="{
        'chat-button--gallery-scroll':
          isGallery && userHasScrolledPastBreakpoint,
      }"
      @click="toggleChat"
    >
      <span v-if="isMediaView">
        chat <SoonaIcon name="message-circle-lines-alt" />
      </span>
      <SoonaIcon v-else name="message-circle-lines-alt" />
      <span v-show="unreadCount > 0" class="unread-counter">
        {{ unreadCount }}
      </span>
    </button>
    <div v-show="isChatOpen" ref="chatModalCard" class="modal-card">
      <header class="modal-card-head">
        <SoonaButton
          class="chat-modal-head-icon-button"
          variation="icon-transparent"
          size="large"
          :title="`${
            notificationsDisabled ? 'enable' : 'disable'
          } notification sound`"
          @on-click="toggleNotificationSounds"
        >
          <SoonaIcon :name="notificationsDisabled ? 'bell-slash' : 'bell'" />
        </SoonaButton>
        <div v-if="messages.length === 0" class="has-text-white">
          <p>soona chat</p>
        </div>
        <div v-else>
          <!-- TODO: stack avatars if group members exceeds a certain number -->
          <ul class="chat-members">
            <li v-for="member in members" :key="member.uid" class="chat-member">
              <SoonaAvatar
                :name="member.name"
                :src="member.avatar"
                size="2rem"
                type="user"
                :alt="`${member.name} avatar`"
                :title="member.name"
              />
            </li>
          </ul>
        </div>
        <SoonaButton
          class="chat-modal-head-icon-button"
          variation="icon-transparent"
          size="large"
          title="close chat"
          @on-click="toggleChat"
        >
          <SoonaIcon name="compress-alt" />
        </SoonaButton>
      </header>
      <ChatStatus
        :connection-status="connectionStatus"
        :display-refresh="displayRefresh"
        @refresh="refresh"
      />
      <ChatError v-if="error">
        {{ error.display ?? error.message ?? error }}
      </ChatError>
      <div ref="chatScrollContainer" class="modal-card-body">
        <JoinChat
          v-if="promptJoinGroup"
          :is-ghost-user="isGhostUser"
          @join="joinChat"
        />
        <section v-else class="chat-messages">
          <div ref="startOfMessagesEl" />
          <WelcomeMessage />
          <component
            :is="generateComponentFilename(message.type)"
            v-for="message in messages"
            :id="`${message.conversationId}-${message.id}`"
            :key="message.id"
            :message="message"
            :is-outgoing="message.sender.uid === currentUserId.toString()"
          />
          <NextStepsMessage v-if="inPendingSelects" />
          <StatusMessage :status="reservationStatus" :is-chat-on="isChatOn" />
          <button
            v-if="showUnreadButton && inProgress"
            class="view-unread-button"
            @click="goToUnreadMessages"
          >
            <SoonaIcon name="arrow-down" size="small" />
            <span class="ml-s">see unread messages</span>
          </button>
          <div ref="endOfMessagesEl" />
        </section>
      </div>
      <footer class="modal-card-foot">
        <ChatActions
          :disabled="disabled || reservationStatus !== 'in_progress'"
          :notifications-disabled="notificationsDisabled"
          :current-user-id="currentUserId"
          :reservation-id="reservationId"
          @typing="handleTyping"
          @submit="scrollToBottomOfChat"
          @picker-open="disableScrollIfEmojiPickerOpen"
          @send-message="sendMessage"
          @send-quick-reaction="sendQuickReaction"
          @send-media-message="sendMediaMessage"
        />
      </footer>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.ReservationChat {
  height: 100%;

  .chat-button {
    background-color: variables.$periwink-blue-50;
    border-radius: 50%;
    bottom: v-bind(bottomOffset);
    cursor: pointer;
    height: 3rem;
    position: fixed;
    right: 1.5625rem;
    transition: 0.2s;
    width: 3rem;
    z-index: 4;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: variables.$periwink-blue-60;
    }

    svg {
      color: variables.$white-default;
      height: 1.625rem;
      width: 1.625rem;
    }

    @media (min-width: variables.$screen-md-min) {
      bottom: 6.75rem;
      height: 3.25rem;
      width: 3.25rem;

      &--gallery-scroll {
        right: 5.25rem;
      }

      svg {
        height: 1.875rem;
        width: 1.875rem;
      }
    }
  }

  .unread-counter {
    background: variables.$friendly-red-50;
    height: 1.875rem;
    width: 1.875rem;
    line-height: 1.875rem;
    border-radius: 50%;
    color: variables.$white-default;
    position: absolute;
    top: -0.625rem;
    right: 0;
  }

  .chat-modal-head-icon-button {
    border: none;
    color: variables.$white-default;
  }

  .modal-card {
    width: 100%;
    border: 0.0625rem solid #d6d8db;
    border-radius: 0.5rem;
    z-index: 15;
    position: sticky;
    top: var(--app-header-viewport-offset);
    margin: 0;
    min-height: min(37.5rem, calc(100dvh - var(--app-header-viewport-offset)));
    height: calc(
      100dvh - var(--app-header-viewport-offset) - var(--app-header-height)
    );

    @supports not (height: 100dvh) {
      min-height: min(37.5rem, calc(100vh - var(--app-header-viewport-offset)));
      height: calc(
        100vh - var(--app-header-viewport-offset) - var(--app-header-height)
      );
    }
  }
  .modal-card-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: variables.$periwink-blue-40;
    padding: 0.75rem;
  }
  .modal-card-body {
    height: 567px;
    padding: 1rem;
    /* make full focus ring visible when using a keyboard */
    outline-offset: -0.25rem;
  }
  .modal-card-foot {
    background: variables.$gray-10;
    border-top: 0.0625rem solid variables.$gray-30;
    padding: 0.75rem;
  }
  .chat-members {
    display: flex;
  }
  .chat-member {
    img {
      border: 0.0625rem solid variables.$white-default;
    }
  }
  .chat-member:not(:first-child) {
    margin-left: -0.625rem;
    z-index: 1;
  }
  .view-unread-button {
    position: sticky;
    bottom: 0;
    left: 50%;
    display: flex;
    align-items: center;
    transform: translateX(-50%);
    padding: 0.5rem;
    background: variables.$white-default;
    color: variables.$black-default;
    border: 0.0625rem solid #d6d8db;
    border-radius: 0.3125rem;
  }
  .view-unread-button:hover {
    background: variables.$gray-10;
  }

  @media (max-width: 45rem) {
    .modal-card {
      width: 100% !important;
      height: 100dvh !important;
      max-height: 100dvh;
      min-height: 0;
      margin: 0;
      left: 0;
      top: 0;
      position: fixed;

      @supports not (height: 100dvh) {
        height: 100vh !important;
        max-height: 100vh;
      }
    }
  }
}
</style>

import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {string | Ref<string>} [collectionId]
 * @param {Object} [params]
 * @param {number} [params.itemsPerPage]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useReEditsCollectionDigitalAssets(
  collectionId,
  { activeAssetId, currentPage = 1, itemsPerPage, filters },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.collectionAssets(collectionId, {
      activeAssetId,
      currentPage,
      itemsPerPage,
      filters,
    }),
    queryFn: async ({ signal }) => {
      const params = { ...toValue(filters) };

      if (activeAssetId) params['active_asset_id'] = toValue(activeAssetId);
      if (currentPage) params['page'] = toValue(currentPage);
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);

      const response = await http.get(
        `/collections/${toValue(collectionId)}/re_edits_collection_digital_assets`,
        {
          params,
          signal,
        }
      );

      return {
        pagination: mapPaginationHeaders(response.headers),
        assets: response.data?.re_edits_collection_digital_assets || [],
      };
    },
    initialPageParam: currentPage,
    ...queryOptions,
  });
}

<script setup>
import { computed, ref } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SceneAssignmentOptionCard from './SceneAssignmentOptionCard.vue';
import { useShotList, useActiveShot } from '@/queries/useShotList';
import { useAssignAssetsToShot } from '@/queries/shots/useAssignAssetsToShot';
import { usePriorityError } from '@/composables/usePriorityError';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
  selectedRdas: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const emit = defineEmits(['complete', 'close']);

const reservationId = computed(() => props.reservationId);
const selectedReservationDigitalAssets = computed(() => props.selectedRdas);

const { data: shotListData, error: shotListError } = useShotList(reservationId);
const { data: activeShot, error: activeShotError } =
  useActiveShot(reservationId);

const { mutate, isPending, error } = useAssignAssetsToShot(reservationId);

const selectedScene = ref(undefined);

const assignToScene = shotId => {
  selectedScene.value = shotId;

  const rdaIds = selectedReservationDigitalAssets.value?.map(rda => rda.id);

  mutate(
    { shotId, rdaIds },
    {
      onSuccess: () => {
        selectedScene.value = undefined;
        emit('close');
      },
    }
  );
};

const priorityError = usePriorityError(shotListError, activeShotError, error);
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>assign to scene</template>

    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>

    <div class="scene-list">
      <SceneAssignmentOptionCard
        v-for="(shot, i) in shotListData?.shots"
        :key="shot.id"
        :shot="shot"
        :index="i"
        :is-active="activeShot?.active_shot_id === shot.id"
        :is-loading="
          (isAssigningToShot || isPending) && selectedScene === shot.id
        "
        :is-disabled="
          (isAssigningToShot || isPending) && selectedScene !== shot.id
        "
        @assign-to-scene="assignToScene"
      />
    </div>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.scene-list {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
}
</style>

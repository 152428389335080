<script setup>
import { storeToRefs } from 'pinia';

import { useAiProducer } from './Composables/useAiProducer';
import { useAiProducerStore } from './Composables/useAiProducerStore';

import ProjectSummary from './ProjectSummary.vue';
import ExpandingCircle from './ExpandingCircle.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTransition from '@/components/ui_library/SoonaTransition.vue';
import { useRouter } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const { resetProducer } = useAiProducer();
const aiProducerStore = useAiProducerStore();
const { isSummaryOpen, hasMessages } = storeToRefs(aiProducerStore);

const router = useRouter();
const { linkClicked } = useBaseEvents();

function goBack() {
  router.push('/');
  linkClicked({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    linkLabel: 'exit producer',
    linkHref: '/',
  });
}

function reset() {
  resetProducer();
  linkClicked({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    linkLabel: 'reset producer',
    linkHref: null,
  });
}

function toggleSummary() {
  aiProducerStore.setIsSummaryOpen(!isSummaryOpen.value);
  linkClicked({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    linkLabel: isSummaryOpen.value ? 'close summary' : 'open summary',
    linkHref: null,
  });
}
</script>

<template>
  <header class="header">
    <div
      class="header__content"
      :class="{ 'header__content-border': isSummaryOpen }"
    >
      <SoonaButton
        class="header__icon-button"
        variation="icon-plain-gray"
        @click="goBack"
      >
        <SoonaIcon name="arrow-left" />
        <span class="u-visually-hidden">back</span>
      </SoonaButton>

      <SoonaButton
        v-if="hasMessages"
        variation="tertiary"
        @click="toggleSummary"
      >
        <b>project summary</b>
        <SoonaIcon
          name="chevron-down"
          class="header__expand-chevron"
          :class="{ header__rotate: isSummaryOpen }"
        />
      </SoonaButton>

      <SoonaButton
        v-if="hasMessages"
        class="header__icon-button"
        variation="icon-plain-gray"
        @click="reset"
      >
        <SoonaIcon name="message-circle-plus" />
        <span class="u-visually-hidden">reset</span>
      </SoonaButton>
    </div>

    <SoonaTransition name="fade-down" enter-delay="100">
      <ProjectSummary v-if="isSummaryOpen" class="header__project-summary" />
    </SoonaTransition>

    <SoonaTransition name="fade-down">
      <ExpandingCircle v-if="isSummaryOpen" />
    </SoonaTransition>
  </header>
</template>

<style lang="scss" scoped>
@use '@/variables';

.header {
  z-index: 3;
  height: 4rem;
  display: flex;
  position: relative;
  align-items: stretch;
  background: variables.$white-default;
  color: variables.$black-default;

  &__icon-button {
    color: variables.$black-default;
  }

  &__content {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0.625rem 1.25rem;
    transition: border 0.4s ease;
    justify-content: space-between;
    border-bottom: 0.125rem solid variables.$white-default;
  }

  &__content-border {
    border-bottom: 0.125rem solid variables.$periwink-blue-10;
  }

  &__project-summary {
    left: 0;
    right: 0;
    top: 4rem;
    z-index: 2;
    box-shadow: none;
    border-radius: 0;
    background: none;
    position: absolute;
    height: calc(100vh - 4rem);
  }

  &__expand-chevron {
    margin-top: 0.25rem;
    transition: transform 0.1s;
  }

  &__rotate {
    transform: rotate(-180deg);
  }

  @media (min-width: 81.25rem) {
    display: none;
  }
}
</style>

import { defineStore } from 'pinia';
import { computed, readonly, ref } from 'vue';
import { MEDIA_EDITOR_STORE_ID } from '@/stores/keys';
import { useMediaEditorComponents } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorComponents';
import { useUnsavedChangesWarning } from '@/components/user/anytime/gallery/media-editor/store/useUnsavedChangesWarning';
import { useMediaEditorIframe } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorIframe';
import { useMediaEditorCanvas } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorCanvas';
import { useMediaEditorSubscriptionAccess } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorSubscriptionAccess';
import { useRouter } from 'vue-router';

export const useMediaEditorStore = defineStore(MEDIA_EDITOR_STORE_ID, () => {
  const activeAction = ref(undefined);
  const closeAction = ref(undefined);

  const router = useRouter();

  const {
    components,
    registerComponent,
    unregisterComponent,
    unregisterAllComponents,
  } = useMediaEditorComponents();
  const activeComponent = computed(() => components.get(activeAction.value));

  const {
    canvasData,
    setCanvasData,
    resetCanvas,
    showForeground,
    setShowForeground,
    canvasComponent,
    registerCanvasComponent,
    selectorData,
    setSelectorData,
    selectionData,
    setSelectionData,
  } = useMediaEditorCanvas();

  const {
    showWarning,
    setShowWarning,
    warningParams,
    continueConfirmed,
    setContinueConfirmed,
    resetWarning,
    checkForUnsavedChanges,
  } = useUnsavedChangesWarning(activeComponent);

  const { iframeUrl, setIframeUrl, isIframeOpen, resetIframe } =
    useMediaEditorIframe();

  const setActiveAction = action => {
    if (!action) {
      console.warn(
        'to clear the active action, pass in the current active action or use forceSetActiveAction.'
      );
    }
    const newAction = checkForUnsavedChanges({ action });
    if (newAction) {
      unregisterComponent(activeAction.value);
      resetCanvas();
      resetIframe();
      activeAction.value =
        newAction === activeAction.value ? undefined : newAction;
    }
  };

  const forceSetActiveAction = action => {
    unregisterComponent(activeAction.value);
    resetCanvas();
    resetIframe();
    activeAction.value = action;
  };

  const saveEdit = async () => {
    await activeComponent.value?.handleSave();
  };

  const assetId = ref(null);
  const assetAccountId = ref(null);
  const showPaywallDialog = ref(false);
  const numberOfAIGenerations = ref(4);
  const showFeedbackDialog = ref(false);
  const carouselDigitalAssetIds = ref([]);
  const carouselPreviousPageLastAssetId = ref(null);
  const carouselNextPageFirstAssetId = ref(null);

  const {
    canAdjustBackgroundOnOwnAccount,
    staffCanAdjustBackgroundOnClientAccount,
    hasSubscriptionDownloadAccess,
  } = useMediaEditorSubscriptionAccess(assetAccountId);

  function $reset() {
    activeAction.value = undefined;
    closeAction.value = undefined;
    assetId.value = null;
    assetAccountId.value = null;
    showPaywallDialog.value = false;
    numberOfAIGenerations.value = 4;
    carouselDigitalAssetIds.value = [];
    unregisterAllComponents();
    resetWarning();
    resetIframe();
    resetCanvas();
  }

  const handleRouteChange = async to => {
    if (continueConfirmed.value) {
      if (to.name === router.currentRoute.value.name) {
        resetCanvas();
        resetWarning();
        resetIframe();
      } else {
        $reset();
      }
      return true;
    }
    return checkForUnsavedChanges({ route: to });
  };

  return {
    carouselDigitalAssetIds: readonly(carouselDigitalAssetIds),
    setCarouselDigitalAssetIds: ids => (carouselDigitalAssetIds.value = ids),
    carouselPreviousPageLastAssetId: readonly(carouselPreviousPageLastAssetId),
    setCarouselPreviousPageLastAssetId: id =>
      (carouselPreviousPageLastAssetId.value = id),
    carouselNextPageFirstAssetId: readonly(carouselNextPageFirstAssetId),
    setCarouselNextPageFirstAssetId: id =>
      (carouselNextPageFirstAssetId.value = id),
    assetId: readonly(assetId),
    setAssetId: id => (assetId.value = id),
    assetAccountId: readonly(assetAccountId),
    setAssetAccountId: id => (assetAccountId.value = id),
    activeAction: readonly(activeAction),
    setActiveAction,
    forceSetActiveAction,
    closeAction: readonly(closeAction),
    setCloseAction: action => (closeAction.value = action),
    registerComponent,
    activeComponent,
    numberOfGenerations: readonly(numberOfAIGenerations),
    setNumberOfGenerations: n => (numberOfAIGenerations.value = n),
    saveEdit,
    showPaywallDialog: readonly(showPaywallDialog),
    setShowPaywallDialog: show => (showPaywallDialog.value = show),
    showWarning: readonly(showWarning),
    setShowWarning: setShowWarning,
    warningParams: readonly(warningParams),
    handleRouteChange,
    continueConfirmed: readonly(continueConfirmed),
    setContinueConfirmed,
    iframeUrl: readonly(iframeUrl),
    setIframeUrl,
    isIframeOpen,
    canvasData: readonly(canvasData),
    setCanvasData,
    showForeground: readonly(showForeground),
    setShowForeground,
    showFeedbackDialog: readonly(showFeedbackDialog),
    setShowFeedbackDialog: show => (showFeedbackDialog.value = show),
    canvasComponent,
    resetCanvas,
    registerCanvasComponent,
    canAdjustBackgroundOnOwnAccount,
    staffCanAdjustBackgroundOnClientAccount,
    hasSubscriptionDownloadAccess,
    selectorData: readonly(selectorData),
    setSelectorData,
    selectionData: readonly(selectionData),
    setSelectionData,
    $reset,
  };
});

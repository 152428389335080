export default function useGroupCatalogItemsByReservation(reservationId) {
  const groupProducts = catalogItems => {
    return catalogItems.reduce((acc, item) => {
      const itemCopy = { ...item };
      const group =
        reservationId.value &&
        itemCopy.attached_to_reservation_id === Number(reservationId.value)
          ? 'this booking'
          : 'more products';

      acc[group] ??= [];
      acc[group].push(itemCopy);
      return acc;
    }, {});
  };

  return {
    groupProducts,
  };
}

<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useAnimatedCollection } from '@/queries/animated_collections/useAnimatedCollection';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import GifDimension from '@/components/user/anytime/gifs/settings/GifDimension.vue';
import GifFormats from '@/components/user/anytime/gifs/settings/GifFormats.vue';
import GifPlayback from '@/components/user/anytime/gifs/settings/GifPlayback.vue';
import GifSpeed from '@/components/user/anytime/gifs/settings/GifSpeed.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import GifPreview from '@/components/user/anytime/gifs/preview/GifPreview.vue';
import { useAnimatedCollectionDigitalAssets } from '@/queries/animated_collection_digital_assets/useAnimatedCollectionDigitalAssets';
import { useUpdateAnimatedCollection } from '@/queries/animated_collections/useUpdateAnimatedCollection';
import { useDeleteAnimatedCollection } from '@/queries/animated_collections/useDeleteAnimatedCollection';
import { useRouter } from 'vue-router';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import EditGifBreadcrumbs from '@/components/user/anytime/gifs/EditGifBreadcrumbs.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  collectionId: {
    type: [Number, String],
    required: true,
  },
  reservationId: {
    type: [Number, String],
    default: null,
  },
});

const router = useRouter();

const { addToast } = useSoonaToast();

const accountId = computed(() => props.accountId);
const collectionId = computed(() => props.collectionId);

const showDeleteConfirmation = ref(false);
const editTitle = ref(false);

const {
  data: animation,
  isLoading: isLoadingCollection,
  error: useAnimatedCollectionError,
} = useAnimatedCollection({
  accountId,
  collectionId,
});

const proxyDigitalAsset = computed(() => animation.value?.proxy_digital_asset);

const {
  data: animatedCDAs,
  isLoading: isLoadingCollectionCDAs,
  error: useAnimatedCollectionDigitalAssetsError,
} = useAnimatedCollectionDigitalAssets(collectionId);

const title = ref(animation.value?.title);

// cdas
const animatedCDAsData = computed(() => animatedCDAs.value || []);
const selectedCDAs = ref(animatedCDAsData.value);
const selectedAssets = computed(() => {
  return selectedCDAs.value
    ?.filter(cda => !cda._destroy)
    .map(cda => ({
      ...cda.digital_asset,
      asset_url: `/account/${cda.digital_asset.account_id}/gallery/assets/asset/${cda.digital_asset.id}`,
    }));
});
const originalIdOrder = computed(() => {
  return animatedCDAsData.value?.reduce((acc, curr) => {
    acc[curr.id] = curr.order;
    return acc;
  }, {});
});

// dimension
const dimensionData = computed(() => {
  return animation.value?.options?.dimensions || 'square';
});
const dimension = ref(dimensionData.value);

// formats
const formatsData = computed(() => {
  return animation.value?.options?.format || ['gif'];
});
const formats = ref(formatsData.value);

// playback
const playbackData = computed(() => {
  return animation.value?.options?.direction || 'loop';
});
const playback = ref(playbackData.value);

// speed
const speedData = computed(() => {
  return animation.value?.options?.speed || 666.66;
});
const speed = ref(speedData.value);

watchEffect(() => {
  if (proxyDigitalAsset.value?.ownership === 'customer') {
    router.replace(`/account/${accountId.value}/gallery`);
  }
});

watchEffect(() => {
  dimension.value = dimensionData.value;
  formats.value = formatsData.value;
  playback.value = playbackData.value;
  speed.value = speedData.value;
  title.value = animation.value?.title;
  selectedCDAs.value = animatedCDAsData.value;
});

// form
const previewOrderHasChanged = computed(() => {
  return selectedCDAs.value?.some((cda, idx) => {
    return idx + 1 !== originalIdOrder.value[cda.id];
  });
});
const formatsHaveChanged = computed(() => {
  return formats.value?.filter(x => !formatsData.value?.includes(x)).length > 0;
});

const isDirty = computed(() => {
  return (
    dimension.value !== dimensionData.value ||
    formats.value.length !== formatsData.value.length ||
    formatsHaveChanged.value ||
    playback.value !== playbackData.value ||
    speed.value !== speedData.value ||
    title.value !== animation.value?.title ||
    previewOrderHasChanged.value ||
    selectedCDAs.value.length !== animatedCDAsData.value?.length
  );
});

const isInvalid = computed(() => {
  return !title.value || !formats.value.length;
});

const { mutate: updateGif, isPending: isUpdatingGif } =
  useUpdateAnimatedCollection(accountId);

const submitUpdates = event => {
  const data = new FormData(event.target);
  let body;
  const title = data.get('title');
  const options = {
    dimensions: data.get('dimension'),
    direction: data.get('playback'),
    format: data.getAll('formats'),
    speed: Number(data.get('speed')),
  };
  body = { options };
  if (title) body.title = title;

  if (
    previewOrderHasChanged.value ||
    selectedAssets.value.length !== selectedCDAs.value.length
  ) {
    let reOrderedCdas = selectedCDAs.value
      ?.filter(cda => !cda._destroy)
      .map((cda, idx) => ({
        id: cda.id,
        order: idx + 1,
      }));
    let destroyedCDAs = selectedCDAs.value
      ?.filter(cda => cda._destroy)
      .map(cda => ({
        id: cda.id,
        _destroy: true,
      }));
    body.animated_collection_digital_assets_attributes = [
      ...reOrderedCdas,
      ...destroyedCDAs,
    ];
  }

  updateGif(
    {
      collectionId: collectionId.value,
      body: body,
    },
    {
      onSuccess: () => {
        editTitle.value = false;
        addToast('successfully updated GIF animation', {
          variation: 'success',
        });
      },
      onError: () => {
        addToast(`error editing GIF animation`, {
          variation: 'error',
        });
      },
    }
  );
};

// show deletion confirmation modal
const setShowDeleteConfirmation = val => {
  showDeleteConfirmation.value = val;
};

const { mutate: destroyAnimation, isPending: isDeleting } =
  useDeleteAnimatedCollection(accountId);

const deleteGif = () => {
  setShowDeleteConfirmation(false);
  destroyAnimation(
    {
      collectionId: collectionId.value,
    },
    {
      onSuccess: () => {
        if (props.reservationId) {
          router.push(`/reservation/${props.reservationId}`);
        } else {
          router.push(`/account/${accountId.value}`);
        }
      },
    }
  );
};

const updateSelectedCDAs = digitalAssets => {
  // we need to update the original CDA list with the provided DA list
  const newDAOrderIds = digitalAssets.map(da => da.id);
  selectedCDAs.value = selectedCDAs.value
    ?.slice()
    .map(cda => ({
      ...cda,
      _destroy: !newDAOrderIds.includes(cda.digital_asset.id),
    }))
    .sort(
      (a, b) =>
        newDAOrderIds.indexOf(a.digital_asset.id) -
        newDAOrderIds.indexOf(b.digital_asset.id)
    );
};

const resetForm = () => {
  editTitle.value = false;
  title.value = animation.value?.title;
  speed.value = speedData.value;
  dimension.value = dimensionData.value;
  formats.value = formatsData.value;
  playback.value = playbackData.value;
};

const priorityErrors = usePriorityErrors(
  useAnimatedCollectionError,
  useAnimatedCollectionDigitalAssetsError
);
</script>
<template>
  <div class="edit-gif">
    <SoonaLoading
      v-if="isLoadingCollection || isLoadingCollectionCDAs"
      :is-loading="true"
      loading-text="loading gif"
    />
    <SoonaError v-else-if="priorityErrors" :priority-errors="priorityErrors" />
    <template v-else>
      <SoonaLoading :is-loading="isUpdatingGif" loading-text="updating gif" />
      <SoonaLoading :is-loading="isDeleting" loading-text="deleting gif" />
      <EditGifBreadcrumbs
        :account-id="accountId"
        :digital-asset-id="proxyDigitalAsset?.id"
        :gif-name="title"
        :reservation-id="reservationId"
      />
      <SoonaForm @submit="submitUpdates">
        <div class="is-pulled-right u-headline--regular">
          <b>GIF</b>MakerPro
        </div>
        <SoonaTextfield
          v-if="editTitle"
          v-model="title"
          name="title"
          label="gif title"
          placeholder="edit gif name"
        />
        <h3 v-else class="u-subheader--heavy">
          {{ title }}
          <SoonaButton
            variation="tertiary"
            @on-click="() => (editTitle = true)"
          >
            <SoonaIcon name="pen-square" />
            <span class="u-visually-hidden">edit gif title</span>
          </SoonaButton>
        </h3>
        <GifPreview
          :selected-assets="selectedAssets"
          :dimension="dimension"
          :playback="playback"
          :speed="speed"
          @on-update-selected-assets="updateSelectedCDAs"
        />
        <div class="edit-gif--details">
          <!-- speed -->
          <GifSpeed v-model="speed" />
          <!-- dimensions -->
          <GifDimension v-model="dimension" />
          <!-- format -->
          <GifFormats v-model="formats" />
          <!-- playback -->
          <GifPlayback v-model="playback" />
        </div>
        <div class="edit-gif--actions">
          <div>
            <SoonaTooltip>
              <template
                #default="{ setRef, mouseenter, focus, mouseleave, blur }"
              >
                <SoonaButton
                  :ref="el => setRef(el)"
                  :disabled="
                    !!proxyDigitalAsset?.bag_collection_digital_asset?.id
                  "
                  variation="tertiary"
                  @mouseenter="mouseenter"
                  @focus="focus"
                  @mouseleave="mouseleave"
                  @blur="blur"
                  @on-click="() => setShowDeleteConfirmation(true)"
                >
                  <SoonaIcon name="trash" />
                  delete
                </SoonaButton>
              </template>
              <template
                v-if="!!proxyDigitalAsset?.bag_collection_digital_asset?.id"
                #tooltip-content
              >
                <p>
                  this animation has been added to the bag. remove from bag if
                  you want to delete
                </p>
              </template>
            </SoonaTooltip>
          </div>
          <SoonaButton variation="tertiary" @on-click="resetForm">
            discard changes
          </SoonaButton>
          <SoonaButton type="submit" :disabled="!isDirty || isInvalid">
            save changes
          </SoonaButton>
        </div>
      </SoonaForm>
      <SoonaDialog
        v-if="showDeleteConfirmation"
        @close="() => setShowDeleteConfirmation(false)"
      >
        <template #heading>are you sure?</template>
        <template #default>
          <p>are you sure you want to delete this gif?</p>
        </template>
        <template #footer>
          <SoonaButton
            variation="tertiary"
            @click="() => setShowDeleteConfirmation(false)"
          >
            cancel
          </SoonaButton>
          <SoonaButton @click="deleteGif">delete</SoonaButton>
        </template>
      </SoonaDialog>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.edit-gif {
  max-width: 37.5rem;
  margin: 2rem auto 0;

  h3 {
    display: flex;
    gap: 0.25rem;
    margin-bottom: 1rem;
  }

  &--details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;

    & > .gif-speed {
      width: 100%;
    }
  }

  &--actions {
    padding-top: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;

    & :first-child {
      flex-grow: 1;
    }
  }
}
</style>

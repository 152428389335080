<script setup>
import { computed } from 'vue';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import { PeriwinkBlue20, Gray30 } from '@/variables.module.scss';

const props = defineProps({
  backButtonCopy: {
    type: String,
    required: false,
    default: 'back',
  },
  displayBackButton: { type: Boolean, default: true },
  headingId: {
    type: String,
    required: false,
  },
  justify: {
    type: String,
    required: false,
    default: 'center',
    validator: value => {
      return ['center', 'left'].includes(value);
    },
  },
  overrideUContainer: {
    type: Boolean,
    default: false,
  },
  subtitle: {
    type: String,
  },
  title: {
    type: String,
    required: true,
  },
  padding: {
    type: String,
    default: '1.5rem 0',
  },
  previousPage: {
    type: Function,
    required: false,
    default() {
      return window.history.back();
    },
  },
  backgroundColor: {
    type: String,
    required: false,
    default: PeriwinkBlue20,
  },
  borderColor: {
    type: String,
    required: false,
    default: Gray30,
  },
});

const justify = computed(() => props.justify);
const padding = computed(() => props.padding);
</script>

<template>
  <header class="title-bar">
    <div
      class="title-bar__inner-wrapper"
      :class="overrideUContainer ? '' : 'u-container'"
    >
      <SoonaBackButton
        v-if="displayBackButton"
        :button-text="backButtonCopy"
        @click="previousPage"
      />

      <div class="title-bar__heading-wrapper">
        <span>
          <h1
            :id="headingId"
            class="title-bar__heading"
            data-cypress="title-bar-heading"
          >
            {{ title }}
          </h1>
          <slot name="flag"></slot>
        </span>
      </div>
      <p v-if="subtitle" class="title-bar__subtitle">
        {{ subtitle }}
      </p>
      <span class="title-bar__additional-info">
        <slot name="additional-info"></slot>
      </span>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.title-bar {
  padding: v-bind('padding');
  position: relative;
  width: 100%;
  background-color: v-bind('backgroundColor');
  border-bottom: 0.0625rem solid v-bind('borderColor');

  &__inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  &__heading-wrapper {
    text-align: v-bind(justify);
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
  }

  &__heading {
    @include variables_fonts.u-headline--heavy;

    color: variables.$black-default;
  }

  &__additional-info {
    padding-top: 1rem;
  }
}
</style>

<template>
  <div class="AddModelDropdown">
    <SoonaDropdownMenu
      variation="secondary-black"
      :copy="choiceSelectionPlaceholder"
      class="select-choice-dropdown"
      size="medium"
      data-cypress="button-add-pro-model"
      @button-action="trackButtonClicked"
    >
      <template #default="{ clickCapture, keydown, mouseover }">
        <SoonaDropdownMenuItem
          v-for="(item, index) in visibleDropdownOptions"
          :key="item.key"
          :class="{ disabled: item.disabled }"
        >
          <button
            role="menuitem"
            :disabled="item.disabled"
            :data-cypress="`button-select-pro-model-choice-${index}`"
            @click.capture="clickCapture"
            @click="item.click(proServiceProvider, item.key)"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            {{ item.text }}
          </button>
        </SoonaDropdownMenuItem>
      </template>
    </SoonaDropdownMenu>
  </div>
</template>

<script>
import { computed } from 'vue';
import { mapMutations, mapState } from 'vuex';
import * as types from 'src/store/mutation-types';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useGetTopChoicesOnReservation } from '@/queries/useGetTopChoicesOnReservation';

export default {
  name: 'AddModelDropdown',
  components: {
    SoonaDropdownMenu,
    SoonaDropdownMenuItem,
  },
  props: {
    proServiceProvider: Object,
    proServiceRequirementId: Number,
    reservationId: Number,
  },
  emits: ['close-modal'],
  setup(props) {
    const reservationId = computed(() => props.reservationId);
    const { data: topChoices } = useGetTopChoicesOnReservation(reservationId);

    const { buttonClicked } = useBaseEvents();

    return {
      topChoices,
      buttonClicked,
    };
  },
  computed: {
    ...mapState({
      currentPspChoices: state => state.proService.currentPSPchoices,
    }),
    isTopChoice() {
      return this.topChoices
        ? this.topChoices.some(
            choice =>
              choice &&
              choice.pro_service_profile_id === this.proServiceProvider?.id &&
              choice.pro_service_requirement_id !== this.proServiceRequirementId
          )
        : false;
    },
    choiceSelectionPlaceholder() {
      let matchingChoice = this.currentPspChoices.find(
        choice =>
          choice.pro_service_profile_id === this.proServiceProvider?.id &&
          !choice._destroy
      );
      if (matchingChoice) {
        switch (matchingChoice.order) {
          case 0:
            return '❤️ top choice';
          case 1:
            return '1st alternative';
          case 2:
            return '2nd alternative';
          default:
            'add model';
        }
      }
      return 'add model';
    },
    visibleDropdownOptions() {
      let dropdownButtons = [
        {
          click: this.addPSPchoice,
          text: this.isTopChoice ? 'already a top choice' : 'add as top choice',
          key: 'top_choice',
          disabled: this.isTopChoice,
        },
        {
          click: this.addPSPchoice,
          text: 'add as 1st alternate',
          key: 'second_choice',
        },
        {
          click: this.addPSPchoice,
          text: 'add as 2nd alternate',
          key: 'third_choice',
        },
      ];
      return dropdownButtons;
    },
  },
  methods: {
    ...mapMutations('proService', [types.ADD_NEW_PSP_CHOICE]),
    async addPSPchoice(choice, value) {
      let choiceParam = {};
      if (value === 'top_choice') {
        choiceParam.order = 0;
      } else if (value === 'second_choice') {
        choiceParam.order = 1;
      } else if (value === 'third_choice') {
        choiceParam.order = 2;
      }
      choiceParam.image_url = this.imageFromArray(choice);
      choiceParam.pro_service_profile_id = choice.id;
      choiceParam.pro_service_requirement_id = this.proServiceRequirementId;
      choiceParam.user_id = this.proServiceProvider.user.id;
      choiceParam.account_id = this.proServiceProvider.user.account_id;
      choiceParam.provider_name = this.proServiceProvider.provider_name;
      choiceParam.user_name = this.proServiceProvider.user_name;
      choiceParam.source = 0;
      await this.ADD_NEW_PSP_CHOICE(choiceParam);

      this.$emit('close-modal');
    },
    imageFromArray(provider) {
      if (provider.image_urls?.length > 0) {
        return provider.image_urls[0];
      } else {
        return null;
      }
    },
    trackButtonClicked() {
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: `${this.proServiceProvider.service_type} provider`,
        buttonLabel: 'add model',
        buttonAction: 'opens add model dropdown',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.AddModelDropdown {
  .select-choice-dropdown {
    display: flex;
    justify-content: center;
    :deep(.soona-button--secondary-black) {
      width: 100%;
    }
    .disabled {
      opacity: 0.4;
    }
  }
}
</style>

<script setup>
import { ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import CreditDetail from './CreditDetail.vue';
defineProps({
  credits: {
    type: Array,
    required: true,
  },
});
const toggleMoreDetails = ref(false);
</script>
<template>
  <div class="more-details-container">
    <SoonaButton
      variation="tertiary"
      size="medium"
      @click="toggleMoreDetails = !toggleMoreDetails"
    >
      credit details
    </SoonaButton>
  </div>
  <SoonaDialog
    v-if="toggleMoreDetails"
    @close="() => (toggleMoreDetails = !toggleMoreDetails)"
  >
    <template #heading>booking credits</template>
    <ul aria-label="booking credits">
      <CreditDetail
        v-for="credit in credits"
        :key="credit.id"
        :credit="credit"
      />
    </ul>
    <template #footer="{ close }">
      <SoonaButton data-cypress="button-dialog-confirm" @on-click="close">
        ok
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped></style>

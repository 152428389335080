import { http } from '@/config/vue-axios';

/**
 *
 * @param {number} orderId
 */
export async function autoApplyDiscount(orderId) {
  const response = await http.put(`/orders/${orderId}/auto_apply_discount`);
  return response.data;
}

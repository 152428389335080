import * as types from 'src/store/mutation-types';

const state = {
  flags: [],
};

const getters = {
  flags(state) {
    return state.flags;
  },
};

const mutations = {
  [types.SET_ALL_FLAGS](state, flags) {
    state.flags = flags;
  },
};

const actions = {
  loadAllFlags({ commit }) {
    return new Promise(resolve => {
      this.http.get('flags.json').then(response => {
        commit(types.SET_ALL_FLAGS, response.data);
        resolve();
      });
    });
  },

  updateAccountFlags({ commit }, { accountId, newflags }) {
    return new Promise(resolve => {
      this.http
        .post(`accounts/${accountId}/update_flags`, {
          account: {
            accounts_flags_attributes: newflags,
          },
        })
        .then(response => {
          commit(
            `account/${types.SET_ACCOUNT_ACCOUNTS_FLAGS}`,
            response.data.updated_flags.accounts_flags,
            {
              root: true,
            }
          );
          commit(
            `account/${types.SET_ACCOUNT_FLAGS}`,
            response.data.updated_flags.flags,
            {
              root: true,
            }
          );
          resolve();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

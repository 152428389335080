<script setup>
import { computed, ref, toRefs } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SceneSection from '@/components/user/anytime/scene_selector/SceneSection.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import ProductChip from '@/components/user/anytime/products/ProductChip.vue';
import PickerProductMultiselectOption from '@/components/user/anytime/products/picker/PickerProductMultiselectOption.vue';
import ProductMultiPicker from '@/components/user/anytime/products/picker/ProductMultiPicker.vue';
import ProductPickerGroupTitle from '@/components/user/anytime/products/picker/ProductPickerGroupTitle.vue';
import useGroupCatalogItemsByReservation from '@/composables/useGroupCatalogItemsByReservation';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  selectedCatalogItems: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(['update:selectedCatalogItems']);

const { accountId, reservationId } = toRefs(props);

const selectedProducts = computed(() =>
  props.selectedCatalogItems.filter(ci => ci.type === 'CatalogProduct')
);
const selectedCustomProps = computed(() =>
  props.selectedCatalogItems.filter(ci => ci.type === 'CatalogProp')
);
const selectedCatalogItemIds = computed(() =>
  props.selectedCatalogItems.map(ci => ci.id)
);

const addCatalogItemToReservationOpen = ref(false);

function deselectCatalogItem(catalogItemId) {
  const newSelectedItems = props.selectedCatalogItems.filter(
    x => x.id !== catalogItemId
  );

  emit('update:selectedCatalogItems', newSelectedItems);
}

function selectCatalogItems(catalogItems) {
  const newSelectedItems = props.selectedCatalogItems.concat(catalogItems);
  addCatalogItemToReservationOpen.value = false;
  emit('update:selectedCatalogItems', newSelectedItems);
}

const { groupProducts } = useGroupCatalogItemsByReservation(reservationId);
</script>

<template>
  <SceneSection component="fieldset">
    <template #heading>products & custom props</template>
    <template #description>
      choose all products that will appear in the scene, even if they do not
      appear in every shot
    </template>
    <SoonaButton
      class="scene-builder-catalog-items__button"
      variation="secondary-black"
      type="button"
      size="medium"
      @on-click="addCatalogItemToReservationOpen = true"
    >
      <SoonaIcon name="plus" />
      add products
    </SoonaButton>

    <ProductMultiPicker
      v-if="addCatalogItemToReservationOpen"
      :account-id="accountId"
      heading="add products"
      :existing-selected-items="selectedCatalogItemIds"
      :attached-to-reservation-id="reservationId"
      order-by="attached_to_reservation_id"
      @cancel="addCatalogItemToReservationOpen = false"
      @select="selectCatalogItems"
    >
      <template
        #product-options="{
          catalogItems,
          selectedCatalogItemIdsString,
          multiSelectChange,
        }"
      >
        <template
          v-for="(items, group) in groupProducts(catalogItems)"
          :key="group"
        >
          <ProductPickerGroupTitle>{{ group }}</ProductPickerGroupTitle>
          <PickerProductMultiselectOption
            v-for="product in items"
            :key="product.id"
            :product="product"
            :checked="
              selectedCatalogItemIdsString.includes(product.id.toString()) ||
              selectedCatalogItemIds.includes(product.id)
            "
            :disabled="selectedCatalogItemIds.includes(product.id)"
            @change="multiSelectChange($event, product)"
          />
        </template>
      </template>
    </ProductMultiPicker>

    <template v-if="selectedProducts.length > 0">
      <h6 id="scene-builder-products-heading" class="u-small--heavy">
        products
      </h6>
      <ul
        class="scene-builder-catalog-items__chips"
        aria-labelledby="scene-builder-products-heading"
      >
        <li v-for="item in selectedProducts" :key="item.id">
          <ProductChip
            :item="item"
            :account-id="accountId"
            removable
            @remove="deselectCatalogItem(item.id)"
          />
        </li>
      </ul>
    </template>

    <template v-if="selectedCustomProps.length > 0">
      <h6 id="scene-builder-custom-props-heading" class="u-small--heavy">
        custom props
      </h6>
      <ul
        class="scene-builder-catalog-items__chips"
        aria-labelledby="scene-builder-custom-props-heading"
      >
        <li v-for="item in selectedCustomProps" :key="item.id">
          <ProductChip
            :item="item"
            :account-id="accountId"
            removable
            @remove="deselectCatalogItem(item.id)"
          />
        </li>
      </ul>
    </template>
  </SceneSection>
</template>

<style lang="scss" scoped>
.scene-builder-catalog-items {
  &__button {
    align-self: flex-start;
    margin-bottom: 0.5rem;
  }

  &__chips {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    list-style: none;
  }
}
</style>

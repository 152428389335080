<script setup>
import { ref } from 'vue';
import { usePackConfigurations } from '@/queries/pack-configurations/usePackConfigurations';
import CreatePackButton from '@/components/crew/pack_builder/CreatePackButton.vue';
import PackDashboardCard from '@/components/crew/pack_builder/PackDashboardCard.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';

const isAccordionOpen = ref(false);

const { data: activePacks, isLoading } = usePackConfigurations({
  packBuilder: true,
  status: ['draft', 'published'],
});
const { data: archivedPacks } = usePackConfigurations({
  packBuilder: true,
  status: ['archived'],
});

const toggleAccordion = () => {
  isAccordionOpen.value = !isAccordionOpen.value;
};
</script>

<template>
  <div>
    <div class="packs">
      <SoonaLoading v-if="isLoading" is-loading loading-text="loading" />
      <div class="packs__header">
        <h2 class="u-display--regular">current packs</h2>
        <CreatePackButton />
      </div>
      <div class="packs__current">
        <PackDashboardCard
          v-for="pack of activePacks?.data"
          :key="pack.id"
          :pack="pack"
        />
        <div
          v-if="archivedPacks?.data.length > 0"
          class="packs__archived"
          :class="{ 'packs__archived--closed': !isAccordionOpen }"
        >
          <h3 class="packs__archived-heading">
            <button
              id="archived-packed-collapse-trigger"
              class="packs__archived-collapse-toggle u-button-reset"
              :aria-expanded="isAccordionOpen"
              aria-controls="archived-packs-collapse-region"
              @click="toggleAccordion"
            >
              <span class="u-title--regular">archived packs</span>
              <SoonaIcon
                :class="['packs__archived-header-chevron', { isAccordionOpen }]"
                name="chevron-down"
              />
            </button>
          </h3>
          <div
            id="archived-packs-collapse-region"
            role="region"
            aria-labelledby="archived-packed-collapse-trigger"
            class="packs__archived"
          >
            <PackDashboardCard
              v-for="pack of archivedPacks?.data"
              :key="pack.id"
              :pack="pack"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.packs {
  margin: 0 auto;
  max-width: 62.5rem;
  padding: 0 1rem;

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 2rem;
  }

  &__current {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__archived {
    width: 100%;

    &--closed {
      padding-bottom: 0;
      [role='region'] {
        display: none;
      }
    }

    &-heading {
      @include variables_fonts.u-subheader--regular;
      border-bottom: 0.0625rem solid variables.$gray-30;
      border-top: 0.0625rem solid variables.$gray-30;
      margin-bottom: 1rem;
      padding: 0;
      width: 100%;
    }

    &-collapse-toggle {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 1rem 0;
    }

    &-header-chevron {
      color: inherit;
      transition: transform 300ms ease-out;
      &.isAccordionOpen {
        transform: rotate(180deg);
      }
    }
  }
}
</style>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { toValue } from 'vue';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<number>} [accountId]
 * @param {number | Ref<number>} [collectionId]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useUpdateFavoritesCollection(accountId, collectionId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.put(
        `/accounts/${toValue(accountId)}/favorites_collections/${toValue(
          collectionId
        )}`,
        {
          favorites_collection: body,
        }
      );

      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collectionsDA(accountId, 'favorites_collection'),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.digitalAssetsObliterateAllKeys(),
        }),
      ]);
    },
  });
}

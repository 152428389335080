<script setup>
import { computed, ref } from 'vue';
import { useDigitalAssetCollections } from '@/queries/digital_assets/useDigitalAssetCollections';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import AlbumPicker from '@/components/user/anytime/album_picker/AlbumPicker.vue';
import { useCreateAlbumCollectionDigitalAsset } from '@/queries/album_collection_digital_assets/useCreateAlbumCollectionDigitalAsset';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useDeleteAlbumCollectionDigitalAsset } from '@/queries/album_collection_digital_assets/useDeleteAlbumCollectionDigitalAsset';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';

const queryClient = useQueryClient();

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId } = storeToRefs(mediaEditorStore);

const { asset } = useMediaEditorDigitalAsset(assetAccountId, assetId);

// use
const { data: collectionItems, error: collectionsError } =
  useDigitalAssetCollections(
    {
      accountId: assetAccountId.value,
      assetId,
      params: {},
    },
    {
      enabled: computed(() => !!asset.value),
    }
  );

const {
  mutate: createAlbumCollectionDigitalAsset,
  error: createAlbumDigitalAssetError,
} = useCreateAlbumCollectionDigitalAsset();

const {
  mutate: deleteAlbumCollectionDigitalAsset,
  error: deleteCollectionItemError,
} = useDeleteAlbumCollectionDigitalAsset();

const { addToast } = useSoonaToast();

// album picker
const isAlbumPickerOpen = ref(false);

const selectAlbum = albumId => {
  createAlbumCollectionDigitalAsset(
    {
      collectionId: albumId,
      body: {
        collection_digital_asset: {
          digital_asset_id: assetId.value,
        },
      },
    },
    {
      onSuccess: () => {
        isAlbumPickerOpen.value = false;

        queryClient.invalidateQueries({
          queryKey: queryKeys.digitalAssetCollections(assetAccountId, assetId),
        });
      },
      onError: () => {
        addToast(`error adding asset to album`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
  isAlbumPickerOpen.value = false;
};

const handleDelete = collectionItem => {
  deleteAlbumCollectionDigitalAsset(
    { collectionId: collectionItem.collection_id, cdaId: collectionItem.id },
    {
      onSuccess: () => {
        addToast(`removed from album ${collectionItem.title}`, {
          variation: 'success',
          noDismissButton: true,
        });

        queryClient.invalidateQueries({
          queryKey: queryKeys.digitalAssetCollections(assetAccountId, assetId),
        });
      },
      onError: () => {
        addToast(`error removing from album ${collectionItem.title}`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};

// error
const priorityError = usePriorityError(
  collectionsError,
  createAlbumDigitalAssetError,
  deleteCollectionItemError
);
</script>

<template>
  <div
    class="digital-asset-collections"
    data-cypress="manage-digital-asset-collections"
  >
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <div
      v-if="collectionItems?.length"
      class="digital-asset-collections__albums"
    >
      <template v-for="ci in collectionItems" :key="ci.id">
        <SoonaChip
          removable
          :to="{
            name: 'account-gallery',
            params: {
              accountId: assetAccountId,
            },
            query: {
              collection_id: ci.collection_id,
            },
          }"
          :data-cypress="`link-collection-${ci.title.replace(/\s+/g, '-')}`"
          @remove="handleDelete(ci)"
        >
          <SoonaIcon name="album" size="small" />
          {{ ci.title }}
        </SoonaChip>
      </template>
    </div>
    <div class="digital-asset-collections__actions">
      <SoonaButton
        variation="tertiary"
        data-cypress="button-add-to-album"
        title="add digital asset to album"
        @click="isAlbumPickerOpen = true"
      >
        <SoonaIcon name="plus-large" />
        add to album
      </SoonaButton>
    </div>

    <AlbumPicker
      v-if="isAlbumPickerOpen"
      :account-id="assetAccountId"
      :existing-options="collectionItems.map(x => x.collection_id)"
      :selected-digital-assets="[asset]"
      @cancel="isAlbumPickerOpen = false"
      @select="selectAlbum"
    />
  </div>
</template>

<style lang="scss" scoped>
.digital-asset-collections {
  display: flex;
  flex-flow: column;
  gap: 1rem;

  &__albums {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;

    &--column {
      flex-direction: column;
      order: 1;
    }
  }
}
</style>

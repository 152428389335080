<template>
  <div class="expanding-circle" />
</template>

<style lang="scss" scoped>
@use '@/variables';

.expanding-circle {
  left: 50%;
  top: -6rem;
  z-index: -1;
  width: 18.75rem;
  height: 18.75rem;
  position: absolute;
  border-radius: 100%;
  transform-origin: 50% 0;
  background-color: white;
  transform: translateX(-50%) scale(0);
  animation: expandCircle 0.3s ease-out forwards;

  @keyframes expandCircle {
    0% {
      transform: translateX(-50%) scale(0);
    }
    100% {
      transform: translateX(-50%) scale(4.5);
    }
  }
}

@media (min-width: variables.$screen-sm-min) {
  .expanding-circle {
    top: -9rem;
    @keyframes expandCircle {
      0% {
        transform: translateX(-50%) scale(0);
      }
      100% {
        transform: translateX(-50%) scale(6);
      }
    }
  }
}

@media (min-width: variables.$screen-md-min) {
  .expanding-circle {
    top: -12rem;
    @keyframes expandCircle {
      0% {
        transform: translateX(-50%) scale(0);
      }
      100% {
        transform: translateX(-50%) scale(8);
      }
    }
  }
}
</style>

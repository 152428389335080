<script setup>
import { computed, toRefs } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const {
  data: reservation,
  isLoading: isLoadingReservation,
  error: reservationError,
} = useReservation(reservationId);

const {
  data: flatBackdropOptionsData,
  isLoading: flatBackdropOptionsLoading,
  error: flatBackdropOptionsError,
} = useReservationTags(reservationId, 'scenes', 'flat_backdrop');

const flatBackdropOptions = computed(
  () => flatBackdropOptionsData.value?.options ?? []
);

const {
  data: lifeStyleOptionsData,
  isLoading: lifestyleOptionsLoading,
  error: lifestyleOptionsError,
} = useReservationTags(reservationId, 'scenes', 'lifestyle');

const lifeStyleOptions = computed(
  () => lifeStyleOptionsData.value?.options ?? []
);

const {
  data: trendSetOptionsData,
  isLoading: trendSetOptionsLoading,
  error: trendSetOptionsError,
} = useReservationTags(reservationId, 'trend-sets');

const trendSetOptions = computed(
  () => trendSetOptionsData.value?.options ?? []
);

const selectableScenes = computed(() => {
  return [
    ...flatBackdropOptions.value,
    ...lifeStyleOptions.value,
    ...trendSetOptions.value,
  ].map(scene => {
    const option = { ...scene };
    if (option.incompatible)
      option.label += ' - incompatible with current location';
    return option;
  });
});

const selectedOptions = computed(() =>
  selectableScenes.value.filter(o => o.selected)
);

const hasReachedMaxSelected = computed(() => {
  return selectedOptions.value?.length >= 2;
});

const isSelectable = option => !hasReachedMaxSelected.value && !option.selected;

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId);

function onSelect(option) {
  addReservationTag({ tagId: option.tag.id });
}
const onDeselect = option => {
  removeReservationTag({
    reservationTagId: option.reservation_tag.id,
    deleteObsoleted: true,
  });
};

const { stepIsIncomplete } = useIncompleteSteps(reservation, slug);

const priorityError = usePriorityError(
  flatBackdropOptionsError,
  lifestyleOptionsError,
  trendSetOptionsError,
  addError,
  removeError,
  reservationError,
  stepIsIncomplete
);

const isBusy = computed(() => {
  return (
    flatBackdropOptionsLoading.value ||
    lifestyleOptionsLoading.value ||
    trendSetOptionsLoading.value ||
    isAdding.value ||
    isRemoving.value ||
    isLoadingReservation.value
  );
});
</script>

<template>
  <SoonaSelect
    :model-values="selectedOptions"
    :options="selectableScenes"
    :disabled="isBusy"
    :loading="isBusy"
    multi
    :selectable="option => isSelectable(option)"
    placeholder="choose a max of 2"
    data-cypress="select-photo-scenes"
    @option:selecting="onSelect"
    @option:deselecting="onDeselect"
  >
    <template #label>scenes</template>
  </SoonaSelect>

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

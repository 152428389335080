<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import BaseProductPicker from './BaseProductPicker.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  multiselect: {
    type: Boolean,
    default: false,
  },
  showCreate: {
    type: Boolean,
    default: false,
  },
  attachedToReservationId: {
    type: [Number, String],
    default: null,
  },
  orderBy: {
    type: String,
    default: 'name',
  },
  showInventoryCounts: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['cancel', 'select', 'createAndSelect']);

const accountId = computed(() => props.accountId);

const searchRef = ref(null);

watchEffect(() => {
  if (searchRef.value) {
    searchRef.value.focus();
  }
});

const search = ref('');

function cancel() {
  emit('cancel');
}

function select(productId, product) {
  if (props.multiselect) {
    emit('createAndSelect', productId, product);
  } else {
    emit('select', productId, product);
  }
}

const includeVariants = ref(false);
</script>

<template>
  <SoonaDialog max-width="48rem" top-affixed @close="cancel">
    <template #heading>
      <slot name="heading" />
    </template>
    <template #header>
      <div v-if="$slots.header" class="product-picker-dialog__header-slot">
        <slot name="header" />
      </div>
      <SoonaTextfield
        ref="searchRef"
        v-model="search"
        type="search"
        label="search products"
        name="products-search"
        placeholder="product name or SKU"
        hide-label
        class="product-picker-dialog__search"
      >
        <template #icon-left>
          <SoonaIcon name="search" size="small" />
        </template>
      </SoonaTextfield>
      <SoonaToggle
        v-model="includeVariants"
        type="switch"
        label="show variants"
        class="product-picker-dialog__variant-toggle"
      />
    </template>
    <BaseProductPicker
      :account-id="accountId"
      :multiselect="multiselect"
      :show-create="showCreate"
      :search="search"
      :attached-to-reservation-id="attachedToReservationId"
      :order-by="orderBy"
      :include-variants="includeVariants"
      :show-inventory-counts="showInventoryCounts"
      @cancel="cancel"
      @select="select"
    >
      <template #product-options="{ catalogItems }">
        <slot name="product-options" :catalog-items="catalogItems" />
      </template>
    </BaseProductPicker>
    <template v-if="$slots.footer" #footer="{ close }">
      <slot name="footer" :close="close" />
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.product-picker-dialog {
  &__header-slot {
    margin-bottom: 1.5rem;
  }

  &__search {
    margin-top: 1rem;
    padding-bottom: 0;
  }

  &__variant-toggle {
    margin-top: 1rem;
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { trendSections } from '@/lib/vimeo-videos';

const { linkClicked } = useBaseEvents();
const route = useRoute();
const subContext = 'get inspired by content created';

const activeSection = ref(trendSections[0].value);

const displayVideos = computed(() => {
  return (
    trendSections.find(section => section.value === activeSection.value)
      ?.videos ?? []
  );
});

function onFilterClick(section) {
  activeSection.value = section.value;

  linkClicked({
    context: route.meta.context,
    subContext,
    linkLabel: section.display,
    linkHref: null,
  });
}
</script>

<template>
  <div class="get-inspired-videos">
    <h2 class="u-headline--heavy get-inspired-videos__heading">
      get inspired by content created in your industry
    </h2>

    <div class="get-inspired-videos__filters">
      <SoonaButton
        v-for="section in trendSections"
        :key="section.value"
        variation="filter"
        size="large"
        class="get-inspired-videos__filters__button"
        :aria-pressed="activeSection === section.value"
        @on-click="onFilterClick(section)"
      >
        {{ section.display }}
      </SoonaButton>
    </div>

    <div class="get-inspired-videos__videos">
      <div
        v-for="video in displayVideos"
        :key="video.vimeoId + video.additionalParams"
        class="get-inspired-videos__videos__frame-wrap"
      >
        <iframe
          :src="`https://player.vimeo.com/video/${video.vimeoId}?title=0&byline=0&portrait=0&responsive=true${video.additionalParams}`"
          allow="fullscreen; picture-in-picture"
          allowfullscreen
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.get-inspired-videos {
  &__heading {
    margin-bottom: 1.5rem;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 3rem;

    &__button {
      font-weight: 800 !important;
    }
  }

  &__videos {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(min(14rem, 100%), 1fr));
    align-items: start;

    &__frame-wrap {
      display: block;
      border-radius: 1rem;
      border: 0.0625rem solid variables.$black-default;
      background-color: variables.$gray-10;
      overflow: hidden;

      iframe {
        display: block;
        border-radius: 0.9375rem;
        aspect-ratio: 9 / 16;
        width: 100%;
      }
    }
  }
}

@mixin get-inspired-large-styles {
  .get-inspired-videos__videos {
    grid-template-columns: repeat(auto-fit, minmax(min(10rem, 100%), 1fr));
  }

  .get-inspired-videos__videos__frame-wrap:nth-child(2n) {
    margin-top: 2rem;
  }
}

@container ugc-page (min-width: 42rem) {
  @include get-inspired-large-styles;
}

/* compat: remove when dropping Safari 15 */
@supports not (container-type: inline-size) {
  @media (min-width: 42rem) {
    @include get-inspired-large-styles;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { useNotes } from '@/queries/notes/useNotes';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';

const props = defineProps({
  assetId: {
    required: true,
    type: [Number, String],
  },
});

const { data, isLoading, error } = useNotes(
  'digital_assets',
  computed(() => props.assetId)
);
</script>

<template>
  <div class="asset-notes">
    <SoonaLoading v-if="isLoading" is-contained is-loading />
    <SoonaError v-else-if="error" :priority-errors="[error]" />
    <template v-else-if="data.notes[0]?.content">
      <h3 class="u-label--heavy">notes</h3>
      <p class="u-label--regular">{{ data.notes[0].content }}</p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.asset-notes {
  position: relative;
}
</style>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { toValue } from 'vue';
import { useStore } from 'vuex';
import * as Sentry from '@sentry/vue';

/**
 *
 * @param {import('vue').MaybeRefOrGetter<number>} accountId
 * @param {import('vue').MaybeRefOrGetter<number>} orderId
 */
export function useDeleteAccountOrder(accountId, orderId) {
  const queryClient = useQueryClient();
  const store = useStore();

  return useMutation({
    mutationFn: async () => {
      const response = await http.delete(
        `accounts/${toValue(accountId)}/orders/${toValue(orderId)}`
      );
      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.accountOrders(toValue(accountId)),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.orders(toValue(accountId)),
        }),
        store.dispatch(
          'currentUser/loadUnpaidAdditionalCharges',
          toValue(accountId)
        ),
      ]);
    },
    onError: errors => {
      Sentry.captureException(new Error('failed to cancel order'), {
        extra: {
          orderId: toValue(orderId),
          message: errors.message,
        },
      });
    },
  });
}

<script setup>
import { computed } from 'vue';

const props = defineProps({
  inventoryPackagePso: {
    type: String,
    required: false,
  },
});

const text = computed(() => {
  switch (props.inventoryPackagePso) {
    case 'store_at_soona':
      return {
        icon: '🗃',
        prefixText: 'soona will safely ',
        actionText: 'store these items',
        suffixText: null,
      };
    case 'return_shipping':
      return {
        icon: '📬',
        prefixText: 'soona will ',
        actionText: 'return these items',
        suffixText: ' to',
      };
    case 'discard':
      return {
        icon: '🗑',
        prefixText: 'soona will safely ',
        actionText: 'discard these items',
        suffixText: null,
      };
    default:
      return {
        icon: '🚨',
        prefixText: 'let us know how you’d like to process these items',
        actionText: null,
        suffixText: null,
      };
  }
});
</script>

<template>
  <span aria-hidden="true">{{ text.icon }}</span>
  {{ text.prefixText
  }}<span v-if="text.actionText" class="u-body--heavy">{{
    text.actionText
  }}</span
  >{{ text.suffixText }}
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const {
  data: reservation,
  isLoading: isLoadingReservation,
  error: reservationError,
} = useReservation(reservationId);

const {
  data: numberOfPhotoData,
  isLoading,
  error: resTagsError,
} = useReservationTags(reservationId, 'photo-quantity');

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId, { skipInvalidate: true });

const numberOfPhotoOptions = computed(() => {
  return numberOfPhotoData.value?.options ?? [];
});

const selectedSegment = computed(() =>
  numberOfPhotoOptions.value?.find(x => x.selected === true)
);

function onSelect(option) {
  if (selectedSegment.value) {
    removeReservationTag(
      {
        reservationTagId: selectedSegment.value.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSuccess: () => {
          addReservationTag({ tagId: option.tag.id });
        },
      }
    );
  } else {
    addReservationTag({ tagId: option.tag.id });
  }
}

const { stepIsIncomplete } = useIncompleteSteps(reservation, slug);

const priorityError = usePriorityError(
  resTagsError,
  addError,
  removeError,
  reservationError,
  stepIsIncomplete
);

const isBusy = computed(
  () =>
    isLoading.value ||
    isAdding.value ||
    isRemoving.value ||
    isLoadingReservation.value
);
</script>

<template>
  <SoonaSelect
    :model-value="selectedSegment"
    :options="numberOfPhotoOptions"
    :reduce="option => option"
    :disabled="isBusy"
    :loading="isBusy"
    :selectable="option => !option.selected"
    data-cypress="select-photo-quantity"
    @update:model-value="onSelect"
  >
    <template #label>photo quantity</template>
  </SoonaSelect>

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

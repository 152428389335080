<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  showNext: {
    default: true,
    type: Boolean,
  },
  showSkip: {
    default: true,
    type: Boolean,
  },
});

const emit = defineEmits(['next', 'skip']);
</script>

<template>
  <div class="complete-profile-card">
    <div v-if="$slots['heading']" class="profile-card-heading">
      <h1 class="u-title--heavy">
        <slot name="heading" />
      </h1>
      <p v-if="$slots['subheading']" class="u-label--small">
        <slot name="subheading" />
      </p>
    </div>
    <div class="content-container">
      <slot />
    </div>
    <div class="action-btns">
      <SoonaButton
        v-if="showSkip"
        variation="tertiary"
        @on-click="emit('skip')"
      >
        skip
      </SoonaButton>
      <SoonaButton
        v-if="showNext"
        :disabled="disabled"
        @on-click="emit('next')"
      >
        next
      </SoonaButton>
      <slot name="action-btns" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.complete-profile-card {
  align-items: center;
  background-color: variables.$white-default;
  border-radius: 0.75rem;
  box-shadow: variables.$elevation-2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 26rem;
  min-height: 21.875rem;
  padding: 1.5rem;
  text-align: center;

  .profile-card-heading {
    padding-bottom: 0.5rem;
  }

  .content-container {
    width: 100%;
  }

  .action-btns {
    display: flex;
    gap: 1rem;
  }
}
</style>

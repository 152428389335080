import { http } from '../config/vue-axios';

/**
 *
 * @param {number} locationId
 * @param {AbortSignal} [signal]
 */
export async function getLocationAvailability(
  locationId,
  { duration, month, reservationId, showAll },
  signal
) {
  const params = {};
  if (reservationId) params['reservation_id'] = reservationId;
  if (month) params['month'] = month;
  if (showAll) params['show_all'] = true;
  if (duration) params['duration'] = duration;
  const response = await http.get(
    `/locations/${locationId}/monthly_availability`,
    {
      params,
      signal,
    }
  );

  return response.data;
}

export async function getLocations(locationTypes, signal) {
  const params = { location_types: locationTypes };
  const response = await http.get(`locations.json`, {
    params,
    signal,
  });

  return response.data;
}

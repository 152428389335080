<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.175 6.533 10 10.35l3.825-3.817L15 7.708l-5 5-5-5 1.175-1.175Z"
      :fill="$attrs.fill || '#71727f'"
    />
  </svg>
</template>

<template>
  <div class="booking-checkbox">
    <label>
      <input
        v-model="inputValue"
        type="checkbox"
        :required="required"
        autofocus
        :data-cypress="cypressName"
      />
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    cypressName: {
      required: false,
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
    modelValue: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.booking-checkbox {
  align-items: center;
  background: transparent;
  border-radius: none;
  display: flex;
  margin: 0;
  max-width: 100%;
  padding: 0;
  padding-bottom: 1.4375rem;
  width: max-content;

  label {
    @include variables_fonts.u-label--regular;

    color: variables.$black-default;
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 0.3125rem;
    cursor: pointer;
  }

  a {
    position: relative;

    &::after {
      background-color: variables.$black-default;
      content: '';
      position: absolute;
      bottom: 0;
      height: 0.0625rem;
      left: 0;
      width: 100%;
    }
  }
}
</style>

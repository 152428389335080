<script setup>
import { computed, ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaUploadDragAndDropModal from '@/components/uploader/SoonaUploadDragAndDropModal.vue';
import SoonaUploadProgressModal from '@/components/uploader/SoonaUploadProgressModal.vue';

import { useUploader } from '@/composables/useUploader.js';
import { queryKeys } from '@/queries/query-keys';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  digitalAssetId: {
    type: [Number, String],
  },
  orderId: {
    type: [Number, String],
    required: true,
  },
  replace: {
    type: Boolean,
    default: false,
  },
  showCopy: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'medium',
  },
  variation: {
    type: String,
    default: 'secondary-black',
  },
});

const accountId = computed(() => props.accountId);
const digitalAssetId = computed(() => props.digitalAssetId);
const orderId = computed(() => props.orderId);

const title = computed(() => (props.replace ? 'replace raw' : 'upload raw'));
const copy = computed(() => (props.showCopy ? title.value : undefined));

const queryClient = useQueryClient();

const { mutate: updateDigitalAsset } = useUpdateDigitalAsset(accountId);

const attachUpload = async blob => {
  updateDigitalAsset(
    {
      assetId: digitalAssetId,
      body: {
        raw: blob.signed_id,
      },
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.orderAddOns(orderId),
        });
      },
    }
  );
};

const dragAndDropModalVisible = ref(false);
const maxConcurrent = ref(5);
const uploadInterval = ref(100);
const fileSizeLimit = ref('');
const label = ref('');
const accept = ref('');
const uploadType = ref('');
const handleUploadFunc = ref(null);
const startUploadFunc = ref(null);
const handleDropFunc = ref(null);
const handleCancelFunc = ref(null);
const activeUploadsLocal = ref([]);

function hideDragAndDropModal() {
  dragAndDropModalVisible.value = false;
}

function onUploadComplete(blob) {
  attachUpload(blob);
  hideDragAndDropModal();
}

function showDragAndDropModalEdit() {
  label.value = 'raw';
  fileSizeLimit.value = '';
  const {
    handleUpload,
    handleDrop,
    activeUploads,
    cancelUploads,
    startUpload,
  } = useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    null,
    null,
    onUploadComplete
  );
  startUploadFunc.value = startUpload;
  handleUploadFunc.value = handleUpload;
  handleDropFunc.value = handleDrop;
  handleCancelFunc.value = cancelUploads;
  activeUploadsLocal.value = activeUploads.value;
  uploadType.value = 'edit';
  dragAndDropModalVisible.value = true;
}
</script>
<template>
  <SoonaButton
    :variation="variation"
    :size="size"
    :title="title"
    @click="showDragAndDropModalEdit()"
  >
    <SoonaIcon
      name="cloud-up-arrow"
      :size="size"
      style="vertical-align: middle"
    />
    {{ copy }}
  </SoonaButton>
  <SoonaUploadDragAndDropModal
    v-if="dragAndDropModalVisible"
    :accept="accept"
    :active-uploads="activeUploadsLocal"
    :is-multiple="true"
    :label="label"
    @close="hideDragAndDropModal"
    @handle-drop="handleDropFunc"
    @handle-upload="handleUploadFunc"
  />
  <SoonaUploadProgressModal
    v-if="activeUploadsLocal.length > 0"
    :is-multiple="true"
    :active-uploads="activeUploadsLocal"
    :label="label"
    @cancel-uploads="handleCancelFunc"
    @start-upload="startUploadFunc"
  />
</template>

const mappings = Object.freeze({
  behind_the_scenes: 'bts',
  user_generated_content: 'ugc',
  bonus_clip: 'bonus',
});

export function getContentCategoryText(contentCategory) {
  if (Object.hasOwn(mappings, contentCategory)) {
    return mappings[contentCategory];
  }
  return null;
}

<script setup>
import { computed, ref } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useTitle, useWindowSize } from '@vueuse/core';
import { component as Viewer } from 'v-viewer';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';

useTitle('soona dialog test page | soona');

const first = ref(false);
const second = ref(false);
const alertdialog = ref(false);
const showFooter = ref(true);
const topAffixed = ref(false);

const things = ['thing 1', 'thing 2', 'thing 3'];

const { width, height } = useWindowSize();
const showRem = ref(false);
const windowSize = computed(() => {
  const remScale = Number.parseFloat(getComputedStyle(document.body).fontSize);
  return showRem.value
    ? `${width.value / remScale}rem x
        ${height.value / remScale}rem`
    : `${width.value}px x ${height.value}px`;
});
</script>

<template>
  <div class="soona-dialog-test-page">
    <h1 class="u-headline--heavy">SoonaDialog Test Page</h1>

    <Teleport to="body">
      <button
        class="soona-dialog-test-page__window-size"
        @click="showRem = !showRem"
      >
        {{ windowSize }}
      </button>
    </Teleport>

    <p>
      the viewport size is shown in the top left of the screen. click it to swap
      between pixel and rem values. (zooming font isn't shown immediately,
      resize the window or reload the page to see updated rem values.
    </p>

    <SoonaButton variation="secondary-gray" @on-click="first = true">
      open first dialog
    </SoonaButton>
    <SoonaButton variation="secondary-gray" @on-click="alertdialog = true">
      open alertdialog
    </SoonaButton>

    <p>
      lots of content here to cause the page to scroll so we can test that body
      scrolling is set to hidden when a dialog is opened.
    </p>
    <p>
      dialogs have a <code>SoonaSelect</code> in them so we can be sure that the
      list nodes are moved into the dialogs and are stacked correctly with
      multiple dialogs.
    </p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>
    <p>content</p>

    <SoonaDialog
      v-if="first"
      id-backdrop="first-dialog-backdrop"
      :top-affixed="topAffixed"
      @close="
        first = false;
        second = false;
      "
    >
      <template #heading>first dialog</template>
      <template #default>
        <p>first dialog content</p>
        <SoonaToggle v-model="showFooter" type="checkbox" label="show footer" />
        <SoonaToggle v-model="topAffixed" type="checkbox" label="top affixed" />
        <SoonaButton variation="secondary-gray" @on-click="second = true">
          open second dialog
        </SoonaButton>

        <SoonaSelect :options="things">
          <template #label>things</template>
        </SoonaSelect>

        <Viewer
          :options="{
            toolbar: false,
            navbar: false,
            title: false,
            backdrop: true,
            minZoomRatio: 0.01,
            maxZoomRatio: 2,
            container: '#first-dialog-backdrop',
          }"
        >
          <img
            src="https://placehold.co/600x400/EEE/31343C"
            alt="placeholder"
          />
          <p>
            click to open v-viewer. this is hacky and not styled correctly, but
            just here for show functionality. viewers must be moved inside of a
            dialog to keep them inside of the focus trap
          </p>
        </Viewer>

        <SoonaDialog v-if="second" @close="second = false">
          <template #heading>second dialog</template>
          <template #default>
            <p>
              second dialog content. pressing <kbd>Escape</kbd> should only
              close this dialog.
            </p>
            <SoonaToggle
              v-model="showFooter"
              type="checkbox"
              label="show footer"
            />
            <SoonaSelect :options="things">
              <template #label>things</template>
            </SoonaSelect>
          </template>
          <template v-if="showFooter" #footer="{ close }">
            <SoonaButton variation="tertiary" @on-click="close">
              cancel
            </SoonaButton>
            <SoonaButton variation="primary" @on-click="close">ok</SoonaButton>
          </template>
        </SoonaDialog>
      </template>
      <template v-if="showFooter" #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
        <SoonaButton variation="primary" @on-click="close">ok</SoonaButton>
      </template>
    </SoonaDialog>

    <SoonaDialog
      v-if="alertdialog"
      role="alertdialog"
      @close="alertdialog = false"
    >
      <template #heading>alert dialog</template>
      <template #default>
        <p>confirmation</p>
      </template>
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
        <SoonaButton variation="primary" @on-click="close">ok</SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-dialog-test-page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;

  &__window-size {
    position: fixed;
    z-index: 999999;
    background: variables.$white-default;
    padding: 0.25rem 0.5rem;
    border-radius: 0 0 0.25rem 0;
    font-variant-numeric: tabular-nums;
    box-shadow: variables.$elevation-2;
  }
}

kbd {
  font-family: ui-monospace, monospace;
  font-size: 0.875rem;
  border-radius: 0.1875rem;
  padding: 0.125rem 0.25rem;
  border: 0.0625rem solid variables.$gray-30;
  background-color: variables.$gray-10;
  border-bottom-width: 0.1875rem;
}
</style>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useCancelPaymentMethod(accountId) {
  const queryClient = useQueryClient();

  const cancelPaymentMethod = async paymentMethodId => {
    const response = await http.delete(`/payment_methods/${paymentMethodId}`);
    return response.data;
  };

  return useMutation({
    mutationFn: body => {
      return cancelPaymentMethod(body.paymentMethodId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.account(accountId),
      });
    },
  });
}

<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import { useReservation } from '@/composables/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import shootComplexityOptions from './shootComplexity.json';
import {
  GreenApple50,
  Daisy50,
  Tangerine50,
  Roses80,
  BubbletapePink70,
  PeriwinkBlue60,
  WhiteDefault,
  BlackDefault,
} from 'src/variables.module.scss';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const emits = defineEmits(['close']);

const selectedShootComplexity = ref(undefined);
const reservationId = computed(() => props.reservationId);

const { shootComplexity } = useReservation(reservationId);

watchEffect(() => {
  selectedShootComplexity.value = shootComplexity.value;
});

const shootComplexityColorLookup = {
  green: GreenApple50,
  yellow: Daisy50,
  orange: Tangerine50,
  burgundy: Roses80,
  purple: BubbletapePink70,
  blue: PeriwinkBlue60,
};

const textColorLookup = {
  black: BlackDefault,
  white: WhiteDefault,
};

const { mutate, isPending } = useUpdateReservation(reservationId);

const setShootComplexity = () => {
  mutate(
    { shoot_complexity: selectedShootComplexity.value },
    {
      onSuccess: () => {
        emits('close');
      },
    }
  );
};

const handleSetShootComplexity = val => {
  selectedShootComplexity.value = val;
};
</script>
<template>
  <SoonaDialog @close="emits('close')">
    <template #heading
      >{{ shootComplexity ? 'update' : 'set' }} shoot complexity</template
    >
    <div class="shoot-complexity__selected" />
    <div v-for="option in shootComplexityOptions" :key="option.value">
      <SoonaToggle
        :model-value="selectedShootComplexity"
        type="radio"
        name="shoot-complexity"
        :native-value="option.value"
        @update:model-value="handleSetShootComplexity(option.value)"
      >
        <template #label>
          <SoonaFlag
            :background-color="shootComplexityColorLookup[option.color]"
            :text-color="textColorLookup[option.text_color]"
            :title="`${option.color} (${option.value})`"
          />
        </template>
      </SoonaToggle>
      <ul class="shoot-complexity__description">
        <li v-for="(desc, i) in option.description" :key="i">
          {{ desc }}
        </li>
      </ul>
    </div>

    <template #footer="{ close }">
      <SoonaButton variation="tertiary" :disabled="isPending" @on-click="close"
        >cancel</SoonaButton
      >
      <SoonaButton
        :disabled="!selectedShootComplexity"
        :loading="isPending"
        @on-click="setShootComplexity"
      >
        save
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss">
@use '@/variables';

.shoot-complexity {
  &__description {
    list-style-type: disc;
    margin: 0.25rem 0 0.25rem 1.5rem;
    padding: 0.25rem 0;
  }
}
</style>

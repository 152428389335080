<script setup>
import { ref, computed, shallowRef } from 'vue';
import uniqueId from 'lodash/uniqueId';
import { onKeyStroke, useTimeoutFn, useMediaQuery } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useTeleportTo } from '@/composables/useTeleportTo';
import { useDialogSoonaSelect } from '@/composables/useDialogSoonaSelect';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useDialogContext } from '@/composables/useDialog';

const props = defineProps({
  size: {
    default: 'large',
    type: String,
    required: false,
    validator: function (value) {
      return ['full-screen', 'large', 'small'].includes(value);
    },
  },
  maxWidth: {
    // overrides `size` prop max-width
    type: String,
    default: null,
    required: false,
    validator(value, props) {
      return !value || (value && props.size !== 'full-screen');
    },
  },
  iconName: {
    type: String,
    required: false,
  },
  iconColor: {
    type: String,
    default: '#000000',
    required: false,
  },
  iconBg: {
    type: String,
    default: 'transparent',
    required: false,
  },
  role: {
    // default and heading slots required if 'alertdialog'
    default: 'dialog',
    type: String,
    required: false,
    validator: function (value) {
      return ['dialog', 'alertdialog'].includes(value);
    },
  },
  topAffixed: {
    type: Boolean,
    default: false,
    validator(value, props) {
      return !value || (value && props.size !== 'full-screen');
    },
  },
  /**
   * useful for targeting a v-viewer container to the background so that it
   * can fill the whole viewport, rather than just the dialog itself
   */
  idBackdrop: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['close']);

const size = computed(() => props.size);
const maxWidth = computed(() => props.maxWidth);
const iconName = computed(() => props.iconName);
const iconColor = computed(() => props.iconColor);

const id = uniqueId('dialog-');
const { checkAllowEscapeClose } = useDialogContext({ id });

const dialogTarget = ref();
const dialogHeaderTarget = ref();
const dialogContentTarget = ref();
const dialogFooterTarget = ref();
const dialogBgTarget = ref();
const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');
const dialogOverflow = ref(false);

const { start: beginClose, isPending: isClosing } = useTimeoutFn(
  () => {
    emit('close');
  },
  prefersReducedMotion.value ? 0 : 150,
  { immediate: false }
);

const close = () => {
  if (!isClosing.value) beginClose();
};

// prevent drag clicks from closing the dialog
const backdropMouseElement = shallowRef(null);
function backdropMouseDown(event) {
  backdropMouseElement.value = event.target;
}
function backdropMouseUp(event) {
  if (
    backdropMouseElement.value === event.target &&
    props.role !== 'alertdialog'
  ) {
    close();
  }
  backdropMouseElement.value = null;
}

onKeyStroke('Escape', () => {
  if (props.role !== 'alertdialog' && checkAllowEscapeClose(dialogBgTarget)) {
    close();
  }
});
useFocusTrap(dialogBgTarget, { immediate: true });

const teleportTo = useTeleportTo();

useDialogSoonaSelect(dialogBgTarget);
</script>

<template>
  <Teleport :to="teleportTo">
    <div
      :id="idBackdrop"
      ref="dialogBgTarget"
      class="soona-dialog"
      :class="{
        'soona-dialog--full-screen': size === 'full-screen',
        'soona-dialog--small': size === 'small',
        'soona-dialog--custom-max-width': maxWidth,
        'soona-dialog--closing': isClosing,
        'soona-dialog--overflow': dialogOverflow,
        'soona-dialog--top-affixed': topAffixed,
      }"
      @mousedown.self="backdropMouseDown"
      @mouseup.self="backdropMouseUp"
    >
      <div
        :id="id"
        ref="dialogTarget"
        class="soona-dialog__inner"
        v-bind="$attrs"
        aria-modal="true"
        :role="role"
        :tabindex="role === 'dialog' ? '-1' : undefined"
        :aria-labelledby="$slots['heading'] ? `${id}-title` : undefined"
        :aria-describedby="
          $slots['default'] && role === 'alertdialog'
            ? `${id}-content`
            : undefined
        "
      >
        <div
          v-if="
            $slots['heading'] ||
            $slots['header'] ||
            role === 'dialog' ||
            iconName
          "
          ref="dialogHeaderTarget"
          class="soona-dialog__header"
        >
          <div class="soona-dialog__header-top">
            <div v-if="iconName" class="soona-dialog__icon">
              <SoonaIcon :name="iconName" size="medium" />
            </div>
            <h2
              v-if="$slots['heading']"
              :id="`${id}-title`"
              class="u-title--heavy soona-dialog__title"
            >
              <slot name="heading" />
            </h2>
            <SoonaButton
              v-if="role === 'dialog'"
              variation="icon-plain-gray"
              size="medium"
              type="button"
              class="soona-dialog__close"
              aria-label="Close this dialog window"
              @click="close"
            >
              <SoonaIcon name="xmark" />
            </SoonaButton>
          </div>
          <slot name="header" />
        </div>
        <div
          v-if="$slots['default']"
          :id="`${id}-content`"
          ref="dialogContentTarget"
          class="soona-dialog__content"
        >
          <slot :close="close" :content-el="dialogContentTarget" />
        </div>
        <div
          v-if="$slots['footer']"
          ref="dialogFooterTarget"
          class="soona-dialog__footer"
        >
          <slot name="footer" :close="close" />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-up {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-1rem);
  }
}

@keyframes fade-in-bg {
  0% {
    background-color: rgba(63, 67, 75, 0);
  }

  100% {
    background-color: rgba(63, 67, 75, 0.7);
  }
}

@keyframes fade-out-bg {
  0% {
    background-color: rgba(63, 67, 75, 0.7);
  }

  100% {
    background-color: rgba(63, 67, 75, 0);
  }
}

.soona-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &__inner {
    display: flex;
    flex-direction: column;
    background-color: variables.$white-default;
    width: 100%;
    position: absolute;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: variables.$black-default;
    animation: 0.15s ease-out both k-fade-in;
    overflow-y: auto;

    .soona-dialog--closing & {
      animation: 0.15s ease-out both k-fade-out;
    }

    /* show borders for dialogs, but not alertdialogs */
    &[role='dialog'] {
      .soona-dialog__footer {
        border-top: 0.0625rem solid variables.$gray-30;
      }
    }
  }

  &__header {
    flex-shrink: 0;
    padding: 1.5rem 1.5rem 1rem;
  }

  &__header-top {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }

  &__icon {
    color: v-bind('iconColor');
    background-color: v-bind('iconBg');
    flex: 0 0 2rem;
    padding: 0.375rem;
    border-radius: 50%;

    svg {
      display: block;
    }
  }

  &__title {
    display: flex;
    align-items: baseline;
    line-height: 1.6;
  }

  &__close {
    margin-left: auto;
  }

  &__content {
    flex-grow: 1;
    padding: 1.5rem;

    &:not(:first-child) {
      padding-top: 1rem;
    }

    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  &__footer {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.75rem;
    padding: 1.5rem;
  }
}

@media (min-width: variables.$screen-sm-min) {
  .soona-dialog {
    background-color: rgba(63, 67, 75, 0.7);
    animation: 0.15s ease-out both fade-in-bg;

    &--closing {
      animation: 0.15s ease-out both fade-out-bg;
    }

    &__inner {
      box-shadow: variables.$elevation-3;
      position: static;
      border-radius: 0.5rem;
      width: 100%;
      max-width: 33rem;
      animation: 0.15s ease-out both fade-in-down;

      .soona-dialog--closing & {
        animation: 0.15s ease-out both fade-out-up;
      }

      .soona-dialog--small & {
        max-width: 21.875rem;
      }

      .soona-dialog--custom-max-width & {
        max-width: v-bind('maxWidth');
      }

      .soona-dialog--full-screen & {
        max-width: none;
        width: 100%;
        height: 100%;
        margin: 0;
        max-height: 100%;
        border-radius: 0;
      }
    }

    &__content {
      &:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

      &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      .soona-dialog--full-screen & {
        max-height: none;
        border-radius: 0;
      }
    }
  }
}

@media (min-height: 30rem) {
  .soona-dialog {
    &__inner {
      overflow: visible;
    }

    &__content {
      overflow: auto;
    }

    /* only actually affix to the top on taller screens */
    &--top-affixed {
      align-items: flex-start;
    }
  }
}

@media (min-width: variables.$screen-sm-min) and (min-height: 30rem) {
  .soona-dialog {
    &__inner {
      /* pixels so that there is more space if a user zooms the text */
      margin-block: 32px;
      max-height: calc(100% - 64px);
    }

    &__content {
      min-height: 3.5rem;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .soona-dialog {
    animation: none !important;

    &__inner {
      animation: none !important;
    }
  }
}
</style>

import { useRoute } from 'vue-router';
import { useReservation } from '@/queries/useReservation';
import { computed } from 'vue';
import { reservationPropertiesV2 } from '@/lib/segment/segment';
import { useUserGlobalAttrs } from '@/composables/user/useUserGlobalAttrs';

export function useCurrentReservationAndUser({ reservationId } = {}) {
  const route = useRoute();

  // use existing route to find reservationId unless the reservationId is provided as an argument
  const resId = computed(() =>
    reservationId?.value ? reservationId.value : route.params?.reservationId
  );

  // query only fires if reservationId is truthy
  const { data: reservation } = useReservation(
    computed(() => resId.value),
    {
      enabled: computed(() => !!resId.value),
    }
  );
  // get current user data
  const { userGlobalAttrs: user } = useUserGlobalAttrs();
  // get tag categories for needed for segment reservation traits
  const tagCategories = computed(() => {
    return reservation.value?.reservation_tags?.map(tag => tag.tag_category);
  });
  // parse reservation data for segment traits
  const reservationTraits = computed(() => {
    return reservationPropertiesV2({
      reservation: reservation.value,
      tags: reservation.value?.tags,
      tagCategories: tagCategories.value,
      location: reservation.value?.location,
      pack: reservation.value?.pack_configuration
        ? reservation.value.pack_configuration
        : null,
    });
  });

  // convert user object keys to camelCase to fulfill segment traits requirements
  const userTraits = computed(() => {
    return Object.keys(user.value).reduce((acc, key) => {
      const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase());
      acc[camelCaseKey] = user.value[key];
      return acc;
    }, {});
  });

  return {
    reservation,
    reservationTraits,
    userTraits,
  };
}

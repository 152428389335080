const HEX = Symbol('hex');
const HUE = Symbol('hue');
const LIGHTNESS = Symbol('lightness');
const SATURATION = Symbol('saturation');

const BACKGROUND_COLOR_SYMBOLS = [
  { symbol: HEX, paywalled: false, label: 'enter hex' },
  { symbol: HUE, paywalled: false, label: 'hue' },
  { symbol: LIGHTNESS, paywalled: false, label: 'lightness' },
  { symbol: SATURATION, paywalled: false, label: 'saturation' },
];

export { BACKGROUND_COLOR_SYMBOLS, HUE, HEX, LIGHTNESS, SATURATION };

<script setup>
import { computed } from 'vue';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSubscriptionBadge from '@/components/ui_library/SoonaSubscriptionBadge.vue';

const props = defineProps({
  headingId: {
    default: 'subscriptions-dialog-label',
    type: String,
  },
  isTwoColumn: {
    default: true,
    type: Boolean,
  },
  showBackButton: {
    default: true,
    type: Boolean,
  },
  tierIcon: {
    type: String,
  },
  tierSlug: {
    type: String,
  },
  tierTitle: {
    default: null,
    type: String,
  },
  noImagePadding: {
    default: false,
    type: Boolean,
  },
  rightContentPadding: {
    default: '4rem',
    type: String,
  },
  rightContentAlignment: {
    default: 'center',
    type: String,
  },
  rightContentJustification: {
    default: 'center',
    type: String,
  },
});

const emits = defineEmits(['back', 'close', 'next']);

const showBackButton = computed(() => props.showBackButton);
const isTwoColumn = computed(() => props.isTwoColumn);
</script>

<template>
  <div class="step-layout">
    <div
      class="step-layout__left"
      :class="{ 'step-layout__left--single-col': !isTwoColumn }"
    >
      <div class="step-layout__left-inner">
        <div
          class="step-layout__header"
          :class="{
            'step-layout__header--first-step': !showBackButton,
          }"
        >
          <div
            class="step-layout__back-btn-container"
            :class="{
              'step-layout__back-btn-container--hidden': !showBackButton,
            }"
          >
            <SoonaBackButton
              :has-margin="false"
              :is-dark-mode="!isTwoColumn"
              @click="emits('back')"
            />
          </div>
          <slot name="header" />
          <SoonaButton
            class="step-layout__close-btn"
            :class="{ 'step-layout__close-btn--two-column': isTwoColumn }"
            variation="icon-gray-outline"
            data-cypress="button-close-subscriptions-dialog"
            @on-click="emits('close')"
          >
            <SoonaIcon name="xmark" /><span class="u-visually-hidden">
              close modal
            </span>
          </SoonaButton>
        </div>
        <div
          class="step-layout__content"
          :class="{ 'step-layout__content--first-step': !showBackButton }"
        >
          <div class="step-layout__heading-container">
            <div class="step-layout__heading-left">
              <SoonaSubscriptionBadge
                v-if="tierTitle"
                class="step-layout__badge"
                :icon-name="tierIcon"
                :tier-slug="tierSlug"
                :tier-title="tierTitle"
                :show-label="true"
                icon-size="x-large"
              />
              <h2
                :id="headingId"
                class="step-layout__heading"
                data-cypress="text-subscriptions-heading"
              >
                <slot name="heading" />
              </h2>
              <p v-if="$slots['subheading']" class="step-layout__subheading">
                <slot name="subheading" />
              </p>
            </div>
          </div>
          <slot />
        </div>
      </div>
    </div>
    <div v-if="isTwoColumn" class="step-layout__right">
      <div
        class="step-layout__right-inner"
        :class="{
          'step-layout__right-inner--full': !$slots['right-col-content'],
        }"
      >
        <div
          class="step-layout__right-top"
          :class="{
            'step-layout__right-top--no-padding': noImagePadding,
          }"
        >
          <slot name="main-image" />
        </div>
        <div
          v-if="$slots['right-col-content']"
          class="step-layout__right-bottom"
        >
          <slot name="right-col-content" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.step-layout {
  background: variables.$gradient-periwink-error;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;

  @media (min-width: variables.$screen-md-min) {
    border-radius: 0.625rem;
    flex-direction: row;
  }

  &__left {
    border-radius: 0;
    border-bottom-left-radius: 0.625rem;
    border-top-left-radius: 0.625rem;
    flex: 1 1 100%;
    height: 100%;

    @media (min-width: variables.$screen-md-min) {
      flex-basis: 50%;
      padding: 0.75rem;
    }

    &--single-col {
      flex-basis: 100%;

      .step-layout__heading-left {
        color: variables.$white-default;
        text-align: center;
        width: 100%;
      }

      .step-layout__left-inner {
        background: transparent;
      }
    }
  }

  &__left-inner {
    background-color: variables.$white-default;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    padding: 1rem 0;
    z-index: 1;

    @media (min-width: 32.5rem) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @media (min-width: variables.$screen-md-min) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &--first-step {
      @media (min-width: variables.$screen-md-min) {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }

  &__back-btn-container {
    margin-left: 1rem;

    &--hidden {
      visibility: hidden;
    }

    @media (min-width: 32.5rem) {
      flex-grow: 1;
      margin: 0;
    }
  }

  &__close-btn {
    margin-left: auto;
    margin-right: 1rem;
    order: 2;
    width: max-content;

    @media (min-width: 32.5rem) {
      margin: 0;
      order: 0;
    }

    @media (min-width: variables.$screen-md-min) {
      outline-color: variables.$friendly-red-50;
      outline-width: 0.25rem;
      position: absolute;
      right: -3.75rem;
      top: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
    padding-top: 0;

    @media (min-width: variables.$screen-md-min) {
      padding: 1.75rem 2rem 1.25rem;
      padding-top: 0;
    }

    &--first-step {
      @media (min-width: variables.$screen-md-min) {
        padding-top: 1.75rem;
      }
    }
  }

  &__badge {
    margin-bottom: 1rem;
  }

  &__heading-container {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.25rem;
  }

  &__heading {
    @include variables_fonts.u-headline--heavy;

    padding-bottom: 0.25rem;

    @media (min-width: variables.$screen-md-min) {
      @include variables_fonts.u-display--heavy;
    }
  }

  &__subheading {
    @media (min-width: variables.$screen-md-min) {
      @include variables_fonts.u-subheader--regular;
    }
  }

  &__toggle-container {
    background-color: variables.$gray-30;
    border-radius: 0.625rem;
    display: flex;
    padding: 0.25rem;
    width: max-content;
  }

  &__toggle-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.5rem 2rem;
    width: max-content;

    input:checked + & {
      background-color: variables.$black-default;
      border-radius: 0.625rem;
      color: variables.$white-default;
    }
  }

  &__right {
    display: none;

    @media (min-width: variables.$screen-md-min) {
      border-bottom-right-radius: 0.625rem;
      border-top-right-radius: 0.625rem;
      display: block;
      flex: 1 1 50%;
      line-height: 0;
      overflow-y: auto;
    }
  }

  &__right-inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: min-content;
    justify-content: space-between;
    min-height: 100%;
    position: relative;

    &--full {
      display: block;
      height: 100%;

      .step-layout__right-top {
        height: 100%;
      }
    }
  }

  &__right-top {
    align-items: v-bind('rightContentAlignment');
    display: flex;
    flex-grow: 1;
    justify-content: v-bind('rightContentJustification');
    padding: v-bind('rightContentPadding');
    position: relative;

    :deep(img) {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }

    &--no-padding {
      padding: 0;
      align-items: flex-end;

      :deep(img) {
        height: auto;
      }
    }
  }

  &__right-bottom {
    margin: auto;
    padding: 2rem 1.75rem;
    padding-top: 0;
  }
}
</style>

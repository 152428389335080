import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useUndoCompleteEdits(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return http.put(
        `/reservations/${toValue(reservationId)}/undo_complete_edits.json`
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
  });
}

/**
 *
 * @param {Object} [params]
 * @param {Object} [filters]
 *
 * */
export default (params, filters) => {
  if (filters) {
    if (filters['catalog_item_id']) {
      params['catalog_item_id'] = filters['catalog_item_id'];
    }
    if (filters['collection_id']) {
      params['collection_id'] = filters['collection_id'];
    }
    if (filters['collection_type']) {
      params['collection_type'] = filters['collection_type'];
    }
    if (filters['collection_type']) {
      params['filter'] = filters['collection_type'];
    } // todo: let us change this to a more specific name after 1/23
    if (filters['origin']) params['origin'] = filters['origin'];
    if (filters['origin_source'])
      params['origin_source'] = filters['origin_source'];
    if (filters['ownership']) params['ownership'] = filters['ownership'];
    if (filters['reservation_id']) {
      params['reservation_id'] = filters['reservation_id'];
    }
    if (filters['visibility']) params['visibility'] = filters['visibility'];
    if (filters['cv_tags']) params['cv_tags'] = filters['cv_tags'];
    if (filters['excluded_cv_tags'])
      params['excluded_cv_tags'] = filters['excluded_cv_tags'];
    if (filters['min_side_length'])
      params['min_side_length'] = filters['min_side_length'];
    if (filters['media_type']) params['media_type'] = filters['media_type'];
    if (filters['origin_source'])
      params['origin_source'] = filters['origin_source'];
  }
};

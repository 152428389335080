<script setup>
import { computed } from 'vue';
import CompetitorDetailRuleResult from './CompetitorDetailRuleResult.vue';

const props = defineProps({
  competitor: {
    type: Object,
    required: true,
  },
});

const imageQualityRuleResults = computed(() =>
  props.competitor.listing_score.rule_results
    .find(rr => rr.rule_group === 'image quality')
    ?.rules.filter(r => r.score !== 1)
);
</script>

<template>
  <div v-if="imageQualityRuleResults.length > 0" class="image-quality">
    <div class="image-quality__header--wrapper">
      <h4 class="image-quality__header u-subheader--heavy">
        image quality problems
      </h4>
    </div>

    <div class="image-quality__body">
      <ul class="image-quality__rule-results">
        <li v-for="(rule, i) in imageQualityRuleResults" :key="i">
          <CompetitorDetailRuleResult :rule="rule" />
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.image-quality {
  &__header {
    padding: 1rem;

    &--wrapper {
      background-color: variables.$friendly-red-20;
      border-radius: 0.625rem;
      // to do: make this a gradient
      border-left: 1rem solid variables.$gradient-red-start;
    }
  }

  &__body {
    margin-top: 1.5rem;
  }

  &__rule-results {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
</style>

<script setup>
import { ref, watchEffect } from 'vue';
import { useGetCreditReasons } from '@/queries/credits/useGetCreditReasons';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';

const props = defineProps({
  paymentOptions: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const emit = defineEmits([
  'selectPayment',
  'selectCompReason',
  'setPaymentDetails',
]);
const selectedPaymentOption = ref('');
const { data: creditReasonOptions } = useGetCreditReasons();
const selectedCreditReason = ref('');

function updateCompReason(option) {
  selectedCreditReason.value = option;
  emit('selectCompReason', option);
}

function selectPayment(option) {
  emit('selectPayment', option.value);
  if (option.value !== 'comp_order') {
    updateCompReason('');
  }
}

function clearPaymentSelection() {
  selectedPaymentOption.value = '';
  selectedCreditReason.value = '';
}

watchEffect(() => {
  if (props.disabled) {
    clearPaymentSelection();
  }
});
</script>
<template>
  <label
    v-for="option in paymentOptions"
    :key="option.label"
    :option="option"
    :class="{
      'payment-selector__payment-options-card--selected':
        option.value === selectedPaymentOption,
      'payment-selector__payment-options-card--disabled':
        disabled || option.disabled,
    }"
    class="payment-selector__payment-options-card"
    data-cypress="payment-selector-option"
  >
    <input
      :id="option.value"
      v-model="selectedPaymentOption"
      type="radio"
      class="payment-selector__payment-options-card__radio-button"
      :name="option.label"
      :value="option.value"
      :disabled="disabled || option.disabled"
      @click="() => selectPayment(option)"
    />
    <span>
      <p class="payment-selector__payment-options-card__title">
        {{ option.label }}
      </p>
      <p v-if="option.subTitle">
        {{ option.subTitle }}
      </p>
      <ul>
        <li v-for="item in option.description" :key="item.description">
          {{ item }}
        </li>
      </ul>
    </span>
  </label>
  <SoonaSelect
    v-if="selectedPaymentOption === 'comp_order'"
    class="comp-reason-select"
    :options="creditReasonOptions"
    :selectable="option => !option.selected"
    data-cypress="payment-selector-comp-reason-select"
    @option:selecting="updateCompReason"
  >
    <template #label>comp reason</template>
  </SoonaSelect>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.payment-selector {
  &__payment-options-card {
    display: flex;
    align-items: center;
    border: 0.125rem solid variables.$gray-30;
    padding: 0.9375rem;
    border-radius: 0.5rem;
    margin: 1rem 0;
    cursor: pointer;
    ul {
      list-style-type: disc;
      margin-left: 0.9375rem;
    }
    &--selected {
      border: 2px solid variables.$periwink-blue-60;
    }
    &--disabled {
      background-color: #f8f8f8;
      border-color: variables.$gray-30;
      cursor: not-allowed;
    }
    &__radio-button {
      margin-right: 0.9375rem;
    }
    &__title {
      @include variables_fonts.u-body--heavy;
    }
  }
}
.comp-reason-select {
  margin: 0 0 1.25rem 0;
}
</style>

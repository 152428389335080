<script setup>
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';

defineProps({
  filterOptions: {
    type: Array,
    required: true,
  },
});

const searchText = defineModel('searchText', {
  type: String,
  required: true,
});

const selectedFilter = defineModel('selectedFilter', {
  type: String,
  required: true,
});
</script>

<template>
  <div class="bookings-filters">
    <div class="bookings-filters__search">
      <SoonaTextfield
        v-model="searchText"
        type="search"
        label="search"
        name="all-bookings-search"
        placeholder="search keywords"
        hide-label
      >
        <template #icon-left>
          <SoonaIcon name="search" size="small" />
        </template>
      </SoonaTextfield>
    </div>
    <SoonaFilterSingle v-model="selectedFilter" :options="filterOptions" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.bookings-filters {
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;

  &__search {
    :deep(.soona-textfield) {
      padding-bottom: 0;
    }
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getTransferPackageRequirements } from '../api/transferPackageRequirements';

/**
 *
 * @param {Object} params
 * @param {number | Ref<number>} [params.accountId]
 * @param {number | Ref<number>} [params.page]
 * @param {string | Ref<string>} [params.search]
 */
export function useTransferPackageRequirements(params = {}) {
  return useQuery({
    queryKey: queryKeys.transferPackageRequirements(params),
    queryFn: ({ signal }) =>
      getTransferPackageRequirements(
        {
          accountId: unref(params.accountId),
          page: unref(params.page),
          search: unref(params.search),
        },
        signal
      ),
  });
}

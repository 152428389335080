<script setup>
import { computed, ref, nextTick, watch } from 'vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import uniqueId from 'lodash/uniqueId';
import {
  useMediaQuery,
  onKeyStroke,
  useTimeoutFn,
  onClickOutside,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';

const props = defineProps({
  title: {
    default: null,
    type: String,
  },
  noTabIndex: {
    default: false,
    type: Boolean,
  },
  offsetContainerRef: {
    default: null,
  },
  tooltipPosition: {
    default: 'below',
    type: String,
    validator: function (value) {
      return ['below', 'above'].includes(value);
    },
  },
});

const matchMediaIsHover = useMediaQuery('(hover: hover)');
const id = uniqueId('tooltip-');
const toolTipModalOpen = ref(false);
const offsetStyle = ref({});
const offsetStyleCaret = ref({});
const isAbove = ref(false);

const tooltipPosition = computed(() => props.tooltipPosition);

const dialogTarget = ref();
const {
  hasFocus: focusTrapActive,
  activate: focusTrapActivate,
  deactivate: focusTrapDeactivate,
} = useFocusTrap(dialogTarget);

watch(matchMediaIsHover, isWide => {
  if (!isWide && toolTipModalOpen.value) {
    focusTrapActivate();
    return;
  }

  if (isWide && focusTrapActive.value) {
    focusTrapDeactivate();
  }
});

const {
  start: startAnimationTimer,
  stop: stopAnimationTimer,
  isPending: isPendingAnimationTimer,
} = useTimeoutFn(
  () => {
    toolTipModalOpen.value = false;
  },
  200,
  { immediate: false }
);

const handleToolTipButtonHover = e => {
  if (
    matchMediaIsHover.value &&
    (!toolTipModalOpen.value || isPendingAnimationTimer.value)
  ) {
    stopAnimationTimer();

    // Find if needs to be offset from left edge of viewport
    const containingRect =
      props.offsetContainerRef !== null
        ? props.offsetContainerRef.getBoundingClientRect()
        : document.body.getBoundingClientRect();
    const elemRect = e.currentTarget.getBoundingClientRect();
    const offsetLeft = elemRect.left - containingRect.left;
    const offsetBottom = containingRect.bottom - elemRect.bottom;
    if (offsetBottom < 30) isAbove.value = true;

    if (offsetLeft < 200) {
      offsetStyle.value = { right: -(198 - offsetLeft) + 'px' };
      offsetStyleCaret.value = {
        transform: 'translateX(-' + (196 - offsetLeft) + 'px)',
      };
    } else {
      offsetStyle.value = {};
      offsetStyleCaret.value = {};
    }

    if (tooltipPosition.value === 'above') isAbove.value = true;

    toolTipModalOpen.value = true;
  }
};

const handleToolTipButtonLeave = () => {
  if (matchMediaIsHover.value) startAnimationTimer();
};

const handleToolTipClose = () => {
  if (!toolTipModalOpen.value || isPendingAnimationTimer.value) return;

  if (matchMediaIsHover.value) {
    handleToolTipButtonLeave();
    return;
  }

  focusTrapDeactivate();
  startAnimationTimer();
};

const handleToolTipClick = async () => {
  if (
    !matchMediaIsHover.value &&
    (!toolTipModalOpen.value || isPendingAnimationTimer.value)
  ) {
    stopAnimationTimer();
    offsetStyle.value = {};
    offsetStyleCaret.value = {};
    toolTipModalOpen.value = true;

    await nextTick();
    focusTrapActivate();
  }
};

onKeyStroke('Escape', () => {
  handleToolTipClose();
});

onClickOutside(dialogTarget, () => {
  if (!matchMediaIsHover.value) handleToolTipClose();
});
</script>

<template>
  <div class="tooltip">
    <button
      type="button"
      class="u-button-reset tooltip__button"
      aria-label="Help?"
      :aria-expanded="toolTipModalOpen"
      :aria-describedby="id"
      :tabindex="noTabIndex ? '-1' : null"
      @focus="handleToolTipButtonHover"
      @blur="handleToolTipButtonLeave"
      @mouseover="handleToolTipButtonHover"
      @mouseout="handleToolTipButtonLeave"
      @click="handleToolTipClick"
    >
      <SoonaIcon name="circle-question" />
    </button>
    <Teleport to="body" :disabled="matchMediaIsHover">
      <div
        v-if="toolTipModalOpen"
        :id="id"
        ref="dialogTarget"
        :role="matchMediaIsHover ? 'tooltip' : 'dialog'"
        :aria-modal="matchMediaIsHover ? undefined : true"
        :aria-labelledby="matchMediaIsHover ? undefined : `${id}-title`"
        class="tooltip__content"
        :class="{
          'tooltip__content--open': toolTipModalOpen,
          'tooltip__content--closing': isPendingAnimationTimer,
          'tooltip__content--above': isAbove,
        }"
        :style="offsetStyle"
      >
        <header v-if="title" class="tooltip__content__header">
          <h3 :id="`${id}-title`" class="tooltip__content__title">
            {{ title }}
          </h3>
          <button
            type="button"
            class="u-button-reset tooltip__content__close"
            aria-label="Close Modal"
            @click="handleToolTipClose"
          >
            <SoonaIcon name="xmark" />
          </button>
        </header>
        <div class="tooltip__content__copy">
          <p>
            <slot />
          </p>
          <SoonaButton
            type="button"
            class="tooltip__content__copy__confirm"
            aria-label="Close Modal"
            :on-click="handleToolTipClose"
          >
            got it!
          </SoonaButton>
        </div>
        <div class="tooltip__caret" :style="offsetStyleCaret" />
      </div>
      <div
        v-if="toolTipModalOpen"
        class="tooltip__modal-bg"
        :class="{
          'tooltip__modal-bg--open': toolTipModalOpen,
          'tooltip__modal-bg--closing': isPendingAnimationTimer,
        }"
        @touchend="handleToolTipClose"
      />
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.tooltip {
  width: 1.25rem;
  height: 1.25rem;
  align-self: center;
}

.tooltip__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  color: variables.$gray-60;

  svg {
    display: block;
    pointer-events: none;
    width: 1.25rem;
    height: 1.25rem;
  }
}

.tooltip__content {
  position: fixed;
  z-index: 2;
  display: none;
  background-color: variables.$white-default;
  overflow: auto;
  max-height: 31.25rem;
  box-shadow: 0 0.1875rem 0.5rem rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem 0.625rem 0 0;
  width: 100%;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  max-width: 32.5rem;
}

.tooltip__content__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  background-color: variables.$periwink-blue-10;
}

.tooltip__content__title {
  @include variables_fonts.u-subheader--heavy;

  color: variables.$black-default;
  margin: 0;
}

.tooltip__content__close {
  flex: 0 0 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  color: variables.$gray-60;

  svg {
    display: block;
  }
}

.tooltip__content__copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  > p {
    width: 100%;
  }
}

.tooltip__content__copy__confirm {
  margin-top: 1rem;
}

.tooltip__modal-bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(63, 67, 75, 0.7);
}

@keyframes fade-slide-up {
  0% {
    opacity: 0;
    transform: translate(-50%, 100%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes fade-slide-down {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
}

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-up {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
}

@keyframes fade-up-above {
  0% {
    opacity: 0;
    transform: translateY(0.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-down-above {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(0.5rem);
  }
}

@media (hover: none) {
  .tooltip__content {
    animation: 0.2s ease-out both fade-slide-up;

    &--closing {
      animation: 0.2s ease-out both fade-slide-down;
    }

    &--open {
      display: block;
    }
  }

  .tooltip__modal-bg {
    animation: 0.1s ease-out both k-fade-in;

    &--closing {
      animation: 0.1s ease-out 0.1s both k-fade-out;
    }

    &--open {
      display: block;
    }
  }
}

@media (hover: hover) {
  .tooltip {
    position: relative;
  }

  .tooltip__button {
    cursor: help !important;
  }

  .tooltip__content__header {
    display: none;
  }

  .tooltip__content {
    pointer-events: none;
    position: absolute;
    bottom: auto;
    left: auto;
    right: -0.125rem;
    top: 100%;
    margin-top: 0.5rem;
    padding: 0.3125rem 0.5rem 0.375rem;
    color: variables.$white-default;
    filter: drop-shadow(0px 5px 16px rgba(0, 0, 0, 0.15));
    border-radius: 0.3125rem;
    background-color: variables.$black-default;
    overflow: visible;
    max-height: none;
    width: max-content;
    max-width: 12.5rem;
    animation: 0.15s ease-out both fade-down;

    &--closing {
      animation: 0.15s ease-out both fade-up;
    }

    &--open {
      display: block;
    }

    &--above {
      top: auto;
      bottom: 100%;
      animation: 0.15s ease-out both fade-up-above;
      margin-top: 0;
      margin-bottom: 0.5rem;

      &.tooltip__content--closing {
        animation: 0.15s ease-out both fade-down-above;
      }
    }
  }

  .tooltip__content__copy {
    @include variables_fonts.u-label--regular;

    padding: 0;

    &__confirm {
      display: none;
    }
  }

  .tooltip__caret {
    pointer-events: none;
    position: absolute;
    right: 0.5rem;
    bottom: 100%;
    width: 0;
    height: 0;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-bottom: 6px solid variables.$black-default;

    .tooltip__content--above & {
      bottom: auto;
      top: 100%;
      border-bottom: 0;
      border-top: 6px solid variables.$black-default;
    }
  }
}
</style>

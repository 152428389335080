import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {string | Ref<string>} [accountId]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useFavoritesCollection(accountId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.collectionDA(accountId, 'favorites_collection'),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/accounts/${toValue(accountId)}/favorites_collection`,
        {
          signal,
        }
      );
      return response.data;
    },
    ...queryOptions,
  });
}

<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';

// Props
const props = defineProps({
  lineItems: {
    type: Array,
    required: true,
  },
});
const lineItems = computed(() => props.lineItems);

// Methods
const getLineItemPreviewUrl = lineItem => {
  return lineItem.digital_asset?.preview?.url || lineItem.product.thumbnail_url;
};
</script>

<template>
  <!-- Invoice Line Items Table -->
  <table class="line-items-table">
    <thead>
      <tr>
        <th>Description</th>
        <th>Qty</th>
        <th>Unit Price</th>
        <th>Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="lineItem in lineItems"
        :key="lineItem.id"
        class="line-items-table__item"
      >
        <td>
          <div class="line-items-table__item-description">
            <img
              class="line-items-table__item-img"
              :src="getLineItemPreviewUrl(lineItem)"
              alt=""
            />
            <p class="u-label--heavy">{{ lineItem.product.name }}</p>
          </div>
        </td>
        <td>{{ lineItem.quantity }}</td>
        <td>{{ toCurrency(lineItem.product.rate) }}</td>
        <td>{{ toCurrency(lineItem.total) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.line-items-table {
  width: stretch;
  thead {
    border-bottom: 0.0625rem solid variables.$gray-30;
    margin-bottom: 0.5rem;
  }
  th,
  td {
    @include variables_fonts.u-label--small;
    min-width: 4rem;

    text-align: center;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
  th {
    font-weight: 800;
    vertical-align: bottom;
    padding: 0.5rem 0;
  }
  td {
    vertical-align: middle;
    padding: 0.75rem 0;
  }

  &__item {
    margin: 0.5rem 0;
  }

  &__item-description {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 0;
  }

  &__item-img {
    height: 5rem;
    width: 5rem;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    object-fit: contain;
  }

  &__item-break {
    margin: 0.5rem 0 0.75rem;
  }
}

// Desktop Styles
@mixin line-items-table-desktop-styles {
  .line-items-table {
    // Invoice Line Items Table
    th {
      @include variables_fonts.u-label--heavy;
      padding: 0.75rem 0;
    }
    td {
      @include variables_fonts.u-label--regular;
    }

    &__item-img {
      height: 6.25rem;
      width: 6.25rem;
      margin-bottom: 0;
    }

    // Billing Costs
    &__cost {
      p {
        @include variables_fonts.u-label--regular;
      }
    }
  }
}

@container order-view (min-width: 37.5rem) {
  @include line-items-table-desktop-styles;
}

/* compat: remove when dropping Safari 15 */
@supports not (container-type: inline-size) {
  @media (min-width: 39.5rem) {
    @include line-items-table-desktop-styles;
  }
}

// Print Styles
@media print {
  .line-items-table {
    thead {
      margin-bottom: 0;
    }
    th,
    td {
      width: 15%;
      text-align: right !important;

      &:first-child {
        width: 55%;
        text-align: left !important;
      }
    }
    th {
      color: black !important;
    }

    &__item-description {
      width: 55%;
      p {
        font-weight: 400 !important;
      }
    }

    &__item-img {
      display: none;
    }
  }
}
</style>

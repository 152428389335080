<script setup>
import { toRef } from 'vue';
import { useCapability } from '../composables/useCapability';

const props = defineProps({
  capabilityName: {
    type: String,
    required: true,
  },
  subjectType: {
    type: String,
    required: false,
  },
  subjectId: {
    type: [String, Number],
    required: false,
  },
  showDefaultPlaceholder: {
    type: Boolean,
    default: false,
  },
});

const { hasCapability, status } = useCapability({
  capability: toRef(props, 'capabilityName'),
  subjectType: toRef(props, 'subjectType'),
  subjectId: toRef(props, 'subjectId'),
});
</script>

<template>
  <span v-if="status === 'pending'">
    <slot name="placeholder">
      <beat-loader
        :loading="showDefaultPlaceholder"
        color="#ffffff"
        size="0.625rem"
      />
    </slot>
  </span>
  <span v-else>
    <slot v-if="hasCapability"></slot>
    <slot v-else name="notCapable"></slot>
  </span>
</template>

<script setup>
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaIcon from '../ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  linkHref: {
    type: String,
    required: true,
  },
  linkLabel: {
    type: String,
    required: true,
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const handleLinkClick = () => {
  linkClicked({
    context: route.meta?.context,
    subContext: 'trending packs modal',
    linkLabel: props.linkLabel,
    linkHref: props.linkHref,
  });
};
</script>

<template>
  <div class="crew-recommendation">
    <div class="crew-recommendation__content">
      <figure class="crew-recommendation__crew">
        <img
          class="crew-recommendation__crew--image"
          src="/images/liz.png"
          alt="liz avatar"
        />
        <figcaption class="u-badge--small crew-recommendation__crew--title">
          CEO
        </figcaption>
      </figure>
      <div class="crew-recommendation__body">
        <div class="crew-recommendation__body--heading">
          <SoonaIcon name="lightbulb-alt" size="small" />
          <h4 class="u-label--heavy">Liz Recommends</h4>
        </div>
        <p class="u-label--regular">
          “Book this shoot to make the most out of your Black Friday and Cyber
          Monday ads.”
        </p>
      </div>
    </div>

    <SoonaButton
      element="router-link"
      :to="linkHref"
      class="crew-recommendation__action-btn"
      @on-click="handleLinkClick"
    >
      {{ linkLabel }}
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.crew-recommendation {
  background-color: variables.$bubbletape-pink-05;
  margin-bottom: 1rem;
  padding: 1rem;

  &__content {
    align-items: center;
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  &__crew {
    flex: 0 0 auto;
    position: relative;
    z-index: 1;

    &--image {
      border-radius: 0.3125rem;
      box-shadow: variables.$elevation-1;
      height: 3.75rem;
      width: 3.75rem;
    }

    &--title {
      align-items: flex-end;
      background-color: variables.$periwink-blue-40;
      border-radius: 0.3125rem;
      box-shadow: variables.$elevation-1;
      color: variables.$white-default;
      display: flex;
      height: 2rem;
      justify-content: center;
      position: absolute;
      top: 2.625rem;
      width: 100%;
      z-index: -1;
    }
  }

  &__body {
    padding-top: 0.5rem;

    &--heading {
      display: flex;
      margin-bottom: 0.25rem;

      > svg {
        color: variables.$periwink-blue-60;
      }
    }
  }
}

:deep(.crew-recommendation__action-btn) {
  width: 100%;
}
</style>

<script setup>
import { computed, ref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import TextArea from '@/components/shared/TextArea.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import WarningIcon from '@/components/svgs/WarningIcon.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useReEditsCollectionDigitalAsset } from '@/queries/re_edits_collection_digital_assets/useReEditsCollectionDigitalAsset';
import { useMe } from '@/composables/user/useMe';
import { useReEditsCollection } from '@/queries/re_edits_collections/useReEditsCollection';
import { useCapability } from '@/composables/useCapability';
import { useDeleteReEditCollectionDigitalAsset } from '@/queries/re_edits_collection_digital_assets/useDeleteReEditsCollectionDigitalAsset';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUpdateNote } from '@/queries/notes/useUpdateNote';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { storeToRefs } from 'pinia';

const props = defineProps({
  isMobileView: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const router = useRouter();

const { isMobileView } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();

const { assetId, assetAccountId } = storeToRefs(mediaEditorStore);

const { reservationId, reEditsCDAId, reEditsCollectionId } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const { currentAccountId } = useMe();
const { addToast } = useSoonaToast();
const { hasCapability: canManageCustomerCollections } = useCapability({
  capability: 'manage_customer_collections',
});

const { data: reEditsCollection } = useReEditsCollection(
  assetAccountId,
  reEditsCollectionId,
  {
    enabled: computed(
      () => !!assetAccountId.value && !!reEditsCollectionId.value
    ),
  }
);

const reEditRequestHasBeenSubmitted = computed(() => {
  return reEditsCollection.value?.options?.status === 'submitted';
});

const { data: reEditsCDA, error: reEditsError } =
  useReEditsCollectionDigitalAsset(reEditsCollectionId, reEditsCDAId);

const isEditing = ref(false);
const loading = ref(false);

const note = ref('');
const noteEdit = ref('');

const noteUnchanged = computed(() => note.value === noteEdit.value);

const noteId = computed(() => reEditsCDA.value?.note?.id);

// save and edit
const orderSubmitted = computed(
  () => reEditsCollection.value?.status === 'submitted'
);

const canCancel = computed(() => {
  return (
    (!orderSubmitted.value &&
      currentAccountId.value === assetAccountId.value) ||
    canManageCustomerCollections.value
  );
});

const canEdit = computed(
  () => !orderSubmitted.value && currentAccountId.value === assetAccountId.value
);

const { mutate: updateNote, isPending: isNoteUpdating } = useUpdateNote(
  'digital_assets',
  assetId
);

const { mutate: cancelCDA, isPending: cancelCDAPending } =
  useDeleteReEditCollectionDigitalAsset(assetAccountId, reEditsCollectionId);

// save
const saveNote = () => {
  if (!noteId.value) {
    return;
  }
  updateNote(
    { noteId: noteId.value, content: noteEdit.value },
    {
      onSuccess: () => {
        note.value = noteEdit.value;
        isEditing.value = false;
      },
      onError: () => {
        addToast('error saving re-edit note', { variation: 'error' });
      },
    }
  );
};

// cancel
const dialogOpen = ref(false);

const cancelEdit = () => {
  noteEdit.value = note.value;
  isEditing.value = false;
};

const cancelRequest = () => {
  dialogOpen.value = false;
  if (!reEditsCDAId.value) {
    return;
  }
  cancelCDA(
    { cdaId: reEditsCDAId },
    {
      onSuccess: () => {
        mediaEditorStore.forceSetActiveAction(null);
        addToast('re-edit request canceled', { variation: 'success' });

        if (route.name === 'reservation-asset-re-edit-view') {
          router.push({
            name: 'gallery',
            params: { reservationId: reservationId.value },
          });
        }
      },
      onError: () => {
        addToast('error canceling re-edit request', { variation: 'error' });
      },
    }
  );
};

const priorityError = usePriorityError(reEditsError);
const isLoading = computed(
  () => loading.value || cancelCDAPending.value || isNoteUpdating.value
);
const loadingCopy = computed(() => {
  if (isNoteUpdating.value) {
    return 'saving';
  } else if (cancelCDAPending.value) {
    return 'canceling';
  } else {
    return 'loading';
  }
});

watch(
  () => reEditsCDA.value?.note?.content,
  value => {
    note.value = value;
    noteEdit.value = value;
  },
  { immediate: true }
);
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <SoonaLoading
    v-if="isLoading"
    :loading-text="loadingCopy"
    is-contained
    is-loading
  />
  <template v-else-if="!isEditing">
    <div v-if="!isEditing" class="requested-re-edit">
      <div class="requested-re-edit__note">
        {{ note }}
        <SoonaButton
          v-if="canEdit && !reEditRequestHasBeenSubmitted"
          variation="secondary-gray"
          class="requested-re-edit__edit"
          @on-click="() => (isEditing = true)"
        >
          <SoonaIcon name="pen-square" />
          edit
        </SoonaButton>
      </div>
      <SoonaButton
        v-if="canCancel && !reEditRequestHasBeenSubmitted"
        class="requested-re-edit__cancel"
        variation="secondary-gray"
        @click="() => (dialogOpen = true)"
      >
        <SoonaIcon name="xmark" />
        cancel re-edit request
      </SoonaButton>
      <SoonaAlert v-if="reEditRequestHasBeenSubmitted">
        there is a re-edit request in progress. when complete you can submit
        additional re-edit requests
      </SoonaAlert>
    </div>
  </template>

  <template v-if="isEditing">
    <TextArea
      v-if="isEditing"
      v-model:model-value="noteEdit"
      placeholder="type notes for the editor…"
      :rows="isMobileView ? 4 : 7"
      :disabled="loading"
    />
    <div class="requested-re-edit__save-cancel">
      <SoonaButton type="button" variation="tertiary" @on-click="cancelEdit">
        cancel
      </SoonaButton>
      <SoonaButton :disabled="noteUnchanged" @click="saveNote">
        save
      </SoonaButton>
    </div>
  </template>
  <SoonaDialog v-if="dialogOpen" @close="() => (dialogOpen = false)">
    <template #heading>
      <div class="warning__header">
        <WarningIcon class="warning__icon" />
        cancel re-edit?
      </div>
    </template>
    are you sure you want to cancel this re-edit request?
    <template #footer>
      <SoonaButton
        type="button"
        variation="tertiary"
        @click="() => (dialogOpen = false)"
      >
        back
      </SoonaButton>
      <SoonaButton
        type="button"
        variation="primary"
        @click="() => cancelRequest()"
      >
        confirm
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style scoped lang="scss">
.requested-re-edit {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__note {
    display: flex;
    padding-top: 0.75rem;
    flex-direction: column;
    gap: 2.3125rem;
    align-self: stretch;
    white-space: pre-wrap;
  }

  &__edit {
    align-self: flex-end;
  }

  &__cancel {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
  }

  &__save-cancel {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
  }
}

.warning {
  &__header {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &__icon {
    padding-top: 0.25rem;
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import { useCreateFeedback } from '@/queries/feedback/useCreateFeedback.js';
import { useGetFeedbackOptions } from '@/queries/feedback/useGetFeedbackOptions.js';
import { usePriorityError } from 'src/composables/usePriorityError';

const props = defineProps({
  category: {
    type: String,
    requried: true,
  },
  open: {
    type: Boolean,
    required: true,
  },
  optionsType: {
    type: String,
    required: true,
  },
  subjectType: {
    type: String,
    required: true,
  },
  subjectId: {
    type: [Number, String],
    required: true,
  },
});

const emit = defineEmits(['close', 'confirm']);

defineOptions({ inheritAttrs: false });

const { data: feedbackOptions, error: feedbackOptionsError } =
  useGetFeedbackOptions(computed(() => props.optionsType));

const selectedOptions = ref([]);
const notes = ref('');
const category = computed(() => props.category);
const subjectType = computed(() => props.subjectType);
const subjectId = computed(() => props.subjectId);

const updateSelected = (feedbackOption, selected) => {
  if (selected) {
    selectedOptions.value?.push(feedbackOption);
  } else {
    selectedOptions.value?.pop(feedbackOption);
  }
};

const {
  mutate,
  isPending: isMutating,
  error: createError,
} = useCreateFeedback();

const priorityError = usePriorityError(createError, feedbackOptionsError);

const close = () => {
  if (isMutating.value) return;

  notes.value = '';

  emit('close');
};

const createFeedback = () => {
  const dataToSubmit = {
    subject_type: subjectType.value,
    subject_id: subjectId.value,
    notes: notes.value,
    options: selectedOptions.value,
    category: category.value,
  };

  mutate(dataToSubmit, {
    onSuccess: () => {
      notes.value = '';
      selectedOptions.value = [];
      emit('confirm');
    },
  });
};
</script>

<template>
  <SoonaDialog v-if="open" @close="close">
    <template #heading>
      <span class="feedback-dialog__heading"
        >reject (do not deliver to customer)</span
      >
    </template>
    <SoonaError v-if="priorityError" class="feedback-dialog__error">
      {{ priorityError }}
    </SoonaError>

    <div class="feedback-dialog__options">
      <label class="label" for="disable-reason">
        <span class="label__heading"
          >what is the reason for rejecting these edits?</span
        >
        <br />
        <span class="label__subheading">choose all that apply</span>
      </label>
      <SoonaToggle
        v-for="feedbackOption in feedbackOptions"
        :key="feedbackOption"
        type="checkbox"
        name="disable-reason"
        :label="feedbackOption"
        :model-value="feedbackOption"
        @update:model-value="newVal => updateSelected(feedbackOption, newVal)"
      />
    </div>
    <div class="feedback-dialog__notes">
      <SoonaTextfield v-model="notes" element="textarea" label="notes" />
    </div>

    <template #footer>
      <SoonaButton type="button" variation="tertiary" @click="close">
        cancel
      </SoonaButton>
      <SoonaButton type="button" variation="primary" @click="createFeedback">
        continue
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';

.feedback-dialog {
  &__heading {
    text-transform: lowercase;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;

    .label {
      &__heading {
        @include variables_fonts.u-label--heavy;
      }

      &__subheading {
        @include variables_fonts.u-label--regular;
      }
    }
  }

  &__notes {
    margin-top: 1rem;
  }
}
</style>

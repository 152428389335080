<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';
import { component as Viewer } from 'v-viewer';
import {
  browserTimeZone,
  convertToMMDDYYYYlong,
  convertToHHMM12,
} from '@/lib/date-formatters';
import { useCapability } from 'src/composables/useCapability';
import { useMe } from '@/composables/user/useMe';
import {
  BubbletapePink10,
  FriendlyRed30,
  GreenApple20,
  PeriwinkBlue30,
  Roses30,
  Tangerine20,
} from 'src/variables.module.scss';
import Autolinker from 'autolinker';

const props = defineProps({
  note: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['delete-note']);

const { currentUserId } = useMe();

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});
const { hasCapability: canManageCrewNotes } = useCapability({
  capability: 'manage_crew_notes',
});
const { hasCapability: canViewCrewNotes } = useCapability({
  capability: 'crew_notes',
});

const noteType = computed(() => props.note?.note_type);

const flagTitle = computed(() => noteType.value.replace(/_/g, ''));

const backgroundColor = computed(() => {
  switch (noteType.value) {
    case 'post_production':
      return GreenApple20;
    case 'pre_production':
      return PeriwinkBlue30;
    case 'inventory':
      return Tangerine20;
    case 'scheduling':
      return BubbletapePink10;
    case 'sales':
      return Roses30;
    default:
      return FriendlyRed30;
  }
});

const note = computed(() => props.note);

const formattedNoteContent = computed(() => {
  return Autolinker.link(note.value.content, {
    target: '_blank',
    rel: 'noopener noreferrer',
  });
});

const imageAssets = computed(() => {
  let assets = [];

  if (note.value.assets) {
    assets = note.value.assets.filter(asset => asset.is_image);
  }
  if (note.value.asset && note.value.asset_is_image) {
    assets.push({
      id: note.value.asset_id,
      filename: note.value.asset_filename,
      is_image: note.value.asset_is_image,
      url: note.value.asset,
    });
  }

  return assets;
});

const nonImageAssets = computed(() => {
  let assets = [];

  if (note.value.assets) {
    assets = note.value.assets.filter(asset => !asset.is_image);
  }
  if (note.value.asset && !note.value.asset_is_image) {
    assets.push({
      id: note.value.asset_id,
      filename: note.value.asset_filename,
      is_image: note.value.asset_is_image,
      url: note.value.asset,
    });
  }

  return assets;
});

const showDeletedBtn = computed(() => {
  return (
    canManageCrewNotes.value ||
    (props.note.creator.id === currentUserId.value &&
      (isFtSoonaStaff.value || canViewCrewNotes.value))
  );
});
</script>
<template>
  <div class="crew-notes">
    <div class="crew-notes__header">
      <SoonaFlag
        size="small"
        :title="flagTitle"
        :background-color="backgroundColor"
        type="pill"
      />
      <span class="crew_notes__delete-btn">
        <SoonaButton
          v-if="showDeletedBtn"
          variation="tertiary"
          size="medium"
          @on-click="emit('delete-note', note)"
        >
          delete
        </SoonaButton>
      </span>
    </div>
    <div class="crew-notes__note">
      <div class="crew-notes__author">
        <SoonaAvatar
          :name="note.creator.name"
          :src="note.creator.avatar"
          size="3.125rem"
          type="user"
        />
        <p class="u-label--heavy">{{ note.creator.name }}</p>
      </div>
      <div class="crew-notes__details">
        <p class="u-label--heavy">
          {{ convertToMMDDYYYYlong(note.created_at) }} at
          {{ convertToHHMM12(note.created_at) }} {{ browserTimeZone() }}
        </p>
        <p
          class="crew-notes__text u-body--regular"
          v-html="formattedNoteContent"
        />
        <viewer
          v-if="imageAssets?.length"
          class="crew-notes__assets"
          :options="{
            toolbar: true,
            navbar: false,
            title: false,
            backdrop: true,
            minZoomRatio: 0.01,
            maxZoomRatio: 2,
            keyboard: true,
            scalable: false,
          }"
        >
          <img
            v-for="asset in imageAssets"
            :key="asset.id"
            class="crew-notes__assets--asset"
            :src="asset.url"
            alt="note asset"
          />
        </viewer>
        <ul v-if="nonImageAssets?.length" class="crew-notes__assets">
          <li v-for="asset in nonImageAssets" :key="asset.id">
            <a
              class="u-body--heavy crew-notes__assets--filename"
              :href="asset.url"
              target="_blank"
            >
              <SoonaIcon name="file" />
              {{ asset.filename }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use '@/variables';

.crew-notes {
  border-top: 0.25rem solid variables.$gray-30;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__author {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 1rem;
  }

  &__note {
    display: flex;
    gap: 3rem;
  }

  &__details {
    flex: 1 1 auto;
    white-space: pre-wrap;
  }

  &__text {
    a {
      text-decoration: underline;
      color: variables.$periwink-blue-70;
      overflow-wrap: anywhere;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__assets {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;

    &--asset {
      border-radius: 0.3125rem;
      flex: 0 0 auto;
      height: 6.25rem;
      object-fit: cover;
      width: 6.25rem;

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }

    &--filename {
      align-items: center;
      background-color: variables.$white-default;
      border: 0.0625rem solid variables.$gray-30;
      border-radius: variables.$radius-small;
      display: flex;
      gap: 0.5rem;
      overflow: hidden;
      padding: 0.75rem;
      width: 100%;
      word-wrap: break-word;

      svg {
        color: variables.$gray-60;
        flex-shrink: 0;
      }
    }
  }

  &__delete-btn {
    padding: 0.5rem;
  }
}
</style>

import { reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';

const state = reactive({
  backRouteFullPath: null,
});

export default () => {
  const { backRouteFullPath } = toRefs(state);

  const router = useRouter();

  const setBackRoute = newRoute => {
    state.backRouteFullPath = newRoute.fullPath;
  };

  const handleClose = async () => {
    if (
      backRouteFullPath.value &&
      // fixme: update logic to not use this API
      // eslint-disable-next-line no-restricted-properties
      router.options.history.state.back === backRouteFullPath.value
    ) {
      router.back();
    } else if (backRouteFullPath.value) {
      router.push(backRouteFullPath.value);
    } else {
      router.push({ name: 'competitive-analysis' });
    }
  };

  return {
    backRouteFullPath,
    setBackRoute,
    handleClose,
  };
};

<script setup>
import { useStore } from 'vuex';
import { useCreateReservation } from '@/queries/useCreateReservation';
import { computed, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useBookingEvents } from '@/composables/segment/useBookingEvents';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  discount: String,
  flow: String,
  reservationType: String,
  packConfigurationId: Number,
  page: String,
  mediaType: String,
});

const router = useRouter();
const route = useRoute();

const store = useStore();

const { error, isPending, status, mutate } = useCreateReservation();
const { bookingStarted } = useBookingEvents();
const redirectToProcess = ref(false);

const defaultV2Flag = useFlag('default_v2_booking_flow');
const defaultV3Flag = useFlag('default_v3_booking_flow');

const isAuthenticated = computed(() => !!store.state.currentUser.userID);

const createReservationOnMount = () => {
  if (
    !isAuthenticated.value &&
    route.query?.flow !== 'headshots_for_all' &&
    route.query?.flow !== 'headshots_for_all_event'
  ) {
    let redirect = `/booking/new`;
    if (route.query?.flow?.startsWith('pack')) {
      let optionalParams = route.fullPath.match(/\?(.*)/)[0];
      redirect = `/book/pack/${route.query.pack}${optionalParams}`;
    }

    return router.push({
      name: 'signUpPartTwo',
      query: { ...route.query, redirect },
    });
  }

  const flowToUse = computed(() => {
    if (props.flow) {
      return props.flow;
    } else if (defaultV3Flag.value) {
      return 'default_v3';
    } else if (defaultV2Flag.value) {
      return 'default_v2';
    } else {
      return 'default_v1';
    }
  });

  let reservationData = {
    account_id: store.state?.currentUser?.accountId,
    reservation_line_items: [],
    reservation_tags: [],
    use_primary_location: true,
    location_id: flowToUse?.value === 'headshots_for_all_event' ? 6 : null,
    reservation_type: props.reservationType || 'anytime',
    coupon_code: props.discount,
    booking_flow: flowToUse?.value,
  };

  if (props.packConfigurationId) {
    reservationData.pack_configuration_id = props.packConfigurationId;
    reservationData.pack_quantity = 1;
  }

  const redirectToNextStep = (reservation, nextStep) => {
    redirectToProcess.value = true;

    router.push({
      path: `/booking/${reservation.id}/${nextStep}`,
      query: route.query,
      replace: true,
    });

    bookingStarted({
      context: 'booking',
      subContext: nextStep.replace(/-/g, ' '),
      path: route.path,
      reservation: reservation,
    });
  };

  mutate(
    {
      reservation: reservationData,
      media_type_title: props.mediaType,
    },
    {
      onSuccess: reservation => {
        let nextStep;
        if (props.page) {
          nextStep = props.page;
        } else {
          nextStep = reservation.steps.find(s => !s.complete)?.slug;
        }

        redirectToNextStep(reservation, nextStep);
      },
      onError: error => {
        if (error.response.data?.code === 'Reservation::InvalidH4aError') {
          router.push({
            path: '/booking/free-headshot-received',
            replace: true,
          });
        }
      },
    }
  );
};

onMounted(async () => {
  createReservationOnMount();
});
</script>

<template>
  <div>
    <SoonaError v-if="error && error.message">
      {{ error.message }}
    </SoonaError>
    <SoonaLoading
      v-else
      :is-loading="isPending"
      :is-dark="false"
      :loading-text="status"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { getHowItWorksVideoBySection } from '@/lib/vimeo-videos';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaVimeoPlayer from '@/components/SoonaVimeoPlayer.vue';

const props = defineProps({
  context: {
    required: true,
    type: String,
  },
  subContext: {
    required: true,
    type: String,
  },
});

const { linkClicked } = useBaseEvents();

const showVideoDialog = ref(false);

const handleVideoDialog = () => {
  showVideoDialog.value = true;

  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'watch platform overview',
    linkHref: null,
  });
};

const videoDetails = computed(() => {
  return getHowItWorksVideoBySection('subscriptionsDialog');
});
</script>

<template>
  <div class="info-video">
    <button
      class="u-button-reset u-label--heavy info-video__btn"
      @click="handleVideoDialog"
    >
      <span>watch platform overview</span>
      <span class="info-video__icon">
        <SoonaIcon name="play-solid" size="x-small" />
      </span>
    </button>
    <SoonaDialog
      v-if="showVideoDialog"
      max-width="48rem"
      @close="showVideoDialog = false"
    >
      <template #heading>how it works</template>
      <SoonaVimeoPlayer
        :vimeo-id="videoDetails?.videoId"
        :additional-params="videoDetails?.additionalParams"
        :show-close-x="false"
      />
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.info-video {
  margin: 0 auto;
  order: 4;
  padding-top: 0.25rem;

  @media (min-width: variables.$screen-md-min) {
    flex-grow: 1;
    order: 0;
    padding-top: 0;
  }

  &__btn {
    align-items: center;
    color: variables.$white-default;
    display: flex;
    gap: 0.5rem;
    margin: 0 auto;
    padding: 0.5rem;

    @media (min-width: variables.$screen-md-min) {
      margin: 0;
      margin-left: auto;
    }

    span {
      text-decoration: underline;
    }
  }

  &__icon {
    align-items: center;
    background: variables.$periwink-blue-70;
    border-radius: 50%;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    width: 1.25rem;
  }
}
</style>

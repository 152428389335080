<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useIntegrations } from '@/composables/useIntegrations';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const accountId = computed(() => props.accountId);

const { actionableIntegrations } = useIntegrations(accountId);

const integrations = computed(() => actionableIntegrations.value ?? []);

const integrationObjects = computed(() => {
  let integrationTypes = {};
  const integrationObjects = integrations.value.reduce((acc, integration) => {
    if (integration.type === 'AmazonIntegration') {
      if (!integrationTypes.amazon) {
        integrationTypes.amazon = 1;
        acc.push({
          integration,
          icon: 'amazon-logo',
          integrationType: 'amazon',
        });
      } else {
        integrationTypes.amazon += 1;
      }
    } else if (integration.type === 'ShopifyIntegration') {
      if (!integrationTypes.shopify) {
        integrationTypes.shopify = 1;
        acc.push({
          integration,
          icon: 'shopify-logo',
          integrationType: 'shopify',
        });
      } else {
        integrationTypes.shopify += 1;
      }
    }
    return acc;
  }, []);
  integrationObjects.forEach(integrationObject => {
    integrationObject.count =
      integrationTypes[integrationObject.integrationType];
  });
  return integrationObjects;
});
</script>
<template>
  <div
    v-if="integrationObjects?.length > 0"
    class="product-integrations-header"
  >
    <div
      v-for="integrationObject in integrationObjects"
      :key="integrationObject.integration.id"
      class="product-integrations-header__item"
    >
      <SoonaIcon :name="integrationObject.icon" size="medium" />
      <p v-if="integrationObject?.count > 1">
        {{ integrationObject.count }} stores
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.product-integrations-header {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  align-items: center;
  color: variables.$gray-60;

  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>

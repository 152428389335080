import beauty_and_skincare_1 from 'images/booking/industry/beauty_and_skincare/1.jpg';
import beauty_and_skincare_1_2x from 'images/booking/industry/beauty_and_skincare/1@2x.jpg';
import beauty_and_skincare_2 from 'images/booking/industry/beauty_and_skincare/2.jpg';
import beauty_and_skincare_2_2x from 'images/booking/industry/beauty_and_skincare/2@2x.jpg';
import beauty_and_skincare_3 from 'images/booking/industry/beauty_and_skincare/3.jpg';
import beauty_and_skincare_3_2x from 'images/booking/industry/beauty_and_skincare/3@2x.jpg';
import beauty_and_skincare_4 from 'images/booking/industry/beauty_and_skincare/4.jpg';
import beauty_and_skincare_4_2x from 'images/booking/industry/beauty_and_skincare/4@2x.jpg';
import beauty_and_skincare_5 from 'images/booking/industry/beauty_and_skincare/5.jpg';
import beauty_and_skincare_5_2x from 'images/booking/industry/beauty_and_skincare/5@2x.jpg';
import beauty_and_skincare_6 from 'images/booking/industry/beauty_and_skincare/6.jpg';
import beauty_and_skincare_6_2x from 'images/booking/industry/beauty_and_skincare/6@2x.jpg';
import health_and_wellness_1 from 'images/booking/industry/health_and_wellness/1.jpg';
import health_and_wellness_1_2x from 'images/booking/industry/health_and_wellness/1@2x.jpg';
import health_and_wellness_2 from 'images/booking/industry/health_and_wellness/2.jpg';
import health_and_wellness_2_2x from 'images/booking/industry/health_and_wellness/2@2x.jpg';
import health_and_wellness_3 from 'images/booking/industry/health_and_wellness/3.jpg';
import health_and_wellness_3_2x from 'images/booking/industry/health_and_wellness/3@2x.jpg';
import health_and_wellness_4 from 'images/booking/industry/health_and_wellness/4.jpg';
import health_and_wellness_4_2x from 'images/booking/industry/health_and_wellness/4@2x.jpg';
import health_and_wellness_5 from 'images/booking/industry/health_and_wellness/5.jpg';
import health_and_wellness_5_2x from 'images/booking/industry/health_and_wellness/5@2x.jpg';
import health_and_wellness_6 from 'images/booking/industry/health_and_wellness/6.jpg';
import health_and_wellness_6_2x from 'images/booking/industry/health_and_wellness/6@2x.jpg';
import food_and_beverage_1 from 'images/booking/industry/food_and_beverage/1.jpg';
import food_and_beverage_1_2x from 'images/booking/industry/food_and_beverage/1@2x.jpg';
import food_and_beverage_2 from 'images/booking/industry/food_and_beverage/2.jpg';
import food_and_beverage_2_2x from 'images/booking/industry/food_and_beverage/2@2x.jpg';
import food_and_beverage_3 from 'images/booking/industry/food_and_beverage/3.jpg';
import food_and_beverage_3_2x from 'images/booking/industry/food_and_beverage/3@2x.jpg';
import food_and_beverage_4 from 'images/booking/industry/food_and_beverage/4.jpg';
import food_and_beverage_4_2x from 'images/booking/industry/food_and_beverage/4@2x.jpg';
import food_and_beverage_5 from 'images/booking/industry/food_and_beverage/5.jpg';
import food_and_beverage_5_2x from 'images/booking/industry/food_and_beverage/5@2x.jpg';
import food_and_beverage_6 from 'images/booking/industry/food_and_beverage/6.jpg';
import food_and_beverage_6_2x from 'images/booking/industry/food_and_beverage/6@2x.jpg';
import clothing_and_shoes_1 from 'images/booking/industry/clothing_and_shoes/1.jpg';
import clothing_and_shoes_1_2x from 'images/booking/industry/clothing_and_shoes/1@2x.jpg';
import clothing_and_shoes_2 from 'images/booking/industry/clothing_and_shoes/2.jpg';
import clothing_and_shoes_2_2x from 'images/booking/industry/clothing_and_shoes/2@2x.jpg';
import clothing_and_shoes_3 from 'images/booking/industry/clothing_and_shoes/3.jpg';
import clothing_and_shoes_3_2x from 'images/booking/industry/clothing_and_shoes/3@2x.jpg';
import clothing_and_shoes_4 from 'images/booking/industry/clothing_and_shoes/4.jpg';
import clothing_and_shoes_4_2x from 'images/booking/industry/clothing_and_shoes/4@2x.jpg';
import clothing_and_shoes_5 from 'images/booking/industry/clothing_and_shoes/5.jpg';
import clothing_and_shoes_5_2x from 'images/booking/industry/clothing_and_shoes/5@2x.jpg';
import clothing_and_shoes_6 from 'images/booking/industry/clothing_and_shoes/6.jpg';
import clothing_and_shoes_6_2x from 'images/booking/industry/clothing_and_shoes/6@2x.jpg';
import fashion_accessories_1 from 'images/booking/industry/fashion_accessories/1.jpg';
import fashion_accessories_1_2x from 'images/booking/industry/fashion_accessories/1@2x.jpg';
import fashion_accessories_2 from 'images/booking/industry/fashion_accessories/2.jpg';
import fashion_accessories_2_2x from 'images/booking/industry/fashion_accessories/2@2x.jpg';
import fashion_accessories_3 from 'images/booking/industry/fashion_accessories/3.jpg';
import fashion_accessories_3_2x from 'images/booking/industry/fashion_accessories/3@2x.jpg';
import fashion_accessories_4 from 'images/booking/industry/fashion_accessories/4.jpg';
import fashion_accessories_4_2x from 'images/booking/industry/fashion_accessories/4@2x.jpg';
import fashion_accessories_5 from 'images/booking/industry/fashion_accessories/5.jpg';
import fashion_accessories_5_2x from 'images/booking/industry/fashion_accessories/5@2x.jpg';
import fashion_accessories_6 from 'images/booking/industry/fashion_accessories/6.jpg';
import fashion_accessories_6_2x from 'images/booking/industry/fashion_accessories/6@2x.jpg';
import jewelry_1 from 'images/booking/industry/jewelry/1.jpg';
import jewelry_1_2x from 'images/booking/industry/jewelry/1@2x.jpg';
import jewelry_2 from 'images/booking/industry/jewelry/2.jpg';
import jewelry_2_2x from 'images/booking/industry/jewelry/2@2x.jpg';
import jewelry_3 from 'images/booking/industry/jewelry/3.jpg';
import jewelry_3_2x from 'images/booking/industry/jewelry/3@2x.jpg';
import jewelry_4 from 'images/booking/industry/jewelry/4.jpg';
import jewelry_4_2x from 'images/booking/industry/jewelry/4@2x.jpg';
import jewelry_5 from 'images/booking/industry/jewelry/5.jpg';
import jewelry_5_2x from 'images/booking/industry/jewelry/5@2x.jpg';
import jewelry_6 from 'images/booking/industry/jewelry/6.jpg';
import jewelry_6_2x from 'images/booking/industry/jewelry/6@2x.jpg';
import pet_1 from 'images/booking/industry/pet/1.jpg';
import pet_1_2x from 'images/booking/industry/pet/1@2x.jpg';
import pet_2 from 'images/booking/industry/pet/2.jpg';
import pet_2_2x from 'images/booking/industry/pet/2@2x.jpg';
import pet_3 from 'images/booking/industry/pet/3.jpg';
import pet_3_2x from 'images/booking/industry/pet/3@2x.jpg';
import pet_4 from 'images/booking/industry/pet/4.jpg';
import pet_4_2x from 'images/booking/industry/pet/4@2x.jpg';
import pet_5 from 'images/booking/industry/pet/5.jpg';
import pet_5_2x from 'images/booking/industry/pet/5@2x.jpg';
import pet_6 from 'images/booking/industry/pet/6.jpg';
import pet_6_2x from 'images/booking/industry/pet/6@2x.jpg';
import home_and_garden_1 from 'images/booking/industry/home_and_garden/1.jpg';
import home_and_garden_1_2x from 'images/booking/industry/home_and_garden/1@2x.jpg';
import home_and_garden_2 from 'images/booking/industry/home_and_garden/2.jpg';
import home_and_garden_2_2x from 'images/booking/industry/home_and_garden/2@2x.jpg';
import home_and_garden_3 from 'images/booking/industry/home_and_garden/3.jpg';
import home_and_garden_3_2x from 'images/booking/industry/home_and_garden/3@2x.jpg';
import home_and_garden_4 from 'images/booking/industry/home_and_garden/4.jpg';
import home_and_garden_4_2x from 'images/booking/industry/home_and_garden/4@2x.jpg';
import home_and_garden_5 from 'images/booking/industry/home_and_garden/5.jpg';
import home_and_garden_5_2x from 'images/booking/industry/home_and_garden/5@2x.jpg';
import home_and_garden_6 from 'images/booking/industry/home_and_garden/6.jpg';
import home_and_garden_6_2x from 'images/booking/industry/home_and_garden/6@2x.jpg';
import tech_1 from 'images/booking/industry/tech/1.jpg';
import tech_1_2x from 'images/booking/industry/tech/1@2x.jpg';
import tech_2 from 'images/booking/industry/tech/2.jpg';
import tech_2_2x from 'images/booking/industry/tech/2@2x.jpg';
import tech_3 from 'images/booking/industry/tech/3.jpg';
import tech_3_2x from 'images/booking/industry/tech/3@2x.jpg';
import tech_4 from 'images/booking/industry/tech/4.jpg';
import tech_4_2x from 'images/booking/industry/tech/4@2x.jpg';
import tech_5 from 'images/booking/industry/tech/5.jpg';
import tech_5_2x from 'images/booking/industry/tech/5@2x.jpg';
import tech_6 from 'images/booking/industry/tech/6.jpg';
import tech_6_2x from 'images/booking/industry/tech/6@2x.jpg';
import toys_and_hobbies_1 from 'images/booking/industry/toys_and_hobbies/1.jpg';
import toys_and_hobbies_1_2x from 'images/booking/industry/toys_and_hobbies/1@2x.jpg';
import toys_and_hobbies_2 from 'images/booking/industry/toys_and_hobbies/2.jpg';
import toys_and_hobbies_2_2x from 'images/booking/industry/toys_and_hobbies/2@2x.jpg';
import toys_and_hobbies_3 from 'images/booking/industry/toys_and_hobbies/3.jpg';
import toys_and_hobbies_3_2x from 'images/booking/industry/toys_and_hobbies/3@2x.jpg';
import toys_and_hobbies_4 from 'images/booking/industry/toys_and_hobbies/4.jpg';
import toys_and_hobbies_4_2x from 'images/booking/industry/toys_and_hobbies/4@2x.jpg';
import toys_and_hobbies_5 from 'images/booking/industry/toys_and_hobbies/5.jpg';
import toys_and_hobbies_5_2x from 'images/booking/industry/toys_and_hobbies/5@2x.jpg';
import toys_and_hobbies_6 from 'images/booking/industry/toys_and_hobbies/6.jpg';
import toys_and_hobbies_6_2x from 'images/booking/industry/toys_and_hobbies/6@2x.jpg';
import outdoor_and_recreation_1 from 'images/booking/industry/outdoor_and_recreation/1.jpg';
import outdoor_and_recreation_1_2x from 'images/booking/industry/outdoor_and_recreation/1@2x.jpg';
import outdoor_and_recreation_2 from 'images/booking/industry/outdoor_and_recreation/2.jpg';
import outdoor_and_recreation_2_2x from 'images/booking/industry/outdoor_and_recreation/2@2x.jpg';
import outdoor_and_recreation_3 from 'images/booking/industry/outdoor_and_recreation/3.jpg';
import outdoor_and_recreation_3_2x from 'images/booking/industry/outdoor_and_recreation/3@2x.jpg';
import outdoor_and_recreation_4 from 'images/booking/industry/outdoor_and_recreation/4.jpg';
import outdoor_and_recreation_4_2x from 'images/booking/industry/outdoor_and_recreation/4@2x.jpg';
import outdoor_and_recreation_5 from 'images/booking/industry/outdoor_and_recreation/5.jpg';
import outdoor_and_recreation_5_2x from 'images/booking/industry/outdoor_and_recreation/5@2x.jpg';
import outdoor_and_recreation_6 from 'images/booking/industry/outdoor_and_recreation/6.jpg';
import outdoor_and_recreation_6_2x from 'images/booking/industry/outdoor_and_recreation/6@2x.jpg';
import education_1 from 'images/booking/industry/education/1.jpg';
import education_1_2x from 'images/booking/industry/education/1@2x.jpg';
import education_2 from 'images/booking/industry/education/2.jpg';
import education_2_2x from 'images/booking/industry/education/2@2x.jpg';
import education_3 from 'images/booking/industry/education/3.jpg';
import education_3_2x from 'images/booking/industry/education/3@2x.jpg';
import education_4 from 'images/booking/industry/education/4.jpg';
import education_4_2x from 'images/booking/industry/education/4@2x.jpg';
import education_5 from 'images/booking/industry/education/5.jpg';
import education_5_2x from 'images/booking/industry/education/5@2x.jpg';
import education_6 from 'images/booking/industry/education/6.jpg';
import education_6_2x from 'images/booking/industry/education/6@2x.jpg';
import CBD_1 from 'images/booking/industry/CBD/1.jpg';
import CBD_1_2x from 'images/booking/industry/CBD/1@2x.jpg';
import CBD_2 from 'images/booking/industry/CBD/2.jpg';
import CBD_2_2x from 'images/booking/industry/CBD/2@2x.jpg';
import CBD_3 from 'images/booking/industry/CBD/3.jpg';
import CBD_3_2x from 'images/booking/industry/CBD/3@2x.jpg';
import CBD_4 from 'images/booking/industry/CBD/4.jpg';
import CBD_4_2x from 'images/booking/industry/CBD/4@2x.jpg';
import CBD_5 from 'images/booking/industry/CBD/5.jpg';
import CBD_5_2x from 'images/booking/industry/CBD/5@2x.jpg';
import CBD_6 from 'images/booking/industry/CBD/6.jpg';
import CBD_6_2x from 'images/booking/industry/CBD/6@2x.jpg';

const INTERSTITIAL_HEADLINES = {
  'beauty-and-skincare': 'omg we ❤️ beauty & skincare products!',
  'health-and-wellness': 'omg we ❤️ health & wellness products!',
  'food-and-beverage': 'omg we ❤️ food & beverage products!',
  'clothing-and-shoes': 'omg we ❤️ clothing & shoes!',
  accessories: 'omg we ❤️ fashion accessories!',
  jewelry: 'omg we ❤️ jewelry!',
  'pet-products': 'omg we ❤️ pet products!',
  'home-and-garden': 'omg we ❤️ home & garden products!',
  tech: 'omg we ❤️ tech products!',
  'toys-and-hobbies': 'omg we ❤️ toys & hobbies products!',
  'outdoor-and-recreation': 'omg we ❤️ outdoor & recreation products!',
  education: 'omg we ❤️ education products!',
  cbd: 'omg we ❤️ CBD products!',
  'personal-brand': 'we can’t wait to work with you!',
  other: 'we can’t wait to work with you!',
};

const INTERSTITIAL_IMAGES = {
  'beauty-and-skincare': [
    [beauty_and_skincare_1, beauty_and_skincare_1_2x],
    [beauty_and_skincare_2, beauty_and_skincare_2_2x],
    [beauty_and_skincare_3, beauty_and_skincare_3_2x],
    [beauty_and_skincare_4, beauty_and_skincare_4_2x],
    [beauty_and_skincare_5, beauty_and_skincare_5_2x],
    [beauty_and_skincare_6, beauty_and_skincare_6_2x],
  ],
  'health-and-wellness': [
    [health_and_wellness_1, health_and_wellness_1_2x],
    [health_and_wellness_2, health_and_wellness_2_2x],
    [health_and_wellness_3, health_and_wellness_3_2x],
    [health_and_wellness_4, health_and_wellness_4_2x],
    [health_and_wellness_5, health_and_wellness_5_2x],
    [health_and_wellness_6, health_and_wellness_6_2x],
  ],
  'food-and-beverage': [
    [food_and_beverage_1, food_and_beverage_1_2x],
    [food_and_beverage_2, food_and_beverage_2_2x],
    [food_and_beverage_3, food_and_beverage_3_2x],
    [food_and_beverage_4, food_and_beverage_4_2x],
    [food_and_beverage_5, food_and_beverage_5_2x],
    [food_and_beverage_6, food_and_beverage_6_2x],
  ],
  'clothing-and-shoes': [
    [clothing_and_shoes_1, clothing_and_shoes_1_2x],
    [clothing_and_shoes_2, clothing_and_shoes_2_2x],
    [clothing_and_shoes_3, clothing_and_shoes_3_2x],
    [clothing_and_shoes_4, clothing_and_shoes_4_2x],
    [clothing_and_shoes_5, clothing_and_shoes_5_2x],
    [clothing_and_shoes_6, clothing_and_shoes_6_2x],
  ],
  accessories: [
    [fashion_accessories_1, fashion_accessories_1_2x],
    [fashion_accessories_2, fashion_accessories_2_2x],
    [fashion_accessories_3, fashion_accessories_3_2x],
    [fashion_accessories_4, fashion_accessories_4_2x],
    [fashion_accessories_5, fashion_accessories_5_2x],
    [fashion_accessories_6, fashion_accessories_6_2x],
  ],
  jewelry: [
    [jewelry_1, jewelry_1_2x],
    [jewelry_2, jewelry_2_2x],
    [jewelry_3, jewelry_3_2x],
    [jewelry_4, jewelry_4_2x],
    [jewelry_5, jewelry_5_2x],
    [jewelry_6, jewelry_6_2x],
  ],
  'pet-products': [
    [pet_1, pet_1_2x],
    [pet_2, pet_2_2x],
    [pet_3, pet_3_2x],
    [pet_4, pet_4_2x],
    [pet_5, pet_5_2x],
    [pet_6, pet_6_2x],
  ],
  'home-and-garden': [
    [home_and_garden_1, home_and_garden_1_2x],
    [home_and_garden_2, home_and_garden_2_2x],
    [home_and_garden_3, home_and_garden_3_2x],
    [home_and_garden_4, home_and_garden_4_2x],
    [home_and_garden_5, home_and_garden_5_2x],
    [home_and_garden_6, home_and_garden_6_2x],
  ],
  tech: [
    [tech_1, tech_1_2x],
    [tech_2, tech_2_2x],
    [tech_3, tech_3_2x],
    [tech_4, tech_4_2x],
    [tech_5, tech_5_2x],
    [tech_6, tech_6_2x],
  ],
  'toys-and-hobbies': [
    [toys_and_hobbies_1, toys_and_hobbies_1_2x],
    [toys_and_hobbies_2, toys_and_hobbies_2_2x],
    [toys_and_hobbies_3, toys_and_hobbies_3_2x],
    [toys_and_hobbies_4, toys_and_hobbies_4_2x],
    [toys_and_hobbies_5, toys_and_hobbies_5_2x],
    [toys_and_hobbies_6, toys_and_hobbies_6_2x],
  ],
  'outdoor-and-recreation': [
    [outdoor_and_recreation_1, outdoor_and_recreation_1_2x],
    [outdoor_and_recreation_2, outdoor_and_recreation_2_2x],
    [outdoor_and_recreation_3, outdoor_and_recreation_3_2x],
    [outdoor_and_recreation_4, outdoor_and_recreation_4_2x],
    [outdoor_and_recreation_5, outdoor_and_recreation_5_2x],
    [outdoor_and_recreation_6, outdoor_and_recreation_6_2x],
  ],
  education: [
    [education_1, education_1_2x],
    [education_2, education_2_2x],
    [education_3, education_3_2x],
    [education_4, education_4_2x],
    [education_5, education_5_2x],
    [education_6, education_6_2x],
  ],
  cbd: [
    [CBD_1, CBD_1_2x],
    [CBD_2, CBD_2_2x],
    [CBD_3, CBD_3_2x],
    [CBD_4, CBD_4_2x],
    [CBD_5, CBD_5_2x],
    [CBD_6, CBD_6_2x],
  ],
  'personal-brand': [
    [tech_1, tech_1_2x],
    [fashion_accessories_2, fashion_accessories_2_2x],
    [beauty_and_skincare_2, beauty_and_skincare_2_2x],
    [home_and_garden_4, home_and_garden_4_2x],
    [outdoor_and_recreation_3, outdoor_and_recreation_3_2x],
    [food_and_beverage_4, food_and_beverage_4_2x],
  ],
  other: [
    [tech_1, tech_1_2x],
    [fashion_accessories_2, fashion_accessories_2_2x],
    [beauty_and_skincare_2, beauty_and_skincare_2_2x],
    [home_and_garden_4, home_and_garden_4_2x],
    [outdoor_and_recreation_3, outdoor_and_recreation_3_2x],
    [food_and_beverage_4, food_and_beverage_4_2x],
  ],
};

function interstitialHeadline(name) {
  if (INTERSTITIAL_HEADLINES[name] === undefined) {
    return INTERSTITIAL_HEADLINES.other;
  }

  return INTERSTITIAL_HEADLINES[name];
}

function interstitialImages(name) {
  if (INTERSTITIAL_IMAGES[name] === undefined) {
    return INTERSTITIAL_IMAGES.other;
  }

  return INTERSTITIAL_IMAGES[name];
}

export { interstitialHeadline, interstitialImages };

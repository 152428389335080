<script setup>
import { computed, ref, toRefs, watch } from 'vue';
import { refDebounced } from '@vueuse/core';
import { useCompetitors } from '@/queries/useCompetitors';
import PaginatorSmall from '@/components/PaginatorSmall.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { keepPreviousData } from '@tanstack/vue-query';
import { useExampleCompetitorStaffPicks } from '@/queries/useExampleCompetitorStaffPicks';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  integrationId: {
    type: [Number, String],
    default: null,
  },
  search: {
    type: String,
    required: true,
  },
  filter: {
    type: String,
    default: null,
  },
  orderBy: {
    type: String,
    default: 'name',
  },
});

const {
  accountId,
  integrationId,
  search,
  filter: filterPropValue,
  orderBy: orderByValue,
} = toRefs(props);

const searchDebounced = refDebounced(search, 200);

const currentPage = ref(1);

const itemsPerPage = ref(20);

watch([searchDebounced], () => {
  currentPage.value = 1;
});

const filterProp = computed(() => {
  if (filterPropValue.value) {
    return filterPropValue.value;
  }
  return null;
});

const competitorsDataIsEnabled = computed(() => !!integrationId.value);

const {
  data: competitorsData,
  error,
  isLoading: competitorsLoading,
  isSuccess: competitorsIsSuccess,
} = useCompetitors(
  accountId,
  {
    query: searchDebounced,
    filter: filterProp,
    orderBy: orderByValue,
    direction: 'asc',
    currentPage,
    itemsPerPage,
  },
  {
    placeholderData: keepPreviousData,
    enabled: competitorsDataIsEnabled,
  }
);

const exampleCompetitorsDataIsEnabled = computed(() => !integrationId.value);

const {
  data: exampleCompetitorsData,
  error: exampleCompetitorsError,
  isLoading: exampleCompetitorsLoading,
} = useExampleCompetitorStaffPicks(accountId, {
  enabled: exampleCompetitorsDataIsEnabled,
});

const paginationData = computed(() =>
  integrationId.value
    ? competitorsData.value?.pagination
    : exampleCompetitorsData.value?.pagination
);

const competitorsPagination = computed(() =>
  paginationData.value
    ? paginationData.value
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

const competitors = computed(() => {
  if (
    !integrationId.value ||
    (integrationId.value &&
      competitorsIsSuccess.value &&
      !competitorsData.value?.competitors.length)
  ) {
    return exampleCompetitorsData.value ?? [];
  }
  return competitorsData.value?.competitors ?? [];
});

const isLoading = computed(
  () => competitorsLoading.value || exampleCompetitorsLoading.value
);

const priorityError = usePriorityError(error, exampleCompetitorsError);
</script>

<template>
  <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
  <div v-if="competitors.length > 0" class="competitor-picker__options">
    <slot name="competitor-options" :competitors="competitors" />
  </div>
  <PaginatorSmall
    class="competitor-picker__paginator"
    :page="competitorsPagination.currentPage"
    :pages="competitorsPagination.totalPages"
    @page-change="currentPage = $event"
  />
  <p
    v-if="!isLoading && competitors.length < 1"
    class="competitor-picker__no-results"
  >
    no results
  </p>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.competitor-picker {
  &__no-results {
    @include variables_fonts.u-body--regular;

    margin: 4rem auto;
    text-align: center;
    color: variables.$gray-60;
  }
  &__options {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &__paginator:not(:last-child) {
    margin-bottom: 1rem;
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { getReservationTimeline } from '@/api/timeline';

/**
 *
 * @param {number | Ref<number>} reservationId
 * @param {string | Ref<string>} timezone
 */
export function useReservationTimeline(reservationId, { timezone } = {}) {
  return useQuery({
    queryKey: queryKeys.reservationTimeline(reservationId, timezone),
    queryFn: ({ signal, params = { timezone } }) =>
      getReservationTimeline(
        unref(reservationId),
        { timezone: params.timezone },
        signal
      ),
  });
}

<script setup>
import { ref, toRef, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import BookingLayout from './BookingLayout.vue';
import { usePackConfiguration } from '@/queries/pack-configurations/usePackConfiguration';
import { useCreatePackReservation } from '@/queries/useCreatePackReservation';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import WarningIcon from 'src/components/svgs/WarningIcon.vue';
import { useReservation } from '@/composables/useReservation';
import { useFlag } from '@/composables/useFlag';
import SoonaPackDetail from 'src/components/booking/SoonaPackDetail.vue';

const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  transitionName: String,
  packId: String,
  reservationId: String,
});

const router = useRouter();
// fixme: update logic to not use this API
// eslint-disable-next-line no-restricted-properties
const lastPage = router.options.history.state.back;

const {
  data: pack,
  error,
  isLoading,
} = usePackConfiguration({
  packId: toRef(props, 'packId'),
});
const { itemSelected, buttonClicked } = useBaseEvents();

const backButtonCopyFromHistory = computed(() => {
  if (lastPage?.includes('type')) return 'pack or custom';
  return 'all packs';
});

const packQuantity = ref(1);

watch(props, () => {
  packQuantity.value = 1;
});

function handleIncrement() {
  if (packQuantity.value < pack.value?.max_quantity) {
    packQuantity.value++;

    buttonClicked({
      context: 'booking',
      subContext: 'pack details',
      buttonLabel: 'increment',
      buttonAction: 'increment pack quantity',
    });
  }
}

function handleDecrement() {
  if (packQuantity.value > 1) packQuantity.value--;
}

const {
  error: createPackResError,
  mutate,
  isPending: isMutating,
} = useCreatePackReservation();

const priorityError = usePriorityError(createPackResError, error);

const { reservation, shootType } = useReservation(
  computed(() => props.reservationId)
);
const showCustomToPackModal = ref(false);
const byoPackSwapWarningFlag = useFlag('byo_pack_swap_warning');

function onNextClick(skipSwitchCheck) {
  if (
    byoPackSwapWarningFlag.value &&
    skipSwitchCheck !== true &&
    shootType.value !== 'unknown' &&
    reservation.value?.isPickAPack === false
  ) {
    showCustomToPackModal.value = true;
    return;
  }

  mutate(
    {
      reservationId: props.reservationId,
      packId: props.packId,
      quantity: packQuantity.value,
    },
    {
      onSuccess: () => {
        itemSelected({
          context: 'booking',
          subContext: 'pack details',
          itemId: pack.value.pack_product.id,
          itemLabel: pack.value.name,
          itemPrice: pack.value.pack_price,
          itemQuantity: packQuantity.value,
        });
        props.onNext();
      },
    }
  );
}

function onBackFromHistory() {
  if (lastPage?.includes('type')) return props.onBack('type');
  return props.onBack('packs');
}

function handleSwitchWarningConfirm() {
  showCustomToPackModal.value = false;
  onNextClick(true);

  buttonClicked({
    context: 'booking',
    subContext: 'pack details',
    buttonLabel: 'confirm change',
    buttonAction: 'confirm intent to switch from custom shoot to pack',
  });
}
function handleSwitchWarningCancel() {
  showCustomToPackModal.value = false;

  buttonClicked({
    context: 'booking',
    subContext: 'pack details',
    buttonLabel: 'cancel',
    buttonAction: 'dismiss custom shoot to pack warning modal',
  });
}
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      next-button-copy="book this pack"
      :back-button-copy="backButtonCopyFromHistory"
      :on-next-click="onNextClick"
      :on-back-click="() => onBackFromHistory()"
      :is-step-valid="!isMutating"
      no-sidecart
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <article class="pack-details prebooking-transition__content">
        <SoonaLoading
          v-if="isLoading"
          :is-loading="true"
          :is-dark="false"
          is-contained
          loading-text="loading"
        />
        <SoonaPackDetail
          v-if="pack"
          :pack="pack"
          :on-back="onBack"
          :pack-quantity="packQuantity"
          @on-increment="handleIncrement"
          @on-decrement="handleDecrement"
        />
        <div
          v-if="pack"
          class="pack-details__photos"
          :aria-title="`${pack.name} example photos`"
        >
          <div v-for="(img, index) in pack.reference_images" :key="index">
            <video
              v-if="img.image_content_type?.startsWith('video/')"
              autoplay
              loop
              playsinline
              muted
            >
              <source :src="img.image_url" :type="img.image_content_type" />
            </video>
            <img
              v-else
              :src="img.image_url"
              :alt="`${pack.name} example photo`"
            />
          </div>
        </div>
      </article>
      <SoonaDialog
        v-if="showCustomToPackModal"
        @close="handleSwitchWarningCancel"
      >
        <template #heading>
          <strong class="pack-details__switch-warning">
            <WarningIcon aria-title="Warning" />
            changing from custom shoot to pack shoot
          </strong>
        </template>
        <p>
          want to continue with this pack shoot? If you choose to continue, you
          will lose the custom shoot selections you’ve made so far.
        </p>
        <template #footer="{ close }">
          <SoonaButton variation="tertiary" @on-click="close">
            cancel
          </SoonaButton>
          <SoonaButton
            data-cypress="button-dialog-confirm"
            @on-click="handleSwitchWarningConfirm"
          >
            confirm changes
          </SoonaButton>
        </template>
      </SoonaDialog>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.pack-details {
  width: 100%;
  max-width: 58.375rem;
  position: relative;

  &__photos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-top: 1.5rem;
    width: 100%;

    > div {
      display: block;

      &:nth-child(8n - 7),
      &:nth-child(8n - 4) {
        grid-column: span 2;
        grid-row: span 2;
      }
    }

    img,
    video {
      display: block;
      border-radius: 0.625rem;
      object-fit: cover;
      width: 100%;
      height: 100%;
      background-color: variables.$gray-10;
    }
  }

  &__switch-warning {
    display: flex;
    align-items: center;

    svg {
      flex: 0 0 1.75rem;
      margin-right: 0.5rem;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    flex-direction: row;
    align-items: flex-start;
    gap: 1.5rem;

    &__photos {
      margin-top: 0;
      flex: 1 1 44%;
      max-width: 25rem;
    }
  }
}
</style>

import { http } from '../config/vue-axios';

export async function createPackReservation({
  reservationId,
  packId,
  quantity,
} = {}) {
  const body = {
    pack_configuration_id: packId,
    quantity: quantity,
  };
  const response = await http.post(`/reservations/${reservationId}/pack`, body);
  return response.data;
}

/**
 *
 * @param {number} reservationId
 */
export async function resetPack(reservationId) {
  const url = `/reservations/${reservationId}/pack.json`;

  const response = await http.delete(url);

  return response.status === 204;
}

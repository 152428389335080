import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useDeliverAllPendingEdits(accountId, reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return http.post(
        `/accounts/${toValue(accountId)}/edits_collection/deliver_all_pending.json`,
        { reservation_id: toValue(reservationId) }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
  });
}

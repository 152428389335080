<script setup>
import { computed, watch, ref, provide, toRefs } from 'vue';
import {
  onKeyStroke,
  useMediaQuery,
  useTimeoutFn,
  useTitle,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import TopActionBarBase from '@/components/user/anytime/gallery/media-editor/action-bars/TopActionBarBase.vue';
import MediaAsset from '@/components/user/anytime/gallery/media-editor/MediaAsset.vue';
import { TELEPORT_TO } from '@/composables/useTeleportTo';
import { truncate } from '@/lib/text';
import { useDialogContext } from '@/composables/useDialog';
import uniqueId from 'lodash/uniqueId';
import DownloadDigitalAsset from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAsset.vue';
import FlagsAndActions from '@/components/user/anytime/reservation/crew_edits/asset_view/FlagsAndActions.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { useReEditsCollectionDigitalAsset } from '@/queries/re_edits_collection_digital_assets/useReEditsCollectionDigitalAsset';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  editsCollectionDigitalAsset: {
    type: Object,
    default: undefined,
  },
  isFileLoading: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['close']);

const { asset, isFileLoading } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();
const assetId = computed(() => asset.value?.id);
const assetAccountId = computed(() => asset.value?.account_id);

const { mediaUrl, title, reEditsCDAId, reEditsCollectionId } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const popoverRef = ref();
const id = uniqueId('media-editor-');
const { checkAllowEscapeClose } = useDialogContext({ id });
provide(TELEPORT_TO, popoverRef);

const { isPending: isClosing } = useTimeoutFn(
  e => {
    emits('close', e);
  },
  150,
  { immediate: false }
);

function closePopover(e) {
  emits('close', e);
}

const { data: reEditsCDA } = useReEditsCollectionDigitalAsset(
  reEditsCollectionId,
  reEditsCDAId,
  {
    enabled: computed(
      () => !!reEditsCollectionId.value && !!reEditsCDAId.value
    ),
  }
);
const note = computed(() => reEditsCDA.value?.note?.content);
const hasReEditNote = computed(() => {
  return !!note.value;
});

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');
useFocusTrap(popoverRef, { immediate: true });
const fileTitle = computed(() => {
  return truncate(asset.value?.title, 50, true);
});
const tabTitle = computed(() => `${fileTitle.value ?? 'AI studio'} | soona`);
useTitle(tabTitle);
const handleClose = () => {
  closePopover();
};
// lifecycle hooks
onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(popoverRef)) {
    handleClose();
  }
});
const { pageViewed } = useBaseEvents();
watch(
  assetId,
  () => {
    pageViewed();
    mediaEditorStore.setAssetId(assetId.value);
    mediaEditorStore.setAssetAccountId(assetAccountId.value);
  },
  { immediate: true }
);
</script>
<template>
  <Teleport to="body">
    <div
      ref="popoverRef"
      class="media-editor"
      :class="{
        'media-editor--closing': isClosing,
      }"
      role="dialog"
      aria-modal="true"
    >
      <TopActionBarBase :is-wide="matchMediaIsWide">
        {{ title }}
        <template #actions>
          <div class="media-editor__header-actions">
            <FlagsAndActions
              v-if="asset"
              :asset="asset"
              :edits-collection-digital-asset="editsCollectionDigitalAsset"
            />
            <DownloadDigitalAsset :asset="asset" />
          </div>
        </template>
      </TopActionBarBase>
      <div class="media-editor__editing-panel">
        <div class="media-editor__middle-column">
          <slot name="error" />
          <div class="media-editor__img-wrapper">
            <MediaAsset
              v-if="asset"
              :is-file-loading="isFileLoading"
              :src-url="mediaUrl"
            />
          </div>
          <SoonaAlert v-if="hasReEditNote" class="media-editor__re-edit-note">
            re-edit note:
            {{ note }}
          </SoonaAlert>
        </div>
      </div>
      <slot name="carousel" />
    </div>
  </Teleport>
</template>
<style lang="scss" scoped>
@use 'src/variables';
@use 'src/variables_fonts';

.media-editor {
  background-color: variables.$gray-10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  animation:
    0.25s ease-out both scale-up,
    0.15s ease-out both k-fade-in;

  @media (prefers-reduced-motion: reduce) {
    animation: 0.15s ease-out both k-fade-in;
  }

  &--closing {
    animation:
      0.15s ease-out both scale-down,
      0.15s ease-out both k-fade-out;

    @media (prefers-reduced-motion: reduce) {
      animation: 0.15s ease-out both k-fade-out;
    }
  }

  &__header-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  &__editing-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    @media (min-width: variables.$screen-md-min) {
      flex-direction: row;
    }
  }

  &__re-edit-note {
    max-width: 60rem;
  }

  &__middle-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    transition: padding-right 0.3s cubic-bezier(0.22, 1, 0.36, 1);

    &--no-overflow {
      overflow-y: hidden;
    }
  }

  &__img-wrapper {
    align-items: center;
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    justify-items: center;
    min-height: 0;
    padding: 1rem 0 1rem 1rem;
    width: 100%;

    &--dense {
      gap: 0rem;
      padding: 0;
    }
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.95);
  }
}

@keyframes scale-down {
  to {
    transform: scale(0.95);
  }
}
</style>

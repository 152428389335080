import { computed } from 'vue';
import { useTierBySlug } from '@/queries/tiers/useTierBySlug';

export const useInventory = () => {
  const { data: fastPassTier } = useTierBySlug('fast-pass');

  const fastPassMonthlyPrice = computed(() => {
    return fastPassTier.value?.product?.prices.find(
      p => p.recurring_interval == 'month'
    )?.formatted_price;
  });

  const postShootOptions = computed(() => {
    return [
      {
        value: 'store_at_soona',
        addPackageTitle: 'store this package',
        emoji: '🗃',
        price: fastPassMonthlyPrice.value,
        priceNote: 'monthly',
        firstInfo: 'store it! sign me up for soona fast pass',
        secondInfo:
          'soona fast pass means easier scheduling. special promos. studio storage.',
      },
      {
        value: 'return_shipping',
        addPackageTitle: 'return this package',
        requiredAction: 'needs_return',
        emoji: '📫',
        price: null,
        priceNote: null,
        firstInfo: 'send it back to me',
        secondInfo: 'cost of shipping label applies.',
      },
      {
        value: 'discard',
        addPackageTitle: 'discard this package',
        requiredAction: 'needs_discard',
        emoji: '🗑',
        price: 'FREE',
        priceNote: '',
        firstInfo: 'discard it for me please',
        secondInfo:
          'we will safely discard your product in a way that protects your brand.',
      },
      {
        value: 'already_stored',
        emoji: '🌟',
        price: '',
        priceNote: 'you have my product',
        firstInfo: 'my product is already at soona',
        secondInfo:
          'already enrolled in the fast pass program? add your stored items directly to your booking.',
      },
    ];
  });

  const packageHandlingOptions = computed(() => {
    return [
      {
        value: 'store_at_soona',
        addPackageTitle: 'store this package',
        emoji: '🗃',
        price: fastPassMonthlyPrice.value,
        priceNote: '/mo',
        firstInfo: 'store it!',
        secondInfo: 'with soona fast pass',
        tooltipTitleMobile:
          'store your items at a soona location with fast pass',
        tooltipContent:
          'soona fast pass means easier scheduling. special promos. studio storage. we’ll set you up after you book.',
      },
      {
        value: 'discard',
        addPackageTitle: 'discard this package',
        requiredAction: 'needs_discard',
        emoji: '🗑',
        price: 'FREE',
        priceNote: '',
        firstInfo: 'discard it',
        secondInfo: 'for me please',
        tooltipTitleMobile: 'discard',
        tooltipContent:
          'we will safely discard your product in a way that protects your brand.',
      },
      {
        value: 'return_shipping',
        addPackageTitle: 'return this package',
        requiredAction: 'needs_return',
        emoji: '📫',
        price: null,
        priceNote: 'shipping costs apply',
        firstInfo: 'send it back to me',
        secondInfo: null,
        tooltipTitleMobile: null,
        tooltipContent: null,
      },
      {
        value: 'already_stored',
        emoji: '🌟',
        price: '',
        priceNote: 'you have my product',
        firstInfo: 'my product is already at soona',
        secondInfo: null,
        tooltipTitleMobile: 'my product is already at soona',
        tooltipContent:
          'already enrolled in the fast pass program? add your stored items directly to your booking.',
      },
      {
        value: 'no_products_to_send',
        emoji: '',
        price: '',
        priceNote: '',
        firstInfo: 'I’m not sending anything!',
        secondInfo: null,
        tooltipTitleMobile: null,
        tooltipContent: null,
      },
    ];
  });

  return {
    postShootOptions,
    packageHandlingOptions,
  };
};

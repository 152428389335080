import { computed } from 'vue';
import { useMediaQuery } from '@vueuse/core';

export default () => {
  // 45.0625rem is used here to match the breakpoint for the chat box.
  // we should consider using a common breakpoint for chat, and this should be updated when that changes.
  const chatIsDesktop = useMediaQuery('(min-width: 45.0625rem)');
  const chatIsMobile = computed(() => !chatIsDesktop.value);

  const actionBarIsDesktop = useMediaQuery('(min-width: 48rem)');
  const actionBarIsMobile = computed(() => !actionBarIsDesktop.value);

  return { chatIsDesktop, chatIsMobile, actionBarIsDesktop, actionBarIsMobile };
};

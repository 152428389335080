<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useAnyDialogOpen } from '@/composables/useDialog';

const props = defineProps({
  controls: {
    required: true,
    type: String,
  },
  hasOpened: {
    required: true,
    type: Boolean,
  },
  isExpanded: {
    required: true,
    type: Boolean,
  },
  percentComplete: {
    required: true,
    type: Number,
  },
  title: {
    required: true,
    type: String,
  },
});

const emits = defineEmits(['open']);

const isAnyDialogOpen = useAnyDialogOpen();

const hasPercentChanged = ref(false);
const wiggle = ref(false);

const hasOpened = computed(() => props.hasOpened);
const isExpanded = computed(() => props.isExpanded);
const percentComplete = computed(() => props.percentComplete);

watch([percentComplete, isAnyDialogOpen], ([newVal], [oldVal]) => {
  if ((hasPercentChanged.value && !isAnyDialogOpen.value) || !hasOpened.value) {
    wiggle.value = true;
  } else if (newVal > oldVal && !wiggle.value && isAnyDialogOpen.value) {
    hasPercentChanged.value = true;
  } else if (newVal > oldVal && !wiggle.value) {
    wiggle.value = true;
  } else {
    hasPercentChanged.value = false;
    wiggle.value = false;
  }
});

onMounted(() => {
  if (!hasOpened.value) wiggle.value = true;
});
</script>

<template>
  <button
    class="u-button-reset quest-button"
    :class="{
      'quest-button--expanded': isExpanded,
      'quest-button--wiggle': wiggle,
    }"
    :aria-controls="controls"
    data-cypress="button-open-quest-dialog"
    @click="emits('open')"
  >
    <span class="u-subheader--heavy">{{ title }} - {{ percentComplete }}%</span>
    <span class="u-subheader--heavy quest-button__open">open</span>
  </button>
</template>

<style lang="scss" scoped>
@use '@/variables';

@keyframes wiggle {
  0% {
    transform: translateX(0) rotate(0);
  }
  20% {
    transform: translateX(0.25rem) rotate(-4deg);
  }
  40% {
    transform: translateX(-0.25rem) rotate(3deg);
  }
  60% {
    transform: translateX(0.125rem) rotate(-2deg);
  }
  80% {
    transform: translateX(-0.125rem) rotate(2deg);
  }
  100% {
    transform: translateX(0) rotate(0);
  }
}

.quest-button {
  align-items: center;
  background-color: variables.$green-apple-20;
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  transition: color 0.2s;
  width: 100%;

  &:hover {
    color: variables.$gray-80;
  }

  &:focus-visible {
    outline: 0.125rem solid variables.$periwink-blue-50;
    outline-offset: -0.125rem;
  }

  @media (min-width: variables.$screen-md-min) {
    background-color: variables.$black-default;
    border-radius: 4rem;
    bottom: 2rem;
    color: variables.$white-default;
    padding: 1rem 1.5rem;
    position: fixed;
    right: 2rem;
    transition: background-color 0.2s;
    width: max-content;
    z-index: 1;

    &:hover {
      background-color: variables.$bingo-blue-darker;
      color: variables.$white-default;
    }

    &:focus-visible {
      outline-color: variables.$bubbletape-pink-60;
      outline-offset: 0;
    }

    &:active {
      background-color: variables.$bingo-blue-dark;
    }

    &--expanded {
      visibility: hidden;
    }

    &--wiggle {
      @media (min-width: variables.$screen-md-min) and (prefers-reduced-motion: no-preference) {
        animation: wiggle 1.5s ease-in-out;
      }
    }

    &__open {
      display: none;
    }
  }
}
</style>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { removeIntegrationAccount } from '../api/integrations';

/**
 * @param {number | Ref<number>} accountId
 * @param {number | Ref<number>} integrationId
 */
export function useRemoveIntegrationAccount(accountId, integrationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: params => removeIntegrationAccount(params),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.integrations(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.catalog(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.listingInsights(accountId, integrationId),
        }),
      ]);
    },
  });
}

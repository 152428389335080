import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { createAddress } from '../api/addresses';
import { queryKeys } from './query-keys';
import { unref } from 'vue';

export function useCreateAddress(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => createAddress(unref(accountId), body),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.addresses(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.inventoryPackages(),
        }),
      ]);
    },
  });
}

export const shippingProvider = {
  methods: {
    getUrl(value) {
      if (value.shipping_provider.toLowerCase() == 'usps') {
        return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${value.tracking_number}%2C`;
      } else if (value.shipping_provider.toLowerCase() == 'fedex') {
        return `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${value.tracking_number}&cntry_code=us`;
      } else if (value.shipping_provider.toLowerCase() == 'ups') {
        return `https://www.ups.com/track?loc=en_US&tracknum=${value.tracking_number}&requester=WT/`;
      } else {
        return;
      }
    },
  },
};

import { unref, watchEffect } from 'vue';

/**
 * @callback mutateAddReservationTags
 * @param {Object} body
 * @param {number} body.tagId
 * @returns void
 */

/**
 *
 * @param {Ref} reservationTags
 * @param {Ref} reservation
 * @param {Ref | string} slug
 * @param {mutateAddReservationTags} mutate
 */
export function useAddSkippableTag(reservationTags, reservation, slug, mutate) {
  watchEffect(() => {
    if (
      reservationTags.value?.skippable_tag_id &&
      reservation.value?.steps.find(step => step.slug === unref(slug))
        ?.complete === false
    ) {
      mutate({ tagId: reservationTags.value.skippable_tag_id });
    }
  });
}

import { http } from '../config/vue-axios';

/**
 *
 * @param {AbortSignal} [signal]
 */
export async function getHighlightedPacks(
  { reservationId, industrySlug, numberOfPacks },
  signal
) {
  const params = {};

  if (reservationId) {
    params.reservation_id = reservationId;
  }

  if (industrySlug) {
    params.industry_slug = industrySlug;
  }

  if (numberOfPacks) params.number_of_packs = numberOfPacks;

  const response = await http.get(
    '/pack_configurations/highlighted_packs.json',
    {
      signal,
      params,
    }
  );
  return response.data;
}

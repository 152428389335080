<script setup>
import { computed, ref } from 'vue';
import { useInfiniteAccountsOrders } from 'src/queries/accounts-orders/useGetAccountsOrders';
import { useUpdateAccountsOrder } from 'src/queries/accounts-orders/useUpdateAccountsOrder';
import { useCapability } from 'src/composables/useCapability';
import OrderListItem from 'src/components/user/anytime/billing_and_orders/OrderListItem.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});
const accountId = computed(() => props.accountId);
const accountIdExists = computed(() => !!props.accountId);

function scrollToBottom() {
  const element = document.getElementById('orders-list-container');
  if (element) {
    element.scrollTop = element.scrollHeight;
  }
}

const { mutate: mutateAccountsOrders, isError: updateAccountError } =
  useUpdateAccountsOrder(accountId, {
    enabled: accountIdExists,
  });

const {
  data: getAccountOrders,
  isError: infiniteOrdersError,
  isSuccess: isOrdersSuccess,
  fetchNextPage,
  hasNextPage: hasNextPage,
} = useInfiniteAccountsOrders(accountId, {
  itemsPerPage: 5,
  enabled: accountIdExists,
});

const errorMessages = computed(() => {
  let errors = [];
  if (infiniteOrdersError.value) {
    errors.push('Error loading orders');
  }
  if (updateAccountError.value) {
    errors.push('Error canceling order');
  }
  return errors;
});
const orders = computed(() =>
  (getAccountOrders.value?.pages || []).flatMap(x => x.orders)
);

async function loadMoreOrders() {
  scrollToBottom();
  return fetchNextPage();
}

//canceling order modal
const cancelOrderId = ref(null);

const modalVisible = ref(false);

function openModal(orderId) {
  cancelOrderId.value = orderId;
  modalVisible.value = true;
}

function cancelOrder() {
  mutateAccountsOrders({ order_id: cancelOrderId.value, status: 'canceled' });
  modalVisible.value = false;
}
</script>

<template>
  <div class="orders-list__header">
    <h2 class="u-title--heavy">studio payments</h2>
    <em class="u-small--regular">*does not include refunds at this time</em>
  </div>
  <SoonaError v-for="message in errorMessages" :key="message">
    {{ message }}
  </SoonaError>
  <div v-if="orders.length > 0" id="orders-list-container" class="orders-list">
    <OrderListItem
      v-for="order in orders"
      :key="order.id"
      :order="order"
      :account-id="accountId"
      :is-ft-soona-staff="isFtSoonaStaff"
      @cancel-order="openModal"
    />
  </div>
  <SoonaNoResults v-else-if="isOrdersSuccess" :show-image="false">
    <template #header>no studio payments yet…</template>
    <template #button>
      <SoonaButton variation="primary" element="a" href="/book/new">
        build a shoot
      </SoonaButton>
    </template>
  </SoonaNoResults>
  <div class="order-list__actions">
    <SoonaButton
      v-if="hasNextPage && orders.length > 0"
      size="medium"
      variation="secondary-gray"
      @on-click="loadMoreOrders"
    >
      load more orders
    </SoonaButton>
  </div>
  <SoonaDialog v-if="modalVisible" @close="() => (modalVisible = false)">
    <template #heading>confirm cancel order</template>
    <p>are you sure you want to cancel this order? this cannot be undone.</p>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">back</SoonaButton>
      <SoonaButton data-cypress="button-dialog-confirm" @on-click="cancelOrder">
        yes, cancel order
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.orders-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    min-height: 2.5rem;
  }

  &__actions {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

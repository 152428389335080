<script setup>
import { computed, ref, watch } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import InviteCollaboratorDialog from './InviteCollaboratorDialog.vue';
import ResendInvitationDialog from '@/components/user/anytime/dashboard/collaborators/ResendInvitationDialog.vue';
import RemoveInvitationDialog from '@/components/user/anytime/dashboard/collaborators/RemoveInvitationDialog.vue';
import { useInvitations } from '@/queries/invitations/useInvitations';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});
const accountId = computed(() => props.accountId);

const itemsPerPage = ref(10);
const currentPage = ref(1);
watch(itemsPerPage, () => {
  currentPage.value = 1;
});

const {
  data: invitations,
  error,
  isSuccess,
} = useInvitations(accountId, {
  currentPage,
  itemsPerPage,
  status: 'sent',
});
const invitationsItems = computed(() => invitations.value?.invitations ?? []);
const invitationsPagination = computed(() => {
  if (invitations.value) {
    return invitations.value.pagination;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});

const priorityError = usePriorityError(error);

const invitationIdToCancel = ref(null);
const invitationToResend = ref(null);
const inviteDialogOpen = ref(false);
</script>

<template>
  <div class="invitations-list">
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <SoonaNoResults
      v-if="isSuccess && invitationsItems.length === 0"
      :show-image="false"
      class="invitations-list__no-results"
    >
      <template #header>no pending invites</template>
      <template #button>
        <SoonaButton
          variation="secondary-black"
          @on-click="inviteDialogOpen = true"
        >
          invite team members
        </SoonaButton>
      </template>
    </SoonaNoResults>
    <table v-else-if="invitationsItems.length > 0" class="invitations-table">
      <thead class="table-header">
        <tr>
          <th colspan="2" class="avatar-column u-badge--small">name</th>
          <th class="menu-column">
            <span class="u-visually-hidden">actions</span>
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr v-for="item in invitationsItems" :key="item.id">
          <td class="avatar-column is-hidden-mobile">
            <SoonaAvatar
              :name="item.name || item.email"
              :src="item.avatar_url"
              size="2rem"
              type="user"
            />
          </td>
          <td class="name-column">
            <p v-if="item.name" class="collaborator-name u-button--large-caps">
              {{ item.name }}
            </p>
            <p class="u-small--regular collaborator-email">
              {{ item.email }}
            </p>
          </td>
          <td class="menu-column">
            <SoonaDropdownMenu copy="actions" variation="secondary-black">
              <template #default="{ clickCapture, keydown, mouseover }">
                <SoonaDropdownMenuItem>
                  <button
                    role="menuitem"
                    @click="invitationToResend = item"
                    @click.capture="clickCapture"
                    @keydown="keydown"
                    @mouseover="mouseover"
                  >
                    resend invitation
                  </button>
                </SoonaDropdownMenuItem>
                <SoonaDropdownMenuItem>
                  <button
                    role="menuitem"
                    @click="invitationIdToCancel = item.id"
                    @click.capture="clickCapture"
                    @keydown="keydown"
                    @mouseover="mouseover"
                  >
                    cancel invitation
                  </button>
                </SoonaDropdownMenuItem>
              </template>
            </SoonaDropdownMenu>
          </td>
        </tr>
      </tbody>
    </table>
    <PaginatorFull
      v-model:page="currentPage"
      v-model:records-per-page="itemsPerPage"
      :total-pages="invitationsPagination.totalPages"
      :total-count="invitationsPagination.totalCount"
      :page-count="invitationsItems.length"
    />

    <RemoveInvitationDialog
      v-if="invitationIdToCancel"
      :invitation-id="invitationIdToCancel"
      :account-id="accountId"
      @close="invitationIdToCancel = null"
    />

    <ResendInvitationDialog
      v-if="invitationToResend"
      :account-id="accountId"
      :invite-email="invitationToResend.email"
      :invite-role-id="invitationToResend.role.id"
      @close="invitationToResend = null"
    />

    <InviteCollaboratorDialog
      v-if="inviteDialogOpen"
      :account-id="accountId"
      @close="inviteDialogOpen = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.invitations-list {
  &__no-results {
    margin-top: 1rem;
  }

  .invitations-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;

    .avatar-column {
      padding: 1rem 0 1rem 0.75rem;
      width: 2.75rem;
    }

    .name-column {
      padding: 1rem 0.75rem;

      p {
        word-break: break-word;
      }
    }

    .menu-column {
      padding: 1rem 0.25rem;
      text-align: right;
    }

    .table-header {
      border-bottom: solid 0.0625rem variables.$gray-30;

      th {
        color: variables.$gray-60;
      }
    }

    .table-body {
      tr {
        border-bottom: solid 0.0625rem variables.$gray-30;

        td {
          vertical-align: middle;
        }
      }
    }

    .collaborator-email {
      user-select: all;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import ProductInventoryPackage from '@/components/user/anytime/products/inventory/ProductInventoryPackage.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  packages: {
    type: Array,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  heading: {
    type: String,
    required: true,
  },
  itemsPerPage: {
    type: Number,
    required: true,
  },
  priorityError: {
    type: [String, null],
    required: false,
  },
  totalCount: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
});

defineEmits(['update:currentPage', 'update:itemsPerPage']);

const headingId = computed(() => {
  return 'product-inventory-heading-' + props.heading.replace(/\W/g, '-');
});
</script>

<template>
  <section class="product-inventory-list" :aria-labelledby="headingId">
    <h2 :id="headingId" class="product-inventory-list__heading">
      {{ heading }}
    </h2>
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <ProductInventoryPackage
      v-for="pkg in packages"
      :key="pkg.id"
      :inventory-package="pkg"
    />

    <PaginatorFull
      :page="currentPage"
      :records-per-page="itemsPerPage"
      :total-pages="totalPages"
      :total-count="totalCount"
      :page-count="packages.length"
      @update:page="$emit('update:currentPage', $event)"
      @update:records-per-page="$emit('update:itemsPerPage', $event)"
    />
  </section>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-inventory-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__heading {
    @include variables_fonts.u-subheader--heavy;
    color: variables.$black-default;
  }
}

@media (min-width: variables.$screen-lg-min) {
  .product-inventory-list {
    gap: 1.5rem;
  }
}
</style>

<template>
  <label>
    <input
      id="checkbox"
      type="checkbox"
      :checked="status"
      :disabled="disabledStatus"
      @change="handleClick(isChecked($event), item)"
    />
    <span :class="disabledStatus ? 'disable' : ''"></span>
  </label>
</template>

<script>
export default {
  props: {
    item: Object,
    handleClick: Function,
    status: Boolean,
    disabledStatus: Boolean,
  },
  methods: {
    isChecked(event) {
      return event.srcElement.checked;
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';

label input {
  visibility: hidden; /* <-- Hide the default checkbox. The rest is to hide and allow tabbing, which display:none prevents */
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
}

label span {
  height: 1.875rem;
  width: 1.875rem;
  border: 0.125rem solid variables.$periwink-blue-20;
  border-radius: 0.3125rem;
  display: inline-block;
  background-color: variables.$white-default;
  transition: background-color 0.3s;

  &.disable {
    border-color: variables.$gray-50;
    background-color: variables.$gray-30;
    cursor: auto;
  }
}

label span:hover {
  border-color: variables.$periwink-blue-60;
  cursor: pointer;

  &.disable {
    border-color: variables.$gray-50;
    cursor: auto;
  }
}

[type='checkbox']:checked + span {
  background-color: variables.$periwink-blue-60;
  color: variables.$white-default;
  border: 0.125rem solid variables.$periwink-blue-60;
}

[type='checkbox']:checked + span:before {
  content: '\2714';
  position: relative;
  font-size: 16pt;
  font-weight: 800;
  bottom: 0.1875rem;
  left: 0.25rem;
}
</style>

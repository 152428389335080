<script setup>
import { computed } from 'vue';

const props = defineProps({
  reservation: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    required: true,
    validator: value => ['scheduleStatus', 'bookingType'].includes(value),
  },
});

const generatedClassesAndText = computed(() => {
  if (props.type === 'scheduleStatus') {
    if (props.reservation.start && props.reservation.status !== 'draft') {
      return 'scheduled';
    } else if (
      !props.reservation.start &&
      props.reservation.status !== 'draft'
    ) {
      return 'unscheduled';
    }
  } else if (props.type === 'bookingType') {
    if (
      props.reservation.status === 'draft' &&
      props.reservation.draft_type === 'client_draft'
    ) {
      return 'draft';
    } else if (props.reservation.previous_id) {
      return 'continuation';
    } else if (
      props.reservation.status === 'draft' &&
      props.reservation.draft_type === 'suggested_draft'
    ) {
      return 'suggested';
    }
  }
  return null;
});
</script>

<template>
  <p
    v-if="generatedClassesAndText"
    class="gallery-card-badge"
    :class="generatedClassesAndText"
  >
    {{ generatedClassesAndText }}
  </p>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.gallery-card-badge {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.125rem 0.375rem;
  border-radius: 0.0625rem;
  @include variables_fonts.u-badge--small;

  background: variables.$gray-20;
  border: 0.0625rem solid variables.$gray-30;
  color: variables.$gray-60;

  &.unscheduled {
    background: variables.$tangerine-20;
    border: 0.0625rem solid variables.$tangerine-30;
    color: variables.$tangerine-70;
  }
  &.scheduled {
    background: variables.$avo-toast-20;
    border: 0.0625rem solid variables.$avo-toast-30;
    color: variables.$avo-toast-60;
  }
}
</style>

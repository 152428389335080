import { toValue } from 'vue';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { reservationDigitalAssets } from '@/api/reservationDigitalAssets';

const getNextPageParam = lastPage => {
  const pagination = lastPage?.pagination;

  if (!pagination) return null;
  if (pagination.currentPage >= pagination.totalPages) return null;

  return pagination.currentPage + 1;
};

const getPreviousPageParam = firstPage => {
  const pagination = firstPage?.pagination;

  if (!pagination) return null;
  if (pagination.currentPage <= 1) return null;

  return pagination.currentPage - 1;
};

/**
 *
 * @param {string | number} [reservationId]
 * @param {string} [context]
 * @param {string | null} [params.album]
 * @param {string | null} [params.filter] # todo: consider using a more specific name after 1/23
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 * */
export function useInfiniteReservationDigitalAssets(
  reservationId,
  { itemsPerPage = 10, currentPage, filters = {} },
  queryOptions
) {
  return useInfiniteQuery({
    queryKey: queryKeys.reservationDigitalAssets(reservationId, {
      currentPage,
      itemsPerPage,
      filters,
      infinite: true,
    }),
    queryFn: ({ signal, pageParam }) => {
      return reservationDigitalAssets(
        toValue(reservationId),
        {
          currentPage: pageParam,
          itemsPerPage: toValue(itemsPerPage),
          filters: toValue(filters),
        },
        signal
      );
    },
    initialPageParam: currentPage,
    getNextPageParam,
    getPreviousPageParam,
    ...queryOptions,
  });
}

import { http } from '@/config/vue-axios';

/**
 *
 * @param {number} questId
 */
export async function getQuest(questId) {
  const response = await http.get(`/quests/${questId}.json`);
  return response.data;
}

/**
 *
 * @param {number} questId
 * @param {Object} [body]
 */
export async function updateQuest(questId, body = {}) {
  const response = await http.patch(`/quests/${questId}`, {
    quest: body,
  });

  return response.data;
}

<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useCreateCredit } from '@/queries/credits/useCreateCredit';
import { computed, ref } from 'vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';

const props = defineProps(['accountId', 'reservationId']);

const { mutate, isPending } = useCreateCredit();

const options = [
  { label: 'photo', value: 'photo-default' },
  { label: 'video', value: 'video-default' },
  { label: 'bts video', value: 'video-behind_the_scenes' },
  { label: 'gif', value: 'animation-default' },
];

const creditType = ref(null);
const quantity = ref(0);
const showModal = ref(false);

function resetState() {
  showModal.value = false;
  creditType.value = null;
  quantity.value = 0;
}

const formValid = computed(() => {
  return (
    !!creditType.value &&
    quantity.value > 0 &&
    creditType.value.split('-').length === 2
  );
});

function create() {
  if (!formValid.value) {
    return;
  }

  const [type, category] = creditType.value.split('-');
  mutate(
    {
      account_id: props.accountId,
      reservation_id: props.reservationId,
      credit_type: type,
      content_category: category,
      credit_reason: 'down_payment',
      quantity: quantity.value,
    },
    {
      onSuccess: () => {
        resetState();
      },
    }
  );
}
</script>

<template>
  <div>
    <SoonaButton
      variation="tertiary"
      size="medium"
      data-cypress="create-credit-button"
      @on-click="showModal = true"
    >
      add booking credits
    </SoonaButton>
    <SoonaDialog v-if="showModal" @close="resetState">
      <template #heading>add booking credit</template>
      <SoonaForm
        id="add-credit-form"
        class="create-credit-form"
        @submit="create"
      >
        <SoonaSelect v-model="creditType" :options="options">
          <template #label>credit type</template>
        </SoonaSelect>

        <SoonaTextfield
          v-model="quantity"
          type="number"
          :min="0"
          label="quantity"
          name="quantity"
          class="quantity-textfield"
          required
        />
      </SoonaForm>
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">
          cancel
        </SoonaButton>
        <SoonaButton
          form="add-credit-form"
          type="submit"
          :loading="isPending"
          :disabled="!formValid"
          data-cypress="button-dialog-confirm"
        >
          create
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.create-credit-form {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  & > * {
    flex: 1 1 50%;
  }
}

.quantity-textfield {
  padding-bottom: 0;
}
</style>

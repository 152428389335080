import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { watchDebounced } from '@vueuse/core';
import { usePromotionWidgets as usePromotionWidgetsQuery } from '@/queries/usePromotions';
import { useFlag } from '@/composables/useFlag';

/**
 *
 * @param update {Function}
 */
export function usePromotionWidgets(update) {
  const route = useRoute();
  const promoWidgetFeatureFlag = useFlag('promo_engine');

  const isEnabled = computed(() => {
    if (Object.keys(route.meta).length !== 0) {
      return (
        route.meta.hide_header_promo_banner !== true &&
        promoWidgetFeatureFlag.value
      );
    } else {
      return false;
    }
  });
  const bannerType = computed(() =>
    route.meta.context === 'booking' ? 'BookingBanner' : 'PlatformBanner'
  );
  const { data: promotionWidgetsResponse } = usePromotionWidgetsQuery(
    bannerType,
    {
      enabled: isEnabled,
    }
  );
  const promotionWidgets = computed(() => {
    return promotionWidgetsResponse.value ?? [];
  });

  const hasPromotionWidgets = computed(() => {
    return promotionWidgets.value && promotionWidgets.value.length > 0;
  });

  const promoWidgetsEnabled = computed(
    () => isEnabled.value && hasPromotionWidgets.value
  );

  watchDebounced(
    () => [promoWidgetsEnabled.value, promotionWidgets.value],
    () => {
      update();
    },
    { debounce: 500 }
  );

  return { promoWidgetsEnabled, promotionWidgets };
}

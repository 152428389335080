import * as types from 'src/store/mutation-types';

const state = {
  defaultPaymentInfo: undefined,
  saveCard: false,
  errorText: undefined,
};

const getters = {};

const mutations = {
  [types.SET_SAVE_CARD](state, value) {
    state.saveCard = value;
  },
  [types.SET_DEFAULT_PAYMENT_INFO](state, value) {
    state.defaultPaymentInfo = value;
  },
  [types.SET_ERRORS](state, value) {
    state.errorText = value;
  },
};

const actions = {
  setSaveCard({ commit }, shouldSaveCard) {
    commit(types.SET_SAVE_CARD, shouldSaveCard);
  },
  fetchDefaultPayment({ commit }, accountId) {
    return new Promise((resolve, reject) => {
      this.http.post(`accounts/${accountId}/default_payment`).then(
        response => {
          commit(types.SET_DEFAULT_PAYMENT_INFO, response.data.payment_info);
          commit(types.SET_SAVE_CARD, true);

          resolve(response);
        },
        error => {
          console.error('fetch_default_payment error', error);
          reject(error);
        }
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

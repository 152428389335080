<template>
  <div class="ProgressBar">
    <section class="bar">
      <div class="bar-line" :style="`width: ${quizPercentageCompleted}%`"></div>
    </section>
    <section class="progress-text mt-m">
      <p class="text-s">
        <span v-if="quizPercentageCompleted > 0">good job!</span> you're
        {{ quizPercentageCompleted }}% there!
      </p>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ProgressBar',
  computed: {
    ...mapGetters('account', ['quizPercentageCompleted']),
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.ProgressBar {
  width: 75%;
  position: relative;
  .bar {
    display: flex;
    height: 0.75rem;
    background: variables.$white-default;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
  }
  .bar-line {
    background: variables.$bright-blue;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  .progress-text {
    text-align: right;
  }
}

.text-s {
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .ProgressBar {
    width: 100%;
  }
}
</style>

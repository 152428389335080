<script setup>
import SoonaExpansionPanel from '@/components/ui_library/SoonaExpansionPanel.vue';
import RequirementTypeDisplay from './RequirementTypeDisplay.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { convertToHHMM12, convertToMMDDYY } from '@/lib/date-formatters';
import { computed } from 'vue';

const props = defineProps({
  requirementType: {
    type: String,
    required: true,
  },
  updatedAt: {
    type: String,
    default: null,
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

function onExpandChange(isExpanded) {
  if (isExpanded) {
    linkClicked({
      context: route.meta?.context ?? 'unknown',
      subContext: 'crew requirements',
      linkLabel: props.requirementType,
      linkHref: null,
    });
  }
}

const updatedAtDisplay = computed(() => {
  if (!props.updatedAt) return null;
  return `${convertToMMDDYY(props.updatedAt)} at ${convertToHHMM12(
    props.updatedAt
  )}`;
});
</script>

<template>
  <SoonaExpansionPanel @expand-change="onExpandChange">
    <template #header>
      <RequirementTypeDisplay :requirement-type="requirementType">
        <template v-if="updatedAt" #meta>
          updated at
          <time :datetime="updatedAt">{{ updatedAtDisplay }}</time>
        </template>
      </RequirementTypeDisplay>
    </template>
    <template #panel>
      <slot name="default" />
    </template>
  </SoonaExpansionPanel>
</template>

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useUpdateSubscription() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const params = { subscription: body };

      const response = await http.put(
        `/subscriptions/${toValue(body.subscription_id)}.json`,
        params
      );

      return response.data;
    },
    onSuccess: async data => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(data.account_id),
        }),
        queryClient.invalidateQueries({ queryKey: queryKeys.capability() }),
      ]);
    },
  });
}

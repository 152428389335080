import { http } from '../config/vue-axios';
import { mapPaginationHeaders } from '../lib/api-transforms';

/**
 *
 * @param {string | number} accountId
 * @param {Object} [params]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {Array<string>} [params.orderStatus]
 * @param {Array<string>} [params.orderType]
 * @param {AbortSignal} [signal]
 */
export async function getAccountsOrders(
  accountId,
  { currentPage, itemsPerPage, orderStatus, orderType } = {},
  signal
) {
  const params = {};

  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items_per_page'] = itemsPerPage;
  if (orderStatus) params['order_status'] = orderStatus;
  if (orderType) params['order_type'] = orderType;

  const response = await http.get(`accounts/${accountId}/orders`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    orders: response.data,
  };
}

/**
 *
 * @param {string | number} accountId
 * @param {Object} [body]
 */
export async function updateAccountsOrder(accountId, body = {}) {
  const response = await http.put(`accounts/${accountId}/orders`, body);

  return response.data;
}

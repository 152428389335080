<script setup>
import { toRef } from 'vue';
import { useAccount } from '@/composables/useAccount';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const { account } = useAccount(toRef(() => props.accountId));
</script>

<template>
  <h1>customer questionnaire will live here</h1>
  <p>this area is under construction. it'll use the following account data:</p>
  <pre>
    {{ account }}
  </pre>
</template>

<script setup>
import { computed } from 'vue';
import { useGetProServiceProducts } from '@/queries/useGetProServiceProducts';
import { useSearchTags } from '@/queries/useSearchTags';
import PackSubSection from '@/components/crew/pack_builder/PackSubSection.vue';
import PackImageUploader from '@/components/crew/pack_builder/PackImageUploader.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const props = defineProps({
  category: {
    type: String,
    required: false,
  },
  editingDisabled: {
    type: Boolean,
    required: true,
  },
  includedGifs: {
    type: Object,
    required: false,
  },
  includedPhotos: {
    type: Object,
    required: false,
  },
  includedVideos: {
    type: Object,
    required: false,
  },
  photoSegment: {
    type: Object,
    required: false,
  },
  productDescription: {
    type: String,
    required: false,
  },
  proServiceProducts: {
    type: Array,
    required: false,
  },
  thumbnailUrl: {
    type: String,
    required: false,
  },
});

const emit = defineEmits([
  'update:includedGifs',
  'update:includedPhotos',
  'update:includedVideos',
  'update:photoSegment',
  'update:productDescription',
  'update:proServiceProducts',
  'update:thumbnail',
  'update:thumbnailUrl',
]);

// pack product image
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const originalThumbnailImage = props.thumbnailUrl;
const setThumbnailBlob = blob => {
  let url = blob
    ? `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
    : originalThumbnailImage;
  emit(`update:thumbnailUrl`, url);
  emit(`update:thumbnail`, blob?.signed_id);
};

const isPhotoPack = computed(() => props.category === 'photo');

const creditOptions = Array.from(Array(30), (_, i) => ({
  value: i,
  label: i,
}));

// photo segment
const { data: photoQuantity } = useSearchTags(
  {
    tagCategorySlug: computed(() => 'photo-quantity'),
  },
  {
    enabled: isPhotoPack.value,
  }
);
const photoQuantityOptions = computed(() => {
  return photoQuantity?.value?.map(x => ({
    value: x.id,
    label: x.title,
  }));
});
const photoSegment = computed(() => props.photoSegment?.[0]);
const selectedPhotoQuantity = computed({
  get() {
    return photoQuantityOptions?.value?.find(
      option => option.value === photoSegment.value?.tag_id
    );
  },
  set(value) {
    let result = photoQuantityOptions.value.find(x => value === x.value);
    emit('update:photoSegment', [
      { tag_id: result.value, title: result.label },
    ]);
  },
});

// pro services
// eslint-disable-next-line vue/no-dupe-keys
const { data: proServiceProducts } = useGetProServiceProducts();
const toCurrency = (value, currency = 'USD') => {
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
const proServices = computed(() => {
  let category = isPhotoPack.value ? 'photo' : 'video';
  return proServiceProducts?.value?.reduce((acc, product) => {
    if (
      product.name !== 'content creator' &&
      (product.shoot_type === null || product.shoot_type === category)
    ) {
      acc = [
        ...acc,
        {
          label: `${product.name} (${product.level}) - ${toCurrency(
            product.rate
          )}`,
          value: {
            description: product.description,
            level: product.level,
            name: product.name,
            rate: product.rate,
            product_ids: product.product_ids,
            thumbnail_url: product.thumbnail_url,
          },
        },
      ];
    }
    return acc;
  }, []);
});
const selectedProService = computed({
  get() {
    return proServices?.value?.filter(x =>
      props.proServiceProducts?.find(
        y => y.name === x.value.name && y.level === x.value.level
      )
    );
  },
  set(value) {
    emit('update:proServiceProducts', value);
  },
});

// credits
const includedPhotos = computed({
  get() {
    return props.includedPhotos?.default;
  },
  set(value) {
    emit('update:includedPhotos', { default: value });
  },
});
const includedGifs = computed({
  get() {
    return props.includedGifs?.default;
  },
  set(value) {
    emit('update:includedGifs', { default: value });
  },
});
const includedVideos = computed({
  get() {
    return props.includedVideos?.default;
  },
  set(value) {
    emit('update:includedVideos', { default: value });
  },
});
</script>

<template>
  <div class="pack-reservation-summary">
    <PackSubSection heading="reservation summary">
      <SoonaTextfield
        :model-value="productDescription"
        label="pack product description"
        type="text"
        name="pack-product-description"
        element="textarea"
        rows="4"
        :maxlength="300"
        @update:model-value="$emit('update:productDescription', $event)"
      />

      <PackImageUploader
        class="pack-reservation-summary__image-uploader"
        image-type="product"
        :set-pack-image-blob="setThumbnailBlob"
        :pack-image-url="thumbnailUrl"
      >
        <template #label> product image </template>
        <template #subtext> used in the sidebar cart </template>
      </PackImageUploader>

      <SoonaSelect
        v-if="isPhotoPack"
        v-model:model-value="selectedPhotoQuantity"
        class="pack-reservation-summary__photo-quantity"
        :options="photoQuantityOptions"
        :multi="false"
      >
        <template #label> photo quantity (for shoot length) </template>
      </SoonaSelect>
      <SoonaSelect
        v-model:model-values="selectedProService"
        class="pack-reservation-summary__pro_services"
        :options="proServices"
        :multi="true"
        :disabled="editingDisabled"
      >
        <template #label> pro services </template>
      </SoonaSelect>
      <div class="pack-reservation-summary__credits">
        <SoonaSelect
          v-model:model-value="includedPhotos"
          :options="creditOptions"
          :disabled="editingDisabled"
        >
          <template #label>included photos</template>
        </SoonaSelect>
        <SoonaSelect
          v-model:model-value="includedVideos"
          :options="creditOptions"
          :disabled="editingDisabled"
        >
          <template #label>included videos</template>
        </SoonaSelect>
        <SoonaSelect
          v-model:model-value="includedGifs"
          :options="creditOptions"
          :disabled="editingDisabled"
        >
          <template #label>included gifs</template>
        </SoonaSelect>
      </div>
    </PackSubSection>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/_variables.scss';
@import 'src/_variables_fonts.scss';
.pack-reservation-summary {
  &__image-uploader,
  &__photo-quantity,
  &__pro_services {
    margin-bottom: 1rem;
  }

  &__credits {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 1rem;
  }
}
</style>

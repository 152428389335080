<template>
  <footer
    class="booking-footer"
    :class="{
      'booking-footer--scrolled': isUserScrolling,
      'booking-footer--sidecart': !noSidecart,
    }"
    :style="`z-index: ${zIndex};`"
  >
    <div class="u-container booking-footer__inner">
      <SoonaButton
        v-if="showBack"
        class="booking-footer__back"
        :on-click="backClicked"
        variation="tertiary"
        :data-cypress="cypressNameBack"
        @on-click="
          buttonClicked({
            context: 'booking',
            subContext: 'booking footer',
            buttonLabel: matchMediaIsWide ? `${backButtonCopy}` : `back`,
            buttonAction: 'backClicked',
          })
        "
      >
        <ArrowLeft class="booking-footer__back__icon" />
        {{ matchMediaIsWide ? backButtonCopy : 'back' }}
      </SoonaButton>
      <SoonaButton
        v-if="showNext"
        class="booking-footer__next"
        :disabled="!isStepValid"
        :loading="isLoading"
        :on-click="nextClicked"
        :data-cypress="cypressNameNext"
        @on-click="
          buttonClicked({
            context: 'booking',
            subContext: 'booking footer',
            buttonLabel: matchMediaIsWide ? `next: ${ctaText}` : `${ctaText}`,
            buttonAction: 'nextClicked',
          })
        "
      >
        {{ matchMediaIsWide ? 'next: ' + ctaText : ctaText }}
        <ArrowRightSmall
          v-if="!matchMediaIsWide"
          class="booking-footer__next__icon"
          fill="#fff"
        />
      </SoonaButton>
      <label for="booking-progress" class="u-visually-hidden">
        Booking progress:
      </label>
      <div
        class="booking-footer__progress"
        :class="{
          'booking-footer__progress--beginning': progressPercent === 0,
          'booking-footer__progress--complete': progressPercent === 100,
        }"
      >
        <progress id="booking-progress" max="100" :value="progressPercent">
          {{ progressPercent }}%
        </progress>
      </div>
    </div>
    <div class="booking-footer__toggles">
      <Transition name="toggle-slide-in">
        <button
          v-if="!noSidecart"
          type="button"
          class="u-button-reset booking-footer__toggle booking-footer__toggle--sidecart"
          @click="toggleSidecart"
        >
          Shoot Summary
          <SoonaIcon name="camera" />
        </button>
      </Transition>
      <HubSpotChatButton v-if="showHubSpotChat" />
    </div>
    <HubSpotChat v-if="showHubSpotChat" />
  </footer>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useShowHubSpotChat } from 'src/composables/useShowHubSpotChat.js';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import matchMediaMixin from 'src/mixins/matchMediaMixin';
import ArrowLeft from 'src/components/svgs/ArrowLeft.vue';
import ArrowRightSmall from 'src/components/svgs/ArrowRightSmall.vue';
import debounce from 'lodash/debounce';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useReservation } from '@/composables/useReservation';
import { useFlag } from '@/composables/useFlag';
import HubSpotChat from 'src/components/booking/v3/p2/HubSpotChat.vue';
import HubSpotChatButton from 'src/components/booking/v3/p2/HubSpotChatButton.vue';
import { useIntegrations } from '@/composables/useIntegrations';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  components: {
    ArrowLeft,
    ArrowRightSmall,
    HubSpotChat,
    HubSpotChatButton,
    SoonaButton,
    SoonaIcon,
  },
  mixins: [matchMediaMixin],
  props: {
    progressPercent: {
      required: true,
      default: 1,
      type: Number,
    },
    backButtonCopy: {
      default: 'back',
      required: false,
      type: String,
    },
    backClicked: {
      required: false,
      type: Function,
    },
    buttonText: {
      default: 'next',
      required: false,
      type: String,
    },
    cypressNameBack: {
      default: 'survey-back',
      required: false,
      type: String,
    },
    cypressNameNext: {
      default: 'survey-next',
      required: false,
      type: String,
    },
    isLoading: {
      default: false,
      required: false,
      type: Boolean,
    },
    isStepValid: {
      default: false,
      required: true,
      type: Boolean,
    },
    nextClicked: {
      required: true,
      type: Function,
      default() {
        return null;
      },
    },
    showBack: {
      default: true,
      required: false,
      type: Boolean,
    },
    showNext: {
      default: true,
      required: false,
      type: Boolean,
    },
    zIndex: {
      default: 8,
      required: false,
      type: Number,
    },
    noSidecart: {
      default: false,
      type: Boolean,
    },
    toggleSidecart: {
      required: true,
      type: Function,
    },
    reservationId: {
      required: true,
    },
  },
  setup(props) {
    const { isFree, isPack } = useReservation(
      computed(() => props.reservationId)
    );
    const { buttonClicked } = useBaseEvents();
    const hubSpotFlag = useFlag('hubspot_chat');
    const showHubSpotChat = useShowHubSpotChat();

    const store = useStore();
    const accountId = computed(() => store.state.currentUser?.accountId);

    const { hasShopifyIntegration } = useIntegrations(accountId);

    return {
      hubSpotFlag,
      isFree,
      isPack,
      buttonClicked,
      showHubSpotChat,
      hasShopifyIntegration,
    };
  },
  data() {
    return {
      handleDebouncedScroll: null,
      isUserScrolling: false,
    };
  },
  computed: {
    backClick() {
      return this.backClicked;
    },
    progressPercentWithSymbol() {
      return this.progressPercent + '%';
    },
    ctaText() {
      return this.buttonText;
    },
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100, {
      maxWait: 600,
      leading: true,
    });
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
  methods: {
    handleScroll() {
      this.isUserScrolling = window.scrollY > 20;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.booking-footer {
  background-color: variables.$white-default;
  border-top: 0.0625rem solid variables.$gray-30;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  transition:
    box-shadow 0.4s ease-out,
    right 0.3s ease-out,
    opacity 0.3s ease-out;

  &--scrolled {
    box-shadow: 1.25rem 0.3125rem 1rem rgba(0, 0, 0, 0.15);
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem;
    max-width: 43.75rem;
  }

  &__back {
    min-width: 0;
    min-height: 2.375rem;
    margin-left: -0.3125rem;
    text-decoration: none;

    &__icon {
      transition: transform 0.1s ease-out;
    }

    &:hover,
    &:focus-visible {
      color: variables.$black-default !important;

      .booking-footer__back__icon {
        transform: translateX(-0.1875rem);
      }
    }
  }

  &__next {
    margin-left: auto;
  }

  &__back,
  &__next {
    .booking-footer.fade-in-leave-active & {
      // prevent next and back buttons from being
      // re-clicked during footer transition in/out
      pointer-events: none;
    }
  }

  &__progress {
    position: relative;
    width: 100%;
    margin-top: 1.25rem;

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: -1px;
      top: 50%;
      transform: translateY(-50%);
      width: 0.5rem;
      height: 0.5rem;
      background-color: variables.$white-default;
      border: 2px solid variables.$gray-40;
      border-radius: 50%;
      transition:
        border-color 0.1s ease-out,
        background-color 0.1s ease-out;
    }

    &::before {
      right: auto;
      left: -1px;
      border-color: variables.$black-default;
      background-color: variables.$black-default;
    }

    &--beginning::before {
      background-color: variables.$white-default;
      border-color: variables.$gray-40;
    }

    &--complete::after {
      border-color: variables.$black-default;
      background-color: variables.$black-default;
    }
  }

  &__progress > progress {
    display: block;
    border: 0;
    height: 0.125rem;
    width: 100%;
    background-color: variables.$gray-30;
    overflow: hidden;
    --value: v-bind('progressPercentWithSymbol');

    &::-webkit-progress-bar {
      background-color: variables.$gray-30;
    }

    &::-webkit-progress-value {
      background-color: variables.$black-default;
      transition: width 0.6s;
    }

    &::-moz-progress-bar {
      background-color: variables.$black-default;
      transition: padding-bottom 0.6s;
      padding-bottom: var(--value);
      transform-origin: 0 0;
      transform: rotate(-90deg) translateX(-15px);
      padding-left: 15px;
      height: 0;
    }

    ::-ms-fill {
      background-color: variables.$black-default;
    }
  }

  &__toggles {
    position: absolute;
    bottom: 100%;
    right: 0;
    display: flex;
    border-top-left-radius: 0.625rem;
    transition:
      box-shadow 0.4s ease-out,
      right 0.3s ease-out;

    .booking-footer--scrolled & {
      box-shadow: 0px -5px 14px -10px rgba(0, 0, 0, 0.15);
    }
  }

  &__toggle {
    @include variables_fonts.u-small--heavy;

    display: flex;
    align-items: center;
    border: 1px solid variables.$gray-30;
    border-right: 0;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    letter-spacing: 1.5px;
    border-radius: 0;
    background-color: variables.$white-default;
    transition: background-color 0.1s ease-out;

    > svg {
      display: block;
      margin-left: 0.5rem;
    }

    &:first-child {
      border-top-left-radius: 0.625rem;
    }

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-20;
    }

    &--sidecart {
      transition: transform 0.3s ease-out;
    }
  }

  @media (min-width: 48rem) {
    &__inner {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }

  @media (min-width: 60rem) {
    &__toggle--sidecart {
      display: none;
    }
  }

  &--sidecart {
    @media (min-width: 60rem) {
      max-width: calc(100% - 22.125rem);
    }

    @media (min-width: 75rem) {
      max-width: calc(100% - 27.625rem);
    }
  }
}

.toggle-slide-in-enter-from,
.toggle-slide-in-leave-to {
  transform: translateX(100%);
}
</style>

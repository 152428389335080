<template>
  <div class="page-container-offset">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.page-container-offset {
  margin-left: -1rem;
  margin-right: -1rem;

  @media screen and (min-width: 48rem) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  @media screen and (min-width: 64rem) {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}
</style>

<script setup>
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  imageSrc: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    default: 'read the blog post',
  },
  to: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['link-clicked']);
</script>

<template>
  <div class="blog-post-link__wrapper">
    <div class="blog-post-link__image-and-text">
      <img :src="imageSrc" alt="" class="blog-post-link__image" />
      <div class="blog-post-link__title--wrapper">
        <a
          class="blog-post-link__title"
          :href="to"
          target="_blank"
          @click="emit('link-clicked')"
        >
          {{ title }}
        </a>
        <p class="blog-post-link__subtitle">{{ subtitle }}</p>
      </div>
    </div>
    <div class="blog-post-link__icon--wrapper">
      <SoonaIcon
        name="arrow-up-right-from-square"
        class="blog-post-link__icon"
        size="medium"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.blog-post-link {
  &__wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    padding: 0.75rem;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    background-color: variables.$bubbletape-pink-05;
    position: relative;

    &:hover {
      background-color: variables.$bubbletape-pink-10;
    }
  }

  &__image-and-text {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 0.75rem;
  }

  &__image {
    max-width: 3rem;
    min-height: 3rem;
    border-radius: 0.3125rem;
    align-self: stretch;
    object-fit: cover;
  }

  &__title {
    @include variables_fonts.u-body--heavy;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }

  &__subtitle {
    @include variables_fonts.u-label--regular;

    color: variables.$gray-60;
  }

  &__icon {
    color: variables.$gray-60;
  }
}
</style>

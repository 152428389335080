import { http } from '@/config/vue-axios';

/**
 *
 * @param {number} reservationId
 * @param {Object} rate
 */
export async function triggerShippingCheckoutStartedEvent(
  reservationId,
  rate,
  comped = false
) {
  const response = await http.post(
    `reservations/${reservationId}/shipping_details/shipping_checkout_started`,
    { rate, comped: comped }
  );
  return response.data;
}

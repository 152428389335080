import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {MaybeRef<string | number>} collectionId
 * @param {MaybeRef<string | number>} digitalAssetId
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useStaffPicksCollectionDigitalAssetByDigitalAssetId(
  collectionId,
  digitalAssetId,
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.collectionAssetByDigitalAsset(
      collectionId,
      'staff_picks_collection',
      digitalAssetId
    ),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/collections/${toValue(collectionId)}/staff_picks_collection_digital_assets/by_digital_asset/${toValue(digitalAssetId)}`,
        {
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

<script setup>
import { computed, provide, ref } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { usePriorityError } from '@/composables/usePriorityError';
import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_BACK_TEXT,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useReservation } from '@/composables/useReservation';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import { useEditsCollectionDigitalAssetByDigitalAssetId } from '@/queries/edits_collection_digital_assets/useEditsCollectionDigitalAssetByDigitalAssetId';
import EditDetailsCarousel from '@/components/user/anytime/reservation/crew_edits/EditDetailsCarousel.vue';
import EditorsMediaEditor from '@/components/user/anytime/gallery/media-editor/editors/EditorsMediaEditor.vue';

const props = defineProps({
  isChatOpen: {
    type: Boolean,
  },
  userHasScrolledPastBreakpoint: {
    type: Boolean,
  },
  assetContext: {
    type: String,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [String, Number],
    required: true,
  },
  filter: {
    type: String,
    required: false,
  },
});

const router = useRouter();

const reservationId = computed(() => props.reservationId);
const digitalAssetId = computed(() => props.digitalAssetId);

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const { nameTruncated, reservationAccountId } = useReservation(reservationId);
const { data: editsCollection } = useEditsCollection(reservationAccountId, {
  enabled: computed(() => !!reservationAccountId.value),
});
const editsCollectionId = computed(() => editsCollection.value?.id);

const filterLookup = {
  all: {},
  delivered: {
    edit_status: 'accepted',
    visibility: 'all',
  },
  pending: {
    edit_status: 'pending',
  },
  rejected: {
    edit_status: 'rejected',
  },
  're-edit': {
    edit_status: 'customer_rejected',
  },
  unlinked: {
    missing_original: 1,
  },
  hidden: {
    edit_status: 'accepted',
    visibility: 'crew',
  },
};

const filter = computed(() => props.filter);

const filters = computed(() => {
  const selectedFilter = props.filter ?? 'all';

  return {
    reservation_id: reservationId.value,
    origin: 'soona',
    ...filterLookup[selectedFilter],
  };
});

const {
  data: editsCollectionDigitalAsset,
  isLoading: isFileLoading,
  error,
} = useEditsCollectionDigitalAssetByDigitalAssetId(
  editsCollectionId,
  digitalAssetId,
  { filters },
  {
    enabled: computed(() => !!editsCollectionId.value),
  }
);

const priorityError = usePriorityError(error);

// routes
const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'crew-edits-asset-view',
    params: {
      digitalAssetId: id,
      reservationId: reservationId.value,
    },
    query: {
      filter: filter.value,
    },
  };
};

const backUrl = computed(() => {
  return filter.value
    ? `/reservation/${reservationId.value}/crew/edits?filter=${filter.value}`
    : `/reservation/${reservationId.value}/crew/edits`;
});

const previousRoute = computed(() => {
  return createRouteLocation(
    editsCollectionDigitalAsset.value?.previous?.digital_asset_id
  );
});

const nextRoute = computed(() => {
  return createRouteLocation(
    editsCollectionDigitalAsset.value?.next?.digital_asset_id
  );
});

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, previousRoute);
provide(MEDIA_POPOVER_NEXT_KEY, nextRoute);
provide(
  MEDIA_POPOVER_BACK_TEXT,
  computed(() => `in ${nameTruncated.value}`)
);

// redirect back to gallery
const isRedirecting = ref(false);
const goToGallery = () => {
  isRedirecting.value = true;
  router.push(backUrl.value);
};
</script>
<template>
  <EditorsMediaEditor
    v-if="!isRedirecting"
    :asset="editsCollectionDigitalAsset?.digital_asset"
    :edits-collection-digital-asset="editsCollectionDigitalAsset"
    :is-file-loading="isFileLoading"
    @close="goToGallery"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #carousel>
      <EditDetailsCarousel
        v-if="matchMediaIsWide && editsCollection?.id"
        :edits-collection-id="editsCollection?.id"
        :reservation-id="reservationId"
        :active-asset-id="editsCollectionDigitalAsset?.digital_asset?.id"
        title="reservation crew edits"
        :selected-filter="filter"
        :filters="filters"
      />
    </template>
  </EditorsMediaEditor>
</template>

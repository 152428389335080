<script setup>
import { toRefs } from 'vue';

import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

import ResizerListItem from '@/components/user/anytime/gallery/media-editor/media-multiplier/ResizerListItem.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: true,
  },
  isMobileView: {
    type: Boolean,
    default: false,
  },
  hasFreemiumAccess: {
    type: Boolean,
    default: true,
  },
  socialMedias: {
    type: Array,
    default: null,
  },
  selectedSocialMedia: {
    type: String,
    required: false,
    default: '',
  },
});

const emits = defineEmits(['select']);

const {
  isLoading,
  isMobileView,
  hasFreemiumAccess,
  socialMedias,
  selectedSocialMedia,
} = toRefs(props);

const route = useRoute();
const { linkClicked } = useBaseEvents();

const mediaEditorStore = useMediaEditorStore();

const handleSocialMediaClick = ({ name, old_name = '' }) => {
  if (hasFreemiumAccess.value) {
    mediaEditorStore.setShowPaywallDialog(true);
    return;
  }

  linkClicked({
    context: route.meta.context,
    subContext: 'media editor resize',
    linkLabel: `${name}${old_name ? `(${old_name})` : ''}`,
    linkHref: null,
  });

  emits('select', { name });
};
</script>

<template>
  <div class="social-medias">
    <SoonaSkeleton v-if="isLoading" is-loading class="social-medias__loading" />
    <template v-else>
      <h5 v-if="!isMobileView" class="u-label--regular">
        sizing options for platforms
      </h5>
      <div
        v-if="hasFreemiumAccess"
        class="social-medias__paywalled"
        :class="{ 'social-medias__paywalled--desktop': !isMobileView }"
      >
        <span class="u-subheader--heavy">platform-specific resizing</span>
        <span class="u-body--regular">
          create your listing images, social media crops, and more
        </span>
        <SoonaButton
          variation="pizzazz"
          size="medium"
          @on-click="() => mediaEditorStore.setShowPaywallDialog(true)"
        >
          unlock tools
        </SoonaButton>
      </div>
      <ul
        v-if="!hasFreemiumAccess || (hasFreemiumAccess && !isMobileView)"
        class="social-medias--list"
      >
        <li v-for="socialMedia in socialMedias" :key="socialMedia.name">
          <ResizerListItem
            :icon="`${socialMedia.name.toLowerCase()}-logo`"
            :class="selectedSocialMedia === socialMedia.name && 'active'"
            :tabindex="hasFreemiumAccess ? '-1' : '0'"
            @click="handleSocialMediaClick(socialMedia)"
          >
            {{
              `${socialMedia.name}${
                socialMedia.old_name ? ` (${socialMedia.old_name})` : ''
              }`
            }}
          </ResizerListItem>
        </li>
      </ul>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.social-medias {
  position: relative;

  &__loading {
    height: 10rem;
  }

  &__paywalled {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(
      180deg,
      variables.$white-translucent-50 0%,
      rgba(255, 255, 255, 0.9) 79.37%
    );
    backdrop-filter: blur(0.3125rem);
    left: 0;

    height: calc(100% - 2.625rem);
    width: 100%;
    gap: 0.5rem;
    text-align: center;

    &--desktop {
      position: absolute;
      justify-content: center;
      top: 2.625rem;
    }

    > div {
      display: flex;
      padding: 0.25rem 0.5rem;
      margin-bottom: 0.5rem;
      align-items: center;
      gap: 0.25rem;
      color: variables.$white-default;
      border-radius: 3.125rem;
      background: var(
        --gradients-button,
        linear-gradient(
          63deg,
          variables.$periwink-blue-70 2.88%,
          variables.$roses-60 98.95%
        )
      );

      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  h5 {
    color: variables.$gray-60;
    margin: 0.75rem 0;
  }

  &--list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-bottom: 1rem;
  }
}
</style>

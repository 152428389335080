<template>
  <div class="admin-create-trend-set-button">
    <SoonaButton data-cypress="button-create-trend-set" @click="openModal">
      <SoonaIcon name="plus-large" /> add a new trend set
    </SoonaButton>
    <AdminCreateTrendSetModal
      v-if="showModal"
      :close-modal="closeModal"
      data-cypress="modal-create-trend-set"
    />
  </div>
</template>

<script>
import AdminCreateTrendSetModal from './AdminCreateTrendSetModal.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'AdminCreateTrendSetButton',
  components: {
    AdminCreateTrendSetModal,
    SoonaButton,
    SoonaIcon,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

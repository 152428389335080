<script setup>
import { computed } from 'vue';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useUpdateCollaborator } from '@/queries/collaborators/useUpdateCollaborator';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  collaborator: {
    type: Object,
    required: true,
  },
  collaboratorRoleId: {
    type: Number,
    required: true,
  },
  collaboratorRoleName: {
    type: String,
    required: true,
  },
});

defineEmits(['close']);

const accountId = computed(() => Number(props.accountId));
const collaboratorId = computed(() => props.collaborator.id);
const roleName = computed(() => props.collaboratorRoleName);

const {
  mutate,
  isPending: isUpdateCollaboratorLoading,
  error: updateCollaboratorError,
} = useUpdateCollaborator(accountId, collaboratorId);

const formattedRole = computed(() => roleName.value?.replace('account ', ''));

const textLookup = {
  admin: 'as an admin, this user will gain the ability to:',
  collaborator:
    'as a collaborator, this user will no longer have the ability to:',
};
const adminAbilities = [
  'change payment method or make subscription changes',
  'change collaborator roles',
  'remove collaborators',
  'change account owner',
];

function changeRole(close) {
  mutate(
    {
      role_id: props.collaboratorRoleId,
    },
    { onSuccess: () => close() }
  );
}

const priorityError = usePriorityError(updateCollaboratorError);
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>make {{ formattedRole }}</template>
    <template #default>
      <div class="edit-collaborator-dialog__user">
        <SoonaAvatar
          :name="collaborator.name"
          size="3rem"
          :src="collaborator.avatar_url"
          type="user"
        />
        <div class="edit-collaborator-dialog__info">
          <p class="u-body--heavy">
            {{ collaborator.name }}
          </p>
          <p class="u-small--regular">
            {{ collaborator.email }}
          </p>
        </div>
      </div>
      <div class="edit-collaborator-dialog__alert">
        <p class="u-body--heavy">
          {{ textLookup[formattedRole] }}
        </p>
        <ul class="edit-collaborator-dialog__alert--abilities">
          <li
            v-for="ability in adminAbilities"
            :key="ability"
            class="edit-collaborator-dialog__alert--ability"
          >
            {{ ability }}
          </li>
        </ul>
      </div>
      <SoonaError v-if="priorityError">
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        variation="primary"
        :loading="isUpdateCollaboratorLoading"
        @on-click="changeRole(close)"
      >
        make {{ formattedRole }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.edit-collaborator-dialog {
  &__user {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__alert {
    padding: 0.75rem;
    border-radius: 0.3125rem;
    border: 0.0625rem solid variables.$tangerine-40;
    background-color: variables.$tangerine-10;
    gap: 0.5rem;

    &--abilities {
      padding: 1rem 1.5rem;
    }

    &--ability {
      list-style: disc;
    }
  }
}
</style>

<script setup>
import {
  amazonAuthorizationRedirectUrl,
  shopifyAppUrl,
} from '@/lib/integrations/constants';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import IntegrateAmazonShopify from 'images/listing-insights/integrate-amazon-shopify.svg';
import IntegrateAmazon from 'images/competitive-analysis/integrate-amazon.svg';

defineProps({
  amazonOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);
</script>

<template>
  <SoonaDialog @close="emit('close')">
    <template #heading>connect accounts</template>
    <template #default>
      <div class="integrate-accounts-dialog--icon-section">
        <img
          class="integrate-accounts-dialog--icon"
          :src="amazonOnly ? IntegrateAmazon : IntegrateAmazonShopify"
          alt=""
        />
      </div>
      <div class="integrate-accounts-dialog--copy">
        <h3 class="u-subheader--heavy integrate-accounts-dialog--subheader">
          how it works
        </h3>
        <ul class="integrate-accounts-dialog--list">
          <li class="u-body--regular">
            <SoonaIcon name="grid-plus" />

            <p>
              <span class="u-body--heavy">connect</span> <br />

              integrate with {{ amazonOnly ? 'Amazon' : 'Amazon or Shopify' }}
              in a few clicks to import listings and publish content directly
              from soona.
            </p>
          </li>
          <li class="u-body--regular">
            <SoonaIcon name="chart-simple" />

            <p>
              <span class="u-body--heavy">
                get insights
                <template v-if="amazonOnly">
                  + compare with competitors
                </template>
              </span>
              <br />

              evaluate visual content + receive targeted creative
              recommendations.
            </p>
          </li>
          <li class="u-body--regular">
            <SoonaIcon name="lock-alt" />

            <p>
              <span class="u-body--heavy">your data is yours</span>
              <br />

              soona does not share your data, and you can choose to delete it at
              any time.
            </p>
          </li>
        </ul>
        <p class="integrate-accounts-dialog--help-link-copy">
          to learn more about integrations
          <a
            class="integrate-accounts-dialog--link"
            href="https://support.soona.co/integrate-with-soona"
            target="_blank"
            >visit our help page</a
          >
        </p>
        <div class="integrate-accounts-dialog--buttons">
          <SoonaButton
            element="a"
            :href="amazonAuthorizationRedirectUrl"
            variation="secondary-gray"
            class="integrate-accounts-dialog--cta-button"
          >
            <SoonaIcon name="amazon-logo" />
            connect Amazon
            <SoonaIcon name="arrow-right" />
          </SoonaButton>
          <SoonaButton
            v-if="!amazonOnly"
            element="a"
            :href="shopifyAppUrl"
            variation="secondary-gray"
            size="large"
            class="integrate-accounts-dialog--cta-button"
          >
            <SoonaIcon name="shopify-logo" />
            connect Shopify
            <SoonaIcon name="arrow-right" />
          </SoonaButton>
        </div>
      </div>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.integrate-accounts-dialog {
  &--icon-section {
    border-radius: 0.625rem;
    background: linear-gradient(90deg, #5566ea 0%, #93a2f8 100%);
    text-align: center;
    padding: 1rem 0;
    margin-bottom: 1.5rem;
  }

  &--subheader {
    margin-bottom: 1.5rem;
  }

  &--list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 1rem;
    flex-direction: column;
    margin-bottom: 1.5rem;

    li {
      display: flex;
      gap: 1rem;
      align-items: flex-start;

      svg {
        min-height: 1.5rem;
        min-width: 1.5rem;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  &--help-link-copy {
    margin-bottom: 2rem;
  }

  &--link {
    border-bottom: 1px solid variables.$black-default;
  }

  &--buttons {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
  }

  &--cta-button {
    flex: 1 auto;
  }
}
</style>

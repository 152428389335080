export function parseResponseStream(data) {
  if (data.object == 'thread.run' && data.status == 'requires_action') {
    return {
      event: 'thread.run.requires_action',
      runId: data.id,
      toolCalls: data.required_action.submit_tool_outputs.tool_calls,
    };
  }

  switch (data.object) {
    case 'thread.message':
      if (data.status == 'completed') {
        return {
          event: 'thread.message.completed',
        };
      } else if (data.status == 'in_progress') {
        return {
          event: 'thread.message.in_progress',
        };
      }
      break;

    case 'thread.message.delta':
      return {
        event: 'thread.message.delta',
        text: data.delta.content[0].text.value,
      };

    default:
      return {
        event: 'noop',
      };
  }
}

export function isDuplicateEvent(prev, curr) {
  if (!curr.created_at || !curr.object || !curr.status) return;
  return (
    prev.object === curr.object &&
    prev.created_at === curr.created_at &&
    prev.status === curr.status
  );
}

import { toValue } from 'vue';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';

/**
 * @param {import('vue').MaybeRefOrGetter<number | string>} reservationId
 * @param [queryOptions]
 */
export function useReservationCopy(reservationId, queryOptions = undefined) {
  return useMutation({
    mutationFn: async body => {
      const response = await http.post(
        `/reservations/${toValue(reservationId)}/copy`,
        body
      );

      return response.data;
    },
    ...queryOptions,
  });
}

/**
 * @param {import('vue').MaybeRefOrGetter<number | string>} reservationId
 * @param [queryOptions]
 */
export function useReservationCopyable(
  reservationId,
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.reservationCopyable(reservationId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/reservations/${toValue(reservationId)}/copyable`,
        { signal }
      );
      return response.status === 204;
    },
    // expected to return an error if the reservation is not copyable
    retry: 0,
    ...queryOptions,
  });
}

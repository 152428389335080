import * as types from 'src/store/mutation-types';

const state = {
  errors: undefined,
};

const getters = {
  getErrorMessage:
    ({ errors }) =>
    key => {
      if (key === 'errors') {
        let key2 = Object.keys(errors[key])[0];
        if (key2 === 'accounts') {
          return errors[key][key2][0];
        }
        return `${key2.charAt(0).toUpperCase() + key2.slice(1)} ${
          errors[key][key2]
        }.`;
      } else if (key === 'error') {
        return errors.error;
      } else if (key === 'email') {
        if (errors[key][0].error === 'taken') {
          return 'There is already an account with this email address. Please sign in or use a different email.';
        } else if (errors[key][0].error === 'blank') {
          return 'The email field is required.';
        }
      } else if (key === 'password') {
        if (errors[key][0].error === 'blank') {
          return 'The password field is required.';
        }
      } else if (key === 'name') {
        if (errors[key][0].error === 'blank') {
          return 'The name field is required.';
        }
      } else if (
        key === 'coupon' &&
        errors[key][0].error === 'invalid coupon'
      ) {
        return 'This coupon code is invalid.';
      } else if (
        key === 'coupon' &&
        errors[key][0].error === 'coupon already applied'
      ) {
        return 'A coupon has already been applied.';
      } else if (
        ['incomplete_number', 'incomplete_expiry', 'incomplete_cvc'].includes(
          key
        )
      ) {
        return errors[key][0].message;
      } else if (key === 'incomplete_zip') {
        return 'Your zip code is incomplete.';
      } else if (key == 'incorrect_cvc') {
        return 'Incorrect card information, please double check your info and try again';
      } else if (key == 'message') {
        return errors[key];
      }

      console.error(`An unknown error occurred for key: ${key}`, errors);
      return 'An unknown error occurred.';
    },
  getErrorMessages({ errors }, getters) {
    if (!errors) {
      return undefined;
    }
    return Object.keys(errors).map(key => {
      return getters.getErrorMessage(key);
    });
  },
};

const mutations = {
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [types.RESET_ERRORS](state) {
    state.errors = undefined;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

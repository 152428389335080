<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { amazonAuthorizationRedirectUrl } from '@/lib/integrations/constants';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  accountId: { type: [Number, String], required: true },
  integrationId: { type: [Number, String] },
  listingCount: { type: Number, required: false },
  listingLabel: { type: String, required: false },
  size: {
    type: String,
    required: false,
    default: 'medium',
    validator: function (value) {
      return ['small', 'medium'].includes(value);
    },
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  hasValidIntegration: {
    type: Boolean,
    required: true,
  },
});

const route = useRoute();

const { linkClicked } = useBaseEvents();

const trackLinkClicked = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'store overview',
    linkLabel: props.listingLabel,
    linkHref: `/user/anytime#/account/listing-insights/integration/store-list`,
  });
};

const classNames = computed(() => {
  return `listing-breakdown-card__rating listing-breakdown-card__rating--${props.listingLabel} listing-breakdown-card__rating--${props.size} u-button--small-caps`;
});

const iconLookup = {
  poor: 'triangle-exclamation',
  okay: 'circle-partial',
  great: 'circle-check',
};

const storeListRedirectTo = computed(() => {
  if (props.integrationId) {
    return `/user/anytime#/account/${props.accountId}/listing-insights/integration/${props.integrationId}/store-list?score_category=${props.listingLabel}`;
  }

  return `/user/anytime#/account/${props.accountId}/listing-insights/integration/store-list?score_category=${props.listingLabel}`;
});

const href = computed(() =>
  props.hasValidIntegration
    ? storeListRedirectTo.value
    : `${amazonAuthorizationRedirectUrl}?soona_redirect_to=${route.path}`
);
</script>

<template>
  <SoonaSkeleton
    v-if="props.isLoading"
    :class="`listing-breakdown-card__skeleton listing-breakdown-card__skeleton--${props.size}`"
  />
  <div
    v-else
    :class="`listing-breakdown-card listing-breakdown-card--${props.size}`"
  >
    <div :class="classNames">
      <SoonaIcon :name="iconLookup[props.listingLabel]" size="medium" />
      {{ props.listingLabel }}
    </div>
    <div :class="`listing-breakdown-card__count--${props.size} u-title--heavy`">
      <a
        class="listing-breakdown-card__name"
        :href="href"
        @click="trackLinkClicked"
      >
        {{ listingCount ?? 0 }} listing{{ listingCount === 1 ? '' : 's' }}
        <SoonaIcon
          class="listing-breakdown-card__arrow-icon"
          name="arrow-right"
          size="medium"
        />
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-breakdown-card {
  flex: 1 0 0;
  border-radius: 0.626rem;
  border: 0.0625rem solid variables.$gray-30;
  box-shadow: variables.$elevation-0;
  position: relative;

  &--small {
    align-items: center;
    background-color: variables.$white-default;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-10;
    }
  }

  &--medium {
    background-color: variables.$gray-10;
    padding: 0.75rem 0;
  }

  &__rating {
    display: flex;
    align-items: center;
    width: 6.75rem;
    padding: 0.75rem 1.5rem 0.75rem 1rem;
    gap: 0.25rem;

    svg {
      flex-shrink: 0;
    }

    &--poor {
      background: linear-gradient(
        360deg,
        variables.$gradient-red-start 0%,
        variables.$gradient-red-end 100%
      );
    }

    &--okay {
      background: linear-gradient(
        360deg,
        variables.$gradient-gold-start 0%,
        variables.$gradient-gold-end 100%
      );
    }

    &--great {
      background: linear-gradient(
        360deg,
        variables.$gradient-green-start 0%,
        variables.$gradient-green-end 100%
      );
    }

    &--small {
      border-radius: 1.25rem 3rem 3rem 1.25rem;
      height: 2.625rem;
    }

    &--medium {
      border-radius: 0 3rem 3rem 0;
      height: 2.75rem;
    }
  }

  &__count {
    &--small {
      flex-grow: 1;
      padding-right: 1rem;
    }
    &--medium {
      padding: 0 1rem;
      margin: 1.5rem 0 0 0;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    &:hover,
    &:focus-visible {
      color: variables.$black-default;
    }
  }

  &__arrow-icon {
    transition: transform 0.1s ease-out;
  }

  &:hover,
  &:focus-visible {
    .listing-breakdown-card__arrow-icon {
      transform: translateX(0.1875rem);
    }
  }

  &__skeleton {
    flex: 1 1 0;
    min-width: 7rem;
    border-radius: 0.625rem;

    &--medium {
      height: 7.625rem;
    }
  }
}
</style>

<script setup>
import { computed, ref, toRefs, watch } from 'vue';
import { refDebounced } from '@vueuse/core';
import { useListings } from '@/queries/useListings';
import { useExampleListings } from '@/queries/useExampleListings';
import PaginatorSmall from '@/components/PaginatorSmall.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { keepPreviousData } from '@tanstack/vue-query';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  integrationId: {
    type: [Number, String],
    default: null,
  },
  search: {
    type: String,
    required: true,
  },
  filter: {
    type: String,
    default: null,
  },
  orderBy: {
    type: String,
    default: 'name',
  },
});

const {
  accountId,
  integrationId,
  search,
  filter: filterPropValue,
  orderBy: orderByValue,
} = toRefs(props);

const searchDebounced = refDebounced(search, 200);

const currentPage = ref(1);

const itemsPerPage = ref(20);

watch([searchDebounced], () => {
  currentPage.value = 1;
});

const filterProp = computed(() => {
  if (filterPropValue.value) {
    return filterPropValue.value;
  }
  return null;
});

const listingsDataIsEnabled = computed(() => !!integrationId.value);

// TO DO: get listings based on account instead of integration
const {
  data: listingsData,
  error: listingsError,
  isLoading: listingsLoading,
  isSuccess: listingsSuccess,
} = useListings(
  accountId,
  {
    integrationId,
    query: searchDebounced,
    filter: filterProp,
    orderBy: orderByValue,
    direction: 'asc',
    currentPage,
    itemsPerPage,
  },
  {
    placeholderData: keepPreviousData,
    enabled: listingsDataIsEnabled,
  }
);

const exampleListingsDataIsEnabled = computed(
  () => !integrationId.value || !listingsData.value?.listings.length
);

const {
  data: exampleListingsData,
  error: exampleListingsError,
  isLoading: exampleListingsLoading,
} = useExampleListings(
  accountId,
  { platform: 'amazon' },
  {
    enabled: exampleListingsDataIsEnabled,
  }
);

const listings = computed(() => {
  if (
    !integrationId.value ||
    (integrationId.value &&
      listingsSuccess.value &&
      !listingsData.value?.listings.length)
  ) {
    return exampleListingsData.value?.listings ?? [];
  }
  return listingsData.value?.listings ?? [];
});

const paginationData = computed(() =>
  integrationId.value
    ? listingsData.value?.pagination
    : exampleListingsData.value?.pagination
);

const listingsPagination = computed(() =>
  paginationData.value
    ? paginationData.value
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

const isLoading = computed(
  () => listingsLoading.value || exampleListingsLoading.value
);

const priorityError = usePriorityError(listingsError, exampleListingsError);
</script>

<template>
  <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
  <div v-if="listings.length > 0" class="listing-picker__options">
    <slot name="listing-options" :listings="listings" />
  </div>
  <PaginatorSmall
    class="listing-picker__paginator"
    :page="listingsPagination.currentPage"
    :pages="listingsPagination.totalPages"
    @page-change="currentPage = $event"
  />
  <p
    v-if="!isLoading && listings.length < 1"
    class="listing-picker__no-results"
  >
    no results
  </p>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.listing-picker {
  &__no-results {
    @include variables_fonts.u-body--regular;

    margin: 4rem auto;
    text-align: center;
    color: variables.$gray-60;
  }
  &__options {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &__paginator:not(:last-child) {
    margin-bottom: 1rem;
  }
}
</style>

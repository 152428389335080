<script setup>
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import CrewAssignmentModal from 'src/components/user/anytime/crew/CrewAssignmentModal.vue';
import { useGetCrewMember } from 'src/queries/useGetCrewMember';
import { ref, computed } from 'vue';

const props = defineProps({
  reservation: {
    required: true,
    type: Object,
  },
  selection: {
    required: true,
    type: Object,
  },
  proServiceRequirement: {
    required: true,
    type: Object,
  },
});
const selectionUserId = computed(() => props.selection.user_id);

const { data: crew_member } = useGetCrewMember(selectionUserId);

const crewAssignmentModalVisible = ref(false);

function openCrewAssignmentModal() {
  crewAssignmentModalVisible.value = true;
}

function closeCrewAssignmentModal() {
  crewAssignmentModalVisible.value = false;
}

function viewAssignments() {
  window.open(
    `/user/anytime#/account/${props.selection.account_id}/assignments`
  );
}

const currentDropDownOptions = computed(() => {
  return [
    {
      click: openCrewAssignmentModal,
      text: 'assign model',
      key: 'assign_model',
    },
    {
      click: viewAssignments,
      text: 'view assignments',
      key: 'view_assignments',
    },
  ];
});
</script>
<template>
  <div class="selection-dropdown-provider">
    <SoonaDropdownMenu variation="secondary-black" copy="model options">
      <template #default="{ keydown, mouseover }">
        <SoonaDropdownMenuItem
          v-for="item in currentDropDownOptions"
          :key="item.key"
        >
          <button
            role="menuitem"
            @click="item.click"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            {{ item.text }}
          </button>
        </SoonaDropdownMenuItem>
      </template>
    </SoonaDropdownMenu>
    <CrewAssignmentModal
      v-if="crewAssignmentModalVisible"
      :modal-visible="crewAssignmentModalVisible"
      :close-modal="closeCrewAssignmentModal"
      :assign-crew-directly="true"
      :reservation-start="reservation.start"
      :reservation-end="reservation.end"
      :reservation-id="reservation.id"
      :prefilled-pro-service-profile-id="selection.pro_service_profile_id"
      :prefilled-user="crew_member"
      :prefilled-reservation-service="{
        title: proServiceRequirement.numbered_title,
        role: proServiceRequirement.selected_tags[0].title,
        id: proServiceRequirement.id,
      }"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.selection-dropdown-provider {
  display: flex;
  justify-content: center;
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import useActionBarMediaQuery from '@/components/user/anytime/reservation/gallery/digital_asset_multiselect/useActionBarMediaQuery';

const props = defineProps({
  actionBarIsVisible: {
    type: Boolean,
    default: false,
  },
  chatIsOpen: {
    type: Boolean,
    default: false,
  },
  userHasScrolledPastBreakpoint: {
    type: Boolean,
    required: true,
  },
});

const scrollToTop = () => {
  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  });
};

const { actionBarIsMobile } = useActionBarMediaQuery();

const toTopButtonBulkSelectOffset = computed(
  () => props.actionBarIsVisible && actionBarIsMobile.value
);
</script>

<template>
  <button
    v-if="!chatIsOpen"
    class="gallery-view__to-top-btn"
    :class="{
      'gallery-view__to-top-btn--active': userHasScrolledPastBreakpoint,
      'gallery-view__to-top-btn--bulk-select-offset':
        toTopButtonBulkSelectOffset,
    }"
    @click="scrollToTop"
  >
    <SoonaIcon name="arrow-up" />
    <span class="u-visually-hidden">back to top</span>
  </button>
</template>

<style lang="scss" scoped>
@use '@/variables';

.gallery-view {
  &__to-top-btn {
    align-items: center;
    background-color: variables.$periwink-blue-50;
    border-radius: 50%;
    bottom: 6.75rem;
    cursor: pointer;
    display: flex;
    height: 3rem;
    justify-content: center;
    opacity: 0;
    padding: 0;
    position: fixed;
    right: 1.5625rem;
    transition: 0.2s;
    visibility: hidden;
    width: 3rem;
    z-index: 5;

    &:hover {
      background-color: variables.$periwink-blue-60;
    }

    &--active {
      opacity: 1;
      visibility: visible;
    }

    &--bulk-select-offset {
      bottom: 9.25rem;
    }

    svg {
      color: variables.$white-default;
      height: 1.625rem;
      width: 1.625rem;
    }

    @media (min-width: variables.$screen-md-min) {
      height: 3.25rem;
      width: 3.25rem;

      svg {
        height: 1.875rem;
        width: 1.875rem;
      }
    }
  }
}
</style>

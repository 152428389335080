<script setup>
import { computed, provide, ref, toRefs } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { usePriorityError } from '@/composables/usePriorityError';
import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_BACK_TEXT,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import MediaEditor from '@/components/user/anytime/gallery/media-editor/MediaEditor.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useStaffPicksCollection } from '@/queries/staff_picks_collection/useStaffPicksCollection';
import { useStaffPicksCollectionDigitalAssetByDigitalAssetId } from '@/queries/staff_picks_collection_digital_assets/useStaffPicksCollectionDigitalAssetByDigitalAssetId';
import truncate from 'lodash/truncate';
import StaffPicksCollectionAssetCarousel from './StaffPicksCollectionAssetCarousel.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  digitalAssetId: {
    type: [String, Number],
    required: true,
  },
});

const router = useRouter();

const { accountId, digitalAssetId } = toRefs(props);

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const { data: staffPicksCollection, error: collectionError } =
  useStaffPicksCollection(accountId);
const staffPicksCollectionId = computed(() => staffPicksCollection.value?.id);

const {
  data: staffPicksCollectionDigitalAsset,
  isLoading: isFileLoading,
  error: staffPicksCollectionDigitalAssetError,
} = useStaffPicksCollectionDigitalAssetByDigitalAssetId(
  staffPicksCollectionId,
  digitalAssetId,
  {
    enabled: computed(() => !!staffPicksCollectionId.value),
  }
);
const priorityError = usePriorityError(
  collectionError,
  staffPicksCollectionDigitalAssetError
);

// routes
const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'expert-picks-assets-media-view',
    params: {
      accountId: accountId.value,
      digitalAssetId: id,
    },
  };
};

const backUrl = computed(() => {
  return `/account/${accountId.value}/gallery/expert-picks`;
});

const previousRoute = computed(() => {
  return createRouteLocation(
    staffPicksCollectionDigitalAsset.value?.previous?.digital_asset_id
  );
});

const nextRoute = computed(() => {
  return createRouteLocation(
    staffPicksCollectionDigitalAsset.value?.next?.digital_asset_id
  );
});

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, previousRoute);
provide(MEDIA_POPOVER_NEXT_KEY, nextRoute);
provide(
  MEDIA_POPOVER_BACK_TEXT,
  computed(() =>
    staffPicksCollection.value?.title
      ? `in ${truncate(
          staffPicksCollection.value?.title.replace(
            /^staff picks$/,
            'expert picks'
          ),
          { length: 50 }
        )}`
      : null
  )
);

// redirect back to gallery
const isRedirecting = ref(false);
const goToGallery = () => {
  isRedirecting.value = true;
  router.push(backUrl.value);
};
</script>

<template>
  <MediaEditor
    v-if="!isRedirecting"
    :asset="staffPicksCollectionDigitalAsset?.digital_asset"
    :is-asset-loading="isFileLoading"
    @close="goToGallery"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #carousel>
      <StaffPicksCollectionAssetCarousel
        v-if="matchMediaIsWide && staffPicksCollectionId"
        :staff-picks-collection-id="staffPicksCollectionId"
        :album-title="staffPicksCollection?.title"
        :active-asset-id="staffPicksCollectionDigitalAsset?.digital_asset?.id"
      />
    </template>
  </MediaEditor>
</template>

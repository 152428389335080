<script setup>
import { computed, ref } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { usePriorityError } from '@/composables/usePriorityError';
import { useQueryClient } from '@tanstack/vue-query';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';
import { useUpdateEditsCollectionDigitalAsset } from '@/queries/edits_collection_digital_assets/useUpdateEditsCollectionDigitalAsset';
import { useUploader } from '@/composables/useUploader.js';
import OverlayDropdown from '@/components/infinite_asset_gallery/OverlayDropdown.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaUploadDragAndDropModal from '@/components/uploader/SoonaUploadDragAndDropModal.vue';
import SoonaUploadProgressModal from '@/components/uploader/SoonaUploadProgressModal.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  asset: {
    required: true,
    type: Object,
  },
  enableReview: {
    required: true,
    type: Boolean,
  },
  orderId: {
    required: true,
    type: [Number, String],
  },
});

const emit = defineEmits(['hide-feedback', 'show-feedback']);

const queryClient = useQueryClient();

const accountId = computed(() => props.accountId);
const asset = computed(() => props.asset);

// make pending & reject edit
const orderId = computed(() => props.orderId);

const editsCollectionDigitalAsset = computed(
  () => asset.value?.edits_collection_digital_asset
);
const editsCollectionId = computed(
  () => editsCollectionDigitalAsset.value?.collection_id
);

const {
  mutate: updateEditsCollectionDigitalAsset,
  isPending: isUpdatingEditsCollectionDigitalAsset,
  error: updateEditsCollectionDigitalAssetError,
} = useUpdateEditsCollectionDigitalAsset(accountId, editsCollectionId);

const bustCache = async () => {
  await queryClient.invalidateQueries({
    queryKey: queryKeys.orderAddOns(orderId),
  });
};

const makePending = () => {
  updateEditsCollectionDigitalAsset(
    {
      cdaId: editsCollectionDigitalAsset.value?.id,
      body: { edit_status: 'pending' },
    },
    { onSuccess: bustCache }
  );
};

const confirmReject = () => {
  emit('hide-feedback');

  updateEditsCollectionDigitalAsset(
    {
      cdaId: editsCollectionDigitalAsset.value?.id,
      body: { edit_status: 'rejected' },
    },
    { onSuccess: bustCache }
  );
};

const reject = () => {
  if (asset.value.auto_edited) emit('show-feedback');
  else confirmReject();
};

// upload & replace raw
const digitalAssetId = computed(() => asset.value.id);

const {
  mutate: updateDigitalAsset,
  isPending: isUpdatingDigitalAsset,
  error: updateDigitalAssetError,
} = useUpdateDigitalAsset(accountId);

const attachUpload = async blob => {
  updateDigitalAsset(
    {
      assetId: digitalAssetId,
      body: {
        raw: blob.signed_id,
      },
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.orderAddOns(orderId),
        });
      },
    }
  );
};

// errors & loading
const priorityError = usePriorityError(
  updateDigitalAssetError,
  updateEditsCollectionDigitalAssetError
);

const isLoading = computed(
  () =>
    isUpdatingDigitalAsset.value || isUpdatingEditsCollectionDigitalAsset.value
);

// modal stuff
const dragAndDropModalVisible = ref(false);
const maxConcurrent = ref(5);
const uploadInterval = ref(100);
// TODO: figure out a good file limit before opening up to customers
const fileSizeLimit = ref('');
const accept = ref('');
const uploadType = ref('');
const handleUploadFunc = ref(null);
const startUploadFunc = ref(null);
const handleDropFunc = ref(null);
const handleCancelFunc = ref(null);
const activeUploadsLocal = ref([]);

function hideDragAndDropModal() {
  dragAndDropModalVisible.value = false;
}

function onUploadCompleteRaw(blob) {
  attachUpload(blob);
  hideDragAndDropModal();
}

function showDragAndDropModalEdit() {
  fileSizeLimit.value = '';
  const {
    handleUpload,
    handleDrop,
    activeUploads,
    cancelUploads,
    startUpload,
  } = useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    null,
    null,
    onUploadCompleteRaw
  );
  startUploadFunc.value = startUpload;
  handleUploadFunc.value = handleUpload;
  handleDropFunc.value = handleDrop;
  handleCancelFunc.value = cancelUploads;
  activeUploadsLocal.value = activeUploads.value;
  uploadType.value = 'edit';
  dragAndDropModalVisible.value = true;
}
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <SoonaLoading v-if="isLoading" is-loading />
  <OverlayDropdown
    icon-name="dots-horizontal"
    visually-hidden-text="more options"
  >
    <template #tooltip-content>more options</template>
    <template #default="{ clickCapture, keydown, mouseover }">
      <SoonaDropdownMenuItem
        v-if="enableReview && asset.edit_status === 'pending'"
      >
        <button
          role="menuitem"
          @click="reject"
          @click.capture="clickCapture"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          reject
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem
        v-if="enableReview && asset.edit_status === 'rejected'"
      >
        <button
          role="menuitem"
          @click="makePending"
          @click.capture="clickCapture"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          make pending
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem v-if="asset">
        <button
          role="menuitem"
          @click="showDragAndDropModalEdit"
          @click.capture="clickCapture"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          {{ asset.raw ? 'replace raw' : 'attach raw' }}
        </button>
      </SoonaDropdownMenuItem>
    </template>
  </OverlayDropdown>
  <SoonaUploadDragAndDropModal
    v-if="dragAndDropModalVisible"
    :accept="accept"
    :active-uploads="activeUploadsLocal"
    is-multiple
    label="raw"
    @close="hideDragAndDropModal"
    @handle-drop="handleDropFunc"
    @handle-upload="handleUploadFunc"
  />
  <SoonaUploadProgressModal
    v-if="activeUploadsLocal.length > 0"
    :active-uploads="activeUploadsLocal"
    is-multiple
    label="raw"
    @cancel-uploads="handleCancelFunc"
    @start-upload="startUploadFunc"
  />
</template>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { mapReservationPaginationHeaders } from '@/lib/api-transforms';
/**
 *
 * @param {MaybeRef<string | number>} accountId
 * @param {Object} params
 * @param {MaybeRef<number | string>} [params.activeAssetId]
 * @param {MaybeRef<boolean>} [params.facets]
 * @param {MaybeRef<Object>} [params.filters]
 * @param {MaybeRef<number>} [params.currentPage]
 * @param {MaybeRef<number>} [params.itemsPerPage]
 * @param {MaybeRef<string>} [params.searchQuery]
 * @param {MaybeRef<string>} [params.sortBy]
 * @param {MaybeRef<string>} [params.sortDirection]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 * */
export function useShopMore(
  accountId,
  {
    activeAssetId,
    facets,
    filters,
    currentPage,
    itemsPerPage = 10,
    searchQuery,
    sortBy,
    sortDirection,
  },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.shopMoreDigitalAssets(accountId, {
      activeAssetId,
      facets,
      filters,
      currentPage,
      itemsPerPage,
      searchQuery,
      sortBy,
      sortDirection,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (toValue(activeAssetId))
        params['active_asset_id'] = toValue(activeAssetId);
      if (facets) params['facets'] = toValue(facets);
      if (currentPage) params['page'] = toValue(currentPage);
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);
      if (searchQuery) params['search_query'] = toValue(searchQuery);
      if (sortBy) params['order_by'] = toValue(sortBy).replace(/\s+/g, '_');
      if (sortDirection) params['direction'] = toValue(sortDirection);
      if (filters) {
        if (toValue(filters)['collection_type']) {
          params['collection_type'] = toValue(filters)['collection_type'];
        }
      }

      const response = await http.get(
        `/accounts/${toValue(accountId)}/shop_more.json`,
        { params, signal }
      );

      return {
        pagination: mapReservationPaginationHeaders(response.headers),
        assets: response.data || [],
      };
    },
    ...queryOptions,
  });
}

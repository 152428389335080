<script setup>
import { computed, toRef } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaExpansionPanel from '@/components/ui_library/SoonaExpansionPanel.vue';
import AccordionHeaderIcon from '@/components/user/anytime/listing_insights/listing_detail/accordions/AccordionHeaderIcon.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaBlogPostLink from '@/components/ui_library/SoonaBlogPostLink.vue';
import ImageQualityRuleResult from './ImageQualityRuleResult.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useListingDetailAccordion } from '@/components/user/anytime/listing_insights/listing_detail/accordions/useListingDetailAccordion';
import { useImageQualityRulesData } from './useImageQualityRulesData';
import AiDisclaimer from '@/components/user/anytime/listing_insights/listing_detail/accordions/AiDisclaimer.vue';

const props = defineProps({
  accountId: { type: [String, Number], default: undefined }, // accountId is undefined when accessed from temporary listing insights
  actions: { type: Array, default: () => [] },
  data: { type: Object, required: true },
  platform: {
    type: String,
    required: true,
    validator: function (value) {
      return ['Amazon', 'Shopify'].includes(value);
    },
  },
  assets: { type: Array, required: true },
});

const emit = defineEmits(['refreshListing', 'showSubscriptionsDialog']);

const route = useRoute();

const { hasBrokenRules, linkTo, linkTitle, linkImage } =
  useListingDetailAccordion(
    toRef(() => props.data),
    toRef(() => props.platform)
  );

const { linkClicked } = useBaseEvents();

const trackLinkClicked = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'single listing overview - image quality',
    linkLabel: `Learn More - ${linkTitle.value}`,
    linkHref: linkTo,
  });
};

const actions = computed(() => props.actions ?? []);
const getListingAction = (failingImage, failingRules) => {
  const ruleSlugs = failingRules.map(rule => rule.rule_slug.value);
  return actions.value.find(action => {
    return (
      ruleSlugs.includes(action.listing_rule_slug) &&
      failingImage.digital_asset_id === action.original_asset.id
    );
  });
};

const { failingImages, numberOfFailingImages } = useImageQualityRulesData(
  toRef(() => props.data)
);

const refreshListing = () => {
  emit('refreshListing');
};
</script>

<template>
  <SoonaExpansionPanel>
    <template #header>
      <span class="image-quality__header--wrapper">
        <AccordionHeaderIcon icon-name="aperture" :status="data.score.label" />
        <span class="image-quality__header-content">
          <span class="image-quality__header-title">image quality</span>
          <span class="image-quality__header-actions">
            <span v-if="hasBrokenRules" class="image-quality__header-action">
              <SoonaIcon
                name="status-dot-small"
                size="small"
                class="image-quality__header-action-icon--fail"
              />
              <span class="image-quality__header-action-text">
                <template v-if="numberOfFailingImages === 0">
                  no content to score!
                </template>
                <template v-else>
                  replace
                  {{ numberOfFailingImages }}
                  image{{ numberOfFailingImages > 1 ? 's' : '' }}
                </template>
              </span>
            </span>
            <span v-else class="image-quality__header-action">
              <SoonaIcon
                name="circle-check-solid"
                size="small"
                class="image-quality__header-action-icon--pass"
              />
              <span class="image-quality__header-action-text">
                looking sharp!
              </span>
            </span>
          </span>
        </span>
      </span>
    </template>

    <template #panel>
      <div class="image-quality__panel--wrapper">
        <div v-if="hasBrokenRules" class="image-quality__panel--fail">
          <div
            v-if="numberOfFailingImages === 0"
            class="image-quality__panel--no-images-detected"
          >
            <p>no images detected</p>
            <span class="u-label--regular">
              publish images to this listing on {{ platform }} to see a score.
            </span>
            <SoonaButton
              variation="tertiary"
              size="medium"
              @click="refreshListing"
            >
              <SoonaIcon name="refresh-cw-alt" />
              refresh
            </SoonaButton>
          </div>
          <ImageQualityRuleResult
            v-for="(failingImage, i) in failingImages"
            :key="i"
            :failing-image="failingImage"
            :account-id="accountId"
            :listing-action="getListingAction(failingImage, failingImage.rules)"
            @show-subscriptions-dialog="
              $emit('showSubscriptionsDialog', $event)
            "
          />
        </div>
        <div v-else>
          <div class="image-quality__panel-images">
            <div
              v-for="(asset, i) in assets"
              :key="i"
              class="image-quality__panel-image"
            >
              <img :src="asset.digital_asset_url" alt="" />
            </div>
          </div>
          <div class="image-quality__panel--pass">
            <ul>
              <li>
                <SoonaIcon
                  name="circle-check-solid"
                  size="small"
                  class="image-quality__header-action-icon--pass"
                />
                high resolution
              </li>
              <li>
                <SoonaIcon
                  name="circle-check-solid"
                  size="small"
                  class="image-quality__header-action-icon--pass"
                />
                consistent aspect ratios
              </li>
              <li>
                <SoonaIcon
                  name="circle-check-solid"
                  size="small"
                  class="image-quality__header-action-icon--pass"
                />
                professional production quality
              </li>
            </ul>
          </div>
        </div>
        <AiDisclaimer />
        <div class="image-quality__panel-blog-link">
          <p>learn more</p>
          <SoonaBlogPostLink
            :image-src="linkImage"
            :title="linkTitle"
            :to="linkTo"
            @link-clicked="trackLinkClicked"
          />
        </div>
      </div>
    </template>
  </SoonaExpansionPanel>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.image-quality {
  &__header {
    &--wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &-title {
      @include variables_fonts.u-badge--small;
      color: variables.$gray-60;
    }

    &-action {
      @include variables_fonts.u-body--heavy;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;

      &-icon {
        &--fail {
          color: variables.$roses-60;
        }
        &--pass {
          color: variables.$avo-toast-40;
        }
      }
    }
  }

  &__panel {
    &--no-images-detected {
      border-radius: 0.625rem;
      background: variables.$periwink-blue-10;
      padding: 1rem 0;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.75rem;

      p {
        @include variables_fonts.u-body--heavy;
      }
    }

    &--pass {
      border-radius: 0.625rem;
      background: variables.$periwink-blue-10;
      padding: 1.5rem;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      align-items: left;
      gap: 0.75rem;

      ul {
        display: grid;
        gap: 0.75rem;

        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.25rem;
        }
      }
    }

    &-images {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
      padding-bottom: 1rem;
    }

    &-image {
      width: 5rem;
      height: 5rem;
      flex: 0 0 5rem;

      img {
        border-radius: 0.3125rem;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &-blog-link {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      margin-top: 1rem;

      p {
        @include variables_fonts.u-badge--big;

        color: variables.$gray-60;
      }
    }
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__panel {
      &--wrapper {
        padding: 1rem;
      }
    }
  }
}
</style>

<script setup>
import { useFlag } from '@/composables/useFlag';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  inputName: {
    required: true,
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const searchText = defineModel('searchText', {
  type: String,
  required: true,
});

const searchbarFlag = useFlag('apollo_dam_gallery_rework_searchbar');
</script>

<template>
  <div v-if="searchbarFlag" class="searchbar">
    <SoonaTextfield
      v-model="searchText"
      type="search"
      label="search"
      :name="inputName"
      placeholder="search"
      hide-label
      :disabled="disabled"
    >
      <template #icon-left>
        <SoonaIcon name="search" size="small" />
      </template>
    </SoonaTextfield>
  </div>
</template>

<script setup>
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { Roses20, Roses80 } from '@/variables.module.scss';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { computed } from 'vue';
import { useUpdateEditsCollectionDigitalAsset } from '@/queries/edits_collection_digital_assets/useUpdateEditsCollectionDigitalAsset';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
    default: undefined,
  },
  editsCollectionDigitalAssetId: {
    type: [Number, String],
    required: true,
    default: undefined,
  },
  editsCollectionId: {
    type: [Number, String],
    required: true,
    default: undefined,
  },
  visibility: {
    required: true,
    type: String,
    default: '',
  },
});

const { addToast } = useSoonaToast();

const accountId = computed(() => props.accountId);
const editsCollectionId = computed(() => props.editsCollectionId);

const { mutate, isPending } = useUpdateEditsCollectionDigitalAsset(
  accountId,
  editsCollectionId
);

const makePending = () => {
  mutate(
    {
      cdaId: props.editsCollectionDigitalAssetId,
      body: { edit_status: 'pending' },
    },
    {
      onError: () => {
        addToast(`error making asset back to pending status`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};
</script>
<template>
  <div>
    <SoonaFlag :background-color="Roses20" :color="Roses80" title="rejected" />
    <SoonaButton
      element="button"
      variation="secondary-black"
      :loading="isPending"
      :disabled="isPending"
      @on-click="makePending"
    >
      make pending
    </SoonaButton>
  </div>
</template>

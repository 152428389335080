import { inject, toValue, watchEffect } from 'vue';

/**
 *
 * @param {MaybeRef<string | null | undefined>} anytimeToken
 * @description this will register and unregister the anytime token when it
 * changes. VueSocketManager manages connecting and disconnecting to registered
 * tokens.
 */
export function useRegisterAnytimeToken(anytimeToken) {
  /**
   * @type {VueSocketManager}
   */
  const $socket = inject('$socket');

  watchEffect(onCleanup => {
    const token = toValue(anytimeToken);
    let unregister;

    if (token) {
      unregister = $socket.register(token);
    }

    onCleanup(() => {
      if (unregister) {
        unregister();
      }
    });
  });
}

<script setup>
import { ref, toRefs, computed } from 'vue';
import { useMe } from '@/composables/user/useMe';

import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';

const props = defineProps({
  previewTemplate: {
    type: Object,
    required: true,
  },
  iframeRef: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['close-preview', 'recreate-scene']);

const { previewTemplate, iframeRef } = toRefs(props);

const previewingTemplate = ref(previewTemplate.value);
const srcUrl = computed(() => previewingTemplate.value.srcUrl);
const templateName = computed(() => previewingTemplate.value.name);
const wysiwygUrl = computed(() => previewingTemplate.value.wysiwygUrl);
const templateStatus = computed(() => previewingTemplate.value.user_status);

const mokkerIframeDomain = import.meta.env.VITE_MOKKER_IFRAME_DOMAIN || '*';

const imageLoaded = ref(false);
const wysiwygLoaded = ref(false);

const { currentAccountId } = useMe();

const handleStatusUpdate = status => {
  iframeRef.value.contentWindow.postMessage(
    {
      action: 'mokker:update-template',
      payload: { id: previewTemplate.value.id, status },
    },
    mokkerIframeDomain
  );
  emits('close-preview');
};

const openTemplateRoute = computed(() => {
  return {
    name: 'ai-studio-template',
    params: {
      accountId: currentAccountId.value,
      templateId: previewTemplate.value.id,
    },
    query: {
      tool: 'none',
    },
  };
});
</script>
<template>
  <SoonaDialog
    max-width="70rem"
    max-content-height="100%"
    @close="() => emits('close-preview')"
  >
    <template #heading
      >{{ templateName }}
      <SoonaChip
        v-if="templateStatus === 'draft'"
        size="medium"
        variation="black"
        class="title-draft-chip"
        >DRAFT
      </SoonaChip>
    </template>

    <div class="ai-asset-preview__dialog">
      <div class="ai-asset-preview">
        <div class="ai-asset-preview__image-container">
          <span class="u-body--heavy">example result</span>
          <SoonaSkeleton
            v-if="!imageLoaded"
            class="ai-asset-preview__skeleton"
            randomize-timing
          />
          <img
            v-show="imageLoaded"
            :src="srcUrl"
            alt="template preview"
            @load="imageLoaded = true"
          />
          <aside class="ai-asset-preview__image-container__generation-hint">
            every AI generation is unique. the example above shows one possible
            output of this template. it may take several tries to get an image
            that suits your needs.
          </aside>
        </div>
        <div class="ai-asset-preview__image-container">
          <span class="u-body--heavy">template canvas</span>
          <SoonaSkeleton
            v-if="!wysiwygLoaded"
            class="ai-asset-preview__skeleton"
            randomize-timing
          />
          <img
            v-show="wysiwygLoaded"
            :src="wysiwygUrl"
            alt="scene preview"
            @load="wysiwygLoaded = true"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <div class="ai-asset-preview__dialog__footer">
        <SoonaButton
          v-if="templateStatus !== 'approved'"
          element="router-link"
          variation="secondary-black"
          :to="openTemplateRoute"
        >
          use template
        </SoonaButton>
        <SoonaButton
          v-if="templateStatus !== 'archived'"
          variation="secondary-black"
          @click="() => handleStatusUpdate('archived')"
        >
          archive
        </SoonaButton>
        <SoonaButton
          v-if="templateStatus === 'archived'"
          variation="primary"
          @click="() => handleStatusUpdate('draft')"
        >
          undo
        </SoonaButton>
        <SoonaButton
          v-else-if="templateStatus === 'draft'"
          variation="primary"
          @click="() => handleStatusUpdate('approved')"
        >
          approve
        </SoonaButton>
        <SoonaButton
          v-else-if="templateStatus === 'approved'"
          element="router-link"
          variation="primary"
          :to="openTemplateRoute"
        >
          use template
        </SoonaButton>
      </div>
    </template>
  </SoonaDialog>
</template>

<style scoped lang="scss">
@use '@/variables';

.title-draft-chip {
  margin-left: 0.5rem;
}

.ai-asset-preview {
  gap: 1rem;
  flex: 1 1 0;
  width: 100%;
  display: flex;

  &__image-container:nth-child(1) {
    flex: 1.5 1 0;
  }

  &__image-container {
    flex: 1 1 0;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: start;

    & img {
      width: 100%;
      aspect-ratio: 1 / 1;
      object-fit: contain;
      border-radius: 0.1875rem;
    }

    &__generation-hint {
      border-radius: 0.4rem;
      padding: 0.6rem 0.8rem;
      background: variables.$periwink-blue-10;
      border: 1px solid variables.$periwink-blue-30;
    }
  }

  &__skeleton {
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  &__dialog {
    gap: 2rem;
    padding: 0;
    display: flex;
    min-height: 36rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;

    &__footer {
      gap: 1rem;
      bottom: 0;
      display: flex;
      position: relative;
      justify-content: right;
    }

    @media (max-width: 40rem) {
      min-height: 100%;
    }
  }

  @media (max-width: 40rem) {
    flex-direction: column;
  }
}
</style>

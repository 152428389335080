import { useIntersectionObserver, useMutationObserver } from '@vueuse/core';
import checkertileUrl from '@images/checkertile.svg';

export default {
  mounted(el) {
    /**
     * @param {boolean} [update] - set to true if the image should be immediately updated if it has been loaded already
     */
    function loadImage(update = false) {
      const imageElement = Array.from(el.children).find(
        el => el.nodeName === 'IMG'
      );

      // when update is true, run load logic for image if it has already loaded
      if (imageElement && (!update || imageElement.src)) {
        imageElement.addEventListener('load', () => {
          el.parentElement.style.background = `url('${imageElement.dataset.url}') no-repeat, url("${checkertileUrl}") repeat`;
          el.parentElement.style.backgroundPosition = 'center, top left';
          el.parentElement.style.backgroundSize = 'cover, auto';
          el.parentElement.classList.add('loaded');
          Array.from(el.parentElement.children).forEach(e =>
            e.classList.add('loaded')
          );
        });
        imageElement.src = imageElement.dataset.url;
      }
    }

    // vueuse uses this hook in a directive, so this should be fine for us to do too
    // https://github.com/vueuse/vueuse/blob/4f4141ad3ce9bbb86dbf57c57ceb3792a28ffdd6/packages/core/useIntersectionObserver/directive.ts
    const { isSupported } = useIntersectionObserver(
      el,
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            loadImage();
            // an image can only initially load once, so we stop the observer once that's happened
            observer.unobserve(el);
          }
        });
      },
      {
        // circumstances under which the observer's callback is invoked
        root: null, // defaults to the browser viewport if not specified or if null
        threshold: 0, // the degree of intersection between the target element and its root (0 - 1)
        // threshold of 1.0 means that when 100% of the target is visible within
        //the element specified by the root option, the callback is invoked
      }
    );

    if (!isSupported.value) {
      loadImage();
    }

    useMutationObserver(
      el,
      mutations => {
        if (
          mutations.some(
            m => m.type === 'attributes' && m.attributeName === 'data-url'
          )
        ) {
          loadImage(true);
        }
      },
      {
        subtree: true,
        attributes: true,
        attributeFilter: ['data-url'],
      }
    );
  },
};

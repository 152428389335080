<template>
  <ol class="trend-steps" aria-label="detailed steps">
    <li>
      <h2 class="trend-steps__heading u-title--heavy">create a free brief</h2>
      <p class="u-body--regular">
        outline your vision for the content. we’ll send this vision to content
        creators.
      </p>
    </li>
    <li>
      <h2 class="trend-steps__heading u-title--heavy">choose creators</h2>
      <p class="u-body--regular">
        choose the right creator who fits your ideal demographic to create the
        content.
      </p>
    </li>
    <li>
      <h2 class="trend-steps__heading u-title--heavy">get new content</h2>
      <p class="u-body--regular">
        your fresh content is optimized for performance on leading platforms and
        networks.
      </p>
    </li>
  </ol>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.trend-steps {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: clamp(1rem, 3vw, 2rem);
  row-gap: 1rem;
  counter-reset: trend-steps-section-counter;

  li {
    counter-increment: trend-steps-section-counter;
    flex: 1 1 100%;
    border-radius: 1rem;
    box-shadow: variables.$elevation-1;
    padding: 2rem clamp(1rem, 3vw, 2rem);

    &::before {
      content: counter(trend-steps-section-counter);
      @include variables_fonts.u-headline--heavy;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: variables.$black-default;
      border-radius: 10rem;
      padding: 0.25rem;
      height: 2.75rem;
      width: 2.75rem;
      color: variables.$white-default;
      margin-bottom: 0.5rem;
    }
  }

  &__heading {
    margin-bottom: 0.1875rem;
  }
}

@mixin trend-steps-large-styles {
  .trend-steps li {
    flex: 1 1 12rem;
  }
}

@container ugc-page (min-width: 46rem) {
  @include trend-steps-large-styles;
}

/* compat: remove when dropping Safari 15 */
@supports not (container-type: inline-size) {
  @media (min-width: 42rem) {
    @include trend-steps-large-styles;
  }
}
</style>

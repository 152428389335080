import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { updateStudioAccess } from 'src/api/reservation';
import { queryKeys } from 'src/queries/query-keys';
import { toValue } from 'vue';

export function useUpdateStudioAccess(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: params => updateStudioAccess(toValue(reservationId), params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(reservationId),
      });
    },
  });
}

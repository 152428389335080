<script setup>
import { computed, toRefs } from 'vue';
import SoonaTab from '@/components/ui_library/SoonaTab.vue';
import { useGetCatalogItemActiveListings } from 'src/queries/useGetCatalogItemActiveListings';
import { keepPreviousData } from '@tanstack/vue-query';
import { useRoute } from 'vue-router';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useCatalogItem } from '@/queries/useCatalog';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  catalogItemId: {
    type: String,
    required: true,
  },
});

const { accountId, catalogItemId } = toRefs(props);

const route = useRoute();

// catalog item module
const {
  data: productData,
  isLoading: productLoading,
  error: productError,
} = useCatalogItem(accountId, catalogItemId);

const product = computed(() => productData.value ?? { name: '' });

// connected listings module
const {
  data: listingsData,
  isLoading: listingsLoading,
  error: listingsError,
} = useGetCatalogItemActiveListings(accountId, catalogItemId, {
  placeholderData: keepPreviousData,
});

const listings = computed(() => listingsData.value?.listings ?? []);

const firstListing = computed(() => listings.value[0] ?? null);

const toListingTab = computed(() => {
  if (listingsLoading.value || listings.value.length === 0) {
    return '/';
  }
  return {
    name: 'listing-optimize',
    params: {
      accountId: accountId.value,
      integrationId: firstListing.value?.integration_id ?? '',
      externalProductId: firstListing.value?.external_product_id ?? '',
      catalogItemId: firstListing.value?.catalog_item_id ?? '',
    },
    replace: true,
  };
});

const insightsTabIsActive = computed(
  () => route.name === 'listing-optimize' || route.name === 'listing-variants'
);
const isLoading = computed(() => listingsLoading.value || productLoading.value);
const priorityError = usePriorityError(productError, listingsError);
</script>

<template>
  <div class="product-nav--wrapper u-container">
    <div v-if="isLoading" class="product-nav__loading">
      <SoonaSkeleton
        v-for="i in 3"
        :key="i"
        class="product-nav__loading--skeleton"
      />
    </div>
    <div v-else class="product-nav">
      <SoonaTab
        :to="{
          name: 'product-detail',
          params: { accountId, catalogItemId },
        }"
        :is-selected="route.name === 'product-detail'"
        replace
      >
        overview
      </SoonaTab>
      <SoonaTab
        v-if="firstListing"
        :to="toListingTab"
        :is-selected="insightsTabIsActive"
        replace
      >
        insights
      </SoonaTab>
      <SoonaTab
        v-if="product.variant_count > 0"
        :to="{
          name: 'product-variants',
          params: { accountId, catalogItemId },
        }"
        :is-selected="route.name === 'product-variants'"
        replace
      >
        variants
      </SoonaTab>
    </div>
    <SoonaError v-if="priorityError" :error="priorityError" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.product-nav {
  display: flex;
  border-bottom: 0.0625rem solid variables.$gray-30;
  overflow-x: auto;

  &__loading {
    width: 100%;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    border-bottom: 0.0625rem solid variables.$gray-30;

    &--skeleton {
      height: 100%;
      width: 6rem;
      border-radius: 0.25rem 0.25rem 0 0;
    }
  }

  :deep(.soona-tab:not([aria-current='true'])) {
    border-bottom: none;
    display: flex;
    gap: 0.25rem;
  }
}
</style>

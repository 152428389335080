<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useAccount } from 'src/queries/account/useAccount';
import { useUpdateAccount } from 'src/queries/account/useUpdateAccount';
import { useGetUser } from 'src/queries/users/useGetUser';
import { useUpdateUser } from 'src/queries/users/useUpdateUser';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';

const props = defineProps({
  accountId: Number,
  userId: Number,
});

const accountId = computed(() => props.accountId);
const userId = computed(() => props.userId);

const {
  data: user,
  isLoading: loadingUser,
  error: userLoadingError,
} = useGetUser(userId);

const {
  data: account,
  isLoading: loadingAccount,
  error: accountLoadingError,
} = useAccount(accountId);

const accountName = ref(account.value?.name);
const name = ref(user.value?.name);
const email = ref(user.value?.email);

// separate into different effects so they can update individually
watchEffect(() => {
  accountName.value = account.value?.name;
});
watchEffect(() => {
  name.value = user.value?.name;
  email.value = user.value?.email;
});

const isDirty = computed(
  () =>
    accountName.value !== account.value?.name ||
    name.value !== user.value?.name ||
    email.value !== user.value?.email
);

const {
  mutate: updateAccount,
  isPending: updatingAccount,
  error: updateAccountError,
} = useUpdateAccount(accountId);

const {
  mutate: updateUser,
  isPending: updatingUser,
  error: updateUserError,
} = useUpdateUser(userId);

const isBusy = computed(() => {
  return (
    loadingUser.value ||
    loadingAccount.value ||
    updatingAccount.value ||
    updatingUser.value
  );
});

const priorityError = usePriorityError(
  updateAccountError,
  updateUserError,
  accountLoadingError,
  userLoadingError
);

function save(event) {
  const data = new FormData(event.target);

  const submittedAccountName = data.get('account-name');
  const submittedUserName = data.get('user-name');
  const submittedEmail = data.get('email');

  updateAccount(
    { name: submittedAccountName },
    {
      onSuccess: () => {
        updateUser({
          email: submittedEmail,
          name: submittedUserName,
          username: submittedEmail,
        });
      },
    }
  );
}
</script>

<template>
  <SoonaForm v-slot="{ hasErrors }" @submit="save">
    <SoonaTextfield
      v-model="name"
      label="customer name"
      name="user-name"
      placeholder="enter the customer’s name"
      :required="true"
      :rules="['required']"
      :disabled="isBusy"
    />
    <SoonaTextfield
      v-model="email"
      label="customer email"
      name="email"
      placeholder="enter the customer’s email"
      type="email"
      :required="true"
      :rules="['email', 'required']"
      :disabled="isBusy"
    />
    <SoonaTextfield
      v-model="accountName"
      label="account name"
      name="account-name"
      placeholder="enter the customer’s name"
      :required="true"
      :rules="['required']"
      :disabled="isBusy"
      data-cypress="account-name"
    />
    <SoonaButton
      variation="secondary-black"
      type="submit"
      size="medium"
      data-cypress="save-customer-account-button"
      :disabled="isBusy || hasErrors || !isDirty"
    >
      save customer account
    </SoonaButton>
  </SoonaForm>

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

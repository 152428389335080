<script setup>
import { computed } from 'vue';
import { useAccount } from '@/queries/account/useAccount';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

const props = defineProps({
  accountId: Number,
  previousReservationName: {
    type: String,
    required: false,
  },
});

const {
  data: account,
  error,
  isSuccess,
} = useAccount(computed(() => props.accountId));

const profileData = computed(() => {
  return {
    image: account.value?.avatar_url,
    name: account.value?.name,
    email: account.value?.owner?.email,
  };
});
</script>

<template>
  <div class="booking-account">
    <div v-if="isSuccess" class="booking-account__profile">
      <SoonaAvatar
        class="booking-account__profile__avatar"
        :name="profileData.name"
        size="2rem"
        :src="profileData.image"
        type="account"
      />
      <p class="booking-account__profile__name">{{ profileData.name }}</p>
      <p class="booking-account__profile__email" data-cypress="account-email">
        {{ profileData.email }}
      </p>
    </div>
    <p
      v-if="previousReservationName"
      class="booking-account__previous-reservation"
      data-cypress="continuation-of-booking-name"
    >
      continuation of:
      <span class="booking-account__previous-reservation__name">{{
        previousReservationName
      }}</span>
    </p>

    <SoonaError v-if="error">
      {{ error.message }}
    </SoonaError>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.booking-account {
  &__heading {
    @include variables_fonts.u-title--heavy;
    color: variables.$black-default;
    margin-bottom: 1.5rem;
  }

  &__profile {
    display: grid;
    grid-template-areas:
      'image name'
      'image email';
    grid-column-gap: 0.5rem;
    grid-template-columns: 2rem 1fr;
    margin-bottom: 1.5rem;

    &__avatar {
      grid-area: image;
    }

    &__name {
      grid-area: name;
      @include variables_fonts.u-label--heavy;
      color: variables.$black-default;
    }

    &__email {
      grid-area: email;
      @include variables_fonts.u-small--regular;
      color: variables.$gray-60;
    }
  }

  &__previous-reservation {
    color: variables.$black-default;

    &__name {
      @include variables_fonts.u-body--heavy;
    }
  }
}
</style>

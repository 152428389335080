<script setup>
import { computed } from 'vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import { useGetNoteTypes } from '@/queries/notes/useGetNoteTypes';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  noteType: {
    type: String,
    required: true,
  },
});

defineEmits(['update:noteType']);

const { data: noteTypes, isLoading } = useGetNoteTypes();

const noteTypeOptions = computed(() => {
  if (noteTypes.value) {
    return Object.keys(noteTypes.value).map(noteTypeLabel => {
      return {
        label: noteTypeLabel.replace(/_/g, ' '),
        value: noteTypeLabel,
      };
    });
  } else {
    return [];
  }
});
</script>
<template>
  <SoonaSelect
    :model-value="noteType"
    :inline="true"
    :disabled="disabled"
    :loading="isLoading"
    :options="noteTypeOptions"
    placeholder="select note type"
    @update:model-value="$emit('update:noteType', $event)"
  >
    <template #label> note type </template>
  </SoonaSelect>
</template>

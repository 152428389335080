const CUSTOM = Symbol('custom');
const PLATFORM = Symbol('platform');
const COMMON = Symbol('common');

const RESIZE_SYMBOLS = [
  { symbol: CUSTOM, paywalled: false, label: 'custom size' },
  { symbol: PLATFORM, paywalled: true, label: 'platform/social crops' },
  { symbol: COMMON, paywalled: false, label: 'common sizes' },
];

export { RESIZE_SYMBOLS, CUSTOM, PLATFORM, COMMON };

<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SceneList from './SceneList.vue';
import SceneDetails from './SceneDetails.vue';
import SceneProducts from './SceneProducts.vue';
import SceneBuilder from './SceneBuilder.vue';
import {
  useShotList,
  useActiveShot,
  useSetActiveShot,
} from '@/queries/useShotList';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { useSceneCardContent } from '@/components/user/anytime/scene_selector/useSceneCardContent.js';
import AutoEditActions from './AutoEditActions.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  reservationIsInProgress: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['toggleLoader']);

const reservationId = computed(() => props.reservationId);

const {
  data: shotListData,
  isLoading: isLoadingShotList,
  isSuccess: shotListIsSuccess,
  error: shotListError,
} = useShotList(reservationId);

const shotList = computed(() => shotListData.value?.shots ?? []);

const {
  data: activeShot,
  isLoading: isLoadingActiveShot,
  isSuccess: activeShotIsSuccess,
  error: activeShotError,
} = useActiveShot(reservationId);

const {
  mutate: setActiveShot,
  isPending: setActiveShotIsLoading,
  error: setActiveShotError,
} = useSetActiveShot(reservationId);

const isLoading = computed(
  () => isLoadingShotList.value || isLoadingActiveShot.value
);

const priorityError = usePriorityError(
  setActiveShotError,
  shotListError,
  activeShotError
);

const dataIsInitialized = computed(
  () => shotListIsSuccess.value && activeShotIsSuccess.value
);

const sceneSelector = ref(null);

// selecting a scene module
const selectedSceneId = ref(undefined);

watchEffect(() => {
  if (dataIsInitialized.value && !selectedSceneId.value) {
    if (activeShot.value) {
      selectedSceneId.value = activeShot.value.active_shot_id;
    } else if (shotList.value?.length > 0) {
      selectedSceneId.value = shotList.value[0].id;
    }
  }
});

const selectedScene = computed(() =>
  shotList.value?.find(x => x.id === selectedSceneId.value)
);

const selectedSceneNumber = computed(
  () => shotList.value.find(x => x.id === selectedSceneId.value)?.number
);

const setSelectedSceneId = id => {
  selectedSceneId.value = id;
};

const isActive = computed(
  () => selectedSceneId.value === activeShot.value?.active_shot_id
);

const { isComplete, contentType } = useSceneCardContent(
  selectedScene,
  isActive
);

const startScene = () => {
  emit('toggleLoader', true);
  setActiveShot(selectedSceneId.value, {
    onSettled: () => {
      emit('toggleLoader', false);
    },
  });
};

const iconName = computed(() => {
  if (contentType.value?.title === 'Photo' && isActive.value) {
    return 'camera-solid';
  }
  if (contentType.value?.title === 'Video') {
    return isActive.value ? 'video-solid' : 'video';
  }
  return 'camera';
});

// create new scene module
const sceneBuilderMode = ref(false);

const addScene = () => {
  sceneBuilderMode.value = true;
};

const closeSceneBuilder = sceneId => {
  if (sceneId) {
    setSelectedSceneId(sceneId);
  }
  sceneBuilderMode.value = false;
  window.scrollTo({ top: sceneSelector.value.offsetTop });
};

// edit/duplicate scene module
const sceneToCopy = ref(null);
const editMode = ref(false);

watchEffect(() => {
  if (sceneBuilderMode.value === false) {
    sceneToCopy.value = null;
    editMode.value = false;
  }
});

const duplicateScene = () => {
  sceneToCopy.value = selectedScene.value;
  sceneBuilderMode.value = true;
};

const editScene = () => {
  editMode.value = true;
  sceneToCopy.value = selectedScene.value;
  sceneBuilderMode.value = true;
};
</script>

<template>
  <div ref="sceneSelector" class="scene-selector">
    <SoonaLoading
      v-if="isLoading"
      is-loading
      loading-text="loading scenes"
      is-contained
    />
    <SoonaError v-if="priorityError" class="scene-selector__error">
      {{ priorityError }}
    </SoonaError>

    <template v-if="dataIsInitialized">
      <div
        v-if="shotList.length === 0 && !sceneBuilderMode"
        class="scene-selector__empty"
      >
        <h3>add a scene to get started</h3>
        <SoonaButton
          size="medium"
          title="create new scene"
          @on-click="addScene"
        >
          new scene
        </SoonaButton>
      </div>
      <SceneList
        v-else-if="!sceneBuilderMode"
        :active-shot="activeShot"
        :selected-scene-id="selectedSceneId"
        :shot-list="shotList"
        @set-selected-scene="setSelectedSceneId"
        @add-scene="addScene"
      />
      <SceneBuilder
        v-if="sceneBuilderMode"
        :account-id="accountId"
        :reservation-id="reservationId"
        :shot-list-length="shotList.length"
        :scene-to-copy="sceneToCopy"
        :edit-mode="editMode"
        :original-scene-number="selectedSceneNumber"
        @close-scene-builder="closeSceneBuilder"
      />
      <div v-else-if="selectedScene" class="scene-selector__current-scene">
        <div class="scene-selector__current-scene--header">
          <div class="scene-selector__current-scene--title">
            <SoonaIcon
              :name="iconName"
              size="medium"
              :class="{ 'icon-active': isActive }"
            />
            <h4>scene {{ selectedSceneNumber }}</h4>
            <SoonaIcon
              v-if="isComplete"
              class="icon-complete"
              name="check"
              size="medium"
            />
          </div>
          <div class="scene-selector__current-scene--actions">
            <SoonaButton
              variation="tertiary"
              size="medium"
              @on-click="editScene"
            >
              <SoonaIcon name="pen-square" />
              edit
            </SoonaButton>
            <SoonaButton
              variation="tertiary"
              size="medium"
              @on-click="duplicateScene"
            >
              <SoonaIcon name="duplicate" />
              duplicate
            </SoonaButton>
            <div v-if="isActive" class="active-scene-label">
              <SoonaIcon
                name="status-dot-small"
                size="medium"
                class="icon-active"
              />
              ACTIVE
            </div>
            <SoonaButton
              v-else
              size="medium"
              :loading="setActiveShotIsLoading"
              :disabled="!reservationIsInProgress"
              @on-click="startScene"
            >
              start
            </SoonaButton>
          </div>
        </div>
        <AutoEditActions
          v-if="
            isActive || isComplete || selectedScene.digital_assets_count > 0
          "
          :reservation-id="reservationId"
          :selected-scene="selectedScene"
        />
        <div class="scene-selector__current-scene--content">
          <SceneDetails :selected-scene="selectedScene" />
          <SceneProducts
            :account-id="accountId"
            :selected-scene="selectedScene"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.scene-selector {
  border: 0.0625rem solid variables.$gray-30;
  box-shadow: variables.$elevation-0;
  border-radius: 0.625rem;
  background-color: variables.$periwink-blue-10;
  padding: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  /* anchor loading indicator */
  position: relative;
  overflow: hidden;

  &__error {
    flex: 1 1 100%;
    margin-top: 0;
  }

  &__empty {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    justify-items: center;
    align-items: center;

    h3 {
      @include variables_fonts.u-headline--heavy;
    }
  }

  &__current-scene {
    padding-top: 1rem;
    width: 100%;
    border-top: solid 0.0625rem variables.$gray-30;

    &--header {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }

    &--actions {
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;
    }

    &--title {
      display: flex;
      flex-direction: row;
      gap: 0.375rem;
      align-items: center;

      h4 {
        @include variables_fonts.u-subheader--heavy;
      }
    }

    &--content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1.25rem;

      h5 {
        @include variables_fonts.u-small--heavy;
      }
    }

    .icon-active {
      color: variables.$friendly-red-50;
    }

    .icon-complete {
      color: variables.$avo-toast-40;
    }

    .active-scene-label {
      @include variables_fonts.u-button--small-caps;

      display: flex;
      flex-direction: row;
      gap: 0.375rem;
      align-items: center;
      /* take up the same space as a medium button to prevent some layout shift */
      padding: 0.375rem 0;
    }

    // desktop
    // screen-sm-min = 768px
    @media (min-width: variables.$screen-sm-min) {
      &--content {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

.skeleton {
  &-button {
    height: 1rem;
    width: 4rem;
  }

  &-image {
    width: 2rem;
    height: 2rem;
  }
}
</style>

import { useQuery } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
import { toValue } from 'vue';

/**
 * @returns {UseMutationReturnType<any, unknown, void, unknown>}
 */
export function useMokkerAvailableCredits(accountId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.mokkerAvailableCredits(accountId),
    queryFn: async () => {
      const response = await http.get(
        `/mokker_accounts/${toValue(accountId)}/available_credits`
      );
      return response.data;
    },
    ...queryOptions,
  });
}

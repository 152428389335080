import { useElementBounding } from '@vueuse/core';
import { watchEffect } from 'vue';

export function useSetAppHeaderHeights(elRef) {
  const { top, height, update } = useElementBounding(elRef);

  watchEffect(() => {
    /*
     * --app-header-viewport-offset will be the distance from the top of the
     * viewport to the bottom of the header. when the header is positioned
     * statically, it will be at most the height of the header, and at least 0
     * when it is scrolled out of view. when positioned sticky, it will be the
     * height of the header (the same as the other property below).
     */
    document.documentElement.style.setProperty(
      '--app-header-viewport-offset',
      `${Math.max(height.value + top.value, 0)}px`
    );
    /*
     * --app-header-height is always the rendered height of the header, whether
     * it is sticky or static
     */
    document.documentElement.style.setProperty(
      '--app-header-height',
      `${height.value}px`
    );
  });

  return { update };
}

<script setup>
import { computed } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import AssetSummary from './DigitalAssetSummary.vue';
import Download from './Download.vue';
import UploadDigitalAssetEdits from '@/components/crew/orders/UploadDigitalAssetEdits.vue';

import {
  BlackDefault,
  FriendlyRed30,
  FriendlyRed60,
  Tangerine30,
  PeriwinkBlue30,
  AvoToast60,
  AvoToast20,
  Gray10,
} from 'src/variables.module.scss';

const props = defineProps({
  addOn: {
    required: true,
    type: Object,
  },
  orderId: {
    required: true,
    type: [Number, String],
  },
  enableReview: {
    required: true,
    type: Boolean,
  },
});

const speed = computed(() => {
  const speed = props.addOn?.options?.speed;
  if (!speed) return;

  return `${speed} ms, ${(1000 / speed).toFixed(1)} frames/s`;
});

const edits = computed(() => {
  return props.addOn.derivatives.filter(derivative => {
    return derivative.edit_status === 'accepted';
  });
});

const pendingEdits = computed(() => {
  return props.addOn.derivatives.filter(derivative => {
    return derivative.edit_status === 'pending';
  });
});

const rejectedEdits = computed(() => {
  return props.addOn.derivatives.filter(derivative => {
    return derivative.edit_status === 'rejected';
  });
});
</script>
<template>
  <div class="add-on-line-item">
    <div class="add-on-line-item__column_1">
      <router-link
        :to="{
          name: 'crew-order-add-on-line-item',
          params: {
            orderId: orderId,
            addOnLineItemId: addOn.id,
          },
        }"
      >
        <img
          v-if="addOn.preview?.url"
          :src="addOn.preview.url"
          alt=""
          class="add-on-line-item-thumbnail"
        />
      </router-link>
      <p class="reservation-file-name u-body--heavy">
        {{ addOn.title }}
      </p>
      <UploadDigitalAssetEdits
        v-if="enableReview"
        :account-id="addOn.account_id"
        :order-id="orderId"
        :reservation-id="addOn.reservation_id"
        :original-digital-asset-id="addOn.digital_asset_id"
        variation="secondary-gray"
      />
      <Download :add-on="addOn" />
    </div>
    <div class="add-on-line-item__column_2">
      <SoonaFlag
        v-if="addOn.is_preferred"
        :background-color="PeriwinkBlue30"
        :text-color="BlackDefault"
        title="preferred"
      />
      <p class="section-title">media type</p>
      <p>{{ addOn.media_type }}</p>
      <div v-if="addOn.media_add_ons.length > 0" class="add-on-add-ons">
        <p class="section-title">add-ons</p>
        <ul class="add-on-items">
          <li
            v-for="mediaAddOn in addOn.media_add_ons"
            :key="mediaAddOn.id"
            class="add-on-item is-size-7"
          >
            {{ mediaAddOn.name }} ({{
              Number(mediaAddOn.total).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            }})
          </li>
        </ul>
      </div>
      <div class="add-on-notes">
        <p v-if="addOn.note" class="section-title">notes</p>
        <p class="note-text">{{ addOn.note }}</p>
      </div>
      <dl v-if="addOn.options" class="options">
        <dt>dimensions</dt>
        <dd>{{ addOn.options.dimensions }}</dd>
        <dt>speed</dt>
        <dd>{{ speed }}</dd>
        <dt>format(s)</dt>
        <dd>
          {{ addOn.options.format.join('/') }} [{{ addOn.options.direction }}]
        </dd>
      </dl>
    </div>
    <div class="add-on-line-item__column_3">
      <div class="derivatives">
        <SoonaFlag
          v-if="edits.length > 0"
          :background-color="AvoToast60"
          :text-color="AvoToast20"
          title="edit delivered"
        />
        <SoonaFlag
          v-else-if="pendingEdits.length > 0"
          :background-color="Tangerine30"
          :text-color="BlackDefault"
          title="pending"
        />
        <SoonaFlag
          v-else
          :background-color="FriendlyRed30"
          :text-color="FriendlyRed60"
          title="needs edit"
        />
        <p v-if="edits.length > 0" class="section-title">delivered edits</p>
        <AssetSummary
          v-for="derivative in edits"
          :key="derivative.id"
          :add-on-line-item-id="addOn.id"
          :order-id="orderId"
          :original-digital-asset-id="addOn.digital_asset_id"
          :digital-asset="derivative"
          :enable-review="enableReview"
        />
        <p v-if="pendingEdits.length > 0" class="section-title">
          pending edits
        </p>
        <AssetSummary
          v-for="derivative in pendingEdits"
          :key="derivative.id"
          :add-on-line-item-id="addOn.id"
          :order-id="orderId"
          :original-digital-asset-id="addOn.digital_asset_id"
          :digital-asset="derivative"
          :enable-review="enableReview"
        />
        <p v-if="rejectedEdits.length > 0" class="section-title">
          rejected edits
        </p>
        <AssetSummary
          v-for="derivative in rejectedEdits"
          :key="derivative.id"
          :add-on-line-item-id="addOn.id"
          :order-id="orderId"
          :original-digital-asset-id="addOn.digital_asset_id"
          :digital-asset="derivative"
          :background-color="Gray10"
          :enable-review="enableReview"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.add-on-line-item {
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  gap: 1.5rem;
  justify-content: space-between;
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: 0.0625rem solid variables.$gray-20;
  }

  &__column_1 {
    flex: 0 1 10rem;

    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
  }

  &__column_2 {
    flex: 1 1 30rem;

    display: flex;
    flex-flow: column wrap;
  }

  &__column_3 {
    flex: 0 1 30rem;

    display: flex;
    flex-flow: column wrap;
    gap: 0.5rem;
  }

  .section-title {
    @include variables_fonts.u-label--heavy;
    margin-top: 0.5rem;
    padding: 0.25rem;
  }

  p {
    padding: 0 0.25rem;
  }

  p.help-text {
    @include variables_fonts.u-small--regular;
  }

  .add-on-items {
    line-height: 1.1;
    margin-bottom: 0.5rem;

    .add-on-item {
      @include variables_fonts.u-small--regular;
      padding: 0 0.25rem;
      display: block;
    }
  }

  .add-on-notes {
    max-width: 30rem;
    .note-text {
      @include variables_fonts.u-small--regular;
    }
  }

  .empty-list {
    background-color: variables.$gray-10;
    color: variables.$gray-60;

    padding: 0.5rem;
    margin: 0.5rem 0;
  }

  .derivatives {
    min-width: min(100%, 20rem);
  }

  .options {
    dt {
      @include variables_fonts.u-label--heavy;
      padding: 0.25rem;
    }
    dd {
      @include variables_fonts.u-small--regular;
      padding-left: 0.25rem;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { convertToDateLong } from '@/lib/date-formatters';
import { toCurrency } from '@/lib/currency';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

// Props
const props = defineProps({
  currentOrder: {
    type: Object,
    required: true,
  },
  amountDue: {
    type: Number,
    required: true,
  },
});
const currentOrder = computed(() => props.currentOrder);

// Data
const payNowLink = computed(() => `#/checkout/${currentOrder.value.id}`);
const isPaid = computed(
  () =>
    currentOrder.value.status === 'paid' ||
    currentOrder.value.status === 'completed'
);
const isCanceled = computed(
  () =>
    currentOrder.value.status === 'canceled' ||
    currentOrder.value.status === 'voided'
);

const dueDate = computed(() =>
  convertToDateLong(currentOrder.value.created_at)
);
const dateCollected = computed(() =>
  convertToDateLong(currentOrder.value.date_collected)
);
const paymentMethod = computed(() => {
  const {
    payment_method_type: paymentType,
    funding,
    last4,
  } = currentOrder.value.payment_historical_info ?? {};
  let type = '';
  if (paymentType === 'card') {
    type = funding + ' ' + paymentType;
  } else if (paymentType === 'us_bank_account') {
    type = 'Bank Account';
  } else if (paymentType) {
    type = paymentType.replace('_', ' ');
  }

  return { type, last4 };
});
</script>

<template>
  <!-- Status Card -->
  <div
    class="order-status-card"
    :class="{
      'order-status-card--paid': isPaid,
      'order-status-card--canceled': isCanceled,
      'order-status-card--unpaid': !isPaid && !isCanceled,
    }"
  >
    <template v-if="isPaid">
      <strong class="u-subheader--heavy">Paid on {{ dateCollected }}</strong>
      <p class="u-label--regular">
        <template v-if="paymentMethod.last4">
          <span style="text-transform: capitalize">
            {{ paymentMethod.type }}
          </span>
          ending in {{ paymentMethod.last4 }}.
        </template>
        Thanks for your business!
      </p>
    </template>
    <template v-else-if="!isPaid && !isCanceled">
      <strong class="u-subheader--heavy"
        >{{ toCurrency(amountDue) }} USD due on {{ dueDate }}</strong
      >
      <SoonaButton
        variation="solid-black"
        size="medium"
        class="order-status-card__pay-now-btn"
        :href="payNowLink"
        element="a"
      >
        pay now
      </SoonaButton>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

// Status Card
.order-status-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 0.25rem;
  padding: 1rem;
  gap: 0.5rem;
  height: min-content;
  margin-bottom: 0.75rem;

  &--paid {
    background: variables.$friendly-red-50;
    color: variables.$white-default;
  }

  &--unpaid {
    background: variables.$periwink-blue-20;
  }

  &__pay-now-btn {
    margin-top: 0.25rem;
    width: fit-content;
  }
}

@mixin order-status-card-desktop-styles {
  // Status Card
  .order-status-card {
    max-width: 50%;
    padding: 1.25rem;
  }
}

@container order-view (min-width: 37.5rem) {
  @include order-status-card-desktop-styles;
}

/* compat: remove when dropping Safari 15 */
@supports not (container-type: inline-size) {
  @media (min-width: 39.5rem) {
    @include order-status-card-desktop-styles;
  }
}

// Print Styles
@media print {
  .order-status-card {
    border: 1px solid variables.$black-default;
    border-radius: 6px;
    width: fit-content;
    max-width: none;

    &__pay-now-btn {
      padding: 0 !important;
      margin: 0 !important;
    }

    &--paid {
      print-color-adjust: exact;
      background: none;
      color: variables.$friendly-red-50;
      border-color: variables.$friendly-red-50;
    }

    a {
      text-decoration: underline;
      color: #001aff;
    }
  }
}
</style>

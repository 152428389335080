import { toValue } from 'vue';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {import('vue').MaybeRefOrGetter<"accounts" | "reservations" | "digital_assets">} subjectType
 * @param {import('vue').MaybeRefOrGetter<number>} subjectId
 * @param {Object} [params]
 * @params {Ref<number>} params.currentPage
 * @params {Ref<number>} params.itemsPerPage
 * @param {UseQueryOptions} queryOptions
 */
export function useInfiniteNotes(
  subjectType,
  subjectId,
  { itemsPerPage } = {},
  queryOptions
) {
  return useInfiniteQuery({
    queryKey: queryKeys.notes(subjectType, subjectId, {
      itemsPerPage,
      infinite: true,
    }),
    queryFn: async ({ signal, pageParam }) => {
      const params = { page: pageParam };

      if (toValue(itemsPerPage)) params.items = toValue(itemsPerPage);

      const response = await http.get(
        `/${toValue(subjectType)}/${toValue(subjectId)}/notes`,
        {
          params,
          signal,
        }
      );

      return {
        pagination: mapPaginationHeaders(response.headers),
        notes: response.data,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (
        !lastPage?.pagination.totalPages ||
        lastPageParam >= lastPage.pagination.totalPages
      ) {
        return null;
      }

      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return null;
      }
      return firstPageParam - 1;
    },
    ...queryOptions,
  });
}

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import StyleQuizLayout from '@/components/quiz/StyleQuizLayout.vue';

const router = useRouter();
const store = useStore();

const allRoles = computed(() => store.state.styleQuiz.allShootRoles);
const currentQuiz = computed(() => store.state.styleQuiz.currentQuiz);
const hasRoleSelected = computed(
  () => store.state.styleQuiz.shootRole !== null
);

const roles = (columns, column) =>
  allRoles.value?.filter((val, i) => i % columns === column);

const toggleRole = role => store.dispatch('styleQuiz/toggleShootRole', role);
</script>

<template>
  <StyleQuizLayout>
    <template #left-column>
      <h1 class="u-display--heavy">what role do you play on set? 👩‍🚀</h1>
      <p class="u-label--heavy">
        tell us how you prefer to participate in a photo or video shoot.
      </p>
      <div class="next-btn-wrapper">
        <SoonaButton
          :disabled="!hasRoleSelected"
          size="medium"
          data-cypress="button-next"
          @on-click="() => router.push({ name: 'style-quiz-word-picker' })"
        >
          next
        </SoonaButton>
      </div>
    </template>
    <template #right-column>
      <div class="flex-wrapper">
        <SoonaLoading v-if="!allRoles.length > 0" is-contained is-loading />
        <div v-for="n in 3" v-else :key="`role-col-${n}`" class="flex-section">
          <button
            v-for="(role, index) in roles(3, n - 1)"
            :key="index"
            class="u-button-reset role-btn"
            :data-cypress="`div-role-${n}-${index}`"
            @click="toggleRole(role)"
          >
            <span
              class="role-emoji"
              :class="{
                'role-emoji--selected': currentQuiz.shootRole === role.id,
              }"
            >
              {{ role.icon }}
            </span>
            <span class="u-label--heavy">{{ role.title }}</span>
            <span class="u-small--regular role-description">
              {{ role.description }}
            </span>
          </button>
        </div>
      </div>
    </template>
  </StyleQuizLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.next-btn-wrapper {
  margin-left: auto;
}

.flex-wrapper {
  display: flex;
  margin: 0 auto;
  max-width: 30rem;
  padding: 0.5rem 0;
  position: relative;
  width: 100%;
}

.flex-section {
  width: 33.3333%;
}

.role-btn {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 11.25rem;
  margin-bottom: 1.5rem 0;
  white-space: normal;
  width: 100%;
}

.role-emoji {
  align-items: center;
  background: variables.$bubbletape-pink-10;
  border-radius: 50%;
  display: flex;
  font-size: 2rem;
  justify-content: center;
  height: 4.6875rem;
  width: 4.6875rem;

  &--selected {
    border: 0.0125rem solid variables.$black-default;
  }
}

.role-description {
  max-width: 5.3125rem;
}

@media (min-width: variables.$screen-md-min) {
  .role-btn {
    &:nth-child(2n + 1) {
      margin-left: -2.3125rem;
    }

    &:nth-child(2n) {
      margin-left: 2.3125rem;
    }
  }
}
</style>

import { computed, toValue } from 'vue';
import { useCapability } from '@/composables/useCapability';

const subjectCapabilityMap = Object.freeze({
  digital_assets: 'manage_digital_asset_notes',
});

export function useNonCreatorEditingAllowed(subjectType) {
  const capability = computed(
    () => subjectCapabilityMap[toValue(subjectType)] ?? null
  );

  const { hasCapability: canManageNotes } = useCapability(
    {
      capability: capability,
    },
    {
      enabled: computed(() => !!capability.value),
    }
  );

  return computed(() => !!capability.value && canManageNotes.value);
}

import { http } from '../config/vue-axios';

export async function getProductsSearch(
  { locationId, productType, selfServable, includesMediaAddOns, orderId } = {},
  signal
) {
  const params = {};

  if (locationId) params['location_id'] = locationId;
  if (productType) params['product_type'] = productType;
  if (selfServable) params['self_servable'] = selfServable;
  if (includesMediaAddOns)
    params['includes_media_add_ons'] = includesMediaAddOns;
  if (orderId) params['order_id'] = orderId;

  const response = await http.get(`products/search`, {
    params,
    signal,
  });

  return response.data;
}

export async function getProServiceProducts(signal) {
  const response = await http.get(`/products/pro_service_products.json`, {
    signal,
  });

  return response.data;
}

export async function getTiers(signal) {
  const response = await http.get(`/tiers.json`, {
    signal,
  });

  return response.data;
}

export async function getTierBySlug(tierSlug, signal) {
  const response = await http.get(`/tiers/${tierSlug}.json`, {
    signal,
  });

  return response.data;
}

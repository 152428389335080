<script setup>
import { ref, computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useGetTags } from '@/queries/useGetTags';
import { useRoute } from 'vue-router';
import CarouselLoading from '@/components/platform-home/CarouselLoading.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import ProServicesDialog from './ProServicesDialog.vue';
import SoonaImageCard from '@/components/ui_library/SoonaImageCard.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const accountId = computed(() => props.accountId);

const showDialog = ref(false);
const selectedTagId = ref(null);

const context = route.meta.context;
const subContext = 'professional services';

const { data: proServices, isLoading } = useGetTags({
  tag_category_slug: 'pro-service-sm',
  order_by_industry: true,
});

const tagList = computed(() => proServices.value);

const handleCardClick = tag => {
  selectedTagId.value = tag.id;
  showDialog.value = true;

  linkClicked({
    context: context,
    subContext: subContext,
    linkLabel: tag.title,
    linkHref: null,
  });
};

const handleDialogClose = () => {
  showDialog.value = false;
  selectedTagId.value = null;
};
</script>

<template>
  <section class="pro-services" data-cypress="section-dashboard-pro-services">
    <h3
      id="dashboard-pro-services"
      class="u-title--heavy"
      data-cypress="text-pro-services-heading"
    >
      professional services
    </h3>
    <CarouselLoading v-if="isLoading" />
    <PageContainerOffset v-else>
      <SoonaSlider tag-name="ul" aria-labelledby="dashboard-pro-services">
        <SoonaImageCard
          v-for="tag in tagList"
          :key="tag.id"
          animation-delay="0s"
          :cypress-name="`button-slider-${tag.title.replace(/\s+/g, '-')}`"
          @click="() => handleCardClick(tag)"
        >
          <img :src="tag.image_url" alt="" />
          <template #caption>{{ tag.title }}</template>
        </SoonaImageCard>
      </SoonaSlider>
    </PageContainerOffset>
  </section>
  <ProServicesDialog
    v-if="showDialog"
    :account-id="accountId"
    :tag-id="selectedTagId"
    @close="handleDialogClose"
  />
</template>

<style scoped lang="scss">
.pro-services {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}
</style>

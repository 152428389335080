import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getTags } from '../api/tags';

/**
 *
 * @param {object | Ref<object>} params
 */
export function useGetTags(params, queryOptions) {
  return useQuery({
    queryKey: queryKeys.tags(params),
    queryFn: ({ signal }) => getTags(unref(params), signal),
    ...queryOptions,
  });
}

<script setup>
const inputValues = defineModel({ required: true, type: Array });

const updateFormats = (format, e) => {
  let currentFormats = inputValues.value.slice();
  if (e.target.checked) {
    currentFormats.push(format);
  } else {
    currentFormats = currentFormats.filter(f => f !== format);
  }

  inputValues.value = currentFormats;
};
</script>
<template>
  <div>
    <h3 class="u-badge--large">format:</h3>
    <ul>
      <li>
        <input
          id="gif-format--gif"
          type="checkbox"
          value="gif"
          name="formats"
          :checked="inputValues.includes('gif')"
          @change="updateFormats('gif', $event)"
        />
        <label for="gif-format--gif" class="u-body--heavy">
          I need .gif
          <span class="u-label--small">perfect for emails!</span>
        </label>
      </li>
      <li>
        <input
          id="gif-format--mp4"
          type="checkbox"
          value="mp4"
          name="formats"
          :checked="inputValues.includes('mp4')"
          @change="updateFormats('mp4', $event)"
        />
        <label for="gif-format--mp4" class="u-body--heavy">
          I need .mp4
          <span class="u-label--small">perfect for Instagram!</span>
        </label>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
h3 {
  padding-bottom: 1rem;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  li {
    display: flex;
    gap: 0.5rem;

    input:focus-visible,
    label:focus-visible {
      outline: none;
    }

    label {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      gap: 0.25rem;
    }
  }
}
</style>

import { http } from '../config/vue-axios';

/**
 *
 * @param {Number} [reservationId]
 * @param {String} [status]
 */
export async function updateShotListStatus(reservationId, status) {
  const response = await http.put(
    `/reservations/${reservationId}/${status}.json`
  );
  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {AbortSignal} [signal]
 */
export async function getShotList(reservationId, signal) {
  const response = await http.get(`/reservations/${reservationId}/shots.json`, {
    signal,
  });

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {AbortSignal} [signal]
 */
export async function getActiveShot(reservationId, signal) {
  const response = await http.get(
    `/reservations/${reservationId}/active_shot.json`,
    {
      signal,
    }
  );

  if (response.status === 204) {
    return null;
  }

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {number} shotId
 * @param {AbortSignal} [signal]
 */
export async function setActiveShot(reservationId, shotId, signal) {
  const response = await http.post(
    `/reservations/${reservationId}/set_active_shot.json`,
    { active_shot_id: shotId },
    {
      signal,
    }
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {Object} params
 * @param {AbortSignal} [signal]
 */
export async function createShot(reservationId, params, signal) {
  const response = await http.post(
    `/reservations/${reservationId}/shots.json`,
    {
      shot: params,
    },
    {
      signal,
    }
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {Object} params
 * @param {AbortSignal} [signal]
 */
export async function updateShot(reservationId, params, signal) {
  const response = await http.put(
    `/reservations/${reservationId}/shots/${params.id}.json`,
    {
      shot: params,
    },
    {
      signal,
    }
  );

  return response.data;
}

<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useCollaborators } from '@/queries/collaborators/useCollaborators';
import { useUpdateAccountOwner } from '@/queries/account/useUpdateAccountOwner';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  collaborator: {
    type: Object,
    required: true,
  },
});

defineEmits(['close']);

const accountId = computed(() => Number(props.accountId));

const currentAccountOwner = ref(undefined);
const newAccountOwner = ref(undefined);
const showOwnerTypeWarning = ref(false);
const disableSubmit = ref(true);

const {
  data: collaborators,
  error: collaboratorsError,
  isLoading: collaboratorsLoading,
} = useCollaborators(accountId);
const collaboratorsList = computed(
  () =>
    collaborators.value?.collaborators.map(x => ({
      label: `${x.name} (${x.email})`,
      value: x,
    })) ?? []
);

watchEffect(() => {
  let accountOwner = collaborators.value?.collaborators.find(
    x => x.is_account_owner
  );
  currentAccountOwner.value = accountOwner;
  newAccountOwner.value = accountOwner;
});

watch(newAccountOwner, newOwner => {
  if (!newOwner) return;

  disableSubmit.value = newOwner.id === currentAccountOwner.value?.id;

  showOwnerTypeWarning.value = newOwner.role?.name !== 'account admin';
});

const {
  mutate,
  isPending: isUpdateOwnerLoading,
  error: updateOwnerError,
} = useUpdateAccountOwner(accountId);

function updateAccountOwner(close) {
  mutate(
    {
      owner_user_id: newAccountOwner.value.user_id,
    },
    { onSuccess: () => close() }
  );
}

const priorityError = usePriorityError(collaboratorsError, updateOwnerError);
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>change account owner</template>
    <template #default>
      <SoonaSelect
        v-model="newAccountOwner"
        class="account-owner-dialog__collaborators-list"
        :loading="collaboratorsLoading"
        :options="collaboratorsList"
        :disabled="isUpdateOwnerLoading"
      >
        <template #label>account owner</template>
      </SoonaSelect>
      <div
        v-if="showOwnerTypeWarning"
        class="account-owner-dialog__alert"
        aria-live="polite"
      >
        <p>
          {{ newAccountOwner.name }} does not currently hold an admin role on
          this account. making them owner will grant admin privileges.
        </p>
      </div>
      <div class="account-owner-dialog__alert">
        <p>
          changing the account owner will permanently change the primary contact
          person for the account. the former owner will remain on the account in
          an admin role. if you wish to remove the former owner, you may do so
          after changing ownership.
        </p>
      </div>
      <SoonaError v-if="priorityError">
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        variation="primary"
        :disabled="disableSubmit"
        :loading="isUpdateOwnerLoading"
        @on-click="updateAccountOwner(close)"
      >
        change owner
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.account-owner-dialog {
  &__alert {
    border-radius: 0.3125rem;
    border: 0.0625rem solid variables.$tangerine-40;
    background-color: variables.$tangerine-10;
    gap: 0.5rem;
    margin-top: 1rem;
    padding: 0.75rem;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { useTimeoutFn } from '@vueuse/core';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { soonaToastVariations } from './soona-toast-variations';

const props = defineProps({
  variation: {
    default: undefined,
    type: String,
    validator: function (value) {
      return ['info', 'success', 'error', 'alert'].includes(value);
    },
  },
  iconName: {
    type: String,
    default: undefined,
    required: false,
  },
  iconColor: {
    type: String,
    default: undefined,
    required: false,
  },
  iconBg: {
    type: String,
    default: undefined,
    required: false,
  },
  noDismissButton: {
    type: Boolean,
    default: false,
    required: false,
  },
  noTimeout: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const emit = defineEmits(['close']);

if (!props.noTimeout) {
  useTimeoutFn(
    () => {
      emit('close');
    },
    6000,
    { immediate: true }
  );
}

const handleClose = () => {
  emit('close');
};

const config = computed(() => {
  const variation =
    (props.variation && soonaToastVariations[props.variation]) || null;

  return {
    ...variation,
    ...(props.iconName && { iconName: props.iconName }),
    ...(props.iconColor && { iconColor: props.iconColor }),
    ...(props.iconBg && { iconBg: props.iconBg }),
    ...(props.noDismissButton && { noDismissButton: props.noDismissButton }),
  };
});
</script>

<template>
  <div class="soona-toast">
    <div v-if="config.iconName" class="soona-toast__icon">
      <SoonaIcon :name="config.iconName" size="medium" />
    </div>
    <div class="soona-toast__content" role="status">
      <h2 class="u-body--heavy"><slot /></h2>
      <p v-if="$slots['subtext']" class="u-label--regular soona-toast__subtext">
        <slot name="subtext" />
      </p>
    </div>
    <SoonaButton
      v-if="!config.noDismissButton"
      class="soona-toast__close"
      :class="{
        'soona-toast__close--top-aligned': $slots['subtext'],
      }"
      variation="icon-plain-gray"
      size="small"
      type="button"
      aria-label="dismiss"
      @click="handleClose"
    >
      <SoonaIcon name="xmark" size="small" />
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-toast {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  background-color: variables.$white-default;
  padding: 0.5rem;
  border: 0.0625rem solid variables.$black-translucent-04;
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.16);
  border-radius: 0.3125rem;

  &__icon {
    color: v-bind('config.iconColor');
    background-color: v-bind('config.iconBg');
    flex: 0 0 2rem;
    padding: 0.375rem;
    border-radius: 50%;

    svg {
      display: block;
    }
  }

  &__content:first-child {
    margin-left: 0.25rem;
  }

  &__subtext {
    color: variables.$gray-60;
  }

  &__close {
    flex: 0 0 1rem;
    height: 1rem;
    margin-left: auto;

    &--top-aligned {
      align-self: flex-start;
    }

    svg {
      display: block;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';

const props = defineProps({
  accountId: { type: [String, Number], required: true },
  item: {
    type: Object,
    required: true,
  },
  removable: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'large',
    validator: function (value) {
      return ['large', 'medium'].includes(value);
    },
  },
  isLink: {
    type: Boolean,
    default: true,
  },
});

const accountId = computed(() => props.accountId);
const item = computed(() => props.item);
const removable = computed(() => props.removable);
const size = computed(() => props.size);
</script>

<template>
  <SoonaChip
    class="product-chip"
    :to="
      isLink
        ? {
            path: `/account/${accountId}/products/${item.id}`,
          }
        : null
    "
    :removable="removable"
    :image-src="item.preview_url ?? PlaceholderImage"
    :size="size"
    image-alt="product image"
  >
    {{ item.name }}
  </SoonaChip>
</template>

import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { getAccountsRoles } from '@/api/accounts';

export function useAccountsRoles() {
  return useQuery({
    queryKey: queryKeys.accountsRoles(),
    queryFn: ({ signal }) => getAccountsRoles({ signal }),
    // these don't change, let's cache for a for minutes at least
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  });
}

<template>
  <div class="QuickReactionAddedMessage">
    <div :class="isOutgoing ? 'outgoing' : 'incoming'">
      <div class="name_and_timestamp">
        <p class="name">{{ message.sender.name.split(' ', 1)[0] }}</p>
        &middot;
        <p class="timestamp">
          {{ convertToHHMM12(message.sentAt * 1000) }}
        </p>
      </div>
      <div class="avatar_and_message">
        <ChatAvatar v-if="!isOutgoing" :message="message" />
        <p class="message_text">{{ message.data.customData.emoji }}</p>
        <ChatAvatar v-if="isOutgoing" :message="message" />
      </div>
    </div>
  </div>
</template>

<script>
import ChatAvatar from '../ChatAvatar.vue';
import dateTimeMixin from '@/mixins/dateTimeMixin';

export default {
  name: 'QuickReactionAddedMessage',
  components: {
    ChatAvatar,
  },
  mixins: [dateTimeMixin],
  props: {
    message: Object,
    isOutgoing: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.QuickReactionAddedMessage {
  padding: 0.75rem 0;
  .name_and_timestamp {
    display: flex;
    align-items: center;
    color: variables.$gray-90;
    .name {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.5px;
      padding: 3px;
    }
    .timestamp {
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.5px;
      padding: 3px;
    }
  }
  .message_text {
    font-size: 28px;
    line-height: 28px;
  }
  .incoming {
    display: flex;
    flex-direction: column;

    .name_and_timestamp {
      justify-content: flex-start;
      margin-left: 42px;
    }
    .avatar_and_message {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .message_text {
        margin-left: 10px;
        margin-right: 37px;
      }
    }
  }
  .outgoing {
    display: flex;
    flex-direction: column;

    .name_and_timestamp {
      justify-content: flex-end;
      margin-right: 42px;
    }
    .avatar_and_message {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .message_text {
        margin-right: 10px;
        margin-left: 37px;
      }
    }
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getLocationAvailability, getLocations } from '../api/location.js';

/**
 *
 * @param {string | number} locationId
 * @param {Object} [params]
 * @param {string | number} [params.reservationId]
 * @param { Date | string } [params.month]
 */
export function useLocationAvailability(
  locationId,
  { duration, month, reservationId, showAll } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.locationAvailability({
      duration,
      locationId,
      month,
      reservationId,
      showAll,
    }),
    queryFn: ({ signal }) =>
      getLocationAvailability(
        unref(locationId),
        {
          reservationId: unref(reservationId),
          month: unref(month),
          duration: unref(duration),
          showAll: unref(showAll),
        },
        signal
      ),
    ...queryOptions,
  });
}

export function useLocations(locationTypes = []) {
  return useQuery({
    queryKey: queryKeys.locations(unref(locationTypes)),
    queryFn: ({ signal }) => getLocations(unref(locationTypes), signal),
  });
}

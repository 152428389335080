<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="18" height="18" rx="9" fill="#fff" />
    <path
      d="M9 1C4.584 1 1 4.584 1 9s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8ZM7.4 13l-4-4 1.128-1.128L7.4 10.736l6.072-6.072L14.6 5.8 7.4 13Z"
      fill="#508b18"
    />
  </svg>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  component: {
    type: String,
    default: 'div',
  },
});

const headingWrapperEl = computed(() =>
  props.component === 'fieldset' ? 'legend' : 'div'
);
</script>

<template>
  <component :is="component" class="scene-fieldset">
    <component :is="headingWrapperEl" class="scene-fieldset__legend">
      <h5>
        <slot name="heading" />
      </h5>
    </component>
    <p v-if="$slots.description" class="scene-fieldset__description">
      <slot name="description" />
    </p>
    <slot />
  </component>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.scene-fieldset {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &:not(:last-child) {
    padding-bottom: 1.5rem;
    border-bottom: 0.0625rem solid variables.$gray-30;
  }

  &__legend h5 {
    @include variables_fonts.u-label--heavy;
  }

  &__description {
    display: block;
    @include variables_fonts.u-label--regular;
    color: variables.$gray-60;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import { useSchedulingPreferences } from '@/composables/useSchedulingPreferences';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  defaultIsDirty: {
    type: Boolean,
    required: true,
  },
  editMode: {
    type: Boolean,
    required: true,
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['reset-to-default', 'update:subject-type']);

const {
  schedulingPreferenceLookup,
  hasAccountPreference,
  isCustomPreference,
  subjectType,
} = useSchedulingPreferences(
  computed(() => props.accountId),
  computed(() => props.reservationId)
);

const defaultIsDirty = computed(() => props.defaultIsDirty);

const schedulingPreferenceType = computed(() => {
  if (defaultIsDirty.value || isCustomPreference.value) {
    return 'Custom';
  } else {
    return subjectType.value;
  }
});

const showAccountPreferenceOptions = computed(() => {
  return props.editMode && defaultIsDirty.value && hasAccountPreference.value;
});
</script>
<template>
  <div
    v-if="isCustomPreference || hasAccountPreference"
    class="availability-actions"
  >
    <div class="availability-actions__label-wrapper">
      <div class="availability-actions__label">
        <SoonaIcon
          :class="schedulingPreferenceLookup[schedulingPreferenceType].class"
          :name="schedulingPreferenceLookup[schedulingPreferenceType].iconName"
          size="small"
          style="vertical-align: middle"
        />
        {{ schedulingPreferenceLookup[schedulingPreferenceType].copy }}
      </div>
      <SoonaButton
        v-if="showAccountPreferenceOptions"
        type="button"
        variation="tertiary"
        @click="emit('reset-to-default')"
      >
        reset to default
      </SoonaButton>
    </div>
    <SoonaToggle
      v-if="showAccountPreferenceOptions"
      :model-value="false"
      type="checkbox"
      label="update default availability to current selections"
      name="set-account-preference"
      @update:model-value="emit('update:subject-type', $event)"
    />
  </div>
  <template v-else-if="!isDialog && editMode">
    <SoonaToggle
      :model-value="true"
      type="checkbox"
      label="apply to all future bookings"
      name="set-account-preference"
      @update:model-value="emit('update:subject-type', $event)"
    />
    <p class="availability-actions__label--subtext">you can update anytime</p>
  </template>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.availability-actions {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
  &__label-wrapper {
    display: flex;
    gap: 1rem;
  }
  &__label {
    align-items: center;
    display: flex;
    gap: 0.25rem;

    &--subtext {
      @include variables_fonts.u-label--regular;

      color: variables.$gray-60;
      margin-left: 1.5rem;
    }
  }
}

.check-icon {
  color: variables.$green-apple-50;
}

.info-icon {
  color: variables.$periwink-blue-60;
}
</style>

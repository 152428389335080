<script setup>
import { ref } from 'vue';
import { useMutationObserver, useTimeoutFn } from '@vueuse/core';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

const isHubSpotChatClosed = ref(true);
const hubSpotChatRef = ref(null);

const { start: startHideHubSpotChatTimer } = useTimeoutFn(
  () => {
    window.HubSpotConversations.widget.remove();
  },
  200,
  { immediate: false }
);

const closeHubSpotChat = () => {
  window.HubSpotConversations?.widget?.close();
  startHideHubSpotChatTimer();
};

useMutationObserver(
  hubSpotChatRef,
  mutationList => {
    for (const mutation of mutationList) {
      if (
        mutation.type === 'childList' &&
        mutation.addedNodes.length > 0 &&
        isHubSpotChatClosed.value
      ) {
        for (const node of mutation.addedNodes) {
          if (node.id === 'hubspot-conversations-inline-parent') {
            isHubSpotChatClosed.value = false;
          }
        }
      } else if (
        mutation.type === 'childList' &&
        mutation.removedNodes.length > 0 &&
        !isHubSpotChatClosed.value
      ) {
        for (const node of mutation.removedNodes) {
          if (node.id === 'hubspot-conversations-inline-parent') {
            isHubSpotChatClosed.value = true;
          }
        }
      }
    }
  },
  {
    childList: true,
  }
);
</script>

<template>
  <div
    id="hubspot-chat-container"
    ref="hubSpotChatRef"
    class="hubspot-chat"
    :class="{ 'hubspot-chat--closed': isHubSpotChatClosed }"
  >
    <button @click="closeHubSpotChat">
      <SoonaIcon name="chevron-down" />
      <span class="u-visually-hidden">Close chat</span>
    </button>
  </div>
</template>

<style lang="scss">
@use '@/variables';

.hubspot-chat {
  bottom: 0;
  left: 0;
  height: 100%;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  transition:
    opacity 0.2s,
    visibility 0.2s;
  visibility: visible;
  width: 100%;

  @media (min-width: variables.$screen-sm-min) {
    bottom: 1.25rem;
    height: 40.625rem;
    left: auto;
    position: absolute;
    right: 1.25rem;
    top: auto;
    width: 24rem;
  }

  &--closed {
    opacity: 0;
    visibility: hidden;
  }

  button {
    align-items: center;
    background: transparent;
    border: none;
    border-radius: 50%;
    color: variables.$white-default;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    position: absolute;
    right: 3.5rem;
    top: 1rem;
    transition: 0.2s;
    width: 2.5rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

#hubspot-conversations-inline-parent {
  height: 100%;
  width: 100%;
}

#hubspot-conversations-inline-iframe {
  border: none;
  box-shadow: variables.$elevation-2;
  height: 100%;
  width: 100%;

  @media (min-width: variables.$screen-sm-min) {
    border-radius: 0.5rem;
  }
}
</style>

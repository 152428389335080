import { readonly, ref, watchEffect } from 'vue';
import { CometChat } from '@cometchat-pro/chat';
import * as Sentry from '@sentry/vue';

export function useCometChatConnectionStatus() {
  const connectionStatus = ref(CometChat.getConnectionStatus());

  watchEffect(onCleanup => {
    const listenerId = 'CONNECTION_LISTENER';
    CometChat.addConnectionListener(
      listenerId,
      new CometChat.ConnectionListener({
        onConnected: () => {
          connectionStatus.value = CometChat.CONNECTION_STATUS.CONNECTED;
          Sentry.addBreadcrumb({
            category: 'chat',
            message: `websocket status connected`,
            level: 'info',
          });
        },
        inConnecting: () => {
          connectionStatus.value = CometChat.CONNECTION_STATUS.CONNECTING;
          Sentry.addBreadcrumb({
            category: 'chat',
            message: `websocket status connecting`,
            level: 'info',
          });
        },
        onDisconnected: () => {
          connectionStatus.value = CometChat.CONNECTION_STATUS.DISCONNECTED;
          Sentry.addBreadcrumb({
            category: 'chat',
            message: `websocket status disconnected`,
            level: 'info',
          });
        },
        onFeatureThrottled: () => {
          connectionStatus.value =
            CometChat.CONNECTION_STATUS.FEATURE_THROTTLED;
          Sentry.addBreadcrumb({
            category: 'chat',
            message: `websocket status feature throttled`,
            level: 'info',
          });
        },
      })
    );

    onCleanup(() => {
      CometChat.removeConnectionListener(listenerId);
    });
  });

  function connect() {
    CometChat.connect();
  }

  function disconnect() {
    CometChat.disconnect();
  }

  return {
    connectionStatus: readonly(connectionStatus),
    connect,
    disconnect,
  };
}

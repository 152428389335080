<script setup>
import { computed, ref } from 'vue';
import ShotListNoteDialog from './ShotListNoteDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import TwoDaysPriorAlertDialog from './TwoDaysPriorAlertDialog.vue';
import UploadShotListDialog from './UploadShotListDialog.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useReservation } from '@/composables/useReservation';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUpdateShotList } from '@/queries/shot_list/useUpdateShotList';
import {
  convertToMMDDYY,
  convertToHHMM12,
  browserTimeZone,
} from '@/lib/date-formatters';

const props = defineProps({
  headingElement: {
    default: 'h4',
    type: String,
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
  shotListDocuments: {
    type: Object,
    default: () => ({}),
  },
  status: {
    type: String,
    default: null,
  },
});

const { addToast } = useSoonaToast();

const showUploadShotlistDialog = ref(false);
const showShotListNoteDialog = ref(false);
const showChangesDialog = ref(false);

const subHeadingElement = computed(() => {
  const headingElement = props.headingElement.slice(-1);

  return `h${+headingElement + 1}`;
});

const draftShotList = computed(() => props.status === 'draft');

const reservationId = computed(() => props.reservationId);
const shotListId = computed(() => props.shotListDocuments?.id);

const {
  isLessThanTwoBusinessDaysBeforeShoot,
  isPendingShotlist,
  isPreShoot,
  scheduledDateFormatted,
  shotListNote,
} = useReservation(reservationId);

const isEditable = computed(
  () => draftShotList.value && (isPreShoot.value || isPendingShotlist.value)
);

const updatedDate = computed(() => {
  return convertToMMDDYY(props.shotListDocuments?.modified_date_time);
});
const updatedTime = computed(() => {
  return convertToHHMM12(props.shotListDocuments?.modified_date_time);
});

const { mutate: updateShotList, error: updateError } = useUpdateShotList(
  reservationId,
  shotListId
);

// links
const urls = computed(() => props.shotListDocuments?.urls);

const handleAddLink = () => {
  if (!scheduledDateFormatted.value) {
    showUploadShotlistDialog.value = true;
    return;
  }

  if (isLessThanTwoBusinessDaysBeforeShoot.value) {
    showChangesDialog.value = true;
  } else {
    showUploadShotlistDialog.value = true;
  }
};

const removeUrl = url => {
  let params = {
    urls: urls.value.filter(link => link !== url),
  };

  updateShotList(params, {
    onSuccess: () => {
      addToast(`successfully removed link`, {
        variation: 'success',
      });
    },
  });
};

// attachments
const documents = computed(() => props.shotListDocuments?.documents);

const removeDocument = document => {
  let params = {
    documents: documents.value
      .filter(doc => doc.id !== document.id)
      .map(doc => doc.signed_id),
  };

  updateShotList(params, {
    onSuccess: () => {
      addToast(`removed ${document.filename}`, {
        variation: 'success',
      });
    },
  });
};

const handleCloseChangeDialog = () => {
  showChangesDialog.value = false;
  showUploadShotlistDialog.value = true;
};

const priorityError = usePriorityError(updateError);
</script>
<template>
  <div class="shot-list-documents">
    <div>
      <component :is="headingElement" class="u-subheader--heavy"
        >attachments</component
      >
      <p class="shot-list-documents__updated">
        updated {{ updatedDate }} at {{ updatedTime }} {{ browserTimeZone() }}
      </p>
    </div>
    <ul class="shot-list-documents__items">
      <li
        v-for="document in documents"
        :key="document.id"
        class="shot-list-documents__item"
      >
        <SoonaIcon name="file" />
        <a
          class="u-body--heavy shot-list-documents__filename"
          :href="document.url"
          target="_blank"
          >{{ document.filename }}</a
        >
        <p class="shot-list-documents__item--document-size">
          {{ Math.floor(+document.file_size / 1000) }}KB
        </p>
        <SoonaTooltip v-if="isEditable">
          <template #default="{ setRef, mouseenter, focus, mouseleave, blur }">
            <SoonaButton
              :ref="el => setRef(el)"
              variation="icon-plain-gray"
              size="small"
              @on-click="removeDocument(document)"
              @mouseenter="mouseenter"
              @focus="focus"
              @mouseleave="mouseleave"
              @blur="blur"
            >
              <SoonaIcon name="xmark" />
            </SoonaButton>
          </template>
          <template #tooltip-content>remove this file</template>
        </SoonaTooltip>
      </li>
      <li v-for="url in urls" :key="url" class="shot-list-documents__item">
        <SoonaIcon name="link" />
        <a class="shot-list-documents__url" target="_blank" :href="url">{{
          url
        }}</a>
        <SoonaTooltip v-if="isEditable">
          <template #default="{ setRef, mouseenter, focus, mouseleave, blur }">
            <SoonaButton
              :ref="el => setRef(el)"
              variation="icon-plain-gray"
              size="small"
              @on-click="removeUrl(url)"
              @mouseenter="mouseenter"
              @focus="focus"
              @mouseleave="mouseleave"
              @blur="blur"
            >
              <SoonaIcon class="shot-list-documents__item--icon" name="xmark" />
            </SoonaButton>
          </template>
          <template #tooltip-content>remove this URL</template>
        </SoonaTooltip>
      </li>
    </ul>
    <div v-if="isEditable" class="shot-list-documents__button-group">
      <SoonaButton variation="tertiary" @on-click="handleAddLink">
        <SoonaIcon name="plus" />add attachment
      </SoonaButton>
      <SoonaButton
        v-if="!shotListNote"
        variation="tertiary"
        @on-click="showShotListNoteDialog = true"
      >
        <SoonaIcon name="plus" />add notes
      </SoonaButton>
    </div>
    <div v-if="shotListNote" class="shot-list-documents__note">
      <div class="shot-list-documents__note--header">
        <component :is="subHeadingElement" class="u-subheader--heavy"
          >note to the crew</component
        >
        <SoonaButton
          v-if="isEditable"
          variation="tertiary"
          size="medium"
          @on-click="showShotListNoteDialog = true"
        >
          <SoonaIcon name="pen-square" />edit
        </SoonaButton>
      </div>
      <p>{{ shotListNote }}</p>
    </div>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
  </div>

  <UploadShotListDialog
    v-if="showUploadShotlistDialog"
    :reservation-id="reservationId"
    :shot-list-documents="shotListDocuments"
    @close-dialog="showUploadShotlistDialog = false"
  />
  <ShotListNoteDialog
    v-if="showShotListNoteDialog"
    :reservation-id="reservationId"
    :shot-list-note="shotListNote"
    @close-dialog="showShotListNoteDialog = false"
  />
  <TwoDaysPriorAlertDialog
    v-if="showChangesDialog"
    @close-dialog="handleCloseChangeDialog"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.shot-list-documents {
  align-items: flex-start;
  background-color: variables.$gray-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: variables.$radius-large;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0;
  padding: 1.5rem;
  width: 100%;

  &__updated {
    @include variables_fonts.u-body--regular;
    color: variables.$gray-70;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  &__item {
    align-items: center;
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: variables.$radius-small;
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem;
    width: 100%;

    svg {
      color: variables.$gray-60;
      flex-shrink: 0;
    }

    &--document-size {
      @include variables_fonts.u-body--regular;

      color: variables.$gray-60;
    }
  }

  &__filename {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__url {
    @include variables_fonts.u-body--regular;

    text-decoration: underline;
    color: variables.$periwink-blue-70;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__button-group {
    display: flex;
    gap: 1rem;
  }

  &__note {
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-height: 15rem;
    overflow-y: auto;
    padding: 1em;
    white-space: pre-wrap;
    width: 100%;

    &--header {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>

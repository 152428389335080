import { useAssistant } from '@/components/ai_assistant/composables/useAssistant';
import { useAssistantIds } from '@/components/ai_assistant/composables/useAssistantIds';

import { useFunctionHandler } from './useFunctionHandler';
import { useAiProducerStore } from './useAiProducerStore';

export function useAiProducer() {
  const { $reset } = useAiProducerStore();
  const { functionHandler } = useFunctionHandler();
  const { packAssistant, packAssistantDev } = useAssistantIds();

  const soonaEnv = import.meta.env.VITE_SOONA_ENV;

  const assistantId = soonaEnv === 'prod' ? packAssistant : packAssistantDev;

  return useAssistant({
    assistantId,
    functionHandler,
    includes: ['packs'],
    onReset: () => $reset(),
  });
}

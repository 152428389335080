<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import fullBodyModelImg from '@images/pro-services/full-body-model.jpg';
import handModelImg from '@images/pro-services/hand-model.jpg';
import petModelImg from '@images/pro-services/pet-model.jpg';
import stylistImg from '@images/pro-services/stylist.jpg';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  reservationId: {
    required: true,
    type: [Number, String],
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const trackLinkClicked = () => {
  linkClicked({
    context: route.meta?.context,
    subContext: 'pro service upsell card',
    linkLabel: 'add pro services',
    linkHref: '/reservation/shoot-upgrades',
  });
};

const imgList = computed(() => {
  return [
    {
      src: fullBodyModelImg,
      alt: 'full body model',
      caption: 'model',
    },
    {
      src: handModelImg,
      alt: 'hand model',
      caption: 'hand model',
    },
    {
      src: petModelImg,
      alt: 'pet model',
      caption: 'pet model',
    },
    {
      src: stylistImg,
      alt: 'stylist',
      caption: 'stylist',
    },
  ];
});
</script>

<template>
  <aside class="pro-service-upsell-card">
    <div class="card-inner">
      <div class="card-left">
        <div class="card-icon">
          <SoonaIcon name="lightbulb-alt-monochrome" />
        </div>
        <div class="upsell-card-content">
          <strong class="u-badge--small card-status">optional</strong>
          <h2 class="u-subheader--heavy card-title">elevate your shoot</h2>
          <p class="u-body--regular">it’s not too late to add more pros...</p>
          <div class="model-types">
            <figure v-for="img in imgList" :key="img.alt.replace(/\s+/g, '-')">
              <img :src="img.src" :alt="img.alt" />
              <figcaption class="u-small--regular">
                {{ img.caption }}
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="card-right">
        <SoonaButton
          variation="secondary-black"
          element="router-link"
          size="medium"
          :to="{
            name: 'shoot-upgrades',
            params: { reservationId: reservationId },
          }"
          @on-click="trackLinkClicked"
        >
          add pro services
        </SoonaButton>
      </div>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
@use '@/variables';

.pro-service-upsell-card {
  .card-inner {
    background-color: variables.$white-default;
    padding: 1rem;
    border: 0.0625rem solid variables.$gray-30;
    box-shadow: variables.$elevation-0;
    border-radius: 0.625rem;
    transition:
      background-color 0.1s ease-out,
      box-shadow 0.1s ease-out,
      border-color 0.1s ease-out;
  }

  .card-left {
    display: flex;
    gap: 0.75rem;
    padding: 0;
    margin-bottom: 0.75rem;
  }

  .card-right {
    padding: 0;
    gap: 1rem;
  }

  .card-icon {
    background-color: variables.$periwink-blue-10;
    border-color: variables.$periwink-blue-20;
    color: variables.$periwink-blue-50;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;
    flex: 0 0 2.75rem;
    height: 2.75rem;
    width: 2.75rem;
    border-radius: 50%;
    border: 0.0625rem solid transparent;

    svg {
      display: block;
    }
  }

  .upsell-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.5rem;
    max-width: 20.75rem;
    padding-right: 1.5rem;
  }

  .card-status {
    font-weight: 900;
    display: block;
    text-transform: uppercase;
    color: variables.$periwink-blue-50;
  }

  .card-title {
    text-transform: lowercase;
    transition: color 0.1s ease-out;
  }

  .model-types {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;

    figure {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
    }

    img {
      border-radius: 50%;
      height: 3rem;
      width: 3rem;
    }
  }

  .card-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

@container csr-action-items (min-width: 36rem) {
  .card-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
  }

  .card-left {
    flex-grow: 1;
    margin-bottom: 0;
  }

  .card-icon {
    margin: 0 0.75rem 0 0;
  }
}
</style>

<template>
  <div class="ShootExceptionNote">
    <hr class="order-divider" />
    <h3
      :id="`shoot-exception-heading-${shootException.id}`"
      class="shoot-exception-header"
    >
      <button
        class="u-button-reset"
        :aria-expanded="open"
        :aria-controls="`shoot-exception-container-${shootException.id}`"
        @click="toggleOpen"
      >
        <span class="header-text u-body--all-caps-black">{{ header }}</span>
        <SoonaIcon :class="['message-chevron', { open }]" name="chevron-down" />
      </button>
    </h3>
    <div
      :id="`shoot-exception-container-${shootException.id}`"
      ref="collapsible"
      class="shoot-exception-note-container"
      :hidden="!open"
      :aria-labelledby="`shoot-exception-heading-${shootException.id}`"
      role="region"
    >
      <div class="shoot-exception-details">
        <p>
          {{
            isClientParticipation ? 'participation level' : 'unstart reason'
          }}:
          {{ shootException.reason }}
        </p>
        <p>notes: {{ shootException.note ? shootException.note : '' }}</p>
        <p>created by: {{ shootException.user_name }}</p>
        <p>
          created at:
          {{ shootExceptionDate.toLocaleDateString() }}
          {{
            shootExceptionDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              timeZoneName: 'short',
            })
          }}
        </p>
        <p>unscheduled: {{ reservation.end ? 'no' : 'yes' }}</p>
      </div>
      <button
        v-if="isClientParticipation"
        class="delete-button"
        @click="handleDeleteShootException"
      >
        delete
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'ShootExceptionNote',
  components: {
    SoonaIcon,
  },
  props: {
    shootException: Object,
    reservation: Object,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    header() {
      if (this.isClientParticipation) {
        return `shoot exception: ${this.humanReadableShootExceptionType} - ${this.shootException.reason}`;
      } else {
        return `shoot exception:  ${this.humanReadableShootExceptionType}`;
      }
    },
    humanReadableShootExceptionType() {
      return this.shootException.exception_type.replace(/_/g, ' ');
    },
    isClientParticipation() {
      return this.shootException.exception_type === 'client_participation';
    },
    shootExceptionDate() {
      return new Date(this.shootException.created_at);
    },
    reservationId() {
      return this.$route.params.reservationId;
    },
  },
  methods: {
    ...mapActions('reservation', ['deleteShootException']),
    toggleOpen() {
      return (this.open = !this.open);
    },
    collapsibleHeight() {
      return this.$refs.collapsible
        ? this.$refs.collapsible.scrollHeight
        : 1000;
    },
    async handleDeleteShootException() {
      await this.deleteShootException({
        reservationId: this.reservationId,
        shootExceptionId: this.shootException.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.ShootExceptionNote {
  margin-bottom: 1.5rem;

  .order-divider {
    height: 4px;
    margin: 0 0 1.5rem;
    background-color: variables.$gray-30;
  }
  .shoot-exception-header > button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      variables.$tangerine-40;
    padding: 1rem;
    width: 100%;
    white-space: normal;
    text-align: left;
  }

  .header-text {
    color: variables.$gray-90;
  }

  .message-chevron {
    color: inherit;
    transition: transform 300ms ease-out;

    &.open {
      transform: rotate(180deg);
    }
  }
  .shoot-exception-note-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;

    &[hidden] {
      display: none;
    }
  }
  .delete-button {
    background: transparent;
    color: variables.$periwink-blue-60;
    text-decoration: underline;
    font-size: 12px;
  }
}
</style>

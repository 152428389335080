<script setup>
import { computed, toRefs } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps.js';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaSegmentedRadios from '@/components/ui_library/SoonaSegmentedRadios.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const {
  data: reservation,
  isLoading: isLoadingReservation,
  error: reservationError,
} = useReservation(reservationId);

const isPreferred = computed(
  () => reservation.value?.account?.preferred?.is_preferred ?? false
);
const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');

const tagCategorySlug = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return 'media-type-membership';
  } else if (isPreferred.value) {
    return 'media-type-preferred';
  } else {
    return 'media-type';
  }
});

const {
  data: mediaTypeData,
  isLoading,
  error: mediaTypeError,
} = useReservationTags(reservationId, tagCategorySlug.value);

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId, { skipInvalidate: true });

const { stepIsIncomplete } = useIncompleteSteps(reservation, slug);

const mediaTypeOptions = computed(() => {
  return (
    mediaTypeData.value?.options.map(x => ({
      label: x.tag.title,
      value: x.tag.id,
    })) ?? []
  );
});

const selectedType = computed(() =>
  mediaTypeData.value?.options.find(x => x.selected === true)
);
const selectedTypeValue = computed(
  () => selectedType.value?.tag.id ?? undefined
);

function onSelect(id) {
  if (selectedType.value) {
    removeReservationTag(
      {
        reservationTagId: selectedType.value.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSuccess: () => {
          addReservationTag({ tagId: id });
        },
      }
    );
  } else {
    addReservationTag({ tagId: id });
  }
}

const priorityError = usePriorityError(
  mediaTypeError,
  addError,
  removeError,
  reservationError,
  stepIsIncomplete
);

const isBusy = computed(
  () =>
    isLoading.value ||
    isAdding.value ||
    isRemoving.value ||
    isLoadingReservation.value
);
</script>

<template>
  <SoonaSegmentedRadios
    label="content type"
    name="content-type"
    :disabled="isBusy"
    :options="mediaTypeOptions"
    :model-value="selectedTypeValue"
    data-cypress="radio-content-type"
    @update:model-value="onSelect"
  />

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useMokkerDigitalAsset } from '@/queries/mokker/useMokkerDigitalAsset';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  previewPayload: {
    type: Object,
    required: true,
  },
  iframeRef: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['closePreview', 'approveImage']);

const { previewPayload, iframeRef } = toRefs(props);

const previewImgUuid = ref(previewPayload.value.uuid);

const renderJobId = computed(
  () =>
    previewPayload.value.renders.find(
      render => render.uuid === previewImgUuid.value
    )?.render_job_id || null
);

const { data: mokkerDigitalAsset, error: mokkerDigitalAssetError } =
  useMokkerDigitalAsset(previewImgUuid);

const assetAccountId = computed(() => mokkerDigitalAsset.value?.account_id);

const assetId = computed(() => mokkerDigitalAsset.value?.id);

const { mediaUrl, error: mediaEditorDigitalAssetError } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const mokkerIframeDomain = import.meta.env.VITE_MOKKER_IFRAME_DOMAIN || '*';

const handleRecreateScene = () => {
  iframeRef.value.contentWindow.postMessage(
    {
      action: 'mokker:recreate-scene',
      payload: { render_job_id: renderJobId.value },
    },
    mokkerIframeDomain
  );
  emit('closePreview');
};

const handleClose = () => {
  emit('closePreview');
};

const approveImage = () => {
  emit('approveImage', assetId.value);
};

const priorityError = usePriorityError(
  mokkerDigitalAssetError,
  mediaEditorDigitalAssetError
);
</script>

<template>
  <SoonaDialog max-width="65rem" max-content-height="100%" @close="handleClose">
    <template #heading>{{ previewPayload.project_title }}</template>
    <div class="approve-generated-asset-dialog">
      <SoonaError v-if="priorityError" :error="priorityError" />
      <div class="approve-generated-asset-dialog__image">
        <SoonaSkeleton
          v-if="!mediaUrl"
          class="approve-generated-asset-dialog__image--skeleton"
        />
        <img
          v-else
          :src="mediaUrl"
          :alt="mokkerDigitalAsset?.preview?.alt_text"
        />
      </div>
    </div>
    <template #footer>
      <SoonaButton
        v-if="!!renderJobId"
        variation="secondary-black"
        @click="handleRecreateScene()"
      >
        recreate scene
      </SoonaButton>
      <SoonaButton variation="primary" @click="approveImage">
        use this image
        <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style scoped lang="scss">
@use '@/variables';

.approve-generated-asset-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    &--skeleton {
      width: 10rem;
      height: 10rem;
      border-radius: 0.625rem;

      @media (min-width: variables.$screen-sm-min) {
        width: 36rem;
        height: 36rem;
      }
    }

    @media (min-width: variables.$screen-sm-min) {
      img {
        max-height: 40rem;
        object-fit: cover;
      }
    }
  }
}
</style>

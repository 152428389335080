<template>
  <ProfileCard
    class-name="BillingDetails"
    title="billing & order history"
    icon="💳"
    :action-path="`/account/${accountId}/orders`"
    action-text="view billing & order history"
  >
    <p class="text-s">
      view order history and update your default payment method for quick
      checkout.
    </p>
  </ProfileCard>
</template>

<script>
import ProfileCard from 'src/components/user/anytime/profile/edit/cards/ProfileCard.vue';

export default {
  name: 'BillingDetails',
  components: { ProfileCard },
  computed: {
    accountId() {
      return this.$route.params.accountId;
    },
  },
};
</script>

<script setup>
import { computed, provide, ref, toRefs } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { usePriorityError } from '@/composables/usePriorityError';
import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_BACK_TEXT,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import MediaEditor from '@/components/user/anytime/gallery/media-editor/MediaEditor.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useFavoritesCollectionDigitalAssetByDigitalAssetId } from '@/queries/favorites-collection-digital-assets/useFavoritesCollectionDigitalAssetByDigitalAssetId';
import truncate from 'lodash/truncate';
import FavoritesCollectionAssetCarousel from './FavoritesCollectionAssetCarousel.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  digitalAssetId: {
    type: [String, Number],
    required: true,
  },
});

const router = useRouter();

const { accountId, digitalAssetId } = toRefs(props);

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const { data: favoritesCollection, error: collectionError } =
  useFavoritesCollection(accountId);
const favoritesCollectionId = computed(() => favoritesCollection.value?.id);

const {
  data: favoritesCollectionDigitalAsset,
  isLoading: isFileLoading,
  error: favoritesCollectionDigitalAssetError,
} = useFavoritesCollectionDigitalAssetByDigitalAssetId(
  favoritesCollectionId,
  digitalAssetId,
  {
    enabled: computed(() => !!favoritesCollectionId.value),
  }
);
const priorityError = usePriorityError(
  collectionError,
  favoritesCollectionDigitalAssetError
);

// routes
const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'favorites-assets-media-view',
    params: {
      accountId: accountId.value,
      digitalAssetId: id,
    },
  };
};

const backUrl = computed(() => {
  return `/account/${accountId.value}/gallery/favorites`;
});

const previousRoute = computed(() => {
  return createRouteLocation(
    favoritesCollectionDigitalAsset.value?.previous?.digital_asset_id
  );
});

const nextRoute = computed(() => {
  return createRouteLocation(
    favoritesCollectionDigitalAsset.value?.next?.digital_asset_id
  );
});

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, previousRoute);
provide(MEDIA_POPOVER_NEXT_KEY, nextRoute);
provide(
  MEDIA_POPOVER_BACK_TEXT,
  computed(() =>
    favoritesCollection.value?.title
      ? `in ${truncate(favoritesCollection.value?.title, { length: 50 })}`
      : null
  )
);

// redirect back to gallery
const isRedirecting = ref(false);
const goToGallery = () => {
  isRedirecting.value = true;
  router.push(backUrl.value);
};
</script>

<template>
  <MediaEditor
    v-if="!isRedirecting"
    :asset="favoritesCollectionDigitalAsset?.digital_asset"
    :is-asset-loading="isFileLoading"
    @close="goToGallery"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #carousel>
      <FavoritesCollectionAssetCarousel
        v-if="matchMediaIsWide && favoritesCollectionId"
        :favorites-collection-id="favoritesCollectionId"
        :album-title="favoritesCollection?.title"
        :active-asset-id="favoritesCollectionDigitalAsset?.digital_asset?.id"
      />
    </template>
  </MediaEditor>
</template>

import { computed, unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { getReservationTags } from 'src/api/reservation_tags';

export function createReservationTagsQuery(
  reservationId,
  tagCategorySlug,
  tagSubcategory = null,
  discountId = null
) {
  return {
    queryKey: queryKeys.reservationTagCategories(
      reservationId,
      tagCategorySlug,
      tagSubcategory,
      discountId
    ),
    queryFn: ({ signal }) =>
      getReservationTags(
        unref(reservationId),
        unref(tagCategorySlug),
        unref(tagSubcategory),
        unref(discountId),
        signal
      ),
    enabled: computed(() => !!unref(reservationId)),
  };
}

/**
 *
 * @param {number | Ref<number>} reservationId
 * @param {string | Ref<string>} tagCategorySlug
 * @param {string | Ref<string>} tagSubcategory
 * @param {string | Ref<string>} discountId
 */
export function useReservationTags(
  reservationId,
  tagCategorySlug,
  tagSubcategory,
  discountId
) {
  return useQuery(
    createReservationTagsQuery(
      reservationId,
      tagCategorySlug,
      tagSubcategory,
      discountId
    )
  );
}

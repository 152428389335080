<script setup>
import { computed, provide, ref } from 'vue';
import MediaEditor from '@/components/user/anytime/gallery/media-editor/MediaEditor.vue';

import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
  MEDIA_POPOVER_BACK_TEXT,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useRouter } from 'vue-router';
import { useMediaQuery } from '@vueuse/core';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import ShopMoreAssetCarousel from './ShopMoreAssetCarousel.vue';
import { useShopMoreDigitalAssetByDigitalAssetId } from '@/queries/shop_more/useShopMoreDigitalAssetByDigitalAssetId';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [Number, String],
    required: true,
  },
});
const router = useRouter();
const accountId = computed(() => props.accountId);
const digitalAssetId = computed(() => props.digitalAssetId);

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const {
  data: file,
  isLoading: isFileLoading,
  error: fileLoadingError,
} = useShopMoreDigitalAssetByDigitalAssetId(accountId, digitalAssetId);

// routes
const nextId = computed(() => file.value?.next_id);
const prevId = computed(() => file.value?.prev_id);

const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'shop-more-assets-media-view',
    params: {
      accountId: accountId.value,
      digitalAssetId: id,
    },
  };
};

const prevRoute = computed(() => createRouteLocation(prevId.value));
const nextRoute = computed(() => createRouteLocation(nextId.value));

const backUrl = computed(() => {
  return `/account/${accountId.value}/gallery/shop-more`;
});

const backText = ref('shop more');

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, prevRoute);
provide(MEDIA_POPOVER_NEXT_KEY, nextRoute);
provide(MEDIA_POPOVER_BACK_TEXT, backText);

const isRedirecting = ref(false);
const handleClose = () => {
  isRedirecting.value = true;
  router.push(backUrl.value);
};

const priorityError = usePriorityError(fileLoadingError);
</script>

<template>
  <MediaEditor
    v-if="!isRedirecting"
    :asset="file"
    :is-asset-loading="isFileLoading"
    @close="handleClose"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #carousel>
      <ShopMoreAssetCarousel
        v-if="matchMediaIsWide"
        :account-id="accountId"
        :active-asset-id="file?.id"
        :back-text="backText"
      />
    </template>
  </MediaEditor>
</template>

<script setup>
import { ref } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaItemCardSelectable from '@/components/ui_library/SoonaItemCardSelectable.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  listingAction: {
    type: Object,
    required: true,
  },
  platform: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['close', 'publishToSiblings']);

const integrationLogo = {
  Amazon: 'amazon-logo',
  Shopify: 'shopify-logo',
};

const selectedOption = ref(true);

const handleSubmit = event => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const publishTo = formData.get('publishTo');
  const publishToSiblings = publishTo === 'true';
  emits('publishToSiblings', publishToSiblings);
};
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>publish to listing(s)</template>
    <template #default>
      <div class="publish-to-siblings-dialog__content">
        <p>
          This image appears in {{ listingAction.sibling_count }} other
          listings. Would you like to update just this one or all of them?
        </p>
        <form class="publish-to-siblings-dialog__form" @submit="handleSubmit">
          <SoonaItemCardSelectable
            id="allListings"
            type="radio"
            :value="true"
            name="publishTo"
            :display-image="false"
            checked
            @change="selectedOption = $event.target.value"
          >
            <div
              class="publish-to-siblings-dialog__option u-body--heavy publish-to-siblings-dialog__option--centered"
            >
              Publish to all {{ listingAction.sibling_count + 1 }} listings
            </div>
          </SoonaItemCardSelectable>
          <SoonaItemCardSelectable
            id="justThisListing"
            type="radio"
            name="publishTo"
            :value="false"
            :display-image="false"
            @change="selectedOption = $event.target.value"
          >
            <div class="publish-to-siblings-dialog__option">
              <span class="u-body--heavy">Publish to this one listing</span>
              <div class="publish-to-siblings-dialog__listing">
                <div>
                  <SoonaIcon :name="integrationLogo[platform]" size="small" />
                </div>
                {{ listingAction.catalog_item_name }}
              </div>
            </div>
          </SoonaItemCardSelectable>

          <SoonaButton
            class="publish-to-siblings-dialog__button"
            type="submit"
            :disabled="!selectedOption"
          >
            publish
          </SoonaButton>
        </form>
      </div>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.publish-to-siblings-dialog {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__option {
    padding: 0.75rem 1rem;
    padding-left: 3rem;
    height: 100%;

    &--centered {
      display: flex;

      align-items: center;
    }
  }

  &__listing {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
  }

  &__button {
    width: 100%;
  }
}
</style>

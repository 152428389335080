<script setup>
import { computed } from 'vue';
import uniqueId from 'lodash/uniqueId';
import ArrowLeft from 'src/components/svgs/ArrowLeft.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';

// emits, props, and data
const props = defineProps({
  // SLOTS
  // - body
  // - price-point

  cardData: {
    default: () => {}, // optional, the entire data object so it can be passed to parent on card selection
    required: false,
    type: Object,
  },
  hasArrow: {
    default: false,
    required: false,
    type: Boolean,
  },
  hasSmallBodyText: {
    default: false,
    required: false,
    type: Boolean,
  },
  image: {
    default: () => {
      // { src: require('images/booking/food.jpg'), alt: 'food' }
      return {
        src: undefined,
        alt: undefined,
      };
    },
    required: false,
    type: Object,
  },
  imageList: {
    default: () => [], // [{ src: require('images/booking/food.jpg'), alt: 'food' }, { src: require('images/booking/soap.jpg'), alt: 'soap' }]
    required: false,
    type: Array,
  },
  imageTags: {
    default: undefined, // (badge on top of image)
    required: false,
    type: Array,
  },
  isLink: {
    default: false,
    required: false,
    type: Boolean,
  },
  isDisabled: {
    default: false,
    required: false,
    type: Boolean,
  },
  isSelected: {
    default: false,
    required: false,
    type: Boolean,
  },
  onCardClick: {
    default: () => undefined,
    required: false,
    type: Function,
  },
  title: {
    default: undefined,
    required: true,
    type: String,
  },
  titleElement: {
    default: 'h2',
    required: false,
    type: String,
    validator: function (value) {
      return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value);
    },
  },
  to: {
    default: undefined,
    required: false,
    type: String,
  },
  href: {
    default: undefined,
    type: String,
    required: false,
  },
});
const emit = defineEmits(['onClick']);
const baseId = uniqueId();
const imageId = `clickable-card-image-${baseId}`;

// computed
const imagesToRender = computed(() => {
  if (props.image.src) return [props.image];
  if (props.imageList.length >= 3) return props.imageList.slice(0, 3);
  if (props.imageList.length > 0) return props.imageList.slice(0, 1);
  return [];
});

// methods
function handleChange(val) {
  if (props.onCardClick) props.onCardClick(val);
}

function makeLowercase(val) {
  return val?.toLowerCase().replace(/\s/g, '-');
}
const component = computed(() => {
  if (props.isLink) {
    return props.href ? 'a' : 'router-link';
  }
  return 'label';
});
</script>

<template>
  <input
    v-if="component === 'label'"
    :id="`clickable-card-option-${makeLowercase(title)}`"
    name="industry"
    type="radio"
    :checked="isSelected"
    class="u-visually-hidden card-hidden-input"
    :data-cypress="`input-${makeLowercase(title)}`"
    @change="handleChange(cardData)"
  />
  <component
    v-bind="$attrs"
    :is="component"
    class="soona-clickable-card"
    :class="{
      'soona-clickable-card--disabled': isDisabled,
      'soona-clickable-card--selected': isSelected,
    }"
    :for="
      component === 'label'
        ? `clickable-card-option-${makeLowercase(title)}`
        : undefined
    "
    :to="to"
    :href="href"
    :data-cypress="`${component}-${makeLowercase(title)}`"
    :tabindex="component !== 'label' ? 0 : undefined"
    @click="emit('onClick')"
  >
    <figure
      v-if="imagesToRender.length > 0"
      class="soona-clickable-card__figure"
      :class="{
        'soona-clickable-card__figure--triple': imagesToRender.length >= 3,
      }"
    >
      <span
        v-for="(img, index) in imagesToRender"
        :key="`${imageId}-${img.src}`"
        class="soona-clickable-card__image-wrapper"
        :class="{
          'soona-clickable-card__image-wrapper--triple':
            imagesToRender.length >= 3,
        }"
      >
        <img
          class="soona-clickable-card__image"
          :class="{
            'soona-clickable-card__image--primary': index === 0,
          }"
          :src="img.src"
          :alt="img.alt"
        />
        <div class="soona-clickable-card__image-tags">
          <SoonaFlag
            v-for="imageTag in imageTags"
            :key="imageTag.title"
            :title="imageTag.title"
            :text-color="imageTag.textColor"
            :background-color="imageTag.backgroundColor"
            :font-weight="imageTag.fontWeight"
            type="pill"
          >
            <template v-if="imageTag.animation || imageTag.iconLeft" #icon-left>
              <lottie-player
                v-if="imageTag.animation"
                class="soona-flag__media"
                autoplay
                loop
                :src="imageTag.animation"
              />
              <span v-if="imageTag.iconLeft">{{ imageTag.iconLeft }}</span>
            </template>
          </SoonaFlag>
        </div>
      </span>
    </figure>
    <div class="soona-clickable-card__content">
      <component :is="titleElement" class="soona-clickable-card__title">
        {{ title }}
      </component>
      <p v-if="$slots['price-point']" class="soona-clickable-card__price">
        <slot name="price-point" />
      </p>
      <div
        v-if="$slots['body']"
        class="soona-clickable-card__body"
        :class="{ 'soona-clickable-card__body--small': hasSmallBodyText }"
      >
        <slot name="body" />
      </div>
    </div>
    <ArrowLeft
      v-if="hasArrow"
      class="soona-clickable-card__arrow"
      aria-hidden="true"
    />
  </component>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-clickable-card {
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  color: variables.$black-default;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 27.5rem;
  padding: 1rem;
  transition: box-shadow 0.2s;
  width: 100%;

  &:hover {
    box-shadow: variables.$elevation-3;
  }

  &:active {
    box-shadow: variables.$elevation-2;
  }

  .card-hidden-input:focus-visible + & {
    filter: drop-shadow(0px 0px 2px variables.$periwink-blue-60);
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--selected {
    border-color: variables.$black-default;
  }

  &__figure {
    height: 11rem;
    margin-bottom: 0.75rem;

    @media (min-width: variables.$screen-xs-min) {
      height: 7.75rem;
    }

    @media (min-width: variables.$screen-sm-min) {
      height: auto;
      max-height: 11rem;

      &--triple {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 5rem);
      }
    }
  }

  &__image-wrapper {
    position: relative;

    &--triple {
      &:first-child {
        grid-area: 1 / 1 / 1 / 1;
      }

      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }
    }

    @media (min-width: variables.$screen-sm-min) {
      &--triple {
        &:first-child {
          grid-area: 1 / 1 / 3 / 4;
        }

        &:nth-child(2) {
          display: block;
          grid-area: 1 / 4 / 1 / 6;
        }

        &:nth-child(3) {
          display: block;
          grid-area: 2 / 4 / 2 / 6;
        }
      }
    }
  }

  &__image {
    border-radius: 0.625rem;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  // propping up the <img> to avoid 'layout jank' as images load
  &__image--primary {
    background-color: variables.$gray-10;
    height: 11rem;

    @media (min-width: variables.$screen-xs-min) {
      height: 7.75rem;
    }

    @media (min-width: variables.$screen-sm-min) {
      height: 11rem;
    }
  }

  &__image-tags {
    display: flex;
    gap: 0.25rem;
    position: absolute;
    bottom: 0.625rem;

    left: 0.375rem;
    z-index: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__title {
    @include variables_fonts.u-subheader--heavy;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-title--heavy;
    }
  }

  &__price {
    @include variables_fonts.u-subheader--regular;

    padding-top: 0.25rem;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-title--regular;
    }
  }

  &__body {
    @include variables_fonts.u-label--regular;

    flex-grow: 1;
    padding-top: 0.25rem;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-body--regular;

      &--small {
        @include variables_fonts.u-label--regular;
      }
    }
  }

  &__arrow {
    display: block;
    margin-bottom: -5px;
    margin-left: auto;
    margin-top: 8px;
    transform: rotate(180deg);
  }

  .soona-flag__media {
    width: 0.875rem;
    height: 0.875rem;
  }
}
</style>

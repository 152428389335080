import { toValue } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';

/**
 * @returns UseMutationReturnType<*>
 */

export function useCreateCvServiceTask(type) {
  return useMutation({
    mutationFn: async ({ digitalAssetId, inputs }) => {
      const response = await http.post(
        `/cv_service_tasks/${toValue(type)}/${toValue(digitalAssetId)}`,
        {
          inputs: inputs,
        }
      );
      return response.data;
    },
  });
}

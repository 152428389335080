<script setup>
import ProductMultiSelector from './ProductMultiSelector.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const emits = defineEmits(['onSelectProduct', 'onDeselectProduct']);
const store = useStore();
const products = computed(
  () => store.getters['reservation/tagCategoryAnimationProducts']
);
const productsInCart = computed(
  () => store.getters['reservation/productsInCart']
);
function onSelectProduct(product) {
  emits('onSelectProduct', product);
}

function onDeselectProduct(product) {
  emits('onDeselectProduct', product);
}
</script>
<template>
  <ProductMultiSelector
    :products="products"
    :products-in-cart="productsInCart"
    label="select products"
    cypress="animation-product"
    @on-select="onSelectProduct"
    @on-deselect="onDeselectProduct"
  />
</template>

<template>
  <div class="SoonaTag">
    <!-- crew assignment specific tags  -->
    <div v-if="type === 'assignment'">
      <div class="soona-tag-assignment">
        <div
          v-for="(tag, index) in tags"
          :key="index"
          :class="isNotProservice(tag) ? 'soona-tag-container-assignment' : ''"
        >
          <img
            :src="
              tag.thumbnail_url
                ? tag.thumbnail_url
                : tag.image_url
                  ? tag.image_url
                  : photoImage
            "
            :class="
              isNotProservice(tag)
                ? 'soona-tag-thumbnail'
                : 'soona-tag-thumbnail-assignment'
            "
            :alt="`tag with a title of ${tag.name}`"
          />
          <div v-if="isNotProservice(tag)" class="text-container">
            <p class="soona-tag-name">
              {{ tag.name ? tag.name.toLowerCase() : tag.title.toLowerCase() }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- original tags -->
    <div v-else class="soona-tag">
      <div
        v-for="(tag, index) in tags"
        :key="index"
        class="soona-tag-container"
      >
        <img
          :src="
            tag.thumbnail_url
              ? tag.thumbnail_url
              : tag.image_url
                ? tag.image_url
                : photoImage
          "
          class="soona-tag-thumbnail"
          :alt="`tag with a title of ${tag.name}`"
        />
        <div class="text-container">
          <p class="soona-tag-name">
            {{
              tag.name
                ? tag.name.toLowerCase().includes('instagram') ||
                  tag.name.toLowerCase().includes('shopify') ||
                  tag.name.toLowerCase().includes('amazon')
                  ? tag.name.charAt(0).toUpperCase() + tag.name.slice(1)
                  : tag.name.toLowerCase()
                : tag.title.toLowerCase().includes('instagram') ||
                    tag.title.toLowerCase().includes('shopify') ||
                    tag.title.toLowerCase().includes('amazon')
                  ? tag.title.charAt(0).toUpperCase() + tag.title.slice(1)
                  : tag.title.toLowerCase()
            }}{{ tag.quantity > 1 ? ` x${tag.quantity}` : '' }}
            <span v-if="tag.level && canViewProServiceSizes"
              >({{ tag.level }})</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import photoImage from 'images/photo.png';

export default {
  name: 'SoonaTag',
  props: {
    tags: Array,
    type: String,
  },
  setup() {
    const { hasCapability: canViewProServiceSizes } = useCapability({
      capability: 'view_pro_service_sizes',
    });

    return { canViewProServiceSizes };
  },
  computed: {
    ...mapGetters(['nonProServiceTags']),
    photoImage: () => photoImage,
  },
  methods: {
    isNotProservice(tag) {
      let nonProBoolean = this.nonProServiceTags.includes(tag.title);
      return nonProBoolean;
    },
  },
};
</script>

<style lang="scss" scoped>
.SoonaTag {
  width: 100%;
}

.soona-tag {
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  max-height: fit-content;
  width: 100%;
  // take care to test on 320px wide viewports if changing the min width here
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.soona-tag-assignment {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-left: -10px;
}

.soona-tag-container,
.soona-tag-container-assignment {
  display: flex;
  flex: 1 1 225px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  height: 75px;

  .soona-tag-thumbnail {
    width: 60px;
    object-fit: cover;
    object-position: 30% 10%;
    height: 100%;
  }

  .soona-tag-name {
    font-weight: 800;
    padding: 1rem;
  }
}

.text-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.soona-tag-container-assignment {
  flex: initial;
  height: 60px;
}

.soona-tag-thumbnail-assignment {
  height: 60px;
  border-radius: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 0 5px;
}
</style>

<script setup>
import { computed } from 'vue';
import CompetitorPreviewCard from '@/components/user/anytime/competitive_analysis/CompetitorPreviewCard.vue';
import { useExampleCompetitorStaffPicks } from '@/queries/useExampleCompetitorStaffPicks';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { usePriorityError } from '@/composables/usePriorityError';

const props = defineProps({
  accountId: { type: String, required: true },
  hasAmazonIntegration: { type: Boolean, required: true },
  industryTitle: { type: String, required: false },
});

const accountId = computed(() => props.accountId);

const {
  data: exampleCompetitorsData,
  isLoading,
  error: error,
} = useExampleCompetitorStaffPicks(accountId);

const competitors = computed(() => {
  return exampleCompetitorsData.value ?? [];
});

const priorityError = usePriorityError(error);
</script>

<template>
  <div class="example-competitors-card-list">
    <div>
      <h2 class="example-competitors-card-list__title u-subheader--heavy">
        <SoonaIcon name="lightbulb-alt" />
        explore example competitors
      </h2>
      <p class="u-body--regular">
        some of our favorite
        <span v-if="hasAmazonIntegration && industryTitle">{{
          industryTitle
        }}</span>
        content from Amazon
      </p>
    </div>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <template v-if="isLoading">
      <CompetitorPreviewCard v-for="i in 3" :key="i" :is-loading="true" />
    </template>
    <TransitionGroup
      name="competitor"
      tag="div"
      class="example-competitors-card-list__list"
    >
      <CompetitorPreviewCard
        v-for="(competitor, i) in competitors"
        :key="i"
        :competitor="competitor"
      />
    </TransitionGroup>
  </div>
</template>

<style lang="scss" scoped>
.example-competitors-card-list {
  margin: 1rem 0;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  &__title {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

// transitions

/* base */
.competitor {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.competitor-move {
  transition: all 0.4s ease;
}

/* appearing */
.competitor-enter-active {
  transition: all 0.3s ease-out;
}

/* disappearing */
.competitor-leave-active {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disppear to */
.competitor-enter-from,
.competitor-leave-to {
  opacity: 0;
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import {
  getAccountInventoryItems,
  getUnidentifiedAccountInventoryItemsCount,
} from 'src/api/inventoryItem';
import { queryKeys } from '../query-keys';

/**
 *
 * @param {Object} params
 * @param {number | Ref<number>} [params.accountId]
 * @param {UseQueryOptions} queryOptions
 */
export function useAccountInventoryItems(accountId, params = {}, queryOptions) {
  return useQuery({
    queryKey: queryKeys.inventoryItems(accountId, params),
    queryFn: ({ signal }) =>
      getAccountInventoryItems(
        unref(accountId),
        { catalogItemId: unref(params.catalogItemId) },
        signal
      ),
    ...queryOptions,
  });
}

/**
 *
 * @param {Object} params
 * @param {number | Ref<string>} [params.accountId]
 * @param {UseQueryOptions} queryOptions
 */
export function useUnidentifiedAccountInventoryItemsCount(
  accountId,
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.inventoryItemsUnidentifiedCount(accountId),
    queryFn: ({ signal }) =>
      getUnidentifiedAccountInventoryItemsCount(unref(accountId), signal),
    ...queryOptions,
  });
}

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  brand: {
    type: String,
    default: undefined,
  },
});

const brand = computed(() => props.brand?.toLowerCase());
</script>

<template>
  <img
    v-if="brand === 'visa'"
    src="@images/credit-cards/visa.svg"
    alt="Visa Credit Card Icon"
  />
  <img
    v-else-if="brand === 'mastercard'"
    src="@images/credit-cards/mastercard.svg"
    alt="Mastercard Credit Card Icon"
  />
  <img
    v-else-if="brand === 'amex'"
    src="@images/credit-cards/amex.svg"
    alt="American Express Credit Card Icon"
  />
  <img
    v-else-if="brand === 'discover'"
    src="@images/credit-cards/discover.svg"
    alt="Discover Credit Card Icon"
  />
  <SoonaIcon v-else name="credit-card" />
</template>

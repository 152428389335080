import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import applyDigitalAssetFilters from '@/queries/digital_assets/applyDigitalAssetFilters';

/**
 *
 * @param {MaybeRef<string>} accountId
 * @param {MaybeRef<string>} digitalAssetId
 * @param {MaybeRef<Object>} [params]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useShopMoreDigitalAssetByDigitalAssetId(
  accountId,
  digitalAssetId,
  { filters } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.shopMoreDigitalAsset(accountId, digitalAssetId, {
      filters,
    }),
    queryFn: async ({ signal }) => {
      let params = {};
      const apiFilters = toValue(filters);
      applyDigitalAssetFilters(params, apiFilters);

      const response = await http.get(
        `/accounts/${toValue(accountId)}/shop_more/${toValue(digitalAssetId)}`,
        {
          params,
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

<template>
  <div class="inventory-card">
    <div class="info-wrapper">
      <div class="inventory-name-date">
        <p>
          <span class="date-time-location">
            <span class="date-time">
              {{ convertToMMDDYYYYlong(inventoryPackage.created_at) }}
            </span>
            <span class="red"> | </span>
            {{ inventoryPackage.location.name.toUpperCase() }}
          </span>
          <br />
        </p>
      </div>
      <div class="inventory-details">
        <p>
          package status:
          <span class="details">
            {{ getPackageStatuses[inventoryPackage.status] }}
          </span>
        </p>
        <p v-if="inventoryPackage.description">package contents:</p>
        <span class="details">
          {{ inventoryPackage.description }}
        </span>
      </div>
      <div class="images">
        <span v-for="item in inventoryItems" :key="item.id" class="tooltip">
          <span v-if="item.quantity > 1" class="imageQuantity">
            {{ item.quantity }}
          </span>
          <img :src="item.image_url" />
          <span v-if="item.description?.length > 0" class="tooltiptext">
            {{ item.description }}
          </span>
        </span>
        <span v-if="additionalInventoryItems" class="moreItems">
          + {{ additionalInventoryItems }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dateTimeMixin from '../../../../mixins/dateTimeMixin';
import { packageStatuses } from 'src/lib/inventory/constants';

export default {
  name: 'InventoryPickerCard',
  mixins: [dateTimeMixin],
  props: {
    inventoryPackage: Object,
  },
  computed: {
    getPackageStatuses() {
      return packageStatuses;
    },
    inventoryItems() {
      return this.inventoryPackage.inventory_items.slice(0, 3);
    },
    additionalInventoryItems() {
      const len = this.inventoryPackage.inventory_items.length;

      if (len > 3) return len - 3;

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.inventory-card {
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 1rem 0.25rem;
  max-height: none;
  min-height: 12.5rem;
  position: relative;
}

.info-wrapper {
  padding: 20px 20px 0 20px;
  width: 100%;
}

.inventory-name-date {
  font-size: 14px;
  font-weight: 900;
}

.red {
  color: variables.$friendly-red-50;
}

.date-time-location {
  letter-spacing: 0.1rem;
  font-weight: 900;
  font-size: 14px;

  .date-time {
    text-transform: lowercase;
  }
}

.inventory-details {
  margin: 10px 0 20px;
  font-size: 12px;
  font-weight: 900;
  float: left;
  width: 80%;
  p {
    margin-bottom: 4px;
  }
  .details {
    font-weight: 100;
  }
}

.description {
  font-weight: 900;
  font-size: 20px;
}

.images {
  margin-top: 14px;
  float: right;
  width: 20%;
  img {
    height: 25px;
    width: 25px;
    object-fit: cover;
    margin-right: 5px;
    margin-bottom: 0px;
    border-radius: 3px;
  }
  .imageQuantity {
    background-color: variables.$white-default;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: inline-block;
    text-align: center;
    padding-left: 1px;
    position: absolute;
    margin-left: 87px;
    margin-top: -7px;
  }
}

.moreItems {
  height: 25px;
  width: 25px;
  font-size: 0.8em;
  text-align: center;
  margin-right: 5px;
  margin-bottom: 5px;
  color: variables.$periwink-blue-60;
  border-radius: 3px;
  border: 1px solid variables.$gray-50;
  position: relative;
  display: inline-block;
  top: -6px;
  left: -3px;
  padding-top: 4px;
}

.recentBooking {
  font-weight: 100;
  margin: -6px 0 -3px -7px;
}

// tooltip
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  font-size: 12px;
  font-weight: 100;
  visibility: visible;
  width: 120px;
  background-color: variables.$white-default;
  color: variables.$gray-90;
  border: 1px solid variables.$periwink-blue-60;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -35%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>

import { ref, readonly } from 'vue';
import uniqueId from 'lodash/uniqueId';
import { SOONA_TOAST_PROVIDE_KEY } from '@/components/ui_library/soona_toast/soona-toast-provide-key';

export function createSoonaToaster() {
  const list = ref([]);

  function generateId() {
    return uniqueId('soona-toast-');
  }

  function addToast(
    message,
    {
      subtext,
      variation,
      iconName,
      iconColor,
      iconBg,
      noDismissButton,
      noTimeout,
    } = {}
  ) {
    list.value = [
      ...list.value,
      {
        id: generateId(),
        message,
        subtext,
        variation,
        iconName,
        iconColor,
        iconBg,
        noDismissButton,
        noTimeout,
      },
    ];
  }

  function removeToast(id) {
    list.value = list.value.filter(toast => toast.id !== id);
  }

  const soonaToaster = {
    addToast,
    removeToast,
    list: readonly(list),
  };

  return {
    install(app) {
      app.provide(SOONA_TOAST_PROVIDE_KEY, soonaToaster);
    },
    ...soonaToaster,
  };
}

<script setup>
import { ref, nextTick, useId, useTemplateRef, watchEffect } from 'vue';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue';
import { useDialogContext } from '@/composables/useDialog';
import { onKeyStroke, onClickOutside, useMediaQuery } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/queries/account/useAccount';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';
import UserMenuContent from './UserMenuContent.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import CreateAccountDialog from '@/components/platform-layout/sidebar/CreateAccountDialog.vue';

const { currentAccountId } = useMe();
const { data: currentAccount } = useAccount(currentAccountId);

const menuIsFullScreen = useMediaQuery('(max-height: 30rem)');

const open = ref(false);

const buttonEl = useTemplateRef('user-button');
const menuEl = useTemplateRef('menu');

const { floatingStyles } = useFloating(buttonEl, menuEl, {
  whileElementsMounted: (...args) =>
    autoUpdate(...args, {
      // to fix errors in Cypress tests. remove if tests pass without this
      animationFrame: true,
    }),
  middleware: [offset(12), shift(), flip()],
  placement: 'bottom-end',
  open,
});

const menuId = useId();
const userButtonId = useId();
const {
  checkAllowEscapeClose,
  start: dialogStart,
  stop: dialogStop,
} = useDialogContext({
  id: menuId,
  immediate: false,
  modal: menuIsFullScreen,
});

onKeyStroke('Escape', () => {
  if (open.value && menuEl.value && checkAllowEscapeClose(menuEl)) {
    open.value = false;
  }
});
const { activate: focusTrapActivate, deactivate: focusTrapDeactivate } =
  useFocusTrap(menuEl, {
    clickOutsideDeactivates: true,
  });

watchEffect(() => {
  if (open.value) {
    // wait for the menu to mount and exist in the DOM before trapping focus
    nextTick(() => {
      focusTrapActivate();
      dialogStart();
    });
  } else {
    focusTrapDeactivate();
    dialogStop();
  }
});

onClickOutside(menuEl, e => {
  if (open.value) {
    open.value = false;
    e.preventDefault();
    e.stopPropagation();
  }
});

function navClickAutoClose(e) {
  // auto-close menu when clicking on any link
  if (e.target.closest('a')) {
    open.value = false;
  }
}

const createAccountDialogOpen = ref(false);

function openCreateAccountDialog() {
  open.value = false;

  // just in case, nextTick this so focus management isn't cancelled out
  nextTick(() => {
    createAccountDialogOpen.value = true;
  });
}

function closeCreateAccountDialog() {
  createAccountDialogOpen.value = false;
}
</script>

<template>
  <button
    :id="userButtonId"
    ref="user-button"
    class="user-nav-button"
    :aria-expanded="open"
    :aria-controls="menuId"
    aria-haspopup="menu"
    data-cypress="open-user-menu"
    title="user and account settings"
    @click="open = !open"
  >
    <span class="u-visually-hidden">
      open user menu for account: {{ currentAccount?.name }}
    </span>
    <span
      class="user-nav-button__account-name u-small--heavy"
      aria-hidden="true"
    >
      {{ currentAccount?.name }}
    </span>
    <SoonaAvatar
      :name="currentAccount?.name"
      :src="currentAccount?.avatar_url"
      size="1.75rem"
      type="account"
    />
  </button>

  <Transition name="user-menu-fade">
    <Teleport v-if="open" to="body">
      <div
        :id="menuId"
        ref="menu"
        role="menu"
        :aria-labelledby="userButtonId"
        :style="menuIsFullScreen ? null : floatingStyles"
        class="user-menu__popover"
        :class="{ 'user-menu__popover--full-screen': menuIsFullScreen }"
        @click="navClickAutoClose"
      >
        <div v-if="menuIsFullScreen" class="user-menu__popover__header">
          <h2 class="u-title--heavy">
            user<span class="u-visually-hidden"> and account settings</span>
          </h2>
          <SoonaButton
            variation="icon-plain-gray"
            size="medium"
            @on-click="open = false"
          >
            <SoonaIcon name="xmark" />
            <span class="u-visually-hidden">close user menu</span>
          </SoonaButton>
        </div>
        <UserMenuContent @create-account="openCreateAccountDialog" />
      </div>
    </Teleport>
  </Transition>
  <CreateAccountDialog
    v-if="createAccountDialogOpen"
    @close="closeCreateAccountDialog"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.user-menu {
  &__popover {
    max-width: 23rem;
    width: 100%;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    background: variables.$white-default;
    box-shadow: variables.$elevation-1;
    opacity: 1;
    transition: opacity 0.1s ease-out;

    &.user-menu-fade-enter-from,
    &.user-menu-fade-leave-to {
      opacity: 0;
    }

    &--full-screen {
      position: fixed;
      inset: 0;
      border: none;
      border-radius: 0;
      overflow-y: auto;
      max-width: 100%;
    }

    &__header {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
    }
  }
}

.user-nav-button {
  display: flex;
  padding: 0.3125rem;
  border-radius: 1.25rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.625rem;
  background: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;

  &:focus-visible {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  &:hover {
    background-color: variables.$gray-10;
  }

  &:active {
    background-color: variables.$gray-20;
  }

  &__account-name {
    display: none;
  }
}

@media (min-width: 28rem) {
  .user-nav-button {
    padding: 0.3125rem 0.75rem;
    border-radius: 0.5rem;

    &__account-name {
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 6rem;
    }
  }
}
</style>

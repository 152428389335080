import { http } from '../config/vue-axios';
import { mapPaginationHeaders } from '../lib/api-transforms';

/**
 *
 * @param {string} widgetType
 * @param {AbortSignal} [signal]
 */
export async function getPromotionWidgetsByType(widgetType, signal) {
  const params = { widget_type: widgetType };
  const response = await http.get(`promotion_widgets.json`, {
    params,
    signal,
  });

  return response.data;
}

export async function getPromotions(
  {
    page = 1,
    itemsPerPage = 2,
    orderBy = 'title',
    direction = 'desc',
    viewArchived = false,
  },
  signal
) {
  let params = {
    page: page,
    items: itemsPerPage,
    order_by: orderBy,
    direction: direction,
    view_archived: viewArchived,
  };

  const response = await http.get(`promotions.json`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    promotions: response.data,
  };
}

export async function createPromotion(promotion, signal) {
  const response = await http.post(`promotions.json`, promotion, {
    signal,
  });

  return response.data;
}

export async function editPromotion(id, signal) {
  const response = await http.get(`promotions/${id}/edit.json`, {
    signal,
  });

  return response.data;
}

export async function updatePromotion(id, promotion, signal) {
  const response = await http.put(`promotions/${id}.json`, promotion, {
    signal,
  });

  return response.data;
}

export async function deletePromotion(id, promotion, signal) {
  const response = await http.delete(`promotions/${id}.json`, promotion, {
    signal,
  });

  return response.data;
}

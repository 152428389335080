import { inject } from 'vue';
import { SOONA_TOAST_PROVIDE_KEY } from './soona-toast-provide-key';

export function useSoonaToast() {
  const toast = inject(SOONA_TOAST_PROVIDE_KEY);

  if (!toast) {
    throw new Error(
      '[useSoonaToast] must be used inside of a `<SoonaToastRoot />`'
    );
  }

  return {
    addToast: toast.addToast,
  };
}

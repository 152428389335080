<script setup>
import { watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { provide, ref, useId, useTemplateRef } from 'vue';

const listboxId = useId();

const open = ref(false);
const highlightedElementID = ref(null);
const ariaActivedescendantID = ref(null);

watch(open, val => {
  if (!val) {
    highlightedElementID.value = null;
  }
});

const comboboxRef = useTemplateRef('comboboxRef');

const modelValue = defineModel({
  type: [Number, String],
  default: null,
});

const modelSearchValue = defineModel('searchText', {
  type: [Number, String],
  default: '',
});

onClickOutside(comboboxRef, () => {
  open.value = false;
});

const onChange = value => {
  open.value = false;
  modelValue.value = value;
  modelSearchValue.value = value;
  ariaActivedescendantID.value = `combobox-option-${value}`;
};

provide('comboboxContext', {
  open,
  onChange,
  listboxId,
  modelValue,
  comboboxRef,
  modelSearchValue,
  highlightedElementID,
  ariaActivedescendantID,
  setOpen: val => (open.value = val),
  toggleCombobox: () => (open.value = !open.value),
});

defineExpose({ open });

const handleKeydown = async event => {
  if (!open.value) return;
  if (event.ctrlKey || event.altKey || event.metaKey) return;

  const activeOption = document.getElementById(highlightedElementID.value);

  if (!activeOption) return;

  switch (event.key) {
    case 'ArrowDown':
    case 'ArrowRight': {
      event.preventDefault();
      let sibling = activeOption.nextElementSibling;
      if (sibling) highlightedElementID.value = sibling.id;
      break;
    }
    case 'ArrowUp':
    case 'ArrowLeft': {
      event.preventDefault();
      let sibling = activeOption.previousElementSibling;
      if (sibling) highlightedElementID.value = sibling.id;
      break;
    }
    case 'Enter':
      event.preventDefault();
      onChange(activeOption.getAttribute('data-value'));
      break;
    case 'Escape':
      event.preventDefault();
      open.value = false;
      break;
    default:
      break;
  }
};
</script>

<template>
  <div ref="comboboxRef" class="soona-combobox" @keydown="handleKeydown">
    <slot name="trigger" />
    <slot name="popover" />
  </div>
</template>

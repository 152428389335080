import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';
import { toValue } from 'vue';
import * as Sentry from '@sentry/vue';

/**
 *
 * @param {import('vue').MaybeRefOrGetter<"accounts" | "reservations" | "digital_assets">} subjectType
 * @param {import('vue').MaybeRefOrGetter<number>} subjectId
 */
export function useUpdateNote(subjectType, subjectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ noteId, content }) => {
      const response = await http.put(
        `/${toValue(subjectType)}/${toValue(subjectId)}/notes/${noteId}`,
        {
          content,
        }
      );
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.notes(subjectType, subjectId),
      });
    },
    onError: error => {
      Sentry.captureException(new Error('failed to update note'), {
        extra: { error },
      });
    },
  });
}

<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { computed } from 'vue';
import { useUpdateReEditsCollection } from '@/queries/re_edits_collections/useUpdateReEditsCollection';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  collectionId: {
    type: [Number, String],
    required: true,
  },
  reEditCategory: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);

const accountId = computed(() => props.accountId);
const collectionId = computed(() => props.collectionId);
const reEditCategory = computed(() => props.reEditCategory);

const { mutate: submitReEdit } = useUpdateReEditsCollection(accountId);

const completeReEditsOrder = close => {
  submitReEdit(
    {
      collectionId,
      body: {
        category: reEditCategory.value,
        status: 'completed',
      },
    },
    {
      onSuccess: () => {
        close();
      },
    }
  );
};
</script>
<template>
  <SoonaDialog @close="() => emit('close')">
    <template #heading>
      are you sure you want to complete this re-edit?
    </template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton variation="primary" @on-click="completeReEditsOrder(close)">
        complete re-edit
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { PeriwinkBlue80, PeriwinkBlue20 } from 'src/variables.module.scss';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';
import { usePackConfigurations } from '@/queries/pack-configurations/usePackConfigurations';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import BuildYourShootImage from 'images/listing-insights/soona-internal-small.jpg';
import ShootRequestDialog from '@/components/shared/ShootRequestDialog.vue';
import SoonaSuccess from 'src/components/ui_library/SoonaSuccess.vue';

const props = defineProps({
  platform: {
    type: String,
    required: true,
  },
});

const { platform } = toRefs(props);

const { data: recommendedPacks, isSuccess: packsLoaded } =
  usePackConfigurations({
    query: platform,
  });

const recommendedPack = computed(() => {
  if (platform.value === 'Amazon') {
    return (
      recommendedPacks.value?.data.find(
        x => x.name === 'Amazon listing starter pack'
      ) ?? recommendedPacks.value?.data[0]
    );
  }
  return (
    recommendedPacks.value?.data.find(x => x.name === 'Shopify plus pack') ??
    recommendedPacks.value?.data[0]
  );
});

const route = useRoute();

const { linkClicked } = useBaseEvents();

const handleCardClick = (linkLabel, linkHref) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'single listing overview',
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
};

const displayShootRequestDialog = ref(false);
const hasSubmittedShootRequest = ref(false);
</script>

<template>
  <div class="create-fresh-content">
    <div class="create-fresh-content__header">
      <h3 class="u-subheader--heavy">create fresh content</h3>
      <SoonaFlag
        type="pill"
        title="studio"
        :background-color="PeriwinkBlue20"
        :text-color="PeriwinkBlue80"
      >
        <template #icon-left>
          <SoonaIcon name="camera-alt-1" size="small" />
        </template>
      </SoonaFlag>
    </div>
    <SoonaItemCard
      v-if="packsLoaded && recommendedPack"
      aria-labelledby="recommended-pack"
      :image-url="recommendedPack.pack_main_image_url"
      class="create-fresh-content__card"
    >
      <template #content>
        <div class="create-fresh-content__card--content">
          <a
            :href="`/book/pack/${recommendedPack.id}`"
            class="create-fresh-content__card--name u-body--heavy"
            @click="
              handleCardClick(
                recommendedPack.name,
                `/book/pack/${recommendedPack.id}`
              )
            "
          >
            refresh this product now
          </a>
          <p class="u-body--regular">
            {{ recommendedPack.name }} {{ recommendedPack.pack_price }}
          </p>
        </div>
      </template>
    </SoonaItemCard>
    <SoonaSuccess v-if="hasSubmittedShootRequest">
      <p class="u-subheader--heavy">your shoot request has been submitted!</p>
      <p>a member of the soona team will reach out.</p>
    </SoonaSuccess>
    <SoonaItemCard
      v-else
      aria-labelledby="build-your-shoot"
      :image-url="BuildYourShootImage"
      class="create-fresh-content__card"
    >
      <template #content>
        <div class="create-fresh-content__card--content">
          <div
            class="create-fresh-content__card--name u-body--heavy"
            role="button"
            tabindex="0"
            @click="displayShootRequestDialog = true"
          >
            get a quote for any project
          </div>
          <p class="u-label--regular">contact sales</p>
        </div>
      </template>
    </SoonaItemCard>
  </div>
  <ShootRequestDialog
    v-if="displayShootRequestDialog"
    @shoot-request-submitted="hasSubmittedShootRequest = true"
    @close="displayShootRequestDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.create-fresh-content {
  background: variables.$periwink-blue-10;
  border-radius: 0.625rem;
  padding: 1.5rem;
  border: 0.0625rem solid variables.$gray-30;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__card {
    position: relative;
    width: 100%;

    &:hover {
      background-color: variables.$gray-10;
    }

    &--content {
      padding: 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &--name {
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 1;
      }
    }

    [role='button'] {
      cursor: pointer;
    }
  }
}
</style>

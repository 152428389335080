<template>
  <div class="soona-tag-option-card-modal-main-image">
    <div
      v-if="!hasCarousel"
      class="soona-tag-option-card-modal-main-image__image-wrapper"
    >
      <figure class="soona-tag-option-card-modal-main-image__figure">
        <img
          class="soona-tag-option-card-modal-main-image__image"
          :src="mainImage.src"
          :alt="mainImage.alt"
        />
      </figure>
    </div>
    <div
      v-else
      class="soona-tag-option-card-modal-main-image__carousel-wrapper"
    >
      <SoonaCarousel
        :images="imageList"
        :image-pips="true"
        :wrap-around="false"
        height="100%"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
import SoonaCarousel from 'src/components/ui_library/SoonaCarousel.vue';

export default {
  components: {
    SoonaCarousel,
  },
  props: {
    hasCarousel: {
      default: false,
      required: false,
      type: Boolean,
    },
    imageList: {
      default: () => [], // FORMAT: { src: require('images/booking/food.jpg'), alt: 'food' }
      required: false,
      type: Array,
    },
  },
  computed: {
    mainImage() {
      if (!this.hasCarousel) return this.imageList[0];
      return { alt: undefined, src: undefined };
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.soona-tag-option-card-modal-main-image {
  margin-bottom: 0.75rem;
  max-width: 24.625rem;
  margin-left: auto;
  margin-right: auto;

  &__image-wrapper,
  &__figure {
    height: 100%;
  }

  &__image {
    border-radius: 0.625rem;
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
  }

  :deep(.image-pips-wrapper) {
    height: 350px;
    width: 100%;

    img {
      border-radius: 0.625rem;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    padding-right: 0.75rem;
    width: 50%;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
</style>

<script setup>
import { computed, ref, toValue, watch } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { useCreateCvServiceTask } from '@/queries/cv_service_tasks/useCreateCvServiceTask';
import { storeToRefs } from 'pinia';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useStartViewTransition } from '@/composables/useStartViewTransition';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useMokkerAvailableCredits } from '@/queries/mokker/useMokkerAvailableCredits';
import { useMe } from '@/composables/user/useMe';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId, numberOfGenerations } =
  storeToRefs(mediaEditorStore);

const { currentAccountId } = useMe();
const assetBelongsCurrentAccount = computed(
  () => assetAccountId.value === currentAccountId.value
);

const { startViewTransition } = useStartViewTransition();

const {
  mediaWidth: w,
  mediaHeight: h,
  isPhoto,
  assetLoading,
  isSuccess: imageLoaded,
  ownedByCustomer,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const extendRatio = ref(1.5);
const extendOptions = [1.25, 1.5, 1.75, 2];
const wPadding = computed(() => w.value * extendRatio.value - w.value);
const hPadding = computed(() => h.value * extendRatio.value - h.value);
const selectedSize = ref(null);
const rightRatios = computed(() => {
  return {
    canvasWidth: w.value * extendRatio.value,
    canvasHeight: h.value,
    xcoord: 0,
    ycoord: 0,
  };
});
const leftRatios = computed(() => {
  return {
    canvasWidth: w.value * extendRatio.value,
    canvasHeight: h.value,
    xcoord: wPadding.value,
    ycoord: 0,
  };
});
const bottomRatios = computed(() => {
  return {
    canvasWidth: w.value,
    canvasHeight: h.value * extendRatio.value,
    xcoord: 0,
    ycoord: 0,
  };
});
const topRatios = computed(() => {
  return {
    canvasWidth: w.value,
    canvasHeight: h.value * extendRatio.value,
    xcoord: 0,
    ycoord: hPadding.value,
  };
});

const allRatios = computed(() => {
  const shiftRatio = (extendRatio.value - 1) / 2;
  return {
    canvasWidth: w.value * extendRatio.value,
    canvasHeight: h.value * extendRatio.value,
    xcoord: w.value * shiftRatio,
    ycoord: h.value * shiftRatio,
  };
});
const sizes = [
  {},
  {
    label: 'up',
    direction: 'top',
    ratio: true,
    width: 2,
    height: 3,
    scaleFn: topRatios,
    icon: 'arrow-up',
  },
  {},
  {
    label: 'left',
    direction: 'left',
    ratio: true,
    width: 1,
    height: 1,
    scaleFn: leftRatios,
    icon: 'arrow-left',
  },
  {
    label: 'center',
    direction: 'all',
    ratio: true,
    width: 2,
    height: 3,
    scaleFn: allRatios,
    icon: 'maximize',
  },
  {
    label: 'right',
    direction: 'right',
    ratio: true,
    width: 2,
    height: 3,
    scaleFn: rightRatios,
    icon: 'arrow-right',
  },
  {},
  {
    label: 'down',
    direction: 'bottom',
    ratio: true,
    width: 2,
    height: 3,
    scaleFn: bottomRatios,
    icon: 'arrow-down',
  },
  {},
];

const handleSizeChange = size => {
  startViewTransition(() => {
    selectedSize.value = toValue(size);
    mediaEditorStore.setSelectorData(size);
  });
};

const setExtendRatio = ratio => {
  startViewTransition(() => {
    extendRatio.value = ratio;
  });
};

// credits
const { data: availableCreditsResponse, isLoading: isAvailableCreditsLoading } =
  useMokkerAvailableCredits(assetAccountId, {
    enabled: computed(() => !!assetAccountId.value),
  });

const availableCredits = computed(
  () => availableCreditsResponse.value?.available_credits > 0
);

// save to gallery
const queryClient = useQueryClient();
const {
  mutate: createUncropTask,
  isPending: createUncropTaskLoading,
  error: createUncropTaskError,
} = useCreateCvServiceTask('uncrop');
const handleSave = async () => {
  for (let i = 0; i < (numberOfGenerations.value ?? 1); i++) {
    createUncropTask({
      digitalAssetId: assetId,
      inputs: {
        direction: selectedSize.value.direction,
        extend_factor: extendRatio.value,
      },
    });
  }
};

watch(createUncropTaskLoading, () => {
  queryClient.invalidateQueries({
    queryKey: queryKeys.mokkerAvailableCredits(assetAccountId),
  });
});

const loading = computed(
  () =>
    assetLoading.value ||
    isAvailableCreditsLoading.value ||
    createUncropTaskLoading.value
);

watch(assetId, () => {
  if (!assetId.value || assetLoading.value) return;
  if (!isPhoto.value) {
    mediaEditorStore.forceSetActiveAction(null);
  }
  handleSizeChange(sizes.find(size => size.label === 'center'));
});

watch(
  imageLoaded,
  () => {
    if (imageLoaded.value) {
      handleSizeChange(
        selectedSize.value || sizes.find(size => size.label === 'center')
      );
    }
  },
  { immediate: true }
);
</script>
<template>
  <SoonaError v-if="createUncropTaskError">
    {{ createUncropTaskError }}
  </SoonaError>
  <div v-else class="uncrop">
    <div class="uncrop__dpad">
      <div
        v-for="size in sizes"
        :key="size.direction"
        :aria-hidden="!size.label"
        :class="{ 'uncrop--direction__invisible': !size.label }"
      >
        <div class="uncrop--direction" :aria-hidden="!size.label">
          <div
            class="uncrop--direction__container"
            :class="
              selectedSize?.label === size.label
                ? 'uncrop--direction__selected'
                : ''
            "
            @click="() => handleSizeChange(size)"
          >
            <SoonaIcon v-if="size.icon" :name="size.icon" />
          </div>
          <div class="uncrop--direction__text">{{ size.label }}</div>
        </div>
      </div>
    </div>

    <div class="uncrop__bottom">
      <div class="u-subheader--heavy">size</div>
      <div class="u-label--regular uncrop__subheading">
        select how much you’d like to expand your image by
      </div>

      <div class="uncrop__size-options">
        <div
          v-for="option in extendOptions"
          :key="option"
          :class="{
            'uncrop__size-options__selected': extendRatio === option,
            'uncrop__size-options__option': extendRatio !== option,
          }"
          @click="setExtendRatio(option)"
        >
          {{ option }}x
        </div>
      </div>

      <SoonaButton
        v-if="assetBelongsCurrentAccount"
        variation="primary"
        class="uncrop__expand-button"
        :disabled="!selectedSize || !availableCredits || !ownedByCustomer"
        :loading="loading"
        @on-click="handleSave"
      >
        expand image
      </SoonaButton>
    </div>
  </div>
</template>
<style scoped lang="scss">
@use '@/variables';

.uncrop {
  display: flex;
  flex-direction: column;

  &__dpad {
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
  }

  &__subheading {
    color: variables.$gray-60;
  }

  &--direction {
    &__container {
      display: flex;
      width: 4rem;
      height: 3.125rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0.625rem;
      border: 1px solid variables.$gray-30;
      &:hover {
        background: variables.$periwink-blue-10;
        cursor: pointer;
      }
    }

    &__invisible {
      visibility: hidden;
    }

    &__selected {
      border: 2px solid variables.$periwink-blue-70;
      background: variables.$periwink-blue-20;
    }
    &__text {
      text-align: center;
    }
  }

  &__size-options {
    padding: 1rem 0 1rem 0;
    display: flex;
    gap: 0.5rem;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      width: 3.25rem;
      height: 2rem;
      flex-shrink: 0;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      cursor: pointer;
    }

    &__option {
      border: 1px solid variables.$gray-30;
      color: variables.$gray-60;

      &:hover {
        background: variables.$periwink-blue-10;
      }
    }

    &__selected {
      background: variables.$periwink-blue-20;
      border: 1.5px solid variables.$periwink-blue-70;
      color: variables.$periwink-blue-70;
    }
  }
}
</style>

<script setup>
import { ref, computed, toRefs } from 'vue';

import { useDebounceFn } from '@vueuse/core';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import {
  COMMON,
  CUSTOM,
} from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/filter_symbols';

const props = defineProps({
  fileSizes: {
    type: Object,
    required: true,
  },
  isMobileView: {
    type: Boolean,
    default: false,
  },
  lockedAspectRatio: {
    type: Boolean,
    required: true,
  },
  mobileFilter: {
    type: Object,
    default: () => {},
  },
  selectedSizes: {
    type: Object,
    required: false,
    default: () => {},
  },
  selectionSize: {
    type: Object,
    required: false,
    default: () => {},
  },
});
const emits = defineEmits([
  'close-panel',
  'resize-area',
  'toggle-aspect-ratio',
]);

const {
  fileSizes,
  isMobileView,
  lockedAspectRatio,
  mobileFilter,
  selectedSizes,
  selectionSize,
} = toRefs(props);

const widthEl = ref(null);
const heightEl = ref(null);

const commonSizes = ref([
  { label: 'original', value: 1, ratio: true },
  { label: 'freeform', value: 2, ratio: false },
  { label: '1:1 (square)', value: 3, ratio: true, width: 1, height: 1 },
  { label: '2:3 (vertical)', value: 4, ratio: true, width: 2, height: 3 },
  { label: '9:16 (vertical)', value: 5, ratio: true, width: 9, height: 16 },
  { label: '16:9 (horizontal)', value: 6, ratio: true, width: 16, height: 9 },
  { label: '3:2 (horizontal)', value: 7, ratio: true, width: 3, height: 2 },
]);
const selectedCommonSize = ref(null);

// mobile filters
const filterCustomSize = computed(() => mobileFilter.value?.symbol === CUSTOM);
const filterCommonSizes = computed(() => mobileFilter.value?.symbol === COMMON);

const horizontalImage = computed(
  () => fileSizes.value.width > fileSizes.value.height
);

const smallerFileSize = computed(() =>
  Math.min(fileSizes.value.width, fileSizes.value.height)
);
const biggerFileSize = computed(() =>
  Math.max(fileSizes.value.width, fileSizes.value.height)
);
const targetSize = computed(() =>
  commonSizes.value
    .filter(size => size.value === selectedCommonSize.value)
    .pop()
);
const squareTarget = computed(
  () => targetSize.value.width === targetSize.value.height
);
const horizontalTarget = computed(
  () => targetSize.value.width > targetSize.value.height
);

const selectedWidth = computed(
  () =>
    (!lockedAspectRatio.value && selectionSize.value?.width) ||
    selectedSizes.value?.width ||
    fileSizes.value?.width
);
const selectedHeight = computed(
  () =>
    (!lockedAspectRatio.value && selectionSize.value?.height) ||
    selectedSizes.value?.height ||
    fileSizes.value?.height
);

const resetSelectedCommonSize = () => {
  selectedCommonSize.value = null;
};

defineExpose({
  resetSelectedCommonSize,
});

const validateSizes = () => {
  const widthInput = widthEl.value.$el.querySelector('input');
  const heightInput = heightEl.value.$el.querySelector('input');

  if (typeof widthInput.valueAsNumber !== 'number') return false;
  if (typeof heightInput.valueAsNumber !== 'number') return false;

  if (widthInput.value < 100 || heightInput.value < 100) {
    if (widthInput.value < 100) widthInput.value = 100;
    if (heightInput.value < 100) heightInput.value = 100;
  }
  if (widthInput.value > 9999) widthInput.value = '';
  if (heightInput.value > 9999) heightInput.value = '';

  return true;
};

const handleSelectSize = value => {
  selectedCommonSize.value = value;

  const targetWidth =
    !targetSize.value.ratio || !targetSize.value.width
      ? fileSizes.value.width
      : squareTarget.value
        ? smallerFileSize.value
        : horizontalTarget.value
          ? horizontalImage.value
            ? biggerFileSize.value
            : smallerFileSize.value
          : horizontalImage.value
            ? smallerFileSize.value *
              (targetSize.value.width / targetSize.value.height)
            : biggerFileSize.value *
              (targetSize.value.width / targetSize.value.height);
  const targetHeight =
    !targetSize.value.ratio || !targetSize.value.height
      ? fileSizes.value.height
      : squareTarget.value
        ? smallerFileSize.value
        : horizontalTarget.value
          ? horizontalImage.value
            ? biggerFileSize.value *
              (targetSize.value.height / targetSize.value.width)
            : smallerFileSize.value *
              (targetSize.value.height / targetSize.value.width)
          : horizontalImage.value
            ? smallerFileSize.value
            : biggerFileSize.value;

  emits('resize-area', {
    name: targetSize.value.label,
    width: Math.round(targetWidth),
    height: Math.round(targetHeight),
    ratio: targetSize.value.ratio,
  });
};

const updateSize = () => {
  if (!validateSizes()) return;

  emits('resize-area', {
    name: 'custom',
    width: 1 * widthEl.value.$el.querySelector('input').value,
    height: 1 * heightEl.value.$el.querySelector('input').value,
    ratio: lockedAspectRatio.value,
    custom: true,
  });
};

const updateSizeDebounced = useDebounceFn(updateSize, 1000);
</script>
<template>
  <div class="custom-size">
    <div v-if="!isMobileView" class="panel-header">
      <SoonaButton
        variation="icon-gray-outline"
        size="medium"
        @click="emits('close-panel')"
      >
        <SoonaIcon name="arrow-left" />
      </SoonaButton>
      <h3 class="u-subheader--heavy">custom / common sizes</h3>
    </div>
    <div class="custom-size__content">
      <template v-if="!isMobileView || filterCustomSize">
        <div class="custom-size__inputs">
          <SoonaTextfield
            ref="widthEl"
            label="width"
            name="custom-width"
            placeholder="px"
            type="number"
            :step="1"
            :model-value="selectedWidth"
            :min="100"
            :max="9999"
            :rules="['integer', 'min', 'max']"
            @input="updateSizeDebounced"
            @keydown.tab="updateSize"
            @keydown.enter="updateSize"
          />
          <SoonaTextfield
            ref="heightEl"
            label="height"
            name="custom-height"
            placeholder="px"
            type="number"
            :step="1"
            :model-value="selectedHeight"
            :min="100"
            :max="9999"
            :rules="['integer', 'min', 'max']"
            @input="updateSizeDebounced"
            @keydown.tab="updateSize"
            @keydown.enter="updateSize"
          />
        </div>
        <label class="u-checkbox u-label--small custom-size__ratio">
          <input
            :checked="lockedAspectRatio"
            type="checkbox"
            @change="() => emits('toggle-aspect-ratio')"
          />
          lock selection frame ratio
        </label>
      </template>
      <div v-if="!isMobileView" class="fixed-ratios">
        <SoonaSelect
          placeholder="choose"
          :model-value="selectedCommonSize"
          :options="commonSizes"
          @update:model-value="handleSelectSize"
        >
          <template #label>common sizes</template>
        </SoonaSelect>
      </div>
      <div v-else-if="filterCommonSizes" class="custom-size__mobile-options">
        <SoonaButton
          v-for="size in commonSizes"
          :key="size.value"
          :class="
            selectedCommonSize === size.value ? 'soona-button--pizzazz' : ''
          "
          :variation="selectedCommonSize === size.value ? 'filter' : 'filter'"
          size="medium"
          @click="handleSelectSize(size.value)"
        >
          {{ size.label }}
        </SoonaButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/variables';

.custom-size {
  max-width: 100%;
  background: variables.$white-default;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.25rem;
  overflow: auto;

  .panel-header {
    display: flex;
    position: sticky;
    z-index: 1;
    top: 0;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 0 1rem 1rem;
    background: variables.$white-default;
  }

  .fixed-ratios {
    margin-top: 1.75rem;

    select {
      width: 100%;
    }
  }

  &__content {
    max-width: 100%;
    padding: 0.25rem 1rem 0;
  }

  &__inputs {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    padding-bottom: 0.5rem;

    &::after {
      content: '×';
      display: flex;
      align-items: center;
      min-height: 2.625rem;
    }

    .soona-textfield {
      padding-bottom: 0;

      &:last-child {
        order: 1;
      }
    }
  }

  &__ratio {
    display: flex;
    align-items: center;
    margin: 0.5rem 0 0;
    padding: 0;
  }

  &__mobile-options {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-bottom: 0.2rem;

    .soona-button--pizzazz {
      color: variables.$white-default;
    }
  }
}
</style>

<script setup>
import { computed, onBeforeUnmount, ref } from 'vue';
import { convertToHHmmss } from '@/lib/date-formatters';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  checkoutDeadline: {
    required: true,
    type: String,
  },
  reservationId: {
    required: true,
    type: [Number, String],
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const checkoutDeadline = computed(() => props.checkoutDeadline);
const convertedCheckoutDeadline = computed(() => dayjs(checkoutDeadline.value));
const reservationId = computed(() => props.reservationId);

const currentTime = ref(dayjs());
const linkLabel = 'shop to discount next shoot';
const linkHref = `/reservation/${reservationId.value}`;

const updateCurrentTime = () => {
  currentTime.value = dayjs();
};

const checkoutTimeLeft = computed(() => {
  const deadline = convertedCheckoutDeadline.value?.diff(currentTime.value);

  if (deadline > 0) return convertToHHmmss(deadline, 'UTC');
  return '00:00:00';
});

const isIncentiveAvailable = computed(() => {
  const hoursLeft = Math.floor(
    convertedCheckoutDeadline.value.diff(currentTime.value) / (1000 * 60 * 60)
  );

  return hoursLeft < 24 && checkoutTimeLeft.value !== '00:00:00';
});

const updateTimeInterval = setInterval(updateCurrentTime, 1000);

const handleLinkClick = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'fast checkout badge',
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
};

onBeforeUnmount(() => {
  clearInterval(updateTimeInterval);
});
</script>

<template>
  <router-link
    v-if="isIncentiveAvailable"
    class="u-button-reset speedy-checkout-badge"
    :to="{ name: 'gallery', params: { reservationId: reservationId } }"
    @click="handleLinkClick"
  >
    <span class="u-label--heavy">{{ linkLabel }}</span>
    <span class="u-small--heavy speedy-checkout-badge__time">
      {{ checkoutTimeLeft }} remaining
      <SoonaIcon name="arrow-right" />
    </span>
  </router-link>
</template>

<style scoped lang="scss">
@use '@/variables';

.speedy-checkout-badge {
  background: variables.$gradient-pink-periwink;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  padding-bottom: 0.5rem;
  position: absolute;
  text-align: left;
  width: 100%;
  z-index: 2;

  &__time {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
</style>

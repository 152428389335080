<script setup>
import { computed, ref, watch } from 'vue';
import SceneOption from './SceneOption.vue';
import SceneItem from './SceneItem.vue';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

const props = defineProps({
  environmentType: {
    type: Number,
    required: false,
  },
  environmentFollowup: {
    type: Number,
    required: false,
  },
  environmentTypeOptions: {
    type: Array,
    required: false,
  },
  environmentFollowupOptions: {
    type: Array,
    required: false,
  },
});

const emit = defineEmits([
  'update:environmentType',
  'update:environmentFollowup',
]);

defineOptions({ inheritAttrs: false });

const backdropSelectionOpen = ref(false);

function selectEnvironmentType(event) {
  emit('update:environmentType', Number(event.target.value));
}

function selectEnvironmentFollowup(tagId) {
  emit('update:environmentFollowup', tagId);
  backdropSelectionOpen.value = false;
}

const selectedEnvironmentTypeTag = computed(() =>
  props.environmentTypeOptions?.find(x => x.id === props.environmentType)
);

const selectedEnvironmentFollowupTag = computed(() =>
  props.environmentFollowupOptions?.find(
    x => x.id === props.environmentFollowup
  )
);

// close dialog if environment type changes
watch(
  () => props.environmentType,
  () => {
    backdropSelectionOpen.value = false;
  }
);
</script>

<template>
  <SceneOption
    v-for="option in environmentTypeOptions"
    :id="option.id"
    :key="option.id"
    :image-url="option.image_url"
    :value="option.id"
    type="radio"
    :checked="environmentType === option.id"
    name="scene-environment-option"
    @change="selectEnvironmentType"
  >
    {{ option.title }}
  </SceneOption>

  <template
    v-if="
      selectedEnvironmentTypeTag &&
      environmentFollowupOptions &&
      environmentFollowupOptions.length > 0
    "
  >
    <h5>backdrop selection</h5>
    <SoonaItemCard
      :image-url="
        selectedEnvironmentFollowupTag
          ? selectedEnvironmentFollowupTag.image_url
          : selectedEnvironmentTypeTag.image_url
      "
      aria-labelledby="environment-followup-title"
    >
      <template #content>
        <div class="backdrop-tag__content">
          <p
            id="environment-followup-title"
            class="backdrop-tag__title"
            :class="{ 'u-visually-hidden': !selectedEnvironmentFollowupTag }"
          >
            {{
              selectedEnvironmentFollowupTag
                ? selectedEnvironmentFollowupTag.title
                : `choose ${selectedEnvironmentTypeTag.title}`
            }}
          </p>
          <SoonaButton
            v-if="selectedEnvironmentFollowupTag"
            variation="secondary-gray"
            size="medium"
            type="button"
            @on-click="backdropSelectionOpen = true"
          >
            change
          </SoonaButton>
          <SoonaButton
            v-else
            variation="primary"
            size="medium"
            type="button"
            @on-click="backdropSelectionOpen = true"
          >
            choose {{ selectedEnvironmentTypeTag.title }}
          </SoonaButton>
        </div>
      </template>
    </SoonaItemCard>
  </template>

  <SoonaDialog
    v-if="
      backdropSelectionOpen &&
      selectedEnvironmentTypeTag &&
      environmentFollowupOptions.length > 0
    "
    @close="backdropSelectionOpen = false"
  >
    <template #heading>
      <span class="backdrop-selection-dialog__heading">
        {{ selectedEnvironmentTypeTag.title }}s
      </span>
    </template>

    <div class="backdrop-selection-dialog__options">
      <SceneItem
        v-for="option in environmentFollowupOptions"
        :id="option.id"
        :key="option.id"
        :image-url="option.image_url"
      >
        <template #heading>{{ option.title }}</template>
        <template #actions>
          <SoonaButton
            class="choose-button"
            variation="primary"
            size="medium"
            type="button"
            :disabled="environmentFollowup === option.id"
            :aria-label="`choose ${option.title}`"
            @on-click="selectEnvironmentFollowup(option.id)"
          >
            choose
          </SoonaButton>
        </template>
      </SceneItem>
    </div>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';

.backdrop-selection-dialog {
  &__heading {
    text-transform: lowercase;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}

h5 {
  @include variables_fonts.u-label--heavy;
  margin-top: 0.5rem;
}

.backdrop-tag {
  &__content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
    height: 100%;
    justify-content: space-between;
    padding: 0.75rem 1rem;
  }

  &__title {
    @include variables_fonts.u-body--heavy;
    text-transform: lowercase;
  }
}
</style>

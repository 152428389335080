<script setup>
import { computed, ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import { usePriorityErrors } from 'src/composables/usePriorityErrors';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { useCreateCompetitor } from '@/queries/useCreateCompetitor';
import useSubscriptionAndIntegration from '@/composables/subscription/useSubscriptionAndIntegration';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useAccount } from '@/composables/useAccount';
import IntegrateAccountsDialog from '@/components/shared/IntegrateAccountsDialog.vue';
import { useSuggestedSubscription } from '@/composables/subscription/useSuggestedSubscription';
import { isAmazonIntegration } from '@/components/user/anytime/products/integration-utils';
import ReconnectAmazonDialog from '@/components/user/anytime/products/ReconnectAmazonDialog.vue';

const props = defineProps({
  accountId: { type: String, required: true },
});

const { buttonClicked } = useBaseEvents();

const accountId = computed(() => props.accountId);
const {
  subscriptionLoaded,
  integrationsLoaded,
  userCanUpgrade,
  integrationsData,
} = useSubscriptionAndIntegration(accountId);

const integrations = computed(() => integrationsData.value ?? []);

const hasAmazonIntegration = computed(() =>
  integrations.value.some(isAmazonIntegration)
);

const amazonIntegration = computed(() =>
  integrations.value.find(isAmazonIntegration)
);

const { suggestedCompetitorsTier } = useSuggestedSubscription(accountId);

const { account } = useAccount(accountId);

const hasMetTierQuota = computed(() => {
  return (
    account.value?.total_competitors_count >=
    account.value?.max_competitors_count
  );
});

const showDialog = ref(false);
const showReconnectAmazonDialog = ref(false);

const urlInput = ref('');

const { mutate, isPending, error, reset } = useCreateCompetitor(accountId);

const toggleShowDialog = value => {
  showDialog.value = value;
  if (!value) {
    urlInput.value = '';
    reset();
  }
};

const addToWatchlist = () => {
  mutate(
    { pageUrl: urlInput.value, externalProvider: 'amazon' },
    {
      onSuccess: () => {
        buttonClicked({
          context: 'competitive analysis',
          subContext: 'watchlist',
          buttonLabel: 'add to watchlist',
          buttonAction: 'addToWatchlist',
        });
        toggleShowDialog(false);
      },
    }
  );
};

const trackAddCompetitorClicked = () => {
  buttonClicked({
    context: 'competitive analysis',
    subContext: 'watchlist',
    buttonLabel: 'add competitors',
    buttonAction: 'handleClick',
  });
};

const variation = computed(() => {
  if (hasMetTierQuota.value || !hasAmazonIntegration.value) {
    return 'pizzazz';
  }

  return 'solid-black';
});

const showSubscriptionsDialog = ref(false);
const showIntegrationDialog = ref(false);

const handleClick = () => {
  if (!hasAmazonIntegration.value) {
    showIntegrationDialog.value = true;
    trackAddCompetitorClicked();
    return;
  }
  if (amazonIntegration.value?.status === 'invalid_state') {
    showReconnectAmazonDialog.value = true;
    trackAddCompetitorClicked();
    return;
  }
  if (hasMetTierQuota.value) {
    showSubscriptionsDialog.value = true;
    trackAddCompetitorClicked();
    return;
  }
  toggleShowDialog(true);
};

const displayButton = computed(() => {
  if (!subscriptionLoaded.value || !integrationsLoaded.value) {
    return false;
  }

  return !hasMetTierQuota.value || userCanUpgrade.value;
});

const priorityErrors = usePriorityErrors(error);
</script>

<template>
  <template v-if="displayButton">
    <SoonaButton size="medium" :variation="variation" @click="handleClick">
      <SoonaIcon name="plus-large" /> add
    </SoonaButton>
  </template>

  <IntegrateAccountsDialog
    v-if="showIntegrationDialog"
    amazon-only
    @close="showIntegrationDialog = false"
  />

  <SubscriptionsDialog
    v-if="showSubscriptionsDialog"
    :selected-tier-slug="suggestedCompetitorsTier.slug"
    @close="showSubscriptionsDialog = false"
  />

  <SoonaDialog
    v-if="showDialog"
    class="add-competitor-dialog"
    @close="toggleShowDialog(false)"
  >
    <template #heading>add from Amazon</template>
    <template #default>
      <ul class="add-competitor-dialog__instructions">
        <li><SoonaIcon name="binoculars" /><span>browse Amazon.com</span></li>
        <li>
          <SoonaIcon name="clone" /><span>copy the URL of any product</span>
        </li>
        <li><SoonaIcon name="plus" /><span>paste the address below</span></li>
        <li>
          <SoonaIcon name="wand-magic-sparkles" />
          <span>soona analyzes and saves to your watchlist</span>
        </li>
      </ul>
      <SoonaForm id="competitor-form" @submit="addToWatchlist">
        <div class="add-competitor-dialog__form-content">
          <SoonaTextfield
            v-model:model-value="urlInput"
            class="add-competitor-dialog__url-input"
            :has-error="!!priorityErrors"
            :diabled="isPending"
            label="Amazon product URL"
            type="text"
            required
            placeholder="https://www.amazon.com/dp/Z083ZRLL1H/"
          />
          <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
        </div>
      </SoonaForm>
    </template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" size="medium" @click="close">
        cancel
      </SoonaButton>
      <SoonaButton
        form="competitor-form"
        type="submit"
        size="medium"
        :loading="isPending"
      >
        add to watchlist
      </SoonaButton>
    </template>
  </SoonaDialog>
  <ReconnectAmazonDialog
    v-if="showReconnectAmazonDialog"
    @close="showReconnectAmazonDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.add-competitor-dialog {
  &__instructions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    li {
      display: flex;
      gap: 0.375rem;
      align-items: center;

      svg {
        color: variables.$periwink-blue-70;
      }
    }
  }

  &__form-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__url-input {
    padding-bottom: 0;
  }
}
</style>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useCreateAlbumCollection } from '@/queries/album_collections/useCreateAlbumCollection.js';
import { usePriorityError } from '@/composables/usePriorityError';
import { useRouter } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

const emits = defineEmits(['close', 'confirm']);

const router = useRouter();

const inputRef = ref(null);
const title = ref('');

const accountId = computed(() => props.accountId);

const { mutate, error } = useCreateAlbumCollection(accountId);
const priorityError = usePriorityError(error);

const handleCreateAlbum = () => {
  mutate(
    {
      body: {
        account_id: accountId.value,
        title: title.value,
      },
    },
    {
      onSuccess: res => {
        router.push({
          name: 'account-gallery',
          params: { accountId: accountId.value },
          query: { collection_id: res.id },
        });
        emits('close');
      },
    }
  );
};

onMounted(() => {
  inputRef.value.focus();
});
</script>

<template>
  <SoonaDialog @close="emits('close')">
    <template #heading>
      <h2 class="u-title--heavy">new album</h2>
    </template>
    <SoonaTextfield
      ref="inputRef"
      v-model="title"
      name="album-title"
      type="text"
      label="album name"
      data-cypress="input-new-album-name"
    />
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton @on-click="handleCreateAlbum">create</SoonaButton>
    </template>
  </SoonaDialog>
</template>

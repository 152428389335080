<script setup>
import { computed, ref, shallowRef } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import RequirementEdit from './RequirementEdit.vue';
import { component as VViewer } from 'v-viewer';
import UploadedFile from '@/components/crew/account-requirements/UploadedFile.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaEditorContent from '@/components/ui_library/soona_editor/SoonaEditorContent.vue';
import AccountRequirementsVersionsDialog from '@/components/crew/account-requirements/AccountRequirementsVersionsDialog.vue';

const props = defineProps({
  accountRequirement: {
    type: Object,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: true,
  },
});

const files = computed(() => {
  return props.accountRequirement.files ?? [];
});

const filesImages = computed(() => {
  return files.value.filter(file => file.content_type.includes('image'));
});

const filesOther = computed(() => {
  return files.value.filter(file => !file.content_type.includes('image'));
});

const $viewer = shallowRef(null);

const inited = viewer => {
  $viewer.value = viewer;
};

const viewIndex = index => {
  $viewer.value.view(index);
};

const editMode = ref(false);
const historyDialogVisible = ref(false);
</script>

<template>
  <div class="requirement-view">
    <template v-if="!editMode">
      <div class="requirement-view__meta">
        <SoonaButton
          variation="tertiary"
          @on-click="historyDialogVisible = true"
        >
          <SoonaIcon name="refresh-ccw-clock" /> history
        </SoonaButton>
        <SoonaButton
          v-if="!readonly"
          variation="tertiary"
          @on-click="editMode = true"
        >
          <SoonaIcon name="pen-square" /> edit
        </SoonaButton>
      </div>

      <AccountRequirementsVersionsDialog
        v-if="historyDialogVisible"
        :account-id="accountRequirement.account_id"
        :requirement-type="accountRequirement.requirement_type"
        @close="historyDialogVisible = false"
      />

      <SoonaEditorContent :content-html="accountRequirement.content_html" />

      <div v-if="files.length > 0" class="requirement-view__files">
        <VViewer
          v-if="filesImages.length > 0"
          class="requirement-view__files__images"
          :images="filesImages"
          :options="{
            toolbar: true,
            navbar: true,
            title: true,
            backdrop: true,
            minZoomRatio: 0.01,
            maxZoomRatio: 2,
            url: 'data-source',
          }"
          @inited="inited"
        >
          <template #default="{ images }">
            <SoonaTooltip
              v-for="(file, index) in images"
              :key="file.id"
              placement="top"
            >
              <template
                #default="{ setRef, mouseenter, focus, mouseleave, blur }"
              >
                <img
                  :ref="setRef"
                  :key="file.id"
                  :src="file.thumbnail.url"
                  :data-source="file.download_url ?? file.thumbnail.url"
                  :alt="file.filename"
                  tabindex="0"
                  @blur="blur"
                  @focus="focus"
                  @mouseenter="mouseenter"
                  @mouseleave="mouseleave"
                  @keyup.enter="viewIndex(index)"
                />
              </template>
              <template #tooltip-content>{{ file.filename }}</template>
            </SoonaTooltip>
          </template>
        </VViewer>

        <UploadedFile
          v-for="file in filesOther"
          :key="file.id"
          :content-type="file.content_type"
          :download-url="file.download_url"
          :filename="file.filename"
          :meta="file.file_size_display"
        />
      </div>
    </template>

    <RequirementEdit
      v-if="editMode"
      :account-requirement="accountRequirement"
      @close="editMode = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.requirement-view {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__meta {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  &__files {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.5rem;

    &__images {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 0.75rem;
      flex-wrap: wrap;

      img {
        border-radius: 0.3125rem;
        flex: 0 0 5rem;
        cursor: pointer;
        height: 5rem;
        width: auto;
        object-fit: cover;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }
}
</style>

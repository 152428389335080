import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { updateCatalogItem, archiveCatalogItems } from 'src/api/catalog';
import { queryKeys } from './query-keys';
import { unref } from 'vue';

/**
 *
 * @param {Ref<number> | number} accountId
 * @param {Ref<number> | number} itemId
 * @returns {UseMutationReturnType}
 */
export function useUpdateCatalogItem(accountId, itemId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: productDetails =>
      updateCatalogItem(unref(accountId), unref(itemId), { productDetails }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.catalogItem(accountId, itemId),
      });
    },
  });
}

export function useArchiveCatalogItems(accountId) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: items => archiveCatalogItems(unref(accountId), { items }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.catalog(accountId),
      });
    },
  });
}

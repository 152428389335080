<script setup>
import { computed, toRefs, ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SidebarSection from './SidebarSection.vue';
import { useCreateABrief } from '@/composables/trend/useCreateABrief';
import { useFlag } from '@/composables/useFlag';
import { useReservations } from 'src/queries/reservations/useReservations';
import ShootSetupDialog from './ShootSetupDialog.vue';
import { useSiteNavEvents } from '../useSiteNavEvents';
import DuplicateReservationDialog from '@/components/reservation/DuplicateReservationDialog.vue';
import { useCapability } from '@/composables/useCapability';

const props = defineProps({
  currentAccountId: {
    type: Number,
    required: true,
  },
});

const { currentAccountId } = toRefs(props);
const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});
const { trackLinkClicked } = useSiteNavEvents();

// UGC
const utmContent = 'side_nav_create_ugc_brief';
const ugcButtonCopy = 'create a UGC brief';
const subContext = 'site sidebar navigation';

const { handleCreateABrief } = useCreateABrief({
  utmContent,
  subContext,
  buttonCopy: ugcButtonCopy,
});

//grab a few live bookings in case there are suprise shoots going on
const { data: liveReservationsData } = useReservations(
  {
    accountId: currentAccountId,
    itemsPerPage: 4,
    currentPage: 1,
    filter: 'live',
  },
  // mark as fresh for 1 minute to cache  results a little
  { staleTime: 60 * 1000 }
);

const reservationInProgress = computed(() =>
  liveReservationsData.value?.reservations.find(
    liveReservation =>
      liveReservation.status === 'in_progress' &&
      liveReservation.reservation_type !== 'surprise'
  )
);

// duplicate bookings
const { data: previousReservationsData } = useReservations(
  {
    accountId: currentAccountId,
    itemsPerPage: 1,
    currentPage: 1,
    filter: 'duplicatable',
  },
  { staleTime: 60 * 1000 }
);

const pegasusDuplicateReservationFlag = useFlag(
  'pegasus_duplicate_reservation'
);

const hasPreviousBookings = computed(
  () => previousReservationsData.value?.reservations.length > 0
);

const displayShootSetupDialog = ref(false);
const displayDuplicateBookingDialog = ref(false);
const reservationIdToDuplicate = ref(null);

const handleDisplayDuplicateReservationDialog = reservationId => {
  displayShootSetupDialog.value = false;
  reservationIdToDuplicate.value = reservationId;
  displayDuplicateBookingDialog.value = true;
};

const handleCloseDisplayDuplicateReservationDialog = () => {
  displayDuplicateBookingDialog.value = false;
  displayShootSetupDialog.value = true;
  reservationIdToDuplicate.value = null;
};
</script>

<template>
  <SidebarSection>
    <div class="primary-nav-action-buttons">
      <SoonaButton
        v-if="reservationInProgress"
        element="a"
        size="medium"
        :href="`/#/reservation/${reservationInProgress.id}`"
        @on-click="trackLinkClicked('join live', '/reservation')"
      >
        join live
      </SoonaButton>
      <SoonaButton
        v-else-if="
          hasPreviousBookings &&
          !isFtSoonaStaff &&
          pegasusDuplicateReservationFlag
        "
        size="medium"
        @click="displayShootSetupDialog = true"
      >
        build a shoot
      </SoonaButton>
      <SoonaButton
        v-else
        element="router-link"
        size="medium"
        :to="{ name: 'createReservation' }"
        @on-click="trackLinkClicked('create', '/book')"
      >
        build a shoot
      </SoonaButton>
      <SoonaButton
        class="primary-nav__create-ugc-btn"
        size="medium"
        variation="secondary-black"
        @on-click="handleCreateABrief"
      >
        {{ ugcButtonCopy }}
      </SoonaButton>
    </div>
  </SidebarSection>
  <ShootSetupDialog
    v-if="displayShootSetupDialog"
    :current-account-id="currentAccountId"
    @close="displayShootSetupDialog = false"
    @duplicate-reservation="handleDisplayDuplicateReservationDialog"
  />
  <DuplicateReservationDialog
    v-if="displayDuplicateBookingDialog"
    :reservation-id="reservationIdToDuplicate"
    @close="handleCloseDisplayDuplicateReservationDialog"
  />
</template>

<style scoped>
.primary-nav-action-buttons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 1rem;
  gap: 0.75rem;
}
</style>

<template>
  <div class="ProfileQuizCards">
    <transition name="slide-fade" mode="out-in" tag="div">
      <component :is="currentComponent" />
    </transition>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import AccountType from 'src/components/user/anytime/profile/AccountType.vue';
import WhatBringsYouToSoona from 'src/components/user/anytime/profile/WhatBringsYouToSoona.vue';
import LetsGetTheBasics from 'src/components/user/anytime/profile/LetsGetTheBasics.vue';
import AccountAddress from 'src/components/user/anytime/profile/AccountAddress.vue';
import PreferredTimezone from 'src/components/user/anytime/profile/PreferredTimezone.vue';
import LetsConnect1 from 'src/components/user/anytime/profile/LetsConnect1.vue';
import LetsConnect2 from 'src/components/user/anytime/profile/LetsConnect2.vue';
import UseMyContent from 'src/components/user/anytime/profile/UseMyContent.vue';
import WhereDoYouAdvertise from 'src/components/user/anytime/profile/WhereDoYouAdvertise.vue';
import WhereDoYouSell from 'src/components/user/anytime/profile/WhereDoYouSell.vue';
import AlternateContentUse from 'src/components/user/anytime/profile/AlternateContentUse.vue';
import BrandFont from 'src/components/user/anytime/profile/BrandFont.vue';
import BrandColor from 'src/components/user/anytime/profile/BrandColor.vue';
import UploadProfilePic from 'src/components/user/anytime/profile/UploadProfilePic.vue';
import CompanyLogo from 'src/components/user/anytime/profile/CompanyLogo.vue';
import BrandGuide from 'src/components/user/anytime/profile/BrandGuide.vue';
import VisualStyle from 'src/components/user/anytime/profile/VisualStyle.vue';

export default {
  name: 'ProfileQuizCards',
  components: {
    AccountType,
    WhatBringsYouToSoona,
    LetsGetTheBasics,
    AccountAddress,
    PreferredTimezone,
    LetsConnect1,
    LetsConnect2,
    UseMyContent,
    WhereDoYouAdvertise,
    WhereDoYouSell,
    AlternateContentUse,
    BrandFont,
    BrandColor,
    UploadProfilePic,
    CompanyLogo,
    BrandGuide,
    VisualStyle,
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    ...mapGetters('account', [
      'hasAccountType',
      'whatBringsYouToSoonaCompleted',
      'letsGetTheBasicsCompleted',
      'addressCompleted',
      'timezoneCompleted',
      'letsConnect1Completed',
      'letsConnect2Completed',
      'useMyContentCompleted',
      'whereDoYouAdvertiseCompleted',
      'whereDoYouSellCompleted',
      'alternateContentUseCompleted',
      'fontsCompleted',
      'colorsCompleted',
      'profilePicCompleted',
      'companyLogoCompleted',
      'brandGuideCompleted',
      'styleQuizCompleted',
    ]),
    currentComponent() {
      if (!this.whatBringsYouToSoonaCompleted) {
        return WhatBringsYouToSoona;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        !this.letsGetTheBasicsCompleted
      ) {
        return LetsGetTheBasics;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        !this.addressCompleted
      ) {
        return AccountAddress;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        !this.timezoneCompleted
      ) {
        return PreferredTimezone;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        !this.profilePicCompleted
      ) {
        return UploadProfilePic;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        !this.letsConnect1Completed
      ) {
        return LetsConnect1;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        this.letsConnect1Completed &&
        !this.letsConnect2Completed
      ) {
        return LetsConnect2;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        this.letsConnect1Completed &&
        this.letsConnect2Completed &&
        !this.useMyContentCompleted
      ) {
        return UseMyContent;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        this.letsConnect1Completed &&
        this.letsConnect2Completed &&
        this.useMyContentCompleted &&
        !this.whereDoYouAdvertiseCompleted
      ) {
        return WhereDoYouAdvertise;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        this.letsConnect1Completed &&
        this.letsConnect2Completed &&
        this.useMyContentCompleted &&
        this.whereDoYouAdvertiseCompleted &&
        !this.whereDoYouSellCompleted
      ) {
        return WhereDoYouSell;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        this.letsConnect1Completed &&
        this.letsConnect2Completed &&
        this.useMyContentCompleted &&
        this.whereDoYouAdvertiseCompleted &&
        this.whereDoYouSellCompleted &&
        !this.alternateContentUseCompleted
      ) {
        return AlternateContentUse;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        this.letsConnect1Completed &&
        this.letsConnect2Completed &&
        this.useMyContentCompleted &&
        this.whereDoYouAdvertiseCompleted &&
        this.whereDoYouSellCompleted &&
        this.alternateContentUseCompleted &&
        !this.companyLogoCompleted
      ) {
        return CompanyLogo;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        this.letsConnect1Completed &&
        this.letsConnect2Completed &&
        this.useMyContentCompleted &&
        this.whereDoYouAdvertiseCompleted &&
        this.whereDoYouSellCompleted &&
        this.alternateContentUseCompleted &&
        this.companyLogoCompleted &&
        !this.fontsCompleted
      ) {
        return BrandFont;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        this.letsConnect1Completed &&
        this.letsConnect2Completed &&
        this.useMyContentCompleted &&
        this.whereDoYouAdvertiseCompleted &&
        this.whereDoYouSellCompleted &&
        this.alternateContentUseCompleted &&
        this.companyLogoCompleted &&
        this.fontsCompleted &&
        !this.colorsCompleted
      ) {
        return BrandColor;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        this.letsConnect1Completed &&
        this.letsConnect2Completed &&
        this.useMyContentCompleted &&
        this.whereDoYouAdvertiseCompleted &&
        this.whereDoYouSellCompleted &&
        this.alternateContentUseCompleted &&
        this.companyLogoCompleted &&
        this.fontsCompleted &&
        this.colorsCompleted &&
        !this.brandGuideCompleted
      ) {
        return BrandGuide;
      }
      if (
        this.whatBringsYouToSoonaCompleted &&
        this.letsGetTheBasicsCompleted &&
        this.addressCompleted &&
        this.timezoneCompleted &&
        this.profilePicCompleted &&
        this.letsConnect1Completed &&
        this.letsConnect2Completed &&
        this.useMyContentCompleted &&
        this.whereDoYouAdvertiseCompleted &&
        this.whereDoYouSellCompleted &&
        this.alternateContentUseCompleted &&
        this.companyLogoCompleted &&
        this.fontsCompleted &&
        this.colorsCompleted &&
        this.brandGuideCompleted &&
        !this.styleQuizCompleted
      ) {
        return VisualStyle;
      }

      return null;
    },
  },
};
</script>

import { ref } from 'vue';
import {
  useCreatePromotion,
  useUpdatePromotion,
} from 'src/queries/usePromotions';
import { useRouter } from 'vue-router';

export const useCreateOrUpdatePromotion = promoId => {
  const router = useRouter();
  const { mutate: createMutation, isPending: isLoading } = useCreatePromotion();
  const { mutate: updateMutation } = useUpdatePromotion(promoId);
  const errors = ref(null);

  const formData = ref({
    title: null,
    promo_type: null,
    promo_code: null,
    start_at: null,
    end_at: null,
    promotion_widgets_attributes: [
      {
        id: null,
        type: 'BookingBanner',
        description: null,
        enabled: false,
        copy_code_enabled: false,
        link_modal_title: null,
        link_modal_description: null,
        link_cta: null,
        link_enabled: false,
        link_modal_enabled: false,
        link_icon_enabled: false,
        link_icon: null,
        text_color: 'white',
        background_color: '#5566ea',
      },
      {
        id: null,
        type: 'PlatformBanner',
        description: null,
        enabled: false,
        copy_code_enabled: false,
        link_modal_title: null,
        link_modal_description: null,
        link_cta: null,
        link_enabled: false,
        link_modal_enabled: false,
        link_icon_enabled: false,
        link_icon: null,
        text_color: 'white',
        background_color: '#5566ea',
      },
    ],
  });

  const createPromotion = () => {
    createMutation(
      { promotion: formData.value },
      {
        onSuccess: () => router.push('/admin/promotions'),
        onError: error => {
          errors.value = error.response.data.errors;
        },
      }
    );
  };

  const updatePromotion = () => {
    updateMutation(
      { promotion: formData.value },
      {
        onSuccess: () => router.push('/admin/promotions'),
        onError: error => {
          errors.value = error.response.data.errors;
        },
      }
    );
  };

  const setFormData = (key, value) => {
    formData.value[key] = value;
  };

  const mapExistingPromotionToFormData = promotion => {
    if (promotion) {
      formData.value.title = promotion.title;
      formData.value.promo_type = promotion.promo_type;
      formData.value.promo_code = promotion.promo_code;
      formData.value.start_at = promotion.start_at;
      formData.value.end_at = promotion.end_at;

      formData.value.promotion_widgets_attributes.forEach(pwAttr => {
        let promotionWidget = promotion.promotion_widgets.find(
          pw => pwAttr['type'] === pw.type
        );

        pwAttr.id = promotionWidget.id;
        pwAttr.description = promotionWidget.description;
        pwAttr.enabled = promotionWidget.enabled;
        pwAttr.link_modal_enabled = promotionWidget.link_modal_enabled;
        pwAttr.link_modal_title = promotionWidget.link_modal_title;
        pwAttr.link_modal_description = promotionWidget.link_modal_description;
        pwAttr.link_enabled = promotionWidget.link_enabled;
        pwAttr.link_cta = promotionWidget.link_cta;
        pwAttr.link_url = promotionWidget.link_url;
        pwAttr.link_icon_enabled = promotionWidget.link_icon_enabled;
        pwAttr.link_icon = promotionWidget.link_icon;
        pwAttr.text_color = promotionWidget.text_color;
        pwAttr.background_color = promotionWidget.background_color;
        pwAttr.type = promotionWidget.type;
        pwAttr.copy_code_enabled = promotionWidget.copy_code_enabled;
      });
    }
  };

  const setPromotionWidgetsAttributes = (key, value) => {
    let bookingBanner = formData.value.promotion_widgets_attributes.find(
      pwAttr => pwAttr.type === 'BookingBanner'
    );

    bookingBanner[key] = value;
  };

  const setPlatformBannerAttributes = (key, value) => {
    let platformBanner = formData.value.promotion_widgets_attributes.find(
      pwAttr => pwAttr.type === 'PlatformBanner'
    );

    platformBanner[key] = value;
  };

  const setStyles = value => {
    let bookingBanner = formData.value.promotion_widgets_attributes.find(
      pwAttr => pwAttr.type === 'BookingBanner'
    );

    bookingBanner.text_color = value.color;
    bookingBanner.background_color = value.background_color;
  };

  const setPlatformBannerStyles = value => {
    let platformBanner = formData.value.promotion_widgets_attributes.find(
      pwAttr => pwAttr.type === 'PlatformBanner'
    );

    platformBanner.text_color = value.color;
    platformBanner.background_color = value.background_color;
  };

  return {
    formData,
    createPromotion,
    updatePromotion,
    setFormData,
    setPromotionWidgetsAttributes,
    setPlatformBannerAttributes,
    setStyles,
    setPlatformBannerStyles,
    mapExistingPromotionToFormData,
    isLoading,
    errors,
  };
};

<script setup>
import { computed, ref, toRefs } from 'vue';
import EmptyImg from 'images/shot-list/scenes/empty-products.png';
import SectionLayout from './SectionLayout.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import ProductPickerGroupTitle from '@/components/user/anytime/products/picker/ProductPickerGroupTitle.vue';
import ProductMultiPicker from '@/components/user/anytime/products/picker/ProductMultiPicker.vue';
import PickerProductMultiselectOption from '@/components/user/anytime/products/picker/PickerProductMultiselectOption.vue';
import ProductChip from '@/components/user/anytime/products/ProductChip.vue';
import useGroupCatalogItemsByReservation from '@/composables/useGroupCatalogItemsByReservation';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
  showSaveError: {
    default: false,
    type: Boolean,
  },
});

const { accountId, reservationId } = toRefs(props);

const showDialog = ref(false);

const selectedCatalogItems = defineModel('selectedCatalogItems', {
  type: Array,
  required: true,
});

const selectedProducts = computed(() =>
  selectedCatalogItems.value.filter(ci => ci.type === 'CatalogProduct')
);
const selectedCustomProps = computed(() =>
  selectedCatalogItems.value.filter(ci => ci.type === 'CatalogProp')
);
const selectedCatalogItemIds = computed(() =>
  selectedCatalogItems.value.map(ci => ci.id)
);

function deselectCatalogItem(catalogItemId) {
  selectedCatalogItems.value = selectedCatalogItems.value.filter(
    x => x.id !== catalogItemId
  );
}

function selectCatalogItems(catalogItems) {
  selectedCatalogItems.value = selectedCatalogItems.value.concat(catalogItems);
  showDialog.value = false;
}

const { groupProducts } = useGroupCatalogItemsByReservation(reservationId);
</script>

<template>
  <!-- TODO: base show-empty on selected product length -->
  <SectionLayout
    :empty-img="EmptyImg"
    has-small-img
    :show-empty="selectedCatalogItems.length < 1"
  >
    <template #heading>products & custom props</template>
    <template #subheading>
      include all products that will appear in the scene, even if they do not
      appear in every shot.
    </template>
    <template #empty-heading>which products are in this scene?</template>
    <template #empty-error>
      <SoonaError v-if="showSaveError" no-margin>
        select at least one product or custom prop
      </SoonaError>
    </template>
    <template #empty-btn>
      <SoonaButton variation="solid-black" @on-click="showDialog = true">
        <SoonaIcon name="plus-large" /> add products
      </SoonaButton>
    </template>

    <SoonaButton
      variation="secondary-gray"
      size="medium"
      @on-click="showDialog = true"
    >
      <SoonaIcon name="plus-large" /> add products
    </SoonaButton>
    <template v-if="selectedProducts.length > 0">
      <h5 id="shotlist-builder-products-heading" class="u-small--heavy">
        products
      </h5>
      <ul
        class="shotlist-builder-catalog-items__chips"
        aria-labelledby="shotlist-builder-products-heading"
      >
        <li v-for="item in selectedProducts" :key="item.id">
          <ProductChip
            :item="item"
            :account-id="accountId"
            removable
            :is-link="false"
            @remove="deselectCatalogItem(item.id)"
          />
        </li>
      </ul>
    </template>

    <template v-if="selectedCustomProps.length > 0">
      <h5 id="shotlist-builder-custom-props-heading" class="u-small--heavy">
        custom props
      </h5>
      <ul
        class="shotlist-builder-catalog-items__chips"
        aria-labelledby="shotlist-builder-custom-props-heading"
      >
        <li v-for="item in selectedCustomProps" :key="item.id">
          <ProductChip
            :item="item"
            :account-id="accountId"
            removable
            :is-link="false"
            @remove="deselectCatalogItem(item.id)"
          />
        </li>
      </ul>
    </template>
  </SectionLayout>

  <ProductMultiPicker
    v-if="showDialog"
    :account-id="accountId"
    heading="add products"
    :existing-selected-items="selectedCatalogItemIds"
    :attached-to-reservation-id="reservationId"
    order-by="attached_to_reservation_id"
    @cancel="showDialog = false"
    @select="selectCatalogItems"
  >
    <template
      #product-options="{
        catalogItems,
        selectedCatalogItemIdsString,
        multiSelectChange,
      }"
    >
      <template
        v-for="(items, group) in groupProducts(catalogItems)"
        :key="group"
      >
        <ProductPickerGroupTitle>{{ group }}</ProductPickerGroupTitle>
        <PickerProductMultiselectOption
          v-for="product in items"
          :key="product.id"
          :product="product"
          :checked="
            selectedCatalogItemIdsString.includes(product.id.toString()) ||
            selectedCatalogItemIds.includes(product.id)
          "
          :disabled="selectedCatalogItemIds.includes(product.id)"
          @change="multiSelectChange($event, product)"
        />
      </template>
    </template>
  </ProductMultiPicker>
</template>

<style lang="scss" scoped>
.shotlist-builder-catalog-items {
  &__chips {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    list-style: none;
  }
}
</style>

/*
 * these are all used `undefined` for the locale so the user's preference will
 * be used, rather than `en-US` for all
 */

// 3:35 PM
export const timeFormat = new Intl.DateTimeFormat(undefined, {
  timeStyle: 'short',
});

// Tuesday
export const weekdayFormat = new Intl.DateTimeFormat(undefined, {
  weekday: 'long',
});

// Dec 3
export const dateFormat = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  day: 'numeric',
});

// Dec 3, 2024
export const dateYearFormat = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'medium',
});

// Tuesday, December 3, 2024 at 3:35 PM
export const fullDateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'full',
  timeStyle: 'short',
});

// for use in things like 'yesterday' and '12 minutes ago'
export const relativeTimeFormatter = new Intl.RelativeTimeFormat(undefined, {
  localeMatcher: 'best fit',
  numeric: 'auto',
  style: 'long',
});

import { computed, unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import {
  getInventoryPackages,
  checkForDuplicatePackages,
  getInventoryPackagesSummary,
} from '../../api/inventoryPackages';
import { queryKeys } from '../query-keys';

/**
 *
 * @param {Object} params
 * @param {number | Ref<number>} [params.accountId]
 * @param {number | null | Ref<number | null>} [params.pickable]
 * @param {number | Ref<number>} [params.skipReservationId]
 * @param {number | Ref<number>} [params.reservationId]
 * @param {boolean} [params.hasEligibleReturns]
 * @param {number | Ref<number>} [params.itemsPerPage]
 * @param {number | Ref<number>} [params.currentPage]
 * @param {string | Ref<string>} [params.filteredStatus]
 * @param {UseQueryOptions} queryOptions
 */
export function useInventoryPackages(params = {}, queryOptions) {
  return useQuery({
    queryKey: queryKeys.inventoryPackages(params),
    queryFn: ({ signal }) =>
      getInventoryPackages(
        {
          accountId: unref(params.accountId),
          pickable: unref(params.pickable),
          skipReservationId: unref(params.skipReservationId),
          reservationId: unref(params.reservationId),
          hasEligibleReturns: params.hasEligibleReturns,
          itemsPerPage: unref(params.itemsPerPage),
          currentPage: unref(params.currentPage),
          filteredStatus: unref(params.filteredStatus),
        },
        signal
      ),
    ...queryOptions,
  });
}

export function useCheckForDuplicatePackages(params = {}) {
  return useQuery({
    queryKey: queryKeys.duplicatePackages(
      params.accountId,
      params.trackingNumber
    ),
    queryFn: ({ signal }) =>
      checkForDuplicatePackages(
        {
          accountId: unref(params.accountId),
          trackingNumber: unref(params.trackingNumber),
        },
        signal
      ),
    enabled: computed(() => params.trackingNumber.value !== undefined),
  });
}

export function useInventoryPackagesSummary(params = {}, queryOptions) {
  return useQuery({
    queryKey: queryKeys.inventoryPackagesSummary(params),
    queryFn: ({ signal }) =>
      getInventoryPackagesSummary(
        {
          query: unref(params.query),
          page: unref(params.page),
          itemsPerPage: unref(params.itemsPerPage),
          locationId: unref(params.locationId),
          orderBy: unref(params.orderBy),
          direction: unref(params.direction),
          returnLabel: unref(params.returnLabel),
          shippingStatus: unref(params.shippingStatus),
          packageStatus: unref(params.packageStatus),
          requiredAction: unref(params.requiredAction),
        },
        signal
      ),
    ...queryOptions,
  });
}

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import { useRefreshIntegration } from '@/queries/useRefreshIntegration';
import {
  amazonAuthorizationRedirectUrl,
  amazonSellerCentralManageUrl,
} from '@/lib/integrations/constants';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  integration: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['openRemoveAmazonDataDialog', 'close']);

dayjs.extend(relativeTime);

const integration = computed(() => props.integration);

const accountId = computed(() => props.accountId);

const requiresAttention = computed(() => {
  return integration.value.status === 'invalid_state';
});

const lastUpdated = computed(() => {
  return integration.value.refreshed_at_in_words
    ? `updated ${integration.value.refreshed_at_in_words} ago`
    : `updated ${dayjs().to(dayjs(integration.value.created_at))}`;
});

const refreshDisabled = computed(() => integration.value.is_importing);

const { mutate } = useRefreshIntegration(accountId);

function importProducts() {
  mutate({
    integration_type: 'amazon',
    id: integration.value.id,
  });
  emits('close');
}
</script>

<template>
  <div class="amazon-integration">
    <div class="amazon-integration__store">
      <SoonaIcon name="amazon-logo" size="2x-large" />
      <div class="amazon-integration__store__details">
        <p class="amazon-integration__store__details__name">
          Amazon Seller Account
        </p>
        <template v-if="requiresAttention">
          <p class="amazon-integration__store__details__requires-attention">
            <SoonaIcon
              class="amazon-integration__store__details__requires-attention-icon"
              name="circle-exclamation-solid"
              size="small"
            />
            disconnected
          </p>
        </template>
        <template v-else>
          <p class="amazon-integration__store__details__last-updated">
            {{ lastUpdated }}
          </p>
        </template>
      </div>
    </div>
    <template v-if="requiresAttention">
      <div class="amazon-integration__btn-group">
        <SoonaButton
          variation="tertiary"
          size="medium"
          @click="emits('openRemoveAmazonDataDialog', integration.id)"
        >
          remove
        </SoonaButton>
        <SoonaButton
          element="a"
          variation="secondary-black"
          size="medium"
          :href="amazonAuthorizationRedirectUrl"
        >
          reconnect
        </SoonaButton>
      </div>
    </template>
    <template v-else>
      <SoonaDropdownMenu
        v-if="!requiresAttention"
        class="amazon-integration__dropdown"
        variation="icon-plain-gray"
      >
        <template #trigger-content>
          <SoonaIcon name="dots-horizontal" size="large" />
        </template>
        <template #default="{ keydown, mouseover, clickCapture }">
          <SoonaDropdownMenuItem>
            <button
              role="menuitem"
              class="no-text-transform"
              :disabled="refreshDisabled"
              @keydown="keydown"
              @mouseover="mouseover"
              @click.capture="clickCapture"
              @click="() => importProducts()"
            >
              <SoonaIcon
                class="integrations-info__button-icon"
                size="medium"
                name="refresh-cw-alt"
              />&nbsp; refresh
            </button>
          </SoonaDropdownMenuItem>
          <SoonaDropdownMenuItem>
            <a
              role="menuitem"
              aria-label="manage amazon store"
              class="no-text-transform"
              target="_blank"
              :href="amazonSellerCentralManageUrl"
              @click.capture="clickCapture"
              @keydown="keydown"
              @mouseover="mouseover"
            >
              <SoonaIcon
                class="integrations-info__button-icon"
                size="medium"
                name="arrow-up-right-from-square"
              />&nbsp; manage
            </a>
          </SoonaDropdownMenuItem>
        </template>
      </SoonaDropdownMenu>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.amazon-integration {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;

  &__btn-group {
    flex: 1;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
  }

  &__store {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;

    &__details {
      &__name {
        @include variables_fonts.u-body--heavy;
        color: variables.$gray-80;
      }

      &__last-updated {
        @include variables_fonts.u-label--small;
        color: variables.$gray-50;
      }

      &__requires-attention-icon {
        color: variables.$roses-60;
        height: 0.75rem;
        width: 0.75rem;
      }

      &__requires-attention {
        @include variables_fonts.u-label--small;

        display: flex;
        gap: 0.25rem;
        align-items: center;
        color: variables.$black-default;
      }
    }
  }
}
</style>

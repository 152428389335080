<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaUploadForm from '@/components/uploader/SoonaUploadForm.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useUploader } from 'src/composables/useUploader.js';
import { useStorageUsage } from '@/queries/account/useStorageUsage';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaUploadProgressModal from '@/components/uploader/SoonaUploadProgressModal.vue';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';

const props = defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
  platform: {
    type: String,
    required: true,
  },
  uploadedFiles: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['uploadComplete']);

const accountId = computed(() => props.accountId);
const { addToast } = useSoonaToast();

const { data: accountStorage, error: availStorageFetchError } =
  useStorageUsage(accountId);

const remainingStorageBytes = computed(() => {
  if (accountStorage.value) {
    return (
      accountStorage.value?.total_available_storage -
      accountStorage.value?.total_used_storage
    );
  }

  return 0;
});

const maxConcurrent = ref(1);
const uploadInterval = ref(100);
const fileSizeLimit = ref(50);
const isDragover = ref(false);
const alertMessage = ref('');
const errorMessage = ref('');
const activeUploadsLocal = ref([]);
const cancelUploadsLocal = ref(() => {});
const acceptedFileTypes = ref('.png,.jpg,.jpeg');
const preventLowResolutionImageUpload = ref(true);
const preventUploadGreaterThanTwentyMegapixels = computed(() =>
  props.platform === 'Shopify' ? true : false
);

function validateNotCR2(file) {
  if (file.name.toLowerCase().endsWith('.cr2'))
    return 'cannot upload RAW (CR2) files here';
}

const showAlertOrError = (type = 'alert', message) => {
  if (type === 'error') {
    errorMessage.value = message;
    return;
  }

  alertMessage.value = message;
};

const onUploadError = errMsg => {
  cancelUploadsLocal.value();
  showAlertOrError('error', errMsg);
};

const onUploadComplete = blob => {
  emit('uploadComplete', blob);
};

const {
  handleUpload,
  handleDrop,
  activeUploads,
  // percentComplete: totalPercentComplete,
  cancelUploads,
  startUpload,
} = useUploader(
  maxConcurrent,
  uploadInterval,
  fileSizeLimit,
  validateNotCR2,
  onUploadError,
  onUploadComplete,
  remainingStorageBytes,
  preventLowResolutionImageUpload,
  preventUploadGreaterThanTwentyMegapixels
);

watchEffect(() => {
  activeUploadsLocal.value = activeUploads.value;
  cancelUploadsLocal.value = cancelUploads;
});

const handleDroppedFiles = e => {
  // don’t allow drag-and-drop upload if available storage check is still loading
  if (!accountStorage.value) return;

  isDragover.value = false;
  alertMessage.value = '';
  errorMessage.value = '';

  handleDrop(e);
};

const isUploading = computed(() => activeUploads.value.length > 0);

const completedUploads = computed(() =>
  activeUploads.value.filter(upload => upload.completed)
);

const handleCancelUploads = () => {
  if (completedUploads.value.length > 0) {
    cancelUploads();
  } else {
    cancelUploads();
    addToast('upload canceled', {
      variation: 'info',
      iconName: 'xmark',
    });
  }
};

const priorityError = usePriorityError(availStorageFetchError, errorMessage);
</script>

<template>
  <SoonaUploadForm
    v-if="!isUploading"
    :accept="acceptedFileTypes"
    :active-uploads="activeUploadsLocal"
    label="upload from device"
    @drop="handleDroppedFiles"
    @upload="
      $event => {
        alertMessage = '';
        errorMessage = '';
        handleUpload($event);
      }
    "
  >
    <template #content-bottom>
      <SoonaAlert
        v-if="alertMessage"
        class="listing-action-upload__notification"
      >
        {{ alertMessage }}
      </SoonaAlert>
      <SoonaError
        v-if="errorMessage || priorityError"
        class="listing-action-upload__notification"
      >
        {{ errorMessage || priorityError }}
      </SoonaError>
      <ul v-if="uploadedFiles" class="uploaded-files">
        <li v-for="file in uploadedFiles" :key="file.key">
          <img
            v-if="file.content_type.startsWith('image')"
            :src="`/rails/active_storage/blobs/${file.signed_id}/${file.filename}`"
            class="thumbnail-img"
          />
          {{ file.filename }}
        </li>
      </ul>
    </template>
  </SoonaUploadForm>
  <SoonaUploadProgressModal
    v-if="isUploading"
    :active-uploads="activeUploadsLocal"
    :is-multiple="false"
    label="label"
    :hide-label="false"
    @cancel-uploads="handleCancelUploads"
    @start-upload="startUpload"
  />
</template>

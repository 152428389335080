import { http } from '@/config/vue-axios';

/**
 *
 * @param {Object} params
 * @param {number} [params.accountId]
 * @param {number} [params.page]
 * @param {string} [params.search]
 * @param {AbortSignal} [signal]
 * @returns {Promise<any>}
 */
export async function getTransferPackageRequirements(
  { accountId, page, search },
  signal
) {
  let params = {
    ...(!!accountId && { account_id: accountId }),
    ...(!!page && { page: page }),
    ...(!!search && { search: search }),
  };
  const response = await http.get(`transfer_package_requirements.json`, {
    params,
    signal,
  });
  return response.data;
}

/**
 *
 * @param {number} transferId
 * @param {Object} transferPackageRequirement
 * @param {string} transferPackageRequirement.status
 * @param {string} transferPackageRequirement.tracking_number
 * @param {string} transferPackageRequirement.shipping_provider
 * @param {string} transferPackageRequirement.notes
 * @returns {Promise<AxiosResponse<any>>}
 */
export function putTransferPackageRequirementsTransferId(
  transferId,
  { status, tracking_number, shipping_provider, notes }
) {
  return http.put(`transfer_package_requirements/${transferId}.json`, {
    transfer_package_requirement: {
      status,
      tracking_number,
      shipping_provider,
      notes,
    },
  });
}

<script setup>
import { computed } from 'vue';
import GoogleSSO from '@/components/shared/GoogleSSO.vue';
import ShopifySignInButton from '@/components/shared/ShopifySignInButton.vue';

const props = defineProps({
  hideShopify: {
    default: false,
    type: Boolean,
  },
  isGoogleStep: {
    default: false,
    type: Boolean,
  },
});

const isGoogleStep = computed(() => props.isGoogleStep);
</script>

<template>
  <div class="other-actions">
    <GoogleSSO />
    <ShopifySignInButton v-if="!hideShopify" />
    <span v-if="!isGoogleStep" class="u-small--heavy other-actions__divider">
      or
    </span>
    <p v-if="isGoogleStep">
      you’ve already signed up with a Google account. sign in with Google to
      continue.
    </p>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.other-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__divider {
    color: variables.$gray-40;
    display: block;
    padding: 1rem 0;
    position: relative;
    text-align: center;
    width: 100%;

    &::before,
    &::after {
      background-color: variables.$gray-40;
      content: '';
      height: 0.0625rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(50% - 1rem);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  p {
    padding-top: 1rem;
    text-align: center;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { useExampleListings } from '@/queries/useExampleListings';
import { keepPreviousData } from '@tanstack/vue-query';
import ListingCard from '@/components/user/anytime/listing_insights/listing_card/ListingCard.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import QuestHelper from '@/components/quest/QuestHelper.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import GetYourScoreCard from './listing_card/GetYourScoreCard.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const accountId = computed(() => props.accountId);

const {
  data: listingsData,
  error: listingsError,
  isLoading,
  isSuccess,
} = useExampleListings(accountId, {
  placeholderData: keepPreviousData,
});

const listings = computed(() => listingsData.value?.listings ?? []);

const error = usePriorityError(listingsError);
</script>

<template>
  <SoonaError v-if="error">{{ error }}</SoonaError>
  <div v-if="!isLoading && isSuccess" class="example-listings">
    <div class="example-listings__header">
      <h3 class="example-listings__title u-subheader--heavy">
        <SoonaIcon name="lightbulb-alt" />
        explore example listings
      </h3>
      <p class="u-body--regular">
        get a feel for soona’s scores and recommendations by clicking one of the
        examples below.
      </p>
    </div>
    <div class="example-listings__listings-list">
      <QuestHelper
        v-for="(listing, i) in listings"
        :key="listing.listing_id"
        quest-task-slug="listing_insights"
        :hide="i !== 0"
      >
        <ListingCard :account-id="accountId" :listing="listing" />
      </QuestHelper>
      <GetYourScoreCard />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.example-listings {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__listings-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    position: relative;
  }
}
</style>

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param {string | Ref<string>} subjectType
 * @param {string | Ref<number>} subjectId
 */
export function useDeleteSchedulingPreference(reservationId, accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ schedulingPreferenceId }) => {
      const response = await http.delete(
        `scheduling_preferences/${toValue(schedulingPreferenceId)}`
      );
      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.schedulingPreference(
            'Reservation',
            reservationId
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.schedulingPreference('Account', accountId),
        }),
      ]);
    },
  });
}

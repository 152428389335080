import { toValue } from 'vue';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { digitalAssets } from '@/api/digitalAssets';

const getNextPageParam = lastPage => {
  const pagination = lastPage?.pagination;

  if (!pagination) return null;
  if (pagination.currentPage >= pagination.totalPages) return null;

  return pagination.currentPage + 1;
};

const getPreviousPageParam = firstPage => {
  const pagination = firstPage?.pagination;

  if (!pagination) return null;
  if (pagination.currentPage <= 1) return null;

  return pagination.currentPage - 1;
};

/**
 *
 * @param {Object} [params]
 * @param {string | number} [params.activeAssetId]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 * */
export function useInfiniteDigitalAssets(
  accountId,
  {
    activeAssetId,
    filters = null,
    itemsPerPage = 10,
    currentPage,
    searchQuery,
    sortBy,
    sortDirection,
    similarityText,
    similarityThreshold,
  },
  queryOptions
) {
  return useInfiniteQuery({
    queryKey: queryKeys.digitalAssets(accountId, {
      activeAssetId,
      filters,
      itemsPerPage,
      searchQuery,
      sortBy,
      sortDirection,
      similarityText,
      similarityThreshold,
      infinite: true,
    }),
    queryFn: ({ signal, pageParam }) => {
      return digitalAssets(
        toValue(accountId),
        {
          activeAssetId: toValue(activeAssetId),
          filters: toValue(filters),
          itemsPerPage: toValue(itemsPerPage),
          currentPage: pageParam,
          searchQuery: toValue(searchQuery),
          sortBy: toValue(sortBy),
          sortDirection: toValue(sortDirection),
          similarityText: toValue(similarityText),
          similarityThreshold: toValue(similarityThreshold),
        },
        signal
      );
    },
    initialPageParam: currentPage,
    getNextPageParam,
    getPreviousPageParam,
    ...queryOptions,
  });
}

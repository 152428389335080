<script setup>
import ActionBarButton from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarButton.vue';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { storeToRefs } from 'pinia';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import {
  ADJUST_ACTION,
  AI_EXPAND_ACTION,
  AI_PROPS_ACTION,
  AI_SCENES_ACTION,
  PREMIUM_EDITS_ACTION,
  RE_EDITS_ACTION,
  REMOVE_BACKGROUND_ACTION,
  RESIZE_ACTION,
  SHADOWS_ACTION,
} from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import { computed, onMounted, ref, watch } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useFlag } from '@/composables/useFlag';
import { useMokkerRedirect } from '@/queries/mokker/useMokkerRedirect';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useMe } from '@/composables/user/useMe';

const mediaEditorStore = useMediaEditorStore();
const {
  activeAction,
  assetId,
  assetAccountId,
  canAdjustBackgroundOnOwnAccount,
  staffCanAdjustBackgroundOnClientAccount,
} = storeToRefs(mediaEditorStore);

const {
  assetUuid,
  isPhoto,
  rdaId,
  pendingEditorTasks,
  foregroundUrl,
  maskUrl,
  mediaUrl,
  ownedBySoona,
  ownedByCustomer,
  reEditsCDAId,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const {
  isAdjustActive,
  isAIScenesActive,
  isAIPropsActive,
  isAIExpandActive,
  isImageResizerActive,
  isPremiumEditsActive,
  isReEditRequestActive,
  isRemoveBackgroundActive,
  isShadowsActive,
} = useMediaEditor();

const pegasusAIExpandFlag = useFlag('pegasus_ai_expand');
const pegasusPaywallRemoveBackgroundFlag = useFlag(
  'pegasus_paywall_remove_background'
);

const { currentAccountId } = useMe();
const isMyAccount = computed(
  () => currentAccountId.value === assetAccountId.value
);

const route = useRoute();
const { linkClicked } = useBaseEvents();

const handleLinkClicked = actionText => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor menu',
    linkLabel: actionText,
    linkHref: null,
  });
};

const hasSubscriptionDownloadAccess = computed(
  () =>
    canAdjustBackgroundOnOwnAccount?.value ||
    staffCanAdjustBackgroundOnClientAccount?.value
);

const handleButtonClick = (actionText, actionSymbol) => {
  handleLinkClicked(actionText);
  mediaEditorStore.setActiveAction(actionSymbol);
};

const handlePaywallButtonClick = (actionText, actionSymbol) => {
  if (hasSubscriptionDownloadAccess.value) {
    handleButtonClick(actionText, actionSymbol);
  } else {
    mediaEditorStore.setShowPaywallDialog(true);
  }
};

const handleRemoveBackgroundButtonClick = () => {
  if (
    !pegasusPaywallRemoveBackgroundFlag.value ||
    hasSubscriptionDownloadAccess.value
  ) {
    handleButtonClick('remove background', REMOVE_BACKGROUND_ACTION);
  } else {
    mediaEditorStore.setShowPaywallDialog(true);
  }
};

const clickPremiumEdits = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor menu',
    linkLabel: 'premium edits',
    linkHref: null,
  });
  mediaEditorStore.setActiveAction(PREMIUM_EDITS_ACTION);
};

const enableAIStudio = computed(
  () => ownedByCustomer.value && !!assetUuid.value && isMyAccount.value
);

// AI studio iframe

const { mutate: getMokkerRedirect, isPending: aiStudioRedirectLoading } =
  useMokkerRedirect();

const { addToast } = useSoonaToast();

const failureToast = () => {
  mediaEditorStore.setIframeUrl(undefined);
  addToast(`Failed to open AI studio`, {
    variation: 'error',
  });
};

const loadIframeUrl = () => {
  getMokkerRedirect(assetUuid.value, {
    onSuccess: response => {
      if (response.result) {
        const urlSuffix = isAIPropsActive.value ? '&photo_prop=1' : '';
        mediaEditorStore.setIframeUrl(`${response.result}${urlSuffix}`);
      } else {
        failureToast();
      }
    },
    onError: failureToast,
  });
};

const handleAIStudioButtonClick = (actionText, actionSymbol) => {
  handleLinkClicked(actionText);
  if (
    (isAIScenesActive.value && actionSymbol === AI_SCENES_ACTION) ||
    (isAIPropsActive.value && actionSymbol === AI_PROPS_ACTION)
  ) {
    mediaEditorStore.setIframeUrl(undefined);
  }
  mediaEditorStore.setActiveAction(actionSymbol);
};

// load iframe when AI scenes or AI props are active
// have to watch assetUuid because it's not set when the component is mounted
const loadIframeWhenReady = ref(false);
onMounted(() => {
  if (isAIScenesActive.value || isAIPropsActive.value) {
    loadIframeWhenReady.value = true;
  }
});

watch(activeAction, () => {
  if (isAIScenesActive.value || isAIPropsActive.value) {
    loadIframeUrl();
  }
});

watch(assetUuid, () => {
  if (loadIframeWhenReady.value) {
    loadIframeUrl();
    loadIframeWhenReady.value = false;
  }
});
</script>

<template>
  <ActionBarButton
    v-if="isPhoto"
    column="left"
    icon-name="sliders"
    label="adjust"
    :enabled="!!maskUrl && !pendingEditorTasks"
    :loading="pendingEditorTasks"
    :is-selected="isAdjustActive"
    @on-click="() => handleButtonClick('adjust', ADJUST_ACTION)"
  />
  <ActionBarButton
    v-if="isPhoto"
    column="left"
    icon-name="scissors"
    label="remove background"
    :enabled="!!foregroundUrl && !pendingEditorTasks"
    :loading="pendingEditorTasks || !foregroundUrl"
    :is-selected="isRemoveBackgroundActive"
    :is-paywalled="!!pegasusPaywallRemoveBackgroundFlag"
    @on-click="handleRemoveBackgroundButtonClick"
  />
  <ActionBarButton
    v-if="isPhoto"
    column="left"
    icon-name="wand-magic-sparkles"
    label="AI scenes"
    :enabled="!!mediaUrl && !!enableAIStudio"
    :is-selected="isAIScenesActive"
    :loading="aiStudioRedirectLoading && isAIScenesActive"
    @on-click="handleAIStudioButtonClick('AI scenes', AI_SCENES_ACTION)"
  >
    <template #content-bottom>
      <small class="u-badge--small action-bar-left__beta">beta</small>
    </template>
  </ActionBarButton>
  <ActionBarButton
    v-if="isPhoto"
    column="left"
    icon-name="hand-holding"
    label="AI props"
    :enabled="!!mediaUrl && !!enableAIStudio"
    :is-selected="isAIPropsActive"
    :loading="aiStudioRedirectLoading && isAIPropsActive"
    @on-click="handleAIStudioButtonClick('AI props', AI_PROPS_ACTION)"
  >
    <template #content-bottom>
      <small class="u-badge--small action-bar-left__beta">beta</small>
    </template>
  </ActionBarButton>
  <ActionBarButton
    v-if="isPhoto && pegasusAIExpandFlag"
    column="left"
    icon-name="maximize"
    label="AI expand"
    :enabled="!!mediaUrl"
    :is-selected="isAIExpandActive"
    @on-click="handleButtonClick('AI studio', AI_EXPAND_ACTION)"
  >
    <template #content-bottom>
      <small class="u-badge--small action-bar-left__beta">beta</small>
    </template>
  </ActionBarButton>
  <ActionBarButton
    v-if="isPhoto"
    column="left"
    icon-name="shadows"
    label="shadows"
    :enabled="!!maskUrl && !pendingEditorTasks"
    :loading="pendingEditorTasks"
    :is-selected="isShadowsActive"
    is-paywalled
    @on-click="() => handlePaywallButtonClick('shadows', SHADOWS_ACTION)"
  />
  <ActionBarButton
    v-if="isPhoto"
    column="left"
    icon-name="resize"
    label="resize"
    :enabled="!!mediaUrl"
    :is-selected="isImageResizerActive"
    :show-quest-helper="true"
    @on-click="() => handleButtonClick('resize', RESIZE_ACTION)"
  />
  <ActionBarButton
    v-if="reEditsCDAId"
    column="left"
    icon-name="circle-exclamation"
    label="requested re-edit"
    :is-selected="isReEditRequestActive"
    @on-click="handleButtonClick('re-edits', RE_EDITS_ACTION)"
  />
  <ActionBarButton
    v-if="ownedBySoona && rdaId"
    column="left"
    icon-name="pen-swirl"
    label="premium edits"
    :is-selected="isPremiumEditsActive"
    @on-click="clickPremiumEdits"
  />
</template>

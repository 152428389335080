import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { digitalAssets } from '@/api/digitalAssets';

/**
 *
 * @param {Object} [params]
 * @param {string | number} [assetCollectionId]
 * @param {string} [context]
 * @param {string | number} [params.activeAssetId]
 * @param {string | null} [params.album]
 * @param {string | null} [params.filters]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 * */
export function useDigitalAssetsFacets(
  {
    accountId,
    assetCollectionId,
    context,
    activeAssetId,
    album = null,
    filters = {},
    itemsPerPage = 10,
    currentPage = 1,
  },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.digitalAssets(accountId, {
      assetCollectionId,
      context,
      activeAssetId,
      album,
      filters,
      itemsPerPage,
      currentPage,
      facets: 1,
    }),
    queryFn: ({ signal }) => {
      return digitalAssets(
        toValue(accountId),
        {
          assetCollectionId: toValue(assetCollectionId),
          activeAssetId: toValue(activeAssetId),
          context: toValue(context),
          album: toValue(album),
          facets: 1,
          filters: toValue(filters),
          itemsPerPage: toValue(itemsPerPage),
          currentPage: toValue(currentPage),
        },
        signal
      );
    },
    ...queryOptions,
  });
}

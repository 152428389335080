<script setup>
import { computed, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import QuestHelper from '@/components/quest/QuestHelper.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import SoonaTab from '@/components/ui_library/SoonaTab.vue';
import InviteCollaboratorDialog from './InviteCollaboratorDialog.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const route = useRoute();
useTitle(computed(() => `${route.meta.page_title} | soona`));

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

const inviteDialogOpen = ref(false);
</script>

<template>
  <div class="collaborators">
    <SoonaBackButton
      element="router-link"
      :to="{ path: `/account/${accountId}/profile` }"
      button-text="profile"
    />
    <div class="page-content">
      <div class="title-row">
        <h1 class="u-headline--heavy">manage team members</h1>
        <QuestHelper quest-task-slug="add_collaborators">
          <SoonaButton
            data-cypress="button-invite-team-members"
            @on-click="inviteDialogOpen = true"
          >
            invite team members
          </SoonaButton>
        </QuestHelper>
      </div>
      <p>
        your team will be able to create, attend, and select items to purchase
        from your content.
      </p>
      <div class="collaborators-tabs">
        <SoonaTab
          :to="{ path: `/account/${accountId}/collaborators` }"
          :is-selected="route.path === `/account/${accountId}/collaborators`"
        >
          <template #copy>team members</template>
        </SoonaTab>
        <SoonaTab
          :to="{ path: `/account/${accountId}/collaborators/invitations` }"
          :is-selected="
            route.path === `/account/${accountId}/collaborators/invitations`
          "
        >
          <template #copy>invitations</template>
        </SoonaTab>
      </div>
    </div>

    <router-view :account-id="accountId" />

    <InviteCollaboratorDialog
      v-if="inviteDialogOpen"
      :account-id="accountId"
      @close="inviteDialogOpen = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.collaborators {
  .page-content {
    margin: 0.25rem 0 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .title-row {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;
    }

    .collaborators-tabs {
      display: flex;
      border-bottom: 0.0625rem solid variables.$gray-30;
      overflow-x: auto;

      :deep(.soona-tab:not([aria-current='true'])) {
        border-bottom: none;
        display: flex;
        gap: 0.25rem;
      }
    }
  }
}
</style>

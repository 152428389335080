import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { getCreditReasons } from '@/api/credits';

export function useGetCreditReasons() {
  return useQuery({
    queryKey: queryKeys.creditReasons(),
    queryFn: () => getCreditReasons(),
  });
}

import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';

// payments_todo: remove tax breakdown as follow up
export function useCreateSalesTax(
  { accountId, orderTotal, billingAddressId, discountId, productsList },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.accountSalesTax(accountId, {
      orderTotal,
      billingAddressId,
      discountId,
      productsList,
    }),
    queryFn: async ({ signal }) => {
      const response = await http.post(
        `/accounts/${toValue(accountId)}/sales_tax/`,
        {
          sales_taxes: {
            order_total: toValue(orderTotal),
            address_id: toValue(billingAddressId),
            discount_id: toValue(discountId),
            products: toValue(productsList),
          },
        },
        { signal }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

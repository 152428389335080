<script setup>
import { computed } from 'vue';
import SoonaItemCard from 'src/components/ui_library/SoonaItemCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useProductExternalIdentifiers } from '@/components/user/anytime/products/useProductExternalIdentifiers';
import ProductFlags from '@/components/user/anytime/products/product-page/ProductFlags.vue';

const props = defineProps({
  buttonVariation: {
    type: String,
    default: 'primary',
  },
  product: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['select']);

const product = computed(() => props.product);
const id = computed(() => `picker-product-option-${product.value.id}`);
const { sku, asin } = useProductExternalIdentifiers(product);
</script>

<template>
  <SoonaItemCard
    :aria-labelledby="`${id}-heading`"
    :image-url="product.preview_url"
  >
    <template #content>
      <div class="picker-product-option__wrapper">
        <div class="picker-product-option__content">
          <ProductFlags :product="product" />
          <h2
            :id="`${id}-heading`"
            class="picker-product-option__content__heading"
          >
            {{ product.name }}
          </h2>
          <dl>
            <div v-if="sku">
              <dt>SKU</dt>
              <dd>{{ sku }}</dd>
            </div>
            <div v-if="asin">
              <dt>ASIN</dt>
              <dd>{{ asin }}</dd>
            </div>
          </dl>
        </div>
        <div class="picker-product-option__actions">
          <SoonaButton
            class="choose-button"
            :variation="buttonVariation"
            size="medium"
            type="button"
            :disabled="disabled"
            :aria-label="`choose ${product.name}`"
            @on-click="$emit('select', product.id, product)"
          >
            choose
          </SoonaButton>
        </div>
      </div>
    </template>
  </SoonaItemCard>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.picker-product-option {
  &__wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem 0.75rem;
    height: 100%;
  }

  &__content {
    flex: 1 1 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__heading {
      @include variables_fonts.u-body--heavy;
    }

    dl {
      line-height: 1;
    }

    dt {
      @include variables_fonts.u-small--heavy;
      display: inline-block;
      margin-right: 0.25rem;
    }

    dd {
      @include variables_fonts.u-small--regular;
      display: inline-block;
    }
  }

  &__badge {
    margin-top: 0.25rem;
  }

  &__actions {
    .choose-button {
      width: auto;
    }
  }
}
</style>

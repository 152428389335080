<script setup>
import { computed } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useQueryClient } from '@tanstack/vue-query';
import { onMounted, onBeforeUnmount, ref, watch } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { useMe } from '@/composables/user/useMe';
import SoonaLoading from '@/components/SoonaLoading.vue';
import PreviewTemplateDialog from '@/components/user/anytime/gallery/media-editor/media-multiplier/ai/PreviewTemplateDialog.vue';
import PreviewAIGenerationDialog from '@/components/user/anytime/gallery/media-editor/media-multiplier/ai/PreviewAIGenerationDialog.vue';
import ApproveGeneratedAssetDialog from '@/components/user/anytime/listing_insights/listing_actions/views/ApproveGeneratedAssetDialog.vue';
import { useRoute } from 'vue-router';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';

defineProps({
  isContained: {
    default: false,
    type: Boolean,
  },
});

const emits = defineEmits([
  'close-iframe',
  'update-action',
  'iframe-loaded',
  'approve-image',
]);

const route = useRoute();
const isListingActionRoute = computed(
  () => route.name === 'listing-action-media-view'
);

const mokkerIframeDomain = import.meta.env.VITE_MOKKER_IFRAME_DOMAIN || '*';

const VALID_EMITS = [''];

const VALID_MESSAGES = [
  'iframe-loaded',
  'disable-soona-loader',
  'reload-available-generations',
  'open-image-preview',
  'open-template-preview',
  'image-upscale-ready',
  'show-paywall-dialog',
  'close-iframe',
  'update-action',
];

const { currentAccountId } = useMe();
const queryClient = useQueryClient();

const mediaEditorStore = useMediaEditorStore();
const { iframeUrl } = storeToRefs(mediaEditorStore);

const emitMessage = message => {
  emits(message);
};

const showLoader = ref(true);
const previewPayload = ref();
const previewTemplate = ref();
const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const reloadAvailableGenerations = async () => {
  await queryClient.invalidateQueries({
    queryKey: queryKeys.mokkerAvailableCredits(currentAccountId),
  });
};

const aiEditorFrame = ref();

const notifyMediaQueryBreakpoint = breakpoint => {
  switch (breakpoint) {
    case 'wide': {
      aiEditorFrame.value.contentWindow.postMessage(
        {
          action: 'mokker:breakpoint-reached',
          payload: {
            breakpoint: matchMediaIsWide.value ? 'desktop' : 'mobile',
          },
        },
        mokkerIframeDomain
      );
      break;
    }
    default:
      console.warn('invalid breakpoint', breakpoint);
  }
};

const updatePreviewPayload = render => {
  previewPayload.value.renders = previewPayload.value.renders.map(r => {
    if (r.uuid === render.uuid) {
      return render;
    }
    return r;
  });
};

const handleMessage = (message, payload) => {
  switch (message) {
    case 'disable-soona-loader':
      showLoader.value = false;
      notifyMediaQueryBreakpoint('wide');
      emits('iframe-loaded');
      break;
    case 'reload-available-generations':
      reloadAvailableGenerations();
      break;
    case 'open-image-preview':
      if (!payload?.uuid) break;
      previewPayload.value = payload;
      break;
    case 'open-template-preview':
      if (!payload?.id) break;
      previewTemplate.value = payload;
      break;
    case 'image-upscale-ready':
      updatePreviewPayload(payload);
      queryClient.invalidateQueries({
        queryKey: queryKeys.mokkerDigitalAsset(payload.uuid),
      });
      break;
    case 'show-paywall-dialog':
      mediaEditorStore.setShowPaywallDialog(true);
      break;
    case 'close-iframe':
      emits('close-iframe');
      mediaEditorStore.closeAction?.();
      break;
    case 'update-action':
      mediaEditorStore.setActiveAction(payload);
      break;

    default:
      console.warn('received invalid message', message);
  }
};

const validateMessageFormat = event => {
  const defaultReturn = { message: undefined, payload: undefined };
  const action = event.data?.action;
  const payload = event.data?.payload;
  if (!action || typeof action !== 'string') return defaultReturn;
  const [receiver, message] = action.split(':');
  if (receiver !== 'soona' || !message) return defaultReturn;
  return { message, payload };
};

const receiveMessage = event => {
  const { message, payload } = validateMessageFormat(event);
  if (!message) return;
  if (VALID_EMITS.includes(message)) emitMessage(message);
  if (VALID_MESSAGES.includes(message)) handleMessage(message, payload);
};

onMounted(() => {
  window.addEventListener('message', receiveMessage);
});

onBeforeUnmount(() => {
  window.removeEventListener('message', receiveMessage);
});

watch(iframeUrl, () => {
  if (!iframeUrl.value) return;
  showLoader.value = true;
});

watch(matchMediaIsWide, () => {
  notifyMediaQueryBreakpoint('wide');
});
</script>

<template>
  <SoonaLoading
    v-if="showLoader"
    is-loading
    :is-contained="isContained"
    loading-text="preparing the studio"
  />
  <div
    v-show="!showLoader"
    class="ai-editor"
    :class="{ 'ai-editor--contained': isContained }"
  >
    <iframe ref="aiEditorFrame" :src="iframeUrl" class="ai-editor__iframe" />
  </div>
  <ApproveGeneratedAssetDialog
    v-if="isListingActionRoute && !!previewPayload"
    class="ai-editor__preview-dialog"
    :iframe-ref="aiEditorFrame"
    :preview-payload="previewPayload"
    @close-preview="previewPayload = null"
    @approve-image="$emit('approve-image', $event)"
  />
  <PreviewAIGenerationDialog
    v-else-if="!!previewPayload"
    class="ai-editor__preview-dialog"
    :iframe-ref="aiEditorFrame"
    :preview-payload="previewPayload"
    @close-preview="previewPayload = null"
  />
  <PreviewTemplateDialog
    v-if="!!previewTemplate"
    :iframe-ref="aiEditorFrame"
    class="ai-editor__preview-dialog"
    :preview-template="previewTemplate"
    @close-preview="previewTemplate = null"
  />
</template>

<style scoped lang="scss">
.ai-editor {
  width: 100%;
  height: 100%;

  &__iframe {
    width: 100%;
    height: 100%;
    display: block;
  }

  &__preview-dialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 17;
  }

  &--contained {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 16;
  }
}
</style>

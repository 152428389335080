<script setup>
import { ref } from 'vue';
import SoonaComboboxRoot from '@/components/ui_library/soona_combobox/SoonaComboboxRoot.vue';
import SoonaComboboxButton from '@/components/ui_library/soona_combobox/SoonaComboboxButton.vue';
import SoonaComboboxOption from '@/components/ui_library/soona_combobox/SoonaComboboxOption.vue';
import SoonaComboboxListbox from '@/components/ui_library/soona_combobox/SoonaComboboxListbox.vue';
import SoonaComboboxInput from '@/components/ui_library/soona_combobox/SoonaComboboxInput.vue';
import SoonaSearch from '@/components/ui_library/soona_search/SoonaSearch.vue';

const searchText2 = ref('');
const searchText3 = ref('');
const searchText4 = ref('');
const searchText5 = ref('');
const searchText6 = ref('');
const value = ref('');
</script>

<template>
  <div class="soona-combobox-test-page">
    <h2>new search</h2>
    <SoonaSearch v-model:search-text="searchText2" />

    <hr />

    <h2>this is an unstyled one</h2>
    <SoonaComboboxRoot v-model:search-text="searchText3">
      <template #trigger>
        <SoonaComboboxInput />
      </template>

      <template #popover>
        <SoonaComboboxListbox>
          <SoonaComboboxOption>Option without value 1</SoonaComboboxOption>
          <SoonaComboboxOption>Option without value 2</SoonaComboboxOption>
          <SoonaComboboxOption v-for="x in 10" :key="x" :value="x">
            Option {{ x }}
          </SoonaComboboxOption>
        </SoonaComboboxListbox>
      </template>
    </SoonaComboboxRoot>

    <div class="soona-combobox-test-page__flex-space-between">
      <h2>these are to demo floating UI flip and shift functionality</h2>
      <SoonaComboboxRoot v-model:search-text="searchText4">
        <template #trigger>
          <SoonaComboboxInput />
        </template>

        <template #popover>
          <SoonaComboboxListbox>
            <SoonaComboboxOption>Option without value 1</SoonaComboboxOption>
            <SoonaComboboxOption>Option without value 2</SoonaComboboxOption>
            <SoonaComboboxOption v-for="x in 10" :key="x" :value="x">
              Option {{ x }}
            </SoonaComboboxOption>
          </SoonaComboboxListbox>
        </template>
      </SoonaComboboxRoot>

      <SoonaComboboxRoot v-model:search-text="searchText5">
        <template #trigger>
          <SoonaComboboxInput />
        </template>

        <template #popover>
          <SoonaComboboxListbox>
            <SoonaComboboxOption>Option without value 1</SoonaComboboxOption>
            <SoonaComboboxOption>Option without value 2</SoonaComboboxOption>
            <SoonaComboboxOption v-for="x in 10" :key="x" :value="x">
              Option {{ x }}
            </SoonaComboboxOption>
          </SoonaComboboxListbox>
        </template>
      </SoonaComboboxRoot>
    </div>

    <div class="soona-combobox-test-page__flex-space-between">
      <h2>these ones are buttons</h2>
      <SoonaComboboxRoot v-model="value">
        <template #trigger>
          <SoonaComboboxButton>Media Type -- {{ value }}</SoonaComboboxButton>
        </template>

        <template #popover>
          <SoonaComboboxListbox>
            <SoonaComboboxOption>Option without value 1</SoonaComboboxOption>
            <SoonaComboboxOption>Option without value 2</SoonaComboboxOption>
            <SoonaComboboxOption v-for="x in 10" :key="x" :value="x">
              Option {{ x }}
            </SoonaComboboxOption>
          </SoonaComboboxListbox>
        </template>
      </SoonaComboboxRoot>

      <SoonaComboboxRoot v-model="value">
        <template #trigger>
          <SoonaComboboxButton>Media Type -- {{ value }}</SoonaComboboxButton>
        </template>

        <template #popover>
          <SoonaComboboxListbox>
            <SoonaComboboxOption>Option without value 1</SoonaComboboxOption>
            <SoonaComboboxOption>Option without value 2</SoonaComboboxOption>
            <SoonaComboboxOption v-for="x in 10" :key="x" :value="x">
              Option {{ x }}
            </SoonaComboboxOption>
          </SoonaComboboxListbox>
        </template>
      </SoonaComboboxRoot>
    </div>

    <h2>this one is inverted! (desktop only)</h2>
    <SoonaSearch v-model:search-text="searchText6" />
  </div>
</template>

<style lang="scss" scoped>
.soona-combobox-test-page {
  display: flex;
  flex-flow: column;
  gap: 3rem;

  &__flex-space-between {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
</style>

<script setup>
import SoonaSortBySelect from '@/components/ui_library/SoonaSortBySelect.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';

defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  searchPlaceholder: {
    type: String,
    required: false,
    default: 'search',
  },
  filterOptions: {
    type: Array,
    required: true,
  },
  sortByOptions: {
    type: Object,
    required: true,
  },
  straightTop: {
    type: Boolean,
    required: false,
  },
});

const searchText = defineModel('searchText', {
  type: String,
  required: true,
});
const selectedFilter = defineModel('selectedFilter', {
  type: String,
  default: null,
});
const sortBy = defineModel('sortBy', {
  type: Object,
  required: true,
});
</script>

<template>
  <SoonaSkeleton v-if="isLoading" class="soona-search-filter__skeleton" />
  <div
    v-else
    class="soona-search-filter"
    :class="{ 'soona-search-filter--straight-top': straightTop }"
  >
    <div class="soona-search-filter__search">
      <SoonaTextfield
        v-model="searchText"
        type="search"
        :placeholder="searchPlaceholder"
      >
        <template #icon-left>
          <SoonaIcon name="search" size="small" />
        </template>
      </SoonaTextfield>
    </div>
    <div class="soona-search-filter__filter-and-sort-wrapper">
      <div class="soona-search-filter__filters-wrapper">
        <div v-if="$slots['content-left']">
          <slot name="content-left" />
        </div>
        <SoonaFilterSingle
          v-else-if="filterOptions.length > 0"
          v-model="selectedFilter"
          :options="filterOptions"
        >
          <template #option="{ iconName, label }">
            <SoonaIcon v-if="iconName" size="medium" :name="iconName" />
            {{ label }}
          </template>
        </SoonaFilterSingle>
        <div
          v-if="$slots['secondary-filters']"
          class="soona-search-filter__secondary-filters"
        >
          <slot name="secondary-filters" />
        </div>
      </div>
      <div v-if="sortByOptions.length > 0" class="soona-search-filter__sort">
        <SoonaSortBySelect
          :sort-by="sortBy"
          :options="sortByOptions"
          @update-sort-by="sortBy = $event"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

// mobile first styles
.soona-search-filter {
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__skeleton {
    width: 100%;
    border-radius: 0.625rem;
    height: 8.125rem;
  }

  &--straight-top {
    border-radius: 0 0 0.625rem 0.625rem;
  }

  &__header {
    @include variables_fonts.u-headline--heavy;
  }

  &__search {
    :deep(.soona-textfield) {
      padding-bottom: 0;
    }
  }

  &__filter-and-sort-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  &__filters-wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: center;
  }

  &__secondary-filters {
    border-left: 0.125rem solid variables.$gray-30;
    padding-left: 1rem;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: center;
  }

  &__sort {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;

    label {
      @include variables_fonts.u-label--heavy;

      color: variables.$black-default;
    }

    :deep(.vs__dropdown-toggle) {
      background-color: variables.$input-background-color;
      min-width: 10.9375rem;
    }
  }
}
</style>

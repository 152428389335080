import { hashKey, useQuery, useQueryClient } from '@tanstack/vue-query';
import { computed, inject } from 'vue';
import { meQueryConfig } from '@/queries/users/me';

export const SET_IS_ME_RESETTING = Symbol('SET_IS_ME_RESETTING');

export function useMe() {
  const queryClient = useQueryClient();
  const { data } = useQuery(meQueryConfig);
  const setMeLoading = inject(SET_IS_ME_RESETTING, () => {});

  async function invalidateMe() {
    await queryClient.invalidateQueries({ queryKey: meQueryConfig.queryKey });
  }

  /**
   * use for when the current user is updated and we need to update everything
   * @return {Promise<void>}
   */
  async function resetMe() {
    setMeLoading(true);

    /*
     * Invalidate "me" query first so that query has the new and correct data
     * before any dependent queries (that use current account id, for example)
     * are reset. This will result in multiple fetches for some active queries,
     * but we can't really do anything else. TanStack Query will cancel
     * requests and ensure latest data for us.
     */
    try {
      await invalidateMe();

      const meQueryHash = hashKey(meQueryConfig.queryKey);
      await queryClient.resetQueries({
        predicate: query => {
          // explicitly do not reset the "me" query
          if (query.queryHash === meQueryHash) {
            return false;
          }
          // reset any query that does not have an Infinite gcTime
          return query.gcTime !== Infinity;
        },
      });
    } finally {
      setMeLoading(false);
    }
  }

  const currentAccountId = computed(() => {
    return data.value?.account_id;
  });

  const currentUserId = computed(() => {
    return data.value?.id;
  });

  const isSoftSignUp = computed(() => {
    return data.value?.soft_sign_up;
  });

  const isAuthenticated = computed(() => !!data.value);

  const myDepartment = computed(() => data.value?.employee_type_department);

  return {
    currentAccountId,
    currentUserId,
    invalidateMe,
    isAuthenticated,
    isSoftSignUp,
    // pro tip: use a RegExp /\bme\b/ to more easily find uses of this in code!
    me: data,
    myDepartment,
    resetMe,
  };
}

<template>
  <div class="account-requirements">
    <div>
      <div class="account-requirements__heading-row">
        <h3 class="u-subheader--heavy">account requirements</h3>
        <slot name="heading-row" />
      </div>
      <p class="u-label--regular">
        <slot name="description" />
      </p>
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.account-requirements {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__heading-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    flex-wrap: wrap;
  }
}
</style>

import { http } from '../config/vue-axios';
import { mapPaginationHeaders } from '../lib/api-transforms';
import { toValue } from 'vue';

export async function getSharedGallery(
  sharedGalleryHash,
  { currentPage, itemsPerPage },
  signal
) {
  const params = { hash: sharedGalleryHash };

  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;

  const response = await http.get('/shared_gallery', {
    params: params,
    signal,
  });
  return {
    pagination: mapPaginationHeaders(response.headers),
    data: response.data,
  };
}
export async function getSharedGalleryUrl(reservationId) {
  const response = await http.get(
    `/reservations/${toValue(reservationId)}/shared_gallery_link`
  );
  return response.data;
}

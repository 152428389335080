<script setup>
import {
  BubbletapePink30,
  Tangerine40,
  AvoToast30,
  PeriwinkBlue30,
} from 'src/variables.module.scss';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';

const badgeList = [
  { title: 'build a shoot', bgColor: Tangerine40 },
  { title: 'improve listings', bgColor: AvoToast30 },
  { title: 'edit with AI', bgColor: PeriwinkBlue30 },
  { title: 'publish to your sites', bgColor: BubbletapePink30 },
];
</script>
<template>
  <div class="left-column">
    <div class="left-column__inner">
      <p class="u-button--large-caps left-column__tagline">
        make, manage, and measure
      </p>
      <p class="u-display--heavy left-column__heading">
        where over 20k brands create with confidence
      </p>
      <ul class="left-column__badge-container" aria-label="soona capabilities">
        <li v-for="badge in badgeList" :key="badge.bgColor">
          <SoonaFlag
            :background-color="badge.bgColor"
            font-weight="bolder"
            :title="badge.title"
            type="pill"
          />
        </li>
      </ul>
      <!-- extra div wrapper to work around Safari grid quirk -->
      <div>
        <div class="left-column__img-wrapper">
          <div class="left-column__free-badge">
            <p class="u-badge--large">start for free</p>
          </div>
          <img
            class="left-column__img left-column__img--a"
            src="@images/sign-in/grid_1@2x.jpg"
            alt=""
          />
          <img
            class="left-column__img left-column__img--b"
            src="@images/sign-in/grid_2@2x.jpg"
            alt=""
          />
          <img
            class="left-column__img left-column__img--c"
            src="@images/sign-in/grid_3@2x.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.left-column {
  align-items: center;
  background-color: variables.$green-apple-20;
  container: left-column / inline-size;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 3.75rem;

  &__inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 39rem;
  }

  &__tagline {
    margin-bottom: 0.75rem;
    text-align: center;
    text-wrap: balance;
    text-wrap: pretty;
  }

  &__heading {
    padding-bottom: 2rem;
    text-align: center;
    text-wrap: balance;
    text-wrap: pretty;
  }

  &__badge-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    justify-content: center;
    padding-bottom: 2.5rem;
  }

  &__img-wrapper {
    display: grid;
    gap: 1.5rem;
    grid-template-areas:
      'a b'
      'a c';
    position: relative;
  }

  &__img {
    border-radius: 0.625rem;
    box-shadow: variables.$elevation-1;
    height: 100%;
    width: 100%;
    object-fit: cover;

    &--a {
      grid-area: a;
    }

    &--b {
      grid-area: b;
    }

    &--c {
      grid-area: c;
    }
  }

  &__free-badge {
    align-items: center;
    background-color: variables.$periwink-blue-50;
    border-radius: 50%;
    color: variables.$white-default;
    display: flex;
    height: 5rem;
    justify-content: center;
    left: -0.5rem;
    padding: 0.5rem;
    position: absolute;
    text-align: center;
    top: -0.5rem;
    transform: rotate(-16.248deg);
    width: 5rem;
  }

  @container left-column (min-width: 35rem) {
    &__heading {
      font-size: 2.5rem;
    }

    &__subheading {
      margin-bottom: 2.25rem;
    }
  }
}
</style>

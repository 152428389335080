import { http } from '@/config/vue-axios';

/**
 *
 * @param {Object} params
 * @param {number} params.inventoryPackageId
 * @param {number} params.reservationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteInventoryPackagesReservations({
  inventoryPackageId,
  reservationId,
}) {
  return http.delete('inventory_packages_reservations.json', {
    params: {
      inventory_package_id: inventoryPackageId,
      reservation_id: reservationId,
    },
  });
}

/**
 *
 * @param {Object} body
 * @param {number} body.packageId
 * @param {number} body.reservationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function postInventoryPackagesReservations({
  packageId,
  reservationId,
}) {
  const body = {
    inventory_package_id: packageId,
    reservation_id: reservationId,
  };

  return http.post(`inventory_packages_reservations`, body);
}

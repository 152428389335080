import { toValue } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { deleteDigitalAsset } from '@/api/digitalAssets';

/**
 *
 * @param {Object} [params]
 * @param {string | Ref<string>} [accountId]
 * @param {string | Ref<string>} [assetId]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */

export function useDeleteDigitalAsset({ accountId, assetId }) {
  return useMutation({
    mutationFn: () => deleteDigitalAsset(toValue(accountId), toValue(assetId)),
  });
}

<script setup>
import { computed } from 'vue';
import required_tags from '@app/assets/lists/required-tags/required-tags.json';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useReservationTags } from '@/queries/reservation-tags/useReservationTags';
import { useReservation } from '@/composables/useReservation';
import { useProductsSearch } from '@/queries/useProductsSearch';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { toCurrency } from '@/lib/currency';

const props = defineProps({
  reservationId: String,
  tagRequirement: {},
  cancelClicked: Function,
  confirmClicked: Function,
  secondaryConfirmClicked: Function,
  segmentContext: String,
  segmentSubContext: String,
  addingProduct: {
    type: Boolean,
    default: true,
  },
  videoSlug: {
    type: String,
    required: false,
  },
});

const emit = defineEmits([
  'cancelClicked',
  'confirmClicked',
  'secondaryConfirmClicked',
]);

const reservationId = computed(() => props.reservationId);

const { reservation, proServiceSlugForSize, VIDEO_SIZE_LOOKUP } =
  useReservation(reservationId);

const { data: products, error: productsError } = useProductsSearch({
  locationId: computed(() => reservation.value?.location.id),
  orderId: computed(() => reservation.value?.down_payment_order?.id),
});

const product = computed(() => {
  return productId => products.value?.find(p => p.id === productId);
});

const lookupSize = computed(() => {
  if (props.videoSlug) {
    return props.videoSlug === 'talking head'
      ? 'audio'
      : VIDEO_SIZE_LOOKUP[props.videoSlug];
  }
  return proServiceSlugForSize;
});

const { data: upgradeData } = useReservationTags(
  reservationId,
  lookupSize.value
);

const priorityError = usePriorityError(productsError);

const upgradeOptions = computed(() => {
  return upgradeData.value?.options || [];
});

const requirementCopy = computed(() => {
  return required_tags.find(rt => rt.name === props.tagRequirement.name);
});

const addingBodyCopy = computed(() => {
  if (requirementCopy.value?.type === 'video') {
    return requirementCopy.value.body;
  }
  return (
    requirementCopy.value?.body + ' price is based on the length of your shoot.'
  );
});

const removingBodyCopy = computed(() => {
  return requirementCopy.value?.removeBody;
});

const bodyCopy = computed(() => {
  if (props.addingProduct) {
    return addingBodyCopy.value;
  } else {
    return removingBodyCopy.value;
  }
});

const tagRequired = computed(() => {
  return props.tagRequirement.requirement_type === 'required';
});

const confirmCopy = computed(() => {
  if (props.addingProduct) {
    return tagRequired.value ? 'add to shoot' : 'add with model';
  }
  return 'remove';
});

const requiredOption = computed(() => {
  return upgradeOptions.value.find(option => {
    return option.tag.id === props.tagRequirement.required_tag_id;
  });
});

const requiredProduct = computed(() => {
  return product.value(requiredOption.value?.product.id);
});

// segment
const { linkClicked, itemSelected } = useBaseEvents();

function handleCancel() {
  if (props.segmentContext) {
    linkClicked({
      context: props.segmentContext,
      subContext: props.segmentSubContext,
      linkLabel: 'cancel',
    });
  }
  emit('cancelClicked');
}

function handleConfirm() {
  if (props.segmentContext) {
    if (props.addingProduct) {
      itemSelected({
        context: props.segmentContext,
        subContext: props.segmentSubContext,
        itemId: requiredOption.value.product.id,
        itemLabel: requiredOption.value.tag.title,
        itemPrice: requiredOption.value.tag.price_description,
        itemQuantity: 1,
      });
    }
    itemSelected({
      context: props.segmentContext,
      subContext: props.segmentSubContext,
      itemLabel: confirmCopy.value,
    });
  }
  emit('confirmClicked');
}

function handleSecondaryConfirm() {
  if (props.segmentContext) {
    itemSelected({
      context: props.segmentContext,
      subContext: props.segmentSubContext,
      itemLabel: 'add without model',
    });
  }
  emit('secondaryConfirmClicked');
}

const productRate = computed(() => {
  return parseInt(requiredProduct.value?.price_info?.rate, 10);
});

const productDiscountedRate = computed(() => {
  return (
    parseInt(requiredProduct.value?.price_info?.discounted_rate, 10) || null
  );
});
</script>

<template>
  <SoonaDialog data-cypress="tag-requirement-dialog" @close="handleCancel">
    <template #heading>
      <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
      <div>
        <strong class="header">
          {{ requirementCopy?.title }}
        </strong>
      </div>
    </template>
    <div>
      <div v-html="bodyCopy"></div>
      <template v-if="addingProduct"
        ><hr />
        <div class="required-tag-pricing__item">
          <img
            v-if="requiredProduct"
            :src="requiredProduct.thumbnail_url"
            class="required-tag-pricing__item-img"
            alt=""
          />
          <div
            v-if="requiredProduct"
            class="required-tag-pricing__item-content"
          >
            <div class="required-tag-pricing__item-title-price">
              <strong class="required-tag-pricing__item-title">
                {{ requiredProduct.name }}
              </strong>
              <em class="required-tag-pricing__item-price">
                <div
                  :class="{
                    'required-tag-pricing__item-price__old':
                      productDiscountedRate,
                  }"
                >
                  {{ toCurrency(productRate) }}
                </div>
                <div
                  v-if="productDiscountedRate"
                  class="required-tag-pricing__item-price__new"
                >
                  {{ toCurrency(productDiscountedRate) }}
                </div>
              </em>
            </div>
          </div>
        </div>
      </template>
    </div>
    <template #footer>
      <SoonaButton
        variation="tertiary"
        size="medium"
        data-cypress="tag-requirement-cancel"
        @on-click="handleCancel"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        v-if="!tagRequired && addingProduct"
        variation="tertiary"
        size="medium"
        data-cypress="tag-requirement-secondary-confirm"
        @on-click="handleSecondaryConfirm"
      >
        add without model
      </SoonaButton>
      <SoonaButton
        size="medium"
        data-cypress="tag-requirement-confirm"
        @on-click="handleConfirm"
      >
        {{ confirmCopy }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.header {
  display: flex;
  align-items: center;
}
.required-tag-pricing {
  margin: 1rem 0;

  &__item {
    display: flex;
    align-items: flex-start;
  }

  &__item-img {
    display: block;
    flex: 0 0 3.875rem;
    max-width: 3.875rem;
    border-radius: 0.3125rem;
    margin-right: 0.75rem;
  }

  &__item-content {
    flex-grow: 1;
    padding: 0;
  }

  &__item-title-price {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }

  &__item-title {
    display: flex;
    text-transform: lowercase;
  }

  &__item-price {
    display: flex;
    align-items: center;
    font-style: normal;
    margin-left: 0.5rem;
    text-align: right;
    gap: 0.25rem;

    &__old {
      text-decoration: line-through;
    }

    &__new {
      @include variables_fonts.u-body--heavy;

      color: variables.$friendly-red-70;
    }
  }
}
</style>

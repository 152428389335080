import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default function useSelectListing(competitor, integrationId, accountId) {
  const router = useRouter();
  const pickerIsOpen = ref(false);

  function select(listing) {
    pickerIsOpen.value = false;
    const query = {
      listing: listing.listing_id,
    };
    if (!listing.is_example_external_product) {
      query.integration = integrationId.value;
    }
    if (competitor.value.example_staff_pick) {
      query.example_competitor = competitor.value.id;
    } else {
      query.competitor = competitor.value.id;
    }
    router.push({
      name: 'competitor-compare',
      params: {
        accountId: accountId.value,
      },
      query,
    });
  }

  return { select, pickerIsOpen };
}

<template>
  <li role="none">
    <slot></slot>
  </li>
</template>

<style lang="scss" scoped>
@use '@/variables';

li[role='none'] {
  width: 100%;
}

:slotted([role='menuitem'], [role='separator']) {
  display: block;
  width: 100%;
  background: variables.$white-default;
  border-radius: 0.3125rem;

  &:disabled {
    color: variables.$gray-50;
    cursor: not-allowed;

    &:hover,
    &:active {
      background-color: variables.$gray-10;
      border-color: variables.$gray-10;
      color: variables.$gray-50;
    }
  }
}

:slotted([role='separator']) {
  margin: 0.5rem 0;
  border-bottom: 0.0625rem solid variables.$gray-30;
}

:slotted([role='menuitem']) {
  padding: 0.5rem 0.75rem;
  color: variables.$black-default;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  transition: background-color 0.1s ease-out;
  text-transform: lowercase;
  min-width: 12.5rem;
  letter-spacing: 0.015625rem;
  text-decoration: none;
  border-radius: 0;
  border: none;

  &:hover,
  &:focus-visible {
    background-color: variables.$friendly-red-10;
  }
}
</style>

<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import * as Sentry from '@sentry/vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';

const store = useStore();
const account = computed(() => store.state.account);
const countriesList = computed(() => store.getters['account/countriesList']);

const isEditing = ref(false);
const street1 = ref('');
const street2 = ref('');
const city = ref('');
const state = ref('');
const zip = ref('');
const country = ref('');
const email = ref('');
const phone = ref('');

const disabled = computed(() => {
  return zip.value === undefined && country.value === undefined;
});

function prefillExistingData() {
  phone.value = account.value.phone;
  email.value = account.value.email;
  street1.value = account.value.address?.street1;
  street2.value = account.value.address?.street2;
  city.value = account.value.address?.city;
  state.value = account.value.address?.state;
  zip.value = account.value.address?.zip;
  country.value = account.value.address?.country;
}

function toggleEdit() {
  return (isEditing.value = !isEditing.value);
}

async function save() {
  const info = {
    address: {
      street1: street1.value,
      street2: street2.value,
      city: city.value,
      state: state.value,
      zip: zip.value,
      country: country.value,
    },
    address_card: {
      complete: true,
    },
    phone: phone.value,
    email: email.value,
  };
  try {
    await store.dispatch('account/updateAccount', { accountParams: info });
    return (isEditing.value = !isEditing.value);
  } catch (error) {
    if (error.response.status !== 422) {
      Sentry.captureException(new Error('Updating account address failed'), {
        extra: { error },
      });
    }
  }
}

watchEffect(() => {
  if (account.value) {
    prefillExistingData();
  }
});
</script>
<template>
  <div class="contact-info">
    <h3 class="u-title--heavy mb-l">contact info</h3>
    <div class="field-wrapper">
      <SoonaTextfield
        v-model="street1"
        class="contact-info__input"
        :disabled="!isEditing"
        label="street address"
        name="street1"
        :placeholder="isEditing ? 'street address' : ''"
        type="text"
      />
      <SoonaTextfield
        v-model="street2"
        class="contact-info__input"
        :disabled="!isEditing"
        label="suite or apt. #"
        name="street2"
        :placeholder="isEditing ? 'suite or apt #' : ''"
        type="text"
      />
    </div>
    <div class="field-wrapper">
      <SoonaTextfield
        v-model="city"
        class="contact-info__input"
        :disabled="!isEditing"
        label="city"
        name="city"
        :placeholder="isEditing ? 'city' : ''"
        type="text"
      />
    </div>
    <div class="field-wrapper">
      <SoonaTextfield
        v-model="state"
        class="contact-info__input"
        :disabled="!isEditing"
        label="state"
        name="state"
        :placeholder="isEditing ? 'state' : ''"
        type="text"
      />
      <SoonaTextfield
        v-model="zip"
        class="contact-info__input"
        :disabled="!isEditing"
        label="zip code"
        name="zip_code"
        :placeholder="isEditing ? '55555' : ''"
        title="five-digit zip code"
        type="text"
      >
        <template #tooltip>
          <SoonaHelperText>
            enter 00000 if you don’t have a zip code
          </SoonaHelperText>
        </template>
      </SoonaTextfield>
    </div>
    <div class="field-wrapper">
      <div class="input-wrapper country">
        <label class="u-label--heavy contact-label" for="country">
          country <span>*</span>
        </label>
        <v-select
          v-if="isEditing"
          v-model:model-value="country"
          class="profile-country-select"
          :get-option-label="opt => `${opt.emoji} ${opt.name}`"
          placeholder="search for your country"
          :options="countriesList"
          :reduce="country => country.code"
        ></v-select>
        <p v-else class="u-label--small field-read-mode">
          {{ country }}
        </p>
      </div>
    </div>
    <Capability
      capability-name="manage_account"
      subject-type="account"
      :subject-id="account.id"
    >
      <div class="action-btns">
        <SoonaButton v-if="!isEditing" size="medium" @on-click="toggleEdit">
          edit
        </SoonaButton>
        <SoonaButton
          v-else
          class="save-btn"
          :disabled="disabled"
          size="medium"
          @on-click="save"
        >
          save
        </SoonaButton>
      </div>
    </Capability>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.contact-info {
  background-color: variables.$white-default;
  border-radius: 0.75rem;
  box-shadow: variables.$elevation-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;

  .field-wrapper {
    align-items: flex-end;
    display: flex;
    gap: 1rem;
  }

  .input-wrapper {
    margin-top: 1em;
    margin-bottom: 1em;
    position: relative;
  }

  .contact-label {
    align-items: center;
    display: flex;
    gap: 0.25rem;
    left: 0.25rem;
    position: absolute;
    top: -1.125rem;

    span {
      color: variables.$friendly-red-50;
    }
  }

  .field-read-mode {
    background: variables.$gray-10;
    border: none;
    border-radius: 0.375rem;
    height: 1.875rem;
    overflow: hidden;
    padding: 0.5em 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__input {
    width: 100%;
  }

  .country {
    width: 100%;
  }

  .action-btns {
    margin: auto;
    width: max-content;
  }

  .save-btn {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }
}
</style>

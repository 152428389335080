<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const emit = defineEmits(['sendQuickReaction']);

const thumbsUp = '👍';

function submitReaction() {
  emit('sendQuickReaction', thumbsUp);
}
</script>

<template>
  <SoonaButton
    variation="icon-plain-gray"
    title="send quick thumbs up"
    @on-click="submitReaction"
  >
    <span class="QuickThumbsUp__emoji">{{ thumbsUp }} </span>
  </SoonaButton>
</template>

<style lang="scss" scoped>
.QuickThumbsUp__emoji {
  font-size: 1.5rem;
  line-height: 1;
}
</style>

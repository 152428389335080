<script setup>
import { ref, computed, onMounted } from 'vue';

defineProps({
  posterImage: {
    required: true,
    type: Object,
  },
  videoSrc: {
    required: true,
    type: [String, null],
  },
  videoAlt: {
    required: true,
    type: [String, null],
  },
});

const emit = defineEmits(['video-played']);

const video = ref(null);

const isTouchDevice = computed(() => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
});

function showVideoControls(event) {
  event.target.setAttribute('controls', 'true');
}

function hideVideoControls(event) {
  if (!isTouchDevice.value) {
    event.target.removeAttribute('controls');
  }
}

function resetVideo(event) {
  event.target.currentTime = 2.75;
}

const pauseVideo = () => {
  video.value.pause();
};

function videoPlayed() {
  emit('video-played');
}

onMounted(() => {
  if (isTouchDevice.value) {
    video.value.setAttribute('controls', 'true');
  }
});

defineExpose({
  pauseVideo,
});
</script>
<template>
  <div class="soona-tag-option-card-main-video">
    <figure class="soona-tag-option-card-main-video__video-wrapper">
      <div class="soona-tag-option-card-main-video__video">
        <video
          ref="video"
          controlslist="nodownload"
          :src="videoSrc"
          :poster="posterImage.src"
          @mouseover="showVideoControls"
          @mouseleave="hideVideoControls"
          @play="videoPlayed"
          @ended="resetVideo"
        >
          sorry, your browser doesn't support embedded videos
        </video>
      </div>
      <figcaption class="u-visually-hidden">{{ videoAlt }}</figcaption>
    </figure>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-tag-option-card-main-video {
  padding-bottom: 1rem;

  @media (min-width: variables.$screen-sm-min) {
    padding: 1rem;
    padding-right: 0.75rem;
    width: 50%;
  }

  &__video-wrapper {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1.3fr 0.7fr;
    grid-template-rows: 1.1fr 1.1fr;
    height: 100%;
    max-height: 11rem;

    @media (min-width: variables.$screen-sm-min) {
      max-height: none;
    }
  }

  &__video {
    display: block;
    grid-area: 1 / 1 / 3 / 3;

    > video {
      border-radius: 0.625rem;
      object-fit: cover;
      height: 100%;
      width: 100%;
      display: block;
    }

    @media (max-width: variables.$screen-xs-max) {
      &:not(:first-child) {
        display: none;
      }
    }
  }

  :deep(.video-pips-wrapper) {
    height: 350px;
    width: 100%;

    video {
      border-radius: 0.625rem;
    }
  }
}
</style>

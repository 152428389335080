<script setup>
import { computed } from 'vue';
import TheKing from 'images/empty-states/theking@2x.jpg';
import maskBlobEmpty from 'images/empty-states/mask-blob-empty.svg';
import maskBlobSearchFilterEmpty from 'images/empty-states/mask-blob-search-filter-empty.svg';
import maskCircle from 'images/empty-states/mask-circle.svg';
import { Gray10 } from '@/variables.module.scss';

const props = defineProps({
  backgroundColor: {
    default: Gray10,
    type: String,
  },
  showImage: {
    type: Boolean,
    default: true,
  },
  imageSource: {
    type: String,
    default: TheKing,
  },
  mask: {
    type: String,
    required: false,
    default: 'blob-search-filter-empty',
    validator: value =>
      ['blob-empty', 'blob-search-filter-empty', 'circle', 'none'].includes(
        value
      ),
  },
});

const masks = {
  'blob-empty': maskBlobEmpty,
  'blob-search-filter-empty': maskBlobSearchFilterEmpty,
  circle: maskCircle,
  none: '',
};

const hasMask = computed(() => props.mask !== 'none');
const maskSvg = computed(() => `url("${masks[props.mask]}")`);
</script>

<template>
  <div class="no-results__wrapper">
    <slot name="image">
      <img
        v-if="showImage"
        class="no-results__image"
        :class="{ 'no-results__image--mask': hasMask }"
        :src="imageSource"
        alt=""
      />
    </slot>
    <h2
      v-if="$slots.header"
      class="no-results__header"
      data-cypress="soona-no-results-heading"
    >
      <slot name="header"></slot>
    </h2>
    <p v-if="$slots.body" class="no-results__text">
      <slot name="body"></slot>
    </p>
    <div v-if="$slots.button" class="no-results__button">
      <slot name="button"></slot>
    </div>
    <div v-if="$slots.footer" class="no-results__additional-content">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.no-results {
  &__wrapper {
    width: 100%;
    background-color: v-bind('backgroundColor');
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    padding: 3rem 1.5rem;
  }

  &__header {
    @include variables_fonts.u-headline--heavy;

    color: variables.$black-default;
  }

  &__image {
    display: block;
    width: 100%;
    max-width: 15.625rem;

    &--mask {
      mask: v-bind('maskSvg') center / 12.5rem no-repeat;
      mask-size: contain;
    }
  }

  &__text {
    max-width: 25rem;
    color: variables.$black-default;
  }

  &__button {
    margin-top: 1.5rem;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: center;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import { useRefreshIntegration } from '@/queries/useRefreshIntegration';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  integration: {
    type: Object,
    required: true,
  },
});

const integration = computed(() => props.integration);

const accountId = computed(() => props.accountId);

const lastUpdated = computed(() => {
  return `updated ${integration.value.refreshed_at_in_words} ago`;
});

const refreshDisabled = computed(() => integration.value.is_importing);

const href = computed(
  () => `${integration.value.shopify_store_url}/settings/apps?tab=installed`
);

const { mutate } = useRefreshIntegration(accountId);

function importProducts() {
  mutate({
    integration_type: 'shopify',
    id: integration.value.id,
  });
}
</script>
<template>
  <div class="shopify-integration">
    <div class="shopify-integration__store">
      <SoonaIcon name="shopify-logo" size="2x-large" />
      <div class="shopify-integration__store__details">
        <p class="shopify-integration__store__details__name">
          {{ integration.shop_name }}
        </p>
        <p class="shopify-integration__store__details__last-updated">
          {{ lastUpdated }}
        </p>
      </div>
    </div>
    <SoonaDropdownMenu
      class="shopify-integration__dropdown"
      variation="icon-plain-gray"
    >
      <template #trigger-content>
        <SoonaIcon name="dots-horizontal" size="large" />
      </template>
      <template #default="{ keydown, mouseover, clickCapture }">
        <SoonaDropdownMenuItem>
          <button
            role="menuitem"
            class="no-text-transform"
            :disabled="refreshDisabled"
            @keydown="keydown"
            @mouseover="mouseover"
            @click.capture="clickCapture"
            @click="() => importProducts()"
          >
            <SoonaIcon
              class="integrations-info__button-icon"
              size="medium"
              name="refresh-cw-alt"
            />&nbsp; refresh
          </button>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem>
          <a
            role="menuitem"
            aria-label="manage shopify store"
            class="no-text-transform"
            target="_blank"
            :href="href"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            <SoonaIcon
              class="integrations-info__button-icon"
              size="medium"
              name="arrow-up-right-from-square"
            />&nbsp; manage
          </a>
        </SoonaDropdownMenuItem>
      </template>
    </SoonaDropdownMenu>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.shopify-integration {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &__store {
    display: flex;
    align-items: center;
    gap: 1rem;

    &__details {
      &__name {
        @include variables_fonts.u-body--heavy;
        color: variables.$gray-80;
      }

      &__last-updated {
        @include variables_fonts.u-label--small;
        color: variables.$gray-50;
      }
    }
  }
}
</style>

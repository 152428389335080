export function toCurrency(
  value,
  currency = 'USD',
  minimumFractionDigits = 2,
  trailingZeroDisplay = 'auto'
) {
  if (typeof value === 'string') {
    value = parseFloat(value);
  }
  return value?.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: minimumFractionDigits,
    trailingZeroDisplay: trailingZeroDisplay,
  });
}

<template>
  <div class="account-profile-card" :class="className">
    <span v-if="banner" class="profile-card-banner">{{ banner }}</span>
    <h3 class="profile-card-title">{{ title }}</h3>
    <div class="profile-card-content">
      <p v-if="icon" class="card-icon">{{ icon }}</p>
      <slot />
    </div>
    <div v-if="actionPath && actionText" class="profile-card-action">
      <router-link
        :to="{
          path: actionPath,
        }"
        class="button is-text text-s action-button"
      >
        {{ actionText }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileCard',
  props: {
    title: String,
    banner: String,
    icon: String,
    actionPath: String,
    actionText: String,
    className: String,
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.account-profile-card {
  align-items: center;
  background-color: variables.$white-default;
  border-radius: 0.75rem;
  box-shadow: variables.$elevation-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;

  .profile-card-title {
    width: 100%;
    font-size: 1.25rem;
    font-weight: 800;
    text-align: left;
    margin-bottom: 16px;
  }

  .profile-card-banner {
    width: 100%;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
    letter-spacing: 2.4px;
    height: 30px;
    padding-top: 7px;
    background: #d5e4ff40;
  }

  .profile-card-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .card-icon {
    background: #f2f7ff;
    border-radius: 50%;
    font-size: 48px;
    margin-right: 1.5rem;
    line-height: 48px;
    padding: 20px;
  }

  .profile-card-action {
    width: 100%;
    text-align: right;

    .action-button {
      color: variables.$periwink-blue-60;
    }
  }
}
</style>

import { track } from '@/lib/segment/segment';
import { useCurrentReservationAndUser } from '@/composables/segment/useCurrentReservationAndUser';

export function useShippingEvents() {
  const { reservationTraits, userTraits } = useCurrentReservationAndUser();

  /**
   * Shipping Started
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   */
  const shippingStarted = ({ context, subContext } = {}) => {
    const payload = {
      context,
      subContext,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    track('Shipping Started', payload);
  };

  return {
    shippingStarted,
  };
}

import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';

export function useGetNoteTypes() {
  return useQuery({
    queryKey: queryKeys.noteTypes(),
    queryFn: async ({ signal }) => {
      const response = await http.get('/notes/note_types', { signal });
      return response.data;
    },
  });
}

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useSetColorCard(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ shotId, params }) => {
      {
        const response = await http.post(
          `/reservations/${toValue(reservationId)}/shots/${toValue(
            shotId
          )}/set_color_card`,
          { digital_asset_id: params.digital_asset_id }
        );

        return response.data;
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservationShots(reservationId),
      });
    },
  });
}

<script setup>
import ForgotPasswordButton from 'src/components/shared/ForgotPasswordButton.vue';
import UseDifferentAccountButton from 'src/components/shared/UseDifferentAccountButton.vue';

const emits = defineEmits(['handle-use-different-account']);
</script>

<template>
  <div class="password-step-actions">
    <ForgotPasswordButton />
    <UseDifferentAccountButton
      @on-click="() => emits('handle-use-different-account')"
    />
  </div>
</template>

<style lang="scss" scoped>
.password-step-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  padding-bottom: 2rem;
}
</style>

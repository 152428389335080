<script setup>
import { ref } from 'vue';
import BookingTypeModal from 'src/components/user/anytime/crew/BookingTypeModal.vue';
import CreateShootExceptionModal from './CreateShootExceptionModal.vue';
import ContinuationDetailsModal from 'src/components/user/anytime/crew/ContinuationDetailsModal.vue';
import ShootComplexityDialog from './ShootComplexityDialog.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useRouter } from 'vue-router';
import { useFlag } from '@/composables/useFlag';
import { useCapability } from 'src/composables/useCapability';

const props = defineProps({
  reservationId: {
    type: Number,
    required: true,
  },
});

const router = useRouter();

const showContinuationDetailModal = ref(false);
const showShootExceptionModal = ref(false);
const showBookingTypeModal = ref(false);
const showShootComplexityDialog = ref(false);

const bookingTypeUpdateByStaffFlag = useFlag('booking_type_update_by_staff');
const shootComplexityFlag = useFlag('toaster_shoot_complexity');

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

function openContinuationModal() {
  showContinuationDetailModal.value = true;
}

function closeContinuationModal() {
  showContinuationDetailModal.value = false;
}

function openShootExceptionModal() {
  showShootExceptionModal.value = true;
}

function closeShootExceptionModal() {
  showShootExceptionModal.value = false;
}

function openBookingTypeModal() {
  showBookingTypeModal.value = true;
}

function closeBookingTypeModal() {
  showBookingTypeModal.value = false;
}

function goToOrdersPage() {
  router.push({
    path: `/reservation/${props.reservationId}/additional-order`,
    replace: true,
  });
}
</script>
<template>
  <div class="booking-crew-actions">
    <div class="booking-crew-actions__actions">
      <SoonaButton
        variation="secondary-gray"
        size="medium"
        :on-click="openShootExceptionModal"
      >
        add shoot exception
      </SoonaButton>
      <SoonaButton
        data-cypress="create-continuation-shoot-button"
        variation="secondary-gray"
        size="medium"
        :on-click="openContinuationModal"
      >
        create continuation
      </SoonaButton>
      <SoonaButton
        variation="secondary-gray"
        size="medium"
        :on-click="goToOrdersPage"
      >
        create new order
      </SoonaButton>
      <SoonaButton
        v-if="isFtSoonaStaff && bookingTypeUpdateByStaffFlag"
        variation="secondary-gray"
        size="medium"
        :on-click="openBookingTypeModal"
      >
        change booking type
      </SoonaButton>
      <SoonaButton
        v-if="shootComplexityFlag"
        variation="secondary-gray"
        size="medium"
        @click="showShootComplexityDialog = true"
      >
        shoot complexity
      </SoonaButton>
    </div>
    <ContinuationDetailsModal
      v-if="showContinuationDetailModal"
      :close-modal="closeContinuationModal"
    />
    <CreateShootExceptionModal
      v-if="showShootExceptionModal"
      :reservation-id="reservationId"
      @close-modal="closeShootExceptionModal"
    />
    <BookingTypeModal
      v-if="showBookingTypeModal"
      :close-modal="closeBookingTypeModal"
    />
    <ShootComplexityDialog
      v-if="showShootComplexityDialog"
      :reservation-id="reservationId"
      @close="showShootComplexityDialog = false"
    />
  </div>
</template>
<style lang="scss">
@use '@/variables';

.booking-crew-actions {
  padding: 1rem;
  border-radius: 0.3125rem;
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
</style>

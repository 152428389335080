<script setup>
import { computed, provide, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useTitle } from '@vueuse/core';
import SoonaTab from 'src/components/ui_library/SoonaTab.vue';
import TitleBar from '@/components/shared/TitleBar.vue';
import { WhiteDefault } from '@/variables.module.scss';
import listingActionContent from './listingActionContent';
import ActionViewTemplateHeader from './ActionViewTemplateHeader.vue';
import MissingContentView from './missing_content/MissingContentView.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  actionSlug: {
    type: String,
    required: true,
  },
});

const route = useRoute();
const router = useRouter();

const pageContent = computed(() => {
  return listingActionContent.find(x => x.slug === props.actionSlug);
});

provide('pageContent', pageContent);

useTitle(`${pageContent.value.pageTitle} | soona`);

const { pageViewed } = useBaseEvents();
onMounted(() => {
  pageViewed();
});

const currentRouteName = computed(() => route.name);

const isToDoTab = computed(() => {
  return currentRouteName.value === 'listing-insights-actions';
});

const isCompleteTab = computed(() => {
  return currentRouteName.value === 'complete-actions';
});

const isMissingActionsPage = computed(() => {
  return pageContent.value.pageTitle === 'missing content';
});

const handlePreviousPage = () => {
  const mediaEditorPattern =
    /^\/account\/\d+\/listing-insights\/action\/\d+\/asset\/\d+$/;
  // fixme: update logic to not use this API
  // eslint-disable-next-line no-restricted-properties
  if (mediaEditorPattern.test(router.options.history.state.back)) {
    router.push({ name: 'listing-insights-dashboard' });
  } else {
    router.go(-1);
  }
};
</script>

<template>
  <div class="action-template">
    <TitleBar
      :title="pageContent.pageTitle"
      :background-color="WhiteDefault"
      :border-color="WhiteDefault"
      :previous-page="handlePreviousPage"
      override-u-container
      heading-id="test"
      padding="0"
      class="action-template__title-bar"
    />
    <MissingContentView
      v-if="isMissingActionsPage"
      :account-id="accountId"
      :page-content="pageContent"
    />
    <template v-else>
      <ActionViewTemplateHeader :page-content="pageContent" />
      <div class="action-template__tabs">
        <SoonaTab
          copy="to do"
          :to="{
            name: 'listing-insights-actions',
            params: {
              accountId: accountId,
              actionSlug: actionSlug,
            },
            replace: true,
          }"
          :is-selected="isToDoTab"
          remove-bottom-border
        />
        <SoonaTab
          copy="completed"
          :to="{
            name: 'complete-actions',
            params: {
              accountId: accountId,
              actionSlug: actionSlug,
            },
            replace: true,
          }"
          :is-selected="isCompleteTab"
          remove-bottom-border
        />
      </div>
    </template>

    <router-view v-if="!isMissingActionsPage" v-slot="{ Component }">
      <component
        :is="Component"
        :account-id="accountId"
        :action-slug="actionSlug"
      />
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.action-template {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__tabs {
    border-bottom: 0.0625rem solid variables.$gray-30;
  }
}
</style>

<script setup>
import { computed, provide, ref } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { usePriorityError } from '@/composables/usePriorityError';
import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_BACK_TEXT,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import MediaEditor from '@/components/user/anytime/gallery/media-editor/MediaEditor.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import BookingReEditsCarousel from '@/components/user/anytime/reservation/editor/BookingReEditsCarousel.vue';
import { useReservation } from '@/composables/useReservation';
import { useReEditsCollection } from '@/queries/re_edits_collections/useReEditsCollection';
import { useReEditsCollectionDigitalAssetByDigitalAssetId } from '@/queries/re_edits_collection_digital_assets/useReEditsCollectionDigitalAssetByDigitalAssetId';

const props = defineProps({
  isChatOpen: {
    type: Boolean,
  },
  userHasScrolledPastBreakpoint: {
    type: Boolean,
  },
  assetContext: {
    type: String,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [String, Number],
    required: true,
  },
});

const router = useRouter();

const reservationId = computed(() => props.reservationId);
const digitalAssetId = computed(() => props.digitalAssetId);

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const { activeReEditsCollectionId, nameTruncated, reservationAccountId } =
  useReservation(reservationId);
const { data: reEditsCollection } = useReEditsCollection(
  reservationAccountId,
  activeReEditsCollectionId,
  {
    enabled: computed(
      () => !!reservationAccountId.value && !!activeReEditsCollectionId.value
    ),
  }
);
const reEditsCollectionId = computed(() => reEditsCollection.value?.id);
const filters = computed(() => ({
  reservation_id: reservationId.value,
  origin: 'soona',
  ownership: 'customer',
  visibility: 'all',
}));

const {
  data: reEditsCollectionDigitalAsset,
  isLoading: isFileLoading,
  error,
} = useReEditsCollectionDigitalAssetByDigitalAssetId(
  reEditsCollectionId,
  digitalAssetId,
  { filters },
  {
    enabled: computed(
      () => !!reEditsCollectionId.value && !!digitalAssetId.value
    ),
  }
);

const priorityError = usePriorityError(error);

// routes
const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'reservation-asset-re-edit-view',
    params: {
      digitalAssetId: id,
      reservationId: reservationId.value,
    },
  };
};

const backUrl = computed(() => {
  return `/reservation/${reservationId.value}`;
});

const previousRoute = computed(() => {
  return createRouteLocation(
    reEditsCollectionDigitalAsset.value?.previous?.digital_asset_id
  );
});

const nextRoute = computed(() => {
  return createRouteLocation(
    reEditsCollectionDigitalAsset.value?.next?.digital_asset_id
  );
});

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, previousRoute);
provide(MEDIA_POPOVER_NEXT_KEY, nextRoute);
provide(
  MEDIA_POPOVER_BACK_TEXT,
  computed(() => `in ${nameTruncated.value}`)
);

// redirect back to gallery
const isRedirecting = ref(false);
const goToGallery = () => {
  isRedirecting.value = true;
  router.push(backUrl.value);
};
</script>
<template>
  <MediaEditor
    v-if="!isRedirecting"
    :asset="reEditsCollectionDigitalAsset?.digital_asset"
    :is-asset-loading="isFileLoading"
    @close="goToGallery"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #carousel>
      <BookingReEditsCarousel
        v-if="matchMediaIsWide && reEditsCollection?.id"
        :re-edits-collection-id="reEditsCollection?.id"
        :reservation-id="reservationId"
        :active-asset-id="reEditsCollectionDigitalAsset?.digital_asset?.id"
        title="reservation re-edits"
      />
    </template>
  </MediaEditor>
</template>

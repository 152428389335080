<script setup>
import NotificationModalItem from '@/components/NotificationModalItem.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { FriendlyRed50, FriendlyRed20 } from 'src/variables.module.scss';

defineProps({
  additionalChargeUnpaid: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(['close']);

function closeModal() {
  emit('close');
}
</script>

<template>
  <SoonaDialog
    icon-name="bell"
    :icon-color="FriendlyRed50"
    :icon-bg="FriendlyRed20"
    @close="closeModal"
  >
    <template #heading>
      notifications ({{ additionalChargeUnpaid.length }})
    </template>

    <NotificationModalItem
      v-for="charge in additionalChargeUnpaid"
      :key="charge.id"
      :order="charge"
      @close-modal="closeModal"
    />
  </SoonaDialog>
</template>

import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { confirmProServiceRequirement } from 'src/api/proServiceRequirements';
import { queryKeys } from 'src/queries/query-keys';
import { unref } from 'vue';

export function useConfirmProServiceRequirement(
  proServiceRequirementId,
  reservationId
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      confirmProServiceRequirement(
        unref(proServiceRequirementId),
        unref(reservationId)
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservationReadinessSteps(reservationId),
      });
    },
  });
}

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent } from '@tiptap/vue-3';
import Underline from '@tiptap/extension-underline';
import CharacterCount from '@tiptap/extension-character-count';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import { PreventNewLines } from './custom_plugins/PreventNewLines';
import { LinkDialogHandler } from './custom_plugins/LinkDialogHandler';
import SoonaEditorToolbar from './SoonaEditorToolbar.vue';

const props = defineProps({
  disableNewLines: {
    type: Boolean,
    default: false,
  },
  maxCharacters: {
    type: Number,
    required: false,
  },
  minHeight: {
    type: String,
    default: '200px',
  },
  placeholder: {
    type: String,
    required: false,
  },
  toolbarActions: {
    type: Object,
    default: () => ({
      bold: true,
      italic: true,
      underline: true,
      strike: true,
    }),
  },
});

const model = defineModel();

const editor = ref(null);

watch(model, value => {
  const isSame =
    JSON.stringify(editor.value.getJSON()) === JSON.stringify(value);

  if (isSame) {
    return;
  }

  editor.value.commands.setContent(value, false);
});

onMounted(() => {
  const extensions = [
    StarterKit,
    Underline,
    Placeholder.configure({
      placeholder: props.placeholder || 'Write something…',
    }),
  ];

  if (props.maxCharacters) {
    extensions.push(
      CharacterCount.configure({
        limit: props.maxCharacters,
      })
    );
  }

  if (props.disableNewLines) {
    extensions.push(PreventNewLines);
  }

  if (props.toolbarActions.link) {
    extensions.push(
      Link.extend({ inclusive: false }).configure({
        openOnClick: false,
        protocols: ['http', 'https', 'mailto', 'tel', 'sms'],
        defaultProtocol: 'https',
        autolink: true,
        linkOnPaste: true,
      }),
      LinkDialogHandler
    );
  }

  editor.value = new Editor({
    extensions: extensions,
    content: model.value,
    onUpdate: () => {
      model.value = editor.value.getJSON();
    },
  });
});

// destroy the editor and all its event listeners
onBeforeUnmount(() => {
  editor.value.destroy();
});
</script>

<template>
  <div class="soona-editor">
    <div class="soona-editor__header">
      <slot name="label" />
      <div
        v-if="editor && props.maxCharacters"
        class="soona-editor__header-character-count u-label--regular"
      >
        {{ editor.storage.characterCount.characters() }}/{{
          props.maxCharacters
        }}
        characters
      </div>
    </div>

    <EditorContent :editor="editor" />

    <SoonaEditorToolbar
      v-if="editor"
      :editor="editor"
      :toolbar-actions="toolbarActions"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-editor {
  :deep(.tiptap) {
    min-height: v-bind('props.minHeight');
    border: 0.0625rem solid variables.$gray-40;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 1rem;
  }

  :deep(.tiptap p.is-editor-empty:first-child::before) {
    content: attr(data-placeholder);
    float: left;
    color: variables.$gray-60;
    pointer-events: none;
    height: 0;
  }

  :deep(.tiptap ul) {
    list-style: initial;
    margin-left: 1rem;
  }

  :deep(.tiptap ol) {
    margin-left: 1.25rem;
  }

  :deep(.tiptap h1) {
    @include variables_fonts.u-headline--heavy;
  }

  :deep(.tiptap h2) {
    @include variables_fonts.u-title--heavy;
  }

  :deep(.tiptap h3) {
    @include variables_fonts.u-subheader--heavy;
  }

  :deep(.tiptap h4),
  :deep(.tiptap h5),
  :deep(.tiptap h6) {
    @include variables_fonts.u-body--heavy;
  }

  :deep(.tiptap blockquote) {
    padding: 0 1em;
    color: variables.$gray-90;
    border-left: 0.25em solid variables.$gray-30;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }
}
</style>

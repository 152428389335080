<template>
  <div class="ShootDetails">
    <div v-if="shootComplexityFlag" class="shoot-item">
      <p class="shoot-header">shoot complexity:</p>
      <p class="u-body--regular">
        <i>
          {{ shootComplexityLookup[currentReservation.shoot_complexity] }}
        </i>
      </p>
    </div>
    <div class="shoot-item">
      <p class="shoot-header">bay assignment:</p>
      <p class="u-body--regular">
        <i> {{ currentReservation.bookable_spaces_name }} </i>
      </p>
    </div>
    <div class="shoot-item">
      <p class="shoot-header">title:</p>
      <p class="u-body--regular">
        <span v-if="isPhoto">📷 </span>
        <span v-if="isVideo">🎥 </span>
        <i class="shoot-title">{{ reservationName }}</i>
      </p>
    </div>
    <div class="shoot-item">
      <p class="shoot-header">reservation type:</p>
      <p class="u-body--regular">
        <i> {{ currentReservation.reservation_type.replace(/_/g, ' ') }} </i>
      </p>
    </div>
    <div class="shoot-item">
      <p class="shoot-header">booking type:</p>
      <p>
        <i> {{ bookingTypeTags }}</i>
      </p>
    </div>
    <div class="shoot-item">
      <p class="shoot-header">
        shot list:<span v-if="currentReservation.shot_count != 1">
          {{ currentReservation.shot_count }} shots
        </span>
        <span v-else> {{ currentReservation.shot_count }} shot</span>
      </p>
      <p v-if="hasCustomProp">
        <i>has custom props</i>
      </p>
    </div>
    <div class="shoot-item">
      <p class="shoot-header">pro services:</p>
      <p>
        <i>{{ proServicesList }}</i>
      </p>
    </div>
    <div v-if="currentReservation.continuations?.length > 0" class="shoot-item">
      <p class="shoot-header">continuation shoots:</p>
      <ul>
        <li
          v-for="continuation in currentReservation.continuations"
          :key="continuation.id"
          class="shoot-link"
        >
          <router-link
            class="view-shoot-btn"
            :title="continuation.name"
            :to="{ path: `/reservation/${continuation.id}/info` }"
            >{{ continuation.name }}</router-link
          >
        </li>
      </ul>
    </div>
    <div v-if="currentReservation.previous" class="shoot-item">
      <p class="shoot-header">parent shoot:</p>
      <p class="shoot-link">
        <router-link
          class="view-shoot-btn"
          :title="currentReservation.previous.name"
          :to="{ path: `/reservation/${currentReservation.previous.id}/info` }"
          >{{ currentReservation.previous.name }}</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import { useFlag } from '@/composables/useFlag';
import unescape from 'lodash/unescape';

export default {
  name: 'ShootDetails',
  setup() {
    const { hasCapability: canViewProServiceSizes } = useCapability({
      capability: 'view_pro_service_sizes',
    });

    const shootComplexityLookup = {
      low: 'green (low)',
      basic: 'yellow (basic)',
      medium: 'orange (medium)',
      advanced: 'burgundy (advanced)',
      high: 'purple (high)',
      complex: 'blue (complex)',
    };

    const shootComplexityFlag = useFlag('toaster_shoot_complexity');

    return {
      canViewProServiceSizes,
      shootComplexityFlag,
      shootComplexityLookup,
    };
  },
  computed: {
    ...mapGetters('reservation', [
      'isPhoto',
      'isVideo',
      'purchasedProServicesNames',
    ]),
    ...mapState({
      currentReservation: state => state.reservation.currentReservation,
    }),
    hasCustomProp() {
      return this.currentReservation.shot_count > 0
        ? this.currentReservation.shot_custom_prop.find(
            shot => shot.has_custom_prop === true
          )
        : false;
    },
    bookingTypeTags() {
      return (
        this.currentReservation?.tags
          ?.map(tag => tag.title)
          .join(', ')
          .toLowerCase() || ''
      );
    },
    proServicesList() {
      let filteredPurchasedPSnames = this.purchasedProServicesNames?.filter(
        item => {
          return item.display !== false;
        }
      );
      return (
        filteredPurchasedPSnames
          ?.map(
            proservice =>
              `${proservice.quantity > 1 ? `${proservice.quantity} x ` : ''}${
                proservice.name
              }${
                this.canViewProServiceSizes ? '(' + proservice.level + ')' : ''
              }`
          )
          ?.join(', ')
          ?.toLowerCase() || ''
      );
    },
    reservationName() {
      return unescape(this.currentReservation.name);
    },
  },
};
</script>
<style lang="scss">
@use '@/variables';
.ShootDetails {
  .shoot-item {
    margin: 0.5em;
  }
  .shoot-header {
    color: variables.$gray-50;
    letter-spacing: 1px;
  }
  .shoot-title {
    white-space: pre-line;
  }
  .shoot-link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .view-shoot-btn {
    text-decoration: underline;
    color: variables.$periwink-blue-60;
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import { useReservation } from 'src/composables/useReservation';
import { useConfirmComped } from 'src/queries/reservations/useConfirmComped';
import { useConfirmSuggested } from 'src/queries/reservations/useConfirmSuggested';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { useRouter } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';

const props = defineProps({
  reservationId: {
    type: String,
    required: true,
  },
});

const router = useRouter();

const reservationId = computed(() => props.reservationId);

const showCompleteModal = ref(false);

const {
  reservation,
  isConcierge,
  isCompedDraft,
  isContinuation,
  isSuggested,
  isSurprise,
  isLoading,
  error,
} = useReservation(reservationId);

const reservationType = computed(() => {
  if (isConcierge.value) {
    return 'concierge';
  }

  if (isSurprise.value) {
    return 'surprise';
  }

  if (isCompedDraft.value && isContinuation.value) {
    return 'down payment continuation';
  }
  if (isCompedDraft.value) {
    return 'down payment';
  }

  return isContinuation.value ? 'continuation' : '';
});

const {
  mutate: confirmComped,
  isPending: isComping,
  error: compingError,
} = useConfirmComped(reservationId);

const {
  mutate: confirmSuggested,
  isPending: isConfirmingSuggested,
  error: confirmingSuggestedError,
} = useConfirmSuggested(reservationId);

const isBusy = computed(
  () => isLoading.value || isComping.value || isConfirmingSuggested.value
);

const { validateIncompleteSteps, hasIncompleteSteps, missingStepsError } =
  useIncompleteSteps(reservation);

function complete() {
  validateIncompleteSteps.value = true;

  if (hasIncompleteSteps.value) {
    return;
  }

  showCompleteModal.value = true;
}

const confirmComplete = () => {
  if (isSuggested.value) {
    confirmSuggested(
      {},
      {
        onSuccess: () =>
          router.push(`/account/${reservation.value.account_id}`),
      }
    );
  } else {
    confirmComped(
      {},
      {
        onSuccess: data =>
          router.push(
            isSurprise.value
              ? `/reservation/${data.id}/info`
              : `/account/${reservation.value.account_id}`
          ),
      }
    );
  }
};

const priorityError = usePriorityError(
  error,
  compingError,
  confirmingSuggestedError,
  missingStepsError
);
</script>

<template>
  <SoonaButton class="full-width-btn" :disabled="isBusy" @click="complete">
    create booking
  </SoonaButton>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <SoonaDialog
    v-if="showCompleteModal"
    @close="() => (showCompleteModal = false)"
  >
    <template #heading>confirmation</template>
    <p v-if="isSuggested">
      this suggested {{ reservationType }} booking will appear in the customer’s
      account in ‘my bookings’ under suggested bookings. an email will be sent
      to the customer to complete the booking.
    </p>
    <p v-if="isCompedDraft">
      <strong>
        this is a comped {{ reservationType }} booking. no credits will be added
        to this reservation.
      </strong>
      this will show up in the ‘upcoming’ bookings on the customer’s account. if
      you selected a pro service in this order, it will also be comped. if you
      want to charge for the pro service instead, delete it above and add as an
      additional order on the crew page.
    </p>
    <p v-if="isSurprise">
      this {{ reservationType }} booking will not appear in the customer’s
      account. no email will be sent to the customer.
    </p>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        data-cypress="button-dialog-confirm"
        @on-click="confirmComplete"
      >
        confirm
      </SoonaButton>
    </template>
  </SoonaDialog>
  <SoonaLoading
    :is-loading="isBusy"
    :is-dark="false"
    :loading-text="'please wait'"
  />
</template>

<style lang="scss" scoped>
.full-width-btn {
  width: 100%;
  margin-bottom: 1rem;
}
</style>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { createInvitation } from '@/api/invitations';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

export function useCreateInvitation(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: invitation => createInvitation(unref(accountId), invitation),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collaborators(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.invitations(accountId),
        }),
      ]);
    },
  });
}

<script setup>
import uniqueId from 'lodash/uniqueId';
import { inject, computed, toRefs } from 'vue';

import { RADIO_BUTTON_GROUP_KEY } from './radio-button-group-key';

const props = defineProps({
  value: {
    type: [String, Number],
    required: true,
  },
  label: {
    type: [String, Number],
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const rootContext = inject(RADIO_BUTTON_GROUP_KEY);
const { disabled, value, label } = toRefs(props);

const id = uniqueId('soona-radio-button-item-');
const name = computed(() => rootContext.name.value);
const checked = computed(() => rootContext.modelValue?.value === value.value);
const isDisabled = computed(() => rootContext.disabled.value || disabled.value);

function handleChange() {
  // Fallback for browsers that don't support view transition API:
  if (!document.startViewTransition || !rootContext.animate.value) {
    rootContext.changeModelValue(value.value);
    return;
  }

  // With a transition:
  document.startViewTransition(() => rootContext.changeModelValue(value.value));
}
</script>

<template>
  <div class="radio-button">
    <input
      :id="id"
      type="radio"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="isDisabled"
      class="radio-button__input"
      @change="handleChange"
    />

    <label class="radio-button__label u-label--regular" :for="id">
      <span
        :data-text="label"
        :style="{ 'view-transition-name': `content-${value}` }"
        class="radio-button__content"
      >
        <strong v-if="checked">{{ label }}</strong>
        <span v-else>{{ label }}</span>
        <slot />
      </span>

      <span v-if="checked" class="radio-button__selected-background" />
    </label>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.radio-button {
  &__label {
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 1.375rem;
    color: variables.$gray-70;

    input:checked + & {
      color: variables.$black-default;
    }
    input:focus-visible + & {
      .radio-button__selected-background {
        outline: solid 0.125rem variables.$periwink-blue-70;
      }
    }
  }

  &__label:hover {
    background: variables.$gray-10;
  }

  &__input {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    white-space: nowrap;
    clip-path: inset(50%);
  }

  &__selected-background {
    inset: 0;
    z-index: 0;
    position: absolute;
    border-radius: 1.375rem;
    background: variables.$white-default;
    view-transition-name: background-indicator;
  }

  &__content {
    z-index: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding: 0.25rem 0.75rem;
  }

  &__content::after {
    // Prevents shifting of size when the font weight changes
    // by using a hidden pseudo element already bolded
    font-weight: 800;

    height: 0;
    overflow: hidden;
    user-select: none;
    visibility: hidden;
    pointer-events: none;
    content: attr(data-text);
    content: attr(data-text) / '';

    @media speech {
      display: none;
    }
  }
}
</style>

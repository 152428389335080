import { http } from '../config/vue-axios';
import { mapPaginationHeaders } from '../lib/api-transforms';

/**
 *
 * @param {Object} params
 * @param {number} [params.accountId]
 * @param {number | null} [params.pickable]
 * @param {number} [params.skipReservationId]
 * @param {number} [params.reservationId]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {string} [params.filteredStatus]
 * @param {AbortSignal} [signal]
 * @returns {Promise<any>}
 */
export async function getInventoryPackages(
  {
    accountId,
    pickable = null,
    skipReservationId,
    reservationId,
    hasEligibleReturns,
    itemsPerPage,
    currentPage,
    filteredStatus,
  },
  signal
) {
  const params = {};

  if (accountId) params['account_id'] = accountId;
  if (reservationId) params['reservation_id'] = reservationId;
  if (skipReservationId) params['skip_reservation_id'] = skipReservationId;
  if (pickable !== null) params.pickable = pickable;
  if (itemsPerPage) params.items = itemsPerPage;
  if (currentPage) params.page = currentPage;
  if (filteredStatus) params.filtered_status = filteredStatus;
  if (hasEligibleReturns) params.has_eligible_returns = true;

  const response = await http.get('inventory_packages', { params, signal });

  return {
    pagination: mapPaginationHeaders(response.headers),
    inventoryPackages: response.data,
  };
}

/**
 *
 * @param {Object} packageAttributes
 * @returns {Promise<AxiosResponse<any>>}
 */
export function postInventoryPackages(packageAttributes) {
  return http.post(`inventory_packages.json`, {
    inventory_package: packageAttributes,
  });
}

/**
 *
 * @param {number} packageId
 * @param {AbortSignal} [signal]
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInventoryPackagesPackageId(packageId, signal) {
  return http.get(`inventory_packages/${packageId}.json`, { signal });
}

/**
 *
 * @param {number} inventoryPackageId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteInventoryPackagesPackageId(inventoryPackageId) {
  return http.delete(`inventory_packages/${inventoryPackageId}.json`);
}

/**
 *
 * @param {number} packageId
 * @param {Object} params
 * @param {Object} params.packageAttributes
 * @param {Object} params.reservationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function putInventoryPackagesPackageId(
  packageId,
  { packageAttributes }
) {
  return http.put(`inventory_packages/${packageId}.json`, {
    inventory_package: packageAttributes,
  });
}

/**
 *
 * @param {Object} params
 * @param {string | null} [params.query]
 * @param {number} [params.page]
 * @param {number | null} [params.locationId]
 * @param {string} [params.orderBy]
 * @param {string} [params.direction]
 * @param {Array} [params.shippingStatus]
 * @param {Array} [param.packageStatus]
 * @param {Array} [param.requiredAction]
 * @param {AbortSignal} [signal]
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getInventoryPackagesSummary(
  {
    query = null,
    page = 1,
    itemsPerPage = 30,
    locationId = null,
    orderBy = 'id',
    direction = 'desc',
    returnLabel = null,
    shippingStatus = null,
    packageStatus = null,
    requiredAction = null,
  },
  signal
) {
  let params = {
    query: query,
    page: page,
    items: itemsPerPage,
    location_id: locationId,
    order_by: orderBy,
    direction: direction,
  };
  if (returnLabel) {
    params['return_label'] = returnLabel;
  }
  if (shippingStatus && shippingStatus.length > 0) {
    params['shipping_status'] = shippingStatus;
  }
  if (packageStatus && packageStatus.length > 0) {
    params['package_status'] = packageStatus;
  }
  if (requiredAction && requiredAction.length > 0) {
    params['required_action'] = requiredAction;
  }

  const response = await http.get(`inventory_packages/summary.json`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    inventoryPackages: response.data,
  };
}

export async function checkForDuplicatePackages(
  { accountId, trackingNumber },
  signal
) {
  let params = {
    account_id: accountId,
    query: trackingNumber,
  };
  const response = await http.get(`inventory_packages/check_for_duplicates`, {
    params,
    signal,
  });
  return response.data;
}

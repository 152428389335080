<script setup>
import { computed } from 'vue';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import { useSubscription } from '@/composables/subscription/useSubscription';
import SoonaSubscriptionBadge from '@/components/ui_library/SoonaSubscriptionBadge.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const accountId = computed(() => props.accountId);
const { subscriptionTier, subscriptionItems } = useSubscription(accountId);

const hasBaseTier = computed(() =>
  subscriptionItems.value?.some(item => item.subscription_item_type === 'base')
);

const subscriptionAddOns = computed(() => {
  if (!subscriptionItems.value) return [];

  return subscriptionItems.value.filter(
    item => item.subscription_item_type === 'add_on'
  );
});

const onFreeTier = computed(() => {
  return !hasBaseTier.value && !subscriptionAddOns.value.length;
});

const formatAddOnName = addOn => {
  return addOn.replace('-month', '').replace('-year', '');
};

const iconLookup = {
  'fast-pass': 'box-archive',
};
</script>

<template>
  <div class="subscription-card drop-shadow-container">
    <h3 class="u-title--heavy">your plan</h3>
    <p v-if="onFreeTier" class="u-badge--large subscription-card__tier">
      <SoonaSubscriptionBadge icon-size="large" :tier-title="'free'" />
      <span class="subscription-card__tier--name">free</span>
    </p>
    <div v-else class="subscription-card__enrolled">
      <p
        v-if="hasBaseTier"
        class="u-badge--large subscription-card__tier"
        :data-tier-slug="subscriptionTier?.slug"
      >
        <SoonaSubscriptionBadge
          :icon-name="subscriptionTier?.icon_name"
          icon-size="large"
          :tier-slug="subscriptionTier?.slug"
          :tier-title="subscriptionTier?.short_name"
        />
        <span class="subscription-card__tier--name">{{
          subscriptionTier?.short_name
        }}</span>
      </p>
      <p
        v-for="addOn in subscriptionAddOns"
        :key="addOn.id"
        class="u-badge--large subscription-card__tier"
        :data-tier-slug="subscriptionTier?.slug"
      >
        <SoonaSubscriptionBadge
          :icon-name="iconLookup[formatAddOnName(addOn.nickname)]"
          icon-size="large"
          :tier-slug="subscriptionTier?.slug"
          :tier-title="formatAddOnName(addOn.nickname)"
        />
        <span class="subscription-card__tier--name">{{
          formatAddOnName(addOn.nickname).replace('-', ' ')
        }}</span>
      </p>
    </div>

    <span class="subscription-card__link">
      <SoonaLink :to="`/account/${accountId}/subscriptions`" size="small">
        manage
      </SoonaLink>
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.subscription-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__enrolled {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__tier {
    display: flex;
    align-items: center;
    border-radius: 1.25rem;
    background-color: variables.$bubbletape-pink-10;

    &[data-tier-slug='tier-one'] {
      background-color: variables.$periwink-blue-20;
    }

    &[data-tier-slug='tier-two'] {
      background-color: variables.$bubbletape-pink-10;
    }

    &[data-tier-slug='tier-three'] {
      background-color: variables.$tangerine-30;
    }

    &[data-tier-slug='fast-pass'] {
      background: variables.$green-apple-10;
    }

    &--name {
      margin-left: 0.5rem;
    }
  }

  &__link {
    align-self: flex-end;
  }
}
</style>

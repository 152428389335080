<script setup>
import { ref, computed, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

import { useSaveDigitalAssetForeground } from '@/queries/digital_assets/useSaveDigitalAssetForeground';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';

import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import { REMOVE_BACKGROUND_ACTION } from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

defineProps({
  isMobileView: {
    type: Boolean,
    default: false,
  },
});

const { addToast } = useSoonaToast();
const { linkClicked } = useBaseEvents();

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId } = storeToRefs(mediaEditorStore);

const { aspectRatio, previewUrl, foregroundUrl, isPhoto, assetLoading } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const showingForeground = ref(false);
const panelLoading = ref(false);

const route = useRoute();

const showForeground = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor remove background menu',
    linkLabel: 'remove background',
    linkHref: null,
  });
  showingForeground.value = true;
  mediaEditorStore.setShowForeground(showingForeground.value);
};

const resetImage = () => {
  showingForeground.value = false;
  mediaEditorStore.setShowForeground(showingForeground.value);
};

const {
  mutate: saveForegroundAsDigitalAsset,
  isPending: digitalAssetLoading,
  error: digitalAssetError,
} = useSaveDigitalAssetForeground(assetAccountId, assetId);

const handleSave = () => {
  saveForegroundAsDigitalAsset(
    {},
    {
      onSuccess: (e = null) => {
        addToast(`we’re generating your image...`, {
          variation: 'success',
        });
        mediaEditorStore.closeAction(e);
      },
      onError: () => {
        addToast(`error saving edit`, {
          variation: 'error',
        });
      },
    }
  );
};

mediaEditorStore.registerComponent(REMOVE_BACKGROUND_ACTION, {
  handleSave,
  handleReset: resetImage,
  warnBeforeClose: () => {},
});

const isLoading = computed(
  () => digitalAssetLoading.value || panelLoading.value
);

const previewImgLoaded = ref(false);
const foregroundImgLoaded = ref(false);

watch(
  [assetId, foregroundUrl],
  fileVal => {
    if (!assetId.value || assetLoading.value) return;
    if (!isPhoto.value) {
      mediaEditorStore.forceSetActiveAction(null);
    } else {
      panelLoading.value = true;
      if (fileVal && foregroundUrl.value) {
        previewImgLoaded.value = false;
        foregroundImgLoaded.value = false;
        resetImage();
        panelLoading.value = false;
      }
    }
  },
  {
    immediate: true,
  }
);

onUnmounted(() => {
  resetImage();
});
</script>

<template>
  <SoonaError v-if="digitalAssetError">
    {{ digitalAssetError }}
  </SoonaError>
  <SoonaLoading
    v-if="isLoading"
    :is-contained="panelLoading"
    is-loading
    :loading-text="panelLoading ? 'loading…' : 'saving…'"
  />
  <div
    class="remove-background"
    :class="{ 'remove-background--mobile': isMobileView }"
  >
    <SoonaButton
      :disabled="showingForeground"
      variation="secondary-gray"
      class="remove-background__remove-bg"
      @on-click="showForeground"
    >
      <SoonaIcon name="scissors" size="medium" />
      remove background
    </SoonaButton>
    <SoonaButton
      :disabled="!showingForeground"
      variation="secondary-gray"
      class="remove-background__reset-button"
      @on-click="resetImage"
    >
      reset adjustments
    </SoonaButton>
    <div
      v-if="isPhoto && !!previewUrl && !!foregroundUrl && !isMobileView"
      class="remove-background__preview"
      :class="{
        'remove-background__preview--loaded':
          previewImgLoaded && foregroundImgLoaded,
      }"
    >
      <SoonaSkeleton
        v-if="!previewImgLoaded || !foregroundImgLoaded"
        class="remove-background__preview-loading"
      />
      <img :src="foregroundUrl" alt="" @load="foregroundImgLoaded = true" />
      <div class="remove-background__preview-img">
        <img :src="previewUrl" alt="" @load="previewImgLoaded = true" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.remove-background {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  overflow: hidden;

  &--mobile {
    gap: 1.25rem;
    margin-top: 0;
    justify-content: flex-end;
  }

  &__preview {
    position: relative;
    order: -1;
    min-height: 5.75rem;
    --aspectRatio: v-bind('aspectRatio');
    aspect-ratio: var(aspectRatio, auto);

    img {
      display: block;
      visibility: hidden;
    }

    &-loading {
      height: 100%;
    }

    &--loaded img {
      visibility: visible;
    }
  }

  &__preview-img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    clip-path: polygon(66.666% 0, 0 0, 0 100%, 33.333% 100%);
    will-change: clip-path;
    transition: width 0.5s cubic-bezier(0.22, 1, 0.36, 1) 0.08s;

    img {
      max-width: none;
      height: 100%;
    }

    @media (prefers-reduced-motion: no-preference) {
      .remove-background__preview--loaded & {
        animation: 0.8s cubic-bezier(0.22, 1, 0.36, 1) 0.2s both mask-over;
      }
    }
  }

  &__reset-button {
    margin-top: auto;

    .remove-background--mobile & {
      margin-top: 0;
    }
  }

  @media (prefers-reduced-motion: no-preference) and (hover: hover) {
    &__remove-bg:not(:disabled):hover,
    &__remove-bg:not(:disabled):focus-visible {
      + * + .remove-background__preview .remove-background__preview-img {
        width: 66.66%;
      }
    }

    &__reset-button:not(:disabled):hover,
    &__reset-button:not(:disabled):focus-visible {
      + .remove-background__preview .remove-background__preview-img {
        width: 133.333%;
      }
    }
  }
}

@keyframes mask-over {
  from {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
}
</style>

<script setup>
import { computed, toRefs } from 'vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useIntegrations } from '@/composables/useIntegrations';
import { useCatalogItem } from 'src/queries/useCatalog';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  catalogItemId: {
    type: [String, Number],
    required: true,
  },
  listing: {
    type: Object,
    required: true,
  },
  selectedIntegrationId: {
    type: [String, Number, null],
    required: true,
  },
});

defineEmits(['select']);

const { accountId, catalogItemId, selectedIntegrationId, listing } =
  toRefs(props);

const { data: productData } = useCatalogItem(accountId, catalogItemId);

const { integrations, currentIntegrationType } = useIntegrations(
  accountId,
  selectedIntegrationId
);

const selectedIntegration = computed(() => {
  return integrations.value.find(
    integration =>
      Number(integration.id) === Number(selectedIntegrationId.value)
  );
});

function integrationName(integration) {
  if (integration.type === 'AmazonIntegration') {
    return 'Amazon';
  } else if (integration.type === 'ShopifyIntegration') {
    let store = integration.external_provider_id;
    store = store.replaceAll('-', ' ');
    store = store.replaceAll('.myshopify.com', ' ');
    store = store.replaceAll('admin.shopify.com/store/', ' ');
    return store;
  }
}

const integrationLogoLookup = {
  ShopifyIntegration: 'shopify-logo',
  AmazonIntegration: 'amazon-logo',
};

const isAmazonIntegration = computed(
  () => currentIntegrationType.value === 'AmazonIntegration'
);

const listingUrl = computed(
  () => `https://www.amazon.com/dp/${listing.value.catalog_item_info?.asin}`
);

const hasMultipleIntegrations = computed(
  () => productData.value.external_products.length > 1
);
</script>

<template>
  <div
    class="current-integration-listing"
    :class="{
      'current-integration-listing__selectable': hasMultipleIntegrations,
    }"
  >
    <SoonaDropdownMenu
      v-if="hasMultipleIntegrations"
      title="choose integration"
      variation="secondary-gray"
      size="large"
    >
      <template #trigger-content>
        <SoonaIcon
          :name="integrationLogoLookup[selectedIntegration.type]"
          size="medium"
        />
        <span class="integration-name">{{
          integrationName(selectedIntegration)
        }}</span>
      </template>
      <template #default="{ keydown, mouseover, clickCapture }">
        <SoonaDropdownMenuItem
          v-for="ep in productData?.external_products"
          :key="ep.id"
        >
          <button
            class="current-integration-listing__select"
            role="menuitem"
            @click="() => $emit('select', ep)"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            <SoonaIcon
              :name="integrationLogoLookup[ep.integration.type]"
              size="medium"
            />
            <span class="integration-name">{{
              integrationName(ep.integration)
            }}</span>
            <SoonaIcon
              v-if="ep.integration.id === Number(selectedIntegrationId)"
              class="current-integration-listing__icon"
              name="check"
              size="medium"
            />
          </button>
        </SoonaDropdownMenuItem>
      </template>
    </SoonaDropdownMenu>
    <div v-else class="current-integration-listing__select">
      <SoonaIcon
        :name="integrationLogoLookup[selectedIntegration.type]"
        size="medium"
        class="current-integration-listing__logo"
      />
      <span class="current-integration-listing__name">{{
        integrationName(selectedIntegration)
      }}</span>
    </div>

    <a
      v-if="isAmazonIntegration"
      :href="listingUrl"
      class="current-integration-listing__link"
      target="_blank"
    >
      view listing
      <div>
        <SoonaIcon name="arrow-up-right-from-square" size="small" />
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.current-integration-listing {
  display: flex;
  flex-flow: column;
  gap: 1rem;

  &__selectable {
    flex-flow: row wrap;
    align-items: center;
  }

  &__link {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__select {
    display: flex;
    text-transform: none;
    gap: 0.5rem;
    align-items: center;
  }

  &__icon {
    color: variables.$periwink-blue-60;
    margin-left: auto;
  }

  &__logo {
    background-color: variables.$white-default;
    border-radius: 50%;
    padding: 0.125rem;
  }

  &__name {
    text-transform: capitalize;
    font-weight: 800;
  }
}
</style>

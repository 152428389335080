<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

defineProps({
  isLoading: { type: Boolean, required: true },
  isFooter: { type: Boolean, required: false, default: false },
});

const emit = defineEmits(['closeSceneBuilder']);

const closeSceneBuilder = () => {
  emit('closeSceneBuilder');
};
</script>

<template>
  <div
    class="scene-builder__actions"
    :class="{ 'scene-builder__footer': isFooter }"
  >
    <SoonaButton
      variation="tertiary"
      size="medium"
      type="button"
      @on-click="closeSceneBuilder"
    >
      cancel
    </SoonaButton>
    <SoonaButton size="medium" type="submit" :loading="isLoading">
      save
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
.scene-builder {
  &__actions {
    display: flex;
    flex-flow: row wrap;
    gap: 2rem;
  }

  &__footer {
    width: 100%;
    justify-content: flex-end;
  }
}
</style>

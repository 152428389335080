<script setup>
import AssetNotes from '@/components/dam/asset_comparison/AssetNotes.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import DigitalAssetNotesList from '@/components/notes/digital-asset-notes/DigitalAssetNotesList.vue';
import { useFlag } from '@/composables/useFlag';

defineProps({
  flagData: {
    default: null,
    type: Object,
  },
  options: {
    required: true,
    type: Array,
  },
  speed: {
    default: null,
    type: String,
  },
});

const asset = defineModel('asset', {
  default: null,
  type: Object,
});

const hasToasterDigitalAssetCommentsFlag = useFlag(
  'toaster_digital_asset_comments'
);
</script>

<template>
  <div class="asset-details">
    <div class="u-label--regular asset-actions">
      <SoonaSelect v-model="asset" :options="options" />
      <div class="extra-actions">
        <slot name="actions" />
      </div>
    </div>
    <div class="asset-title">
      <SoonaFlag
        v-if="flagData"
        :background-color="flagData.backgroundColor"
        :text-color="flagData.textColor"
        :title="flagData.title"
      />
      <h2 class="u-subheader--heavy">{{ asset?.title }}</h2>
    </div>
    <div class="wrappable-content">
      <div class="asset-notes">
        <AssetNotes
          v-if="!hasToasterDigitalAssetCommentsFlag && asset"
          :asset-id="asset.id"
        />
        <DigitalAssetNotesList
          v-else-if="hasToasterDigitalAssetCommentsFlag && asset.id"
          :digital-asset-id="asset.id"
          :show-no-notes-message="false"
          newest-first
        />
      </div>
      <div class="asset-add-ons">
        <!-- flexible slot for add-ons & other details -->
        <slot name="details" />
        <!-- gif info -->
        <dl v-if="asset?.options && speed">
          <dt class="u-label--heavy">dimensions</dt>
          <dd class="u-small--regular">
            {{ asset?.options.dimensions }}
          </dd>
          <dt class="u-label--heavy">speed</dt>
          <dd class="u-small--regular">{{ speed }}</dd>
          <dt class="u-label--heavy">format(s)</dt>
          <dd class="u-small--regular">
            {{ asset?.options.format.join('/') }} [{{
              asset?.options.direction
            }}]
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.asset-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.asset-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}

.extra-actions {
  display: flex;
  gap: 0.5rem;
}

.asset-title {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  width: 100%;
}

.wrappable-content {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.asset-add-ons {
  flex-shrink: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.asset-notes {
  flex: 1 1 15rem;
}
</style>

<script setup>
import { computed, onMounted } from 'vue';
import { useActionItems } from '@/queries/reservations/useActionItems';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useFlag } from '@/composables/useFlag';
import { useGetRecentlyWrappedReservations } from '@/queries/useGetRecentlyWrappedReservations';
import { useInventoryPackages } from '@/queries/inventory/useInventoryPackages';
import { useStore } from 'vuex';
import { useTitle } from '@vueuse/core';
import ActionItems from './action-items/ActionItems.vue';
import ContentBanner from './ContentBanner.vue';
import DuoBanner from './DuoBanner.vue';
import GetInspiredCarousel from '@/components/shared/GetInspiredCarousel.vue';
import HeroBanner from './HeroBanner.vue';
import PostShootReturnAddressPrompt from '@/components/user/anytime/dashboard/PostShootReturnAddressPrompt.vue';
import ProfessionalServices from './ProfessionalServices.vue';
import TrendingPacks from './TrendingPacks.vue';
import AutoOpenSubscriptionsDialog from './AutoOpenSubscriptionsDialog.vue';
import SpeedyCheckoutBanner from '@/components/ui_library/SpeedyCheckoutBanner.vue';
import ReshopHalloweenBanner from '../ReshopHalloweenBanner.vue';
import { useShopMore } from '@/queries/shop_more/useShopMore';
import { useAccount } from '@/composables/useAccount';

useTitle('home | soona');
const { pageViewed } = useBaseEvents();
onMounted(() => {
  pageViewed();
});

const apolloDashboardContentBanner = useFlag('apollo_dashboard_content_banner');
const speedyCheckoutFlag = useFlag('apollo_speedy_checkout');
const reshopSaleFlag = useFlag('phoenix_reshop_sale');
const store = useStore();

const isAuthenticated = computed(
  () => store.getters['currentUser/isAuthenticated']
);
const currentAccountId = computed(() => store.state.currentUser?.accountId);
const { account } = useAccount(currentAccountId);

// eligible packages for return
const { data } = useInventoryPackages({
  accountId: currentAccountId,
  hasEligibleReturns: true,
  itemsPerPage: 1,
});

const hasEligibleReturns = computed(
  () => data.value?.inventoryPackages.length > 0
);

const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
const { data: actionItems, isSuccess: actionItemsLoaded } = useActionItems(
  {
    accountId: currentAccountId,
    timezone,
  },
  {
    enabled: computed(() => isAuthenticated.value && !!currentAccountId.value),
  }
);

const showActionItems = computed(() => {
  return (
    actionItemsLoaded.value &&
    !Object.values(actionItems.value).every(x => !x.length)
  );
});

const { data: recentlyWrappedReservations } = useGetRecentlyWrappedReservations(
  currentAccountId,
  { enabled: speedyCheckoutFlag.value }
);

const speedyCheckoutReservation = computed(
  () => recentlyWrappedReservations.value?.[0]
);

const { data: facetsData } = useShopMore(currentAccountId, {
  facets: true,
  searchQuery: '',
  itemsPerPage: 1,
  currentPage: 1,
});

const showReshopSale = computed(() => {
  return reshopSaleFlag.value && facetsData.value?.pagination?.onSaleCount > 0;
});
</script>

<template>
  <div class="platform-home">
    <ContentBanner v-if="apolloDashboardContentBanner" />
    <HeroBanner />

    <ReshopHalloweenBanner
      v-if="showReshopSale"
      :is-preferred="account?.preferred"
    />

    <SpeedyCheckoutBanner
      v-if="
        speedyCheckoutFlag && speedyCheckoutReservation?.fast_checkout_eligible
      "
      :checkout-deadline="speedyCheckoutReservation.fast_checkout_deadline"
      :reservation-id="speedyCheckoutReservation.id"
    />

    <PostShootReturnAddressPrompt
      v-if="hasEligibleReturns"
      :account-id="currentAccountId"
    />

    <ActionItems
      v-if="showActionItems"
      :account-id="currentAccountId"
      :action-items="actionItems"
    />
    <TrendingPacks />
    <DuoBanner :account-id="currentAccountId" />
    <ProfessionalServices :account-id="currentAccountId" />
    <GetInspiredCarousel />
    <AutoOpenSubscriptionsDialog />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.platform-home {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  container-type: inline-size;

  @media (min-width: variables.$screen-sm-min) {
    gap: 4.5rem;
  }
}
</style>

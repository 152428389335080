<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCreateABrief } from '@/composables/trend/useCreateABrief';

const utmContent = 'shoot_upgrades_create_a_brief';
const subContext = 'create a brief ugc card';
const buttonCopy = 'create a brief';
const { handleCreateABrief } = useCreateABrief({
  utmContent,
  subContext,
  buttonCopy,
});
const handleUGCClick = () => {
  handleCreateABrief();
};
</script>
<template>
  <div class="ugc-card drop-shadow-container">
    <div class="ugc-card__left">
      <h3 class="ugc-card__title">grow your business with UGC</h3>
      <h4 class="ugc-card__subtitle">
        UGC converts customers at twice the rate
      </h4>
      <p class="ugc-card__text">
        offer authentic perspectives from everyday folks through reviews, posts,
        and videos.
      </p>
      <SoonaButton
        class="ugc-card__button"
        variation="secondary-black"
        @on-click="handleUGCClick"
      >
        {{ buttonCopy }}
      </SoonaButton>
    </div>
    <div class="ugc-card__right">
      <div class="ugc-card__image-wrapper">
        <img
          class="ugc-card__image"
          src="@images/booking-upgrades/ugc_example.png"
          alt=""
        />
        <SoonaIcon class="ugc-card__image-icon" name="stars" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.ugc-card {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background: variables.$bubbletape-pink-20;
  box-shadow: 0rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.2);
  margin-top: 1rem;
  color: variables.$black-default;
  padding: 0 1rem 1rem 1rem;
  width: 100%;

  &__left {
    display: flex;
    flex-direction: column;
    flex: 3;
  }

  &__title {
    @include variables_fonts.u-headline--heavy;
    margin-top: 1.25rem;
    padding-top: 0.5rem;
  }

  &__subtitle {
    @include variables_fonts.u-body--heavy;
    display: none;
  }

  &__text {
    display: none;
    @include variables_fonts.u-body--regular;
    line-height: 1.3333;
    margin-top: 0.625rem;
  }

  &__button {
    margin-top: 0.75rem;
    max-width: fit-content;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 2;
    padding-left: 1rem;
    padding-top: 1rem;
    width: fit-content;
    max-width: 10rem;
  }

  &__image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__image {
    display: block;
    float: right;
    border-radius: 0.625rem;
    max-height: 8.75rem;
    height: auto;
  }

  &__image-icon {
    color: variables.$white-default;
    position: absolute;
    top: 1rem;
    right: 0.5rem;
  }
}

@media (min-width: variables.$screen-sm-min) {
  .ugc-card {
    &__subtitle {
      display: block;
    }
    &__text {
      display: block;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import {
  browserTimeZone,
  convertToHHMM12,
  convertToMMDDYYYYmonthAbr,
} from '@/lib/date-formatters';
import variables from 'src/variables.module.scss';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: false,
  },
  animationDelay: {
    type: Number,
    default: 0,
  },
  item: {
    type: Object,
    required: true,
  },
  tagName: {
    type: String,
    default: 'li',
  },
  type: {
    type: String,
    required: true,
    validator: value => {
      return [
        'next',
        'pending-selects',
        'suggested-booking',
        'suggested-pack',
        'today',
        'unscheduled',
        'default-return-address',
        'package-return-address',
      ].includes(value);
    },
  },
});

const emit = defineEmits(['openDialog']);

defineOptions({
  inheritAttrs: false,
});

const item = computed(() => props.item);
const tagName = computed(() => props.tagName);
const type = computed(() => props.type);

const crewPackRecommendationFlag = useFlag('toaster_crew_pack_recommendation');

const iconNames = {
  next: 'calendar-heart-monochrome',
  'suggested-booking': 'lightbulb-alt-monochrome',
  'suggested-pack': 'lightbulb-alt-monochrome',
  today: 'calendar-heart-monochrome',
  unscheduled: 'clipboard-list-monochrome',
  'default-return-address': 'mailbox-alt-monochrome',
  'package-return-address': 'mailbox-alt-monochrome',
};

const itemColors = {
  next: { bg: variables.AvoToast20, icon: variables.AvoToast40 },
  'pending-selects': {
    bg: variables.PeriwinkBlue30,
    icon: variables.PeriwinkBlue40,
  },
  'suggested-booking': {
    bg: variables.Tangerine20,
    icon: variables.Tangerine70,
  },
  'suggested-pack': {
    bg: variables.Tangerine20,
    icon: variables.Tangerine70,
  },
  today: { bg: variables.FriendlyRed30, icon: variables.FriendlyRed50 },
  unscheduled: {
    bg: variables.Tangerine20,
    icon: variables.Tangerine70,
  },
  'default-return-address': {
    bg: variables.PeriwinkBlue30,
    icon: variables.PeriwinkBlue60,
  },
  'package-return-address': {
    bg: variables.PeriwinkBlue30,
    icon: variables.PeriwinkBlue60,
  },
};

const iconName = computed(() => iconNames[type.value]);
const itemColor = computed(() => itemColors[type.value]);

const hasDialog = computed(() => {
  if (!crewPackRecommendationFlag.value) {
    return ['default-return-address'].includes(type.value);
  }

  return ['default-return-address', 'suggested-pack'].includes(type.value);
});

const isSuggested = computed(() => {
  if (!crewPackRecommendationFlag.value) {
    return type.value === 'suggested-booking';
  }
  return type.value === 'suggested-booking' || type.value === 'suggested-pack';
});
const isPendingSelects = computed(() => type.value === 'pending-selects');
const hasPackagesPendingReturn = computed(() =>
  ['default-return-address', 'package-return-address'].includes(type.value)
);

const isWrapped = computed(() => {
  return ['pending_selects', 'edits_required', 'completed'].includes(
    item.value.status
  );
});
const isTodayAndLive = computed(
  () => type.value === 'today' && item.value.live
);

const linkTo = computed(() => {
  if (isWrapped.value || isTodayAndLive.value) {
    return `/reservation/${item.value.id}`;
  } else if (hasPackagesPendingReturn.value) {
    return `/account/${props.accountId}/products/inventory`;
  } else {
    return `/reservation/${item.value.id}/info`;
  }
});

const dateObj = computed(() => {
  if (!item.value.start) return;
  return new Date(item.value.start);
});
const datetime = computed(() => {
  if (!item.value.start) return;
  return dateObj.value.toISOString();
});
const displayDate = computed(() => {
  if (!item.value.start) return;
  return convertToMMDDYYYYmonthAbr(dateObj.value);
});
const displayTime = computed(() => {
  if (!item.value.start) return;
  return convertToHHMM12(dateObj.value) + ' ' + browserTimeZone();
});

// segment event
const { linkClicked } = useBaseEvents({ reservationId: item.value.id });

const handleLinkClicked = () => {
  if (isPendingSelects.value) {
    linkClicked({
      context: 'home',
      subContext: 'action items',
      linkLabel: 'time to check out',
      linkHref: `/reservation/${item.value.id}`,
    });
  }
};
</script>

<template>
  <component :is="tagName">
    <button
      v-if="hasDialog"
      v-bind="$attrs"
      class="u-body--heavy action-item action-item-button"
      :class="`action-item--${type}`"
      :style="`animation-delay: ${animationDelay}s`"
      type="button"
      @click="() => emit('openDialog')"
    >
      <div
        class="action-item__icon"
        :class="`action-item__icon--${type}`"
        :style="`animation-delay: ${animationDelay + 0.3}s`"
      >
        <SoonaIcon :name="iconName" />
      </div>
      <div class="action-item__content">
        <div class="action-item__content-top">
          <SoonaFlag
            v-if="$slots['pill']"
            :background-color="itemColor.bg"
            type="pill"
          >
            <template #title>
              <strong class="u-badge--big">
                <slot name="pill" />
              </strong>
            </template>
          </SoonaFlag>
          <SoonaChip
            v-if="
              crewPackRecommendationFlag && isSuggested && item.creator_name
            "
            size="medium"
            :image-alt="item.creator_name"
            :image-src="item.creator_avatar_url"
            class="action-item__suggested-by"
          >
            suggested by {{ item.creator_name }}
          </SoonaChip>
        </div>
        <slot />
        <p
          v-if="hasPackagesPendingReturn"
          class="u-label--regular action-item__subtext"
        >
          you can change handling options for any item in the inventory section
          of your product catalog if you prefer to have your products discarded
          or stored.
        </p>
      </div>
      <SoonaIcon name="arrow-right" class="action-item__arrow" />
      <ul
        v-if="crewPackRecommendationFlag && item.pack_id"
        class="action-item__images"
      >
        <li
          class="action-item__image"
          :style="`animation-delay: ${animationDelay + 0.7}s`"
        >
          <img :src="item.pack_image_url" alt="media asset" />
        </li>
      </ul>
    </button>
    <router-link
      v-else
      v-bind="$attrs"
      :to="linkTo"
      class="u-body--heavy action-item"
      :class="`action-item--${type}`"
      :style="`animation-delay: ${animationDelay}s`"
      @click.prevent="handleLinkClicked"
    >
      <div
        v-if="isPendingSelects"
        class="action-item__primary-image"
        :style="`animation-delay: ${animationDelay + 0.3}s`"
      >
        <img
          v-if="item.images.length > 0"
          :src="item.images[0]"
          alt="shoot asset"
        />
      </div>
      <div
        v-else
        class="action-item__icon"
        :class="`action-item__icon--${type}`"
        :style="`animation-delay: ${animationDelay + 0.3}s`"
      >
        <SoonaIcon :name="iconName" />
      </div>
      <div class="action-item__content">
        <div class="action-item__content-top">
          <SoonaFlag
            v-if="$slots['pill']"
            :background-color="itemColor.bg"
            type="pill"
          >
            <template #title>
              <strong class="u-badge--big">
                <slot name="pill" />
              </strong>
            </template>
          </SoonaFlag>
          <em
            v-if="type === 'unscheduled'"
            class="u-badge--big action-item__alert"
          >
            <span class="u-small--heavy action-item__alert-qty">
              {{ item.action_item_qty }}
            </span>
            to-do{{ item.action_item_qty === 1 ? '' : 's' }}
          </em>
          <em v-if="isTodayAndLive" class="u-badge--big action-item__alert">
            join live
          </em>
          <SoonaChip
            v-if="isSuggested && item.creator_name"
            size="medium"
            :image-alt="item.creator_name"
            :image-src="item.creator_avatar_url"
            class="action-item__suggested-by"
          >
            suggested by {{ item.creator_name }}
          </SoonaChip>
        </div>
        <slot />
        <p
          v-if="isPendingSelects"
          class="u-label--regular action-item__subtext"
        >
          find the content you love
        </p>
        <p
          v-else-if="hasPackagesPendingReturn"
          class="u-label--regular action-item__subtext"
        >
          you can change handling options for any item in the inventory section
          of your product catalog if you prefer to have your products discarded
          or stored.
        </p>
        <time
          v-else-if="type === 'today' || type === 'next'"
          :datetime="datetime"
          class="u-label--regular action-item__subtext"
        >
          {{ displayDate }} @ {{ displayTime }}
        </time>
      </div>
      <SoonaIcon name="arrow-right" class="action-item__arrow" />
      <ul
        v-if="isPendingSelects && item.images?.length > 0"
        class="action-item__images"
      >
        <li
          v-for="(image, index) in item.images"
          :key="`pending-select-${index}`"
          class="action-item__image"
          :style="`animation-delay: ${animationDelay + 0.4 + 0.3 * index}s`"
        >
          <img :src="image" alt="media asset" />
        </li>
      </ul>
    </router-link>
  </component>
</template>

<style scoped lang="scss">
@use 'src/variables';

.action-item {
  display: grid;
  grid-template-columns: 2.5rem 1fr 1.5rem;
  gap: 0.75rem;
  align-items: center;
  padding: 1rem 0;
  border-radius: 0.625rem;
  background-color: variables.$white-default;
  transition: background-color 0.1s ease-out;
  animation: k-fade-in 0.8s both ease-in-out;

  &-button {
    width: 100%;
    text-align: left;
  }

  &--pending-selects,
  &--suggested-pack {
    grid-template-areas:
      '. . .'
      '. images .';
  }

  &:hover {
    color: variables.$black-default;
    background-color: variables.$gray-10;
  }

  &__primary-image {
    height: 2.5rem;
    border-radius: 0.3125rem;
    overflow: hidden;
    animation:
      k-fade-in 0.2s both ease-out,
      scale-up 0.6s both cubic-bezier(0.34, 1.56, 0.64, 1);

    @media (prefers-reduced-motion: reduce) {
      animation: k-fade-in 0.2s both ease-out;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 0.5rem;
    height: 2.5rem;
    color: v-bind('itemColor.icon');
    background-color: v-bind('itemColor.bg');
    animation:
      k-fade-in 0.2s both ease-out,
      scale-up 0.6s both cubic-bezier(0.34, 1.56, 0.64, 1);

    @media (prefers-reduced-motion: reduce) {
      animation: k-fade-in 0.2s both ease-out;
    }

    svg {
      display: block;
    }
  }

  &__content {
    grid-column: span 2;
  }

  &__content-top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
  }

  &__alert {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-style: normal;
    font-weight: 900;
    color: variables.$friendly-red-60;
  }

  &__alert-qty {
    padding: 0 0.25rem;
    border-radius: 0.5rem;
    background-color: variables.$friendly-red-50;
    color: variables.$white-default;
    min-width: 1rem;
    text-align: center;
  }

  &__suggested-by {
    font-weight: 400;
  }

  &__subtext {
    display: block;
    margin-top: 0.25rem;
  }

  &__arrow {
    display: none;
  }

  &__images {
    grid-column: images / span 2;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__image {
    height: 3.7rem;
    width: 3.7rem;
    border-radius: 0.3125rem;
    overflow: hidden;
    animation:
      k-fade-in 0.3s both ease-out,
      scale-up 0.5s both cubic-bezier(0.34, 1.56, 0.64, 1);

    @media (prefers-reduced-motion: reduce) {
      animation: k-fade-in 0.3s both ease-out;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  @container action-items (min-width: 22.375rem) {
    &__image {
      height: 4rem;
      width: 4rem;
    }
  }

  @container action-items (min-width: 42.375rem) {
    grid-template-columns: 3.5rem 1fr 1.5rem;
    padding: 1.5rem 1rem;
    gap: 1rem;

    &__primary-image {
      height: 3.5rem;
    }

    &__icon {
      padding: 1rem;
      height: 3.5rem;
    }

    &__content {
      grid-column: auto;
    }

    &__arrow {
      display: flex;
    }

    &__images {
      grid-column: images;
    }

    &__image {
      height: 5rem;
      width: 5rem;
    }
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}
</style>

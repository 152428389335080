<script setup>
import { computed, ref, onMounted } from 'vue';
import TitleBar from '@/components/shared/TitleBar.vue';
import { WhiteDefault } from '@/variables.module.scss';
import { useRouter, useRoute } from 'vue-router';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SelectIntegrationDropdown from '@/components/user/anytime/listing_insights/SelectIntegrationDropdown.vue';
import ManageIntegrationsButton from '@/components/user/anytime/products/ManageIntegrationsButton.vue';
import ManageIntegrationsDialog from '@/components/user/anytime/products/ManageIntegrationsDialog.vue';
import RemoveAmazonAccountDialog from '@/components/user/anytime/products/RemoveAmazonAccountDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import AccountInfo from '@/components/user/anytime/dashboard/AccountInfo.vue';
import { useTitle } from '@vueuse/core';
import { useIntegrations } from '@/composables/useIntegrations';
import { useCapability } from '@/composables/useCapability';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useAccount } from '@/queries/account/useAccount';
import ListingCard from '@/components/user/anytime/listing_insights/listing_card/ListingCard.vue';
import ListingsListFilter from '@/components/user/anytime/listing_insights/ListingsListFilter.vue';
import ListingsListLoadingState from '@/components/user/anytime/listing_insights/ListingsListLoadingState.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import QuestHelper from '@/components/quest/QuestHelper.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import AnalyzingYourListingsBanner from '@/components/user/anytime/listing_insights/AnalyzingYourListingsBanner.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import { useListingsListQueryParams } from '@/components/user/anytime/listing_insights/useListingsListQueryParams';
import { useListings } from '@/queries/useListings';
import { keepPreviousData } from '@tanstack/vue-query';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const props = defineProps({
  accountId: { type: String, required: true },
});

const accountId = computed(() => props.accountId);

const router = useRouter();
const route = useRoute();

useTitle(`listings | soona`);

const { pageViewed } = useBaseEvents();
onMounted(() => {
  pageViewed();
});

// account info
const { data: account, error: accountInfoError } = useAccount(accountId);

// listings data
const {
  actionableIntegrations,
  integrationsSuccess,
  integrationsError,
  currentPlatformName,
} = useIntegrations(accountId);

const integrations = computed(() => actionableIntegrations.value ?? []);

// manage integrations module
const displayManageIntegrationsDialog = ref(false);

const {
  searchText,
  searchDebounced,
  scoreCategory,
  sortBy,
  orderBy,
  direction,
  currentPage,
  itemsPerPage,
  integrationId,
  resetFilters,
} = useListingsListQueryParams();

const {
  data: listingsData,
  error: listingsError,
  isLoading,
  isSuccess,
} = useListings(
  accountId,
  {
    integrationId,
    query: searchDebounced,
    scoreCategory,
    orderBy,
    direction,
    currentPage,
    itemsPerPage,
  },
  {
    placeholderData: keepPreviousData,
    enabled: integrationsSuccess,
  }
);

const listingsPagination = computed(() =>
  listingsData.value
    ? listingsData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

const listings = computed(() => listingsData.value?.listings ?? []);

const listingIsCalculating = computed(() => {
  return isSuccess.value && !listingsData.value?.all_listings_scored;
});

const backToDashboard = () => {
  let query = { source: route.query?.source, quest: route.query?.quest };
  if (integrationId.value) {
    query.integration_id = integrationId.value;
  }
  router.push({
    name: 'listing-insights-dashboard',
    params: { accountId: accountId.value },
    query: query,
  });
};

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

function toggleIntegrationsDialog() {
  displayManageIntegrationsDialog.value =
    !displayManageIntegrationsDialog.value;
}

const integrationsToDisplay = ['AmazonIntegration', 'ShopifyIntegration'];

const selectedIntegrationId = ref(null);

function setIntegrationId(id) {
  let query = {};
  if (id) {
    query = { integration_id: id };
  }
  router.push({
    name: 'listing-insights-store-list',
    params: { accountId: accountId.value },
    query: query,
  });
}

const displayRemoveAmazonAccountDialog = ref(false);

const displayRemoveAmazonAccountDialogHandler = integrationId => {
  selectedIntegrationId.value = integrationId;
  displayManageIntegrationsDialog.value = false;
  displayRemoveAmazonAccountDialog.value = true;
};

const closeRemoveAmazonAccountDialog = () => {
  displayManageIntegrationsDialog.value = true;
  displayRemoveAmazonAccountDialog.value = false;
};

const handleRemovingIntegration = () => {
  router.push(`/account/${accountId.value}/listing-insights`);
};

const priorityError = usePriorityError(
  integrationsError,
  listingsError,
  accountInfoError
);
</script>

<template>
  <div class="listings-list-wrapper">
    <TitleBar
      title="listings"
      :background-color="WhiteDefault"
      back-button-copy="back"
      :border-color="WhiteDefault"
      override-u-container
      justify="left"
      heading-id="listings-list-heading"
      padding="0"
      :previous-page="backToDashboard"
      class="listings-list__title-bar"
    >
      <template #additional-info>
        <div class="integration-status">
          <SoonaIcon
            v-if="currentPlatformName === 'Amazon'"
            name="amazon-logo"
          />
          <SoonaIcon
            v-else-if="currentPlatformName === 'Shopify'"
            name="shopify-logo"
          />
          <SelectIntegrationDropdown
            v-if="integrations.length > 1"
            :selected-integration-id="integrationId"
            :open-manage-accounts="toggleIntegrationsDialog"
            :account-id="accountId"
            variation="secondary-gray"
            @select="setIntegrationId"
          />
          <ManageIntegrationsButton
            v-else
            :account-id="accountId"
            @on-click="toggleIntegrationsDialog"
          />
          <ManageIntegrationsDialog
            v-if="displayManageIntegrationsDialog"
            :account-id="accountId"
            :integrations-to-display="integrationsToDisplay"
            @close="toggleIntegrationsDialog"
            @open-remove-amazon-data-dialog="
              displayRemoveAmazonAccountDialogHandler
            "
          />
          <RemoveAmazonAccountDialog
            v-if="displayRemoveAmazonAccountDialog && selectedIntegrationId"
            :account-id="accountId"
            :integration-id="selectedIntegrationId"
            @close="closeRemoveAmazonAccountDialog"
            @removing-integration="handleRemovingIntegration"
          />
        </div>
      </template>
    </TitleBar>

    <SoonaError v-if="priorityError" no-margin>
      {{ priorityError }}
    </SoonaError>
    <AccountInfo
      v-if="isFtSoonaStaff && account"
      class="listings-list__account-info"
      :account="account"
      :link-to-profile="true"
    />

    <div v-if="isLoading" class="listings-list__loading">
      <ListingsListLoadingState />
    </div>
    <div v-else-if="listingIsCalculating" class="listings-list__loading">
      <AnalyzingYourListingsBanner />
    </div>
    <div v-else-if="isSuccess && !listingIsCalculating" class="listings-list">
      <ListingsListFilter
        v-model:search-text="searchText"
        v-model:selected-filter="scoreCategory"
        v-model:sort-by="sortBy"
      />
      <TransitionGroup
        name="listing"
        tag="div"
        class="listings-list__listing-list"
      >
        <SoonaNoResults
          v-if="listings.length === 0"
          key="listings-list-empty-search-filter"
        >
          <template #header>oops, we couldn’t find a match!</template>
          <template #body>
            try a different search or filter combination
          </template>
          <template #button>
            <SoonaButton variation="secondary-black" :on-click="resetFilters">
              reset filters
            </SoonaButton>
          </template>
        </SoonaNoResults>
        <template v-for="listing in listings" :key="listing.listing_id">
          <QuestHelper quest-task-slug="listing_insights">
            <ListingCard
              :account-id="accountId"
              :integration-id="listing.integration_id"
              :listing="listing"
            />
          </QuestHelper>
        </template>
      </TransitionGroup>
      <PaginatorFull
        v-model:page="currentPage"
        v-model:records-per-page="itemsPerPage"
        class="listings-list__paginator-top"
        :total-pages="listingsPagination.totalPages"
        :total-count="listingsPagination.totalCount"
        :page-count="listings.length"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.listings-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.integration-status {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: variables.$gray-60;
}

.listings-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  &__title-bar {
    margin-bottom: -1rem;

    &:deep(.title-bar__additional-info) {
      padding-top: 0.5rem;
    }
  }

  &__loading {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__account-info {
    margin-bottom: 1.5rem;
  }

  &__listing-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}

// transitions

/* base */
.listing {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.listing-move {
  transition: all 0.4s ease;
}

/* appearing */
.listing-enter-active {
  transition: all 0.3s ease-out;
}

/* disappearing */
.listing-leave-active {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disppear to */
.listing-enter-from,
.listing-leave-to {
  opacity: 0;
}
</style>

<script setup>
import { computed, ref, watch } from 'vue';
import { keepPreviousData } from '@tanstack/vue-query';
import CarouselBase from '@/components/user/anytime/gallery/media-editor/CarouselBase.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';
import { useDigitalAssets } from '@/queries/digital_assets/useDigitalAssets';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  catalogItemId: {
    type: [String, Number],
    required: true,
  },
  activeAssetId: {
    type: [String, Number],
    default: null,
  },
  backText: {
    type: String,
    required: true,
  },
});

const accountId = computed(() => props.accountId);
const activeAssetId = computed(() => props.activeAssetId);
const currentPage = ref(null);
const userChangeablePage = ref(0);

watch([activeAssetId, accountId], () => {
  currentPage.value = null;
  userChangeablePage.value = 0;
});

const filters = computed(() => ({
  ownership: 'customer',
  visibility: 'all',
  catalog_item_id: props.catalogItemId,
}));

const { data: filePages, isLoading } = useDigitalAssets(
  {
    accountId,
    activeAssetId: activeAssetId,
    currentPage,
    itemsPerPage: 12,
    filters,
  },
  {
    placeholderData: keepPreviousData,
    enabled: computed(() => !!activeAssetId.value),
  }
);

watch(userChangeablePage, newValue => {
  if (newValue > 0) {
    currentPage.value = newValue;
  }
});
</script>

<template>
  <CarouselBase
    v-if="filePages?.assets?.length > 0 && !isLoading"
    :file-pages="filePages"
    @update-page="pageNum => (userChangeablePage = pageNum)"
  >
    <template #title>{{ backText }}</template>
    <router-link
      v-for="da in filePages?.assets"
      :key="da.id"
      class="carousel-base__img-link"
      :class="{
        'carousel-base__img-link--active': activeAssetId === da?.id,
      }"
      :to="{
        name: 'product-assets-media-view',
        params: {
          accountId: da?.account_id,
          catalogItemId,
          digitalAssetId: da?.id,
        },
      }"
    >
      <DigitalAssetImage :src="da?.preview?.url" />
    </router-link>
  </CarouselBase>
</template>

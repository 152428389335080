<script setup>
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import uniqueId from 'lodash/uniqueId';
import {
  GradientRedStart,
  GradientRedEnd,
  GradientGreenStart,
  GradientGreenEnd,
  GradientGoldStart,
  GradientGoldEnd,
} from 'src/variables.module.scss';

const props = defineProps({
  accessibleTitle: {
    type: String,
    required: true,
  },
  accessibleDescription: {
    type: String,
    required: false,
    validator(value, props) {
      return value !== props.accessibleTitle;
    },
  },
  data: {
    type: Object,
    default: () => ({
      total: 0,
      segments: [],
    }),
  },
  isLoading: { type: Boolean, default: false },
});

const id = uniqueId('soona-stacked-bar-chart-');

const percentage = value => {
  const total = props.data.total;
  return `${(value / total) * 100}%`;
};

const xOffset = index => {
  const segments = props.data.segments;
  const previousSegments = segments.slice(0, index);
  const previousSegmentsTotal = previousSegments.reduce(
    (acc, segment) => acc + segment.value,
    0
  );
  return percentage(previousSegmentsTotal);
};

const colorLookupStart = {
  poor: GradientRedStart,
  okay: GradientGoldStart,
  great: GradientGreenStart,
};

const colorLookupEnd = {
  poor: GradientRedEnd,
  okay: GradientGoldEnd,
  great: GradientGreenEnd,
};
</script>

<template>
  <SoonaSkeleton v-if="isLoading" class="soona-stacked-bar-chart__skeleton" />
  <figure v-else class="soona-stacked-bar-chart">
    <div>
      <svg
        width="100%"
        height="100%"
        role="group"
        :aria-labelledby="`${id}-title`"
        :aria-describedy="accessibleDescription ? `${id}-desc` : null"
      >
        <title :id="`${id}-title`">{{ accessibleTitle }}</title>
        <desc v-if="accessibleDescription" :id="`${id}-desc`">
          {{ accessibleDescription }}
        </desc>

        <defs>
          <linearGradient
            v-for="(segment, index) in data.segments"
            :id="`gradient-${segment.label}`"
            :key="`gradient-$${index}`"
            x1="0"
            x2="0"
            y1="1"
            y2="0"
          >
            <stop
              offset="0%"
              :stop-color="colorLookupStart[segment.label]"
            ></stop>
            <stop
              offset="100%"
              :stop-color="colorLookupEnd[segment.label]"
            ></stop>
          </linearGradient>
        </defs>

        <g id="segments" role="list">
          <g
            v-for="(segment, index) in data.segments"
            :id="`segment-${segment.label}`"
            :key="segment.label"
            role="listitem"
            :aria-label="`${segment.label}: ${percentage(segment.value)}`"
          >
            <rect
              :id="`segment-${segment.label}-bar`"
              :x="xOffset(index)"
              y="0"
              :width="percentage(segment.value)"
              height="100%"
              :fill="`url(#gradient-${segment.label})`"
            ></rect>
          </g>
        </g>
      </svg>
    </div>

    <!-- Legend -->
    <figcaption class="soona-stacked-bar-chart__legend">
      <slot name="figcaption"></slot>
    </figcaption>
  </figure>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-stacked-bar-chart {
  &__skeleton {
    height: 1rem;
    width: 100%;
    border-radius: 1rem;
  }

  [role='group'] {
    width: 100%;
    height: 1rem;
    border-radius: 1rem;
  }

  &__legend {
    margin-top: 1rem;
    display: flex;
    flex-flow: row wrap;
    gap: 0.75rem;
  }
}
</style>

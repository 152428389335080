<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

const emits = defineEmits(['close', 'confirm']);
</script>

<template>
  <SoonaDialog @close="emits('close')">
    <template #heading>
      <span
        ><svg
          width="28"
          height="28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="info-trait-svg"
        >
          <path
            d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14Z"
            fill="#E4E8FD"
          />
          <path
            d="M14 6.708A7.294 7.294 0 0 0 6.708 14 7.294 7.294 0 0 0 14 21.292 7.294 7.294 0 0 0 21.292 14 7.294 7.294 0 0 0 14 6.708Zm.73 10.938h-1.46V13.27h1.46v4.375Zm0-5.834h-1.46v-1.458h1.46v1.459Z"
            fill="#3C51F1"
          />
        </svg>
        incomplete traits
      </span>
    </template>
    <p>
      you haven’t completed filling out all of your traits. we cannot schedule
      you until all of your traits are complete.
    </p>
    <template #footer="{ close }">
      <SoonaButton
        variation="tertiary"
        data-cypress="button-dialog-cancel"
        @on-click="close"
      >
        save &amp; do this later
      </SoonaButton>
      <SoonaButton
        data-cypress="button-dialog-confirm"
        @on-click="emits('confirm')"
      >
        complete traits now
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.info-trait-svg {
  margin-right: 0.5rem;
  position: relative;
  top: 7px;
}
</style>

import colors from '@/variables.module.scss';

export const soonaToastVariations = {
  info: {
    iconName: 'circle-information-solid',
    iconColor: colors.PeriwinkBlue70,
    iconBg: colors.PeriwinkBlue20,
  },
  success: {
    iconName: 'circle-check-solid',
    iconColor: colors.AvoToast40,
    iconBg: colors.AvoToast20,
  },
  error: {
    iconName: 'circle-exclamation-solid',
    iconColor: colors.Roses60,
    iconBg: colors.Roses20,
  },
  alert: {
    iconName: 'triangle-exclamation-solid',
    iconColor: colors.Tangerine70,
    iconBg: colors.Tangerine20,
  },
};

import { unref } from 'vue';
import { useQuery, useInfiniteQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { getAccountsOrders } from '@/api/accountsOrders';

/**
 *
 * @param {Ref<string | number>} accountId
 * @param {Object} [params]
 * @param {Ref<number>} [params.currentPage]
 * @param {Ref<Array<string>> | Array<string>} [params.orderStatus]
 * @param {Ref<Array<string>> | Array<string>} [params.orderType]
 * @param {Object} [queryOptions]
 */
export function useGetAccountsOrders(
  accountId,
  { currentPage, orderStatus, orderType } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.accountOrders(accountId, {
      currentPage,
      orderStatus,
      orderType,
    }),
    queryFn: ({ signal }) =>
      getAccountsOrders(
        unref(accountId),
        {
          currentPage: unref(currentPage),
          orderStatus: unref(orderStatus),
          orderType: unref(orderType),
        },
        signal
      ),
    ...queryOptions,
  });
}

/**
 *
 * @param {Ref<string | number>} accountId
 * @param {Object} [params]
 * @param {Ref<number>} [params.currentPage]
 * @param {Ref<Array<string>> | Array<string>} [params.orderStatus]
 * @param {Ref<Array<string>> | Array<string>} [params.orderType]
 * @param {Object} [queryOptions]
 */
export function useInfiniteAccountsOrders(
  accountId,
  { currentPage, itemsPerPage, orderStatus, orderType } = {},
  queryOptions
) {
  return useInfiniteQuery({
    queryKey: queryKeys.accountOrders(accountId, {
      currentPage,
      orderStatus,
      orderType,
    }),
    queryFn: ({ signal, pageParam }) =>
      getAccountsOrders(
        unref(accountId),
        {
          currentPage: pageParam.currentPage,
          itemsPerPage: pageParam.itemsPerPage,
          orderStatus: unref(orderStatus),
          orderType: unref(orderType),
        },
        signal
      ),
    initialPageParam: { currentPage: 1, itemsPerPage },
    getNextPageParam: lastPage => {
      if (
        lastPage.pagination.currentPage + 1 >
        lastPage.pagination.totalPages
      ) {
        return null;
      } else {
        return {
          currentPage: lastPage.pagination.currentPage + 1,
          itemsPerPage: lastPage.pagination.itemsPerPage,
        };
      }
    },
    ...queryOptions,
  });
}

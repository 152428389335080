import { computed, isRef, unref } from 'vue';
import { useQueries } from '@tanstack/vue-query';
import { capabilityQueryConfig } from '../queries/useCapability';

/**
 * @typedef CapabilityParams
 * @type {object}
 * @property {string | Ref<string>} capability
 * @property {string | Ref<string>} [subjectType]
 * @property {string | number | Ref<string | number>} [subjectId]
 */

/**
 * @typedef CapabilitiesResult
 * @type {Object.<string, CapabilityResult>}
 *
 * @description an object with any string as the key, and the value of a CapabilityResult
 */

/**
 * @description this returns a computed ref with an object of capability names
 * and status/hasCapability pairs. because this is a ref at the top level, it
 * cannot be destructured, or reactivity will be broken.
 *
 * @param {Ref<CapabilityParams[]>} capabilities
 * @returns {ComputedRef<CapabilitiesResult>}
 */
export function useCapabilities(capabilities) {
  if (!isRef(capabilities) || !Array.isArray(unref(capabilities))) {
    throw new Error('[useCapabilities] must be called with a ref array');
  }

  const capabilitiesOptions = computed(() =>
    capabilities.value.map(({ subjectType, subjectId }) =>
      capabilityQueryConfig({ subjectType, subjectId })
    )
  );

  const queries = useQueries({ queries: capabilitiesOptions });

  return computed(() =>
    capabilities.value.reduce((acc, capability, index) => {
      // this assumes that the capabilities array will always match the order and presence of the queries array
      const query = queries.value[index];

      acc[capability.capability] = {
        status: query.status,
        hasCapability:
          query.data?.includes(unref(capability.capability)) || false,
      };

      return acc;
    }, {})
  );
}

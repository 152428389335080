<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useQueryClient } from '@tanstack/vue-query';
import { useMe } from '@/composables/user/useMe';
import { queryKeys } from '@/queries/query-keys';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';
import { useIndustries } from 'src/queries/useIndustries';
import { useCapability } from '@/composables/useCapability';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import Capability from '@/components/Capability.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const queryClient = useQueryClient();
const { me, invalidateMe } = useMe();

const avatarLoading = ref(false);
const isEditing = ref(false);
const website = ref('');
const industry = ref('');
const preferred_timezone = ref('');
const phone = ref('');
const email = ref('');
const name = ref('');

const store = useStore();
const account = computed(() => store.state.account);
const isMyAccount = computed(
  () => store.state.currentUser.accountId === store.state.account.id
);
const guessedTimeZone = computed(
  () => store.getters['account/guessedTimeZone']
);
const timeZonesList = computed(() =>
  store.getters['account/timeZonesList'].map(tz => ({
    ...tz,
    label: tz.name?.replace('_', ' ') ?? '',
  }))
);

const { data: industries } = useIndustries();
const selectedIndustrySlug = ref(undefined);
const accountSelectedIndustry = computed(() => account.value?.industry?.slug);
function industryMapper(industry) {
  return {
    ...industry,
    label: industry.title,
    value: industry.slug,
    active: industry.slug === accountSelectedIndustry.value,
  };
}
const industryOptions = computed(() => {
  if (!industries.value) {
    return [];
  } else if (
    industries.value.findIndex(i => i.slug === accountSelectedIndustry.value) >
    -1
  ) {
    return industries.value.map(industryMapper);
  } else {
    const deprecatedIndustry = {
      ...account.value.industry,
      active: false,
    };
    return [...industries.value, deprecatedIndustry].map(industryMapper);
  }
});

const disabled = computed(() => {
  return !preferred_timezone.value;
});

const authorized = computed(() => {
  return isMyAccount.value || isFtSoonaStaff.value;
});

function prefillExistingData() {
  website.value = account.value.profile_data.lets_get_the_basics?.data?.website;
  industry.value = account.value?.industry?.slug;
  name.value = account.value.name;
  email.value = account.value.email;
  phone.value = account.value.phone;
  preferred_timezone.value = account.value.preferred_timezone
    ? account.value.preferred_timezone
    : guessedTimeZone.value;
}

async function handleAvatarUploadComplete(blob) {
  avatarLoading.value = true;
  await store.dispatch('account/createAvatarFromUpload', {
    accountId: account.value.id,
    blob: blob,
  });
  avatarLoading.value = false;
}

function toggleEdit() {
  return (isEditing.value = !isEditing.value);
}

async function save() {
  const info = {
    industry_id: industryOptions.value.find(
      i => i.slug === selectedIndustrySlug.value
    )?.id,
    lets_get_the_basics: {
      data: {
        website: website.value,
        industry:
          account.value.profile_data.lets_get_the_basics.data.industry_type,
      },
      complete: true,
    },
    preferred_timezone: preferred_timezone.value,
    preferred_timezone_card: {
      complete: true,
    },
    upload_profile_pic: {
      complete: true,
    },
    phone: phone.value,
    email: email.value,
    name: name.value,
  };
  await store.dispatch('account/updateAccount', { accountParams: info });
  if (account.value?.id) {
    const promises = [
      queryClient.invalidateQueries({
        queryKey: queryKeys.account(account.value.id),
      }),
    ];

    if (me.value?.accounts?.some(a => a.id === account.value.id)) {
      promises.push(invalidateMe());
    }

    await Promise.all(promises);
  }
  return (isEditing.value = !isEditing.value);
}

watchEffect(() => (selectedIndustrySlug.value = account.value?.industry?.slug));

watchEffect(() => {
  if (account.value) {
    prefillExistingData();
  }
});
</script>
<template>
  <div class="TheBasics">
    <h3 class="u-title--heavy mb-l">the basics</h3>
    <div class="upload-image-container">
      <SoonaAvatar
        :name="account.name"
        :src="account.avatar_url"
        size="9.375rem"
        type="account"
      />
      <div class="upload-overlay"></div>
      <div v-if="authorized" class="upload-icon">
        <SoonaUpload
          class-overide="u-headline--regular is-marginless"
          :upload-complete="handleAvatarUploadComplete"
          :hide-label="true"
          :hide-icon="avatarLoading"
          :camera-icon="true"
          :is-multiple="false"
          :icon-size-overide="true"
          accept=".jpeg, .jpg, .png, .gif"
        />
        <beat-loader
          v-if="avatarLoading"
          color="#FFFFFF"
          size="10px"
          class="avatar-loader"
        />
      </div>
    </div>
    <fieldset class="field-wrapper">
      <SoonaTextfield
        v-model="name"
        :disabled="!isEditing"
        label="account name"
        name="name"
        :placeholder="isEditing ? 'name' : ''"
        type="text"
        class="textfield-remove-padding"
      />
      <SoonaTextfield
        v-model="website"
        :disabled="!isEditing"
        label="website"
        name="website"
        :placeholder="isEditing ? 'https://' : ''"
        type="text"
        class="textfield-remove-padding"
      />
      <SoonaSelect
        v-if="isEditing"
        v-model:model-value="selectedIndustrySlug"
        :options="industryOptions"
        :selectable="option => !option.active"
      >
        <template #label>industry type</template>
      </SoonaSelect>
      <div v-else>
        <p class="u-label--heavy basics-read-mode-label">industry type</p>
        <p class="field-read-mode">
          {{ account && account.industry && account.industry.title }}
        </p>
      </div>
      <SoonaSelect
        v-if="isEditing"
        v-model:model-value="preferred_timezone"
        :options="timeZonesList"
        :reduce="timezone => timezone.name"
      >
        <template #label>
          preferred timezone
          <span class="basics-label-required">*</span>
        </template>
      </SoonaSelect>
      <div v-else>
        <p class="u-label--heavy basics-read-mode-label">
          preferred timezone
          <span class="basics-read-mode-label__required">*</span>
        </p>
        <p class="field-read-mode">
          {{ preferred_timezone?.replace('_', ' ') ?? '' }}
        </p>
      </div>
    </fieldset>
    <Capability
      capability-name="manage_account"
      subject-type="account"
      :subject-id="account.id"
    >
      <div class="action-btns">
        <SoonaButton v-if="!isEditing" size="medium" @on-click="toggleEdit">
          edit
        </SoonaButton>
        <SoonaButton
          v-else
          class="save-btn"
          :disabled="disabled"
          size="medium"
          @on-click="save"
        >
          save
        </SoonaButton>
      </div>
    </Capability>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.TheBasics {
  background-color: variables.$white-default;
  border-radius: 0.75rem;
  box-shadow: variables.$elevation-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;

  .field-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 0;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .textfield-remove-padding {
    padding-bottom: 0;
  }

  .basics-read-mode-label {
    margin-bottom: 0.4375rem;

    &__required {
      color: variables.$friendly-red-50;
    }
  }

  .basics-select {
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$white-default;
    border-radius: 0.375rem;
    box-shadow: variables.$elevation-1;
    height: 1.875rem;
    padding: 0.5em 0.75em;
    width: 100%;

    &:focus {
      border-color: variables.$gray-90;
    }
  }

  .field-read-mode {
    background: variables.$gray-20;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.375rem;
    overflow: hidden;
    padding: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .upload-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    width: 150px;
    opacity: 0.3;
    background: variables.$gray-90;
    border-radius: 50%;
    display: none;
  }

  .upload-icon {
    color: variables.$white-default;
    position: absolute;
    top: 50%;
    left: 47%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    display: none;
    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -0.5625rem;
      transform: translate(-50%, 0.125rem);
    }
  }

  .upload-image-container:hover .upload-icon {
    display: block;
  }

  .upload-image-container:hover .upload-overlay {
    display: block;
  }

  .upload-image-container {
    margin: 1em auto;
    position: relative;
  }

  .avatar-loader {
    position: absolute;
    top: 59px;
    left: 43px;
  }

  .action-btns {
    margin: auto;
    width: max-content;
  }

  .save-btn {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }
}
</style>

<script setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useMe } from '@/composables/user/useMe';
import ShopMore from '@/components/account_gallery/ShopMore.vue';
import { useOrder } from '@/queries/orders/useOrder';
import SoonaLoading from '@/components/SoonaLoading.vue';
import TrendHeader from '@/components/user-generated-content/TrendHeader.vue';

const props = defineProps({
  orderId: {
    type: [String, Number],
    default: null,
  },
});

useTitle('thanks', { titleTemplate: '%s | soona' });
const route = useRoute();
const { pageViewed, linkClicked } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

const orderId = computed(() => props.orderId);
// silently ignore errors and let more generic fallback display
const { data: order, isLoading: orderIsLoading } = useOrder(
  orderId,
  {
    ignore_checks: true,
  },
  { enabled: computed(() => orderId.value !== null), retry: 0 }
);

const DISPLAY_TYPES = Object.freeze({
  GENERIC: 'generic',
  ORDER_ADD_ON: 'order_add_on',
  ORDER: 'order',
});

const displayType = computed(() => {
  if (!orderId.value || !order.value) {
    return DISPLAY_TYPES.GENERIC;
  } else if (order.value?.order_type === 'add_on') {
    return DISPLAY_TYPES.ORDER_ADD_ON;
  } else {
    return DISPLAY_TYPES.ORDER;
  }
});

const { currentAccountId } = useMe();

function segmentLinkClicked(e) {
  linkClicked({
    context: route.meta?.context ?? 'unknown',
    subContext: 'order complete',
    linkLabel: e.currentTarget.textContent.trim(),
    linkHref: e.currentTarget.href,
  });
}
</script>

<template>
  <div class="order-confirmation">
    <SoonaLoading
      v-if="orderIsLoading"
      is-loading
      class="order-confirmation__loading"
    />
    <template v-else>
      <div class="order-confirmation__main">
        <div class="order-confirmation__checkmark">
          <SoonaIcon name="check" size="large" />
        </div>
        <template v-if="displayType === DISPLAY_TYPES.ORDER_ADD_ON">
          <h1 class="u-headline--heavy">thanks for your order!</h1>
          <div class="order-confirmation__details">
            <p class="u-body--regular">
              great choices! time for the soona crew to work their editing
              magic.
            </p>
            <p class="u-body--regular">
              we’ll notify you when the finished assets are ready.
            </p>
          </div>
          <div class="order-confirmation__actions">
            <SoonaButton
              element="router-link"
              variation="secondary-black"
              :to="{
                name: 'order-editing-summary',
                params: {
                  reservationId: order.reservation_id,
                  orderId: order.id,
                },
              }"
              @on-click="segmentLinkClicked"
            >
              review order
            </SoonaButton>
            <SoonaButton
              element="router-link"
              variation="solid-black"
              :to="`/reservation/${order.reservation_id}`"
              @on-click="segmentLinkClicked"
            >
              return to booking
            </SoonaButton>
          </div>
        </template>
        <template v-else-if="displayType === DISPLAY_TYPES.ORDER">
          <h1 class="u-headline--heavy">thanks for your order!</h1>
          <div class="order-confirmation__details">
            <p class="u-body--regular">we have received your payment.</p>
            <p class="u-body--regular">
              we can’t wait to keep creating with you.
            </p>
          </div>
          <div class="order-confirmation__actions">
            <SoonaButton
              element="router-link"
              variation="secondary-black"
              :to="`/account/${currentAccountId}/orders/${orderId}`"
              @on-click="segmentLinkClicked"
            >
              review order
            </SoonaButton>
            <SoonaButton
              v-if="order.reservation_id"
              element="router-link"
              variation="solid-black"
              :to="`/reservation/${order.reservation_id}`"
              @on-click="segmentLinkClicked"
            >
              return to booking
            </SoonaButton>
          </div>
        </template>
        <template v-else>
          <h1 class="u-headline--heavy">thanks!</h1>
          <div class="order-confirmation__details">
            <p class="u-body--regular">
              we’ve received your payment. we can’t wait to keep creating with
              you!
            </p>
          </div>
          <div class="order-confirmation__actions">
            <SoonaButton
              element="router-link"
              variation="secondary-black"
              :to="`/account/${currentAccountId}/orders`"
              @on-click="segmentLinkClicked"
            >
              billing history
            </SoonaButton>
            <SoonaButton
              element="router-link"
              variation="solid-black"
              :to="`/account/${currentAccountId}/gallery`"
              @on-click="segmentLinkClicked"
            >
              go to gallery
            </SoonaButton>
          </div>
        </template>
      </div>
      <h2 class="u-title--heavy">what’s next?</h2>
      <TrendHeader heading-element="h3" />
      <ShopMore
        class="view-thanks__shop-more-digital-assets"
        :account-id="currentAccountId"
      >
        <template #title>did you miss any?</template>
      </ShopMore>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.order-confirmation {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  container: ugc-page / inline-size;

  &__loading {
    position: static;
    width: auto;
    z-index: auto;
    height: 100%;
    min-height: 8rem;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }

  &__checkmark {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    color: variables.$white-default;
    border-radius: 2rem;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 100%
      ),
      variables.$green-apple-40;

    svg {
      height: 3rem;
      width: 3rem;
    }
  }

  &__details {
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { getUser } from '../../api/users';

/**
 *
 * @param {number | Ref<number>} userId
 */
export function useGetUser(userId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.user(userId),
    queryFn: ({ signal }) => getUser(unref(userId), signal),
    ...queryOptions,
  });
}

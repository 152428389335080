<template>
  <SoonaDialog @close="closeModal">
    <template #heading>continuation details</template>
    <div class="shot-list-status">
      <p class="subheader-text">
        would you like to copy this shot list to the continuation shoot?
      </p>
      <label class="control radio">
        <input
          v-model="shotStatus"
          name="shot-status"
          type="radio"
          class="button-group-input"
          value="all"
          data-cypress="radio-copy-all-shots"
        />
        <span class="control-indicator all"></span>
        <span class="label-text">all shots</span>
      </label>
      <label class="control radio">
        <input
          v-model="shotStatus"
          name="shot-status"
          type="radio"
          class="button-group-input"
          value="incomplete"
          data-cypress="radio-copy-incomplete-shots"
        />
        <span class="control-indicator incomplete"></span>
        <span class="label-text">incomplete shots</span>
      </label>
      <label class="control radio">
        <input
          v-model="shotStatus"
          name="shot-status"
          type="radio"
          class="button-group-input"
          value="none"
          data-cypress="radio-copy-none-shots"
        />
        <span class="control-indicator none"></span>
        <span class="label-text">none</span>
      </label>
    </div>
    <div class="copy-packages">
      <p class="subheader-text">
        do you want to include the inventory items from this shoot into the
        next?
      </p>
      <label class="control radio">
        <input
          v-model="copyPackages"
          name="copy-packages"
          type="radio"
          class="button-group-input"
          :value="true"
          data-cypress="radio-copy-packages-yes"
        />
        <span class="control-indicator yes"></span>
        <span class="label-text">yes</span>
      </label>
      <label class="control radio">
        <input
          v-model="copyPackages"
          name="copy-packages"
          type="radio"
          class="button-group-input"
          :value="false"
          data-cypress="radio-copy-packages-no"
        />
        <span class="control-indicator no"></span>
        <span class="label-text">no</span>
      </label>
    </div>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        :disabled="disableContinue"
        data-cypress="button-dialog-confirm"
        @on-click="handleConfirm"
      >
        continue
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<script>
import { mapGetters } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

export default {
  name: 'ContinuationDetailsModal',
  components: {
    SoonaButton,
    SoonaDialog,
  },
  props: {
    closeModal: Function,
  },
  data() {
    return {
      shotStatus: '',
      copyPackages: '',
    };
  },
  computed: {
    ...mapGetters('reservation', ['currentReservation']),
    parentReservation() {
      return this.currentReservation.previous || this.currentReservation;
    },
    disableContinue() {
      return this.shotStatus === '' || this.copyPackages === '';
    },
  },
  methods: {
    handleConfirm() {
      let shotStatus = this.shotStatus;
      let copyPackages = this.copyPackages;

      this.$router.push(
        `/crew/booking/new?account_id=${this.parentReservation.account_id}&previous_reservation_id=${this.parentReservation.id}&shot_list=${shotStatus}&inventory_packages=${copyPackages}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';

.subheader-text {
  margin-bottom: 8px;
  font-weight: 800;
  font-size: 14px;
}

.button-group-input {
  display: none;
}

.control-indicator {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2px;
  height: 40px;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  line-height: 25px;
  background: variables.$gray-20;
  border: 1px solid variables.$gray-20;
  justify-content: center;
  align-items: center;
  padding: 0px 14px;
}

.control-indicator.all {
  width: 88px;
  border-radius: 5px 0px 0px 5px;
}

.control-indicator.incomplete {
  width: 156px;
}

.control-indicator.none {
  width: 65px;
  border-radius: 0px 5px 5px 0px;
}

.control-indicator.yes {
  width: 52px;
  border-radius: 5px 0px 0px 5px;
}

.control-indicator.no {
  width: 52px;
  border-radius: 0px 5px 5px 0px;
}

.radio {
  margin-left: 0;
}

.radio input:checked ~ .control-indicator {
  background: variables.$friendly-red-20;
  border-color: variables.$friendly-red-40;
}

.radio input:hover:checked ~ .control-indicator {
  background: variables.$friendly-red-30;
  border-color: variables.$friendly-red-50;
}

.radio input:hover ~ .control-indicator {
  background: variables.$gray-30;
  border-color: variables.$gray-50;
}

.label-text {
  position: relative;
  bottom: 30px;
  left: 16px;
}
</style>

import { storeToRefs } from 'pinia';

import { useCreateThread } from '@/queries/ai_producer/useCreateThread';

import { useRunMessage } from './useRunMessage';
import { useAssistantStore } from './useAssistantStore';
import { useSaveThread } from '@/queries/ai_producer/useSaveThread';

export function useAssistant({
  onReset,
  includes,
  assistantId,
  functionHandler,
}) {
  const store = useAssistantStore();
  const {
    threadId,
    messages,
    isWaiting,
    userInput,
    eventSource,
    hasMessages,
    scrollContainerRef,
    isWaitingForInitialResponse,
  } = storeToRefs(store);

  const { handleRunMessage } = useRunMessage({ functionHandler });

  const {
    mutate: createThread,
    error: createThreadError,
    isPending: isCreatingThread,
  } = useCreateThread({ onSuccess: res => store.setThreadId(res.id) });

  const { mutate: saveThread } = useSaveThread();

  function submitMessage(message) {
    store.setIsWaiting(true);
    store.setIsWaitingForInitialResponse(true);
    store.appendMessage({ role: 'user', text: message });
    store.setUserInput('');
    handleRunMessage({ message, assistantId, includes });
  }

  function resetProducer() {
    eventSource.value?.close();
    store.$reset();
    createThread();
    onReset?.();
  }

  function resetUnmounted() {
    eventSource.value?.close();
    store.$reset();
    onReset?.();
  }

  return {
    messages,
    threadId,
    userInput,
    isWaiting,
    eventSource,
    hasMessages,
    isCreatingThread,
    createThreadError,
    scrollContainerRef,
    isWaitingForInitialResponse,
    createThread,
    saveThread,
    submitMessage,
    resetProducer,
    resetUnmounted,
  };
}

<script setup>
import { computed } from 'vue';
import BeatLoader from '@/components/shared/BeatLoader.vue';
import { BubbletapePink30 } from 'src/variables.module.scss';

const props = defineProps({
  marginTop: { type: String, default: '15vh' },
});

const marginTop = computed(() => props.marginTop);
</script>

<template>
  <div class="banner__wrapper">
    <div class="banner__animation">
      <BeatLoader :color="BubbletapePink30" size="0.75em" />
    </div>
    <h2 class="banner__header">analyzing your listings...</h2>
    <p class="banner__text">
      this may take a few minutes as we crunch some numbers.
    </p>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.banner {
  &__wrapper {
    background: rgba(0, 0, 0, 0.68);
    border-radius: 0.625rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
    max-width: 31.25rem;
    align-self: center;
    margin-top: v-bind(marginTop);
  }

  &__header {
    @include variables_fonts.u-title--heavy;

    color: variables.$white-default;
  }

  &__text {
    color: variables.$white-default;
    text-align: center;
  }
}
</style>

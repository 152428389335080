<template>
  <div
    class="ImageMessage"
    :style="{ justifyContent: isOutgoing ? 'flex-end' : 'flex-start' }"
  >
    <ChatAvatar v-if="!isOutgoing" :message="message" />
    <div class="image-container">
      <img
        v-viewer="{
          toolbar: {
            download: downloadImage,
          },
          navbar: false,
          title: false,
          backdrop: true,
          minZoomRatio: 0.01,
          maxZoomRatio: 2,
          container: teleportTo ?? '.media-editor',
        }"
        :src="message.data.url"
        alt="image attachment"
        :style="{
          borderBottomLeftRadius: message.caption ? 0 : '10px',
          borderBottomRightRadius: message.caption ? 0 : '10px',
        }"
      />
      <p v-if="message.caption">{{ message.caption }}</p>
    </div>
    <ChatAvatar v-if="isOutgoing" :message="message" />
  </div>
</template>

<script>
import { useTeleportTo } from '@/composables/useTeleportTo';
import ChatAvatar from '../ChatAvatar.vue';

export default {
  name: 'ImageMessage',
  components: {
    ChatAvatar,
  },
  props: {
    message: Object,
    isOutgoing: Boolean,
  },
  setup() {
    const teleportTo = useTeleportTo();

    return { teleportTo };
  },
  methods: {
    async downloadImage() {
      const response = await fetch(this.message.data.url);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = this.message.data.attachments[0].name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss">
@use '@/variables';

.ImageMessage {
  display: flex;
  padding: 0.75rem 0;

  .image-container {
    display: flex;
    flex-direction: column;
    margin: 0 0.3125rem;
    border-radius: 0.625rem;
    width: 8.75rem;

    img {
      object-fit: cover;
      width: 8.75rem;
      height: 7.5rem;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem;
      transition: opacity 0.1s ease-out;
      cursor: pointer;

      &:hover,
      &:focus-visible {
        opacity: 0.7;
      }
    }

    p {
      background: variables.$periwink-blue-20;
      padding: 0.75rem;
      border-bottom-right-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
}

.viewer-download {
  height: 2.625rem !important;
  width: 2.625rem !important;
  background-image: url('~images/download.svg');
  background-position: center;
  background-repeat: no-repeat;
}
</style>

<script setup>
const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;
</script>
<template>
  <div class="marketing-email-consent">
    <p>
      by signing up you agree to our
      <a
        href="http://soona.co/terms-of-service/"
        target="_blank"
        rel="noopener noreferrer"
      >
        terms of service</a
      >
      ({{ termsOfServiceDate }}). and to receive ecomm tips, inspiration and
      special offers from soona. view our
      <a
        href="http://soona.co/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        privacy policy</a
      >.
    </p>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.marketing-email-consent {
  @include variables_fonts.u-small--regular;

  margin-top: 1rem;
  padding: 0.75rem;
  background: variables.$gray-10;

  a {
    text-decoration: underline;
  }
}
</style>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getChatAuth } from '@/api/users';
import { queryKeys } from '@/queries/query-keys';

export function useChatAuth(userId, queryConfig = undefined) {
  return useQuery({
    queryKey: queryKeys.userChatAuth(userId),
    queryFn: ({ signal }) => getChatAuth(toValue(userId), signal),
    // cache without marking stale for 30 minutes
    gcTime: 1000 * 60 * 30,
    staleTime: 1000 * 60 * 30,
    ...queryConfig,
  });
}

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param { string | number | Ref<string | number> } collectionId
 * @param { string | number | Ref<string | number> | string } cdaId
 */
export function useDeleteStaffPickCollectionDigitalAsset(collectionId, cdaId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.delete(
        `/collections/${toValue(
          collectionId
        )}/staff_picks_collection_digital_assets/${toValue(cdaId)}`
      );

      return response.data;
    },
    onSuccess: async (data, variables) => {
      // optional variable for deleting, so we can delete a query we know will 404 after this mutation succeeds
      if (variables?.digital_asset_id) {
        queryClient.removeQueries({
          queryKey: queryKeys.collectionAssetByDigitalAsset(
            collectionId,
            'staff_picks_collection',
            variables.digital_asset_id
          ),
        });
      }

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.digitalAssetsObliterateAllKeys(),
        }),
      ]);
    },
  });
}

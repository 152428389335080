import { http } from '@/config/vue-axios';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { toValue } from 'vue';

export function useMokkerDigitalAsset(assetUuid) {
  return useQuery({
    queryKey: queryKeys.mokkerDigitalAsset(assetUuid),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/mokker_digital_assets/${toValue(assetUuid)}`,
        {
          signal,
        }
      );
      return response.data;
    },
  });
}

<script setup>
import { computed } from 'vue';
import SoonaImageScroller from '@/components/ui_library/SoonaImageScroller.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import accessoriesAndEducationImage from 'images/booking/industry/headshots/Accessories_and_Education@2x.jpg';
import beautyAndSkincareImage from 'images/booking/industry/headshots/Beauty_And_Skincare@2x.jpg';
import clothingAndShoesImage from 'images/booking/industry/headshots/Clothing_And_Shoes@2x.jpg';
import foodAndBeverageAndOutdoorAndRecreationImage from 'images/booking/industry/headshots/Food_And_Beverage_and_Outdoor_And_Recreation@2x.jpg';
import healthAndWellnessImage from 'images/booking/industry/headshots/Health_And_Wellness@2x.jpg';
import homeAndGardenImage from 'images/booking/industry/headshots/Home_And_Garden@2x.jpg';
import jewelryAndCBDImage from 'images/booking/industry/headshots/Jewelry_and_CBD@2x.jpg';
import petProductsImage from 'images/booking/industry/headshots/Pet_Products@2x.jpg';
import techImage from 'images/booking/industry/headshots/Tech@2x.jpg';
import toysAndHobbiesAndPersonalBrandImage from 'images/booking/industry/headshots/Toys_And_Hobbies_and_Personal_Brand@2x.jpg';

const props = defineProps({
  selectedIndustry: {
    type: Object,
    default: null,
  },
});

const industryHash = {
  accessories: 0,
  education: 0,
  'beauty-and-skincare': 1,
  'clothing-and-shoes': 2,
  'food-and-beverage': 3,
  'outdoor-and-recreation': 3,
  'health-and-wellness': 4,
  'home-and-garden': 5,
  other: 5,
  jewelry: 6,
  cbd: 6,
  'pet-products': 7,
  tech: 8,
  'toys-and-hobbies': 9,
  'personal-brand': 9,
};

const industry = computed(() => {
  return industryHash[props.selectedIndustry?.slug] || 0;
});

const carouselImages = [
  accessoriesAndEducationImage,
  beautyAndSkincareImage,
  clothingAndShoesImage,
  foodAndBeverageAndOutdoorAndRecreationImage,
  healthAndWellnessImage,
  homeAndGardenImage,
  jewelryAndCBDImage,
  petProductsImage,
  techImage,
  toysAndHobbiesAndPersonalBrandImage,
];
const icons = ['scissors', 'map', 'paint-roller', 'camera', 'resize'];
</script>
<template>
  <section class="industry-side-banner">
    <div class="industry-side-banner__header">
      <h1>content for all</h1>
      <div class="industry-side-banner__header__icon-list">
        <span
          v-for="(icon, index) in icons"
          :key="index"
          class="industry-side-banner__icon-container"
        >
          <SoonaIcon
            class="industry-side-banner__icon"
            :name="icon"
            size="x-large"
          />
        </span>
      </div>
    </div>
    <SoonaImageScroller
      class="industry-side-banner__carousel"
      :images="carouselImages"
      :active-image-index="industry"
      :visual-wrapping="true"
      image-gap="small"
    />
    <div class="industry-side-banner__footer">
      <p v-if="selectedIndustry" class="u-subheader--heavy">
        {{ selectedIndustry.title }} expert
      </p>
      <p class="industry-side-banner__footer__text">
        our team of content experts is available for a free consult to set your
        shoot up for success.
      </p>
    </div>
  </section>
</template>
<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.industry-side-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0rem 1rem 0rem;
  background-color: variables.$green-apple-20;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    &__icon-list {
      display: flex;
      gap: 0.5rem;
    }

    h1 {
      @include variables_fonts.u-title--heavy;
      text-wrap: nowrap;
      color: variables.$periwink-blue-60;
      text-align: center;
      padding: 0 1rem;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    &__text {
      @include variables_fonts.u-subheader--regular;
      text-align: center;
      line-height: 1.15;
      width: 80%;
    }
  }

  &__carousel {
    height: 10rem;
  }

  &__icon {
    color: variables.$white-default;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    background-color: variables.$periwink-blue-60;
    border-radius: 50%;
    line-height: 0;
    padding: 0.612rem;
  }
}

@media (min-width: variables.$screen-sm-min) {
  .industry-side-banner {
    gap: 2rem;
    height: 45rem;
    padding-top: 4rem;

    h1 {
      @include variables_fonts.u-display--heavy;
      font-size: 2.5rem;
    }

    &__carousel {
      height: 18rem;
    }
  }
}
</style>

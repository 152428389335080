<script setup>
import { computed, ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import { useInfiniteAlbumCollections } from '@/queries/album_collections/useAlbumCollections';
import { useRoute } from 'vue-router';
import { useStaffPicksCollection } from '@/queries/staff_picks_collection/useStaffPicksCollection';
import editsImage from '@images/edits.png';
import staffPicksImage from '@images/staff picks.png';
import CarouselLoading from '@/components/platform-home/CarouselLoading.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaAlbumCard from '@/components/ui_library/SoonaAlbumCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import NewAlbumDialog from './NewAlbumDialog.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

const emit = defineEmits(['album-selected']);
const { linkClicked } = useBaseEvents();
const route = useRoute();

const showAlbumDialog = ref(false);
const accountId = computed(() => props.accountId);

const { data: editsCollection, isPending: isEditsCollectionLoading } =
  useEditsCollection(accountId);
const { data: staffPicksCollection, isPending: isStaffPicksCollectionLoading } =
  useStaffPicksCollection(accountId);

const { data: albumCollections, isPending: isAlbumCollectionsLoading } =
  useInfiniteAlbumCollections(accountId, {
    itemsPerPage: 8,
    sortBy: 'date modified',
    sortDirection: 'desc',
  });

const formattedAlbumCollections = computed(
  () =>
    albumCollections.value?.pages[0]?.collections?.map(albumCollection => ({
      ...albumCollection,
      routerTo: {
        name: 'account-gallery',
        params: {
          accountId: accountId.value,
        },
        query: { collection_id: albumCollection.id },
      },
    })) ?? []
);

const editsCollectionWithFallbackImage = computed(() => {
  return {
    ...editsCollection.value,
    title: editsCollection.value.title.replace(/^edits$/, 'professional edits'),
    preview: { url: editsCollection.value?.preview?.url || editsImage },
    routerTo: {
      name: 'account-gallery',
      params: {
        accountId: accountId.value,
      },
      query: { collection_id: editsCollection.value?.id },
    },
  };
});

const staffPicksCollectionWithFallbackImage = computed(() => {
  return {
    ...staffPicksCollection.value,
    title: staffPicksCollection.value.title.replace(
      /^staff picks$/,
      'expert picks'
    ),
    preview: {
      url: staffPicksCollection.value?.preview?.url || staffPicksImage,
    },
    routerTo: {
      name: 'account-gallery',
      params: {
        accountId: accountId.value,
      },
      query: { collection_id: staffPicksCollection.value?.id },
    },
  };
});

const allCollections = computed(() => [
  editsCollectionWithFallbackImage.value || {},
  staffPicksCollectionWithFallbackImage.value || {},
  ...formattedAlbumCollections.value,
]);

const isLoading = computed(
  () =>
    isAlbumCollectionsLoading.value ||
    isEditsCollectionLoading.value ||
    isStaffPicksCollectionLoading.value
);

const trackClick = (label, href = null) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'albums',
    linkLabel: label,
    linkHref: href,
  });
};

const handleOpenDialog = () => {
  trackClick('new album');
  showAlbumDialog.value = true;
};

const handleCloseDialog = () => {
  trackClick('cancel');
  showAlbumDialog.value = false;
};

const albumSelected = albumId => {
  emit('album-selected', albumId);
};
</script>

<template>
  <section class="albums" data-cypress="section-albums">
    <div class="albums__heading">
      <h2
        id="albums"
        data-cypress="text-albums-heading"
        class="u-title--heavy albums__heading-title"
      >
        albums

        <SoonaButton
          size="medium"
          variation="icon-inherit"
          @on-click="handleOpenDialog"
        >
          <SoonaIcon name="circle-plus" />
          <span class="u-visually-hidden">create a new album</span>
        </SoonaButton>
      </h2>
      <SoonaButton
        element="router-link"
        :to="{ name: 'albums' }"
        variation="tertiary"
        size="medium"
        @on-click="trackClick('all albums', `/account/${accountId}/my-albums`)"
      >
        all albums <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </div>
    <CarouselLoading v-if="isLoading" />
    <PageContainerOffset v-else>
      <SoonaSlider
        tag-name="ul"
        aria-labelledby="albums"
        class="albums__slider"
      >
        <SoonaAlbumCard
          v-for="album in allCollections"
          :key="album.id"
          inner-element="router-link"
          :to="album.routerTo"
          :title="album.title"
          :thumbnail-url="album.preview?.url"
          :total-assets="album.total_assets"
          cypress-name="account-album-card"
          @click="albumSelected(album.id)"
        />
      </SoonaSlider>
    </PageContainerOffset>

    <NewAlbumDialog
      v-if="showAlbumDialog"
      :account-id="accountId"
      @close="handleCloseDialog"
    />
  </section>
</template>

<style lang="scss" scoped>
.albums {
  &__heading {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    justify-content: space-between;
  }

  &__heading-title {
    gap: 0.25rem;
    display: flex;
    align-items: center;
  }

  &__slider {
    // Album animation gets cutoff by overflow rules while on large devices
    @media (min-width: 90rem) {
      :deep(.soona-slider__content) {
        padding-left: 0.5rem;
        margin-left: -0.5rem;
      }
    }
  }
}
</style>

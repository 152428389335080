import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {string | Ref<string>} context
 */
export function useGetFeedbackOptions(context) {
  return useQuery({
    queryKey: queryKeys.feedbackOptions(context),
    queryFn: async ({ signal }) => {
      const response = await http.get(`/feedbacks/options/${unref(context)}`, {
        signal,
      });

      return response.data;
    },
  });
}

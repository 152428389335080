<template>
  <div
    class="FileMessage"
    :style="{ justifyContent: isOutgoing ? 'flex-end' : 'flex-start' }"
  >
    <ChatAvatar v-if="!isOutgoing" :message="message" />
    <a class="pdf-container" :href="message.data.url" target="_blank">
      <img src="@images/pdf.svg" alt="pdf icon" />
      <p>{{ message.data.attachments[0].name }}</p>
    </a>
    <ChatAvatar v-if="isOutgoing" :message="message" />
  </div>
</template>

<script>
import ChatAvatar from '../ChatAvatar.vue';

export default {
  name: 'FileMessage',
  components: {
    ChatAvatar,
  },
  props: {
    message: Object,
    isOutgoing: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.FileMessage {
  display: flex;
  padding: 0.75rem 0;
  .pdf-container {
    display: flex;
    margin: 0 5px;
    padding: 12px;
    max-width: 200px;
    border-radius: 10px;
    border: 0.5px solid #d6d8db;
    background: variables.$gray-10;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    p {
      margin-left: 12px;
      word-break: break-word;
    }
  }
}
</style>

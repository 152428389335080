<script setup>
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import AddTrackingNumber from 'src/components/user/anytime/shipping/AddTrackingNumber.vue';
import ReturnAddressModal from '@/components/user/anytime/inventory/return-address/ReturnAddressModal.vue';
import InventoryPicker from 'src/components/user/anytime/inventory/InventoryPicker.vue';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';

defineProps({
  accountId: {
    type: Number,
    required: false,
  },
  reservationId: {
    type: Number,
    required: false,
  },
});

// pso & return address
const store = useStore();

const packageToUpdate = computed(
  () => store.state.inventoryPackages.currentPackage?.id
);

const dialogs = reactive({
  inventoryPicker: false,
  returnAddress: false,
  trackingNumber: false,
});

const openReturnAddressClosePackageHandlingDialog = () => {
  dialogs.trackingNumber = false;
  dialogs.returnAddress = true;
};

const closeReturnAddressAndPackageHandlingDialog = () => {
  dialogs.trackingNumber = false;
  dialogs.returnAddress = false;
};

const openAddTrackingNumberModal = () => {
  dialogs.trackingNumber = true;
};

const closeAddTrackingNumberModal = () => {
  dialogs.trackingNumber = false;
};

const openInventoryPicker = () => {
  dialogs.inventoryPicker = true;
};

const closeInventoryPicker = () => {
  dialogs.inventoryPicker = false;
};
</script>

<template>
  <div>
    <SoonaDropdownMenu copy="add package" variation="secondary-black">
      <template #default="{ clickCapture, keydown, mouseover }">
        <SoonaDropdownMenuItem>
          <button
            role="menuitem"
            data-cypress="add-new-package-link"
            @click.capture="clickCapture"
            @click="openAddTrackingNumberModal"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            ADD NEW PACKAGE
          </button>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem>
          <button
            role="menuitem"
            @click.capture="clickCapture"
            @click="openInventoryPicker"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            ADD FROM ACCOUNT
          </button>
        </SoonaDropdownMenuItem>
      </template>
    </SoonaDropdownMenu>

    <AddTrackingNumber
      v-if="dialogs.trackingNumber"
      :reservation-id="reservationId"
      @open-return-address-close-tracking-number-dialog="
        openReturnAddressClosePackageHandlingDialog
      "
      @close-tracking-number-modal="closeAddTrackingNumberModal"
    />
    <ReturnAddressModal
      v-if="dialogs.returnAddress"
      :account-id="accountId"
      :package-to-update="packageToUpdate"
      @cancel="closeReturnAddressAndPackageHandlingDialog"
      @success="dialogs.returnAddress = false"
    />
    <InventoryPicker
      :account-id="accountId"
      :close-picker="closeInventoryPicker"
      :picker-is-open="dialogs.inventoryPicker"
      :reservation-id="reservationId"
    />
  </div>
</template>

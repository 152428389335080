import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getProServiceProducts } from '../api/products';

export function useGetProServiceProducts() {
  return useQuery({
    queryKey: queryKeys.proServiceProducts(),
    queryFn: ({ signal }) => getProServiceProducts(signal),
  });
}

import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { createCompetitor } from '@/api/competitors';

export function useCreateCompetitor(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => createCompetitor(unref(accountId), body),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.competitors(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(accountId),
        }),
      ]);
    },
  });
}

import { useQuery } from '@tanstack/vue-query';
import { getDefaultSubscriptionPaymentMethod } from '@/api/subscriptions';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

/**
 * @param {MaybeRef<string>} subscriptionId
 * @param {Object} [queryOptions]
 */
export function useDefaultSubscriptionPaymentMethod(
  subscriptionId,
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.defaultSubscriptionPaymentMethod(),
    queryFn: ({ signal }) =>
      getDefaultSubscriptionPaymentMethod(unref(subscriptionId), signal),
    ...queryOptions,
  });
}

<template>
  <figure class="interstitial">
    <figcaption
      class="interstitial__headline"
      data-cypress="caption-industry-interstital"
    >
      {{ headline }}
    </figcaption>
    <div class="interstitial__grid" aria-hidden="true">
      <div
        v-for="(image, index) in images"
        :key="'image' + index"
        class="interstitial__block"
      >
        <img
          :src="image[0]"
          :srcset="image[0] + ' 1x,' + image[1] + ' 2x'"
          alt=""
        />
      </div>
    </div>
  </figure>
</template>

<script>
export default {
  props: {
    headline: String,
    images: Array,
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.interstitial {
  width: 100%;
  max-width: 58rem;
  animation: interstitial-enter 0.3s both ease-out;

  &__headline {
    @include variables_fonts.u-headline--heavy;

    margin: 0 0 1.5rem;
    text-align: center;
    color: variables.$black-default;
  }

  &__grid {
    columns: 2 9.75rem;
  }

  &__block {
    break-inside: avoid-column;
    margin-bottom: 0.875rem;
    border-radius: 0.625rem;
    overflow: hidden;
    transform: translateZ(0);

    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 0.625rem;
      animation: image-slide-in 0.8s both cubic-bezier(0, 0.55, 0.45, 1);
    }

    &:nth-child(1) {
      background-color: variables.$green-apple-30;
      height: 34.933333vw;

      img {
        animation-delay: 100ms;
        transform: translateY(-100%);
      }
    }

    &:nth-child(2) {
      background-color: variables.$friendly-red-50;
      height: 41.066667vw;

      img {
        animation-delay: 0.64s;
        transform: translateY(100%);
      }
    }

    &:nth-child(3) {
      background-color: variables.$bubbletape-pink-30;
      height: 35.2vw;

      img {
        animation-delay: 0.6s;
        transform: translateX(-100%);
      }
    }

    &:nth-child(4) {
      background-color: variables.$tangerine-40;
      height: 46.933333vw;

      img {
        animation-delay: 280ms;
        transform: translateY(100%);
      }
    }

    &:nth-child(5) {
      background-color: variables.$periwink-blue-30;
      height: 29.6vw;

      img {
        animation-delay: 0.4s;
        transform: translateY(-100%);
      }
    }

    &:nth-child(6) {
      background-color: variables.$periwink-blue-40;
      height: 41.333333vw;

      img {
        animation-delay: 0.48s;
        transform: translateX(100%);
      }
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    justify-content: center;

    &__grid {
      columns: auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1.33272639fr 0.34003718fr 1.12979911fr;
      gap: 1.125rem 0.875rem;
      grid-template-areas:
        'top-left top-center top-right'
        'bottom-left top-center bottom-right'
        'bottom-left bottom-center bottom-right';
      height: 56.045082vw;
      max-height: 34.1875rem;
    }

    &__headline {
      @include variables_fonts.u-display--heavy;

      margin-bottom: 2.5rem;
    }

    &__block {
      height: auto !important;
      margin-bottom: 0;

      &:nth-child(1) {
        grid-area: top-left;
      }

      &:nth-child(2) {
        grid-area: bottom-left;
      }

      &:nth-child(3) {
        grid-area: top-right;
      }

      &:nth-child(4) {
        grid-area: top-center;
      }

      &:nth-child(5) {
        grid-area: bottom-center;
      }

      &:nth-child(6) {
        grid-area: bottom-right;
      }
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .interstitial {
    animation: none !important;

    &__block > img {
      animation: none !important;
      transform: translate(0, 0) !important;
    }
  }
}

@keyframes interstitial-enter {
  from {
    opacity: 0;
    transform: translateX(3em);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes image-slide-in {
  to {
    transform: translate(0, 0);
  }
}
</style>

<script setup>
import { computed } from 'vue';

import SoonaIcon from '../ui_library/soona_icon/SoonaIcon.vue';
import SoonaComboboxRoot from '../ui_library/soona_combobox/SoonaComboboxRoot.vue';
import SoonaComboboxButton from '../ui_library/soona_combobox/SoonaComboboxButton.vue';
import SoonaComboboxListbox from '../ui_library/soona_combobox/SoonaComboboxListbox.vue';
import SoonaComboboxOption from '../ui_library/soona_combobox/SoonaComboboxOption.vue';

const props = defineProps({
  activeOption: {
    type: String,
    default: null,
  },
  filter: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['filter-change']);

const activeOption = computed({
  get() {
    return props.activeOption;
  },
  set(option) {
    emit('filter-change', { [props.filter.type.value]: option });
  },
});

function clearFilter(event) {
  event.stopPropagation();
  activeOption.value = null;
}
</script>

<template>
  <div class="filter-by">
    <SoonaComboboxRoot v-model="activeOption">
      <template #trigger>
        <div class="filter-by__combobox-button-container">
          <SoonaComboboxButton
            :data-has-value="!!activeOption"
            class="filter-by__combobox-button"
            size="medium"
          >
            {{
              activeOption
                ? filter.options.find(opt => opt.value === activeOption).label
                : filter.type.label
            }}

            <SoonaIcon
              v-if="!activeOption"
              size="small"
              name="chevron-down"
              class="filter-by__button-chevron"
            />
          </SoonaComboboxButton>

          <button
            v-if="activeOption"
            title="clear filter"
            aria-label="clear filter"
            class="filter-by__clear-button u-button-reset"
            @click="clearFilter"
          >
            <SoonaIcon
              name="xmark"
              size="small"
              class="filter-by__clear-button--icon"
            />
          </button>
        </div>
      </template>

      <template #popover>
        <SoonaComboboxListbox>
          <SoonaComboboxOption
            v-for="option in filter.options"
            :key="option.value"
            :value="option.value"
            class="filter-by__combobox-option"
          >
            {{ option.label }}
          </SoonaComboboxOption>
        </SoonaComboboxListbox>
      </template>
    </SoonaComboboxRoot>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.filter-by {
  &__combobox-button-container {
    position: relative;
  }

  &__combobox-button {
    border-radius: 1.625rem;

    &[data-has-value='true'] {
      padding-right: 1.875rem;
    }

    &[aria-expanded='true'] {
      .filter-by__button-chevron {
        transform: rotate(180deg);
      }
    }

    @media (prefers-reduced-motion: no-preference) {
      .filter-by__button-chevron {
        transition: transform 0.2s;
      }
    }
  }

  &__clear-button {
    top: 50%;
    display: flex;
    right: 0.25rem;
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 50%;
    position: absolute;
    align-items: center;
    transform: translateY(-50%);

    &:hover {
      background: variables.$black-translucent-04;

      & .filter-by__clear-button--icon {
        color: variables.$periwink-blue-70;
      }
    }
  }
}
</style>

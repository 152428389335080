import { useQuery } from '@tanstack/vue-query';
import { unref } from 'vue';
import { getProratedAmount } from '@/api/subscriptions';
import { queryKeys } from '@/queries/query-keys';

/**
 * @param {Object} params
 * @param {number | Ref<string>} [params.items]
 */
export function useProratedSubscription(subscriptionId, priceId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.subscriptionProratedAmount(subscriptionId, priceId),
    queryFn: ({ signal }) =>
      getProratedAmount(unref(subscriptionId), unref(priceId), signal),
    gcTime: 0,
    ...queryOptions,
  });
}

<script setup>
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { computed } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';

import { FriendlyRed60, WhiteDefault } from 'src/variables.module.scss';

const props = defineProps({
  title: {
    required: true,
    type: String,
  },
  titleElement: {
    required: true,
    type: String,
  },
  priceInfo: {
    default: null,
    required: false,
    type: Object,
  },
  description: {
    required: true,
    type: String,
  },
  layout: {
    required: true,
    type: String,
  },
  showDescription: {
    default: true,
    type: Boolean,
  },
  hasMoreDetails: {
    required: true,
    type: Boolean,
  },
  handleOpenModal: {
    required: true,
    type: Function,
  },
});

const rate = computed(() => {
  return props.priceInfo?.rate;
});

const discountedRate = computed(() => {
  return props.priceInfo?.discounted_rate;
});

const percentDiscount = computed(() => {
  return props.priceInfo?.percent_discount;
});

const toCurrency = (value, currency = 'USD') => {
  if (!value) {
    return '';
  }
  const formatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};
</script>
<template>
  <div class="soona-tag-option-card__title-wrapper">
    <component
      :is="titleElement"
      v-if="title"
      class="soona-tag-option-card__title"
    >
      {{ title }}
    </component>
    <SoonaButton
      v-if="hasMoreDetails && layout === 'horizontal'"
      class="u-button-reset soona-tag-option-card__more-details"
      data-cypress="button-more-details"
      variation="tertiary"
      @on-click="handleOpenModal"
    >
      more details
    </SoonaButton>
  </div>
  <div v-if="priceInfo" class="soona-tag-option-card__title-wrapper">
    <div
      class="soona-tag-option-card__price"
      :class="[
        { 'soona-tag-option-card__price--vertical': layout === 'vertical' },
      ]"
    >
      <div
        v-if="discountedRate"
        class="soona-tag-option-card__price__discounted"
      >
        <span class="soona-tag-option-card__price__old">
          {{ toCurrency(rate) }}
        </span>
        <span class="soona-tag-option-card__price__new">
          {{ toCurrency(discountedRate) }}
        </span>
        <SoonaFlag
          :background-color="FriendlyRed60"
          :text-color="WhiteDefault"
          :title="`${percentDiscount}% off`"
          font-weight="bolder"
        />
      </div>
      <template v-else>
        {{ toCurrency(rate) }}
      </template>
      <span
        v-if="title.toLowerCase() === 'grocery shopping'"
        class="soona-tag-option-card__price-extra"
      >
        + cost of goods
      </span>
    </div>
  </div>
  <SoonaButton
    v-if="hasMoreDetails && layout === 'vertical'"
    class="u-button-reset soona-tag-option-card__more-details"
    data-cypress="button-more-details"
    variation="tertiary"
    @on-click="handleOpenModal"
  >
    more details
  </SoonaButton>
  <p
    v-if="description && showDescription"
    class="soona-tag-option-card__description"
    v-html="description"
  ></p>
</template>
<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.soona-tag-option-card__title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.soona-tag-option-card__title {
  @include variables_fonts.u-title--heavy;

  @media (min-width: variables.$screen-sm-min) {
    @include variables_fonts.u-headline--heavy;

    padding-bottom: 0.375rem;
  }
}

.soona-tag-option-card__more-details {
  @include variables_fonts.u-body--regular;

  justify-content: flex-start !important;
  position: relative !important;
  text-decoration: underline !important;

  &:hover,
  &:focus-visible {
    color: inherit !important;
    text-decoration: none !important;
  }
}

.soona-tag-option-card__price {
  @include variables_fonts.u-title--regular;
  padding-bottom: 0.375rem;

  &__discounted {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__old {
    text-decoration: line-through;
  }

  &__new {
    color: variables.$friendly-red-70;
    @include variables_fonts.u-title--heavy;
  }
}

.soona-tag-option-card__description {
  @include variables_fonts.u-body--regular;

  @media (min-width: variables.$screen-sm-min) {
    @include variables_fonts.u-subheader--regular;

    flex-grow: 1;
    padding-bottom: 1rem;
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaVimeoPlayer from '@/components/SoonaVimeoPlayer.vue';
import mediaEditorImage from 'images/media_editor_thumbnail@2x.jpg';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import { getHowItWorksVideoBySection } from '@/lib/vimeo-videos';

const props = defineProps({
  tier: {
    type: Object,
    required: true,
  },
  ctaCopy: {
    type: String,
    default: 'upgrade',
  },
});

const route = useRoute();
const { linkClicked } = useBaseEvents();

const showVideoOverlay = ref(false);
const showSubscriptionsDialog = ref(false);

const tier = computed(() => props.tier);

const handleLinkClicked = (label, href) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'subscription entrypoint banner',
    linkLabel: label,
    linkHref: href,
  });
};

const handleVideoOverlay = () => {
  showVideoOverlay.value = true;
  handleLinkClicked('play video', '#');
};

const videoDetails = getHowItWorksVideoBySection('mediaEditor');
</script>

<template>
  <aside class="me-subx-banner">
    <button
      class="u-button-reset me-subx-banner__video-img"
      title="watch video"
      @click="handleVideoOverlay"
    >
      <img :src="mediaEditorImage" alt="" />
    </button>
    <p class="u-subheader--heavy">
      <span class="u-badge--small me-subx-banner__lead-in">
        did you know you can
      </span>
      multiply your images & create on-brand content at scale
    </p>
    <div class="me-subx-banner__buttons">
      <SoonaButton
        variation="tertiary-white"
        class="me-subx-banner__learn-more"
        @click="
          () => {
            showSubscriptionsDialog = true;
            handleLinkClicked('learn more', '#');
          }
        "
      >
        learn more
      </SoonaButton>
      <SoonaButton
        variation="solid-black"
        data-cypress="button-subscription-upgrade"
        @on-click="
          () => {
            showSubscriptionsDialog = true;
            handleLinkClicked(ctaCopy, '#');
          }
        "
      >
        {{ ctaCopy }}
      </SoonaButton>
      <SoonaButton
        variation="secondary-transparent"
        class="me-subx-banner__watch-video"
        @on-click="handleVideoOverlay"
      >
        <SoonaIcon name="play-solid" size="medium" /> watch video
      </SoonaButton>
    </div>
  </aside>
  <SoonaDialog
    v-if="showVideoOverlay"
    max-width="48rem"
    @close="showVideoOverlay = false"
  >
    <template #heading>AI studio</template>
    <SoonaVimeoPlayer
      :vimeo-id="videoDetails?.videoId"
      :additional-params="videoDetails?.additionalParams"
      :show-close-x="false"
    />
  </SoonaDialog>
  <SubscriptionsDialog
    v-if="showSubscriptionsDialog"
    :selected-tier-slug="tier.slug"
    @close="showSubscriptionsDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.me-subx-banner {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background: variables.$gradient-periwink-error;
  border-radius: 0.625rem;
  padding: 1.25rem;
  color: variables.$white-default;

  &__lead-in {
    display: block;
    font-weight: 900;
    margin-bottom: 0.5rem;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
  }

  &__video-img,
  &__learn-more {
    display: none;
  }

  @media (min-width: variables.$screen-sm-min) {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    min-height: 6.75rem;
    padding: 1rem 1.5rem 1rem 1rem;
    background: url('~images/subscriptions/north_star.svg'),
      url('~images/subscriptions/north_star.svg'),
      variables.$gradient-periwink-error;
    background-repeat: no-repeat;
    background-position:
      bottom -1rem right 23%,
      top -0.25rem right 33%,
      0 0;
    background-size: 4.5rem, 3.125rem, auto;

    &__video-img {
      display: block;
      border-radius: 0.3125rem;
      box-shadow: variables.$elevation-1;
      max-width: 13.75rem;
      margin: -2.25rem 0;
      transition: transform 0.1s ease-out;

      img {
        display: block;
        border-radius: 0.3125rem;
      }

      &:hover,
      &:focus-visible {
        transform: scale(1.02);
        box-shadow: variables.$elevation-2;
      }
    }

    &__buttons {
      flex-shrink: 0;
      flex-wrap: nowrap;
      margin-left: auto;
      gap: 1.25rem;

      > button {
        flex-shrink: 0;
      }
    }

    &__learn-more {
      display: inline-flex;
    }

    &__watch-video {
      display: none;
    }
  }

  @media (min-width: variables.$screen-lg-min) {
    gap: 1.5rem;
    padding: 1rem 2rem 1rem 1.5rem;
  }
}
</style>

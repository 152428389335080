<template>
  <div class="StatusMessage">
    <p
      v-if="
        status !== 'in_progress' &&
        status !== 'preshoot' &&
        status !== 'pending_shotlist'
      "
      class="disabled-msg"
    >
      your session is now complete. if you need something please email us at
      hey@soona.co
    </p>
    <p
      v-if="status === 'preshoot' || status === 'pending_shotlist'"
      class="u-small--regular disabled-msg"
    >
      your session is coming up soon! if you need something before then please
      email us at hey@soona.co
    </p>
    <p v-if="!isChatOn" class="u-small--regular disabled-msg">
      chat is currently unavailable! if you need assistance please email us at
      hey@soona.co
    </p>
  </div>
</template>

<script>
export default {
  name: 'StatusMessage',
  props: {
    status: String,
    isChatOn: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.StatusMessage {
  .disabled-msg {
    padding: 1em;
    border-radius: 0.4375rem;
    color: variables.$gray-50;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import AcceptedIcon from 'src/components/svgs/AcceptedIcon.vue';
import PendingIcon from 'src/components/svgs/PendingIcon.vue';

const props = defineProps({
  crewAssignments: {
    type: Array,
    required: true,
  },
  modelRequirements: {
    type: Array,
    required: true,
  },
  purchasedProServices: {
    type: Array,
    required: true,
  },
});

function requirementChoicesMatchAssingment(req, assignment) {
  return (
    req.id === assignment.pro_service_requirement_id &&
    (req.pro_service_requirement_choices?.some(
      choice =>
        choice.pro_service_profile.id === assignment.pro_service_profile?.id
    ) ||
      req.service_tag.title === assignment.crew_role)
  );
}

const requirementsWithAssignmentAccepted = computed(() => {
  return props.modelRequirements?.filter(req =>
    props.crewAssignments?.some(
      assignment =>
        assignment.status === 'accepted' &&
        requirementChoicesMatchAssingment(req, assignment)
    )
  );
});

const requirementsWithPendingAssignment = computed(() => {
  return props.modelRequirements?.filter(
    req =>
      !requirementsWithAssignmentAccepted.value?.some(
        accepted => accepted.id === req.id
      ) &&
      props.crewAssignments?.some(
        assignment =>
          assignment.status === 'sent' &&
          requirementChoicesMatchAssingment(req, assignment)
      )
  );
});

const numPurchasedCustomManicureServices = computed(() => {
  return props.purchasedProServices.reduce((total, service) => {
    return total + (service.name === 'manicure custom' ? service.quantity : 0);
  }, 0);
});

const numPurchasedCustomPedicureServices = computed(() => {
  return props.purchasedProServices.reduce((total, service) => {
    return total + (service.name === 'pedicure custom' ? service.quantity : 0);
  }, 0);
});

const numPurchasedPressOnManicureServices = computed(() => {
  return props.purchasedProServices.reduce((total, service) => {
    return (
      total + (service.name === 'manicure press on' ? service.quantity : 0)
    );
  }, 0);
});

const numPurchasedHairAndMakeupServices = computed(() => {
  return props.purchasedProServices.reduce((total, service) => {
    return total + (service.name === 'hair and makeup' ? service.quantity : 0);
  }, 0);
});

const numRequestedCustomManicureServices = computed(() => {
  return props.modelRequirements.reduce((total, req) => {
    return (
      total +
      (req.pro_service_extras?.manicure?.selection === 'manicure custom'
        ? 1
        : 0)
    );
  }, 0);
});

const numRequestedCustomPedicureServices = computed(() => {
  return props.modelRequirements.reduce((total, req) => {
    return (
      total +
      (req.pro_service_extras?.pedicure?.selection === 'pedicure custom'
        ? 1
        : 0)
    );
  }, 0);
});

const numRequestedPressOnManicureServices = computed(() => {
  return props.modelRequirements.reduce((total, req) => {
    return (
      total +
      (req.pro_service_extras?.manicure?.selection === 'manicure press on'
        ? 1
        : 0)
    );
  }, 0);
});

const numRequestedHairAndMakeupServices = computed(() => {
  return props.modelRequirements.reduce((total, req) => {
    return total + (req.pro_service_extras?.hmu?.selection === 'yes' ? 1 : 0);
  }, 0);
});

const showModificationsSection = computed(() => {
  return (
    numRequestedHairAndMakeupServices.value > 0 ||
    numRequestedPressOnManicureServices.value > 0 ||
    numRequestedCustomManicureServices.value > 0 ||
    numRequestedCustomPedicureServices.value > 0
  );
});
</script>

<template>
  <div class="ProServiceSelectionActionOverview">
    <div class="model-status-section">
      <div v-if="modelRequirements.length > 0" class="models-section">
        <AcceptedIcon />
        <p>
          {{ requirementsWithAssignmentAccepted.length }} of
          {{ modelRequirements.length }} accepted model
        </p>
      </div>
      <div
        v-if="requirementsWithPendingAssignment.length > 0"
        class="models-section"
      >
        <PendingIcon />
        <p>{{ requirementsWithPendingAssignment.length }} pending model</p>
      </div>
    </div>
    <div v-if="showModificationsSection" class="all-modifications-section">
      <div
        v-if="numRequestedCustomManicureServices > 0"
        class="modification-section"
      >
        <p class="modification-emoji">💅</p>
        <p>
          {{ numPurchasedCustomManicureServices }} of
          {{ numRequestedCustomManicureServices }} custom manicures
        </p>
      </div>
      <div
        v-if="numRequestedPressOnManicureServices > 0"
        class="modification-section"
      >
        <p class="modification-emoji">💅</p>
        <p>
          {{ numPurchasedPressOnManicureServices }} of
          {{ numRequestedPressOnManicureServices }} press on manicures
        </p>
      </div>
      <div
        v-if="numRequestedCustomPedicureServices > 0"
        class="modification-section"
      >
        <p class="modification-emoji">💅</p>
        <p>
          {{ numPurchasedCustomPedicureServices }} of
          {{ numRequestedCustomPedicureServices }} custom pedicures
        </p>
      </div>
      <div
        v-if="numRequestedHairAndMakeupServices > 0"
        class="modification-section"
      >
        <p class="modification-emoji">👩‍🎤</p>
        <p>
          {{ numPurchasedHairAndMakeupServices }} of
          {{ numRequestedHairAndMakeupServices }} hair and makeup
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.ProServiceSelectionActionOverview {
  display: flex;
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: 0.156rem;
  gap: 8rem;
  text-transform: uppercase;
  .model-status-section {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    .models-section {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
  .all-modifications-section {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    .modification-section {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      .modification-emoji {
        height: 1.125rem;
        width: 1.125rem;
      }
    }
  }
}
@media only screen and (max-width: variables.$screen-xs-max) {
  .ProServiceSelectionActionOverview {
    flex-direction: column;
    align-items: left;
    gap: 0.75rem;
  }
}
</style>

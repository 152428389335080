<script setup>
import { ref, onMounted, computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  paymentOptions: {
    type: Array,
    required: true,
  },
  paymentMethodTypes: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['addPaymentMethod', 'selectedPaymentMethod']);
const paymentMethodTypes = computed(() => {
  return props.paymentMethodTypes;
});

const paymentOptions = computed(() => {
  return props.paymentOptions.filter(option => {
    return paymentMethodTypes.value.includes(option.payment_method_type);
  });
});

const selectedOption = ref('');

const selectOption = option => {
  selectedOption.value = option.external_payment_method_id;
  emit('selectedPaymentMethod', option);
};

const brand = paymentMethod => {
  if (paymentMethod.payment_method_type === 'card') {
    return `${paymentMethod?.payment_method_data?.brand} credit card`;
  }
  if (paymentMethod.payment_method_type === 'us_bank_account') {
    return `${paymentMethod?.payment_method_data?.bank_name} bank account`;
  }
};

const paymentMethodType = paymentMethod => {
  if (paymentMethod.payment_method_type === 'card') {
    return 'credit-card';
  }
  if (paymentMethod.payment_method_type === 'us_bank_account') {
    return 'bank-account';
  }
};

onMounted(() => {
  const defaultOption = paymentOptions.value.find(
    option => option.default === true
  );
  if (defaultOption) {
    selectedOption.value = defaultOption.external_payment_method_id;
  } else if (paymentOptions.value.length > 0) {
    selectedOption.value = paymentOptions.value[0].external_payment_method_id;
  }
});
</script>

<template>
  <div>
    <div
      v-for="(option, index) in paymentOptions"
      :key="index"
      :class="[
        'payment-option',
        { selected: selectedOption === option.external_payment_method_id },
      ]"
      @click="selectOption(option)"
    >
      <div class="icon-container">
        <SoonaIcon
          :name="paymentMethodType(option)"
          size="medium"
          class="icon"
        />
      </div>
      <div class="details">
        <div class="type">{{ brand(option) }}</div>
        <div class="number">
          **** **** **** {{ option?.payment_method_data?.last4 }}
        </div>
      </div>

      <SoonaIcon
        v-if="selectedOption === option.external_payment_method_id"
        name="circle-check-solid"
        size="medium"
        class="check"
      />
      <SoonaIcon v-else name="circle" size="medium" class="unchecked" />
    </div>
    <SoonaButton
      class="add-payment-method"
      variation="tertiary"
      element="button"
      @click="emit('addPaymentMethod')"
    >
      + add payment method
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.payment-option {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  margin-bottom: 12px;
  background-color: #ffffff;
  cursor: pointer;
  transition:
    border-color 0.3s,
    background-color 0.3s;
}

.add-payment-method {
  color: variables.$periwink-blue-60;
  margin-top: 0.3rem;
  text-decoration: none;
}

.payment-option.selected {
  background-color: variables.$periwink-blue-20;
  border: 2px solid variables.$periwink-blue-60;
  .check {
    color: #3042d3;
    height: 1.5rem;
    width: 1.5rem;
  }
}

.payment-option {
  .check {
    color: #717171;
  }
}

.unchecked {
  color: #71727f;
  height: 1.5rem;
  width: 1.5rem;
}

.icon-container {
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: variables.$periwink-blue-60;
  border: 1px solid #c9d0fc;
  background-color: #ffffff;
  border-radius: 50%;
  width: 3.1rem;
  height: 3.1rem;
}
.icon {
  font-size: 24px;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: variables.$periwink-blue-60;
}

.icon svg {
  color: #3042d3;
}

.details {
  flex: 1;
}

.type {
  font-size: 14px;
  font-weight: 800;
  color: #333;
}

.number {
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
}
</style>

import { toValue, unref } from 'vue';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import {
  getShotList,
  getActiveShot,
  setActiveShot,
  createShot,
  updateShot,
} from '../api/shot_list';
import { queryKeys } from './query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<number>} reservationId
 * @param {UseQueryOptions} [queryOptions]
 */
export function useShotList(reservationId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.reservationShotList(reservationId),
    queryFn: ({ signal }) => getShotList(unref(reservationId), signal),
    ...queryOptions,
  });
}

export function useActiveShot(reservationId) {
  return useQuery({
    queryKey: queryKeys.reservationActiveShot(reservationId),
    queryFn: ({ signal }) => getActiveShot(unref(reservationId), signal),
  });
}

/**
 *
 * @param {number | Ref<number>} reservationId
 * @param {number | Ref<number>} shotId
 * @param {UseQueryOptions} [queryOptions]
 */
export function useShot(reservationId, shotId, queryOptions = undefined) {
  return useQuery({
    queryKey: queryKeys.reservationShot(reservationId, shotId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/reservations/${toValue(reservationId)}/shots/${toValue(shotId)}.json`,
        { signal }
      );

      if (response.status === 204) {
        return null;
      }

      return response.data;
    },
    ...queryOptions,
  });
}

export function useSetActiveShot(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => setActiveShot(unref(reservationId), body),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationActiveShot(reservationId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationShotList(reservationId),
        }),
      ]);
    },
  });
}

export function useCreateShot(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => createShot(unref(reservationId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservationShots(reservationId),
      });
    },
  });
}

/**
 *
 * @param {Ref<number> | number} reservationId
 * @returns {UseMutationReturnType}
 */
export function useDeleteShot(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ shotId }) => {
      await http.delete(
        `/reservations/${reservationId.value}/shots/${shotId}.json`
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservationShots(reservationId),
      });
    },
  });
}

/**
 *
 * @param {Ref<number> | number} reservationId
 * @returns {UseMutationReturnType}
 */
export function useUpdateShot(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => updateShot(unref(reservationId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservationShots(reservationId),
      });
    },
  });
}

/**
 *
 * @param {import('vue').MaybeRefOrGetter<string | number>} reservationId
 */
export function useSetShotListOrder(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.put(
        `/reservations/${toValue(reservationId)}/shots/order`,
        body
      );

      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservationShots(reservationId),
      });
    },
  });
}

<script setup>
import { computed } from 'vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  unsubmittedReEdits: {
    type: Array,
  },
  fontSize: {
    type: String,
    default: 'normal',
    validator: value => {
      return ['small', 'normal'].includes(value);
    },
  },
});

const route = useRoute();

const fontSize = computed(() => props.fontSize);

const fontClasses = {
  small: 'u-label--regular',
  normal: 'u-body--regular',
};

const fontClass = computed(() => {
  return fontClasses[fontSize.value] ?? fontClasses.normal;
});

const isGallery = computed(() => {
  return route.name === 'gallery';
});

const isCurrentReservation = reEdit => {
  const reservationId =
    reEdit.options?.reservation?.id || reEdit.reservation_id;
  return Number(route.params.reservationId) === reservationId;
};
</script>
<template>
  <SoonaAlert
    v-for="reEdit in unsubmittedReEdits"
    :key="reEdit.id"
    class="re-edit-alert"
  >
    <div class="re-edits-message">
      <p :class="fontClass">
        your re-edit request has not been submitted for
        <strong>{{
          reEdit.reservation_name || reEdit.options?.reservation?.title
        }}</strong
        >. re-edit requests are submitted automatically after 6 hours.
      </p>
      <router-link
        v-if="!isGallery || (isGallery && !isCurrentReservation(reEdit))"
        :to="`/reservation/${
          reEdit.options?.reservation?.id || reEdit.reservation_id
        }/`"
        class="u-small--regular re-edits-view"
      >
        view request
      </router-link>
    </div>
  </SoonaAlert>
</template>

<style lang="scss" scoped>
@use '@/variables';

.re-edit-alert {
  max-width: 75rem;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  .re-edits-message {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
  }

  .re-edits-view {
    white-space: nowrap;
  }

  @media (min-width: 48rem) {
    .re-edits-message {
      flex-wrap: nowrap;
    }
  }

  @media (min-width: 64rem) {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }
}

:deep(.soona-alert) {
  align-items: center;
}
</style>

import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default function useSelectCompetitor(
  listingId,
  integrationId,
  accountId
) {
  const router = useRouter();
  const pickerIsOpen = ref(false);

  function select(competitor) {
    pickerIsOpen.value = false;
    const query = {
      listing: listingId.value,
    };
    if (competitor.example_staff_pick) {
      query.example_competitor = competitor.id;
    } else {
      query.competitor = competitor.id;
      query.integration = integrationId.value;
    }
    router.push({
      name: 'competitor-compare',
      params: {
        accountId: accountId.value,
      },
      query,
    });
  }

  return { select, pickerIsOpen };
}

<script setup>
import { ref, computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaVimeoPlayer from '@/components/SoonaVimeoPlayer.vue';
import competitiveAnalysisImage from 'images/integration-banner_competitive-analysis.jpg';
import listingInsightsImage from 'images/integration-banner_listing-insights.jpg';
import {
  amazonAuthorizationRedirectUrl,
  shopifyAppUrl,
} from '@/lib/integrations/constants';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useFlag } from '@/composables/useFlag';
import { getHowItWorksVideoBySection } from '@/lib/vimeo-videos';

const props = defineProps({
  section: {
    required: true,
    type: String,
  },
  marginBottom: {
    type: String,
    default: '1.9375rem',
  },
});

const integrations = [
  {
    section: 'products',
    integrations: ['amazon', 'shopify'],
  },
  {
    section: 'listingInsights',
    integrations: ['amazon', 'shopify'],
  },
  {
    section: 'competitiveAnalysis',
    integrations: ['amazon'],
  },
];
const urlSources = {
  amazon: amazonAuthorizationRedirectUrl,
  shopify: shopifyAppUrl,
};

// segment events
const route = useRoute();
const { linkClicked } = useBaseEvents();

const showVideoOverlay = ref(false);

const actualSection = computed(() => props.section);
const availableIntegrations = computed(
  () =>
    integrations.find(item => item.section === actualSection.value)
      .integrations ?? []
);
const videoThumb = computed(() => {
  if (actualSection.value === 'listingInsights') return listingInsightsImage;

  return competitiveAnalysisImage;
});

const buttonLabel = integration =>
  `connect ${integration.charAt(0).toUpperCase()}${integration.substring(1)}${
    actualSection.value === 'products' ? ' store' : ''
  }`;
const trackLinkClicked = (integration, href) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'integration banner',
    linkLabel: `connect ${integration}`,
    linkHref: href,
  });
};
const showWindowOverlay = () => {
  showVideoOverlay.value = true;
  trackLinkClicked('play video', '#');
};

const integrationBannerFlag = useFlag('apollo_integration_banner');

const videoDetails = computed(() => {
  return actualSection.value === 'listingInsights' ||
    actualSection.value === 'competitiveAnalysis'
    ? getHowItWorksVideoBySection(actualSection.value)
    : null;
});
</script>
<template>
  <div v-if="integrationBannerFlag" class="integrations-banner__container">
    <div class="integrations-banner__wrapper">
      <div class="integrations-banner__content">
        <h2 class="u-headline--heavy">
          <slot name="headline" />
        </h2>
        <p class="u-body--regular">
          <slot name="details" />
        </p>
        <div class="integrations-banner__buttons-container">
          <SoonaButton
            v-for="integration in availableIntegrations"
            :key="integration"
            element="a"
            :href="urlSources[integration]"
            variation="secondary-gray"
            @click="trackLinkClicked(integration, urlSources[integration])"
          >
            <SoonaIcon :name="`${integration}-logo`" />
            {{ buttonLabel(integration) }}
            <SoonaIcon name="arrow-right" />
          </SoonaButton>
        </div>
      </div>
      <button
        class="u-button-reset integrations-banner__video"
        @click="showWindowOverlay"
      >
        <img :src="videoThumb" />
      </button>
    </div>
  </div>
  <SoonaDialog
    v-if="showVideoOverlay"
    max-width="48rem"
    @close="showVideoOverlay = false"
  >
    <template #heading>how it works</template>
    <SoonaVimeoPlayer
      :vimeo-id="videoDetails?.videoId"
      :additional-params="videoDetails?.additionalParams"
      :show-close-x="false"
    />
  </SoonaDialog>
</template>
<style scoped lang="scss">
@use '@/variables';

.integrations-banner {
  &__container {
    background: variables.$gradient-pink-periwink;
    border-radius: 0.5rem;
    container: integrations-banner / inline-size;
    padding: 1rem;
    margin-bottom: v-bind('marginBottom');

    &.subscriber {
      background: variables.$gradient-periwink-error;
    }
  }

  &__wrapper {
    padding: 3.5rem 2.25rem;
    background: variables.$white-default;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    display: flex;
    gap: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    h2 {
      padding-bottom: 0.25rem;
    }

    p {
      max-width: 33.75rem;
      margin-top: 0.4375rem;
      flex-grow: 1;
    }
  }
  &__video {
    display: none;
    width: 19.3125rem;

    @media (min-width: variables.$screen-sm-min) {
      display: block;
    }

    img {
      border-radius: 0.5rem;
    }
  }

  &__buttons-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
  }

  .competitors-dashboard & {
    &__content {
      p {
        padding: 0.4375rem 0 1.5rem;
      }
    }
  }

  .listings-dashboard & {
    &__wrapper {
      padding: 2.8125rem 2.25rem;
    }

    &__content {
      margin: 0.6875rem 0;
    }
  }

  .products-page & {
    &__wrapper {
      padding: 4.625rem;
      text-align: center;

      @media (max-width: variables.$screen-xs-max) {
        padding: 2rem;
      }
    }
    &__content {
      p {
        max-width: 28.75rem;
        margin: auto;
      }
    }
    &__video {
      display: none;
    }

    &__buttons-container {
      gap: 1.375rem;
      padding-top: 3rem;
      justify-content: center;

      & .soona-button {
        padding: 1.5625rem 5rem 1.6875rem;

        @media (max-width: variables.$screen-sm-max) {
          padding: 1.5625rem 2rem 1.6875rem;
          flex-direction: column;
        }

        > :nth-child(1) {
          display: flex;
          flex-wrap: wrap;

          > :nth-child(1) {
            flex-grow: 1;
            height: 3rem;
            width: 100%;
          }

          > :nth-child(2) {
            display: block;
            content: 'store';
          }
        }
      }
    }
  }

  @container integrations-banner (min-width: 53rem) {
    &__buttons-container {
      flex-direction: row;
      padding-bottom: 0;
    }
  }
}
</style>

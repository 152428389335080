import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { getReservationReadinessSteps } from '../../api/reservation_readiness_steps';

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useReservationReadinessSteps(reservationId) {
  return useQuery({
    queryKey: queryKeys.reservationReadinessSteps(reservationId),
    queryFn: ({ signal }) =>
      getReservationReadinessSteps(unref(reservationId), signal),
  });
}

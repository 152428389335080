<script setup>
import { ref, computed } from 'vue';
import SoonaDialog from 'src/components/ui_library/SoonaDialog.vue';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import ProServiceTypePill from 'src/components/directory/ProServiceTypePill.vue';
import MediaTypeToggle from 'src/components/directory/MediaTypeToggle.vue';
import accountImage from 'images/account-placeholder.svg';
import SoonaButton from '../ui_library/SoonaButton.vue';
import { useBookingEvents } from '@/composables/segment/useBookingEvents';
import { useCreateReservation } from '@/queries/useCreateReservation';
import { useRouter, useRoute } from 'vue-router';
import { useFlag } from '@/composables/useFlag';
import { useMe } from '@/composables/user/useMe';
import SoonaError from '../ui_library/SoonaError.vue';
import SoonaLoading from '../SoonaLoading.vue';

const props = defineProps({
  profile: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const router = useRouter();
const route = useRoute();
const { currentAccountId } = useMe();
const { isPending, mutate } = useCreateReservation();
const defaultV2Flag = useFlag('default_v2_booking_flow');
const defaultV3Flag = useFlag('default_v3_booking_flow');
const { bookingStarted } = useBookingEvents();

const flowToUse = computed(() => {
  if (defaultV3Flag.value) {
    return 'default_v3';
  } else if (defaultV2Flag.value) {
    return 'default_v2';
  } else {
    return 'default_v1';
  }
});

const mediaType = ref(null);

const profile = computed(() => props.profile);

const profileImage = computed(
  () => profile.value?.primary_image_url || accountImage
);

const serviceTypeParam = computed(() => {
  return profile.value?.service_type?.replace(/ /g, '_');
});

const reservationData = computed(() => {
  return {
    account_id: currentAccountId.value,
    reservation_line_items: [],
    reservation_tags: [],
    use_primary_location: true,
    reservation_type: 'anytime',
    booking_flow: flowToUse?.value,
    pro_service_requirement_choices_attributes: [
      {
        pro_service_profile_id: profile.value.id,
        source: 1,
      },
    ],
  };
});

const reservationValid = computed(() => !!mediaType.value);
const errorMessage = ref(null);

const redirectToNextStep = (reservation, nextStep) => {
  router.push({
    path: `/booking/${reservation.id}/${nextStep}`,
    query: {
      pro_service: serviceTypeParam.value,
    },
    replace: true,
  });

  bookingStarted({
    context: 'booking',
    subContext: nextStep.replace(/-/g, ' '),
    path: route.path,
    reservation: reservation,
  });
};

const createReservationAndRedirect = () => {
  if (!reservationValid.value) {
    errorMessage.value = 'Please select a media type';
    return;
  }
  errorMessage.value = null;

  mutate(
    {
      reservation: reservationData.value,
      media_type_title: mediaType.value,
    },
    {
      onSuccess: reservation => {
        const nextStep = reservation.steps.find(
          s => !s.complete && s.slug !== 'industry'
        )?.slug;
        redirectToNextStep(reservation, nextStep);
      },
    }
  );
};
</script>
<template>
  <SoonaDialog class="book-model-dialog" @close="emit('close')">
    <template #heading>build a shoot</template>
    <div class="book-model-dialog__body">
      <SoonaLoading v-if="isPending" is-loading is-contained />
      <div class="book-model-dialog__model-info">
        <div class="book-model-dialog__model-info__img-container">
          <img :src="profileImage" alt="" />
          <ProServiceTypePill
            class="book-model-dialog__model-info__service-type"
            :pro-service-type="profile.service_type"
          />
        </div>
        <p class="u-subheader--heavy">{{ profile.provider_name }}</p>
        <p class="u-title--heavy">book this model</p>
        <p class="u-body--regular book-model-dialog__body__instructions">
          after you build your shoot we’ll confirm the date and time to ensure
          you can work with your chosen model.
        </p>
      </div>
      <SoonaForm
        class="book-model-dialog__form"
        @submit="createReservationAndRedirect"
      >
        <MediaTypeToggle
          :selected-media-type="mediaType"
          @change="mediaType = $event"
        />
        <SoonaError v-if="errorMessage">
          {{ errorMessage }}
        </SoonaError>
        <SoonaButton
          type="submit"
          :disabled="!reservationValid"
          variation="solid-black"
        >
          build a shoot
        </SoonaButton>
      </SoonaForm>
    </div>
  </SoonaDialog>
</template>
<style scoped lang="scss">
@use '@/variables';

.book-model-dialog {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    &__instructions {
      text-align: center;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  &__model-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 0.75rem;

    &__img-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
    }
    img {
      width: 10rem;
      object-fit: cover;
      border-radius: 1rem;
    }
    &__service-type {
      position: absolute;
      bottom: 1rem;
    }
  }

  &__toggle-container {
    background-color: variables.$gray-30;
    border-radius: 0.625rem;
    display: flex;
    padding: 0.25rem;
    width: max-content;
  }

  &__toggle-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.5rem 2rem;
    width: max-content;

    input:checked + & {
      background-color: variables.$black-default;
      border-radius: 0.625rem;
      color: variables.$white-default;
    }
  }
}

@media (min-width: variables.$screen-sm-min) {
  .book-model-dialog {
    &__body {
      gap: 2rem;
    }

    &__form {
      gap: 1.5rem;
    }
  }
}
</style>

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useSumbitShotList(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await http.put(
        `/reservations/${toValue(reservationId)}/submit_shotlist.json`
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservation(reservationId),
      });
    },
  });
}

<script setup>
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  context: {
    type: String,
  },
  subContext: {
    type: String,
  },
  fullPage: {
    type: Boolean,
    default: false,
  },
});

const { linkClicked } = useBaseEvents();

const linkHref = 'https://soona.co/contact-sales';
const linkLabel = 'connect with an expert';

const trackChatClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
};
</script>

<template>
  <aside
    class="chat-banner"
    :class="{
      'chat-banner--full-page': fullPage,
    }"
  >
    <div>
      <h3 :class="fullPage ? 'u-body--heavy' : 'u-headline--heavy'">
        looking for an enterprise solution?
      </h3>
      <p class="u-body--regular">
        soona has custom packages for larger companies to get the best price on
        all of our offerings.
      </p>
    </div>
    <SoonaButton
      element="a"
      :href="linkHref"
      target="_blank"
      rel="noopener noreferrer"
      variation="solid-black"
      @on-click="trackChatClick"
    >
      {{ linkLabel }}
    </SoonaButton>
  </aside>
</template>

<style lang="scss" scoped>
@use '@/variables';

.chat-banner {
  align-items: center;
  border-radius: 0.625rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem 1.5rem 1rem 1.25rem;
  background-color: transparent;
  color: variables.$white-default;

  &--full-page {
    padding: 1rem 0;
  }
}
</style>

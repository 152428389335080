// This file contains any user and account info we will use with with external providers/vendors
import { ulid } from 'ulid';
import { getCookie } from 'src/lib/global/storage';

export function getAnonymousId() {
  let anonymousId;
  if (window.analytics?.user) {
    anonymousId = window.analytics.user().anonymousId();
  } else if (getCookie('ajs_anonymous_id').length > 0) {
    anonymousId = getCookie('ajs_anonymous_id');
  } else {
    anonymousId = ulid();
    if (window.analytics?.setAnonymousId) {
      window.analytics.setAnonymousId(anonymousId);
    }
  }

  return anonymousId;
}

export function getGlobalUserId(user) {
  return user?.ulid || getAnonymousId();
}

export function getGlobalAttrs(user, account, parseEmptyFields) {
  let traits = {
    id: getGlobalUserId(user),
    accountId: account?.ulid,
    bookSoonaUserId: user?.id,
    bookSoonaAccountId: account?.id,
    email: user?.email,
    name: user?.name,
    employee: !!user?.employee_type_id,
    provider: user?.provider,
    subscriber: account?.subscription?.subscriber,
    subscriptionStatus: account?.subscription?.status,
    subscriptionTier: account?.subscription?.tier,
  };

  if (parseEmptyFields) {
    return Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(traits).filter(([_, v]) => v != null)
    );
  }

  return traits;
}

export function getEnvHostname() {
  let hostname = import.meta.env.VITE_HOSTNAME;

  if (!hostname || /soona-[a-z]+\.ngrok\.io/.test(hostname)) {
    return 'local';
  } else {
    return hostname;
  }
}

<template>
  <CompleteProfileCard
    class="brand-color"
    :disabled="disabled"
    @next="saveBrandColor"
    @skip="skipCard"
  >
    <template #heading>what are your brand colors?</template>
    <template #subheading>
      enter your brand colors as
      <SoonaButton
        element="a"
        href="https://www.google.com/search?q=color+picker"
        target="_blank"
        rel="noopener noreferrer"
        size="small"
        variation="tertiary"
      >
        hex
      </SoonaButton>
      codes below
    </template>
    <fieldset class="field-wrapper">
      <legend class="u-visually-hidden">brand colors</legend>
      <SoonaTextfield
        v-model="primary"
        class="brand-input"
        label="primary"
        name="primary"
        placeholder="#f0562e"
      />
      <SoonaTextfield
        v-model="secondary"
        class="brand-input"
        label="secondary"
        name="secondary"
        placeholder="#5566ea"
      />
      <SoonaTextfield
        v-model="other"
        class="brand-input"
        label="add the rest separated by commas"
        name="other"
        placeholder="#e470e6, #508b18"
      />
    </fieldset>
  </CompleteProfileCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'BrandColor',
  components: { CompleteProfileCard, SoonaButton, SoonaTextfield },
  data() {
    return {
      primary: '',
      secondary: '',
      other: '',
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    disabled() {
      return !this.primary && !this.secondary && !this.other;
    },
  },
  watch: {
    account: function () {
      this.prefillExistingData();
    },
  },
  mounted() {
    this.prefillExistingData();
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    prefillExistingData() {
      this.primary = this.account.profile_data?.brand_colors?.data.primary;
      this.secondary = this.account.profile_data?.brand_colors?.data.secondary;
      this.other = this.account.profile_data?.brand_colors?.data.other;
    },
    async saveBrandColor() {
      let info = {
        brand_colors: {
          data: {
            primary: this.primary,
            secondary: this.secondary,
            other: this.other,
          },
          complete: true,
        },
      };
      await this.updateAccount({ accountParams: info });
    },
    async skipCard() {
      let info = {
        brand_colors: {
          skip: true,
        },
      };
      try {
        await this.updateAccount({ accountParams: info });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(
            new Error('Updating account brand colors failed'),
            {
              extra: { error },
            }
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-color {
  .field-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }

  .brand-input {
    padding-bottom: 0.5rem;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useReservation } from 'src/queries/useReservation';
const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: false,
  },
});
const route = useRoute();
const { linkClicked } = useBaseEvents();

const reservationId = computed(() => props.reservationId);

const { data: reservation, isLoading } = useReservation(reservationId, {
  enabled: computed(() => reservationId.value !== null),
});

const clientUpgradable = computed(() => {
  return reservation.value?.scheduled_reservation_date === null;
});

const title = computed(() => {
  if (clientUpgradable.value) {
    return 'elevate your shoot';
  }
  return 'want to elevate your content?';
});

const subTitle = computed(() => {
  if (clientUpgradable.value) {
    return 'it’s not too late.';
  }
  return '';
});

const bodyText = computed(() => {
  if (clientUpgradable.value) {
    return 'add one of our popular model or styling services to take your content to the next level.';
  }
  return 'it’s not too late to book a model or stylist for your shoot.';
});

const buttonText = computed(() => {
  if (clientUpgradable.value) {
    return 'shop now';
  }
  return 'contact our crew';
});

const buttonElement = computed(() => {
  if (clientUpgradable.value) {
    return 'router-link';
  }
  return 'a';
});

const buttonHref = computed(() => {
  if (clientUpgradable.value) {
    return null;
  }
  return 'https://soona.co/contact-us';
});

const buttonTarget = computed(() => {
  if (clientUpgradable.value) {
    return null;
  }
  return '_blank';
});

const segmentHref = computed(() => {
  if (clientUpgradable.value) {
    return `user/anytime#/reservation/${reservationId.value}/shoot-upgrades`;
  }
  return buttonHref.value;
});

const segmentSubContext = computed(() => 'upgrade card - save more');

function trackLinkClicked() {
  if (route.meta.context) {
    linkClicked({
      context: route.meta.context,
      subContext: segmentSubContext.value,
      linkLabel: buttonText.value,
      linkHref: segmentHref.value,
    });
  }
}
</script>
<template>
  <div class="upgrade-card-container drop-shadow-container">
    <div class="upgrade-card-container__body">
      <img
        class="upgrade-card-container__body__img"
        src="@images/booking-upgrades/booking-upgrades-hand@2x.png"
        alt=""
      />
      <div
        v-if="!isLoading"
        class="upgrade-card-container__body__title-section"
      >
        <h3 class="upgrade-card-container__body__title">
          {{ title }}
        </h3>
        <h4 v-if="subTitle" class="upgrade-card-container__body__sub-title">
          {{ subTitle }}
        </h4>
      </div>
      <p
        v-if="bodyText && !isLoading"
        class="upgrade-card-container__body__text"
      >
        {{ bodyText }}
      </p>
    </div>
    <SoonaButton
      v-if="!isLoading"
      class="upgrade-card__link"
      :element="buttonElement"
      :href="buttonHref"
      variation="secondary-black"
      :target="buttonTarget"
      :to="{
        name: 'shoot-upgrades',
        params: { reservationId },
      }"
      @on-click="trackLinkClicked"
    >
      {{ buttonText }}
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.upgrade-card-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background: variables.$tangerine-10;
  box-shadow: 0rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.2);
  margin-top: 1rem;
  color: variables.$black-default;
  padding: 0rem 1rem 1rem 1rem;
  width: 100%;

  &__body {
    &__img {
      display: block;
      float: right;
      min-width: 0rem;
      min-height: 0rem;
      max-width: 7.5rem;
      height: auto;
      width: 30%;
      padding: 1rem 0.5rem 0 0.5rem;
    }

    &__title {
      @include variables_fonts.u-headline--heavy;
      margin-top: 1.25rem;
      padding-top: 0.5rem;
    }

    &__sub-title {
      @include variables_fonts.u-body--heavy;
    }

    &__text {
      @include variables_fonts.u-body--regular;
      line-height: 1.3333;
      margin-top: 0.625rem;
    }
  }

  :deep(.upgrade-card__link) {
    margin-top: 0.75rem;
    min-width: auto !important;
    max-width: fit-content !important;
  }
}
</style>

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param { ref } accountId
 * @param { ref } collectionId
 */
export function useDeleteReEditCollectionDigitalAsset(accountId, collectionId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ cdaId }) => {
      const response = await http.delete(
        `/collections/${toValue(
          collectionId
        )}/re_edits_collection_digital_assets/${toValue(cdaId)}`
      );

      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collectionsDA(accountId, 're_edits_collection'),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationDigitalAssets(),
        }),
      ]);
    },
  });
}

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param {import('vue').MaybeRefOrGetter<number | string>} accountId
 * @param {Object} [params]
 * @param {import('vue').MaybeRefOrGetter<string[]>} [params.requirementTypes]
 * @param {Object} [queryOptions]
 */
export function useAccountRequirements(
  accountId,
  params = {},
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.accountRequirements(accountId, params),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/accounts/${toValue(accountId)}/account_requirements`,
        {
          params: {
            requirement_types: toValue(params.requirementTypes),
          },
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';
import { editsCollectionDigitalAssets } from '@/api/editsCollectionDigitalAssets';

/**
 *
 * @param {string | number} collectionId
 * @param {Object} [params]
 * @param {number} [params.activeAssetId]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {Object} [params.filters]
 * @param {UseQueryOptions} [queryOptions]
 */
export function useEditsCollectionDigitalAssets(
  collectionId,
  { activeAssetId, currentPage = 1, itemsPerPage, facets, filters } = {},
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.collectionAssets(collectionId, {
      activeAssetId,
      currentPage,
      itemsPerPage,
      facets,
      filters,
    }),
    queryFn: async ({ signal }) => {
      return editsCollectionDigitalAssets(
        toValue(collectionId),
        {
          activeAssetId: toValue(activeAssetId),
          currentPage: toValue(currentPage),
          itemsPerPage: toValue(itemsPerPage),
          facets: toValue(facets),
          filters: toValue(filters),
        },
        signal
      );
    },
    ...queryOptions,
  });
}

<script setup>
import { toRef } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useSceneCardContent } from './useSceneCardContent';

const props = defineProps({
  shot: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: true,
  },
  isSelected: {
    type: Boolean,
    required: true,
  },
});

const { imageSrc, imageContentType, iconName, isComplete } =
  useSceneCardContent(
    toRef(() => props.shot),
    toRef(() => props.isActive)
  );
</script>

<template>
  <button class="scene-card" :aria-pressed="isSelected">
    <video v-if="imageContentType.startsWith('video/')" loop playsinline muted>
      <source :src="imageSrc" :type="imageContentType" />
    </video>
    <img v-else :src="imageSrc" alt="scene reference image" />
    <span class="scene-card__name">
      <SoonaIcon
        :name="iconName"
        size="x-small"
        :class="{ 'icon-active': isActive, 'icon-complete': isComplete }"
      />
      scene {{ index + 1 }}
    </span>
  </button>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.scene-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
  background-color: variables.$periwink-blue-10;
  color: variables.$gray-60;
  border: 0.0625rem solid transparent;
  outline-offset: -0.125rem;
  border-radius: 0.625rem;

  &:hover {
    background-color: variables.$black-translucent-04;
  }

  &:active {
    background-color: variables.$black-translucent-08;
  }

  &[aria-pressed='true'] {
    border-color: variables.$black-default;
    background-color: variables.$white-default;
    color: variables.$black-default;
  }

  img,
  video {
    width: 3.5rem;
    height: 3.5rem;
    object-fit: cover;
  }

  &__name {
    @include variables_fonts.u-label--heavy;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3125rem;
    width: 100%;
    white-space: nowrap;
  }
}
.icon-active {
  color: variables.$friendly-red-50;
}

.icon-complete {
  color: variables.$avo-toast-40;
}
</style>

import { computed } from 'vue';

export const usePurchasedFilesSelected = selectedReservationDigitalAssets => {
  const purchasedFilesSelected = computed(() => {
    return selectedReservationDigitalAssets.value.some(
      rda => rda.digital_asset.ownership === 'customer'
    );
  });
  return { purchasedFilesSelected };
};

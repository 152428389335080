import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { http } from '@/config/vue-axios';

export function useSocialMedias() {
  return useQuery({
    queryKey: queryKeys.socialMedias(),
    queryFn: async ({ signal }) => {
      const response = await http.get('/social_medias', { signal });

      return response.data;
    },
  });
}

export function useSocialMediaSizes() {
  return useQuery({
    queryKey: queryKeys.socialMediaSizes(),
    queryFn: async ({ signal }) => {
      const response = await http.get('/social_medias/sizes', { signal });

      return response.data;
    },
  });
}

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  hideChangeRecordsPerPage: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasFullWidthNav: {
    type: Boolean,
    required: false,
    default: false,
  },
  page: {
    type: Number,
    required: true,
  },
  recordsPerPage: {
    type: Number,
    required: true,
  },
  recordsPerPageOptions: {
    type: Array,
    required: false,
    default: () => [10, 20, 30, 40, 50],
    validator: value => {
      return (
        Array.isArray(value) &&
        // they're all numbers and integers over 0
        value.every(val => Number.isInteger(val) && val > 0) &&
        // no duplicates
        new Set(value).size === value.length
      );
    },
  },
  totalPages: {
    type: Number,
    required: true,
  },
  totalCount: {
    type: Number,
    required: true,
  },
  pageCount: {
    type: Number,
    required: true,
  },
  perPageText: {
    type: String,
    required: false,
    default: () => 'rows per page',
  },
});

const emits = defineEmits(['update:recordsPerPage', 'update:page']);

/*
 * show paginator if there are more records than the smallest page,
 * or 1 if the recordsPerPageOptions is empty
 */
const showPaginator = computed(() => {
  if (
    props.hideChangeRecordsPerPage &&
    props.recordsPerPage >= props.totalCount
  ) {
    return false;
  } else {
    return (
      props.totalCount >
      (props.recordsPerPageOptions.length > 0
        ? Math.min(...props.recordsPerPageOptions)
        : 1)
    );
  }
});

const firstRecordIndex = computed(
  () => (props.page - 1) * props.recordsPerPage + 1
);
const lastRecordIndex = computed(
  () => (props.page - 1) * props.recordsPerPage + props.pageCount
);

const canNext = computed(() => props.page < props.totalPages);
const next = () => {
  if (canNext.value) {
    emits('update:page', props.page + 1);
  }
};

const canPrev = computed(() => props.page > 1);
const prev = () => {
  if (canPrev.value) {
    emits('update:page', props.page - 1);
  }
};
</script>

<template>
  <div v-if="showPaginator" class="paginator">
    <div v-if="!hideChangeRecordsPerPage" class="per-section">
      <label for="page-size-select">{{ perPageText }}</label>
      <select
        id="page-size-select"
        :value="recordsPerPage"
        @change="$emit('update:recordsPerPage', Number($event.target.value))"
      >
        <option
          v-for="count in recordsPerPageOptions"
          :key="count"
          :value="count"
        >
          {{ count }}
        </option>
      </select>
    </div>
    <div
      class="page-change-section"
      :class="{ 'page-change-section--full-width': hasFullWidthNav }"
    >
      <p class="current-page-info">
        {{ firstRecordIndex.toLocaleString() }} -
        {{ lastRecordIndex.toLocaleString() }} of
        {{ totalCount.toLocaleString() }} items
      </p>
      <button
        class="page-button button page-prev"
        :disabled="!canPrev"
        @click="prev"
      >
        <SoonaIcon name="chevron-left" />
        <span class="u-visually-hidden">previous page</span>
      </button>
      <button
        class="page-button button page-next"
        :disabled="!canNext"
        @click="next"
      >
        <SoonaIcon name="chevron-right" />
        <span class="u-visually-hidden">next page</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.paginator {
  width: 100%;
  background-color: variables.$white-default;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  border: solid 1px #d6d8db;
  padding: 0 1rem;

  .per-section {
    font-size: 0.875rem;
    color: variables.$gray-60;

    @media only screen and (max-width: 768px) {
      display: none !important;
    }

    & > label {
      margin-right: 0.25rem;

      &::after {
        content: ':';
      }
    }

    & > select {
      color: variables.$gray-60;
      border: 0;
    }
  }

  .page-change-section {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin: 0;

    @media only screen and (max-width: 768px) {
      width: 100%;
      justify-content: space-between;

      .page-button:first-of-type {
        margin-left: auto;
      }
    }

    .current-page-info {
      font-size: 0.875rem;
      color: variables.$gray-60;
      margin: 1rem 1.5rem;
    }

    .page-button {
      border: none;
      color: variables.$gray-60;
      margin: 0;
    }

    &--full-width {
      justify-content: space-between;
      width: 100%;

      .page-prev {
        margin-left: auto;
      }
    }
  }
}
</style>

<script setup>
import { ref, toRefs } from 'vue';
import DeleteDigitalAsset from '@/components/user/anytime/gallery/media-editor/DeleteDigitalAsset.vue';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  assetId: {
    type: [String, Number],
    required: true,
  },
  assetAccountId: {
    type: [String, Number],
    required: true,
  },
});

defineEmits(['load-preview']);

const { assetId, assetAccountId } = toRefs(props);

const { previewUrl } = useMediaEditorDigitalAsset(assetAccountId, assetId);

const isLoaded = ref(false);
</script>

<template>
  <div class="generation-preview">
    <div v-if="isLoaded" class="generation-preview__delete">
      <DeleteDigitalAsset :asset-id="assetId" :account-id="assetAccountId" />
    </div>
    <img
      v-show="isLoaded"
      v-if="previewUrl"
      class="carousel-img__img"
      :class="{ 'carousel-img__img--loaded': isLoaded }"
      :src="previewUrl"
      alt=""
      @load="isLoaded = true"
      @click="$emit('load-preview', assetId)"
    />
    <SoonaSkeleton
      v-if="!isLoaded"
      class="carousel-img__skeleton"
      randomize-timing
    />
  </div>
</template>

<style scoped lang="scss">
.generation-preview {
  &__delete {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.carousel-img__skeleton {
  min-width: 10rem;
  min-height: 10rem;
}
</style>

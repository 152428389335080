<script setup>
import dayjs from 'dayjs';
import { computed, ref, toRef } from 'vue';
import { useIntervalFn } from '@vueuse/core';

import { differenceBetweenDates } from '@/lib/date-formatters';
import SoonaTransition from './SoonaTransition.vue';

const props = defineProps({
  padStart: {
    type: Boolean,
    default: false,
  },
  targetDate: {
    type: String,
    required: true,
  },
});

const currentTime = ref(dayjs());
useIntervalFn(() => (currentTime.value = dayjs()), 1000);
const targetTime = computed(() => dayjs(toRef(props, 'targetDate').value));

const timeLeft = computed(() => {
  const deadline = targetTime.value?.diff(currentTime.value);

  if (deadline > 0) {
    return differenceBetweenDates(currentTime.value, targetTime.value, 'UTC');
  }

  return {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
});
</script>

<template>
  <span v-if="timeLeft" class="countdown">
    <template v-for="(value, key) in timeLeft" :key="key">
      <SoonaTransition
        mode="out-in"
        name="fade-up-short"
        exit-name="fade-down-short"
      >
        <span :key="value" class="countdown__number">
          {{ padStart ? String(value).padStart(2, '0') : value }}
        </span>
      </SoonaTransition>

      <span class="countdown__unit">
        {{ ' ' }}
        {{ value < 2 ? key.slice(0, -1) : key }}
        {{ ' ' }}
      </span>

      <span v-if="key === 'hours'" class="countdown__word-break" />
    </template>

    <span class="countdown__unit"><slot /></span>
  </span>
</template>

<style lang="scss" scoped>
@use '@/variables';

.countdown {
  text-transform: uppercase;

  &__number {
    display: inline-block;
    font-variant-numeric: tabular-nums;
    transition:
      opacity 0.15s ease-in,
      transform 0.15s ease-in;
  }

  &__word-break {
    @media (max-width: variables.$screen-sm-max) {
      display: block;
    }
  }
}
</style>

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useBulkRemoveFromBag(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ assetType, assetIds }) => {
      const params = {
        asset_type: toValue(assetType),
        asset_ids: toValue(assetIds),
      };

      return http.post(
        `accounts/${toValue(accountId)}/bag/bulk_destroy`,
        params
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.bag() });
    },
  });
}

<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import BulkCreditsModal from 'src/components/modal-payment-flows/preferred/BulkCreditsModal.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  preferredCredits: {
    type: Number,
    required: true,
  },
  cartTotals: Object,
});

const route = useRoute();

const showBulkCreditsModal = ref(false);

const isPreferred = computed(() => props.preferredCredits > 0);
const photoCount = computed(() => props.cartTotals?.photo_count || 0);
const paymentsDisablePreferredFlag = useFlag('payments_disable_preferred');

const premiumEditsCount = computed(
  () => props.cartTotals?.premium_edits_count || 0
);

const saveAmount = computed(() => {
  return photoCount.value * 4 + premiumEditsCount.value * 9;
});

const { linkClicked } = useBaseEvents();

const handleCloseModal = () => (showBulkCreditsModal.value = false);

function trackLinkClicked(label, href = null) {
  linkClicked({
    context: route.meta.context,
    subContext: 'preferred checkout ad',
    linkLabel: label,
    linkHref: href,
  });
}

const handleOpenModalClick = () => {
  showBulkCreditsModal.value = true;
  trackLinkClicked('learn more');
};
</script>
<template>
  <div
    v-if="!paymentsDisablePreferredFlag"
    :class="[
      'preferred-advertisement',
      isPreferred ? 'preferred' : 'no-preferred',
    ]"
  >
    <BulkCreditsModal v-if="showBulkCreditsModal" @close="handleCloseModal" />
    <span v-if="isPreferred">
      <SoonaIcon name="party-horn-duotone" /> yay! you're saving $$ with
      preferred!
      <button
        class="u-button-reset"
        data-cypress="button-bulk-credits-modal"
        @click="handleOpenModalClick"
      >
        get more credits
      </button>
    </span>
    <span v-else>
      <SoonaIcon name="badge-dollar-duotone" /> save up to ${{ saveAmount }} on
      today's order with preferred
      <button
        class="u-button-reset"
        data-cypress="button-bulk-credits-modal"
        @click="handleOpenModalClick"
      >
        learn more
      </button>
    </span>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.preferred-advertisement {
  font-weight: bold;
  text-align: center;
  padding: 1.25rem;
  border-radius: 0 0 0.5rem 0.5rem;

  svg {
    vertical-align: middle;
  }

  a,
  button {
    display: block;
    font-size: 1rem;
    margin: 0 auto;
    margin-top: 0.5rem;
    font-weight: normal;
    text-decoration: underline;
    color: inherit;
  }

  &.no-preferred {
    background: variables.$green-apple-10;
    border: 0.0625rem solid variables.$avo-toast-40;

    svg {
      color: variables.$avo-toast-40;
    }
  }
  &.preferred {
    background: variables.$bingo-blue;
    color: variables.$white-default;

    svg {
      color: variables.$white-default;
    }
  }
}
</style>

import { http } from '../config/vue-axios';

/**
 *
 * @param {Object} [params]
 * @param {string | number} [params.accountId]
 * @param {AbortSignal} [signal]
 * @returns {Promise<any>}
 */
export async function getIntegrations(accountId, signal) {
  const params = {
    account_id: accountId,
  };

  const response = await http.get('/integrations', {
    signal,
    params,
  });

  return response.data;
}

/**
 *
 * @param {Object} [params]
 * @returns {Promise<any>}
 */
export async function refreshIntegration(params) {
  const response = await http.post(
    `/integrations/${params['integration_type']}/${params['id']}/refresh`,
    {
      params,
    }
  );

  return response.data;
}

/**
 *
 * @param {Object} [params]
 * @returns {Promise<any>}
 */
export async function updateIntegration(params) {
  const response = await http.put(`/integrations/${params['id']}`, {
    integration: params,
  });

  return response.data;
}

/**
 *
 * @param {Object} [params]
 * @param {string} [params.integration_type]
 * @param {string | number} [params.id]
 * @returns {Promise<any>}
 */
export async function removeIntegrationAccount(params) {
  const response = await http.post(
    `/integrations/${params['integration_type']}/${params['id']}/remove_account`
  );

  return response.status === 204;
}

<script setup>
import { provide, toRefs } from 'vue';

import { RADIO_CARD_GROUP_KEY } from './radio-card-group-key';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  labelVisuallyHidden: {
    type: Boolean,
    default: true,
  },
  name: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const { disabled, name, isLoading } = toRefs(props);
const modelValue = defineModel({ type: String });

provide(RADIO_CARD_GROUP_KEY, {
  name,
  isLoading,
  modelValue,
  disabled: disabled,
  changeModelValue: value => {
    modelValue.value = value;
  },
});
</script>

<template>
  <fieldset class="radio-card-root" :disabled="disabled">
    <legend :class="{ 'u-visually-hidden': labelVisuallyHidden }">
      {{ label }}
    </legend>

    <slot :model-value="modelValue"></slot>
  </fieldset>
</template>

<style lang="scss" scoped>
.radio-card-root {
  gap: 1.25rem;
  display: flex;
  flex-direction: column;
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { getAcceptableInvitation } from '@/api/invitations';

/**
 *
 * @param {string | number} accountId
 * @param {string | number} invitationId
 * @param {object | Ref<object>} params
 */
export function useGetAcceptableInvitation(accountId, invitationId, params) {
  return useQuery({
    queryKey: queryKeys.invitationAcceptable(accountId, invitationId, params),
    queryFn: ({ signal }) =>
      getAcceptableInvitation(
        unref(accountId),
        unref(invitationId),
        unref(params),
        signal
      ),
  });
}

<script setup>
import { computed, ref } from 'vue';
import { useAccount } from '@/queries/account/useAccount';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useDeleteAccount } from '@/queries/account/useDeleteAccount';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useCapability } from '@/composables/useCapability';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

const accountId = computed(() => props.accountId);
const { data: account } = useAccount(accountId);

const { hasCapability: canManageAccount } = useCapability({
  capability: 'manage_account',
  subjectType: 'account',
  subjectId: accountId,
});

const showDeleteModal = ref(false);
const showDeleteConfirmation = ref(false);
const verifiedAccountDeletion = ref('');

function handleShowDeleteModal() {
  showDeleteModal.value = true;
}
function handleHideDeleteModal() {
  verifiedAccountDeletion.value = '';
  showDeleteModal.value = false;
}

const { mutate: deleteAccount, isPending: isDeletingAccount } =
  useDeleteAccount(accountId);

const validAccountName = computed(() => {
  return (
    verifiedAccountDeletion.value.toLowerCase() ===
    account.value.name.toLowerCase()
  );
});

const { addToast } = useSoonaToast();
const handleConfirmDeletion = () => {
  if (validAccountName.value) {
    deleteAccount(
      { accountId: accountId },
      {
        onSuccess: async () => {
          window.location.href = '/';
        },
        onError: () => {
          addToast(`error deleting account`, {
            variation: 'error',
            noDismissButton: true,
          });
        },
        onSettled: () => {
          showDeleteConfirmation.value = false;
          showDeleteModal.value = false;
        },
      }
    );
  }
};
</script>

<template>
  <div v-if="canManageAccount && account.is_deletable" class="delete-account">
    <div class="action-btns">
      <SoonaButton
        size="medium"
        variation="secondary-black"
        @on-click="handleShowDeleteModal"
      >
        delete this account
      </SoonaButton>
    </div>

    <SoonaDialog
      v-if="showDeleteModal"
      role="alertdialog"
      @close="handleHideDeleteModal"
    >
      <template #heading>delete account</template>
      <p class="delete-account__warning">
        By proceeding with account deletion,
        <strong>please be aware that this action is permanent.</strong> Once
        your account is deleted, all associated content will be removed and
        cannot be recovered. No files or data will automatically transfer to
        another account.
      </p>
      <p>
        <em
          >If you have any questions or wish to retain certain files, please
          contact our support team before deleting your account. We’re here to
          help!</em
        >
      </p>

      <div class="confirm-deletion-form">
        <SoonaTextfield
          v-model="verifiedAccountDeletion"
          :label="'Type the account name to confirm deletion: ' + account.name"
          :placeholder="account.name"
          type="text"
        />
      </div>

      <template #footer="{ close }">
        <SoonaButton
          :disabled="isDeletingAccount"
          variation="tertiary"
          @on-click="close"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          :loading="isDeletingAccount"
          :disabled="!validAccountName"
          @on-click="handleConfirmDeletion"
        >
          confirm deletion
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.delete-account {
  background-color: variables.$white-default;
  border-radius: 0.75rem;
  box-shadow: variables.$elevation-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;

  .action-btns {
    margin: auto;
    width: max-content;
  }

  .save-btn {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }
}

.delete-account__warning,
.delete-account__account-name {
  margin-bottom: 1rem;
}

.confirm-deletion-form {
  margin-top: 1rem;
  background-color: variables.$roses-10;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 0.5rem;
}
</style>

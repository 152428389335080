import {
  PeriwinkBlue60,
  PeriwinkBlue30,
  PeriwinkBlue10,
  GreenApple60,
  GreenApple30,
  GreenApple10,
} from '@/variables.module.scss';
import { toValue } from 'vue';

/*
 * `requirementType` matches the `requirement_type` enum for the AccountRequirement model
 */
export const requirementTypes = {
  shoot_guidelines: {
    requirementType: 'shoot_guidelines',
    label: 'shoot guidelines',
    icon: 'camera-alt-1',
    iconColor: PeriwinkBlue60,
    iconBackground: PeriwinkBlue10,
    iconBorder: PeriwinkBlue30,
  },
  editing_standards: {
    requirementType: 'editing_standards',
    label: 'editing standards',
    icon: 'pen-swirl',
    iconColor: GreenApple60,
    iconBackground: GreenApple10,
    iconBorder: GreenApple30,
  },
};

/**
 * @typedef {Object} RequirementTypeDisplay
 * @property {string} requirementType
 * @property {string} label
 * @property {string} icon
 * @property {string} iconColor
 * @property {string} iconBackground
 * @property {string} iconBorder
 */

/**
 *
 * @param {import('vue').MaybeRefOrGetter<Object[]>} accountRequirements
 * @returns {{data: Object | null, displayData: RequirementTypeDisplay}[]}
 */
export function mergeWithAccountRequirements(accountRequirements) {
  return Object.values(requirementTypes).map(reqTypeDisplayObj => {
    return {
      displayData: reqTypeDisplayObj,
      // add a data property that contains the AccountRequirement object from the server
      data:
        toValue(accountRequirements)?.find(
          accReq =>
            accReq.requirement_type === reqTypeDisplayObj.requirementType
        ) ?? null,
    };
  });
}

<script setup>
import { inject } from 'vue';

const props = defineProps({
  ariaLabel: {
    type: String,
    default: 'search and filter',
  },
  isMobile: {
    type: Boolean,
    default: false,
  },
  placeholderText: {
    type: String,
    default: 'search & filter',
  },
});

const {
  ariaActivedescendantID,
  listboxId,
  open,
  setOpen,
  modelSearchValue,
  toggleCombobox,
} = inject('comboboxContext');

const handleBlur = () => {
  if (props.isMobile) return;

  toggleCombobox();
};
</script>

<template>
  <input
    v-model="modelSearchValue"
    type="search"
    role="combobox"
    aria-haspopup="listbox"
    aria-autocomplete="list"
    :aria-label="ariaLabel"
    :aria-expanded="open"
    :aria-controls="listboxId"
    :aria-activedescendant="ariaActivedescendantID"
    :placeholder="placeholderText"
    @focus="setOpen(true)"
    @blur="handleBlur"
  />
</template>

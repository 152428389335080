import { computed, ref, watch, watchEffect } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { refDebounced } from '@vueuse/core';
import useCompetitorsPopoverBackRoute from './useCompetitorsPopoverBackRoute';

export const useCompetitorWatchListQueryParams = (strictFilter = null) => {
  const router = useRouter();

  const route = useRoute();

  // filters
  const searchText = ref(route.query.search ?? '');

  const searchDebounced = refDebounced(searchText, 200);

  const filter = strictFilter
    ? ref(strictFilter)
    : ref(route.query.filter ?? 'all');

  const sortBy = ref({
    orderBy: route.query.order_by ?? 'created_at',
    direction: route.query.direction ?? 'desc',
  });

  const orderBy = computed(() => sortBy.value.orderBy);

  const direction = computed(() => sortBy.value.direction);

  // pagination
  const currentPage = ref(
    route.query.current_page ? Number(route.query.current_page) : 1
  );

  watchEffect(() => {
    if (route.query.current_page) {
      currentPage.value = Number(route.query.current_page);
    }
  });

  const itemsPerPage = ref(
    route.query.items_per_page ? Number(route.query.items_per_page) : 10
  );

  watch([itemsPerPage, searchDebounced, orderBy, direction, filter], () => {
    currentPage.value = 1;
  });

  // URL query params
  const queryObj = computed(() => {
    let result = {
      filter: filter.value,
      order_by: orderBy.value,
      direction: direction.value,
      current_page: currentPage.value,
      items_per_page: itemsPerPage.value,
    };
    if (searchDebounced.value) result.search = searchDebounced.value;
    return result;
  });

  const { setBackRoute } = useCompetitorsPopoverBackRoute();

  watchEffect(() => {
    if (route.name === 'competitive-analysis') {
      router.replace({
        query: queryObj.value,
      });
      setBackRoute(route);
    }
  });

  function resetFilters() {
    searchText.value = '';
    filter.value = strictFilter ? strictFilter : 'all';
  }

  return {
    searchText,
    searchDebounced,
    filter,
    sortBy,
    orderBy,
    direction,
    currentPage,
    itemsPerPage,
    queryObj,
    resetFilters,
  };
};

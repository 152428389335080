<script setup>
import { computed } from 'vue';
import ShopifyIntegration from '@/components/user/anytime/products/shopify/ShopifyIntegration.vue';
import AmazonIntegration from './AmazonIntegration.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  integration: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['openRemoveAmazonDataDialog', 'close']);

const allowedIntegrations = {
  ShopifyIntegration: ShopifyIntegration,
  AmazonIntegration: AmazonIntegration,
};

const integrationComponent = computed(() => {
  return allowedIntegrations[props.integration.type];
});
</script>

<template>
  <div v-if="integrationComponent" class="integration">
    <component
      :is="integrationComponent"
      :account-id="accountId"
      :integration="integration"
      @open-remove-amazon-data-dialog="
        emit('openRemoveAmazonDataDialog', $event)
      "
      @close="emit('close')"
    ></component>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.integration {
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>

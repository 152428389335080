<script setup>
import { computed } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { toCurrency } from '@/lib/currency';
import { FriendlyRed60, WhiteDefault } from 'src/variables.module.scss';

const props = defineProps({
  description: {
    default: undefined,
    required: false,
    type: String,
  },
  priceInfo: {
    default: undefined,
    required: false,
    type: Object,
  },
  title: {
    default: undefined,
    required: false,
    type: String,
  },
  titleElement: {
    default: undefined,
    required: false,
    type: String,
    validator: function (value) {
      return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value);
    },
  },
});

const rate = computed(() => {
  return props.priceInfo?.rate;
});

const discountedRate = computed(() => {
  return props.priceInfo?.discounted_rate;
});

const percentDiscount = computed(() => {
  return props.priceInfo?.percent_discount;
});
</script>

<template>
  <div class="soona-tag-option-card-modal-main-copy">
    <component
      :is="titleElement"
      v-if="title"
      class="soona-tag-option-card-modal-main-copy__title"
    >
      {{ title }}
    </component>
    <div v-if="priceInfo" class="soona-tag-option-card-modal-main-copy__price">
      <div
        v-if="discountedRate"
        class="soona-tag-option-card-modal-main-copy__price__discounted"
      >
        <span class="soona-tag-option-card-modal-main-copy__price__old">
          {{ toCurrency(rate * 1.0) }}
        </span>
        <span class="soona-tag-option-card-modal-main-copy__price__new">
          {{ toCurrency(discountedRate * 1.0) }}
        </span>
        <SoonaFlag
          :background-color="FriendlyRed60"
          :text-color="WhiteDefault"
          :title="percentDiscount + '% off'"
          font-weight="bolder"
        />
      </div>
      <span
        v-if="title.toLowerCase() === 'grocery shopping'"
        class="soona-tag-option-card-modal-main-copy__price-extra"
      >
        + cost of goods
      </span>
    </div>
    <p
      v-if="description"
      class="soona-tag-option-card-modal-main-copy__description"
      v-html="description"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-tag-option-card-modal-main-copy {
  &__title {
    @include variables_fonts.u-title--heavy;

    margin-bottom: 0.25rem;
  }

  &__price {
    @include variables_fonts.u-title--regular;

    margin-bottom: 0.25rem;

    &__discounted {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__old {
      text-decoration: line-through;
    }

    &__new {
      color: variables.$friendly-red-70;
      @include variables_fonts.u-title--heavy;
    }
  }

  &__description {
    @include variables_fonts.u-body--regular;
  }

  @media (min-width: variables.$screen-sm-min) {
    padding-left: 0.75rem;
    width: 50%;
    flex-grow: 1;

    &__title {
      @include variables_fonts.u-headline--heavy;

      margin-bottom: 0.375rem;
      padding-top: (14px);
    }

    &__price {
      margin-bottom: 0.375rem;
    }

    &__description {
      @include variables_fonts.u-subheader--regular;

      flex-grow: 1;
    }
  }
}
</style>

<script setup>
import { ref, watchEffect } from 'vue';
import DialogSelectableCard from './DialogSelectableCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

const props = defineProps({
  alreadySelected: {
    default: () => [],
    type: Array,
  },
});
const emit = defineEmits(['close', 'save']);

const selectedProducts = ref([]);

const handleClear = () => {
  selectedProducts.value = [];
};

const handleSelection = (isChecked, option) => {
  if (isChecked) {
    selectedProducts.value = [...selectedProducts.value, option];
  } else {
    selectedProducts.value = selectedProducts.value.filter(
      product => product.id !== option.id
    );
  }
};

watchEffect(() => {
  selectedProducts.value = props.alreadySelected;
});
</script>

<template>
  <SoonaDialog @close="emit('close')">
    <template #heading>add soona props</template>
    <template #default="{ contentEl }">
      <DialogSelectableCard
        v-if="contentEl"
        :container-el="contentEl"
        query-slug="shot-props"
        :selected-products="selectedProducts"
        @handle-selection="handleSelection"
      />
    </template>
    <template #footer="{ close }">
      <div class="action-btns">
        <div class="btn-group">
          <p class="u-label--heavy">{{ selectedProducts.length }} selected</p>
          <SoonaButton
            size="medium"
            variation="tertiary"
            @on-click="handleClear"
          >
            clear
          </SoonaButton>
        </div>
        <div class="btn-group">
          <SoonaButton size="medium" variation="tertiary" @on-click="close">
            cancel
          </SoonaButton>
          <SoonaButton size="medium" @on-click="emit('save', selectedProducts)">
            add selected
          </SoonaButton>
        </div>
      </div>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
.action-btns {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.btn-group {
  align-items: center;
  display: flex;
  gap: 1rem;
}
</style>

<script setup>
import { computed, ref, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import ProServiceDropdownFilter from './ProServiceDropdownFilter.vue';
import ProServiceSelectionFilterModal from './ProServiceSelectionFilterModal.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  mainTag: {
    required: true,
    type: Object,
  },
  modelValue: {
    required: true,
    type: Array,
  },
  paginationTotalCount: {
    required: true,
    type: Number,
  },
  tagFollowUpCategories: {
    type: Array,
    required: true,
  },
  workedWithAccountId: {
    default: null,
    type: Number,
  },
});

const emit = defineEmits([
  'clear-filters',
  'select-models-worked-with',
  'update:modelValue',
]);

const { buttonClicked } = useBaseEvents();
const route = useRoute();

const showDialog = ref(false);

const { mainTag, tagFollowUpCategories } = toRefs(props);

const filterDialogConfirmText = computed(() =>
  props.paginationTotalCount > 0
    ? `view ${props.paginationTotalCount} models`
    : 'no matching models'
);

const numberActiveFilters = computed(
  () => props.modelValue.length + (props.workedWithAccountId ? 1 : 0)
);

const filteredSortedTagCategories = computed(() => {
  if (!tagFollowUpCategories.value) return [];

  return tagFollowUpCategories.value.filter(
    tc =>
      tc.title !== 'manicure' &&
      tc.title !== 'manicure color' &&
      tc.title !== 'pronoun'
  );
});

const basicInfoTagCategories = computed(() =>
  filteredSortedTagCategories.value.filter(
    tc => tc.special_category === 'psp_trait_basic_info'
  )
);

const quickFilters = computed(() => {
  if (!mainTag.value?.fitler_names) return basicInfoTagCategories.value;

  const filterNamesArray = mainTag.value.filter_names.split(',');

  return tagFollowUpCategories.value.filter(tc =>
    filterNamesArray.includes(tc.title)
  );
});

const context = computed(() => route.meta.context);

const handleShowDialog = () => {
  showDialog.value = true;

  buttonClicked({
    context: context.value,
    subContext: `${mainTag.value?.title}`,
    buttonLabel: 'filters',
    buttonAction: 'opens model filters modal',
  });
};

const handleDialogConfirm = () => {
  showDialog.value = false;

  buttonClicked({
    context: context.value,
    subContext: `${mainTag.value?.title} filters modal`,
    buttonLabel: filterDialogConfirmText.value,
    buttonAction: 'updates pro service selection options',
  });
};

const handleDialogCancel = () => {
  showDialog.value = false;

  buttonClicked({
    context: context.value,
    subContext: `${mainTag.value?.title} filters modal`,
    buttonLabel: 'x',
    buttonAction: 'closes model filters modal',
  });
};
</script>

<template>
  <div class="selection-filters">
    <div class="quick-filters">
      <SoonaButton
        :class="{ 'models--active': workedWithAccountId }"
        variation="filter"
        size="medium"
        @on-click="emit('select-models-worked-with')"
      >
        <SoonaIcon name="heart" />
        <span class="u-label--heavy">models I’ve worked with</span>
      </SoonaButton>
      <ProServiceDropdownFilter
        v-for="quickFilter in quickFilters"
        :key="quickFilter.id"
        :model-value="modelValue"
        :tag-category="quickFilter"
        :pro-service-provider-type="mainTag.title"
        @update:model-value="item => emit('update:modelValue', item)"
      />
      <SoonaButton
        class="clear-filters"
        variation="tertiary"
        size="medium"
        @on-click="e => emit('clear-filters', e)"
      >
        clear filters
      </SoonaButton>
    </div>
    <SoonaButton
      variation="secondary-gray"
      class="dialog-btn"
      size="medium"
      @on-click="handleShowDialog"
    >
      <SoonaIcon name="sliders" /> filters
      <span
        v-if="numberActiveFilters > 0"
        class="u-small--heavy active-filters"
      >
        {{ numberActiveFilters }}
      </span>
    </SoonaButton>
    <ProServiceSelectionFilterModal
      v-if="showDialog"
      :model-value="modelValue"
      :main-tag="mainTag"
      :confirm-button-copy="filterDialogConfirmText"
      :worked-with-account-id="workedWithAccountId"
      @confirm-clicked="handleDialogConfirm"
      @cancel-clicked="handleDialogCancel"
      @select-models-worked-with="emit('select-models-worked-with')"
      @update:model-value="item => emit('update:modelValue', item)"
      @clear-filters="e => emit('clear-filters', e)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.selection-filters {
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  container: selection-filters / inline-size;
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
  padding: 1rem;
  position: relative;
  z-index: 1;
}

.quick-filters {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 0.5rem;

  > div:not(.clear-filters),
  > button:not(.clear-filters) {
    display: none;
  }
}

.dialog-btn {
  flex-shrink: 0;
}

.active-filters {
  align-items: center;
  background-color: variables.$friendly-red-50;
  border-radius: 50%;
  color: variables.$white-default;
  display: flex;
  justify-content: center;
  padding: 0 0.25rem;
}

.models {
  &--active {
    border-color: variables.$friendly-red-40;
    background-color: variables.$friendly-red-20;

    svg {
      color: variables.$friendly-red-50;
    }
  }
}

@container selection-filters (min-width: 35rem) {
  .quick-filters {
    > div:not(.clear-filters),
    > button:not(.clear-filters) {
      display: flex;
    }
  }

  .dialog-btn {
    flex-grow: 0;
    height: max-content;
  }
}
</style>

<script setup>
import { ref } from 'vue';
import BackdropColorDialog from './BackdropColorDialog.vue';
import EmptyImg from 'images/shot-list/scenes/empty-backdrop-color.png';
import SectionLayout from './SectionLayout.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';

defineProps({
  selectedOption: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['handle-select']);

const showDialog = ref(false);

const handleSelect = option => {
  emit('handle-select', 'backdrop_color', option);
  showDialog.value = false;
};
</script>

<template>
  <SectionLayout :empty-img="EmptyImg" :show-empty="!selectedOption">
    <template #heading>backdrop color</template>
    <template #empty-btn>
      <SoonaButton variation="solid-black" @on-click="showDialog = true">
        choose backdrop color
      </SoonaButton>
    </template>
    <SoonaButton
      size="medium"
      variation="secondary-gray"
      @on-click="showDialog = true"
    >
      edit
    </SoonaButton>
    <SoonaChip
      :image-src="selectedOption?.image_url"
      image-alt="selected backdrop color"
    >
      {{ selectedOption?.title?.toLowerCase() }}
    </SoonaChip>
  </SectionLayout>
  <BackdropColorDialog
    v-if="showDialog"
    @select="handleSelect"
    @close="showDialog = false"
  />
</template>

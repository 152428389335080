<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useGetIntegrations } from '@/queries/useGetIntegrations';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  variation: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['on-click']);

const accountId = computed(() => props.accountId);

const { data: integrationsData } = useGetIntegrations(accountId);

const integrations = computed(() => integrationsData.value ?? []);

const productIntegrations = computed(() => {
  return integrations.value.filter(integration =>
    ['ShopifyIntegration', 'AmazonIntegration'].includes(integration.type)
  );
});

const buttonCopy = computed(() => {
  return productIntegrations.value?.length > 0
    ? 'manage accounts'
    : 'connect accounts';
});

const buttonVariation = computed(() => {
  if (props.variation) return props.variation;

  return productIntegrations.value?.length > 0 ? 'tertiary' : 'secondary-gray';
});

function onClick() {
  emit('on-click', { buttonCopy: buttonCopy.value });
}

const integrationsRequiringAttentionCount = computed(() => {
  return integrations.value.filter(
    integration => integration.status === 'invalid_state'
  ).length;
});
</script>

<template>
  <SoonaButton
    class="add-integration__button"
    :variation="buttonVariation"
    size="medium"
    @click="onClick"
  >
    <SoonaIcon name="integrations" size="medium" />
    {{ buttonCopy }}
    <SoonaIcon
      v-if="integrationsRequiringAttentionCount > 0"
      class="add-integration__status-badge"
      name="status-dot-small"
    />
  </SoonaButton>
</template>

<style lang="scss" scoped>
@use '@/variables';

.add-integration {
  &__status-badge {
    color: variables.$friendly-red-50;
  }
}
</style>

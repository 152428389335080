<script setup>
import { computed } from 'vue';
import uniqueId from 'lodash/uniqueId';
import {
  GradientRedStart,
  GradientRedEnd,
  GradientGreenStart,
  GradientGreenEnd,
  GradientGoldStart,
  GradientGoldEnd,
  GradientPeriwinkStart,
  GradientPeriwinkEnd,
} from 'src/variables.module.scss';

const props = defineProps({
  data: {
    type: Array,
    required: true,
    validator: value =>
      // check that sum of all data points is not greater than 1
      value.reduce((acc, { value }) => acc + value, 0) <= 1 &&
      // check the presense of value and label properties
      value.map(obj => 'value' in obj && 'label' in obj).every(Boolean),
  },
  size: {
    type: String,
    default: 'large',
    validator: function (value) {
      return ['large', 'medium', 'small'].includes(value);
    },
  },
  displayValue: {
    type: Number,
    required: false,
  },
  accessibleTitle: {
    type: String,
    required: true,
  },
  accessibleDescription: {
    type: String,
    required: false,
    validator(value, props) {
      return value !== props.accessibleTitle;
    },
  },
  labelPosition: {
    type: String,
    default: 'bottom',
    validator: function (value) {
      return ['bottom', 'right', 'none'].includes(value);
    },
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const id = uniqueId('soona-donut-chart-');

const sizes = {
  small: '3.125rem',
  medium: '4rem',
  large: '10.5rem',
};
const sizeRem = computed(() => sizes[props.size]);

const classNames = computed(() => {
  let classNames = `soona-donut-chart soona-donut-chart--${props.size} soona-donut-chart--label-${props.labelPosition}`;
  if (props.isLoading) {
    classNames += ' soona-donut-chart--loading';
  }
  return classNames;
});

const data = computed(() => props.data);

const colorLookupStart = {
  poor: GradientRedStart,
  okay: GradientGoldStart,
  great: GradientGreenStart,
};
const colorLookupEnd = {
  poor: GradientRedEnd,
  okay: GradientGoldEnd,
  great: GradientGreenEnd,
};

const cx = 18;
const cy = 18;
const radius = 100 / (2 * Math.PI);
const circumference = computed(() => 2 * Math.PI * radius);

const calculateStrokeDashOffset = (value, cir) => {
  const strokeDiff = value * cir;

  return cir - strokeDiff;
};

const calculatedChartData = computed(() => {
  let angleOffset = -90;
  let chartData = [];

  data.value.forEach(segment => {
    const data = {
      degrees: angleOffset,
    };
    chartData.push(data);
    angleOffset = segment.value * 360 + angleOffset;
  });

  return chartData;
});

const returnCircleTransformValue = index => {
  return `rotate(${calculatedChartData?.value[index]?.degrees}, ${cx}, ${cy})`;
};
</script>

<template>
  <figure :class="classNames">
    <svg
      viewBox="0 0 36 36"
      role="img"
      :aria-labelledby="`${id}-title ${id}-desc`"
    >
      <defs>
        <linearGradient v-if="isLoading"></linearGradient>
        <linearGradient
          v-for="(segment, index) in data"
          v-else
          :id="`gradient-${segment.label}`"
          :key="`gradient-$${index}`"
          x1="0"
          y1="0"
          x2="1"
          y2="1"
        >
          <stop
            offset="0%"
            :stop-color="colorLookupStart[segment.label]"
          ></stop>
          <stop
            offset="100%"
            :stop-color="colorLookupEnd[segment.label]"
          ></stop>
        </linearGradient>

        <linearGradient id="gradient-background" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" :stop-color="GradientPeriwinkStart"></stop>
          <stop offset="100%" :stop-color="GradientPeriwinkEnd"></stop>
        </linearGradient>
      </defs>

      <title :id="`${id}-title`">{{ accessibleTitle }}</title>
      <desc v-if="accessibleDescription" :id="`${id}-desc`">
        {{ accessibleDescription }}
      </desc>
      <g>
        <circle
          :cx="cx"
          :cy="cy"
          :r="radius"
          stroke="url(#gradient-background)"
          :stroke-width="4"
          :stroke-dasharray="circumference"
          fill="transparent"
        />
        <text></text>
      </g>
      <g v-for="(segment, index) in data" :key="segment.label">
        <circle
          :cx="cx"
          :cy="cy"
          :r="radius"
          fill="transparent"
          :stroke="`url(#gradient-${segment.label})`"
          :stroke-width="4"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="
            calculateStrokeDashOffset(segment.value, circumference)
          "
          :transform="returnCircleTransformValue(index)"
          :stroke-linecap="data.length > 1 ? 'butt' : 'round'"
        ></circle>
        <text
          v-if="displayValue || displayValue === 0"
          x="50%"
          y="50%"
          :class="`soona-donut-chart__number soona-donut-chart__number--${props.size}`"
        >
          {{ displayValue }}
        </text>
      </g>
    </svg>
    <figcaption
      v-if="$slots['label']"
      :class="`soona-donut-chart__label soona-donut-chart__label--${props.size}`"
    >
      <slot name="label" />
    </figcaption>
  </figure>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-donut-chart {
  @include variables_fonts.u-body--heavy;

  align-items: center;
  display: flex;
  gap: 0.5em;

  svg {
    height: min(v-bind('sizeRem'), 100%);
    width: min(v-bind('sizeRem'), 100%);
  }

  &--small {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  &--label-bottom {
    flex-direction: column;
    justify-content: center;
  }

  &--label-none {
    flex-direction: row;
    justify-content: flex-end;
  }

  &--label-right {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 1rem;
  }

  &__label {
    @include variables_fonts.u-title--regular;

    align-items: center;
    display: flex;
    gap: 0.25rem;

    &--small {
      @include variables_fonts.u-label--regular;
      text-transform: uppercase;
    }
  }

  &__number {
    font-size: 0.7rem;
    text-anchor: middle;

    &--medium,
    &--large {
      transform: translateY(0.25rem);
    }
    &--small {
      transform: translateY(0.3rem);
    }
  }

  &__skeleton {
    height: v-bind('sizeRem');
    width: v-bind('sizeRem');
    border-radius: 50%;
  }

  &--loading {
    svg {
      animation: circleanimation 0.45s linear infinite;
    }
    .soona-donut-chart__number {
      display: none;
    }
  }
}

@keyframes circleanimation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
</style>

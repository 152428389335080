<script setup>
import { computed, toRefs } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps.js';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const {
  data: reservation,
  isLoading: isLoadingReservation,
  error: reservationError,
} = useReservation(reservationId);

const {
  data: contentPurposeData,
  isLoading,
  error: resTagsError,
} = useReservationTags(reservationId, 'purpose');

const contentPurposeOptions = computed(() => {
  return contentPurposeData.value?.options ?? [];
});

const selectedPurposes = computed(
  () => contentPurposeOptions.value?.filter(x => x.selected === true) ?? []
);

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId);

const { stepIsIncomplete } = useIncompleteSteps(reservation, slug);

const priorityError = usePriorityError(
  addError,
  removeError,
  resTagsError,
  reservationError,
  stepIsIncomplete
);

function onSelect(option) {
  addReservationTag({ tagId: option.tag.id });
}

const onDeselect = option => {
  removeReservationTag({
    reservationTagId: option.reservation_tag.id,
    deleteObsoleted: true,
  });
};

const isBusy = computed(
  () =>
    isLoading.value ||
    isAdding.value ||
    isRemoving.value ||
    isLoadingReservation.value
);
</script>

<template>
  <SoonaSelect
    :model-values="selectedPurposes"
    :options="contentPurposeOptions"
    :disabled="isBusy"
    :loading="isBusy"
    :selectable="option => !option.selected"
    multi
    data-cypress="select-content-purpose"
    @option:selecting="onSelect"
    @option:deselecting="onDeselect"
  >
    <template #label>content purpose</template>
  </SoonaSelect>

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

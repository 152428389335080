<script setup>
import { ref } from 'vue';
import UploadActions from '@/components/uploader/UploadActions.vue';
import UpdateBooking from '@/components/user/anytime/reservation/gallery/staff_actions/UpdateBooking.vue';
import { useCapabilities } from '@/composables/useCapabilities';

defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
});

const capabilitiesRef = ref([{ capability: 'shoot_actions' }]);
const capabilities = useCapabilities(capabilitiesRef);
</script>

<template>
  <div class="crew-tools">
    <div class="crew-tools--upload">
      <UploadActions :reservation-id="reservationId" />
    </div>
    <UpdateBooking
      v-if="capabilities.shoot_actions.hasCapability"
      :reservation-id="reservationId"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.crew-tools {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  &--upload {
    margin-right: auto;
  }
}
</style>

import { track } from '@/lib/segment/segment';
import { useCurrentReservationAndUser } from '@/composables/segment/useCurrentReservationAndUser';

export function useAssetEvents() {
  const { userTraits } = useCurrentReservationAndUser();

  /**
   * Asset Published To Listing
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {number} catalogItemId what is the id for the catalog item?
   * @param {string} platform what platform are you publishing you? ex: Amazon, Shopify
   * @param {string} productName what is the product name?
   * @param {string} productType what is the product type? ex: makeup
   * @param {string} sku what is the product sku?
   */
  const assetPublishedToListing = ({
    context,
    subContext,
    catalogItemId,
    platform,
    productName,
    productType,
    sku,
  } = {}) => {
    const payload = {
      context,
      subContext,
      catalogItemId,
      platform,
      productName,
      productType,
      sku,
      ...userTraits.value,
    };
    track('Asset Published To Listing', payload);
  };

  return {
    assetPublishedToListing,
  };
}

<script setup>
import { useSharedGallery } from '@/queries/shared-gallery/useSharedGallery';
import { computed, onMounted, ref, watch } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import HowItWorks from '@/components/booking/v3/p2/HowItWorks.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaReservationFilePreview from '@/components/ui_library/preview/SoonaReservationFilePreview.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import { keepPreviousData } from '@tanstack/vue-query';

const props = defineProps({
  sharedGalleryHash: {
    type: String,
    required: true,
  },
});

const currentPage = ref(1);
const itemsPerPage = ref(20);

const currentPreviewFile = ref(null);
const sharedGalleryHash = computed(() => props.sharedGalleryHash);

const {
  data: galleryData,
  isLoading,
  isFetching,
  isSuccess,
  error: loadSharedGalleryError,
} = useSharedGallery(
  sharedGalleryHash,
  { currentPage, itemsPerPage },
  {
    placeholderData: keepPreviousData,
  }
);

const gallery = computed(() => galleryData.value?.data);

const assets = computed(() => gallery.value?.assets);
const account_name = computed(() => gallery.value?.account_name);
const reservationName = computed(() => gallery.value?.name);

const numberOfFiles = computed(() => gallery.value?.number_of_files);

const galleryPagination = computed(() => {
  if (galleryData.value) {
    return galleryData.value.pagination;
  } else {
    return {
      currentPage: 0,
      itemsPerPage: 0,
      totalCount: 0,
      totalPages: 0,
    };
  }
});

watch(currentPage, () =>
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
);
watch(itemsPerPage, () => (currentPage.value = 1));

const { linkClicked, pageViewed } = useBaseEvents();
const route = useRoute();

const isViewable = computed(() => {
  return [
    'in_progress',
    'pending_selects',
    'edits_required',
    'completed',
  ].includes(gallery.value?.status);
});

const notViewableCopy = computed(() => {
  return !gallery.value?.error && !isViewable.value
    ? "shoot hasn't started yet, check back soon"
    : 'sorry, something went wrong';
});

const pageLoading = computed(() => {
  return isFetching.value || isLoading.value;
});

onMounted(() => {
  pageViewed();
});

const mediaIconOverlay = media_type => {
  switch (media_type) {
    case 'video':
      return 'play';
    case 'animation':
      return 'gif-text';
    default:
      return null;
  }
};
</script>

<template>
  <section
    v-if="
      (gallery?.error || loadSharedGalleryError || !isViewable) && !pageLoading
    "
    class="container justify-center"
  >
    <div class="notification w-75">
      <h1 class="title">
        {{ notViewableCopy }}
      </h1>
    </div>
  </section>
  <div v-else class="shared-gallery">
    <SoonaLoading
      v-if="pageLoading"
      is-loading
      loading-text="loading"
      is-contained
    />
    <template v-if="isSuccess">
      <div class="shared-gallery__content-bar">
        <SoonaIcon name="heart" />
        <div>
          this custom content was created at
          <a href="https://soona.co">soona</a> and is available for
          {{ account_name }} to shop.
        </div>
        <div>
          <SoonaButton
            element="a"
            href="https://soona.co"
            variation="tertiary"
            @on-click="
              linkClicked({
                context: route.meta.context,
                subContext: 'call to action bar',
                linkLabel: 'create with soona',
                linkHref: '',
              })
            "
          >
            create with soona
          </SoonaButton>
        </div>
      </div>
      <div class="u-container shared-gallery__container">
        <div class="shared-gallery__heading">
          <h2 class="shared-gallery__welcome">
            welcome to {{ account_name }}’s gallery
          </h2>
          <div class="shared-gallery__subtitle">
            for their shoot: {{ reservationName }}
          </div>
        </div>
        <PaginatorFull
          v-model:page="currentPage"
          v-model:records-per-page="itemsPerPage"
          class="shared-gallery__pagination__top"
          :has-full-width-nav="true"
          :hide-change-records-per-page="true"
          :page-count="assets.length"
          :total-count="numberOfFiles"
          :total-pages="galleryPagination?.totalPages"
        />
        <div class="shared-gallery__gallery">
          <button
            v-for="file in assets"
            :key="file.id"
            class="u-button-reset shared-gallery__gallery-item"
            @click="currentPreviewFile = file"
          >
            <img
              :src="file.preview?.url"
              class="shared-gallery__gallery-item__image"
              alt="shared-gallery-image"
            />
            <span
              v-if="mediaIconOverlay(file.media_type)"
              class="shared-gallery__gallery-item__icon"
            >
              <SoonaIcon :name="mediaIconOverlay(file.media_type)" />
              <span class="u-visually-hidden">{{ file.media_type }}</span>
            </span>
          </button>
          <SoonaReservationFilePreview
            v-if="currentPreviewFile"
            :title="
              currentPreviewFile?.title || `image ${currentPreviewFile?.id}`
            "
            :is-gif="currentPreviewFile?.media_type === 'animation'"
            :is-video="currentPreviewFile?.media_type === 'video' || false"
            :watermark-url="currentPreviewFile?.watermark?.url || ''"
            :image-url="currentPreviewFile?.preview?.url || ''"
            :content-type="currentPreviewFile?.content_type || ''"
            @close="() => (currentPreviewFile = null)"
          />
        </div>
        <PaginatorFull
          v-model:page="currentPage"
          v-model:records-per-page="itemsPerPage"
          class="shared-gallery__pagination__bottom"
          :has-full-width-nav="true"
          :hide-change-records-per-page="true"
          :page-count="assets.length"
          :total-count="numberOfFiles"
          :total-pages="galleryPagination?.totalPages"
        />
      </div>
    </template>
    <hr class="shared-gallery__how" />
    <HowItWorks :is-authenticated="false" />
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.shared-gallery {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__pagination {
    &__top {
      margin-bottom: 2rem;
    }
    &__bottom {
      margin-top: 2rem;
    }
  }

  &__content-bar {
    background-color: variables.$green-apple-10;
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.6875rem;
    margin-bottom: 2rem;
    @include variables_fonts.u-body--heavy;

    a {
      text-decoration: underline;
    }
  }

  &__container {
    margin-bottom: 2rem;
    flex-grow: 1;
  }

  &__how {
    border-top: 0.0625rem solid variables.$black-default;
    margin: 0;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding-bottom: 2rem;
  }

  &__welcome {
    @include variables_fonts.u-headline--heavy;
  }

  &__subtitle {
    @include variables_fonts.u-subheader--regular;
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 0.33rem;
  }

  &__gallery-item {
    position: relative;
    display: block;

    &__image {
      display: block;
      height: 14.0625rem;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -2.3125rem 0 0 -2.3125rem;
      height: 4.625rem;
      width: 4.625rem;
      color: variables.$white-default;
      border-radius: 2.3125rem;
      background-color: rgba(0, 0, 0, 0.2);

      svg {
        display: block;
        height: 2rem;
        width: 2rem;
      }
    }

    @media (max-width: variables.$screen-sm-max) {
      &__image {
        height: auto;
      }
    }
  }

  @media (max-width: variables.$screen-sm-max) {
    &__content-bar {
      display: block;
    }
  }
}
</style>

<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

const emit = defineEmits(['create-brief-click']);

const route = useRoute();
const { linkClicked } = useBaseEvents();
function onBriefClick() {
  linkClicked({
    context: route.meta.context,
    subContext: 'user generated content',
    linkLabel: 'create a free brief',
    linkHref: null,
  });

  emit('create-brief-click');
}
</script>

<template>
  <div class="ugc-testimonial">
    <div class="ugc-testimonial__ugc-by-trend-card">
      <h2 class="u-display--heavy">
        user generated content at soona is powered by
        <img
          class="trend-logo"
          src="@images/trend-by-soona.svg"
          alt="Trend by soona"
          width="128"
          height="36"
          style="width: 8rem; height: 2.25rem"
        />
      </h2>
      <SoonaButton
        variation="solid-black"
        size="large"
        class="trend-header__inner__content__brief-button"
        @on-click="onBriefClick"
      >
        create a free brief
      </SoonaButton>
    </div>
    <div class="ugc-testimonial__testimonial-card">
      <div class="ugc-testimonial__testimonial-card__squid-column">
        <div
          class="ugc-testimonial__testimonial-card__squid-column__quote-header"
        >
          <img
            src="@images/trend/squid_logo@2x.png"
            alt="user generated content testimonial"
          />
          <p
            class="u-subheader--heavy ugc-testimonial__testimonial-card__squid-column__quote-header_info"
          >
            the results?<br />8 million views and counting 👀
          </p>
        </div>
        <blockquote class="u-headline--regular">
          <q
            >your UGC literally changed everything overnight for me. Like not,
            not even kidding. I can’t even explain to you.</q
          >
        </blockquote>
      </div>
      <img
        src="@images/trend/squid_ugc@2x.jpg"
        class="ugc-testimonial__testimonial-card__ugc-example"
        alt="User Generated Content Testimonial"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.ugc-testimonial {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  gap: 1.5rem;

  &__ugc-by-trend-card,
  &__testimonial-card {
    display: flex;
    flex-direction: column;
    background-color: variables.$green-apple-30;
    border: 0.0625rem solid variables.$black-default;
    border-radius: 0.9375rem;
    padding: 1.25rem;
  }

  &__ugc-by-trend-card {
    flex: 2 2 17.5rem;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    .trend-logo {
      margin-bottom: -0.75rem;
    }
  }

  &__testimonial-card {
    flex: 3 3 25rem;
    flex-direction: row;

    &__squid-column {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &__quote-header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 1rem;

        img {
          border-radius: 1rem;
          width: 4rem;
          height: 4rem;
        }

        &__info {
          flex: 1 1 7rem;
        }
      }
    }

    &__ugc-example {
      display: none;
      align-self: center;
      width: 10.75rem;
      border-radius: 1rem;
      border: 0.0625rem solid variables.$black-default;
    }
  }
}

@mixin testimonial-large-styles {
  .ugc-testimonial {
    margin-block: 5.75rem;

    &__ugc-by-trend-card {
      padding: 2.75rem;
    }

    &__testimonial-card {
      padding: 1.875rem;
      gap: 3rem;

      &__squid-column {
        margin-top: 0.875rem;
      }

      &__ugc-example {
        display: block;
      }
    }
  }
}

@container ugc-page (min-width: 46rem) {
  @include testimonial-large-styles;
}

/* compat: remove when dropping Safari 15 */
@supports not (container-type: inline-size) {
  @media (min-width: 46rem) {
    @include testimonial-large-styles;
  }
}
</style>

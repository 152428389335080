<template>
  <div class="Timeline">
    <NoteInput
      :reservation-id="reservationId"
      :is-pending="isPendingNote"
      :error="createNoteError"
      @submit-note="handleCreateNote"
    />
    <div class="items">
      <div v-for="item in items" :key="item.key" class="item">
        <CustomerNote
          v-if="item.type === 'customerNote' && item['item'].content != null"
          :note="item.item"
        />
        <CrewNote
          v-if="item.type === 'note'"
          :note="item.item"
          @delete-note="handleDeleteNote"
        />
        <TimelineAddOnOrder
          v-if="item.type === 'order' && item.item.order_type === 'add_on'"
          :order="item.item"
          :reservation="currentReservation"
        />
        <TimelineOrder
          v-else-if="item.type === 'order'"
          :order="item.item"
          :reservation="currentReservation"
        />
        <ShootExceptionNote
          v-if="item.type === 'shoot_exception'"
          :shoot-exception="item.item"
          :reservation="currentReservation"
        />
      </div>
      <SuggestedBookingCreated
        v-if="currentReservation.draft_type === 'suggested_draft'"
        :suggested-booking="currentReservation"
        :creator="downPaymentOrderCreator"
      />
    </div>
  </div>
</template>

<script>
import { computed, toRef } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import NoteInput from 'src/components/user/anytime/note/NoteInput.vue';
import CrewNote from 'src/components/user/anytime/note/CrewNote.vue';
import CustomerNote from 'src/components/user/anytime/note/CustomerNote.vue';
import TimelineAddOnOrder from 'src/components/user/anytime/crew/TimelineAddOnOrder.vue';
import TimelineOrder from 'src/components/user/anytime/crew/TimelineOrder.vue';
import ShootExceptionNote from 'src/components/user/anytime/crew/ShootExceptionNote.vue';
import SuggestedBookingCreated from 'src/components/user/anytime/crew/SuggestedBookingCreated.vue';
import { useReservationOrders } from 'src/queries/reservations/useReservationOrders.js';
import { useCapability } from 'src/composables/useCapability';
import { useCreateNote } from '../../../../queries/notes/useCreateNote';
import { useDeleteNote } from '../../../../queries/notes/useDeleteNote';
import { useNotes } from '../../../../queries/notes/useNotes';

export default {
  components: {
    NoteInput,
    CrewNote,
    TimelineAddOnOrder,
    TimelineOrder,
    ShootExceptionNote,
    CustomerNote,
    SuggestedBookingCreated,
  },
  props: {
    reservationId: Number,
  },
  setup(props) {
    const { hasCapability: canViewTimelineItems } = useCapability({
      capability: 'view_timeline_items',
    });

    const reservationId = toRef(props, 'reservationId');
    const { data: reservationNotes } = useNotes('reservations', reservationId);
    const {
      mutate: createNote,
      isPending: isPendingNote,
      error: createNoteError,
    } = useCreateNote('reservations', reservationId);
    const { mutate: deleteNote } = useDeleteNote('reservations', reservationId);

    const loadOrders = computed(
      () => reservationId.value && canViewTimelineItems.value
    );
    const { data: orders } = useReservationOrders(reservationId, {
      enabled: loadOrders,
    });

    const handleCreateNote = (reply, assets, noteType) => {
      createNote({
        content: reply,
        assets: assets,
        note_type: noteType,
      });
    };

    const handleDeleteNote = note => {
      deleteNote({ noteId: note.id });
    };

    return {
      handleCreateNote,
      handleDeleteNote,
      reservationNotes: computed(() => reservationNotes.value?.notes),
      orders: computed(() => orders.value),
      isPendingNote,
      createNoteError,
    };
  },
  data() {
    return {
      showContinuationDetailModal: false,
      customerShotListNote: {},
    };
  },
  computed: {
    ...mapGetters('reservation', ['currentReservation', 'shotListNote']),
    downPaymentOrderCreator() {
      const downpaymentOrder = this.orders?.find(
        order => order.order_type === 'down_payment'
      );
      return downpaymentOrder?.creator.name;
    },
    items() {
      const notes =
        this.reservationNotes?.map(note => ({
          key: 'note_' + note.id,
          type: 'note',
          created: new Date(note.created_at),
          item: note,
        })) || [];

      const customerNotes = [
        {
          key: 'customer-shot-list-note',
          type: 'customerNote',
          item: this.customerShotListNote,
        },
      ];

      const orders =
        this.orders?.map(order => ({
          key: 'order_' + order.id,
          type: 'order',
          created: order.date_collected
            ? new Date(order.date_collected)
            : new Date(order.created_at),
          item: order,
        })) || [];

      const shootExceptions =
        this.currentReservation?.shoot_exceptions?.map(shootException => ({
          key: 'shoot_exception_' + shootException.id,
          type: 'shoot_exception',
          created: new Date(shootException.created_at),
          item: shootException,
        })) || [];

      return [...orders, ...shootExceptions, ...notes, ...customerNotes].sort(
        (a, b) => b.created - a.created
      );
    },
  },
  mounted() {
    this.customerShotListNote = {
      content: this.shotListNote,
      creator: {
        name: this.currentReservation.account.name,
      },
    };
  },
  beforeUnmount() {
    this.clearOrders();
  },
  methods: {
    ...mapActions('reservation', ['loadOrders', 'clearOrders']),
    openContinuationModal() {
      this.showContinuationDetailModal = true;
    },
    closeContinuationModal() {
      this.showContinuationDetailModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.Timeline {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
</style>

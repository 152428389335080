<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import SoonaTab from 'src/components/ui_library/SoonaTab.vue';

const route = useRoute();
const isHappeningNow = computed(() => route.name == 'happeningNow');
const isPendingSelects = computed(() => route.name == 'pendingSelects');
const isReadyToEdit = computed(() => route.name == 'readyToEdit');
const isCompletedOrders = computed(() => route.name == 'completedOrders');
</script>

<template>
  <nav class="admin-bookings-nav">
    <SoonaTab
      data-cypress="tab-bookings-happening-now"
      copy="HAPPENING NOW"
      :to="{ name: 'happeningNow' }"
      :is-selected="isHappeningNow"
    />
    <SoonaTab
      data-cypress="tab-bookings-pending-selects"
      copy="PENDING SELECTS"
      :to="{ name: 'pendingSelects' }"
      :is-selected="isPendingSelects"
    />
    <SoonaTab
      data-cypress="tab-bookings-ready-to-edit"
      copy="READY TO EDIT"
      :to="{ name: 'readyToEdit' }"
      :is-selected="isReadyToEdit"
    />
    <SoonaTab
      data-cypress="tab-bookings-ready-completed-orders"
      copy="COMPLETED ORDERS"
      :to="{ name: 'completedOrders' }"
      :is-selected="isCompletedOrders"
    />
  </nav>
</template>

<style lang="scss" scoped>
.admin-bookings-nav {
  margin-bottom: 1rem;
  overflow-x: auto;
  display: flex;
}
</style>

<script setup>
import { ref } from 'vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import IntegrateAccountsDialog from '@/components/shared/IntegrateAccountsDialog.vue';

const displayDialog = ref(false);
</script>

<template>
  <div class="get-your-score-card__wrapper">
    <button
      class="get-your-score-card u-button-reset"
      @click="displayDialog = true"
    >
      <div class="get-your-score-card__product-image-cell">
        <img :src="PlaceholderImage" alt="" />
      </div>
      <div class="get-your-score-card__content">
        <span class="get-your-score-card__product-name">
          get your score
          <SoonaIcon
            name="arrow-right"
            class="get-your-score-card__product-name--icon"
          />
        </span>
      </div>
    </button>
    <IntegrateAccountsDialog
      v-if="displayDialog"
      @close="displayDialog = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.get-your-score-card {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 0.25rem;
  position: relative;
  background-color: variables.$periwink-blue-10;
  border: 0.125rem dashed variables.$gray-30;
  border-radius: 0.625rem;
  min-height: 4.5rem;
  width: 100%;
  overflow: hidden;

  &:hover {
    background-color: variables.$periwink-blue-20;
    border-color: variables.$periwink-blue-70;

    .get-your-score-card__product-name--icon {
      transform: translateX(0.1875rem);
    }
  }

  &:focus-visible {
    outline: 0.125rem dashed variables.$periwink-blue-70;
    border-color: transparent;

    .get-your-score-card__product-name--icon {
      transform: translateX(0.1875rem);
    }
  }

  &__product-image-cell {
    flex: 0 0 4.5rem;
    position: relative;
    background-color: variables.$gray-20;
    min-height: 4.5rem;
    border-radius: 0.625rem;

    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }

  &__content {
    flex: 1 1 12rem;
    padding: 0.5rem 0 0.5rem 1rem;
    margin: auto;
  }

  &__product-name {
    @include variables_fonts.u-body--heavy;

    display: flex;
    align-items: center;

    &--icon {
      transition: transform 0.1s ease-out;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import uniqueId from 'lodash/uniqueId';
import {
  GradientRedStart,
  GradientRedEnd,
  GradientGreenStart,
  GradientGreenEnd,
  GradientGoldStart,
  GradientGoldEnd,
} from 'src/variables.module.scss';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      value: 0,
      label: '',
      minValue: 0,
      maxValue: 1,
      low: 0.33,
      high: 0.66,
    }),
    validator: value =>
      // check that value is in between min and max values
      value.value <= value.maxValue && value.value >= value.minValue,
  },
  accessibleTitle: {
    type: String,
    required: true,
  },
  accessibleDescription: {
    type: String,
    required: false,
    validator(value, props) {
      return value !== props.accessibleTitle;
    },
  },
  isLoading: { type: Boolean, default: false },
  size: {
    type: String,
    default: 'large',
    validator: value => ['small', 'large'].includes(value),
  },
});
const id = uniqueId('soona-meter-chart-');

const percentageValue = computed(() => props.data.value * 100);

const iconLookup = {
  poor: 'triangle-exclamation',
  okay: 'circle-check',
  great: 'dazzling-star',
};

const colorLookupStart = {
  poor: GradientRedStart,
  okay: GradientGoldStart,
  great: GradientGreenStart,
};

const colorLookupEnd = {
  poor: GradientRedEnd,
  okay: GradientGoldEnd,
  great: GradientGreenEnd,
};
</script>

<template>
  <SoonaSkeleton
    v-if="isLoading"
    :class="`soona-meter__skeleton soona-meter__skeleton--${size}`"
  />
  <figure v-else :class="`soona-meter soona-meter--${size}`">
    <div
      :aria-valuenow="data.value"
      :aria-valuemin="data.minValue"
      :aria-valuemax="data.maxValue"
      :aria-labelledby="`${id}-title ${id}-desc`"
    >
      <svg
        role="meter"
        width="100%"
        height="100%"
        class="soona-meter__fill"
        aria-hidden="true"
      >
        <defs>
          <linearGradient
            :id="`gradient-${data.label}`"
            x1="0"
            x2="0"
            y1="1"
            y2="0"
          >
            <stop offset="0%" :stop-color="colorLookupStart[data.label]" />
            <stop offset="100%" :stop-color="colorLookupEnd[data.label]" />
          </linearGradient>
        </defs>
        <title :id="`${id}-title`">{{ accessibleTitle }}</title>
        <desc v-if="accessibleDescription" :id="`${id}-desc`">
          {{ accessibleDescription }}
        </desc>

        <rect
          x="0"
          y="0"
          :width="`${percentageValue}%`"
          height="100%"
          :fill="`url(#gradient-${data.label})`"
        ></rect>
        <template v-if="size === 'large'">
          <line x1="33%" y1="0" x2="33%" y2="100%" stroke="#00000014" />
          <line x1="66%" y1="0" x2="66%" y2="100%" stroke="#00000014" />

          <SoonaIcon
            v-if="data.label"
            :name="iconLookup[data.label]"
            size="medium"
            x="10"
            y="12"
          />
          <text
            x="33"
            y="22"
            dominant-baseline="middle"
            font-weight="800"
            font-variant="all-small-caps"
          >
            {{ data.label }}
          </text>
        </template>
      </svg>
    </div>
  </figure>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-meter {
  margin: 1rem 0;

  [role='meter'] {
    width: 100%;
    height: 2.625rem;
    border-radius: 0.3125rem;
    background: linear-gradient(
      360deg,
      variables.$gradient-periwink-start 0%,
      variables.$gradient-periwink-end 100%
    );
  }

  &--small {
    margin: 0;
    [role='meter'] {
      height: 0.5rem;
      border-radius: 1rem;
    }
  }

  &__fill {
    height: 100%;
    box-sizing: border-box;
    border-radius: 0.3125rem;
  }

  &__skeleton {
    height: 2.625rem;
    width: 100%;
    border-radius: 0.3125rem;
    margin: 1rem 0;

    &--small {
      height: 0.5rem;
      border-radius: 1rem;
    }
  }
}
</style>

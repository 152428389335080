import { ref } from 'vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { usePostResetPassword } from '@/queries/users/useResetPassword';

export const useResetPassword = () => {
  const {
    mutate: resetPasswordMutation,
    error: resetPasswordError,
    reset: resetResetPassword,
  } = usePostResetPassword();

  const errorMessages = usePriorityErrors(resetPasswordError);

  const username = ref(null);

  function resetErrors() {
    resetResetPassword();
  }

  async function resetPassword(emailType = null) {
    resetErrors();
    resetPasswordMutation(
      {
        username: username.value,
        emailType: emailType,
      },
      {
        onSuccess: () => {
          window.location.href = '/#/';
        },
      }
    );
  }

  return {
    username,
    errorMessages,
    resetPassword,
    resetErrors,
  };
};

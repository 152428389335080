import { http } from '../config/vue-axios';

/**
 *
 * @param {Object} [params]
 * @param {string | number} [params.proServiceTypeId]
 * @param {Object} [params.availability]
 * @param {string | number} [params.proServiceCategoryId]
 * @param {Array<number>} [params.locationIds]
 * @param {string} [params.query]
 * @param {Array<object>} [params.filters]
 * @param {number} [params.workedWithAccountId]
 * @param {boolean} [params.isActive]
 * @param {string} [params.sort]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {AbortSignal} [signal]
 */
export async function getProServiceProfiles(
  {
    proServiceTypeId,
    availability,
    proServiceCategoryId,
    locationIds,
    query,
    filters,
    workedWithAccountId,
    isActive,
    sort,
    currentPage,
    itemsPerPage,
  } = {},
  signal
) {
  const proServiceType = {
    id: proServiceTypeId,
    category_id: proServiceCategoryId,
    select_type: 'single',
  };
  const response = await http.post('pro_service_profiles', {
    pro_service_type: proServiceType,
    availability: availability,
    location_ids: locationIds,
    search: query,
    filters: filters,
    worked_with_account_id: workedWithAccountId,
    is_active: isActive,
    sort: sort,
    page: currentPage,
    per: itemsPerPage,
    signal,
  });
  return {
    pagination: {
      currentPage: response.data.page,
      itemsPerPage: response.data.per,
      totalCount: response.data.totalCount,
      totalPages: response.data.totalPages,
    },
    proServiceProfiles: response.data.pro_service_profiles,
    formattedFilters: response.data.formatted_filters,
  };
}

/**
 *
 * @param {number} [proServiceProfileId]
 * @param {AbortSignal} [signal]
 */
export async function getProServiceProfile(proServiceProfileId, signal) {
  const response = await http.get(
    `/pro_service_profiles/${proServiceProfileId}.json`,
    {
      signal,
    }
  );
  return response.data;
}

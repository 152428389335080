<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  selectedBasicInfoCategories: {
    required: true,
    type: Array,
  },
  selectedSizesTalentsCategories: {
    required: true,
    type: Array,
  },
  service: {
    required: true,
    type: Object,
  },
});

const service = computed(() => props.service);

const filterMeasures = computed(() =>
  service.value.measurements.filter(meas => meas.value !== '')
);

const sampleWorkUrl = computed(() => {
  if (service.value.sample_work.includes('http')) {
    return service.value.sample_work;
  }

  return '//' + service.value.sample_work;
});

const formatServiceMeasurements = item => {
  const measurment = service.value.required_measurements.find(
    m => m.measurement_key === item.key
  );
  if (item.key === 'height') {
    const feet = Math.floor(item.value / 12);
    const inches = item.value % 12;

    return `${feet}ft. ${inches}in.`;
  } else {
    return `${item.value}${measurment.format}.`;
  }
};
</script>

<template>
  <div class="saved-traits-section">
    <div class="selected-display-name">
      <p v-if="!!service.name">
        <strong>display name:</strong> {{ service.name }}
      </p>
    </div>
    <div class="selector-traits-section">
      <div class="special-category-section">
        <div v-for="item in selectedBasicInfoCategories" :key="item.id">
          <p>
            <strong>{{ item.traitTitle }}:</strong>
            {{ item.selectedTraits }}
          </p>
        </div>
      </div>
      <div class="special-category-section">
        <div v-for="item in selectedSizesTalentsCategories" :key="item.id">
          <p>
            <strong>{{ item.traitTitle }}:</strong>
            {{ item.selectedTraits }}
          </p>
        </div>
      </div>
      <div v-if="service.measurements" class="special-category-section">
        <p v-for="item in filterMeasures" :key="item.key">
          <strong>{{ item.key }}:</strong>
          {{ ' ' + formatServiceMeasurements(item) }}
        </p>
      </div>
    </div>
    <p v-if="!!service.sample_work">
      <strong>sample work: </strong>
      <SoonaButton
        class="sample-work-link"
        element="a"
        variation="tertiary"
        target="_blank"
        :href="sampleWorkUrl"
      >
        {{ service.sample_work }}
      </SoonaButton>
    </p>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.saved-traits-section {
  width: 100%;

  .selected-display-name {
    margin-bottom: none;
  }

  .sample-work-link {
    text-decoration: underline;
  }

  .selector-traits-section {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .special-category-section {
    width: 100%;

    p {
      margin-bottom: 0.5rem;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    .selected-display-name {
      margin-bottom: 1rem;
    }

    .selector-traits-section {
      justify-content: normal;
      flex-direction: row;
    }

    .special-category-section {
      margin-right: 3rem;
      width: 33.3333%;

      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>

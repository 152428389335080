<template>
  <div
    class="soona-tag-option-card-main-image"
    :class="{
      'soona-tag-option-card-main-image--vertical': layout === 'vertical',
    }"
  >
    <figure
      v-if="!hasCarousel"
      class="soona-tag-option-card-main-image__image-wrapper"
    >
      <div
        v-for="(image, i) in imagesToRender"
        :key="`${image.src}-${i}`"
        class="soona-tag-option-card-main-image__image"
        :class="imageClass"
      >
        <img :src="image.src" :alt="image.alt" />
      </div>
    </figure>
    <div v-else class="soona-tag-option-card-main-image__carousel-wrapper">
      <SoonaCarousel
        :images="imageList"
        :image-pips="true"
        :wrap-around="false"
        height="100%"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
import SoonaCarousel from 'src/components/ui_library/SoonaCarousel.vue';

export default {
  components: {
    SoonaCarousel,
  },
  props: {
    hasCarousel: {
      default: false,
      required: false,
      type: Boolean,
    },
    imageList: {
      default: () => [], // FORMAT: { src: require('images/booking/food.jpg'), alt: 'food' }
      required: false,
      type: Array,
    },
    layout: {
      required: true,
      type: String,
    },
  },
  computed: {
    imageClass() {
      if (this.hasCarousel || this.imageList.length <= 1) return undefined;
      if (this.imageList.length === 2)
        return 'soona-tag-option-card-main-image__image--double';
      return 'soona-tag-option-card-main-image__image--triple';
    },
    imagesToRender() {
      if (!this.hasCarousel) return this.imageList.slice(0, 3);
      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.soona-tag-option-card-main-image {
  padding-bottom: 1rem;

  @media (min-width: variables.$screen-sm-min) {
    padding: 1rem;
    padding-right: 0.75rem;
    width: 50%;
  }

  &--vertical {
    width: 100%;

    @media (min-width: variables.$screen-sm-min) {
      padding-right: 1rem;
    }

    .soona-tag-option-card-main-image__image-wrapper {
      display: grid;
      gap: 1rem;
      grid-template-columns: 1.3fr 0.7fr;
      grid-template-rows: 1.1fr 1.1fr;
      max-height: none;

      .soona-tag-option-card-main-image__image {
        max-height: none;

        @media (min-width: variables.$screen-sm-min) {
          min-height: 15rem;
          max-height: 0;
        }

        @media (min-width: variables.$screen-lg-min) {
          min-height: 16rem;
          max-height: 0;
        }
      }
    }
  }

  &__image-wrapper {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1.3fr 0.7fr;
    grid-template-rows: 1.1fr 1.1fr;
    height: 100%;
    max-height: 11rem;

    @media (min-width: variables.$screen-sm-min) {
      max-height: none;
    }
  }

  &__image {
    display: block;
    grid-area: 1 / 1 / 3 / 3;

    > img {
      border-radius: 0.625rem;
      object-fit: cover;
      height: 100%;
      width: 100%;
      display: block;
    }

    @media (max-width: variables.$screen-xs-max) {
      &:not(:first-child) {
        display: none;
      }
    }

    @media (min-width: variables.$screen-sm-min) {
      &--double {
        &:first-child {
          grid-area: 1 / 1 / 3 / 2;
        }

        &:nth-child(2) {
          grid-area: 1 / 2 / 3 / 3;
        }
      }

      &--triple {
        &:first-child {
          grid-area: 1 / 1 / 3 / 2;
        }

        &:nth-child(2) {
          grid-area: 1 / 2 / 2 / 2;
        }

        &:nth-child(3) {
          grid-area: 2 / 2 / 3 / 3;
        }
      }
    }
  }

  :deep(.image-pips-wrapper) {
    height: 350px;
    width: 100%;

    img {
      border-radius: 0.625rem;
    }
  }
}
</style>

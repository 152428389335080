<script setup>
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const description = defineModel('description', {
  required: true,
  type: String,
});
</script>

<template>
  <section class="scene-description">
    <label class="u-subheader--heavy" for="scene-description">
      description
    </label>
    <p class="u-label--regular section-subheading">(optional)</p>
    <SoonaTextfield
      id="scene-description"
      v-model="description"
      element="textarea"
      placeholder="describe the angles or shots you want to capture."
      data-cypress="input-description"
    />
  </section>
</template>

<style lang="scss" scoped>
@use '@/variables';

.section-subheading {
  color: variables.$gray-70;
  padding-bottom: 0.5rem;
}
</style>

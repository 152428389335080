import { computed } from 'vue';
import { useReEditsCollection as useReEditsCollectionQuery } from '@/queries/re_edits_collections/useReEditsCollection';

export function useReEditsCollection(accountId, collectionId) {
  const { data: reEditsCollection, isLoading } = useReEditsCollectionQuery(
    accountId,
    collectionId,
    {
      enabled: computed(() => !!accountId.value && !!collectionId.value),
    }
  );

  const collectionStatus = computed(() => {
    return reEditsCollection.value?.options?.status ?? null;
  });

  const isDraft = computed(() => {
    return collectionStatus.value === 'draft';
  });

  const isSubmitted = computed(() => {
    return collectionStatus.value === 'submitted';
  });

  const lastStatusTransition = computed(() => {
    const statusTransitions =
      reEditsCollection.value?.options?.status_transitions;

    return statusTransitions?.length > 0
      ? statusTransitions[statusTransitions.length - 1]
      : null;
  });

  const submittedTimeAndDate = computed(() => {
    const date = new Date(lastStatusTransition.value?.updated_at);
    let time = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });
    return `${time} on ${date.toLocaleDateString()}`;
  });

  return {
    collectionStatus,
    isDraft,
    isLoading,
    isSubmitted,
    lastStatusTransition,
    reEditsCollection,
    submittedTimeAndDate,
  };
}

<script setup>
import { computed } from 'vue';
import ShopifyIntegrationPrimarySelect from '@/components/user/anytime/products/shopify/ShopifyIntegrationPrimarySelect.vue';
import { useIntegrations } from '@/composables/useIntegrations';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});
const accountId = computed(() => Number(props.accountId));

const { hasShopifyIntegration } = useIntegrations(accountId);
</script>

<template>
  <div v-if="hasShopifyIntegration">
    <ShopifyIntegrationPrimarySelect
      class="shopify-integration-select"
      label="billed Shopify store"
      :account-id="accountId"
    />
  </div>
</template>

<style lang="scss" scoped>
.shopify-integration-select {
  margin: 0 0.75rem;
}
</style>

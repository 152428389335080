<template>
  <div data-cypress="flash">
    <TransitionGroup name="fade" appear>
      <SoonaError
        v-for="message in errorMessages"
        :key="message.text + message.type + message.timeout"
      >
        {{ message.text }}
      </SoonaError>
      <SoonaSuccess
        v-for="message in successMessages"
        :key="message.text + message.type + message.timeout"
      >
        {{ message.text }}
      </SoonaSuccess>
      <SoonaInfo
        v-for="message in infoMessages"
        :key="message.text + message.type + message.timeout"
      >
        {{ message.text }}
      </SoonaInfo>
      <SoonaAlert
        v-for="message in warningMessages"
        :key="message.text + message.type + message.timeout"
      >
        {{ message.text }}
      </SoonaAlert>
    </TransitionGroup>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import * as types from 'src/store/mutation-types';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaInfo from '@/components/ui_library/SoonaInfo.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaSuccess from '@/components/ui_library/SoonaSuccess.vue';

export default {
  components: {
    SoonaSuccess,
    SoonaAlert,
    SoonaInfo,
    SoonaError,
  },
  computed: {
    ...mapState({
      messages: state => state.flash.messages,
    }),
    ...mapGetters('flash', [
      'errorMessages',
      'successMessages',
      'warningMessages',
      'infoMessages',
    ]),
  },
  mounted: function () {
    this.timeoutMessages();
  },
  updated: function () {
    this.timeoutMessages();
  },
  methods: {
    ...mapMutations('flash', [types.REMOVE_FLASH_MESSAGE]),
    timeoutMessages: function () {
      for (var i = this.messages.length - 1; i >= 0; i--) {
        let message = this.messages[i];
        let timeout = Object.prototype.hasOwnProperty.call(message, 'timeout')
          ? message.timeout
          : true;
        if (timeout) {
          let delay = message.delay || 30000;
          this.timer = setTimeout(
            function () {
              this.REMOVE_FLASH_MESSAGE(message);
            }.bind(this),
            delay
          );
        }
      }
    },
  },
};
</script>

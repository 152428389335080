<script setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import { usePackConfigurationState } from '@/components/crew/pack_builder/usePackConfigurationState';
import TitleBar from 'src/components/shared/TitleBar.vue';
import PackBuilderSidebar from './PackBuilderSidebar.vue';
import PackBuilderNavigation from './PackBuilderNavigation.vue';
import PackBuilderFooter from './PackBuilderFooter.vue';
import PackPreview from '@/components/crew/pack_builder/sections/PackPreview.vue';
import PackInfo from '@/components/crew/pack_builder/sections/PackInfo.vue';
import PackShotList from '@/components/crew/pack_builder/sections/PackShotList.vue';
import ReservationSummary from '@/components/crew/pack_builder/sections/PackReservationSummary.vue';

const props = defineProps({
  packConfigurationId: {
    type: String,
    required: true,
  },
});

const packId = computed(() => props.packConfigurationId);

const route = useRoute();
const router = useRouter();

const {
  discardChanges,
  hasUnsavedChanges,
  hasUnsavedProServiceChanges,
  isMutating,
  isSuccess,
  pack,
  save,
  savePackName,
} = usePackConfigurationState(packId);

const sections = computed(() => [
  {
    slug: 'pack-info',
    name: 'pack info',
    to: `/crew/packs/${packId.value}/edit`,
  },
  {
    slug: 'reservation-summary',
    name: 'reservation summary',
    to: { query: { section: 'reservation-summary' } },
  },
  {
    slug: 'shot-list',
    name: 'shot list',
    to: { query: { section: 'shot-list' } },
  },
  {
    slug: 'preview',
    name: 'preview',
    to: { query: { section: 'preview' } },
  },
]);

const currentPage = computed(() => {
  if (Object.keys(route.query).length === 0) {
    return 'pack-info';
  }
  return route.query.section;
});

const previousPage = () => {
  router.push('/crew/packs');
};

function saveAndExit() {
  save(() => {
    router.push('/crew/packs');
  });
}

const packNameEditMode = ref(false);

const saveUpdatedPackName = () => {
  packNameEditMode.value = false;
  savePackName();
};

const discardUnsavedChanges = () => {
  if (packNameEditMode.value) {
    packNameEditMode.value = false;
  }
  discardChanges();
};

const publishedOrArchivedPack = computed(() =>
  ['published', 'archived'].includes(pack.status)
);

const packName = computed(
  () => `${pack.name} ${pack.status === 'archived' ? '(archived)' : ''}`
);

const packVisibility = computed(() => {
  return pack.hide_in_booking
    ? 'this pack is hidden in the booking flow.'
    : null;
});

onBeforeRouteUpdate(() => {
  if (packNameEditMode.value) {
    alert('please save or discard updates to the pack name.');
    return false;
  }
});
</script>

<template>
  <div v-if="isSuccess" class="pack-builder">
    <TitleBar
      :title="packName"
      :subtitle="packVisibility"
      heading-id="pack-builder-heading"
      :previous-page="previousPage"
    />

    <section
      class="pack-builder__content"
      aria-labelledby="pack-builder-heading"
    >
      <PackBuilderNavigation
        :sections="sections"
        :current-page="currentPage"
        :pack-name-edit-mode="packNameEditMode"
      />

      <div class="pack-builder__content-body">
        <PackInfo
          v-if="currentPage === 'pack-info'"
          v-model:name="pack.name"
          v-model:description-body="pack.description.body"
          v-model:includes="pack.description.includes"
          v-model:pack-price="pack.pack_price"
          v-model:pack-product-rate="pack.pack_product.rate"
          v-model:original-price="pack.original_price"
          v-model:display-discount="pack.display_discount"
          v-model:default-recommended-pack="pack.default_recommended_pack"
          v-model:pack-discount="pack.pack_discount"
          v-model:max-quantity="pack.max_quantity"
          v-model:merchandising-tags="pack.merchandising_tags"
          v-model:preview-vimeo-id="pack.preview_vimeo_id"
          v-model:pack-main-image-url="pack.pack_main_image_url"
          v-model:email-template-name="pack.email_template_name"
          :is-pack-video="pack.isPackVideo"
          :category="pack.category"
          :pack-name-edit-mode="packNameEditMode"
          :editing-disabled="publishedOrArchivedPack"
          @save-pack-name="saveUpdatedPackName"
          @enter-edit-mode="packNameEditMode = true"
          @update:pack-main-image="pack.pack_main_image = $event"
        />
        <ReservationSummary
          v-if="currentPage === 'reservation-summary'"
          v-model:product-description="pack.pack_product.description"
          v-model:included-gifs="pack.pack_product.included_gifs"
          v-model:included-photos="pack.pack_product.included_photos"
          v-model:included-videos="pack.pack_product.included_videos"
          v-model:pro-service-products="pack.pro_service_products"
          v-model:photo-segment="pack.photo_segment"
          v-model:thumbnail-url="pack.pack_product.thumbnail_url"
          v-model:thumbnail="pack.pack_product.thumbnail"
          :category="pack.category"
          :editing-disabled="publishedOrArchivedPack"
        />
        <PackShotList
          v-if="currentPage === 'shot-list'"
          v-model:description-shot-list="pack.description.shotlist_header"
          :shot-list="pack.shotTemplates"
          :category="pack.category"
          :pack-id="packId"
          :pack-tag-id="pack.packTagId"
        />
        <PackPreview v-if="currentPage === 'preview'" :pack="pack" />
      </div>
    </section>

    <PackBuilderFooter
      :sections="sections"
      :current-page="currentPage"
      class="pack-builder__footer"
      :is-mutating="isMutating"
      :has-unsaved-changes="hasUnsavedChanges"
      :pack-name-edit-mode="packNameEditMode"
      @discard-changes="discardUnsavedChanges"
      @save-and-exit="saveAndExit"
      @save="save"
    />

    <PackBuilderSidebar
      :pack="pack"
      :current-page="currentPage"
      :has-pro-service-changes="hasUnsavedProServiceChanges"
      class="pack-builder__sidebar"
    />
  </div>
</template>

<style lang="scss">
@use '@/variables';

.pack-builder {
  flex: 1 0 auto;
  margin: -2rem 0;
  display: grid;
  grid-template-areas:
    'title sidebar'
    'content sidebar'
    'footer sidebar';
  /* use minmax with 0 here to prevent blowout */
  grid-template-columns: minmax(0, auto) minmax(auto, 33rem);
  grid-template-rows: auto 1fr auto;

  &__title {
    grid-area: title;
  }

  &__content {
    grid-area: content;
    margin: 0 auto;
    max-width: min(100%, 34.875rem);
  }

  &__footer {
    grid-area: footer;
  }

  &__sidebar {
    grid-area: sidebar;
    border-left: 0.0625rem solid variables.$gray-30;
  }
}
</style>

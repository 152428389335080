<script setup>
import { computed, toRef } from 'vue';
import {
  BubbletapePink20,
  Tangerine20,
  PeriwinkBlue70,
  PeriwinkBlue20,
} from 'src/variables.module.scss';
import { useAccount } from '@/composables/useAccount';
import { useFlag } from '@/composables/useFlag';
import { useStore } from 'vuex';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaImageCard from '@/components/ui_library/SoonaImageCard.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import TrendingFreePack from '@/components/platform-home/TrendingFreePack.vue';
import AiProducerPackRecommendation from '../booking/v3/p2/ai-producer/AiProducerPackRecommendation.vue';

defineProps({
  packs: { type: Array, required: true },
  ariaLabelledby: { type: String, required: true },
  producerPackRecommender: { type: Boolean, default: false },
});

defineEmits(['handle-card-click']);

const store = useStore();

const accountId = computed(() => store.state.currentUser.accountId);
const { account } = useAccount(toRef(() => accountId.value));
const isNewCustomer = computed(() => account.value?.is_new_customer);
const trendingFreePackFlag = useFlag('apollo_trending_free_pack');
const producerFlag = useFlag('pegasus_ai_producer');

const isNew = created => {
  const convertedDate = new Date(created);
  const today = new Date();
  const thirtyDaysAgo = new Date().setDate(today.getDate() - 30);

  return thirtyDaysAgo < convertedDate;
};

const isFeatured = merchandising_tags => {
  return merchandising_tags?.includes('champ');
};

const isTrending = merchandising_tags => {
  return merchandising_tags?.includes('trending');
};
</script>

<template>
  <SoonaSlider tag-name="ul" :aria-labelledby="ariaLabelledby">
    <TrendingFreePack v-if="isNewCustomer && trendingFreePackFlag" />
    <AiProducerPackRecommendation
      v-if="producerFlag && producerPackRecommender"
    />

    <SoonaImageCard
      v-for="pack in packs"
      :key="pack.id"
      animation-delay="0s"
      :cypress-name="`button-slider-${pack.name.replace(/\s+/g, '-')}`"
      @click="() => $emit('handle-card-click', pack)"
    >
      <img :src="pack.pack_main_image_url" />
      <template #flag>
        <SoonaFlag
          v-if="
            isFeatured(pack.merchandising_tags) ||
            isTrending(pack.merchandising_tags) ||
            isNew(pack.created_at)
          "
          :background-color="
            isFeatured(pack.merchandising_tags)
              ? BubbletapePink20
              : isTrending(pack.merchandising_tags)
                ? Tangerine20
                : isNew(pack.created_at)
                  ? PeriwinkBlue20
                  : ''
          "
          :title="
            isFeatured(pack.merchandising_tags)
              ? '👀 featured'
              : isTrending(pack.merchandising_tags)
                ? '🔥 trending'
                : isNew(pack.created_at)
                  ? 'new'
                  : ''
          "
          :text-color="
            isFeatured(pack.merchandising_tags) ||
            isTrending(pack.merchandising_tags)
              ? '#000000'
              : isNew(pack.created_at)
                ? PeriwinkBlue70
                : '#000000'
          "
          type="pill"
        />
      </template>
      <template #caption>{{ pack.name }}</template>
    </SoonaImageCard>
  </SoonaSlider>
</template>

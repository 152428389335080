<script setup>
import { computed } from 'vue';

const inputValue = defineModel({ required: true });

const framesPerSecond = computed(() => {
  return (1000 / inputValue.value).toFixed(2);
});
</script>

<template>
  <div class="gif-speed">
    <div class="gif-speed--items">
      <input
        id="gif-speed--slowest"
        v-model="inputValue"
        class="u-visually-hidden"
        name="speed"
        type="radio"
        :value="1333.33"
      />
      <label for="gif-speed--slowest">🐢</label>
      <input
        id="gif-speed--slow"
        v-model="inputValue"
        class="u-visually-hidden"
        name="speed"
        type="radio"
        :value="1000"
      />
      <label for="gif-speed--slow">🐈</label>
      <input
        id="gif-speed--normal"
        v-model="inputValue"
        class="u-visually-hidden"
        name="speed"
        type="radio"
        :value="666.66"
      />
      <label for="gif-speed--normal">🦙</label>
      <input
        id="gif-speed--fast"
        v-model="inputValue"
        class="u-visually-hidden"
        name="speed"
        type="radio"
        :value="333.33"
      />
      <label for="gif-speed--fast">🐇</label>
      <input
        id="gif-speed--fastest"
        v-model="inputValue"
        class="u-visually-hidden"
        name="speed"
        type="radio"
        :value="200"
      />
      <label for="gif-speed--fastest">🐎</label>
    </div>
    <span>
      <b>gif speed:</b>&nbsp;{{ inputValue }}ms, {{ framesPerSecond }} frames/s
    </span>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.gif-speed--items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 3.125rem;
  background: #d8e8ff;
  border-radius: 20rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  input:focus-visible + label,
  input:checked + label {
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
    margin: -1.625rem;
    border: 0.25rem solid variables.$periwink-blue-50;
    background: variables.$white-default;
    text-align: center;
    align-content: center;
  }

  label {
    background: transparent;
    cursor: pointer;
    transform: rotateY(180deg);
  }
}

span {
  margin: 1rem 0;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
}
</style>

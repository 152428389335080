<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useTimeoutFn } from '@vueuse/core';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import StyleQuizLayout from '@/components/quiz/StyleQuizLayout.vue';

const TEXT_OPTIONS = [
  'revealing your inner teenage dreams...',
  'checking the moon phases...',
  'texting your besties for insights...',
  'reading your horoscope...',
  'grabbing a cold brew real quick...',
  'searching for all your secrets...',
  'brb. smashing the patriarchy.',
  'adding some sparkle to your results...',
  'quick reminder: black lives matter.',
  'reticulating splines...',
  'writing Obama a quick love note...',
  'poppin’ champagne to celebrate your results...',
];

const router = useRouter();
const store = useStore();

const quizResult = computed(() => store.state.styleQuiz.quizResult);

const { start: startResultsTimeout } = useTimeoutFn(
  () =>
    router.push({
      name: 'style-quiz-style',
      params: { quizStyleId: quizResult.value.quiz_style_id },
    }),
  7500,
  { immediate: false }
);

const currentText = ref('');
const textOptions = ref([]);

const shuffle = a => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

// eslint-disable-next-line no-use-before-define
const { start: startCycleTextTimeout } = useTimeoutFn(() => cycleText(), 2500, {
  immediate: false,
});

const cycleText = () => {
  currentText.value = shuffle(textOptions.value).pop();
  if (textOptions.value.length === 0) textOptions.value = TEXT_OPTIONS;
  startCycleTextTimeout();
};

// lifecycle
onMounted(() => {
  textOptions.value = TEXT_OPTIONS;
  cycleText();
  startResultsTimeout();
});
</script>

<template>
  <StyleQuizLayout>
    <template #left-column>
      <h1 class="u-display--heavy">
        <transition name="slide-fade" mode="out-in">
          <span :key="currentText">{{ currentText }}</span>
        </transition>
      </h1>
      <div class="skip-wrapper">
        <SoonaButton
          element="router-link"
          :to="{
            name: 'style-quiz-style',
            params: { quizStyleId: quizResult.quiz_style_id },
          }"
          variation="tertiary"
          data-cypress="button-skip-to-results"
        >
          skip
        </SoonaButton>
      </div>
    </template>
    <template #right-column>
      <div class="loading-wrapper">
        <div class="lottie-wrapper">
          <lottie-player
            autoplay
            loop
            src="@images/anim/lottie-quiz-loader.lott"
          />
        </div>
      </div>
    </template>
  </StyleQuizLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.skip-wrapper {
  margin: 0 auto;
}

.loading-wrapper {
  align-items: center;
  background-color: variables.$bubbletape-pink-10;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--app-header-viewport-offset));
  justify-content: center;
}

.lottie-wrapper {
  max-width: 20rem;
}

@media (min-width: variables.$screen-md-min) {
  .skip-wrapper {
    margin-right: 0;
  }
}
</style>

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { saveResizedDigitalAsset } from '@/api/digitalAssets';
import { queryKeys } from '@/queries/query-keys';

/**
 * @param { Ref<any> } accountId
 * @param { Ref<any> } assetId
 * @returns {UseMutationReturnType<any, unknown, void, unknown>}
 */
export function useSaveResizedDigitalAsset(accountId, assetId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body =>
      saveResizedDigitalAsset(toValue(accountId), toValue(assetId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.digitalAssets(accountId),
      });
    },
  });
}

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import SoonaSuccess from 'src/components/ui_library/SoonaSuccess.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import ShareDraft from 'src/components/shared/ShareDraft.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCollaborators } from '@/queries/collaborators/useCollaborators';

const props = defineProps({
  reservationId: Number,
});

const { linkClicked } = useBaseEvents();
const store = useStore();

const showShareModal = ref(false);

const account = computed(() => store.state.account);

const { data } = useCollaborators(
  computed(() => account.value?.id),
  {
    currentPage: 1,
  },
  { enabled: computed(() => !!account.value?.id) }
);

const trackSeeMyAccountClick = () => {
  linkClicked({
    context: 'booking',
    subContext: 'down payment',
    linkLabel: 'see my account',
    linkHref: `/user/anytime#/account`,
  });
};
</script>

<template>
  <div>
    <SoonaSuccess class="save-draft">
      <p>
        your draft booking has been saved to your account.
        <a
          :href="`/user/anytime#/account/${account.id}`"
          data-cypress="account-link"
          @click="trackSeeMyAccountClick"
        >
          see my account
        </a>
      </p>
      <template v-if="data?.collaborators?.length > 0">
        <p>
          <strong>share this order with team members!</strong>
        </p>
        <SoonaButton
          variation="secondary-black"
          :on-click="() => (showShareModal = true)"
        >
          Share Order
        </SoonaButton>
      </template>
    </SoonaSuccess>
    <ShareDraft
      v-if="showShareModal"
      :account="account"
      :reservation-id="props.reservationId"
      :cancel-clicked="() => (showShareModal = false)"
      :confirm-clicked="() => (showShareModal = false)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.save-draft {
  margin-bottom: 0;

  a {
    text-decoration: underline;
    color: variables.$periwink-blue-60;

    &:hover,
    &:focus-visible {
      text-decoration: none;
      color: variables.$periwink-blue-60;
    }
  }

  p + p {
    margin: 0.5rem 0;
  }
}
</style>

<style lang="scss" scoped>
//  non-scoped style so we can update modal and checkbox styles
.share-modal {
  .collab-select {
    max-height: 364px;
    max-width: 617px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      display: flex;
      width: 50%;
      margin-top: 10px;
      .user-checkbox {
        margin-right: 5px;
        display: flex;
        align-items: center;
      }
      label span {
        height: 15px;
        width: 15px;
      }
      [type='checkbox']:checked + span:before {
        font-size: 10pt;
        font-weight: normal;
        bottom: 7px;
        left: 0px;
      }
    }
  }
}
</style>

import { track } from '@/lib/segment/segment';

export function useProServiceEvents() {
  /**
   * Pro Services Selection Started
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} proServiceProviderType what type of pro service am I?
   */
  const proServiceSelectionStarted = payload => {
    track('Pro Service Selection Started', payload);
  };

  return {
    proServiceSelectionStarted,
  };
}

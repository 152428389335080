<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { toCurrency } from '@/lib/currency';
import { useAcceptTrialSubscription } from '@/queries/subscriptions/useAcceptTrialSubscription';
import { useAccount } from '@/composables/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCreateSubscription } from '@/queries/subscriptions/useCreateSubscription';
import { useIntegrations } from '@/composables/useIntegrations';
import { useSubscriptionItemProration } from '@/composables/subscription/useSubscriptionItemProration';
import { useCreateSubscriptionItem } from '@/queries/subscriptions/useCreateSubscriptionItem';
import PlanSelect from '@/components/ui_library/PlanSelect.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaPaymentMethods from 'src/components/SoonaPaymentMethods.vue';
import StepLayout from '@/components/modal-payment-flows/subscriptions/StepLayout.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import BillingInfo from 'src/components/checkout/BillingInfo.vue';
import TrialTimeline from '@/components/modal-payment-flows/subscriptions/TrialTimeline.vue';
import { usePriorityErrors } from 'src/composables/usePriorityErrors';
import { useFlag } from '@/composables/useFlag';
import { useRoute } from 'vue-router';
import EstimatedSalesTax from '@/components/sales_tax/EstimatedSalesTax.vue';
import { useSalesTax } from '@/composables/sales_tax/useSalesTax';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import { useUpdateSubscription } from '@/queries/subscriptions/useUpdateSubscription';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  context: {
    default: null,
    type: String,
  },
  flow: {
    type: String,
    required: true,
  },
  hasActiveSubscription: {
    default: false,
    type: Boolean,
  },
  isLoading: {
    default: false,
    type: Boolean,
  },
  price: {
    default: () => ({}),
    type: Object,
  },
  reservationId: {
    default: null,
    type: String,
  },
  selectedTier: {
    default: () => ({}),
    type: Object,
  },
  showBackButton: {
    default: false,
    type: Boolean,
  },
  subContext: {
    required: true,
    type: String,
  },
  offerTrial: {
    default: false,
    type: Boolean,
  },
});

const emits = defineEmits(['back', 'close', 'next', 'update:price']);

const salesTaxStore = useSalesTaxStore();

const route = useRoute();

const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;

const { inputChanged, linkClicked } = useBaseEvents();

const pegasusTrialSubscriptionsFlag = useFlag(
  'pegasus_accept_trial_subscriptions'
);

const isProcessing = ref(false);
const trialDays = ref(7);
const tierScale = ref(['tier-one', 'tier-two', 'tier-three']);
const accountId = computed(() => props.accountId);
const price = computed({
  get() {
    return props.price;
  },
  set(val) {
    emits('update:price', val);
  },
});
const selectedBillingInterval = computed(() => price.value?.recurring_interval);
const selectedTier = computed(() => props.selectedTier);
const showBackButton = computed(() => props.showBackButton);
const tierIcon = computed(() => selectedTier.value?.product.icon_name);
const tierSlug = computed(() => selectedTier.value?.slug);
const tierTitle = computed(() => selectedTier.value?.name);
const isUpgradingFlow = computed(
  () => props.flow === 'billingCycleUpgrade' || props.flow === 'tierUpgrade'
);

const priceOptions = computed(() => {
  const tierPrices = selectedTier.value?.product?.prices ?? [];
  let options = [];

  for (const p of tierPrices) {
    options.push({
      label: `billed ${p.recurring_interval}ly`,
      value: { ...p },
    });
  }

  return options;
});

const {
  account,
  hasSoonaStorage,
  subscription: currentSubscription,
  baseSubscriptionItem,
  subscriptionChargedViaStripe,
} = useAccount(accountId);

const isTrialing = computed(() => account.value?.is_trialing);
const isDowngrading = computed(() => {
  const activeTierWeight = tierScale.value.indexOf(
    currentSubscription.value?.tier?.slug
  );
  const viewingTierWeight = tierScale.value.indexOf(tierSlug.value);

  return (
    currentSubscription.value?.status === 'trialing' &&
    activeTierWeight > viewingTierWeight
  );
});

const currentTierSlug = computed(() => {
  if (!currentSubscription.value) return null;

  return currentSubscription.value?.tier.slug;
});

const currentTierName = computed(() => {
  if (!currentSubscription.value) return null;

  return currentSubscription.value?.tier.name;
});

// proration preview
const {
  addOnTotal,
  error: subscriptionItemProrationError,
  baseTierTotalProratedDisplay,
  fastPassAddOnTotalProratedDisplay,
  nextSubscriptionStart,
  isFetchingProratedData,
  proratedAccountBalanceAmountInDollars,
  proratedAccountBalanceAmountDisplay,
  proratedDataLoading,
  prorationDate,
  prorationSubtotalExcludingTaxInDollars,
  prorationTaxAmountInDollars,
  proratedTotal,
  recurringInterval,
  showProrationPreview,
} = useSubscriptionItemProration(accountId, price.value, {
  tierSlug: tierSlug.value,
});

const isLoadingProratedData = computed(() => {
  return isFetchingProratedData.value && proratedDataLoading.value;
});

const { mutate: createSubscription, error: errorCreateSubscription } =
  useCreateSubscription(accountId);
const { mutate: updateSubscription, error: errorUpdateSubscription } =
  useUpdateSubscription(accountId);
const { mutate: acceptTrialSubscription, error: errorAcceptTrialSubscription } =
  useAcceptTrialSubscription(accountId);
const { mutate: createSubscriptionItem, error: errorCreateSubscriptionItem } =
  useCreateSubscriptionItem(accountId);
const { hasShopifyIntegration } = useIntegrations(accountId);

const onSelect = option => {
  inputChanged({
    context: props.context,
    subContext: props.subContext,
    inputLabel: option.label,
    inputType: 'select',
    inputValue: option.value,
  });
};

// lifecycle hooks
onMounted(() => {
  // TODO: create & fire a 'subscriptions checkout started' segment event
});

const priorityErrors = usePriorityErrors(
  errorCreateSubscription,
  errorCreateSubscriptionItem,
  errorAcceptTrialSubscription,
  errorUpdateSubscription,
  subscriptionItemProrationError
);

const tierValues = { 'tier-one': 0, 'tier-two': 1, 'tier-three': 2 };

const showTrialCopy = computed(() => {
  if (!isTrialing.value) {
    return false;
  }

  const currentTierValue = tierValues[currentTierSlug.value] || 0;
  const selectedTierValue = tierValues[tierSlug.value] || 0;

  return currentTierValue >= selectedTierValue;
});

const chargeViaStripe = computed(() => {
  return !hasShopifyIntegration.value || subscriptionChargedViaStripe.value;
});

const chargeUsingCardOnFile = computed(() => {
  if (
    account.value?.is_trialing &&
    !currentSubscription.value?.external_subscription_id
  ) {
    return false;
  }
  return !!currentSubscription.value;
});

const confirmButtonCopy = computed(() => {
  if (props.offerTrial) return `start ${trialDays.value} day trial`;

  return `get soona ${tierTitle.value}`;
});

const preSalesTaxSubtotal = computed(() => {
  return showProrationPreview.value ? null : price.value?.unit_amount;
});

const productsList = computed(() => {
  if (currentSubscription.value && hasShopifyIntegration.value) {
    const currentItem = currentSubscription.value.subscription_items[0];

    return [
      {
        id: currentItem.product_id,
        price_id: currentItem.price_id,
        quantity: 1,
      },
      {
        id: selectedTier.value?.product?.id,
        price_id: price.value?.id,
        quantity: 1,
      },
    ];
  } else {
    return [
      {
        id: selectedTier.value?.product?.id,
        price_id: price.value?.id,
        quantity: 1,
      },
    ];
  }
});

const {
  isLoading: isCalculatingSalesTax,
  taxAmountExclusiveDisplay,
  totalWithSurchargeInDollars,
  stripeSalesTaxCalculationId,
} = useSalesTax({
  accountId,
  preSalesTaxSubtotal,
  productsList,
  calculate: computed(() => {
    if (showProrationPreview.value) return false;
    return !!selectedTier.value && !!price.value;
  }),
});

const completeCheckoutSuccess = response => {
  if (response?.result?.body?.data?.appSubscriptionCreate?.confirmationUrl) {
    window.location.href =
      response.result.body.data.appSubscriptionCreate.confirmationUrl;
  } else {
    emits('next');
  }
};

const completePaymentMethodCheckout = async (
  paymentMethodId,
  paymentMethodType,
  savePaymentMethod,
  shopifyStoreDomain
) => {
  try {
    isProcessing.value = true;
    let startDate = new Date();

    if (isUpgradingFlow.value) {
      // update base tier ID call
      updateSubscription(
        {
          subscription_id: currentSubscription.value.id,
          new_base_price_id: price.value.id,
        },
        {
          onSuccess: response => {
            completeCheckoutSuccess(response);
          },
          onSettled: () => {
            isProcessing.value = false;
          },
        }
      );
    } else if (isTrialing.value && pegasusTrialSubscriptionsFlag.value) {
      acceptTrialSubscription(
        {
          accepting_same_tier:
            currentTierSlug.value === selectedTier.value?.slug,
          is_internal: false,
          price_id: price.value.id,
          save_card: savePaymentMethod,
          shopify_payment_shop_domain: hasShopifyIntegration.value
            ? shopifyStoreDomain
            : null,
          subscription_id: account.value.subscription?.id,
          terms_accepted: true,
          token: hasShopifyIntegration.value ? null : paymentMethodId,
          taxCalculationId: stripeSalesTaxCalculationId.value,
        },
        {
          onSuccess: response => {
            completeCheckoutSuccess(response);
          },
          onSettled: () => {
            isProcessing.value = false;
          },
        }
      );
    } else if (!account.value.subscription) {
      createSubscription(
        {
          accountId: accountId.value,
          billingCycleAnchor: startDate,
          isInternal: false,
          priceIds: [price.value.id],
          saveCard: savePaymentMethod,
          slug: tierSlug.value,
          startDate: startDate,
          termsAccepted: true,
          token: hasShopifyIntegration.value ? null : paymentMethodId,
          shopifyPaymentShopDomain: hasShopifyIntegration.value
            ? shopifyStoreDomain
            : null,
          isTrial: props.offerTrial,
          discount: route.query.discount,
          reservationId: props.reservationId,
          taxCalculationId: stripeSalesTaxCalculationId.value,
        },
        {
          onSuccess: response => {
            completeCheckoutSuccess(response);
          },
          onSettled: () => {
            isProcessing.value = false;
          },
        }
      );
    } else {
      let body = {
        add_price_ids: [price.value.id],
        subscription_id: account.value.subscription?.id,
        reservation_id: props.reservationId,
        tax_calculation_id: stripeSalesTaxCalculationId.value,
      };

      const priceToRemove = currentSubscription.value.subscription_items.filter(
        s => s.subscription_item_type === 'base'
      )[0]?.price_id;

      if (priceToRemove) {
        body.remove_price_ids = [priceToRemove];
      }
      if (showProrationPreview.value) {
        body.proration_date = prorationDate.value;
      }
      await createSubscriptionItem(body, {
        onSuccess: response => {
          completeCheckoutSuccess(response);
        },
        onSettled: () => {
          isProcessing.value = false;
        },
      });
    }
  } catch (error) {
    console.error(error);
    isProcessing.value = false;
  } finally {
    linkClicked({
      context: props.context,
      subContext: props.subContext,
      linkLabel: `get soona ${tierTitle.value}`,
      linkHref: null,
    });
  }
};

const nonProratedAmountDueToday = computed(() => {
  let priceAmount = totalWithSurchargeInDollars.value;
  if (props.offerTrial || isDowngrading.value) priceAmount = 0;
  return toCurrency(priceAmount, 'USD', 2);
});

onUnmounted(() => {
  salesTaxStore.$reset();
});

const showFastPassBillingCycleChangeAlert = computed(() => {
  if (!hasSoonaStorage.value) return false;

  return (
    !!baseSubscriptionItem.value &&
    selectedBillingInterval.value !==
      baseSubscriptionItem.value?.recurring_interval
  );
});

const showShopifySubscriptionMessage = computed(() => {
  if (!hasShopifyIntegration.value) return false;
  if (!hasSoonaStorage.value) return false;
  return !baseSubscriptionItem.value;
});
</script>

<template>
  <StepLayout
    class="checkout-step"
    :show-back-button="showBackButton"
    :tier-icon="tierIcon"
    :tier-slug="tierSlug"
    :tier-title="tierTitle"
    :right-content-alignment="offerTrial ? 'flex-start' : 'center'"
    :right-content-justification="offerTrial ? 'flex-start' : 'center'"
    :right-content-padding="offerTrial ? '7rem 4rem' : '4rem'"
    @back="emits('back')"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template #heading>add payment info</template>
    <template #subheading>cancel your subscription anytime</template>
    <SoonaLoading
      v-if="isLoading || isProcessing || isLoadingProratedData"
      is-loading
      is-contained
      class="checkout-step__loading"
    />
    <div v-if="!hasActiveSubscription" class="checkout-step__select-container">
      <span
        v-if="selectedBillingInterval === 'yearly'"
        class="u-label--heavy checkout-step__select-banner"
      >
        save 30% with our yearly plan 🎉
      </span>
      <PlanSelect
        v-model:model-value="price"
        placeholder="select billing cycle"
        :hide-badge="selectedBillingInterval === 'yearly'"
        :no-top-border-radius="selectedBillingInterval === 'yearly'"
        :options="priceOptions"
        @option:selecting="onSelect"
      />
    </div>
    <BillingInfo class="checkout-step__billing-info" :account-id="accountId" />
    <SoonaAlert v-if="showFastPassBillingCycleChangeAlert">
      Changing your billing cycle will also adjust your Fast Pass billing cycle.
    </SoonaAlert>
    <SoonaAlert v-if="showShopifySubscriptionMessage">
      <h4 class="u-subheader--heavy">
        this will be added to your existing subscription
      </h4>
      <p>
        due to Shopify app restrictions we will refund your current subscription
        and re-enroll you with the addition of fast pass above.
      </p>
    </SoonaAlert>
    <SoonaPaymentMethods
      :on-payment-action="completePaymentMethodCheckout"
      :stripe-payment-required="chargeViaStripe"
      :is-stripe-disclaimer-below-checkout-button="!hasShopifyIntegration"
      :total="price > 0 ? price : 1"
      :account-id="accountId"
      :saveable-card="false"
      :payment-method-types="['card', 'us_bank_account']"
      :action-text="confirmButtonCopy"
      :disable-confirm-button="subscriptionItemProrationError"
      :show-using-card-on-file="chargeUsingCardOnFile"
      is-subscription-charge
      action-variation="solid-black"
    >
      <template #action-block>
        <div v-if="showProrationPreview">
          <div
            v-if="hasSoonaStorage"
            class="checkout-step__platform-membership-proration"
          >
            <div>base membership</div>
            <div>
              {{ baseTierTotalProratedDisplay }}
            </div>
          </div>
          <div v-if="hasSoonaStorage" class="checkout-step__fastpass-proration">
            <div>fast pass membership</div>
            <div>
              {{ fastPassAddOnTotalProratedDisplay }}
            </div>
          </div>
          <div class="checkout-step__subtotal">
            <div>subtotal</div>
            <div>
              <template v-if="showProrationPreview">
                {{ toCurrency(prorationSubtotalExcludingTaxInDollars) }}
              </template>
              <template v-else>
                {{ toCurrency(subscriptionPrice.unit_amount) }}
              </template>
            </div>
          </div>
          <div class="checkout-step__surcharge">
            <div class="checkout-step__surcharge-text">
              <EstimatedSalesTax />
            </div>
            <div data-cypress="summary-payment-surcharge">
              <span v-if="isCalculatingSalesTax">calculating...</span>
              <span v-else-if="showProrationPreview">
                {{ toCurrency(prorationTaxAmountInDollars) }}
              </span>
              <span v-else>{{ taxAmountExclusiveDisplay }}</span>
            </div>
          </div>
          <div class="checkout-step__info-container checkout-step__proration">
            <h4 class="u-subheader--all-caps-black">due today</h4>
            <p class="u-headline--heavy">
              {{ proratedTotal }}
            </p>
          </div>
          <p class="u-small--regular">
            <strong v-if="!isUpgradingFlow">
              you will be charged {{ addOnTotal }} + sales tax every
              {{ recurringInterval }} starting {{ nextSubscriptionStart }}.
            </strong>
            <strong
              v-else-if="
                isUpgradingFlow && proratedAccountBalanceAmountInDollars > 0
              "
            >
              Your remaining balance will be issued as a
              {{ proratedAccountBalanceAmountDisplay }} account credit if
              applicable.
            </strong>
          </p>
        </div>
        <div v-else class="checkout-step__info-container">
          <p class="checkout-step__subtotal">
            subtotal
            <span>
              {{ toCurrency(price?.unit_amount) }}
            </span>
          </p>
          <p class="checkout-step__surcharge">
            <EstimatedSalesTax class="checkout-step__surcharge-text" />
            <span v-if="isCalculatingSalesTax">calculating...</span>
            <span v-else>
              {{ taxAmountExclusiveDisplay }}
            </span>
          </p>
          <p class="checkout-step__total">
            <span class="u-title--heavy">
              due today
              <span v-if="offerTrial" class="trial-days-free">
                ({{ trialDays }} days free)
              </span>
            </span>
            <span
              class="u-title--heavy"
              data-cypress="text-subscriptions-dialog-total"
            >
              {{ nonProratedAmountDueToday }}
            </span>
          </p>
          <!-- below is for downgrading while trialing -->
          <p v-if="showTrialCopy" class="u-small--regular">
            your trial {{ currentTierName }} plan continues until expiration.
            <strong>
              you’ll be charged
              {{ toCurrency(price?.unit_amount, 'USD', 0) }} + sales tax on
              {{ nextSubscriptionStart }}. cancel anytime.
            </strong>
          </p>
        </div>
        <SoonaError
          v-if="priorityErrors"
          :priority-errors="priorityErrors"
          no-margin
        />
      </template>
    </SoonaPaymentMethods>
    <!-- below is for general trial sign ups -->
    <p v-if="offerTrial" class="u-small--regular checkout-step__tos">
      your trial continues for {{ trialDays }} days.
      <strong>
        you’ll be charged
        {{ toCurrency(price?.unit_amount, 'USD', 0) }} + sales tax on
        {{ nextSubscriptionStart }}. cancel anytime.
      </strong>
    </p>
    <p class="u-small--regular checkout-step__tos">
      by continuing you agree to our
      <a href="https://soona.co/terms" target="_blank" rel="noopener noreferrer"
        >terms of service</a
      >
      ({{ termsOfServiceDate }}) and
      <a
        href="https://soona.co/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        >privacy policy</a
      >
      for soona {{ tierTitle }}.
    </p>
    <template v-if="offerTrial" #main-image>
      <TrialTimeline />
    </template>
    <template v-else #main-image>
      <img
        class="checkout-step__star-top"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img
        class="checkout-step__star-bottom"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img src="@images/subscriptions/lights_camera_action@2x.png" alt="" />
    </template>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.checkout-step {
  &__loading {
    border-radius: 0.625rem;
    animation: 0.1s ease-out both k-fade-in;
  }

  &__select-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    position: relative;

    :deep(.vs__dropdown-menu) {
      top: 100% !important;
      left: 0 !important;
      width: 100% !important;
    }
  }

  &__billing-info {
    margin-bottom: 1rem;
  }

  &__select-banner {
    background-color: variables.$periwink-blue-70;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    color: variables.$white-default;
    text-align: center;
    padding: 0.5rem 0;
    width: 100%;
  }

  &__info-container {
    margin-top: 2rem;
  }

  &__proration {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  &__platform-membership-proration,
  &__fastpass-proration,
  &__subtotal,
  &__surcharge {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.25rem;

    &-text {
      display: flex;
      gap: 0.25rem;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;

    .trial-days-free {
      color: variables.$avo-toast-60;
    }
  }

  :deep(.soona-payment__submit) {
    width: 100%;
  }

  &__tos {
    padding-top: 1rem;

    a {
      text-decoration: underline;
    }
  }

  &__star-top {
    position: absolute;
    left: 1rem;
    top: 2rem;
    height: 4.9375rem !important;
    width: 4.9375rem !important;
  }

  &__star-bottom {
    position: absolute;
    right: 1.5rem;
    bottom: 3rem;
    height: 5.625rem !important;
    width: 5.625rem !important;
  }
}
</style>

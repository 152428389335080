<script setup>
import { onMounted, useId } from 'vue';
import { useInterval } from '@vueuse/core';

const gumId = useId();
const blueId = useId();
const blueOffsetX = 128;
const blueOffsetY = 128;

function moveCircle(circle, initialX, initialY) {
  if (!circle) return;

  const circleSize = 300;
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const randomX = Math.floor(Math.random() * (screenWidth - circleSize));
  const randomY = Math.floor(Math.random() * (screenHeight - circleSize));

  circle.style.transform = `translate(${initialX + randomX}px, ${initialY + randomY}px)`;
}

useInterval(() => {
  const bubbleGumOffsetX = -Math.abs(window.innerWidth - 128);
  const bubbleGumOffsetY = -Math.abs(window.innerHeight + 160);

  moveCircle(document.getElementById(blueId), blueOffsetX, blueOffsetY);
  moveCircle(
    document.getElementById(gumId),
    bubbleGumOffsetX,
    bubbleGumOffsetY
  );
}, 35000);

onMounted(() => {
  const bubbleGumOffsetX = -Math.abs(window.innerWidth - 128);
  const bubbleGumOffsetY = -Math.abs(window.innerHeight + 160);

  setTimeout(() => {
    moveCircle(document.getElementById(blueId), blueOffsetX, blueOffsetY);
    moveCircle(
      document.getElementById(gumId),
      bubbleGumOffsetX,
      bubbleGumOffsetY
    );
  }, 1000);
});
</script>

<template>
  <div :id="blueId" class="circle-blue" />
  <div :id="gumId" class="circle-bubblegum" />
</template>

<style scoped lang="scss">
@use '@/variables';

.circle-blue,
.circle-bubblegum {
  z-index: -2;
  width: 25rem;
  height: 25rem;
  border-radius: 50%;
  position: absolute;
  filter: blur(6rem);
  transition: transform 40s ease;
}

.circle-blue {
  top: -8rem;
  left: -8rem;
  background: radial-gradient(
    circle,
    variables.$periwink-blue-30 0%,
    variables.$periwink-blue-10 100%
  );
}
.circle-bubblegum {
  right: 8rem;
  bottom: -10rem;

  background: radial-gradient(
    circle,
    variables.$bubbletape-pink-20 0%,
    variables.$bubbletape-pink-02 100%
  );
}
</style>

<script setup>
import { computed, ref } from 'vue';
import BookingLayout from './BookingLayout.vue';
import faqData from 'src/components/booking/v3/p2/upgrades/faq.json';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaTagOptionCard from 'src/components/ui_library/soona_tag_option_card/SoonaTagOptionCard.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useReservation } from '@/composables/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapabilities } from '@/composables/useCapabilities';

const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const reservationId = computed(() => props.reservationId);

const { itemSelected } = useBaseEvents();

let buttonsLoading = ref([]);

// api calls
const {
  downPaymentDiscount,
  reservation,
  requiredTagIds,
  proServiceSlugForSize,
  shootType,
  error,
} = useReservation(reservationId);

const discountId = computed(() => downPaymentDiscount.value?.id);

const {
  data: upgradeData,
  isLoading: isLoadingProServiceOptions,
  isSuccess: isProServiceUpgradeOptionsSuccess,
  error: resProServiceTagsError,
} = useReservationTags(
  reservationId,
  proServiceSlugForSize,
  'pro_service_upgrade',
  discountId
);

const {
  data: videoUpgradeData,
  isLoading: isLoadingVideoOptions,
  isSuccess: isVideoUpgradeOptionsSuccess,
  error: resVideoTagsError,
} = useReservationTags(reservationId, 'video-upgrades', 'video_upgrade');

const upgradeOptions = computed(() => {
  const videoOptions = videoUpgradeData.value?.options || [];
  const proServiceOptions = upgradeData.value?.options || [];
  return videoOptions
    .concat(proServiceOptions)
    .sort((a, b) => a.tag.order - b.tag.order);
});

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId);

const capabilitiesRef = ref([{ capability: 'ft_soona_staff' }]);
const capabilities = useCapabilities(capabilitiesRef);

function isRemovable(tag) {
  return (
    requiredTagIds.value.indexOf(tag.id) === -1 ||
    capabilities.value.ft_soona_staff.hasCapability
  );
}

const priorityError = usePriorityError(
  removeError,
  addError,
  resProServiceTagsError,
  resVideoTagsError,
  error
);

// computed
const isBusy = computed(() => {
  return (
    buttonsLoading.value?.length >= 1 || isAdding.value || isRemoving.value
  );
});
const disabled = computed(
  () =>
    isLoadingProServiceOptions.value ||
    isLoadingVideoOptions.value ||
    isBusy.value
);
const isStepValid = computed(() => !isBusy.value);

// methods
function isLoading(id) {
  return buttonsLoading.value?.includes(id);
}

function onOptionChange(option, quantity) {
  if (buttonsLoading.value?.includes(option.tag.id) || isBusy.value) return;

  buttonsLoading.value = [...buttonsLoading.value, option.tag.id];

  const beingSelected = !option.selected;

  if (beingSelected) {
    addReservationTag(
      { tagId: option.tag.id, quantity: quantity },
      {
        onSettled: () =>
          (buttonsLoading.value = buttonsLoading.value?.filter(
            o => o !== option.tag.id
          )),
        onSuccess: () => {
          itemSelected({
            context: 'booking',
            subContext: 'upgrades',
            itemId: option.product.id,
            itemLabel: option.tag.title,
            itemPrice: option.tag.price_description,
            itemQuantity: quantity,
          });
        },
      }
    );
  } else {
    removeReservationTag(
      {
        reservationTagId: option.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSettled: () =>
          (buttonsLoading.value = buttonsLoading.value?.filter(
            o => o !== option.tag.id
          )),
      }
    );
  }
}

// navigation
function onBackClick() {
  props.onBack();
}

const currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});

const isComplete = computed(() => {
  return currentStep.value?.complete;
});

function onNextClick() {
  if (!isComplete.value) {
    addReservationTag(
      { tagId: upgradeData.value.skippable_tag_id },
      {
        onSuccess: () => {
          props.onNext();
        },
      }
    );
  } else {
    props.onNext();
  }
}
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="booking-upgrades prebooking-transition__content">
        <SoonaLoading
          v-if="!upgradeOptions"
          :is-loading="!upgradeOptions"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading>
          add more upgrades to your shoot
          <template v-if="shootType === 'photo'" #subtitle>
            pricing may vary based on the length of your shoot or number of
            items. see more details for pricing info.
          </template>
        </SoonaHeading>
        <template
          v-if="
            isProServiceUpgradeOptionsSuccess && isVideoUpgradeOptionsSuccess
          "
        >
          <SoonaTagOptionCard
            v-for="option in upgradeOptions"
            :key="option.tag.id"
            :tag-id="option.tag.id"
            :description="option.tag.description"
            :modal-description="option.tag.description_long"
            :button-disabled="disabled || !isRemovable(option.tag)"
            :has-carousel="false"
            :has-more-details="true"
            :has-quantity-incrementor="false"
            :min-quantity="1"
            :has-shadow="true"
            :primary-image="option.tag.image_url"
            :secondary-images="option.tag.image_secondary_urls"
            :tertiary-images="option.tag.image_tertiary_urls"
            :in-bag="option.selected"
            :is-loading="isLoading(option.tag.id)"
            :price-info="option.product?.price_info"
            :title="option.tag.title"
            title-element="h2"
            modal-title-element="h3"
            :has-pro-service-comparison="option.tag.has_comparison_image_tags"
            :pro-service-name="option.tag.title"
            :modal-subtitle="option.tag.sub_title_alt"
            :modal-subcopy="option.tag.summary"
            @action="onOptionChange(option, $event)"
          />
        </template>
        <SoonaFaq :faq-tab-list="faqData" />
      </div>
    </BookingLayout>
  </transition>
</template>

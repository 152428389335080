<script setup>
import { computed, toRefs } from 'vue';
import { useAccountRequirements } from '@/queries/account-requirements/useAccountRequirements';
import AccountRequirementsLayout from './AccountRequirementsLayout.vue';
import AccountRequirementExpansionPanel from './AccountRequirementExpansionPanel.vue';
import {
  mergeWithAccountRequirements,
  requirementTypes,
} from './requirement-types';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import RequirementEmpty from './RequirementEmpty.vue';
import RequirementView from '@/components/crew/account-requirements/RequirementView.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const { accountId } = toRefs(props);

const {
  data: accountRequirements,
  error: accountRequirementsError,
  isLoading: accountRequirementsLoading,
} = useAccountRequirements(accountId, {
  requirementTypes: Object.keys(requirementTypes),
});

const priorityErrors = usePriorityErrors(accountRequirementsError);

const requirementsDisplay = computed(() => {
  return mergeWithAccountRequirements(accountRequirements.value);
});
</script>

<template>
  <AccountRequirementsLayout>
    <template #description>
      info added here will be shown on all bookings for this account
    </template>
    <SoonaSkeleton v-if="accountRequirementsLoading" style="height: 5rem" />
    <SoonaError
      v-if="priorityErrors"
      :priority-errors="priorityErrors"
      no-margin
    />
    <template v-if="accountRequirements">
      <template
        v-for="accReq in requirementsDisplay"
        :key="accReq.requirementType"
      >
        <AccountRequirementExpansionPanel
          v-if="accReq.data"
          :requirement-type="accReq.displayData.requirementType"
          :updated-at="accReq.data?.updated_at"
        >
          <RequirementView
            :account-requirement="accReq.data"
            :readonly="false"
          />
        </AccountRequirementExpansionPanel>
        <RequirementEmpty
          v-else
          :account-id="accountId"
          :requirement-type="accReq.displayData.requirementType"
        />
      </template>
    </template>
  </AccountRequirementsLayout>
</template>

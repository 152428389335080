import { computed, unref } from 'vue';

export function useAuthenticationError(...errors) {
  return computed(() => {
    const errorList = [];

    for (let i = 0; i < errors.length; i++) {
      const error = unref(errors[i]);

      if (error?.response?.data?.error) {
        errorList.push(
          error.response.data.error
            .toLocaleLowerCase()
            .replaceAll('username', 'email')
        );
      } else if (error?.response?.data?.errors) {
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          errorList.push(`${key} ${value[0]}`);
        }
      }
    }

    return errorList;
  });
}

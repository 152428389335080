import { useMutation } from '@tanstack/vue-query';
import { signUserIn } from '../../api/users';
import * as Sentry from '@sentry/vue';

/**
 */
export function useSignUserIn() {
  return useMutation({
    mutationFn: attributes => signUserIn(attributes),
    onError: error =>
      Sentry.captureException(new Error('failed to sign in'), {
        extra: { error },
      }),
  });
}

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextField from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import MarketingEmailConsent from '@/components/shared/MarketingEmailConsent.vue';
import AccountPicker from '@/components/booking/sign_in/AccountPicker.vue';
import GoogleSSO from 'src/components/shared/GoogleSSO.vue';
import AuthTitles from '@/components/integrations/AuthTitles.vue';
import UseDifferentAccountButton from '@/components/shared/UseDifferentAccountButton.vue';
import SoonaTelephoneField from 'src/components/ui_library/SoonaTelephoneField.vue';
import { useAuth } from '@/composables/useAuth';
import * as Sentry from '@sentry/vue';

const route = useRoute();
const store = useStore();

const {
  data,
  handleEmailCheck,
  handleUseDifferentAccount,
  isEmailStep,
  isGoogleSSOStep,
  isPasswordStep,
  isPickAccountStep,
  isSignUpStep,
} = useAuth();

let currentUser = ref(null);
const username = ref('');
const name = ref('');
let selectedAccount = ref(null);
let accountList = ref([]);
let error = ref('');
let signUpError = ref('');

const isAuthenticated = computed(
  () => store.getters['currentUser/isAuthenticated']
);
const currentUserAccounts = computed(() => store.state.currentUser?.accounts);

const selectedAccountId = computed(() => selectedAccount.value?.id);

const redirectTo = computed(() => {
  return route?.query?.redirect;
});

const disabled = computed(() => {
  if (isEmailStep) return !data.email;
  if (isPasswordStep) return !data.password;
  if (isSignUpStep) return !data.firstName && !data.email && !data.password;

  return false;
});

async function setSelectedAccount(account) {
  selectedAccount.value = account;
}

function switchForms(step) {
  error.value = '';
  data.authStep = step;
}

async function handleShopifyCallback() {
  let redirect = redirectTo.value;
  if (selectedAccount.value) {
    redirect = `${redirect}&soona_account_id=${selectedAccount.value.id}`;
  }
  window.location.assign(redirect);
}

async function signIn() {
  error.value = '';

  try {
    const response = await fetch(`/integrations/shopify/sign_in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
    });

    const body = await response.json();

    if (body.error) {
      return (error.value = body.error);
    }

    currentUser.value = body.user;
    accountList.value = body.accounts;

    if (accountList.value.length === 1) {
      handleShopifyCallback();
    }

    if (accountList.value.length > 1) {
      data.authStep = 'pick_account';
    }
  } catch (error) {
    Sentry.captureException(
      new Error('failed to sign in during Shopify flow'),
      {
        extra: { error },
      }
    );
  }
}

async function signUp() {
  signUpError.value = '';

  try {
    const response = await fetch(`/integrations/shopify/sign_up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          email: data.email,
          username: username.value,
          phone: data.phone,
          name: name.value,
          marketing_email_consent: data.marketingEmailConsent,
        },
        integration_id: route.query.integration_id,
      }),
    });

    const body = await response.json();

    if (response.status === 422) {
      throw body.error;
    }

    currentUser.value = body.user;

    handleShopifyCallback();
  } catch (error) {
    signUpError.value =
      error + '. please try reinstalling our app through shopify.';
    Sentry.captureException(
      new Error('failed to sign up during Shopify flow'),
      {
        extra: { error },
      }
    );
  }
}

onMounted(() => {
  username.value = route.query.shop;

  if (isAuthenticated.value) {
    switchForms('pick_account');
    accountList.value = currentUserAccounts.value;
  } else {
    switchForms('sign_up');
    name.value = route.query.shop;
  }
});
</script>
<template>
  <div class="shopify-auth">
    <div class="shopify-auth__container">
      <SoonaError v-if="signUpError">{{ signUpError }}</SoonaError>
      <AuthTitles
        :auth-step="data.authStep"
        :first-name="data.firstName"
        :switch-forms="switchForms"
      />

      <!-- Google SSO step -->
      <div v-if="isGoogleSSOStep" class="shopify-auth__google-sso">
        <p>you previously signed in with Google.</p>
        <GoogleSSO copy="continue with Google" />
        <div class="shopify-auth__different-account">
          not you?
          <UseDifferentAccountButton @on-click="handleUseDifferentAccount" />
        </div>
      </div>

      <!-- Sign In Step -->
      <SoonaForm v-if="!isPickAccountStep && !isGoogleSSOStep">
        <SoonaTextField
          v-if="isEmailStep"
          v-model="data.email"
          class="shopify-auth__textfield"
          label="email"
          autocomplete="email"
          placeholder="email"
          type="email"
          required
        />
        <SoonaTextField
          v-if="isPasswordStep"
          v-model="data.password"
          class="shopify-auth__textfield"
          label="password"
          type="password"
          autocomplete="current-password"
          placeholder="password"
          required
        />
        <SoonaButton
          v-if="isEmailStep"
          class="shopify-auth__submit-btn"
          type="button"
          data-cypress="button-create"
          :disabled="disabled"
          :on-click="handleEmailCheck"
        >
          continue with email
        </SoonaButton>

        <div v-if="error">
          <SoonaError>
            {{ error }}
          </SoonaError>
        </div>

        <UseDifferentAccountButton
          v-if="isPasswordStep"
          @on-click="handleUseDifferentAccount"
        />

        <SoonaButton
          v-if="isPasswordStep"
          class="shopify-auth__submit-btn"
          type="button"
          data-cypress="button-create"
          :disabled="disabled"
          :on-click="signIn"
        >
          sign in
        </SoonaButton>

        <span
          v-if="isEmailStep || isPasswordStep"
          class="shopify-auth__divider"
        >
          or
        </span>

        <GoogleSSO v-if="isEmailStep || isPasswordStep" />
      </SoonaForm>

      <!-- Sign Up Step -->
      <SoonaForm v-if="isSignUpStep" v-slot="{ hasErrors }">
        <GoogleSSO copy="sign up with Google" />
        <span class="shopify-auth__divider"> or </span>
        <SoonaTextField
          v-model="name"
          class="shopify-auth__textfield"
          label="name"
          type="text"
          placeholder="shopify"
          required
          :rules="['required', 'minlength']"
        />
        <SoonaTextField
          v-model="data.email"
          class="shopify-auth__textfield"
          label="email"
          type="email"
          placeholder="name@company.com"
          required
          :rules="['required', 'email']"
        />
        <SoonaTelephoneField
          v-model="data.phone"
          class="account-info__textfield"
          :required="true"
          label="phone number"
          cypress-name="downpayment-input-phone"
          ><template #helper>required</template></SoonaTelephoneField
        >
        <MarketingEmailConsent
          v-model="data.marketingEmailConsent"
          class="shopify-auth__marketing-consent"
          align="left"
        />
        <SoonaButton
          class="shopify-auth__submit-btn"
          type="submit"
          data-cypress="button-create"
          :on-click="signUp"
          :disabled="disabled || hasErrors"
        >
          create account
        </SoonaButton>
      </SoonaForm>

      <!-- account picker step -->
      <div v-if="isPickAccountStep">
        <h2 class="u-headline--heavy account-picker__title">
          sign in or connect to Shopify
        </h2>
        <AccountPicker
          class="shopify-auth__account-picker"
          :account-list="accountList"
          :selected-account-id="selectedAccountId"
          padding-bottom="16px"
          @on-click="setSelectedAccount"
        />

        <SoonaButton
          class="shopify-auth__submit-btn"
          type="button"
          data-cypress="button-create"
          :disabled="!selectedAccount"
          :on-click="handleShopifyCallback"
        >
          select account
        </SoonaButton>

        <UseDifferentAccountButton
          class="shopify-auth__submit-btn"
          @on-click="handleUseDifferentAccount"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.shopify-auth {
  &__container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 1.25rem;
    text-align: center;
  }

  &__submit-btn {
    display: block;
    width: 100%;
  }

  &__divider {
    @include variables_fonts.u-small--heavy;

    display: inline-block;
    padding: 1rem 0;
    position: relative;
    text-align: center;
    width: 100%;

    &::before,
    &::after {
      background-color: variables.$black-default;
      content: '';
      height: 2px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(50% - 20px);
    }

    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }

  &__link-btn {
    background-color: transparent;
    color: variables.$black-default;
    padding: 0;
    text-decoration: underline;
  }

  &__submit-btn {
    margin-top: 1.25rem;
  }

  &__textfield {
    margin-bottom: 1.25rem;
    padding-bottom: 0;
  }

  &__marketing-consent {
    padding-bottom: 0;
  }

  &__account-picker {
    margin-bottom: 1.25rem;
  }

  &__google-sso {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>

import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import { store } from 'src/store/store';
import { queryKeys } from './queries/query-keys';
import { getCapabilities } from './api/capabilities';
import { useQueryClient } from '@tanstack/vue-query';
import { getIsFeatureFlagActiveGuard } from '@/route-utils';

export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes.routes,
  scrollBehavior(to, from, savedPosition) {
    // savedPosition is from browser refresh + back btn + forward btn
    if (savedPosition) return savedPosition;
    // do not scroll if we are on the same page (allow search/filter params to change)
    if (to.path === from?.path) return {};
    // preserve scroll position if route meta data is present
    if (to.meta.preserve_scroll_position) return {};
    // default to starting from top of page
    return { top: 0 };
  },
});

router.beforeResolve(async (to, from, next) => {
  // vue router injects global providers into global route guards
  const queryClient = useQueryClient();
  const isAuthenticated = store.getters['currentUser/isAuthenticated'];
  const isFeatureFlagActive = await getIsFeatureFlagActiveGuard(queryClient);

  async function checkCapability(requires_capability, to) {
    const params = {};
    if (
      typeof requires_capability === 'object' &&
      requires_capability.subjectType
    ) {
      if (typeof requires_capability.subjectId === 'function') {
        params.subjectType = requires_capability.subjectType;
        params.subjectId = requires_capability.subjectId(to);
      } else {
        params.subjectType = requires_capability.subjectType;
        params.subjectId = requires_capability.subjectId;
      }
    }

    /* prefetching does not make a fetch request if the query is in the cache
     * and not stale
     * https://vue-query-next.vercel.app/#/guides/prefetching
     */
    await queryClient.prefetchQuery({
      queryKey: queryKeys.capability(params),
      queryFn: ({ signal }) => getCapabilities(params, signal),
      gcTime: 60 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
    });
    // if the prefetch failed, fall back to an empty array
    const capabilities =
      queryClient.getQueryData(queryKeys.capability(params)) || [];

    const capability =
      typeof requires_capability === 'string'
        ? requires_capability
        : requires_capability.capability;

    return capabilities.includes(capability);
  }

  const capabilityCheckPasses = async function (to) {
    // make it always an array
    const capabilityEntries = Array.isArray(to.meta.requires_capability)
      ? to.meta.requires_capability
      : [to.meta.requires_capability];

    if (
      !capabilityEntries.every(entry => {
        return (
          (typeof entry === 'object' && typeof entry.capability === 'string') ||
          // otherwise it must be a string, anything else is an invalid value
          typeof entry === 'string'
        );
      })
    ) {
      throw new Error(
        'Invalid Route Config: when setting requires_capability, it must be typed as Array<string | object>, string, or object'
      );
    }

    try {
      // return true the first any capability check passes
      return await Promise.any(
        capabilityEntries.map(
          entry =>
            new Promise((resolve, reject) => {
              return checkCapability(entry, to)
                .then(result => {
                  if (result) {
                    resolve(true);
                  } else {
                    reject(false);
                  }
                })
                .catch(() => {
                  reject(false);
                });
            })
        )
      );
    } catch (e) {
      // if all checks fail, return false
      return false;
    }
  };

  // only allow route if the flag is *not* active
  if (
    to.meta.in_absence_of_flag &&
    isFeatureFlagActive(to.meta.in_absence_of_flag)
  ) {
    return next('/');
  }

  // only allow route if flag is active
  if (to.meta.requires_flag && !isFeatureFlagActive(to.meta.requires_flag)) {
    return next('/');
  }

  //auth check
  if (!to.meta.requires_auth || isAuthenticated) {
    //capability check
    if (!to.meta.requires_capability || (await capabilityCheckPasses(to))) {
      next();
    } else {
      //probably should push a 'you can't do this' error to flash error state

      //send them back where they came from if we can, otherwise send them home
      const nextPath =
        from && !from.requires_capability && from.path !== '/sign-in'
          ? from.path
          : '/';
      next(nextPath);
    }
  } else {
    //they tried to go somewhere that requires authentication
    next({ name: 'authSignIn', query: { ...to.query, redirect: to.path } });
  }
});

export default router;

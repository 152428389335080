<script setup>
import { ref, watchEffect, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useTitle, useIntersectionObserver } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaMediumImageBanner from '@/components/ui_library/SoonaMediumImageBanner.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaImageCard from '@/components/ui_library/SoonaImageCard.vue';
import SoonaGrid from '@/components/ui_library/SoonaGrid.vue';
import GetInspiredCarousel from '@/components/shared/GetInspiredCarousel.vue';
import {
  useHeroItems,
  useBlogCategories,
  useGetPostsByCategory,
} from '@/queries/useDiscoveryItems';

import { Tangerine20, AvoToast20 } from 'src/variables.module.scss';

useTitle('discovery | soona');
const { pageViewed } = useBaseEvents();
onMounted(() => {
  pageViewed();
});

const route = useRoute();
const { linkClicked, filterSelected } = useBaseEvents();
const { data: heroItems, isLoading: heroItemsLoading } = useHeroItems();

const heroItem = ref({});
const addToCart = ref({});
const theCheckout = ref({});

const blogPostsScrollObserverEl = ref(null);

const selectedCategory = ref('amazon');

const isCategorySelected = categorySlug =>
  categorySlug === selectedCategory.value;

const {
  data: blogCategories,
  isLoading: blogCategoriesLoading,
  error: blogCategoriesError,
} = useBlogCategories();
const {
  data: blogPostsPages,
  hasNextPage,
  fetchNextPage,
  isLoading: blogPostsLoading,
  isFetching: blogPostsFetching,
  error: blogPostsError,
} = useGetPostsByCategory(selectedCategory);

const inspoError = usePriorityError(blogCategoriesError, blogPostsError);

const blogPosts = computed(
  () => blogPostsPages.value?.pages?.flatMap(x => x.items) ?? []
);

watchEffect(() => {
  heroItem.value = heroItems.value?.find(item => item['type'] === 'hero');
  addToCart.value = heroItems.value?.find(
    item => item['type'] === 'add-to-cart'
  );
  theCheckout.value = heroItems.value?.find(
    item => item['type'] === 'the-checkout'
  );
});

function selectFilter(filter) {
  selectedCategory.value = filter;
  filterSelected({
    context: route.meta.context,
    subContext: 'blog categories filter',
    filterLabel: filter,
  });
}

function openLink(linkLabel, linkHref, linkLocation) {
  linkClicked({
    context: route.meta.context,
    subContext: linkLocation,
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
}

const loadNextPage = () => {
  if (hasNextPage.value && !blogPostsFetching.value) return fetchNextPage();
};

useIntersectionObserver(
  blogPostsScrollObserverEl,
  ([entry]) => {
    if (entry.isIntersecting) loadNextPage();
  },
  {
    threshold: 1,
  }
);
</script>

<template>
  <div class="platform-sidebar-layout__main">
    <div class="discovery-container">
      <section>
        <SoonaSkeleton v-if="heroItemsLoading" class="loading__hero-item" />
        <div v-else-if="heroItem" class="discovery-hero-banner">
          <span class="discovery-hero-banner__details">
            <h2>{{ heroItem.title }}</h2>
            <p>{{ heroItem.description }}</p>
            <SoonaButton
              element="a"
              target="_blank"
              :href="heroItem.link"
              variation="secondary-black"
              @click="openLink('read more', heroItem.link, 'hero banner')"
            >
              read more
              <SoonaIcon name="arrow-up-right-from-square" />
            </SoonaButton>
          </span>
          <span class="discovery-hero-banner__image-wrapper">
            <img :src="heroItem.image" :alt="heroItem.image_alt || ''" />
          </span>
        </div>
      </section>
      <GetInspiredCarousel>
        <template #title>
          <h3 class="u-title--heavy">get inspired</h3>
        </template>
      </GetInspiredCarousel>
      <section>
        <div class="discovery-direct-links">
          <SoonaSkeleton
            v-if="heroItemsLoading"
            class="loading__medium-banners"
          />
          <SoonaMediumImageBanner
            v-else-if="theCheckout"
            :background-color="Tangerine20"
          >
            <template #title>The Checkout</template>
            <template #content>{{ theCheckout.title }}</template>
            <template #image>
              <img
                :src="theCheckout.image"
                :alt="theCheckout.image_alt || ''"
              />
            </template>
            <template #button>
              <SoonaButton
                element="a"
                target="_blank"
                :href="theCheckout.link"
                variation="secondary-black"
                @click="openLink('read more', theCheckout.link, 'the checkout')"
              >
                read more
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </template>
          </SoonaMediumImageBanner>
          <SoonaSkeleton
            v-if="heroItemsLoading"
            class="loading__medium-banners"
          />
          <SoonaMediumImageBanner
            v-else-if="addToCart"
            :background-color="AvoToast20"
          >
            <template #title>Add to Cart</template>
            <template #content>{{ addToCart.title }}</template>
            <template #image>
              <img :src="addToCart.image" :alt="addToCart.image_alt || ''" />
            </template>
            <template #button>
              <SoonaButton
                element="a"
                target="_blank"
                :href="addToCart.link"
                variation="secondary-black"
                @click="openLink('watch now', addToCart.link, 'add to cart')"
              >
                watch now
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </template>
          </SoonaMediumImageBanner>
        </div>
      </section>
      <section>
        <h3>industry inspo</h3>
        <SoonaError v-if="inspoError">{{ inspoError }}</SoonaError>
        <div
          v-if="blogCategoriesLoading"
          class="loading loading__blog-categories"
        ></div>
        <div v-else class="blog-posts-categories">
          <div class="blog-posts-categories__wrapper">
            <SoonaButton
              v-for="category in blogCategories"
              :key="category.id"
              :data-slug="category.slug"
              :aria-pressed="isCategorySelected(category.slug)"
              class="category-item"
              variation="filter"
              @click="selectFilter(category.slug)"
            >
              {{ category.title }}
            </SoonaButton>
          </div>
        </div>
        <SoonaSkeleton v-if="blogPostsLoading" class="loading__blog-posts" />
        <template v-else>
          <SoonaGrid class="blog-posts">
            <SoonaImageCard
              v-for="item in blogPosts"
              :key="item.id"
              :href="item.link"
              tag-name="div"
              inner-element="a"
              target="_blank"
              class="blog-post-item"
              @click="openLink(item.title.trim(), item.link, 'blog posts')"
            >
              <img :src="item.image" :alt="item.image_alt || ''" />
              <template #caption>{{ item.title }}</template>
            </SoonaImageCard>
          </SoonaGrid>
          <div ref="blogPostsScrollObserverEl"></div>
        </template>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

section > h3 {
  @include variables_fonts.u-title--heavy;
  font-weight: bold;
  padding: 4.5rem 0 1.625rem;
}

.discovery-container {
  max-width: calc(100vw - 2rem);
}

.loading {
  &__hero-item {
    height: 32.75rem;

    @media (min-width: variables.$screen-md-min) {
      height: 21.6875rem;
    }
  }
  &__medium-banners {
    width: 100%;
    height: 15.625rem;
  }
  &__blog-categories {
    height: 3rem;
  }
  &__blog-posts {
    margin-top: 1.75rem;
    width: 100%;
    height: 28.125rem;
  }
}

.discovery-hero-banner {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  background: variables.$periwink-blue-10;
  border-radius: 0.625rem;
  overflow: hidden;
  justify-content: space-between;
  max-width: 100%;
  cursor: pointer;

  @media (min-width: variables.$screen-md-min) {
    flex-direction: row;
  }

  &__details {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    align-items: flex-start;
    gap: 0.75rem;

    > a {
      position: static;

      @media (min-width: variables.$screen-md-min) {
        display: block;
      }

      &::before {
        content: '';
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }

    @media (min-width: variables.$screen-md-min) {
      padding: 2.5rem 3.25rem 3.125rem;
      width: 50%;
    }

    h2 {
      @include variables_fonts.u-headline--heavy;

      @media (min-width: variables.$screen-md-min) {
        @include variables_fonts.u-display--heavy;
      }
    }
    p {
      flex-grow: 1;
    }
  }

  &__image-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 15.625rem;
    align-items: center;

    @media (min-width: variables.$screen-md-min) {
      width: 50%;
      max-height: none;
    }

    img {
      display: block;
      transition: 0.3s transform cubic-bezier(0.34, 1.56, 0.64, 1);
      min-height: 21.6875rem;
      width: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    color: initial;

    @media (prefers-reduced-motion: no-preference) and (hover: hover) {
      img {
        transform: scale(1.05);
      }
    }
  }
}

.get-inspired {
  gap: 0;
}

.discovery-direct-links {
  margin-top: 4.5rem;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  @media (min-width: variables.$screen-md-min) {
    flex-direction: row;
  }
}

.blog-posts-categories {
  overflow-x: auto;

  &__wrapper {
    gap: 0.5rem;
    display: flex;
    width: fit-content;

    > button {
      text-transform: lowercase;
      white-space: nowrap;
    }
  }

  @media (min-width: variables.$screen-md-min) {
    width: auto;
  }
}

.blog-posts {
  margin-top: 1.75rem;
  grid-template-columns: repeat(auto-fill, minmax(min(11rem, 50%), 1fr));

  @media (min-width: variables.$screen-md-min) {
    grid-template-columns: repeat(auto-fill, minmax(min(18rem, 100%), 1fr));
  }
}
</style>

<script setup>
const props = defineProps({
  title: {
    type: String,
    require: true,
  },
});
</script>

<template>
  <header class="pack-builder-sidebar-header">
    <h2 class="pack-builder-sidebar-header__title">{{ props.title }}</h2>
  </header>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.pack-builder-sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: variables.$periwink-blue-10;
  padding: 1rem 1.5rem;
  position: relative;
  margin-bottom: 1rem;

  &::after {
    content: '';
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    bottom: 0;
    height: 1px;
    background-color: variables.$gray-40;
  }

  &__title {
    @include variables_fonts.u-badge--big;
    color: variables.$black-default;
  }
}
</style>

export default {
  data() {
    return {
      mqReducedMotion: window.matchMedia('(prefers-reduced-motion: reduce)'),
    };
  },
  methods: {
    collapseSection(element, toggleVar, varIndex = null) {
      const vThis = this;

      if (!this.mqReducedMotion || this.mqReducedMotion.matches) {
        element.style.transition = 'none';
        element.style.display = 'none';
        if (varIndex !== null) vThis[toggleVar][varIndex] = false;
        else vThis[toggleVar] = false;
        return;
      }

      // Temp disable CSS transitions
      const elementTransition = element.style.transition;
      element.style.removeProperty('transition');

      element.style.display = 'inherit';

      // On next frame, explicitly set the element's height,
      // so we aren't transitioning out of 'auto'
      requestAnimationFrame(function () {
        element.style.height = element.scrollHeight + 'px';
        element.style.transition = elementTransition;

        if (varIndex !== null) vThis[toggleVar][varIndex] = false;
        else vThis[toggleVar] = false;

        // On the next frame have the element transition to height: 0
        requestAnimationFrame(function () {
          element.style.height = 0 + 'px';
          element.addEventListener(
            'transitionend',
            vThis.collapseTransitionEnd
          );
        });
      });
    },
    collapseTransitionEnd(e) {
      // Remove event listener so it only gets triggered once
      e.target.removeEventListener('transitionend', this.collapseTransitionEnd);

      // remove the element from the DOM
      e.target.style.display = 'none';
    },
    expandSection(element, toggleVar, varIndex = null) {
      if (varIndex !== null) this[toggleVar][varIndex] = true;
      else this[toggleVar] = true;

      element.style.display = 'inherit';

      if (!this.mqReducedMotion || this.mqReducedMotion.matches) {
        element.style.transition = 'none';
        element.style.height = null;
        return;
      }

      element.style.removeProperty('transition');

      // Transition to the height of its inner content
      element.style.height = element.scrollHeight + 'px';
      element.addEventListener('transitionend', this.expandTransitionEnd);
    },
    expandTransitionEnd(e) {
      // Remove event listener so it only gets triggered once
      e.target.removeEventListener('transitionend', this.expandTransitionEnd);

      e.target.style.display = 'inherit';

      // Remove "height" from the element's inline styles, return to its initial value
      e.target.style.height = null;
    },
  },
};

<template>
  <div class="TypeformModal" />
</template>

<script>
import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/popup.css';
export default {
  name: 'TypeformModal',
  props: {
    formId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    email: {
      type: String,
      required: false,
    },
    reservationId: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      typeformPopup: null,
    };
  },
  watch: {
    open(value) {
      if (value) {
        this.typeformPopup.open();
      } else {
        this.typeformPopup.close();
      }
    },
  },
  mounted() {
    this.typeformPopup = createPopup(this.formId, {
      hidden: {
        reservation_id: this.reservationId,
        email: this.email,
        name: this.name,
      },
      onSubmit: event => {
        this.onSubmit(event.responseId);
        this.onClose();
      },
      onClose: () => {
        this.onClose();
      },
    });
    if (this.open) this.typeformPopup.open();
  },
};
</script>

<style lang="scss"></style>

<script setup>
import { toRef } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';
import { useSceneCardContent } from '@/components/user/anytime/scene_selector/useSceneCardContent.js';

const props = defineProps({
  shot: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['assignToScene']);

const { imageSrc, iconName, isComplete } = useSceneCardContent(
  toRef(() => props.shot),
  toRef(() => props.isActive)
);

const assignToScene = () => {
  emit('assignToScene', props.shot.id);
};
</script>

<template>
  <SoonaItemCard
    :image-url="imageSrc"
    :aria-labelledby="`scene-${shot.id}-title`"
  >
    <template #content>
      <div class="scene-option__content">
        <div class="scene-option__title">
          <SoonaIcon
            :name="iconName"
            size="medium"
            :class="{ 'icon-active': isActive, 'icon-complete': isComplete }"
          />
          <p :id="`scene-${shot.id}-title`">
            {{ `scene ${index + 1}` }}
          </p>
        </div>
        <div class="scene-option__actions">
          <SoonaButton
            variation="primary"
            size="medium"
            type="button"
            :loading="isLoading"
            :disabled="isDisabled"
            @on-click="assignToScene"
          >
            choose
          </SoonaButton>
        </div>
      </div>
    </template>
  </SoonaItemCard>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.scene-option {
  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.3125rem;
    height: 100%;
    padding: 0.75rem 1rem;
  }

  &__title {
    @include variables_fonts.u-body--heavy;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
}

.icon-active {
  color: variables.$friendly-red-50;
}

.icon-complete {
  color: variables.$avo-toast-40;
}
</style>

<script setup>
import { computed, ref } from 'vue';
import ChooseEditsModal from 'src/components/checkout/ChooseEditsModal.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import { useFlag } from '@/composables/useFlag';
import { useSubscription } from '@/composables/subscription/useSubscription';

const props = defineProps({
  accountId: {
    required: true,
    type: Number,
  },
  addOnList: {
    required: true,
    type: Array,
  },
  assetId: {
    required: true,
    type: Number,
  },
  assetTitle: {
    required: true,
    type: String,
  },
  isGif: {
    required: true,
    type: Boolean,
  },
  isPhoto: {
    required: true,
    type: Boolean,
  },
  isVideo: {
    required: true,
    type: Boolean,
  },
  reservationId: {
    required: true,
    type: Number,
  },
  usingPreferred: {
    required: false,
    type: Boolean,
    default: false,
  },
});

const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');
const isModalOpen = ref(false);

const addOnList = computed(() => props.addOnList);
const isGif = computed(() => props.isGif);
const isVideo = computed(() => props.isVideo);
const accountId = computed(() => props.accountId);
const usingPreferred = computed(() => props.usingPreferred);

const { hasTierTwoSubscription, hasTierThreeSubscription } =
  useSubscription(accountId);

const isUsingPreferredCredit = computed(() => {
  return usingPreferred.value;
});

const hasMembershipDiscount = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return hasTierTwoSubscription.value || hasTierThreeSubscription.value;
  } else {
    return false;
  }
});

const closeModal = () => {
  isModalOpen.value = false;
};

const openModal = () => {
  isModalOpen.value = true;
};
</script>

<template>
  <div class="choose-edits">
    <div class="choose-edits__left">
      <div class="choose-edits__icon">
        <SoonaIcon name="pen-swirl-monochrome" />
      </div>
      <button data-cypress="choose-edits-button" @click="openModal">
        add premium edits
      </button>
    </div>
    <template v-if="isPhoto">
      <p class="choose-edits__pricing">
        <template v-if="isUsingPreferredCredit">
          included with preferred credit
        </template>
        <template v-else-if="hasMembershipDiscount"
          >free with your membership</template
        >
        <template v-else>$9/edit</template>
      </p>
    </template>
  </div>
  <ChooseEditsModal
    v-if="isModalOpen"
    :account-id="accountId"
    :add-on-list="addOnList"
    :asset-id="assetId"
    :asset-title="assetTitle"
    :is-gif="isGif"
    :is-video="isVideo"
    :reservation-id="reservationId"
    :using-preferred="usingPreferred"
    @close="closeModal"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.choose-edits {
  align-items: center;
  background-color: variables.$tangerine-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  margin: 1rem 0;

  &__left {
    align-items: center;
    display: flex;
  }

  &__icon {
    flex: none;
    align-items: center;
    background-color: variables.$white-default;
    border-radius: 50%;
    color: variables.$periwink-blue-70;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    width: 2.5rem;
  }

  button {
    @include variables_fonts.u-label--regular;

    background: transparent;
    margin-left: 0.5rem;
    text-decoration: underline;
    transition: 0.2s;

    &:hover,
    &:focus-visible {
      color: variables.$periwink-blue-70;
    }

    &:active {
      color: variables.$periwink-blue-80;
      text-decoration: none;
    }

    @media (min-width: variables.$screen-md-min) {
      @include variables_fonts.u-body--regular;
    }
  }

  &__pricing {
    @include variables_fonts.u-label--regular;
  }
}
</style>

<script setup>
import SoonaSearchFilter from '@/components/ui_library/SoonaSearchFilter.vue';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';

const searchText = defineModel('searchText', {
  type: String,
  required: true,
});
const selectedFilter = defineModel('selectedFilter', {
  type: String,
  required: true,
});
const selectedStatusFilter = defineModel('selectedStatusFilter', {
  type: String,
  required: true,
});
const includeVariants = defineModel('includeVariants', {
  type: Boolean,
  required: true,
});
const sortBy = defineModel('sortBy', {
  type: Object,
  required: true,
});

const primaryFilterOptions = ['products', 'props'];
const secondaryFilterOptions = ['active', 'draft', 'archived', 'at soona'];

const sortOptions = [{ value: 'name', label: 'name' }];
</script>

<template>
  <SoonaSearchFilter
    v-model:search-text="searchText"
    v-model:selected-filter="selectedFilter"
    v-model:sort-by="sortBy"
    search-placeholder="search products"
    :filter-options="primaryFilterOptions"
    :sort-by-options="sortOptions"
    straight-top
  >
    <template #secondary-filters>
      <SoonaFilterSingle
        v-model="selectedStatusFilter"
        :options="secondaryFilterOptions"
      />
      <SoonaToggle
        v-model="includeVariants"
        type="switch"
        label="show variants"
        class="product-filters__variant-toggle"
      />
    </template>
  </SoonaSearchFilter>
</template>

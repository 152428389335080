<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';
import useCompetitorPlatformName from './useCompetitorPlatformName';
import { usePlatformName as useListingPlatformName } from '@/components/user/anytime/listing_insights/listing_card/usePlatformName';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const item = computed(() => props.item);
const type = computed(() =>
  item.value?.type?.includes('Competitor') ? 'competitor' : 'listing'
);

const platformName = computed(() => {
  if (type.value === 'competitor') {
    return useCompetitorPlatformName(item.value.type);
  }
  return useListingPlatformName(item)?.platform?.value;
});
</script>

<template>
  <div
    v-if="!item.catalog_item_info?.list_price"
    class="price__empty u-body--regular"
  >
    see {{ platformName }} for price
  </div>
  <div
    v-else
    class="price u-headline--heavy"
    :class="{
      'price--loading': isLoading,
    }"
  >
    {{ toCurrency(item.catalog_item_info.list_price) }}
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.price {
  &__empty {
    background-color: variables.$gray-20;
    color: variables.$gray-60;
    width: fit-content;
    padding: 0.25rem 0.5rem;
    border-radius: 0.3125rem;
  }

  &--loading {
    color: variables.$gray-30;
  }
}
</style>

const SoonaSignIn = () => import('@/components/authentication/SoonaSignIn.vue');

export const styleQuizPaths = [
  {
    path: '/style-quiz',
    component: () => import('src/components/quiz/StyleQuiz.vue'),
    meta: {
      context: 'style quiz',
      routeLayoutOptions: {
        noContainer: true,
      },
    },
    children: [
      {
        path: '',
        name: 'style-quiz',
        component: () => import('src/components/quiz/LandingPage.vue'),
      },
      {
        path: 'pick-image',
        name: 'style-quiz-image-picker',
        component: () => import('src/components/quiz/ImagePicker.vue'),
      },
      {
        path: 'sign-in',
        name: 'style-quiz-sign-in',
        component: SoonaSignIn,
        meta: {
          context: 'style quiz sign in',
          routeLayoutOptions: {
            noContainer: true,
          },
        },
        props: {
          basePath: '/style-quiz',
        },
      },
      {
        path: 'sign-up',
        name: 'style-quiz-sign-up',
        component: () => import('src/components/quiz/SignUp.vue'),
      },
      {
        path: 'pick-star',
        name: 'style-quiz-star-picker',
        component: () => import('src/components/quiz/StarPicker.vue'),
      },
      {
        path: 'pick-role',
        name: 'style-quiz-role-picker',
        component: () => import('src/components/quiz/RolePicker.vue'),
      },
      {
        path: 'pick-words',
        name: 'style-quiz-word-picker',
        component: () => import('src/components/quiz/WordPicker.vue'),
      },
      {
        path: 'quiz-style/:quizStyleId',
        name: 'style-quiz-style',
        component: () => import('src/components/quiz/QuizResults.vue'),
      },
    ],
  },
];

<template>
  <div class="page-container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
// if updating, mirror left/right paddings with negative margins in PageContainerOffset
.page-container {
  padding: 2rem 1rem;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  max-width: 90rem;
}

@media screen and (min-width: 48rem) {
  .page-container {
    padding: 2rem 1.5rem;
  }
}

@media screen and (min-width: 64rem) {
  .page-container {
    padding: 2.5rem 2rem;
  }
}
</style>

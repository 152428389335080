<script setup>
import { computed, ref, toRefs } from 'vue';
import ListingActionTemplate from './ListingActionTemplate.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { AvoToast80, AvoToast20 } from '@/variables.module.scss';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ComparisonViewer from './ComparisonViewer.vue';
import { useDisablePublishFlow } from '@/composables/useDisablePublishFlow';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  listingAction: {
    type: Object,
    required: true,
  },
});

const { accountId } = toRefs(props);

dayjs.extend(relativeTime);

const showComparison = ref(false);

const { disablePublishFlow } = useDisablePublishFlow({
  accountId: accountId,
});

const publishedAtTimestamp = computed(() => {
  let timestamp = dayjs(props.listingAction.published_at);
  if (timestamp.isBefore(dayjs().subtract(1, 'week'))) {
    return timestamp.format('MMM DD, YYYY');
  }
  return dayjs().to(timestamp);
});
</script>

<template>
  <ListingActionTemplate
    :listing-action="listingAction"
    @open-comparison-viewer="showComparison = true"
  >
    <template #actions>
      <div
        v-if="listingAction.status === 'complete'"
        class="listing-action-complete u-label--regular"
      >
        <SoonaFlag
          title="published"
          type="pill"
          :text-color="AvoToast80"
          :background-color="AvoToast20"
        >
          <template #icon-left>
            <SoonaIcon
              name="circle-check-solid"
              size="small"
              class="listing-action-complete--icon"
            />
          </template>
        </SoonaFlag>
        <span v-if="!disablePublishFlow">
          {{ publishedAtTimestamp }}
        </span>
      </div>
    </template>
  </ListingActionTemplate>
  <ComparisonViewer
    v-if="showComparison"
    :listing-action-slug="listingAction.listing_action_slug"
    :original-asset="listingAction.source_asset ?? listingAction.original_asset"
    :replacement-asset="listingAction.replacement_asset"
    @close="showComparison = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-action-complete {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  color: variables.$gray-70;

  &--icon {
    color: variables.$avo-toast-60;
  }
}
</style>

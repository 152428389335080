import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getTopChoicesOnReservation } from '../api/proServiceRequirements';

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useGetTopChoicesOnReservation(reservationId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.topChoicesOnReservation(reservationId),
    queryFn: ({ signal }) =>
      getTopChoicesOnReservation(unref(reservationId), signal),
    ...queryOptions,
  });
}

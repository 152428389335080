<script setup>
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { computed } from 'vue';
import { useDeleteInvitation } from '@/queries/invitations/useDeleteInvitation';
import { usePriorityError } from '@/composables/usePriorityError';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  invitationId: {
    type: Number,
    required: true,
  },
});

defineEmits(['close']);

const accountId = computed(() => props.accountId);
const invitationId = computed(() => props.invitationId);

const {
  mutate: deleteInvitation,
  isPending: isDeleteInvitationLoading,
  error: deleteInvitationError,
} = useDeleteInvitation(accountId, invitationId);

const priorityError = usePriorityError(deleteInvitationError);

function removeInvitation(close) {
  deleteInvitation(
    {},
    {
      onSuccess: () => close(),
    }
  );
}
</script>

<template>
  <SoonaDialog type="alertdialog" @close="$emit('close')">
    <template #heading>cancel invite</template>
    <p>
      are you sure you want to cancel this invitation? this cannot be undone.
    </p>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        variation="primary"
        :loading="isDeleteInvitationLoading"
        @on-click="removeInvitation(close)"
      >
        remove
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

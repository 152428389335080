<script setup>
import { computed, ref } from 'vue';
import ShotListNoteDialog from './ShotListNoteDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useReservation } from '@/composables/useReservation';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const showShotListNoteDialog = ref(false);

const reservationId = computed(() => props.reservationId);
const { isPendingShotlist, isPreShoot, packDetails, shotListNote } =
  useReservation(reservationId);

const packType = computed(() => packDetails.value?.category);

const shotListDescription = computed(() =>
  packDetails.value?.description?.shotlist_header?.length
    ? packDetails.value?.description.shotlist_header[0]
    : ''
);
</script>
<template>
  <div class="pack-header">
    <div class="pack-header__content">
      <img
        class="pack-header__content--image"
        :src="packDetails?.pack_main_image_url"
      />
      <div class="pack-header__content--description">
        <h3 class="u-title--heavy">{{ packDetails?.name }}</h3>
        <p>
          {{ shotListDescription }}
        </p>
        <p>
          looking for more custom
          {{ packType === 'video' ? packType : 'shots' }}?
          <router-link
            class="pack-header__link"
            :to="{
              name: 'createReservation',
            }"
            target="_blank"
          >
            book another shoot</router-link
          >.
        </p>
        <p><strong>quantity purchased:</strong> {{ packDetails.quantity }}</p>
        <div v-if="shotListNote" class="pack-header__note">
          <div class="pack-header__note--header">
            <h4 class="u-subheader--heavy">note to the crew</h4>
            <SoonaButton
              v-if="isPendingShotlist || isPreShoot"
              variation="tertiary"
              size="medium"
              @on-click="showShotListNoteDialog = true"
            >
              <SoonaIcon name="pen-square" />edit
            </SoonaButton>
          </div>
          <div class="pack-header__note--body">
            <p>{{ shotListNote }}</p>
          </div>
        </div>
        <SoonaButton
          v-else-if="isPendingShotlist || isPreShoot"
          variation="tertiary"
          @on-click="showShotListNoteDialog = true"
        >
          <SoonaIcon name="plus" />add note
        </SoonaButton>
      </div>
    </div>
  </div>
  <ShotListNoteDialog
    v-if="showShotListNoteDialog"
    :reservation-id="reservationId"
    :shot-list-note="shotListNote"
    @close-dialog="showShotListNoteDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.pack-header {
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  box-shadow: variables.$elevation-0;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  width: 100%;

  &__content {
    display: flex;
    gap: 1rem;

    &--image {
      align-self: start;
      aspect-ratio: 1;
      border-radius: 0.3125rem;
      display: none;
      flex: 0 0 12.5rem;
      min-width: 0;
      object-fit: cover;

      @media (min-width: variables.$screen-sm-min) {
        display: block;
      }
    }

    &--description {
      align-items: flex-start;
      display: flex;
      flex: auto;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  &__link {
    text-decoration: underline;
  }

  &__note {
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-top: 0.5rem;

    padding: 1em;
    white-space: pre-wrap;
    width: 100%;

    &--header {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      justify-content: space-between;
    }

    &--body {
      max-height: 10rem;
      overflow-y: auto;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { convertToDateLong } from '@/lib/date-formatters';

// Props
const props = defineProps({
  currentOrder: {
    type: Object,
    required: true,
  },
});
const currentOrder = computed(() => props.currentOrder);
const dateOfIssue = computed(() =>
  convertToDateLong(currentOrder.value.created_at)
);
</script>

<template>
  <div class="invoice-details">
    <h2 class="u-headline--heavy invoice-details__headline">Invoice</h2>
    <div class="invoice-details__row">
      <p class="u-small--heavy">Invoice number</p>
      <p class="u-small--regular">{{ currentOrder.id }}</p>
    </div>
    <div class="invoice-details__row">
      <p class="u-small--heavy">Order number</p>
      <p class="u-small--regular">#{{ currentOrder.id }}</p>
    </div>
    <div class="invoice-details__row">
      <p class="u-small--heavy">Date of issue</p>
      <p class="u-small--regular">{{ dateOfIssue }}</p>
    </div>
    <div class="invoice-details__row">
      <p class="u-small--heavy">Date due</p>
      <p class="u-small--regular">{{ dateOfIssue }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.invoice-details {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__headline {
    margin-bottom: 0.75rem;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

// Desktop Styles
@mixin invoice-details-desktop-styles {
  .invoice-details {
    max-width: 14rem;
    margin-right: 1rem;
  }
}

@container order-view (min-width: 37.5rem) {
  @include invoice-details-desktop-styles;
}

/* compat: remove when dropping Safari 15 */
@supports not (container-type: inline-size) {
  @media (min-width: 39.5rem) {
    @include invoice-details-desktop-styles;
  }
}

@media print {
  .invoice-details {
    width: 15rem;

    &__row {
      p {
        font-weight: 700;
      }
    }
  }
}
</style>

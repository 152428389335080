<template>
  <div class="ProServiceProviderSelectionCard" data-cypress="pro-model-card">
    <div class="psp-select-card">
      <div class="top-half">
        <SoonaCarousel
          class="soona-carousel-component"
          :images="proServiceProvider.image_urls"
          :wrap-around="false"
          :image-pips="false"
          height="200px"
          width="200px"
        />
        <div class="view-details-section">
          <button
            class="button is-small view-details"
            @click="openViewDetailsModal"
          >
            view details
          </button>
        </div>
      </div>
      <ProServiceProviderSelectionViewDetails
        v-if="showViewDetailsModal"
        :pro-service-provider-id="proServiceProvider.id"
        :close-modal="closeViewDetailsModal"
        :pro-service-requirement-id="proServiceRequirementId"
        :reservation-id="reservationId"
      />

      <span class="info-wrapper">
        <p class="psp-name">{{ formatName }}</p>
        <AddModelDropdown
          :pro-service-provider="proServiceProvider"
          :pro-service-requirement-id="proServiceRequirementId"
          :reservation-id="reservationId"
        />
      </span>
      <div class="psp-availability-container">
        <AvailabilityFlag
          v-if="
            (showAvailability || isFtSoonaStaff) &&
            proServiceProvider.availability !== null &&
            proServiceProvider.availability !== undefined
          "
          :availability="proServiceProvider.availability"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import SoonaCarousel from 'src/components/ui_library/SoonaCarousel.vue';
import AddModelDropdown from './AddModelDropdown.vue';
import ProServiceProviderSelectionViewDetails from './ProServiceProviderSelectionViewDetails.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import AvailabilityFlag from '../../../directory/AvailabilityFlag.vue';
import { useFlag } from '@/composables/useFlag';
import { useCapability } from '@/composables/useCapability';

export default {
  name: 'ProServiceProviderSelectionCard',
  components: {
    SoonaCarousel,
    AddModelDropdown,
    ProServiceProviderSelectionViewDetails,
    AvailabilityFlag,
  },
  props: {
    proServiceProvider: Object,
    proServiceRequirementId: Number,
    reservationId: Number,
  },
  setup(props) {
    const route = useRoute();

    const { buttonClicked } = useBaseEvents();

    // provider details
    const showViewDetailsModal = ref(false);

    const openViewDetailsModal = () => {
      showViewDetailsModal.value = true;
      buttonClicked({
        context: route.meta.context,
        subContext: `${props.proServiceProvider.service_type} provider`,
        buttonLabel: 'view details',
        buttonAction: 'opens model details modal',
      });
    };
    const closeViewDetailsModal = () => {
      showViewDetailsModal.value = false;
    };

    const { hasCapability: isFtSoonaStaff } = useCapability({
      capability: 'ft_soona_staff',
    });
    const showAvailability = useFlag('apollo_pro_service_availability');

    return {
      closeViewDetailsModal,
      openViewDetailsModal,
      showViewDetailsModal,
      isFtSoonaStaff,
      showAvailability,
    };
  },
  data() {
    return {
      choiceMade: '',
    };
  },
  computed: {
    formatName() {
      let name = this.proServiceProvider.name
        ? this.proServiceProvider.name
        : this.proServiceProvider.user.profile_full_name
          ? this.proServiceProvider.user.profile_full_name
          : this.proServiceProvider.user.name;
      let nameArray = name.split(' ');
      return nameArray.length > 1
        ? nameArray[0] + ' ' + nameArray[1].slice(0, 1) + '.'
        : nameArray[0];
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.ProServiceProviderSelectionCard {
  .psp-select-card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 12.3125rem;
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    padding: 0;

    .top-half {
      &:hover {
        .view-details-section {
          .view-details {
            opacity: 1 !important;
            transition: fill 0.1s ease-out !important;
          }
        }
      }
      .soona-carousel-component {
        :deep(img) {
          height: 12.5rem;
          width: 12.375rem;
          border-radius: 2% 2% 0% 0%;
          object-fit: cover;
        }
      }
      .soona-carousel-component:hover {
        opacity: 1;
      }
      .avatar-image {
        height: 12.5rem;
        width: 12.375rem;
        padding: 1.875rem;
      }
      .view-details-section {
        display: flex;
        width: fit-content;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0%);

        .view-details {
          background: variables.$tangerine-20;
          border: 1px solid variables.$tangerine-20;
          border-radius: 50px;
          align-items: center;
          display: flex;
          justify-content: center;
          padding: 5px 10px;
          padding-top: 7px;
          color: variables.$black-default;
          font-size: 11px;
          font-weight: 400;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          opacity: 0;
        }
      }
    }

    .info-wrapper {
      padding: 0.625rem;
      white-space: nowrap;
      align-items: center;

      .psp-name {
        @include variables_fonts.u-title--heavy;
        margin-bottom: 0.3125rem;
        height: 2.1875rem;
        width: 11.5625rem;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .psp-availability-container {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
}
</style>

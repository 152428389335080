<template>
  <div class="booking-gallery">
    <div class="header-container">
      <h2 class="u-title--heavy">{{ title }}</h2>
      <SoonaLink
        v-if="accountId && includeSeeAll"
        :to="`/account/${accountId}/bookings`"
      >
        see all
      </SoonaLink>
    </div>
    <SoonaGrid v-if="reservations.length > 0" class="reservations-container">
      <router-link
        v-for="reservation in reservations"
        :key="reservation.id"
        :to="{
          path:
            linkTo !== 'gallery'
              ? `/reservation/${reservation.id}/${linkTo}`
              : `/reservation/${reservation.id}`,
        }"
        class="reservation-card"
        :data-cypress="`container-reservation-${reservation.id}`"
      >
        <div
          class="preview-image"
          :style="
            reservation.preview_url
              ? `background-image: url('${reservation.preview_url}');`
              : `background-image: linear-gradient(rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5)), url('${reservation.location.booking_gallery_image_url}');`
          "
        >
          <div v-if="reservation.status === 'in_progress'" class="live-badge">
            <p>live</p>
          </div>
          <div v-if="onSale(reservation)" class="sale-badge">
            <p>🎉 on sale!</p>
          </div>
          <SpeedyCheckoutBadge
            v-if="speedyCheckoutFlag && reservation.fast_checkout_eligible"
            :checkout-deadline="reservation.fast_checkout_deadline"
            :reservation-id="reservation.id"
          />
          <div v-if="!hideAssignments" class="crew-container is-pulled-right">
            <img
              v-for="crew in reservation.crew_assignments"
              :key="crew.id"
              :src="crew.user_avatar || soonaIconMenuImage"
              class="crew-avatar-img"
              :title="assignmentInfo(crew)"
            />
          </div>
        </div>

        <div class="details-container">
          <div v-if="detailedTitle" class="detailed-title u-subheader--heavy">
            <span v-if="reservation.account.vip" class="vip-booking">VIP</span
            ><SoonaFlag
              v-for="flag in filteredAccountFlags(reservation)"
              :key="flag.id"
              :title="flag.title"
              :text-color="flag.text_color"
              :background-color="flag.background_color"
            /><span class="reservation-name title"
              >{{ reservation.account.name }} - <span v-html="reservation.name"
            /></span>
          </div>
          <p v-else class="u-subheader--heavy" v-html="reservation.name" />

          <p v-if="detailedTitle" class="datetime">
            {{ convertToMMDDYYYYlong(reservation.start) }} @
            {{ convertToHHMM12(reservation.start) }}
          </p>

          <div v-if="includeTags" class="infos-container">
            <div class="info-container">
              <img
                class="info-image"
                :src="reservationTypeImage(reservation)"
                alt=""
              />
              <div class="info-title-container">
                <p class="info-title">
                  {{ reservation.location.short_name }}
                </p>
                <p class="info-subtitle">
                  {{ reservationType(reservation) }}
                </p>
              </div>
            </div>

            <div
              v-for="tag in tags(reservation)"
              :key="tag.id"
              class="info-container"
              :class="!tag.image_url ? 'centered' : ''"
            >
              <img
                v-if="tag.image_url"
                class="info-image"
                :src="tag.image_url"
                alt=""
              />
              <div class="info-title-container">
                <p class="info-title">
                  {{ tag.title.toLowerCase() }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </SoonaGrid>
    <div v-else class="empty-booking-message">
      <div
        class="empty-booking-figure is-flex justify-center items-center flex-column"
      >
        <img
          src="@images/anim/empty-bookings.svg"
          alt="empty bookings blob head"
        />
        <p class="text-s mt-s has-text-centered">
          <strong> <slot name="empty-message"></slot></strong>
        </p>
      </div>
    </div>

    <div v-if="!lastPage && reservations.length > 0" class="footer-container">
      <SoonaButton variation="secondary-gray" @on-click="loadMoreClicked">
        load more
      </SoonaButton>
    </div>
  </div>
</template>

<script>
import { useFlag } from '@/composables/useFlag';
import dateTimeMixin from 'src/mixins/dateTimeMixin';
import soonaIconMenuImage from 'images/soona-icon-menu.png';

import inStudio from '@images/in_studio.png';
import anytime from '@images/anytime.png';
import surprise from '@images/surprise.png';
import internal from '@images/internal.png';
import headShotsForAll from '@images/headshots_for_all.png';
import SoonaFlag from 'src/components/ui_library/SoonaFlag.vue';
import SoonaGrid from '@/components/ui_library/SoonaGrid.vue';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SpeedyCheckoutBadge from '@/components/bookings/SpeedyCheckoutBadge.vue';

const reservationTypeImageMap = {
  in_studio: inStudio,
  anytime: anytime,
  surprise: surprise,
  internal: internal,
  headshots_for_all: headShotsForAll,
};

export default {
  components: {
    SoonaButton,
    SoonaLink,
    SoonaGrid,
    SoonaFlag,
    SpeedyCheckoutBadge,
  },
  mixins: [dateTimeMixin],
  props: {
    title: String,
    reservations: Array,
    linkTo: String,
    loadMore: Function,
    lastPage: Boolean,
    detailedTitle: Boolean,
    includeTags: Boolean,
    includeSeeAll: Boolean,
    hideAssignments: Boolean,
    accountId: {
      type: String,
      required: false,
    },
  },
  setup() {
    const speedyCheckoutFlag = useFlag('apollo_speedy_checkout');

    return {
      speedyCheckoutFlag,
      soonaIconMenuImage,
    };
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    tags(reservation) {
      return [...reservation.tags].sort((a, b) => a.id - b.id).slice(0, 1);
    },
    reservationType(reservation) {
      let type = reservation.reservation_type;

      if (type === 'anytime') {
        return 'online';
      } else if (type === 'headshots_for_all') {
        return 'h4all 🎉';
      } else {
        return reservation.reservation_type.replace(/_/g, ' ');
      }
    },
    onSale(reservation) {
      return (
        reservation.start != null &&
        new Date(reservation.start) < new Date(2022, 9, 1) &&
        new Date() < new Date(1668837540000) &&
        reservation.tags.some(t => t.title === 'Photo')
      );
    },
    filteredAccountFlags(reservation) {
      return reservation.account?.flags?.filter(
        flag => flag.slug === 'preferred'
      );
    },
    loadMoreClicked() {
      this.page = this.page + 1;
      this.loadMore(this.page);
    },
    assignmentInfo(crew) {
      return `${crew.user_name} (${crew.crew_role})`;
    },
    reservationTypeImage(reservation) {
      return reservationTypeImageMap[reservation.reservation_type];
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.booking-gallery {
  .header-container {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .reservation-card {
    display: block;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    /* to clip images with this border-radius */
    overflow: hidden;

    &:hover {
      background-color: variables.$gray-10;
    }

    &:active,
    &:focus-visible {
      outline: 0.125rem solid variables.$periwink-blue-60;
    }

    .preview-image {
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      aspect-ratio: 3 / 2;
      position: relative;

      @supports not (aspect-ratio: 3 / 2) {
        &::before {
          float: left;
          padding-top: 66.66666667%;
          content: '';
        }

        &::after {
          display: block;
          content: '';
          clear: both;
        }
      }

      .live-badge {
        display: inline-block;
        padding: 0.5rem 1rem;
        background-color: variables.$friendly-red-50;
        color: variables.$white-default;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 2px;
        font-weight: 800;
      }

      .crew-container {
        padding: 5px;

        .crew-avatar-img {
          height: 40px;
          width: 40px;
          margin-left: 5px;
          object-fit: cover;
          border-radius: 50%;
          border: 2px solid variables.$white-default;
        }
      }
    }

    .details-container {
      padding: 1rem;

      .title {
        margin: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .vip-booking {
          margin-right: 5px;
          padding: 1px 4px 1px 5px;
          color: variables.$white-default;
          background-color: variables.$friendly-red-50;
          text-transform: uppercase;
          font-size: 14px;
          border-radius: 3px;
          bottom: 1px;
          position: relative;
        }
      }

      .datetime {
        font-size: 10px;
        margin-bottom: 8px;
      }

      .infos-container {
        display: flex;
        flex-flow: row wrap;
        gap: 0.625rem;

        .info-container {
          display: flex;
          flex-grow: 1;
          height: 2.5rem;
          max-width: 8rem;
          background-color: variables.$white-default;
          border-radius: 0.375rem;
          border: 0.0625rem solid variables.$gray-30;
          overflow: hidden;

          &.centered {
            justify-content: center;
          }

          .info-image {
            height: auto;
            width: 2.5rem;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          .info-title-container {
            padding: 0.25rem 0.5rem;
            font-size: 0.6875rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .info-title {
              text-transform: lowercase;
              font-weight: 800;
            }
          }
        }
      }
    }
  }

  .footer-container {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.details-container {
  .detailed-title {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    width: 100%;

    &.title {
      font-size: 1rem;

      .vip-booking {
        margin-right: 0;
      }
    }

    .reservation-name {
      font-size: 1rem;
    }
  }
}

.sale-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.3125rem 0.625rem;
  width: 6.375rem;
  height: 1.625rem;

  color: variables.$white-default;
  font-weight: 800;
  letter-spacing: 0.094rem;
  text-transform: uppercase;
  font-size: 0.688rem;

  background: variables.$periwink-blue-60;

  border-radius: 3.125rem;
  position: relative;
  top: 1rem;
  float: right;
  right: 1rem;
}

.empty-booking-message {
  display: flex;
  justify-content: center;

  .empty-booking-figure {
    width: 9.375rem;

    .empty-booking-animation {
      height: 9.375rem;
      width: 9.375rem;
    }
  }
}

.fast-checkout-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.3125rem 0.625rem;
  height: 1.625rem;
  background: variables.$bubbletape-pink-10;
  font-weight: 800;
  letter-spacing: 0.094rem;
  text-transform: uppercase;
  font-size: 0.688rem;
  border-radius: 3.125rem;
  position: relative;
  top: 1rem;
  float: right;
  right: 1rem;

  .icon {
    flex: 0 0 1rem;
    margin-right: 0.25rem;
  }
}
</style>

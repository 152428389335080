<script setup>
import { computed, toRefs } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  showBackButton: {
    default: false,
    type: Boolean,
  },
  size: {
    default: undefined,
    type: String,
    validator: value => {
      return ['', 'closed', 'short', 'medium', 'large'].includes(value);
    },
  },
});

const emits = defineEmits(['back', 'close']);

const { size, showBackButton } = toRefs(props);
const sizeClass = computed(() => {
  if (size.value) {
    return `action-bar-bottom--${size.value}`;
  } else {
    return '';
  }
});
</script>

<template>
  <section
    class="action-bar-bottom"
    :class="[
      {
        'action-bar-bottom--primary-nav': $slots['primary-nav'],
        'action-bar-bottom--short':
          $slots['secondary-nav'] && !$slots['primary-nav'],
        'action-bar-bottom--medium':
          !sizeClass &&
          $slots['default'] &&
          !$slots['primary-nav'] &&
          !$slots['secondary-nav'],
      },
      sizeClass,
    ]"
  >
    <Transition name="header-toggle">
      <header v-if="!$slots['primary-nav']" class="action-bar-bottom__header">
        <SoonaButton
          v-if="showBackButton"
          class="action-bar-bottom__back"
          variation="icon-gray-outline"
          size="medium"
          @on-click="emits('back')"
        >
          <SoonaIcon name="arrow-left" size="medium" />
        </SoonaButton>
        <h2 v-if="$slots['heading']" class="u-body--heavy">
          <slot name="heading" />
        </h2>
        <SoonaButton
          class="action-bar-bottom__close"
          variation="icon-gray-outline"
          size="medium"
          @on-click="emits('close')"
        >
          <SoonaIcon name="xmark" size="medium" />
        </SoonaButton>
      </header>
    </Transition>
    <div class="action-bar-bottom__content">
      <nav v-if="$slots['primary-nav']" class="action-bar-bottom__nav">
        <slot name="primary-nav" />
      </nav>
      <Transition v-else name="subnav-transition" mode="out-in">
        <div
          :key="$slots['secondary-nav'] ? 'secondary-nav' : 'content'"
          class="subnav-transition"
          :class="{
            'subnav-transition--has-back': showBackButton,
            'subnav-transition--full-height': $slots['has-own-scroll'],
          }"
        >
          <p
            v-if="$slots['subheading']"
            class="u-label--regular action-bar-bottom__subheading"
          >
            <slot name="subheading" />
          </p>
          <nav
            v-if="$slots['secondary-nav']"
            class="action-bar-bottom__nav action-bar-bottom__nav--secondary"
          >
            <slot name="secondary-nav" />
          </nav>
          <slot v-else-if="$slots['has-own-scroll']" name="has-own-scroll" />
          <slot v-else />
        </div>
      </Transition>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use 'src/variables';

.action-bar-bottom {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-top: 0.0625rem solid variables.$gray-30;
  background-color: variables.$white-default;
  height: 3.5625rem;
  border-radius: 1rem 1rem 0 0;
  transition:
    height 0.4s cubic-bezier(0.22, 1, 0.36, 1),
    border-radius 0.4s cubic-bezier(0.22, 1, 0.36, 1);

  &--primary-nav {
    border-radius: 0;
  }

  &--closed {
    height: 0;
  }

  &--short {
    height: 11.4375rem;
  }

  &--medium {
    height: 18.5rem;
  }

  &--large {
    height: 21.5rem;
  }

  &__header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1.25rem 1rem;
  }

  &__close {
    margin-left: auto;
  }

  &__content {
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0 0 2rem;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__subheading {
    color: variables.$gray-60;
    margin-bottom: 1.25rem;
  }

  &__nav {
    display: flex;

    &--secondary {
      justify-content: space-around;
      gap: 0.75rem;
    }
  }
}

.subnav-transition {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  &--full-height {
    overflow: hidden;
  }
}

.header-toggle-enter-active {
  transition: opacity 0.3s ease-out;
}

.header-toggle-enter-from {
  opacity: 0;
}

.header-toggle-enter-active {
  transition: opacity 0.3s ease-out;
}

.subnav-transition-enter-active,
.subnav-transition-leave-active {
  transition:
    opacity 0.2s ease-out,
    transform 0.2s ease-out;
}

.subnav-transition-enter-from,
.subnav-transition-leave-to {
  opacity: 0;
  transform: translateX(-1em);

  &.subnav-transition--has-back {
    transform: translateX(1em);
  }
}
</style>

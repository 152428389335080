<template>
  <div>
    <div class="heading-wrapper">
      <div class="input-wrapper">
        <v-select
          v-model:model-value="currentLocation"
          :get-option-label="location => `${location.name}`"
          :options="account.owner.locations"
          :reduce="locations => locations"
          :clearable="false"
          :searchable="false"
        ></v-select>
        <v-select
          v-model:model-value="bookableSpaceFilter"
          multiple
          :get-option-label="bs => `${bs.name}`"
          :options="bookableSpaces"
          :reduce="bookableSpaces => bookableSpaces.id"
          :clearable="false"
          :searchable="false"
          :placeholder="`all ${currentLocation.name} bays`"
        >
          <template #option="bs">
            <div :class="bs.status == 'inactive' ? 'is-inactive' : ''">
              <span
                ><input
                  type="checkbox"
                  :checked="bookableSpaceFilter.includes(bs.id)"
                  class="mr-xs"
                />
                <b>{{ bs.name }}</b></span
              >
              <br />
              <p class="ml-l">{{ bs.description }}</p>
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <FullCalendar
      ref="calendar"
      :options="calendarOptions"
      data-cypress="availability-calendar"
    />
    <unavailability-event-modal
      v-if="currentEvent && showUnavailabilityModal"
      :current-event="currentEvent"
      :close-modal="closeUnavailabilityEventModal"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import * as types from 'src/store/mutation-types';
import UnavailabilityEventModal from 'src/components/shared/UnavailabilityEventModal.vue';
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import styles from 'src/variables.module.scss';

export default {
  components: {
    FullCalendar,
    UnavailabilityEventModal,
  },
  props: [
    'user',
    'location',
    'search',
    'assignedReservations',
    'scheduleStatus',
    'packageStatus',
  ],
  data() {
    return {
      calendarOptions: {
        plugins: [timeGridPlugin, dayGridPlugin],
        initialView: 'timeGridWeek',
        slotMinTime: '07:00:00',
        slotMaxTime: '22:00:00',
        height: 'auto',
        timeZone: 'local',
        nowIndicator: true,
        headerToolbar: {
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        eventTimeFormat: {
          // like '14:30'
          hour: '2-digit',
          minute: '2-digit',
        },
        eventSources: [
          {
            events: (info, successCallback, failureCallback) => {
              if (this.currentLocation?.name) {
                this.loadScheduleForLocation({
                  start: info.startStr.substring(0, 10),
                  end: info.endStr.substring(0, 10),
                  q: this.search,
                  scheduleStatus: this.scheduleStatus,
                  packageStatus: this.packageStatus,
                  bookableSpaceIds: this.bookableSpaceFilter,
                  crewIds: [this.account.owner.id],
                }).then(() => {
                  successCallback(this.reservations);
                });
              } else {
                failureCallback([]);
              }
            },
            eventDataTransform: originalEventData => {
              let eventData = { ...originalEventData };
              return {
                title: eventData.title,
                start: eventData.formatted_time.start,
                end: eventData.formatted_time.end,
                extendedProps: { ...eventData },
                borderColor: styles.BlackDefault,
                textColor: styles.BlackDefault,
                color: eventData.color,
                display: 'block',
              };
            },
          },
          {
            events: (info, successCallback, failureCallback) => {
              if (this.currentLocation?.name && !this.isProServiceAccount) {
                this.loadUnavailabilityEvents({
                  start: info.startStr.substring(0, 10),
                  end: info.endStr.substring(0, 10),
                  crewUserIds: [this.account.owner.id],
                  type: 'UnavailabilityEvent',
                  timeZone:
                    this.calendarOptions.timeZone === 'local'
                      ? this.currentLocation.timezone
                      : this.calendarOptions.timeZone,
                }).then(() => {
                  successCallback(this.events);
                });
              } else {
                failureCallback([]);
              }
            },
            textColor: 'white',
            backgroundColor: '#6b6b6b',
            borderColor: '#6b6b6b',
            eventDataTransform: originalEventData => {
              let eventData = { ...originalEventData };
              return {
                title: eventData.title,
                start: eventData.formatted_time.start,
                end: eventData.formatted_time.end,
                extendedProps: { ...eventData },
                display: 'block',
              };
            },
          },
          {
            events: (info, successCallback, failureCallback) => {
              if (this.currentLocation?.name && this.isProServiceAccount) {
                this.loadUserAvailabilityEvents({
                  start: info.startStr.substring(0, 10),
                  end: info.endStr.substring(0, 10),
                  userId: this.account.owner.id,
                  type: 'AvailabilityEvent',
                  timeZone:
                    this.calendarOptions.timeZone === 'local'
                      ? this.currentLocation.timezone
                      : this.calendarOptions.timeZone,
                }).then(response => {
                  successCallback(response);
                });
              } else {
                failureCallback([]);
              }
            },
            borderColor: styles.BlackDefault,
            textColor: styles.BlackDefault,
            backgroundColor: styles.GreenApple30,
            eventDataTransform: originalEventData => {
              let eventData = { ...originalEventData };
              return {
                title: eventData.title,
                start: eventData.formatted_time.start,
                end: eventData.formatted_time.end,
                extendedProps: { ...eventData },
                display: 'block',
              };
            },
          },
        ],
        eventContent: arg => {
          let event = { ...arg.event._def.extendedProps };
          let view = { ...arg.view };
          if (event.type === 'UnavailabilityEvent') {
            let eventName = event.user.name;
            if (view.type === 'dayGridMonth') {
              let content = `<p class="u-label--small">${event.formatted_time.start_readable} ${eventName}</p>`;
              return { html: `<div class="fc-content">${content}</div>` };
            } else {
              let duration = this.calculateDuration(event.start, event.end);
              let content = '';
              if (duration < 0.5) {
                content = `<p class="u-label--small">${event.formatted_time.start_readable} ${eventName}</p>`;
              } else {
                content = `<p class="u-label--small">${event.formatted_time.start_readable}</p>
                <p class="u-label--regular">${eventName}</p>
                <p class="u-label--regular">Unavailable</p>`;
              }
              return { html: `<div class="fc-content">${content}</div>` };
            }
          } else if (event.type === 'AvailabilityEvent') {
            let eventName = 'available';
            if (view.type === 'dayGridMonth') {
              let content = `< class="u-label--small">${event.formatted_time.start_readable} - ${event.formatted_time.end_readable} ${eventName}</p>`;
              return { html: `<div class="fc-content">${content}</div>` };
            } else {
              let duration = this.calculateDuration(event.start, event.end);
              let content = '';
              if (duration < 0.5) {
                content = `<p class="u-label--small">${event.formatted_time.start_readable} ${eventName}</p>`;
              } else {
                content = `<p class="u-label--small">${event.formatted_time.start_readable} - ${event.formatted_time.end_readable}</p>
                <p class="u-label--regular">${eventName}</p>`;
              }
              return { html: `<div class="fc-content">${content}</div>` };
            }
          } else {
            let passed = event.order_status === 'completed';
            let eventName = event.account_name;
            if (event.shoot_type == 'video') {
              if (passed) {
                eventName =
                  '<span class="completed-icons">🎥 </span>' + eventName;
              } else {
                eventName = '🎥 ' + eventName;
              }
            }
            let crewIcon = '';
            if (event.crew.length > 0) {
              if (passed) {
                crewIcon = '<span class="completed-icons">👤</span>';
              } else {
                crewIcon = '👤';
              }
            }
            if (view.type === 'dayGridMonth') {
              let content = `<p class="u-label--small">${event.formatted_time.start_readable} ${eventName} ${crewIcon}</p>`;
              if (passed) {
                content = `<div class="completed-schedule-text">${content}</div>`;
              }
              return { html: `<div class="fc-content">${content}</div>` };
            } else {
              let duration = this.calculateDuration(event.start, event.end);
              let bayAssignmentNames = event.bookable_spaces.map(bay => {
                return `<p>${bay.name}</p>`;
              });
              let content = '';
              if (duration < 0.5) {
                content = `<p class="u-label--small">${event.formatted_time.start_readable} ${eventName} ${crewIcon}</p>`;
              } else {
                content = `<p class="u-label--small">${
                  event.formatted_time.start_readable
                }</p>
                <p class="u-label--regular">${eventName}</p>
                <div class="u-label--small">
                  <i>
                    ${bayAssignmentNames.join('')}
                  </i>
                </div>
                <div>
                  ${crewIcon}
                </div>`;
              }
              if (passed) {
                content = `<div class="completed-schedule-text">${content}</div>`;
              }
              return { html: `<div class="fc-content">${content}</div>` };
            }
          }
        },
        eventClick: info => {
          this.eventSelected(info.event._def.extendedProps);
        },
      },
      startOfCurrentWeek: new Date(),
      currentView: null,
      bookableSpaceFilter: [],
      showUnavailabilityModal: false,
      currentEvent: null,
      hover: false,
    };
  },
  computed: {
    ...mapGetters('reservations', ['bookableSpaceIds']),
    ...mapState({
      reservations: state => state.schedule.reservations,
      selectedLocation: state => state.locations.currentLocation,
      locations: state => state.locations.locations,
      bookableSpaces: state => state.locations.currentLocationSpaces,
      currentUserId: state => state.currentUser.userID,
      account: state => state.account,
      events: state => state.events.events,
    }),
    currentLocation: {
      get() {
        return this.selectedLocation;
      },
      set(value) {
        this.loadLocationByName({ name: value.name }).then(() => {
          localStorage.setItem(
            'defaultLocation',
            JSON.stringify(this.selectedLocation)
          );
          this.bookableSpaceFilter = [];
          this.$nextTick(() => {
            this.refreshEvents();
          });
        });
      },
    },
    isProServiceAccount() {
      return this.account.owner.employee_type_title === 'pro services';
    },
  },
  watch: {
    bookableSpaceFilter: {
      handler() {
        this.refreshEvents();
      },
      deep: true,
    },
    currentLocation: function (location) {
      this.updateCalendar(location);
    },
  },
  mounted() {
    this.loadLocations().then(() => {
      const accountLocations = typeof this.account.owner.locations[0];
      if (accountLocations != 'undefined' && accountLocations !== null) {
        this.currentLocation = this.account.owner.locations[0];
      } else if (localStorage.getItem('defaultLocation')) {
        this.currentLocation = JSON.parse(
          localStorage.getItem('defaultLocation')
        );
      } else {
        this.currentLocation = this.locations[0];
      }
    });
    this.calendarApi = this.$refs.calendar.getApi();
  },
  methods: {
    ...mapActions('schedule', ['loadScheduleForLocation']),
    ...mapActions('locations', ['loadLocationByName', 'loadLocations']),
    ...mapActions('events', ['loadUnavailabilityEvents']),
    ...mapActions('account', ['loadProServiceAvailability']),
    ...mapMutations('schedule', [types.TOGGLE_UPDATE_RESERVATION_MODAL]),
    eventSelected(event) {
      if (event.type === 'UnavailabilityEvent') {
        this.currentEvent = event;
        this.showUnavailabilityModal = true;
      } else if (event.type === 'AvailabilityEvent') {
        this.currentEvent = null;
      } else {
        this.$router.push({ path: `/reservation/${event.id}/info` });
      }
    },
    closeUnavailabilityEventModal() {
      this.currentEvent = null;
      this.showUnavailabilityModal = false;
      this.refreshEvents();
    },
    refreshEvents() {
      this.calendarApi.refetchEvents();
    },
    calculateDuration(start, end) {
      start = new Date(start);
      end = new Date(end);
      return (end.getTime() - start.getTime()) / 1000 / 60 / 60;
    },
    updateCalendar(location) {
      this.calendarOptions.timeZone = location.timezone;
    },
    async loadUserAvailabilityEvents(params) {
      let queryParams = `start=${params.start}&end=${params.end}&type=${
        params.type
      }&time_zone=${encodeURIComponent(params.timeZone)}&subject_id=${
        params.userId
      }&subject_type=User`;
      let response = await this.http.get(`events.json?${queryParams}`);
      return response.data;
    },
  },
};
</script>

<style lang="scss">
@use '@/variables';

.is-inactive {
  color: gray;
}
.heading-wrapper {
  display: flex;
  .input-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .vs__dropdown-toggle {
      width: 190px;
      margin-left: 20px;
    }
  }
}
.unavailability-wrapper {
  display: flex;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  font-size: 12px;
  font-weight: 100;
  visibility: visible;
  width: 120px;
  background-color: variables.$white-default;
  color: variables.$gray-90;
  border: 0.0625rem solid variables.$periwink-blue-60;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -30px;
  left: -70px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.toggle-text {
  margin-left: 5px;
  margin-top: 3px;
}
.fc-toolbar-chunk {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.fc .fc-toolbar {
  gap: 0.5rem;
}
@media screen and (max-width: variables.$screen-sm-max) {
  .heading-wrapper {
    flex-direction: column;
    .input-wrapper {
      flex-direction: column;
      .vs__dropdown-toggle {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
}
@media only screen and (max-width: 975px) {
  .fc-header-toolbar {
    .fc-toolbar-chunk {
      .fc-toolbar-title {
        font-size: 100%;
      }
    }
  }
}
@media only screen and (max-width: 534px) {
  .fc-header-toolbar {
    display: flex;
    flex-flow: row wrap;
    .fc-toolbar-chunk:nth-child(2) {
      order: 3;
      margin: auto;
    }
  }
}
</style>

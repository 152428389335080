<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="18" height="18" rx="9" fill="#fff" />
    <path
      d="M17 9c0-4.416-3.584-8-8-8S1 4.584 1 9s3.584 8 8 8 8-3.584 8-8ZM5 9.8V8.2h7.52v1.6H5Z"
      fill="#a36200"
    />
  </svg>
</template>

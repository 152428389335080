<template>
  <div class="inventory-tab">
    <div v-if="storeAtSoona" class="pso-wrapper no_option">
      <p class="post-shoot-option-text-wrapper cancel">
        <strong>let us know how you'd like to process these items</strong>
        <button class="editPSObtn blue" @click="showEditModal">
          choose option
        </button>
      </p>
    </div>
    <div v-else-if="returnShipping || discardOption" :class="psoWrapperClass">
      <p class="post-shoot-option-text-wrapper">
        <span
          class="post-shoot-option-text"
          :class="displayStyle === 'short' ? 'short' : ''"
          v-html="postShootOptionText"
        ></span>
        <button class="editPSObtn" @click="showEditModal">edit option</button>
      </p>
      <div v-if="displayStyle !== 'short'" class="pso-icon">
        {{ psoIcon }}
      </div>
    </div>
    <SoonaDialog
      v-if="editModalVisible"
      class="cancellation-update-PSO-modal"
      @close="closeEditModal"
    >
      <template #heading>membership cancellation</template>
      <p class="subhead">let us know how you'd like to process stored items</p>
      <div class="options-wrapper">
        <span
          v-for="option in editablePSOcancel"
          :key="option.value"
          class="post-shoot-option-card"
        >
          <label>
            <input
              v-model="postShootOption"
              type="radio"
              name="package-option"
              :value="option"
            />
            <post-shoot-options-card
              :class="disableFastPassReturnOption(option) ? 'disabled' : ''"
              :option="option"
            />
          </label>
        </span>
      </div>
      <p v-if="psoChoice.value === 'discard'" class="discard-warning">
        ⚠️ are you sure you want to discard this package?
      </p>
      <template #footer="{ close }">
        <SoonaButton
          variation="tertiary"
          data-cypress="button-dialog-cancel"
          @on-click="close"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          data-cypress="button-dialog-confirm"
          @on-click="confirmEdit"
        >
          {{ confirmButtonText }}
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import PostShootOptionsCard from '../../../booking/PostShootOptionCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useInventory } from '@/composables/useInventory';

export default {
  components: { SoonaDialog, PostShootOptionsCard, SoonaButton },
  props: {
    inventoryPackage: Object,
    displayStyle: String,
    updateFastPassPackage: Function,
  },
  setup() {
    const { postShootOptions } = useInventory();

    return { postShootOptions };
  },
  data() {
    return {
      editModalVisible: false,
      psoChoice: {},
      errors: undefined,
    };
  },
  computed: {
    ...mapGetters('reservation', ['currentReservation']),
    ...mapGetters('account', ['accountId']),
    ...mapState({ account: state => state.account }),
    postShootOption: {
      get: function () {
        return this.psoChoice.value;
      },
      set: function (value) {
        this.onSelectOfPSO(value);
      },
    },
    storeAtSoona() {
      return this.inventoryPackage.post_shoot_option === 'store_at_soona'
        ? true
        : false;
    },
    returnShipping() {
      return this.inventoryPackage.post_shoot_option === 'return_shipping'
        ? true
        : false;
    },
    discardOption() {
      return this.inventoryPackage.post_shoot_option === 'discard'
        ? true
        : false;
    },
    editablePSOcancel() {
      return this.postShootOptions
        .filter(pso => pso.value !== 'already_stored')
        .filter(pso => pso.value !== 'store_at_soona');
    },
    psoWrapperClass() {
      if (this.inventoryPackage.post_shoot_option) {
        return this.displayStyle === 'short'
          ? `pso-wrapper short-style ${this.inventoryPackage.post_shoot_option}`
          : `pso-wrapper ${this.inventoryPackage.post_shoot_option}`;
      } else {
        return 'pso-wrapper no_option';
      }
    },
    postShootOptionText() {
      if (this.returnShipping) {
        return this.displayStyle === 'short'
          ? '<strong>POST SHOOT: RETURN</strong>'
          : 'soona will <strong>send these items back.</strong>';
      } else if (this.discardOption) {
        return this.displayStyle === 'short'
          ? '<strong>POST SHOOT: DISCARD</strong>'
          : 'soona will <strong>safely discard these items.</strong>';
      } else {
        return this.displayStyle === 'short'
          ? '<strong>POST SHOOT: STORE</strong>'
          : 'soona will <strong>store these items.</strong> return or discard these items';
      }
    },
    psoIcon() {
      if (this.returnShipping) {
        return '🔙';
      } else if (this.discardOption) {
        return '🗑';
      } else {
        return '🗃';
      }
    },
    confirmButtonText() {
      return this.psoChoice.value === 'discard' ? 'yes, discard' : 'save';
    },
  },
  methods: {
    ...mapActions('inventoryPackages', ['stagePackagesForReturn']),
    disableFastPassReturnOption(option) {
      let isFastPass =
        this.inventoryPackage.status === 'stored' &&
        !this.inventoryPackage.required_action;
      return (
        isFastPass &&
        !this.inventoryPackage.intake_complete &&
        option.value === 'return_shipping'
      );
    },
    showEditModal() {
      this.editModalVisible = true;
    },
    confirmEdit() {
      let packageAttributes = {
        inventoryPackageId: this.inventoryPackage.id,
        post_shoot_option: this.psoChoice.value,
        required_action:
          this.inventoryPackage.status === 'stored'
            ? this.psoChoice.requiredAction
            : null,
      };
      this.updateFastPassPackage(packageAttributes);
      this.editModalVisible = false;
    },
    closeEditModal() {
      this.editModalVisible = false;
    },
    onSelectOfPSO(value) {
      this.psoChoice = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.pso-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 12px;
  font-weight: 100;
  &.no_option {
    border: 1px solid variables.$friendly-red-50;
  }
  &.discard {
    background-color: #e9eab3;
  }
  &.return_shipping {
    background-color: #fde9fd;
  }
  &.short-style {
    height: 30px !important;
    margin-top: 5px;
    padding: 7px 10px;
  }
  .post-shoot-option-text-wrapper {
    display: flex;
    width: 75%;
    &.cancel {
      width: 100%;
      justify-content: space-between;
    }
    .editPSObtn {
      background: none;
      text-decoration: underline;
      margin-top: -1px;
      &.blue {
        color: variables.$periwink-blue-60;
      }
    }
  }
}

//modal
.options-wrapper {
  .post-shoot-option-card {
    height: 20px;
    width: 20px;
    border: 1px solid transparent;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .option-content {
        box-shadow: 0 0 0 2px variables.$black-default;

        &:hover {
          box-shadow: 0 0 0 2px variables.$black-default;
        }
      }
    }
  }
}
</style>

<script setup>
import { computed, toRefs } from 'vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import { component as Viewer } from 'v-viewer';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  listing: {
    type: Object,
    default: null,
  },
});

const { listing } = toRefs(props);

const isLoading = computed(() => !listing.value);

const additionalAssets = computed(() => {
  return listing.value?.assets.filter(
    asset => asset.digital_asset_url !== listing.value.main_image_url
  );
});
</script>

<template>
  <div v-if="isLoading" class="listing-detail-images">
    <SoonaSkeleton class="listing-detail-images__img--loading" />
    <div class="listing-detail-images__additional-assets">
      <SoonaSkeleton
        v-for="i in 3"
        :key="i"
        class="listing-detail-images__additional-assets__img--loading"
      />
    </div>
  </div>
  <Viewer
    v-else
    :options="{
      toolbar: false,
      navbar: false,
      title: false,
      backdrop: true,
      minZoomRatio: 0.01,
      maxZoomRatio: 2,
      keyboard: true,
    }"
    class="listing-detail-images"
  >
    <img
      :src="listing.main_image_url ?? PlaceholderImage"
      alt="listing main image"
    />
    <div
      v-if="additionalAssets.length"
      class="listing-detail-images__additional-assets"
    >
      <img
        v-for="asset in additionalAssets"
        :key="asset.digital_asset_id"
        :src="asset.digital_asset_url"
        :alt="`image ${asset.image_position}`"
      />
    </div>
  </Viewer>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-detail-images {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;

  &__img--loading,
  img {
    border-radius: 0.3125rem;
    object-fit: cover;
    width: 9.75rem;
    height: 9.75rem;

    &:hover {
      cursor: zoom-in;
    }
  }

  &__additional-assets {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;

    &__img--loading,
    img {
      border-radius: 0.3125rem;
      object-fit: cover;
      width: 5rem;
      height: 5rem;
    }
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    flex-flow: row nowrap;
  }
}
</style>

<script setup>
import { computed, ref, toRef } from 'vue';
import { useRoute } from 'vue-router';
import BookingLayout from '../BookingLayout.vue';
import faqData from './faq.json';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaTagOptionCard from 'src/components/ui_library/soona_tag_option_card/SoonaTagOptionCard.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useReservation } from '@/composables/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useTagCategories } from '@/queries/useTagCategories';
import TagRequirementDialog from '@/components/booking/v3/p2/TagRequirementDialog.vue';

// props and data
const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

let buttonsLoading = ref([]);
let soonaTagOptionCard = ref([]);

const { itemSelected, linkClicked } = useBaseEvents();
const route = useRoute();
const reservationId = computed(() => props.reservationId);

const {
  data: videoTypeData,
  status,
  error: resTagsError,
} = useReservationTags(reservationId, toRef(props, 'stepName'));

const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId);

const { reservation, proServiceSlugForSize, isLoading, error } =
  useReservation(reservationId);

const formattedProServiceSlugForSize = computed(() => {
  const slug = proServiceSlugForSize.value;

  return { slug, include_tags: true };
});

const { data: proServiceTagCategory, isLoading: isTagCategoryLoading } =
  useTagCategories(formattedProServiceSlugForSize, {
    enabled: computed(() => !!proServiceSlugForSize.value),
  });

// computed
const isBusy = computed(() => {
  return (
    buttonsLoading.value?.length >= 1 ||
    isAdding.value ||
    isRemoving.value ||
    isLoading.value ||
    isTagCategoryLoading.value
  );
});

const currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});

const isStepValid = computed(() => {
  return !isBusy.value && currentStep.value?.complete;
});

const videoTypeOptions = computed(() => {
  return videoTypeData.value?.options || [];
});

const priorityError = usePriorityError(
  addError,
  removeError,
  resTagsError,
  error
);

const isTagRequirementDialogOpen = ref(false);
const tagRequirement = ref({});
const currentOption = ref({});

// methods
function trackVideoPlayed(id) {
  for (const card of soonaTagOptionCard.value) {
    if (card.tagId !== id) {
      card.pauseVideo();
    }
  }
}

function onBackClick() {
  props.onBack();
}

function onNextClick() {
  if (route.query?.pro_service) {
    const proServiceTagId = proServiceTagCategory.value?.[0].tags?.find(
      t =>
        t.title.replace(/\s+/g, '_').replace(/&/g, 'and') ===
        route.query.pro_service
    )?.id;

    const hasThisProService = reservation.value?.tags?.find(
      t =>
        t.title.replace(/\s+/g, '_').replace(/&/g, 'and') ===
        route.query.pro_service
    );

    if (!hasThisProService) {
      addReservationTag(
        { tagId: proServiceTagId },
        {
          onSuccess: () => {
            props.onNext();
          },
        }
      );
    } else {
      props.onNext();
    }
  } else {
    props.onNext();
  }
}

function removeFromButtonList(tag_id) {
  buttonsLoading.value = buttonsLoading.value?.filter(o => o !== tag_id);
}

function removeReservationTagHandler(option) {
  removeReservationTag(
    {
      reservationTagId: option.reservation_tag.id,
      deleteObsoleted: true,
    },
    {
      onSettled: () => removeFromButtonList(option.tag.id),
    }
  );
}

function addOrRemoveSelection({
  option,
  addRecommended = false,
  trackItemAdded = false,
}) {
  const beingSelected = !option.selected;
  if (beingSelected) {
    let alreadySelectedTag = videoTypeOptions.value.filter(t => t.selected)[0];
    if (alreadySelectedTag) {
      removeReservationTagHandler(alreadySelectedTag);
    }
    addReservationTag(
      { tagId: option.tag.id, addRecommended: addRecommended },
      {
        onSettled: () => removeFromButtonList(option.tag.id),
        onSuccess: () => {
          if (trackItemAdded) {
            itemSelected({
              context: 'booking',
              subContext: 'video type page',
              itemLabel: option?.tag?.title,
              itemPrice: 0,
              itemQuantity: 1,
            });
          }
        },
      }
    );
  } else {
    removeReservationTagHandler(option);
  }
}

function handleOpenTagRequirementDialog(option, requirement) {
  tagRequirement.value = requirement;
  currentOption.value = option;
  isTagRequirementDialogOpen.value = true;
}

function handleCloseTagRequirementDialog() {
  removeFromButtonList(currentOption.value.tag.id);
  tagRequirement.value = {};
  currentOption.value = {};
  isTagRequirementDialogOpen.value = false;
}

function addTagsToReservation(addRecommended = true) {
  addOrRemoveSelection({
    option: currentOption.value,
    addRecommended: addRecommended,
  });
  isTagRequirementDialogOpen.value = false;
  currentOption.value = {};
}

function addSelectedTagToReservation() {
  addTagsToReservation(false);
}

const isProServiceSelected = option => {
  if (!route.query?.pro_service) return;

  const alreadySelectedProService = route.query.pro_service;
  const proServiceRecommendation = option.tag.recommended_tags?.[0]?.title
    .replace(/\s+/g, '_')
    .replace(/&/g, 'and');

  if (
    alreadySelectedProService === 'full_body_model' &&
    proServiceRecommendation === 'hand_model'
  )
    return true;

  return alreadySelectedProService === proServiceRecommendation;
};

function onSelectionChange(option) {
  const tagRequirement = option.tag.tag_requirements.length
    ? option.tag.tag_requirements[0]
    : false;

  const isProServiceAlreadySelected = isProServiceSelected(option);

  if (tagRequirement && !option.selected && !isProServiceAlreadySelected) {
    linkClicked({
      context: 'booking',
      subContext: 'video type page',
      linkLabel: option?.tag?.title,
      linkHref: '#add-to-shoot',
    });
    handleOpenTagRequirementDialog(option, tagRequirement);
  } else {
    addOrRemoveSelection({ option: option, trackItemAdded: true });
  }
}
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="video-type prebooking-transition__content">
        <SoonaLoading
          v-if="!videoTypeOptions"
          :is-loading="!videoTypeOptions"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading>
          start building your video
          <template #subtitle>
            select a video type below. you will build your video by purchasing
            clips after your shoot. final project cost will vary based on the
            number of video clips and editing options you select after your
            shoot.
          </template>
        </SoonaHeading>
        <div v-if="status === 'success'" class="video-type__block">
          <SoonaTagOptionCard
            v-for="option in videoTypeOptions"
            ref="soonaTagOptionCard"
            :key="option.tag.id"
            :description="option.tag.description"
            :has-carousel="false"
            :has-more-details="false"
            :has-quantity-incrementor="false"
            :min-quantity="1"
            :has-shadow="true"
            :primary-image="option.tag.image_url"
            :primary-video="option.tag.video_url"
            :primary-video-alt="option.tag.title"
            :in-bag="option.selected"
            :is-loading="isBusy"
            :tag-id="option.tag.id"
            :title="option.tag.title"
            :button-copy="`build your ${option.tag.sub_title}`"
            title-element="h2"
            @action="onSelectionChange(option)"
            @video-played="trackVideoPlayed"
          />
        </div>
        <SoonaFaq :faq-tab-list="faqData" />
        <TagRequirementDialog
          v-if="isTagRequirementDialogOpen"
          :reservation-id="reservationId"
          :tag-requirement="tagRequirement"
          :video-slug="currentOption.tag.title"
          segment-context="booking"
          segment-sub-context="video type page"
          @confirm-clicked="addTagsToReservation"
          @secondary-confirm-clicked="addSelectedTagToReservation"
          @cancel-clicked="handleCloseTagRequirementDialog"
        ></TagRequirementDialog>
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.video-type {
  &__block {
    display: block;
  }

  &__recommendation {
    align-items: center;
    display: flex;

    svg {
      flex: 0 0 1.75rem;
      margin-right: 0.5rem;
    }
  }
}
</style>

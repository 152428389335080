<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';

defineProps({
  element: {
    default: 'li',
    type: String,
  },
  option: {
    required: true,
    type: Object,
  },
  locationName: {
    default: '',
    type: String,
  },
});

const emit = defineEmits(['handle-choose']);
</script>

<template>
  <component :is="element">
    <SoonaItemCard
      :aria-labelledby="`item-${option.id}`"
      :image-url="option.image_url"
    >
      <template #content>
        <div class="right-column">
          <div class="column-content">
            <p class="u-body--heavy item-title">{{ option.title }}</p>
            <p v-if="option.incompatible" class="u-label--small">
              <em>unavailable at {{ locationName }} studio</em>
            </p>
          </div>
          <div class="btn-wrapper">
            <SoonaButton
              :disabled="option.incompatible"
              size="medium"
              variation="solid-black"
              @on-click="emit('handle-choose', option)"
            >
              choose
            </SoonaButton>
          </div>
        </div>
      </template>
    </SoonaItemCard>
  </component>
</template>

<style lang="scss" scoped>
.right-column {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0 0.75rem;
}

.column-content {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.item-title {
  text-transform: lowercase;
}

.btn-wrapper {
  align-items: center;
  display: flex;
}
</style>

import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { updateAccountOwner } from '@/api/accounts';

/**
 *
 * @param {number | Ref<Object>} accountId
 */
export function useUpdateAccountOwner(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => updateAccountOwner(unref(accountId), body),
    onSuccess: async () =>
      await queryClient.invalidateQueries({
        queryKey: queryKeys.account(accountId),
      }),
  });
}

<script setup>
import CrewNote from './CrewNote.vue';
import NoteInput from './NoteInput.vue';

defineProps({
  error: {
    type: Object,
    default: undefined,
  },
  isPending: {
    type: Boolean,
    default: false,
  },
  notes: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['delete-note', 'submit-note']);

const handleSubmitNote = (reply, signedIds, noteType) => {
  emit('submit-note', reply, signedIds, noteType);
};
</script>
<template>
  <div class="AddNote">
    <NoteInput
      :is-pending="isPending"
      :error="error"
      @submit-note="handleSubmitNote"
    />
    <slot name="paginator" />
    <div>
      <CrewNote
        v-for="note in notes"
        :key="note.id"
        :note="note"
        @delete-note="emit('delete-note', note)"
      />
      <hr v-if="notes.length > 0" class="note-divider" />
    </div>
    <slot name="paginator" />
  </div>
</template>

<style lang="scss">
@use '@/variables';

.AddNote {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .note-divider {
    background-color: variables.$gray-30;
    height: 0.25rem;
    margin: 0 0 1rem;
  }
}
</style>

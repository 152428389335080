import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { updateUser } from '@/api/users';
import { useMe } from '@/composables/user/useMe';

/**
 *
 * @param {number | Ref<Object>} userId
 */
export function useUpdateUser(userId) {
  const queryClient = useQueryClient();
  const { me, invalidateMe } = useMe();

  return useMutation({
    mutationFn: body => updateUser(unref(userId), body),
    onSuccess: async result => {
      let promises = [
        queryClient.invalidateQueries({
          queryKey: queryKeys.user(result.id),
        }),
      ];

      if (result.id === me.value.id) promises.push(invalidateMe());

      await Promise.all(promises);
    },
  });
}

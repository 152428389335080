<script setup>
import { computed } from 'vue';
import PlaceholderImage from 'images/placeholder.png';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  displayImage: {
    type: Boolean,
    default: true,
  },
  id: {
    type: [String, Number],
    required: true,
  },
  hasRoundImage: {
    default: false,
    type: Boolean,
  },
  imageUrl: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  soonaIcon: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'checkbox',
    validator: value => ['radio', 'checkbox'].includes(value),
  },
});

defineOptions({ inheritAttrs: false });

const getImage = computed(() => {
  return props.imageUrl ?? PlaceholderImage;
});

const controlId = computed(() =>
  `${props.name}-${props.id}`.replace(/\s/g, '')
);

const inputPosition = computed(() => (props.displayImage ? 'right' : 'left'));
</script>

<template>
  <div class="item-card-selectable">
    <input
      :id="controlId"
      :name="name"
      :type="type"
      v-bind="$attrs"
      :class="inputPosition"
    />
    <label :for="controlId">
      <span v-if="soonaIcon" class="item-card-selectable__icon">
        <SoonaIcon :name="soonaIcon" />
      </span>
      <span
        v-else-if="displayImage"
        class="item-card-selectable__image"
        :class="{ 'item-card-selectable__image--round': hasRoundImage }"
      >
        <img :src="getImage" alt="" />
      </span>
      <span class="item-card-selectable__content">
        <slot />
      </span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.item-card-selectable {
  position: relative;
  width: 100%;

  input {
    position: absolute;
    /* subtract half of the height */
    top: calc(50% - 0.625rem);
    height: 1.25rem;
    width: 1.25rem;
    cursor: pointer;
    accent-color: variables.$periwink-blue-60;
  }

  input.left {
    left: 1rem;
  }

  input.right {
    right: 1rem;
  }

  label {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    column-gap: 0.25rem;
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    overflow: hidden;
    min-height: 4.5rem;
    cursor: pointer;
    user-select: none;
  }

  input:checked + label {
    background-color: variables.$periwink-blue-20;
    outline-offset: -0.0625rem;
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  input:hover + label {
    background-color: variables.$gray-10;
  }

  input:checked:hover + label {
    background-color: variables.$periwink-blue-30;
  }

  input:disabled + label {
    border-color: transparent;
    background-color: variables.$gray-20;

    &:hover {
      background-color: variables.$gray-20;
      cursor: not-allowed;
    }

    &__content {
      color: variables.$gray-60;
    }
  }

  input:disabled:checked {
    cursor: not-allowed;

    &:hover + label {
      background-color: variables.$gray-20;
    }

    + label {
      outline: 0.125rem solid variables.$gray-40;
    }
  }

  &__image {
    position: relative;
    flex: 0 0 4.5rem;
    background-color: variables.$gray-20;
    min-height: 4.5rem;

    &--round {
      border-radius: 50%;
      flex-basis: auto;
      height: 2.75rem;
      margin: 1rem;
      min-height: auto;
      overflow: hidden;
      width: 2.75rem;
    }

    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__icon {
    position: relative;
    flex: 0 0 4.5rem;
    min-height: 4.5rem;

    svg {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding: 1rem;
    }
  }

  &__content {
    flex: 1 1 12rem;
  }
}
</style>

import { track } from '@/lib/segment/segment';
import { useCurrentReservationAndUser } from '@/composables/segment/useCurrentReservationAndUser';

export function usePromotionEvents() {
  const { reservationTraits, userTraits } = useCurrentReservationAndUser();

  /**
   * Booking Started
   * @param {string} creativeName the name of the promotional creative (title of promotion; e.g. 'blackfriday sale')
   * @param {string} creativeSlot the name of the promotional creative slot associated with the event (where in the app this promo was; e.g. 'booking banner')
   * @param {string} promotionName the name of the promotion associated with the event (in this case we’ll use the discount code if present)
   * @param {string} promotionId NOT IN USE YET the ID of the promotion associated with the event (the unique identifier for the discount code or promotion used)
   */
  const promotionViewed = ({
    creativeName,
    creativeSlot,
    promotionName,
  } = {}) => {
    const payload = {
      creativeName,
      creativeSlot,
      promotionName,
      locationId: reservationTraits.value.locationId,
      products: [],
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };

    track('Promotion Viewed', payload);
  };

  /**
   * Booking Started
   * @param {string} creativeName the name of the promotional creative (title of promotion; e.g. 'blackfriday sale')
   * @param {string} creativeSlot the name of the promotional creative slot associated with the event (where in the app this promo was; e.g. 'booking banner')
   * @param {string} promotionName the name of the promotion associated with the event (in this case we’ll use the discount code if present)
   * @param {string} promotionId NOT IN USE YET the ID of the promotion associated with the event (the unique identifier for the discount code or promotion used)
   */
  const promotionSelected = ({
    creativeName,
    creativeSlot,
    promotionName,
  } = {}) => {
    const payload = {
      creativeName,
      creativeSlot,
      promotionName,
      locationId: reservationTraits.value.locationId,
      ...userTraits.value,
    };

    track('Promotion Selected', payload);
  };

  return {
    promotionViewed,
    promotionSelected,
  };
}

<template>
  <div class="TextMessage">
    <div :class="isOutgoing ? 'outgoing' : 'incoming'">
      <!-- Name and Timestamp -->
      <div class="name_and_timestamp">
        <p class="name">{{ message.sender.name.split(' ', 1)[0] }}</p>
        &middot;
        <p class="timestamp">{{ convertToHHMM12(message.sentAt * 1000) }}</p>
      </div>
      <div class="avatar_and_message">
        <ChatAvatar v-if="!isOutgoing" :message="message" />
        <!-- Message Text -->
        <div class="message_text" v-html="formatLink(message.data.text)" />
        <ChatAvatar v-if="isOutgoing" :message="message" />
      </div>
    </div>
  </div>
</template>

<script>
import Autolinker from 'autolinker';
import ChatAvatar from '../ChatAvatar.vue';
import dateTimeMixin from '@/mixins/dateTimeMixin';

export default {
  components: {
    ChatAvatar,
  },
  mixins: [dateTimeMixin],
  props: {
    message: Object,
    isOutgoing: Boolean,
  },
  methods: {
    formatLink(value) {
      return Autolinker.link(value, {
        mention: 'instagram',
        className: 'auto-link',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';

.TextMessage {
  padding: 0.75rem 0;
  .name_and_timestamp {
    display: flex;
    align-items: center;
    color: variables.$gray-90;
    .name {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.5px;
      padding: 3px;
    }
    .timestamp {
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.5px;
      padding: 3px;
    }
  }
  .message_text {
    padding: 0.75rem;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    word-break: break-word;
    white-space: pre-wrap;
  }
  // incoming message styles
  .incoming {
    display: flex;
    flex-direction: column;

    .name_and_timestamp {
      justify-content: flex-start;
      margin-left: 42px;
    }
    .avatar_and_message {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .message_text {
        background: variables.$gray-20;
        border-radius: 1px 10px 10px 10px;
        margin-left: 5px;
        margin-right: 37px;
      }
    }
  }
  // outgoing message styles
  .outgoing {
    display: flex;
    flex-direction: column;

    .name_and_timestamp {
      justify-content: flex-end;
      margin-right: 42px;
    }
    .avatar_and_message {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .message_text {
        background: variables.$periwink-blue-20;
        border-radius: 10px 1px 10px 10px;
        margin-right: 5px;
        margin-left: 37px;
      }
    }
  }

  :deep(.auto-link) {
    color: variables.$gray-90;
    text-decoration: underline;
    word-break: break-all;
  }
}
</style>

import { computed } from 'vue';

export const useListingRuleResults = listing => {
  const listingImageQualityRuleResults = computed(
    () =>
      listing.value.rule_results.find(rr => rr.rule_group === 'image quality')
        ?.rules
  );
  const listingGalleryCountRuleResult = computed(
    () =>
      listing.value.rule_results.find(rr => rr.rule_group === 'gallery count')
        ?.rules[0]
  );
  const allListingVisualMixRuleResults = computed(
    () =>
      listing.value.rule_results.find(
        rr => rr.rule_group === 'visual content mix'
      )?.rules
  );
  const listingVisualMixRuleResults = computed(() =>
    allListingVisualMixRuleResults.value.filter(
      r => r.rule !== 'main image product on white'
    )
  );

  return {
    listingImageQualityRuleResults,
    listingGalleryCountRuleResult,
    allListingVisualMixRuleResults,
    listingVisualMixRuleResults,
  };
};

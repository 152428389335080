<script setup>
import DigitalAssetCard from '@/components/account_gallery/DigitalAssetCard.vue';
import InfiniteGallery from '@/components/infinite_asset_gallery/InfiniteGallery.vue';
import { computed, ref } from 'vue';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import { useInfiniteFavoritesCollectionDigitalAssets } from '@/queries/favorites-collection-digital-assets/useInfiniteFavoritesCollectionDigitalAssets';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import MultiSelectActionBar from '@/components/account_gallery/MultiSelectActionBar.vue';
import { useElementBounding } from '@vueuse/core';
import EmptySoonaCollection from '@/components/account_gallery/collections/EmptySoonaCollection.vue';
import { useBulkSelection } from '@/composables/digital_assets/useBulkSelection';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  favoritesCollectionId: {
    type: [String, Number],
    required: true,
  },
});

const favoritesCollectionId = computed(() => props.favoritesCollectionId);

const wrapper = ref(null);
const itemsPerPage = ref(100);

const { left, width } = useElementBounding(wrapper);

const { gutter, offsetTop, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 14,
  gapRem: 0.75,
});

const filters = computed(() => ({
  visibility: 'all',
}));

const { assetRows, fetchPage, isLoading, error, pagination, rawData } =
  useInfiniteDigitalAssets(favoritesCollectionId, {
    rowWidth,
    itemsPerPage,
    filters,
    gutter,
    height: rowHeight,
    query: useInfiniteFavoritesCollectionDigitalAssets,
  });

const priorityErrors = usePriorityErrors(error);

const { onSelectionClick, resetSelection, isAssetSelected, selectedAssets } =
  useBulkSelection(rawData, fcda => fcda.digital_asset);
</script>

<template>
  <div class="favorites-collection-digital-assets-gallery">
    <div
      v-if="assetRows.length"
      class="favorites-collection-digital-assets-gallery__sort-wrapper"
    >
      <p class="favorites-collection-digital-assets-gallery__items">
        {{ pagination.totalCount.toLocaleString() }} items
      </p>
    </div>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <EmptySoonaCollection
      v-else-if="!isLoading && !assetRows.length"
      album-name="favorites"
    />
    <div v-else ref="wrapper">
      <InfiniteGallery
        v-slot="{ data }"
        :rows="assetRows"
        :height="rowHeight"
        :gap="gutter"
        :offset-top="offsetTop"
      >
        <DigitalAssetCard
          v-for="asset in data.assets"
          :key="asset.id"
          :asset="asset.digital_asset"
          :paged-asset="asset"
          :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
          :selected="isAssetSelected(asset)"
          :to="{
            name: 'favorites-assets-media-view',
            params: {
              accountId: accountId,
              digitalAssetId: asset.digital_asset?.id,
            },
          }"
          @request-page="pageNumber => fetchPage(pageNumber)"
          @selection-click="onSelectionClick(asset, $event)"
        />
      </InfiniteGallery>
      <MultiSelectActionBar
        :account-id="accountId"
        :page-bounding-rect-left="left"
        :page-bounding-rect-width="width"
        :selected-assets="selectedAssets"
        @close="resetSelection"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.favorites-collection-digital-assets-gallery {
  &__sort-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__items {
    color: variables.$gray-60;
  }
}
</style>

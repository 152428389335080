import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useBulkApplyAssetAddOns(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ addOnData }) => {
      const params = { add_on_data: addOnData };

      return http.post(
        `accounts/${toValue(accountId)}/bag/bulk_apply_asset_add_ons`,
        params
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.bag() });
    },
  });
}

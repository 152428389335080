<script setup>
import { computed } from 'vue';
import ListingOptimizeAccordionSection from '@/components/user/anytime/listing_insights/listing_detail/accordions/ListingOptimizeAccordionSection.vue';
import SoonaMeter from '@/components/ui_library/SoonaMeter.vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import SoonaDonutChart from '@/components/ui_library/SoonaDonutChart.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  score: {
    type: Object,
    required: true,
  },
  product: {
    type: Object,
    default: () => ({}),
  },
});

const score = computed(() => props.score);

const productTitle = computed(() => {
  return props.product?.title ?? 'your product';
});

const formattedMeterData = computed(() => {
  return {
    value: score.value.visual_average.score.score / 100,
    label: score.value.visual_average.score.label,
    minValue: 0,
    maxValue: 1,
    low: 0.33,
    high: 0.66,
  };
});

const scoreBreakdown = computed(() => {
  return score.value?.score_breakdown?.reduce((acc, score) => {
    return [
      ...acc,
      {
        rule: score.rule,
        score: {
          label: score.score.label,
          value: score.score.score / 100,
        },
        display_value: score.display_value,
      },
    ];
  }, []);
});

const images = computed(() => {
  return score.value?.assets.map(asset => asset.image_url);
});

// score averages
const iconLookup = {
  'gallery count': 'album',
  'image quality': 'aperture',
  'visual mix': 'stars',
};
</script>

<template>
  <div class="listing-details-summary">
    <header class="listing-details-summary__header">
      <div class="listing-details-summary__description">
        <img :src="images[0] ?? PlaceholderImage" alt="listing main image" />
      </div>
      <h1 class="u-title--heavy">{{ productTitle }}</h1>
      <p>
        {{ images.length }} photo{{
          images.length > 1 || images.length === 0 ? 's' : ''
        }}
      </p>
    </header>
    <div class="listing-details-summary__visual-performance">
      <div class="listing-details-summary__visual-performance--overall-score">
        {{ score.visual_average.display_value }}
        <span
          class="listing-details-summary__visual-performance--denominator u-display--regular"
          >/ 100</span
        >
      </div>
    </div>
    <SoonaMeter
      v-if="score"
      :data="formattedMeterData"
      accessible-title="overall score"
    />
    <div class="listing-details-summary__score-averages">
      <div
        v-for="category in scoreBreakdown"
        :key="category.rule"
        class="listing-details-summary__score-average"
      >
        <SoonaDonutChart
          :accessible-title="category.rule"
          :data="[category.score]"
          :display-value="category.display_value"
          label-position="right"
          size="medium"
        >
          <template #label>
            <SoonaIcon
              class="soona-donut-chart__description--icon"
              :name="iconLookup[category.rule]"
              size="medium"
            />
            <p class="u-body--regular">{{ category.rule }}</p>
          </template>
        </SoonaDonutChart>
      </div>
    </div>
    <ListingOptimizeAccordionSection
      v-if="score"
      platform="Shopify"
      :listing-data="score"
      :display-video-accordion="false"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.listing-details-summary {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    margin-bottom: 1rem;
  }

  &__description {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;

    img {
      border-radius: 0.3125rem;
      object-fit: cover;
      width: 9.75rem;
      height: 9.75rem;
    }
  }

  &__visual-performance {
    align-items: flex-end;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &--overall-score {
      font-size: 4.5rem;
      font-weight: 800;
      line-height: 5rem;
      letter-spacing: 0.015625rem;
    }

    &--denominator {
      color: variables.$gray-60;
      margin-left: -0.5rem;
    }
  }

  &__score-averages {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__score-average {
    color: variables.$black-default;
    flex: 1 0 0;
    background-color: variables.$white-default;
    border-radius: 0.625rem;
    box-shadow: variables.$elevation-0;
    min-width: 15rem;
    padding: 1rem 0;
  }
}

@media (min-width: variables.$screen-sm-min) {
  .listing-details-summary {
    padding: 1.5rem 2rem;

    &__description {
      flex-flow: row nowrap;
    }
  }
}
</style>

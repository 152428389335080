<script setup>
import { useMe } from '@/composables/user/useMe';
import { useSiteNavEvents } from '@/components/platform-layout/useSiteNavEvents';
import { useCapability } from '@/composables/useCapability';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import TermsListItemLink from '@/components/platform-layout/header/user-menu/TermsListItemLink.vue';
import AppListItemLink from '@/components/platform-layout/header/user-menu/AppListItemLink.vue';
import AccountPickerList from '@/components/platform-layout/header/user-menu/AccountPickerList.vue';

const emit = defineEmits(['create-account']);
const { trackLinkClickedUserMenu } = useSiteNavEvents();

// heads up! these need to be called in capture phase so they fire before the menu closes automatically when clicking a link
// make sure to use `@click.capture` in the template
function genericLinkClicked(e) {
  trackLinkClickedUserMenu(
    e.currentTarget.textContent.trim(),
    e.currentTarget.href
  );
}

const { currentAccountId, me } = useMe();
const { hasCapability: manage_collaborators } = useCapability({
  capability: 'manage_collaborators',
  subjectType: 'account',
  subjectId: currentAccountId,
});

function createAccount() {
  trackLinkClickedUserMenu('add new account', null);
  emit('create-account');
}
</script>

<template>
  <div role="group" aria-label="user" class="user-menu-content__user">
    <SoonaAvatar
      class="user-menu-content__user__avatar"
      :name="me?.name"
      :src="me?.avatar_url"
      size="4.5rem"
      type="user"
    />
    <div class="user-menu-content__user__info">
      <h3 class="u-subheader--heavy user-menu-content__user__info__name">
        {{ me?.name }}
      </h3>
      <SoonaButton
        element="router-link"
        variation="secondary-gray"
        size="small"
        to="/user-settings"
        @click.capture="genericLinkClicked"
      >
        <SoonaIcon name="user" />
        user settings
      </SoonaButton>
      <form
        action="/users/sign_out"
        method="post"
        style="display: inline-flex"
        aria-label="sign out"
      >
        <SoonaButton
          type="submit"
          variation="secondary-gray"
          size="small"
          @click="trackLinkClickedUserMenu('sign out', `/users/sign_out`)"
        >
          <SoonaIcon name="arrow-right-from-bracket" />
          sign out
        </SoonaButton>
      </form>
    </div>
  </div>
  <div role="separator" class="user-menu-content__separator" />
  <div
    role="group"
    aria-label="account picker"
    class="user-menu-content__account-picker"
  >
    <div class="user-menu-content__account-picker__header">
      <h3 class="u-label--heavy">select account</h3>
      <SoonaButton
        variation="tertiary"
        size="small"
        data-cypress="add-new-account"
        @click="createAccount"
      >
        add new
        <SoonaIcon name="plus" />
      </SoonaButton>
    </div>
    <AccountPickerList />
  </div>
  <div role="separator" class="user-menu-content__separator" />
  <div role="group" aria-label="links" class="user-menu-content__links">
    <ul class="user-menu-content__links__app" aria-label="account links">
      <AppListItemLink
        icon-name="briefcase"
        :to="`/account/${currentAccountId}/profile`"
        @click.capture="genericLinkClicked"
      >
        account settings
      </AppListItemLink>
      <AppListItemLink
        v-if="manage_collaborators"
        :to="`/account/${currentAccountId}/collaborators`"
        icon-name="user-plus-alt-1"
        @click.capture="genericLinkClicked"
      >
        team members
      </AppListItemLink>
      <AppListItemLink
        v-if="manage_collaborators"
        :to="`/account/${currentAccountId}/subscriptions`"
        icon-name="crown"
        @click.capture="genericLinkClicked"
      >
        manage plan
      </AppListItemLink>
      <AppListItemLink
        v-if="manage_collaborators"
        :to="`/account/${currentAccountId}/orders`"
        icon-name="dollar-sign"
        @click.capture="genericLinkClicked"
      >
        billing history
      </AppListItemLink>
    </ul>
    <ul class="user-menu-content__links__terms" aria-label="terms links">
      <TermsListItemLink href="https://support.soona.co">
        help center
      </TermsListItemLink>
      <TermsListItemLink href="https://soona.co/terms">
        terms of use
      </TermsListItemLink>
      <TermsListItemLink href="https://soona.co/privacy-policy">
        privacy policy
      </TermsListItemLink>
      <TermsListItemLink href="https://soona.co">soona.co</TermsListItemLink>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.user-menu-content {
  &__user {
    padding: 1rem;
    border-radius: 0.625rem 0.625rem 0 0;
    display: flex;
    flex-flow: row wrap;
    gap: 0.625rem;
    align-items: center;

    &__avatar {
      flex: 0 0 4.5rem;
    }

    &__info {
      flex: 1 1 0;
      display: flex;
      flex-flow: row wrap;
      gap: 0.625rem;

      &__name {
        flex: 1 1 100%;
      }
    }
  }

  &__separator {
    border-bottom: 0.0625rem solid variables.$periwink-blue-20;
  }

  &__account-picker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 0.625rem;
    padding: 0.625rem 1rem;
    background: variables.$periwink-blue-10;

    &__header {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      gap: 0.5rem;
    }
  }

  &__links {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__app {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(min(8rem, 100%), 1fr));
      gap: 0.875rem 0.625rem;
    }

    &__terms {
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
      justify-content: space-between;
    }
  }
}
</style>

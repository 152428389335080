<template>
  <CompleteProfileCard
    class="brand-font"
    :disabled="disabled"
    @next="saveBrandFont"
    @skip="skipCard"
  >
    <template #heading>
      let’s talk typeface. what are your brand fonts?
    </template>
    <fieldset class="field-wrapper">
      <legend class="u-visually-hidden">brand fonts</legend>
      <SoonaTextfield
        v-model="primary"
        class="brand-input"
        label="primary"
        name="primary"
        placeholder="Lato Heavy"
      />
      <SoonaTextfield
        v-model="secondary"
        class="brand-input"
        label="secondary"
        name="secondary"
        placeholder="Comic Sans"
      />
      <SoonaTextfield
        v-model="alternative"
        class="brand-input"
        label="alternative"
        name="alternative"
        placeholder="Papyrus"
      />
    </fieldset>
  </CompleteProfileCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'BrandFont',
  components: { CompleteProfileCard, SoonaTextfield },
  data() {
    return {
      primary: '',
      secondary: '',
      alternative: '',
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    disabled() {
      return !this.primary && !this.secondary && !this.alternative;
    },
  },
  watch: {
    account: function () {
      this.prefillExistingData();
    },
  },
  mounted() {
    this.prefillExistingData();
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    prefillExistingData() {
      this.primary = this.account.profile_data.brand_fonts?.data?.primary;
      this.secondary = this.account.profile_data.brand_fonts?.data?.secondary;
      this.alternative =
        this.account.profile_data.brand_fonts?.data?.alternative;
    },
    async saveBrandFont() {
      let info = {
        brand_fonts: {
          data: {
            primary: this.primary,
            secondary: this.secondary,
            alternative: this.alternative,
          },
          complete: true,
        },
      };
      await this.updateAccount({ accountParams: info });
    },
    async skipCard() {
      let info = {
        brand_fonts: {
          skip: true,
        },
      };
      try {
        await this.updateAccount({ accountParams: info });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(
            new Error('Updating account brand fonts failed'),
            {
              extra: { error },
            }
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-font {
  .field-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }

  .brand-input {
    padding-bottom: 0.5rem;
  }
}
</style>

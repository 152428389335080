<script setup>
import { computed } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import CompleteCustomer from './complete/CompleteCustomer.vue';
import CompleteInternal from './complete/CompleteInternal.vue';

const props = defineProps({
  reservationId: {
    type: String,
    required: true,
  },
});

const reservationId = computed(() => props.reservationId);

const { data: reservation } = useReservation(reservationId);

const isInternal = computed(
  () => reservation.value?.reservation_type === 'internal'
);
</script>

<template>
  <div class="confirm-booking">
    <CompleteInternal v-if="isInternal" :reservation-id="reservationId" />
    <CompleteCustomer v-else :reservation-id="reservationId" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

@media (max-width: variables.$mobile-max) {
  .confirm-booking {
    margin-bottom: 3rem;
  }
}
</style>

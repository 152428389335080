<script setup>
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { onMounted, computed } from 'vue';
import BigHeroButton from '@/components/user-generated-content/BigHeroButton.vue';
import { useCreateABrief } from '@/composables/trend/useCreateABrief';
import { useRoute } from 'vue-router';

const emit = defineEmits(['close']);

const { modalDisplayed } = useBaseEvents();
const route = useRoute();

const utmContent = 'click_here_to_create_a_brief_modal';
const context = computed(() => route.meta?.context);
const subContext = 'create a brief modal';
const buttonCopy = 'click here to create a brief';

onMounted(() => {
  modalDisplayed({
    context: context.value,
    subContext,
    modalName: 'create an account on trend',
  });
});

const { handleCreateABrief, handleLogin } = useCreateABrief({
  utmContent,
  subContext,
  buttonCopy,
});

const onCreateABriefClick = async () => {
  handleCreateABrief();
  emit('close');
};

const onLoginClick = async () => {
  handleLogin();
  emit('close');
};
</script>

<template>
  <SoonaDialog max-width="45rem" @close="emit('close')">
    <template #heading>create an account on Trend</template>

    <div class="trend-create-account-dialog__logos">
      <img
        src="@images/soona-logo-black.svg"
        alt="soona logo"
        width="325"
        height="58"
        class="trend-create-account-dialog__logos__logo"
      />
      <img
        src="@images/down-right-arrow.svg"
        alt="arrow pointing right"
        width="61"
        height="47"
        class="trend-create-account-dialog__logos__arrow"
      />
      <img
        src="@images/trend-by-soona.svg"
        alt="Trend by soona logo"
        width="131"
        height="37"
        class="trend-create-account-dialog__logos__logo"
      />
    </div>

    <div class="trend-create-account-dialog__content">
      <p class="u-headline--heavy">
        you’ll be redirected to Trend’s website to create an account and get set
        up.
      </p>
      <BigHeroButton
        is="button"
        class="trend-create-account-dialog__create-brief-button"
        @click="onCreateABriefClick"
      >
        click here to<wbr /> create a brief
      </BigHeroButton>
      <p class="u-body--heavy">
        10% off your first order with code:
        <span style="user-select: all">firstcontent10</span>
      </p>
      <p>
        already have an account?
        <a
          class="trend-create-account-dialog__log-in-link u-body--heavy"
          @click="onLoginClick"
        >
          log&nbsp;in
          <span class="u-visually-hidden">to Trend</span>
        </a>
      </p>
    </div>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.trend-create-account-dialog {
  &__logos {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    img {
      display: block;
    }

    &__logo {
      width: min(8rem, 100%);
    }

    &__arrow {
      transform: rotate(-153.884deg) scaleX(-1);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    align-items: center;
    text-align: center;
  }

  &__create-brief-button {
    width: min(16rem, 100%);
    aspect-ratio: 1 / 1;
  }

  &__log-in-link {
    text-decoration: underline;
  }
}
</style>

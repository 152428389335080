import { computed } from 'vue';
import { useTiers as useTiersQuery } from '@/queries/tiers/useTiers';

export function useTiers() {
  const { data: tiers, isLoading } = useTiersQuery();

  const basicTier = computed(() => {
    return tiers.value?.find(t => t.slug === 'tier-one') ?? {};
  });

  const proTier = computed(() => {
    return tiers.value?.find(t => t.slug === 'tier-two') ?? {};
  });

  const businessTier = computed(() => {
    return tiers.value?.find(t => t.slug === 'tier-three') ?? {};
  });

  return {
    basicTier,
    proTier,
    businessTier,
    isLoading,
  };
}

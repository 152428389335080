import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getExternalIdentifierTypes } from '../api/externalIdentifierTypes';

export function useGetExternalIdentifierTypes(accountId) {
  return useQuery({
    queryKey: queryKeys.accountExternalIdentifierTypes(accountId),
    queryFn: ({ signal }) =>
      getExternalIdentifierTypes(unref(accountId), signal),
  });
}

<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useCapability } from '@/composables/useCapability';

defineProps({
  isEditView: Boolean,
});

const advertise = ref({
  instagram: false,
  facebook: false,
  linkedin: false,
  audio: false,
  sem: false,
  display: false,
  print: false,
  television: false,
  tiktok: false,
  youtube: false,
  other: false,
  none: false,
});

const columnOne = [
  { label: 'Instagram', value: 'instagram' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'LinkedIn', value: 'linkedin' },
  { label: 'audio', value: 'audio' },
  { label: 'SEM', value: 'sem' },
  { label: 'display', value: 'display' },
];

const columnTwo = [
  { label: 'print', value: 'print' },
  { label: 'television', value: 'television' },
  { label: 'TikTok', value: 'tiktok' },
  { label: 'Youtube', value: 'youtube' },
  { label: 'other', value: 'other' },
  { label: 'none', value: 'none' },
];

const store = useStore();

const account = computed(() => store.state.account);
const accountId = computed(() => store.state.account.id);

const { hasCapability: canManageAccount } = useCapability({
  capability: 'manage_account',
  subjectType: 'account',
  subjectId: accountId,
});

const disabled = computed(() => {
  return (
    !advertise.value.instagram &&
    !advertise.value.facebook &&
    !advertise.value.linkedin &&
    !advertise.value.audio &&
    !advertise.value.sem &&
    !advertise.value.display &&
    !advertise.value.print &&
    !advertise.value.television &&
    !advertise.value.tiktok &&
    !advertise.value.youtube &&
    !advertise.value.other &&
    !advertise.value.none
  );
});

const isEditing = ref(false);

const toggleEdit = () => {
  isEditing.value = !isEditing.value;
};

const prefillExistingData = () => {
  advertise.value.instagram =
    account.value.profile_data.where_do_you_advertise?.data?.instagram;
  advertise.value.facebook =
    account.value.profile_data.where_do_you_advertise?.data?.facebook;
  advertise.value.linkedin =
    account.value.profile_data.where_do_you_advertise?.data?.linkedin;
  advertise.value.audio =
    account.value.profile_data.where_do_you_advertise?.data?.audio;
  advertise.value.sem =
    account.value.profile_data.where_do_you_advertise?.data?.sem;
  advertise.value.display =
    account.value.profile_data.where_do_you_advertise?.data?.display;
  advertise.value.print =
    account.value.profile_data.where_do_you_advertise?.data?.print;
  advertise.value.television =
    account.value.profile_data.where_do_you_advertise?.data?.television;
  advertise.value.tiktok =
    account.value.profile_data.where_do_you_advertise?.data?.tiktok;
  advertise.value.youtube =
    account.value.profile_data.where_do_you_advertise?.data?.youtube;
  advertise.value.other =
    account.value.profile_data.where_do_you_advertise?.data?.other;
  advertise.value.none =
    account.value.profile_data.where_do_you_advertise?.data?.none;
};

const saveAdvertiseContent = async () => {
  let info = {
    where_do_you_advertise: {
      data: {
        instagram: advertise.value.instagram,
        facebook: advertise.value.facebook,
        linkedin: advertise.value.linkedin,
        audio: advertise.value.audio,
        sem: advertise.value.sem,
        display: advertise.value.display,
        print: advertise.value.print,
        television: advertise.value.television,
        tiktok: advertise.value.tiktok,
        youtube: advertise.value.youtube,
        other: advertise.value.other,
        none: advertise.value.none,
      },
      complete: true,
    },
  };
  try {
    await store.dispatch('account/updateAccount', { accountParams: info });
    toggleEdit();
  } catch (error) {
    if (error.response.status !== 422) {
      Sentry.captureException(
        new Error('Updating account advertising info failed'),
        {
          extra: { error },
        }
      );
    }
  }
};

const skipCard = async () => {
  let info = {
    where_do_you_advertise: {
      skip: true,
    },
  };
  await store.dispatch('account/updateAccount', { accountParams: info });
};

watchEffect(() => {
  if (account.value) {
    prefillExistingData();
  }
});
</script>
<template>
  <CompleteProfileCard
    v-if="canManageAccount"
    class="where-do-you-advertise"
    :class="{ 'where-do-you-advertise--edit-view': isEditView }"
    :disabled="disabled"
    :show-next="!isEditView"
    :show-skip="!isEditView"
    @next="saveAdvertiseContent"
    @skip="skipCard"
  >
    <template #heading>where do you advertise?</template>
    <template #subheading>select all that apply</template>
    <fieldset>
      <div class="advertise-column">
        <label v-for="item in columnOne" :key="item.value">
          <input
            v-model="advertise[item.value]"
            type="checkbox"
            name="advertise"
            :value="item.value"
            :disabled="isEditView && !isEditing"
          />
          {{ item.label }}
        </label>
      </div>
      <div class="advertise-column">
        <label v-for="item in columnTwo" :key="item.value">
          <input
            v-model="advertise[item.value]"
            type="checkbox"
            name="advertise"
            :value="item.value"
            :disabled="isEditView && !isEditing"
          />
          {{ item.label }}
        </label>
      </div>
    </fieldset>
    <template v-if="isEditView" #action-btns>
      <SoonaButton v-if="!isEditing" size="medium" @on-click="toggleEdit">
        edit
      </SoonaButton>
      <SoonaButton
        v-else
        class="save-btn"
        size="medium"
        @on-click="saveAdvertiseContent"
      >
        save
      </SoonaButton>
    </template>
  </CompleteProfileCard>
</template>

<style lang="scss" scoped>
@use '@/variables';

.where-do-you-advertise {
  &--edit-view {
    flex-grow: 1;
    max-width: none;
  }

  fieldset {
    display: flex;
    gap: 0.5rem;
    justify-content: space-around;
    padding: 1rem 0;

    input:not(:disabled) {
      cursor: pointer;
    }
  }

  .advertise-column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .save-btn {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }
}
</style>

export function isActiveAmazonIntegration(integration) {
  return (
    integration.type === 'AmazonIntegration' && integration.status === 'active'
  );
}

export function isShopifyIntegration(integration) {
  return integration.type === 'ShopifyIntegration';
}

export function isAmazonIntegration(integration) {
  return integration.type === 'AmazonIntegration';
}

export function isActiveShopifyIntegration(integration) {
  return isShopifyIntegration(integration) && integration.status === 'active';
}

export function isAmazonIntegrationLoading(integration) {
  return isActiveAmazonIntegration(integration) && integration.is_importing;
}

export function isShopifyIntegrationLoading(integration) {
  return isActiveShopifyIntegration(integration) && integration.is_importing;
}

const errorImportTaskStatuses = ['errored'];
export function isAmazonIntegrationErrored(integration) {
  return (
    isActiveAmazonIntegration(integration) &&
    errorImportTaskStatuses.includes(integration.most_recent_import?.status)
  );
}

export function isActiveTrendIntegration(integration) {
  return (
    integration.type === 'TrendIntegration' && integration.status === 'active'
  );
}

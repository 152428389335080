import { useMutation, useQueryClient } from '@tanstack/vue-query';
import {
  createShotTemplate,
  deleteShotTemplate,
  updateShotTemplate,
} from '../api/shot_templates';
import { queryKeys } from './query-keys';

export function useCreateShotTemplate(packId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => createShotTemplate(body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.packConfiguration(packId),
      });
    },
  });
}

export function useDeleteShotTemplate(packId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: shotTemplateId => {
      return deleteShotTemplate(shotTemplateId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.packConfiguration(packId),
      });
    },
  });
}

export function useUpdateShotTemplate(packId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => {
      return updateShotTemplate(body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.packConfiguration(packId),
      });
    },
  });
}

<template>
  <div
    v-if="isSoonaStaff || isAssignedReservation"
    class="mt-l add-crew-assignment-container"
  >
    <DisplayCrewAssignment
      v-for="crewAssignment in displayedCrewAssignments"
      :key="crewAssignment.id"
      :crew-assignment="crewAssignment"
      :reservation-id="reservationId"
      :remove-crew="removeCrew"
    ></DisplayCrewAssignment>
    <CrewAssignmentModal
      v-if="crewAssignmentModalVisible"
      :modal-visible="crewAssignmentModalVisible"
      :close-modal="hideCrewAssignmentModal"
      :assign-crew-directly="true"
      :reservation-start="reservationStart"
      :reservation-end="reservationEnd"
      :reservation-id="reservationId"
    />
    <SoonaButton
      v-if="isFtSoonaStaff"
      :disabled="disabled"
      size="medium"
      @click="showCrewAssignmentModal"
    >
      add crew
    </SoonaButton>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import DisplayCrewAssignment from './DisplayCrewAssignment.vue';
import CrewAssignmentModal from './CrewAssignmentModal.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import dateTimeMixin from 'src/mixins/dateTimeMixin';

export default {
  name: 'CreateCrewAssignment',
  components: {
    DisplayCrewAssignment,
    CrewAssignmentModal,
    SoonaButton,
  },
  mixins: [dateTimeMixin],
  props: {
    disabled: Boolean,
    reservationId: Number,
    reservationStart: String,
    reservationEnd: String,
  },
  setup() {
    const { hasCapability: isFtSoonaStaff } = useCapability({
      capability: 'ft_soona_staff',
    });

    const { hasCapability: isSoonaStaff } = useCapability({
      capability: 'soona_staff',
    });

    const { hasCapability: isAssignedReservation } = useCapability({
      capability: 'assigned_reservation',
    });

    return { isAssignedReservation, isFtSoonaStaff, isSoonaStaff };
  },
  data() {
    return {
      crewAssignmentModalVisible: false,
    };
  },
  computed: {
    ...mapState({
      persistedCrewAssignments: state => state.crewAssignment.crewAssignments,
    }),
    displayedCrewAssignments() {
      return this.persistedCrewAssignments;
    },
  },
  watch: {
    reservationId: function (val) {
      if (val) {
        this.loadCrewAssignments(val);
      }
    },
  },
  mounted() {
    if (this.reservationId) {
      this.loadCrewAssignments(this.reservationId);
    }
  },
  methods: {
    ...mapActions('crewAssignment', [
      'loadCrewAssignments',
      'removeCrewAssignment',
    ]),
    showCrewAssignmentModal() {
      this.crewAssignmentModalVisible = true;
    },
    hideCrewAssignmentModal() {
      this.crewAssignmentModalVisible = false;
    },
    removeCrew(crew) {
      //submits a delete crew assignment request to the db
      this.removeCrewAssignment({
        crewAssignmentId: crew.id,
        reservationId: this.reservationId,
      });
    },
  },
};
</script>

import { error, debug } from './logger';

export default class Listener {
  static staticEvents = [
    'connect',
    'error',
    'disconnect',
    'reconnect',
    'reconnect_attempt',
    'reconnecting',
    'reconnect_error',
    'reconnect_failed',
    'connect_error',
    'connect_timeout',
    'connecting',
    'ping',
    'pong',
  ];

  constructor(io, emitters) {
    this.io = io;
    this.register();
    this.emitters = emitters;
  }

  register() {
    this.io.onevent = packet => {
      let [eventType, body] = packet.data;
      this.onEvent(eventType, body);
    };

    Listener.staticEvents.forEach(event =>
      this.io.on(event, args => {
        const logger = event === 'error' ? error : debug;
        logger('socket.io - ' + event, args);
      })
    );
  }

  onEvent(eventType, body) {
    this.emitters.forEach(emitter => emitter.emit(eventType, body));
  }
}

<script setup>
import { computed } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import PromoCode from '@/components/PromoCode.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});

const reservationId = computed(() => props.reservationId);
const { data: reservation, isSuccess, error } = useReservation(reservationId);

const priorityError = usePriorityError(error);
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <PromoCode
    v-if="isSuccess"
    :reservation-id="reservationId"
    :order-id="reservation?.down_payment_order?.id"
  />
</template>

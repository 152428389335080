import { http } from '@/config/vue-axios';

export const meQueryConfig = {
  /* The queryKey is hardcoded in this file so that it's extra clear this is a
   * bit of a different query, and all data should be exclusively determined
   * from the Rails session, with no additional params.
   *
   * If for some reason this _is_ updated to use params or any dynamic data
   * with a Vue Ref object in the queryKey, the `resetMe` function in useMe.js
   * _must_ be updated to support unref-ing the queryKey using something like
   * https://github.com/TanStack/query/blob/fe7dca69555f9ecba3802bfc1eb67d6dc0142b69/packages/vue-query/src/utils.ts#L50
   * or manually crafted for the specific queryKey structure.
   */
  queryKey: ['me'],
  queryFn: async ({ signal }) => {
    const response = await http.get('/users/me', {
      signal,
    });
    return response.data;
  },
  staleTime: 60 * 60 * 1000,
  gcTime: Infinity,
};

<script setup>
import { ref, watchEffect } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import ListingPicker from './ListingPicker.vue';

defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  integrationId: {
    type: [Number, String],
    default: null,
  },
  orderBy: {
    type: String,
    default: 'name',
  },
});

const emit = defineEmits(['cancel']);

function cancel() {
  emit('cancel');
}

const searchRef = ref(null);

watchEffect(() => {
  if (searchRef.value) {
    searchRef.value.focus();
  }
});

const search = ref('');
</script>

<template>
  <SoonaDialog max-width="48rem" top-affixed @close="cancel">
    <template #heading>
      <slot name="heading" />
    </template>
    <template #header>
      <div v-if="$slots.header" class="listing-picker-dialog__header-slot">
        <slot name="header" />
      </div>
      <SoonaTextfield
        v-if="integrationId"
        ref="searchRef"
        v-model="search"
        type="search"
        label="search listings"
        name="listings-search"
        placeholder="listing name"
        hide-label
        class="listing-picker-dialog__search"
      >
        <template #icon-left>
          <SoonaIcon name="search" size="small" />
        </template>
      </SoonaTextfield>
    </template>
    <ListingPicker
      :account-id="accountId"
      :integration-id="integrationId"
      :search="search"
      :order-by="orderBy"
    >
      <template #listing-options="{ listings }">
        <slot name="listing-options" :listings="listings" />
      </template>
    </ListingPicker>
    <template v-if="$slots.footer" #footer="{ close }">
      <slot name="footer" :close="close" />
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.listing-picker-dialog {
  &__header-slot {
    margin-bottom: 1.5rem;
  }
  &__search {
    margin-top: 1rem;
    padding-bottom: 0;
  }
  &__variant-toggle {
    margin-top: 1rem;
  }
}
</style>

<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { usePriorityError } from '@/composables/usePriorityError';
import { useFlag } from '@/composables/useFlag';
import { useSchedulingPreferences } from '@/composables/useSchedulingPreferences';
import { useCreateSchedulingPreference } from '@/queries/scheduling/useCreateSchedulingPreference';
import { useDeleteSchedulingPreference } from '@/queries/scheduling/useDeleteSchedulingPreference';
import { useUpdateSchedulingPreference } from '@/queries/scheduling/useUpdateSchedulingPreference';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SchedulingPreferencesForm from './SchedulingPreferencesForm.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  editMode: {
    default: false,
    type: Boolean,
  },
  formId: {
    type: String,
    required: true,
  },
  cancelEdits: {
    default: false,
    type: Boolean,
  },
  accountId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  setAccountPreference: {
    type: Boolean,
    default: null,
  },
  isDialog: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(['close', 'reset-cancel-edits']);

const accountSchedulingPreferencesFlag = useFlag(
  'toaster_account_scheduling_preferences'
);

const queryClient = useQueryClient();

const reservationId = computed(() => props.reservationId);
const accountId = computed(() => props.accountId);

const {
  schedulingPreference,
  schedulingPreferencesLoading,
  schedulingPreferencesError,
  subjectType: existingSubjectType,
  hasAccountPreference,
} = useSchedulingPreferences(accountId, reservationId);

// mutate
const subjectType = ref(null);
const subjectId = ref(null);

watchEffect(() => {
  subjectType.value = props.setAccountPreference
    ? 'Account '
    : schedulingPreference.value?.subject_type;

  subjectId.value = props.setAccountPreference
    ? accountId.value
    : schedulingPreference.value?.subject_id;
});

const handleUpdateSubject = value => {
  if (value === 'Account') {
    subjectType.value = 'Account';
    subjectId.value = accountId.value;
  } else {
    subjectType.value = 'Reservation';
    subjectId.value = reservationId.value;
  }
};

const {
  mutate: updateSchedulingPreference,
  isPending: isUpdatigSchedulingPreference,
  error: updateSchedulingPreferenceError,
} = useUpdateSchedulingPreference(
  computed(() => schedulingPreference.value?.id),
  reservationId,
  accountId
);

const {
  mutate: createSchedulingPreference,
  isPending: isCreatingSchedulingPreference,
  error: createSchedulingPreferenceError,
} = useCreateSchedulingPreference(reservationId, accountId);

const {
  mutate: deleteSchedulingPreference,
  isPending: isDeletingSchedulingPreferences,
  error: deleteSchedulingPreferencesError,
} = useDeleteSchedulingPreference(reservationId, accountId);

const modifySchedulingPreference = preferences => {
  if (accountSchedulingPreferencesFlag.value) {
    if (
      subjectType.value === 'Reservation' &&
      existingSubjectType.value === 'Account'
    ) {
      // has account preference; creates new custom reservation preference
      createSchedulingPreference(
        {
          note: preferences.notes,
          scheduling_preferences_slots_attributes: [...preferences.availablity],
          preferred_time_zone: preferences.selectedTimeZone,
          subject_type: subjectType.value,
          subject_id: subjectId.value,
        },
        {
          onSuccess: () => {
            emit('close', false);
            subjectType.value = 'Reservation';

            queryClient.invalidateQueries({
              queryKey: queryKeys.reservationReadinessSteps(reservationId),
            });
          },
        }
      );
    } else if (
      subjectType.value === 'Account' &&
      existingSubjectType.value === 'Reservation' &&
      hasAccountPreference.value
    ) {
      // has reservation preference and account preference;
      // remove reservation preference and default to account preference
      deleteSchedulingPreference(
        {
          schedulingPreferenceId: schedulingPreference.value?.id,
        },
        {
          onSuccess: () => {
            emit('close', false);
            subjectType.value = 'Reservation';

            queryClient.invalidateQueries({
              queryKey: queryKeys.reservationReadinessSteps(reservationId),
            });
          },
        }
      );
    } else {
      // updates either existing reservation or account preference
      updateSchedulingPreference(
        {
          note: preferences.notes,
          scheduling_preferences_slots_attributes: [...preferences.availablity],
          preferred_time_zone: preferences.selectedTimeZone,
          subject_type: subjectType.value,
          subject_id: subjectId.value,
        },
        {
          onSuccess: () => {
            emit('close', false);
            subjectType.value = 'Reservation';

            queryClient.invalidateQueries({
              queryKey: queryKeys.reservationReadinessSteps(reservationId),
            });
          },
        }
      );
    }
  } else {
    updateSchedulingPreference(
      {
        note: preferences.notes,
        scheduling_preferences_slots_attributes: [...preferences.availablity],
        preferred_time_zone: preferences.selectedTimeZone,
        subject_type: subjectType.value,
        subject_id: subjectId.value,
      },
      {
        onSuccess: () => {
          emit('close', false);
          subjectType.value = 'Reservation';

          queryClient.invalidateQueries({
            queryKey: queryKeys.reservationReadinessSteps(reservationId),
          });
        },
      }
    );
  }
};

const priorityError = usePriorityError(
  schedulingPreferencesError,
  updateSchedulingPreferenceError,
  createSchedulingPreferenceError,
  deleteSchedulingPreferencesError
);
</script>
<template>
  <div class="availability">
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <div>
      <div v-if="schedulingPreferencesLoading" class="loading-state">
        <SoonaSkeleton v-for="i in 6" :key="i" class="loading-state__card" />
      </div>
      <SchedulingPreferencesForm
        v-else
        class="availability__preferences--form"
        :form-id="formId"
        :account-id="accountId"
        :reservation-id="reservationId"
        :is-dialog="isDialog"
        :edit-mode="editMode"
        :cancel-edits="cancelEdits"
        @submit="modifySchedulingPreference"
        @reset-cancel-edits="() => emit('reset-cancel-edits')"
        @update:subject-type="handleUpdateSubject"
      />
      <SoonaLoading
        v-if="
          isUpdatigSchedulingPreference ||
          isCreatingSchedulingPreference ||
          isDeletingSchedulingPreferences
        "
        is-loading
        loading-text="loading"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.loading-state {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__card {
    height: 2.5rem;
    width: 100%;
    border-radius: variables.$radius-small;
  }
}
</style>

import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { deleteReservationLineItem } from '@/api/reservation_line_items';

/**
 * @param {number | Ref<number>} reservationId
 * @param {number | Ref<number>} reservationLineItemId
 */
export function useDeleteReservationLineItem(
  reservationId,
  reservationLineItemId
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return deleteReservationLineItem(
        unref(reservationId),
        unref(reservationLineItemId)
      );
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKeys.bag() }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationDigitalAssetMediaAddOns(
            unref(reservationId)
          ),
        }),
      ]);
    },
  });
}

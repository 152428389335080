<script setup>
import { computed } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { useConfirmComped } from 'src/queries/reservations/useConfirmComped';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { useRouter } from 'vue-router';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';

const props = defineProps({
  reservationId: {
    type: String,
    required: true,
  },
});

const router = useRouter();

const reservationId = computed(() => props.reservationId);

const { data: reservation, isLoading, error } = useReservation(reservationId);

const {
  mutate: confirmComped,
  isPending: isComping,
  error: compingError,
} = useConfirmComped(reservationId);

const isBusy = computed(() => isLoading.value || isComping.value);

const { validateIncompleteSteps, hasIncompleteSteps, missingStepsError } =
  useIncompleteSteps(reservation);

function complete() {
  validateIncompleteSteps.value = true;
  if (hasIncompleteSteps.value) {
    return;
  }
  confirmComped(
    {},
    {
      onSuccess: data => router.push(`/reservation/${data.id}/info`),
    }
  );
}

const priorityError = usePriorityError(error, compingError, missingStepsError);
</script>

<template>
  <SoonaButton :disabled="isBusy" @click="complete">
    create {{ reservation.reservation_type }} booking
  </SoonaButton>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

<style lang="scss" scoped>
button.soona-button {
  width: 100%;
  margin-bottom: 1rem;
}
</style>

<script setup>
import { ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import BulkCreditsModal from 'src/components/modal-payment-flows/preferred/BulkCreditsModal.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  headingElement: {
    default: 'h3',
    type: String,
  },
  dialogToShow: {
    default: 'credits',
    type: String,
    validator: function (value) {
      return ['credits', 'subscription'].includes(value);
    },
  },
});

const route = useRoute();
const { linkClicked } = useBaseEvents();

const linkLabel = 'open dialog to purchase credits';
const showDialog = ref(false);

const handleCloseDialog = () => (showDialog.value = false);

const handleOpendialog = () => {
  showDialog.value = true;

  linkClicked({
    context: route.meta.context,
    subContext: 'platform sidebar',
    linkLabel: linkLabel,
    linkHref: null,
  });
};
</script>

<template>
  <aside class="credit-purchase-banner">
    <div v-if="$slots['image']" class="credit-purchase-banner__left">
      <div class="credit-purchase-banner__img-container">
        <slot name="image" />
      </div>
    </div>
    <div class="credit-purchase-banner__center">
      <component
        :is="headingElement"
        v-if="$slots['heading-title'] || $slots['heading-subtitle']"
        class="credit-purchase-banner__heading"
      >
        <span class="u-badge--small">
          <slot name="heading-title" />
        </span>
        <span class="u-subheader--heavy">
          <slot name="heading-subtitle" />
        </span>
      </component>
      <div
        v-if="$slots['content-title'] || $slots['content-body']"
        class="credit-purchase-banner__logos-section"
      >
        <p v-if="$slots['content-title']" class="u-badge--small">
          <slot name="content-title" />
        </p>
        <slot name="content-body" />
      </div>
    </div>
    <div class="credit-purchase-banner__right">
      <button
        class="u-button-reset credit-purchase-banner__btn"
        @click="handleOpendialog"
      >
        <span class="u-badge--small credit-purchase-banner__btn-top">
          <slot name="btn-label" />
        </span>
        <span class="credit-purchase-banner__btn-bottom">
          <span>
            <span v-if="$slots['btn-bold']" class="u-subheader--heavy">
              <slot name="btn-bold" />
            </span>
            <span v-if="$slots['btn-sublabel']" class="u-label--regular">
              <slot name="btn-sublabel" />
            </span>
          </span>
          <SoonaIcon name="arrow-right" />
        </span>
      </button>
    </div>
    <BulkCreditsModal
      v-if="showDialog && dialogToShow === 'credits'"
      show-congrats-links
      @close="handleCloseDialog"
    />
  </aside>
</template>

<style lang="scss" scoped>
@use '@/variables';

.credit-purchase-banner {
  background-color: variables.$green-apple-10;
  border-radius: 0.625rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  overflow: hidden;
  width: 100%;

  @media (min-width: variables.$screen-sm-min) {
    flex-wrap: nowrap;
  }

  &__left {
    display: none;

    @media (min-width: variables.$screen-sm-min) {
      display: block;
    }
  }

  &__img-container {
    height: 5.625rem;
    width: 9.375rem;

    :deep(img) {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__center {
    align-items: center;
    display: flex;
    flex-grow: 1;
    gap: 4.75rem;
    padding: 0.75rem;
    padding-bottom: 0;

    @media (min-width: variables.$screen-sm-min) {
      padding: 1.5rem;
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__logos-section {
    display: none;

    @media (min-width: variables.$screen-xl-min) {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  &__right {
    align-items: center;
    display: flex;
    padding: 0.75rem;

    @media (min-width: variables.$screen-sm-min) {
      margin-left: auto;
    }
  }

  &__btn {
    background-color: variables.$green-apple-30;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: flex-end;
    padding: 1rem;
    padding-bottom: 0.5rem;
    padding-right: 0.75rem;
    text-align: left;
  }

  &__btn-bottom {
    align-items: center;
    display: flex;
    gap: 0.5rem;

    span:first-child {
      flex-grow: 1;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useReservation } from '@/composables/useReservation';
import unescape from 'lodash/unescape';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';

const props = defineProps({
  reservationId: {
    required: true,
    type: [Number, String],
  },
  titleEl: {
    default: 'h2',
    type: String,
    validator: value => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value),
  },
});

const {
  reservation,
  downPaymentOrderTotal,
  error: reservationError,
  isPack,
  lengthOfShoot,
  reservationLocation,
  shootType,
  totalProServices,
} = useReservation(computed(() => props.reservationId));

const priorityErrors = usePriorityErrors(reservationError);

const imageUrl = computed(
  () =>
    reservation.value?.preview_url ??
    reservation.value?.booking_gallery_image_url
);

const packOrByo = computed(() => {
  return isPack.value ? 'pack' : 'BYO';
});

const reservationTotal = computed(() => {
  return toCurrency(downPaymentOrderTotal.value, 'USD', 2, 'stripIfInteger');
});
</script>

<template>
  <SoonaItemCard
    :image-url="imageUrl"
    :aria-labelledby="`reservation-${reservationId}-title`"
  >
    <template #content>
      <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
      <div v-if="reservation" class="card-content__wrapper">
        <div class="card-content">
          <component
            :is="titleEl"
            :id="`reservation-${reservationId}-title`"
            class="u-body--heavy"
          >
            {{ unescape(reservation?.name) }}
          </component>
          <p class="u-label--regular">
            {{ lengthOfShoot?.display }} · {{ reservationLocation?.name }} ·
            {{ reservationTotal }}
          </p>
          <p class="u-label--regular gray-text">
            <SoonaIcon name="camera" size="small" />
            {{ shootType }} · {{ packOrByo }}
          </p>
          <p v-if="totalProServices" class="u-label--regular gray-text">
            <SoonaIcon name="user-heart-alt-1" size="small" />
            {{ totalProServices }} pro service{{
              totalProServices === 1 ? '' : 's'
            }}
          </p>
        </div>
        <slot name="actions"></slot>
      </div>
    </template>
  </SoonaItemCard>
</template>

<style lang="scss" scoped>
@use '@/variables';

.card-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &__wrapper {
    padding: 0.5rem 1rem;
    gap: 0.25rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }
}

.gray-text {
  align-items: center;
  color: variables.$gray-60;
  display: flex;
  gap: 0.25rem;
}
</style>

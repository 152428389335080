<script setup>
import { computed } from 'vue';
const props = defineProps({
  cypressName: {
    required: false,
    type: String,
  },
  modelValue: {
    required: true,
    type: Boolean,
  },
  align: {
    required: false,
    type: String,
    default: 'center',
  },
});
const emits = defineEmits(['update:modelValue']);
const inputValue = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emits('update:modelValue', val);
  },
});
</script>

<template>
  <div class="marketing-email-consent">
    <input
      id="marketing-content-checkbox"
      v-model="inputValue"
      type="checkbox"
      :data-cypress="cypressName"
      @update="emits('update:modelValue', $event.target.value)"
    />
    <label for="marketing-content-checkbox">
      <span
        :class="{
          'u-small--regular': textSize === 'small',
        }"
      >
        receive ecomm tips. inspiration. and special offers from soona. view our
        <a
          href="http://soona.co/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          >privacy policy</a
        >.
      </span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.marketing-email-consent {
  border-radius: none;
  margin: 0;
  max-width: 100%;
  padding: 0;
  padding-bottom: 1.4375rem;
  width: max-content;
  display: flex;
  text-align: v-bind(align);
  label {
    @include variables_fonts.u-label--regular;
    color: variables.$black-default;
  }
  input {
    margin-right: 0.3125rem;
    cursor: pointer;
  }
  a {
    position: relative;
    &::after {
      background-color: variables.$black-default;
      content: '';
      position: absolute;
      bottom: 0;
      height: 0.0625rem;
      left: 0;
      width: 100%;
    }
  }
}
</style>

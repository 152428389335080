import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { postPackConfiguration } from '@/api/packConfigurations';
import { queryKeys } from '../query-keys';
import { unref } from 'vue';

export function useCreatePackConfiguration() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => postPackConfiguration(unref(body)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.packConfigurations({
          packBuilder: true,
          status: ['draft', 'published'],
        }),
      });
    },
  });
}

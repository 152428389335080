export const collaboratorsRoutes = [
  {
    path: '/account/:accountId/collaborators',
    name: 'team members',
    component: () =>
      import(
        '@/components/user/anytime/dashboard/collaborators/CollaboratorsPage.vue'
      ),
    meta: {
      context: 'team members',
      page_title: 'team members',
      requires_auth: true,
      requires_capability: {
        capability: 'manage_collaborators',
        subjectType: 'account',
        subjectId: to => to.params.accountId,
      },
      show_quest: true,
    },
    props: route => ({
      accountId: route.params.accountId,
    }),
    children: [
      {
        path: '',
        name: 'collaborators-list',
        component: () =>
          import(
            '@/components/user/anytime/dashboard/collaborators/CollaboratorsList.vue'
          ),
        meta: {
          context: 'team members management',
          page_title: 'team members management',
          requires_auth: true,
          requires_capability: {
            capability: 'manage_collaborators',
            subjectType: 'account',
            subjectId: to => to.params.accountId,
          },
        },
      },
      {
        path: 'invitations',
        name: 'invitations-list',
        component: () =>
          import(
            '@/components/user/anytime/dashboard/collaborators/InvitationsList.vue'
          ),
        meta: {
          context: 'team members invitations',
          page_title: 'team members invitations',
          requires_auth: true,
          requires_capability: {
            capability: 'manage_collaborators',
            subjectType: 'account',
            subjectId: to => to.params.accountId,
          },
        },
      },
    ],
  },
];

<script setup>
import { computed, watchEffect } from 'vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaItemCardSelectable from 'src/components/ui_library/SoonaItemCardSelectable.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { useGetInfiniteShotTags } from '@/queries/useGetShotTags';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useVirtualizer } from '@tanstack/vue-virtual';

const props = defineProps({
  containerEl: {
    type: Object,
    required: true,
  },
  querySlug: {
    required: true,
    type: String,
  },
  selectedProducts: {
    required: true,
    type: Array,
  },
});

const emit = defineEmits(['handle-selection']);

// virtualized infinite scroll
const {
  data,
  error,
  isLoading,
  isFetching,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
} = useGetInfiniteShotTags({ slug: computed(() => props.querySlug) });

const allRows = computed(() =>
  data.value ? data.value.pages.flatMap(d => d.data) : []
);

const rowVirtualizerOptions = computed(() => {
  return {
    count: data.value?.pages.at(-1)?.pagination?.totalCount ?? 0,
    getScrollElement: () => props.containerEl,
    estimateSize: () => 74,
    overscan: 5,
    gap: 16,
  };
});

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions);
const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());
const totalSize = computed(() => `${rowVirtualizer.value?.getTotalSize()}px`);

const measureElement = el => {
  if (!el) return;

  rowVirtualizer.value.measureElement(el);
};

watchEffect(() => {
  const lastItem = virtualRows.value.at(-1);

  if (!lastItem) return;

  if (
    lastItem.index >= allRows.value.length - 1 &&
    hasNextPage.value &&
    !isFetchingNextPage.value
  ) {
    fetchNextPage();
  }
});

const showSubCategoryHeader = (prev, curr) => {
  if (prev === undefined) return true;

  return prev.subcategory !== curr?.subcategory;
};

const priorityErrors = usePriorityErrors(error);

const selectedProductIdsSet = computed(
  () => new Set(props.selectedProducts.map(p => p.id))
);
</script>

<template>
  <SoonaLoading
    v-if="isLoading || (isFetching && !isFetchingNextPage)"
    is-loading
    is-contained
  />
  <SoonaError v-else-if="priorityErrors" :priority-errors="priorityErrors" />
  <div
    v-else
    class="dialog-selectable-card__rows"
    :style="{
      height: totalSize,
    }"
  >
    <div
      v-for="virtualRow in virtualRows"
      :key="virtualRow.key"
      :ref="measureElement"
      :data-index="virtualRow.index"
      class="dialog-selectable-card__row"
      :style="{
        transform: `translateY(${virtualRow.start}px)`,
      }"
    >
      <h3
        v-if="
          showSubCategoryHeader(
            allRows?.[virtualRow.index - 1],
            allRows?.[virtualRow.index]
          )
        "
        class="u-subheader--heavy dialog-selectable-card__row--subheader"
      >
        {{ allRows?.[virtualRow.index]?.subcategory?.replace(/_/g, ' ') }}
      </h3>
      <SoonaItemCardSelectable
        :id="`option-${allRows?.[virtualRow.index]?.id}`"
        :aria-labelledby="`${allRows?.[virtualRow.index]?.id}-heading`"
        :checked="selectedProductIdsSet.has(allRows?.[virtualRow.index]?.id)"
        :image-url="allRows[virtualRow.index]?.image_url"
        :disabled="false"
        name="props"
        @change="
          e =>
            emit(
              'handle-selection',
              e.target.checked,
              allRows[virtualRow.index]
            )
        "
      >
        <template #default>
          <div class="dialog-selectable-card__wrapper">
            <div class="dialog-selectable-card__content">
              <h2
                :id="`${allRows?.[virtualRow.index]?.id}-heading`"
                class="u-body--heavy"
              >
                {{ allRows?.[virtualRow.index]?.title }}
              </h2>
            </div>
          </div>
        </template>
      </SoonaItemCardSelectable>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.dialog-selectable-card {
  &__rows {
    position: relative;
    width: 100%;
  }

  &__row {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &--subheader {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  &__wrapper {
    display: flex;
    padding: 0.5rem 0.75rem;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>

import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { applyCoupon } from '@/api/reservation';

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useApplyCoupon(reservationId, accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ coupon, orderId }) =>
      applyCoupon(unref(reservationId), coupon, orderId),
    onSuccess: async (data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservations(),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.order(variables.orderId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.accountSalesTax(accountId),
        }),
      ]);
    },
  });
}

<template>
  <div>
    <div class="custom-tracker">
      <div class="tracker-bar">
        <div
          class="segment"
          :class="{
            active:
              statusUnknown ||
              statusPreTransit ||
              statusTransit ||
              statusDelivered,
          }"
        >
          <div
            class="point"
            :class="{
              active: statusUnknown,
            }"
          >
            <span v-if="statusUnknown" class="tracker-icon">📦</span>
            <div
              class="status-label"
              :class="{
                active:
                  statusUnknown ||
                  statusPreTransit ||
                  statusTransit ||
                  statusDelivered,
              }"
            >
              unknown
            </div>
          </div>
        </div>
        <div
          class="segment"
          :class="{
            active: statusPreTransit || statusTransit || statusDelivered,
          }"
        >
          <div
            v-if="!isDropOffPackage"
            class="point"
            :class="{
              active: statusPreTransit,
            }"
          >
            <span v-if="statusPreTransit" class="tracker-icon">📦</span>
            <div
              class="status-label"
              :class="{
                active: statusPreTransit || statusTransit || statusDelivered,
              }"
            >
              pre-transit
            </div>
          </div>
        </div>
        <div
          class="segment"
          :class="{
            active: statusTransit || statusDelivered,
          }"
        >
          <div
            v-if="!isDropOffPackage"
            class="point"
            :class="{
              active: statusTransit,
            }"
          >
            <span v-if="statusTransit" class="tracker-icon">📦</span>
            <div
              class="status-label"
              :class="{
                active: statusTransit || statusDelivered,
              }"
            >
              transit
            </div>
          </div>
        </div>
        <div
          class="segment"
          :class="{
            active: statusDelivered,
          }"
        >
          <div
            class="point"
            :class="{
              active: statusDelivered,
            }"
          >
            <span v-if="statusDelivered" class="tracker-icon">📦</span>
            <div
              class="status-label"
              :class="{
                active: statusDelivered,
              }"
            >
              delivered
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShippingTracker',
  props: {
    inventoryPackage: Object,
  },
  computed: {
    //   these shippo statuses can be found at https://goshippo.com/docs/reference#tracks
    statusUnknown() {
      return (
        this.inventoryPackage.inbound_shipping_detail.shipping_status ===
          'UNKNOWN' ||
        this.inventoryPackage.inbound_shipping_detail.shipping_status ===
          null ||
        this.inventoryPackage.inbound_shipping_detail.shipping_status ===
          undefined
      );
    },
    statusPreTransit() {
      return (
        this.inventoryPackage.inbound_shipping_detail.shipping_status ===
        'PRE_TRANSIT'
      );
    },
    statusTransit() {
      return (
        this.inventoryPackage.inbound_shipping_detail.shipping_status ===
        'TRANSIT'
      );
    },
    statusDelivered() {
      return (
        this.inventoryPackage.inbound_shipping_detail.shipping_status ===
        'DELIVERED'
      );
    },
    isDropOffPackage() {
      return (
        this.inventoryPackage.inbound_shipping_detail.shipping_provider ===
        'drop off'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.custom-tracker {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracker-bar {
  height: 0.5rem;
  display: flex;
  position: relative;
  width: 100%;
}

.segment {
  background: #d9e7ff;
  height: 0.75rem;
  flex: 1 1 0px;
  position: relative;
  display: flex;
  align-items: center;
  &.active {
    background: variables.$periwink-blue-60;
  }
}

.point {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background: variables.$periwink-blue-60;
  position: absolute;
  right: -0.25rem;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    height: 3rem;
    width: 3rem;
    border: 2px solid variables.$white-default;
    background: #d9e7ff;
  }
}

.point:last-child {
  right: 2px;
  &.active {
    right: -0.25rem;
  }
}

.segment:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.segment:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.status-label {
  position: absolute;
  bottom: -1.5rem;
  font-size: 11px;
  white-space: nowrap;
  color: #d9e7ff;
  &.active {
    color: variables.$periwink-blue-60;
  }
}

.tracker-icon {
  position: absolute;
  top: 0.65rem;
}

@media only screen and (max-width: 480px) {
  .custom-tracker {
    width: 96%;
  }
}
</style>

<script setup>
import { ref } from 'vue';
import EnvironmentDialog from './EnvironmentDialog.vue';
import EmptyImg from 'images/shot-list/scenes/empty-environment.png';
import SectionLayout from './SectionLayout.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';

defineProps({
  selectedOption: {
    default: null,
    type: Object,
  },
  showSaveError: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(['handle-select']);

const showDialog = ref(false);

const handleSelect = option => {
  if (option.title !== 'Color Backdrop') {
    emit('handle-select', 'backdrop_color', null);
  }

  if (option.title !== 'Textured Surface') {
    emit('handle-select', 'backdrop_texture', null);
  }

  emit('handle-select', 'environment', option);
  showDialog.value = false;
};
</script>

<template>
  <SectionLayout :empty-img="EmptyImg" :show-empty="!selectedOption">
    <template #heading>environment type</template>
    <template #empty-heading>what will the backdrop look like?</template>
    <template #empty-error>
      <SoonaError v-if="showSaveError" no-margin>
        environment is required
      </SoonaError>
    </template>
    <template #empty-btn>
      <SoonaButton variation="solid-black" @on-click="showDialog = true">
        choose environment
      </SoonaButton>
    </template>
    <SoonaButton
      size="medium"
      variation="secondary-gray"
      @on-click="showDialog = true"
    >
      edit
    </SoonaButton>
    <SoonaChip
      :image-src="selectedOption?.image_url"
      image-alt="selected environment"
    >
      {{ selectedOption?.title?.toLowerCase() }}
    </SoonaChip>
  </SectionLayout>
  <EnvironmentDialog
    v-if="showDialog"
    @select="handleSelect"
    @close="showDialog = false"
  />
</template>

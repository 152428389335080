import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {number} accountId
 * @param {array} existingCollaborators
 * @param {array} newInvites
 * @param {number} reservationId
 * @returns {Object}
 */
export async function sendGalleryInvitations(
  accountId,
  { existingCollaborators, newInvites, reservationId }
) {
  const response = await http.post(
    `/accounts/${accountId}/invitations/gallery_invitations`,
    {
      invitations: {
        new_invites: newInvites,
        existing_collaborators: existingCollaborators,
        reservation_id: reservationId,
      },
    }
  );
  return response.data;
}

/**
 *
 * @param {string} accountId
 * @param {string} invitationId
 * @param {string} bookingId via params
 * @param {string} token via params
 * @param {AbortSignal} [signal]
 * @returns {Object}
 */
export async function getAcceptableInvitation(
  accountId,
  invitationId,
  params = {},
  signal
) {
  const response = await http.get(
    `/accounts/${accountId}/invitations/${invitationId}/acceptable`,
    {
      params,
      signal,
    }
  );

  return response.data;
}

/**
 *
 * @param {string | number} accountId
 * @param {Object} params
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.status]
 * @param {AbortSignal} [signal]
 * @return {Promise<any>}
 */
export async function getInvitations(
  accountId,
  { currentPage, itemsPerPage, status },
  signal
) {
  const params = {};

  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;
  if (status) params['status'] = status;

  const response = await http.get(`/accounts/${accountId}/invitations`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    invitations: response.data,
  };
}

/**
 *
 * @param {string | number} accountId
 * @param {Object} invitation
 */
export async function createInvitation(accountId, invitation) {
  const response = await http.post(`/accounts/${accountId}/invitations`, {
    invitation,
  });

  return response.data;
}

/**
 * @param {string | number} accountId
 * @param {string | number} invitationId
 */
export async function removeInvitation(accountId, invitationId) {
  const response = await http.delete(
    `/accounts/${accountId}/invitations/${invitationId}`
  );

  return response.data;
}

<script setup>
import { computed, ref } from 'vue';
import { useMokkerChildGenerations } from '@/queries/digital_assets/useMokkerChildGenerations';
import GenerationPreviewThumbnail from '@/components/user/anytime/gallery/media-editor/media-multiplier/uncrop/GenerationPreviewThumbnail.vue';
import GenerationPreviewDialog from '@/components/user/anytime/gallery/media-editor/media-multiplier/uncrop/GenerationPreviewDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaRadioButtonGroup from '@/components/ui_library/soona_radio_buttons/SoonaRadioButtonGroup.vue';
import SoonaRadioButtonItem from '@/components/ui_library/soona_radio_buttons/SoonaRadioButtonItem.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId, numberOfGenerations } =
  storeToRefs(mediaEditorStore);

const { assetUuid } = useMediaEditorDigitalAsset(assetAccountId, assetId);

const {
  data: childGenerations,
  error: AIGenerationsError,
  loading: AIGenerationsLoading,
} = useMokkerChildGenerations(
  assetUuid,
  { taskType: 'UncropCvServiceTask' },
  {
    enabled: computed(() => !!assetUuid.value),
  }
);

// dialog
const previewAssetId = ref(null);

const navigatePreviousNextAsset = direction => {
  const currentIndex = childGenerations.value.findIndex(
    child => child.id === previewAssetId.value
  );
  const newIndex =
    (currentIndex + direction + childGenerations.value.length) %
    childGenerations.value.length;

  previewAssetId.value = childGenerations.value[newIndex].id;
};
</script>

<template>
  <SoonaError v-if="AIGenerationsError" :error="AIGenerationsError" />
  <SoonaLoading v-else-if="AIGenerationsLoading" is-loading is-contained />
  <template v-else>
    <div class="generations__selector">
      <div class="generations__selector-heading">images generated:</div>
      <SoonaRadioButtonGroup
        label="number of generations"
        name="generations"
        class="generations__selector-radio"
        :model-value="numberOfGenerations"
        @update:model-value="mediaEditorStore.setNumberOfGenerations"
      >
        <SoonaRadioButtonItem
          v-for="option in [1, 2, 4]"
          :key="option"
          :value="option"
          :label="option"
        />
      </SoonaRadioButtonGroup>
    </div>
    <div v-if="childGenerations" class="generations">
      <GenerationPreviewThumbnail
        v-for="child in childGenerations"
        :key="child.uuid"
        class="generations__preview"
        :asset-id="child.id"
        :asset-account-id="child.account_id"
        @load-preview="previewAssetId = $event"
      />
      <GenerationPreviewDialog
        v-if="previewAssetId"
        :asset-id="previewAssetId"
        :asset-account-id="assetAccountId"
        @previous-next-asset="navigatePreviousNextAsset($event)"
        @close="previewAssetId = null"
      />
    </div>
  </template>
</template>

<style scoped lang="scss">
@use '@/variables';

.generations {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;

  &__selector {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 2rem;

    &-heading {
      color: variables.$gray-60;
    }

    &-radio {
      width: fit-content;
    }
  }

  &__preview {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 0 0 variables.$gray-30;
    transition: box-shadow 0.1s ease-out;

    &:hover {
      box-shadow: 0 0 0 0.25rem variables.$gray-30;
      cursor: pointer;
    }
  }
}
</style>

<script setup>
import { ref } from 'vue';
import uniqueId from 'lodash/uniqueId';

const id = ref(uniqueId('sidebar-section-heading'));
</script>

<template>
  <div class="sidebar-section" v-bind="$attrs">
    <h2 v-if="$slots.heading" :id="id" class="sidebar-section__heading">
      <slot name="heading" />
    </h2>
    <slot :heading-id="id" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.sidebar-section {
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  align-items: stretch;
  gap: 0.125rem;

  &__heading {
    @include variables_fonts.u-badge--small;

    display: flex;
    margin: 0 0 0.25rem 0.75rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    color: variables.$gray-60;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { convertToMMDDYYYYmonthAbr } from '@/lib/date-formatters';
import { toCurrency } from '@/lib/currency';
import {
  AvoToast20,
  AvoToast80,
  BubbletapePink20,
  BubbletapePink80,
  Roses20,
  Roses80,
  Tangerine20,
  Tangerine80,
} from '@/variables.module.scss';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
});

const flagData = computed(() => {
  switch (props.order.status) {
    case 'completed':
      return {
        backgroundColor: AvoToast20,
        textColor: AvoToast80,
        title: 'completed',
      };
    case 'paid':
      return {
        backgroundColor: Tangerine20,
        textColor: Tangerine80,
        title: 'paid',
      };
    case 'submitted':
      return {
        backgroundColor: Tangerine20,
        textColor: Tangerine80,
        title: 'pending',
      };
    default:
      return {
        backgroundColor: Roses20,
        textColor: Roses80,
        title: 'canceled',
      };
  }
});

const isOrder = computed(() => props.order.type === 'order');

const paymentDate = computed(() => {
  return convertToMMDDYYYYmonthAbr(
    props.order.date_collected ?? props.order.updated_at
  );
});

const paymentMethod = computed(() =>
  props.order.payment_method?.payment_method_data?.display_brand?.replace(
    /_/g,
    ' '
  )
);

const totalAssetsOnOrder = computed(() => {
  if (!props.order.asset_count) return null;

  const assetCount = props.order.asset_count;
  let totalAssets = [];

  if (assetCount.images > 0) {
    totalAssets.push(
      `${assetCount.images} image${assetCount.images > 1 ? 's' : ''}`
    );
  }

  if (assetCount.gifs > 0) {
    totalAssets.push(`${assetCount.gifs} gif${assetCount.gifs > 1 ? 's' : ''}`);
  }

  if (assetCount.videos > 0) {
    totalAssets.push(
      `${assetCount.videos} video${assetCount.videos > 1 ? 's' : ''}`
    );
  }

  return totalAssets.join(', ');
});

const toRoute = computed(() => {
  if (isOrder.value) {
    return {
      name: 'order-editing-summary',
      params: {
        reservationId: props.order.reservation_id,
        orderId: props.order.id,
      },
    };
  }

  return {
    name: 're-edit-editing-summary',
    params: {
      reservationId: props.order.reservation_id,
      reEditId: props.order.id,
    },
  };
});
</script>

<template>
  <RouterLink class="order-preview-card" :to="toRoute">
    <div v-if="order.digital_assets.length" class="order-preview-card__img">
      <img :src="order.digital_assets[0].preview_url" alt="" />
    </div>
    <div class="order-preview-card__body">
      <p
        v-if="order.status === 'paid' || order.status === 'submitted'"
        class="u-label--regular order-preview-card__in-progress"
      >
        🎨 we’re working on it! edits will be added to your gallery when
        complete.
      </p>
      <div class="order-preview-card__bottom">
        <div class="order-preview-card__general-details">
          <p class="u-subheader--heavy">
            {{ paymentDate }}
          </p>
          <p v-if="isOrder" class="u-label--heavy">order#: {{ order.id }}</p>
          <p class="u-label--regular order-preview-card__booking">
            booking#: {{ order.reservation_id }}
          </p>
        </div>
        <div class="order-preview-card__payment-details">
          <div class="order-preview-card__status">
            <SoonaFlag
              :background-color="flagData.backgroundColor"
              :text-color="flagData.textColor"
              :title="flagData.title"
            />
            <SoonaFlag
              v-if="!isOrder"
              :background-color="BubbletapePink20"
              :text-color="BubbletapePink80"
              title="re-edit"
            />
            <p v-else class="u-subheader--heavy">
              {{ toCurrency(order.amount_collected) }}
            </p>
          </div>
          <p v-if="paymentMethod && isOrder" class="u-label--regular">
            <span class="order-preview-card__card">{{ paymentMethod }}</span>
            ending in {{ order.payment_method.payment_method_data.last4 }}
          </p>
          <p class="u-label--regular order-preview-card__total-assets">
            {{ totalAssetsOnOrder }}
          </p>
        </div>
      </div>
    </div>
    <SoonaIcon name="chevron-right" />
  </RouterLink>
</template>

<style lang="scss" scoped>
@use '@/variables';

.order-preview-card {
  align-items: stretch;
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  color: variables.$black-default;
  display: flex;
  gap: 1rem;
  padding: 0.75rem;
  transition: background-color 0.1s;

  &:focus-visible:not(:active) {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  &:hover,
  &:focus-visible {
    background-color: variables.$gray-10;
  }

  &:active {
    background-color: variables.$periwink-blue-20;
  }

  &__img {
    display: none;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.75rem;
  }

  &__in-progress {
    color: variables.$tangerine-80;
  }

  &__bottom {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 1rem;
    height: 100%;
  }

  &__general-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.25rem;
  }

  &__booking {
    color: variables.$gray-70;
  }

  &__payment-details {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-end;
  }

  &__status {
    display: flex;
    gap: 0.625rem;
  }

  &__card {
    text-transform: capitalize;
  }

  &__total-assets {
    color: variables.$gray-70;
  }

  svg {
    display: none;
  }

  @media (min-width: variables.$screen-sm-min) {
    &__img {
      border-radius: 0.3125rem;
      display: flex;
      height: 6.75rem;
      overflow: hidden;
      width: 6.75rem;

      img {
        object-fit: cover;
        width: 100%;
      }
    }

    svg {
      display: block;
      margin: auto 0;
    }
  }
}
</style>

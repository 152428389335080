<script setup>
import { computed } from 'vue';

const props = defineProps({
  message: Object,
  isOutgoing: Boolean,
});

const assetLink = computed(() => {
  if (
    props.message?.data?.customData?.reservationId &&
    props.message?.data?.customData?.digitalAssetId
  ) {
    return `/reservation/${props.message.data.customData.reservationId}/asset/${props.message.data.customData.digitalAssetId}`;
  }

  if (
    props.message?.data?.customData?.reservationFile?.reservation_id &&
    props.message?.data?.customData?.reservationFile?.id
  ) {
    return `/reservation/${props.message.data.customData.reservationFile.reservation_id}/media/${props.message.data.customData.reservationFile.id}`;
  }

  return null;
});

const truncatedNote = computed(() => {
  const text =
    props.message?.data?.customData?.note ??
    props.message?.data?.customData?.reservationFile?.note ??
    '';
  if (!text) return;
  if (text.length <= 160) return text;

  return text.substring(0, 160) + '\u2026';
});

const title = computed(() => {
  return (
    props.message?.data?.customData?.title ??
    props.message?.data?.customData?.reservationFile?.title ??
    'asset'
  );
});
</script>

<template>
  <div class="NoteAddedMessage">
    <p class="message-text">
      {{ message.sender.name.split(' ', 1)[0] }} added a note ✏️ "{{
        truncatedNote
      }}" on
    </p>
    <router-link v-if="assetLink" :to="{ path: assetLink }">
      <span class="file-link">
        {{ title }}
      </span>
    </router-link>
    <span v-else class="file-link">{{ title }}</span>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.NoteAddedMessage {
  padding: 0.75rem 0;
  margin: 0 37px;

  .message-text {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: variables.$gray-70;
    word-break: break-word;
  }

  .file-link {
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    color: variables.$gray-70;
  }
}
</style>

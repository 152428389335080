import * as Sentry from '@sentry/vue';
import * as types from 'src/store/mutation-types';
import { countries } from '../modules/shared/countries';
import { timeZones } from '../modules/shared/timezones';

const accountTypes = [
  {
    name: 'personal',
    description: 'for your bad self!',
  },
  {
    name: 'brand',
    description: 'for your brand or on behalf of other brands.',
  },
];

const personalTrack = [
  'account_type_card',
  'preferred_timezone_card',
  'upload_profile_pic',
  'lets_connect_one',
  'lets_connect_two',
  'style_quiz',
];
const brandTrack = [
  'account_type_card',
  'what_brings_you_to_soona',
  'lets_get_the_basics',
  'address_card',
  'preferred_timezone_card',
  'lets_connect_one',
  'lets_connect_two',
  'upload_profile_pic',
  'use_my_content',
  'where_do_you_sell',
  'where_do_you_advertise',
  'alternate_content_use',
  'company_logo',
  'brand_fonts',
  'brand_colors',
  'brand_guide',
  'style_quiz',
];

const brandCategoryIndustries = [
  { id: 0, title: 'me!', enum: 'me!', icon: '🙋‍♀️' },
  { id: 1, title: 'beauty!', enum: 'beauty', icon: '💄' },
  {
    id: 2,
    title: 'health & wellness',
    enum: 'health_and_wellness',
    icon: '🧘🏼‍♀️',
  },
  { id: 3, title: 'fashion', enum: 'fashion', icon: '👟' },
  { id: 4, title: 'food & bev', enum: 'food_and_bev', icon: '🥤' },
  { id: 5, title: 'pet products', enum: 'pet_products', icon: '🐈' },
  { id: 6, title: 'tech', enum: 'tech', icon: '📱' },
  { id: 7, title: 'toys & games', enum: 'toys_and_games', icon: '🧩' },
  { id: 8, title: 'home & garden', enum: 'home_and_garden', icon: '🏡' },
  {
    id: 9,
    title: 'professional services',
    enum: 'professional_services',
    icon: '💡',
  },
  { id: 10, title: 'CBD', enum: 'CBD', icon: '🌿' },
  { id: 11, title: 'other', enum: 'other', icon: '💬' },
];

const state = {
  avatar_url: undefined,
  logo_url: undefined,
  guide_url: undefined,
  email: undefined,
  external_user_id: undefined,
  id: undefined,
  name: undefined,
  phone: undefined,
  vip: undefined,
  subscription: undefined,
  brand_category: undefined,
  shoot_role: undefined,
  has_assignments: undefined,
  preferred: undefined,
  referral_code: undefined,
  current_quiz_style: {},
  account_type: null,
  address: {},
  owner: {},
  profile_data: {},
  pro_service_availability: [],
  allShootRoles: [
    {
      id: 0,
      title: 'director',
      enum: 'director',
      description: 'I know exactly what I want!',
      icon: '🎬',
    },
    {
      id: 1,
      title: 'producer',
      enum: 'producer',
      description: 'I have the list! the rest is up to the crew.',
      icon: '📋',
    },
    {
      id: 2,
      title: 'collaborator',
      enum: 'collaborator',
      description: 'I have a good start on my list. but open to ideas!',
      icon: '📓',
    },
    {
      id: 3,
      title: 'creative',
      enum: 'creative',
      description: 'I have ideas! but I want more!',
      icon: '✏️',
    },
    {
      id: 4,
      title: 'newbie',
      enum: 'newbie',
      description: 'I need to be guided step by step.',
      icon: '🖍️',
    },
  ],
  unpaidAdditionalChargeOrder: {},
  additionalChargeProducts: [],
  additionalChargeOrder: {
    products: {},
    salesTax: {},
    memo: '',
    chargeImmediately: false,
    compedOrder: false,
    creditReason: '',
    creditReasonDetails: '',
  },
  accounts_flags: [],
  flags: [],
  // do not use in app code, this is internal to the store to prevent race conditions
  __desiredAccountId: null,
};

const getters = {
  accountName(state) {
    return state.name;
  },
  accountEmail(state) {
    return state.email;
  },
  accountId(state) {
    return state.id;
  },
  brandCategory(state) {
    const category = brandCategoryIndustries.find(
      b => b.enum === state.brand_category
    );
    return category ? category : {};
  },
  defaultPaymentMethod(state) {
    return state.payment_methods?.find(p => p.default);
  },
  isProServiceAccount(state) {
    return state.owner.employee_type_title === 'pro services';
  },
  accountTypes() {
    return accountTypes;
  },
  hasAccountType(state) {
    return state.account_type !== null;
  },
  whatBringsYouToSoonaCompleted(state) {
    return (
      state.profile_data?.what_brings_you_to_soona?.complete ||
      state.profile_data?.what_brings_you_to_soona?.skip
    );
  },
  letsGetTheBasicsCompleted(state) {
    return state.profile_data?.lets_get_the_basics?.complete;
  },
  addressCompleted(state) {
    return state.profile_data?.address_card?.complete;
  },
  timezoneCompleted(state) {
    return state.profile_data?.preferred_timezone_card?.complete;
  },
  letsConnect1Completed(state) {
    return (
      state.profile_data?.lets_connect_one?.complete ||
      state.profile_data?.lets_connect_one?.skip
    );
  },
  letsConnect2Completed(state) {
    return (
      state.profile_data?.lets_connect_two?.complete ||
      state.profile_data?.lets_connect_two?.skip
    );
  },
  useMyContentCompleted(state) {
    return state.profile_data?.use_my_content?.complete;
  },
  whereDoYouAdvertiseCompleted(state) {
    return (
      state.profile_data?.where_do_you_advertise?.complete ||
      state.profile_data?.where_do_you_advertise?.skip
    );
  },
  whereDoYouSellCompleted(state) {
    return (
      state.profile_data?.where_do_you_sell?.complete ||
      state.profile_data?.where_do_you_sell?.skip
    );
  },
  alternateContentUseCompleted(state) {
    return (
      state.profile_data?.alternate_content_use?.complete ||
      state.profile_data?.alternate_content_use?.skip
    );
  },
  fontsCompleted(state) {
    return (
      state.profile_data?.brand_fonts?.complete ||
      state.profile_data?.brand_fonts?.skip
    );
  },
  colorsCompleted(state) {
    return (
      state.profile_data?.brand_colors?.complete ||
      state.profile_data?.brand_colors?.skip
    );
  },
  profilePicCompleted(state) {
    return (
      state.profile_data?.upload_profile_pic?.complete ||
      state.profile_data?.upload_profile_pic?.skip
    );
  },
  companyLogoCompleted(state) {
    return (
      state.profile_data?.company_logo?.complete ||
      state.profile_data?.company_logo?.skip
    );
  },
  brandGuideCompleted(state) {
    return (
      state.profile_data?.brand_guide?.complete ||
      state.profile_data?.brand_guide?.skip
    );
  },
  styleQuizCompleted(state) {
    return state.profile_data?.style_quiz?.complete;
  },
  quizPercentageCompleted(state) {
    let entries = Object.entries(state.profile_data);
    let incompleteSteps = entries.filter(
      entry => entry[1].complete === false || entry[1]?.skip === true
    );
    if (state.account_type === 'brand') {
      let filteredIncompleteSteps = incompleteSteps.filter(step =>
        brandTrack.find(item => item === step[0])
      );
      return Math.ceil(
        100 - (filteredIncompleteSteps.length / brandTrack.length) * 100
      );
    } else if (state.account_type === 'personal') {
      let filteredIncompleteSteps = incompleteSteps.filter(step =>
        personalTrack.find(item => item === step[0])
      );
      return Math.ceil(
        100 - (filteredIncompleteSteps.length / personalTrack.length) * 100
      );
    } else {
      return 0;
    }
  },
  countriesList() {
    return countries;
  },
  timeZonesList() {
    return timeZones;
  },
  guessedTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  productsWithQuantities(state) {
    return state.additionalChargeProducts.map(product => {
      const quantity = state.additionalChargeOrder.products[product.id] || 0; // || 0 prevents undefined keys from being cast to NaN

      return {
        ...product,
        quantity,
      };
    });
  },
  photoAccountCreditProducts(state, getters) {
    return getters.productsWithQuantities.filter(
      ({ product_type, shoot_type }) =>
        product_type === 'credit' && shoot_type === 'photo'
    );
  },
  videoAccountCreditProducts(state, getters) {
    return getters.productsWithQuantities.filter(
      ({ content_category, product_type, shoot_type }) =>
        product_type === 'credit' &&
        shoot_type === 'video' &&
        !(content_category === 'behind_the_scenes')
    );
  },
  gifAccountCreditProducts(state, getters) {
    return getters.productsWithQuantities.filter(
      ({ product_type, shoot_type }) =>
        product_type === 'credit' && shoot_type === 'animation'
    );
  },
  productsInCart(state) {
    const productsInCart = [];
    state.additionalChargeProducts.forEach(product => {
      const quantity = state.additionalChargeOrder.products[product.id] || 0; // || 0 prevents undefined keys from being cast to NaN

      if (quantity > 0) {
        productsInCart.push({
          ...product,
          quantity: quantity,
        });
      }
    });
    return productsInCart;
  },
};

const mutations = {
  [types.SET_DESIRED_ACCOUNT_ID](state, accountId) {
    state.__desiredAccountId = Number(accountId);
  },
  [types.SET_ACCOUNT](state, account) {
    Object.assign(state, account);
  },
  [types.SET_SUBSCRIPTION](state, subscription) {
    state.subscription = subscription;
  },
  [types.SET_PRO_SERVICE_AVAILABILITY](state, pro_service_availability) {
    state.pro_service_availability = pro_service_availability;
  },
  [types.SET_ADD_CREDITS_PRODUCTS](state, products) {
    state.additionalChargeProducts = products;
  },
  [types.SET_ADD_CREDITS_ITEMS](state, payload) {
    state.additionalChargeOrder.products = {
      ...state.additionalChargeOrder.products,
      ...payload,
    };
  },
  [types.REMOVE_ADD_CREDITS_ITEM](state, payload) {
    delete state.additionalChargeOrder.products[payload];
  },
  [types.CLEAR_ADD_CREDITS_ITEMS](state) {
    state.additionalChargeOrder.products = {};
  },
  [types.SET_ADD_CREDITS_MEMO](state, memo) {
    state.additionalChargeOrder.memo = memo;
  },
  [types.SET_ADD_CREDITS_IMMEDIATELY](state, chargeImmediately) {
    state.additionalChargeOrder.chargeImmediately = !!chargeImmediately;
  },
  [types.SET_COMPED_ORDER](state, compedOrder) {
    state.additionalChargeOrder.compedOrder = !!compedOrder;
  },
  [types.SET_COMPED_CREDITS_REASON](state, creditReason) {
    state.additionalChargeOrder.creditReason = creditReason;
  },
  [types.SET_COMPED_CREDITS_REASON_DETAILS](state, creditReasonDetails) {
    state.additionalChargeOrder.creditReasonDetails = creditReasonDetails;
  },
  [types.SET_ACCOUNT_ACCOUNTS_FLAGS](state, accounts_flags) {
    state.accounts_flags = accounts_flags;
  },
  [types.SET_ACCOUNT_FLAGS](state, flags) {
    state.flags = flags;
  },
};

const actions = {
  async loadAccount({ commit, state }, accountId) {
    commit(types.SET_DESIRED_ACCOUNT_ID, accountId);
    return this.http.get(`accounts/${accountId}.json`).then(
      response => {
        /*
         * multiple load accounts may be in flight at the same time, and
         * response order is not guaranteed to match call order, so we can
         * verify and only set the account if the response account is the same
         * as the most recent action to load an account
         *
         * without this check, for example, if the first call to load an
         * account takes 3 seconds, but the second call to load a different
         * account takes 2 second, the app will have the second account loaded,
         * but then 2 seconds later be changed to the first account (not what
         * we want!)
         */
        if (state.__desiredAccountId === response.data.id) {
          commit(types.SET_ACCOUNT, response.data);
        }
      },
      error => {
        commit(`errors/${types.SET_ERRORS}`, error.response.data, {
          root: true,
        });
      }
    );
  },
  createAvatarFromUpload({ commit }, { accountId, blob }) {
    return this.http
      .put(`accounts/${accountId}/update_avatar.json`, {
        account: {
          avatar: blob.signed_id,
        },
      })
      .then(response => {
        commit(types.SET_ACCOUNT, response.data);
      })
      .catch(error => {
        console.error(error);
      });
  },
  createCompanyLogoFromUpload({ commit }, { accountId, blob }) {
    return this.http
      .put(`accounts/${accountId}/update_logo.json`, {
        account: {
          logo: blob.signed_id,
        },
      })
      .then(response => {
        commit(types.SET_ACCOUNT, response.data);
      })
      .catch(error => {
        console.error(error);
      });
  },
  createBrandGuideFromUpload({ commit }, { accountId, blob }) {
    return this.http
      .put(`accounts/${accountId}/update_brand_guide.json`, {
        account: {
          guide: blob.signed_id,
        },
      })
      .then(response => {
        commit(types.SET_ACCOUNT, response.data);
      })
      .catch(error => {
        console.error(error);
      });
  },
  updateIsVipStatus({ commit }, { accountId }) {
    return this.http.put(`accounts/${accountId}/toggle_vip_status.json`).then(
      response => {
        commit(types.SET_ACCOUNT, response.data);
      },
      error => {
        commit(`errors/${types.SET_ERRORS}`, error.response.data, {
          root: true,
        });
      }
    );
  },
  transferReservations({ state }, { newAccountId, transferQuotes }) {
    return this.http
      .put(`accounts/${state.id}/transfer_reservations`, {
        new_account_id: newAccountId,
        transfer_quotes: transferQuotes,
      })
      .then(response => {
        return [response.data, null];
      })
      .catch(error => {
        Sentry.captureException('failed to transfer reservations to new user', {
          extra: error,
        });
        return [null, error.response];
      });
  },
  updateAccount({ state, commit }, { accountParams }) {
    return this.http.put(`accounts/${state.id}`, accountParams).then(
      response => {
        commit(types.SET_ACCOUNT, response.data);
      },
      error => {
        commit(`errors/${types.SET_ERRORS}`, error.response.data, {
          root: true,
        });
      }
    );
  },
  loadProServiceAvailability({ commit }, { accountId, start, view }) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `accounts/${accountId}/pro_service_availability?start=${start.toISOString()}`,
          {
            params: { view: view },
          }
        )
        .then(
          response => {
            commit(types.SET_PRO_SERVICE_AVAILABILITY, response.data);
            resolve(response);
          },
          error => {
            Sentry.captureException('failed to load availability', {
              extra: error,
            });
            reject(error);
          }
        );
    });
  },
  setAdditionalChargeItems({ commit }, payload) {
    commit(types.SET_ADD_CREDITS_ITEMS, payload);
  },

  removeAdditionalChargeItem({ commit }, payload) {
    commit(types.REMOVE_ADD_CREDITS_ITEM, payload);
  },

  clearAdditionalChargeItems({ commit }) {
    commit(types.CLEAR_ADD_CREDITS_ITEMS);
    commit(types.SET_ADD_CREDITS_MEMO, '');
    commit(types.SET_ADD_CREDITS_IMMEDIATELY, false);
    commit(types.SET_COMPED_ORDER, false);
    commit(types.SET_COMPED_CREDITS_REASON, '');
    commit(types.SET_COMPED_CREDITS_REASON_DETAILS, '');
  },
  setAdditionalChargeImmediately({ commit }, chargeImmediately) {
    commit(types.SET_ADD_CREDITS_IMMEDIATELY, chargeImmediately);
  },
  setCompedOrder({ commit }, compedOrder) {
    commit(types.SET_COMPED_ORDER, compedOrder);
  },
  setCreditReason({ commit }, creditReason) {
    commit(types.SET_COMPED_CREDITS_REASON, creditReason);
  },
  setAdditionalChargeMemo({ commit }, memo) {
    commit(types.SET_ADD_CREDITS_MEMO, memo);
  },
  async createAdditionalChargeOrder(
    { dispatch, state },
    {
      isShopifyOrder = false,
      stripe = null,
      card = null,
      saveCard = false,
      salesTax = {},
      calculateSalesTax = false,
    }
  ) {
    const {
      products,
      memo,
      chargeImmediately,
      compedOrder,
      creditReason,
      creditReasonDetails,
    } = state.additionalChargeOrder;
    let paymentToken = null;
    if (stripe && card) {
      await stripe.createToken(card).then(
        response => {
          paymentToken = response.token.id;
        },
        error => {
          Sentry.captureException('failed to create stripe token', {
            extra: error,
          });
          throw new Error(error.response.data.message);
        }
      );
    }
    if (stripe && !card) {
      paymentToken = stripe;
    }
    return this.http
      .post(`orders.json`, {
        products,
        memo,
        charge_immediately: chargeImmediately,
        comped_order: compedOrder,
        credit_reason: creditReason,
        credit_reason_details: creditReasonDetails,
        reservation_id: null,
        account_id: state.id,
        save_card: saveCard,
        token: paymentToken,
        sales_tax: salesTax,
        calculate_sales_tax: calculateSalesTax,
      })
      .then(
        response => {
          if (isShopifyOrder) {
            window.top.location.href = response.data.confirmation_url;
          } else {
            dispatch('clearAdditionalChargeItems');
            return [response.data, null];
          }
        },
        error => {
          Sentry.captureException('failed to create additional charge order', {
            extra: error,
          });
          throw new Error(error.response.data.message);
        }
      );
  },
  async createPaymentMethodAdditionalChargeOrder(
    { dispatch, state },
    {
      isShopifyOrder = false,
      paymentMethod = null,
      paymentMethodType = null,
      savePaymentMethod = false,
      salesTax = {},
    }
  ) {
    const {
      products,
      memo,
      chargeImmediately,
      compedOrder,
      creditReason,
      creditReasonDetails,
    } = state.additionalChargeOrder;
    return this.http
      .post(`orders.json`, {
        products,
        memo,
        charge_immediately: chargeImmediately,
        comped_order: compedOrder,
        credit_reason: creditReason,
        credit_reason_details: creditReasonDetails,
        reservation_id: null,
        account_id: state.id,
        save_payment_method: savePaymentMethod,
        payment_method_id: paymentMethod,
        payment_method_type: paymentMethodType,
        sales_tax: salesTax,
      })
      .then(
        response => {
          if (isShopifyOrder) {
            window.top.location.href = response.data.confirmation_url;
          } else {
            dispatch('clearAdditionalChargeItems');
            return [response.data, null];
          }
        },
        error => {
          Sentry.captureException('failed to create additional charge order', {
            extra: error,
          });
          throw new Error(error.response.data?.message ?? 'unknown error');
        }
      );
  },
  cancelOrder(_, { accountId, orderId }) {
    return this.http.delete(`accounts/${accountId}/orders/${orderId}`).then(
      response => {
        return [response.data, null];
      },
      error => {
        Sentry.captureException(
          new Error('failed to cancel additional charge order'),
          {
            extra: error,
          }
        );
        return [null, error.response];
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <li>
    <a
      class="terms-list-item-link u-small--regular"
      target="_blank"
      rel="noopener"
      v-bind="$attrs"
    >
      <slot />
    </a>
  </li>
</template>

<style lang="scss" scoped>
@use '@/variables';

.terms-list-item-link {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-start;
  color: variables.$gray-60;
  transition: color 0.1s ease-out;
  border-radius: 0.3125rem;
  text-decoration: underline;

  &:focus-visible {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  &:hover {
    color: variables.$periwink-blue-70;
  }

  &:active {
    color: variables.$periwink-blue-80;
  }
}
</style>

<template>
  <h3 class="u-body--heavy product-picker-group-title">
    <slot name="default"></slot>
  </h3>
</template>
<style lang="scss" scoped>
@use '@/variables';

.product-picker-group-title {
  color: variables.$black-default;

  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}
</style>

import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { updateTask } from '@/api/questTasks';

/**
 *
 * @param {number | Ref<Object>} taskId
 */
export function useUpdateTask(taskId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => updateTask(unref(taskId), body),
    onSuccess: async response => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.quest(response.quest_id),
      });
    },
  });
}

<script setup>
import { computed } from 'vue';
import { useReservation } from '@/composables/useReservation';
import { useAccount } from '@/composables/useAccount';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: {
    type: [String, Number],
  },
  preferred: {
    type: Object,
    default: null,
  },
  reservationId: {
    type: [String, Number],
  },
  tagline: {
    type: String,
    default: null,
  },
});

const reservationId = computed(() => props.reservationId);
const appliedDiscountTagline = computed(() => props.tagline);
const { isPack, reservation, shootType } = useReservation(reservationId);
const preferredTier = computed(() => {
  return props.preferred?.preferred_tier;
});
const { isDigitalSubscriber } = useAccount(reservation.value?.account_id);

const isValid = computed(() => {
  if (appliedDiscountTagline.value) return true;
  return !(isPack.value || shootType.value !== 'photo');
});

const showBanner = computed(() => {
  return (
    appliedDiscountTagline.value ||
    preferredTier.value === '200' ||
    preferredTier.value === '500'
  );
});

const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');
const hasMembershipIntent = computed(() => {
  if (!phoenixStudioRentalFeeFlag.value) return false;

  return (
    reservation.value?.studio_access?.level === 'tier-two' ||
    isDigitalSubscriber.value
  );
});
</script>
<template>
  <div
    v-if="isValid && showBanner"
    class="auto-applied-discount-banner"
    :class="{ 'membership-discounts': hasMembershipIntent }"
  >
    <template v-if="appliedDiscountTagline">
      <p>{{ appliedDiscountTagline }}</p>
    </template>
    <template v-else-if="preferredTier === '200'">
      <p>you’re saving 10% on all models + upgrades with soona preferred!</p>
    </template>
    <template v-else-if="preferredTier === '500'">
      <p>you’re saving 20% on all models + upgrades with soona preferred!</p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.auto-applied-discount-banner {
  @include variables_fonts.u-small--regular;

  justify-content: space-between;
  align-items: center;
  color: variables.$white-default;
  background-color: variables.$bingo-blue-dark;
  text-align: center;
  padding: 0.5rem 1rem;
}

.membership-discounts {
  background: variables.$gradient-red-to-blue-periwink;
}
</style>

<template>
  <div class="columns is-centered is-vcentered is-mobile">
    <div class="shipping-success-container">
      <div class="shotlist-header has-text-centered">
        <h2 class="title has-text-centered is-size-4-mobile">
          🎉 sweet success! 🎉
        </h2>
        <p
          v-if="returnedShippingDetails.length > 1"
          class="is-italic has-text-centered ml-m mr-m mb-m"
        >
          your packages are ready to ship back to you! want to track them? save
          the tracking numbers or bookmark the USPS pages below!
        </p>
        <p v-else class="is-italic has-text-centered ml-m mr-m mb-m">
          your package is ready to ship back to you! want to track it? save the
          tracking number or bookmark the USPS page below!
        </p>

        <lottie-player
          autoplay
          loop
          src="@images/anim/lottie-shipping-completed.lott"
          style="width: 250px; display: inline-block"
        />
      </div>

      <div class="completed-meta">
        <div
          v-for="(rate, index) in selectedRates"
          :key="rate.id"
          class="is-size-7"
        >
          <ConfirmationPackageSummary
            :rate="rate"
            :shipping-detail="returnedShippingDetails[index]"
            :index="index"
            :total-packages="returnedShippingDetails.length"
          />
        </div>
      </div>

      <div class="mt-m mb-m has-text-centered">
        <a class="soona-link is-size-7" @click.prevent="onBack">
          BACK TO DETAILS
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConfirmationPackageSummary from './ConfirmationPackageSummary.vue';

export default {
  name: 'ReturnCompleted',
  components: { ConfirmationPackageSummary },
  data() {
    return {
      selectedRate: undefined,
    };
  },
  computed: {
    ...mapGetters('currentUser', ['currentAccount']),
    ...mapState({
      returnedShippingDetails: state =>
        state.reservation.returnedShippingDetails,
      selectedRates: state => state.reservation.selectedRates,
    }),
  },
  methods: {
    ...mapActions('reservation', ['newShipment']),
    newShipmentClicked() {
      return this.newShipment();
    },
    overrideDurationTerms(terms) {
      if (
        terms ===
        'Delivery within 1, 2, or 3 days based on where your package started and where it’s being sent.'
      ) {
        return 'delivery guaranteed within 3 business days based on your location';
      } else {
        return terms;
      }
    },
    onBack() {
      this.$router.push({
        name: 'inventory',
        params: { accountId: this.currentAccount.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.completed-meta {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useCapability } from '@/composables/useCapability';

defineProps({
  isEditView: Boolean,
});

const contentUse = ref({
  email: false,
  social_media: false,
  fb_shops: false,
  print: false,
  blog: false,
  internal: false,
  other: false,
  none: false,
});

const columnOne = [
  { label: 'email', value: 'email' },
  { label: 'social media', value: 'social_media' },
  { label: 'fb shops', value: 'fb_shops' },
  { label: 'print', value: 'print' },
];

const columnTwo = [
  { label: 'blog', value: 'blog' },
  { label: 'internal', value: 'internal' },
  { label: 'other', value: 'other' },
  { label: 'none', value: 'none' },
];

const store = useStore();

const account = computed(() => store.state.account);
const accountId = computed(() => store.state.account.id);

const { hasCapability: canManageAccount } = useCapability({
  capability: 'manage_account',
  subjectType: 'account',
  subjectId: accountId,
});

const disabled = computed(() => {
  return (
    !contentUse.value.email &&
    !contentUse.value.social_media &&
    !contentUse.value.fb_shops &&
    !contentUse.value.print &&
    !contentUse.value.blog &&
    !contentUse.value.internal &&
    !contentUse.value.other &&
    !contentUse.value.none
  );
});

const isEditing = ref(false);

const toggleEdit = () => {
  isEditing.value = !isEditing.value;
};
const prefillExistingData = () => {
  let data = account.value.profile_data.alternate_content_use?.data;
  if (data) {
    contentUse.value.email = data.email;
    contentUse.value.social_media = data.social_media;
    contentUse.value.fb_shops = data.fb_shops;
    contentUse.value.print = data.print;
    contentUse.value.blog = data.blog;
    contentUse.value.internal = data.internal;
    contentUse.value.other = data.other;
    contentUse.value.none = data.none;
  }
};

const saveContentSharing = async () => {
  const info = {
    alternate_content_use: {
      data: {
        email: contentUse.value.email,
        social_media: contentUse.value.social_media,
        fb_shops: contentUse.value.fb_shops,
        print: contentUse.value.print,
        blog: contentUse.value.blog,
        internal: contentUse.value.internal,
        other: contentUse.value.other,
        none: contentUse.value.none,
      },
      complete: true,
    },
  };
  try {
    await store.dispatch('account/updateAccount', { accountParams: info });
    toggleEdit();
  } catch (error) {
    if (error.response.status !== 422) {
      Sentry.captureException(
        new Error('Updating account content sharing failed'),
        {
          extra: { error },
        }
      );
    }
  }
};

const skipCard = async () => {
  let info = {
    alternate_content_use: {
      skip: true,
    },
  };
  try {
    await store.dispatch('account/updateAccount', { accountParams: info });
  } catch (error) {
    if (error.response.status !== 422) {
      Sentry.captureException(
        new Error('Skipping account alternate content use failed'),
        {
          extra: { error },
        }
      );
    }
  }
};

watchEffect(() => {
  if (account.value) {
    prefillExistingData();
  }
});
</script>

<template>
  <CompleteProfileCard
    v-if="canManageAccount"
    class="alt-content-use"
    :class="{ 'alt-content-use--edit-view': isEditView }"
    :disabled="disabled"
    :show-next="!isEditView"
    :show-skip="!isEditView"
    @next="saveContentSharing"
    @skip="skipCard"
  >
    <template #heading>where else do you use content?</template>
    <template #subheading>select all that apply</template>
    <fieldset>
      <div class="content-use-column">
        <label v-for="item in columnOne" :key="item.value">
          <input
            v-model="contentUse[item.value]"
            type="checkbox"
            name="sell"
            :value="item.value"
            :disabled="isEditView && !isEditing"
          />
          {{ item.label }}
        </label>
      </div>
      <div class="content-use-column">
        <label v-for="item in columnTwo" :key="item.value">
          <input
            v-model="contentUse[item.value]"
            type="checkbox"
            name="sell"
            :value="item.value"
            :disabled="isEditView && !isEditing"
          />
          {{ item.label }}
        </label>
      </div>
    </fieldset>
    <template v-if="isEditView" #action-btns>
      <SoonaButton v-if="!isEditing" size="medium" @on-click="toggleEdit">
        edit
      </SoonaButton>
      <SoonaButton
        v-else
        class="save-btn"
        size="medium"
        @on-click="saveContentSharing"
      >
        save
      </SoonaButton>
    </template>
  </CompleteProfileCard>
</template>

<style lang="scss" scoped>
@use '@/variables';

.alt-content-use {
  &--edit-view {
    flex-grow: 1;
    max-width: none;
  }

  fieldset {
    display: flex;
    gap: 0.5rem;
    justify-content: space-around;
    padding: 1rem 0;

    input:not(:disabled) {
      cursor: pointer;
    }
  }

  .content-use-column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .save-btn {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }
}
</style>

<script setup>
const inputValue = defineModel({ required: true });
</script>
<template>
  <div>
    <h3 class="u-badge--large">dimensions:</h3>
    <ul>
      <li>
        <input
          id="gif-dimension--as_is"
          v-model="inputValue"
          type="radio"
          name="dimension"
          value="as_is"
        />
        <label for="gif-dimension--as_is" class="u-body--heavy">
          leave as-is
        </label>
      </li>
      <li>
        <input
          id="gif-dimension--square"
          v-model="inputValue"
          type="radio"
          name="dimension"
          value="square"
        />
        <label for="gif-dimension--square" class="u-body--heavy">
          square
          <span class="u-label--small">1x1</span>
        </label>
      </li>
      <li>
        <input
          id="gif-dimension--tall"
          v-model="inputValue"
          type="radio"
          name="dimension"
          value="tall"
        />
        <label for="gif-dimension--tall" class="u-body--heavy">
          tall
          <span class="u-label--small">9x16</span>
        </label>
      </li>
      <li>
        <input
          id="gif-dimension--portrait"
          v-model="inputValue"
          type="radio"
          name="dimension"
          value="portrait"
        />
        <label for="gif-dimension--portrait" class="u-body--heavy">
          portrait
          <span class="u-label--small">4x5</span>
        </label>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
h3 {
  padding-bottom: 1rem;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  li {
    display: flex;
    gap: 0.5rem;

    input:focus-visible,
    label:focus-visible {
      outline: none;
    }

    label {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      gap: 0.25rem;
    }
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import BookingLayout from './BookingLayout.vue';
import faqData from 'src/components/booking/v3/p2/add-models/faq.json';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaTagOptionCard from 'src/components/ui_library/soona_tag_option_card/SoonaTagOptionCard.vue';
import { useReservation } from '@/composables/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityError } from 'src/composables/usePriorityError';
import TagRequirementDialog from '@/components/booking/v3/p2/TagRequirementDialog.vue';
import { useCapability } from '@/composables/useCapability';

const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const reservationId = computed(() => props.reservationId);

const { linkClicked, itemSelected } = useBaseEvents();

let buttonsLoading = ref([]);

// api calls
const {
  downPaymentDiscount,
  reservation,
  requiredTags,
  recommendedTagIds,
  tagRequirements,
  proServiceSlugForSize,
  shootType,
  error,
} = useReservation(reservationId);

const discountId = computed(() => downPaymentDiscount.value?.id);

const {
  data: modelData,
  isLoading: isLoadingOptions,
  isSuccess: isModelOptionsSuccess,
  error: resTagsError,
} = useReservationTags(
  reservationId,
  proServiceSlugForSize,
  'pro_service_model',
  discountId
);

const modelOptions = computed(() => {
  return modelData.value?.options || [];
});
const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(reservationId);

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(reservationId);

const priorityError = usePriorityError(
  removeError,
  addError,
  resTagsError,
  error
);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

function isRemovable(tag) {
  return requiredTags.value.indexOf(tag.id) === -1 || isFtSoonaStaff.value;
}

function displayModalBeforeRemoved(tag) {
  return recommendedTagIds.value.includes(tag.id);
}

// computed
const isBusy = computed(() => {
  return (
    buttonsLoading.value?.length >= 1 || isAdding.value || isRemoving.value
  );
});
const disabled = computed(() => isLoadingOptions.value || isBusy.value);
const isStepValid = computed(() => !isBusy.value);

// methods
function isLoading(id) {
  return buttonsLoading.value?.includes(id);
}

function removeFromButtonList(option) {
  buttonsLoading.value = buttonsLoading.value?.filter(o => o !== option.tag.id);
}

function removeTag(option) {
  removeReservationTag(
    {
      reservationTagId: option.reservation_tag.id,
      deleteObsoleted: true,
    },
    {
      onSettled: () => removeFromButtonList(option),
    }
  );
}

const isTagRequirementDialogOpen = ref(false);
const tagRequirement = ref({});
const currentOption = ref({});

function handleOpenTagRequirementDialog(option, requirement) {
  linkClicked({
    context: 'booking',
    subContext: 'video type page',
    linkLabel: option?.tag?.title,
    linkHref: '#remove-from-shoot',
  });
  tagRequirement.value = requirement;
  currentOption.value = option;
  isTagRequirementDialogOpen.value = true;
}

function handleCloseTagRequirementDialog() {
  removeFromButtonList(currentOption.value);
  tagRequirement.value = {};
  currentOption.value = {};
  isTagRequirementDialogOpen.value = false;
}

function onOptionChange(option, quantity) {
  if (buttonsLoading.value?.includes(option.tag.id) || isBusy.value) return;

  buttonsLoading.value = [...buttonsLoading.value, option.tag.id];

  const beingSelected = !option.selected;

  if (beingSelected) {
    addReservationTag(
      { tagId: option.tag.id, quantity: quantity },
      {
        onSettled: () => removeFromButtonList(option),
        onSuccess: () => {
          itemSelected({
            context: 'booking',
            subContext: 'models',
            itemId: option.product.id,
            itemLabel: option.tag.title,
            itemPrice: option.tag.price_description,
            itemQuantity: quantity,
          });
        },
      }
    );
  } else {
    const tagRequirement = tagRequirements.value.find(
      tr =>
        tr.required_tag_id === option.tag.id &&
        tr.requirement_type === 'recommended'
    );
    if (tagRequirement) {
      handleOpenTagRequirementDialog(option, tagRequirement);
    } else {
      removeTag(option);
    }
  }
}
function removeTagFromReservation() {
  removeTag(currentOption.value);
  isTagRequirementDialogOpen.value = false;
  currentOption.value = {};
}
// navigation
function onBackClick() {
  props.onBack();
}

const currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});

const isComplete = computed(() => {
  return currentStep.value?.complete;
});

function onNextClick() {
  if (!isComplete.value) {
    addReservationTag(
      { tagId: modelData.value.skippable_tag_id },
      {
        onSuccess: () => {
          props.onNext();
        },
      }
    );
  } else {
    props.onNext();
  }
}
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="add-models prebooking-transition__content">
        <SoonaLoading
          v-if="!modelOptions"
          :is-loading="!modelOptions"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading>
          add a model to your {{ shootType }} shoot
          <template #subtitle>
            price is based on the length of your shoot. models can be selected
            after you complete booking.
          </template>
        </SoonaHeading>
        <template v-if="isModelOptionsSuccess">
          <SoonaTagOptionCard
            v-for="option in modelOptions"
            :key="option.tag.id"
            :description="option.tag.description"
            :modal-description="option.tag.description_long"
            :button-disabled="disabled || !isRemovable(option.tag)"
            :display-modal-before-removed="
              displayModalBeforeRemoved(option.tag)
            "
            :has-carousel="false"
            :has-more-details="true"
            :has-quantity-incrementor="true"
            :has-shadow="true"
            :primary-image="option.tag.image_url"
            :secondary-images="option.tag.image_secondary_urls"
            :tertiary-images="option.tag.image_tertiary_urls"
            :in-bag="option.selected"
            :is-loading="isLoading(option.tag.id)"
            :max-quantity="10"
            :min-quantity="1"
            :price-info="option.product?.price_info"
            :tag-id="option.tag.id"
            :title="option.tag.title"
            title-element="h2"
            modal-title-element="h3"
            :has-pro-service-comparison="option.tag.has_comparison_image_tags"
            :pro-service-name="option.tag.title"
            :modal-subtitle="option.tag.sub_title_alt"
            :modal-subcopy="option.tag.summary"
            @action="onOptionChange(option, $event)"
          />
        </template>
        <SoonaFaq :faq-tab-list="faqData" />
      </div>
      <TagRequirementDialog
        v-if="isTagRequirementDialogOpen"
        :reservation-id="reservationId"
        :adding-product="false"
        :tag-requirement="tagRequirement"
        segment-context="booking"
        segment-sub-context="add models page"
        @cancel-clicked="handleCloseTagRequirementDialog"
        @confirm-clicked="removeTagFromReservation"
      ></TagRequirementDialog>
    </BookingLayout>
  </transition>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import CarouselLoading from '@/components/platform-home/CarouselLoading.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaImageCard from '@/components/ui_library/SoonaImageCard.vue';
import { useGetInspiredItems } from '@/queries/useDiscoveryItems';

const route = useRoute();
const { linkClicked } = useBaseEvents();

const { data: inspiredItems, isLoading } = useGetInspiredItems();

function openLink(linkLabel, linkHref) {
  linkClicked({
    context: route.meta.context,
    subContext: 'get inspired carousel',
    linkLabel: linkLabel,
    linkHref: linkHref,
  });

  window.open(linkHref, '_blank');
}
</script>
<template>
  <section class="get-inspired">
    <slot name="title">
      <h3 class="u-title--heavy">get inspired</h3>
    </slot>
    <CarouselLoading v-if="isLoading" />
    <PageContainerOffset v-else>
      <SoonaSlider
        class="get-inspired__carousel"
        tag-name="ul"
        aria-labelledby="discovery-get-inspired-slider"
      >
        <SoonaImageCard
          v-for="item in inspiredItems"
          :key="item.id"
          class="get-inspired__item"
          @click="openLink(item.title, item.link)"
        >
          <img :src="item.image" :alt="item.image_alt || ''" />
          <template #caption>{{ item.title }}</template>
        </SoonaImageCard>
      </SoonaSlider>
    </PageContainerOffset>
  </section>
</template>
<style lang="scss" scoped>
.get-inspired {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
</style>

import { payAddOnOrder } from '@/api/accounts';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

export function usePayAddOnOrder(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => {
      return payAddOnOrder(unref(accountId), body);
    },
    onSuccess: async () => {
      const invalidations = [
        queryClient.invalidateQueries({ queryKey: queryKeys.bag() }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(accountId),
        }),
      ];

      await Promise.all(invalidations);
    },
  });
}

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param { string | number } accountId
 */
export function useUpdateAlbumCollection(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ collectionId, body }) => {
      const response = await http.put(
        `/accounts/${toValue(accountId)}/album_collections/${toValue(
          collectionId
        )}`,
        {
          album_collection: body,
        }
      );
      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collectionsDA(accountId, 'album_collection'),
        }),
      ]);
    },
  });
}

<script setup>
import { computed, ref, watch } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import {
  useGetCatalogItemAmazonListing,
  useCatalogItem,
} from '@/queries/useCatalog';
import { usePriorityError } from 'src/composables/usePriorityError';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  listingUpdating: {
    type: Boolean,
    required: true,
  },
  catalogItemId: {
    type: [Number, String],
    required: true,
  },
  file: {
    type: Object,
    required: true,
  },
  disabledSlots: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['slot-selected']);
const selectedImageSlot = ref({});
const accountId = computed(() => props.accountId);
const listingUpdating = computed(() => props.listingUpdating);
const catalogItemId = computed(() => props.catalogItemId);
const file = computed(() => props.file);
const fileExternalUrl = computed(
  () => file.value?.external_s3?.url ?? file.value?.external_s3_url ?? ''
);
const filePreviewUrl = computed(
  () => file.value?.preview?.url ?? file.value?.preview_url ?? ''
);

const {
  data: amazonListingData,
  isLoading: isAmazonListingLoading,
  error: amazonListingError,
} = useGetCatalogItemAmazonListing(accountId, catalogItemId);

const {
  data: catalogItemData,
  isLoading: isCatalogItemLoading,
  error: catalogItemError,
} = useCatalogItem(accountId, catalogItemId);

const imageSlots = [
  'main_product_image_locator',
  'other_product_image_locator_1',
  'other_product_image_locator_2',
  'other_product_image_locator_3',
  'other_product_image_locator_4',
  'other_product_image_locator_5',
  'other_product_image_locator_6',
  'other_product_image_locator_7',
  'other_product_image_locator_8',
  'swatch_product_image_locator',
];

const listingImages = computed(() => {
  let listingData = amazonListingData.value;
  if (listingData) {
    if (listingData.data.test_listing) {
      const images = listingData.data.images;
      return imageSlots.slice(0, images.length).reduce((acc, slot, index) => {
        acc[slot] = images[index];
        return acc;
      }, {});
    } else {
      let imagesHash = {};
      let images = listingData.data?.images ?? [];
      images.forEach(
        image => (imagesHash[Object.keys(image)[0]] = Object.values(image)[0])
      );
      return imagesHash;
    }
  }

  return {};
});

const catalogItem = computed(() => catalogItemData.value);

const publishOperations = computed(() => {
  let operations = [];
  let slots = Object.keys(selectedImageSlot.value);
  slots.forEach(slot => {
    let operation = {
      op: listingImages.value[slot] ? 'replace' : 'add',
      slot: slot,
      url: selectedImageSlot.value[slot][1],
    };
    operations.push(operation);
  });
  return operations;
});

function selectImageSlot(slotPosition) {
  selectedImageSlot.value = {};
  selectedImageSlot.value[imageSlots[slotPosition]] = [
    filePreviewUrl.value,
    fileExternalUrl.value,
  ];
}

watch(publishOperations, operations => {
  emit('slot-selected', operations);
});

const slotTitle = index => {
  if (index === 0) {
    return 'main';
  } else if (index === 9) {
    return 'swatch';
  }
  return index + 1;
};

const isLoading = computed(
  () =>
    isAmazonListingLoading.value ||
    isCatalogItemLoading.value ||
    listingUpdating.value
);

const priorityError = usePriorityError(amazonListingError, catalogItemError);
</script>

<template>
  <div class="amazon-publish-image-selector">
    <template v-if="isLoading">
      <SoonaSkeleton class="amazon-publish-image-selector__loading-slot" />
    </template>
    <SoonaError v-else-if="priorityError">
      we are having trouble retrieving your Amazon product information. please
      try again later.
    </SoonaError>
    <div v-else class="amazon-publish-image-selector__image-preview">
      <div class="amazon-publish-image-selector__image-wrapper">
        <p class="amazon-publish-image-selector__product-name">
          {{ catalogItem?.name ?? '' }}
        </p>
        <img
          class="amazon-publish-image-selector__image"
          :src="filePreviewUrl"
          alt=""
        />
        <p class="amazon-publish-image-selector__filename">
          {{ file?.title ?? '' }}
        </p>
      </div>
    </div>
    <div
      v-if="!priorityError"
      class="amazon-publish-image-selector__image-slots-wrapper"
    >
      <h3 class="amazon-publish-image-selector__title">
        select an image slot in your Amazon gallery
      </h3>
      <p class="amazon-publish-image-selector__subtitle">
        your new asset can fill an empty slot or replace an existing image.
      </p>
      <div class="amazon-publish-image-selector__image-slots">
        <div
          v-for="(slot, index) in imageSlots"
          :key="index"
          class="amazon-publish-image-selector__image-slot"
        >
          <div v-if="isLoading">
            <div class="amazon-publish-image-selector__loading-slot-wrapper">
              <SoonaSkeleton
                class="amazon-publish-image-selector__loading-slot"
              />
            </div>
          </div>
          <div v-else>
            <button
              v-if="selectedImageSlot[slot] || listingImages[slot]"
              class="amazon-publish-image-selector__slot-image-wrapper u-button-reset"
              :class="{
                'amazon-publish-image-selector__slot-image-wrapper--disabled':
                  disabledSlots.includes(index),
                'amazon-publish-image-selector__slot-image-wrapper--selected':
                  selectedImageSlot[slot],
              }"
              :disabled="disabledSlots.includes(index)"
              @click="selectImageSlot(index)"
            >
              <img
                class="amazon-publish-image-selector__slot-image"
                :src="selectedImageSlot[slot]?.[0] || listingImages[slot]"
              />
              <div
                v-if="listingImages[slot] && !selectedImageSlot[slot]"
                class="amazon-publish-image-selector__replace-button-wrapper"
              >
                <span
                  class="amazon-publish-image-selector__replace-button u-label--heavy"
                >
                  replace
                </span>
              </div>
            </button>
            <button
              v-else
              class="amazon-publish-image-selector__default-image u-button-reset"
              :class="{
                'amazon-publish-image-selector__default-image--disabled':
                  disabledSlots.includes(index),
              }"
              :disabled="disabledSlots.includes(index)"
              @click="selectImageSlot(index)"
            >
              <SoonaIcon name="plus" />
            </button>
          </div>
          <p class="amazon-publish-image-selector__slot-title">
            {{ slotTitle(index) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.amazon-publish-image-selector {
  &__image-preview {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: variables.$screen-sm-min) {
      flex-direction: row;
    }
  }

  &__icon {
    flex: 1;
    color: variables.$gray-60;
    display: none;

    @media (min-width: variables.$screen-sm-min) {
      display: block;
    }
  }

  &__product-name {
    font-weight: 700;
  }

  &__product-name {
    flex: 5;
  }

  &__image-wrapper {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
  }

  &__image {
    max-height: 10rem;
  }

  &__image-slots-wrapper {
    margin-top: 2rem;
  }

  &__title {
    font-weight: 800;
  }

  &__subtitle {
    color: variables.$gray-60;
  }

  &__image-slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    gap: 1rem;
  }

  &__image-slot {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;

    @media (min-width: variables.$screen-xs-min) {
      width: 45%;
    }

    @media (min-width: variables.$screen-sm-min) {
      width: 31.5%;
    }
  }

  &__default-image {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variables.$gray-10;
    border: 0.125rem dashed variables.$gray-40;
    color: variables.$gray-60;

    &:hover {
      background-color: variables.$gray-20;
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__slot-image-wrapper {
    position: relative;

    &:hover,
    &:focus-visible {
      .amazon-publish-image-selector__replace-button-wrapper {
        opacity: 1;
      }
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &--selected {
      outline-color: variables.$periwink-blue-60;
      outline-style: solid;
      outline-width: 0.125rem;
    }
  }

  &__replace-button-wrapper {
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: 0.25s ease;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.3125rem;
  }

  &__replace-button {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;

    // copy of secondary-black SoonaButton styles
    padding: 0.375rem 0.9375rem;
    border: 0.0625rem solid variables.$black-default;
    border-radius: 0.3125rem;
    background-color: variables.$white-default;
    color: variables.$black-default;
  }

  &__slot-image,
  &__default-image,
  &__loading-slot {
    height: 10rem;
    border-radius: 0.3125rem;
    transition: 0.5s ease;
    object-fit: contain;
  }

  &__slot-title {
    font-weight: 800;
  }

  &__slot-title,
  &__filename {
    font-size: 0.875rem;
  }
}
</style>

import * as Sentry from '@sentry/browser';
import { getGlobalUserId } from '../user/globalUser';
import { getCookie } from 'src/lib/global/storage';

const facebookPixelProps = {
  fbc: getCookie('_fbc'),
  fbp: getCookie('_fbp'),
};

export function getAnonymousId() {
  if (window.analytics && window.analytics.user) {
    return window.analytics.user().anonymousId();
  } else {
    return null;
  }
}

export function identify(userId, attributes) {
  window.analytics && window.analytics.identify(userId, attributes);
}

export function group(groupId, account) {
  window.analytics &&
    window.analytics.group(groupId, {
      id: account?.ulid ? account.ulid : null,
      name: account?.name ? account.name : null,
      book_soona_account_id: account?.id ? account.id : null,
    });
}

export function groupV2(groupId, account) {
  let traits = {
    id: account.ulid,
    name: account.name,
    bookSoonaAccountId: account.id,
  };
  let noNullTraits = Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(traits).filter(([_, v]) => v != null)
  );

  window.analytics &&
    window.analytics.group(groupId, {
      traits: noNullTraits,
    });
}

export function page(pageName, pageProperties = {}) {
  pageProperties = { ...pageProperties, ...facebookPixelProps };
  window.analytics && window.analytics.page(pageName, pageProperties);
}

export function structuredTags(tags, tagCategories) {
  let structuredData = {};

  if (tags && tagCategories) {
    tags.forEach(tag => {
      let category = tagCategories.find(tc => tc.id === tag.tag_category_id);
      if (category) {
        let category_title = category.title
          .trim()
          .toLowerCase()
          .replace(/\s/g, '_');

        if (Array.isArray(structuredData[category_title])) {
          structuredData[category_title].push(tag.title);
        } else {
          structuredData[category_title] = [tag.title];
        }
      } else {
        Sentry.captureException(
          new Error(
            `could not find tag ${tag.id} - ${tag.title} in tagCategories`
          ),
          {
            extra: { tag, tagCategories },
          }
        );
      }
    });
  }

  return structuredData;
}

export function reservationProperties({
  reservation = null,
  tags = null,
  tagCategories = null,
  location = null,
  pack = null,
}) {
  return {
    reservation_id: reservation?.id ? reservation.id : null,
    reservation_type: reservation?.reservation_type
      ? reservation.reservation_type
      : null,
    start: reservation?.start ? reservation.start : null,
    location_name: location?.name ? location.name : null,
    tags: structuredTags(tags, tagCategories),
    pack_details: {
      is_a_pack: !!pack,
      id: pack?.id ? pack.id : null,
      name: pack?.name ? pack.name : null,
      pack_type: pack?.category ? pack.category : null,
    },
  };
}

export function reservationPropertiesV2({
  reservation = null,
  tags = null,
  tagCategories = null,
  location = null,
  pack = null,
}) {
  return {
    reservationId: reservation?.id ? reservation.id : null,
    reservationType: reservation?.reservation_type
      ? reservation.reservation_type
      : null,
    start: reservation?.start ? reservation.start : null,
    locationName: location?.name ? location.name : null,
    locationId: location?.id ? location.id : null,
    tags: structuredTags(tags, tagCategories),
    packDetails: {
      isAPack: !!pack,
      id: pack?.id ? pack.id : null,
      name: pack?.name ? pack.name : null,
      packType: pack?.category ? pack.category : null,
    },
  };
}

/**
 * @param {String} eventName For Optimizely metrics, you must create the event in Optimizely and the names must match
 * @param {Object} eventProperties see userAndAccountTraits() for traits
 * @param {Object} authedUser
 * @param {string} [authedUser.ulid]
 * */
export function track(eventName, eventProperties = {}, authedUser = {}) {
  eventProperties = { ...eventProperties, ...facebookPixelProps };
  window.analytics &&
    window.analytics.track(eventName, eventProperties, {
      Optimizely: { userId: getGlobalUserId(authedUser) },
    });
}

export function userAndAccountTraits(user) {
  return {
    user_id: user?.userULID ? user.userULID : null,
    email: user?.email ? user.email : null,
    account_id: user?.accountULID ? user.accountULID : null,
    book_soona_account_id: user?.accountId ? user.accountId : null,
    book_soona_user_id: user?.userID ? user.userID : null,
    employee:
      user.userULID && user?.capabilities ? user.capabilities.length > 0 : null,
  };
}

import { useQuery } from '@tanstack/vue-query';
import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param {string | Ref<string>} collectionId
 * @param {string | Ref<string>} cdaId
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns UseMutationReturnType<*>
 */
export function useReEditsCollectionDigitalAsset(
  collectionId,
  cdaId,
  queryOptions = null
) {
  return useQuery({
    queryKey: queryKeys.collectionAsset(
      collectionId,
      're_edits_collection',
      cdaId
    ),
    queryFn: async () => {
      const response = await http.get(
        `/collections/${toValue(collectionId)}/re_edits_collection_digital_assets/${toValue(cdaId)}`
      );
      return response.data;
    },
    ...queryOptions,
  });
}

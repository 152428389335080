import { http } from '@/config/vue-axios';

export const featureFlagsQueryConfig = {
  queryKey: ['feature-flags'],
  queryFn: async ({ signal }) => {
    const response = await http.get('/users/me/features', {
      signal,
    });
    return response.data;
  },
  staleTime: 60 * 60 * 1000,
  // 72 hours: something huge, but not Infinity, so that useMe resetting auto applies here
  gcTime: 72 * 60 * 60 * 1000,
};

import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {Object} [params]
 * @param {string | number} [params.accountId]
 * @param {'draft' | 'live' | past' | 'suggested' | 'upcoming'} [params.filter]
 * @param {string} [params.query]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {AbortSignal} [signal]
 */
export async function getReservations(
  { accountId, filter, query, currentPage, itemsPerPage } = {},
  signal
) {
  const params = {};

  if (accountId) params['account_id'] = accountId;
  if (filter) params['filter'] = filter;
  if (query) params['query'] = query;
  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;

  const response = await http.get(`/reservations.json`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    reservations: response.data,
  };
}

/**
 *
 * @param {Object} [params]
 * @param {string | number} [params.accountId]
 * @param {string} [params.timezone]
 * @param {AbortSignal} [signal]
 */
export async function getActionItems({ accountId, timezone } = {}, signal) {
  const params = {};

  if (accountId) params['account_id'] = accountId;
  if (timezone) params['timezone'] = timezone;

  const response = await http.get(`/reservations/action_items.json`, {
    params,
    signal,
  });

  return response.data;
}

/**
 * @param {string | number} reservationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function removeDraft(reservationId) {
  return http.put(`/reservations/${reservationId}/remove_draft`);
}

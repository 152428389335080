<script setup>
import 'vue-advanced-cropper/dist/style.css';
import { computed, ref, watch } from 'vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId, selectorData } = storeToRefs(mediaEditorStore);
const scaleFn = computed(() => selectorData.value?.scaleFn);

const { mediaUrl, mediaWidth, mediaHeight } = useMediaEditorDigitalAsset(
  assetAccountId,
  assetId
);

const drawCheckeredBackground = (can, nRow, nCol) => {
  var ctx = can.getContext('2d');
  var w = can.width;
  var h = can.height;

  nRow = nRow || 8; // default number of rows
  nCol = nCol || 8; // default number of columns

  w /= nCol; // width of a block
  h /= nRow; // height of a block

  // set fill color to dark gray
  ctx.fillStyle = '#f0f0f0';

  for (var i = 0; i < nRow; ++i) {
    for (var j = 0, col = nCol / 2; j < col; ++j) {
      ctx.rect(2 * j * w + (i % 2 ? 0 : w), i * h, w, h);
    }
  }

  ctx.fill();
};

const extendCanvas = ref();
const imgRef = ref();
const canvasCtx = computed(() => extendCanvas.value?.getContext('2d'));
const drawImage = () => {
  if (!scaleFn.value) {
    console.warn('no scaleFn found');
    return;
  }

  const { canvasWidth, canvasHeight, xcoord, ycoord } = scaleFn.value;

  extendCanvas.value.width = canvasWidth;
  extendCanvas.value.height = canvasHeight;

  drawCheckeredBackground(extendCanvas.value, 24, 24);

  canvasCtx.value.drawImage(
    imgRef.value,
    xcoord,
    ycoord,
    mediaWidth.value,
    mediaHeight.value
  );
};

watch(
  mediaUrl,
  () => {
    const img = new Image();
    imgRef.value = img;
    img.src = mediaUrl.value;
    imgRef.value = img;
    img.onload = () => drawImage();
  },
  { immediate: true }
);

watch([selectorData, scaleFn], () => drawImage());
</script>

<template>
  <canvas ref="extendCanvas" class="extend"></canvas>
</template>

<style scoped lang="scss">
.extend {
  display: block;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
</style>

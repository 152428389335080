<script setup>
import { inject } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

defineProps({
  ariaLabel: {
    type: String,
    default: 'search and filter',
  },
  variation: {
    default: 'secondary-periwink',
    type: String,
    validator: function (value) {
      return [
        'primary',
        'solid-black',
        'secondary-gray',
        'secondary-periwink',
      ].includes(value);
    },
  },
  size: {
    default: 'large',
    type: String,
    validator: function (value) {
      return ['large', 'medium', 'small'].includes(value);
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { toggleCombobox, ariaActivedescendantID, listboxId, open } =
  inject('comboboxContext');
</script>

<template>
  <SoonaButton
    tabindex="0"
    role="combobox"
    class="combo-input"
    aria-haspopup="listbox"
    :aria-expanded="open"
    :aria-label="ariaLabel"
    :aria-controls="listboxId"
    :aria-activedescendant="ariaActivedescendantID"
    :size="size"
    :disabled="disabled"
    :variation="variation"
    @click="toggleCombobox"
  >
    <slot />
  </SoonaButton>
</template>
/>

<script setup>
import { computed } from 'vue';
import SoonaSelectedChip from 'src/components/SoonaSelectedChip.vue';

const props = defineProps({
  onClick: {
    default: () => {},
    required: false,
    tye: Function,
  },
  selectedOptions: {
    default: () => [],
    required: false,
    tye: Array,
  },
});

const displaySelectedOptions = computed(() => {
  return props.selectedOptions?.length > 0;
});
</script>
<template>
  <div
    class="selected-options"
    :class="{
      'selected-options--hidden': !displaySelectedOptions,
    }"
  >
    <span class="selected-options__label"> selected: </span>
    <SoonaSelectedChip
      v-for="(option, i) in selectedOptions"
      :key="i"
      class="selected-options__chip"
      :title="option.tag.title"
      :selected-tag="option"
      @on-click="onClick"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.selected-options {
  min-height: 2.75rem;
  padding-bottom: 0.75rem;
  width: 100%;

  &--hidden {
    visibility: hidden;
  }

  &__label {
    @include variables_fonts.u-body--regular;
  }

  &__chip {
    @include variables_fonts.u-label--regular;

    margin-right: 0.25rem;
  }
}
</style>

import { unref } from 'vue';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getAssignedReservations } from '../api/assignedReservations';

/**
 *
 * @param {Object} [params]
 * @param {number} [params.userId]
 * @param {number} [params.itemsPerPage]
 */
export function useGetAssignedReservations(userId, { itemsPerPage } = {}) {
  return useInfiniteQuery({
    queryKey: queryKeys.assignedReservations(userId),
    queryFn: ({ signal, pageParam = { currentPage: 1, itemsPerPage } }) =>
      getAssignedReservations(
        unref(userId),
        {
          currentPage: pageParam.currentPage,
          itemsPerPage: pageParam.itemsPerPage,
        },
        signal
      ),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      if (
        lastPage.pagination.currentPage + 1 >
        lastPage.pagination.totalPages
      ) {
        return null;
      } else {
        return {
          currentPage: lastPage.pagination.currentPage + 1,
          itemsPerPage: lastPage.pagination.itemsPerPage,
        };
      }
    },
  });
}

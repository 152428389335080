<script setup>
import { inject } from 'vue';
import SoonaToast from './SoonaToast.vue';
import { SOONA_TOAST_PROVIDE_KEY } from './soona-toast-provide-key';

defineProps({
  to: {
    type: [Object, String],
    default: 'body',
  },
});

const { list, removeToast } = inject(SOONA_TOAST_PROVIDE_KEY);
</script>

<template>
  <slot />
  <Teleport :to="to">
    <TransitionGroup
      name="soona-toasts"
      class="toast-provider"
      :class="{
        'toast-provider--empty': list.length === 0,
      }"
      tag="section"
    >
      <SoonaToast
        v-for="toast in list"
        :key="toast.id"
        :variation="toast.variation"
        :icon-name="toast.iconName"
        :icon-color="toast.iconColor"
        :icon-bg="toast.iconBg"
        :no-dismiss-button="toast.noDismissButton"
        :no-timeout="toast.noTimeout"
        @close="removeToast(toast.id)"
      >
        {{ toast.message }}
        <template v-if="toast.subtext" #subtext>{{ toast.subtext }}</template>
      </SoonaToast>
    </TransitionGroup>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';

@keyframes move-up {
  0% {
    transform: translateY(1rem);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes move-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0.5rem);
  }
}

.toast-provider {
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: calc(100% - 1rem);
  max-width: 21.75rem;

  &--empty {
    pointer-events: none;
  }
}

.soona-toasts-enter-active {
  animation:
    0.3s cubic-bezier(0.34, 1.56, 0.64, 1) both move-up,
    0.15s ease-out both k-fade-in;
}

.soona-toasts-leave-active {
  animation:
    0.15s ease-out both move-down,
    0.15s ease-out both k-fade-out;
}

.soona-toasts-move {
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@media (prefers-reduced-motion: reduce) {
  .soona-toasts-enter-active {
    animation: 0.15s ease-out both k-fade-in;
  }

  .soona-toasts-leave-active {
    animation: 0.15s ease-out both k-fade-out;
  }

  .soona-toasts-move {
    transition: none;
  }
}

.soona-toasts-leave-active {
  position: absolute;
  z-index: -1;
  left: 0;
  width: 100%;

  &:only-child {
    bottom: 0;
  }

  &:first-child:not(:only-child) {
    bottom: calc(100% + 0.5rem);
  }
}
</style>

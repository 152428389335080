import { ref } from 'vue';
import { useClipboard, useTimeoutFn } from '@vueuse/core';

export function useCopy() {
  let copySuccess = ref(false);
  const { copy, text } = useClipboard();

  const { start } = useTimeoutFn(() => {
    copySuccess.value = false;
  }, 3000);

  const copyText = text => {
    copy(text).then(() => {
      copySuccess.value = true;
      start();
    });
  };

  return { copySuccess, copyText, copiedText: text };
}

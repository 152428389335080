<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import EnterpriseTemplatesBanner from 'images/enterprise/templates_center_asset.png';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});
</script>

<template>
  <aside class="me-ai-templates">
    <h3 class="u-title--heavy">my AI templates</h3>
    <div class="me-ai-templates__wrapper">
      <img
        :src="EnterpriseTemplatesBanner"
        alt="hey dude logo"
        class="me-ai-templates__image"
      />
      <SoonaButton
        size="medium"
        element="router-link"
        variation="solid-black"
        :to="`/account/${props.accountId}/media-editor/templates`"
        @on-click="redirectToMokker"
      >
        browse templates <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
@use '@/variables';

.me-ai-templates {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__wrapper {
    align-items: center;
    background: linear-gradient(
      100deg,
      variables.$tangerine-40 14.23%,
      variables.$friendly-red-50 89.52%
    );

    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    outline: 0.0625rem solid variables.$gray-30;
    outline-offset: -0.0625rem;
    padding: 1.5rem;
  }

  &__image {
    width: 50%;
  }
}
</style>

import { http } from '../config/vue-axios';
import { mapPaginationHeaders } from '../lib/api-transforms';

/**
 *
 * @param {Object} [params]
 * @param {number} [params.userId]
 * @param {string} [params.type]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {AbortSignal} [signal]
 */
export async function getAssignedReservations(
  userId,
  { type, currentPage, itemsPerPage } = {},
  signal
) {
  const params = {};

  if (type) params['type'] = type;
  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;

  const response = await http.get(
    `users/${userId}/assigned_reservations.json`,
    {
      params,
      signal,
    }
  );

  return {
    pagination: mapPaginationHeaders(response.headers),
    reservations: response.data,
  };
}

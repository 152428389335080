import { computed } from 'vue';
import { useReservationSchedulingPreference } from '@/queries/scheduling/useReservationSchedulingPreference';
import { useAccountSchedulingPreference } from '@/queries/scheduling/useAccountSchedulingPreference';

export function useSchedulingPreferences(accountId, reservationId) {
  const {
    data: schedulingPreference,
    isLoading: schedulingPreferenceLoading,
    error: schedulingPreferenceError,
  } = useReservationSchedulingPreference(reservationId, {
    enabled: computed(() => !!reservationId?.value),
  });

  const {
    data: accountPreference,
    isLoading: accountPreferenceLoading,
    error: accountPreferenceError,
  } = useAccountSchedulingPreference(accountId, {
    enabled: computed(() => !!accountId?.value),
  });

  const hasAccountPreference = computed(() => {
    return !!accountPreference.value?.id;
  });

  const isCustomPreference = computed(() => {
    return (
      schedulingPreference.value?.subject_type === 'Reservation' &&
      hasAccountPreference.value
    );
  });

  const subjectType = computed(() => {
    return schedulingPreference.value?.subject_type;
  });

  const schedulingPreferenceLookup = {
    Account: {
      copy: 'default availability',
      class: 'check-icon',
      iconName: 'circle-check-solid',
    },
    Custom: {
      copy: 'custom availability',
      class: 'info-icon',
      iconName: 'circle-information-solid',
    },
    Reservation: {
      copy: 'custom availability',
      class: 'info-icon',
      iconName: 'circle-information-solid',
    },
  };

  const days = [
    { label: 'monday', value: 'monday' },
    { label: 'tuesday', value: 'tuesday' },
    { label: 'wednesday', value: 'wednesday' },
    { label: 'thursday', value: 'thursday' },
    { label: 'friday', value: 'friday' },
  ];

  const slots = [
    { label: 'morning: 8am - 12pm', value: 'morning' },
    { label: 'midday: 11am - 3pm', value: 'afternoon' },
    { label: 'afternoon: 2pm - 6pm', value: 'evening' },
  ];

  const timeZoneOptions = [
    { label: 'eastern (ET)', value: 'eastern (ET)' },
    { label: 'central (CT)', value: 'central (CT)' },
    { label: 'mountain (MT)', value: 'mountain (MT)' },
    { label: 'pacific (PT)', value: 'pacific (PT)' },
  ];
  return {
    days,
    slots,
    timeZoneOptions,
    schedulingPreferenceLookup,
    schedulingPreference,
    schedulingPreferenceLoading,
    schedulingPreferenceError,
    accountPreference,
    accountPreferenceLoading,
    accountPreferenceError,
    hasAccountPreference,
    isCustomPreference,
    subjectType,
  };
}

<script setup>
import { computed, toRef } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaExpansionPanel from '@/components/ui_library/SoonaExpansionPanel.vue';
import AccordionHeaderIcon from '@/components/user/anytime/listing_insights/listing_detail/accordions/AccordionHeaderIcon.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaBlogPostLink from '@/components/ui_library/SoonaBlogPostLink.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { Gray20 } from 'src/variables.module.scss';
import VideoAccordionAmazonContent from './VideoAccordionAmazonContent.vue';
import VideoRuleResult from './VideoRuleResult.vue';
import { useListingDetailAccordion } from '@/components/user/anytime/listing_insights/listing_detail/accordions/useListingDetailAccordion';
import AiDisclaimer from '@/components/user/anytime/listing_insights/listing_detail/accordions/AiDisclaimer.vue';

const props = defineProps({
  platform: {
    type: String,
    required: true,
    validator: function (value) {
      return ['Amazon', 'Shopify'].includes(value);
    },
  },
  data: {
    type: Object,
    required: true,
  },
});

const route = useRoute();

const { platformBlogs } = useListingDetailAccordion(
  toRef(() => props.data),
  toRef(() => props.platform)
);
const linkTo = computed(
  () => platformBlogs['video count'][props.platform]?.link ?? ''
);
const linkTitle = computed(
  () => platformBlogs['video count'][props.platform]?.title ?? ''
);
const linkImage = computed(
  () => platformBlogs['video count'][props.platform]?.image ?? '/'
);

const { linkClicked } = useBaseEvents();

const trackLinkClicked = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'single listing overview - video',
    linkLabel: `Learn More - ${linkTitle.value}`,
    linkHref: linkTo,
  });
};

const videoRules = computed(() => props.data?.rules_result.rules);

const failingRules = computed(() => videoRules.value.filter(x => x.score < 1));

const iconStatus = computed(() => {
  if (props.platform === 'Amazon') {
    return 'indeterminate';
  }
  return failingRules.value.length > 0 ? 'poor' : 'good';
});
</script>

<template>
  <SoonaExpansionPanel>
    <template #header>
      <span class="video__header--wrapper">
        <AccordionHeaderIcon icon-name="video-file-play" :status="iconStatus" />
        <span class="video__header-content">
          <span class="video__header-title">video</span>
          <span class="video__header-actions">
            <SoonaFlag
              v-if="platform === 'Amazon'"
              :background-color="Gray20"
              title="coming soon"
            />
            <span
              v-else-if="failingRules.length > 0"
              class="video__header-action"
            >
              <SoonaIcon
                name="status-dot-small"
                size="small"
                class="video__header-action-icon--fail"
              />
              <span class="video__header-action-text">
                {{ failingRules.length }}
                {{
                  failingRules.length === 1 ? 'opportunity' : 'opportunities'
                }}
              </span>
            </span>
            <span v-else class="video__header-action">
              <SoonaIcon
                name="circle-check-solid"
                size="small"
                class="video__header-action-icon--pass"
              />
              <span class="video__header-action-text">looking sharp!</span>
            </span>
          </span>
        </span>
      </span>
    </template>

    <template #panel>
      <div class="video__panel--wrapper">
        <VideoAccordionAmazonContent v-if="platform === 'Amazon'" />
        <div v-else class="video__panel">
          <ul>
            <VideoRuleResult
              v-for="rule in videoRules"
              :key="rule.rule"
              :rule="rule"
            />
          </ul>
        </div>
        <AiDisclaimer v-if="platform === 'Shopify'" />
        <div class="video__panel-blog-link">
          <p class="u-body--heavy">learn more</p>
          <SoonaBlogPostLink
            :image-src="linkImage"
            :title="linkTitle"
            :to="linkTo"
            @link-clicked="trackLinkClicked"
          />
        </div>
      </div>
    </template>
  </SoonaExpansionPanel>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.video {
  &__panel {
    &--wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__header {
    &--wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &-title {
      @include variables_fonts.u-badge--small;

      color: variables.$gray-60;
    }

    &-action {
      @include variables_fonts.u-body--heavy;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;

      &-icon {
        &--fail {
          color: variables.$roses-60;
        }

        &--pass {
          color: variables.$avo-toast-40;
        }
      }

      &-text {
        text-wrap: wrap;
      }
    }
  }

  &__panel {
    &-blog-link {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      margin-top: 1rem;

      p {
        @include variables_fonts.u-badge--big;

        color: variables.$gray-60;
      }
    }
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__panel {
      &--wrapper {
        padding: 1rem;
      }
    }
  }
}
</style>

<script setup>
import { useCapability } from '@/composables/useCapability';
import { useAccount } from '@/queries/account/useAccount';
import { toRefs } from 'vue';
import AccountInfo from '@/components/user/anytime/dashboard/AccountInfo.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';

const props = defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
  linkToProfile: {
    type: Boolean,
    default: false,
  },
  hidePii: {
    type: Boolean,
    default: false,
  },
});

defineOptions({
  inheritAttrs: false,
});

const { accountId } = toRefs(props);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const { data, error } = useAccount(accountId, {
  enabled: isSoonaStaff,
});

const priorityError = usePriorityError(error);
</script>

<template>
  <template v-if="isSoonaStaff">
    <AccountInfo
      v-if="data"
      :account="data"
      :link-to-profile="linkToProfile"
      :hide-pii="hidePii"
      v-bind="$attrs"
    />
    <SoonaError v-else-if="priorityError">
      {{ priorityError }}
    </SoonaError>
  </template>
</template>

<template>
  <div class="TimelineOrder">
    <hr class="order-divider" />
    <h3
      :id="`order-heading-${order.id}`"
      class="order-header"
      :class="`order-${order.status}`"
    >
      <button
        class="u-button-reset"
        :aria-expanded="open"
        :aria-controls="`order-container-${order.id}`"
        @click="toggleOpen"
      >
        <span class="header-text u-body--all-caps-black">{{ header }}</span>
        <SoonaIcon :class="['header-chevron', { open }]" name="chevron-down" />
      </button>
    </h3>
    <div
      :id="`order-container-${order.id}`"
      class="order-container"
      :aria-labelledby="`order-heading-${order.id}`"
      role="region"
      :hidden="!open"
    >
      <div class="order-info">
        <div v-if="order.payment_provider" class="tag">
          {{ order.payment_provider }}
        </div>
        <div class="tag">
          {{ tag }}
        </div>
        <div v-if="order.status === 'canceled'" class="tag">
          canceled by:
          <span
            v-if="currentReservation.account.name === order.canceled_by.name"
          >
            customer</span
          >
          <span v-else>{{ order.canceled_by.name }}</span>
        </div>
      </div>
      <div class="order-info">
        <router-link
          v-if="
            order.order_type === 'additional_charge' &&
            order.status === 'created'
          "
          :to="{
            name: 'checkout',
            params: { orderId: order.id },
          }"
          class="button is-small is-employee ml-s u-small--regular"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>view checkout page</span>
        </router-link>
        <a
          v-if="order.external_order_id && order.payment_provider === 'stripe'"
          :href="stripeLink"
          class="button is-small is-employee ml-s u-small--regular"
          target="_blank"
          rel="noopener noreferrer"
        >
          view on stripe
        </a>
      </div>
      <DownPaymentOrder
        v-if="order.order_type === 'down_payment'"
        :order="order"
        :reservation="reservation"
      />
      <AdditionalChargeOrder
        v-if="order.order_type === 'additional_charge'"
        :order="order"
      />
    </div>
  </div>
</template>

<script>
import DownPaymentOrder from 'src/components/user/anytime/crew/DownPaymentOrder.vue';
import AdditionalChargeOrder from 'src/components/user/anytime/crew/AdditionalChargeOrder.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import { mapState } from 'vuex';

export default {
  name: 'TimelineOrder',
  components: {
    DownPaymentOrder,
    AdditionalChargeOrder,
    SoonaIcon,
  },
  props: {
    order: Object,
    reservation: Object,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    header() {
      let orderType;
      if (this.order.order_type === 'down_payment') {
        orderType = `down payment #${this.order.id}`;
      } else if (this.order.order_type === 'add_on') {
        orderType = `add-on order #${this.order.id}`;
      } else {
        orderType = `additional charge order #${this.order.id}`;
      }

      const orderDate = this.order.date_collected
        ? new Date(this.order.date_collected)
        : new Date(this.order.created_at);

      return `${orderType}: ${orderDate.toLocaleDateString()} ${orderDate.toLocaleTimeString(
        [],
        { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' }
      )}`;
    },
    stripeLink() {
      if (this.order.external_order_id.startsWith('pi_')) {
        return `${this.stripe_dashboard}/payments/${this.order.external_order_id}`;
      } else {
        return `${this.stripe_dashboard}/orders/${this.order.external_order_id}`;
      }
    },
    stripe_dashboard() {
      return import.meta.env.VITE_STRIPE_DASHBOARD_URL;
    },
    tag() {
      let orderStatus;
      if (this.order.status === 'paid') {
        orderStatus = 'paid 💸';
      } else if (this.order.status === 'canceled') {
        orderStatus = 'canceled 🙅‍♀️';
      } else {
        orderStatus = `${this.order.status}`;
      }
      return orderStatus;
    },
    ...mapState({
      currentReservation: state => state.reservation.currentReservation,
    }),
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss">
@use '@/variables';

.TimelineOrder {
  margin-bottom: 1.5rem;

  .order-divider {
    height: 4px;
    margin: 0 0 1.5rem;
    background-color: #223355;
  }

  .order-header {
    & > button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: variables.$periwink-blue-60;
      color: variables.$white-default;
      padding: 1rem;
      white-space: normal;
      text-align: left;
    }

    &.order-created {
      & > button {
        background-color: variables.$bubbletape-pink-10;
        color: variables.$black-default;
      }
    }
    &.order-canceled {
      & > button {
        background-color: #b5b5b6;
        color: variables.$black-default;
      }
    }

    .header-text {
      letter-spacing: 0.2rem;
    }

    .header-chevron {
      color: inherit;
      transition: transform 300ms ease-out;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .order-container[hidden] {
    display: none;
  }

  .order-info {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
    flex-wrap: wrap;

    .tag {
      margin-left: 0.5rem;
    }
  }
}
</style>

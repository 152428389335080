import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param {number | Ref<number>} reservationId
 */
export function useCreateReservationLineItem(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.post(
        `reservations/${toValue(reservationId)}/reservation_line_items.json`,
        {
          reservation_line_item: body,
        }
      );
      return response.data;
    },
    onSuccess: async result => {
      const promises = [
        queryClient.invalidateQueries({ queryKey: queryKeys.bag() }),
      ];
      if (result.digital_asset_id) {
        promises.push(
          queryClient.invalidateQueries({
            queryKey: queryKeys.reservationDigitalAssetMediaAddOns(
              toValue(reservationId),
              result.digital_asset_id
            ),
          })
        );
      }

      await Promise.all(promises);
    },
  });
}

<script setup>
import { computed, ref, toRefs } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useIntegrations } from '@/composables/useIntegrations';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useRoute, useRouter } from 'vue-router';
import ReconnectAmazonDialog from '@/components/user/anytime/products/ReconnectAmazonDialog.vue';
import CurrentIntegrationListing from './CurrentIntegrationListing.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  integrationId: {
    type: String,
    required: true,
  },
  listing: {
    type: Object,
    default: null,
  },
});

const emits = defineEmits(['refreshListing']);
const route = useRoute();
const router = useRouter();
dayjs.extend(relativeTime);

const { accountId, integrationId, listing } = toRefs(props);

const { currentPlatformName, currentIntegration } = useIntegrations(
  accountId,
  integrationId
);

const displayRefreshButton = computed(
  () =>
    ['Amazon', 'Shopify'].includes(currentPlatformName.value) && listing.value
);

const showReconnectAmazonDialog = ref(false);

const refreshListing = () => {
  if (
    currentPlatformName.value === 'Amazon' &&
    currentIntegration.value?.status === 'invalid_state'
  ) {
    showReconnectAmazonDialog.value = true;
    return;
  }

  emits('refreshListing');
};

const selectIntegrationListing = externalProduct => {
  router.replace({
    name: 'listing-optimize',
    params: {
      accountId: accountId.value,
      catalogItemId: route.params.catalogItemId,
      externalProductId: externalProduct.id,
      integrationId: externalProduct.integration.id,
    },
  });
};
</script>

<template>
  <div v-if="listing" class="current-integration">
    <CurrentIntegrationListing
      :account-id="accountId"
      :selected-integration-id="integrationId"
      :catalog-item-id="listing.catalog_item_id"
      :listing="listing"
      @select="selectIntegrationListing"
    />
    <div class="current-integration__refresh">
      <p class="u-small--regular">
        updated {{ dayjs().to(dayjs(listing.updated_at)) }}
      </p>
      <SoonaButton
        v-if="displayRefreshButton"
        variation="icon-plain-gray"
        size="small"
        title="refresh listing"
        @click="refreshListing"
      >
        <SoonaIcon name="refresh-cw-alt" />
      </SoonaButton>
    </div>
  </div>
  <div v-else class="current-integration__loading">
    <SoonaSkeleton class="current-integration__loading--skeleton" />
    <SoonaSkeleton class="current-integration__loading--skeleton" />
  </div>
  <ReconnectAmazonDialog
    v-if="showReconnectAmazonDialog"
    @close="showReconnectAmazonDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.current-integration {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;

  &__loading {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;

    &--skeleton {
      width: 5rem;
      height: 2rem;
    }
  }

  &__refresh {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-self: flex-end;
  }
}
</style>

import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {Object} [params]
 * @param {string | number} accountId
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {AbortSignal} [signal]
 */
export async function getCollaborators(
  accountId,
  { currentPage, itemsPerPage } = {},
  signal
) {
  const params = {};

  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;

  const response = await http.get(`/accounts/${accountId}/collaborators`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    collaborators: response.data,
  };
}

/**
 * @param {string | number} accountId
 * @param {string | number} collaboratorId
 */
export async function removeCollaborator(accountId, collaboratorId) {
  const response = await http.delete(
    `/accounts/${accountId}/collaborators/${collaboratorId}`
  );

  return response.data;
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} collaboratorId
 */
export async function editCollaborator(accountId, collaboratorId, body) {
  const response = await http.put(
    `/accounts/${accountId}/collaborators/${collaboratorId}`,
    body
  );

  return response.data;
}

// see booking/v3/p2/SceneCard on how to use this logic in composition api (https://vueuse.org/core/usemediaquery/#usemediaquery)
export default {
  data() {
    return {
      matchMediaMinWidth: 768,
      matchMediaIsWide: false,
    };
  },
  computed: {
    screenMin: function () {
      return window.matchMedia(
        '(min-width: ' + this.matchMediaMinWidth + 'px)'
      );
    },
  },
  methods: {
    handleWidthChange(event) {
      this.matchMediaIsWide = event.matches;
    },
  },
  mounted() {
    this.matchMediaIsWide = window.innerWidth >= this.matchMediaMinWidth;
    // MediaQueryList doesn't inherit EventTarget in older browsers, so we need to check before using the event listener
    if (this.screenMin.addEventListener) {
      this.screenMin.addEventListener('change', this.handleWidthChange);
    } else {
      this.screenMin.addListener(this.handleWidthChange);
    }
  },
  beforeUnmount() {
    if (this.screenMin.removeEventListener) {
      this.screenMin.removeEventListener('change', this.handleWidthChange);
    } else {
      this.screenMin.removeListener(this.handleWidthChange);
    }
  },
};

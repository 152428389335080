<script setup>
import { useSubscription } from '@/composables/subscription/useSubscription';
import { computed } from 'vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    default: undefined,
  },
  defaultLoaderMinHeight: {
    type: String,
    default: '0',
  },
});

const accountId = computed(() => props.accountId);
const defaultLoaderMinHeight = computed(() => props.defaultLoaderMinHeight);

const {
  error,
  canEnrollFastPass,
  canCancelFastPass,
  hasTierOneSubscription,
  hasTierTwoSubscription,
  hasPremiumEditsAccess,
  hasLiveSubscription,
  isLoading,
  isSuccess,
  subscriptionStartDate,
} = useSubscription(accountId);

const priorityError = usePriorityError(error);
</script>

<template>
  <slot v-if="error" name="error" :priority-error="priorityError">
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
  </slot>
  <slot v-else-if="isLoading" name="loading">
    <SoonaSkeleton class="base-subx__loading" />
  </slot>
  <slot
    v-else-if="isSuccess"
    name="success"
    :can-enroll-fast-pass="canEnrollFastPass"
    :can-cancel-fast-pass="canCancelFastPass"
    :has-tier-one-subscription="hasTierOneSubscription"
    :has-tier-two-subscription="hasTierTwoSubscription"
    :has-premium-edits-access="hasPremiumEditsAccess"
    :has-live-subscription="hasLiveSubscription"
    :subscription-start-date="subscriptionStartDate"
  />
</template>

<style lang="scss" scoped>
.base-subx {
  &__loading {
    min-height: v-bind('defaultLoaderMinHeight');
  }
}
</style>

export function useRegexHelper() {
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // explanation for this regex: https://stackoverflow.com/a/719543
  const fileNameRegex = /^[a-zA-Z0-9_-]+$/;
  const httpsRegex = /^https:\/\//;
  const urlRegex =
    /^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
  return {
    emailRegex,
    fileNameRegex,
    httpsRegex,
    urlRegex,
  };
}

<script setup>
import { computed } from 'vue';
import SoonaItemCard from 'src/components/ui_library/SoonaItemCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { Gray20 } from '@/variables.module.scss';

const props = defineProps({
  buttonVariation: {
    type: String,
    default: 'secondary-black',
  },
  listing: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['select']);

const listing = computed(() => props.listing);

const id = computed(() => `picker-listing-option-${listing.value.id}`);

const badgeIconName = computed(() => {
  if (listing.value.integration_type === 'AmazonIntegration') {
    return 'amazon-logo';
  }
  if (listing.value.integration_type === 'ShopifyIntegration') {
    return 'shopify-logo';
  }
  return null;
});
</script>

<template>
  <SoonaItemCard
    :aria-labelledby="`${id}-heading`"
    :image-url="listing.main_image_url"
  >
    <template #image-badge>
      <SoonaIcon
        v-if="badgeIconName"
        :name="badgeIconName"
        class="listing-option__badge"
        size="large"
      />
    </template>
    <template #content>
      <div class="listing-option__wrapper">
        <div class="listing-option__content">
          <SoonaFlag
            v-if="listing.is_example_external_product"
            class="soona-tab__flag"
            title="example"
            :background-color="Gray20"
          />
          <h2 :id="`${id}-heading`" class="listing-option__content__heading">
            {{ listing.name }}
          </h2>
        </div>
        <div class="listing-option__actions">
          <SoonaButton
            class="choose-button"
            :variation="buttonVariation"
            size="medium"
            type="button"
            :disabled="disabled"
            :aria-label="`choose ${listing.name}`"
            @on-click="$emit('select', listing)"
          >
            choose
          </SoonaButton>
        </div>
      </div>
    </template>
  </SoonaItemCard>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.listing-option {
  &__wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem 0.75rem;
    height: 100%;
  }

  &__badge {
    background-color: variables.$white-default;
    border-radius: 50%;
    padding: 0.125rem;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__heading {
      @include variables_fonts.u-body--heavy;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__badge {
    margin-top: 0.25rem;
  }

  &__actions {
    .choose-button {
      width: auto;
    }
  }
}
</style>

<script setup>
import { ref } from 'vue';
import { useResizeObserver } from '@vueuse/core';

const toolbar = ref(null);
const expandedContainer = ref(null);
const showCondensed = ref(false);
const widthSnapshot = ref(0);

const checkIfFits = () => {
  if (!toolbar.value) return;
  const toolbarWidth = toolbar.value.clientWidth;

  if (expandedContainer.value) {
    const expandedWidth = expandedContainer.value.scrollWidth;
    // Snapshot the width for when the expanded container unmounts
    widthSnapshot.value = expandedWidth;

    // Toggle the display based on space available
    showCondensed.value = expandedWidth > toolbarWidth;
  } else if (widthSnapshot.value) {
    showCondensed.value = widthSnapshot.value > toolbarWidth;
  }
};

useResizeObserver(toolbar, checkIfFits);
</script>

<template>
  <div ref="toolbar" class="toolbar">
    <slot v-if="showCondensed" name="condensed" />
    <div v-else ref="expandedContainer" class="toolbar__expanded">
      <slot name="expanded" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toolbar {
  flex: 1;
  width: 100%;
  gap: 0.5rem;
  min-width: 0;
  display: flex;
  position: relative;
  align-items: center;
  white-space: nowrap;

  &__expanded {
    flex: 1;
  }
}
</style>

export const ordersPaths = [
  {
    path: '/account/:accountId/orders',
    component: () =>
      import('@/components/user/anytime/billing_and_orders/Orders.vue'),
    meta: {
      context: 'billing history',
      requires_auth: true,
    },
    children: [
      {
        path: '',
        name: 'orders-invoices',
        component: () =>
          import(
            '@/components/user/anytime/billing_and_orders/OrdersInvoices.vue'
          ),
        meta: {
          requires_auth: true,
        },
        props: route => ({
          accountId: route.params.accountId,
        }),
        children: [
          {
            path: '',
            name: 'order-list',
            component: () =>
              import(
                '@/components/user/anytime/billing_and_orders/OrderList.vue'
              ),
            meta: {
              requires_auth: true,
            },
            props: route => ({
              accountId: route.params.accountId,
            }),
          },
          {
            path: 'subscription-invoices',
            name: 'subscription-invoices-list',
            component: () =>
              import('@/components/subscriptions/SubscriptionInvoices.vue'),
            meta: {
              requires_auth: true,
              requires_capability: [
                {
                  capability: 'view_account_subscription',
                  subjectType: 'account',
                  subjectId: to => to.params.accountId,
                },
                'crew_manage_subscription',
              ],
            },
            props: route => ({
              accountId: route.params.accountId,
            }),
          },
        ],
      },
      {
        path: 'additional-order',
        name: 'account-additional-order',
        component: () =>
          import(
            '@/components/user/anytime/billing_and_orders/AccountAdditionalOrder.vue'
          ),
        meta: {
          context: 'account additional order',
          requires_auth: true,
          routeLayoutOptions: {
            noContainer: true,
          },
        },
        props: route => ({
          accountId: route.params.accountId,
        }),
      },
      {
        path: ':orderId',
        name: 'order-view',
        component: () =>
          import(
            '@/components/user/anytime/billing_and_orders/view_order/OrderView.vue'
          ),
        meta: {
          requires_auth: true,
        },
        props: route => ({
          accountId: route.params.accountId,
          orderId: route.params.orderId,
        }),
      },
    ],
  },
];

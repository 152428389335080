<script setup>
import { ref } from 'vue';
import SoonaPropsDialog from './SoonaPropsDialog.vue';
import EmptyImg from 'images/shot-list/scenes/empty-soona-props.png';
import SectionLayout from './SectionLayout.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  selectedOptions: {
    default: () => [],
    type: Array,
  },
});

const emit = defineEmits(['handle-remove', 'handle-select']);

const showDialog = ref(false);

const handleSelect = options => {
  emit('handle-select', 'props', options);
  showDialog.value = false;
};
</script>

<template>
  <SectionLayout :empty-img="EmptyImg" :show-empty="!selectedOptions.length">
    <template #heading>soona props</template>
    <template #subheading>(optional)</template>
    <template #empty-heading>pick out props from our collection</template>
    <template #empty-btn>
      <SoonaButton variation="solid-black" @on-click="showDialog = true">
        <SoonaIcon name="plus-large" /> add props
      </SoonaButton>
    </template>
    <SoonaButton
      size="medium"
      variation="secondary-gray"
      @on-click="showDialog = true"
    >
      <SoonaIcon name="plus-large" /> add props
    </SoonaButton>
    <div class="chip-wrapper">
      <SoonaChip
        v-for="selection in selectedOptions"
        :key="selection.id"
        :image-src="selection.image_url"
        image-alt="selected soona prop"
        removable
        @remove="emit('handle-remove', 'props', selection.id)"
      >
        {{ selection.title.toLowerCase() }}
      </SoonaChip>
    </div>
  </SectionLayout>
  <SoonaPropsDialog
    v-if="showDialog"
    :already-selected="selectedOptions"
    @save="handleSelect"
    @close="showDialog = false"
  />
</template>

<style lang="scss" scoped>
.chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}
</style>

<template>
  <div
    v-if="allDeletedTrendSets.length > 0"
    class="admin-deleted-trend-sets"
    :class="{ 'admin-deleted-trend-sets--closed': isAccordionClosed }"
    data-cypress="component-admin-trend-sets-deleted"
  >
    <h3 class="admin-deleted-trend-sets-heading">
      <a
        id="admin-deleted-trend-sets-collapse-toggle"
        class="admin-deleted-trend-sets-collapse-toggle"
        href="#admin-deleted-trend-sets-flex"
        aria-controls="admin-deleted-trend-sets-flex"
        :aria-expanded="!isAccordionClosed"
        role="button"
        type="button"
        data-cypress="anchor-open-close-deleted-trend-set"
        @click.prevent="isAccordionClosed = !isAccordionClosed"
      >
        <span>deleted trend sets</span>
        <SoonaIcon :name="isAccordionClosed ? 'chevron-down' : 'chevron-up'" />
      </a>
    </h3>
    <div
      id="admin-deleted-trend-sets-flex"
      class="admin-deleted-trend-sets-flex"
      aria-labelledby="admin-deleted-trend-sets-collapse-toggle"
    >
      <AdminTrendSet
        v-for="trendSet of allDeletedTrendSets"
        :key="trendSet.id"
        :trend-set="trendSet"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AdminTrendSet from './AdminTrendSet.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'AdminDeletedTrendSets',
  components: {
    AdminTrendSet,
    SoonaIcon,
  },
  data() {
    return {
      isAccordionClosed: true,
    };
  },
  computed: {
    ...mapState({
      allDeletedTrendSets: state => state.trendSetBuilder.allDeletedTrendSets,
    }),
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.admin-deleted-trend-sets {
  max-width: 969px;
  border-bottom: 1px solid variables.$gray-30;
  border-top: 1px solid variables.$gray-30;
  padding-bottom: 20px;
  width: 100%;

  &__header {
    display: flex;
    padding-bottom: 72px;
    padding-left: 40px;
  }

  h2 {
    padding-right: 32px;
  }

  &--closed {
    padding-bottom: 0;

    .admin-deleted-trend-sets-collapse-toggle {
      display: flex;
      justify-content: space-between;

      &:after {
        top: 20px;
        transform: rotate(-45deg);
      }
    }

    .admin-deleted-trend-sets-flex {
      display: none;
    }
  }

  &-heading {
    @include variables_fonts.u-subheader--regular;
  }

  &-collapse-toggle {
    cursor: pointer;
    display: block;
    padding: 41px 21px 41px 41px;
    position: relative;

    svg {
      color: variables.$gray-60;
    }
  }

  &-flex {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 41px;
  }
}
</style>

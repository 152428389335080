<script setup>
import { computed, ref } from 'vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import { useLocations } from 'src/queries/useLocation';

const props = defineProps({
  locationId: {
    type: Number,
    default: null,
    required: false,
  },
  editable: {
    type: Boolean,
    default: true,
    required: false,
  },
});
const emit = defineEmits(['selectLocation']);
const locationId = computed(() => props.locationId);
const { data: locationsData, isLoading: isLoadingLocations } = useLocations();
const locationOptions = computed(() => {
  if (!locationsData.value) {
    return [];
  }
  return locationsData.value.reduce((acc, location) => {
    if (location.name !== 'unassigned') {
      acc.push({ label: location.name, value: location.id });
    }
    return acc;
  }, []);
});

const selectedLocation = ref(locationId);

function selectLocation(option) {
  emit('selectLocation', option);
}
</script>
<template>
  <SoonaSelect
    :model-value="selectedLocation"
    :options="locationOptions"
    :disabled="!editable || isLoadingLocations"
    :loading="isLoadingLocations"
    data-cypress="select-location"
    @option:selecting="selectLocation"
  >
    <template #label>location</template>
  </SoonaSelect>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useTitle } from '@vueuse/core';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

useTitle('style quiz | soona');

const store = useStore();
const isAuthenticated = computed(
  () => store.getters['currentUser/isAuthenticated']
);
</script>

<template>
  <div class="landing-page">
    <h1 class="u-display--heavy">let’s uncover your brand’s visual style 🕵️‍♀️</h1>
    <p class="u-body--heavy">
      take the soona style quiz to find out what visual personality your brand
      radiates. convert your visual style into a scene list for the crew to
      capture!
    </p>
    <div class="action-btns">
      <SoonaButton
        v-if="!isAuthenticated"
        element="router-link"
        variation="tertiary"
        :to="{
          name: 'style-quiz-sign-in',
          query: { redirect: '/style-quiz/pick-image' },
        }"
      >
        sign in
      </SoonaButton>
      <SoonaButton
        element="router-link"
        size="medium"
        :to="{ name: 'style-quiz-image-picker' }"
        data-cypress="button-lets-go"
      >
        let’s go!
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.landing-page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
  margin: 0 auto;
  max-width: 23rem;
}

.action-btns {
  display: flex;
  justify-content: space-around;
}
</style>

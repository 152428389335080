import { reactive, readonly } from 'vue';

export const useMediaEditorComponents = () => {
  const components = reactive(new Map());

  /**
   * @param {Symbol} symbol
   * @param handlers
   * @description register a component with the media editor store
   * handlers should provide the following functions
   * - handleSave - save the current edit as a new asset
   * - warnBeforeClose - return an object with entries 'warn' and 'message'.
   *                     If 'warn' is true, the message will be displayed to the user before closing the editor.
   *                     If no message is provided, a default message will be displayed.
   * - handleReset - reset the current image canvas to its original state
   */
  const registerComponent = (symbol, handlers) => {
    if (typeof handlers.handleSave !== 'function') {
      console.warn(`${symbol.toString()} is missing handleSave`);
    }
    if (handlers.warnBeforeClose === undefined) {
      console.warn(`${symbol.toString()} is missing warnBeforeClose`);
    }
    if (handlers.handleReset === undefined) {
      console.warn(`${symbol.toString()} is missing handleReset`);
    }
    components.set(symbol, handlers);
  };

  const unregisterComponent = symbol => {
    components.delete(symbol);
  };

  const unregisterAllComponents = () => {
    components.clear();
  };

  return {
    components: readonly(components),
    registerComponent,
    unregisterComponent,
    unregisterAllComponents,
  };
};

<script setup>
import { computed } from 'vue';
import { useNonCreatorEditingAllowed } from '@/components/notes/useNonCreatorEditingAllowed';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import NoteDisplay from '@/components/notes/NoteDisplay.vue';
import NoteCreate from '@/components/notes/NoteCreate.vue';
import { useInfiniteNotes } from '@/queries/notes/useInfiniteNotes';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  subjectType: {
    type: String,
    required: true,
  },
  subjectId: {
    type: [String, Number],
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
});

defineEmits(['see-all-notes']);

const subjectType = computed(() => props.subjectType);
const subjectId = computed(() => props.subjectId);

const nonCreatorEditingAllowed = useNonCreatorEditingAllowed(subjectType);

const {
  data: notesData,
  isLoading,
  error,
} = useInfiniteNotes(
  subjectType,
  subjectId,
  {
    itemsPerPage: 1,
  },
  {
    enabled: computed(() => !!subjectType.value && !!subjectId.value),
  }
);

const totalCount = computed(
  () => notesData.value?.pages?.at(-1)?.pagination?.totalCount ?? 0
);

const priorityErrors = usePriorityErrors(error);

const mostRecentNote = computed(() => {
  return (
    notesData.value?.pages
      .flatMap(d => d.notes)
      .toReversed()
      .at(0) ?? null
  );
});
</script>

<template>
  <div class="notes-condensed">
    <SoonaError
      v-if="priorityErrors"
      :priority-errors="priorityErrors"
      no-margin
    />
    <SoonaSkeleton v-if="isLoading" class="notes-condensed__loading" />
    <template v-if="notesData">
      <SoonaButton
        v-if="totalCount > 1"
        variation="tertiary"
        size="medium"
        @on-click="$emit('see-all-notes')"
      >
        see all {{ totalCount.toLocaleString() }} notes
      </SoonaButton>
      <NoteDisplay
        v-if="mostRecentNote"
        :subject-id="subjectId"
        :subject-type="subjectType"
        :non-creator-editing-allowed="nonCreatorEditingAllowed"
        :note="mostRecentNote"
      />
      <NoteCreate
        :placeholder="placeholder"
        :subject-id="subjectId"
        :subject-type="subjectType"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.notes-condensed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4375rem;
  align-self: stretch;

  &__loading {
    height: 2.75rem;
    width: 100%;
    flex: 1 1 3rem;
  }
}
</style>

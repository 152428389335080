<script setup>
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaUploadForm from './SoonaUploadForm.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

defineProps({
  activeUploads: {
    type: Array,
    required: true,
  },
  isMultiple: {
    type: Boolean,
    required: true,
  },
  accept: {
    type: String,
    required: true,
  },
  label: {
    required: true,
  },
});

const emit = defineEmits(['handleDrop', 'handleUpload', 'close']);

function closeModal() {
  emit('close');
}

function handleUpload(e) {
  emit('handleUpload', e);
  closeModal();
}

function handleDrop(e) {
  emit('handleDrop', e);
  closeModal();
}
</script>

<template>
  <SoonaDialog @close="closeModal">
    <template #heading>{{ label }}</template>
    <SoonaUploadForm
      :accept="accept"
      :is-multiple="isMultiple"
      :active-uploads="activeUploads"
      @drop="handleDrop"
      @upload="handleUpload"
    />
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @click="close">cancel</SoonaButton>
    </template>
  </SoonaDialog>
</template>

import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { toValue } from 'vue';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 * @param {import('vue').MaybeRefOrGetter<number | string>} accountId
 * @param {import('vue').MaybeRefOrGetter<string>} requirementType
 * @param {Object} [params]
 * @params {import('vue').MaybeRefOrGetter<number>} [params.currentPage]
 * @params {import('vue').MaybeRefOrGetter<number>} [params.itemsPerPage]
 * @param {Object} [queryOptions]
 */
export function useAccountRequirementVersions(
  accountId,
  requirementType,
  { currentPage, itemsPerPage } = {},
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.accountRequirementVersions(accountId, requirementType, {
      currentPage,
      itemsPerPage,
    }),
    queryFn: async ({ signal }) => {
      const requestParams = {};
      if (toValue(itemsPerPage)) requestParams.items = toValue(itemsPerPage);
      if (toValue(currentPage)) requestParams.page = toValue(currentPage);

      const response = await http.get(
        `/accounts/${toValue(accountId)}/account_requirements/${toValue(requirementType)}/versions`,
        { signal, params: requestParams }
      );

      return {
        pagination: mapPaginationHeaders(response.headers),
        versions: response.data,
      };
    },
    ...queryOptions,
  });
}

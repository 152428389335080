<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';
import FeatureList from '@/components/subscriptions/FeatureList.vue';
import SoonaMembershipCard from '../booking/v3/p2/SoonaMembershipCard.vue';

const props = defineProps({
  selectedBillingInterval: {
    default: 'year',
    type: String,
    validator: function (value) {
      return ['month', 'year'].includes(value);
    },
  },
  tier: {
    type: Object,
    required: true,
  },
  showBorder: {
    default: false,
    type: Boolean,
  },
  bestOfferBorder: {
    default: false,
    type: [Boolean, String],
    validator: function (value) {
      return [false, 'pizzazz', 'black'].includes(value);
    },
  },
});

const selectedBillingInterval = computed(() => props.selectedBillingInterval);

const price = computed(() =>
  selectedBillingInterval.value === 'year'
    ? props.tier.product.prices?.find(p => p.recurring_interval === 'year')
    : props.tier.product.prices?.find(p => p.recurring_interval === 'month')
);

const amount = computed(() => {
  if (!price.value) return 0;

  return price.value.recurring_interval === 'year'
    ? +price.value.unit_amount / 12
    : +price.value.unit_amount;
});
</script>

<template>
  <article
    class="tier-card tier-card--clean"
    :class="{
      'best-offer': bestOfferBorder,
      [`best-offer--${bestOfferBorder}`]: bestOfferBorder,
      'tier-card--border': showBorder,
    }"
  >
    <div
      v-if="bestOfferBorder"
      class="best-offer--content u-button--small-caps"
    >
      best value
    </div>
    <div class="tier-card__top" :data-tier-slug="tier.slug">
      <div class="tier-card__basic-info">
        <h3 class="u-display--heavy">{{ tier.name }}</h3>
        <p class="u-subheader--heavy tier-card__price">
          {{ toCurrency(amount, price?.currency, 0) }}/month
          <span v-if="selectedBillingInterval === 'year'"
            >(billed annually)</span
          >
        </p>
      </div>
      <SoonaMembershipCard
        :title="tier.name"
        :tier-slug="tier.slug"
        class="tier-card__badge"
      />
    </div>
    <div class="tier-card__bottom">
      <FeatureList
        class="tier-card__features"
        :feature-list="tier.product.card_feature_list"
        :has-no-gap="true"
        :show-tooltip="true"
      />
      <slot />
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.tier-card {
  z-index: 0;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;

  p span {
    font-weight: normal;
  }

  &.best-offer {
    position: relative;
    background: white;
    border-radius: 1.5rem;
    border: 0.3125rem solid transparent;
    border-top: 0rem;

    &::before {
      content: '';
      z-index: -1;
      inset: -0.3125rem;
      top: -2.25rem;
      position: absolute;
      border-radius: 1.25rem;
    }

    &.best-offer--black::before {
      background: variables.$black-default;
    }

    &.best-offer--pizzazz::before {
      background: variables.$gradient-red-to-blue-periwink;
    }

    & .best-offer--content {
      left: 0;
      right: 0;
      top: -1.625rem;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      color: variables.$white-default;
    }
  }

  &--border {
    border-radius: 1rem;
    border: 0.0625rem solid variables.$gray-30;

    .tier-card__top {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 0.75rem 0.25rem 0.625rem 0.25rem;
    background-color: variables.$white-default;
  }

  & .tier-card__badge {
    margin-left: 1rem;
  }

  &__basic-info {
    padding: 1.5rem 0 0.625rem 1.25rem;
    flex-grow: 1;
    text-align: left;
  }

  &__summary {
    text-align: left;
  }

  &__bottom {
    background-color: variables.$white-default;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 1rem 1.25rem 1rem;
  }

  &__features {
    padding-bottom: 1.5rem;
  }
}
</style>

<script setup>
import { computed, reactive, toRef } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SceneOption from './SceneOption.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useTagCategories } from 'src/queries/useTagCategories';
import { useGetTags } from 'src/queries/useGetTags';
import { useReservation } from '@/composables/useReservation';
import { useCreateShot, useUpdateShot } from '@/queries/useShotList';
import { useMe } from '@/composables/user/useMe';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import SceneEnvironmentSelector from './SceneEnvironmentSelector.vue';
import SceneSection from './SceneSection.vue';
import { useSelectedShotOptions } from './useSelectedShotOptions';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SceneBuilderActions from './SceneBuilderActions.vue';
import SceneBuilderSoonaProps from '@/components/user/anytime/scene_selector/SceneBuilderSoonaProps.vue';
import SceneBuilderCatalogItems from '@/components/user/anytime/scene_selector/SceneBuilderCatalogItems.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  editMode: {
    type: Boolean,
    required: true,
  },
  originalSceneNumber: {
    type: Number,
    required: false,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  sceneToCopy: {
    type: Object,
    required: false,
  },
  shotListLength: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['closeSceneBuilder']);

const reservationId = computed(() => props.reservationId);
const accountId = computed(() => props.accountId);
const {
  shootType,
  isLoading: isLoadingReservation,
  error: reservationError,
} = useReservation(reservationId);

const { currentUserId } = useMe();

const { data: shotTypeOptions, error: tagCategoriesError } = useTagCategories({
  include_tags: true,
  slug: ['shot-content', 'shot-environment', 'shot-props'],
});

const contentTypeOptions = computed(
  () => shotTypeOptions.value?.find(x => x.title === 'Content').tags ?? []
);

// to do:
// filter by environments already present in shot list
// filter by capable environments (or does api only return capable environments for assigned location/bay?)
// if selecing a color, must also select color backdrop tag
const environmentTypeOptions = computed(
  () => shotTypeOptions.value?.find(x => x.title === 'Environment').tags ?? []
);

const soonaPropTagCategory = computed(() =>
  shotTypeOptions.value?.find(x => x.is_props === true)
);

// shot options module
const {
  contentType,
  environmentType,
  environmentFollowup,
  selectedCatalogItems,
  selectedSoonaPropTags,
  referenceImages,
  description,
  shotSubjects,
  customProps,
} = useSelectedShotOptions(
  toRef(() => props.sceneToCopy),
  shootType,
  contentTypeOptions,
  environmentTypeOptions
);

const selectedShotOptions = reactive({
  contentType: contentType,
  environmentType: environmentType,
  environmentFollowup: environmentFollowup,
  selectedCatalogItems: selectedCatalogItems,
  selectedSoonaPropTags: selectedSoonaPropTags,
  referenceImages: referenceImages,
  shotSubjects: shotSubjects,
  customProps: customProps,
});

const isSelected = (type, id) => {
  if (Array.isArray(selectedShotOptions[type])) {
    return selectedShotOptions[type].includes(id);
  }
  return selectedShotOptions[type] === id;
};

const selectedContentTypeTag = computed(() =>
  contentTypeOptions.value?.find(x => x.id === selectedShotOptions.contentType)
);

const followUpCategories = computed(() =>
  environmentTypeOptions.value
    ?.find(x => x.id === selectedShotOptions.environmentType)
    ?.tag_follow_up_categories?.map(fTc => fTc.tag_category_id)
);

const environmentFollowupOptionQuery = computed(() => {
  return { tag_category_ids: followUpCategories.value };
});

const {
  data: environmentFollowupOptions,
  isLoading: isLoadingEnvironmentFollowupOptions,
} = useGetTags(environmentFollowupOptionQuery, {
  enabled: () => !!followUpCategories.value?.length,
});

const selectContentType = event => {
  selectedShotOptions.contentType = Number(event.target.value);
};

const selectSoonaProps = tags => {
  selectedShotOptions.selectedSoonaPropTags = tags;
};

function removeSoonaProp(propId) {
  selectedShotOptions.selectedSoonaPropTags =
    selectedShotOptions.selectedSoonaPropTags.filter(x => x.id !== propId);
}

// create or update shot module
const {
  mutate: createShot,
  isPending: isLoadingCreateShot,
  error: createShotError,
} = useCreateShot(reservationId);
const {
  mutate: updateShot,
  isPending: isLoadingUpdateShot,
  error: updateShotError,
} = useUpdateShot(reservationId);

const closeSceneBuilder = sceneId => {
  emit('closeSceneBuilder', sceneId);
};

const catalogItemsShotsParam = () => {
  if (props.editMode) {
    const result = props.sceneToCopy.catalog_items.map(originalCatalogItem => {
      const exists = selectedShotOptions.selectedCatalogItems.find(
        ci => ci.id === originalCatalogItem.id
      );

      return {
        id: originalCatalogItem.catalog_items_shot_id,
        catalog_item_id: originalCatalogItem.id,
        _destroy: exists ? 0 : 1,
      };
    });
    selectedShotOptions.selectedCatalogItems.forEach(catalogItem => {
      if (!result.find(x => x.catalog_item_id === catalogItem.id)) {
        result.push({ catalog_item_id: catalogItem.id });
      }
    });
    return result;
  } else {
    return selectedShotOptions.selectedCatalogItems.map(catalogItem => ({
      catalog_item_id: catalogItem.id,
    }));
  }
};

const mapToTagId = id => ({ tag_id: id });

const createOrUpdateScene = () => {
  const contentTypeParam = {
    tag_id: selectedContentTypeTag.value?.id,
  };

  const envTypeTag = environmentTypeOptions.value?.find(
    x => x.id === selectedShotOptions.environmentType
  );
  const envFollowupTag = environmentFollowupOptions.value?.find(
    x => x.id === selectedShotOptions.environmentFollowup
  );
  const environmentTags = [];
  if (envTypeTag) environmentTags.push({ tag_id: envTypeTag.id });
  if (envTypeTag && envFollowupTag)
    environmentTags.push({ tag_id: envFollowupTag.id });

  const soonaPropParams = selectedShotOptions.selectedSoonaPropTags.map(tag => {
    return {
      tag_id: tag.id,
    };
  });

  const referenceImageParams =
    selectedShotOptions.referenceImages.map(mapToTagId);

  const shotSubjectParams = selectedShotOptions.shotSubjects.map(mapToTagId);

  const customPropsParams = selectedShotOptions.customProps.map(mapToTagId);

  const catalog_items_shots_attributes = catalogItemsShotsParam();

  // to do: handle required fields
  // question: how do we signify required fields in the template?
  const params = {
    description: description.value,
    tag_categories: [],
    created_by_user_id: currentUserId.value,
    catalog_items_shots_attributes: catalog_items_shots_attributes,
  };

  const shot_tags = [
    contentTypeParam,
    ...environmentTags,
    ...soonaPropParams,
    ...referenceImageParams,
    ...shotSubjectParams,
    ...customPropsParams,
  ];

  params.shot_tags_attributes = shot_tags;

  if (props.editMode) {
    params.id = props.sceneToCopy.id;
    updateShot(params, {
      onSuccess: () => {
        closeSceneBuilder();
      },
    });
  } else {
    createShot(params, {
      onSuccess: data => {
        closeSceneBuilder(data.id);
      },
    });
  }
};

const priorityError = usePriorityError(
  tagCategoriesError,
  reservationError,
  createShotError,
  updateShotError
);

const iconName = computed(() =>
  selectedContentTypeTag.value?.title === 'Video' ? 'video' : 'camera'
);
</script>

<template>
  <SoonaForm
    class="scene-builder"
    aria-labelledby="scene-builder-heading"
    @submit="createOrUpdateScene"
  >
    <div class="scene-builder__header">
      <div class="scene-builder__title">
        <SoonaIcon :name="iconName" size="medium" />
        <h4 id="scene-builder-heading">
          scene {{ editMode ? originalSceneNumber : shotListLength + 1 }}
        </h4>
      </div>
      <SceneBuilderActions
        :is-loading="
          isLoadingCreateShot ||
          isLoadingUpdateShot ||
          isLoadingEnvironmentFollowupOptions
        "
        @close-scene-builder="closeSceneBuilder"
      />
    </div>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <div class="scene-builder__content">
      <SceneSection component="fieldset">
        <template #heading>content type</template>
        <template #description>pick one</template>
        <SceneOption
          v-for="option in contentTypeOptions"
          :id="option.id"
          :key="option.id"
          :image-url="option.image_url"
          :checked="isSelected('contentType', option.id)"
          :disabled="isLoadingReservation"
          :value="option.id"
          type="radio"
          name="scene-content-type"
          is-lowercase
          @change="selectContentType"
        >
          {{ option.title }}
        </SceneOption>
      </SceneSection>

      <SceneSection component="fieldset">
        <template #heading>environment</template>
        <template #description>pick one</template>

        <SceneEnvironmentSelector
          v-model:environment-type="selectedShotOptions.environmentType"
          v-model:environment-followup="selectedShotOptions.environmentFollowup"
          :environment-type-options="environmentTypeOptions"
          :environment-followup-options="environmentFollowupOptions"
        />
      </SceneSection>

      <SceneBuilderCatalogItems
        v-model:selected-catalog-items="
          selectedShotOptions.selectedCatalogItems
        "
        :account-id="accountId"
        :reservation-id="reservationId"
      />

      <SceneBuilderSoonaProps
        v-if="soonaPropTagCategory"
        :selected-soona-prop-tags="selectedShotOptions.selectedSoonaPropTags"
        @remove-soona-prop="removeSoonaProp"
        @select-soona-props="selectSoonaProps"
      />

      <SoonaTextfield
        v-model="description"
        label="description"
        element="textarea"
        placeholder="notes on the goals for this scene"
      >
        <template #helper>optional</template>
      </SoonaTextfield>
    </div>

    <SoonaError v-if="priorityError" aria-hidden="true">
      {{ priorityError }}
    </SoonaError>
    <SceneBuilderActions
      :is-loading="isLoadingCreateShot || isLoadingUpdateShot"
      :is-footer="true"
      @close-scene-builder="closeSceneBuilder"
    />
  </SoonaForm>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.scene-builder {
  width: 100%;

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 1.5rem;
  }

  &__title {
    display: flex;
    flex-direction: row;
    gap: 0.375rem;
    align-items: center;

    h4 {
      @include variables_fonts.u-subheader--heavy;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
</style>

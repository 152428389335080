import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { postSearchTags } from '@/api/tags';
import { unref } from 'vue';

/**
 *
 * @param {Object} [params]
 * @param {ref} params.query
 * @param {string} params.sortBy
 * @param {string} params.sortDirection
 * @param {number} params.tagCategoryId
 * @param {string} params.tagCategorySlug
 * @param {UseQueryOptions} queryOptions
 */
export function useSearchTags(
  { tagCategoryId, tagCategorySlug, query, sortBy, sortDirection } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.tags({
      tagCategoryId,
      tagCategorySlug,
      query,
      sortBy,
      sortDirection,
    }),
    queryFn: ({ signal }) =>
      postSearchTags(
        {
          tagCategoryId: unref(tagCategoryId),
          tagCategorySlug: unref(tagCategorySlug),
          query: unref(query),
          sortBy: unref(sortBy),
          sortDirection: unref(sortDirection),
        },
        signal
      ),
    ...queryOptions,
  });
}

<script setup>
import { useRouter } from 'vue-router';
import TitleBar from 'src/components/shared/TitleBar.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import PageContainerOffsetTop from '@/components/platform-layout/PageContainerOffsetTop.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';

defineProps({
  pageName: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  loadingText: {
    type: String,
    required: true,
  },
});

const router = useRouter();

const handleBackClicked = () => {
  router.push('/admin/promotions');
};
</script>

<template>
  <div class="promotion-form-wrapper">
    <SoonaLoading
      :is-loading="loading"
      :is-dark="false"
      :loading-text="loadingText"
    />
    <PageContainerOffsetTop>
      <PageContainerOffset>
        <TitleBar
          class="promotion-form-wrapper__col-1"
          heading-id="add-on-order-edit-heading"
          :title="pageName"
          :previous-page="handleBackClicked"
        />
      </PageContainerOffset>
    </PageContainerOffsetTop>

    <div class="promotion-form-wrapper__main-container">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';
.promotion-form-wrapper {
  &__col-1 {
    margin-bottom: 1.5rem;
  }

  &__main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
  }
}
</style>

<script setup>
import { computed, ref, watch } from 'vue';
import { keepPreviousData } from '@tanstack/vue-query';
import { useEditsCollectionDigitalAssets } from '@/queries/edits_collection_digital_assets/useEditsCollectionDigitalAssets';
import CarouselBase from '@/components/user/anytime/gallery/media-editor/CarouselBase.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';

const props = defineProps({
  editsCollectionId: {
    type: [String, Number],
    required: true,
  },
  activeAssetId: {
    type: [String, Number],
    default: null,
  },
  albumTitle: {
    type: String,
    default: null,
  },
});

const activeAssetId = computed(() => props.activeAssetId);
const editsCollectionId = computed(() => props.editsCollectionId);
const currentPage = ref(null);
const userChangeablePage = ref(0);

watch([activeAssetId, editsCollectionId], () => {
  currentPage.value = null;
  userChangeablePage.value = 0;
});

const { data: filePages, isLoading } = useEditsCollectionDigitalAssets(
  editsCollectionId,
  {
    activeAssetId,
    currentPage,
    itemsPerPage: 12,
  },
  {
    placeholderData: keepPreviousData,
    enabled: computed(() => !!activeAssetId.value),
  }
);

watch(userChangeablePage, newValue => {
  if (newValue > 0) {
    currentPage.value = newValue;
  }
});
</script>

<template>
  <CarouselBase
    v-if="filePages?.assets?.length > 0 && !isLoading"
    :file-pages="filePages"
    @update-page="pageNum => (userChangeablePage = pageNum)"
  >
    <template #title>{{ albumTitle }} gallery</template>
    <router-link
      v-for="ecda in filePages?.assets"
      :key="ecda.id"
      class="carousel-base__img-link"
      :class="{
        'carousel-base__img-link--active':
          activeAssetId === ecda.digital_asset?.id,
      }"
      :to="{
        name: 'edits-assets-media-view',
        params: {
          accountId: ecda.digital_asset?.account_id,
          digitalAssetId: ecda.digital_asset?.id,
        },
      }"
    >
      <DigitalAssetImage :src="ecda.digital_asset?.preview?.url" />
    </router-link>
  </CarouselBase>
</template>

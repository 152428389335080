import { useDigitalAsset } from '@/queries/digital_assets/useDigitalAsset';

import { computed } from 'vue';
import { truncate } from '@/lib/text';
import { useReservationDigitalAsset } from '@/queries/reservation_digital_assets/useReservationDigitalAsset';

export function useMediaEditorDigitalAsset(accountId, assetId, params = {}) {
  const {
    data: asset,
    error,
    isLoading: assetLoading,
    isSuccess,
  } = useDigitalAsset(
    { accountId, assetId, params },
    {
      enabled: computed(() => !!accountId?.value && !!assetId?.value),
    }
  );

  const assetUuid = computed(() => asset.value?.uuid);
  const autoEditUrl = computed(() => asset.value?.auto_edit?.url);
  const editorSafeUrl = computed(() => asset.value?.editor_safe?.url);
  const previewUrl = computed(() => asset.value?.preview?.url);
  const watermarkUrl = computed(() => asset.value?.watermark?.url);
  const webUrl = computed(() => asset.value?.web?.url);
  const maskUrl = computed(() => asset.value?.mask?.url);
  const shadowsUrl = computed(() => asset.value?.shadows?.url);

  const _foreground = computed(() => asset.value?.foreground);
  const foregroundUrl = computed(() => _foreground.value?.url);
  const foregroundPreviewUrl = computed(() => _foreground.value?.preview?.url);
  const mediaUrl = computed(() => asset.value?.media?.url);
  const rawUrl = computed(() => asset.value?.raw?.url);
  const mediaWidth = computed(() => asset.value?.media?.width);
  const mediaHeight = computed(() => asset.value?.media?.height);
  const mediaType = computed(() => asset.value?.media_type);
  const aspectRatio = computed(() => mediaWidth.value / mediaHeight.value);
  const title = computed(() => asset.value?.title);
  const truncatedTitle = computed(() => truncate(title.value, 50, true));
  const assetTypePurchased = computed(
    () => asset.value?.asset_type === 'purchased'
  );

  const isOriginal = computed(() => asset.value?.asset_type === 'original');

  const visibilityAll = computed(() => asset.value?.visibility === 'all');

  const createdInEditor = computed(
    () => asset.value?.asset_type === 'platform'
  );
  const isPhoto = computed(
    () =>
      asset.value?.media_type === 'photo' &&
      asset.value?.content_type !== 'image/gif'
  );

  const isVideo = computed(() => asset.value?.media_type === 'video');

  const isGif = computed(() => asset.value?.media_type === 'animation');
  const isBagged = computed(
    () => !!asset.value?.bag_collection_digital_asset?.id
  );

  const isIndependentRawAsset = computed(
    () =>
      (!asset.value?.preview?.url && asset.value?.raw?.url) ||
      asset.value?.raw_only
  );

  const reservationId = computed(
    () => asset.value?.reservation_digital_asset?.reservation_id
  );

  const rdaId = computed(() => asset.value?.reservation_digital_asset?.id);

  const pendingEditorTasks = computed(
    () => (asset.value?.pending_editor_tasks || 0) > 0
  );

  const ownedByCustomer = computed(() => asset.value?.ownership === 'customer');
  const ownedBySoona = computed(() => asset.value?.ownership === 'soona');
  const userUploaded = computed(() => asset.value?.user_uploaded);
  const originatedByCustomer = computed(
    () => asset.value?.origin === 'customer'
  );
  const isSoonaCreatedInMediaEditor = computed(
    () =>
      asset.value?.origin === 'soona' && asset.value?.asset_type === 'platform'
  );
  const AIStudioCreation = computed(
    () => asset.value?.origin_source === 'mokker'
  );

  const inBag = computed(() => !!asset.value?.bag_collection_digital_asset?.id);
  const animatedCollectionId = computed(
    () => asset.value?.animated_collection?.id
  );

  const gifHasAlreadyBeenRecommended = computed(
    () => asset.value?.animated_collection?.recommended
  );

  const rotation = computed(() => asset.value?.rotation);
  const notRotated = computed(() => rotation.value === 0);

  const staffPickCDAId = computed(
    () => asset.value?.staff_picks_collection_digital_asset?.id
  );

  const expertPickId = computed(
    () => asset.value?.staff_picks_collection_digital_asset?.added_by_user_id
  );

  const favoriteCDAId = computed(
    () => asset.value?.favorites_collection_digital_asset?.id
  );

  const reEditsCDAId = computed(
    () => asset.value?.re_edits_collection_digital_asset?.id
  );

  const reEditsCollectionId = computed(
    () => asset.value?.re_edits_collection_digital_asset?.collection_id
  );

  const bagCollectionCdaId = computed(
    () => asset.value?.bag_collection_digital_asset?.id
  );

  // reservationDigitalAsset
  const {
    data: reservationDigitalAsset,
    isLoading: rdaLoading,
    isSuccess: rdaSuccess,
  } = useReservationDigitalAsset(
    {
      reservationId: reservationId,
      rdaId: rdaId,
    },
    {
      enabled: computed(() => !!rdaId.value && !!reservationId.value),
    }
  );

  return {
    asset,
    assetUuid,
    assetTypePurchased,
    autoEditUrl,
    editorSafeUrl,
    aspectRatio,
    createdInEditor,
    error,
    assetLoading,
    isSuccess,
    isVideo,
    isPhoto,
    isGif,
    isBagged,
    isOriginal,
    isIndependentRawAsset,
    previewUrl,
    title,
    truncatedTitle,
    foregroundUrl,
    foregroundPreviewUrl,
    maskUrl,
    shadowsUrl,
    watermarkUrl,
    webUrl,
    mediaUrl,
    rawUrl,
    mediaWidth,
    mediaHeight,
    mediaType,
    reservationId,
    rdaId,
    pendingEditorTasks,
    ownedByCustomer,
    ownedBySoona,
    inBag,
    originatedByCustomer,
    isSoonaCreatedInMediaEditor,
    AIStudioCreation,
    visibilityAll,
    userUploaded,
    animatedCollectionId,
    gifHasAlreadyBeenRecommended,
    rotation,
    notRotated,
    reservationDigitalAsset,
    staffPickCDAId,
    expertPickId,
    favoriteCDAId,
    reEditsCDAId,
    reEditsCollectionId,
    bagCollectionCdaId,
    rdaLoading,
    rdaSuccess,
  };
}

<script setup>
import { computed } from 'vue';
import SoonaPackDetail from '@/components/booking/SoonaPackDetail.vue';
const props = defineProps({
  pack: {
    type: Object,
    required: true,
  },
});

const pack = computed(() => props.pack);
</script>

<template>
  <div class="pack-preview">
    <SoonaPackDetail :pack="pack" :is-preview="true" />
    <div
      v-if="pack"
      class="pack-preview__photos"
      :aria-title="`${pack.name} example photos`"
    >
      <div v-for="(img, index) in pack.reference_images" :key="index">
        <video
          v-if="img.image_content_type?.startsWith('video/')"
          autoplay
          loop
          playsinline
          muted
        >
          <source :src="img.image_url" :type="img.image_content_type" />
        </video>
        <img v-else :src="img.image_url" :alt="`${pack.name} example photo`" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.pack-preview {
  width: 100%;
  max-width: 58.375rem;

  &__photos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-top: 1.5rem;
    width: 75%;

    > div {
      display: block;

      &:nth-child(8n - 7),
      &:nth-child(8n - 4) {
        grid-column: span 2;
        grid-row: span 2;
      }
    }

    img,
    video {
      display: block;
      border-radius: 0.625rem;
      object-fit: cover;
      width: 100%;
      height: 100%;
      background-color: variables.$gray-10;
    }
  }
}
</style>

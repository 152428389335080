<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import TitleBar from '@/components/shared/TitleBar.vue';
import ProductDetails from './ProductDetails.vue';

const props = defineProps({
  accountId: { type: String, required: true },
});

const accountId = computed(() => props.accountId);

const router = useRouter();
</script>

<template>
  <div class="create-product">
    <TitleBar
      back-button-copy="all products"
      title="new product"
      :previous-page="() => router.push(`/account/${accountId}/products`)"
      heading-id="create-product-heading"
    />
    <div class="create-product__body u-container">
      <div class="create-product__image">
        <img :src="PlaceholderImage" alt="placeholder product image" />
      </div>
      <div class="create-product__content">
        <ProductDetails :account-id="accountId" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.create-product {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex: 1 1 auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @media (min-width: variables.$screen-xs-min) {
    &__image {
      max-width: 23.75rem;
      margin: 1.5rem auto;
    }
  }

  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__body {
      flex-direction: row;
      gap: 1.5rem;
    }

    &__image {
      flex: 0 1 50%;
      max-width: 100%;
      margin: 0;
    }

    &__content {
      flex: 0 1 50%;
      width: 100%;
      margin: 0;
    }
  }

  // screen-lg-min = 1200px
  @media (min-width: variables.$screen-lg-min) {
    &__body {
      gap: 2.5rem;
    }

    &__image {
      flex: 0 1 60%;
    }

    &__content {
      flex: 0 1 40%;
    }
  }
}
</style>

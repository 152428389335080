import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {Ref<string | number>} accountId
 * @param {Object} [params]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.orderBy]
 * @param {string} [params.orderDir]
 * @param signal
 * @return {Promise<{pagination: {itemsPerPage: number, totalPages: number, totalCount: number, currentPage: number}, invoices: Object[]}>}
 */
export async function getInvoices(
  accountId,
  { currentPage, itemsPerPage, orderBy, orderDir } = {},
  signal
) {
  const params = {};

  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;
  if (itemsPerPage) params['order_by'] = orderBy;
  if (itemsPerPage) params['order_dir'] = orderDir;

  const response = await http.get(`/accounts/${accountId}/invoices`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    invoices: response.data,
  };
}

export async function getInvoice(accountId, id, signal) {
  const response = await http.get(`/accounts/${accountId}/invoices/${id}`, {
    signal,
  });

  return response.data;
}

<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useMe } from '@/composables/user/useMe';
import { useReservation } from '@/composables/useReservation';
import {
  useCreateShot,
  useSetShotListOrder,
  useShotList,
} from '@/queries/useShotList';
import CustomerScene from './scenes/CustomerScene.vue';
import PackHeader from './PackHeader.vue';
import ShotListScenes from './ShotListScenes.vue';
import SceneLimitDialog from './SceneLimitDialog.vue';
import ShotListDocuments from './ShotListDocuments.vue';
import ShotListEmptyState from './ShotListEmptyState.vue';
import ShotListHeader from './ShotListHeader.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import TwoDaysPriorAlertDialog from './TwoDaysPriorAlertDialog.vue';
import UploadShotListDialog from './UploadShotListDialog.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';

const route = useRoute();

const isPreUploadShotlist = ref(false);
const showUploadShotlistDialog = ref(false);
const showChangesDialog = ref(false);
const showSceneLimitDialog = ref(false);
const addScene = ref(false);

const reservationId = computed(() => route.params.reservationId);

const {
  data: shotListData,
  isLoading: isLoadingShotList,
  error: shotListError,
} = useShotList(reservationId);

const sceneList = computed(() => shotListData.value?.shots);
const hasScenes = computed(() => shotListData.value?.shots?.length > 0);
const attachments = computed(() => shotListData.value?.attachments);
const submittedAt = computed(() => shotListData.value?.submitted_date_time);
const reSubmittedAt = computed(
  () => shotListData.value?.re_submitted_date_time
);
const status = computed(() => shotListData.value?.shotlist_status);
const previousSceneNumber = computed(() => sceneList.value?.at(-1)?.order);

const hasAttachments = computed(
  () =>
    attachments.value?.documents?.length > 0 ||
    attachments.value?.urls?.length > 0
);

const {
  error: reservationError,
  estimatedPhotosNeeded,
  isLessThanTwoBusinessDaysBeforeShoot,
  isLoading: isLoadingReservation,
  isPack,
  isPendingShotlist,
  isPreShoot,
  lengthOfShoot,
  reservationAccountId,
  scheduledDateFormatted,
} = useReservation(reservationId);

const maxScenes = computed(() => {
  if (estimatedPhotosNeeded.value === '1 - 5') return 5;
  if (estimatedPhotosNeeded.value === '6 - 10') return 10;
  if (estimatedPhotosNeeded.value === '11 - 15') return 15;
  return 25;
});

const {
  mutate: createShot,
  isPending: isLoadingCreateShot,
  error: createShotError,
} = useCreateShot(reservationId);

const { me } = useMe();
const sceneToDuplicate = ref(null);

const duplicateScene = () => {
  const params = {
    description: sceneToDuplicate.value.description,
    shot_tags_attributes: sceneToDuplicate.value.shot_tags.map(d => ({
      tag_id: d.tag.id,
    })),
    tag_categories: [],
    created_by_user_id: me.value,
    catalog_items_shots_attributes: sceneToDuplicate.value.catalog_items.map(
      ci => ({
        catalog_item_id: ci.id,
      })
    ),
  };

  createShot(params, { onSuccess: () => (sceneToDuplicate.value = null) });
};

const handleDuplicate = scene => {
  sceneToDuplicate.value = scene;

  if (sceneList.value?.length >= maxScenes.value) {
    showSceneLimitDialog.value = true;
  } else {
    duplicateScene();
  }
};

const handleAddLink = (isCustomShotList = false) => {
  if (!scheduledDateFormatted.value) {
    showUploadShotlistDialog.value = true;
    return;
  }

  if (isLessThanTwoBusinessDaysBeforeShoot.value) {
    if (isCustomShotList) isPreUploadShotlist.value = true;

    showChangesDialog.value = true;
  } else {
    showUploadShotlistDialog.value = true;
  }
};

const handleCloseChangeDialog = () => {
  showChangesDialog.value = false;
  if (isPreUploadShotlist.value) showUploadShotlistDialog.value = true;
  else addScene.value = true;
};

const handleShowScene = () => {
  if (sceneList.value?.length >= maxScenes.value) {
    showSceneLimitDialog.value = true;
  } else if (isLessThanTwoBusinessDaysBeforeShoot.value) {
    showChangesDialog.value = true;
  } else {
    addScene.value = true;
  }
};

const handleLimitDialogConfirm = () => {
  showSceneLimitDialog.value = false;

  if (sceneToDuplicate.value) duplicateScene();
  else addScene.value = true;
};

const handleLimitDialogClose = () => {
  showSceneLimitDialog.value = false;
  if (sceneToDuplicate.value) sceneToDuplicate.value = null;
};

const handleExitSceneView = () => {
  addScene.value = false;
};

const isEditable = computed(
  () =>
    status.value === 'draft' && (isPreShoot.value || isPendingShotlist.value)
);

const {
  mutate: mutateShotListOrder,
  isPending: isSettingShotListOrder,
  error: setShotListOrderError,
} = useSetShotListOrder(reservationId);

function onUpdateSceneOrder(updatedScenes) {
  const shots = updatedScenes.map((shot, index) => ({
    id: shot.id,
    order: index + 1,
  }));

  mutateShotListOrder({ shots });
}

const isLoading = computed(
  () =>
    isLoadingCreateShot.value ||
    isLoadingReservation.value ||
    isLoadingShotList.value ||
    isSettingShotListOrder.value
);
const priorityErrors = usePriorityErrors(
  createShotError,
  shotListError,
  setShotListOrderError,
  reservationError
);
</script>

<template>
  <div class="shot-list">
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <SoonaLoading v-if="isLoading" is-loading />
    <div
      v-else-if="hasAttachments || addScene || hasScenes"
      class="shot-list__content"
    >
      <ShotListHeader
        :has-attachments="hasAttachments"
        :has-scenes="hasScenes"
        :is-editable="isEditable"
        :is-pack="isPack"
        :scene-builder-mode="addScene"
        :status="status"
        :submitted-at="submittedAt"
        :reservation-id="reservationId"
        :re-submitted-at="reSubmittedAt"
        @open-dialog="handleAddLink"
        @show-scenes="handleShowScene"
      />
      <ShotListDocuments
        v-if="hasAttachments"
        :reservation-id="reservationId"
        :shot-list-documents="attachments"
        :status="status"
      />
      <div v-if="hasScenes" class="shot-list__scene-wrapper">
        <PackHeader v-if="isPack" :reservation-id="reservationId" />
        <ShotListScenes
          :scenes="sceneList"
          :reservation-account-id="reservationAccountId"
          :reservation-id="reservationId"
          :is-editable="isEditable"
          :submitted-at="submittedAt"
          :is-pack="isPack"
          @update:scenes="onUpdateSceneOrder"
          @duplicate="handleDuplicate"
        />
        <SoonaButton
          v-if="!isPack && !addScene && isEditable"
          variation="secondary-black"
          @on-click="handleShowScene"
        >
          <SoonaIcon name="plus" /> add scene
        </SoonaButton>
      </div>
      <CustomerScene
        v-if="addScene"
        :previous-scene-number="previousSceneNumber"
        :reservation-id="reservationId"
        @exit-scene-view="handleExitSceneView"
      />
    </div>
    <ShotListEmptyState
      v-else
      :reservation-id="reservationId"
      :status="status"
      :submitted-at="submittedAt"
      :re-submitted-at="reSubmittedAt"
      @open-dialog="handleAddLink"
      @show-scenes="addScene = true"
    />
    <UploadShotListDialog
      v-if="showUploadShotlistDialog"
      :reservation-id="reservationId"
      :shot-list-documents="attachments"
      @close-dialog="showUploadShotlistDialog = false"
    />
    <TwoDaysPriorAlertDialog
      v-if="showChangesDialog"
      @close-dialog="handleCloseChangeDialog"
    />
    <SceneLimitDialog
      v-if="showSceneLimitDialog"
      :max-scenes="maxScenes"
      :shoot-length="lengthOfShoot?.displaySingular"
      @add-scene="handleLimitDialogConfirm"
      @close-dialog="handleLimitDialogClose"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.shot-list {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__content {
    width: 100%;
  }

  &__content-wrapper {
    width: 100%;
  }

  &__scene-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2rem;
  }
}
</style>

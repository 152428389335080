<script setup>
import { computed, ref, toRef } from 'vue';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import BookingLayout from '../BookingLayout.vue';
import ScenesContainer from 'src/components/booking/v3/p2/ScenesContainer.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import WarningIcon from 'src/components/svgs/WarningIcon.vue';
import { usePriorityError } from 'src/composables/usePriorityError';

// data & props
const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

let isModalOpen = ref(false);
let buttonsLoading = ref([]);

// api calls
const { data: trendSetOptionsData, error } = useReservationTags(
  toRef(props, 'reservationId'),
  toRef(props, 'stepName')
);

const {
  mutate,
  isPending: isMutating,
  error: updateError,
} = useUpdateReservation(toRef(props, 'reservationId'));

const priorityError = usePriorityError(updateError, error);

// computed
const trendSetOptions = computed(
  () => trendSetOptionsData.value?.options || []
);
const selectedOptions = computed(() => {
  return [...trendSetOptions.value].filter(o => o.selected);
});

const hasReachedMaxSelected = computed(() => {
  return selectedOptions.value?.length >= 2;
});

const isStepValid = computed(() => {
  return !isMutating.value;
});

// methods

function onBackClick() {
  props.onBack();
}

function onNextClick() {
  props.onNext();
}

function onSelectionChange(option) {
  const newReservationTag = {
    ...option.reservation_tag,
    _destroy: !option.reservation_tag._destroy,
  };

  const dataToSubmit = { reservation_tags_attributes: [newReservationTag] };

  mutate(dataToSubmit, {
    onSettled: () =>
      (buttonsLoading.value = buttonsLoading.value?.filter(
        o => o !== option.tag.id
      )),
    onSuccess: () => {
      if (option.tag.title === 'showoff' && option.reservation_tag._destroy) {
        isModalOpen.value = true;
      }
    },
  });
}

const updateButtonList = id => {
  if (buttonsLoading.value?.includes(id) || isMutating.value) return;

  buttonsLoading.value = [...buttonsLoading.value, id];
};
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isMutating"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="video-trend-sets prebooking-transition__content">
        <SoonaHeading title-element="h1">
          select your trend set
          <template #subtitle>
            pick up 2 options. need more? book another shoot.
          </template>
        </SoonaHeading>
        <ScenesContainer
          :buttons-loading="buttonsLoading"
          :has-border="false"
          :has-reached-max-selected="hasReachedMaxSelected"
          :is-mutating="isMutating"
          :on-click="onSelectionChange"
          :options="trendSetOptions"
          @update-button-list="updateButtonList"
        />
        <SoonaDialog v-if="isModalOpen" @close="() => (isModalOpen = false)">
          <template #heading>
            <strong class="video-trend-sets__recommendation">
              <WarningIcon aria-title="Warning" />
              hand model required
            </strong>
          </template>
          <p>
            if you’d like to feature hands showing off your product: add a hand
            model to your shoot on the upgrades page.
          </p>
          <template #footer>
            <SoonaButton
              data-cypress="button-dialog-confirm"
              @on-click="() => (isModalOpen = false)"
            >
              add showoff
            </SoonaButton>
          </template>
        </SoonaDialog>
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.video-trend-sets {
  color: variables.$black-default;

  &__grid {
    display: grid;
    gap: 1.5rem 1rem;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    @media (min-width: variables.$screen-sm-min) {
      gap: 1.5rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__recommendation {
    align-items: center;
    display: flex;

    svg {
      flex: 0 0 1.75rem;
      margin-right: 0.5rem;
    }
  }
}
</style>

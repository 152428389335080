<template>
  <div class="shipping">
    <div class="column soona-container">
      <div class="columns is-centered">
        <div class="column is-three-fifths">
          <h2 class="title has-text-centered is-size-4-mobile">
            homeward bound!
          </h2>

          <!-- soona address -->

          <p class="soona-title-a1 mb-m"><b>FROM:</b></p>

          <div class="location-address-container">
            <p class="is-size-7">soona</p>
            <p class="is-size-7">
              {{ [location.address1, location.address2].join(' ') }},
              {{ location.city }}, {{ location.state }} {{ location.zip }}
            </p>
          </div>

          <!-- address -->

          <form
            id="shipment-form"
            @submit.prevent="continueClicked"
            @keydown.enter.prevent
          >
            <p class="mt-l soona-title-a1 mb-m"><b>SHIP TO:</b></p>
            <SoonaTextfield
              v-model:model-value="shipment.address_to.name"
              cypress-name="shipment-name"
              label="name or company"
              placeholder="Company Name"
              required
            />
            <SoonaTextfield
              v-model:model-value="shipment.address_to.street1"
              cypress-name="shipment-address"
              label="street address"
              placeholder="123 Main St."
              required
            />
            <SoonaTextfield
              v-model:model-value="shipment.address_to.street2"
              cypress-name="shipment-suite"
              label="suite or apartment"
              placeholder="PO Box 456"
            >
              <template #helper>optional</template>
            </SoonaTextfield>
            <SoonaTextfield
              v-model:model-value="shipment.address_to.city"
              cypress-name="shipment-city"
              label="city"
              placeholder="city"
              required
            />

            <div class="return-shipment__state-zip-wrapper">
              <SoonaTextfield
                v-model:model-value="shipment.address_to.state"
                class="return-shipment__state-zip-input"
                cypress-name="shipment-state"
                label="state"
                maxlength="2"
                placeholder="state"
                required
              />
              <SoonaTextfield
                v-model:model-value="shipment.address_to.zip"
                class="return-shipment__state-zip-input"
                cypress-name="shipment-zip"
                label="zip code"
                pattern="[0-9]{5}"
                placeholder="00000"
                required
              />
            </div>

            <p class="soona-title-a1 mt-l mb-m"><b>RETURNABLE PACKAGES:</b></p>

            <div class="location-address-container">
              <div
                v-for="(inventoryPackage, index) in returnablePackages"
                :key="inventoryPackage.id"
                class="mb-s"
              >
                <p v-if="returnablePackages.length > 1" class="is-size-7">
                  package #{{ index + 1 }}
                </p>
                <p v-else class="is-size-7">package details</p>
                <p class="is-size-7">
                  {{ formatDetails(inventoryPackage) }}
                </p>
                <p class="is-size-7">
                  {{ inventoryPackage.description }}
                </p>
              </div>
            </div>

            <!-- submit shipment -->
            <div class="return-shipment__action-btns">
              <SoonaButton :is-loading="isSubmitting" type="submit">
                continue
              </SoonaButton>
              <SoonaButton variation="tertiary" @on-click="onCancel">
                cancel
              </SoonaButton>
            </div>
          </form>

          <!-- invalid address and address verification modals -->
          <SoonaDialog
            v-if="errorMessage.verify == false && showInvalidAddressModal"
            @close="
              () => {
                showInvalidAddressModal = false;
                isSubmitting = false;
              }
            "
          >
            <template #heading>ope! please try again.</template>
            <p class="mt-m is-lowercase">{{ errorMessage.text }}</p>
            <template #footer="{ close }">
              <SoonaButton
                data-cypress="button-dialog-confirm"
                @on-click="close"
              >
                try again
              </SoonaButton>
            </template>
          </SoonaDialog>

          <SoonaDialog
            v-if="errorMessage.verify == true && showVerifyAddressModal"
            @close="
              () => {
                requestShipment();
                showVerifyAddressModal = false;
              }
            "
          >
            <template #heading>did you mean?</template>
            <div class="location-address-container mt-m">
              <p class="is-size-7">{{ errorMessage.address.name }}</p>
              <p class="is-size-7">
                {{ errorMessage.address.street1 }}
                {{ errorMessage.address.street2 }}
              </p>
              <p class="is-size-7">
                {{ errorMessage.address.city }},
                {{ errorMessage.address.state }}
                {{ errorMessage.address.zip }}
              </p>
            </div>

            <template #footer="{ close }">
              <SoonaButton
                variation="tertiary"
                data-cypress="button-dialog-cancel"
                @on-click="close"
              >
                no thanks
              </SoonaButton>
              <SoonaButton
                data-cypress="button-dialog-confirm"
                @on-click="
                  () => {
                    updateAddressFromAndRequestShipment();
                    showVerifyAddressModal = false;
                  }
                "
              >
                yes, please
              </SoonaButton>
            </template>
          </SoonaDialog>
        </div>

        <div
          class="ml-l shipping-directions-container column is-two-fifths has-text-centered"
        >
          <img
            src="@images/anim/shipment.svg"
            alt=""
            class="lottie-container"
          />
          <div class="has-text-centered">
            <p class="u-body--heavy mb-m">
              <span> we are sending back: </span>
            </p>
            <p class="u-body--regular">
              items from the selected packages! purchase your label and we will
              send them back in the mail.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'ReturnShipment',
  components: { SoonaButton, SoonaDialog, SoonaTextfield },
  data() {
    return {
      shipment: {
        address_to: {
          name: '',
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: '',
          country: 'US',
        },
        parcels: [],
      },
      isSubmitting: false,
      validatedAddress: undefined,
      showVerifyAddressModal: false,
      showInvalidAddressModal: false,
      errorMessage: {
        verify: undefined,
        address: {
          name: '',
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: '',
          country: 'US',
        },
        text: '',
      },
      dropdownActive: false,
    };
  },
  computed: {
    ...mapGetters('inventoryPackages', ['returnablePackages']),
    ...mapGetters('currentUser', ['currentAccount']),
    invalidOrIncompleteValidateAddress() {
      return (
        this.validatedAddress.is_complete == false ||
        this.validatedAddress.validation_results.is_valid == false
      );
    },
    suggestedChangesInValidateAddress() {
      return this.validatedAddress.validation_results.messages.length > 0;
    },
    location() {
      // assumes all packages are in the same location
      return this.returnablePackages[0].location;
    },
  },
  beforeMount() {
    if (this.returnablePackages[0]?.account_id != this.currentAccount.id) {
      this.prefillAddress();
    }
  },
  methods: {
    ...mapActions('reservation', ['createShipment', 'validateAddress']),
    async continueClicked() {
      if (this.checkValidityOfRequiredInputs() && !this.isSubmitting) {
        this.isSubmitting = true;

        let response = await this.validateAddress({
          reservationId: this.returnablePackages[0].latest_reservation.id,
          address: this.shipment.address_to,
        });
        this.validatedAddress = response.data;

        if (this.invalidOrIncompleteValidateAddress) {
          this.invalidAddress();
        } else if (this.suggestedChangesInValidateAddress) {
          this.verificationNeeded();
        } else {
          this.requestShipment();
        }
      }
    },
    checkValidityOfRequiredInputs() {
      let validity = document.getElementById('shipment-form');
      return validity.checkValidity();
    },
    invalidAddress() {
      this.showInvalidAddressModal = true;

      // Note that Shippo currently returns only one (1) message on failure
      // even if multiple fields are invalid
      this.errorMessage = {
        verify: false,
        text: this.validatedAddress.validation_results.messages[0].text,
      };

      this.isSubmitting = false;
    },
    verificationNeeded() {
      this.showVerifyAddressModal = true;

      this.errorMessage = {
        verify: true,
        address: {
          name: this.validatedAddress.name,
          street1: this.validatedAddress.street1,
          street2: this.validatedAddress.street2,
          city: this.validatedAddress.city,
          state: this.validatedAddress.state,
          zip: this.validatedAddress.zip,
          country: this.validatedAddress.country,
        },
      };
    },
    updateAddressFromAndRequestShipment() {
      let updatedAddressFrom = {
        name: this.validatedAddress.name,
        street1: this.validatedAddress.street1,
        street2: this.validatedAddress.street2,
        city: this.validatedAddress.city,
        state: this.validatedAddress.state,
        zip: this.validatedAddress.zip,
        country: this.validatedAddress.country,
      };

      this.shipment.address_to = updatedAddressFrom;
      this.requestShipment();
    },
    requestShipment() {
      let shippoParcel = {
        width: this.returnablePackages[0].width,
        height: this.returnablePackages[0].height,
        length: this.returnablePackages[0].length,
        weight: this.returnablePackages[0].weight,
        mass_unit: 'lb',
        distance_unit: 'in',
      };

      this.createShipment({
        reservationId: this.returnablePackages[0].latest_reservation.id,
        address_from: {
          name: `soona REF( ${this.returnablePackages[0].latest_reservation.id} )`,
          street1: this.location.address1,
          street2: this.location.address2,
          city: this.location.city,
          state: this.location.state,
          zip: this.location.zip,
          country: 'US',
          phone: this.location.phone,
          email: this.location.email,
        },
        address_to: this.shipment.address_to,
        parcels: shippoParcel,
        packagesRemaining: this.returnablePackages.length - 1,
      });
    },
    onCancel() {
      this.$router.push({
        name: 'inventory',
        params: { accountId: this.returnablePackages[0]?.account_id },
      });
    },
    formatDetails(inventoryPackage) {
      return `${inventoryPackage.width} in x ${inventoryPackage.height} in x ${inventoryPackage.length} in | ${inventoryPackage.weight} lbs`;
    },
    prefillAddress() {
      if (
        !this.returnablePackages[0]?.inbound_shipping_detail?.customer_address
      )
        return;

      this.shipment = {
        ...this.shipment,
        address_to: {
          ...this.returnablePackages[0].inbound_shipping_detail
            .customer_address,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.return-shipment {
  &__state-zip-wrapper {
    display: flex;
    gap: 1rem;
  }

  &__state-zip-input {
    &:last-child {
      flex-grow: 1;
    }
  }

  &__action-btns {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding-top: 1.5rem;
  }
}

.shipping-directions-container {
  align-content: center;
  background-color: variables.$tangerine-20;
  display: flex;
  flex-wrap: wrap;
  height: 43.75rem;
  justify-content: center;
  padding: 6%;
  text-align: center;

  img {
    height: 18.75rem;
    width: 12.5rem;
  }
}
</style>

import { computed, toValue } from 'vue';

export function usePriorityErrors(...errors) {
  return computed(() => {
    const errorsArray = [];

    for (let i = 0; i < errors.length; i++) {
      const err = toValue(errors[i]);

      if (!err) continue;

      if (err.response?.status === 403) {
        return [
          {
            message:
              'this page is private. if someone gave you this link you’ll need to sign into an account which has access to this page.',
          },
        ];
      }

      // look for errors somewhere in the response
      const maybeErrors = [];
      if (err.response?.data) {
        if (
          typeof err.response.data === 'string' &&
          !err.response.headers?.['content-type']?.includes('text/html')
        ) {
          maybeErrors.push(err.response.data);
        } else if (Array.isArray(err.response.data)) {
          maybeErrors.push(err.response.data);
        } else if (typeof err.response.data === 'object') {
          maybeErrors.push(
            err.response.data.errors,
            err.response.data.error,
            err.response.data.message
          );
        }
      }

      const currentErrors = [];

      maybeErrors.filter(Boolean).forEach(maybeError => {
        if (maybeError) {
          if (Array.isArray(maybeError)) {
            if (maybeError.every(e => typeof e === 'string')) {
              // array of strings
              currentErrors.push(...maybeError.map(e => ({ message: e })));
            } else if (
              maybeError.every(e => typeof e === 'object' && 'message' in e)
            ) {
              // array of objects with a message property
              currentErrors.push(...maybeError);
            }
          } else if (typeof maybeError === 'string') {
            // a string
            currentErrors.push({ message: maybeError });
          } else if (
            typeof maybeError === 'object' &&
            'message' in maybeError
          ) {
            // an object with a message property
            currentErrors.push(maybeError);
          }
        }
      });

      // an object at `.errors` without a message property. standard Rails validation messages
      if (
        typeof err.response?.data?.errors === 'object' &&
        !err.response.data.errors.message
      ) {
        const validationErrors = Object.entries(err.response.data.errors)
          .filter(
            ([, vals]) =>
              Array.isArray(vals) && vals.every(v => typeof v === 'string')
          )
          .map(([key, vals]) => ({
            message: `${key.replaceAll('_', ' ')} ${vals.join(', ')}`,
          }));
        currentErrors.push(...validationErrors);
      }

      if (currentErrors.length === 0 && err.message) {
        // if we don't have any nice errors, use the AxiosError message
        currentErrors.push({ message: err.message });
      }

      if (currentErrors.length > 0) {
        errorsArray.push(...currentErrors);
      }
    }

    return errorsArray.length > 0 ? errorsArray : null;
  });
}

<script setup>
import { computed, watch, inject, useTemplateRef } from 'vue';
import { autoUpdate, flip, useFloating, size, shift } from '@floating-ui/vue';
import { useIntersectionObserver } from '@vueuse/core';

defineProps({
  disableFloatingUi: {
    type: Boolean,
    default: false,
  },
});

const {
  open,
  listboxId,
  comboboxRef,
  highlightedElementID,
  ariaActivedescendantID,
} = inject('comboboxContext');

const listboxContentRef = useTemplateRef('listboxContentRef');

watch(listboxContentRef, async ref => {
  if (!ref) return;
  const firstOption = ref.firstElementChild.id;
  if (ariaActivedescendantID.value) {
    highlightedElementID.value = ariaActivedescendantID.value;
  } else {
    highlightedElementID.value = firstOption;
  }
});

const target = computed(() =>
  document.getElementById(highlightedElementID.value)
);

useIntersectionObserver(
  target,
  ([{ isIntersecting }]) => {
    if (!isIntersecting) {
      target.value.scrollIntoView({ block: 'nearest' });
    }
  },
  {
    threshold: 1,
  }
);

const { floatingStyles, placement } = useFloating(
  comboboxRef,
  listboxContentRef,
  {
    whileElementsMounted(...args) {
      const cleanup = autoUpdate(...args, { animationFrame: true });
      return cleanup;
    },
    middleware: [
      flip(),
      size({
        apply({ availableHeight, elements }) {
          const heightInRem = availableHeight / 16;
          Object.assign(elements.floating.style, {
            maxHeight: `${heightInRem - 4}rem`,
          });
        },
      }),
      shift(),
    ],
    placement: 'bottom-start',
    open,
  }
);

defineExpose({ placement });
</script>

<template>
  <Transition name="menu-fade">
    <div
      v-if="open"
      :id="listboxId"
      ref="listboxContentRef"
      class="combobox-listbox"
      role="listbox"
      :style="disableFloatingUi ? null : floatingStyles"
    >
      <slot />
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.combobox-listbox {
  z-index: 20;
  overflow-y: auto;
  position: absolute;
  padding: 0.25rem 0;
  min-width: 12.5rem;
  margin-top: 0.25rem;
  box-sizing: border-box;
  border-radius: 0.3125rem;
  box-shadow: variables.$elevation-3;
  transition: opacity 0.075s ease-in-out;
  background-color: variables.$white-default;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  &.menu-fade-enter-from,
  &.menu-fade-leave-to {
    opacity: 0;
  }
}
</style>

<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { computed } from 'vue';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const { addToast } = useSoonaToast();

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId } = storeToRefs(mediaEditorStore);

const { visibilityAll } = useMediaEditorDigitalAsset(assetAccountId, assetId);

const { mutate: updateDigitalAsset, isPending: isUpdatingDigitalAsset } =
  useUpdateDigitalAsset(assetAccountId);

const isLoading = computed(() => !!isUpdatingDigitalAsset.value);

const updateVisibility = visibility => {
  updateDigitalAsset(
    {
      assetId: assetId.value,
      body: {
        visibility,
      },
    },
    {
      onError: () => {
        const msgVerb = visibility === 'crew' ? 'hiding' : 'un-hiding';
        addToast(`error ${msgVerb} asset`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};
</script>
<template>
  <SoonaButton
    :loading="isLoading"
    :disabled="isLoading"
    variation="secondary-gray"
    @on-click="() => updateVisibility(visibilityAll ? 'crew' : 'all')"
  >
    {{ visibilityAll ? 'hide' : 'show' }}
  </SoonaButton>
</template>

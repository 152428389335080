import { track } from '@/lib/segment/segment';
import { useCurrentReservationAndUser } from '@/composables/segment/useCurrentReservationAndUser';

export function useBulkCreditEvents() {
  const { userTraits } = useCurrentReservationAndUser();

  /**
   * Bulk Credits Started
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   */
  const bulkCreditsStarted = ({ context, subContext } = {}) => {
    const payload = {
      context,
      subContext,
      ...userTraits.value,
    };
    track('Bulk Credits Started', payload);
  };

  /**
   * Bulk Credits Checkout Started
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {object} products the selected product(s)
   */
  const bulkCreditsCheckoutStarted = ({
    context,
    subContext,
    products,
  } = {}) => {
    const payload = {
      context,
      subContext,
      products,
      ...userTraits.value,
    };
    track('Bulk Credits Checkout Started', payload);
  };

  return {
    bulkCreditsStarted,
    bulkCreditsCheckoutStarted,
  };
}

<script setup>
import { computed } from 'vue';
import BookingCrewActions from 'src/components/user/anytime/crew/BookingCrewActions.vue';
import CreateCrewAssignment from 'src/components/user/anytime/crew/CreateCrewAssignment.vue';
import Timeline from 'src/components/user/anytime/crew/Timeline.vue';
import ShootDetails from 'src/components/user/anytime/ShootDetails.vue';
import AvailableAccountCredits from 'src/components/user/anytime/credits/AvailableAccountCredits.vue';
import PostShootDetails from 'src/components/user/anytime/crew/PostShootDetails.vue';
import { useCapability } from '@/composables/useCapability';
import { useReservation } from '@/queries/useReservation';
import AccountRequirements from '@/components/crew/account-requirements/AccountRequirements.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  reservationId: {
    type: String,
    required: true,
  },
  crewAssignments: {
    type: Array,
    default: () => [],
  },
  isChatOpen: {
    type: Boolean,
    default: false,
  },
  userHasScrolledPastBreakpoint: {
    type: Boolean,
    default: false,
  },
});

const toasterAccountRequirementsFlag = useFlag('toaster_account_requirements');

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const reservationId = computed(() => Number(props.reservationId));

const { data: reservation } = useReservation(reservationId);

const accountId = computed(() => reservation.value?.account_id);
const currentPage = computed(() => 'bookingPage');
</script>

<template>
  <div class="reservation-crew">
    <div class="reservation-crew__left">
      <BookingCrewActions :reservation-id="reservationId" />
      <AccountRequirements
        v-if="toasterAccountRequirementsFlag && accountId"
        :account-id="accountId"
      />
      <Timeline :reservation-id="reservationId" />
    </div>
    <div class="reservation-crew__right">
      <h3 class="level-left u-body--heavy is-size-7-mobile">shoot details:</h3>
      <ShootDetails />
      <h3 class="level-left u-body--heavy is-size-7-mobile">crew members:</h3>
      <CreateCrewAssignment
        :reservation-id="reservationId"
        :reservation-start="reservation?.start"
        :reservation-end="reservation?.end"
      />
      <hr />
      <h3 class="level-left u-body--heavy is-size-7-mobile">
        post shoot status:
      </h3>
      <PostShootDetails v-if="isSoonaStaff" />
    </div>
  </div>
  <AvailableAccountCredits
    :account-id="accountId"
    :current-page="currentPage"
    :reservation-id="reservationId"
  />
</template>

<style lang="scss" scoped>
.reservation-crew {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;

  &__left {
    flex: 1 1 70%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__right {
    flex: 1 1 15rem;
  }
}
</style>

import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { updateQuest } from '@/api/quests';

/**
 *
 * @param {number | Ref<Object>} questId
 */
export function useUpdateQuest(questId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => updateQuest(unref(questId), body),
    onSuccess: async response => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.quest(response.id),
      });
    },
  });
}

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  sections: {
    type: Array,
    required: true,
  },
  currentPage: {
    type: String,
    required: true,
  },
  isMutating: {
    type: Boolean,
    required: true,
  },
  hasUnsavedChanges: {
    type: Boolean,
    required: true,
  },
  packNameEditMode: {
    type: Boolean,
    required: true,
  },
});

defineEmits(['discard-changes', 'save-and-exit', 'save']);

const router = useRouter();

const currentPageIndex = computed(() =>
  props.sections.indexOf(props.sections.find(x => x.slug === props.currentPage))
);

const nextPage = computed(() => props.sections[currentPageIndex.value + 1]);

const previousPage = computed(() => props.sections[currentPageIndex.value - 1]);

const previousClicked = () => {
  router.push(previousPage.value.to);
  window.scrollTo(0, 0);
};

const nextClicked = () => {
  router.push(nextPage.value.to);
  window.scrollTo(0, 0);
};
</script>

<template>
  <footer class="pack-builder-footer">
    <div class="pack-builder-footer__upper">
      <SoonaButton
        v-if="previousPage"
        class="pack-builder-footer__back"
        :on-click="previousClicked"
        variation="tertiary"
      >
        <SoonaIcon name="arrow-left" /> {{ previousPage.name }}
      </SoonaButton>
      <SoonaButton
        v-if="nextPage"
        class="pack-builder-footer__next"
        :disabled="nextPage.name === 'reservation summary' && packNameEditMode"
        :on-click="nextClicked"
        variation="tertiary"
      >
        {{ nextPage.name }}<SoonaIcon name="arrow-right" />
      </SoonaButton>
    </div>
    <hr />
    <div class="pack-builder-footer__lower">
      <SoonaButton
        v-if="hasUnsavedChanges"
        variation="tertiary"
        @on-click="$emit('discard-changes')"
      >
        discard unsaved changes
      </SoonaButton>
      <SoonaButton
        variation="secondary-black"
        :loading="isMutating"
        :disabled="isMutating || packNameEditMode"
        @on-click="$emit('save-and-exit')"
      >
        save & exit
      </SoonaButton>
      <SoonaButton
        variation="primary"
        :loading="isMutating"
        :disabled="isMutating || packNameEditMode"
        @on-click="$emit('save')"
      >
        save
      </SoonaButton>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@use '@/variables';

.pack-builder-footer {
  &__upper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
    padding: 3rem 1rem;
    margin: 0 auto;
    max-width: 34.875rem;
    width: 100%;
  }

  &__back,
  &__next {
    &:not(:disabled):hover,
    &:focus-visible {
      color: variables.$black-default;
    }
  }

  hr {
    background: variables.$gray-30;
    height: 0.0625rem;
    margin: 0;
  }

  &__lower {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: flex-end;
    padding: 1.5rem;
    margin: 0 auto;
    max-width: 34.875rem;
  }
}
</style>

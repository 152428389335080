<script setup>
import { ref, computed } from 'vue';
import { toMimeTypes } from '@/lib/mime-type-mapping';
import uniqueId from 'lodash/uniqueId';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';

const props = defineProps({
  accept: {
    type: String,
    default: undefined,
  },
  activeUploads: {
    type: Array,
    required: true,
  },
  hideCloudIcon: {
    type: Boolean,
    default: false,
  },
  isMultiple: {
    type: Boolean,
    default: false,
  },
  label: {
    default: 'upload media',
    type: String,
  },
  labelIcon: {
    default: null,
    type: String,
  },
  preventDragoverAnimation: {
    type: Boolean,
    default: false,
  },
  suppressDefaultContent: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['drop', 'upload']);

const accept = computed(() => props.accept);
const activeUploads = computed(() => props.activeUploads);
const isMultiple = computed(() => props.isMultiple);

const dragAndDropHelpTextId = uniqueId('drag-drop-help-text-');
const alertMessageId = uniqueId('alert-message-');

const acceptArray = computed(() => {
  if (!accept.value) return undefined;
  return accept.value.split(',').map(item => item.trim());
});
const isDragover = ref(false);
const alertMessage = ref('');

const isInvalid = files => {
  alertMessage.value = '';
  if (!isMultiple.value && activeUploads.value.length + files.length > 1) {
    alertMessage.value = 'upload files individually';
    return true;
  }

  if (!acceptArray.value) return false;

  const acceptedMimeTypes = toMimeTypes(acceptArray.value);

  return files.some(file => {
    if (!file.type) {
      // no file type present, fall back to check if file ext is in acceptArray
      const extRe = /(?:\.([^.]+))?$/;
      const fileExt = extRe.exec(file.name)[1];
      if (
        !fileExt ||
        !acceptArray.value.includes(`.${fileExt.toLowerCase()}`)
      ) {
        alertMessage.value = `only ${acceptArray.value.join(
          ', '
        )} files allowed`;
        return true;
      }
    } else if (!acceptedMimeTypes.includes(file.type.toLowerCase())) {
      // check file.type against accepted MIME type values
      alertMessage.value = `only ${acceptArray.value.join(', ')} files allowed`;
      return true;
    }
  });
};

const handleUpload = e => {
  if (isInvalid(Array.from(e.target.files))) return;
  emits('upload', e);
};

const handleDrop = e => {
  if (isInvalid([...e.dataTransfer.files])) return;
  emits('drop', e);
};
</script>

<template>
  <form
    class="soona-upload-form"
    :class="{
      'soona-upload-form--dragover': isDragover && !preventDragoverAnimation,
    }"
    method="post"
    action=""
    enctype="multipart/form-data"
    @drag.prevent.stop
    @dragstart.prevent.stop
    @dragover.prevent.stop="isDragover = true"
    @dragenter.prevent.stop="isDragover = true"
    @dragleave.prevent.stop="isDragover = false"
    @dragend.prevent.stop="isDragover = false"
    @drop.prevent.stop="handleDrop"
    @submit.prevent
  >
    <slot name="content-top" />
    <template v-if="!suppressDefaultContent">
      <SoonaIcon
        v-if="!hideCloudIcon"
        name="cloud-up-arrow"
        class="soona-upload-form__icon"
      />
      <label class="soona-upload-form__button">
        <input
          class="u-visually-hidden"
          type="file"
          name="resume"
          :aria-describedby="`${dragAndDropHelpTextId} ${alertMessageId}`"
          :accept="accept"
          :multiple="isMultiple"
          @change="handleUpload"
        />
        <SoonaIcon v-if="labelIcon" :name="labelIcon" /> {{ label }}
      </label>
      <p :id="dragAndDropHelpTextId" class="u-label--regular">
        or drag-and-drop
      </p>
    </template>
    <SoonaAlert v-if="alertMessage" :id="alertMessageId" no-margin>
      {{ alertMessage }}
    </SoonaAlert>
    <slot name="content-bottom" />
  </form>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 12.25rem;
  gap: 0.5rem;
  border-radius: 0.625rem;
  padding: 2rem 1rem;
  background-color: variables.$periwink-blue-10;
  outline: 0.125rem dashed variables.$gray-50;
  outline-offset: -0.125rem;
  transition:
    outline-offset 0.1s ease-in-out,
    outline-color 0.1s ease-in-out;

  &--dragover {
    outline-offset: -0.375rem;
    outline-color: variables.$periwink-blue-50;
  }

  &__icon {
    width: 3rem;
    height: 3rem;
    color: variables.$periwink-blue-80;
    background-color: variables.$periwink-blue-20;
    border-radius: 1.5rem;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
  }

  &__button {
    @include variables_fonts.u-body--heavy;

    align-items: center;
    display: flex;
    gap: 0.25rem;
    padding: 0.4375rem 0.9375rem;
    border: 0.0625rem solid variables.$black-default;
    border-radius: 0.3125rem;
    cursor: pointer;
    transition:
      background-color 0.1s ease-out,
      color 0.1s ease-out,
      border-color 0.1s ease-out;
    user-select: none;
    outline-width: 0.125rem;
    outline-color: variables.$periwink-blue-60;
    background-color: variables.$white-default;
    color: variables.$black-default;

    &:hover {
      background-color: variables.$gray-20;
    }

    &:active {
      background-color: variables.$gray-30;
    }

    &:focus-within:has(:focus-visible) {
      outline-style: solid;
    }

    @supports not selector(:has(:focus-visible)) {
      &:focus-within {
        outline-style: solid;
      }
    }
  }
}
</style>

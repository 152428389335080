<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'StyleQuiz',
  computed: {
    ...mapState({
      account: state => state.account,
    }),
  },
  beforeMount() {
    this.loadStyleQuizPhotos();
    this.loadStyleQuizWords();
  },
  methods: {
    ...mapActions('styleQuiz', ['loadStyleQuizPhotos', 'loadStyleQuizWords']),
  },
};
</script>

<script setup>
import { computed } from 'vue';
import { getMarkRange, getMarkType } from '@tiptap/core';

const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
});

const data = computed(() => {
  const existingHref = props.editor.isActive('link')
    ? props.editor.getAttributes('link').href
    : '';
  const linkRange = getMarkRange(
    props.editor.state.selection.$from,
    getMarkType('link', props.editor.schema)
  );
  const linkText = linkRange
    ? props.editor.state.doc.textBetween(linkRange.from, linkRange.to)
    : '';

  return {
    label: linkText,
    href: existingHref,
  };
});
</script>

<template>
  <div>
    <div>{{ data.label }}</div>

    <a :href="data.href" target="_blank" rel="noopener noreferrer">
      {{ data.href }}
    </a>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

a {
  text-decoration: underline;
  color: variables.$periwink-blue-70;

  &:hover {
    color: variables.$black-default;
    transition: color 0.1s ease-out;
  }

  &:active {
    color: variables.$periwink-blue-80;
  }
}
</style>

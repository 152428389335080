<template>
  <figure
    v-if="imageList"
    class="soona-tag-option-card-modal-grid"
    :class="imageWrapperClass"
  >
    <span
      v-for="(image, i) in imagesToRender"
      :key="`${imageId}-${i}`"
      class="soona-tag-option-card-modal-grid__image-wrapper"
      :class="imageClass"
    >
      <img
        class="soona-tag-option-card-modal-grid__image"
        :src="image.src"
        :alt="image.alt"
      />
      <caption
        v-if="i === 0 && displayProServiceTag"
        class="soona-tag-option-card-modal-grid__image-tag"
      >
        without
        {{
          proServiceName
        }}
      </caption>
      <caption
        v-if="i === 1 && displayProServiceTag"
        class="soona-tag-option-card-modal-grid__image-tag"
      >
        with
        {{
          proServiceName
        }}
      </caption>
    </span>
  </figure>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
export default {
  props: {
    imageList: {
      default: () => [], // FORMAT: { src: require('images/booking/food.jpg'), alt: 'food' }
      required: false,
      type: Array,
    },
    hasProServiceComparison: {
      default: false,
      required: false,
      type: Boolean,
    },
    proServiceName: {
      default: undefined,
      required: false,
      type: String,
    },
  },
  data() {
    const baseId = uniqueId();
    return {
      imageId: `model-modal-image-${baseId}`,
    };
  },
  computed: {
    displayProServiceTag() {
      if (
        this.imageList.length >= 6 &&
        this.hasProServiceComparison &&
        this.proServiceName
      )
        return true;
      return false;
    },
    imageWrapperClass() {
      if (this.imageList.length >= 6 && this.hasProServiceComparison)
        return 'soona-tag-option-card-modal-grid--sextuple-compare';
      if (this.imageList.length >= 6)
        return 'soona-tag-option-card-modal-grid--sextuple';
      if (this.imageList.length >= 4)
        return 'soona-tag-option-card-modal-grid--quadruple';
      if (this.imageList.length >= 2)
        return 'soona-tag-option-card-modal-grid--double';
      return undefined;
    },
    imageClass() {
      if (this.imageList.length >= 6 && this.hasProServiceComparison)
        return 'soona-tag-option-card-modal-grid__image--sextuple-compare';
      if (this.imageList.length >= 6)
        return 'soona-tag-option-card-modal-grid__image--sextuple';
      if (this.imageList.length >= 4)
        return 'soona-tag-option-card-modal-grid__image--quadruple';
      if (this.imageList.length >= 2)
        return 'soona-tag-option-card-modal-grid__image--double';
      return undefined;
    },
    imagesToRender() {
      if (this.imageList.length >= 6) return this.imageList.slice(0, 6);
      if (this.imageList.length >= 4) return this.imageList.slice(0, 4);
      if (this.imageList.length >= 2) return this.imageList.slice(0, 2);
      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-tag-option-card-modal-grid {
  display: grid;
  gap: 1rem;
  height: 100%;
  max-height: 20rem;

  &--double {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 150px);
  }

  &--quadruple {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(9, 50px);
  }

  &--sextuple {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(12, 50px);
  }

  &--sextuple-compare {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(8, 75px);
  }

  @media (min-width: variables.$screen-sm-min) {
    &--double {
      grid-template-columns: 1fr 1fr;
    }

    &--quadruple {
      grid-template-columns: 1fr 0.8fr 1.6fr;
      grid-template-rows: 1.1fr 1.1fr;
    }

    &--sextuple {
      grid-template-columns: repeat(10, 1fr);
      grid-template-rows: repeat(5, 1.1fr);
    }

    &--sextuple-compare {
      grid-template-columns: repeat(22, 1fr);
      grid-template-rows: repeat(5, 1.1fr);
    }
  }

  &__image-wrapper {
    position: relative;
  }

  &__image {
    border-radius: 0.625rem;
    object-fit: cover;
    height: 100%;
    width: 100%;

    &--double {
      &:first-child {
        grid-area: 1 / 1 / 2 / 2;
      }

      &:nth-child(2) {
        grid-area: 2 / 1 / 4 / 2;
      }
    }

    &--quadruple {
      &:first-child {
        grid-area: 1 / 1 / 5 / 3;
      }

      &:nth-child(2) {
        grid-area: 1 / 3 / 3 / 5;
      }

      &:nth-child(3) {
        grid-area: 3 / 3 / 5 / 5;
      }

      &:nth-child(4) {
        grid-area: 5 / 1 / 10 / 5;
      }
    }

    &--sextuple {
      &:first-child {
        grid-area: 1 / 1 / 4 / 7;
      }

      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }

      &:nth-child(4) {
        grid-area: 4 / 1 / 7 / 3;
      }

      &:nth-child(5) {
        grid-area: 4 / 3 / 7 / 7;
      }

      &:nth-child(6) {
        grid-area: 7 / 1 / 11 / 7;
      }
    }

    &--sextuple-compare {
      &:first-child {
        grid-area: 1 / 1 / 3 / 6;
      }

      &:nth-child(2) {
        grid-area: 3 / 1 / 5 / 6;
      }

      &:nth-child(3) {
        display: none;
      }

      &:nth-child(4) {
        grid-area: 5 / 1 / 6 / 4;
      }

      &:nth-child(5) {
        grid-area: 5 / 4 / 6 / 6;
      }

      &:nth-child(6) {
        grid-area: 6 / 1 / 9 / 6;
      }
    }

    @media (min-width: variables.$screen-sm-min) {
      &--double {
        &:first-child {
          grid-area: 1 / 1 / 3 / 2;
        }

        &:nth-child(2) {
          grid-area: 1 / 2 / 3 / 3;
        }
      }

      &--quadruple {
        &:first-child {
          grid-area: 1 / 1 / 3 / 2;
        }

        &:nth-child(2) {
          grid-area: 1 / 2 / 2 / 2;
        }

        &:nth-child(3) {
          grid-area: 2 / 2 / 2 / 2;
        }

        &:nth-child(4) {
          grid-area: 1 / 3 / 3 / 3;
        }
      }

      &--sextuple {
        &:first-child {
          grid-area: 1 / 1 / 4 / 6;
        }

        &:nth-child(2) {
          display: block;
          grid-area: 1 / 6 / 4 / 8;
        }

        &:nth-child(3) {
          display: block;
          grid-area: 4 / 1 / 6 / 3;
        }

        &:nth-child(4) {
          grid-area: 4 / 3 / 6 / 5;
        }

        &:nth-child(5) {
          grid-area: 4 / 5 / 6 / 8;
        }

        &:nth-child(6) {
          grid-area: 1 / 8 / 6 / 11;
        }
      }

      &--sextuple-compare {
        &:first-child {
          grid-area: 1 / 1 / 4 / 8;
        }

        &:nth-child(2) {
          grid-area: 1 / 8 / 4 / 15;
        }

        &:nth-child(3) {
          display: block;
          grid-area: 4 / 1 / 6 / 5;
        }

        &:nth-child(4) {
          grid-area: 4 / 5 / 6 / 11;
        }

        &:nth-child(5) {
          grid-area: 4 / 11 / 6 / 15;
        }

        &:nth-child(6) {
          grid-area: 1 / 15 / 6 / 23;
        }
      }
    }
  }

  &__image-tag {
    @include variables_fonts.u-small--regular;

    background-color: variables.$bubbletape-pink-30;
    border-radius: 6.25rem;
    left: 0.625rem;
    padding: 0.125rem 0.625rem;
    position: absolute;
    top: 0.625rem;
  }
}
</style>

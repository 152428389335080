<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { AvoToast20, FriendlyRed30 } from '@/variables.module.scss';
import { useRuleIcon } from './compare/useRuleIcon';

const props = defineProps({
  rule: {
    type: Object,
    required: true,
  },
});

const scoreColor = computed(() => {
  if (props.rule.score === 1) {
    return AvoToast20;
  }
  return FriendlyRed30;
});
</script>

<template>
  <div class="rule-result">
    <div class="rule-result__description">
      <div class="rule-result__description--icon">
        <SoonaIcon :name="useRuleIcon(rule.rule)" />
      </div>
      <div class="rule-result__description--content">
        <h5 class="rule-result__title u-body--heavy">{{ rule.rule }}</h5>
        <p class="rule-result__description--text u-body--regular">
          {{ rule.rule_description }}
        </p>
      </div>
    </div>
    <div class="rule-result__score">
      <p class="u-title--heavy">
        {{ rule.images.length }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.rule-result {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid variables.$gray-30;

  &__description {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    &--text {
      display: none;
    }
  }

  &__score {
    p {
      padding: 0 0.5rem;
      width: fit-content;
      background-color: v-bind(scoreColor);
      border-radius: 1rem;
    }
  }

  @media (min-width: 40rem) {
    &__description {
      &--text {
        display: block;
      }
    }
  }
}
</style>

import { useIsMutating, useMutation } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { queryKeys } from '../query-keys';
import { computed } from 'vue';

/**
 * @returns {UseMutationReturnType<any, unknown, void, unknown>}
 */
export function useCreateThread(options) {
  const { ...rest } = options;

  const isMutating = useIsMutating({
    mutationKey: queryKeys.aiAssistantCreateThread(),
  });

  const isPending = computed(() => isMutating.value > 0);

  const mutation = useMutation({
    mutationKey: queryKeys.aiAssistantCreateThread(),
    mutationFn: async () => {
      const response = await http.post('/assistants/create_thread');
      return response.data;
    },
    ...rest,
  });

  return {
    ...mutation,
    isPending,
  };
}

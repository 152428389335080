import { readonly } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import emojiJsonUrl from './emojis.min.json?url';

/**
 *
 * @returns {DeepReadonly<UnwrapNestedRefs<Ref<undefined | Object>>>}
 */
export function useEmojiData() {
  const { data } = useQuery({
    queryKey: ['emoji-data'],
    queryFn: () => fetch(emojiJsonUrl).then(response => response.json()),
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return readonly(data);
}

<template>
  <div class="Orders">
    <router-view v-if="accountLoaded && authorized" />
    <section
      v-else-if="accountLoaded && !authorized"
      class="container is-flex justify-center items-center"
    >
      <div class="notification w-75">
        <h1 class="title">sorry, something went wrong…</h1>
        <div>
          this profile is private. if someone gave you this link, you'll need to
          sign into an account which has access to this profile.
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { useCapability } from '@/composables/useCapability';

export default {
  name: 'Orders',
  setup() {
    const { hasCapability: isSoonaStaff } = useCapability({
      capability: 'soona_staff',
    });

    return { isSoonaStaff };
  },
  data() {
    return {
      accountLoaded: false,
    };
  },
  computed: {
    ...mapState({
      isMyAccount: state => state.currentUser.accountId === state.account.id,
      account: state => state.account,
    }),
    accountId() {
      return Number(this.$route.params.accountId);
    },
    authorized() {
      return this.isMyAccount || this.isSoonaStaff;
    },
  },
  created() {
    this.loadAccount(this.$route.params.accountId);
    this.accountLoaded = true;
  },
  methods: {
    ...mapActions('account', ['loadAccount']),
  },
};
</script>

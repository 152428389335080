<script setup>
import { computed } from 'vue';
import { convertToMMDDYYYYmonthAbr } from 'src/lib/date-formatters';
import { packageStatuses } from 'src/lib/inventory/constants';
import { useCapability } from 'src/composables/useCapability';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaLink from 'src/components/ui_library/SoonaLink.vue';
import PostShootAndReturnAddress from 'src/components/user/anytime/inventory/PostShootAndReturnAddress.vue';
import UnidentifiedItemsWarning from './UnidentifiedItemsWarning.vue';
import InventoryItem from './InventoryItem.vue';

const props = defineProps({
  inventoryPackage: {
    type: Object,
    required: true,
  },
});

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const hasUnidentifiedItems = computed(() =>
  props.inventoryPackage.inventory_items.some(x => !x.catalog_item_id)
);
</script>

<template>
  <article class="inventory-package__wrapper">
    <div class="inventory-package__header-wrapper">
      <header class="inventory-package__header">
        <div class="inventory-package__intake-date">
          {{ convertToMMDDYYYYmonthAbr(inventoryPackage.created_at) }}
        </div>
        <div class="inventory-package__location">
          {{ inventoryPackage.location.display_name }}
        </div>
      </header>
      <div v-if="isFtSoonaStaff" class="inventory-package__crew-actions">
        <SoonaButton
          element="router-link"
          size="medium"
          variation="secondary-gray"
          :to="{
            path: `/account/${inventoryPackage.account_id}/package/${inventoryPackage.id}/edit`,
            query: { returnToProductInventory: true },
          }"
        >
          edit
        </SoonaButton>
      </div>
    </div>

    <section>
      <dl class="inventory-package__details">
        <div class="inventory-package__detail">
          <dt>package ID:</dt>
          <dd>{{ inventoryPackage.id }}</dd>
        </div>
        <div class="inventory-package__detail">
          <dt>package status:</dt>
          <dd>{{ packageStatuses[inventoryPackage.status] }}</dd>
        </div>
        <div v-if="isFtSoonaStaff" class="inventory-package__detail">
          <dt>storage location:</dt>
          <dd>{{ inventoryPackage.storage_location || '--' }}</dd>
        </div>
        <div class="inventory-package__detail">
          <dt>recent booking:</dt>
          <dd>
            <SoonaLink
              v-if="inventoryPackage.latest_reservation"
              :to="`/reservation/${inventoryPackage.latest_reservation.id}/info`"
            >
              {{ inventoryPackage.latest_reservation.name }}
            </SoonaLink>
            <span v-else>--</span>
          </dd>
        </div>
        <div
          v-if="inventoryPackage.description"
          class="inventory-package__detail"
        >
          <dt>package contents:</dt>
          <dd>{{ inventoryPackage.description }}</dd>
        </div>
      </dl>
    </section>
    <PostShootAndReturnAddress
      :account-id="inventoryPackage.account_id"
      :inventory-package="inventoryPackage"
    />
    <UnidentifiedItemsWarning v-if="hasUnidentifiedItems" />
    <section
      v-if="inventoryPackage.inventory_items?.length > 0"
      class="inventory-package__items"
    >
      <InventoryItem
        v-for="item in inventoryPackage.inventory_items"
        :key="item.id"
        :account-id="inventoryPackage.account_id"
        :item="item"
        :package-id="inventoryPackage.id"
      />
    </section>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.inventory-package {
  &__wrapper {
    background-color: variables.$gray-10;
    border: 0.0625rem solid variables.$gray-30;
    border-top: 0.4375rem solid variables.$gray-30;
    border-radius: 0.625rem;
    padding: 1rem;

    @media (min-width: variables.$screen-sm-min) {
      border-top: 0.0625rem solid variables.$gray-30;
      border-left: 0.4375rem solid variables.$gray-30;
      padding: 1.5rem;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    @media (min-width: variables.$screen-sm-min) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__intake-date {
    @include variables_fonts.u-body--heavy;

    @media (min-width: variables.$screen-sm-min) {
      border-right: 0.125rem solid variables.$gray-30;
      padding-right: 0.5rem;
    }
  }

  &__location {
    @include variables_fonts.u-button--large-caps;
  }

  &__crew-actions {
    display: flex;
    justify-content: flex-end;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin: 1.25rem 0;
  }

  &__detail {
    display: flex;
    flex-flow: row wrap;
    gap: 0.25rem;

    dt {
      @include variables_fonts.u-label--heavy;

      white-space: nowrap;
    }
    dd {
      @include variables_fonts.u-label--regular;
    }
  }

  &__items {
    margin-top: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}
</style>

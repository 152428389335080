<template>
  <v-select
    v-model:model-value="value"
    class="timezone-picker"
    :clearable="false"
    :get-option-label="
      opt => {
        return labelToShow(opt);
      }
    "
    :options="timezones"
    :reduce="timezone => timezone.name"
  />
</template>

<script>
export default {
  name: 'TimezonePicker',
  props: {
    timezones: Array,
    selectedTimezone: String,
    onChange: Function,
  },
  computed: {
    value: {
      get() {
        return this.selectedTimezone;
      },
      set(val) {
        this.onChange(val);
      },
    },
  },
  methods: {
    labelToShow(opt) {
      return opt.name ? opt.name.replace('_', ' ') : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.timezone-picker {
  border-radius: 7px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .vs__dropdown-toggle {
    border: 1px solid variables.$white-default;
  }

  .vs__search {
    color: gray;
  }
}
</style>

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { editsCollectionDigitalAssets } from '@/api/editsCollectionDigitalAssets';

/**
 *
 * @param {number} [collectionId]
 * @param {Object} [params]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.facets]
 * @param {object} [params.filters]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 * */
export function useEditsCollectionDigitalAssetsFacets(
  collectionId,
  { currentPage = 1, itemsPerPage = 10, facets, filters },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.collectionAssets(collectionId, {
      currentPage,
      itemsPerPage,
      facets,
      filters,
    }),
    queryFn: async ({ signal }) => {
      return editsCollectionDigitalAssets(
        toValue(collectionId),
        {
          currentPage: toValue(currentPage),
          itemsPerPage: toValue(itemsPerPage),
          filters: toValue(filters),
          facets: toValue(facets) ?? 1,
        },
        signal
      );
    },
    ...queryOptions,
  });
}

<script setup>
import { computed } from 'vue';
import { useDeleteShotTemplate } from '@/queries/useShotTemplate';
import { component as Viewer } from 'v-viewer';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';
const props = defineProps({
  packId: {
    type: String,
    required: true,
  },
  selectedScene: {
    type: Object,
    required: true,
  },
});

defineEmits(['edit-scene', 'duplicate-scene']);

const selectedSceneId = computed(() => props.selectedScene?.id);
const packId = computed(() => props.packId);

const referenceImage = computed(() => {
  const shotTags = props.selectedScene?.shot_tags;
  if (!shotTags) return null;
  return shotTags.find(x => x.tag.tag_category_title === 'Reference Image')
    ?.tag;
});

const backgroundTag = computed(() => {
  const shotTags = props.selectedScene?.shot_tags;
  if (!shotTags) return null;
  const findCatTitle = title => x => x.tag.tag_category_title === title;
  return (
    shotTags.find(findCatTitle('Backdrop Color'))?.tag ??
    shotTags.find(findCatTitle('Textures'))?.tag ??
    shotTags.find(findCatTitle('Environment'))?.tag
  );
});

const { mutate: deleteShotTemplate } = useDeleteShotTemplate(packId);
</script>
<template>
  <div class="pack-scene-details">
    <div class="pack-scene-details__actions">
      <SoonaButton
        variation="tertiary"
        size="medium"
        @on-click="$emit('edit-scene')"
      >
        <SoonaIcon name="pen-square" />
        edit
      </SoonaButton>
      <SoonaButton
        variation="tertiary"
        size="medium"
        @on-click="$emit('duplicate-scene')"
      >
        <SoonaIcon name="duplicate" />
        duplicate
      </SoonaButton>
      <SoonaButton
        variation="tertiary"
        size="medium"
        @on-click="deleteShotTemplate(selectedSceneId)"
      >
        <SoonaIcon name="trash" />
        delete
      </SoonaButton>
    </div>
    <div class="pack-scene-details__preview-images">
      <Viewer
        v-if="referenceImage"
        :options="{
          toolbar: false,
          navbar: false,
          title: false,
          backdrop: true,
          minZoomRatio: 0.01,
          maxZoomRatio: 2,
        }"
      >
        <video
          v-if="referenceImage.image_content_type?.startsWith('video/')"
          autoplay
          loop
          playsinline
          muted
          class="inspo-video"
        >
          <source
            :src="referenceImage.image_url"
            :type="referenceImage.image_content_type"
          />
        </video>
        <img
          v-else
          :src="referenceImage.image_url"
          class="inspo-img"
          alt="reference image"
          data-cypress="shot-list-reference-image"
        />
      </Viewer>
      <span v-if="backgroundTag" class="background-tag">
        <SoonaItemCard
          :image-url="backgroundTag.image_url"
          :aria-labelledby="`background-tag-${backgroundTag.id}-title`"
        >
          <template #content>
            <div class="background-tag__content">
              <p
                :id="`background-tag-${backgroundTag.id}-title`"
                class="background-tag__title u-body--heavy"
              >
                {{ backgroundTag.title }}
              </p>
            </div>
          </template>
        </SoonaItemCard>
      </span>
    </div>
    <div
      v-if="selectedScene.description"
      class="pack-scene-details__description"
    >
      <h5 class="u-small--heavy">description</h5>
      <p>
        {{ selectedScene.description }}
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

@supports (overflow-wrap: anywhere) {
  .pack-scene-details__description p {
    overflow-wrap: anywhere;
    word-break: normal;
  }
}

.pack-scene-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__preview-images {
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    gap: 0.5rem;

    .inspo-img,
    .inspo-video,
    .inspo-button {
      width: 4.5rem;
      height: 4.5rem;
    }

    .inspo-img {
      object-fit: cover;
      cursor: pointer;
    }
  }

  .background-tag {
    max-width: 18.75rem; // matches ShotTag
    width: 100%;

    &__content {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0.75rem 1rem;
    }

    &__title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: lowercase;
    }
  }

  &__actions {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: flex-end;
  }

  &__description {
    margin-bottom: 1rem;

    p {
      max-width: 100%;
      word-break: break-word;
    }
  }
}
</style>

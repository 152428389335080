import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getTagCategories } from '../api/tagCategories';

/**
 *
 * @param {object | Ref<object>} params
 */
export function useTagCategories(params, queryOptions) {
  return useQuery({
    queryKey: queryKeys.tagCategories(params),
    queryFn: ({ signal }) => getTagCategories(unref(params), signal),
    ...queryOptions,
  });
}

import { computed, onUnmounted, reactive, ref, unref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthenticationError } from './useAuthenticationError';
import { useChangeAccount } from '@/queries/users/useChangeAccount';
import { useCheckPresenceOfProvider } from '@/queries/users/useCheckPresenceOfProvider';
import { useCreateUser } from '@/queries/users/useCreateUser';
import { useGetAuthToken } from '@/queries/users/useGetAuthToken';
import { useLoadAccount } from '@/queries/account/useLoadAccount';
import { useSignUserIn } from '@/queries/users/useSignUserIn';
import { useMe } from '@/composables/user/useMe';

const AUTH_STEPS = Object.freeze({
  EMAIL: 'email',
  PASSWORD: 'password',
  GOOGLE_SSO: 'google_sso',
  PICK_ACCOUNT: 'pick_account',
  SIGN_UP: 'sign_up',
});

/**
 *
 * @param {Object} [options]
 * @param {Boolean} [options.generateAuthToken]
 * @param {Function} [options.signUpSuccess]
 **/
export const useAuth = (options = {}) => {
  const generateAuthToken = computed(
    () => unref(options.generateAuthToken) ?? false
  );

  const route = useRoute();
  const router = useRouter();

  const { currentUserId, resetMe } = useMe();

  // local state
  const data = reactive({
    agreeToTermsOfService: false,
    authStep: AUTH_STEPS.EMAIL,
    currentUser: null,
    email: null,
    firstName: null,
    marketingEmailConsent: true,
    name: null,
    password: null,
    phone: null,
  });

  const isRedirecting = ref(false);

  const handleRedirect = (redirectToPath, replace = false) => {
    const { redirect, ...otherParams } = route.query;

    isRedirecting.value = true;

    if (redirect?.startsWith('http') || redirect?.startsWith('/book/pack')) {
      window.location.assign(redirect);
    } else if (window.location.pathname.startsWith('/r/')) {
      window.location.assign('/');
    } else {
      router.push({
        path: redirect ? redirect : redirectToPath,
        query: otherParams,
        replace,
      });
    }
  };

  // vue-query
  const {
    mutate: checkPresenceOfProviderMutation,
    isPending: isCheckingPresenceOfProvider,
    error: checkPresenceOfProviderError,
    reset: resetCheckPresenceOfProvider,
  } = useCheckPresenceOfProvider();

  const {
    mutate: loadAccountMutation,
    mutateAsync: loadAccountMutationAsync,
    isPending: isPendingAccount,
    error: loadAccountError,
    reset: resetLoadAccount,
  } = useLoadAccount();

  const {
    mutate: changeAccountMutation,
    isPending: isChangingAccount,
    error: changeAccountError,
    reset: resetChangeAccount,
  } = useChangeAccount({
    // this needs to be at the top mutation level, not in a mutate callback because components may be unmounted, and the callbacks never run
    onSuccess: async (data, variables) => {
      await loadAccountMutationAsync({ accountId: data.account_id });
      if (variables.redirect)
        handleRedirect(variables.redirect, variables.replace || false);
    },
  });

  const {
    mutate: createUserMutation,
    isPending: isCreatingUser,
    error: createUserError,
    reset: resetCreateUser,
  } = useCreateUser();

  const {
    mutate: signInMutation,
    isPending: isSigningIn,
    error: signInError,
    reset: resetSignIn,
  } = useSignUserIn();

  const authenticationErrors = useAuthenticationError(
    changeAccountError,
    checkPresenceOfProviderError,
    createUserError,
    loadAccountError,
    signInError
  );

  // computed
  const isEmailStep = computed(() => data.authStep === AUTH_STEPS.EMAIL);
  const isPasswordStep = computed(() => data.authStep === AUTH_STEPS.PASSWORD);
  const isGoogleSSOStep = computed(
    () => data.authStep === AUTH_STEPS.GOOGLE_SSO
  );
  const isPickAccountStep = computed(
    () => data.authStep === AUTH_STEPS.PICK_ACCOUNT
  );
  const isSignUpStep = computed(() => data.authStep === AUTH_STEPS.SIGN_UP);

  const isPending = computed(() => {
    return (
      isChangingAccount.value ||
      isCheckingPresenceOfProvider.value ||
      isCreatingUser.value ||
      isPendingAccount.value ||
      isRedirecting.value ||
      isSigningIn.value
    );
  });

  const { data: userAuthToken } = useGetAuthToken(currentUserId, {
    enabled: computed(() => !!currentUserId.value && generateAuthToken.value),
  });

  const sendAuthMessage = responseMessage => {
    if (window.opener) window.opener.postMessage(unref(responseMessage), '*');
  };

  // methods
  const resetErrors = () => {
    resetChangeAccount();
    resetCheckPresenceOfProvider();
    resetCreateUser();
    resetLoadAccount();
    resetSignIn();
  };

  const handleEmailCheck = async () => {
    if (!data.email) return;

    checkPresenceOfProviderMutation(data.email, {
      onSuccess: response => {
        if (
          response.hasAuthProvider &&
          response.suggestedAuthMethod === 'google_oauth2' &&
          !!response.firstName
        ) {
          data.firstName = response.firstName;
          return (data.authStep = AUTH_STEPS.GOOGLE_SSO);
        }

        data.authStep = AUTH_STEPS.PASSWORD;
      },
    });
  };

  const handleUseDifferentAccount = () => {
    data.email = null;
    data.firstName = null;
    data.password = null;
    data.authStep = AUTH_STEPS.EMAIL;
    resetErrors();
  };

  // mutations
  const createUser = (attributes, redirect) => {
    createUserMutation(
      { user: attributes },
      {
        onSuccess: async response => {
          loadAccountMutation(
            { accountId: response.account_id },
            {
              onSuccess: async () => {
                if (typeof options.signUpSuccess === 'function') {
                  await options.signUpSuccess(response.account_id);
                }
                if (redirect) handleRedirect(redirect);
              },
            }
          );
        },
      }
    );
  };

  const signIn = (attributes, redirect, showAccountPicker) => {
    signInMutation(
      { user: attributes },
      {
        onSuccess: async response => {
          if (
            showAccountPicker &&
            !isPickAccountStep.value &&
            response.accounts?.length > 1
          ) {
            data.currentUser = response;
            data.authStep = AUTH_STEPS.PICK_ACCOUNT;
          } else {
            // reload absolutely everything
            await resetMe();

            loadAccountMutation(
              { accountId: response.account_id },
              {
                onSuccess: () => {
                  if (redirect) handleRedirect(redirect);
                },
              }
            );
          }
        },
      }
    );
  };

  const signInWithSelectedAccount = (selectedAccountId, redirect) => {
    changeAccountMutation({ accountId: selectedAccountId, redirect });
  };

  /**
   * @description For use in the navigation account switcher. This takes the
   * user to a blank page before switching accounts, to speed things up.
   *
   * @param {Number} selectedAccountId
   * @param {String} redirect
   */
  async function changeAccountInApp(selectedAccountId, redirect) {
    await router.push({ name: 'switch-account' });
    changeAccountMutation({
      accountId: selectedAccountId,
      redirect,
      replace: true,
    });
  }

  // lifecycle hooks
  onUnmounted(() => {
    isRedirecting.value = false;
  });

  return {
    authenticationErrors,
    createUser,
    changeAccountInApp,
    data,
    handleEmailCheck,
    handleUseDifferentAccount,
    isEmailStep,
    isPasswordStep,
    isGoogleSSOStep,
    isPending,
    isPickAccountStep,
    isSignUpStep,
    sendAuthMessage,
    signIn,
    signInWithSelectedAccount,
    userAuthToken,
  };
};

<script setup>
const modelValue = defineModel('modelValue', {
  type: String,
  default: '',
  required: true,
});

const proServiceTypeOptions = [
  {
    value: 'full body model',
    emojiLabel: '💁🏽',
  },

  {
    value: 'hand model',
    emojiLabel: '👋',
  },
  {
    value: 'foot model',
    emojiLabel: '👟',
  },

  {
    value: 'pet model',
    emojiLabel: '🐶',
  },
];
</script>
<template>
  <div class="pro-service-type-toggle">
    <template
      v-for="proServiceType in proServiceTypeOptions"
      :key="proServiceType.value"
    >
      <button
        :id="`button-${proServiceType.value}`"
        class="u-visually-hidden"
        :aria-pressed="modelValue === proServiceType.value"
        :value="proServiceType"
        @click="() => (modelValue = proServiceType.value)"
      ></button>
      <label
        :for="`button-${proServiceType.value}`"
        class="pro-service-type-toggle__label"
      >
        <span class="pro-service-type-toggle__item"
          ><span class="u-display--heavy">{{
            proServiceType.emojiLabel
          }}</span></span
        >
        <span class="u-label--heavy">{{ proServiceType.value }}</span>
      </label>
    </template>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.pro-service-type-toggle {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 0.625rem;
  gap: 1.5rem;

  &__label {
    display: flex;
    gap: 0.75rem;
    flex-direction: column;
    width: 6rem;
    text-align: center;
    cursor: pointer;

    button:focus-visible + & {
      .pro-service-type-toggle__item {
        background-color: variables.$white-translucent-80;
        border-color: variables.$white-translucent-80;
      }
    }

    &:hover,
    &:focus-visible {
      .pro-service-type-toggle__item {
        background-color: variables.$friendly-red-10;
        border-color: variables.$periwink-blue-40;
      }
    }

    button:focus-visible:hover + &,
    button:focus-visible:focus-visible + & {
      .pro-service-type-toggle__item {
        outline: 0.125rem solid variables.$periwink-blue-70;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variables.$white-default;
    box-sizing: content-box;
    border: 0.0625rem solid variables.$periwink-blue-30;
    border-radius: 0.625rem;
    cursor: pointer;
    padding: 0.75rem 0.75rem;
    transition:
      background-color 0.2s,
      border-color 0.2s;
  }
}
</style>

<script setup>
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useMe } from '@/composables/user/useMe';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useTitle } from '@vueuse/core';
import { computed, onMounted } from 'vue';
import { triggerShippingCheckoutStartedEvent } from '@/api/shipping_detail';
import BillingInfo from '@/components/checkout/BillingInfo.vue';
import SoonaPayment from '@/components/SoonaPayment.vue';
import SoonaPaymentMethods from '@/components/SoonaPaymentMethods.vue';
import { useFlag } from '@/composables/useFlag';
import SummaryPayment from '@/components/SummaryPayment.vue';
import { useIntegrations } from '@/composables/useIntegrations';

const paymentsPaymentMethodsFlag = useFlag('payments_payment_methods');
const paymentsNewShippingCheckout = useFlag('payments_shipping_checkout');

const { buttonClicked, linkClicked, pageViewed } = useBaseEvents();
const { currentAccountId } = useMe();
const { hasShopifyIntegration } = useIntegrations(currentAccountId);

const store = useStore();
const route = useRoute();
const router = useRouter();
const title = useTitle();
const reservationId = computed(
  () => store.state.reservation?.currentReservation?.id
);
const currentRate = computed(() => store.state.reservation?.currentRate);
const currentReservationAccountId = computed(
  () => store.state.reservation.currentReservation?.account_id
);
const currentShipment = computed(
  () => store.state.reservation?.currentShipment || {}
);
const errors = computed(() => store.state.errors.errors);

const getErrorMessages = computed(
  () => store.getters['errors/getErrorMessages']
);

const onRefundPolicyClick = (linkLabel, linkHref) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'shipping checkout',
    linkLabel: linkLabel,
    linkHref: linkHref,
  });

  window.open(linkHref, '_blank', 'noopener noreferrer');
};

onMounted(() => {
  title.value = `${route.meta.page_title} | soona`;

  pageViewed();
  triggerShippingCheckoutStartedEvent(reservationId.value, currentRate.value);
});

const onCancel = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'shipping checkout',
    linkLabel: 'cancel',
    linkHref: '/user/anytime#/reservation/info',
  });

  router.push({ name: 'info' });
};

const overrideDurationTerms = terms => {
  if (
    terms ===
    'Delivery within 1, 2, or 3 days based on where your package started and where it’s being sent.'
  ) {
    return 'delivery guaranteed within 3 business days based on your location';
  } else {
    return terms;
  }
};

const submitPaymentClicked = (stripe, card, saveCard) => {
  buttonClicked({
    context: route.meta.context,
    subContext: 'shipping checkout',
    buttonLabel: 'checkout',
    buttonAction: 'submit payment',
  });

  const savePaymentMethod = hasShopifyIntegration.value ? false : !!saveCard;

  if (paymentsPaymentMethodsFlag.value) {
    return store.dispatch('reservation/submitPayment', {
      card: card,
      saveCard: savePaymentMethod,
      shipping_amount: currentRate.value.amount,
      confirm: {
        token: stripe,
      },
      shipping_details: {
        rate_id: currentRate.value.rate_id,
        shipping_provider: currentRate.value.provider,
        customer_address: currentShipment.value.address_from,
        parcels: currentShipment.value.parcels,
        shipping_direction: 'inbound',
      },
    });
  } else {
    return store.dispatch('reservation/submitPayment', {
      stripe: stripe,
      card: card,
      saveCard: saveCard,
      shipping_amount: currentRate.value.amount,
      shipping_details: {
        rate_id: currentRate.value.rate_id,
        shipping_provider: currentRate.value.provider,
        customer_address: currentShipment.value.address_from,
        parcels: currentShipment.value.parcels,
        shipping_direction: 'inbound',
      },
    });
  }
};

const shipToAddress = computed(() => {
  if (!currentShipment.value?.address_to) return '';

  const street = [
    currentShipment.value.address_to.street1,
    currentShipment.value.address_to.street2,
  ].join(' ');

  return `${street}, ${currentShipment.value.address_to.city}, ${currentShipment.value.address_to.state} ${currentShipment.value.address_to.zip}`;
});

const shipFromAddress = computed(() => {
  if (!currentShipment.value?.address_from) return '';

  const street = [
    currentShipment.value.address_from.street1,
    currentShipment.value.address_from.street2,
  ].join(' ');

  return `${street}, ${currentShipment.value.address_from?.city}, ${currentShipment.value.address_from?.state} ${currentShipment.value.address_from?.zip}`;
});
</script>
<template>
  <div class="shipping">
    <div class="column is-full is-centered soona-container">
      <div class="columns is-centered">
        <div class="column is-half">
          <h2 class="title has-text-centered is-size-4-mobile">
            we're almost ready!
          </h2>

          <!-- order summary -->

          <p class="soona-title-a1 mb-m"><b>ORDER CONFIRMATION:</b></p>

          <SummaryPayment
            v-if="paymentsNewShippingCheckout"
            :total="currentRate.amount"
          >
            <tbody class="summary-payment__itemized-body">
              <!-- ship to -->
              <template v-if="shipToAddress">
                <tr class="shipping-info-row">
                  <th colspan="2">
                    <p class="u-body--heavy">Ship to:</p>
                  </th>
                  <th class="faux-cell"></th>
                </tr>
                <tr class="shipping-info-row">
                  <td colspan="2">
                    <p>{{ currentShipment.address_to.name }}</p>
                    <p>
                      {{ shipToAddress }}
                    </p>
                  </td>
                  <td class="faux-cell"></td>
                </tr>
              </template>
              <!-- ship from -->
              <template v-if="shipFromAddress">
                <tr class="shipping-info-row">
                  <th colspan="2">
                    <p class="u-body--heavy">Ship from:</p>
                  </th>
                  <th class="faux-cell"></th>
                </tr>
                <tr class="shipping-info-row">
                  <td colspan="2">
                    <p>{{ currentShipment.address_from.name }}</p>
                    <p>
                      {{ shipFromAddress }}
                    </p>
                  </td>
                  <td class="faux-cell"></td>
                </tr>
              </template>
              <!-- package details -->
              <tr class="shipping-info-row">
                <th colspan="2">
                  <p class="u-body--heavy">Package details:</p>
                </th>
                <th class="faux-cell"></th>
              </tr>
              <tr class="shipping-info-row">
                <td colspan="2">
                  <p>
                    1 package x {{ currentShipment.parcels.length }}" x
                    {{ currentShipment.parcels.width }}" x
                    {{ currentShipment.parcels.height }}" ({{
                      currentShipment.parcels.weight
                    }}
                    lbs)
                  </p>
                </td>
                <td class="faux-cell"></td>
              </tr>
              <!-- carrier -->
              <tr class="shipping-info-row">
                <th colspan="2">
                  <p class="u-body--heavy">Carrier:</p>
                </th>
                <th class="faux-cell"></th>
              </tr>
              <tr class="shipping-info-row">
                <td colspan="2">
                  <p>
                    <b class="soona-title-a1">
                      {{ currentRate.provider }} {{ currentRate.service_level }}
                    </b>
                  </p>
                  <p class="is-lowercase">
                    {{ overrideDurationTerms(currentRate.duration_terms) }}
                  </p>
                  <p>${{ currentRate.amount }}</p>
                </td>
                <td class="faux-cell"></td>
              </tr>
              <!-- subtotal -->
              <tr class="summary-payment__itemized-subtotal">
                <th>subtotal</th>
                <td>${{ currentRate.amount }}</td>
              </tr>
            </tbody>
            <template #billing>
              <BillingInfo
                class="shipping__billing-info"
                :account-id="currentReservationAccountId"
              />
            </template>
            <template #payment>
              <SoonaPaymentMethods
                v-if="paymentsPaymentMethodsFlag"
                :account-id="currentReservationAccountId"
                action-text="checkout"
                button-variation="primary"
                :is-stripe-disclaimer-below-checkout-button="true"
                :on-payment-action="submitPaymentClicked"
                :payment-method-types="['card', 'us_bank_account']"
                :stripe-payment-required="true"
                :total="currentRate.amount"
              />
            </template>
          </SummaryPayment>

          <template v-else>
            <div class="shipping-form-container u-small--regular">
              <p class="soona-title-a1 mb-s"><b>SHIP TO:</b></p>

              <div class="pl-m mb-m">
                <p>{{ currentShipment.address_to.name }}</p>
                <p>
                  {{
                    [
                      currentShipment.address_to.street1,
                      currentShipment.address_to.street2,
                    ].join(' ')
                  }}, {{ currentShipment.address_to.city }},
                  {{ currentShipment.address_to.state }}
                  {{ currentShipment.address_to.zip }}
                </p>
              </div>

              <p class="soona-title-a1 mb-s"><b>FROM:</b></p>

              <div class="pl-m mb-m">
                <p>{{ currentShipment.address_from.name }}</p>
                <p>
                  {{
                    [
                      currentShipment.address_from.street1,
                      currentShipment.address_from.street2,
                    ].join(' ')
                  }}, {{ currentShipment.address_from.city }},
                  {{ currentShipment.address_from.state }}
                  {{ currentShipment.address_from.zip }}
                </p>
              </div>

              <p class="soona-title-a1 mb-s"><b>PACKAGE DETAILS:</b></p>

              <div class="pl-m mb-m">
                <p>
                  1 package x {{ currentShipment.parcels.length }}" x
                  {{ currentShipment.parcels.width }}" x
                  {{ currentShipment.parcels.height }}" ({{
                    currentShipment.parcels.weight
                  }}
                  lbs)
                </p>
              </div>

              <p class="soona-title-a1 mb-s"><b>CARRIER:</b></p>

              <div class="pl-m">
                <p>
                  <b class="soona-title-a1">
                    {{ currentRate.provider }} {{ currentRate.service_level }}
                  </b>
                </p>
                <p class="is-lowercase">
                  {{ overrideDurationTerms(currentRate.duration_terms) }}
                </p>
                <p>${{ currentRate.amount }}</p>
              </div>
            </div>

            <!-- total cost of shipping -->

            <p class="mt-l u-small--regular mb-m">
              <b class="soona-title-a1">TOTAL:</b> ${{ currentRate.amount }}
            </p>

            <h2 class="u-title--heavy payment-info-title">payment info</h2>
            <SoonaPayment
              stripe-payment-required
              :on-payment-action="submitPaymentClicked"
              action-text="checkout"
            >
              <template #action-block>
                <div class="soona-refund-policy">
                  <p class="u-small--regular">
                    shipping fees are non-refundable. please review our
                    <a
                      class="soona-link"
                      @click.prevent="
                        onRefundPolicyClick(
                          'cancellation and refund policy',
                          'https://www.soona.co/refund-policy'
                        )
                      "
                    >
                      cancellation and refund policy
                    </a>
                    for more information.
                  </p>
                </div>
              </template>
            </SoonaPayment>

            <div v-if="errors" class="soona-errors" data-cypress="errors">
              <p
                v-for="(error, index) in getErrorMessages"
                :key="index"
                class="u-small--regular"
              >
                {{ error }}
              </p>
            </div>

            <div class="buttons is-centered">
              <a class="soona-link u-small--regular" @click.prevent="onCancel">
                cancel
              </a>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.shipping {
  &__billing-info {
    margin-bottom: 1rem;
  }
}

.payment-info-title {
  margin-bottom: 1.5rem;
  text-align: center;
}

.soona-refund-policy {
  background-color: variables.$gray-10;
  border-radius: variables.$control-radius;
  padding: 0.5rem;
}

.buttons {
  padding-top: 1rem;
}
</style>
<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.summary-payment {
  .summary-payment__itemized-body {
    .shipping-info-row {
      td,
      th {
        text-transform: none;

        &.faux-cell {
          display: none;
        }
      }
    }
  }
}
</style>

<script setup>
import { computed, ref, watch, onUnmounted } from 'vue';
import Sortable from 'sortablejs';

const props = defineProps({
  /** All SortableJS options are supported; events are handled by the `defineEmits` below and should be used with v-on */
  options: {
    type: Object,
    default: null,
    required: false,
  },
  /** Your list of items **/
  list: {
    type: Array,
    default: () => [],
    required: true,
  },
  /** The name of the key present in each item in the list that corresponds to a unique value. */
  itemKey: {
    type: [String, Function],
    default: '',
    required: true,
  },
  /** The element type to render as (string or function). */
  tag: {
    type: String,
    default: 'div',
    required: false,
  },
});
const emit = defineEmits([
  'choose',
  'unchoose',
  'start',
  'end',
  'add',
  'update',
  'sort',
  'remove',
  'filter',
  'move',
  'clone',
  'change',
]);
const containerRef = ref(null);
const sortable = ref(null);

watch(containerRef, newDraggable => {
  if (newDraggable) {
    sortable.value = new Sortable(newDraggable, {
      ...props.options,
      onChoose: event => emit('choose', event),
      onUnchoose: event => emit('unchoose', event),
      onStart: event => emit('start', event),
      onEnd: event => emit('end', event),
      onAdd: event => emit('add', event),
      onUpdate: event => emit('update', event),
      onSort: event => emit('sort', event),
      onRemove: event => emit('remove', event),
      onFilter: event => emit('filter', event),
      onMove: (event, originalEvent) => emit('move', event, originalEvent),
      onClone: event => emit('clone', event),
      onChange: event => emit('change', event),
    });
  }
});

const getKey = computed(() => {
  if (typeof props.itemKey === 'string') return item => item[props.itemKey];
  return props.itemKey;
});

watch(
  () => props.options,
  options => {
    if (options && sortable?.value) {
      sortable.value.options = { ...sortable.value.options, ...options };
    }
  }
);

onUnmounted(() => {
  if (sortable.value) {
    sortable.value.destroy();
    containerRef.value = null;
    sortable.value = null;
  }
});
</script>

<template>
  <component
    :is="props.tag"
    ref="containerRef"
    :class="props.class"
    :data-sortable-disabled="
      sortable && sortable.options.disabled ? true : undefined
    "
  >
    <slot
      v-for="(item, index) of list"
      :key="getKey(item)"
      :element="item"
      :index="index"
      name="item"
    />
  </component>
</template>

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useRuleResults } from './useRuleResults';
import { useRuleIcon } from './useRuleIcon';

const props = defineProps({
  listing: {
    type: Object,
    required: true,
  },
  competitor: {
    type: Object,
    required: true,
  },
});

const listing = computed(() => props.listing);
const competitor = computed(() => props.competitor);

const {
  listingImageQualityRuleResults,
  listingGalleryCountRuleResult,
  listingVisualMixRuleResults,
  competitorImageQualityRuleResults,
  competitorGalleryCountRuleResult,
  competitorVisualMixRuleResults,
} = useRuleResults(listing, competitor);

const passingResults = computed(() => {
  let result = [];

  // image quality
  for (let lrr of listingImageQualityRuleResults.value) {
    let crr = competitorImageQualityRuleResults.value.find(
      x => x.rule === lrr.rule
    );
    if (crr.score !== 1 && (lrr.score === 1 || lrr.score > crr.score)) {
      result.push({
        rule: lrr.rule,
        ruleDescription: lrr.rule_description,
        listing: lrr.images.length,
        competitor: crr.images.length,
      });
    }
  }

  // gallery count
  if (
    listingGalleryCountRuleResult.value.images.length >
    competitorGalleryCountRuleResult.value.images.length
  ) {
    result.push({
      rule: listingGalleryCountRuleResult.value.rule,
      ruleDescription: listingGalleryCountRuleResult.value.rule_description,
      listing: listingGalleryCountRuleResult.value.images.length,
      competitor: competitorGalleryCountRuleResult.value.images.length,
    });
  }

  // visual mix
  for (let lrr of listingVisualMixRuleResults.value) {
    let crr = competitorVisualMixRuleResults.value.find(
      x => x.rule === lrr.rule
    );
    if (
      (lrr.score === 1 && crr.score !== 1) ||
      lrr.images.length > crr.images.length
    ) {
      result.push({
        rule: lrr.rule,
        ruleDescription: lrr.rule_description,
        listing: lrr.images.length,
        competitor: crr.images.length,
      });
    }
  }
  return result;
});
</script>

<template>
  <div v-if="passingResults.length" class="ahead">
    <div class="ahead__header--wrapper">
      <h4 class="ahead__header u-subheader--heavy">where you’re ahead</h4>
    </div>

    <table class="ahead__body">
      <tr v-for="(result, i) in passingResults" :key="i" class="ahead__result">
        <th scope="row">
          <div class="ahead__description">
            <div class="ahead__description--icon">
              <SoonaIcon :name="useRuleIcon(result.rule)" />
            </div>
            <div class="ahead__description--content">
              <h5 class="u-body--heavy">{{ result.rule }}</h5>
              <p class="ahead__description--text u-body--regular">
                {{ result.ruleDescription }}
              </p>
            </div>
          </div>
        </th>

        <td class="ahead__result--listing">
          <p class="u-title--heavy">{{ result.listing }}</p>
        </td>
        <td class="ahead__result--competitor">
          <p class="u-title--heavy">{{ result.competitor }}</p>
        </td>
      </tr>
    </table>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
.ahead {
  padding: 1rem;

  &__header {
    padding: 1rem;

    &--wrapper {
      background-color: variables.$avo-toast-20;
      border-radius: 0.625rem;
      // to do: make this a gradient
      border-left: 1rem solid variables.$avo-toast-40;
    }
  }

  &__body {
    margin-top: 0.5rem;
  }

  table {
    width: 100%;
  }

  tr {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 1.5rem 0;
    border-bottom: 0.0625rem solid variables.$gray-30;

    th {
      width: 100%;
      padding-bottom: 1rem;
    }
    td {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    td:first-of-type {
      justify-content: flex-end;
    }
  }

  &__description {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    &--text {
      display: none;
    }
  }

  &__result {
    &--listing {
      border-right: 0.0625rem solid variables.$gray-30;
      text-align: end;
      padding-right: 1rem;

      p {
        float: right;
        padding: 0 0.5rem;
        width: fit-content;
        background-color: variables.$avo-toast-30;
        border-radius: 1rem;
      }
    }

    &--competitor {
      text-align: start;
      padding-left: 1rem;

      p {
        width: fit-content;
        padding: 0 0.5rem;
      }
    }
  }

  @media (min-width: 40rem) {
    padding: 1.5rem;

    &__description {
      &--text {
        display: block;
      }
    }

    tr {
      display: grid;
      grid-template-columns: 2fr 1fr 3fr;

      th {
        padding-bottom: 0;
      }
      td {
        width: 100%;
      }
    }
  }
}
</style>

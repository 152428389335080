import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param { string | number | Ref<string | number> } collectionId
 * @param { string | number | Ref<string | number> | string } cdaId
 */
export function useDeleteBagCollectionDigitalAsset(collectionId, cdaId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.delete(
        `/collections/${toValue(
          collectionId
        )}/bag_collection_digital_assets/${toValue(cdaId)}`
      );

      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.digitalAssetsObliterateAllKeys(),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.bag(),
        }),
      ]);
    },
  });
}

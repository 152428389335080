<template>
  <div
    :class="
      noBorder
        ? 'AssignmentAcceptor'
        : 'AssignmentAcceptor drop-shadow-container'
    "
  >
    <div class="assignments-wrapper">
      <div
        v-for="assignment in crewAssignments"
        :key="assignment.id"
        class="assignment"
      >
        <ProServiceAssignmentItem
          :assignment="assignment"
          :reservation="reservation"
        />
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import ProServiceAssignmentItem from 'src/components/user/anytime/pro_service/ProServiceAssignmentItem.vue';

export default {
  name: 'AssignmentAcceptor',
  components: {
    ProServiceAssignmentItem,
  },
  props: {
    crewAssignments: Array,
    reservation: Object,
    noBorder: Boolean,
    pspServiceRequirements: Array,
  },
  computed: {
    computedImageUrl() {
      return (
        this.pspServiceRequirements[0]?.image_url ||
        this.pspServiceRequirements[0]?.selected_tags[0]?.image_url
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.AssignmentAcceptor {
  color: variables.$black-default;
  height: 100%;
  max-height: 15rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  .assignments-wrapper {
    overflow-y: auto;
    .assignment {
      margin: 0.25rem 0.75rem 0.25rem 0.75rem;
      hr {
        margin: 0.75rem 0rem 0.75rem 0rem;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    text-align: left;
  }
}
</style>

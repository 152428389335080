<script setup>
import { computed } from 'vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import SoonaEditor from 'src/components/ui_library/soona_editor/SoonaEditor.vue';

const props = defineProps({
  promoType: {
    type: String,
    required: true,
  },
  includeHyperlink: {
    type: Boolean,
    required: false,
  },
  linkEnabled: {
    type: Boolean,
    required: true,
  },
  linkCta: {
    type: String,
    default: '',
  },
  linkUrl: {
    type: String,
    default: '',
  },
  linkModalEnabled: {
    type: Boolean,
    required: true,
  },
  linkModalTitle: {
    type: String,
    default: '',
  },
  linkModalDescription: {
    type: Object,
    required: false,
  },
  linkIconEnabled: {
    type: Boolean,
    required: false,
  },
  linkIcon: {
    type: String,
    required: false,
  },
});

const emits = defineEmits(['update:model-value']);

const isAnnouncement = computed(() => {
  return props.promoType === 'announcement';
});

const handleHyperlinkToggle = value => {
  if (value === true) {
    emits('update:model-value', 'link_modal_enabled', false);
  }

  emits('update:model-value', 'link_enabled', value);
};
const handleModalToggle = value => {
  if (value === true) {
    if (isAnnouncement.value === true) {
      emits('update:model-value', 'link_cta', 'learn more');
      emits('update:model-value', 'link_modal_title', null);
    } else {
      emits('update:model-value', 'link_cta', 'view terms');
      emits(
        'update:model-value',
        'link_modal_title',
        'view terms & conditions'
      );
    }

    emits('update:model-value', 'link_enabled', false);
  }

  emits('update:model-value', 'link_modal_enabled', value);
};

const handleIconEnabledToggle = value => {
  emits('update:model-value', 'link_icon_enabled', value);
};

const iconOptions = [
  {
    name: 'internal',
    iconType: 'arrow-right',
  },
  {
    name: 'external',
    iconType: 'arrow-up-right-from-square',
  },
  {
    name: 'sale',
    iconType: 'tag',
  },
];
</script>

<template>
  <section v-if="includeHyperlink" class="hyperlink-section">
    <div class="hyperlink-section__header">
      <h3 class="u-title--heavy">hyperlink</h3>
      <div class="hyperlink-section__toggle">
        <SoonaToggle
          :model-value="linkEnabled"
          align="right"
          type="switch"
          @update:model-value="handleHyperlinkToggle"
        />
      </div>
    </div>

    <div class="hyperlink-section__divider" />

    <div v-if="linkEnabled" class="hyperlink-section__form-container">
      <div class="hyperlink-section__field">
        <label class="u-body--regular" for="hyperlink-cta">link text</label>
        <input
          id="hyperlink-cta"
          name="hyperlink-cta"
          class="hyperlink-section__textinput"
          placeholder="enter the call-to-action"
          :value="props.linkCta"
          @change="emits('update:model-value', 'link_cta', $event.target.value)"
        />
      </div>

      <div class="hyperlink-section__field">
        <label class="u-body--regular" for="hyperlink-url">hyperlink</label>
        <input
          id="hyperlink-url"
          name="hyperlink-url"
          class="hyperlink-section__textinput"
          placeholder="enter a valid url (e.g. https://soona.co/)"
          :value="props.linkUrl"
          @change="emits('update:model-value', 'link_url', $event.target.value)"
        />
      </div>

      <div class="hyperlink-section__toggle-field">
        <label class="u-body--regular" for="icon-enabled">icon</label>
        <div class="hyperlink-section__toggle">
          <SoonaToggle
            :model-value="linkIconEnabled"
            align="right"
            type="switch"
            @update:model-value="handleIconEnabledToggle"
          />
        </div>
      </div>

      <div class="hyperlink-section__divider" />

      <div v-if="linkIconEnabled" class="hyperlink-section__form-container">
        <button
          v-for="(icon, index) in iconOptions"
          :key="index"
          class="hyperlink-section__button"
          :class="{
            'hyperlink-section__button-selected': props.linkIcon === icon.name,
          }"
          @click="emits('update:model-value', 'link_icon', icon.name)"
        >
          <SoonaIcon :name="icon.iconType" />
        </button>
      </div>
    </div>
  </section>

  <section class="terms-section">
    <div class="terms-section__header">
      <h3 class="u-title--heavy">
        {{ isAnnouncement ? 'learn more modal' : 'terms modal' }}
      </h3>
      <div class="terms-section__toggle">
        <SoonaToggle
          :model-value="linkModalEnabled"
          align="right"
          type="switch"
          @update:model-value="handleModalToggle"
        />
      </div>
    </div>

    <div class="terms-section__divider" />

    <div v-if="linkModalEnabled" class="terms-section__form-container">
      <div class="terms-section__field">
        <SoonaEditor
          id="terms"
          name="terms"
          class="terms-section__soona-editor"
          :min-height="`163px`"
          :model-value="props.linkModalDescription"
          :placeholder="`enter the copy for terms and conditions of the promo!`"
          @update:model-value="
            emits('update:model-value', 'link_modal_description', $event)
          "
        >
          <template #label
            ><label class="u-body--regular" for="terms">
              {{ isAnnouncement ? 'learn more copy*' : 'terms copy*' }}
            </label></template
          >
        </SoonaEditor>
      </div>
    </div>
  </section>
</template>
<style scoped lang="scss">
@use '@/variables';

.terms-section,
.hyperlink-section {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__divider {
    width: 100%;
    height: 1px;
    background: variables.$gray-30;
    margin: 8px 0;
  }
  label {
    margin-bottom: 3px;
  }
  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  &__textinput {
    max-width: 304px;
    height: 40px;
    border: 1px solid variables.$gray-30;
    border-radius: 5px;
    padding: 16px;
  }
  &__soona-editor {
    margin-bottom: 16px;
  }

  &__toggle {
    display: flex;
    align-items: center;

    :deep(.soona-toggle-wrapper) {
      display: block;
    }
    :deep(.soona-toggle-label) {
      display: inline !important;
    }
  }
  &__toggle-field {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }

  &__button {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin: 0.5rem 1rem 0.5rem 0;
    padding: 0.5rem 1.5rem;

    &-selected {
      color: variables.$black-default;
      background-color: variables.$friendly-red-20;
      border: 1px solid variables.$friendly-red-40;
      transform: scale(1.1);
    }
  }
}
</style>

import { http } from '../config/vue-axios';

/**
 *
 * @param {number} tag_category_id
 * @param {string} tag_category_slug
 * @param {boolean} order_by_industry
 * @param {AbortSignal} [signal]
 */
export async function getTags(params = {}, signal) {
  const response = await http.get(`tags`, { params, signal });

  return response.data;
}

/**
 *
 * @param {number} tagId
 * @param {AbortSignal} [signal]
 */
export async function showTag(tagId, signal) {
  const response = await http.get(`tags/${tagId}`, { signal });

  return response.data;
}

/**
 *
 * @param {string} query
 * @param {string} sortBy
 * @param {string} sortDirection
 * @param {number} tagCategoryId
 * @param {number} tagCategorySlug
 */
export async function postSearchTags(
  { query, sortBy, sortDirection, tagCategoryId, tagCategorySlug } = {},
  signal
) {
  const body = {
    search: query,
    sort_by: sortBy,
    sort_direction: sortDirection,
    tag_category_id: tagCategoryId,
    tag_category_slug: tagCategorySlug,
  };
  const response = await http.post(`tags`, body, { signal });

  return response.data;
}

/**
 *
 * @param {number} tagId
 * @param {AbortSignal} [signal]
 */
export async function getTagFollowUpCategories(tagId, signal) {
  const response = await http.get(`tags/${tagId}/nested_tag_categories.json`, {
    signal,
  });

  return response.data.nested_tag_categories;
}

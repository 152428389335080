<script setup>
import ProductMultiSelector from './ProductMultiSelector.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  reservation: {
    type: Object,
    required: false,
    default: null,
  },
});

const emits = defineEmits(['onSelectProduct', 'onDeselectProduct']);
const isVideo = computed(() => props.reservation?.shoot_type === 'video');
const store = useStore();
const products = computed(
  () => store.getters['reservation/tagCategoryModelProducts']
);
const filteredProducts = computed(() =>
  products.value.filter(
    product =>
      ((product.video_inclusive && isVideo.value) ||
        (!product.video_exclusive && !isVideo.value)) &&
      product.name !== 'content creator'
  )
);
const productsInCart = computed(
  () => store.getters['reservation/productsInCart']
);
function onSelectProduct(product) {
  emits('onSelectProduct', product);
}

function onDeselectProduct(product) {
  emits('onDeselectProduct', product);
}
</script>
<template>
  <ProductMultiSelector
    :products="filteredProducts"
    :products-in-cart="productsInCart"
    label="select models"
    cypress-label="model-product"
    @on-select="onSelectProduct"
    @on-deselect="onDeselectProduct"
  />
</template>

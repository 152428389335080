<script setup>
import SoonaSearchSimple from '../ui_library/soona_search/SoonaSearchSimple.vue';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({
  type: [Number, String],
  default: null,
});
</script>

<template>
  <SoonaSearchSimple v-model="modelValue" :disabled="disabled" />
</template>

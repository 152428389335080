import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getReservations } from '@/api/reservations';
import { queryKeys } from '@/queries/query-keys';

/**
 *
 * @param {Object} [params]
 * @param {Ref<string | number>} [params.accountId]
 * @param {Ref<'draft' | 'live' | past' | 'suggested' | 'upcoming'>} [params.filter]
 * @param {Ref<string>} [params.query]
 * @param {Ref<number>} [params.currentPage]
 * @param {Ref<number>} [params.itemsPerPage]
 * @param {UseQueryOptions} [queryOptions]
 */
export function useReservations(
  { accountId, filter, query, currentPage, itemsPerPage },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.reservations({
      accountId,
      filter,
      currentPage,
      itemsPerPage,
      query,
    }),
    queryFn: ({ signal }) =>
      getReservations(
        {
          accountId: unref(accountId),
          filter: unref(filter),
          currentPage: unref(currentPage),
          itemsPerPage: unref(itemsPerPage),
          query: unref(query),
        },
        signal
      ),
    ...queryOptions,
  });
}

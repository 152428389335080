<script setup>
import { ref, computed } from 'vue';
import { useResizeObserver, useDebounceFn } from '@vueuse/core';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  canRetry: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: '',
  },
  fileName: {
    type: String,
    default: '',
  },
  isWaiting: {
    type: Boolean,
    default: false,
  },
  value: {
    type: Number,
    default: 0,
  },
});

defineEmits(['retry', 'remove']);

const error = computed(() => props.error);
const isWaiting = computed(() => props.isWaiting);
const value = computed(() => props.value);

const progressInnerTarget = ref();
const progressBgWidth = ref('252px');

const debouncedResize = useDebounceFn(() => {
  progressBgWidth.value =
    (progressInnerTarget.value?.clientWidth ?? 260) - 6 + 'px';
}, 80);

useResizeObserver(progressInnerTarget, debouncedResize);
</script>

<template>
  <div class="soona-progress">
    <div v-if="!!fileName" class="u-small--regular soona-progress__filename">
      {{ fileName }}
    </div>
    <div
      ref="progressInnerTarget"
      class="soona-progress-inner"
      :class="{
        'soona-progress-inner--error': !!error,
      }"
    >
      <SoonaIcon v-if="!!error" name="xmark" size="x-small" />
      <SoonaIcon v-else-if="isWaiting" name="clock" size="x-small" />
      <progress
        v-if="!isWaiting && !error"
        :value="value"
        max="100"
        :style="`--value: ${value}%;`"
      />
    </div>
    <template v-if="!!error">
      <strong class="u-small--heavy">
        {{ error }}
      </strong>
      <div class="soona-progress__error-btns">
        <SoonaButton
          size="small"
          variation="tertiary"
          @on-click="$emit('remove')"
        >
          <SoonaIcon name="trash" size="x-small" /> remove file
        </SoonaButton>
        <SoonaButton
          v-if="canRetry"
          size="small"
          variation="tertiary"
          @on-click="$emit('retry')"
        >
          <SoonaIcon name="refresh-cw" size="x-small" /> retry
        </SoonaButton>
      </div>
    </template>
    <em v-else-if="isWaiting" class="u-label--small">waiting…</em>
    <span
      v-else
      class="u-label--small"
      :class="{
        'soona-progress__complete-lighten': !!fileName,
      }"
    >
      {{ value.toFixed() }}% complete
    </span>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.soona-progress {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &__filename {
    word-break: break-all;
  }

  &__complete-lighten {
    color: variables.$gray-60;
  }

  &-inner {
    border: 0.0625rem solid variables.$periwink-blue-30;
    background-color: variables.$white-default;
    min-height: 1.25rem;
    padding: 0.25rem;
    border-radius: 0.625rem;
    transition:
      background-color 0.1s ease-out,
      border-color 0.1s ease-out;

    &--error {
      border-color: variables.$roses-40;
      background-color: variables.$roses-10;
    }

    > svg {
      display: block;
      height: 0.625rem;
      width: 0.625rem;
    }

    > progress {
      display: block;
      border: 0;
      height: 0.625rem;
      width: 100%;
      border-radius: 0.5rem;
      background-color: transparent;
      overflow: hidden;

      &::-webkit-progress-bar {
        background-color: transparent;
      }

      &::-webkit-progress-value {
        border-radius: 0.5rem;
        background: linear-gradient(
          90deg,
          variables.$bubbletape-pink-30 7.54%,
          variables.$periwink-blue-50 94.01%
        );
        background-size: v-bind('progressBgWidth') 100%;
        transition: width 0.6s;
      }

      &::-moz-progress-bar {
        background: linear-gradient(
          180deg,
          variables.$bubbletape-pink-30 7.54%,
          variables.$periwink-blue-50 94.01%
        );
        background-size: 100% v-bind('progressBgWidth');
        transition: padding-bottom 0.6s;
        padding-bottom: var(--value);
        transform-origin: 0 0;
        transform: rotate(-90deg) translateX(-0.625rem);
        padding-left: 0.625rem;
        height: 0;
      }

      ::-ms-fill {
        border-radius: 0.5rem;
        background: linear-gradient(
          90deg,
          variables.$bubbletape-pink-30 7.54%,
          variables.$periwink-blue-50 94.01%
        );
        background-size: v-bind('progressBgWidth') 100%;
      }
    }
  }

  &__error-btns {
    display: flex;
    gap: 0.5rem;
  }
}
</style>

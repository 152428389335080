import { computed, ref, watch, watchEffect } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { refDebounced } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

export const useListingsListQueryParams = () => {
  const router = useRouter();

  const route = useRoute();

  const { filterSelected } = useBaseEvents();

  // filters
  const searchText = ref(route.query.search ?? '');

  const searchDebounced = refDebounced(searchText, 200);

  const scoreCategory = ref(route.query.score_category ?? 'all');

  const sortBy = ref({
    orderBy: route.query.order_by ?? 'priority',
    direction: route.query.direction ?? 'asc',
  });

  watch(sortBy, newSortBy => {
    filterSelected({
      context: 'listing insights',
      subContext: 'all listings',
      filterCategory: 'sort by dropdown',
      filterLabel: newSortBy,
    });
  });

  const orderBy = computed(() => sortBy.value.orderBy);

  const direction = computed(() => sortBy.value.direction);

  const integrationId = computed(() =>
    route.query.integration_id
      ? Number(route.query.integration_id)
      : route.params.integrationId
        ? Number(route.params.integrationId)
        : null
  );

  // pagination
  const currentPage = ref(
    route.query.current_page ? Number(route.query.current_page) : 1
  );

  watchEffect(() => {
    if (route.query.current_page) {
      currentPage.value = Number(route.query.current_page);
    }
  });

  const itemsPerPage = ref(
    route.query.items_per_page ? Number(route.query.items_per_page) : 10
  );

  watch(
    [
      itemsPerPage,
      searchDebounced,
      orderBy,
      direction,
      scoreCategory,
      integrationId,
    ],
    () => {
      currentPage.value = 1;
    }
  );

  // URL query params
  const queryObj = computed(() => {
    let result = {
      score_category: scoreCategory.value,
      order_by: orderBy.value,
      direction: direction.value,
      current_page: currentPage.value,
      items_per_page: itemsPerPage.value,
      source: route.query.source,
      quest: route.query.quest,
    };
    if (integrationId.value) result.integration_id = integrationId.value;
    if (searchDebounced.value) result.search = searchDebounced.value;
    return result;
  });

  watchEffect(() => {
    if (route.name === 'listing-insights-store-list') {
      router.replace({
        query: queryObj.value,
      });
    }
  });

  function resetFilters() {
    searchText.value = '';
    scoreCategory.value = 'all';
  }

  function resetCurrentPage() {
    currentPage.value = 1;
  }

  function backToStoreList() {
    let name = 'listing-insights-store-list';
    let params = {
      accountId: route.params.accountId,
    };
    let query = {
      search: searchText.value,
      score_category: scoreCategory.value,
      order_by: orderBy.value,
      direction: direction.value,
      current_page: currentPage.value,
      items_per_page: itemsPerPage.value,
      source: route.query.source,
      quest: route.query.quest,
      integration_id: route.query.integration_id,
    };

    router.push({
      name: name,
      params: params,
      query: query,
    });
  }

  return {
    searchText,
    searchDebounced,
    scoreCategory,
    sortBy,
    orderBy,
    direction,
    currentPage,
    itemsPerPage,
    integrationId,
    queryObj,
    resetFilters,
    resetCurrentPage,
    backToStoreList,
  };
};

/* Documentation for vue-axios package: https://github.com/imcvampire/vue-axios  */
import axios from 'axios';

const apiInstance = axios.create();
// axios instance for non-api requests, such as user creation & login
const nonAPIInstance = axios.create();

const csrf = document.querySelector('meta[name="csrf-token"]');

if (csrf) {
  apiInstance.defaults.headers.common['X-CSRF-Token'] =
    csrf.getAttribute('content');
  nonAPIInstance.defaults.headers.common['X-CSRF-Token'] =
    csrf.getAttribute('content');
}

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

nonAPIInstance.defaults.headers = headers;
apiInstance.defaults.headers = headers;
apiInstance.defaults.baseURL = '/api';

export const http = apiInstance;
export const nonAPIHttp = nonAPIInstance;

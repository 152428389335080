<script setup>
import uniqueId from 'lodash/uniqueId';
import { inject, computed, toRefs } from 'vue';

import { RADIO_CARD_GROUP_KEY } from './radio-card-group-key';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  value: {
    type: [String, Number],
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  labelVisuallyHidden: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const rootContext = inject(RADIO_CARD_GROUP_KEY);
const { disabled, value, label } = toRefs(props);

const id = uniqueId('soona-radio-card-item-');
const name = computed(() => rootContext.name.value);
const isLoading = computed(() => rootContext.isLoading.value);
const checked = computed(() => rootContext.modelValue?.value === value.value);
const isDisabled = computed(() => rootContext.disabled.value || disabled.value);
</script>

<template>
  <div class="radio-card">
    <input
      :id="id"
      type="radio"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="isDisabled"
      @change="rootContext.changeModelValue(value)"
    />

    <label class="radio-card__label u-label--regular" :for="id">
      <p class="u-visually-hidden">{{ label }}</p>

      <div v-if="checked" class="radio-card__checkmark">
        <SoonaIcon v-if="!isLoading" name="circle-check-solid" size="large" />
        <div v-else class="loading-circle">
          <div class="loading-circle__spinner" />
        </div>
      </div>

      <div v-if="$slots.image" class="radio-card__image">
        <slot name="image" />
      </div>

      <div class="radio-card__content">
        <slot />
      </div>

      <div v-if="$slots.aside" class="radio-card__aside">
        <slot name="aside" />
      </div>
    </label>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.radio-card {
  &__label {
    display: flex;
    cursor: pointer;
    outline-offset: 0;
    position: relative;
    flex-direction: column;
    border-radius: 0.625rem;
    justify-content: space-between;
    background: variables.$white-default;
    outline: solid 0.0625rem variables.$gray-30;

    input:checked + & {
      background: variables.$periwink-blue-20;
      outline: solid 0.125rem variables.$periwink-blue-70;
    }
    input:focus-visible + & {
      background: variables.$periwink-blue-20;
      outline: double 0.25rem variables.$periwink-blue-70;
    }
    input:checked + & .radio-card__aside {
      background: variables.$periwink-blue-10;
    }
  }

  &__label:hover {
    background: variables.$gray-10;
    outline: solid 0.125rem variables.$black-default;
  }

  input {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    white-space: nowrap;
    clip-path: inset(50%);
  }

  &__checkmark {
    z-index: 1;
    top: 0.25rem;
    left: 0.25rem;
    position: absolute;
    color: variables.$periwink-blue-70;
  }

  &__image {
    padding: 1rem 0 0rem 1rem;
  }

  &__content {
    flex: 1;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
  }

  &__aside {
    padding: 1rem;
    background: variables.$gray-05;
    border-top: 0.0625rem solid variables.$gray-30;
  }

  .loading-circle {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0.125rem;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background: variables.$periwink-blue-70;

    &__spinner {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 100%;
      border: 0.125rem solid transparent;
      border-left-color: variables.$white-default;
      animation: circleanimation 0.45s linear infinite;
    }

    @keyframes circleanimation {
      from {
        transform: rotateZ(0deg);
      }
      to {
        transform: rotateZ(360deg);
      }
    }
  }

  @media (min-width: variables.$screen-md-min) {
    &__label {
      align-items: center;
      flex-direction: row;
    }

    &__image {
      padding: 1rem 0 1rem 1rem;
    }

    &__aside {
      border-top: none;
      border-left: 0.0625rem solid variables.$gray-30;
    }
  }
}
</style>

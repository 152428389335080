import { readonly, ref, toValue, watchSyncEffect } from 'vue';
import { useResizeObserver } from '@vueuse/core';

const oneRem = () =>
  Number.parseFloat(getComputedStyle(document.documentElement).fontSize);

export function useInfiniteGalleryWrapper({
  wrapperEl,
  heightRem = 14,
  gapRem = 0.75,
}) {
  const gutter = ref(toValue(gapRem) * oneRem());
  const rowHeight = ref(toValue(heightRem) * oneRem());
  // default to a square aspect ratio and hope for the best until we can measure the wrapper
  const wrapperWidth = ref(toValue(heightRem) * oneRem());
  const offsetTop = ref(0);

  watchSyncEffect(() => {
    if (wrapperEl.value) {
      wrapperWidth.value = wrapperEl.value.clientWidth;
      offsetTop.value = wrapperEl.value.offsetTop;
    }
  });

  useResizeObserver(wrapperEl, entries => {
    const entry = entries[0];
    const { width } = entry.contentRect;
    wrapperWidth.value = width;

    const rem = oneRem();
    gutter.value = toValue(gapRem) * rem;
    rowHeight.value = toValue(heightRem) * rem;

    const newOffsetTop = entry?.target?.offsetTop ?? 0;
    if (offsetTop.value !== newOffsetTop) {
      offsetTop.value = newOffsetTop;
    }
  });

  return {
    gutter: readonly(gutter),
    rowHeight: readonly(rowHeight),
    rowWidth: readonly(wrapperWidth),
    offsetTop: readonly(offsetTop),
  };
}

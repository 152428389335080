<script setup>
import { computed, ref } from 'vue';
import ReferenceImage from './ReferenceImage.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import UploadMedia from './UploadMedia.vue';

const emit = defineEmits(['close', 'handle-select']);

const uploaderRef = ref(null);
const uploadedImages = ref([]);

const isUploading = computed(() => uploaderRef.value?.isUploading ?? false);

const handleRemove = id => {
  uploadedImages.value = uploadedImages.value.filter(image => image.id !== id);
};

const handleUploadComplete = blob => {
  uploadedImages.value = [...uploadedImages.value, blob];
};
</script>

<template>
  <SoonaDialog @close="emit('close')">
    <template #heading>add an image</template>
    <h3 class="u-body--heavy">reference image</h3>
    <!-- using v-show due to v-if leaving images out from time to time -->
    <UploadMedia
      v-show="!uploadedImages.length || isUploading"
      ref="uploaderRef"
      @handle-upload-complete="handleUploadComplete"
    />
    <div v-if="uploadedImages.length && !isUploading">
      <ol>
        <ReferenceImage
          v-for="image in uploadedImages"
          :key="image.id"
          :image="image"
          @handle-remove="handleRemove"
        />
      </ol>
    </div>
    <template #footer="{ close }">
      <div class="action-btns">
        <SoonaButton size="medium" variation="tertiary" @on-click="close">
          cancel
        </SoonaButton>
        <SoonaButton
          size="medium"
          @on-click="emit('handle-select', uploadedImages)"
        >
          save
        </SoonaButton>
      </div>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

h3 {
  padding-bottom: 0.5rem;
}

ol {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  list-style: none;
}

li {
  background: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  overflow: hidden;
  position: relative;
  width: 12.25rem;
}

.remove-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.img-wrapper {
  align-items: center;
  background-color: variables.$gray-10;
  display: flex;
  justify-content: center;
  height: 12.25rem;
}

img {
  max-height: 100%;
}

figcaption {
  padding: 1rem;
}

.action-btns {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
}
</style>

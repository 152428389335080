<script setup>
import { computed } from 'vue';
import HeaderWidget from 'src/components/promotion/HeaderWidget.vue';

const props = defineProps({
  promotionWidgetAttributes: {
    type: Object,
    required: true,
  },
  promoCode: {
    type: String,
    default: null,
  },
});

let bannerWidget = computed(() => {
  return {
    description: props.promotionWidgetAttributes.description,
    link_enabled: props.promotionWidgetAttributes.link_enabled,
    link_cta: props.promotionWidgetAttributes.link_cta,
    link_url: props.promotionWidgetAttributes.link_url,
    link_modal_enabled: props.promotionWidgetAttributes.link_modal_enabled,
    link_modal_description:
      props.promotionWidgetAttributes.link_modal_description,
    link_modal_title: props.promotionWidgetAttributes.link_modal_title,
    link_icon_enabled: props.promotionWidgetAttributes.link_icon_enabled,
    link_icon: props.promotionWidgetAttributes.link_icon,
    text_color: props.promotionWidgetAttributes.text_color,
    background_color: props.promotionWidgetAttributes.background_color,
    promo_code: props.promoCode,
    copy_code_enabled: props.promotionWidgetAttributes.copy_code_enabled,
  };
});
</script>

<template>
  <div class="real-time-preview">
    <HeaderWidget :widgets="[bannerWidget]" :disable-hyperlink="true" />
  </div>
</template>

<style scoped lang="scss">
.real-time-preview {
  margin: 1rem -2rem;
}
</style>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  controls: {
    required: true,
    type: String,
  },
  percentComplete: {
    required: true,
    type: Number,
  },
});

const emits = defineEmits(['close']);

const percentComplete = computed(() => props.percentComplete);
</script>

<template>
  <button
    class="u-button-reset quest-close"
    :aria-controls="controls"
    @click="emits('close')"
  >
    <p class="u-subheader--heavy">close tour - {{ percentComplete }}%</p>
  </button>
</template>

<style lang="scss" scoped>
@use '@/variables';

.quest-close {
  background-color: variables.$white-default;
  border-radius: 4rem;
  bottom: 2rem;
  padding: 1rem 1.5rem;
  position: absolute;
  right: 2rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: variables.$gray-10;
  }

  &:focus-visible {
    outline: 0.125rem solid variables.$bubbletape-pink-60;
  }

  &:active {
    background-color: variables.$gray-30;
  }
}
</style>

import { page, track } from '@/lib/segment/segment';
import { useCurrentReservationAndUser } from '@/composables/segment/useCurrentReservationAndUser';
import { useRouter } from 'vue-router';

export function useBaseEvents({ reservationId } = {}) {
  const { reservationTraits, userTraits } = useCurrentReservationAndUser({
    reservationId,
  });
  const router = useRouter();

  /**
   * Button Clicked
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} buttonLabel the label of the button clicked
   * @param {string} buttonAction what does this button do?
   */
  const buttonClicked = ({
    context,
    subContext,
    buttonLabel,
    buttonAction,
  } = {}) => {
    const payload = {
      context,
      subContext,
      buttonLabel,
      buttonAction,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    track('Button Clicked', payload);
  };

  /**
   * Filter Selected
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} filterCategory what category within the filter experience am I in?
   * @param {string} filterSubCategory what subcategory within the category am I in?
   * @param {string} filterLabel what unique label is selected within the category or subcategory?
   */
  const filterSelected = ({
    context,
    subContext,
    filterCategory,
    filterSubCategory,
    filterLabel,
  } = {}) => {
    const payload = {
      context,
      subContext,
      filterCategory,
      filterSubCategory,
      filterLabel,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    track('Filter Selected', payload);
  };

  /**
   * Results Returned
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {number} quantityResults number of items returned from the query
   * @param {Array} filtersSelected filters affecting query results
   */
  const resultsReturned = ({
    context,
    subContext,
    quantityResults,
    filtersSelected,
  } = {}) => {
    const payload = {
      context,
      subContext,
      quantityResults,
      filtersSelected,
      ...userTraits.value,
    };
    track('Results Returned', payload);
  };

  /**
   * Input Changed
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} inputLabel label of the input field
   * @param {number} inputType contents of the type attribute
   * @param {string} inputValue value selected from a dropdown, radio, or checkbox. *No UGC*.
   */
  const inputChanged = ({
    context,
    subContext,
    inputLabel,
    inputType,
    inputValue,
  } = {}) => {
    const payload = {
      context,
      subContext,
      inputLabel,
      inputType,
      inputValue,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    track('Input Changed', payload);
  };

  /**
   * Item Selected
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} itemId unique id of the product selected; don't pass in a value if there is no explicit product id - tag id doesn't count
   * @param {string} itemLabel the primary name of the item
   * @param {number} itemPrice the price of an item if shown; set to 0 if not shown
   * @param {number} itemQuantity number of items at time of selection
   */
  const itemSelected = ({
    context,
    subContext,
    itemId,
    itemLabel,
    itemPrice,
    itemQuantity,
  } = {}) => {
    const payload = {
      context,
      subContext,
      itemId,
      itemLabel,
      itemPrice,
      itemQuantity,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    track('Item Selected', payload);
  };

  /**
   * Link Clicked
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} linkLabel the label of the link clicked
   * @param {string} linkHref the href of the link clicked; *DO NOT include any params like reservationId except for packId*
   */
  const linkClicked = ({ context, subContext, linkLabel, linkHref } = {}) => {
    const payload = {
      context,
      subContext,
      linkLabel,
      linkHref,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    const authedUser = { ulid: userTraits.value.id };

    track('Link Clicked', payload, authedUser);
  };

  /**
   * Media Added To Cart
   * Note: This event should only be used for add-to-cart events that occur outside of the booking flow
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} itemId unique id of the product selected; don't pass in a value if there is no explicit product id - tag id doesn't count
   * @param {string} itemLabel the primary name of the item
   * @param {number} itemPrice the price of an item if shown; set to 0 if not shown
   * @param {number} itemQuantity number of items at time of selection
   */
  const mediaAddedToCart = ({
    context,
    subContext,
    itemId,
    itemLabel,
    itemPrice,
    itemQuantity,
  } = {}) => {
    const payload = {
      context,
      subContext,
      itemId,
      itemLabel,
      itemPrice,
      itemQuantity,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    track('Media Added To Cart', payload);
  };

  /**
   * Modal Displayed
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} modalName the funnel step
   */
  const modalDisplayed = ({ context, subContext, modalName } = {}) => {
    const payload = {
      context,
      subContext,
      modalName,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    track('Modal Displayed', payload);
  };

  /**
   * Page Viewed
   * @param {string} pageName the name of the page
   * @param {Boolean} useHashPath use entire path, including hash (default false)
   */
  const pageViewed = ({ pageName, useHashPath } = { useHashPath: false }) => {
    function setPath() {
      if (!useHashPath) return router.currentRoute.value.path;
      return window.location.pathname;
    }

    function setFullPath() {
      if (!useHashPath) return router.currentRoute.value.fullPath;
      return window.location.pathname;
    }

    let path = setPath();
    let fullPath = setFullPath();
    let params = router.currentRoute.value.params;

    function getSearchParams() {
      let vueLocationSearch = fullPath.match(/\?(.*)/);
      vueLocationSearch = vueLocationSearch ? vueLocationSearch[0] : '';

      let coreSearchParams = new URLSearchParams(window.location.search);
      let vueSearchParams = new URLSearchParams(vueLocationSearch);

      //location (browser native) params will be replaced by vue query params if there are any collisions
      let allParams = new URLSearchParams({
        ...Object.fromEntries(coreSearchParams),
        ...Object.fromEntries(vueSearchParams),
      });

      return allParams.size > 0 ? `?${allParams.toString()}` : undefined;
    }

    /*
      We scrub params like reservationId from the paths
      to prevent Segment from being flooded with too many unique paths.
      The path '/new-booking/5/industry' and the path '/new-booking/6/industry'
      would count as two separate paths. By scrubbing the paths, we
      can send only '/new-booking/industry to Segment.
    */
    for (const [key, value] of Object.entries(params)) {
      // the only param we want to send to Segment at this time is packId
      if (!['packId', 'mediaId'].includes(key)) {
        path = path.replace(`/${value}`, '');
        fullPath = fullPath.replace(`/${value}`, '');
      }
    }
    /*
      we combine params from regular window.location.search params as well as the vue router params into one merged search params
    */
    const pathTraits = {
      path: path,
      search: getSearchParams(),
      url: import.meta.env.VITE_HOSTNAME + fullPath,
    };

    // we pass in the provided page name, or default to null for the page name when we don't use the name to determine what step the user is on
    page(pageName, { ...pathTraits });
  };

  /**
   * Old Page Viewed
   * temporary workaround for old rails routes that have not been shifted
   * to use Vue routing logic. Thus, they typically are shaped like:
   *
   * http://localhost:3000/book/pack/1?optional=true#/
   *
   * Note that Vue router interprets everything _after_ the anchor (#) as
   * the path.
   */
  const oldPageViewed = ({ path, fullPath, search }) => {
    const pathTraits = {
      path: path,
      search: search,
      url: fullPath,
    };

    // we pass in null for the page name because we don't use the name to determine what step the user is on
    page(null, { ...pathTraits });
  };

  /**
   * Time Selected
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} selectionType did the user select month/day or time?
   * @param {string} UTC datetime the user selected


   */
  const timeSelected = ({ context, subContext, selectionType, UTC } = {}) => {
    const payload = {
      context,
      subContext,
      selectionType,
      UTC,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    track('Time Selected', payload);
  };

  return {
    buttonClicked,
    filterSelected,
    inputChanged,
    itemSelected,
    linkClicked,
    mediaAddedToCart,
    modalDisplayed,
    pageViewed,
    timeSelected,
    oldPageViewed,
    resultsReturned,
  };
}

import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { toValue } from 'vue';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {import('vue').MaybeRefOrGetter<number>} userId
 */
export function useAccountsUsers({ userId }) {
  return useQuery({
    queryKey: queryKeys.accountsUsers({ userId }),
    queryFn: async ({ signal }) => {
      const response = await http.get(`/accounts_users`, {
        params: {
          user_id: toValue(userId),
        },
        signal,
      });

      return response.data;
    },
  });
}

import { http } from '@/config/vue-axios';

/**
 *
 * @param {number} reservationId
 * @param {AbortSignal} [signal]
 */
export async function getReservationTimeline(
  reservationId,
  { timezone } = {},
  signal
) {
  const params = {};
  if (timezone) params['timezone'] = timezone;

  const response = await http.get(`/reservations/${reservationId}/timeline`, {
    params,
    signal,
  });
  return response.data;
}

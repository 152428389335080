import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { refreshIntegration } from '../api/integrations';

export function useRefreshIntegration(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: params => refreshIntegration(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.integrations(accountId),
      });
    },
  });
}

<script setup>
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { useGetIntegrations } from '@/queries/useGetIntegrations';
import { computed } from 'vue';
import {
  isActiveAmazonIntegration,
  isActiveShopifyIntegration,
} from '@/components/user/anytime/products/integration-utils';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  accountFlags: {
    type: Array,
    default: () => [],
  },
});

const { data: integrationsData } = useGetIntegrations(
  computed(() => props.accountId)
);
const hasAmazonIntegration = computed(() => {
  return integrationsData.value?.some(isActiveAmazonIntegration) ?? false;
});

const hasShopifyIntegration = computed(
  () => integrationsData.value?.some(isActiveShopifyIntegration) ?? false
);

const showFlags = computed(() => {
  return (
    hasAmazonIntegration.value ||
    hasShopifyIntegration.value ||
    props.accountFlags.length > 0
  );
});
</script>

<template>
  <div v-if="showFlags" class="account-info-flags">
    <img
      v-if="hasAmazonIntegration"
      src="@images/amazon-logo.svg"
      alt="Amazon logo"
      class="integration-logo amazon-logo"
      title="has Amazon integration"
    />
    <img
      v-if="hasShopifyIntegration"
      src="@images/shopify_glyph.svg"
      alt="Shopify logo"
      class="integration-logo"
      title="has Shopify integration"
    />
    <SoonaFlag
      v-for="flag in accountFlags"
      :key="flag.id"
      :title="flag.title"
      :text-color="flag.text_color"
      :background-color="flag.background_color"
    />
  </div>
</template>

<style lang="scss" scoped>
.account-info-flags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;

  .integration-logo {
    height: 1.25rem;

    &.amazon-logo {
      /* the amazon logo has some margin inside the SVG */
      height: 1.5rem;
      margin: -0.125rem;
    }
  }
}
</style>

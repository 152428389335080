const mimeTypeMappings = Object.freeze({
  '.cr2': 'image/x-canon-cr2',
  '.cr3': 'image/x-canon-cr3',
  '.gif': 'image/gif',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg',
  '.mov': 'video/quicktime',
  '.mp4': 'video/mp4',
  '.mpeg': 'video/mpeg',
  '.png': 'image/png',
  '.pdf': 'application/pdf',
  '.svg': 'image/svg+xml',
  '.webm': 'video/webm',
  '.webp': 'image/webp',
});

/**
 * Map array of file extensions to their MIME types
 * @param {array} fileExtensions e.g. ['.jpg', 'jpeg', '.png']
 * @returns {array} e.g ['image/jpeg', 'image/jpeg', 'image/png']
 */
export function toMimeTypes(fileExtensions) {
  return fileExtensions.map(ext => mimeTypeMappings[ext]);
}

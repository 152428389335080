<script setup>
import { computed, onMounted } from 'vue';
import BaseSubscription from '@/components/subscriptions/BaseSubscription.vue';
import CompetitiveAnalysisBanner from './CompetitiveAnalysisBanner.vue';
import CompetitorsWatchlist from '@/components/user/anytime/competitive_analysis/CompetitorsWatchlist.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useCapability } from '@/composables/useCapability';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useTitle } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useAccount } from '@/composables/useAccount';

const props = defineProps({
  accountId: { type: String, required: true },
});

const accountId = computed(() => props.accountId);

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

const { account } = useAccount(accountId);

const { error } = useCapability({
  capability: 'view_competitive_analysis',
  subjectType: 'account',
  subjectId: accountId,
});

const priorityError = usePriorityError(error);

const totalCompetitorsCount = computed(
  () => account.value?.total_competitors_count
);

useTitle('competitive analysis | soona');

const showBanner = computed(() => totalCompetitorsCount.value > 0);

const showHeader = computed(
  () =>
    // hide h1 if FreemiumBanner is displayed
    !(account.value?.total_competitors_count === 0)
);
</script>

<template>
  <div class="competitors-dashboard-wrapper">
    <CompetitiveAnalysisBanner v-if="showBanner" :account-id="accountId" />
    <header v-if="showHeader" class="competitors-dashboard__header">
      <h1 class="u-headline--heavy">competitive analysis</h1>
    </header>
    <SoonaError v-if="priorityError" no-margin>
      {{ priorityError }}
    </SoonaError>
    <div class="competitors-dashboard">
      <BaseSubscription :account-id="accountId">
        <template #success="{ subscriptionStartDate }">
          <CompetitorsWatchlist
            :account-id="accountId"
            :subscription-start-date="subscriptionStartDate"
          />
        </template>
      </BaseSubscription>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.competitors-dashboard-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.competitors-dashboard {
  display: flex;
  flex-direction: column;
  gap: 6rem;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>

export const paths = [
  {
    path: '/account/:accountId/competitive-analysis',
    name: 'competitive-analysis',
    component: () =>
      import(
        'src/components/user/anytime/competitive_analysis/CompetitorsDashboard.vue'
      ),
    props: route => ({
      accountId: route.params.accountId,
    }),
    meta: {
      context: 'competitive analysis',
      requires_auth: true,
      show_quest: true,
    },
  },
  {
    path: '/account/:accountId/competitive-analysis/compare',
    name: 'competitor-compare',
    component: () =>
      import(
        'src/components/user/anytime/competitive_analysis/compare/CompetitorCompare.vue'
      ),
    props: route => ({
      accountId: route.params.accountId,
    }),
    meta: {
      context: 'competitor compare',
      requires_auth: true,
    },
  },
  {
    path: '/account/:accountId/competitor/:competitorId',
    name: 'competitor-detail',
    component: () =>
      import(
        'src/components/user/anytime/competitive_analysis/CompetitorDetail.vue'
      ),
    props: route => ({
      accountId: route.params.accountId,
      competitorId: route.params.competitorId,
    }),
    meta: {
      context: 'competitor detail',
      requires_auth: true,
      show_quest: true,
    },
  },
  {
    path: '/account/:accountId/example_competitor/:competitorId',
    name: 'example-competitor-detail',
    component: () =>
      import(
        'src/components/user/anytime/competitive_analysis/ExampleCompetitorDetail.vue'
      ),
    props: route => ({
      accountId: route.params.accountId,
      competitorId: route.params.competitorId,
    }),
    meta: {
      context: 'example competitor detail',
      requires_auth: true,
      show_quest: true,
    },
  },
];

export default paths;

<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed } from 'vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useDeleteCollaborator } from '@/queries/collaborators/useDeleteCollaborator';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  collaborator: {
    type: Object,
    required: true,
  },
});

defineEmits(['close']);

const accountId = computed(() => props.accountId);
const collaboratorId = computed(() => props.collaborator.id);

const {
  mutate: deleteCollaborator,
  isPending: isDeleteCollaboratorLoading,
  error: deleteInvitationError,
} = useDeleteCollaborator(accountId, collaboratorId);

const priorityError = usePriorityError(deleteInvitationError);

function removeCollaborator(close) {
  deleteCollaborator(
    {},
    {
      onSuccess: () => close(),
    }
  );
}
</script>

<template>
  <SoonaDialog type="alertdialog" @close="$emit('close')">
    <template #heading>remove from team</template>
    <template #default>
      <div class="remove-collaborator-dialog__user">
        <SoonaAvatar
          :name="collaborator.name"
          size="3rem"
          :src="collaborator.avatar_url"
          type="user"
        />
        <div class="remove-collaborator-dialog__info">
          <p class="u-body--heavy">
            {{ collaborator.name }}
          </p>
          <p class="u-small--regular">
            {{ collaborator.email }}
          </p>
        </div>
      </div>
      <div class="remove-collaborator-dialog__alert">
        <SoonaIcon name="circle-exclamation-solid" size="small" />
        <p>they will no longer have access to this soona account</p>
      </div>
      <SoonaError v-if="priorityError">
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        variation="primary"
        :loading="isDeleteCollaboratorLoading"
        @on-click="removeCollaborator(close)"
      >
        remove
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.remove-collaborator-dialog {
  &__user {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__alert {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    border-radius: 0.3125rem;
    border: 0.0625rem solid variables.$tangerine-40;
    background-color: variables.$tangerine-10;
    gap: 0.5rem;
  }
}
</style>

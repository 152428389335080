import { computed, ref, unref, watch } from 'vue';
import { useInventoryPackages } from '@/queries/inventory/useInventoryPackages';
import { usePriorityError } from '@/composables/usePriorityError';
import { keepPreviousData } from '@tanstack/vue-query';

/**
 * @param {Object} opts
 * @param {Ref<string|number>} opts.accountId
 * @param {string} opts.filteredStatus
 * @param {Ref<boolean> | boolean} opts.enabled
 */
export function useInventorySection({
  accountId,
  filteredStatus,
  enabled = true,
}) {
  const currentPage = ref(1);
  const itemsPerPage = ref(10);
  // go back to the first page when itemsPerPage changes
  watch(itemsPerPage, () => {
    currentPage.value = 1;
  });

  const { data, isLoading, error } = useInventoryPackages(
    {
      accountId: accountId,
      currentPage,
      itemsPerPage,
      filteredStatus: filteredStatus,
    },
    {
      placeholderData: keepPreviousData,
      enabled,
    }
  );

  const packages = computed(() => data.value?.inventoryPackages || []);

  const packagesPagination = computed(() => {
    if (data.value) {
      return data.value.pagination;
    } else {
      return {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      };
    }
  });

  const priorityError = usePriorityError(error);

  const isShown = computed(
    () =>
      unref(enabled) &&
      (packagesPagination.value.totalCount > 0 || priorityError.value)
  );

  return {
    currentPage,
    // we are never in a loading state if the query is not enabled
    isLoading: computed(() => unref(enabled) && isLoading.value),
    isShown,
    itemsPerPage,
    packages,
    packagesPagination,
    priorityError,
  };
}

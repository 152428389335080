<script setup>
import { useMediaQuery } from '@vueuse/core';
import { computed, ref, watchEffect } from 'vue';

import { useCapability } from '@/composables/useCapability';

import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import DigitalAssetCommonActions from './DigitalAssetCommonActions.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import DigitalAssetCommonStatuses from './DigitalAssetCommonStatuses.vue';
import OverlayDownloadAsset from '@/components/account_gallery/OverlayDownloadAsset.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';

const props = defineProps({
  alwaysShowTitle: {
    type: Boolean,
    required: false,
    default: false,
  },
  digitalAsset: {
    type: Object,
    default: () => ({}), // this has a default b/c a placeholder could be passed in causing a render issue
  },
  pagedAsset: {
    type: Object,
    required: true,
  },
  flexGrow: {
    type: Number,
    default: 0,
  },
  to: {
    type: [String, Object],
    default: null,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  selectVisible: {
    type: Boolean,
    default: false,
  },
  selectDisabled: {
    type: Boolean,
    default: false,
  },
  showActionButtons: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['request-page', 'selection-click']);

const id = computed(() => `da-gallery-card-${props.pagedAsset.id}`);
const titleId = computed(() => `${id.value}-title`);

// pagedAsset could be the same as digitalAsset but is more likely to be
// reservationDigitalAsset, catalogItemDigitalAsset, editsCollectionDigitalAsset etc.
const pagedAsset = computed(() => props.pagedAsset);
const aspectRatio = computed(() =>
  Math.min(props.digitalAsset?.preview?.ratio ?? 1, 2.5)
);

watchEffect(() => {
  if (pagedAsset.value.placeholder) {
    emit('request-page', pagedAsset.value.page);
  }
});

const mediaIcon = computed(() => {
  switch (props.digitalAsset?.media_type) {
    case 'photo':
      return 'image-square';
    case 'video':
      return 'play';
    case 'animation':
      return 'gif-text';
    default:
      return null;
  }
});
const mediaIconOverlay = computed(() => {
  switch (props.digitalAsset?.media_type) {
    case 'video':
      return 'play';
    case 'animation':
      return 'gif-text';
    default:
      return null;
  }
});

const customerOwned = computed(
  () => props.digitalAsset?.ownership === 'customer'
);
const hasDownloadUrl = computed(
  () => props.digitalAsset?.file?.url || props.digitalAsset?.web?.url
);

const favoriteCDAId = computed(
  () => props.digitalAsset?.favorites_collection_digital_asset?.id
);
const isFavorited = computed(() => !!favoriteCDAId.value);
const bagCDAId = computed(
  () => props.digitalAsset.bag_collection_digital_asset?.id
);
const isAddedToBag = computed(() => !!bagCDAId.value);

const hasHover = useMediaQuery('(hover: hover)');
const isHovered = ref(!hasHover.value);
const showButtons = () => {
  isHovered.value = true;
};
const hideButtons = () => {
  isHovered.value = false;
};

const { hasCapability: assignedToReservation } = useCapability({
  capability: 'assigned_reservation',
});
const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});
</script>

<template>
  <figure
    class="asset"
    :class="{ 'asset--selected': selected }"
    :aria-labelledby="titleId"
    data-cypress="digital-asset-card"
    @mouseover="showButtons"
    @mouseleave="hideButtons"
    @focusin="showButtons"
  >
    <SoonaSkeleton v-if="pagedAsset.placeholder" class="asset__placeholder" />
    <template v-else>
      <router-link v-if="to" class="asset__link" :to="to">
        <DigitalAssetImage
          :src="digitalAsset.preview?.url"
          :alt="digitalAsset.title"
        />
      </router-link>
      <div v-else class="asset__link">
        <DigitalAssetImage
          :src="digitalAsset.preview?.url"
          :alt="digitalAsset.title"
        />
      </div>
      <div class="asset__overlay">
        <slot name="asset-overlay" />
        <div class="asset__overlay__actions">
          <div
            class="asset__overlay__actions_container"
            :class="{ 'show-always': isFavorited || isAddedToBag }"
          >
            <div class="left-buttons">
              <!-- multi select checkbox -->
              <label
                v-if="selectVisible"
                class="asset__overlay__selection-label"
                :class="{
                  'asset__overlay__selection-label--disabled': selectDisabled,
                }"
              >
                <input
                  :checked="selected"
                  type="checkbox"
                  :disabled="selectDisabled"
                  @click="$emit('selection-click', $event)"
                />
                <span class="u-visually-hidden">
                  asset
                  {{ digitalAsset.title || digitalAsset.id }}
                  selected
                </span>
              </label>

              <!-- save for future reshop promotions -->
              <!-- <SoonaFlag
                v-if="digitalAsset.on_sale"
                title="sale"
                :background-color="Gray90"
                :text-color="WhiteDefault"
                class="asset__overlay__flag"
              >
                <template #icon-left>
                  <SoonaIcon name="clock" size="small" class="clock" />
                </template>
              </SoonaFlag> -->
            </div>

            <div class="right-buttons">
              <slot
                v-if="showActionButtons && isSoonaStaff"
                name="action-buttons"
              />

              <!-- favorite and bag buttons -->
              <DigitalAssetCommonActions
                v-if="digitalAsset?.id && !selected"
                :digital-asset="digitalAsset"
                :is-hovered="isHovered"
              />

              <!-- download asset button -->
              <OverlayDownloadAsset
                v-if="
                  (customerOwned || assignedToReservation) &&
                  hasDownloadUrl &&
                  !selected &&
                  isHovered
                "
                :file-url="digitalAsset.file?.url"
                :web-url="digitalAsset.web?.url"
                :raw-url="digitalAsset.raw?.url"
              />
            </div>
          </div>
        </div>

        <div class="asset__overlay__bottom">
          <div class="asset__overlay__bottom__statuses">
            <slot name="statuses" />
            <DigitalAssetCommonStatuses
              v-if="digitalAsset?.id"
              :digital-asset="digitalAsset"
            />
          </div>
          <div
            v-if="mediaIconOverlay"
            class="asset__media-type"
            :class="{ 'asset__media-type--forced': alwaysShowTitle }"
          >
            <SoonaIcon :name="mediaIconOverlay" />
            <span class="u-visually-hidden">{{ digitalAsset.media_type }}</span>
          </div>
          <p
            :id="titleId"
            class="asset__title"
            :class="{ 'asset__title--forced': alwaysShowTitle }"
            :title="digitalAsset.title"
          >
            <SoonaIcon
              v-if="mediaIcon"
              :name="mediaIcon"
              size="medium"
              class="asset__title__media-type-icon"
            />
            <span class="asset__title__text u-label--regular">
              <span class="u-visually-hidden"
                >{{ digitalAsset.media_type }} -
              </span>
              {{ digitalAsset.title }}
            </span>
          </p>
        </div>
      </div>
    </template>
  </figure>
</template>

<style lang="scss" scoped>
@use '@/variables';

.asset {
  position: relative;
  height: 100%;
  /* prevent overflow from aspect-ratio if in narrow view */
  max-width: 100%;
  aspect-ratio: v-bind(aspectRatio);
  flex-grow: v-bind(flexGrow);
  border-radius: 0.625rem;

  &:hover,
  &:focus-within {
    box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.08);
  }

  .asset__overlay__actions_container {
    display: none;
    width: 100%;

    .left-buttons {
      display: flex;
      gap: 0.25rem;
    }

    .right-buttons {
      gap: 0.25rem;
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      justify-content: end;
    }
  }

  .show-always {
    display: flex;
  }

  &--selected,
  &:hover,
  &:focus-within {
    .asset__overlay__actions_container {
      display: flex;
    }

    .asset__title {
      display: flex;
    }

    .asset__media-type {
      display: none;
    }

    .asset__overlay__action-buttons {
      display: flex;
    }

    .asset__overlay__selection-label {
      display: block;
    }
  }

  @media (hover: none) {
    .asset__overlay__actions_container {
      display: flex;
    }

    .asset__title {
      display: flex;
    }

    .asset__media-type {
      display: none;
    }

    .asset__overlay__action-buttons {
      display: flex;
    }

    .asset__overlay__selection-label {
      display: block;
    }
  }

  &__placeholder {
    height: 100%;
    border-radius: 0.625rem;
  }

  &__link {
    display: block;
    height: 100%;
    border-radius: 0.625rem;
    overflow: hidden;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.25rem;

    &__actions {
      display: flex;
      /* not wrapping here to force the action buttons to wrap instead */
      row-gap: 0.25rem;
      align-items: flex-start;
      padding: 0.5rem;
    }

    &__selection-label {
      display: none;
      pointer-events: auto;
      padding: 0.9375rem;
      margin: -0.5rem;
      cursor: pointer;
      border-radius: 0.625rem 0 0.625rem 0;

      &:not(.asset__overlay__selection-label--disabled):hover {
        background-color: variables.$black-translucent-12;
      }

      &--disabled {
        cursor: not-allowed;
      }

      input[type='checkbox'] {
        height: 1.125rem;
        width: 1.125rem;
        display: block;
        accent-color: variables.$periwink-blue-60;
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    &__flag {
      margin-top: 0.25rem;
    }

    &__action-buttons {
      margin-left: auto;
      display: none;
      flex-flow: row wrap;
      column-gap: 0.5rem;
      row-gap: 0.25rem;
      align-items: center;
      /* wrap items down to the right */
      justify-content: flex-end;

      &--forced {
        display: flex;
      }

      @media screen and (hover: none) {
        display: flex;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1 1 auto;

      &__statuses {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        justify-content: flex-end;
        gap: 0.25rem;
        padding: 0.5rem;
      }
    }
  }

  &__title {
    z-index: 0;
    width: 100%;
    gap: 0.25rem;
    display: none;
    position: relative;
    pointer-events: none;
    align-items: flex-end;
    padding: 0.25rem 0.5rem 0.5rem;
    color: variables.$white-default;
    border-radius: 0 0 0.625rem 0.625rem;
    transition: 0.25s opacity ease-in-out;

    &::after {
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      height: 3.5rem;
      position: absolute;
      border-radius: 0 0 0.625rem 0.625rem;
      background: linear-gradient(
        to top,
        variables.$black-translucent-60 45%,
        rgba(0, 0, 0, 0)
      );
    }

    &--forced {
      display: flex;
    }

    &__text {
      overflow: hidden;
      white-space: nowrap;
      pointer-events: auto;
      text-overflow: ellipsis;
    }

    &__media-type-icon {
      flex: 0 0 1.25rem;
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &__media-type {
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 10rem;
    color: variables.$white-default;
    background-color: variables.$black-translucent-60;
    display: flex;
    align-items: center;
    justify-content: center;

    &--forced {
      display: none;
    }

    svg {
      display: block;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import {
  getExampleListings,
  getExampleListing,
} from 'src/api/example_listings';
import { queryKeys } from 'src/queries/query-keys';

/**
 *
 * @param {string | number} accountId
 * @param {Object} params
 * @param {string | null} [params.platform]
 * @param {UseQueryOptions} queryOptions
 */
export function useExampleListings(accountId, { platform }, queryOptions) {
  return useQuery({
    queryKey: queryKeys.exampleListings(accountId, { platform }),
    queryFn: ({ signal }) => getExampleListings({ platform }, signal),
    ...queryOptions,
  });
}

/**
 *
 * @param {string | number} exampleListingId
 * @param {UseQueryOptions} queryOptions
 */
export function useExampleListing(exampleListingId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.exampleListing(exampleListingId),
    queryFn: ({ signal }) => getExampleListing(unref(exampleListingId), signal),
    ...queryOptions,
  });
}

<script setup>
import { computed, ref } from 'vue';
import AutoEditsFeedback from '@/components/crew/AutoEditsFeedback.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import AttachRaw from '../AddOnLineItem/AttachRaw.vue';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';
import { useUpdateEditsCollectionDigitalAsset } from '@/queries/edits_collection_digital_assets/useUpdateEditsCollectionDigitalAsset';
import { usePriorityError } from 'src/composables/usePriorityError';
import { convertToDDMMYYHHMM12z } from '@/lib/date-formatters';

const props = defineProps({
  orderId: {
    required: true,
    type: [Number, String],
  },
  originalDigitalAssetId: {
    required: true,
    type: [Number, String],
  },
  addOnLineItemId: {
    required: true,
    type: [Number, String],
  },
  digitalAsset: {
    required: true,
    type: Object,
  },
  backgroundColor: {
    require: false,
    type: String,
    default: '#fff',
  },
  enableReview: {
    required: true,
    type: Boolean,
  },
});

const digitalAsset = computed(() => props.digitalAsset);

const createdAt = computed(() => {
  if (!digitalAsset.value) return;

  return convertToDDMMYYHHMM12z(digitalAsset.value.created_at);
});
const showFeedback = ref(false);

const queryClient = useQueryClient();
const accountId = computed(() => digitalAsset.value?.account_id);

const editsCollectionDigitalAsset = computed(
  () => digitalAsset.value?.edits_collection_digital_asset
);
const editsCollectionId = computed(
  () => editsCollectionDigitalAsset.value?.collection_id
);
const { mutate, isPending, error } = useUpdateEditsCollectionDigitalAsset(
  accountId,
  editsCollectionId
);

const priorityError = usePriorityError(error);

const bustCache = async () => {
  await queryClient.invalidateQueries({
    queryKey: queryKeys.orderAddOns(computed(() => props.orderId)),
  });
};

const pend = () => {
  mutate(
    {
      cdaId: editsCollectionDigitalAsset.value?.id,
      body: { edit_status: 'pending' },
    },
    { onSuccess: bustCache }
  );
};

const confirmReject = () => {
  showFeedback.value = false;
  mutate(
    {
      cdaId: editsCollectionDigitalAsset.value?.id,
      body: { edit_status: 'rejected' },
    },
    { onSuccess: bustCache }
  );
};

const reject = () => {
  if (digitalAsset.value.auto_edited) {
    showFeedback.value = true;
  } else {
    confirmReject();
  }
};
</script>
<template>
  <AutoEditsFeedback
    v-if="showFeedback"
    category="auto_edits_post_production"
    subject-type="DigitalAsset"
    :subject-id="digitalAsset.id"
    :open="showFeedback"
    options-type="post"
    @close="() => (showFeedback = false)"
    @confirm="confirmReject"
  />
  <div class="asset-summary-wrapper">
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <div class="asset-summary-wrapper__icon">
      <SoonaIcon
        :name="
          digitalAsset.auto_edited
            ? 'wand-magic-sparkles-monochrome'
            : 'circle-user'
        "
        size="small"
      />
    </div>
    <div class="asset-summary-wrapper__text">
      <time class="created-at">{{ createdAt }}</time
      ><br />
      <span class="asset-summary-title">{{ digitalAsset.title }}</span>
    </div>
    <div class="asset-summary-wrapper__actions">
      <SoonaButton
        v-if="enableReview && digitalAsset.edit_status === 'pending'"
        title="reject"
        size="large"
        variation="icon-plain-gray"
        :disabled="isPending"
        @on-click="reject"
      >
        <SoonaIcon
          name="circle-xmark"
          size="small"
          style="vertical-align: middle"
        />
      </SoonaButton>
      <SoonaButton
        v-else-if="enableReview && digitalAsset.edit_status === 'rejected'"
        title="make pending"
        size="large"
        variation="icon-plain-gray"
        :disabled="isPending"
        @on-click="pend"
      >
        <SoonaIcon
          name="flip-backward"
          size="small"
          style="vertical-align: middle"
        />
      </SoonaButton>
      <SoonaButton
        title="download file"
        size="large"
        element="a"
        variation="icon-plain-gray"
        :href="digitalAsset.file.url"
      >
        <SoonaIcon
          name="arrow-down-to-bracket"
          size="small"
          style="vertical-align: middle"
        />
      </SoonaButton>
      <SoonaButton
        v-if="digitalAsset.raw"
        title="download raw"
        size="large"
        element="a"
        variation="icon-plain-gray"
        :href="digitalAsset.raw.url"
        :download="digitalAsset.raw.filename"
      >
        <SoonaIcon
          name="arrow-down-to-bracket"
          size="small"
          style="vertical-align: middle"
        />
      </SoonaButton>
      <AttachRaw
        v-if="digitalAsset"
        :account-id="accountId"
        :digital-asset-id="digitalAsset.id"
        :order-id="orderId"
        :replace="!!digitalAsset.raw"
        :show-copy="false"
        size="large"
        variation="icon-plain-gray"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.asset-summary-wrapper {
  background-color: v-bind('backgroundColor');
  padding: 0.25rem;
  border-bottom: 1px solid variables.$gray-30;

  &__icon {
    display: inline-block;
    width: fit-content;
    vertical-align: top;
    padding-top: 0.25rem;
    padding-right: 0.25rem;
  }
  &__text {
    display: inline-block;
    width: auto;
  }
  &__actions {
    display: inline-block;
    width: auto;
    float: right;
  }

  .created-at {
    color: variables.$gray-60;
  }
}
.user-avatar {
  display: inline-block;
}
</style>

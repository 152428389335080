<script setup>
import { computed, ref, watch } from 'vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';

const props = defineProps({
  products: {
    type: Array,
    default: null,
    required: true,
  },
  label: {
    type: String,
    default: '',
    required: false,
  },
  cypressLabel: {
    type: String,
    default: `product`,
    required: false,
  },
  productsInCart: {
    type: Array,
    required: false,
  },
});

const emits = defineEmits(['onSelect', 'onDeselect']);

const products = computed(() => {
  return props.products;
});

const productsInCart = computed(() => props.productsInCart);

const productOptions = computed(() => {
  return products.value.map(product => {
    const rate = product.custom_price ? ` $${product.rate}` : '';
    const tier = product.level ? ` (${product.level})` : '';
    return {
      label: product.name.toLowerCase() + rate + tier,
      value: product.id,
    };
  });
});
const selectedOptions = ref([]);

function onSelect(product) {
  emits('onSelect', product);
}

function onDeselect(product) {
  emits('onDeselect', product);
}
watch(
  () => productsInCart.value,
  () => {
    if (productsInCart.value) {
      selectedOptions.value = selectedOptions.value.filter(product =>
        productsInCart.value.some(cartProduct => product === cartProduct.id)
      );
    }
  },
  { deep: true }
);
</script>
<template>
  <SoonaSelect
    v-model:model-values="selectedOptions"
    :options="productOptions"
    multi
    :data-cypress="cypressLabel + '-multi-selector'"
    @option:selecting="onSelect"
    @option:deselecting="onDeselect"
  >
    <template #label>{{ label }}</template>
  </SoonaSelect>
</template>

<script setup>
import { ref } from 'vue';
import SoonaSearchFilter from '@/components/ui_library/SoonaSearchFilter.vue';

const searchText = defineModel('searchText', {
  type: String,
  required: true,
});
const selectedFilter = defineModel('selectedFilter', {
  type: String,
  required: true,
});
const sortBy = defineModel('sortBy', {
  type: Object,
  required: true,
});

const filterOptions = [
  {
    value: 'all',
    label: 'all',
  },
  {
    value: 'poor',
    label: 'poor',
    iconName: 'triangle-exclamation',
  },
  {
    value: 'okay',
    label: 'okay',
    iconName: 'circle-partial',
  },
  {
    value: 'great',
    label: 'great',
    iconName: 'circle-check',
  },
];

const sortOptions = ref([
  { value: 'priority', label: 'recommended' },
  { value: 'score', label: 'score' },
  { value: 'display_group_rank', label: 'Amazon Rank' },
  { value: 'price', label: 'price' },
  { value: 'name', label: 'product name' },
]);
</script>

<template>
  <SoonaSearchFilter
    v-model:search-text="searchText"
    v-model:selected-filter="selectedFilter"
    v-model:sort-by="sortBy"
    search-placeholder="search listings"
    :filter-options="filterOptions"
    :sort-by-options="sortOptions"
  />
</template>

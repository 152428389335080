<script setup>
import { computed } from 'vue';
import { useSoonaIconData } from './useSoonaIconData';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: 'large',
    validator: value => {
      return [
        'x-small',
        'small',
        'medium',
        'large',
        'x-large',
        '2x-large',
        '3x-large',
      ].includes(value);
    },
  },
});

const soonaIconData = useSoonaIconData();

const svgContent = computed(() => {
  return soonaIconData.value
    ? soonaIconData.value[props.name]
    : '<circle cx="12" cy="12" r="12" fill="#f5f6f6" />';
});

const sizes = {
  'x-small': '12px',
  small: '16px',
  medium: '20px',
  large: '24px',
  'x-large': '32px',
  '2x-large': '48px',
  '3x-large': '64px',
};

const size = computed(() => sizes[props.size] ?? sizes.large);
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    v-html="svgContent"
  ></svg>
</template>

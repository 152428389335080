import { unref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { shareDraftEmail } from '@/api/draft';

/**
 *
 * @param {number | Ref<Object>} reservationId
 */
export function useShareDraftEmail(reservationId) {
  return useMutation({
    mutationFn: collaborators =>
      shareDraftEmail(unref(reservationId), collaborators),
  });
}

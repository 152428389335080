import { http, nonAPIHttp } from '../config/vue-axios';

/**
 *
 * @param {number} userId
 */
export async function getUser(userId, signal) {
  const response = await http.get(`/users/${userId}.json`, {
    signal,
  });

  return response.data;
}

/**
 *
 * @param {Object} userAttributes
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function signUserIn(userAttributes) {
  const response = await nonAPIHttp.post(`/users/sign_in.json`, userAttributes);

  return response.data;
}

/**
 *
 * @param {Object} userAttributes
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function createUserAsStaff(userAttributes) {
  const response = await http.post(`/users.json`, userAttributes);

  return response.data;
}

/**
 *
 * @param {Object} userAttributes
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function createUser(userAttributes) {
  const response = await nonAPIHttp.post(`/users.json`, userAttributes);

  return response.data;
}

/**
 *
 * @param {number} userId
 * @param {Object} [body]
 */
export async function updateUser(userId, body = {}) {
  const response = await http.put(`/users/${userId}`, {
    user: body,
  });

  return response.data;
}

/**
 *
 * @param {string} email
 */
export async function checkPresenceOfProvider(email) {
  const response = await http.get(`/users/check_presence_of_provider`, {
    params: {
      email: email,
    },
  });

  return response.data;
}

/**
 * @param {string | number} userId
 * @param {AbortSignal} [signal]
 * @returns {Promise<string>}
 */
export async function getChatAuth(userId, signal) {
  const response = await http.get(`/users/${userId}/chat_auth.json`, {
    signal,
  });

  return response.data;
}

/**
 * @param {string | number} accountId
 * @param {boolean} hasRedirect
 * @param {AbortSignal} [signal]
 * @returns {Promise<string>}
 */
export async function changeAccount({ accountId } = {}, signal) {
  const response = await http.patch(`/users/account/${accountId}`, { signal });

  return response.data;
}

/**
 * @param {string | number} userId
 * @param {AbortSignal} [signal]
 * @returns {Promise<string>}
 */
export async function getAuthToken(userId, signal) {
  const response = await http.get(`/users/${userId}/auth_token.json`, {
    signal,
  });

  return response.data;
}

/**
 *
 * @param {number} userId
 * @param {string} avatarSignedId
 * @return {Promise<any>}
 */
export async function updateUserAvatar(userId, avatarSignedId) {
  const response = await http.put(`/users/${userId}/update_avatar`, {
    user: {
      avatar: avatarSignedId,
    },
  });

  return response.data;
}

/**
 *
 * @param {string} username
 * @return {Promise<any>}
 */
export async function resetPassword({ username, emailType } = {}) {
  const response = await nonAPIHttp.post(`/users/password`, {
    user: {
      username: username,
      email_type: emailType,
    },
  });

  return response.data;
}

/**
 *
 * @param {string} password
 * @param {string} passwordConfirmation
 * @param {string} resetPasswordToken
 * @return {Promise<any>}
 */
export async function updatePassword({
  password,
  passwordConfirmation,
  resetPasswordToken,
} = {}) {
  const response = await nonAPIHttp.put(`/users/password`, {
    user: {
      password: password,
      password_confirmation: passwordConfirmation,
      reset_password_token: resetPasswordToken,
    },
  });

  return response.data;
}

<script setup>
import { useMokkerScenes } from '@/queries/mokker/useMokkerScenes';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import SoonaImageCard from '@/components/ui_library/SoonaImageCard.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const { data: mokkerScenes, isLoading: scenesLoading } = useMokkerScenes();
</script>

<template>
  <aside class="popular-scenes">
    <h3 id="ai-landing-popular-scenes" class="u-subheader--heavy">
      popular AI scenes
    </h3>
    <div v-if="!mokkerScenes || scenesLoading" class="popular-scenes__loading">
      <div v-for="index in 8" :key="index" class="popular-scenes__skeleton">
        <SoonaSkeleton class="popular-scenes__skeleton-img" />
        <SoonaSkeleton class="popular-scenes__skeleton-caption" />
        <SoonaSkeleton class="popular-scenes__skeleton-caption" />
      </div>
    </div>
    <PageContainerOffset v-else class="popular-scenes__images">
      <SoonaSlider tag-name="ul" aria-labelledby="me-landing-popular-scenes">
        <SoonaImageCard
          v-for="scene in mokkerScenes"
          :key="scene.id"
          inner-element="router-link"
          animation-delay="0s"
          :to="{
            name: 'ai-studio-template',
            params: {
              accountId: accountId,
              templateId: scene.id,
            },
          }"
        >
          <img :src="scene.proxied_thumbnail_url" :alt="scene.name" />
        </SoonaImageCard>
      </SoonaSlider>
    </PageContainerOffset>
  </aside>
</template>

<style scoped lang="scss">
.popular-scenes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;

  &__images {
    flex: 1 0 100%;
  }

  &__loading {
    flex: 1 0 100%;
    display: flex;
    gap: 1.5rem;
    padding: 0.5rem;
  }

  &__skeleton {
    flex: 0 0 9.25rem;
    width: 9.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 0.5rem;

    &-img {
      min-height: 7.6875rem;
      border-radius: 0.625rem;
    }

    &-caption {
      height: 1rem;
    }
  }
}
</style>

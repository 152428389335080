<script setup>
import { ref, computed, onMounted } from 'vue';
import SoonaFilter from '@/components/shared/SoonaFilter.vue';

const props = defineProps({
  defaultFilter: {
    type: String,
    default: undefined,
  },
  filterOptions: {
    type: Array,
    required: true,
  },
  showPaywall: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['filter-change']);

const defaultFilter = computed(() => props.defaultFilter);
const filterOptions = computed(() => props.filterOptions);
const showPaywall = computed(() => props.showPaywall);

const selectedFilter = ref(defaultFilter.value);
const soonaFilterRef = ref();

const scrollFilterBtnToCenter = el => {
  const targetX = el.offsetLeft + el.offsetWidth / 2;
  const halfElWidth = soonaFilterRef.value.$el.offsetWidth / 2;
  const scrollXDest = Math.floor(targetX - halfElWidth);

  soonaFilterRef.value.$el.scrollTo({ left: scrollXDest, behavior: 'smooth' });
};

const handleFilterChange = (option, currentTarget) => {
  const filterObj = filterOptions.value.find(value => value.label === option);
  selectedFilter.value = filterObj;
  emits('filter-change', filterObj || option);
  scrollFilterBtnToCenter(currentTarget);
};

onMounted(() => {
  if (!soonaFilterRef.value) return;
  scrollFilterBtnToCenter(soonaFilterRef.value.$el.firstElementChild);
});
</script>

<template>
  <section class="media-action-filter">
    <div class="media-action-filter__content">
      <p
        v-if="$slots['subheading'] && !selectedFilter"
        class="u-label--regular media-action-filter__subheading"
      >
        <slot name="subheading" />
      </p>
      <slot :selected-filter="selectedFilter" />
    </div>
    <SoonaFilter
      ref="soonaFilterRef"
      class="media-action-filter__nav"
      :options="filterOptions"
      :selected-filter="selectedFilter?.label"
      :show-paywall="showPaywall"
      :on-change="handleFilterChange"
    />
  </section>
</template>

<style lang="scss" scoped>
@use '@/variables';

.media-action-filter {
  margin: 0 -1rem;
  max-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__selected {
    color: variables.$gray-60;
    margin-bottom: 1.25rem;
  }

  &__content {
    overflow: auto;
    padding: 0 1rem;
    position: relative;
    background:
      linear-gradient(variables.$white-default 30%, rgba(255, 255, 255, 0))
        center top,
      linear-gradient(rgba(255, 255, 255, 0), variables.$white-default 70%)
        center bottom,
      linear-gradient(rgba(0, 0, 0, 0.12), rgba(255, 255, 255, 0)) center top,
      linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.12)) center bottom;
    background-repeat: no-repeat;
    background-size:
      100% 2.5rem,
      100% 2.5rem,
      100% 0.375rem,
      100% 0.375rem;
    background-attachment: local, local, scroll, scroll;
  }

  &__subheading {
    color: variables.$gray-60;
    margin-bottom: 1.25rem;
  }

  &__nav {
    margin-top: auto;
    flex-shrink: 0;
    flex-wrap: nowrap;
    justify-content: start;
    overflow-x: auto;
    padding: 0 0.5rem;
    scroll-snap-type: x proximity;
    scroll-padding: 50%;
    padding-top: 0.5rem;
    animation: 0.5s cubic-bezier(0, 0.55, 0.45, 1) both slide-in-left;

    :deep(.soona-filter__btn) {
      scroll-snap-align: center;
    }
  }
}

@keyframes slide-in-left {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
}
</style>

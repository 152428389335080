<template>
  <div class="ProServiceProfileCard">
    <hr />
    <div class="trait-description">
      <div class="trait-top">
        <div
          v-if="
            proServiceProfile.id === undefined &&
            service.selected_tags.length === 0
          "
        >
          <ProServiceTraitSelector
            v-for="defaultCategory in defaultCategories"
            :key="defaultCategory.id"
            v-model:model-value="selectedTagsProxy"
            :tag-category="defaultCategory"
          />
        </div>
        <div v-else>
          <span class="traits-toggle">
            <SoonaToggle
              v-model:model-value="toggleService"
              class="trait-title"
              type="switch"
              align="right"
              :label="mainTag.title"
              cypress-name="pro-service-profile-toggle"
              :disabled="editView === true || disableToggle"
            />
          </span>
        </div>
        <IncompleteTraitsWarning
          v-if="showIncompleteTrait"
          :number-incomplete="displayIncompleteTrait"
        />
        <SavedTraits
          v-if="service && !editView"
          :selected-basic-info-categories="selectedBasicInfoCategories"
          :selected-sizes-talents-categories="selectedSizesTalentsCategories"
          :service="service"
        />
        <div
          v-else-if="proServiceProfile.id === undefined || editView === true"
          class="selector-traits-section-editing"
        >
          <div v-if="basicInfoCategories.length <= 0">
            <span class="trait-selector">
              <ProServiceTraitSelector
                v-for="traitCategory in categoriesToShow"
                :key="traitCategory.id"
                v-model:model-value="selectedTagsProxy"
                :tag-category="traitCategory"
              />
            </span>
          </div>
          <div
            v-if="basicInfoCategories.length > 0"
            class="selector-section-wrapper"
          >
            <div class="section-title">
              <p>Basic Info</p>
            </div>
            <span class="trait-selector">
              <span v-if="showDisplayName" class="display-name">
                <label class="display-label">
                  <strong>display name</strong><span class="required">*</span>
                </label>
                <input v-model="displayName" class="display-input" />
              </span>
              <ProServiceTraitSelector
                v-for="traitCategory in basicInfoCategories"
                :key="traitCategory.id"
                v-model:model-value="selectedTagsProxy"
                :tag-category="traitCategory"
              />
              <span v-if="showSampleWork" class="display-name">
                <SoonaTextField
                  v-model:model-value="sampleWork"
                  label="sample work"
                  placeholder="example.com"
                  type="url"
                  :rules="['url']"
                />
              </span>
            </span>
          </div>
          <div v-if="showMeasurements">
            <div class="section-title">
              <p>Measurements</p>
            </div>
            <span class="trait-selector">
              <ProServiceProfileMeasurement
                v-for="measurement in service.required_measurements"
                :key="measurement.measurement_key"
                v-model:model-value="selectedMeasurementsProxy"
                :measurement="measurement"
              />
            </span>
          </div>
          <div
            v-if="sizesTalentsCategories.length > 0"
            class="selector-section-wrapper"
          >
            <div class="section-title">
              <p>
                {{ sizesSectionTitle }}
              </p>
            </div>
            <span class="trait-selector">
              <ProServiceTraitSelector
                v-for="traitCategory in sizesTalentsCategories"
                :key="traitCategory.id"
                v-model:model-value="selectedTagsProxy"
                :tag-category="traitCategory"
              />
            </span>
          </div>
        </div>
      </div>
      <div v-if="mainTagsWithUploader" class="trait-bottom">
        <div
          v-if="
            editView &&
            (filterServiceImages.length > 0 ||
              capabilities.manage_any_pro_service_profile.hasCapability)
          "
          class="section-title"
        >
          <p>
            {{ imageSectionTitle }}
          </p>
        </div>
        <div class="service-image-upload">
          <div
            v-if="serviceImages"
            class="image-list"
            :class="{ edit: editView }"
          >
            <div
              v-for="(image, index) in filterServiceImages"
              :key="image.id"
              class="service-image"
            >
              <button
                v-if="
                  capabilities.manage_any_pro_service_profile.hasCapability &&
                  editView
                "
                class="u-button-reset remove-btn"
                title="remove photo"
                @click="() => removeImage(image)"
              >
                <svg
                  aria-hidden="true"
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10ZM15 9l-6 6M9 9l6 6"
                    stroke="#000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <p v-if="index === 0 && editView" class="primary-image-text">
                primary image
              </p>
              <img :src="image.url" class="thumbnail" />
            </div>
            <SoonaUpload
              v-if="
                capabilities.manage_any_pro_service_profile.hasCapability &&
                editView &&
                mainTagsWithUploader
              "
              class="upload-item-button"
              class-overide="upload-item-button"
              :icon-size-overide="true"
              :upload-complete="blob => saveBlob(blob)"
              :hide-icon="false"
              :is-multiple="true"
              label="add photo"
              accept=".jpeg, .jpg, .png, .gif"
            />
          </div>
          <div v-else class="thumbnail" />
        </div>
      </div>
    </div>
    <span class="button-wrapper">
      <SoonaButton
        v-if="
          service.selected_tags.length > 0 &&
          editView === true &&
          capabilities.manage_any_pro_service_profile.hasCapability
        "
        size="medium"
        variation="tertiary"
        data-cypress="psp-delete-service"
        @click="toggleConfirmationDialog"
      >
        delete service
      </SoonaButton>
      <span class="button-group">
        <SoonaButton
          v-if="service.selected_tags.length > 0 && editView"
          size="medium"
          variation="tertiary"
          @click="cancelEdit"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          v-if="
            (selectedTagsProxy.length > 0 &&
              service.selected_tags.length === 0) ||
            (service.selected_tags.length > 0 && editView)
          "
          :disabled="disableSubmit"
          size="medium"
          data-cypress="psp-submit-button"
          @click="toggleSubmit"
        >
          submit
        </SoonaButton>
      </span>
      <SoonaButton
        v-if="service.selected_tags.length > 0 && !editView"
        variation="tertiary"
        data-cypress="psp-edit-service"
        @click="editTraitSelection"
      >
        edit service
      </SoonaButton>
    </span>
    <DeleteConfirmationDialog
      v-if="showDeleteConfirmationDialog"
      @close="toggleConfirmationDialog"
      @confirm="deleteService"
    />
    <IncompleteTraitDialog
      v-if="showIncompleteTraitDialog"
      @close="submitPSPtraits"
      @confirm="() => (showIncompleteTraitDialog = false)"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import DeleteConfirmationDialog from './DeleteConfirmationDialog.vue';
import IncompleteTraitDialog from './IncompleteTraitDialog.vue';
import IncompleteTraitsWarning from './IncompleteTraitsWarning.vue';
import ProServiceTraitSelector from './ProServiceTraitSelector.vue';
import ProServiceProfileMeasurement from './ProServiceProfileMeasurement.vue';
import SavedTraits from './SavedTraits.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaTextField from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';
import { useRegexHelper } from 'src/composables/useRegexHelper';
import { ref } from 'vue';
import { useCapabilities } from '@/composables/useCapabilities';

export default {
  name: 'ProServiceProfileCard',
  components: {
    DeleteConfirmationDialog,
    IncompleteTraitDialog,
    IncompleteTraitsWarning,
    ProServiceTraitSelector,
    ProServiceProfileMeasurement,
    SavedTraits,
    SoonaButton,
    SoonaTextField,
    SoonaToggle,
    SoonaUpload,
  },
  props: {
    service: {
      type: Object,
      default: function () {
        return { selected_tags: [], is_active: true };
      },
    },
    proServiceProfile: {
      type: Object,
      default: function () {
        return { selected_tags: [] };
      },
    },
  },
  emits: ['updateAddingService'],
  setup() {
    const { urlRegex } = useRegexHelper();

    const capabilitiesRef = ref([
      { capability: 'manage_any_pro_service_profile' },
    ]);

    const capabilities = useCapabilities(capabilitiesRef);

    return {
      capabilities,
      urlRegex,
    };
  },
  data() {
    return {
      serviceImages: [],
      editView: false,
      disableToggle: false,
      showDeleteConfirmationDialog: false,
      selectedTagsProxy: [],
      selectedProServiceTraits: [],
      displayName: undefined,
      sampleWork: undefined,
      selectedMeasurementsProxy: [],
      validationFailed: false,
      showIncompleteTraitDialog: false,
    };
  },
  computed: {
    ...mapState({
      tagCategories: state => state.proService.currentTagCategories,
      userId: state => state.account.account_userId,
    }),
    ...mapGetters('proService', ['profileIncompleteTraits']),
    invalidSampleWork() {
      if (!this.sampleWork) {
        return false;
      }

      return !this.urlRegex.test(this.sampleWork);
    },
    disableSubmit() {
      if (this.validationFailed) {
        return this.validationFailed;
      }

      if (this.sampleWork) {
        return this.invalidSampleWork;
      }

      if (this.capabilities.manage_any_pro_service_profile.hasCapability) {
        return false;
      }
      return this.numSelectedRequiredTraits < this.requiredTraits.length
        ? true
        : false;
    },
    requiredTraits() {
      return this.categoriesToShow.filter(item => item.skippable === false);
    },
    numSelectedRequiredTraits() {
      let selectedRequiredTraits = [];
      let repeats = {};
      this.requiredTraits.forEach(item => {
        this.selectedTagsProxy.forEach(selectedTag => {
          if (
            selectedTag.tag_category_id === item.id &&
            !repeats[selectedTag.tag_category_id]
          ) {
            repeats[selectedTag.tag_category_id] = 1;
            selectedRequiredTraits.push(selectedTag);
          }
        });
      });
      if (this.showDisplayName && this.displayName && this.displayName !== '') {
        selectedRequiredTraits.push({
          title: 'display name',
          skippable: false,
          special_category: '',
        });
      }
      if (this.showSampleWork && this.sampleWork && this.sampleWork !== '') {
        selectedRequiredTraits.push({
          title: 'sample work',
          skippable: false,
          special_category: '',
        });
      }
      return selectedRequiredTraits.length;
    },
    nonRequiredTraits() {
      return this.categoriesToShow.filter(item => item.skippable === true);
    },
    numSelectedNonRequiredTraits() {
      let selectedNonRequiredTraits = [];
      let repeats = {};
      const ignoreTC = ['bra bust size', 'bra cup size', 'barefoot modeling'];

      this.nonRequiredTraits.forEach(item => {
        this.selectedTagsProxy.forEach(selectedTag => {
          if (
            selectedTag.tag_category_id === item.id &&
            !ignoreTC.includes(selectedTag.tag_category_title) &&
            !repeats[selectedTag.tag_category_id]
          ) {
            repeats[selectedTag.tag_category_id] = 1;
            selectedNonRequiredTraits.push(selectedTag);
          }
        });
      });
      return selectedNonRequiredTraits.length;
    },
    mainTag() {
      let defaultTag = {};
      if (this.service.selected_tags.length > 0) {
        defaultTag = this.service.selected_tags.find(t => t.default === true);
      } else {
        defaultTag = this.selectedTagsProxy.find(t => t.default === true) || {};
      }
      return defaultTag;
    },
    categoriesToShow() {
      const ignoreServiceTypes = [
        'full body model',
        'hand model',
        'pet model',
        'foot model',
        'content creator',
        'manicure',
        'pedicure',
      ];
      let categoriesToShow = this.tagCategories.filter(
        tc =>
          !ignoreServiceTypes.includes(tc.title) &&
          this.selectedTagsProxy.some(t =>
            t.tag_follow_up_categories.some(
              tfuc => tfuc.tag_category_id === tc.id
            )
          )
      );
      if (this.showDisplayName) {
        categoriesToShow.push({
          title: 'display name',
          skippable: false,
          special_category: '',
        });
      }
      if (this.showSampleWork) {
        categoriesToShow.push({
          title: 'sample work',
          skippable: true,
          special_category: '',
        });
      }
      return categoriesToShow;
    },
    hasSizeTraits() {
      return this.categoriesToShow.some(
        tc => tc.special_category === 'psp_trait_size'
      );
    },
    hasTalentTraits() {
      return this.categoriesToShow.some(
        tc => tc.special_category === 'psp_trait_talent'
      );
    },
    showDisplayName() {
      const serviceTypes = [
        'baby: 0-1 y/o',
        'toddler: 1-3 y/o',
        'kid: 4-12 y/o',
        'teen: 12-18 y/o',
        'pet model',
      ];
      return this.selectedTagsProxy.some(t => serviceTypes.includes(t.title));
    },
    showSampleWork() {
      const serviceTypes = ['content creator'];
      return this.selectedTagsProxy.some(t => serviceTypes.includes(t.title));
    },
    imageSectionTitle() {
      let service = this.mainTag.title ? this.mainTag.title + ' ' : '';
      return service + 'Images';
    },
    sizesSectionTitle() {
      return this.hasSizeTraits && this.hasTalentTraits
        ? 'Talents & Sizes'
        : 'Sizes';
    },
    basicInfoCategories() {
      return this.categoriesToShow.filter(
        tc => tc.special_category === 'psp_trait_basic_info'
      );
    },
    sizesTalentsCategories() {
      return this.categoriesToShow.filter(
        tc =>
          tc.special_category === 'psp_trait_size' ||
          tc.special_category === 'psp_trait_talent'
      );
    },
    defaultCategories() {
      return this.tagCategories.filter(tc => tc.default === true);
    },
    toggleService: {
      get() {
        return this.service.is_active;
      },
      set(bool) {
        this.updateProfileTraits({
          userId: this.userId,
          profileId: this.service.id,
          isActive: bool,
        })
          .then(() => {
            this.editView = false;
          })
          .catch(error => {
            console.error(error);
          });
      },
    },
    serviceIsActive() {
      return this.service.is_active;
    },
    filterServiceImages() {
      return this.serviceImages.filter(image => image._destroy !== 1);
    },
    mainTagsWithUploader() {
      return (
        this.mainTag.title === 'full body model' ||
        this.mainTag.title === 'hand model' ||
        this.mainTag.title === 'pet model' ||
        this.mainTag.title === 'foot model' ||
        this.mainTag.title === 'content creator'
      );
    },
    showMeasurements() {
      const tagsWithMeasurements = [
        'kid: 4-12 y/o',
        'teen: 12-18 y/o',
        'young adult: 19-24 y/o',
        'adult: 25-35 y/o',
        'middle age adult: 36-55 y/o',
        'mature: 56-65+ y/o',
        'pet model',
      ];
      return (
        this.service.required_measurements &&
        this.selectedTagsProxy.some(t => tagsWithMeasurements.includes(t.title))
      );
    },
    totalIncompleteTraits() {
      const totalCount = this.numAllTraits - this.numSelectedTraits;
      return totalCount;
    },
    numSelectedTraits() {
      let filterSelectedMeasurements = [];
      if (this.showMeasurements) {
        filterSelectedMeasurements = this.selectedMeasurementsProxy.filter(
          m => m.value !== ''
        );
      }
      return (
        filterSelectedMeasurements.length +
        this.numSelectedNonRequiredTraits +
        this.numSelectedRequiredTraits
      );
    },
    numAllTraits() {
      return this.showMeasurements
        ? this.service.required_measurements.length +
            this.filteredSizesTalentsCategories?.length
        : this.filteredSizesTalentsCategories?.length;
    },
    filteredSizesTalentsCategories() {
      return this.categoriesToShow.filter(
        t =>
          t.title !== 'bra cup size' &&
          t.title !== 'bra bust size' &&
          t.title !== 'barefoot modeling'
      );
    },
    incompleteServiceTraitCount() {
      return this.profileIncompleteTraits[this.service.id] || 0;
    },
    displayIncompleteTrait() {
      const extraServiceTypes = [
        'hair and makeup',
        'grocery shopping',
        'styling',
        'steaming',
      ];

      const extraServiceTypesIncluded = this.selectedTagsProxy.some(t =>
        extraServiceTypes.includes(t.title)
      );

      const onlyMainTagSelected =
        this.service.selected_tags.length === 1 &&
        this.service.selected_tags.some(
          t => t.tag_category_title === 'pro services'
        ) &&
        this.incompleteServiceTraitCount === 1;

      if (
        this.totalIncompleteTraits === 1 &&
        this.numAllTraits === 1 &&
        !extraServiceTypesIncluded
      ) {
        return '';
      } else if (this.editView === true) {
        return this.totalIncompleteTraits;
      } else {
        return onlyMainTagSelected ? 0 : this.incompleteServiceTraitCount;
      }
    },
    showIncompleteTrait() {
      if (
        this.displayIncompleteTrait > 0 ||
        this.displayIncompleteTrait === ''
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectedBasicInfoCategories() {
      return this.service.selected_tags
        .filter(
          tc =>
            tc.special_category === 'psp_trait_basic_info' &&
            tc.tag_category_title !== 'pro services'
        )
        .sort((a, b) => a.tag_category_order - b.tag_category_order)
        .reduce((previousValue, currentValue) => {
          const index = previousValue.findIndex(
            p => p.traitId === currentValue.tag_category_id
          );
          if (index > -1) {
            previousValue[index].selectedTraits += ', ' + currentValue.title;
          } else {
            previousValue.push({
              traitTitle: currentValue.tag_category_title,
              selectedTraits: currentValue.title,
              traitId: currentValue.tag_category_id,
            });
          }
          return previousValue;
        }, []);
    },
    selectedSizesTalentsCategories() {
      return this.service.selected_tags
        .filter(
          tc =>
            tc.special_category === 'psp_trait_size' ||
            tc.special_category === 'psp_trait_talent'
        )
        .sort((a, b) => a.tag_category_order - b.tag_category_order)
        .reduce((previousValue, currentValue) => {
          const index = previousValue.findIndex(
            p => p.traitId === currentValue.tag_category_id
          );
          if (index > -1) {
            previousValue[index].selectedTraits += ', ' + currentValue.title;
          } else {
            previousValue.push({
              traitTitle: currentValue.tag_category_title,
              selectedTraits: currentValue.title,
              traitId: currentValue.tag_category_id,
            });
          }
          return previousValue;
        }, []);
    },
  },
  watch: {
    proServiceProfile() {
      //update the local copy if a different selector changes the selected tags
      this.selectedTagsProxy = this.proServiceProfile.selected_tags.map(a => {
        return { ...a };
      });
    },
    serviceIsActive() {
      this.disableToggle = true;
      setTimeout(() => (this.disableToggle = false), 1500);
    },
    selectedTagsProxy(newValue, oldValue) {
      let oldAgeGroup = oldValue.find(
        x => x.tag_category_title === 'age group'
      );
      let newAgeGroup = newValue.find(
        x => x.tag_category_title === 'age group'
      );

      if (
        !!oldAgeGroup &&
        !!newAgeGroup &&
        oldAgeGroup.title !== newAgeGroup.title
      ) {
        this.resetForm();
      }
    },
  },
  mounted() {
    this.createProxy();
    this.checkEditView();
    this.prefillExistingData();

    this.$emitter.on('set-validation-errors', err => {
      if (err.errors.length > 0) {
        this.validationFailed = true;
      } else {
        this.validationFailed = false;
      }
    });
  },
  methods: {
    ...mapActions('proService', [
      'submitChosenProfileTraits',
      'updateProfileTraits',
      'deleteProServiceProfile',
    ]),
    prefillExistingData() {
      this.displayName = this.service.name;
      this.sampleWork = this.service.sample_work;
      this.serviceImages = this.service?.images ? this.service?.images : [];
    },
    createProxy() {
      if (this.service.selected_tags.length > 0) {
        this.selectedTagsProxy = this.service.selected_tags.map(a => {
          return { ...a };
        });
      } else {
        this.selectedTagsProxy = this.proServiceProfile.selected_tags.map(a => {
          return { ...a };
        });
      }

      if (this.service.measurements?.length > 0) {
        this.selectedMeasurementsProxy = this.service.measurements.map(meas => {
          return { ...meas };
        });
      }
    },
    checkEditView() {
      if (this.service.selected_tags.length > 0) {
        this.editView = false;
      } else {
        this.editView = true;
      }
    },
    submitPSPtraits() {
      if (this.showIncompleteTraitDialog) {
        this.showIncompleteTraitDialog = false;
      }
      if (this.displayName === '') {
        this.displayName = null;
      }
      if (this.sampleWork === '') {
        this.sampleWork = null;
      }
      if (this.service.selected_tags.length > 0) {
        this.updateProfileTraits({
          userId: this.userId,
          profileId: this.service.id,
          selectedList: this.selectedTagsProxy,
          displayName: this.displayName,
          sampleWork: this.sampleWork,
          images: this.serviceImages,
          measurements: this.selectedMeasurementsProxy,
        })
          .then(() => {
            this.editView = false;
            this.$emit('updateAddingService');
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        this.submitChosenProfileTraits({
          userId: this.userId,
          selectedList: this.selectedTagsProxy,
          displayName: this.displayName,
          sampleWork: this.sampleWork,
          images: this.serviceImages,
          measurements: this.selectedMeasurementsProxy,
        })
          .then(() => {
            this.selectedTagsProxy = [];
            this.selectedProServiceTraits = [];
            this.editView = false;
            this.addingService = false;
            this.displayName = undefined;
            this.sampleWork = undefined;
            this.prefillExistingData();
            this.$emit('updateAddingService');
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    editTraitSelection() {
      this.editView = true;
      this.createProxy();
    },
    cancelEdit() {
      this.editView = false;
      this.prefillExistingData();
    },
    saveBlob(blob) {
      blob.url = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
      this.serviceImages = [...this.serviceImages, blob];
    },
    removeImage(image) {
      this.serviceImages = this.serviceImages.map(img => {
        const newImage = { ...img };

        if (img.id === image.id) {
          newImage._destroy = 1;
        }

        return newImage;
      });
    },
    toggleConfirmationDialog() {
      this.showDeleteConfirmationDialog = !this.showDeleteConfirmationDialog;
    },
    deleteService() {
      this.deleteProServiceProfile({
        userId: this.userId,
        profileId: this.service.id,
      })
        .then(() => {
          this.editView = false;
        })
        .catch(error => {
          console.error(error);
        });

      this.toggleConfirmationDialog();
    },
    toggleSubmit() {
      if (
        this.totalIncompleteTraits > 0 &&
        !this.capabilities.manage_any_pro_service_profile.hasCapability
      ) {
        this.showIncompleteTraitDialog = true;
      } else {
        this.submitPSPtraits();
      }
    },
    resetForm() {
      const saveTC = ['age group', 'pro services'];

      const saveSelection = this.selectedTagsProxy.filter(t =>
        saveTC.includes(t.tag_category_title)
      );

      this.selectedTagsProxy = saveSelection;
      this.selectedMeasurementsProxy = [];
      this.displayName = '';
      this.sampleWork = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.ProServiceProfileCard {
  .section-title {
    p {
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 2.5px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }
  .required {
    color: variables.$friendly-red-50;
  }
  hr {
    margin: 20px 0;
  }
  .trait-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .trait-bottom {
      width: 100%;
      .service-image-upload {
        .soona-uploader {
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          height: 150px;
          width: 100%;
          border-radius: 3px;
          border: 0.125rem solid variables.$periwink-blue-60;
          font-size: 12px;
          color: variables.$periwink-blue-60;
          margin-top: 0;
        }
        .soona-uploader:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        .service-image {
          height: 9.375rem;
          position: relative;
        }

        .thumbnail {
          display: block;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }

        .replace-uploader,
        .replace-uploader:hover {
          height: auto;
          color: variables.$white-default;
          background-color: variables.$friendly-red-50;
          border: 0.125rem solid variables.$gray-10;
          margin: 0;
          padding: 6px;
        }

        .replace-label-btn {
          z-index: 10;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          height: 28px;
          font-weight: 900;
          font-size: 12px;
          width: 100%;
          max-width: 6rem;
          margin-bottom: 1rem;
          margin-top: 0;
          display: flex;
          justify-content: space-between;
          border-radius: 999px;
          padding: 5px 0 0 16px;
          letter-spacing: 0.1rem;
        }
      }
    }
    .trait-top {
      display: inline-flex;
      flex-direction: column;
      flex-grow: 1;

      .trait-title {
        font-size: 1rem;
        font-weight: 800;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .traits-toggle {
          margin-left: auto;
          margin-bottom: 5px;
        }
      }
      .display-name {
        width: 250px;
        display: flex;
        flex-direction: column;
        .display-label {
          font-size: 14px;
          margin-bottom: 5px;
        }
        .display-input {
          border: 1px solid rgba(60, 60, 60, 0.26);
          border-radius: 4px;
          font-size: 1rem;
          padding: 4px 8px;
        }
      }
      .selector-section-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.875rem;
        .display-name {
          width: 15.625rem;
          margin: 0.625rem 1.875rem 0.625rem 0;
          display: inline-flex;
          flex-direction: column;
          .display-label {
            font-size: 14px;
            margin-bottom: 5px;
          }
          .display-input {
            border: 1px solid rgba(60, 60, 60, 0.26);
            border-radius: 4px;
            font-size: 1rem;
            min-height: 40px;
            padding: 4px 8px;
          }
        }
      }
      .trait-selector {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .trait-description {
      flex-direction: column;
      .trait-top {
        display: inline-flex;
        flex-direction: column;
        flex-grow: 1;
        flex-wrap: wrap;
        .trait-title {
          margin-top: 10px;
        }

        .selector-traits-section-editing {
          .display-name {
            margin: 0.625rem;
          }
          .trait-selector {
            justify-content: center;
            .trait-option {
              margin: 0.625rem;
            }
          }
        }
      }
      .trait-bottom {
        margin: 0;
      }
    }
  }
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
}

.button-group {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.submit-button {
  height: auto;
  background-color: #e0603d;
  color: variables.$white-default;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 10px 30px;
  font-weight: 900;
  letter-spacing: 0.2rem;
  font-size: 15px;
}
.cancel {
  margin-right: 10px;
}

.image-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 9.375rem);
  grid-column-gap: 0.625rem;
  grid-row-gap: 0.625rem;
  justify-content: center;
  margin-top: 30px;

  @media (min-width: variables.$screen-sm-min) {
    justify-content: flex-start;
  }

  .primary-image-text {
    @include variables_fonts.u-button--small-caps;

    margin: 0.625rem 0;
    display: flex;
    align-items: center;
    position: absolute;
    top: -32px;
  }
}

.image-list.edit {
  margin-bottom: 30px;
}

.remove-btn {
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;

  svg {
    fill: variables.$white-default;
    transition: fill 0.1s ease-out;
  }

  &:hover,
  &:focus-visible {
    svg {
      fill: variables.$gray-20;
    }
  }

  &:active {
    svg {
      fill: variables.$gray-30;
    }
  }
}
</style>

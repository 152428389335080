<script setup>
import { computed, nextTick, provide, ref, watch } from 'vue';
import { useAccount } from '@/queries/account/useAccount';
import { useCapability } from '@/composables/useCapability';
import { useFlag } from '@/composables/useFlag';
import { useRoute } from 'vue-router';
import { useSideNavState } from './useSideNavState';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';

import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import HeaderNav from './header/HeaderNav.vue';
import PrimaryNav from './sidebar/PrimaryNav.vue';
import PageContainer from './PageContainer.vue';
import QuestLayout from '@/components/quest/QuestLayout.vue';
import { useStore } from 'vuex';
import { USE_ROUTER_META_PROVIDER_NAME } from '@/components/platform-layout/platform-sidebar-layout-provide';
import { useDialogContext } from '@/composables/useDialog';
import uniqueId from 'lodash/uniqueId';

const props = defineProps({
  useRouterMeta: {
    type: Boolean,
    default: true,
  },
});

defineOptions({ inheritAttrs: false });

provide(
  USE_ROUTER_META_PROVIDER_NAME,
  computed(() => props.useRouterMeta)
);

const onboardingQuestFlag = useFlag('apollo_voyage_facilitator');

const store = useStore();
const isAuthenticated = computed(
  () => store.getters['currentUser/isAuthenticated']
);
const route = useRoute();

const defaultLayoutOptions = {
  sidebarCollapsed: false,
  noContainer: false,
};
const routeLayoutOptions = computed(() => {
  if (props.useRouterMeta && route.meta?.routeLayoutOptions) {
    return {
      ...defaultLayoutOptions,
      ...route.meta.routeLayoutOptions,
    };
  }
  return defaultLayoutOptions;
});

const { isSidebarFloatOpen, isSidebarPushOpen, setSidebarOpen } =
  useSideNavState(routeLayoutOptions);

const sidebarFloatDialogEl = ref(null);
const { activate: focusTrapActivate, deactivate: focusTrapDeactivate } =
  useFocusTrap(sidebarFloatDialogEl);

const id = uniqueId('platform-layout-nav-dialog-');
const { start, stop } = useDialogContext({
  id,
  immediate: false,
});

watch(isSidebarFloatOpen, isOpen => {
  if (isOpen) {
    nextTick(() => {
      focusTrapActivate();
      start();
    });
  } else {
    focusTrapDeactivate();
    stop();
  }
});

function navClickAutoClose(e) {
  // auto-close menu when clicking on any link
  if (e.target.tagName === 'A') {
    setSidebarOpen(() => false);
    window.scrollTo(0, 0);
  }
}

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const accountId = computed(() => store.state.currentUser.accountId);
const { data: account } = useAccount(accountId);
const questId = computed(() => account.value?.platform_quest_id);
const showQuest = computed(
  () =>
    onboardingQuestFlag.value &&
    questId.value &&
    route.meta.show_quest &&
    props.useRouterMeta &&
    !isFtSoonaStaff.value
);
</script>

<template>
  <HeaderNav>
    <template #menu-toggle>
      <SoonaButton
        class="platform-toggle-button"
        variation="icon-plain-gray"
        data-cypress="header-menu-toggle"
        :aria-expanded="isSidebarFloatOpen || isSidebarPushOpen"
        aria-controls="platform-layout-nav-aside platform-layout-nav-dialog"
        @on-click="setSidebarOpen(prev => !prev)"
      >
        <SoonaIcon :name="isSidebarFloatOpen ? 'xmark' : 'menu'" />
        <span class="u-visually-hidden">toggle navigation</span>
      </SoonaButton>
    </template>
  </HeaderNav>
  <QuestLayout v-if="showQuest" :quest-id="questId" />
  <div class="platform-sidebar-layout">
    <Transition name="sidebar-aside">
      <aside
        v-if="isAuthenticated && isSidebarPushOpen"
        id="platform-layout-nav-aside"
        class="platform-sidebar-layout__aside"
      >
        <div class="platform-sidebar-layout__aside-wrap">
          <PrimaryNav />
        </div>
      </aside>
    </Transition>
    <main
      class="platform-sidebar-layout__main"
      :class="{ 'platform-sidebar-layout__main--bot-padding': showQuest }"
    >
      <slot v-if="routeLayoutOptions.noContainer" />
      <PageContainer v-else>
        <slot />
      </PageContainer>
    </main>
  </div>
  <Teleport to="body">
    <Transition name="sidebar-float">
      <div
        v-if="isAuthenticated && isSidebarFloatOpen"
        id="platform-layout-nav-dialog"
        ref="sidebarFloatDialogEl"
        role="dialog"
        aria-modal="true"
        class="sidebar-float-dialog"
        @click.self="setSidebarOpen(() => false)"
        @keydown.esc="setSidebarOpen(() => false)"
      >
        <div class="sidebar-float-dialog__nav-wrap" @click="navClickAutoClose">
          <div class="sidebar-float-dialog__above-nav">
            <SoonaButton
              class="platform-toggle-button"
              variation="icon-plain-gray"
              data-cypress="header-menu-toggle"
              @on-click="setSidebarOpen(() => false)"
            >
              <SoonaIcon name="xmark" />
              <span class="u-visually-hidden">close navigation</span>
            </SoonaButton>
          </div>
          <PrimaryNav />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';

.platform-sidebar-layout {
  display: flex;
  flex: 1 1 auto;

  &__aside {
    flex: none;
    width: 16.25rem;
    background: variables.$white-default;
    margin-left: 0;
  }

  &__main {
    flex: 1 1 auto;
    isolation: isolate;
    /* prevent overflowing */
    max-width: 100%;
    min-width: 0;
  }

  &__aside-wrap {
    position: sticky;
    top: var(--app-header-height);
    overflow: hidden;
    height: calc(100dvh - var(--app-header-viewport-offset));

    @supports not (height: 100dvh) {
      height: calc(100vh - var(--app-header-viewport-offset));
    }
  }
}

.sidebar-aside-enter-from,
.sidebar-aside-leave-to {
  margin-left: -16.25rem;
}
.sidebar-aside-enter-active,
.sidebar-aside-leave-active {
  transition: margin-left 0.2s ease-in-out;
}

.sidebar-float-enter-from,
.sidebar-float-leave-to {
  &.sidebar-float-dialog {
    background-color: rgba(0, 0, 0, 0);
  }

  .sidebar-float-dialog__nav-wrap {
    margin-left: -16.25rem;
  }
}

.sidebar-float-enter-active,
.sidebar-float-leave-active {
  transition: background-color 0.2s ease;

  .sidebar-float-dialog__nav-wrap {
    transition: margin-left 0.2s ease-in-out;
  }
}

@media (prefers-reduced-motion: reduce) {
  .sidebar-aside-enter-active,
  .sidebar-aside-leave-active,
  .sidebar-float-enter-active,
  .sidebar-float-leave-active,
  .sidebar-float-enter-active .sidebar-float-dialog__nav-wrap,
  .sidebar-float-leave-active .sidebar-float-dialog__nav-wrap {
    transition: none;
  }
}

.sidebar-float-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;

  &__nav-wrap {
    margin-left: 0;
    max-width: 16.25rem;
    width: 100%;
    background: variables.$white-default;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__above-nav {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: 0.5rem;
    justify-content: flex-end;
    padding: 0.75rem;
    box-shadow: variables.$elevation-1;
    z-index: 1; /* make shadow layer on top of below nav */
  }
}

.platform-toggle-button {
  color: variables.$black-default;
}

@media print {
  .platform-sidebar-layout__aside,
  .sidebar-float-dialog {
    display: none;
  }
}

@media (min-width: variables.$screen-md-min) {
  .platform-sidebar-layout__main--bot-padding {
    padding-bottom: 4rem;
  }
}
</style>

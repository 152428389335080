<template>
  <div>
    <div class="columns is-gapless" data-cypress="assigned-bookings">
      <div class="column is-full is-full-mobile">
        <section class="dashboard-section">
          <KPIcards v-if="canViewKpis" :user-id="account.owner.id" />
          <div v-if="isFtSoonaStaff" class="gallery-title-container"></div>
          <VerticalGallery
            title="assigned bookings"
            type="assignment"
            :reservations="assignedReservations"
            :own-account="isMyAccount"
            :has-next-page="hasNextPage"
            :fetch-next-page="fetchNextPage"
            class="assignment-gallery"
          />
          <VerticalGallery
            v-if="crewEditsReservations.length > 0"
            title="assigned edits"
            type="assignment"
            :disable-calendar="true"
            :reservations="crewEditsReservations"
            :own-account="isMyAccount"
            class="assignment-gallery"
          />
        </section>
      </div>
    </div>

    <BookingGallery
      title="past assignments"
      :reservations="pastAssignments"
      link-to="info"
      :load-more="loadMorePastAssignments"
      :last-page="lastPreviousPage"
      :include-see-all="false"
      :hide-assignments="true"
    >
      <template #empty-message
        >your past assignments will appear here!</template
      >
    </BookingGallery>
  </div>
</template>

<script>
import { computed } from 'vue';
import { mapState, mapActions, mapGetters, useStore } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import BookingGallery from 'src/components/user/anytime/BookingGallery.vue';
import VerticalGallery from 'src/components/user/anytime/dashboard/VerticalGallery.vue';
import KPIcards from 'src/components/user/anytime/dashboard/KPIcards.vue';
import { useGetAssignedReservations } from 'src/queries/useGetAssignedReservations';

export default {
  components: {
    BookingGallery,
    VerticalGallery,
    KPIcards,
  },
  props: {
    editsLoad: Boolean,
    shopMoreLoad: Boolean,
  },
  setup() {
    const store = useStore();
    const userId = computed(() => store.state.account?.owner.id);
    const {
      data: assignedReservationsPages,
      fetchNextPage,
      hasNextPage,
    } = useGetAssignedReservations(userId, {
      itemsPerPage: 10,
    });

    const assignedReservations = computed(() =>
      (assignedReservationsPages.value?.pages || []).flatMap(
        page => page.reservations
      )
    );

    const { hasCapability: isFtSoonaStaff } = useCapability({
      capability: 'ft_soona_staff',
    });

    const { hasCapability: isSoonaStaff } = useCapability({
      capability: 'soona_staff',
    });

    const { hasCapability: isAssignedToClient } = useCapability({
      capability: 'assigned_to_client',
    });

    const { hasCapability: canViewAllKpis } = useCapability({
      capability: 'view_all_kpis',
    });

    return {
      assignedReservations,
      canViewAllKpis,
      fetchNextPage,
      hasNextPage,
      isAssignedToClient,
      isFtSoonaStaff,
      isSoonaStaff,
    };
  },
  data() {
    return {
      lastPreviousPage: true,
    };
  },
  computed: {
    ...mapState({
      userId: state => state.user.id,
      errors: state => state.errors.errors,
      account: state => state.account,
      pastAssignments: state => state.dashboard.pastAssignments,
      crewEditsReservations: state => state.dashboard.crewEditsReservations,
      isMyAccount: state => state.currentUser.accountId === state.account.id,
    }),
    ...mapGetters('errors', ['getErrorMessage', 'getErrorMessages']),
    canViewKpis() {
      return this.canViewAllKpis || this.isMyAccount;
    },
  },
  mounted() {
    if (this.isAssignedToClient || this.isSoonaStaff) {
      this.loadCrewEditsReservations(this.account.owner.id);
      this.loadPastAssignments({
        userId: this.account.owner.id,
        page: 1,
      }).then(response => {
        this.lastPreviousPage = response;
      });
    }
  },
  methods: {
    ...mapActions('dashboard', [
      'loadPastAssignments',
      'loadCrewEditsReservations',
    ]),
    loadMorePastAssignments(page) {
      let args = {
        userId: this.account.owner.id,
        page: page,
      };

      this.loadPastAssignments(args).then(response => {
        this.lastPreviousPage = response;
      });
    },
  },
};
</script>

<style lang="scss">
.assignment-gallery {
  display: block;
  margin-top: 20px;
}

.unavailability-modal-btn {
  margin-left: auto;
}

.dashboard-section .gallery-title-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}
</style>

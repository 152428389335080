<script setup>
import { useCreateReservation } from '@/queries/useCreateReservation';
import { computed, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { useAccount } from '@/queries/account/useAccount';
import { usePriorityError } from '@/composables/usePriorityError';

const props = defineProps({
  accountId: {
    type: String,
    required: false,
  },
  copyPackages: {
    type: String,
    required: false,
  },
  draftType: {
    type: String,
    required: false,
  },
  previousReservationId: {
    type: String,
    required: false,
  },
  copyShotList: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
  },
});

const router = useRouter();
const hasAccount = computed(() => typeof props.accountId !== 'undefined');

const {
  data: account,
  isLoading: accountIsLoading,
  isSuccess: accountIsSuccess,
  error: accountError,
} = useAccount(
  computed(() => props.accountId),
  { enabled: hasAccount }
);

const {
  error: createError,
  isPending: createIsLoading,
  mutate,
} = useCreateReservation();

const isLoading = computed(
  () => createIsLoading.value || accountIsLoading.value
);
const priorityError = usePriorityError(createError, accountError);

watchEffect(() => {
  if (accountIsSuccess.value || !hasAccount.value) {
    mutate(
      {
        reservation: {
          account_id: props.accountId,
          booking_flow: 'crew',
          coupon_code: props.discount,
          draft_type: props.draftType,
          previous_id: props.previousReservationId,
          reservation_line_items: [],
          reservation_tags: [],
          reservation_type: props.type || 'anytime',
          skipped_pack: true,
          use_primary_location: true,
          ...(account.value?.owner?.id && {
            point_of_contact_user_id: account.value.owner.id,
          }),
        },
        copy_packages: props.copyPackages,
        shot_status: props.copyShotList,
      },
      {
        onSuccess: reservation => {
          router.push({
            path: `/crew/booking/${reservation.id}/edit`,
            replace: true,
          });
        },
      }
    );
  }
});
</script>

<template>
  <div class="crew-booking-new">
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <SoonaLoading v-else :is-loading="isLoading" loading-text="loading" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.crew-booking-new {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 34.875rem;
}
</style>

<script setup>
import { computed, ref } from 'vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUploader } from '@/composables/useUploader';
import BeatLoader from '@/components/shared/BeatLoader.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaProgress from '@/components/ui_library/SoonaProgress.vue';
import SoonaUploadForm from '@/components/uploader/SoonaUploadForm.vue';
import UploadProgressDialog from '@/components/media-editor/UploadProgressDialog.vue';

const emit = defineEmits(['handle-upload-complete']);

const { addToast } = useSoonaToast();

const maxConcurrent = ref(5);
const uploadInterval = ref(100);
const fileSizeLimit = ref(50);
const activeUploadsLocal = ref([]);
const cancelUploadsLocal = ref(() => {});
const alertMessage = ref('');
const errorMessage = ref('');
const acceptedFileTypes = ref('.png,.jpg,.jpeg');

function validateNotCR2(file) {
  if (file.name.toLowerCase().endsWith('.cr2'))
    return 'cannot upload RAW (CR2) files here';
}

const showAlertOrError = (type = 'alert', message) => {
  if (type === 'error') {
    errorMessage.value = message;
    return;
  }

  alertMessage.value = message;
};

const handleUploadError = errMsg => {
  cancelUploadsLocal.value();
  showAlertOrError('error', errMsg);
};

const handleUploadComplete = blob => {
  emit('handle-upload-complete', blob);
};

const { handleUpload, handleDrop, activeUploads, cancelUploads } = useUploader(
  maxConcurrent,
  uploadInterval,
  fileSizeLimit,
  validateNotCR2,
  handleUploadError,
  handleUploadComplete
);

activeUploadsLocal.value = activeUploads.value;
cancelUploadsLocal.value = cancelUploads;

const completedUploads = computed(() =>
  activeUploads.value.filter(upload => upload.completed)
);

const priorityError = usePriorityError(errorMessage);

const isUploading = computed(() => activeUploads.value.length > 0);
defineExpose({ isUploading });

const handleCancelUploads = () => {
  if (completedUploads.value.length > 0) {
    cancelUploads();
  } else {
    cancelUploads();
    addToast('upload canceled', {
      variation: 'info',
      iconName: 'xmark',
    });
  }
};
</script>

<template>
  <SoonaUploadForm
    class="upload-media"
    :accept="acceptedFileTypes"
    :active-uploads="activeUploads"
    is-multiple
    label="upload media"
    @drop="
      $event => {
        alertMessage = '';
        errorMessage = '';
        handleDrop($event);
      }
    "
    @upload="
      $event => {
        alertMessage = '';
        errorMessage = '';
        handleUpload($event);
      }
    "
  >
    <template #content-top>
      <UploadProgressDialog v-if="isUploading">
        <div class="upload-media__progresses">
          <em class="u-subheader--heavy upload-media__progresses-title">
            uploading…
          </em>
          <SoonaProgress
            v-for="(upload, index) in activeUploads"
            :key="index"
            class="upload-media__progress"
            :error="upload.error"
            :is-waiting="!upload.activated"
            :value="(upload.tracker?.loaded / upload.tracker?.total ?? 0) * 100"
          />
        </div>
        <SoonaButton
          v-if="isUploading"
          variation="tertiary"
          size="medium"
          @on-click="handleCancelUploads"
        >
          <template
            v-if="completedUploads.length > 0 && activeUploads.length > 1"
          >
            cancel incomplete upload(s)
          </template>
          <template v-else>cancel upload</template>
        </SoonaButton>
      </UploadProgressDialog>
      <BeatLoader v-if="activeUploadsLocal.length" size="1rem" />
    </template>
    <template #content-bottom>
      <SoonaAlert v-if="alertMessage" class="upload-media__notification">
        {{ alertMessage }}
      </SoonaAlert>
      <SoonaError
        v-if="errorMessage || priorityError"
        class="upload-media__notification"
      >
        {{ errorMessage || priorityError }}
      </SoonaError>
    </template>
  </SoonaUploadForm>
</template>

<style lang="scss" scoped>
.upload-media {
  height: 100%;
  min-height: auto;

  &__progresses {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.75rem;
    min-width: 17.75rem;

    &-title {
      font-style: normal;
    }
  }

  &__progress {
    width: 16.25rem;
  }

  &__notification {
    margin: 1rem 0 0;
  }
}
</style>

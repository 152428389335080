import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {string | Ref<string>} collectionId
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useCreateFavoritesCollectionDigitalAsset(collectionId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.post(
        `/collections/${toValue(
          collectionId
        )}/favorites_collection_digital_assets`,
        { collection_digital_asset: body }
      );
      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.digitalAssetsObliterateAllKeys(),
        }),
      ]);
    },
  });
}

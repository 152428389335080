import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getCatalogItemReservations } from '../api/catalog';

/**
 *
 * @param {string | number} accountId
 * @param {string | number} catalogItemId
 * @param {Object} [params]
 * @param {number} [params.itemsPerPage]
 */
export function useGetCatalogItemReservations(
  accountId,
  catalogItemId,
  { itemsPerPage, currentPage } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.catalogItemReservations(accountId, catalogItemId, {
      itemsPerPage,
      currentPage,
    }),
    queryFn: ({ signal, pageParam = { currentPage, itemsPerPage } }) =>
      getCatalogItemReservations(
        unref(accountId),
        unref(catalogItemId),
        {
          currentPage: unref(pageParam.currentPage),
          itemsPerPage: unref(pageParam.itemsPerPage),
        },
        signal
      ),
    ...queryOptions,
  });
}

<script setup>
import { computed, ref, watch } from 'vue';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import { useInvoices } from '@/queries/invoices/useInvoices';
import InvoiceItem from '@/components/subscriptions/InvoiceItem.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const accountId = computed(() => props.accountId);

const currentPage = ref(1);
const itemsPerPage = ref(10);

const sortOptions = [
  { label: 'created (new to old)', value: 'created_at desc' },
  { label: 'created (old to new)', value: 'created_at asc' },
  { label: 'updated (new to old)', value: 'updated_at desc' },
  { label: 'updated (old to new)', value: 'updated_at asc' },
  { label: 'status z-a', value: 'status desc' },
  { label: 'status a-z', value: 'status asc' },
];

const selectedSort = ref('created_at desc');

const orderBy = computed(() => {
  const [field] = selectedSort.value.split(' ');
  return field;
});
const orderDir = computed(() => {
  const [, direction] = selectedSort.value.split(' ');
  return direction;
});

watch([itemsPerPage, selectedSort], () => {
  currentPage.value = 1;
});

const {
  data: invoicesData,
  isLoading: isInvoicesLoading,
  isSuccess: isInvoicesSuccess,
  error: invoicesError,
} = useInvoices(accountId, {
  currentPage,
  itemsPerPage,
  orderBy,
  orderDir,
});

const invoices = computed(() => invoicesData.value?.invoices ?? []);

const invoicesPagination = computed(() => {
  if (invoicesData.value) {
    return invoicesData.value.pagination;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});

const priorityError = usePriorityError(invoicesError);
</script>

<template>
  <div class="subscription-invoices-header">
    <h2 class="u-title--heavy">subscription payments</h2>
    <SoonaSelect
      v-model="selectedSort"
      :options="sortOptions"
      inline
      :disabled="isInvoicesLoading"
    >
      <template #label>sort by</template>
    </SoonaSelect>
  </div>
  <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
  <template v-if="isInvoicesLoading">
    <SoonaSkeleton v-for="n in 3" :key="n" class="subscription-tier-skeleton" />
  </template>
  <SoonaNoResults
    v-if="isInvoicesSuccess && invoices.length < 1"
    :show-image="false"
  >
    <template #header>no subscription payments</template>
    <template #button>
      <SoonaButton
        variation="pizzazz"
        element="router-link"
        :to="`/account/${accountId}/subscriptions`"
      >
        explore plans
      </SoonaButton>
    </template>
  </SoonaNoResults>
  <InvoiceItem
    v-for="invoice in invoices"
    :key="invoice.id"
    :invoice="invoice"
  />
  <PaginatorFull
    v-model:page="currentPage"
    v-model:records-per-page="itemsPerPage"
    :total-pages="invoicesPagination.totalPages"
    :total-count="invoicesPagination.totalCount"
    :page-count="invoices.length"
  />
</template>

<style lang="scss" scoped>
.subscription-invoices-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.subscription-tier-skeleton {
  height: 6rem;
}
</style>

<script setup>
import HeaderWidget from '@/components/promotion/HeaderWidget.vue';
import { useSetAppHeaderHeights } from '@/composables/useSetAppHeaderHeights';
import { usePromotionWidgets } from '../usePromotionWidgets';
import { computed, ref, watchEffect } from 'vue';
import NavLogo from './NavLogo.vue';
import BagIndicator from '@/components/user/anytime/BagIndicator.vue';
import { useBagCount } from '@/queries/bag/useBag';
import { useStore } from 'vuex';
import NavLink from './NavLink.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import NotificationModal from '@/components/NotificationModal.vue';
import { useSiteNavEvents } from '../useSiteNavEvents';
import { useRoute } from 'vue-router';
import { useMe } from '@/composables/user/useMe';
import UserMenu from './user-menu/UserMenu.vue';

const navRef = ref(null);
const { update } = useSetAppHeaderHeights(navRef);

const { currentAccountId, isAuthenticated } = useMe();
const store = useStore();
const { baseLinkClicked } = useSiteNavEvents();
const route = useRoute();

const { promoWidgetsEnabled, promotionWidgets } = usePromotionWidgets(update);

const unpaidAdditionalCharges = computed(
  () => store.getters['currentUser/unpaidAdditionalCharges']
);
const notificationDialogOpen = ref(false);

const { data: bagCount } = useBagCount(currentAccountId, {
  enabled: computed(() => !!currentAccountId.value),
});
const itemsInBag = computed(() => bagCount.value ?? null);
const bagIsCurrent = computed(() => {
  return route.path.startsWith('/bag');
});

function loadUnpaidAdditionalCharges() {
  return store.dispatch(
    'currentUser/loadUnpaidAdditionalCharges',
    currentAccountId.value
  );
}

watchEffect(() => {
  if (currentAccountId.value) {
    loadUnpaidAdditionalCharges();
  }
});

const handleBagClick = () => {
  window.scrollTo(0, 0);
  baseLinkClicked({
    context: 'site header navigation',
    subContext: 'bag indicator',
    linkLabel: 'check out',
    linkHref: '/bag',
  });
};
</script>

<template>
  <HeaderWidget
    v-if="promoWidgetsEnabled"
    :widgets="promotionWidgets"
    class="app-header-promos"
  />
  <nav ref="navRef" class="platform-top-nav" aria-label="main">
    <div class="platform-top-nav__inner">
      <div class="platform-top-nav__left">
        <slot v-if="isAuthenticated" name="menu-toggle" />
        <NavLogo />
      </div>
      <div class="platform-top-nav__right">
        <template v-if="isAuthenticated">
          <NavLink
            v-if="unpaidAdditionalCharges && unpaidAdditionalCharges.length > 0"
            element="button"
            data-cypress="header-notification-count"
            @click="notificationDialogOpen = true"
          >
            <SoonaIcon name="bell" />
            <template #badge>
              {{ unpaidAdditionalCharges.length }}
              <span class="u-visually-hidden">notifications</span>
            </template>
            <template #tooltip-content>notifications</template>
          </NavLink>
          <NotificationModal
            v-if="notificationDialogOpen"
            :additional-charge-unpaid="unpaidAdditionalCharges"
            @close="notificationDialogOpen = false"
          />
          <NavLink
            href="/user/anytime#/bag/"
            :aria-current="bagIsCurrent ? 'page' : undefined"
            data-cypress="link-nav-bag"
            @click="handleBagClick"
          >
            <SoonaIcon name="soona-bag" />
            <template v-if="itemsInBag && itemsInBag > 0" #badge>
              {{ itemsInBag }}
              <span class="u-visually-hidden">&nbsp;items in bag</span>
            </template>
            <template #tooltip-content>bag</template>
          </NavLink>
          <UserMenu />
        </template>
      </div>
    </div>
    <BagIndicator :items-in-bag="itemsInBag" />
  </nav>
</template>

<style lang="scss" scoped>
@use '@/variables';

.platform-top-nav {
  position: static;
  background: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(0.5rem);

  &__logo {
    border-radius: 0.3125rem;

    &:focus-visible {
      outline: 0.125rem solid variables.$periwink-blue-60;
    }
    img {
      display: block;
      height: 1rem;
      width: auto;
    }
  }

  &__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.75rem;
  }

  &__left,
  &__right {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    align-items: center;
  }

  &__left {
    column-gap: 1rem;
  }
}

@media screen and (min-width: 64rem) {
  .platform-top-nav {
    position: sticky;
    top: 0;
    z-index: 6;
  }
}

@media print {
  .platform-top-nav,
  .app-header-promos {
    display: none;
  }
}
</style>

export const timeZones = [
  {
    offset: 'UTC-10:00',
    offsetDST: 'UTC-10:00',
    name: 'Pacific/Honolulu',
  },
  {
    offset: 'UTC-11:00',
    offsetDST: 'UTC-10:00',
    name: 'Pacific/Midway',
  },
  {
    offset: 'UTC-10:00',
    offsetDST: 'UTC-09:00',
    name: 'America/Adak',
  },
  {
    offset: 'UTC-09:00',
    offsetDST: 'UTC-08:00',
    name: 'America/Anchorage',
  },
  {
    offset: 'UTC-09:00',
    offsetDST: 'UTC-08:00',
    name: 'Pacific/Gambier',
  },
  {
    offset: 'UTC-08:00',
    offsetDST: 'UTC-07:00',
    name: 'America/Dawson_Creek',
  },
  {
    offset: 'UTC-08:00',
    offsetDST: 'UTC-07:00',
    name: 'America/Ensenada',
  },
  {
    offset: 'UTC-08:00',
    offsetDST: 'UTC-07:00',
    name: 'America/Los_Angeles',
  },
  {
    offset: 'UTC-07:00',
    offsetDST: 'UTC-06:00',
    name: 'America/Chihuahua',
  },
  {
    offset: 'UTC-07:00',
    offsetDST: 'UTC-06:00',
    name: 'America/Denver',
  },
  {
    offset: 'UTC-06:00',
    offsetDST: 'UTC-05:00',
    name: 'America/Belize',
  },
  {
    offset: 'UTC-06:00',
    offsetDST: 'UTC-05:00',
    name: 'America/Cancun',
  },
  {
    offset: 'UTC-06:00',
    offsetDST: 'UTC-05:00',
    name: 'America/Chicago',
  },
  {
    offset: 'UTC-06:00',
    offsetDST: 'UTC-05:00',
    name: 'Chile/EasterIsland',
  },
  {
    offset: 'UTC-05:00',
    offsetDST: 'UTC-05:00',
    name: 'America/Bogota',
  },
  {
    offset: 'UTC-05:00',
    offsetDST: 'UTC-04:00',
    name: 'America/Havana',
  },
  {
    offset: 'UTC-05:00',
    offsetDST: 'UTC-04:00',
    name: 'America/New_York',
  },
  {
    offset: 'UTC-04:30',
    offsetDST: 'UTC-04:30',
    name: 'America/Caracas',
  },
  {
    offset: 'UTC-04:00',
    offsetDST: 'UTC-03:00',
    name: 'America/Campo_Grande',
  },
  {
    offset: 'UTC-04:00',
    offsetDST: 'UTC-03:00',
    name: 'America/Santiago',
  },
  {
    offset: 'UTC-04:00',
    offsetDST: 'UTC-04:00',
    name: 'America/La_Paz',
  },
  {
    offset: 'UTC-03:00',
    offsetDST: 'UTC-03:00',
    name: 'America/Argentina/Buenos_Aires',
  },
  {
    offset: 'UTC-03:00',
    offsetDST: 'UTC-02:00',
    name: 'America/Montevideo',
  },
  {
    offset: 'UTC-03:00',
    offsetDST: 'UTC-02:00',
    name: 'America/Araguaina',
  },
  {
    offset: 'UTC-03:00',
    offsetDST: 'UTC-02:00',
    name: 'America/Godthab',
  },
  {
    offset: 'UTC-03:00',
    offsetDST: 'UTC-02:00',
    name: 'America/Miquelon',
  },
  {
    offset: 'UTC-03:00',
    offsetDST: 'UTC-02:00',
    name: 'America/Sao_Paulo',
  },
  {
    offset: 'UTC-03:30',
    offsetDST: 'UTC-02:30',
    name: 'America/St_Johns',
  },
  {
    offset: 'UTC-02:00',
    offsetDST: 'UTC-01:00',
    name: 'America/Noronha',
  },
  {
    offset: 'UTC-01:00',
    offsetDST: 'UTC-01:00',
    name: 'Atlantic/Cape_Verde',
  },
  {
    offset: 'UTC',
    offsetDST: 'UTC',
    name: 'Europe/Belfast',
  },
  {
    offset: 'UTC',
    offsetDST: 'UTC',
    name: 'Africa/Abidjan',
  },
  {
    offset: 'UTC',
    offsetDST: 'UTC',
    name: 'Europe/Dublin',
  },
  {
    offset: 'UTC',
    offsetDST: 'UTC',
    name: 'Europe/Lisbon',
  },
  {
    offset: 'UTC+01:00',
    offsetDST: 'UTC+01:00',
    name: 'Africa/Algiers',
  },
  {
    offset: 'UTC+01:00',
    offsetDST: 'UTC+02:00',
    name: 'Africa/Windhoek',
  },
  {
    offset: 'UTC+01:00',
    offsetDST: 'UTC+02:00',
    name: 'Atlantic/Azores',
  },
  {
    offset: 'UTC+01:00',
    offsetDST: 'UTC+02:00',
    name: 'Atlantic/Stanley',
  },
  {
    offset: 'UTC+01:00',
    offsetDST: 'UTC+02:00',
    name: 'Europe/Amsterdam',
  },
  {
    offset: 'UTC+01:00',
    offsetDST: 'UTC+02:00',
    name: 'Europe/Belgrade',
  },
  {
    offset: 'UTC+01:00',
    offsetDST: 'UTC+02:00',
    name: 'Europe/Brussels',
  },
  {
    offset: 'UTC+02:00',
    offsetDST: 'UTC+03:00',
    name: 'Africa/Cairo',
  },
  {
    offset: 'UTC+02:00',
    offsetDST: 'UTC+03:00',
    name: 'Africa/Blantyre',
  },
  {
    offset: 'UTC+02:00',
    offsetDST: 'UTC+03:00',
    name: 'Asia/Beirut',
  },
  {
    offset: 'UTC+02:00',
    offsetDST: 'UTC+03:00',
    name: 'Asia/Damascus',
  },
  {
    offset: 'UTC+02:00',
    offsetDST: 'UTC+03:00',
    name: 'Asia/Gaza',
  },
  {
    offset: 'UTC+02:00',
    offsetDST: 'UTC+03:00',
    name: 'Asia/Jerusalem',
  },
  {
    offset: 'UTC+03:00',
    offsetDST: 'UTC+04:00',
    name: 'Africa/Addis_Ababa',
  },
  {
    offset: 'UTC+03:00',
    offsetDST: 'UTC+03:00',
    name: 'Asia/Riyadh',
  },
  {
    offset: 'UTC+03:00',
    offsetDST: 'UTC+04:00',
    name: 'Europe/Minsk',
  },
  {
    offset: 'UTC+03:30',
    offsetDST: 'UTC+04:30',
    name: 'Asia/Tehran',
  },
  {
    offset: 'UTC+04:00',
    offsetDST: 'UTC+05:00',
    name: 'Asia/Dubai',
  },
  {
    offset: 'UTC+04:00',
    offsetDST: 'UTC+05:00',
    name: 'Asia/Yerevan',
  },
  {
    offset: 'UTC+04:00',
    offsetDST: 'UTC+05:00',
    name: 'Europe/Moscow',
  },
  {
    offset: 'UTC+04:30',
    offsetDST: 'UTC+04:30',
    name: 'Asia/Kabul',
  },
  {
    offset: 'UTC+05:00',
    offsetDST: 'UTC+05:00',
    name: 'Asia/Tashkent',
  },
  {
    offset: 'UTC+05:30',
    offsetDST: 'UTC+05:30',
    name: 'Asia/Kolkata',
  },
  {
    offset: 'UTC+05:45',
    offsetDST: 'UTC+06:45',
    name: 'Asia/Katmandu',
  },
  {
    offset: 'UTC+06:00',
    offsetDST: 'UTC+06:00',
    name: 'Asia/Dhaka',
  },
  {
    offset: 'UTC+06:00',
    offsetDST: 'UTC+07:00',
    name: 'Asia/Yekaterinburg',
  },
  {
    offset: 'UTC+06:30',
    offsetDST: 'UTC+07:30',
    name: 'Asia/Rangoon',
  },
  {
    offset: 'UTC+07:00',
    offsetDST: 'UTC+08:00',
    name: 'Asia/Bangkok',
  },
  {
    offset: 'UTC+07:00',
    offsetDST: 'UTC+08:00',
    name: 'Asia/Novosibirsk',
  },
  {
    offset: 'UTC+08:00',
    offsetDST: 'UTC+08:00',
    name: 'Asia/Hong_Kong',
  },
  {
    offset: 'UTC+08:00',
    offsetDST: 'UTC+09:00',
    name: 'Asia/Krasnoyarsk',
  },
  {
    offset: 'UTC+08:00',
    offsetDST: 'UTC+08:00',
    name: 'Australia/Perth',
  },
  {
    offset: 'UTC+08:45',
    offsetDST: 'UTC+09:45',
    name: 'Australia/Eucla',
  },
  {
    offset: 'UTC+09:00',
    offsetDST: 'UTC+10:00',
    name: 'Asia/Irkutsk',
  },
  {
    offset: 'UTC+09:00',
    offsetDST: 'UTC+09:00',
    name: 'Asia/Seoul',
  },
  {
    offset: 'UTC+09:00',
    offsetDST: 'UTC+09:00',
    name: 'Asia/Tokyo',
  },
  {
    offset: 'UTC+09:30',
    offsetDST: 'UTC+10:00',
    name: 'Australia/Adelaide',
  },
  {
    offset: 'UTC+09:30',
    offsetDST: 'UTC+09:30',
    name: 'Australia/Darwin',
  },
  {
    offset: 'UTC+09:30',
    offsetDST: 'UTC+10:30',
    name: 'Pacific/Marquesas',
  },
  {
    offset: 'UTC+10:00',
    offsetDST: 'UTC+10:00',
    name: 'Australia/Brisbane',
  },
  {
    offset: 'UTC+10:00',
    offsetDST: 'UTC+11:00',
    name: 'Australia/Hobart',
  },
  {
    offset: 'UTC+10:00',
    offsetDST: 'UTC+11:00',
    name: 'Asia/Yakutsk',
  },
  {
    offset: 'UTC+10:30',
    offsetDST: 'UTC+11:30',
    name: 'Australia/Lord_Howe',
  },
  {
    offset: 'UTC+11:00',
    offsetDST: 'UTC+12:00',
    name: 'Asia/Vladivostok',
  },
  {
    offset: 'UTC+11:30',
    offsetDST: 'UTC+12:30',
    name: 'Pacific/Norfolk',
  },
  {
    offset: 'UTC+12:00',
    offsetDST: 'UTC+13:00',
    name: 'Etc/UTC+12',
  },
  {
    offset: 'UTC+12:00',
    offsetDST: 'UTC+01:00',
    name: 'Asia/Anadyr',
  },
  {
    offset: 'UTC+12:00',
    offsetDST: 'UTC+12:00',
    name: 'Asia/Magadan',
  },
  {
    offset: 'UTC+12:00',
    offsetDST: 'UTC+13:00',
    name: 'Pacific/Auckland',
  },
  {
    offset: 'UTC+12:45',
    offsetDST: 'UTC+13:45',
    name: 'Pacific/Chatham',
  },
  {
    offset: 'UTC+13:00',
    offsetDST: 'UTC+14:00',
    name: 'Pacific/Tongatapu',
  },
  {
    offset: 'UTC+14:00',
    offsetDST: 'UTC+15:00',
    name: 'Pacific/Kiritimati',
  },
];

<template>
  <div class="soona-textfield" :class="$attrs.class">
    <div
      v-if="label"
      class="soona-textfield__label-wrapper"
      :class="{ 'u-visually-hidden': hideLabel }"
    >
      <label
        class="soona-textfield__label"
        :class="{ 'soona-textfield__label--chonky': isChonky }"
        :for="id"
      >
        <span
          v-if="$slots['icon-label']"
          class="soona-textfield__label-icon"
          aria-hidden="true"
        >
          <slot name="icon-label"></slot>
        </span>
        {{ label }}
        <span v-if="$slots['tooltip']" class="soona-textfield__tooltip">
          <slot name="tooltip"></slot>
        </span>
      </label>
      <span v-if="$slots['helper']" class="soona-textfield__helper">
        <slot name="helper"></slot>
      </span>
    </div>
    <p
      v-if="$slots['subtext']"
      :id="`${id}-subtext`"
      class="soona-textfield__label-subtext"
    >
      <slot name="subtext"></slot>
    </p>
    <div class="soona-textfield__input-outer">
      <span
        v-if="$slots['icon-left']"
        class="soona-textfield__icon soona-textfield__icon--left"
        ><slot name="icon-left"
      /></span>
      <input
        v-if="element === 'input'"
        v-bind="attrsForInput"
        :id="id"
        ref="input"
        :value="modelValue"
        :type="inputType"
        :class="[
          inputClass,
          {
            'soona-textfield__input--chonky': isChonky,
            'soona-textfield__input--has-icon-left': $slots['icon-left'],
            'soona-textfield__input--has-icon-right':
              $slots['icon-left'] || showPasswordIcon || showSearchClearButton,
            error: hasError,
          },
        ]"
        :disabled="disabled"
        :min="min"
        :minlength="minlength"
        :max="max"
        :maxlength="maxlength"
        :required="required"
        :step="step"
        :aria-describedby="ariaDescribedby"
        :data-cypress="cypressName ?? $attrs['data-cypress']"
        @blur="onBlur"
        @change="$emit('update:modelValue', $event.target.value)"
        @input="$emit('update:modelValue', $event.target.value)"
        @keydown="handleInputKeydown"
      />
      <textarea
        v-if="element === 'textarea'"
        v-bind="attrsForInput"
        :id="id"
        ref="input"
        :value="modelValue"
        class="soona-textfield__input"
        :class="{
          'soona-textfield__input--disabled': disabled,
          'soona-textfield__input--chonky': isChonky,
          error: hasError,
        }"
        :disabled="disabled"
        :minlength="minlength"
        :maxlength="maxlength"
        :required="required"
        :aria-describedby="ariaDescribedby"
        :data-cypress="cypressName ?? $attrs['data-cypress']"
        @blur="onBlur"
        @change="$emit('update:modelValue', $event.target.value)"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <span
        v-if="
          $slots['icon-right'] && !showPasswordIcon && !showSearchClearButton
        "
        class="soona-textfield__icon soona-textfield__icon--right"
      >
        <slot name="icon-right" />
      </span>
      <button
        v-if="showPasswordIcon"
        class="soona-textfield__visibility-btn soona-textfield__icon soona-textfield__icon--right"
        type="button"
        :title="`${inputType === 'password' ? 'show' : 'hide'} password`"
        @click="toggleVisibility"
      >
        <SoonaIcon
          :name="inputType === 'password' ? 'eye-slash' : 'eye'"
          size="medium"
        />
      </button>
      <button
        v-if="showSearchClearButton"
        class="soona-textfield__visibility-btn soona-textfield__icon soona-textfield__icon--right"
        type="button"
        title="clear search text"
        @click="$emit('update:modelValue', '')"
      >
        <SoonaIcon name="xmark" size="medium" />
      </button>
    </div>
    <span v-if="$slots['helper-bottom']">
      <slot name="helper-bottom"></slot>
    </span>
    <div
      v-if="displayErrors && (validationErrors.length > 0 || maxlength)"
      class="lower-msg-wrapper"
    >
      <ul
        v-if="validationErrors.length > 0"
        :id="`${id}-validation-errors`"
        class="validation-errors"
        data-cypress="errors"
      >
        <li v-for="error in validationErrors" :key="error.errorType">
          <SoonaError>{{ error.message }}</SoonaError>
        </li>
      </ul>
      <p
        v-if="maxlength && showCounter"
        :id="`${id}-character-count`"
        class="soona-textfield__character-count"
        aria-live="polite"
      >
        {{ localizedModelValue }}
        <span class="u-visually-hidden">of</span
        ><span aria-hidden="true">/</span> {{ maxlength.toLocaleString()
        }}<span class="u-visually-hidden"> characters</span>
      </p>
    </div>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';
import { useRegexHelper } from 'src/composables/useRegexHelper';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const dateValidationFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
});

export default {
  components: { SoonaError, SoonaIcon },
  inheritAttrs: false,
  expose: ['focus'],
  props: {
    cypressName: {
      default: undefined,
      required: false,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    element: {
      default: 'input',
      type: String,
      validator: function (value) {
        return ['input', 'textarea'].includes(value);
      },
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      default: false,
      type: Boolean,
    },
    isChonky: {
      default: false,
      required: false,
      type: Boolean,
    },
    label: {
      default: undefined,
      required: false,
      type: String,
    },
    max: {
      default: undefined,
      required: false,
      type: [Number, String],
    },
    maxlength: {
      default: undefined,
      required: false,
      type: Number,
    },
    showCounter: {
      default: true,
      required: false,
      type: Boolean,
    },
    min: {
      default: undefined,
      required: false,
      type: [Number, String],
    },
    minlength: {
      default: undefined,
      required: false,
      type: Number,
    },
    password: {
      default: undefined,
      required: false,
      type: String,
    },
    required: {
      default: undefined,
      required: false,
      type: Boolean,
    },
    rules: {
      default: function () {
        return [];
      },
      required: false,
      type: Array,
      validator: function (rules) {
        const validRules = [
          'email',
          'integer',
          'filename',
          'match',
          'max',
          'min',
          'minlength',
          'required',
          'step',
          'url',
        ];
        return rules.every(rule => validRules.includes(rule));
      },
    },
    showPasswordIcon: {
      default: false,
      required: false,
      type: Boolean,
    },
    step: {
      default: undefined,
      required: false,
      type: Number,
    },
    type: {
      default: undefined,
      required: false,
      type: String,
      validator: function (value) {
        return [
          'date',
          'email',
          'number',
          'password',
          'search',
          'text',
          'time',
          'url',
        ].includes(value);
      },
    },
    modelValue: {
      default: '',
      required: false,
      type: [String, Number],
    },
  },
  emits: ['update:modelValue', 'onBlur'],
  setup() {
    const { emailRegex, fileNameRegex, urlRegex } = useRegexHelper();

    return {
      emailRegex,
      fileNameRegex,
      urlRegex,
    };
  },
  data() {
    const errorTypes = {
      required: () => 'this field is required',
      emailFormatting: () => 'enter a valid email address',
      filename: () => 'enter a valid filename',
      minlength: length => `this field must be at least ${length} characters`,
      match: () => 'your passwords do not match',
      min: min => `enter a value greater than or equal to ${min}`,
      max: max => `enter a value less than or equal to ${max}`,
      integer: () => 'only whole numbers are allowed',
      minDate: formattedMin => `the earliest allowed date is ${formattedMin}`,
      maxDate: formattedMax => `the latest allowed date is ${formattedMax}`,
      urlFormatting: () => 'enter a valid URL',
    };
    return {
      id: uniqueId('textfield-'),
      validationErrors: [],
      inputType: undefined,
      isFormDirty: false,
      uniqueComponentId: null,
      errorTypes,
      debouncedCallValidate: () => {},
      displayErrors: false,
    };
  },
  computed: {
    attrsForInput() {
      const attrs = { ...this.$attrs };
      /*
       * to preserve existing behavior, do not forward the class attribute to
       * the inner input/textarea element
       */
      delete attrs.class;
      return attrs;
    },
    inputClass() {
      if (this.disabled) {
        return 'soona-textfield__input disabled';
      } else if (this.validationErrors.length > 0 && this.displayErrors) {
        return 'soona-textfield__input error';
      } else {
        return 'soona-textfield__input';
      }
    },
    ariaDescribedby() {
      const ids = [];

      if (this.validationErrors.length > 0) {
        ids.push(`${this.id}-validation-errors`);
      }

      if (this.maxlength) {
        ids.push(`${this.id}-character-count`);
      }

      if (this.$slots.subtext) {
        ids.push(`${this.id}-subtext`);
      }

      return ids.join(' ');
    },
    localizedModelValue() {
      return this.modelValue?.length?.toLocaleString() || '0';
    },
    showSearchClearButton() {
      return this.inputType === 'search' && this.modelValue !== '';
    },
  },
  watch: {
    modelValue(val) {
      if (val !== '') {
        this.isFormDirty = true;
      }

      // "reward early, punish late"
      // https://www.smashingmagazine.com/2022/09/inline-validation-web-forms-ux/#4-reward-early-punish-late
      if (this.debouncedCallValidate()) this.displayErrors = false;
    },
  },
  created() {
    /*
     * we cannot use the function returned from debounce as a method, multiple
     * components will share the same instance and chaos will ensue
     * https://stackoverflow.com/a/49780382/3928053
     */
    this.debouncedCallValidate = debounce(() => this.validate(), 200);
  },
  mounted() {
    this.uniqueComponentId = Math.random().toString(36).substring(2, 18);
    this.inputType = this.type;
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    handleInputKeydown(event) {
      if (this.type === 'number' && event.key === 'e') {
        event.preventDefault();
      }

      if (event.code === 'Enter' && this.element === 'input') {
        this.displayErrors = true;
        this.validate();
      }
    },
    onBlur(e) {
      this.$emit('onBlur', e);
      this.displayErrors = true;
      this.validate();
    },
    validate() {
      if (this.rules.length < 1) return true;

      if (this.rules.includes('required')) {
        this.testRequired();
      }

      if (this.isFormDirty) {
        if (this.rules.includes('email')) {
          this.testEmail();
        }
        if (this.rules.includes('filename')) {
          this.testFilename();
        }
        if (this.rules.includes('minlength')) {
          this.testMinlength();
        }
        if (this.rules.includes('match')) {
          this.testMatch();
        }
        if (this.rules.includes('min')) {
          this.testMin();
        }
        if (this.rules.includes('max')) {
          this.testMax();
        }
        if (this.rules.includes('integer')) {
          this.testInteger();
        }
        if (this.rules.includes('step')) {
          this.testStep();
        }
        if (this.rules.includes('url')) {
          this.testUrl();
        }
      }

      return this.validationErrors.length === 0;
    },
    testRequired() {
      if (this.required && !this.modelValue) {
        this.addError('required', this.errorTypes.required());
      } else {
        this.removeError('required');
      }
    },
    testEmail() {
      // explanation for this regex: https://stackoverflow.com/a/719543
      const regex = this.emailRegex;
      if (!regex.test(this.modelValue)) {
        this.addError('emailFormatting', this.errorTypes.emailFormatting());
      }
      if (regex.test(this.modelValue) || this.modelValue === '') {
        this.removeError('emailFormatting');
      }
    },
    testFilename() {
      const regex = this.fileNameRegex;
      if (!regex.test(this.modelValue)) {
        this.addError('filename', this.errorTypes.filename());
      }
      if (regex.test(this.modelValue) || this.modelValue === '') {
        this.removeError('filename');
      }
    },
    testInteger() {
      const num = Number(this.modelValue);
      if (!Number.isInteger(num)) {
        this.addError('integer', this.errorTypes.integer());
      } else {
        this.removeError('integer');
      }
    },
    testMin() {
      if (this.type === 'number') {
        if (this.modelValue < this.min) {
          this.addError('min', this.errorTypes.min(this.min));
        } else {
          this.removeError('min');
        }
      }

      if (this.type === 'date') {
        if (this.modelValue && this.min) {
          /*
           * make sure the dates are local so that validation
           * formatting displays correctly at local time
           */
          const value = new Date(this.modelValue + 'T00:00:00');
          const min = new Date(this.min + 'T00:00:00');
          if (value.getTime() < min.getTime()) {
            this.addError(
              'minDate',
              this.errorTypes.minDate(dateValidationFormatter.format(min))
            );
          } else {
            this.removeError('minDate');
          }
        }
      }
    },
    testMax() {
      if (this.type === 'number') {
        if (this.modelValue > this.max) {
          this.addError('max', this.errorTypes.max(this.max));
        } else {
          this.removeError('max');
        }
      }

      if (this.type === 'date') {
        if (this.modelValue && this.max) {
          // make sure the dates are local so that validation formatting displays correctly at local time
          const value = new Date(this.modelValue + 'T00:00:00');
          const max = new Date(this.max + 'T00:00:00');
          if (value.getTime() > max.getTime()) {
            this.addError(
              'maxDate',
              this.errorTypes.maxDate(dateValidationFormatter.format(max))
            );
          } else {
            this.removeError('maxDate');
          }
        }
      }
    },
    testMinlength() {
      if (this.modelValue && this.modelValue.length < this.minlength) {
        this.addError('minlength', this.errorTypes.minlength(this.minlength));
      } else {
        this.removeError('minlength');
      }
    },
    testMatch() {
      let match = this.modelValue === this.password;
      if (!match) {
        this.addError('match', this.errorTypes.match());
      } else {
        this.removeError('match');
      }
    },
    testStep() {
      // the browser has a much better algorithm for validating steps
      if (this.$refs.input.validity.stepMismatch) {
        let stepDisplay;
        switch (this.type) {
          case 'time': {
            stepDisplay = `${this.step / 60} minutes`;
            break;
          }
          case 'date': {
            stepDisplay = `${this.step} days`;
            break;
          }
          default: {
            stepDisplay = `${this.step}`;
          }
        }
        this.addError(
          'step',
          `the ${this.type} must be in multiples of ${stepDisplay}`
        );
      } else {
        this.removeError('step');
      }
    },
    testUrl() {
      const regex = this.urlRegex;
      if (!regex.test(this.modelValue)) {
        this.addError('urlFormatting', this.errorTypes.urlFormatting());
      }
      if (this.modelValue === '' || regex.test(this.modelValue)) {
        this.removeError('urlFormatting');
      }
    },
    toggleVisibility() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    },
    /**
     * @description add or update error text for a given error type
     * @param {string} errorType - the property name/key on the errorTypes object
     * @param {string} errorMessage - a human readable error message
     */
    addError(errorType, errorMessage) {
      const exists = this.validationErrors.find(
        error => error.type === errorType
      );

      if (exists) {
        // update the error message text if the error type already exists
        this.validationErrors = this.validationErrors.map(error =>
          error.type === errorType ? { ...error, message: errorMessage } : error
        );
      } else {
        // otherwise, immutably add a new error
        this.validationErrors = [
          ...this.validationErrors,
          { type: errorType, message: errorMessage },
        ];
      }

      // this is for the SoonaForm
      this.$emitter.emit('set-validation-errors', {
        childComponentId: this.uniqueComponentId,
        errors: this.validationErrors,
      });
    },
    /**
     * @param {string} errorType - the property name/key on the errorTypes object
     */
    removeError(errorType) {
      this.validationErrors = this.validationErrors.filter(
        error => error.type !== errorType
      );

      this.$emitter.emit('set-validation-errors', {
        childComponentId: this.uniqueComponentId,
        errors: this.validationErrors,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-textfield {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-bottom: 1.9375rem;

  /* disable native search icon on iPad */
  input[type='search']::-webkit-search-decoration {
    display: none;
  }
  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
  }

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.4375rem;
  }

  &__label {
    @include variables_fonts.u-label--heavy;

    align-items: center;
    color: variables.$black-default;
    display: flex;

    @media (min-width: variables.$screen-md-min) {
      &--chonky {
        @include variables_fonts.u-body--heavy;
      }
    }
  }

  &__label-subtext {
    @include variables_fonts.u-label--regular;

    margin-bottom: 0.5rem;
  }

  &__label-icon {
    font-size: 1.25rem;
    padding-right: 0.75rem;

    @media (min-width: variables.$screen-md-min) {
      font-size: 1.5rem;
    }
  }

  &__tooltip {
    padding-left: 0.3125rem;
  }

  &__helper {
    @include variables_fonts.u-label--regular;

    color: variables.$gray-60;

    a {
      color: inherit;
      transition: 0.1s;

      &:hover {
        color: variables.$periwink-blue-70;
      }

      &:active {
        color: variables.$black-default;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;

    &--left {
      color: variables.$gray-60;
      left: 0.625rem;
    }

    &--right {
      right: 0.625rem;
    }
  }

  &__visibility-btn {
    background: none;
    border: none;
    padding: 0;
    line-height: 0;
    color: variables.$gray-60;

    &:hover {
      color: variables.$black-default;
    }
  }

  &__input-outer {
    position: relative;
  }

  &__input {
    @include variables_fonts.u-body--regular;

    background: variables.$white-default;
    border-color: variables.$gray-30;
    border-radius: 0.3125rem;
    border-style: solid;
    border-width: 0.0625rem;
    padding: 0.5rem;
    transition: 0.1s;
    width: 100%;

    &--has-icon-left {
      padding-left: 2rem;
    }
    &--has-icon-right {
      padding-right: 2rem;
    }

    &:hover {
      border-color: variables.$gray-50;
    }

    &:focus {
      border-color: variables.$black-default;
    }

    &:disabled,
    &:read-only,
    &--disabled {
      background-color: variables.$gray-20;
      pointer-events: none;
    }

    &::placeholder {
      @include variables_fonts.u-body--regular;

      color: variables.$gray-60;
    }

    &--chonky {
      @include variables_fonts.u-subheader--regular;

      padding: 0.875rem 1rem;

      &::placeholder {
        @include variables_fonts.u-subheader--regular;
      }

      @media (min-width: variables.$screen-md-min) {
        @include variables_fonts.u-title--regular;

        padding-left: 1.25rem;
        padding-right: 1.25rem;

        &::placeholder {
          @include variables_fonts.u-title--regular;
        }
      }
    }
  }

  .lower-msg-wrapper {
    display: flex;
    font-size: 0.875rem;
    gap: 0.5rem;
    justify-content: space-between;
    padding-top: 0.3125rem;

    .soona-textfield__character-count {
      margin-left: auto;
      white-space: nowrap;
    }
  }
  .validation-errors {
    list-style: none;
    width: 100%;

    li {
      padding: 0;
    }
  }
  .error {
    border-color: variables.$roses-60;
  }
}

textarea.soona-textfield__input {
  resize: vertical;
}
</style>

<script setup>
import { computed } from 'vue';
import ArrowLeft from 'src/components/svgs/ArrowLeft.vue';

// emits, props, and data
const props = defineProps({
  cardData: {
    default: () => {}, // optional, the entire data object so it can be passed to parent on card selection
    required: false,
    type: Object,
  },
  image: String,
  isLink: {
    default: false,
    required: false,
    type: Boolean,
  },
  isDisabled: {
    default: false,
    required: false,
    type: Boolean,
  },
  isSelected: {
    default: false,
    required: false,
    type: Boolean,
  },
  hasArrow: {
    default: false,
    required: false,
    type: Boolean,
  },
  title: {
    default: undefined,
    required: true,
    type: String,
  },
  subtitle: String,
  to: {
    default: undefined,
    required: false,
    type: String,
  },
  href: {
    default: undefined,
    type: String,
    required: false,
  },
});

const emit = defineEmits(['onClick', 'byoCardClicked']);
const subtitle = computed(() => props.subtitle);

// methods
function handleChange(val) {
  emit('byoCardClicked', val);
}

function makeLowercase(val) {
  return val?.toLowerCase().replace(/\s/g, '-');
}
const component = computed(() => {
  if (props.isLink) {
    return props.href ? 'a' : 'router-link';
  }
  return 'label';
});
</script>

<template>
  <input
    :id="`clickable-card-option-${makeLowercase(title)}`"
    name="type"
    type="radio"
    :checked="isSelected"
    class="u-visually-hidden card-hidden-input"
    :data-cypress="`input-${makeLowercase(title)}`"
    :disabled="isDisabled"
    @change="handleChange(cardData)"
  />

  <component
    v-bind="$attrs"
    :is="component"
    class="soona-clickable-card soona-byo-card"
    :class="{
      'soona-byo-card--disabled': isDisabled,
      'soona-byo-card--selected': isSelected,
    }"
    :for="
      component === 'label'
        ? `clickable-card-option-${makeLowercase(title)}`
        : undefined
    "
    :to="to"
    :href="href"
    :data-cypress="`${component}-${makeLowercase(title)}`"
    @click="emit('onClick')"
  >
    <img
      class="soona-byo-card__image"
      :src="image"
      alt="arm holding a camera on a green background"
    />
    <div class="soona-byo-card__content">
      <h2 class="soona-byo-card__title u-subheader--heavy">
        build your own {{ title }} shoot
      </h2>
      <div class="soona-byo-card__subtitle">
        <p class="u-subheader--regular">{{ subtitle }}</p>
        <ArrowLeft
          v-if="hasArrow"
          class="soona-byo-card__arrow"
          aria-hidden="true"
        />
      </div>
    </div>
  </component>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-byo-card {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem;
  color: variables.$black-default;
  background-color: variables.$green-apple-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  transition: box-shadow 0.2s;
  cursor: pointer;

  &__image {
    width: 6.3125rem;
    height: 5.375rem;
    object-fit: cover;
    border-radius: 0.625rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
    width: 100%;
  }

  &__title {
    padding-right: 2.5rem;

    @media (min-width: variables.$screen-sm-min) {
      padding-right: 0;
    }
  }

  &__subtitle {
    align-items: center;
    display: flex;

    @media (min-width: variables.$screen-sm-min) {
      display: block;
    }
  }

  &__arrow {
    display: block;
    margin-left: auto;
    transform: rotate(180deg);
  }

  &--selected {
    border-color: variables.$black-default;
  }
}
</style>

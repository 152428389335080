<template>
  <div class="soona-textarea">
    <textarea
      v-model.trim="inputValue"
      :data-cypress="cypressName"
      class="textarea"
      :rows="rows"
      :placeholder="placeholder"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    modelValue: String,
    rows: {
      type: Number,
      default: 3,
    },
    disabled: Boolean,
    placeholder: String,
    cypressName: {
      type: String,
      default: 'soona-textarea',
    },
    onChange: Function,
  },
  emits: ['update:modelValue'],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        if (this.onChange) {
          this.onChange(val);
        } else {
          this.$emit('update:modelValue', val);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.soona-textarea {
  width: 100%;
  position: relative;

  .textarea {
    border: 0.0625rem solid variables.$gray-30;
    box-shadow: none;
    padding: 0.625rem 0.5rem;
    color: variables.$black-default;
    resize: none;
    margin-bottom: 1rem;
  }

  .textarea:focus {
    border-color: variables.$black-default;
  }
}
</style>

import { http } from 'src/config/vue-axios';

/**
 *
 * @param {string | number} listingId
 * @param {string | number} competitorId
 * @param {boolean} isExampleCompetitor
 * @param {AbortSignal} signal
 * @returns {Promise}
 */
export async function getCompetitorsComparison(
  listingId,
  competitorId,
  isExampleCompetitor = false,
  signal
) {
  const params = {};
  if (isExampleCompetitor) {
    params.example_competitor = true;
  }
  const response = await http.get(
    `/listing/${listingId}/competitor/${competitorId}`,
    { params, signal }
  );

  return response.data;
}

<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useShopMore } from '@/queries/useShopMore';
import CarouselLoading from '@/components/platform-home/CarouselLoading.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaImageCard from '@/components/ui_library/SoonaImageCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const accountId = computed(() => props.accountId);

const { data: shopMoreAssets, isPending } = useShopMore({
  accountId,
  count: 12,
  sortBy: 'date_modified',
  direction: 'desc',
});

const trackClick = (label, href) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'shop more',
    linkLabel: label,
    linkHref: href,
  });
};
</script>

<template>
  <section
    v-if="shopMoreAssets?.length"
    class="shop-more"
    data-cypress="section-shop-more"
  >
    <div class="shop-more__heading">
      <h2
        id="shop-more"
        class="u-title--heavy"
        data-cypress="text-shop-more-heading"
      >
        <slot name="title">shop more</slot>
      </h2>
      <SoonaButton
        element="router-link"
        :to="`/account/${accountId}/gallery/shop-more`"
        variation="tertiary"
        @on-click="trackClick('see all', `/account/${accountId}/shop-more`)"
      >
        see all
        <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </div>
    <CarouselLoading v-if="isPending" />
    <PageContainerOffset v-else>
      <SoonaSlider tag-name="ul" aria-labelledby="shop-more">
        <SoonaImageCard
          v-for="asset in shopMoreAssets"
          :key="asset.id"
          inner-element="router-link"
          :to="{
            name: 'shop-more-assets-media-view',
            params: {
              accountId: accountId,
              digitalAssetId: asset.id,
            },
          }"
          @click="
            trackClick(
              'digital asset thumbnail',
              `/account/${accountId}/asset/${asset.id}?media_type=${asset.media_type}`
            )
          "
        >
          <DigitalAssetImage :src="asset.preview?.url" :alt="asset.title" />
        </SoonaImageCard>
      </SoonaSlider>
    </PageContainerOffset>
  </section>
</template>

<style lang="scss" scoped>
.shop-more {
  &__heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }
}
</style>

import { computed } from 'vue';
import { useCapability } from '@/composables/useCapability';

/*
    MaybeRef<string | Number | null | undefined>} assetAccountId
 */
export const useMediaEditorSubscriptionAccess = assetAccountId => {
  const { hasCapability: canAdjustBackgroundOnOwnAccount } = useCapability(
    {
      capability: 'media_editor_adjust_bg_on_own_account',
      subjectType: 'account',
      subjectId: assetAccountId,
    },
    {
      enabled: computed(() => !!assetAccountId.value),
    }
  );

  const { hasCapability: staffCanAdjustBackgroundOnClientAccount } =
    useCapability({
      capability: 'media_editor_adjust_bg_on_subscription_accounts',
    });

  const hasSubscriptionDownloadAccess = computed(() => {
    return (
      canAdjustBackgroundOnOwnAccount?.value ||
      staffCanAdjustBackgroundOnClientAccount?.value
    );
  });

  return {
    canAdjustBackgroundOnOwnAccount,
    staffCanAdjustBackgroundOnClientAccount,
    hasSubscriptionDownloadAccess,
  };
};

import { ref, computed } from 'vue';
import { useAccount } from '@/queries/account/useAccount';
import { useProductsSearch } from 'src/queries/useProductsSearch';
import { useLocations } from 'src/queries/useLocation';

export function usePreferredCreditProducts(accountId) {
  const creditCount = ref(200);
  const creditStep = ref(50);

  const { data: account } = useAccount(accountId);

  const { data: locationsData } = useLocations();

  const unassignedLocation = computed(() => {
    return locationsData.value?.find(
      location => location.name.toLowerCase() === 'unassigned'
    );
  });

  const creditLocationId = computed(() => {
    return account.value
      ? account.value.credit_location_id
      : unassignedLocation.value?.id;
  });

  const { data: preferredCreditProducts } = useProductsSearch({
    locationId: computed(() => creditLocationId.value),
    productType: computed(() => 'credit'),
    selfServable: computed(() => true),
    includesMediaAddOns: computed(() => true),
  });

  const currentCreditProduct = computed(() => {
    return preferredCreditProducts.value?.find(
      product =>
        product.min <= creditCount.value && product.max >= creditCount.value
    );
  });

  const currentCreditProductShortName = computed(() => {
    let name = '';
    if (currentCreditProduct.value) {
      const split_name = currentCreditProduct.value.name.split(' ');
      name = split_name[0] + ' ' + split_name[1];
    }
    return name;
  });

  const creditProductsMin = computed(() => {
    return (
      Math.trunc(
        (preferredCreditProducts.value?.reduce((min, product) => {
          return product.min < min ? product.min : min;
        }, 1000) ?? 50) / creditStep.value
      ) * creditStep.value
    );
  });

  const creditProductsMax = computed(() => {
    return (
      Math.trunc(
        (preferredCreditProducts.value?.reduce((max, product) => {
          return product.max > max ? product.max : max;
        }, 50) ?? 950) / creditStep.value
      ) * creditStep.value
    );
  });

  const pricePerCredit = computed(() => {
    return currentCreditProduct.value
      ? Number(currentCreditProduct.value.rate)
      : 39;
  });

  const avgSavings = computed(() => {
    return (
      creditCount.value * 9 + (39 - pricePerCredit.value) * creditCount.value
    );
  });

  const totalCost = computed(() => {
    return creditCount.value * pricePerCredit.value;
  });

  return {
    creditCount,
    creditStep,
    pricePerCredit,
    avgSavings,
    totalCost,
    creditProductsMin,
    creditProductsMax,
    currentCreditProduct,
    currentCreditProductShortName,
  };
}

<script setup></script>

<template>
  <div>
    <img
      v-viewer="{
        navbar: false,
        toolbar: false,
        title: false,
        backdrop: true,
        minZoomRatio: 0.01,
        maxZoomRatio: 2,
      }"
      className="next-steps-image"
      src="@images/chat/how_to_checkout.png"
      alt="image attachment"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
.next-steps-image {
  border: 1px solid variables.$friendly-red-50;
  cursor: pointer;
}
</style>

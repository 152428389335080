<script setup>
import { computed, ref, toRef, watch } from 'vue';
import AlertIcon from 'src/components/svgs/AlertIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import { useReservationLocations } from 'src/queries/useReservationLocations';
import { useUpdateReservationLocation } from 'src/queries/useUpdateReservation';
import { useReservation } from 'src/composables/useReservation';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaError from 'src/components/ui_library/SoonaError.vue';

const props = defineProps({
  reservationId: {
    type: String,
    required: true,
  },
  showModal: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const emit = defineEmits(['cancel', 'confirm']);

const { inputChanged, buttonClicked } = useBaseEvents();

const { isAnytime } = useReservation(toRef(props, 'reservationId'));
const { data: locations, error: locationsError } = useReservationLocations(
  toRef(props, 'reservationId'),
  ['studio', 'disabled']
);
const userSelectedLocationId = ref(undefined);
const inStudioOptions = computed(() => {
  return (
    locations.value?.map(l => ({
      label: l.location.display_name,
      value: l.location.id,
      incompatible: l.incompatible,
      disabled: l.disabled,
    })) || []
  );
});
watch(locations, () => {
  if (userSelectedLocationId.value || isAnytime.value) return;
  const selectedLocation = locations.value?.find(l => l.selected);
  if (selectedLocation?.location?.id) {
    userSelectedLocationId.value = selectedLocation.location.id;
  }
});
const incompatibleOptionsExist = computed(() => {
  return locations.value?.some(l => l.incompatible);
});

const {
  mutate,
  isPending: isMutating,
  error: updateError,
} = useUpdateReservationLocation(toRef(props, 'reservationId'));

const handleInputChanged = () => {
  // the select's change event sends back the id of the selected location; this find the human-readable label to send back to Segment
  const selectedLocationLabel = inStudioOptions.value.find(
    option => option.value === userSelectedLocationId.value
  ).label;

  inputChanged({
    context: 'booking',
    subContext: 'location selection modal',
    inputLabel: 'select an in-studio location',
    inputType: 'select',
    inputValue: selectedLocationLabel,
  });
};

const handleStayWithVirtualShoot = () => {
  userSelectedLocationId.value = undefined;
  inputChanged({
    context: 'booking',
    subContext: 'location selection modal',
    inputLabel:
      'stay with a virtual shoot! virtual shoots can accomplish any scenes',
    inputType: 'radio',
  });
};

const setUserSelectedLocation = () => {
  if (isAnytime && !userSelectedLocationId.value) {
    emit('confirm');
    return;
  }

  mutate(
    { locationId: userSelectedLocationId.value, reservationType: 'in_studio' },
    {
      onSuccess: () => {
        buttonClicked({
          context: 'booking',
          subContext: 'location',
          buttonLabel: 'confirm',
          buttonAction: 'close location selection modal',
        });
        emit('confirm');
      },
    }
  );
};
</script>
<template>
  <SoonaDialog
    v-if="showModal"
    :disabled="isMutating"
    @close="() => emit('cancel')"
  >
    <template #heading>change to an in-studio shoot</template>
    <h2>select an in-studio location</h2>
    <SoonaError v-if="locationsError && locationsError.message">
      {{ locationsError.message }}
    </SoonaError>
    <SoonaError v-if="updateError && updateError.message">
      {{ updateError.message }}
    </SoonaError>
    <SoonaSelect
      v-model="userSelectedLocationId"
      class="category-dropdown"
      placeholder="select location"
      :options="inStudioOptions"
      :searchable="true"
      :selectable="option => !option.incompatible && !option.disabled"
      @update:model-value="handleInputChanged"
    />
    <div v-if="incompatibleOptionsExist" class="warning-text">
      <AlertIcon aria-title="Warning" />
      <p>
        note: studio locations in gray aren't available because they cannot
        accomplish your scene selections
      </p>
    </div>
    <p><strong>or</strong></p>
    <SoonaToggle
      :model-value="userSelectedLocationId"
      :native-value="undefined"
      type="radio"
      label="stay with a virtual shoot! virtual shoots can accomplish any scenes"
      @update:model-value="handleStayWithVirtualShoot"
    />
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        :disabled="isMutating"
        data-cypress="button-dialog-confirm"
        @on-click="setUserSelectedLocation"
      >
        confirm
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style scoped lang="scss">
@use '@/variables';

.warning-text {
  display: flex;
  align-items: flex-start;
  margin-top: 0.75rem;
  color: variables.$gray-60;

  svg {
    flex: 0 0 1.25rem;
    margin-right: 0.25rem;
    margin-top: 0.25rem;
  }
}
</style>

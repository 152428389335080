<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaMeter from '@/components/ui_library/SoonaMeter.vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  opportunity: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['selectListingRuleSlug']);

const opportunity = computed(() => props.opportunity);
const route = useRoute();
const catalogItemId = computed(() => route.query.catalog_item);

const scrollToList = () => {
  document.getElementById('missing-content-list')?.scrollIntoView({
    behavior: 'smooth',
  });
};

const goToOpportunity = () => {
  if (opportunity.value.listing_rule_slug === 'lifestyle') {
    return;
  } else {
    emit('selectListingRuleSlug', opportunity.value.listing_rule_slug);
    scrollToList();
  }
};

const label = percent => {
  if (percent < 0.33) {
    return 'poor';
  } else if (percent < 0.66) {
    return 'okay';
  } else {
    return 'great';
  }
};

const formattedMeterData = computed(() => {
  return {
    value: opportunity.value.percent_passing ?? 0,
    label: label(opportunity.value.percent_passing),
    minValue: 0,
    maxValue: 1,
    low: 0.33,
    high: 0.66,
  };
});

const component = computed(() => {
  return opportunity.value.listing_rule_slug === 'lifestyle'
    ? 'router-link'
    : 'button';
});

const to = computed(() => {
  return opportunity.value.listing_rule_slug === 'lifestyle'
    ? {
        name: 'listing-insights-actions',
        params: { actionSlug: 'lifestyle' },
        query: { catalog_item: catalogItemId.value },
      }
    : null;
});
</script>

<template>
  <component
    :is="component"
    class="creative-opportunity-card u-button-reset"
    :to="to"
    @click="goToOpportunity"
  >
    <h3 class="creative-opportunity-card__title u-body--heavy">
      {{ opportunity.listing_rule_title }}
    </h3>
    <div class="creative-opportunity-card__content">
      <div class="creative-opportunity-card__body u-body--regular">
        <div class="creative-opportunity-card__body--text">
          <div class="u-title--heavy">
            {{ (opportunity.percent_passing * 100).toFixed() }}%
          </div>
          <span>of listings</span>
        </div>
        <div class="creative-opportunity-card__cta">
          {{ opportunity.number_of_actions.toLocaleString() }} opportunit{{
            opportunity.number_of_actions === 1 ? 'y' : 'ies'
          }}
          <SoonaIcon
            class="creative-opportunity-card__cta--icon"
            name="arrow-right"
            size="medium"
          />
        </div>
      </div>
      <SoonaMeter
        accessible-title="percentage of listings passing"
        :data="formattedMeterData"
        size="small"
      />
    </div>
  </component>
</template>

<style lang="scss" scoped>
@use '@/variables';

.creative-opportunity-card {
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  padding: 1rem;
  position: relative;
  text-align: left;

  &:hover {
    background-color: variables.$gray-10;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__body {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 1rem;

    &--text {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: 0.25rem;
    }
  }

  &__cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    color: variables.$gray-70;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }

    &--icon {
      @media screen and (prefers-reduced-motion: no-preference) {
        transition: transform 0.1s ease-out;
      }
    }

    &:hover,
    &:focus-visible {
      .creative-opportunity-card__cta--icon {
        transform: translateX(0.1875rem);
      }
    }
  }
}
</style>

<template>
  <div
    class="button-soona-selected"
    :class="selectedTagClass"
    :data-cypress="`selected-tag-${convertForClassName(title)}`"
  >
    <span>{{ title }}</span>
    <button
      class="button-soona-selected--delete"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @mousedown="mouseDown"
      @mouseup="mouseUp"
      @click.prevent="onClick(selectedTag)"
    >
      <SoonaIcon
        name="xmark"
        size="medium"
        data-cypress="button-delete-selected-trend-set"
      />
    </button>
  </div>
</template>
<script>
import strMixin from '@/mixins/strMixin';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'SoonaSelectedChip',
  components: {
    SoonaIcon,
  },
  mixins: [strMixin],
  props: {
    title: String,
    selectedTag: Object,
  },
  emits: ['onClick'],
  data() {
    return {
      isButtonHovered: false,
      isButtonFocused: false,
    };
  },
  computed: {
    selectedTagClass() {
      return {
        'button-soona-selected--hovered': this.isButtonHovered,
        'button-soona-selected--focused': this.isButtonFocused,
      };
    },
  },
  methods: {
    onClick(tag) {
      this.$emit('onClick', tag);
    },
    mouseOver() {
      this.isButtonHovered = true;
    },
    mouseLeave() {
      this.isButtonHovered = false;
      this.isButtonFocused = false;
    },
    mouseDown() {
      this.isButtonFocused = true;
    },
    mouseUp() {
      this.isButtonFocused = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';

.button-soona-selected {
  border: 0.0625rem solid variables.$gray-20;
  background-color: variables.$gray-10;
  color: variables.$black-default;
  border-radius: 3.125rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  cursor: default;

  &:focus {
    border-color: variables.$black-default;

    .button-soona-selected--delete {
      color: variables.$black-default;
    }
  }

  &--hovered {
    border-color: variables.$gray-40;
  }

  &--focused {
    border-color: variables.$black-default;
  }

  &--delete {
    align-items: center;
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    padding: 0.375rem 0.75rem;
    cursor: pointer;
    color: variables.$gray-50;

    &:hover {
      color: variables.$black-default;
    }
  }
}
</style>

import { store } from '@/store/store';
import { featureFlagsQueryConfig } from '@/queries/users/feature-flags';

export const signInBeforeEnter = (to, from, next) => {
  const isAuthenticated = store.getters['currentUser/isAuthenticated'];
  if (!isAuthenticated) return next();

  const {
    redirect = '/',
    isExternalAuthPortal = 'false',
    ...otherParams
  } = to.query;
  if (redirect.startsWith('http') || redirect.startsWith('/book/pack/')) {
    window.location.assign(redirect);
  } else if (
    redirect === '/sign-in?isExternalAuthPortal=true' ||
    isExternalAuthPortal === 'true'
  ) {
    next();
  } else {
    next({
      path: redirect,
      query: otherParams,
    });
  }
};

export const substituteAccountId = (to, from, next) => {
  let normalizedPath = decodeURIComponent(to.path);
  const currentAccount = store.getters['currentUser/currentAccount'];
  const currentAccountId = currentAccount?.id;
  if (currentAccountId) {
    normalizedPath = normalizedPath.replace(
      '<accountId>',
      currentAccountId.toString()
    );
    next({
      path: normalizedPath,
      query: to.query,
      hash: to.hash,
    });
  } else {
    next();
  }
};

/**
 * @param {import('@tanstack/vue-query').QueryClient} queryClient
 * @return {Promise<function(flag: string): boolean>}
 */
export async function getIsFeatureFlagActiveGuard(queryClient) {
  await queryClient.prefetchQuery(featureFlagsQueryConfig);
  // if the prefetch failed, fall back to an empty array
  const flags =
    queryClient.getQueryData(featureFlagsQueryConfig.queryKey) || [];

  return flag => flags.includes(flag);
}

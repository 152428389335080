<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import SoonaDonutChart from '@/components/ui_library/SoonaDonutChart.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import {
  Roses30,
  Tangerine30,
  AvoToast30,
  Gray20,
} from '@/variables.module.scss';
import ListingCardImage from './ListingCardImage.vue';
import ListingCardVariantCount from './ListingCardVariantCount.vue';
import { usePlatformName } from './usePlatformName';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  listing: {
    type: Object,
    required: true,
  },
});

dayjs.extend(relativeTime);
const route = useRoute();

const listing = computed(() => props.listing);

const exampleListing = computed(() => {
  return listing.value.is_example_external_product;
});

const listingData = computed(() => {
  return [
    { label: listing.value.category, value: listing.value.percent_value },
  ];
});

const scoreCategoryColor = {
  poor: Roses30,
  okay: Tangerine30,
  great: AvoToast30,
};

const listingCategories = [
  {
    key: 'image_quality',
    icon: 'aperture',
  },
  {
    key: 'gallery_count',
    icon: 'album',
  },
  {
    key: 'visual_mix',
    icon: 'stars',
  },
];

const { platform } = usePlatformName(listing);

const optimizeListingPage = computed(() => {
  if (exampleListing.value) {
    return {
      name: 'example-listing-detail',
      params: {
        exampleListingId: listing.value.listing_id,
      },
    };
  } else {
    let query = {};
    if (route.query.integration_id) {
      query.integration_id = route.query.integration_id;
    }
    return {
      name: 'listing-optimize',
      params: {
        accountId: props.accountId,
        integrationId: listing.value.integration_id,
        externalProductId: listing.value.external_product_id,
        catalogItemId: listing.value.catalog_item_id,
      },
      query: query,
    };
  }
});
</script>

<template>
  <div class="listing-card">
    <ListingCardImage :listing="listing" :platform="platform" />
    <div class="listing-card__content">
      <div class="listing-card__content--left">
        <div class="listing-card__product-name-cell">
          <SoonaFlag
            v-if="exampleListing"
            class="soona-tab__flag"
            title="example"
            :background-color="Gray20"
          />
          <router-link
            :to="optimizeListingPage"
            class="listing-card__product-name"
          >
            {{ listing.name }}
          </router-link>
          <ListingCardVariantCount
            v-if="listing.variant_count > 0 && platform === 'shopify'"
            :count="listing.variant_count"
          />
        </div>
        <div class="listing-card__photo-count-cell">
          <SoonaIcon
            v-if="listing.image_count < 6"
            name="status-dot-small"
            size="small"
            class="listing-card__photo-count-icon"
          />
          <div class="listing-card__photo-count">
            {{ listing.image_count }} photo{{
              listing.image_count === 1 ? '' : 's'
            }}
          </div>
        </div>
      </div>

      <div class="listing-card__content--right">
        <div class="listing-card__updated-at-cell">
          <div v-if="!exampleListing" class="listing-card__updated-at">
            <SoonaIcon name="refresh-cw-alt" size="small" />
            {{ dayjs().to(dayjs(listing.updated_at)) }}
          </div>
        </div>
        <div class="listing-card__score-cell">
          <SoonaFlag
            v-for="category in listingCategories"
            :key="category.key"
            class="listing-card__score-icon"
            :title="listing[`${category.key}_score`].toString()"
            :background-color="
              scoreCategoryColor[listing[`${category.key}_category`]]
            "
            type="pill"
          >
            <template #icon-left>
              <SoonaIcon :name="category.icon" size="small" />
            </template>
          </SoonaFlag>
          <SoonaDonutChart
            class="listing-card__score-donut"
            accessible-title="listing score"
            :data="listingData"
            :display-value="listing.display_value"
            size="small"
            label-position="none"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.listing-card {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 0.25rem;
  position: relative;
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  overflow: hidden;
  min-height: 4.5rem;
  width: 100%;

  &:hover {
    background-color: variables.$gray-10;
  }

  &__content {
    flex: 1 1 12rem;
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 0.75rem;
    align-items: center;
    padding: 0.5rem 0 0.5rem 1rem;

    &--left {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      gap: 0.5rem;
    }

    &--right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .listing-card__score-icon {
        display: none;
      }
    }
  }

  &__product-name-cell {
    @include variables_fonts.u-body--heavy;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__product-name {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }

  &__photo-count {
    @include variables_fonts.u-label--regular;

    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: variables.$gray-60;

    &-icon {
      color: variables.$roses-60;
    }

    &-cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;
    }
  }

  &__updated-at {
    @include variables_fonts.u-label--regular;

    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: variables.$gray-60;
    align-items: center;
    display: flex;
    gap: 0.25rem;

    &-cell {
      display: none;
    }
  }

  &__score-cell {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1rem;
  }
  &__score-label {
    @include variables_fonts.u-badge__small;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
  }
  &__score-donut {
    height: 3.125rem;
    width: 3.125rem;
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__content {
      display: grid;
      grid-template-columns: 5fr 4fr;
      column-gap: 1rem;
      padding: 0 0 0 1rem;

      &--left {
        display: grid;
        grid-template-columns:
          minmax(12.5rem, 3fr)
          minmax(7.8125rem, 1fr);
        column-gap: 1rem;
      }

      &--right {
        .listing-card__score-icon {
          display: flex;
          min-width: 3.5rem;
          justify-content: space-between;
        }
      }
    }

    &__product-name-cell {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__score-cell {
      flex-direction: row;
    }
  }

  @media (min-width: 960px) {
    &__content {
      &--right {
        display: grid;
        grid-template-columns: 1fr 3fr;
        column-gap: 1rem;
      }
    }
    &__updated-at {
      &-cell {
        display: block;
        margin-top: 0;
      }
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import SoonaTelephoneDisplay from '@/components/ui_library/SoonaTelephoneDisplay.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

const store = useStore();
const account = computed(() => store.state.account);
const accountName = computed(() => account.value.owner.name);
const accountEmail = computed(() => account.value.owner.email);
const accountPhone = computed(() => account.value.owner.phone);
</script>
<template>
  <div class="AccountOwner">
    <h3 class="u-title--heavy">account owner</h3>
    <SoonaAvatar
      class="account-owner-avatar"
      :name="account.owner.name"
      size="9.375rem"
      :src="account.owner.avatar_url"
      type="user"
    />
    <div class="input-wrapper">
      <label class="u-label--heavy account-label" for="name">name</label>
      <p class="u-label--small field-read-mode">{{ accountName }}</p>
    </div>
    <div class="input-wrapper">
      <label class="u-label--heavy account-label" for="primary_email">
        email
      </label>
      <p class="u-label--small field-read-mode">
        {{ accountEmail }}
      </p>
    </div>
    <div class="input-wrapper">
      <label class="u-label--heavy account-label" for="phone">phone</label>
      <p class="u-label--small field-read-mode">
        <SoonaTelephoneDisplay :phone-number="accountPhone" />
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.AccountOwner {
  background-color: variables.$white-default;
  border-radius: 0.75rem;
  box-shadow: variables.$elevation-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1.5rem;

  .account-owner-avatar {
    margin-inline: auto;
  }

  .field-read-mode {
    background: variables.$gray-10;
    border: none;
    border-radius: 0.375rem;
    height: 1.875rem;
    overflow: hidden;
    padding: 0.5em 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .input-wrapper {
    margin-top: 1em;
    margin-bottom: 1em;
    position: relative;
  }

  .account-label {
    align-items: center;
    display: flex;
    gap: 0.25rem;
    left: 0.25rem;
    position: absolute;
    top: -1.125rem;
  }

  .account-owner-edit {
    color: variables.$white-default !important;
  }
}
@media only screen and (max-width: 768px) {
  .AccountOwner {
    .upload-image-container {
      height: 100px;
      width: 100px;
    }
    .upload-overlay {
      height: 100px;
      width: 100px;
    }
  }
}
</style>

<script setup>
import { computed, reactive, ref, watchEffect, watch } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import SoonaUpload from '@/components/uploader/SoonaUpload.vue';
import { useCatalogItem, useCreateProduct } from '@/queries/useCatalog';
import { useUpdateCatalogItem } from '@/queries/useUpdateCatalogItem';
import { useGetExternalIdentifierTypes } from 'src/queries/useGetExternalIdentifierTypes';
import { usePriorityError } from '@/composables/usePriorityError';
import { useRouter } from 'vue-router';
import ProductFlags from '@/components/user/anytime/products/product-page/ProductFlags.vue';
import { useProductIntegrationDetails } from '../useProductIntegrationDetails';
import ProductDetailsLogos from './ProductDetailsLogos.vue';

const props = defineProps({
  accountId: { type: String, required: true },
  // if there is no catalogItemId, the page is in create mode
  catalogItemId: { type: String, default: undefined },
});

const accountId = computed(() => props.accountId);
const catalogItemId = computed(() => props.catalogItemId);
const router = useRouter();

const {
  data: productData,
  isLoading: isLoadingProduct,
  isSuccess,
  error: productError,
} = useCatalogItem(accountId, catalogItemId, {
  enabled: computed(() => !!catalogItemId.value),
});

const product = computed(() => productData.value ?? {});

const {
  mutate: createProduct,
  isPending: isCreating,
  error: createError,
} = useCreateProduct(accountId);

const {
  mutate: updateProduct,
  isPending: isUpdating,
  error: updateError,
} = useUpdateCatalogItem(accountId, catalogItemId);

const {
  data: externalIdentifierTypesData,
  isLoading: isLoadingTypes,
  error: typesError,
} = useGetExternalIdentifierTypes(accountId);

const externalIdentifierTypes = computed(
  () => externalIdentifierTypesData.value ?? []
);

const externalIdentifierValue = eiType => {
  return product.value?.external_identifiers?.find(
    x => x.external_identifier_type.id == eiType.id
  )?.value;
};

const productIdentifiers = computed(() => {
  return externalIdentifierTypes.value.map(type => {
    return {
      id: product.value?.external_identifiers?.find(
        x => x.external_identifier_type.slug === type.slug
      )?.id,
      label: type.name,
      value: externalIdentifierValue(type),
      name: `product-${type.slug}`,
      slug: type.slug,
    };
  });
});

const { isAmazonProduct, isShopifyProduct, platformName } =
  useProductIntegrationDetails(product);

// edit mode module
const editMode = ref(false);

const productName = ref(product.value?.name);
const productIsProp = ref(product.value?.type === 'CatalogProp');
const productIsLargerThanTwentyInches = ref(
  product.value?.is_larger_than_twenty_inches
);
const productNeedsAssembly = ref(product.value?.needs_assembly);
const productNeedsSteaming = ref(product.value?.needs_steaming);
const productSpecialHandlingInstructions = ref(
  product.value?.special_handling_instructions
);
const productPreviewUrl = ref(product.value?.preview_url);
const imageSignedId = ref(null);

const productIdentifiersState = reactive({});

watchEffect(() => {
  productName.value = product.value.name;
  productIsProp.value = product.value.type === 'CatalogProp';
  productPreviewUrl.value = product.value.preview_url;
  productIsLargerThanTwentyInches.value =
    product.value.is_larger_than_twenty_inches;
  productNeedsAssembly.value = product.value.needs_assembly;
  productNeedsSteaming.value = product.value.needs_steaming;
  productSpecialHandlingInstructions.value =
    product.value.special_handling_instructions;
  imageSignedId.value = null;
});

watch(
  productIdentifiers,
  newIdentifiers => {
    newIdentifiers.forEach(identifier => {
      if (!productIdentifiersState[identifier.slug]) {
        productIdentifiersState[identifier.slug] = identifier.value || '';
      }
    });
  },
  { immediate: true }
);

const productIdentifierIsReadOnly = slug => {
  switch (slug) {
    case 'asin':
      return isAmazonProduct.value;
    case 'shopify-product-id':
    case 'shopify-variant-id':
      return isShopifyProduct.value;
    default:
      return false;
  }
};

const getExternalIdentifiers = formData => {
  return productIdentifiers.value.map(type => {
    let value = formData.get(type.name);
    return {
      id: type.id,
      value,
      external_identifier_type_id: externalIdentifierTypes.value.find(
        x => x.slug === type.slug
      )?.id,
      _destroy: value.length === 0 ? 1 : 0,
    };
  });
};

function extractProductData(data) {
  const image = data.get('product-image-signed-id');

  let result = {
    name: data.get('product-name'),
    special_handling_instructions: data
      .get('product-special-handling-instructions')
      .trim(),
    type: data.has('product-is-prop') ? 'CatalogProp' : 'CatalogProduct',
    is_larger_than_twenty_inches: data.has(
      'product-is-larger-than-twenty-inches'
    ),
    needs_assembly: data.has('product-needs-assembly'),
    needs_steaming: data.has('product-needs-steaming'),
    external_identifiers_attributes: getExternalIdentifiers(data),
  };

  if (image) {
    result.image = image;
  }

  return result;
}

function create(event) {
  const data = new FormData(event.target);
  const productBody = extractProductData(data);

  createProduct(productBody, {
    onSuccess: data => {
      router.push(`/account/${accountId.value}/products/${data.id}`);
    },
  });
}

const saveChanges = event => {
  const data = new FormData(event.target);
  const productBody = extractProductData(data);

  updateProduct(productBody, {
    onSuccess: () => {
      editMode.value = false;
    },
  });
};

const discardChanges = () => {
  productName.value = product.value.name;
  productIsProp.value = product.value.type === 'CatalogProp';
  productIsLargerThanTwentyInches.value =
    product.value.is_larger_than_twenty_inches ?? false;
  productNeedsAssembly.value = product.value.needs_assembly;
  productNeedsSteaming.value = product.value.needs_steaming;
  productSpecialHandlingInstructions.value =
    product.value.special_handling_instructions;
  productPreviewUrl.value = product.value.preview_url;
  imageSignedId.value = null;
  editMode.value = false;
};

function handleUploadComplete(blob) {
  imageSignedId.value = blob.signed_id;
  productPreviewUrl.value = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
}

const isLoading = computed(
  () => isLoadingProduct.value || isLoadingTypes.value
);

const priorityError = usePriorityError(
  createError,
  updateError,
  productError,
  typesError
);
</script>

<template>
  <div class="product-details">
    <div class="product-details__heading">
      <h2 class="product-details__heading--title">product details</h2>
      <SoonaButton
        v-if="!editMode && isSuccess"
        size="medium"
        variation="secondary-gray"
        @click="editMode = true"
      >
        <SoonaIcon name="pen-square" size="medium" /> edit
      </SoonaButton>
    </div>
    <ProductDetailsLogos
      :is-amazon-product="isAmazonProduct"
      :is-shopify-product="isShopifyProduct"
    />
    <div
      v-if="(editMode && isSuccess) || !catalogItemId"
      class="product-details__edit"
    >
      <div
        v-if="isAmazonProduct || isShopifyProduct"
        class="product-details__integration-info-banner"
      >
        <SoonaIcon
          class="product-details__integration-info-banner--icon"
          name="circle-information-solid"
        />
        <p>
          editing this product listing on soona will not change the listing on
          {{ platformName }}
        </p>
      </div>
      <SoonaForm
        v-slot="{ hasErrors }"
        @submit="catalogItemId ? saveChanges($event) : create($event)"
      >
        <div class="product-details__edit-inputs">
          <SoonaTextfield
            v-model:model-value="productName"
            label="product name"
            name="product-name"
            :required="true"
            :rules="['required']"
          />
          <p class="product-details__cover-image-label">cover image</p>
          <img
            v-if="productPreviewUrl"
            class="product-details__cover-image-preview"
            :src="productPreviewUrl"
            alt="product preview"
          />
          <SoonaUpload
            class="product-details__uploader"
            class-overide="product-details__uploader__button"
            :upload-complete="handleUploadComplete"
            :hide-icon="false"
            :is-multiple="false"
            :camera-icon="false"
            accept=".jpeg, .jpg, .png"
            :label="`${productPreviewUrl ? 'replace' : 'upload'} photo`"
          />
          <input
            type="hidden"
            :value="imageSignedId"
            name="product-image-signed-id"
          />
          <SoonaToggle
            v-model="productIsLargerThanTwentyInches"
            name="product-is-larger-than-twenty-inches"
            type="checkbox"
            label="larger than 20 in (50 cm) in diameter"
            class="prop-checkbox"
          />
          <SoonaToggle
            v-model="productNeedsAssembly"
            name="product-needs-assembly"
            type="checkbox"
            label="needs assembly"
            class="prop-checkbox"
          />
          <SoonaToggle
            v-model="productNeedsSteaming"
            name="product-needs-steaming"
            type="checkbox"
            label="needs steaming (recommended for apparel)"
            class="prop-checkbox"
          />
          <SoonaToggle
            v-model="productIsProp"
            name="product-is-prop"
            class="prop-checkbox"
            type="checkbox"
            label="is a prop"
          />

          <SoonaTextfield
            v-model="productSpecialHandlingInstructions"
            name="product-special-handling-instructions"
            element="textarea"
            type="text"
            label="special handling instructions"
            placeholder="optional"
            rows="4"
          />
          <SoonaTextfield
            v-for="identifier in productIdentifiers"
            :key="identifier.id"
            v-model="productIdentifiersState[identifier.slug]"
            :label="identifier.label"
            :name="identifier.name"
            placeholder="optional"
            :readonly="productIdentifierIsReadOnly(identifier.slug)"
          />
        </div>
        <div v-if="!catalogItemId" class="product-details__edit-buttons">
          <SoonaButton
            element="router-link"
            size="medium"
            variation="tertiary"
            :to="`/account/${accountId}/products`"
          >
            cancel
          </SoonaButton>
          <SoonaButton
            size="medium"
            type="submit"
            :disabled="hasErrors || isCreating"
          >
            create and save
          </SoonaButton>
        </div>
        <div v-else class="product-details__edit-buttons">
          <SoonaButton
            size="medium"
            type="button"
            variation="tertiary"
            @click="discardChanges"
          >
            cancel
          </SoonaButton>
          <SoonaButton
            size="medium"
            type="submit"
            :disabled="hasErrors || isUpdating"
          >
            save
          </SoonaButton>
        </div>
      </SoonaForm>
    </div>

    <div v-if="!editMode && isSuccess" class="product-details__view">
      <SoonaLoading
        :is-loading="isLoading || isUpdating"
        :is-contained="true"
        :is-dark="false"
        :loading-text="isLoading ? 'loading' : 'updating'"
      />
      <div class="product-details__detail">
        <h3 class="u-label--heavy">product name</h3>
        <p>{{ product.name }}</p>
      </div>
      <ProductFlags class="product-details__flags" :product="product" />
      <div
        v-for="(detail, i) in productIdentifiers.filter(x => x.value)"
        :key="i"
        class="product-details__detail"
      >
        <h3 class="u-label--heavy">{{ detail.label }}</h3>
        <p>{{ detail.value }}</p>
      </div>
      <div>
        <h3 class="u-label--heavy">special handling instructions</h3>
        <p class="product-details__instructions">
          {{ product.special_handling_instructions }}
        </p>
      </div>
    </div>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-details {
  color: variables.$black-default;

  &__heading {
    margin-bottom: 0.75rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    &--title {
      @include variables_fonts.u-title--heavy;
    }
  }

  &__view {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__integration-info-banner {
    margin-top: 1rem;
    background-color: variables.$periwink-blue-20;
    border-radius: 0.3125rem;
    border: 0.0625rem solid variables.$periwink-blue-30;
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    padding: 0.625rem 0.75rem;

    &--icon {
      color: variables.$periwink-blue-50;
      flex: 0 0 1rem;
    }
  }

  &__edit-inputs {
    margin-top: 1.875rem;

    .prop-checkbox {
      margin-bottom: 1.9375rem;
    }
  }

  &__edit-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
  }

  &__instructions {
    white-space: pre-wrap;
  }

  &__badges {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }

  &__cover-image-label {
    @include variables_fonts.u-label--heavy;
    color: variables.$black-default;
    margin-bottom: 0.5rem;
  }

  &__cover-image-preview {
    display: block;
    max-width: 11.25rem;
    max-height: 7.5rem;
    margin-bottom: 0.5rem;
  }

  &__flags {
    margin-bottom: 0.5rem;
  }
}

.product-details__uploader {
  font-size: 1rem;
  height: auto;
  margin-bottom: 1.5rem;

  :deep(.product-details__uploader__button) {
    @include variables_fonts.u-label--heavy;

    display: inline-flex;
    align-items: center;
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    cursor: pointer;
    padding: 0.5rem 0.9375rem;
    justify-content: center;
    min-width: 5.25rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition:
      background-color 0.1s ease-out,
      color 0.1s ease-out,
      border-color 0.1s ease-out,
      color 0.1s ease-out;
    user-select: none;
    vertical-align: middle;
    color: variables.$black-default;

    &:hover {
      background-color: variables.$gray-20;
      border-color: variables.$gray-40;
    }

    &:active {
      background-color: variables.$gray-30;
      border-color: variables.$gray-50;
    }
  }

  :deep(progress) {
    margin: 0;
  }

  :deep(label.file-label) {
    overflow: visible;
  }

  :deep(input:focus + .product-details__uploader__button) {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getCrewMember } from '../api/crewAssignments';

/**
 *
 * @param {number | Ref<number>} userId
 */
export function useGetCrewMember(userId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.crewMember(userId),
    queryFn: ({ signal }) => getCrewMember(unref(userId), signal),
    ...queryOptions,
  });
}

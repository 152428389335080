<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';

const router = useRouter();
const showSubscriptionsDialog = ref(false);

const firstTimeSeeingDialog = computed(
  () => !!router.currentRoute.value.query.sub_promo
);

const handleBannerClick = e => {
  e.preventDefault();
  showSubscriptionsDialog.value = true;
};

const closeDialog = () => {
  router.replace({ query: {} });
  showSubscriptionsDialog.value = false;
};
</script>

<template>
  <div class="subscription-wide-banner">
    <span class="u-body--heavy">
      try our AI scene creator to spark ideas for your shoot.
    </span>
    <SoonaButton element="a" variation="tertiary" @click="handleBannerClick">
      learn more
    </SoonaButton>
  </div>
  <SubscriptionsDialog
    v-if="showSubscriptionsDialog || firstTimeSeeingDialog"
    flow="trialInfo"
    @close="closeDialog"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.subscription-wide-banner {
  color: variables.$white-default;
  background: variables.$periwink-blue-70;
  background-image: none;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  padding: 1rem 1.5625rem;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem !important;

  span {
    margin: 0 0.25rem 0 0;
  }

  a {
    color: variables.$white-default;

    &:hover {
      color: variables.$white-translucent-50 !important;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    background-image: url(@images/subscriptions/CSR_collage@2x.png);
  }
}
</style>

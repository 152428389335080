<script setup>
import { computed, watchEffect } from 'vue';
import PackImageUploader from '@/components/crew/pack_builder/PackImageUploader.vue';
import PackSubSection from '@/components/crew/pack_builder/PackSubSection.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaListEditor from '@/components/ui_library/SoonaListEditor.vue';
import SoonaSegmentedRadios from 'src/components/ui_library/SoonaSegmentedRadios.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const props = defineProps({
  category: {
    type: String,
    required: false,
  },
  descriptionBody: {
    type: Array,
    default: () => [],
    required: false,
  },
  defaultRecommendedPack: {
    type: Boolean,
    required: false,
  },
  displayDiscount: {
    type: Boolean,
    required: false,
  },
  editingDisabled: {
    type: Boolean,
    required: true,
  },
  includes: {
    type: Array,
    default: () => [],
    required: false,
  },
  isPackVideo: {
    type: Boolean,
    required: false,
  },
  maxQuantity: {
    type: Number,
    required: false,
  },
  merchandisingTags: {
    type: Array,
    default: () => [],
    required: false,
  },
  name: {
    type: String,
    required: false,
  },
  originalPrice: {
    type: String,
    required: false,
  },
  packDiscount: {
    type: String,
    required: false,
  },
  packMainImageUrl: {
    type: String,
    required: false,
  },
  packNameEditMode: {
    type: Boolean,
    required: true,
  },
  packPrice: {
    type: String,
    required: false,
  },
  packProductRate: {
    type: String,
    required: false,
  },
  previewVimeoId: {
    type: String,
    required: false,
  },
  emailTemplateName: {
    type: String,
    required: false,
  },
});

const emit = defineEmits([
  'enter-edit-mode',
  'save-pack-name',
  'update:descriptionBody',
  'update:defaultRecommendedPack',
  'update:displayDiscount',
  'update:includes',
  'update:maxQuantity',
  'update:merchandisingTags',
  'update:name',
  'update:originalPrice',
  'update:packDiscount',
  'update:packMainImage',
  'update:packMainImageUrl',
  'update:packPrice',
  'update:packProductRate',
  'update:previewVimeoId',
  'update:emailTemplateName',
]);

const savePackName = () => {
  emit('save-pack-name');
};

const editingDisabled = computed(() => props.editingDisabled);

// pack image
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const originalPackImage = props.packMainImageUrl;
const setPackImageBlob = blob => {
  let url = blob
    ? `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
    : originalPackImage;
  emit(`update:packMainImageUrl`, url);
  emit(`update:packMainImage`, blob?.signed_id);
};

// pack price
const packPrice = computed({
  get() {
    return props.packPrice ? Number(props.packPrice.replace('$', '')) : 0;
  },
  set(value) {
    emit('update:packPrice', `$${value}`);
    emit('update:packProductRate', value);
  },
});

const emailTemplateName = computed({
  get() {
    return props.emailTemplateName;
  },
  set(value) {
    emit('update:emailTemplateName', value);
  },
});

const originalPrice = computed({
  get() {
    return props.originalPrice
      ? Number(props.originalPrice.replace('$', ''))
      : null;
  },
  set(value) {
    emit('update:originalPrice', `$${value}`);
  },
});

const packDiscount = computed({
  get() {
    return props.packDiscount
      ? Number(props.packDiscount.replace('$', ''))
      : null;
  },
  set(value) {
    emit('update:packDiscount', `$${value}`);
  },
});

const numberFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  trailingZeroDisplay: 'stripIfInteger',
});

const discountAmount = computed(() =>
  numberFormat.format(originalPrice.value - packPrice.value)
);

watchEffect(() => {
  packDiscount.value = discountAmount.value;
});

// select and radio options
const packQuantityOptions = Array.from(Array(100), (_, i) => ({
  value: i + 1,
  label: i + 1,
}));

const booleanOptions = [
  { value: true, label: 'yes' },
  { value: false, label: 'no' },
];
</script>

<template>
  <div class="pack-info">
    <PackSubSection heading="basic information" hide-heading>
      <p class="pack-info__category" :data-category="category">
        {{ category }}
      </p>
      <div class="pack-info__pack-name">
        <div class="pack-info__pack-name--input">
          <SoonaTextfield
            :model-value="name"
            label="title"
            type="text"
            name="pack-name"
            :disabled="!packNameEditMode"
            @update:model-value="$emit('update:name', $event)"
          />
        </div>
        <div class="pack-info__pack-name--action">
          <button
            v-if="!packNameEditMode"
            class="pack-info__pack-name--edit"
            @click="$emit('enter-edit-mode')"
          >
            <SoonaIcon name="pen-square" />
            <span class="u-visually-hidden">edit</span>
          </button>

          <SoonaButton v-if="packNameEditMode" @click="savePackName">
            save
          </SoonaButton>
        </div>
      </div>
    </PackSubSection>

    <PackSubSection heading="description">
      <SoonaTextfield
        :model-value="descriptionBody[0]"
        label="description paragraph"
        type="text"
        name="pack-description-body"
        element="textarea"
        rows="4"
        @update:model-value="$emit('update:descriptionBody', [$event])"
      />
    </PackSubSection>

    <PackSubSection heading="includes">
      <SoonaListEditor
        :model-value="includes"
        :disabled="editingDisabled"
        @update:model-value="$emit('update:includes', $event)"
      >
        <template #legend>includes</template>
        <template #description>
          add one item per row. don’t include the bullet point
        </template>
      </SoonaListEditor>
    </PackSubSection>

    <PackSubSection heading="media">
      <SoonaTextfield
        v-if="isPackVideo"
        :model-value="previewVimeoId"
        label="Vimeo preview ID"
        type="text"
        @update:model-value="$emit('update:previewVimeoId', $event)"
      >
        <template #subtext>
          <a
            class="pack-info__video-link"
            href="https://help.soona.co/recommended/admin-pack-builder"
            target="_blank"
            >watch this video
          </a>
          for instructions on how to add a video in Vimeo
        </template>
      </SoonaTextfield>

      <PackImageUploader
        image-type="main"
        :set-pack-image-blob="setPackImageBlob"
        :pack-image-url="packMainImageUrl"
      >
        <template #label> pack card image </template>
        <template #subtext>
          used on the small preview card when choosing a pack
        </template>
      </PackImageUploader>
    </PackSubSection>

    <PackSubSection heading="pricing & classification">
      <SoonaTextfield
        v-model="packPrice"
        :disabled="editingDisabled"
        label="pack price"
        type="number"
        placeholder="0"
        min="0"
      />
      <SoonaTextfield
        v-model="originalPrice"
        :disabled="editingDisabled"
        label="à la carte price"
        type="number"
        placeholder="0"
        :min="packPrice"
      />

      <p class="u-label--heavy">merchandising tags</p>
      <SoonaListEditor
        :model-value="merchandisingTags"
        class="pack-info__merchandising-tags"
        @update:model-value="$emit('update:merchandisingTags', $event)"
      >
        <template #description>
          add one tag per row. these are used to determine recommended pack
          priority. they also appear as a badge on the pack thumbnail in some
          parts of the app.
        </template>
      </SoonaListEditor>

      <SoonaSegmentedRadios
        :model-value="defaultRecommendedPack"
        label="does this pack need to be one of the three (3) recommended packs shown in booking?"
        name="default-recommended-pack"
        :options="booleanOptions"
        @update:model-value="
          $event => $emit('update:defaultRecommendedPack', $event)
        "
      />
      <SoonaSegmentedRadios
        :model-value="displayDiscount"
        label="is the à la carte discount shown to customers during booking?"
        name="display-discount"
        :options="booleanOptions"
        @update:model-value="$event => $emit('update:displayDiscount', $event)"
      />
      <SoonaTextfield
        :model-value="discountAmount"
        label="discount amount (automatically calculated)"
        :readonly="true"
      />
      <SoonaTextfield
        v-model="emailTemplateName"
        label="email template name (usually blank)"
      />
      <SoonaSelect
        :model-value="maxQuantity"
        :options="packQuantityOptions"
        @update:model-value="$event => $emit('update:maxQuantity', $event)"
      >
        <template #label>max number of packs that can be purchased</template>
      </SoonaSelect>
    </PackSubSection>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.pack-info {
  & > *:first-child {
    padding-top: 0;
  }

  &__category {
    display: inline-block;
    background: variables.$bubbletape-pink-20;
    margin-bottom: 1.5rem;
    padding: 0.3125rem 0.625rem;
    /* padding top and bottom plus font-size */
    border-radius: 1.3125rem;
    color: variables.$black-default;
    font-size: 0.6875rem;
    line-height: 1.27272727;
    font-weight: 900;
    letter-spacing: 0.09375rem;
    text-transform: uppercase;

    &[data-category='photo'] {
      background: variables.$tangerine-20;
    }

    &[data-category='video'] {
      background: variables.$green-apple-10;
    }
  }
  &__pack-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;

    &--input {
      flex-grow: 1;
    }

    &--action {
      padding-bottom: 0.375rem;
    }

    &--edit {
      background: none;
    }
  }

  &__video-link {
    color: variables.$periwink-blue-50;
    text-decoration: underline;

    &:hover {
      color: variables.$periwink-blue-60;
    }
  }

  &__merchandising-tags {
    padding-bottom: 1rem;
  }
}
</style>

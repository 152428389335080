import { http } from 'src/config/vue-axios';

/**
 *
 * @param {number} accountId
 * @returns {Promise<any>}
 */
export async function getExampleCompetitorStaffPicks(accountId, signal) {
  const response = await http.get(
    `/accounts/${accountId}/example_competitor_staff_picks`,
    {
      signal,
    }
  );
  return response.data;
}

/**
 *
 * @param {number} accountId
 * @param {number} competitorId
 * @returns {Promise<any>}
 */
export async function getExampleCompetitorStaffPick(
  accountId,
  competitorId,
  signal
) {
  const response = await http.get(
    `/accounts/${accountId}/example_competitor_staff_picks/${competitorId}`,
    {
      signal,
    }
  );
  return response.data;
}

<template>
  <div class="booking-action">
    <a v-if="isCanceled" href="/book/new" class="button booking-action-button">
      BOOK AGAIN
    </a>
    <!--  button for suggested state -->
    <SoonaButton
      v-else-if="
        (currentReservation.draft_type === 'client_draft' ||
          currentReservation.draft_type === 'suggested_draft') &&
        !isUserDashboard
      "
      element="a"
      :href="`/#/booking/${currentReservation.id}`"
      size="medium"
      variation="secondary-black"
      @click.stop
    >
      complete booking
    </SoonaButton>
    <!--  button for pro services state -->
    <a
      v-else-if="
        hasModelService &&
        !hasPickedProServicesPreferences &&
        shippingDetails &&
        shippingDetails.length == 0 &&
        isPendingShotlist
      "
      :href="`/user/anytime#/reservation/${currentReservation.id}/pro-service-traits`"
      class="button booking-action-button"
      @click.stop
    >
      {{ buttonTextProServices }}
    </a>

    <!--  button for shotlist state -->
    <a
      v-else-if="
        !isHeadshotsForAll &&
        (isPendingShotlist || isVideo) &&
        !currentReservation.isPickAPack &&
        !hasShotList &&
        shippingDetails &&
        shippingDetails.length == 0
      "
      :href="`/user/anytime#/reservation/${currentReservation.id}/shotlist`"
      class="button booking-action-button"
      @click.stop
    >
      {{ buttonText }}
    </a>

    <!-- button for preshoot state -->
    <SoonaButton
      v-else-if="
        isPreshoot && !isDashboard && (currentReservation.start !== null || '')
      "
      element="a"
      :href="`/reservations/${currentReservation.id}/download.ics`"
      size="medium"
      variation="secondary-gray"
    >
      <SoonaIcon name="calendar" size="medium" />
      add to your calendar
    </SoonaButton>
    <!-- button for live shoot state -->
    <router-link
      v-else-if="isShootDay"
      :to="{ path: `/reservation/${currentReservation.id}` }"
      class="button booking-action-button"
      @click.stop
    >
      live shoot
    </router-link>
    <!-- button for order state -->
    <router-link
      v-else-if="isOrder"
      :to="{ name: 'gallery' }"
      class="button booking-action-button"
      @click.stop
    >
      shop now
    </router-link>
    <!-- button for editing state -->
    <a
      v-else-if="isEditing"
      href="/book/new"
      class="button booking-action-button"
      @click.stop
    >
      book another shoot
    </a>
    <!-- buttons for completed order state -->
    <template v-else-if="isCompleted">
      <a href="/book/new" class="button booking-action-button" @click.stop>
        book again
      </a>
      <a
        class="button booking-action-button"
        :href="`/reservations/${currentReservation.id}/download_files.zip`"
        >download all</a
      >
    </template>
  </div>
</template>

<script>
import bookingProgressMixin from 'src/mixins/bookingProgressMixin';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'BookingActionButton',
  components: {
    SoonaButton,
    SoonaIcon,
  },
  mixins: [bookingProgressMixin],
  props: {
    currentReservation: Object,
    isDashboard: Boolean,
    shippingDetails: Array,
  },
  data() {
    return {
      buttonText: '',
      trackingName: '',
    };
  },
  computed: {
    reservationId() {
      return this.currentReservation.id;
    },
    buttonTextProServices() {
      if (
        this.hasHandModel &&
        !this.hasFullBodyModel &&
        !this.hasPetModel &&
        !this.hasFootModel
      ) {
        return 'select hand model';
      } else if (
        this.hasFullBodyModel &&
        !this.hasHandModel &&
        !this.hasPetModel &&
        !this.hasFootModel
      ) {
        return 'select full body model';
      } else if (
        this.hasFootModel &&
        !this.hasFullBodyModel &&
        !this.hasHandModel &&
        !this.hasPetModel
      ) {
        return 'select foot model';
      } else if (
        this.hasPetModel &&
        !this.hasFullBodyModel &&
        !this.hasHandModel &&
        !this.hasFootModel
      ) {
        return 'select pet model';
      } else {
        return 'select models';
      }
    },
    isUserDashboard() {
      return this.$route.name === 'info';
    },
  },
  mounted() {
    const isPack = this.currentReservation.pack_configuration_id;

    this.buttonText = isPack ? 'review your shoot' : 'build your shoot';
    this.trackingName = isPack ? 'Review Your Shoot' : 'Build Your Shoot';
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.booking-action {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;

  .booking-action-button {
    background: variables.$friendly-red-50;
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
    color: variables.$white-default;
    font-size: 0.625rem;
    font-weight: 800;
    height: 3em;
    letter-spacing: 0.0625rem;
    padding: 0.5em 1.5em 0.5em 1.5em;
    text-transform: uppercase;
  }

  .complete-button {
    margin-right: 1rem;
  }
}
</style>

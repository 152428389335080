<template>
  <SoonaToastRoot>
    <CometChatProvider :user-id="currentUserId">
      <SoonaLoading
        v-if="isMeResetting"
        is-loading
        loading-text="reloading data…"
        role="status"
        aria-live="assertive"
      />
      <component
        :is="data.component"
        v-if="isIntegrationAuth"
        v-bind="data.props"
      />
      <PlatformSidebarLayout
        v-else
        :use-router-meta="data.component === 'anytime'"
      >
        <ReEditAlert
          v-if="unsubmittedReEdits.length > 0"
          :unsubmitted-re-edits="unsubmittedReEdits"
        />
        <component :is="data.component" v-bind="data.props" />
      </PlatformSidebarLayout>
      <VueQueryDevtools v-if="!disableTanStackQueryDevtools" />
    </CometChatProvider>
  </SoonaToastRoot>
</template>

<script>
import {
  computed,
  defineAsyncComponent,
  provide,
  ref,
  watchSyncEffect,
} from 'vue';
import { useStore } from 'vuex';
import * as types from 'src/store/mutation-types';

const ForgotPassword = defineAsyncComponent(
  () => import('src/components/user/ForgotPassword.vue')
);
const UpdatePassword = defineAsyncComponent(
  () => import('src/components/user/UpdatePassword.vue')
);
const Anytime = defineAsyncComponent(
  () => import('src/components/user/Anytime.vue')
);

import BackButton from 'src/components/user/anytime/BackButton.vue';
import SoonaToastRoot from '@/components/ui_library/soona_toast/SoonaToastRoot.vue';
import ReEditAlert from 'src/components/user/anytime/ReEditAlert.vue';
import 'viewerjs/dist/viewer.css';
import '../stylesheets/app.scss';
import CometChatProvider from '@/components/chat/CometChatProvider.vue';
import PlatformSidebarLayout from '@/components/platform-layout/PlatformSidebarLayout.vue';
import { useRoute, useRouter } from 'vue-router';
import { useDialogProvider } from '@/composables/useDialog';
import { SET_IS_ME_RESETTING, useMe } from '@/composables/user/useMe';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { useAccount } from '@/queries/account/useAccount';
import { useReEditsCollections } from '@/queries/re_edits_collections/useReEditsCollections';
import { useRegisterAnytimeToken } from '@/composables/useRegisterAnytimeToken';

const VueQueryDevtools = defineAsyncComponent(
  async () => (await import('@tanstack/vue-query-devtools')).VueQueryDevtools
);

export default {
  components: {
    VueQueryDevtools,
    SoonaLoading,
    PlatformSidebarLayout,
    CometChatProvider,
    ForgotPassword,
    UpdatePassword,
    Anytime,
    BackButton,
    SoonaToastRoot,
    ReEditAlert,
  },
  props: ['data'],
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const isMeResetting = ref(false);
    provide(SET_IS_ME_RESETTING, value => {
      isMeResetting.value = value;
    });

    const { me, currentAccountId, currentUserId } = useMe();

    // the one place in the app that should sync currentUser to Vuex.
    // we are using watchSyncEffect to ensure data is set in Vuex before render.
    watchSyncEffect(() => {
      store.commit(`currentUser/${types.SET_CURRENT_USER}`, {
        currentUser: me.value ?? null,
      });
    });

    if (currentAccountId.value) {
      store.dispatch('account/loadAccount', currentAccountId.value);
    }

    const { data: newReEditsDraftRequests } = useReEditsCollections(
      currentAccountId,
      ['draft'],
      {
        enabled: computed(() => !!currentAccountId.value),
      }
    );

    // eslint-disable-next-line vue/no-setup-props-reactivity-loss
    const propsRoute = props.data?.props?.route;
    const hash = window.location.hash;
    if (propsRoute && (!hash || hash === '#' || hash === '#/')) {
      router.replace(`/${propsRoute}`);
    }

    // anytime subscription for user's own account
    const currentUserAnytimeToken = computed(
      () => me.value?.anytime_token ?? null
    );
    useRegisterAnytimeToken(currentUserAnytimeToken);

    // crew anytime subscriptions for viewing customer accounts
    const routeAccountId = computed(() => {
      return route.params.accountId;
    });

    const { data: routeAccount } = useAccount(routeAccountId, {
      enabled: computed(() => !!routeAccountId.value),
    });

    const routeAccountAnytimeToken = computed(
      () => routeAccount.value?.anytime_token ?? null
    );
    useRegisterAnytimeToken(routeAccountAnytimeToken);

    const isIntegrationAuth = computed(() => {
      return !!route.query.canva_user_token;
    });

    useDialogProvider();

    const unsubmittedReEdits = computed(() => {
      return newReEditsDraftRequests.value?.re_edits_collections || [];
    });

    const disableTanStackQueryDevtools =
      import.meta.env.VITE_DISABLE_TANSTACK_QUERY_DEVTOOLS === 'true';

    return {
      currentUserId,
      isIntegrationAuth,
      isMeResetting,
      unsubmittedReEdits,
      disableTanStackQueryDevtools,
    };
  },
};
</script>

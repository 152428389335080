<script setup>
import { computed } from 'vue';
import MoreContentEmpty from '@/components/user/anytime/gallery/MoreContentEmpty.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  filterBy: {
    type: String,
    required: true,
  },
});

const filterBy = computed(() => props.filterBy);

const emptyIconName = computed(() => {
  switch (filterBy.value) {
    case 'in bag':
      return 'image-square-duotone';
    case 'favorites':
      return 'heart-duotone';
    case 'expert picks':
      return 'trophy-duotone';
    default:
      return 'image-square-duotone';
  }
});

const emptyFilesMessage = computed(() => {
  switch (filterBy.value) {
    case 'in bag':
      return 'bag some content!';
    case 'favorites':
      return 'favorite some content!';
    case 'expert picks':
      return 'your expert picks will appear here!';
    case 'hidden':
      return 'there is no hidden content';
    case 'raws no preview':
      return 'there are no raws w/o previews';
    default:
      return 'your content will appear here!';
  }
});
</script>
<template>
  <MoreContentEmpty>
    <template #icon>
      <SoonaIcon :name="emptyIconName" />
    </template>
    <template #message>
      {{ emptyFilesMessage }}
    </template>
  </MoreContentEmpty>
</template>

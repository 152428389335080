import { computed } from 'vue';

export const useCompetitorRuleResults = competitor => {
  const competitorImageQualityRuleResults = computed(
    () =>
      competitor.value.listing_score.rule_results.find(
        rr => rr.rule_group === 'image quality'
      )?.rules
  );
  const competitorGalleryCountRuleResult = computed(
    () =>
      competitor.value.listing_score.rule_results.find(
        rr => rr.rule_group === 'gallery count'
      )?.rules[0]
  );
  const allCompetitorVisualMixRuleResults = computed(
    () =>
      competitor.value.listing_score.rule_results.find(
        rr => rr.rule_group === 'visual content mix'
      )?.rules
  );
  const competitorVisualMixRuleResults = computed(() =>
    allCompetitorVisualMixRuleResults.value.filter(
      r => r.rule !== 'main image product on white'
    )
  );

  return {
    competitorImageQualityRuleResults,
    competitorGalleryCountRuleResult,
    allCompetitorVisualMixRuleResults,
    competitorVisualMixRuleResults,
  };
};

<script setup>
import { computed } from 'vue';
import { useTitle } from '@vueuse/core';
import { useAccount } from '@/queries/account/useAccount';
import { useGetAccountsOrder } from '@/queries/accounts-orders/useGetAccountsOrder';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import StatusCard from './StatusCard.vue';
import LineItemsTable from './LineItemsTable.vue';
import InvoiceDetails from './InvoiceDetails.vue';
import BillingAddress from './BillingAddress.vue';
import OrderCosts from './OrderCosts.vue';

// Props
const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  orderId: {
    type: String,
    required: true,
  },
});

const orderId = computed(() => props.orderId);
const accountId = computed(() => props.accountId);

// Meta
useTitle(computed(() => `order ${orderId.value} | soona`));

// Data
const { data: account } = useAccount(accountId);
const { data: currentOrder } = useGetAccountsOrder(accountId, { orderId });

const lineItems = computed(
  () => currentOrder.value.reservation_line_items ?? []
);

const subtotal = computed(() => currentOrder.value?.totals?.subtotal || 0);
const discountTotal = computed(() => currentOrder.value?.totals?.discount || 0);
const surchargeAmount = computed(
  () => currentOrder.value?.totals?.surcharge || 0
);
const creditsAmount = computed(() => currentOrder.value?.totals?.credits || 0);
const totalAmount = computed(() => currentOrder.value?.totals?.total || 0);
const amountDue = computed(() => currentOrder.value?.totals?.amount_due);
const promoCode = computed(() => currentOrder.value.discount?.code);

// Methods
const onDownload = () => {
  window.print();
};

const isPaymentPending = computed(
  () =>
    currentOrder.value?.status === 'created' ||
    currentOrder.value?.status === 'confirmed'
);
</script>

<template>
  <header class="order-view-pdf-header"></header>
  <img
    class="order-view-pdf-logo"
    src="@images/soona-logo-red.svg"
    alt="soona logo"
  />
  <div v-if="currentOrder" class="OrderView order-view">
    <!-- CTA -->
    <div class="order-view__action-btns">
      <SoonaBackButton
        element="router-link"
        :to="`/account/${accountId}/orders`"
        button-text="Orders"
      />
      <SoonaButton variation="tertiary" @click="onDownload">
        <SoonaIcon name="printer" />
        Download
      </SoonaButton>
    </div>
    <div class="order-view__header-container">
      <StatusCard :current-order="currentOrder" :amount-due="amountDue" />
      <div class="order-view__container">
        <InvoiceDetails :current-order="currentOrder" />
        <BillingAddress :account="account" />
      </div>
    </div>
    <LineItemsTable v-if="lineItems" :line-items="lineItems" />
    <hr class="order-view__item-break" />
    <OrderCosts
      :subtotal="subtotal"
      :promo-code="promoCode"
      :discount-total="discountTotal"
      :credits-total="creditsAmount"
      :surcharge-amount="surchargeAmount"
      :total-amount="totalAmount"
      :amount-due="amountDue"
      :is-payment-pending="isPaymentPending"
    />
  </div>
  <footer class="order-view-pdf-footer">
    <p>Invoice total is inclusive of sales tax, where applicable</p>
  </footer>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.order-view {
  margin: 0 auto;
  max-width: 50rem;
  container: order-view / inline-size;

  // CTAs
  &__action-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  // Invoice Details & Billing Address
  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    // flex: 1 0 100%;
  }
}

// Desktop Styles
@mixin order-view-desktop-styles {
  .order-view {
    &__header-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: start;
    }
  }
}

@container order-view (min-width: 37.5rem) {
  @include order-view-desktop-styles;
}

/* compat: remove when dropping Safari 15 */
@supports not (container-type: inline-size) {
  @media (min-width: 39.5rem) {
    @include order-view-desktop-styles;
  }
}

// Print Styles\
.order-view-pdf-header {
  display: none;
}

.order-view-pdf-logo {
  display: none;
}

.order-view-pdf-footer {
  display: none;
}

@media print {
  @page {
    // Hides default print header and footer
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .order-view-pdf-header {
    print-color-adjust: exact;
    display: block;
    height: 0.75rem;
    background: #ffc5ae;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .order-view-pdf-logo {
    display: block;
    position: fixed;
    top: 2.5rem;
    right: 1.75rem;
    width: 6rem;
  }

  .order-view {
    padding: 0 1.5rem;

    &__action-btns {
      display: none;
    }

    &__header-container {
      display: flex;
      flex-direction: column-reverse;
    }

    &__item-break {
      display: none;
    }
  }

  .order-view-pdf-footer {
    display: block;
    position: fixed;
    bottom: 0.75rem;
    left: 2rem;
    right: 2rem;
    border-bottom: 1px solid black;
    padding-bottom: 1rem;

    p {
      @include variables_fonts.u-small--regular;
    }
  }
}
</style>

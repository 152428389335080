<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';

const route = useRoute();
const router = useRouter();

const showDialog = ref(false);

// query param stuff to auto-open dialog
watchEffect(() => {
  if (
    (route.query?.open_subscription_checkout ||
      route.query?.open_subscriptions_modal) &&
    !route.query?.dialog_opened
  ) {
    showDialog.value = true;

    router.replace({
      query: {
        ...route.query,
        dialog_opened: true,
      },
    });
  }
});

const dialogTier = computed(() => {
  if (route.query?.open_subscription_checkout)
    return route.query.open_subscription_checkout;
  return 'tier-one';
});

const handleCloseDialog = () => (showDialog.value = false);
</script>

<template>
  <SubscriptionsDialog
    v-if="showDialog"
    flow="checkoutOnly"
    :selected-tier-slug="dialogTier"
    @close="handleCloseDialog"
  />
</template>

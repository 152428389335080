import { http } from 'src/config/vue-axios';
import { mapPaginationHeaders } from '../lib/api-transforms';

/**
 *
 * @param {number} accountId
 * @param {Object} body
 * @param {string} body.pageUrl
 * @param {string} body.externalProvider
 * @returns {Promise<any>}
 */
export async function createCompetitor(
  accountId,
  { pageUrl, externalProvider }
) {
  const response = await http.post(`/accounts/${accountId}/competitors`, {
    page_url: pageUrl,
    external_provider: externalProvider,
  });
  return response.data;
}

/**
 *
 * @param {number} accountId
 * @returns {Promise<any>}
 */
export async function getCompetitors(
  accountId,
  {
    query = null,
    filter = null,
    orderBy = 'created_at',
    direction = 'asc',
    itemsPerPage,
    currentPage,
  },
  signal
) {
  const params = {};

  if (query) params['query'] = query;
  if (filter) params['filter'] = filter;
  if (orderBy) params['order_by'] = orderBy;
  if (direction) params['direction'] = direction;
  if (itemsPerPage) params.items = itemsPerPage;
  if (currentPage) params.page = currentPage;

  const response = await http.get(`/accounts/${accountId}/competitors`, {
    params,
    signal,
  });
  return {
    pagination: mapPaginationHeaders(response.headers),
    competitors: response.data.competitors,
    scoring_in_progress: response.data.scoring_in_progress,
  };
}

/**
 *
 * @param {string | number} accountId
 * @returns {Promise}
 */
export async function refreshCompetitors(accountId) {
  const response = await http.get(`/accounts/${accountId}/competitors/refresh`);
  return response.status === 204;
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} competitorId
 * @param {AbortSignal} signal
 * @returns {Promise}
 */
export async function getCompetitor(accountId, competitorId, signal) {
  const response = await http.get(
    `/accounts/${accountId}/competitors/${competitorId}`,
    { signal }
  );

  return response.data;
}

/**
 *
 * @param {number} accountId
 * @param {number} competitorId
 * @returns {Promise<any>}
 */
export async function deleteCompetitor(accountId, competitorId) {
  const response = await http.delete(
    `/accounts/${accountId}/competitors/${competitorId}`
  );
  return response.status === 204;
}

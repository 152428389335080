import { useMutation } from '@tanstack/vue-query';
import { checkPresenceOfProvider } from '@/api/users';

/**
 */
export function useCheckPresenceOfProvider() {
  return useMutation({
    mutationFn: email => checkPresenceOfProvider(email),
  });
}

<script setup>
import { computed } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import { useResetPack } from 'src/queries/reservation-packs/useResetPack';
import { usePackConfigurations } from '@/queries/pack-configurations/usePackConfigurations';
import { useCreatePackReservation } from '@/queries/useCreatePackReservation';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaSegmentedRadios from '@/components/ui_library/SoonaSegmentedRadios.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});

const {
  data: reservation,
  error: reservationLoadingError,
  isLoading: loadingReservation,
} = useReservation(computed(() => props.reservationId));
const {
  data: allPackConfigurations,
  error: allPacksError,
  isLoading: loadingPacks,
} = usePackConfigurations({
  packBuilder: true,
});
const {
  error: packSelectError,
  mutate: mutatePack,
  isPending: isMutatingPack,
} = useCreatePackReservation();
const { mutate: resetPack, error: resetError } = useResetPack();
const {
  mutate: mutateReservation,
  error: mutateError,
  isPending: isMutatingReservation,
} = useUpdateReservation(computed(() => props.reservationId));

const isBusy = computed(
  () =>
    loadingReservation.value ||
    loadingPacks.value ||
    isMutatingPack.value ||
    isMutatingReservation.value
);

const priorityError = usePriorityError(
  allPacksError,
  reservationLoadingError,
  mutateError,
  resetError
);

const selectedPack = computed(() =>
  allPackConfigurations.value?.data?.find(
    pc => pc.id === reservation.value?.pack_configuration?.id
  )
);

function setSelected(pack) {
  mutatePack({
    reservationId: props.reservationId,
    packId: pack.id,
    quantity: 1,
  });
}

const bookingTypeOptions = [
  { value: 'custom', label: 'custom' },
  { value: 'packs', label: 'packs' },
];

const selectedBookingType = computed(() =>
  reservation.value?.skipped_pack === false ? 'packs' : 'custom'
);

function onUpdateBookingType(type) {
  if (type === 'packs') {
    mutateReservation({ skipped_pack: false });
  } else {
    if (reservation.value?.isPickAPack) {
      resetPack(props.reservationId);
    } else {
      mutateReservation({ skipped_pack: true });
    }
  }
}
</script>

<template>
  <SoonaSegmentedRadios
    label="booking type"
    :label-visually-hidden="true"
    name="booking-type"
    :disabled="isBusy"
    :options="bookingTypeOptions"
    :model-value="selectedBookingType"
    data-cypress="radio-booking-type"
    @update:model-value="onUpdateBookingType"
  />

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>

  <SoonaSelect
    v-if="!reservation.skipped_pack"
    label="name"
    :reduce="option => option"
    :model-value="selectedPack"
    :options="allPackConfigurations?.data ?? []"
    :disabled="isBusy"
    :loading="isBusy"
    :selectable="option => option.id !== (selectedPack && selectedPack.id)"
    data-cypress="select-pack"
    @update:model-value="setSelected"
  >
    <template #label>pack</template>
  </SoonaSelect>

  <SoonaError v-if="packSelectError">
    {{ packSelectError }}
  </SoonaError>
</template>

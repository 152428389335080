import { http } from '@/config/vue-axios';

/**
 *
 * @param {number} taskId
 * @param {Object} [body]
 */
export async function updateTask(taskId, body = {}) {
  const response = await http.patch(`/quest_tasks/${taskId}`, {
    quest_task: body,
  });

  return response.data;
}

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
  alt: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: '2rem',
  },
  src: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    required: true,
    validator: value => ['account', 'user'].includes(value),
  },
});

// support some sort of emoji, ZWJ sequences will not work, and only display the first one
const firstLetter = computed(() =>
  props.name
    ? String.fromCodePoint(props.name.codePointAt(0)).toLocaleUpperCase()
    : ''
);

const imgError = ref(false);
</script>

<template>
  <img
    v-if="src && !imgError"
    :key="src"
    :src="src"
    :alt="alt"
    class="soona-avatar soona-avatar--img"
    :title="title"
    @error="imgError = true"
    @load="imgError = false"
  />
  <span
    v-else
    class="soona-avatar soona-avatar--initial u-body--heavy"
    :data-type="type"
    :title="title"
    :aria-hidden="!title"
  >
    {{ firstLetter }}
  </span>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-avatar {
  display: block;
  user-select: none;
  border-radius: v-bind('size');
  flex-shrink: 0;
  height: v-bind('size');
  width: v-bind('size');

  &--img {
    object-fit: cover;
  }

  &--initial {
    color: variables.$gray-50;
    background-color: variables.$gray-20;
    border: 0.0625rem solid variables.$gray-30;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(v-bind('size') / 2);

    &[data-type='account'] {
      color: variables.$periwink-blue-50;
      background-color: variables.$periwink-blue-20;
      border: 0.0625rem solid variables.$periwink-blue-30;
    }

    &[data-type='user'] {
      color: variables.$friendly-red-50;
      background-color: variables.$friendly-red-20;
      border: 0.0625rem solid variables.$friendly-red-30;
    }
  }
}
</style>

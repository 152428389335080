<script setup>
import { computed } from 'vue';
import PricingCard from 'src/components/modal-payment-flows/preferred/PricingCard.vue';
import StepLayout from 'src/components/modal-payment-flows/preferred/StepLayout.vue';

const props = defineProps({
  context: {
    type: String,
  },
  planData: {
    type: Array,
  },
  showBackButton: {
    default: true,
    type: Boolean,
  },
  showLearnMoreButton: {
    default: true,
    type: Boolean,
  },
  showMonthlyYearlyToggle: {
    default: false,
    type: Boolean,
  },
  subContext: {
    type: String,
  },
});

const emits = defineEmits(['back', 'close', 'next', 'select-plan']);

const showBackButton = computed(() => props.showBackButton);
const showLearnMoreButton = computed(() => props.showLearnMoreButton);

const handlePlanSelection = plan => {
  emits('select-plan', plan);
  emits('next');
};
</script>

<template>
  <StepLayout
    class="pricing-step"
    :is-two-column="false"
    :show-back-button="showBackButton"
    :show-monthly-yearly-toggle="showMonthlyYearlyToggle"
    @back="emits('back')"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template #heading>
      <slot name="heading" />
    </template>
    <template #subheading>
      <slot name="subheading" />
    </template>
    <div class="pricing-step__plans">
      <PricingCard
        v-for="plan in planData"
        :key="`card-${plan.bulk_credits_title.replace(/\s+/g, '_')}`"
        :context="context"
        :plan="plan"
        :show-learn-more-button="showLearnMoreButton"
        :sub-context="subContext"
        @next="() => handlePlanSelection(plan)"
      />
    </div>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.pricing-step {
  &__plans {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
    margin-bottom: 1.375rem;

    @media (min-width: variables.$screen-sm-min) {
      flex-direction: row;
      height: max-content;
    }
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePackConfigurations } from '@/queries/pack-configurations/usePackConfigurations';
import { useGetHighlightedPacks } from 'src/queries/useGetHighlightedPacks';
import { useRoute } from 'vue-router';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import TrendingPackDialog from '@/components/platform-home/TrendingPackDialog.vue';
import SoonaPackSlider from '@/components/ui_library/SoonaPackSlider.vue';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/composables/useAccount';

const props = defineProps({
  suggestAmazon: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const showDialog = ref(false);
const dialogData = ref(null);
const { currentAccountId } = useMe();
const { account: currentAccount } = useAccount(currentAccountId);

const showAmazonPacks = computed(() => props.suggestAmazon);

const { data: amazonPacks, isSuccess: amazonPacksIsSuccess } =
  usePackConfigurations(
    {
      query: 'amazon',
    },
    { enabled: computed(() => showAmazonPacks.value) }
  );
const { data: recommendedPacks, isSuccess: recommendedPacksIsSuccess } =
  useGetHighlightedPacks(
    {
      industrySlug: computed(
        () => currentAccount.value?.industry?.value?.slug || null
      ),
      numberOfPacks: 12,
    },
    {
      enabled: computed(() => !showAmazonPacks.value),
    }
  );

const packConfigurations = computed(() => {
  if (showAmazonPacks.value) {
    return amazonPacks.value?.data;
  }
  return recommendedPacks.value;
});
const packsLoaded = computed(
  () => amazonPacksIsSuccess.value || recommendedPacksIsSuccess.value
);

const route = useRoute();

const { linkClicked } = useBaseEvents();

const handleCardClick = pack => {
  dialogData.value = pack;
  showDialog.value = true;

  linkClicked({
    context: route.meta.context,
    subContext: 'recommended packs',
    linkLabel: pack.name,
    linkHref: null,
  });
};

const handleDialogClose = () => (showDialog.value = false);
</script>

<template>
  <section v-if="packsLoaded" class="recommended-platform-packs">
    <h3 id="listing-insights-recommended-packs" class="u-title--heavy">
      recommended packs
    </h3>
    <PageContainerOffset>
      <div class="recommended-platform-packs__content-container">
        <SoonaPackSlider
          aria-labelledby="listing-insights-recommended-packs"
          :packs="packConfigurations"
          @handle-card-click="handleCardClick"
        />
      </div>
    </PageContainerOffset>
  </section>
  <TrendingPackDialog
    v-if="showDialog"
    :content-data="dialogData"
    @close="handleDialogClose"
  />
</template>

<style scoped lang="scss">
@use '@/variables';

.recommended-platform-packs {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
</style>

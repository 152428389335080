<script setup>
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { AvoToast20, AvoToast80, Gray20 } from '@/variables.module.scss';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';
import { computed } from 'vue';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
    default: undefined,
  },
  assetId: {
    type: [Number, String],
    required: true,
    default: undefined,
  },
  visibility: {
    required: true,
    type: String,
    default: '',
  },
});

const { addToast } = useSoonaToast();

const accountId = computed(() => props.accountId);

const { mutate, isPending } = useUpdateDigitalAsset(accountId);

const updateVisibility = visibility => {
  mutate(
    {
      assetId: props.assetId,
      body: {
        visibility,
      },
    },
    {
      onError: () => {
        const msgVerb = visibility === 'crew' ? 'hiding' : 'un-hiding';
        addToast(`error ${msgVerb} asset`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};
</script>
<template>
  <div>
    <SoonaFlag
      v-if="visibility === 'crew'"
      :background-color="Gray20"
      title="hidden"
    />
    <SoonaFlag
      v-else
      :background-color="AvoToast20"
      :color="AvoToast80"
      title="delivered"
    />

    <SoonaButton
      v-if="visibility === 'all'"
      element="button"
      variation="secondary-black"
      :loading="isPending"
      :disabled="isPending"
      @on-click="() => updateVisibility('crew')"
    >
      hide
    </SoonaButton>
    <SoonaButton
      v-else
      element="button"
      variation="secondary-black"
      :loading="isPending"
      :disabled="isPending"
      @on-click="() => updateVisibility('all')"
    >
      show
    </SoonaButton>
  </div>
</template>

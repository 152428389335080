<script setup>
import { computed } from 'vue';
import { onKeyStroke } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import WarningIcon from '@/components/svgs/WarningIcon.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';

const props = defineProps({
  message: {
    type: String,
    default:
      'your changes won’t be saved if you close the editor now. close anyway?',
  },
  confirmMessage: {
    type: String,
    default: 'leave & discard',
  },
  // some components still have their own warning component implemented.
  // i'm updating these emit handlers to function props for now until
  // i figure out how all this will work together
  cancel: {
    type: Function,
    default: null,
  },
  continue: {
    type: Function,
    default: null,
  },
});

const mediaEditorStore = useMediaEditorStore();
const { warningParams } = storeToRefs(mediaEditorStore);

const message = computed(() =>
  warningParams.value?.message ? warningParams.value.message : props.message
);

const handleCancel = () => {
  mediaEditorStore.setShowWarning(false);
  if (props.cancel) {
    props.cancel();
  }
};

onKeyStroke('Escape', () => {
  handleCancel();
});

const router = useRouter();
const handleContinue = () => {
  mediaEditorStore.setShowWarning(false);

  if (props.continue) {
    props.continue();
  } else if (warningParams.value.route) {
    mediaEditorStore.setContinueConfirmed(true);
    router.push(warningParams.value.route);
  } else {
    mediaEditorStore.setContinueConfirmed(true);
    mediaEditorStore.setActiveAction(warningParams.value.action);
  }
};
</script>

<template>
  <SoonaDialog @close="handleCancel">
    <template #heading>
      <div class="close-warning__header">
        <WarningIcon class="close-warning__icon" /> unsaved changes
      </div>
    </template>
    {{ message }}
    <template #footer>
      <SoonaButton type="button" variation="tertiary" @click="handleCancel">
        cancel
      </SoonaButton>
      <SoonaButton type="button" variation="primary" @click="handleContinue">
        {{ confirmMessage }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style scoped lang="scss">
@use '@/variables';
.close-warning {
  &__header {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &__icon {
    padding-top: 0.25rem;
  }
}
</style>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { toValue } from 'vue';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<number>} [schedulingPreferenceId]
 * @param {number | Ref<number>} [reservationId]
 * @param {number | Ref<number>} [accountId]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useUpdateSchedulingPreference(
  schedulingPreferenceId,
  reservationId,
  accountId
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.put(
        `/scheduling_preferences/${toValue(schedulingPreferenceId)}`,
        {
          scheduling_preference: body,
        }
      );

      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.schedulingPreference(
            'Reservation',
            reservationId
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.schedulingPreference('Account', accountId),
        }),
      ]);
    },
  });
}

import { computed } from 'vue';

export function usePromoPacks(pack) {
  const metaTikTokPromoPacks = [
    'Meta ads UGC',
    'Meta ads sales and promos',
    'Meta ads customer reviews',
    'TikTok UGC product in action',
    'TikTok UGC testimonial',
    'TikTok UGC unboxing',
  ];

  const isMetaTikTokPromoPack = computed(() => {
    return metaTikTokPromoPacks.includes(pack.value.name);
  });

  return {
    isMetaTikTokPromoPack,
  };
}

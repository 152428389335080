<script setup>
import { computed, toRefs } from 'vue';
import {
  mergeWithAccountRequirements,
  requirementTypes,
} from './requirement-types';
import { useAccountRequirements } from '@/queries/account-requirements/useAccountRequirements';
import AccountRequirementsLayout from './AccountRequirementsLayout.vue';
import AccountRequirementExpansionPanel from './AccountRequirementExpansionPanel.vue';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import RequirementView from './RequirementView.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const { accountId } = toRefs(props);

const {
  data: accountRequirements,
  error: accountRequirementsError,
  isLoading: accountRequirementsLoading,
} = useAccountRequirements(accountId, {
  requirementTypes: Object.keys(requirementTypes),
});

const priorityErrors = usePriorityErrors(accountRequirementsError);

const requirementsDisplay = computed(() => {
  return (
    mergeWithAccountRequirements(accountRequirements.value)
      // remove any requirements that don't have data
      .filter(req => !!req.data)
  );
});
</script>

<template>
  <AccountRequirementsLayout>
    <template #description>
      these guidelines apply to all bookings for this account
    </template>
    <template #heading-row>
      <SoonaLink
        v-if="accountRequirements && requirementsDisplay.length > 0"
        :to="`/account/${accountId}/crew-tools`"
        variation="black"
      >
        manage <SoonaIcon name="arrow-right" />
      </SoonaLink>
    </template>
    <SoonaSkeleton v-if="accountRequirementsLoading" style="height: 5rem" />
    <SoonaError
      v-if="priorityErrors"
      :priority-errors="priorityErrors"
      no-margin
    />
    <template v-if="accountRequirements">
      <AccountRequirementExpansionPanel
        v-for="accReq in requirementsDisplay"
        :key="accReq.displayData.requirementType"
        :requirement-type="accReq.displayData.requirementType"
        :updated-at="accReq.data?.updated_at"
      >
        <RequirementView :account-requirement="accReq.data" />
      </AccountRequirementExpansionPanel>
      <div
        v-if="requirementsDisplay.length <= 0"
        class="account-requirements__no-data"
      >
        <SoonaButton
          element="router-link"
          :to="`/account/${accountId}/crew-tools`"
          variation="secondary-black"
          size="medium"
        >
          add account requirements
        </SoonaButton>
      </div>
    </template>
  </AccountRequirementsLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.account-requirements {
  &__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    background: variables.$gray-10;
    border-radius: 0.625rem;
  }
}
</style>

<script setup>
import { computed, provide } from 'vue';
import ImageQualityAccordion from '@/components/user/anytime/listing_insights/listing_detail/accordions/image_quality/ImageQualityAccordion.vue';
import GalleryCountAccordion from '@/components/user/anytime/listing_insights/listing_detail/accordions/gallery_count/GalleryCountAccordion.vue';
import VideoAccordion from '@/components/user/anytime/listing_insights/listing_detail/accordions/video/VideoAccordion.vue';
import VisualMixAccordion from '@/components/user/anytime/listing_insights/listing_detail/accordions/visual_mix/VisualMixAccordion.vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  accountId: {
    type: [String, Number],
  },
  displayVideoAccordion: {
    type: Boolean,
    default: true,
  },
  listingActions: {
    type: Array,
    default: () => [],
  },
  listingData: {
    type: Object,
    required: true,
  },
  platform: {
    type: String,
    required: true,
    validator: function (value) {
      return ['Amazon', 'Shopify'].includes(value);
    },
  },
});

const emit = defineEmits(['refreshListing', 'showSubscriptionsDialog']);

const refreshListing = () => {
  emit('refreshListing');
};

const listingData = computed(() => props.listingData);
const listingActions = computed(() => props.listingActions);

// listing actions module
const route = useRoute();
const externalProductId = computed(
  () => route.params.externalProductId ?? null
);
const integrationId = computed(() => route.params.integrationId ?? null);
provide('externalProductId', externalProductId);
provide('integrationId', integrationId);

// listing data module
const computeListingData = (rule, ruleGroup) => {
  return computed(() => {
    return {
      ...listingData.value?.score_breakdown.find(x => x.rule === rule),
      rules_result: listingData.value?.rule_results.find(
        x => x.rule_group === ruleGroup
      ),
    };
  });
};

const imageQualityData = computeListingData('image quality', 'image quality');

const galleryCountData = computeListingData('gallery count', 'gallery count');

const visualMixData = computeListingData('visual mix', 'visual content mix');

const videoData = computed(() => {
  return {
    rules_result: listingData.value?.rule_results.find(
      x => x.rule_group === 'video count'
    ),
  };
});

// listing actions module
const getRuleSlugs = listingData => {
  return listingData.rules_result.rules.map(rule => rule.rule_slug) ?? [];
};

const findApplicableListingActions = (ruleSlugs, listingActions) => {
  return (
    listingActions.filter(action =>
      ruleSlugs.includes(action.listing_rule_slug)
    ) ?? []
  );
};

const useApplicableListingActions = listingData => {
  const ruleSlugs = getRuleSlugs(listingData);

  return findApplicableListingActions(ruleSlugs, listingActions.value);
};
</script>

<template>
  <div class="listing-optimize__wrapper">
    <div class="listing-optimize__accordion">
      <ImageQualityAccordion
        :account-id="accountId"
        :data="imageQualityData"
        :actions="useApplicableListingActions(imageQualityData)"
        :platform="platform"
        :assets="listingData.assets"
        @refresh-listing="refreshListing"
        @show-subscriptions-dialog="$emit('showSubscriptionsDialog', $event)"
      />
      <GalleryCountAccordion
        :data="galleryCountData"
        :assets="listingData.assets"
        :platform="platform"
      />
      <VisualMixAccordion
        :account-id="accountId"
        :data="visualMixData"
        :actions="useApplicableListingActions(visualMixData)"
        :assets="listingData.assets"
        :platform="platform"
        @show-subscriptions-dialog="$emit('showSubscriptionsDialog', $event)"
      />
      <VideoAccordion
        v-if="displayVideoAccordion"
        :platform="platform"
        :data="videoData"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-optimize {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__accordion {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>

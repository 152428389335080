<script setup>
import { computed } from 'vue';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import { useCapability } from '@/composables/useCapability';
import { convertToMMDDYY } from '@/lib/date-formatters';
import { packageStatuses } from '@/lib/inventory/constants';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  accountId: { type: String, required: true },
  item: {
    type: Object,
    required: true,
  },
  productName: { type: String, required: true },
});

const accountId = computed(() => props.accountId);

const { hasCapability: hasSoonaStaffCapability } = useCapability({
  capability: 'soona_staff',
});

const isVariant = computed(() => props.item.catalog_item.parent_id !== null);

const displayVariantName = computed(
  () => isVariant.value && props.productName !== props.item.catalog_item.name
);
</script>

<template>
  <SoonaItemCard
    :aria-labelledby="`inventory-item-${item.id}-heading`"
    :image-url="item.image_url"
    :inventory-count="item?.quantity ?? 1"
  >
    <template #content>
      <div class="inventory-item">
        <div v-if="displayVariantName" class="inventory-item__variant">
          <SoonaIcon
            name="reply"
            size="small"
            class="inventory-item__variant--icon"
          />
          <p class="u-label--heavy">
            {{ item.catalog_item.name.substring(productName.length + 1) }}
          </p>
        </div>
        <dl>
          <div
            v-if="item.inventory_package.in_studio_date"
            class="inventory-item__data"
          >
            <dt>received:</dt>
            <dd>
              {{ convertToMMDDYY(item.inventory_package.in_studio_date) }}
            </dd>
          </div>
          <div v-if="hasSoonaStaffCapability" class="inventory-item__data">
            <dt>package ID:</dt>
            <dd>
              <SoonaLink
                :to="{
                  path: `/account/${accountId}/package/${item.inventory_package.id}/edit`,
                  query: { returnToPreviousPage: true },
                }"
              >
                {{ item.inventory_package.id }}
              </SoonaLink>
            </dd>
          </div>
          <div class="inventory-item__data">
            <dt>package status:</dt>
            <dd>{{ packageStatuses[item.inventory_package.status] }}</dd>
          </div>
          <div
            v-if="item.inventory_package.storage_location"
            class="inventory-item__data"
          >
            <template v-if="hasSoonaStaffCapability">
              <dt>storage location:</dt>
              <dd>
                {{ item.inventory_package.storage_location }}
                ({{ item.inventory_package.location_short_name }})
              </dd>
            </template>
            <template v-else>
              <dt>studio location:</dt>
              <dd>
                {{ item.inventory_package.location_name }}
              </dd>
            </template>
          </div>

          <div class="inventory-item__data">
            <dt>notes:</dt>
            <dd>{{ item.description }}</dd>
          </div>
        </dl>
      </div>
    </template>
  </SoonaItemCard>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.inventory-item {
  padding: 0.75rem 1rem;

  &__data {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    gap: 0.25rem;
  }

  dt {
    @include variables_fonts.u-label--heavy;
  }

  dd {
    @include variables_fonts.u-small--regular;
  }

  &__variant {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    color: variables.$gray-60;

    &--icon {
      rotate: 180deg;
    }
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getProServiceProfile } from '../api/proServiceProfiles';

/**
 *
 * @param {Number} [proServiceProfileId]
 * @param {AbortSignal} [signal]
 */
export function useGetProServiceProfile(proServiceProfileId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.proServiceProfile(proServiceProfileId),
    queryFn: ({ signal }) =>
      getProServiceProfile(unref(proServiceProfileId), signal),
    ...queryOptions,
  });
}

import { useMutation } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';

/**
 * @returns {UseMutationReturnType<any, unknown, void, unknown>}
 */
export function useMokkerRedirect() {
  return useMutation({
    mutationFn: async assetUuid => {
      const response = await http.get(
        `/mokker_digital_assets/${assetUuid}/redirect`
      );
      return response.data;
    },
  });
}

import * as types from 'src/store/mutation-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const state = {
  suggestedSlots: [],
  loadingSuggestedSlots: false,
  selectedDate: null,
  selectedTime: null,
  selectedBay: null,
  selectedPhotographer: null,
};

const getters = {
  remainingValidSlots(state) {
    const dateValue = (date, timezone) =>
      dayjs(date).tz(timezone).format('YYYY-M-D');

    const timeValue = (date, timezone) =>
      dayjs(date).tz(timezone).format('h:mm a');

    return state.suggestedSlots.filter(slot => {
      let validDateInSlot =
        state.selectedDate?.date ===
          dateValue(slot.start, state.selectedDate?.timezone) ||
        state.selectedDate === null;
      let validTimeInSlot =
        state.selectedTime?.time ===
          timeValue(slot.start, state.selectedTime?.timezone) ||
        state.selectedTime === null;
      let validBayInSlot =
        state.selectedBay?.id === slot.bay.id || state.selectedBay === null;
      let validPhotographerInSlot =
        state.selectedPhotographer?.id === slot.photographer.id ||
        state.selectedPhotographer === null;
      // if slot matches selectedBay (or if no bay selected) and
      // if slot matches selectedPhotographer (or if no creative selected) and
      // if slot matches selectedTime (or if no time selected) and
      // if slot matches selectedDate (or if no date selected)
      // then slot is still available and valid
      return (
        validDateInSlot &&
        validTimeInSlot &&
        validBayInSlot &&
        validPhotographerInSlot
      );
    });
  },
};

const mutations = {
  [types.SET_SUGGESTED_APPOINTMENTS](state, appointments) {
    state.suggestedSlots = appointments;
  },
  [types.SET_SUGGESTED_APPOINTMENTS_LOADING](state, loading) {
    state.loadingSuggestedSlots = loading;
  },
  [types.RESET_SUGGESTED_SLOTS](state) {
    state.suggestedSlots = [];
  },
  [types.SET_GENIE_DATE](state, date) {
    state.selectedDate = date;
  },
  [types.SET_GENIE_TIME](state, time) {
    state.selectedTime = time;
  },
  [types.SET_GENIE_BAY](state, bay) {
    state.selectedBay = bay;
  },
  [types.SET_GENIE_PHOTOGRAPHER](state, photographer) {
    state.selectedPhotographer = photographer;
  },
  [types.RESET_SOONA_GENIE](state) {
    state.suggestedSlots = [];
    state.selectedDate = null;
    state.selectedTime = null;
    state.selectedBay = null;
    state.selectedPhotographer = null;
  },
};

const actions = {
  resetSoonaGenie({ commit }) {
    commit(types.RESET_SOONA_GENIE);
  },
  loadSuggestedSlots({ commit, rootState }, reservationId) {
    commit(types.SET_SUGGESTED_APPOINTMENTS_LOADING, true);
    return this.http
      .get(
        `reservations/${reservationId}/suggested_appointments.json?duration=${rootState.reservations.duration}`
      )
      .then(response => {
        commit(types.SET_SUGGESTED_APPOINTMENTS_LOADING, false);
        commit(types.SET_SUGGESTED_APPOINTMENTS, response.data);
      });
  },
  selectDate({ commit }, date) {
    commit(types.SET_GENIE_DATE, date);
  },
  selectTime({ commit }, time) {
    commit(types.SET_GENIE_TIME, time);
  },
  selectBay({ commit }, bay) {
    commit(types.SET_GENIE_BAY, bay);
  },
  selectPhotographer({ commit }, photographer) {
    commit(types.SET_GENIE_PHOTOGRAPHER, photographer);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div
    v-show="isLoading"
    class="soona-loading"
    :class="{ 'soona-loading--contained': isContained }"
    :style="{ backgroundColor: bc }"
    data-cypress="soona-loading"
    v-bind="$attrs"
  >
    <div class="soona-loading__content">
      <div class="soona-loading__circle" />
      <p class="soona-loading__text">
        {{ loadingText }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SoonaLoading',
  props: {
    loadingText: {
      type: String,
    },
    isContained: {
      default: false,
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isDark: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    bc() {
      return this.isDark ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)';
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  user-select: none;
  width: 100vw;
  z-index: 300;

  &--contained {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__circle {
    animation: circleanimation 0.45s linear infinite;
    border: 0.125rem solid transparent;
    border-left-color: variables.$friendly-red-50;
    border-radius: 100%;
    height: 3.125rem;
    width: 3.125rem;
  }

  &__text {
    @include variables_fonts.u-label--heavy;

    padding-top: 1.5rem;
  }

  @keyframes circleanimation {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
}
</style>

<template>
  <div class="u-container reschedule">
    <SoonaBackButton @click="() => router.go(-1)" />
    <SoonaLoading v-if="!currentReservation.id" is-loading />
    <div v-else-if="!customerReschedulingFlag || isNotAbleToReschedule">
      <div class="reschedule-error soona-container">
        <div class="error-emoji">🙊</div>
        <p class="error-text" data-cypress="reschedule-error-text">
          {{ errorText }}
        </p>
        <SoonaButton
          element="a"
          :href="soonaSupportFormUrl"
          target="_blank"
          variation="tertiary"
        >
          contact us for support
        </SoonaButton>
      </div>
    </div>
    <div v-else class="reschedule-view">
      <h1 class="title has-text-centered">reschedule your shoot!</h1>
      <div class="reschedule-content">
        <shoot-summary
          v-if="!!currentReservation.id"
          :reservation="currentReservation"
          :account="currentReservation.account"
          :timezone="timezone"
          :on-timezone-change="handleChangeTimezone"
        />
        <div v-if="!!currentReservation.id" class="calendar-container">
          <AppointmentSelection
            :reservation="currentReservation"
            :current-appointment="currentReservation.start"
            :last-available-date="
              currentReservation.last_available_reschedule_date
            "
            :selected-time-slot="selectedTimeSlot"
            :scheduled-time="selectedTimeDisplay"
            :is-busy="loading || submitting"
            :is-vertical="true"
            @time-selected="handleTimeClick"
          />
          <SoonaButton
            :disabled="disabled"
            :is-loading="submitting"
            data-cypress="button-appointment-confirm"
            @on-click="handleNextClick"
          >
            confirm
          </SoonaButton>
          <SoonaError v-if="error">{{ error }}</SoonaError>
        </div>
      </div>
      <p class="has-text-centered">
        need a different time?
        <a :href="soonaSupportFormUrl" class="soona-link" target="_blank"
          >contact our studio for more availability</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import { toRef } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useReservation } from 'src/composables/useReservation';
import { useFlag } from '@/composables/useFlag';
import { convertToHHMM12, timeZoneAbbr } from '@/lib/date-formatters';
import AppointmentSelection from 'src/components/shared/AppointmentSelection.vue';
import ShootSummary from 'src/components/ShootSummary.vue';
import SoonaBackButton from 'src/components/ui_library/SoonaBackButton.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';

export default {
  name: 'Reschedule',
  components: {
    AppointmentSelection,
    ShootSummary,
    SoonaBackButton,
    SoonaButton,
    SoonaError,
    SoonaLoading,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const customerReschedulingFlag = useFlag('toaster-customer-rescheduling');

    const soonaSupportFormUrl = import.meta.env.VITE_SOONA_SUPPORT_FORM_URL;

    const { isLessThanTwoBusinessDaysBeforeShoot, scheduledTime } =
      useReservation(toRef(() => route.params.reservationId));

    return {
      customerReschedulingFlag,
      isLessThanTwoBusinessDaysBeforeShoot,
      router,
      scheduledTime,
      soonaSupportFormUrl,
    };
  },
  data() {
    return {
      availableTimeSlots: [],
      selectedTimeDisplay: undefined,
      selectedTimeSlot: undefined,
      timezone: undefined,
      error: undefined,
      submitting: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('reservation', [
      'currentReservation',
      'isReschedulable',
      'isBelowMaxReschedules',
      'hasOriginalStartAndEnd',
    ]),
    ...mapGetters('account', ['timeZonesList', 'guessedTimeZone']),
    routeName() {
      return this.$route.name;
    },
    disabled() {
      return this.submitting || !this.selectedTimeSlot || this.error;
    },
    isNotAbleToReschedule() {
      return (
        this.currentReservation.id &&
        (!this.isReschedulable ||
          !this.hasOriginalStartAndEnd ||
          !this.isBelowMaxReschedules ||
          this.isLessThanTwoBusinessDaysBeforeShoot)
      );
    },
    errorText() {
      if (!this.isLessThanTwoBusinessDaysBeforeShoot) {
        return 'we want to ensure your shoot time works for you and your team! if you received a time that does not work for you please contact us for support. in the event that you need to reschedule we require 2 business days notice.';
      } else if (this.customerReschedulingFlag && !this.isBelowMaxReschedules) {
        return 'you have reached the maximum number of reschedules for this shoot.';
      } else if (
        this.customerReschedulingFlag &&
        !this.hasOriginalStartAndEnd
      ) {
        return 'hey! looks like this link is no longer valid. check your inbox for an updated link.';
      } else if (this.isLessThanTwoBusinessDaysBeforeShoot) {
        return 'you cannot reschedule your shoot within 2 business days of your original booking time.';
      } else if (this.customerReschedulingFlag && !this.isReschedulable) {
        return 'this shoot is not reschedulable.';
      } else {
        return 'something went wrong.';
      }
    },
  },
  beforeMount() {
    this.timezone = this.guessedTimeZone;
  },
  mounted() {
    this.loadReservation(this.$route.params.reservationId).then(() => {
      this.timezone =
        this.currentReservation.account.preferred_timezone || this.timezone;
      this.selectedTimeSlot = this.currentReservation.start;
    });
  },
  methods: {
    ...mapActions('reservation', [
      'loadReservation',
      'loadBayAvailability',
      'rescheduleReservation',
    ]),
    handleDayClick(day) {
      this.error = undefined;
      this.loading = true;
      this.loadBayAvailability({
        bookableSpaceId: this.currentReservation.bookable_space_ids[0],
        date: day,
        reservationId: this.currentReservation.id,
      })
        .then(timeslots => {
          this.availableTimeSlots = timeslots;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleTimeClick(timeSlot) {
      this.selectedTimeSlot = timeSlot;
      this.selectedTimeDisplay =
        convertToHHMM12(timeSlot, this.currentReservation.location.timezone) +
        ' ' +
        timeZoneAbbr(this.currentReservation.location.timezone);
    },
    handleNextClick() {
      if (this.disabled) return;

      this.submitting = true;
      this.rescheduleReservation({
        start: this.selectedTimeSlot,
        reservationId: this.currentReservation.id,
      }).then(([, error]) => {
        if (error) {
          this.submitting = false;
          this.error = error;
        } else {
          this.submitting = false;
          this.$router.push({ name: 'reschedule-confirmed' });
        }
      });
    },
    handleChangeTimezone(timezone) {
      this.timezone = timezone;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.reschedule-view {
  max-width: 996px;
  width: 100%;
  margin: auto;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    padding: 0 1rem;
    margin: 4rem auto;
  }

  .title {
    margin-bottom: 2rem;
  }

  .reschedule-content {
    display: flex;
    margin-bottom: 2rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .calendar-container {
      width: 50%;
      padding-left: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media only screen and (max-width: 768px) {
        width: 100%;
        padding-left: 0;
        padding-top: 2.25rem;
      }
    }
  }
}

.reschedule-error {
  justify-content: center;
  align-items: center;

  .error-emoji {
    background: variables.$periwink-blue-40 0% 0% no-repeat padding-box;
    border-radius: 50%;
    font-size: 75px;
    margin-right: 1.5rem;
    margin-left: 0.5rem;
    margin-bottom: 41px;
    height: 157px;
    width: 157px;
    text-align: center;
    vertical-align: middle;
    line-height: 157px;
  }

  .error-text {
    margin-bottom: 0.75rem;
    max-width: 50ch;
    text-align: center;
  }
}
</style>

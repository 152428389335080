import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {import('vue').MaybeRefOrGetter<"accounts" | "reservations" | "digital_assets">} subjectType
 * @param {import('vue').MaybeRefOrGetter<number>} subjectId
 */
export function useDeleteNote(subjectType, subjectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ noteId }) => {
      const response = await http.delete(
        `${toValue(subjectType)}/${toValue(subjectId)}/notes/${toValue(noteId)}`
      );
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.notes(subjectType, subjectId),
      });
    },
  });
}

<script setup>
import { ref, computed, watch, nextTick, onMounted } from 'vue';
import { useMediaQuery } from '@vueuse/core';

const props = defineProps({
  images: {
    type: Array,
    required: true,
  },
  activeImageIndex: {
    type: Number,
    required: true,
  },
  imageGap: {
    type: String,
    default: 'medium',
    validator: value => ['small', 'medium', 'large'].includes(value),
  },
  visualWrapping: {
    type: Boolean,
    default: false,
  },
});

const track = ref(null);

const calculatedImages = computed(() => {
  return props.visualWrapping
    ? [props.images[props.images.length - 1], ...props.images, props.images[0]]
    : props.images;
});

const calculatedActiveImageIndex = computed(() => {
  return props.visualWrapping
    ? props.activeImageIndex + 1
    : props.activeImageIndex;
});

const imageGapRem = computed(() => {
  switch (props.imageGap) {
    case 'small':
      return '1rem';
    case 'medium':
      return '2rem';
    case 'large':
      return '3rem';
    default:
      return '0';
  }
});

const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');

const scrollToElement = () => {
  if (track.value) {
    const element = track.value.querySelector(
      `[data-index="${calculatedActiveImageIndex.value}"]`
    );
    nextTick(() => {
      if (element) {
        element.scrollIntoView({
          behavior: prefersReducedMotion.value ? 'auto' : 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    });
  }
};

const waitForImagesToLoad = () => {
  const domImages = track.value.querySelectorAll('img');
  return Promise.all(
    Array.from(domImages).map(
      img =>
        new Promise(resolve => {
          if (img.complete) {
            resolve();
          } else {
            img.addEventListener('load', resolve);
            img.addEventListener('error', resolve);
          }
        })
    )
  );
};

watch(calculatedActiveImageIndex, () => {
  scrollToElement();
});

onMounted(async () => {
  await waitForImagesToLoad();
  scrollToElement();
});
</script>

<template>
  <div ref="track" class="scroller-track" aria-hidden="true">
    <img
      v-for="(image, index) in calculatedImages"
      :key="index"
      class="scroller-item"
      :data-index="index"
      :class="{ active: calculatedActiveImageIndex === index }"
      :src="image"
      alt=""
    />
  </div>
</template>

<style scoped lang="scss">
.scroller-track {
  display: flex;
  --image-gap: v-bind('imageGapRem');
  gap: var(--image-gap);
  width: 100%;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  align-items: center;
}

.scroller-item.active {
  height: 100%;
}

.scroller-item {
  height: 66%;
  width: auto;
  object-fit: cover;
  border-radius: 1rem;
  scroll-snap-align: center;
}
</style>

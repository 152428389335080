<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  modelValue: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const disabled = computed(() => props.disabled);

const isEditMode = ref(false);
const editState = ref(['']);

watchEffect(() => {
  editState.value = props.modelValue.concat(['']);
});

function openEdit() {
  isEditMode.value = true;
}

function done() {
  isEditMode.value = false;
  emit('update:modelValue', editState.value.slice(0, -1));
}

function updateItem(newValue, index) {
  const updated = editState.value.map((oldValue, i) => {
    if (i === index) return newValue;
    return oldValue;
  });

  if (updated[updated.length - 1]) {
    updated.push('');
  }

  editState.value = updated;
}

function removeItem(index) {
  editState.value = editState.value.filter((_, i) => i !== index);
}
</script>

<template>
  <div class="soona-list-editor">
    <fieldset v-if="isEditMode" class="soona-list-editor__fieldset">
      <legend>
        <slot name="legend" />
      </legend>
      <p
        v-if="$slots.description"
        class="soona-list-editor__fieldset__description"
      >
        <slot name="description" />
      </p>
      <div
        v-for="(item, index) in editState"
        :key="index"
        class="soona-list-editor__fieldset__item-row"
      >
        <SoonaTextfield
          class="textfield"
          type="text"
          :model-value="item"
          :name="`list-item-${index}`"
          :label="`item ${index + 1}`"
          :hide-label="true"
          placeholder="add item"
          @update:model-value="updateItem($event, index)"
        />
        <button
          v-if="index !== editState.length - 1"
          class="soona-list-editor__remove-button"
          title="remove item"
          @click="removeItem(index)"
        >
          <span class="u-visually-hidden">remove item {{ index + 1 }}</span>
          <SoonaIcon name="xmark" />
        </button>
      </div>
    </fieldset>

    <ul v-else class="soona-list-editor__list">
      <li v-for="item in modelValue" :key="item">{{ item }}</li>
    </ul>

    <SoonaButton
      v-if="isEditMode"
      variation="secondary-gray"
      size="medium"
      @on-click="done"
    >
      done
    </SoonaButton>
    <SoonaButton
      v-else
      variation="secondary-gray"
      size="medium"
      :disabled="disabled"
      @on-click="openEdit"
    >
      edit
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-list-editor {
  &__fieldset {
    legend {
      @include variables_fonts.u-label--heavy;

      color: variables.$black-default;
      margin-bottom: 0.25rem;
    }

    &__description {
      @include variables_fonts.u-label--regular;

      color: variables.$gray-60;
      margin-bottom: 0.25rem;
    }

    &__item-row {
      display: flex;
      gap: 0.5rem;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .textfield {
        flex: 1 1 auto;
        padding: 0;
      }
    }
  }

  &__list {
    list-style: disc;
    padding-left: 1rem;
  }

  &__fieldset,
  &__list {
    margin-bottom: 1.5rem;
  }

  &__remove-button {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    color: variables.$gray-60;
    background-color: variables.$white-default;
    border: none;

    &:hover {
      background-color: variables.$gray-20;
    }

    &:active {
      background-color: variables.$gray-30;
    }
  }
}
</style>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import EnrollConfirm from '../fast_pass/EnrollConfirm.vue';
import PackageTransfer from './PackageTransfer.vue';
import { useCapabilities } from 'src/composables/useCapabilities';
import { useTransferPackageRequirements } from 'src/queries/useTransferPackageRequirements';
import InventoryPackageList from './InventoryPackageList.vue';
import ManageReturnAddress from './return-address/ManageReturnAddress.vue';
import BaseSubscription from '@/components/subscriptions/BaseSubscription.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();
const accountId = computed(() => Number(route.params?.accountId));
const account = computed(() => store.state.account);

onMounted(() => {
  store.dispatch('inventoryPackages/loadPackages', {
    accountId: accountId.value,
  });
  store.dispatch('inventoryPackages/loadTransferPackages', {
    accountId: accountId.value,
  });
});

const capabilitiesRef = ref([
  { capability: 'soona_staff' },
  { capability: 'ft_soona_staff' },
]);
const capabilities = useCapabilities(capabilitiesRef);

const headingCopy = computed(() => {
  return 'FAST PASS STORAGE';
});

/* transfer packages */
const { data: transferPackageRequirements } = useTransferPackageRequirements({
  accountId,
});

const transferPackages = computed(
  () => transferPackageRequirements.value || []
);

const activeTransferPackages = computed(() =>
  transferPackages.value.filter(
    p => p.status === 'pending' || p.status === 'initiated'
  )
);

/* package returning */
const showReturnSelect = ref(false);
const packagesToReturn = ref([]);

const toggleBulkSelect = () => {
  showReturnSelect.value = true;
};
const cancelReturnSelect = () => {
  showReturnSelect.value = false;
};

const stagePackagesForReturn = payload =>
  store.dispatch('inventoryPackages/stagePackagesForReturn', payload);

const submitReturnChoices = () => {
  stagePackagesForReturn({
    inventoryPackageIds: packagesToReturn.value,
  });
  return router.push({ name: 'return-shipment' });
};

const markedForReturn = inventoryPackage => {
  return packagesToReturn.value.includes(inventoryPackage.id);
};

const selectForReturn = inventoryPackage => {
  if (markedForReturn(inventoryPackage)) {
    packagesToReturn.value = packagesToReturn.value.filter(
      p => p !== inventoryPackage.id
    );
  } else {
    packagesToReturn.value = [...packagesToReturn.value, inventoryPackage.id];
  }
};
</script>

<template>
  <div id="dashboard-account-inventory">
    <template v-if="capabilities.soona_staff.hasCapability">
      <PackageTransfer
        v-for="transfer in activeTransferPackages"
        :key="transfer.id"
        :transfer-package="transfer"
      />
    </template>
    <BaseSubscription :account-id="accountId">
      <template #loading />
      <template #success="{ canEnrollFastPass, canCancelFastPass }">
        <template v-if="canEnrollFastPass || canCancelFastPass">
          <EnrollConfirm class="enroll-confirm-card" :account="account" />
        </template>
      </template>
    </BaseSubscription>

    <div class="return-address-card">
      <ManageReturnAddress :account-id="accountId" />
    </div>

    <div class="pending-packages">
      <div class="inventory-header ml-s mr-s">
        <div class="mb-s">
          <h2>PENDING ITEMS</h2>
          <p>
            soona will process your items 5 business days after your shoot
            wraps. edit your preferences on pending items below.
          </p>
        </div>
        <span>
          <SoonaButton
            v-if="!showReturnSelect"
            size="medium"
            @click="toggleBulkSelect"
          >
            select
          </SoonaButton>
          <span v-else class="action-btns">
            <SoonaButton
              size="medium"
              variation="tertiary"
              @click="cancelReturnSelect"
            >
              cancel
            </SoonaButton>
            <SoonaButton
              :disabled="packagesToReturn.length < 1"
              size="medium"
              @click="submitReturnChoices"
            >
              return
            </SoonaButton>
          </span>
        </span>
      </div>

      <InventoryPackageList
        :account-id="accountId"
        :filtered-status="
          capabilities.ft_soona_staff.hasCapability
            ? 'pending_ft_soona_staff'
            : 'pending'
        "
        :show-return-select="showReturnSelect"
        :select-for-return="selectForReturn"
        :marked-for-return="markedForReturn"
      />
    </div>

    <InventoryPackageList
      class="fast-pass-packages"
      :account-id="accountId"
      filtered-status="fast_pass"
      :show-return-select="showReturnSelect"
      :select-for-return="selectForReturn"
      :marked-for-return="markedForReturn"
    >
      <template #heading>
        <h2>{{ headingCopy }}</h2>
      </template>
    </InventoryPackageList>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

#dashboard-account-inventory {
  /* height of the nav bar, so this is positioned below it */
  scroll-margin-top: 81px;
}

.pending-packages {
  .inventory-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h2 {
      font-weight: 900;
      display: inline-block;
    }

    p {
      font-size: 14px;
    }
  }
}

.fast-pass-packages h2 {
  background-color: variables.$bright-blue;
  padding: 0.625rem 1.25rem;
  margin: 1.25rem 0 0 0.25rem;
  color: variables.$white-default;
  font-weight: 900;
  display: inline-block;
}

.enroll-confirm-card,
.return-address-card {
  margin: 0.25rem 0.25rem 1rem;
}

.action-btns {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
}
</style>

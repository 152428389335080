<template>
  <CompleteProfileCard
    class="brand-guide"
    :disabled="disabled"
    @next="saveBrandGuide"
    @skip="skipCard"
  >
    <template #heading>upload your brand guidelines</template>
    <div v-if="!account.guide_url" class="uploader-wrapper">
      <SoonaUpload
        class-overide="action-btn"
        :upload-complete="handleUploadComplete"
        :hide-icon="false"
        :is-multiple="false"
        label="UPLOAD FILE"
      />
      <beat-loader
        v-if="loading"
        color="#FFFFFF"
        size="10px"
        class="avatar-loader"
      />
    </div>
    <div v-else class="uploader-wrapper">
      <p class="u-label--small">upload successful!</p>
    </div>
  </CompleteProfileCard>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';

export default {
  name: 'BrandGuide',
  components: { CompleteProfileCard, SoonaUpload },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({ account: state => state.account }),
    disabled() {
      return !this.account.guide_url;
    },
  },
  methods: {
    ...mapActions('account', ['createBrandGuideFromUpload', 'updateAccount']),
    handleUploadComplete: function (blob) {
      this.avatarLoading = true;
      this.createBrandGuideFromUpload({
        accountId: this.account.id,
        blob: blob,
      });
    },
    ready() {
      this.loading = false;
    },
    async saveBrandGuide() {
      const info = {
        brand_guide: {
          complete: true,
        },
      };
      await this.updateAccount({ accountParams: info });
    },
    async skipCard() {
      let info = {
        brand_guide: {
          skip: true,
        },
      };
      await this.updateAccount({ accountParams: info });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.brand-guide {
  .uploader-wrapper {
    align-items: center;
    background: variables.$gray-10;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 1rem;
    width: 100%;

    .soona-uploader {
      height: auto;
    }
  }

  .avatar-loader {
    left: 2.6875rem;
    position: absolute;
    top: 3.6875rem;
  }

  .action-btn {
    background: variables.$periwink-blue-50;
    border-radius: 0.375rem;
    box-shadow: variables.$elevation-1;
    color: variables.$white-default;
    display: flex;
    padding: 1rem;
  }
}
</style>

<template>
  <SoonaDialog v-if="pickerIsOpen" @close="closePicker">
    <template #heading>add a stored package</template>
    <SoonaLoading
      :is-loading="isLoading"
      :is-dark="false"
      :loading-text="'please wait'"
    />
    <div v-if="pickablePackages.length >= 1">
      <p>select a package to add to your booking from your account inventory</p>
      <a
        v-for="(inventoryPackage, index) in pickablePackages"
        :id="'p' + index"
        :key="'p' + index"
        @click="
          addPackageToReservation({
            accountId: accountId,
            packageId: inventoryPackage.id,
            reservationId: reservationId,
          })
        "
      >
        <inventory-picker-card :inventory-package="inventoryPackage" />
      </a>
    </div>
    <p v-else>no packages available</p>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
    </template>
  </SoonaDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import InventoryPickerCard from './InventoryPickerCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';

export default {
  name: 'InventoryPicker',
  components: {
    InventoryPickerCard,
    SoonaButton,
    SoonaDialog,
    SoonaLoading,
  },
  props: {
    accountId: Number,
    closePicker: Function,
    pickerIsOpen: Boolean,
    reservationId: Number,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      pickablePackages: state => state.inventoryPackages.pickablePackages,
    }),
  },
  watch: {
    async pickerIsOpen(newValue) {
      if (!newValue) return;
      this.isLoading = true;
      await this.loadPickablePackages({
        accountId: this.accountId,
        skipReservationId: this.reservationId,
      });
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions('inventoryPackages', [
      'loadPickablePackages',
      'addPackageToReservation',
    ]),
  },
};
</script>

import { toValue } from 'vue';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useConfirmSuggested(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.put(
        `/reservations/${toValue(reservationId)}/confirm_suggested`
      );

      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(reservationId),
      });
    },
  });
}

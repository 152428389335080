<script setup>
import { computed } from 'vue';
import ProductIntegration from './ProductIntegration.vue';
import { useIntegrations } from '@/composables/useIntegrations';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  integrationsToDisplay: {
    type: [Array, String],
    default: 'all',
  },
});

const emits = defineEmits(['openRemoveAmazonDataDialog', 'close']);

const accountId = computed(() => props.accountId);

const { actionableIntegrations } = useIntegrations(accountId);

const integrations = computed(() => actionableIntegrations.value ?? []);

const integrationsToDisplay = computed(() => {
  if (props.integrationsToDisplay === 'all') {
    return integrations.value;
  } else {
    return integrations.value.filter(integration =>
      props.integrationsToDisplay.includes(integration.type)
    );
  }
});
</script>

<template>
  <div v-if="integrations.length > 0" class="product-integrations">
    <ProductIntegration
      v-for="integration in integrationsToDisplay"
      :key="integration.id"
      :account-id="accountId"
      :integration="integration"
      @open-remove-amazon-data-dialog="
        emits('openRemoveAmazonDataDialog', $event)
      "
      @close="emits('close')"
    />
  </div>
  <div v-else class="no-product-integrations">
    <p>no connected accounts</p>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.product-integrations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.no-product-integrations {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: variables.$gray-20;
  p {
    color: variables.$gray-80;
  }
}
</style>

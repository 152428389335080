import { unref } from 'vue';
import * as Sentry from '@sentry/browser';
const LibPhoneNumber = () => import('libphonenumber-js');

export function usePhoneHelper() {
  async function parsedPhoneNumber(phoneNumberRef) {
    const phoneNumber = unref(phoneNumberRef);
    if (!phoneNumber) {
      return '';
    }
    try {
      return (await LibPhoneNumber()).parsePhoneNumber(phoneNumber);
    } catch (error) {
      Sentry.captureException(new Error('error importing libphonenumber-js'), {
        extra: { error },
      });
      console.error(error);

      return '';
    }
  }

  async function isPhoneNumberValid(phoneNumberRef) {
    const phoneNumber = unref(phoneNumberRef);
    if (!phoneNumber) {
      return false;
    }
    try {
      const parsed = await parsedPhoneNumber(phoneNumber);
      if (parsed.isValid()) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  async function numberWithCountryCode(phoneNumberRef) {
    const phoneNumber = unref(phoneNumberRef);
    if (!phoneNumber) {
      return '';
    }
    try {
      const parsed = await parsedPhoneNumber(phoneNumber);
      return `+${parsed.countryCallingCode} ${parsed.formatNational()}`;
    } catch (e) {
      return '';
    }
  }

  async function numberWithoutCountryCode(phoneNumberRef) {
    const phoneNumber = unref(phoneNumberRef);
    if (!phoneNumber) {
      return '';
    }
    try {
      const parsed = await parsedPhoneNumber(phoneNumber);
      return parsed.formatNational();
    } catch (e) {
      return '';
    }
  }

  return {
    isPhoneNumberValid,
    numberWithCountryCode,
    numberWithoutCountryCode,
  };
}

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import BeatLoader from '@/components/shared/BeatLoader.vue';
import { Gray50 } from '@/variables.module.scss';

const props = defineProps({
  column: {
    default: 'none',
    type: String,
    validator: function (value) {
      return ['left', 'none', 'right'].includes(value);
    },
  },
  enabled: {
    type: Boolean,
    default: true,
  },
  iconName: {
    type: String,
  },
  isPaywalled: {
    default: false,
    required: false,
    type: Boolean,
  },
  isSelected: {
    default: false,
    type: Boolean,
  },
  label: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  size: {
    default: 'normal',
    type: String,
    validator: function (value) {
      return ['small', 'normal'].includes(value);
    },
  },
});

const emits = defineEmits(['on-click']);

const column = computed(() => props.column);
const isSelected = computed(() => props.isSelected);
const enabled = computed(() => props.enabled);
const disabled = computed(() => !enabled.value);
const loading = computed(() => props.loading);
const size = computed(() => props.size);

const handleClick = () => {
  if (enabled.value) {
    emits('on-click');
  }
};
</script>

<template>
  <button
    class="u-button-reset action-bar-button"
    :class="[
      `action-bar-button--${size}`,
      {
        'action-bar-button--left': column === 'left',
        'action-bar-button--right': column === 'right',
        'action-bar-button--selected': isSelected,
        'action-bar-button--loading': loading,
      },
    ]"
    :disabled="disabled || loading"
    @click="handleClick"
  >
    <BeatLoader
      v-if="loading"
      size="1em"
      :color="Gray50"
      class="action-bar-button__loader"
    />
    <SoonaIcon
      v-if="isPaywalled"
      name="crown-duotone"
      size="x-small"
      class="action-bar-button__paywalled"
    />
    <SoonaIcon :name="iconName" size="medium" class="action-bar-button__icon" />
    {{ label }}
    <slot name="content-bottom" />
  </button>
</template>

<style lang="scss" scoped>
@use 'src/variables';
@use 'src/variables_fonts';

.action-bar-button {
  @include variables_fonts.u-small--heavy;

  align-items: center;
  background-color: variables.$white-default;
  color: variables.$gray-60;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75rem 0.25rem;
  width: 100%;
  border-radius: 0;
  position: relative;
  white-space: normal;
  animation: 0.2s ease-out both k-fade-in;

  &--normal {
    min-width: 4.0625rem;
    max-width: 5rem;
    border-radius: 0.625rem;
  }

  &--small {
    font-size: 0.625rem;
    line-height: 1.2;
  }

  &__icon {
    display: block;
    transform-origin: center;
    transition:
      0.1s color ease-out,
      0.1s transform ease-out;
  }

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background-color: variables.$gray-10;

    .action-bar-button__icon {
      transform: scale(1.2);
    }
  }

  &:disabled {
    color: variables.$gray-40;
    cursor: not-allowed;
  }

  &--selected {
    color: variables.$periwink-blue-80;
    background-color: variables.$periwink-blue-20;

    &:hover:not(:disabled),
    &:focus-visible:not(:disabled) {
      background-color: variables.$periwink-blue-20;
    }
  }

  &--loading:disabled {
    cursor: wait;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__paywalled {
    position: absolute;
    top: 0.1875rem;
    right: 0.5rem;
    color: variables.$tangerine-70;
  }
}
</style>

<script setup>
import { computed, ref, useId, watch, watchEffect } from 'vue';
import { useTextareaAutosize } from '@vueuse/core';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useCreateNote } from '@/queries/notes/useCreateNote';
import { useUpdateNote } from '@/queries/notes/useUpdateNote';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';
import { usePriorityErrors } from '@/composables/usePriorityErrors';

const props = defineProps({
  asset: {
    required: true,
  },
  label: {
    required: false,
    type: String,
    default: 'editing notes',
  },
});

const emits = defineEmits(['is-notes-dirty']);
const queryClient = useQueryClient();

const asset = computed(() => props.asset);
const assetNote = computed(() => asset.value?.note?.content);

const noteIdRef = ref(null);
const noteId = computed(() => noteIdRef.value || asset.value.note?.id);
const assetId = computed(() => asset.value?.id);
const isEditing = ref(false);
const id = useId();
const { textarea: textareaRef, input: currentNote } = useTextareaAutosize({
  styleProp: 'minHeight',
});

const {
  mutate: createDigitalAssetNote,
  isPending: digitalAssetNotePending,
  error: createNoteError,
} = useCreateNote('digital_assets', assetId);

const {
  mutate: updateNote,
  isPending: isUpdating,
  error: updateNoteError,
} = useUpdateNote('digital_assets', assetId);

const priorityErrors = usePriorityErrors(createNoteError, updateNoteError);

const isLoading = computed(
  () => digitalAssetNotePending.value || isUpdating.value
);

// methods
const handleSuccess = data => {
  if (!noteId.value) {
    // c2c-anytime isn't refreshing notedata fast enough to prevent double saves
    // so store it in a ref until cache is busted
    noteIdRef.value = data.find(note => !!note.id)?.id;
  }
  isEditing.value = false;
};

const saveDigitalAssetNote = noteText => {
  if (noteId.value) {
    updateNote(
      { noteId: noteId.value, content: noteText },
      {
        onSuccess: async data => {
          await queryClient.invalidateQueries({ queryKey: queryKeys.bag() });
          handleSuccess(data);
        },
      }
    );
  } else {
    createDigitalAssetNote(
      {
        content: noteText,
      },
      {
        onSuccess: async data => {
          await queryClient.invalidateQueries({ queryKey: queryKeys.bag() });
          handleSuccess(data);
        },
      }
    );
  }
};

function handleSubmit(e) {
  const data = new FormData(e.target);
  const noteText = data.get('note');

  if (assetNote.value === noteText) {
    isEditing.value = false;
    return;
  }

  if (digitalAssetNotePending.value) {
    return;
  }

  saveDigitalAssetNote(noteText);
}

watch(
  assetId,
  newId => {
    noteIdRef.value = null;
    if (!newId) return;
    currentNote.value = assetNote.value;
    isEditing.value = false;
  },
  { immediate: true }
);

watch(
  () => assetNote.value,
  newNote => {
    if (isEditing.value) return;
    currentNote.value = newNote;
  }
);

watchEffect(() => {
  if ((currentNote.value ?? '') !== (assetNote.value ?? '')) {
    emits('is-notes-dirty', true);
    return;
  }
  emits('is-notes-dirty', false);
});
</script>

<template>
  <form class="asset-notes" aria-label="note" @submit.prevent="handleSubmit">
    <label :for="id" :class="{ 'u-visually-hidden': !label }">
      {{ label }}
    </label>
    <div v-if="!isEditing && assetNote !== null" class="asset-notes__display">
      {{ currentNote }}
    </div>
    <textarea
      v-else
      :id="id"
      ref="textareaRef"
      v-model="currentNote"
      name="note"
      autocomplete="off"
      rows="2"
      :disabled="isLoading"
      placeholder="add a note"
    />
    <SoonaError
      v-if="priorityErrors"
      class="asset-notes__error"
      :priority-errors="priorityErrors"
    />
    <SoonaButton
      v-if="!isEditing && assetNote !== null"
      class="asset-notes__button"
      size="medium"
      variation="secondary-gray"
      @on-click="isEditing = true"
    >
      <SoonaIcon name="pen-square" /> edit
    </SoonaButton>
    <SoonaButton
      v-else
      class="asset-notes__button"
      type="submit"
      size="medium"
      :disabled="isLoading || (currentNote ?? '') === ''"
      :loading="isLoading"
    >
      save
    </SoonaButton>
  </form>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.asset-notes {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    @include variables_fonts.u-label--heavy;

    display: block;
    margin-bottom: 0.25rem;
  }

  &__display {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  textarea {
    @include variables_fonts.u-body--regular;

    display: block;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    min-height: 2rem;
    max-height: 25rem;
    padding: 0.25rem 0.5rem;
    resize: vertical;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__helper {
    @include variables_fonts.u-label--regular;

    align-items: center;
    display: flex;
    gap: 0.25rem;

    svg {
      color: variables.$avo-toast-40;
    }

    &--saving {
      svg {
        opacity: 0.4;
      }
    }

    &--error {
      svg {
        color: variables.$roses-60;
      }
    }
  }

  &__error {
    margin: 0.5rem 0 0;
  }

  &__button {
    margin: 0.75rem 0 0 auto;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

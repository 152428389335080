<script setup>
import { computed, ref, watch } from 'vue';
import { keepPreviousData } from '@tanstack/vue-query';
import CarouselBase from '@/components/user/anytime/gallery/media-editor/CarouselBase.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';
import { useReservationDigitalAssets } from '@/queries/reservation_digital_assets/useReservationDigitalAssets';
import { useUnPurchasedBaseFilters } from '@/components/user/anytime/reservation/editor/useUnpurchasedBaseFilters';
import MissingAssetPreview from '@/components/dam/MissingAssetPreview.vue';

const props = defineProps({
  reservationId: {
    type: [String, Number],
    required: true,
  },
  activeAssetId: {
    type: [String, Number],
    default: undefined,
  },
  album: {
    type: String,
    default: null,
  },
  filter: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: 'reservation gallery',
  },
});

const reservationId = computed(() => props.reservationId);
const activeAssetId = computed(() => props.activeAssetId);
const title = computed(() => props.title);

const currentPage = ref(null);
const userChangeablePage = ref(0);

watch(userChangeablePage, newValue => {
  if (newValue > 0) {
    currentPage.value = newValue;
  }
});

const filter = computed(() => props.filter);
const { baseFilters } = useUnPurchasedBaseFilters(filter);
const { data: filePages, isLoading } = useReservationDigitalAssets(
  reservationId,
  {
    activeAssetId: activeAssetId,
    currentPage,
    filters: baseFilters,
    itemsPerPage: 12,
  },
  { placeholderData: keepPreviousData }
);

const files = computed(() => {
  return filePages.value?.assets || [];
});

const query = computed(() => {
  return props.filter ? { filter: props.filter } : {};
});
</script>

<template>
  <CarouselBase
    v-if="files?.length > 0 && !isLoading"
    :file-pages="filePages"
    @update-page="pageNum => (userChangeablePage = pageNum)"
  >
    <template #title>{{ title }}</template>
    <router-link
      v-for="rda in files"
      :key="rda.id"
      :to="{
        path: `/reservation/${reservationId}/asset/${rda.digital_asset.id}`,
        query: query,
      }"
      class="carousel-base__img-link"
      :class="{
        'carousel-base__img-link--active': activeAssetId === rda.id,
      }"
    >
      <DigitalAssetImage
        v-if="rda.digital_asset?.preview?.url"
        :src="rda.digital_asset.preview.url"
      />
      <MissingAssetPreview v-else />
    </router-link>
  </CarouselBase>
</template>

<script setup>
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
});

const email = defineModel('email', {
  type: String,
  required: false,
});

const subjectLine = defineModel('subjectLine', {
  type: String,
  default: '',
});

const notes = defineModel('notes', {
  type: String,
  default: '',
});
</script>

<template>
  <SoonaTextfield
    v-model="email"
    label="email"
    name="email"
    placeholder="client email"
    :disabled="!editable"
    data-cypress="input-email"
  />
  <SoonaTextfield
    v-model="subjectLine"
    label="email subject"
    name="email subject line"
    placeholder="email subject line"
    :disabled="!editable"
    data-cypress="input-email-subject"
  />
  <SoonaTextfield
    v-model="notes"
    label="notes *"
    name="email notes"
    placeholder="email notes"
    element="textarea"
    data-cypress="input-email-notes"
    rows="5"
  />
</template>

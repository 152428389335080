<template>
  <CompleteProfileCard
    class="get-basics"
    :disabled="disabled"
    :show-skip="false"
    @next="saveTimezone"
  >
    <template #heading>what time is it there?</template>
    <fieldset>
      <legend class="u-visually-hidden">preferred timezone</legend>
      <SoonaSelect v-model="timezone" :options="formattedTimeZones">
        <template #label>preferred timezone</template>
      </SoonaSelect>
    </fieldset>
  </CompleteProfileCard>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';

export default {
  name: 'PreferredTimezone',
  components: { CompleteProfileCard, SoonaSelect },
  data() {
    return {
      timezone: undefined,
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    ...mapGetters('account', ['timeZonesList', 'guessedTimeZone']),
    formattedTimeZones() {
      return this.timeZonesList.map(timezone => ({
        label: `${timezone.name.replace(/_/g, ' ')} (${timezone.offset})`,
        value: timezone.name,
      }));
    },
    disabled() {
      return !this.timezone;
    },
  },
  watch: {
    account: {
      handler() {
        this.prefillExistingData();
      },
      deep: true,
    },
  },
  mounted() {
    this.prefillExistingData();
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    prefillExistingData() {
      this.timezone = this.account.preferred_timezone
        ? this.account.preferred_timezone
        : this.guessedTimeZone;
    },
    async saveTimezone() {
      const info = {
        preferred_timezone: this.timezone,
        preferred_timezone_card: {
          complete: true,
        },
      };
      try {
        await this.updateAccount({ accountParams: info });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(
            new Error('Updating account timezone failed'),
            {
              extra: { error },
            }
          );
        }
      }
    },
  },
};
</script>

import { computed } from 'vue';
import { useFlag } from '@/composables/useFlag';
import dayjs from 'dayjs';

export const useShowHubSpotChat = () => {
  const hubSpotFlag = useFlag('hubspot_chat');

  return computed(() => {
    const curDay = dayjs().day();
    const isWeekend = curDay === 0 || curDay === 6;
    const curHour = dayjs().tz('America/Chicago').hour();
    const openHour = 9; // 9am central
    const closeHour = 17; // 5pm central
    const isOpenHours = curHour >= openHour && curHour < closeHour;

    if (
      !window.HubSpotConversations ||
      !hubSpotFlag.value ||
      !isOpenHours ||
      isWeekend
    ) {
      return false;
    }
    return true;
  });
};

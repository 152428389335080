<template>
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="header__nav__menu__item__link__caret"
  >
    <path
      d="m1 1 6 6 6-6"
      stroke="#F0562E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { getCollaborators } from '@/api/collaborators';

/**
 *
 * @param {Ref<string | number>} accountId
 * @param {Object} [params]
 * @param {Ref<number>} [params.currentPage]
 * @param {Ref<number>} [params.itemsPerPage]
 * @param {UseQueryOptions | undefined} [queryOptions]
 */
export function useCollaborators(
  accountId,
  { currentPage, itemsPerPage } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.collaborators(accountId, { currentPage, itemsPerPage }),
    queryFn: ({ signal }) =>
      getCollaborators(
        unref(accountId),
        {
          currentPage: unref(currentPage),
          itemsPerPage: unref(itemsPerPage),
        },
        signal
      ),
    ...queryOptions,
  });
}

<script setup>
import { computed, onMounted, ref } from 'vue';
import { queryKeys } from 'src/queries/query-keys';
import { useCreateReservationLineItem } from 'src/queries/reservationLineItem/useCreateReservationLineItem';
import { useDeleteReservationLineItem } from 'src/queries/reservationLineItem/useDeleteReservationLineItem';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from 'src/components/ui_library/SoonaDialog.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useFlag } from '@/composables/useFlag';
import { useSubscription } from '@/composables/subscription/useSubscription';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  accountId: {
    required: true,
    type: Number,
  },
  addOnList: {
    required: true,
    type: Array,
  },
  assetId: {
    required: true,
    type: Number,
  },
  assetTitle: {
    required: true,
    type: String,
  },
  isGif: {
    required: true,
    type: Boolean,
  },
  isVideo: {
    required: true,
    type: Boolean,
  },
  reservationId: {
    required: true,
    type: Number,
  },
  usingPreferred: {
    required: false,
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const queryClient = useQueryClient();

const lineItemId = ref(undefined);
const selectedAddOns = ref([]);

const accountId = computed(() => props.accountId);
const addOnList = computed(() => props.addOnList);
const assetId = computed(() => props.assetId);
const isGif = computed(() => props.isGif);
const isVideo = computed(() => props.isVideo);
const isPhoto = computed(() => !isGif.value && !isVideo.value);
const reservationId = computed(() => props.reservationId);
const usingPreferred = computed(() => props.usingPreferred);

const { mutate: addAddOn, isPending: isAdding } = useCreateReservationLineItem(
  reservationId.value
);

const { mutate: deleteAddOn, isPending: isDeleting } =
  useDeleteReservationLineItem(
    reservationId.value,
    computed(() => lineItemId.value)
  );

const modalSubtitle = computed(() => {
  if (isVideo.value)
    return 'standard editing includes color correction and trimming your video so it’s ready to post.';
  if (isGif.value)
    return 'standard editing includes color correction and animating your GIF so it’s ready to post. ';
  return 'add-on options to give your image some oomph!';
});

const toCurrency = (value, currency = 'USD') => {
  return value?.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  });
};

const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');

const { hasTierTwoSubscription, hasTierThreeSubscription } =
  useSubscription(accountId);

const isPreferredItem = computed(() => {
  return usingPreferred.value;
});

const hasMembershipDiscount = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return hasTierTwoSubscription.value || hasTierThreeSubscription.value;
  } else {
    return false;
  }
});

const assetDisplayPrice = (value, currency = 'USD') => {
  return toCurrency(value, currency);
};

const formattedSubtotal = computed(() => {
  return toCurrency(
    selectedAddOns.value?.reduce((sum, addOn) => sum + addOn.price, 0)
  );
});

const handleClose = () => emit('close');

const handleInputClick = (e, addOn) => {
  const isChecked = e.target.checked;

  if (isChecked) {
    selectedAddOns.value = [...selectedAddOns.value, addOn];
  } else {
    selectedAddOns.value = selectedAddOns.value?.filter(
      i => i.product.id !== addOn.product.id
    );
  }
};

const handleSave = async () => {
  for (const addOn of addOnList.value) {
    const isSelected = selectedAddOns.value?.some(
      i => addOn.product.id === i.product.id
    );

    if (addOn._destroy && isSelected) {
      await addAddOn({
        product_id: addOn.product.id,
        quantity: 1,
        digital_asset_id: assetId.value,
      });
    } else if (!addOn._destroy && !isSelected) {
      lineItemId.value = await addOn.id;
      await deleteAddOn();
    }
  }

  await queryClient.invalidateQueries({ queryKey: queryKeys.bag() });
  handleClose();
};

// lifecycle hooks
onMounted(() => {
  selectedAddOns.value = addOnList.value?.filter(i => !i._destroy);
});
</script>

<template>
  <SoonaDialog @close="handleClose">
    <SoonaLoading
      v-if="isAdding || isDeleting"
      :is-contained="true"
      :is-loading="true"
      loading-text="saving"
    />
    <template #heading>
      <span class="choose-edits-modal__title">
        choose premium edits for {{ assetTitle }}
      </span>
    </template>
    <p class="choose-edits-modal__subtitle">{{ modalSubtitle }}</p>
    <div
      v-for="addOn in addOnList"
      :key="addOn.id"
      class="choose-edits-modal__input-container"
      :data-cypress="`choose-edits-${addOn.product.name
        .toLowerCase()
        .replace(/\s+/g, '-')}`"
    >
      <label class="choose-edits-modal__label">
        <input
          class="choose-edits-modal__input"
          type="checkbox"
          :checked="!addOn._destroy"
          data-cypress="choose-edits-checkbox"
          @change="e => handleInputClick(e, addOn)"
        />
        <template v-if="isPhoto && (isPreferredItem || hasMembershipDiscount)">
          {{ addOn.product.name }}
        </template>
        <template v-else>
          {{ addOn.product.name }} {{ assetDisplayPrice(addOn.price) }}
        </template>
      </label>
      <p class="choose-edits-modal__helper">
        {{ addOn.product.description }}
      </p>
    </div>
    <p v-if="isPhoto" class="choose-edits-modal__disclaimer">
      *some edit requests are out of soona’s scope. custom requests beyond
      standard edits and premium edits are subject to additional pricing.
    </p>
    <template #footer="{ close }">
      <p
        v-if="!(isPhoto && (isPreferredItem || hasMembershipDiscount))"
        class="choose-edits-modal__subtotal"
      >
        edits subtotal:<span>{{ formattedSubtotal }}</span>
      </p>
      <SoonaButton type="button" variation="tertiary" @click="close">
        cancel
      </SoonaButton>
      <SoonaButton
        type="button"
        variation="primary"
        data-cypress="choose-edits-modal-add-button"
        @click="handleSave"
      >
        add to asset
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.choose-edits-modal {
  &__title {
    word-break: break-word;
  }

  &__subtitle {
    @include variables_fonts.u-label--regular;

    margin-bottom: 1.5rem;
  }

  &__input-container:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  &__input {
    cursor: pointer;
    accent-color: variables.$periwink-blue-60;
    margin-right: 0.3125rem;
  }

  &__label {
    @include variables_fonts.u-body--regular;
  }

  &__helper {
    @include variables_fonts.u-label--regular;

    color: variables.$gray-60;
  }

  &__disclaimer {
    @include variables_fonts.u-small--regular;

    color: variables.$gray-60;
    margin-top: 1.75rem;
  }

  &__subtotal {
    @include variables_fonts.u-subheader--regular;

    align-items: center;
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
    padding-bottom: 0.5rem;

    span {
      @include variables_fonts.u-title--heavy;
    }
  }
}
</style>

<template>
  <div class="mb-l">
    <p v-if="totalPackages > 1" class="soona-title-a1 mb-s">
      <b>PACKAGE {{ index + 1 }}:</b>
    </p>
    <p v-else class="soona-title-a1 mb-s"><b>PACKAGE DETAILS:</b></p>

    <div class="pl-m mb-m">
      <p>
        1 package x {{ shippingDetail.parcels.length }}" x
        {{ shippingDetail.parcels.width }}" x
        {{ shippingDetail.parcels.height }}" ({{
          shippingDetail.parcels.weight
        }}
        lbs)
      </p>
    </div>

    <p class="soona-title-a1 mb-s"><b>CARRIER:</b></p>

    <div class="pl-m">
      <p>
        <b class="soona-title-a1">
          {{ rate.provider }} {{ rate.service_level }}
        </b>
      </p>
      <p class="is-lowercase">
        {{ overrideDurationTerms(rate.duration_terms) }}
      </p>

      <p class="mt-m">
        <b class="soona-title-a1">TRACKING NUMBER:</b>
        <br />
        <a
          :href="shippingDetail.tracking_url"
          class="soona-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ shippingDetail.tracking_number }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    rate: Object,
    totalPackages: Number,
    shippingDetail: Object,
  },
  methods: {
    overrideDurationTerms(terms) {
      if (
        terms ===
        'Delivery within 1, 2, or 3 days based on where your package started and where it’s being sent.'
      ) {
        return 'delivery guaranteed within 3 business days based on your location';
      } else {
        return terms;
      }
    },
  },
};
</script>

<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import { useStore } from 'vuex';
import { ref } from 'vue';

const props = defineProps({
  reservationId: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(['closeModal']);
const store = useStore();

const showShootExceptionModal = ref(false);
const participationLevel = ref(null);
const participationLevelOptions = ref([
  { value: 'engaged', label: 'engaged' },
  { value: 'passive', label: 'passive' },
  { value: 'no show', label: 'no show' },
]);
const shootExceptionNote = ref(null);
const shootExceptionCategory = ref(null);
const shootExceptionCategories = ref([
  { value: 2, label: 'client participation' },
]);

function closeShootExceptionModal() {
  showShootExceptionModal.value = false;
  participationLevel.value = null;
  shootExceptionNote.value = null;
  shootExceptionCategory.value = null;
  emits('closeModal');
}

async function handleSubmitShootException() {
  if (!participationLevel.value || !shootExceptionCategory.value) {
    return;
  }

  await store.dispatch('reservation/createShootException', {
    reservationId: props.reservationId,
    reason: participationLevel.value,
    exceptionType: shootExceptionCategory.value,
    note: shootExceptionNote.value,
  });
  closeShootExceptionModal();
}

function handleParticipationLevelChange(value) {
  participationLevel.value = value;
}

function handleShootExceptionCategoryChange(value) {
  shootExceptionCategory.value = value;
}
</script>
<template>
  <SoonaDialog @close="closeShootExceptionModal">
    <template #heading>shoot exception</template>
    <p class="shoot-exception-subtitle">
      add a shoot exception to this shoot that will display in crew notes.
    </p>
    <div class="shoot-exception-vselect">
      <label for="shoot_exception_category" class="shoot-exception-label"
        >shoot exception category</label
      >
      <SoonaSelect
        name="shoot_exception_category"
        placeholder="select category"
        :model-value="shootExceptionCategory"
        :options="shootExceptionCategories"
        @update:model-value="val => handleShootExceptionCategoryChange(val)"
      />
    </div>
    <div class="shoot-exception-vselect">
      <label for="participation_level" class="shoot-exception-label"
        >participation level</label
      >
      <SoonaSelect
        name="participation_level"
        placeholder="select level"
        :model-value="participationLevel"
        :options="participationLevelOptions"
        @update:model-value="val => handleParticipationLevelChange(val)"
      />
    </div>
    <div class="shoot-exception-note-container">
      <label for="shoot_exception_note" class="shoot-exception-label"
        >notes:</label
      >
      <input
        v-model="shootExceptionNote"
        class="shoot-exception-note-input"
        name="shoot_exception_note"
        type="text"
        placeholder="details on shoot-exception reason"
      />
    </div>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        :disabled="!participationLevel || !shootExceptionCategory"
        data-cypress="button-dialog-confirm"
        @on-click="handleSubmitShootException"
      >
        save
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss">
@use '@/variables';

.shoot-exception-label {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.shoot-exception-vselect {
  width: 50%;
  margin-bottom: 0.5rem;
}

.shoot-exception-note-container {
  display: flex;
  flex-direction: column;

  .shoot-exception-note-input {
    border: 0.0625rem solid variables.$gray-20;
    border-radius: 0.25rem;
    padding: 0.625rem 0.5rem;
    font-size: 1em;
  }
}

.shoot-exception-subtitle {
  font-size: 1rem;
  color: variables.$gray-50;
  margin-bottom: 1.25rem;
}
</style>

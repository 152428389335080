<script setup>
import { computed, onMounted, ref } from 'vue';
import { useAccount } from '@/queries/account/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useDigitalAssets } from '@/queries/digital_assets/useDigitalAssets';
import { useMe } from '@/composables/user/useMe';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useRoute, useRouter } from 'vue-router';
import { useTitle } from '@vueuse/core';
import AccountAlbums from '@/components/account_gallery/AccountAlbums.vue';
import AccountGalleryEmptyState from '@/components/account_gallery/AccountGalleryEmptyState.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import AccountInfoAuto from '@/components/user/anytime/dashboard/AccountInfoAuto.vue';
import UploadMediaDialog from '@/components/account_gallery/UploadMediaDialog.vue';
import AllAssets from './AllAssets.vue';
import AccountGalleryBreadcrumbs from '@/components/account_gallery/AccountGalleryBreadcrumbs.vue';
import EditAlbum from '@/components/account_gallery/EditAlbum.vue';
import { useAlbumCollection } from '@/queries/album_collections/useAlbumCollection';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useStaffPicksCollection } from '@/queries/staff_picks_collection/useStaffPicksCollection';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

const { linkClicked, pageViewed } = useBaseEvents();
const route = useRoute();
const router = useRouter();

const showUploadDialog = ref(false);
const collectionEditModeActive = ref(false);

const { currentAccountId } = useMe();
const accountId = computed(() => +props.accountId);
const {
  data: account,
  error: errorAccount,
  isLoading: isLoadingAccount,
} = useAccount(accountId);

const collectionType = ref(null);
const collectionId = computed({
  get() {
    const id = +route.query?.collection_id;
    return isNaN(id) ? null : id;
  },
  set(value) {
    // favourites need to preserve the current filters
    const favourites_id = account.value?.favorites_collection_id;

    if (
      !!value &&
      value !== favourites_id &&
      // checks the previous to allow toggling favourites
      +route.query?.collection_id !== favourites_id
    ) {
      return router.push({
        query: { ...(value && { collection_id: value }) },
      });
    }

    const query = { ...route.query };
    delete query.collection_id;
    return router.push({
      query: { ...query, ...(value && { collection_id: value }) },
    });
  },
});

const isMyAccount = computed(() => currentAccountId.value === accountId.value);

const { data: editsCollection } = useEditsCollection(accountId);
const { data: staffPicksCollection } = useStaffPicksCollection(accountId);
const { data: favoritesCollection } = useFavoritesCollection(accountId);
const { data: collection } = useAlbumCollection({
  accountId,
  collectionId,
});
const allCollections = computed(() => [
  editsCollection.value || {},
  staffPicksCollection.value || {},
  favoritesCollection.value || {},
  collection.value || {},
]);
const collectionTitle = computed(() => {
  let matchingCollection = allCollections.value.find(
    c => c?.id === +route.query?.collection_id
  );
  if (matchingCollection?.title === 'edits') return 'professional edits';
  if (matchingCollection?.title === 'staff picks') return 'expert picks';

  return matchingCollection?.title;
});

const allowMediaUpload = computed(() => {
  return (
    isMyAccount.value &&
    !(
      editsCollection.value?.id === collectionId.value ||
      staffPicksCollection.value?.id === collectionId.value
    )
  );
});

const allowAlbumEditing = computed(() => {
  return (
    collectionId.value &&
    !(
      favoritesCollection.value?.id === collectionId.value ||
      editsCollection.value?.id === collectionId.value ||
      staffPicksCollection.value?.id === collectionId.value
    )
  );
});

const heading = computed(() => {
  let title = 'gallery';

  if (collectionTitle.value) {
    title = collectionTitle.value;
  }
  if (isMyAccount.value) return title;
  return `${account.value?.name}’s ${title}`;
});
useTitle(heading, { titleTemplate: '%s | soona' });

const {
  data: digitalAssets,
  isLoading: isLoadingDigitalAssets,
  error: errorDigitalAssets,
} = useDigitalAssets({
  accountId,
  itemsPerPage: 1,
  filters: {
    origin: ['customer', 'soona'],
    ownership: ['customer', 'soona'],
    media_type: ['photo', 'video'],
    visibility: 'all',
  },
});

const priorityErrors = usePriorityErrors(errorAccount, errorDigitalAssets);

const hasAssets = computed(() => digitalAssets.value?.assets?.length > 0);
const isLoading = computed(
  () => isLoadingAccount.value || isLoadingDigitalAssets.value
);

const trackClick = (label, href = null) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'header',
    linkLabel: label,
    linkHref: href,
  });
};

const handleOpenUploadDialog = () => {
  trackClick('add media');
  showUploadDialog.value = true;
};

const handleCloseUploadDialog = () => {
  trackClick('cancel');
  showUploadDialog.value = false;
};

const handleAlbumClick = (albumId, albumType = null) => {
  collectionId.value = albumId;
  collectionType.value = albumType;
};

onMounted(() => {
  pageViewed();
});
</script>

<template>
  <section class="gallery" data-cypress="account-gallery">
    <AccountInfoAuto :account-id="accountId" />
    <AccountGalleryBreadcrumbs
      v-if="collectionTitle && favoritesCollection?.id !== collectionId"
      :album-title="collectionTitle"
    />
    <SoonaLoading v-if="isLoading" is-loading />
    <SoonaError v-else-if="priorityErrors" :priority-errors="priorityErrors" />
    <template v-else>
      <div class="gallery__heading">
        <h1 class="u-headline--heavy">
          <span v-if="!collectionEditModeActive">
            {{ favoritesCollection?.id === collectionId ? 'gallery' : heading }}
          </span>
          <EditAlbum
            v-if="allowAlbumEditing"
            :account-id="accountId"
            :collection-id="collectionId"
            @edit-collection-mode="collectionEditModeActive = $event"
          />
        </h1>
        <SoonaButton
          v-if="allowMediaUpload"
          size="medium"
          variation="solid-black"
          @on-click="handleOpenUploadDialog"
        >
          <SoonaIcon name="plus-large" /> add media
        </SoonaButton>
      </div>
      <AccountGalleryEmptyState
        v-if="!hasAssets"
        :account-id="accountId"
        :show-call-to-action-buttons="isMyAccount"
      />
      <div v-else class="gallery__flex">
        <AccountAlbums
          v-if="!collectionTitle || favoritesCollection?.id === collectionId"
          :account-id="accountId"
          @album-selected="handleAlbumClick"
        />
        <AllAssets
          :account-id="accountId"
          @favourites-clicked="handleAlbumClick"
        />
      </div>
    </template>
  </section>
  <UploadMediaDialog
    v-if="showUploadDialog"
    :account-id="accountId"
    :album-id="collectionId"
    :album-type="collectionType"
    @close="handleCloseUploadDialog"
  />
</template>

<style lang="scss" scoped>
.gallery {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h1 {
    margin-bottom: 0.5rem;
  }

  &__flex {
    gap: 3rem;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;

    h1 {
      display: flex;
      align-items: center;
    }
  }
}
</style>

<script setup>
import { toCurrency } from '@/lib/currency';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  price: {
    required: true,
    type: Object,
  },
  selectedBillingInterval: {
    default: 'year',
    type: String,
    validator: function (value) {
      return ['month', 'year'].includes(value);
    },
  },
});

const emits = defineEmits(['change']);
</script>

<template>
  <div class="billing-cycle-option">
    <input
      :id="`billing-cycle-option-${price.recurring_interval}`"
      class="u-visually-hidden"
      name="billing-cycle"
      type="radio"
      :checked="selectedBillingInterval === price.recurring_interval"
      :data-cypress="`input-${price.recurring_interval}`"
      @change="emits('change', price)"
    />
    <label
      :for="`billing-cycle-option-${price.recurring_interval}`"
      class="billing-cycle-option__label"
      :class="{
        'billing-cycle-option__label--has-banner':
          price.recurring_interval === 'year',
      }"
      :data-cypress="`label-${price.recurring_interval}`"
    >
      <span class="billing-cycle-option__checkmark">
        <SoonaIcon name="check" size="medium" />
      </span>
      <span class="billing-cycle-option__label-left">
        <span class="u-body--heavy">{{ price.recurring_interval }}ly</span>
        <span class="u-small--regular">
          {{ toCurrency(+price.unit_amount, 'USD', 0) }} billed
          {{ price.recurring_interval }}ly
        </span>
      </span>
      <span class="billing-cycle-option__label-right">
        <span class="u-body--heavy">{{
          price.recurring_interval === 'year'
            ? toCurrency(+price.unit_amount / 12, 'USD', 0)
            : toCurrency(+price.unit_amount, 'USD', 0)
        }}</span>
        <span class="u-small--regular">per month</span>
      </span>
    </label>
    <span
      v-if="price.recurring_interval === 'year'"
      class="u-label--heavy billing-cycle-option__banner"
    >
      save 30% with our yearly plan 🎉
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.billing-cycle-option {
  &:focus-within:has(:focus-visible) {
    border-radius: 0.6875rem;
    outline: 0.125rem solid variables.$periwink-blue-70;
  }

  @supports not selector(:has(:focus-visible)) {
    &:focus-within {
      border-radius: 0.6875rem;
      outline: 0.125rem solid variables.$periwink-blue-70;
    }
  }

  &__label {
    align-items: center;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    cursor: pointer;
    display: flex;
    gap: 0.75rem;
    justify-content: space-between;
    padding: 0.75rem 1.25rem 1rem 0.75rem;
    transition: background-color 0.2s;

    &--has-banner {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    input:checked + & {
      border-color: variables.$periwink-blue-70;
      border-width: 0.125rem;
      padding: 0.6875rem 1.1875rem 0.9375rem 0.6875rem;

      .billing-cycle-option__checkmark {
        border: none;
        background: variables.$gradient-periwink-error;
        color: variables.$white-default;
      }
    }

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-10;
    }

    &:active {
      border-color: variables.$black-default;
    }

    > span {
      display: flex;
      flex-direction: column;
    }
  }

  &__checkmark {
    align-items: center;
    border: 0.125rem solid variables.$gray-60;
    border-radius: 50%;
    color: transparent;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
  }

  &__label-left {
    flex-grow: 1;
  }

  &__label-right {
    text-align: right;
  }

  &__banner {
    background-color: variables.$periwink-blue-70;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    color: variables.$white-default;
    display: block;
    padding: 0.5rem 0;
    text-align: center;
    width: 100%;
  }
}
</style>

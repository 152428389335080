<script setup>
import SoonaTab from '@/components/ui_library/SoonaTab.vue';

const props = defineProps({
  currentPage: {
    type: String,
    required: true,
  },
  packNameEditMode: {
    type: Boolean,
    required: true,
  },
  sections: {
    type: Array,
    required: true,
  },
});

const isSelected = sectionName => {
  return sectionName === props.currentPage;
};
</script>

<template>
  <nav
    class="crew-pack-builder__content-navigation"
    aria-label="pack configuration"
  >
    <SoonaTab
      v-for="section in sections"
      :key="section.slug"
      :to="section.to"
      :disabled="packNameEditMode"
      :is-selected="isSelected(section.slug)"
      :copy="section.name"
    />
  </nav>
</template>

<style lang="scss" scoped>
@use '@/variables';

.crew-pack-builder__content-navigation {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  overflow-x: auto;
  white-space: nowrap;
  background-color: variables.$white-default;
  position: sticky;
  top: 0;
  z-index: 1;
}

@media (min-width: variables.$screen-sm-min) {
  .crew-pack-builder__content-navigation {
    position: sticky;
    /* sticky app navbar height */
    top: 4.375rem;
  }
}
</style>

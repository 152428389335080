<script setup>
import { computed } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { Gray30 } from 'src/variables.module.scss';
import { useRemoveIntegrationAccount } from '@/queries/useRemoveIntegration';

const props = defineProps({
  accountId: { type: String, required: true },
  integrationId: { type: [Number, String], required: true },
});

const emits = defineEmits(['close', 'removing-integration']);

const accountId = computed(() => props.accountId);
const integrationId = computed(() => props.integrationId);

const { mutate: removeIntegrationAccount } = useRemoveIntegrationAccount(
  accountId,
  integrationId
);

const apiParams = computed(() => {
  return {
    integration_type: 'amazon',
    id: integrationId.value,
  };
});

const removeAmazonAccount = () => {
  emits('removing-integration');
  removeIntegrationAccount(apiParams.value, {
    onSuccess: () => {
      emits('close');
    },
  });
};
</script>
<template>
  <SoonaDialog @close="emits('close')">
    <template #heading>remove account from soona</template>
    <template #default>
      <div class="remove-amazon-data__content">
        <SoonaFlag
          class="remove-amazon-data__flag"
          title="Amazon Seller"
          :border-color="Gray30"
          font-size="1rem"
          type="pill"
        >
          <template #icon-left>
            <SoonaIcon name="amazon-logo" />
          </template>
        </SoonaFlag>
        <p>
          if you remove this account, you will no longer be able to sync product
          data, view listing insights, or publish assets.
        </p>
      </div>
    </template>
    <template #footer>
      <SoonaButton variation="tertiary" @click="emits('close')">
        cancel
      </SoonaButton>
      <SoonaButton variation="primary" @click="removeAmazonAccount">
        remove account
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
.remove-amazon-data {
  &__content {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  &__flag {
    text-transform: none;
    display: flex;
    gap: 0.5rem;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import ListingActionUpload from '@/components/user/anytime/listing_insights/listing_actions/actions/ListingActionUpload.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  platform: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close', 'uploadComplete']);
const accountId = computed(() => props.accountId);
</script>

<template>
  <SoonaDialog @close="$emit('close')">
    <template #heading>upload media</template>
    <ListingActionUpload
      :account-id="accountId"
      :platform="platform"
      @upload-complete="emit('uploadComplete', $event)"
    />
  </SoonaDialog>
</template>

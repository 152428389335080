<script setup>
import { computed } from 'vue';
import { useGetAllEditHistory } from '@/queries/useGetAllEditHistory';
import { usePriorityError } from '@/composables/usePriorityError';
import { useReservation } from '@/composables/useReservation';
import OrderPreviewCard from '@/components/user/anytime/reservation/OrderPreviewCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const reservationId = computed(() => props.reservationId);

const {
  reservationAccountId,
  isLoading: isLoadingReservation,
  error: reservationError,
} = useReservation(reservationId);

const {
  data: editHistory,
  isLoading: isLoadingEditHistory,
  error: editHistoryError,
} = useGetAllEditHistory(reservationId);

const priorityError = usePriorityError(reservationError, editHistoryError);

const isLoading = computed(
  () => isLoadingReservation.value || isLoadingEditHistory.value
);
</script>

<template>
  <article>
    <SoonaLoading v-if="isLoading" is-loading />
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <div v-else class="edit-history">
      <div class="edit-history__header">
        <h2 class="u-subheader--heavy">editing orders for this booking</h2>
        <SoonaButton
          element="router-link"
          :to="{
            path: `/account/${reservationAccountId}/orders`,
          }"
          variation="tertiary"
        >
          billing history <SoonaIcon name="arrow-right" size="medium" />
        </SoonaButton>
      </div>
      <ul class="edit-history__card-list">
        <li v-for="order in editHistory" :key="order.id">
          <OrderPreviewCard :order="order" />
        </li>
      </ul>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.edit-history {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
  }

  &__card-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import StyleQuizLayout from '@/components/quiz/StyleQuizLayout.vue';

const router = useRouter();
const store = useStore();

const account = computed(() => store.state.account);
const allPhotos = computed(() => store.state.styleQuiz.allQuizPhotos);
const currentQuiz = computed(() => store.state.styleQuiz.currentQuiz);

const hasSelectedPhotos = computed(
  () => store.state.styleQuiz.currentQuiz.photos.length > 0
);

const isAuthenticated = computed(
  () => store.getters['currentUser/isAuthenticated']
);

const nextRoute = computed(() => {
  if (account.value?.industry) return 'style-quiz-role-picker';
  return 'style-quiz-star-picker';
});

const togglePhoto = photo => store.dispatch('styleQuiz/toggleQuizPhoto', photo);

const quizPhotos = (columns, column) =>
  allPhotos.value?.filter((val, i) => i % columns === column);
</script>

<template>
  <StyleQuizLayout>
    <template #left-column>
      <h1 class="u-display--heavy">pick the images that make you swoona 😍</h1>
      <p class="u-label--heavy">select all that apply!</p>
      <div class="next-btn-wrapper">
        <SoonaButton
          :disabled="!hasSelectedPhotos"
          size="medium"
          data-cypress="button-next"
          @on-click="() => router.push({ name: nextRoute })"
        >
          next
        </SoonaButton>
      </div>
      <div v-if="!isAuthenticated">
        <p class="u-label--heavy auth-copy">have an account?</p>
        <SoonaButton
          element="router-link"
          variation="tertiary"
          :to="{
            name: 'style-quiz-sign-in',
            query: { redirect: '/style-quiz/pick-image' },
          }"
        >
          sign in
        </SoonaButton>
      </div>
    </template>
    <template #right-column>
      <div class="flex-wrapper">
        <SoonaLoading v-if="!allPhotos.length > 0" is-contained is-loading />
        <div v-for="n in 3" v-else :key="`image-col-${n}`" class="img-column">
          <button
            v-for="(photo, i) in quizPhotos(3, n - 1)"
            :key="photo.id"
            class="u-button-reset img-btn"
            :class="{
              'img-btn--selected': currentQuiz.photos.some(
                p => p.id === photo.id
              ),
            }"
            :data-cypress="`img-picker-${n}-${i}`"
            @click="togglePhoto(photo)"
          >
            <span class="img-wrapper">
              <span v-lazyload>
                <img :data-url="photo.image_url" style="opacity: 0" />
              </span>
            </span>
          </button>
        </div>
      </div>
    </template>
  </StyleQuizLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.next-btn-wrapper {
  margin-left: auto;
}

.auth-copy {
  padding-bottom: 0.5rem;
}

.flex-wrapper {
  display: flex;
  gap: 0.75rem;
  position: relative;
  width: 100%;

  .img-column:nth-child(3) {
    display: none;
  }
}

.img-column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 50%;
}

.img-btn {
  border-radius: 0.25rem;
  border: 0.125rem solid variables.$white-default;
  transition: all 0.4s ease-in-out;

  &--selected {
    border-color: variables.$black-default;
  }
}

.img-wrapper {
  background-color: variables.$bubbletape-pink-10;
  display: block;
  margin-top: 5rem;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  visibility: hidden;

  &.loaded {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: variables.$screen-md-min) {
  .flex-wrapper {
    .img-column:nth-child(3) {
      display: flex;
    }
  }

  .img-column {
    width: 33.3333%;
  }
}
</style>

<script setup>
import { useCapability } from '@/composables/useCapability';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useDeleteCredit } from '@/queries/credits/UseDeleteCredit';
import { computed, ref } from 'vue';
import { convertToMMDDYYYY } from 'src/lib/date-formatters.js';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

const props = defineProps({
  credit: {
    type: Object,
    required: true,
  },
});

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const creditId = computed(() => props.credit.id);
const accountId = computed(() => props.credit.account_id);

const showModal = ref(false);

const { mutate, isPending } = useDeleteCredit(creditId, accountId);

function remove() {
  mutate(
    {},
    {
      onSuccess: () => {
        showModal.value = false;
      },
    }
  );
}

const creditDisplay = computed(() => {
  const reason =
    props.credit.credit_reason === 'down_payment'
      ? 'booking'
      : props.credit.credit_reason;

  const type =
    props.credit.credit_type === 'animation' ? 'gif' : props.credit.credit_type;

  const category =
    props.credit.content_category !== 'default'
      ? props.credit.content_category.replace(/_/g, ' ') + ' '
      : '';

  return `${reason} ${category}${type} credit`;
});
</script>

<template>
  <li class="credit-detail-row-container">
    <div class="credit-detail-row">
      {{ creditDisplay }}
    </div>
    <span v-if="credit.used_at">
      used on {{ convertToMMDDYYYY(credit.used_at) }}
    </span>
    <SoonaButton
      v-else-if="!credit.used_at && isSoonaStaff"
      variation="tertiary"
      class="credit-detail-remove-button"
      @on-click="showModal = true"
    >
      remove
    </SoonaButton>
    <SoonaDialog v-if="showModal" role="alertdialog" @close="showModal = false">
      <template #heading>delete credit</template>
      <p>
        are you sure you want to delete this
        <span class="u-body--heavy">{{ creditDisplay }}</span
        >?
      </p>
      <template #footer="{ close }">
        <SoonaButton :disabled="isPending" variation="tertiary" @click="close">
          cancel
        </SoonaButton>
        <SoonaButton :loading="isPending" @on-click="remove">
          yes, remove it
        </SoonaButton>
      </template>
    </SoonaDialog>
  </li>
</template>

<style lang="scss" scoped>
@use '@/variables';

.credit-detail-row-container {
  background: variables.$white-default;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.credit-detail-row {
  flex: 1 1 auto;
}

.credit-detail-remove-button {
  flex: 0 0 auto;
}
</style>

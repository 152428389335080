import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getCatalogItemActiveListings } from '../api/catalog';

/**
 *
 * @param {string | number} accountId
 * @param {string | number} catalogItemId
 */
export function useGetCatalogItemActiveListings(
  accountId,
  catalogItemId,
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.catalogItemActiveListings(accountId, catalogItemId),
    queryFn: ({ signal }) =>
      getCatalogItemActiveListings(
        unref(accountId),
        unref(catalogItemId),
        signal
      ),
    ...queryOptions,
  });
}

<template>
  <div class="package-transfer">
    <div class="package-transfer-alert-wrapper">
      <SoonaAlert>
        <div class="alert-body">
          <span>
            package transfer
            {{ translatePackageTransferStatus(transferPackage) }} from
            {{ transferPackage?.origin?.short_name }} >
            {{ transferPackage?.destination?.short_name }}
          </span>
          <a class="button is-text text-s" @click="cancelPackageTransfer">
            cancel package transfer
          </a>
        </div>
      </SoonaAlert>
      <button class="button transfer-button" @click="handlePackageStatusChange">
        {{ transferType }} transfer
      </button>
    </div>

    <SoonaDialog v-if="showModal" @close="cancelPackageStatusChange">
      <template #heading>
        {{ cancelPackage ? 'cancel' : transferType }} package transfer
      </template>
      <div v-if="cancelPackage" class="dialog-flex">
        <p class="modal-body-text">
          are you sure you want to cancel this transfer?
        </p>
        <p class="modal-body-text">
          cancelling a transfer does not impact existing reservations.
          <br />
          contact the studio scheduler to update the location of related
          reservations.
        </p>
      </div>
      <div v-else>
        <div v-if="transferPackage.status === 'pending'" class="dialog-flex">
          <p class="modal-body-text">
            add tracking number and confirm package transfer to notify receiving
            studio.
          </p>
          <SoonaSelect
            name="shipping-provider"
            placeholder="select"
            :options="providerOptions"
            :model-value="shippingProvider"
            @update:model-value="val => setSelectedShippingProvider(val)"
          >
            <template #label> shipping provider </template>
          </SoonaSelect>
          <SoonaTextfield
            v-model="trackingNumber"
            label="tracking number"
            name="tracking-number"
            placeholder="input tracking number"
          />
        </div>
        <div v-if="transferPackage.status === 'initiated'">
          <p class="modal-body-text">
            do you want to mark this package transfer complete and notify crew?
          </p>
        </div>
      </div>
      <SoonaTextfield
        v-model="notes"
        label="notes"
        name="notes"
        placeholder="relevant notes"
      />
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">
          cancel
        </SoonaButton>
        <SoonaButton
          data-cypress="button-dialog-confirm"
          @on-click="confirmPackageStatusChange"
        >
          {{ cancelPackage ? 'cancel' : transferType }} transfer
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'PackageTransfer',
  components: {
    SoonaAlert,
    SoonaButton,
    SoonaDialog,
    SoonaSelect,
    SoonaTextfield,
  },
  props: {
    transferPackage: Object,
  },
  data() {
    return {
      showModal: false,
      displayInitiateModal: false,
      displayCancelModal: false,
      cancelPackage: false,
      shippingProvider: {},
      trackingNumber: undefined,
      notes: undefined,
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    ...mapGetters('reservation', ['getProviders']),
    providerOptions() {
      let result = [];
      this.getProviders.forEach(x => {
        result.push({ value: x.name, label: x.name });
      });
      return result;
    },
    transferType() {
      if (this.transferPackage.status === 'pending') {
        return 'initiate';
      } else if (this.transferPackage.status === 'initiated') {
        return 'complete';
      }
      return '';
    },
  },
  methods: {
    ...mapActions('inventoryPackages', ['updateTransferPackage']),
    initiatePackageTransfer() {
      this.displayInitiateModal = true;
    },
    handlePackageStatusChange() {
      this.showModal = !this.showModal;
    },
    cancelPackageStatusChange() {
      this.showModal = false;
      this.cancelPackage = false;
      this.notes = undefined;
      this.trackingNumber = undefined;
    },
    confirmPackageStatusChange() {
      let status = this.cancelPackage ? 'cancelled' : this.transferType;
      if (status === 'initiate') {
        status = 'initiated';
      }
      if (status === 'complete') {
        status = 'completed';
      }

      this.updateTransferPackage({
        transferId: this.transferPackage.id,
        status: status,
        shipping_provider: this.shippingProvider,
        tracking_number: this.trackingNumber,
        notes: this.notes,
      });
      this.showModal = false;
      this.cancelPackage = false;
      this.notes = undefined;
      this.trackingNumber = undefined;
    },
    cancelPackageTransfer() {
      this.showModal = true;
      this.cancelPackage = true;
    },
    setSelectedShippingProvider(val) {
      this.shippingProvider = val;
    },
    translatePackageTransferStatus(pkg) {
      if (pkg.status === 'pending') {
        return 'needed';
      }
      if (pkg.status === 'initiated') {
        return 'in progress';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.package-transfer {
  .package-transfer-alert-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: 20px;

    .soona-alert {
      flex-grow: 3;
      width: 100%;

      :deep(.text-wrapper) {
        width: 100%;

        .body-slot {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }

    a {
      flex-shrink: 1;
    }
  }

  .transfer-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: variables.$white-default;
    border: 1px solid variables.$black-default;
    box-sizing: border-box;
    border-radius: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;

    &:hover {
      background: variables.$gray-20;
    }
  }

  .modal-body-text {
    padding-bottom: 0.5rem;
  }

  .text-wrapper {
    width: 100%;
  }

  .alert-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.dialog-flex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
}

:deep(.soona-textfield) {
  padding-bottom: 0;
}
</style>

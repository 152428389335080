<script setup>
import { computed } from 'vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import { useMediaQuery } from '@vueuse/core';
import { useGetIntegrations } from '@/queries/useGetIntegrations';
import {
  amazonAuthorizationRedirectUrl,
  shopifyAppUrl,
} from '@/lib/integrations/constants';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { isActiveAmazonIntegration } from './integration-utils';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const accountId = computed(() => props.accountId);

const route = useRoute();

const isSmallScreen = useMediaQuery('(max-width: 30rem)');

const { linkClicked } = useBaseEvents();

const trackLinkClicked = (importFrom, href) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'add products dropdown',
    linkLabel: `import from ${importFrom}`,
    linkHref: href,
  });
};

const { data: integrationsData } = useGetIntegrations(accountId);

const integrations = computed(() => integrationsData.value ?? []);

const hasAmazonIntegration = computed(() =>
  integrations.value.some(isActiveAmazonIntegration)
);
</script>

<template>
  <SoonaDropdownMenu
    :copy="isSmallScreen ? 'add' : 'add products'"
    variation="primary"
  >
    <template #default="{ clickCapture, keydown, mouseover }">
      <SoonaDropdownMenuItem>
        <router-link
          role="menuitem"
          :to="{
            path: `/account/${accountId}/products/new`,
          }"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          create new
        </router-link>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem v-if="!hasAmazonIntegration">
        <a
          role="menuitem"
          :href="amazonAuthorizationRedirectUrl"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
          @click="trackLinkClicked('amazon', amazonAuthorizationRedirectUrl)"
        >
          import from <span :style="{ 'text-transform': 'none' }">Amazon</span>
        </a>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem>
        <a
          role="menuitem"
          :href="shopifyAppUrl"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
          @click="trackLinkClicked('shopify', shopifyAppUrl)"
        >
          import from <span :style="{ 'text-transform': 'none' }">Shopify</span>
        </a>
      </SoonaDropdownMenuItem>
    </template>
  </SoonaDropdownMenu>
</template>

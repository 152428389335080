<script setup>
import { computed, ref, watch } from 'vue';
import { getUrl } from 'src/lib/inventory/shippingProvider.js';
import { convertToMMDDYYYY, convertToHHMM12 } from 'src/lib/date-formatters.js';
import { useInventory } from '@/composables/useInventory';
import { useInventoryPackagesSummary } from '@/queries/inventory/useInventoryPackages';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import { keepPreviousData } from '@tanstack/vue-query';

const props = defineProps({
  searchValue: {
    type: String,
    required: false,
  },
  location: {
    type: Object,
    required: true,
  },
  returnLabel: {
    type: Boolean,
    required: true,
    default: false,
  },
  shippingStatus: {
    type: Array,
    required: false,
  },
  packageStatus: {
    type: Array,
    required: false,
  },
  requiredAction: {
    type: Array,
    required: false,
  },
});

// NAVIGATION
const page = ref(1);
const itemsPerPage = ref(30);
const orderBy = ref(undefined);
const direction = ref('asc');

const currentDirection = computed(() => {
  return direction.value === 'asc' ? 'chevron-up' : 'chevron-down';
});

const toggleDirection = () => {
  if (direction.value === 'asc') {
    direction.value = 'desc';
  } else {
    direction.value = 'asc';
  }
};

const clickedOrderField = field => {
  if (orderBy.value === field) {
    toggleDirection();
  } else {
    direction.value = 'asc';
    orderBy.value = field;
  }
};

const searchValue = computed(() => props.searchValue);
const locationId = computed(() => props.location.id);
const returnLabel = computed(() => props.returnLabel);
const shippingStatus = computed(() => props.shippingStatus);
const packageStatus = computed(() => props.packageStatus);
const requiredAction = computed(() => props.requiredAction);

watch(
  [
    orderBy,
    direction,
    itemsPerPage,
    searchValue,
    locationId,
    returnLabel,
    shippingStatus,
    packageStatus,
    requiredAction,
  ],
  () => {
    page.value = 1;
  }
);

// PACKAGES
const { data: packagesData } = useInventoryPackagesSummary(
  {
    query: searchValue,
    page,
    itemsPerPage,
    locationId,
    orderBy,
    direction,
    returnLabel,
    shippingStatus,
    packageStatus,
    requiredAction,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const inventoryPackages = computed(
  () => packagesData.value?.inventoryPackages || []
);
const inventoryPackagesPagination = computed(() =>
  packagesData.value
    ? packagesData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);
// TABLE DETAILS
const shippingStatusMap = {
  PRE_TRANSIT: 'is-warning',
  TRANSIT: 'is-info',
  DELIVERED: 'is-primary',
  RETURNED: 'is-danger',
  FAILURE: 'is-danger',
};

const { postShootOptions } = useInventory();

const postShootOption = shipment => {
  return (
    postShootOptions.value?.find(
      pso => pso.value === shipment.post_shoot_option
    )?.emoji || '⚠️'
  );
};

const isActive = field => {
  return field === orderBy.value;
};

const statusDetails = (details, date) => {
  if (details && date) {
    let formatted_date = convertToMMDDYYYY(date);
    let formatted_time = convertToHHMM12(date);

    return (
      details + '\nLast updated ' + formatted_date + ' at ' + formatted_time
    );
  } else if (details) {
    return details;
  } else {
    return 'No status updates have been received.';
  }
};

const hasInboundDetail = shipment => {
  return shipment.inbound_shipping_detail !== undefined;
};

const hasOutboundDetail = shipment => {
  return shipment.outbound_shipping_detail !== undefined;
};

const computeRequiredAction = shipment => {
  const actionIsOnHold = [
    'awaiting return preference',
    'on hold for discard',
  ].includes(shipment.requiredAction);
  const endDateIsAfterToday =
    shipment.subscription_expires_at > new Date().getTime() / 1000;
  const hasCanceledSubscription = shipment.has_canceled_subscription;
  const packageIsStored = ['stored'].includes(shipment.status);

  if (
    actionIsOnHold ||
    (endDateIsAfterToday && hasCanceledSubscription && packageIsStored)
  )
    return;

  return shipment.requiredAction;
};

const formattedDate = date => {
  return convertToMMDDYYYY(date);
};

const getShippingUrl = detail => {
  return getUrl(detail);
};
</script>

<template>
  <table class="shipping-summary-table table is-striped u-small--regular">
    <thead>
      <tr>
        <th>Package ID</th>
        <th>
          <a
            class="sortable-table-field"
            :class="{ 'active-order': isActive('client_name') }"
            @click.prevent="clickedOrderField('client_name')"
            >Client
            <span v-if="isActive('client_name')">
              <SoonaIcon :name="currentDirection" size="small" />
            </span>
          </a>
          <a
            class="sortable-table-field"
            :class="{ 'active-order': isActive('reservation_name') }"
            @click.prevent="clickedOrderField('reservation_name')"
            >Reservation
            <span v-if="isActive('reservation_name')">
              <SoonaIcon :name="currentDirection" size="small" /> </span
          ></a>
        </th>
        <th>
          <a
            class="sortable-table-field"
            :class="{ 'active-order': isActive('scheduled_date') }"
            @click.prevent="clickedOrderField('scheduled_date')"
            >Scheduled Date
            <span v-if="isActive('scheduled_date')">
              <SoonaIcon :name="currentDirection" size="small" /> </span
          ></a>
        </th>
        <th>Address</th>
        <th>Provider</th>
        <th>Tracking / Ref #</th>
        <th>
          <a
            class="sortable-table-field"
            :class="{ 'active-order': isActive('eta') }"
            @click.prevent="clickedOrderField('eta')"
            >Delivery Date
            <span v-if="isActive('eta')">
              <SoonaIcon :name="currentDirection" size="small" />
            </span>
          </a>
        </th>
        <th>Shipping Status</th>
        <th>Package Status</th>
        <th>Inbound Label</th>
        <th class="return-columns">Return Status</th>
        <th>Return Label</th>
        <th>Location</th>
        <th>Action Required</th>
        <th>Post-Shoot</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="shipment in inventoryPackages"
        :key="shipment.id"
        :class="shipment.post_shoot_option"
      >
        <td>{{ shipment.id }}</td>
        <td>
          <router-link
            class="soona-link"
            :to="{ path: `/account/${shipment.client_id}` }"
          >
            <b>{{ shipment.client_name }}</b>
          </router-link>
          <br />
          <router-link
            class="soona-link"
            :to="{ path: `/reservation/${shipment.reservation_id}/info` }"
          >
            {{ shipment.reservation_name }}
          </router-link>
        </td>
        <td class="has-text-right">
          {{ formattedDate(shipment.reservation_date) }}
        </td>
        <td v-if="shipment.return_address">
          <p>
            {{
              [
                shipment.return_address.address_1,
                shipment.return_address.address_2,
              ].join(' ')
            }}
          </p>
          <p>
            {{ shipment.return_address.city }},
            {{ shipment.return_address.state }}
            {{ shipment.return_address.postal_code }}
          </p>
        </td>
        <td v-else></td>
        <td v-if="hasInboundDetail(shipment)">
          {{ shipment.inbound_shipping_detail.shipping_provider }}
        </td>
        <td v-else></td>

        <td v-if="hasInboundDetail(shipment)">
          <p
            v-if="
              shipment.inbound_shipping_detail.shipping_provider === 'drop off'
            "
          >
            {{ shipment.inbound_shipping_detail.tracking_number }}
          </p>
          <a
            v-else
            :href="
              shipment.inbound_shipping_detail.tracking_url ||
              getShippingUrl(shipment.inbound_shipping_detail)
            "
            class="soona-link"
            target="_blank"
            rel="noopener noreferrer"
            >{{ shipment.inbound_shipping_detail.tracking_number }}
          </a>
          <br />
          <span>REF: {{ shipment.reservation_id }}</span>
        </td>
        <td v-else></td>

        <td
          v-if="
            hasInboundDetail(shipment) && shipment.inbound_shipping_detail?.eta
          "
          class="has-text-right"
        >
          {{ convertToMMDDYYYY(shipment.inbound_shipping_detail.eta) }}
        </td>
        <td v-else></td>

        <td>
          <span
            v-if="
              hasInboundDetail(shipment) &&
              shipment.inbound_shipping_detail?.shipping_status
            "
            :class="
              shippingStatusMap[
                shipment.inbound_shipping_detail.shipping_status
              ]
            "
            class="tag is-rounded is-lowercase shipping-status"
            :title="
              statusDetails(
                shipment.inbound_shipping_detail.status_details,
                shipment.inbound_shipping_detail.status_date
              )
            "
          >
            {{ shipment.inbound_shipping_detail.shipping_status }}
          </span>
        </td>

        <td>
          <router-link
            class="soona-link"
            :to="{
              path: `/account/${shipment.client_id}/package/${shipment.id}/edit`,
              query: {
                returnToShippingSummary: true,
                location: location.id,
              },
            }"
          >
            {{ shipment.status }}
          </router-link>
        </td>

        <td class="has-text-centered">
          <a
            v-if="
              hasInboundDetail(shipment) &&
              shipment.inbound_shipping_detail?.label_url
            "
            :href="shipment.inbound_shipping_detail.label_url"
            class="soona-link shipping-label-btn"
            target="_blank"
            rel="noopener noreferrer"
            title="Inbound Label"
          >
            <SoonaIcon name="package" size="medium" />
          </a>
        </td>

        <td class="return-columns with-background">
          <span
            v-if="
              hasOutboundDetail(shipment) &&
              shipment.outbound_shipping_detail?.shipping_status
            "
            :class="
              shippingStatusMap[
                shipment.outbound_shipping_detail.shipping_status
              ]
            "
            class="tag is-rounded is-lowercase shipping-status"
            :title="
              statusDetails(
                shipment.outbound_shipping_detail.status_details,
                shipment.outbound_shipping_detail.status_date
              )
            "
          >
            {{ shipment.outbound_shipping_detail.shipping_status }}
          </span>
        </td>

        <td class="has-text-centered with-background">
          <a
            v-if="
              hasOutboundDetail(shipment) &&
              shipment.outbound_shipping_detail?.label_url
            "
            :href="shipment.outbound_shipping_detail.label_url"
            class="soona-link shipping-label-btn"
            target="_blank"
            rel="noopener noreferrer"
            title="Return Label"
          >
            <SoonaIcon name="package" size="medium" />
          </a>
        </td>

        <td class="with-background storage-location-column">
          {{ shipment.storage_location }}
        </td>
        <td class="with-background required-action-column">
          {{ computeRequiredAction(shipment) }}
        </td>
        <td class="with-background pso-column">
          {{ postShootOption(shipment) }}
        </td>
      </tr>
    </tbody>
  </table>
  <PaginatorFull
    :page="inventoryPackagesPagination.currentPage"
    :records-per-page="inventoryPackagesPagination.itemsPerPage"
    :total-pages="inventoryPackagesPagination.totalPages"
    :total-count="inventoryPackagesPagination.totalCount"
    :page-count="inventoryPackages.length"
    @update:page="page = $event"
    @update:records-per-page="itemsPerPage = $event"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.sortable-table-field {
  align-items: center;
  display: flex;
  text-decoration: underline;
  text-decoration-style: dotted;

  &:hover {
    color: variables.$periwink-blue-50;
  }
}

.shipping-label-btn {
  color: variables.$periwink-blue-50;
  cursor: pointer;
  display: flex;
  padding: 0.5rem;
  width: max-content;
}

.return-columns {
  border-left: 0.3125rem solid variables.$gray-20 !important;
  padding-left: 25px !important;
}

.storage-location-column {
  max-width: 120px;
}

.active-order {
  color: variables.$periwink-blue-50 !important;
}

.required-action-column {
  font-weight: 800;
}

.with-background {
  background-color: #ffa38e;
}

.store_at_soona > .with-background {
  background-color: #e6effe;
}

.discard > .with-background {
  background-color: #e9eab3;
}

.return_shipping > .with-background {
  background-color: #fde9fd;
}

.pso-column {
  text-align: right;
}
</style>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.667 9.166H6.525l4.659-4.658L10 3.333 3.333 10 10 16.666l1.175-1.175-4.65-4.658h10.142V9.166Z"
      fill="#000"
    />
  </svg>
</template>

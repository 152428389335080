<script setup>
import { computed, useSlots, watchEffect } from 'vue';
import beanMask from 'images/blobs/kidney-bean.svg';
import doggieImage from 'images/booking/down-payment-doggie@2x.jpg';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useRoute } from 'vue-router';
import { useReservation } from '@/composables/useReservation';
import { usePaymentInterstitialRedirect } from './usePaymentInterstitialRedirect';

const props = defineProps({
  reservationId: {
    type: String,
  },
});
const slots = useSlots();

const route = useRoute();
const reservationId = computed(() => props.reservationId);
const { reservationAccountId } = useReservation(reservationId);

const maskSvg = computed(() => `url("${beanMask}")`);

// this is for Shopify post-down payment
const { startInterstitialTimeout } = usePaymentInterstitialRedirect(
  reservationId,
  reservationAccountId
);

watchEffect(() => {
  if (reservationId.value && route.path.match(/\/thank-you\/?/g)) {
    startInterstitialTimeout();
  }
});
</script>

<template>
  <div
    class="interstitial"
    :class="{ 'interstitial--has-footer': slots['footer'] }"
  >
    <div class="interstitial__inner">
      <div class="interstitial__left">
        <img class="interstitial__image" :src="doggieImage" alt="" />
      </div>
      <div class="interstitial__right">
        <div class="interstitial__checkmark">
          <div class="interstitial__checkmark-inner">
            <SoonaIcon name="circle-check-solid" size="large" />
          </div>
        </div>
        <h1 class="interstitial__heading">
          <slot name="heading" />
        </h1>
        <p class="interstitial__subheading">
          <strong v-if="slots['bold-subheading']">
            <slot name="bold-subheading"></slot>
          </strong>
          <slot name="subheading"></slot>
        </p>
        <div class="interstitial__copy">
          <slot name="payment-description"></slot>
        </div>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.interstitial {
  display: flex;
  flex-direction: column;
  animation: interstitial-enter 0.3s both ease-out;
  position: fixed;
  top: var(--app-header-viewport-offset);
  background-color: variables.$periwink-blue-10;
  left: 0;
  right: 0;
  z-index: 1;
  bottom: 0;
  overflow: auto;

  &--has-footer {
    position: absolute;
    overflow: visible;
  }

  &__inner {
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: variables.$periwink-blue-10;
  }

  &__left {
    display: none;
  }

  &__right {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 25.3125rem;
    text-align: center;
    gap: 1rem;
  }

  &__checkmark {
    align-items: center;
    display: flex;
    height: 1.5rem;

    svg {
      color: variables.$avo-toast-40;
      transform: scale(1.2);
      transform-origin: center;
    }
  }

  &__checkmark-inner {
    align-items: center;
    animation: pulse 0.8s alternate infinite;
    box-shadow: 0 0 0 variables.$avo-toast-20;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    transform: scale(0.9);
    transform-origin: center;
  }

  &__heading {
    @include variables_fonts.u-display--heavy;
  }

  &__subheading {
    @include variables_fonts.u-title--regular;

    strong {
      @include variables_fonts.u-title--heavy;
    }
  }

  &__copy {
    @include variables_fonts.u-label--regular;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    :slotted(a:not(.soona-button)) {
      text-decoration: underline;
      text-underline-position: under;
      transition: 0.2s;
    }

    :slotted(a:not(.soona-button)):hover {
      color: variables.$periwink-blue-70;
    }

    :slotted(a:not(.soona-button)):active {
      color: variables.$periwink-blue-70;
      text-decoration: none;
    }
  }
}

@media (min-width: variables.$screen-md-min) {
  .interstitial {
    &__inner {
      padding: 0;
      gap: 2.375rem;
    }

    &__left {
      display: block;
      position: relative;

      &::before {
        animation: wiggle 3s alternate infinite ease-in-out;
        content: v-bind('maskSvg');
        position: absolute;
        transform: matrix(0.92, -0.4, 0.4, 0.92, 0, 0);
      }
    }

    &__image {
      display: block;
      height: 100%;
      mask: v-bind('maskSvg') center / 18.75rem no-repeat;
      mask-size: contain;
      max-height: 24rem;
      max-width: 14.375rem;
      position: relative;
      width: 100%;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .interstitial {
    animation: none !important;

    &__left::before {
      animation: none !important;
    }

    &__checkmark-inner {
      animation: none;
      box-shadow: none;
    }
  }
}

@keyframes interstitial-enter {
  from {
    opacity: 0;
    transform: translateX(3em);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(variables.$avo-toast-20, 0);
  }

  100% {
    box-shadow: 0 0 0 0.625rem rgba(variables.$avo-toast-20, 1);
  }
}

@keyframes wiggle {
  from {
    transform: matrix(0.92, -0.4, 0.4, 0.92, 0, 0) rotate(0);
  }

  to {
    transform: matrix(0.92, -0.4, 0.4, 0.92, 0, 0) rotate(40deg);
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaGrid from '@/components/ui_library/SoonaGrid.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import {
  amazonAuthorizationRedirectUrl,
  shopifyAppUrl,
} from '@/lib/integrations/constants';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useGetIntegrations } from '@/queries/useGetIntegrations';
import { isActiveAmazonIntegration } from './integration-utils';
import { Gray50 } from 'src/variables.module.scss';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  integrationsToDisplay: {
    type: [Array, String],
    default: 'all',
  },
});

const accountId = computed(() => props.accountId);

const displayShopify = computed(() => {
  return (
    props.integrationsToDisplay === 'all' ||
    props.integrationsToDisplay.includes('ShopifyIntegration')
  );
});

const route = useRoute();

const { linkClicked } = useBaseEvents();

const { data: integrationsData } = useGetIntegrations(accountId);

const integrations = computed(() => integrationsData.value ?? []);

const hasAmazonIntegration = computed(() =>
  integrations.value.some(isActiveAmazonIntegration)
);

const amazonLink = computed(() => {
  return hasAmazonIntegration.value ? null : amazonAuthorizationRedirectUrl;
});

const amazonCopy = 'Amazon Seller Central';

function integrationLinkClicked(integrationName, url) {
  linkClicked({
    context: route.meta.context,
    subContext: `${integrationName} connect`,
    linkLabel: `import from ${integrationName}`,
    linkHref: url,
  });
}
</script>

<template>
  <SoonaGrid>
    <div v-if="hasAmazonIntegration" class="external-integration-link disabled">
      <SoonaIcon name="amazon-logo" size="2x-large" />
      <SoonaIcon
        class="external-integration-link__connected-icon"
        name="circle-check-solid"
        :color="Gray50"
      />
      <p>{{ amazonCopy }}</p>
    </div>
    <a
      v-else
      class="external-integration-link"
      :href="amazonLink"
      @click="integrationLinkClicked('amazon', amazonAuthorizationRedirectUrl)"
    >
      <SoonaIcon name="amazon-logo" size="2x-large" />
      <p>{{ amazonCopy }}</p>
    </a>
    <a
      v-if="displayShopify"
      class="external-integration-link"
      role="link"
      :href="shopifyAppUrl"
      @click="integrationLinkClicked('shopify', shopifyAppUrl)"
    >
      <SoonaIcon name="shopify-logo" size="2x-large" />
      <p>Shopify</p>
    </a>
  </SoonaGrid>
</template>

<style lang="scss" scoped>
@use '@/variables';
.external-integration-link {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  border: 0.0625rem solid variables.$gray-20;
  border-radius: 0.5rem;
  text-decoration: none;
  &__connected-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  &:hover {
    background-color: variables.$gray-20;
  }
  p {
    font-size: 1.25rem;
    font-weight: 500;
  }
  &.disabled {
    cursor: not-allowed;
    background-color: variables.$gray-20;
    color: variables.$gray-50;
  }
}
</style>

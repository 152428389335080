<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import AccountPreferenceActions from './AccountPreferenceActions.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useSchedulingPreferences } from '@/composables/useSchedulingPreferences';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  cancelEdits: {
    default: false,
    type: Boolean,
  },
  editMode: {
    default: false,
    type: Boolean,
  },
  formId: {
    type: String,
    required: true,
  },
  isDialog: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits([
  'submit',
  'reset-cancel-edits',
  'update:subject-type',
]);

const {
  days,
  slots,
  timeZoneOptions,
  schedulingPreference,
  hasAccountPreference,
  accountPreference,
} = useSchedulingPreferences(
  computed(() => props.accountId),
  computed(() => props.reservationId)
);

const isExpanded = ref({});
const availablity = ref([]);
const notes = ref('');
const selectedTimeZone = ref(null);

const schedulingPreferenceSlots = computed(
  () => schedulingPreference.value?.slots
);
const schedulingNote = computed(() => schedulingPreference.value?.note);
const schedulingPreferredTimeZone = computed(
  () => schedulingPreference.value?.preferred_time_zone
);
const editMode = computed(() => props.editMode);
const cancelEdits = computed(() => props.cancelEdits);

watchEffect(() => {
  if (schedulingPreference.value) {
    availablity.value = schedulingPreferenceSlots.value;
    notes.value = schedulingNote.value;
    selectedTimeZone.value = schedulingPreferredTimeZone.value;
    isExpanded.value = schedulingPreferenceSlots.value.reduce((acc, slot) => {
      acc[slot.day] = true;
      return acc;
    }, {});
  }
});

watch(
  () => cancelEdits.value,
  () => {
    if (cancelEdits.value) {
      availablity.value = schedulingPreferenceSlots.value;
      notes.value = schedulingNote.value;
      selectedTimeZone.value = schedulingPreferredTimeZone.value;
      isExpanded.value = schedulingPreferenceSlots.value.reduce((acc, slot) => {
        acc[slot.day] = true;
        return acc;
      }, {});

      emit('reset-cancel-edits');
    }
  }
);

const preferenceExists = (day, slot) => {
  return !!availablity.value.find(
    availability => availability.day === day && availability.period === slot
  );
};

// toggling
const toggleSlot = (day, slot) => {
  if (!editMode.value) return;

  if (preferenceExists(day, slot)) {
    availablity.value = availablity.value.map(availability => {
      if (
        availability.day === day &&
        availability.period === slot &&
        availability._destroy === true
      ) {
        return { ...availability, _destroy: false };
      } else if (
        availability.day === day &&
        availability.period === slot &&
        (!availability._destroy || availability._destroy === false)
      ) {
        return { ...availability, _destroy: true };
      }
      return availability;
    });
  } else {
    availablity.value = [...availablity.value, { day, period: slot }];
  }
};

const toggleAllDaySlots = day => {
  if (!editMode.value) return;

  isExpanded.value[day]
    ? (isExpanded.value[day] = false)
    : (isExpanded.value[day] = true);

  if (isExpanded.value[day] === true) {
    slots.forEach(slot => {
      if (preferenceExists(day, slot.value)) {
        availablity.value = availablity.value.map(availability => {
          if (availability?.day === day) {
            return { ...availability, _destroy: false };
          }
          return availability;
        });
      } else {
        availablity.value = [...availablity.value, { day, period: slot.value }];
      }
    });
  } else if (isExpanded.value[day] === false) {
    availablity.value = availablity.value.map(availability => {
      if (availability?.day === day) {
        return { ...availability, _destroy: true };
      }
      return availability;
    });
  }
};

// checked state
const checkedRangeAvailability = (day, range) => {
  return !!availablity.value.find(
    availability =>
      availability?.day === day &&
      availability?.period === range &&
      (!availability._destroy || availability._destroy === false)
  );
};

const checkedDayAvailability = day => {
  return !!availablity.value.find(
    availability =>
      availability?.day === day &&
      (!availability._destroy || availability._destroy === false)
  );
};

// flexible scheduling
const getAllAvailableSlots = () => {
  let availableSlots = schedulingPreferenceSlots.value?.slice();

  days.forEach(day => {
    slots.forEach(slot => {
      if (
        !schedulingPreferenceSlots.value.find(
          availablity =>
            availablity.day === day.value && availablity.period === slot.value
        )
      ) {
        availableSlots.push({ day: day.value, period: slot.value });
      }
    });
  });

  return availableSlots;
};

const allDaysSlotsSelected = () => {
  return days.every(day => {
    return slots.every(slot => {
      return checkedRangeAvailability(day.value, slot.value);
    });
  });
};

const toggleAllAvailableSlots = () => {
  if (!editMode.value) return;

  if (allDaysSlotsSelected()) {
    availablity.value = availablity.value.map(availability => {
      return { ...availability, _destroy: true };
    });
    isExpanded.value = {};
  } else {
    availablity.value = getAllAvailableSlots();
    isExpanded.value = availablity.value.reduce((acc, slot) => {
      acc[slot.day] = true;
      return acc;
    }, {});
  }
};

// default availability
const resetToDefault = ref(false);

const accountSchedulingPreferencesFlag = useFlag(
  'toaster_account_scheduling_preferences'
);

watchEffect(() => {
  if (
    accountSchedulingPreferencesFlag.value &&
    editMode.value &&
    !hasAccountPreference.value
  ) {
    emit('update:subject-type', 'Account');
  }
});

const handleSetSubjectType = value => {
  if (value) {
    emit('update:subject-type', 'Account');
  } else {
    emit('update:subject-type', 'Reservation');
  }
};

const defaultIsDirty = computed(() => {
  if (!accountSchedulingPreferencesFlag.value) return false;
  if (!hasAccountPreference.value) return false;

  return (
    JSON.stringify(availablity.value) !==
      JSON.stringify(accountPreference.value?.slots) ||
    notes.value !== accountPreference.value?.note ||
    selectedTimeZone.value !== accountPreference.value?.preferred_time_zone
  );
});

watchEffect(() => {
  if (defaultIsDirty.value) {
    emit('update:subject-type', 'Reservation');
  }

  resetToDefault.value = false;
});

watchEffect(() => {
  if (resetToDefault.value) {
    availablity.value = accountPreference.value?.slots;
    notes.value = accountPreference.value?.note;
    selectedTimeZone.value = accountPreference.value?.preferred_time_zone;

    emit('update:subject-type', 'Account');
  }

  resetToDefault.value = false;
});
</script>

<template>
  <SoonaForm
    :id="formId"
    class="scheduling-preferences"
    :aria-labelledby="`${formId}-heading`"
    @submit="() => emit('submit', { availablity, notes, selectedTimeZone })"
  >
    <h4 v-if="isDialog" class="scheduling-preferences__title">
      which days of the week are you generally available?
    </h4>
    <div class="scheduling-preferences__preferred-time-zone">
      <SoonaSelect
        v-model:model-value="selectedTimeZone"
        placeholder="select your preferred time zone"
        :options="timeZoneOptions"
        :disabled="!editMode"
      >
        <template #label>preferred time zone</template>
      </SoonaSelect>
    </div>

    <hr class="u-divider" />

    <div class="selection-wrapper">
      <SoonaButton
        :aria-disabled="!editMode"
        :aria-pressed="allDaysSlotsSelected()"
        class="availability-toggle availability-toggle--day"
        :class="{ 'un-editable': !editMode }"
        variation="secondary-gray"
        @on-click="toggleAllAvailableSlots()"
      >
        <SoonaIcon
          v-if="allDaysSlotsSelected()"
          class="check-icon"
          name="circle-check-solid"
          size="small"
          style="vertical-align: middle"
        />
        <SoonaIcon
          v-else
          name="plus"
          size="small"
          style="vertical-align: middle"
        />
        I’m flexible
      </SoonaButton>
    </div>
    <div v-for="day in days" :key="day.value" class="selection-wrapper">
      <SoonaButton
        :id="`availability-header-${day.value}`"
        :aria-controls="`availability-panel-${day.value}`"
        :aria-disabled="!editMode"
        :aria-expanded="isExpanded[day.value]"
        :aria-pressed="checkedDayAvailability(day.value)"
        class="availability-toggle availability-toggle--day"
        :class="{
          'un-editable': !editMode,
        }"
        variation="secondary-gray"
        @on-click="toggleAllDaySlots(day.value)"
      >
        <SoonaIcon
          v-if="checkedDayAvailability(day.value)"
          class="check-icon"
          name="circle-check-solid"
          size="small"
          style="vertical-align: middle"
        />
        <SoonaIcon
          v-else
          name="plus"
          size="small"
          style="vertical-align: middle"
        />
        {{ day.label }}
      </SoonaButton>
      <div
        :id="`availability-panel-${day.value}`"
        :ref="`availability-panel-${day.value}`"
        :aria-labelledby="`availability-header-${day.value}`"
        class="selection-wrapper selection-wrapper--time"
        role="region"
      >
        <SoonaButton
          v-for="slot in slots"
          :key="slot.value"
          :aria-disabled="!editMode"
          :aria-pressed="checkedRangeAvailability(day.value, slot.value)"
          class="availability-toggle availability-toggle--time"
          :class="{
            'un-editable': !editMode,
            'availability-toggle--open': isExpanded[day.value],
          }"
          variation="secondary-gray"
          @on-click="toggleSlot(day.value, slot.value)"
        >
          <SoonaIcon
            v-if="checkedRangeAvailability(day.value, slot.value)"
            class="check-icon disabled"
            name="circle-check-solid"
            size="small"
            style="vertical-align: middle"
          />
          <SoonaIcon
            v-else
            name="plus"
            size="small"
            style="vertical-align: middle"
          />
          {{ slot.label }}
        </SoonaButton>
      </div>
    </div>

    <AccountPreferenceActions
      v-if="accountSchedulingPreferencesFlag"
      :account-id="accountId"
      :reservation-id="reservationId"
      :default-is-dirty="defaultIsDirty"
      :edit-mode="editMode"
      :is-dialog="isDialog"
      @reset-to-default="() => (resetToDefault = true)"
      @update:subject-type="handleSetSubjectType"
    />

    <hr />

    <SoonaTextfield
      v-model="notes"
      :disabled="!editMode"
      label="notes to the crew"
      :maxlength="150"
      placeholder="let us know any specific dates that do not work this month"
      type="text"
    />
  </SoonaForm>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.scheduling-preferences {
  &__sub-heading {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__title {
    @include variables_fonts.u-title--heavy;

    margin-bottom: 1rem;
  }

  &__preferred-time-zone {
    margin-bottom: 1rem;
    max-width: variables.$screen-xxs-min;
  }

  .check-icon {
    color: variables.$green-apple-50;
  }

  .selection-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--time {
      width: 80%;
    }

    .availability-toggle {
      align-items: center;
      border-radius: variables.$radius-small;
      margin-bottom: 1rem;
      width: 100%;

      &--day {
        margin-bottom: 1rem;
        width: 100%;
      }

      &--time {
        display: none;
      }

      &--open {
        display: inherit;
        height: auto;
      }

      &.un-editable {
        color: variables.$gray-50;
        background-color: variables.$gray-20;
        border-color: variables.$gray-20;
        cursor: not-allowed;
      }

      &[aria-pressed='true'] {
        border-color: variables.$black-default;
        background-color: variables.$green-apple-10;
        color: variables.$black-default;

        &.un-editable {
          border-color: variables.$black-default;
          background-color: variables.$green-apple-10;
          cursor: not-allowed;
        }
      }

      &:hover,
      &:focus-visible {
        background-color: variables.$green-apple-10;
        box-shadow: variables.$elevation-0;
        transition: box-shadow 0.2s ease-out;

        &.un-editable {
          background-color: variables.$gray-20;
          box-shadow: none;
          cursor: not-allowed;
          transition: none;
        }

        &[aria-pressed='true'] {
          background-color: variables.$green-apple-10;
          box-shadow: none;
          transition: none;
        }
      }
    }
  }
}
</style>

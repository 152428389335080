import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param { ref } accountId
 * @param { ref } collectionId
 * @returns UseMutationReturnType<*>
 */
export function useRecommendAnimatedCollection(accountId, collectionId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.put(
        `/accounts/${toValue(accountId)}/animated_collections/${toValue(collectionId)}/recommend`
      );
      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.collectionsDA(accountId, 'animated_collection'),
        }),
      ]);
    },
  });
}

import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {import('vue').MaybeRefOrGetter<string | number>} accountId
 * @param {Object} [queryOptions]
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useAccountCreditInfo(accountId, queryOptions = undefined) {
  return useQuery({
    queryKey: queryKeys.accountCreditInfo(accountId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/accounts/${toValue(accountId)}/credit_info`,
        { signal }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import basicCoin from '@images/subscriptions/basic_coin@2x.png';
import businessCoin from '@images/subscriptions/business_coin@2x.png';
import proCoin from '@images/subscriptions/pro_coin@2x.png';
import standardCoin from '@images/subscriptions/standard_coin@2x.png';
import creativityUnlocked from '@images/subscriptions/creativity_unlocked@2x.png';
import scalabilityUnlocked from '@images/subscriptions/scalability_unlocked@2x.png';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import StepLayout from '@/components/modal-payment-flows/subscriptions/StepLayout.vue';
import FeatureList from '@/components/subscriptions/FeatureList.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  context: {
    type: String,
    required: true,
  },
  flow: {
    type: String,
    required: true,
  },
  selectedTier: {
    default: () => ({}),
    type: Object,
  },
  showBackButton: {
    default: false,
    type: Boolean,
  },
  subContext: {
    type: String,
    required: true,
  },
  offerTrial: {
    default: false,
    type: Boolean,
  },
});

const emits = defineEmits(['close']);

const { linkClicked } = useBaseEvents();

const apolloBusinessTierFlag = useFlag('apollo_enterprise_business_tier');

const bottomImageMap = {
  'tier-one': creativityUnlocked,
  'tier-two': creativityUnlocked,
  'tier-three': scalabilityUnlocked,
};

const topImageMap = {
  'tier-one': basicCoin,
  'tier-two': apolloBusinessTierFlag.value ? standardCoin : proCoin,
  'tier-three': businessCoin,
};

const selectedTier = computed(() => props.selectedTier);
const tierTitle = computed(() => selectedTier.value?.name);
const tierBottomImage = computed(
  () => bottomImageMap[selectedTier.value?.slug]
);
const tierTopImage = computed(() => topImageMap[selectedTier.value?.slug]);
const tierIcon = computed(() => selectedTier.value?.product.icon_name);
const tierSlug = computed(() => selectedTier.value?.slug);
const upgraded = computed(() => {
  return props.flow === 'tierUpgrade' || props.flow === 'billingCycleUpgrade';
});

const handleClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'return to dashboard',
    linkHref: null,
  });

  emits('close');
};
</script>

<template>
  <StepLayout
    class="congrats-step"
    :show-back-button="showBackButton"
    :tier-icon="tierIcon"
    :tier-slug="tierSlug"
    :tier-title="tierTitle"
    @close="emits('close')"
  >
    <template #heading>
      <template v-if="upgraded"> Subscription plan updated </template>
      <template v-else>
        welcome! you’re now a soona {{ tierTitle }} member.
      </template>
    </template>
    <template #subheading>
      your subscription perks are ready for immediate access. simply return to
      your dashboard to begin creating.
    </template>
    <div v-if="offerTrial" class="congrats-step__trial-features-list">
      <span class="u-badge--small">to get started</span>
      <FeatureList
        :feature-list="selectedTier?.product?.congrats_feature_list"
      />
    </div>
    <div class="congrats-step__action-btns">
      <SoonaButton
        variation="solid-black"
        data-cypress="button-subscriptions-dialog-continue"
        @on-click="handleClick"
      >
        continue
      </SoonaButton>
    </div>
    <template #main-image>
      <img
        class="congrats-step__star-top-left"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img
        class="congrats-step__star-top-right"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img
        class="congrats-step__star-bottom-right"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <div class="congrats-step__img-wrapper">
        <img class="congrats-step__top-img" :src="tierTopImage" alt="" />
        <img class="congrats-step__bottom-img" :src="tierBottomImage" alt="" />
      </div>
    </template>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.congrats-step {
  &__action-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: auto;
    padding-top: 2rem;
  }

  &__star-top-left {
    height: 3.9375rem !important;
    left: 2rem;
    position: absolute;
    top: 7rem;
    width: 3.9375rem !important;
  }

  &__star-top-right {
    height: 5.625rem !important;
    position: absolute;
    right: 3rem;
    top: 1.5rem;
    width: 5.625rem !important;
  }

  &__star-bottom-right {
    bottom: 3rem;
    height: 3.0625rem !important;
    position: absolute;
    right: 2rem;
    width: 3.0625rem !important;
  }

  &__img-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
  }

  &__top-img {
    max-width: 12.75rem;
  }

  &__bottom-img {
    max-width: 17.5rem;
  }

  &__trial-features-list {
    & .u-badge--small {
      display: block;
      color: variables.$gray-60;
      padding-bottom: 1rem;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useRuleIcon } from '@/components/user/anytime/competitive_analysis/compare/useRuleIcon';
import { FriendlyRed30 } from 'src/variables.module.scss';

const props = defineProps({
  rule: { type: Object, required: true },
});

const pass = computed(() => {
  return props.rule.score === 1;
});

const qualifyingImages = computed(() => {
  return props.rule.images.length;
});

const recommendationText = computed(() => {
  if (pass.value) {
    return null;
  }
  return 'add 1';
});
</script>

<template>
  <li class="result__wrapper">
    <div class="result__icon">
      <SoonaIcon :name="useRuleIcon(rule.rule)" />
    </div>
    <div class="result__content">
      <span class="result__title--wrapper">
        <h4 class="result__title u-body--heavy">
          <span> {{ rule.rule }}:</span>
          <span>{{ qualifyingImages }}/1</span>
        </h4>
        <SoonaIcon
          v-if="pass"
          class="result__icon--pass"
          name="circle-check-solid"
          size="small"
        />
        <SoonaFlag v-else :background-color="FriendlyRed30">
          <template #icon-left>
            <SoonaIcon
              class="result__icon--fail"
              name="status-dot-small"
              size="small"
            />
          </template>
          <template #title>
            {{ recommendationText }}
          </template>
        </SoonaFlag>
      </span>

      <p class="result__description">{{ rule.rule_description }}</p>
    </div>
  </li>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.result {
  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &__title {
    display: flex;
    gap: 0.5rem;
    &--wrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__icon {
    &--pass {
      color: variables.$avo-toast-40;
    }
    &--fail {
      color: variables.$friendly-red-50;
    }
  }
}
</style>

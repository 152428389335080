<script setup>
import { computed, toRefs } from 'vue';
import { useReservation } from '@/queries/useReservation';
import { useUpdateReservation } from '@/queries/useUpdateReservation';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';

const props = defineProps({
  reservationId: {
    type: String,
    required: true,
  },
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const {
  data: reservationData,
  isLoading: isLoadingReservation,
  error: reservationLoadingError,
} = useReservation(reservationId);

const selectedPointOfContactId = computed(
  () => reservationData.value?.point_of_contact_user_id
);

const pointOfContactOptions = computed(
  () =>
    reservationData.value?.account?.users.map(u => ({
      label: `${u.name} - ${u.email}`,
      value: u.id,
    })) ?? []
);

const {
  mutate: updateReservation,
  isPending: isUpdatingReservation,
  error: updateReservationError,
} = useUpdateReservation(reservationId);

const isBusy = computed(() => {
  return isLoadingReservation.value || isUpdatingReservation.value;
});

const userAccountMismatchError = computed(() =>
  selectedPointOfContactId.value &&
  reservationData.value?.account &&
  !reservationData.value.account.users
    .map(user => user.id)
    .includes(selectedPointOfContactId.value)
    ? new Error(
        `the selected user is not a member of the ${reservationData.value.account.name} account`
      )
    : null
);

const { stepIsIncomplete } = useIncompleteSteps(reservationData, slug);

const priorityError = usePriorityError(
  updateReservationError,
  reservationLoadingError,
  stepIsIncomplete
);

function onSetPointOfContact(newValue) {
  updateReservation({
    point_of_contact_user_id: newValue,
  });
}
</script>

<template>
  <SoonaSelect
    :model-value="selectedPointOfContactId"
    placeholder="this person will receive all communications"
    :options="pointOfContactOptions"
    :loading="isBusy"
    :disabled="isBusy"
    label="label"
    :reduce="({ value }) => value"
    @update:model-value="onSetPointOfContact"
  >
    <template #label>customer point of contact</template>
  </SoonaSelect>

  <SoonaAlert v-if="userAccountMismatchError">
    {{ userAccountMismatchError.message }}
  </SoonaAlert>

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

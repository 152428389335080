<script setup>
import { computed, ref, watchEffect } from 'vue';
import DeleteAlbumConfirmDialog from '@/components/account_gallery/collections/DeleteAlbumConfirmDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import { useAlbumCollection } from '@/queries/album_collections/useAlbumCollection';
import { useDeleteAlbumCollection } from '@/queries/album_collections/useDeleteAlbumCollection';
import { usePriorityError } from '@/composables/usePriorityError';
import { useRouter } from 'vue-router';
import { useUpdateAlbumCollection } from '@/queries/album_collections/useUpdateAlbumCollection';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  collectionId: {
    type: [String, Number],
    required: true,
  },
});

const emit = defineEmits(['editCollectionMode']);
const router = useRouter();
const accountId = computed(() => +props.accountId);
const collectionId = computed(() => +props.collectionId);
const deleteAlbumConfirm = ref(false);
const editCollectionNameMode = ref(false);
const title = ref('');

const { data: collection } = useAlbumCollection({
  accountId,
  collectionId,
});

const {
  mutate: updateAlbum,
  isPending: isUpdatingAlbum,
  error: updateAlbumError,
} = useUpdateAlbumCollection(accountId);

const {
  mutate: deleteAlbumCollection,
  isPending: isDeletingAlbum,
  error: deleteAlbumError,
} = useDeleteAlbumCollection(accountId);

const priorityErrorHeader = usePriorityError(
  updateAlbumError,
  deleteAlbumError
);

const toggleForm = active => {
  editCollectionNameMode.value = active;
  emit('editCollectionMode', active);
};

function closeEditCollectionNameMode() {
  toggleForm(false);
  title.value = collection.value?.title;
}

const isBusy = computed(() => isUpdatingAlbum.value || isDeletingAlbum.value);
const isDirty = computed(() => {
  return title.value?.trim().length && title.value !== collection.value?.title;
});

const updateAlbumTitle = event => {
  const data = new FormData(event.target);
  const title = data.get('title')?.trim();

  if (title.length < 1) {
    return;
  }

  updateAlbum(
    {
      collectionId,
      body: {
        title,
      },
    },
    {
      onSuccess: () => {
        closeEditCollectionNameMode();
      },
    }
  );
};

function deleteAlbum() {
  if (!collectionId.value) return;

  deleteAlbumCollection(
    { collectionId: collectionId.value },
    {
      onSuccess: async () => {
        await router.push({
          name: 'account-gallery',
          params: { accountId: accountId.value },
        });
      },
      onSettled: () => {
        deleteAlbumConfirm.value = false;
        toggleForm(false);
      },
    }
  );
}

watchEffect(() => {
  title.value = collection.value?.title;
});
</script>

<template>
  <div class="edit-album">
    <div class="edit-album__header">
      <div
        v-if="!editCollectionNameMode"
        class="edit-album__header__heading-wrap"
      >
        <SoonaTooltip placement="top">
          <template
            #default="{
              setRef,
              mouseenter,
              focus,
              mouseleave,
              blur,
              ariaDescribedby,
            }"
          >
            <SoonaButton
              :ref="setRef"
              variation="icon-plain-gray"
              :aria-describedby="ariaDescribedby"
              @mouseenter="mouseenter"
              @focus="focus"
              @mouseleave="mouseleave"
              @blur="blur"
              @on-click="toggleForm(true)"
            >
              <SoonaIcon name="pen-square" />
              <span class="u-visually-hidden">edit album name</span>
            </SoonaButton>
          </template>
          <template #tooltip-content>edit album name</template>
        </SoonaTooltip>
      </div>
      <SoonaForm
        v-else
        class="edit-album__header__form"
        @submit="updateAlbumTitle"
      >
        <SoonaTextfield
          v-model="title"
          name="title"
          label="album title"
          placeholder="edit album name"
          class="edit-album__header__title-field"
        />
        <div class="edit-album__header__buttons">
          <SoonaButton
            variation="primary"
            type="submit"
            :disabled="!isDirty || isBusy"
            :loading="isUpdatingAlbum"
          >
            save
          </SoonaButton>
          <SoonaButton
            variation="secondary-gray"
            :disabled="isBusy"
            @on-click="closeEditCollectionNameMode"
          >
            cancel
          </SoonaButton>
          <SoonaTooltip placement="top">
            <template
              #default="{
                setRef,
                mouseenter,
                focus,
                mouseleave,
                blur,
                ariaDescribedby,
              }"
            >
              <SoonaButton
                :ref="setRef"
                variation="icon-gray-outline"
                :disabled="isBusy"
                :loading="isDeletingAlbum"
                :aria-describedby="ariaDescribedby"
                @on-click="deleteAlbumConfirm = true"
                @mouseenter="mouseenter"
                @focus="focus"
                @mouseleave="mouseleave"
                @blur="blur"
              >
                <SoonaIcon name="trash" />
                <span class="u-visually-hidden">delete album</span>
              </SoonaButton>
            </template>
            <template #tooltip-content>delete album</template>
          </SoonaTooltip>
        </div>
      </SoonaForm>
      <SoonaError v-if="priorityErrorHeader">
        {{ priorityErrorHeader }}
      </SoonaError>
    </div>
    <DeleteAlbumConfirmDialog
      v-if="deleteAlbumConfirm"
      @close="deleteAlbumConfirm = false"
      @delete="deleteAlbum"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.edit-album {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;

    &__heading-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      flex-flow: row wrap;
      gap: 0.5rem;
    }

    &__heading {
      align-self: center;
    }

    &__form {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: flex-end;
    }

    &__title-field {
      padding-bottom: 0;
    }

    &__buttons {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
</style>

<script setup>
import { computed, ref } from 'vue';
import { useAccount } from '@/composables/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
// TODO: APL-1207 apollo_subscription_upgrades
import { useFlag } from '@/composables/useFlag';
import { useMe } from '@/composables/user/useMe';
import { useRoute } from 'vue-router';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaSubscriptionBadge from 'src/components/ui_library/SoonaSubscriptionBadge.vue';

const route = useRoute();
const { linkClicked } = useBaseEvents();
const apolloTrialForEveryoneFlag = useFlag('apollo_trial_for_everyone');
// TODO: APL-1207 apollo_subscription_upgrades
const canUpgradeFlag = useFlag('apollo_subscription_upgrades');
const { currentAccountId } = useMe();
const {
  account,
  isBusinessSubscriber,
  eligibleForPlatformSubscriptionTrial,
  isLoading,
} = useAccount(currentAccountId);

const linkLabel = 'open a dialog to purchase a subscription';
const showDialog = ref(false);

const subscription = computed(() => account.value?.subscription);
const tierIconName = computed(() => subscription.value?.tier?.icon_name);
const tierShortName = computed(() => subscription.value?.tier?.short_name);
const tierSlug = computed(() => {
  if (account.value?.is_digital_subscriber)
    return subscription.value?.tier?.slug;
  return null;
});
const tierTitle = computed(() => {
  if (!tierShortName.value) return 'free';
  return tierShortName.value;
});
const planCopy = computed(() => {
  if (
    apolloTrialForEveryoneFlag.value &&
    eligibleForPlatformSubscriptionTrial.value
  )
    return 'try our AI tools';
  if (!tierShortName.value) return 'unlock our AI tools';
  return `your plan: ${tierShortName.value}`;
});

const handleCloseDialog = () => (showDialog.value = false);

const handleOpenDialog = () => {
  showDialog.value = true;

  linkClicked({
    context: route.meta.context,
    subContext: 'platform sidebar',
    linkLabel: linkLabel,
    linkHref: null,
  });
};

const linkToManagePlan = computed(() => {
  return (
    isBusinessSubscriber.value ||
    (!canUpgradeFlag.value &&
      (account.value?.is_trialing || tierTitle.value !== 'free'))
  );
});
</script>

<template>
  <div class="subscription-purchase">
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <!-- TODO: APL-1207 apollo_subscription_upgrades -->
    <component
      :is="linkToManagePlan ? 'a' : 'button'"
      v-else
      :class="{
        'u-button-reset': !linkToManagePlan,
      }"
      class="subscription-purchase__button"
      data-cypress="sidebar-subscription-button"
      :href="
        linkToManagePlan
          ? `/user/anytime#/account/${currentAccountId}/subscriptions`
          : undefined
      "
      v-on="!linkToManagePlan ? { click: handleOpenDialog } : {}"
    >
      <SoonaSubscriptionBadge
        :icon-name="tierIconName"
        icon-size="x-small"
        :tier-title="tierTitle"
        :tier-slug="tierSlug"
      />
      <span class="u-label--heavy subscription-purchase__plan">
        {{ planCopy }}
      </span>
      <SoonaIcon
        class="subscription-purchase__arrow"
        name="arrow-right"
        size="small"
      />
      <span class="u-visually-hidden">{{ linkLabel }}</span>
    </component>
    <SubscriptionsDialog
      v-if="showDialog"
      flow="pricingAndCheckout"
      @close="handleCloseDialog"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.subscription-purchase {
  border-radius: 0.625rem;
  position: relative;

  &__button {
    align-items: center;
    border-radius: 0.5rem;
    color: variables.$white-default;
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem;
    width: 100%;

    &:focus-visible {
      outline: 0.125rem solid variables.$black-default;
    }
  }

  &__plan {
    flex-grow: 1;
    text-align: left;
  }

  &__arrow {
    margin-left: auto;
  }
}
</style>

import { useMutation } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';
import { useMe } from '@/composables/user/useMe';
import { http } from '@/config/vue-axios';

/**
 * @param {object} options
 * @param {Function} options.onSuccess
 */
export function useCreateAccount({ onSuccess }) {
  const { resetMe } = useMe();

  return useMutation({
    mutationFn: async attributes => {
      const response = await http.post(`/accounts.json`, attributes);
      return response.data;
    },
    onSuccess: async () => {
      // reload absolutely everything
      await resetMe();

      if (onSuccess) {
        await onSuccess();
      }
    },
    onError: error =>
      Sentry.captureException(new Error('failed to create account'), {
        extra: { error },
      }),
  });
}

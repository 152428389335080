import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getHighlightedPacks } from '../api/highlightedPacks';

export function useGetHighlightedPacks(
  { reservationId, industrySlug, numberOfPacks },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.highlightedPacks({
      reservationId,
      industrySlug,
      numberOfPacks,
    }),
    queryFn: ({ signal }) =>
      getHighlightedPacks(
        {
          reservationId: unref(reservationId),
          industrySlug: unref(industrySlug),
          numberOfPacks: unref(numberOfPacks),
        },
        signal
      ),
    ...queryOptions,
  });
}

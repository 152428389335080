<script setup>
import { computed, onMounted } from 'vue';
import CompetitorsPopover from './CompetitorsPopover.vue';
import CompetitorOverview from './CompetitorOverview.vue';
import { useExampleCompetitorStaffPick } from '@/queries/useExampleCompetitorStaffPicks';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import CompetitorDetailTextContent from './CompetitorDetailTextContent.vue';
import CompetitorDetailRuleResult from './CompetitorDetailRuleResult.vue';
import CompetitorDetailVisualMix from './CompetitorDetailVisualMix.vue';
import CompetitorDetailImageQuality from './CompetitorDetailImageQuality.vue';
import useCompetitorsPopoverBackRoute from './useCompetitorsPopoverBackRoute';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import ListingPickerDialog from './pickers/ListingPickerDialog.vue';
import PickerListingOption from './pickers/PickerListingOption.vue';
import useSelectListing from '@/components/user/anytime/competitive_analysis/pickers/useSelectListing';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  competitorId: {
    type: [String, Number],
    required: true,
  },
});

const { buttonClicked, pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

const accountId = computed(() => props.accountId);
const competitorId = computed(() => props.competitorId);

const { handleClose } = useCompetitorsPopoverBackRoute();

const {
  data: competitorData,
  isSuccess,
  error,
} = useExampleCompetitorStaffPick(accountId, competitorId);

const competitor = computed(() => competitorData.value ?? null);
const integrationId = computed(() => competitor.value.integration_id ?? null);

const galleryCountRuleResult = computed(
  () =>
    competitor.value.listing_score.rule_results.find(
      rr => rr.rule_group === 'gallery count'
    )?.rules[0]
);

const trackCompareClicked = () => {
  buttonClicked({
    context: 'competitive analysis',
    subContext: 'example competitor detail',
    buttonLabel: 'compare',
    buttonAction: 'handleClick',
  });
};

const { select, pickerIsOpen } = useSelectListing(
  competitor,
  integrationId,
  accountId
);

const handleClick = () => {
  pickerIsOpen.value = true;
  trackCompareClicked();
};

const priorityError = usePriorityError(error);
</script>

<template>
  <CompetitorsPopover @close="handleClose">
    <template #header>
      <h1 class="competitor-detail__header u-headline--heavy">competitor</h1>
    </template>
    <template #content>
      <div class="competitor-detail__body--container">
        <div class="competitor-detail__body">
          <div class="competitor-detail__body--header">
            <h3 class="u-title--heavy">competitor</h3>
            <SoonaButton
              variation="secondary-black"
              size="medium"
              @click="handleClick"
            >
              compare
            </SoonaButton>
          </div>
          <SoonaError v-if="priorityError">
            {{ priorityError }}
          </SoonaError>
          <template v-if="isSuccess">
            <CompetitorOverview :competitor="competitor" />
            <CompetitorDetailRuleResult :rule="galleryCountRuleResult" />
            <CompetitorDetailVisualMix :competitor="competitor" />
            <CompetitorDetailImageQuality :competitor="competitor" />
            <CompetitorDetailTextContent :competitor="competitor" />
          </template>
        </div>
      </div>
    </template>
  </CompetitorsPopover>
  <ListingPickerDialog
    v-if="pickerIsOpen"
    :integration-id="integrationId"
    :account-id="accountId"
    @cancel="pickerIsOpen = false"
  >
    <template #heading>choose listing to compare</template>
    <template #listing-options="{ listings }">
      <PickerListingOption
        v-for="listing in listings"
        :key="listing.id"
        :listing="listing"
        @select="select"
      />
    </template>
  </ListingPickerDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.competitor-detail {
  &__body {
    max-width: 45rem;
    background-color: variables.$white-default;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-left: 0.0625rem solid variables.$gray-30;
    border-right: 0.0625rem solid variables.$gray-30;

    &--header {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}
</style>

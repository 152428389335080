<script setup>
import { computed, provide, ref, toRefs } from 'vue';
import MediaEditor from '@/components/user/anytime/gallery/media-editor/MediaEditor.vue';
import { useDigitalAsset } from '@/queries/digital_assets/useDigitalAsset';
import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
  MEDIA_POPOVER_BACK_TEXT,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useRouter } from 'vue-router';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useMe } from '@/composables/user/useMe';
import { useCreateListingActionDigitalAsset } from '@/queries/useListingActionDigitalAssets';
import { useListingAction } from '@/queries/useListingActions';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [Number, String],
    required: true,
  },
  listingActionId: {
    type: [Number, String],
    required: true,
  },
});

const router = useRouter();
const { currentAccountId } = useMe();
const { accountId, digitalAssetId, listingActionId } = toRefs(props);

const isMyAccount = computed(
  () => currentAccountId.value === Number(props.accountId)
);

const params = {
  origin: 'customer',
  ownership: 'customer',
  visibility: 'all',
};

const {
  data: file,
  isLoading: isFileLoading,
  error: fileLoadingError,
} = useDigitalAsset({ accountId, assetId: digitalAssetId, params }, {});

const accountName = computed(() => file.value?.account_name);

const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'listing-action-media-view',
    params: {
      accountId: accountId.value,
      digitalAssetId: id,
    },
  };
};

const previousRouteWasListingDetailPage = computed(() =>
  window.history.state.back.includes('active-listing')
);
const backUrl = computed(() => {
  if (previousRouteWasListingDetailPage.value) {
    return window.history.state.back;
  }
  return `/account/${accountId.value}/listing-insights/action/lifestyle`;
});

const { data: listingAction } = useListingAction(accountId, listingActionId, {
  enabled: previousRouteWasListingDetailPage.value,
});

const backText = computed(() => {
  if (previousRouteWasListingDetailPage.value)
    return listingAction?.value?.catalog_item_name ?? null;
  // to do - dynamic text based on action type
  if (isMyAccount.value) return `my lifestyle content opportunities`;
  if (accountName.value)
    return `${accountName.value}’s lifestyle content opportunities`;
  return `lifestyle content opportunities`;
});

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, null);
provide(MEDIA_POPOVER_NEXT_KEY, null);
provide(MEDIA_POPOVER_BACK_TEXT, backText);

const isRedirecting = ref(false);

const handleClose = event => {
  if (event?.delivered_asset_id) {
    router.push(createRouteLocation(event.delivered_asset_id));
  } else {
    isRedirecting.value = true;
    router.push(backUrl.value);
  }
};

// listing action module
const {
  mutate: createListingActionDigitalAsset,
  error: createListingActionDigitalAssetError,
} = useCreateListingActionDigitalAsset(accountId);

const linkUploadedAsset = digitalAssetId => {
  createListingActionDigitalAsset(
    {
      digital_asset_id: digitalAssetId,
      listing_action_id: listingActionId.value,
    },
    {
      onSuccess: () => {
        isRedirecting.value = true;
        if (previousRouteWasListingDetailPage.value) {
          router.push(window.history.state.back);
          // manual query invalidation needed? seems to work locally without it
        } else {
          router.push({
            name: 'listing-insights-actions',
            params: {
              accountId: accountId.value,
              actionSlug: 'lifestyle',
            },
          });
        }
      },
    }
  );
};

const priorityError = usePriorityError(
  fileLoadingError,
  createListingActionDigitalAssetError
);
</script>

<template>
  <MediaEditor
    v-if="!isRedirecting"
    :asset="file"
    :is-asset-loading="isFileLoading"
    @close="handleClose($event)"
    @approve-image="linkUploadedAsset($event)"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
  </MediaEditor>
</template>

import { storeToRefs } from 'pinia';

import { useAssistantStore } from './useAssistantStore';
import { useHandleEventStream } from './useHandleEventStream';

export function useRunMessage({ functionHandler }) {
  const store = useAssistantStore();
  const { suspendedToolOutputs } = storeToRefs(store);
  const { handleEventStream, submitActionResult } = useHandleEventStream({
    functionHandler,
  });

  function handleRunMessage({ message, assistantId, includes = [] }) {
    const body = {
      prompt: message,
      thread_id: store.threadId,
      assistant_id: assistantId,
    };

    if (includes.length) {
      body['additional_instructions'] = includes;
    }

    handleEventStream('/api/assistants/run_message', {
      headers: {
        'Content-Type': 'application/json',
      },
      payload: JSON.stringify(body),
    });
  }

  function submitSuspendedActionResult(name, result) {
    const toolCallOutputs = suspendedToolOutputs.value;
    const toolIndex = toolCallOutputs.findIndex(tool => tool.name === name);

    toolCallOutputs[toolIndex] = {
      ...toolCallOutputs[toolIndex],
      suspend: false,
      toolOutput: {
        ...toolCallOutputs[toolIndex].toolOutput,
        output: {
          ...toolCallOutputs[toolIndex].toolOutput.output,
          ...result,
        },
      },
    };

    if (toolCallOutputs.some(({ suspend }) => suspend)) {
      return store.setSuspendedToolOutputs(toolCallOutputs);
    }

    submitActionResult(
      toolCallOutputs[0].runId,
      store.threadId,
      toolCallOutputs.map(output => ({
        ...output.toolOutput,
      }))
    );
  }

  return {
    handleRunMessage,
    submitSuspendedActionResult,
  };
}

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { resetPack } from '@/api/packReservation';

export function useResetPack() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: reservationId => resetPack(reservationId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
  });
}

<script setup>
import { computed, ref, watch } from 'vue';
import { keepPreviousData } from '@tanstack/vue-query';
import { useStaffPicksCollectionDigitalAssets } from '@/queries/staff_picks_collection_digital_assets/useStaffPicksCollectionDigitalAssets';
import CarouselBase from '@/components/user/anytime/gallery/media-editor/CarouselBase.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';

const props = defineProps({
  staffPicksCollectionId: {
    type: [String, Number],
    required: true,
  },
  activeAssetId: {
    type: [String, Number],
    default: null,
  },
  albumTitle: {
    type: String,
    default: null,
  },
});

const activeAssetId = computed(() => props.activeAssetId);
const staffPicksCollectionId = computed(() => props.staffPicksCollectionId);
const currentPage = ref(null);
const userChangeablePage = ref(0);

watch([activeAssetId, staffPicksCollectionId], () => {
  currentPage.value = null;
  userChangeablePage.value = 0;
});

const { data: filePages, isLoading } = useStaffPicksCollectionDigitalAssets(
  staffPicksCollectionId,
  {
    activeAssetId,
    currentPage,
    itemsPerPage: 12,
  },
  {
    placeholderData: keepPreviousData,
    enabled: computed(() => !!activeAssetId.value),
  }
);

watch(userChangeablePage, newValue => {
  if (newValue > 0) {
    currentPage.value = newValue;
  }
});
</script>

<template>
  <CarouselBase
    v-if="filePages?.assets?.length > 0 && !isLoading"
    :file-pages="filePages"
    @update-page="pageNum => (userChangeablePage = pageNum)"
  >
    <template #title>{{ albumTitle }} gallery</template>
    <router-link
      v-for="spcda in filePages?.assets"
      :key="spcda.id"
      class="carousel-base__img-link"
      :class="{
        'carousel-base__img-link--active':
          activeAssetId === spcda.digital_asset?.id,
      }"
      :to="{
        name: 'expert-picks-assets-media-view',
        params: {
          accountId: spcda.digital_asset?.account_id,
          digitalAssetId: spcda.digital_asset?.id,
        },
      }"
    >
      <DigitalAssetImage :src="spcda.digital_asset?.preview?.url" />
    </router-link>
  </CarouselBase>
</template>

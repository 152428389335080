import { computed, ref, watch, watchEffect } from 'vue';

export const useSelectedShotOptions = (
  originalScene,
  shootType,
  contentTypeOptions,
  environmentTypeOptions
) => {
  const contentType = ref(undefined);
  const environmentType = ref(undefined);
  const environmentFollowup = ref(undefined);
  const selectedCatalogItems = ref([]);
  const selectedSoonaPropTags = ref([]);
  const referenceImages = ref([]);
  const description = ref('');
  // shot subjects and custom props are here to support scenes built with the original shot list builder.
  const shotSubjects = ref([]);
  const customProps = ref([]);

  const defaultContentTypeId = computed(() => {
    if (originalScene.value) {
      return originalScene.value.shot_tags?.find(
        x => x.tag.tag_category_title === 'Content'
      )?.tag_id;
    }
    return (
      contentTypeOptions.value?.find(
        x => x.title.toLowerCase() === shootType.value
      )?.id ?? undefined
    );
  });

  const defaultEnvironmentTypeId = computed(() => {
    if (originalScene.value) {
      return originalScene.value.shot_tags?.find(
        x => x.tag.tag_category_title === 'Environment'
      )?.tag_id;
    }
    return undefined;
  });

  const defaultEnvironmentFollowupId = computed(() => {
    if (originalScene.value) {
      const environmentTag = environmentTypeOptions.value.find(
        x => x.id === defaultEnvironmentTypeId.value
      );
      if (environmentTag?.tag_follow_up_categories.length > 0) {
        const tagCategoryId =
          environmentTag.tag_follow_up_categories[0].tag_category_id;
        return originalScene.value.shot_tags?.find(
          x => x.tag.tag_category_id === tagCategoryId
        )?.tag_id;
      }
    }
    return undefined;
  });

  // deselect any backgrounds if the user changes to a different environment
  watch(
    environmentType,
    (newVal, oldVal) => {
      if (newVal && oldVal !== undefined) {
        environmentFollowup.value = undefined;
      }
    },
    { immediate: true }
  );

  const defaultSelectedCatalogItems = computed(() => {
    return originalScene.value?.catalog_items ?? [];
  });

  const defaultSelectedSoonaPropTags = computed(() => {
    if (originalScene.value) {
      let propTags = [];
      originalScene.value.shot_tags?.forEach(shotTag => {
        if (shotTag.tag.tag_category_title === 'Props') {
          propTags.push(shotTag.tag);
        }
      });
      return propTags;
    }
    return [];
  });

  const defaultReferenceImageTagIds = computed(() => {
    if (originalScene.value) {
      let referenceImageIds = [];
      originalScene.value.shot_tags?.forEach(shotTag => {
        if (shotTag.tag.tag_category_title === 'Reference Image') {
          referenceImageIds.push(shotTag.tag_id);
        }
      });
      return referenceImageIds;
    }
    return [];
  });

  const defaultDescription = computed(() => {
    if (originalScene.value) {
      return originalScene.value.description;
    }
    return '';
  });

  const defaultShotSubjects = computed(() => {
    if (originalScene.value) {
      let shotSubjectIds = [];
      originalScene.value.shot_tags?.forEach(shotTag => {
        if (shotTag.tag.tag_category_title === 'Shot Subject') {
          shotSubjectIds.push(shotTag.tag_id);
        }
      });
      return shotSubjectIds;
    }
    return [];
  });

  const defaultCustomProps = computed(() => {
    if (originalScene.value) {
      let customProps = [];
      originalScene.value.shot_tags?.forEach(shotTag => {
        if (shotTag.tag.tag_category_title === 'Custom Props') {
          customProps.push(shotTag.tag_id);
        }
      });
      return customProps;
    }
    return [];
  });

  watchEffect(() => {
    contentType.value = defaultContentTypeId.value;
    environmentType.value = defaultEnvironmentTypeId.value;
    environmentFollowup.value = defaultEnvironmentFollowupId.value;
    selectedCatalogItems.value = defaultSelectedCatalogItems.value;
    selectedSoonaPropTags.value = defaultSelectedSoonaPropTags.value;
    referenceImages.value = defaultReferenceImageTagIds.value;
    description.value = defaultDescription.value;
    shotSubjects.value = defaultShotSubjects.value;
    customProps.value = defaultCustomProps.value;
  });

  return {
    contentType,
    environmentType,
    environmentFollowup,
    selectedCatalogItems,
    selectedSoonaPropTags,
    referenceImages,
    description,
    shotSubjects,
    customProps,
  };
};

<script setup>
defineProps({
  assets: {
    type: Array,
    required: true,
  },
  selectedAsset: {
    type: [String, Number],
    required: false,
  },
});

const emits = defineEmits(['asset-selected']);
</script>
<template>
  <div class="soona-selectable-assets__asset-slots">
    <template v-if="assets.length === 0">
      <p class="soona-selectable-assets__no-results">
        <slot name="no-results-text" />
      </p>
    </template>
    <template v-else>
      <div
        v-for="(asset, index) in assets"
        :key="index"
        class="soona-selectable-assets__asset-slot"
        :class="[
          {
            'soona-selectable-assets__asset-slot--selected':
              Number(selectedAsset) === asset.id,
          },
        ]"
        @click="() => emits('asset-selected', asset.id)"
      >
        <div class="soona-selectable-assets__asset-wrapper">
          <img class="soona-selectable-assets__asset" :src="asset.file.url" />
        </div>
      </div>
    </template>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.soona-selectable-assets {
  align-items: flex-start;
  display: flex;

  &__asset-slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
    gap: 1rem;

    @media (min-width: variables.$screen-sm-min) {
      justify-content: flex-start;
    }
  }

  &__asset-wrapper {
    position: relative;
    height: 10rem;
    background-color: variables.$gray-20;
    border-radius: 0.3125rem;
  }

  &__asset {
    height: 100%;
  }

  &__asset-slot {
    position: relative;
    width: 100%;
    text-align: center;
    cursor: pointer;
    border-radius: 0.3125rem;

    &--selected {
      outline-color: variables.$periwink-blue-60;
      outline-style: solid;
      outline-width: 0.125rem;
      outline-offset: 0.1rem;
    }

    @media (min-width: variables.$screen-xs-min) {
      width: 45%;
    }

    @media (min-width: variables.$screen-sm-min) {
      width: 31.5%;
    }
  }

  &__no-results {
    color: variables.$gray-70;
    background-color: variables.$gray-10;
    border-radius: 0.3125rem;
    padding: 1rem;
  }
}
</style>

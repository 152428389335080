<script setup>
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { useDeleteAccountOrder } from '@/queries/orders/useDeleteAccountOrder';
import { toRefs } from 'vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  orderId: {
    type: [Number, String],
    required: true,
  },
});

const emits = defineEmits(['close', 'success']);

const { accountId, orderId } = toRefs(props);

const { mutate, isPending, error } = useDeleteAccountOrder(accountId, orderId);

const confirmDeclineOrder = () => {
  mutate(undefined, {
    onSuccess: () => {
      emits('success');
    },
  });
};

const priorityErrors = usePriorityErrors(error);
</script>
<template>
  <SoonaDialog
    role="alertdialog"
    class="decline-order-dialog"
    @close="emits('close')"
  >
    <SoonaLoading v-if="isPending" is-loading is-contained />
    <SoonaError
      v-if="priorityErrors"
      :priority-errors="priorityErrors"
      no-margin
    />
    <p>Confirm you would like to decline this order.</p>
    <template #heading>decline order</template>
    <template #footer>
      <SoonaButton
        class="decline-order-dialog__cancel"
        size="medium"
        variation="tertiary"
        :disabled="isPending"
        @on-click="emits('close')"
      >
        Cancel
      </SoonaButton>
      <SoonaButton
        class="decline-order-dialog__confirm"
        size="medium"
        :disabled="isPending"
        @on-click="confirmDeclineOrder"
      >
        Decline order
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
.decline-order-dialog {
  &__heading,
  &__cancel,
  &__confirm {
    text-transform: lowercase;
  }
}
</style>

<template>
  <div
    class="VideoMessage"
    :style="{ justifyContent: isOutgoing ? 'flex-end' : 'flex-start' }"
  >
    <ChatAvatar v-if="!isOutgoing" :message="message" />
    <div class="image-container">
      <video controls>
        <source :src="message.data.url" type="video/mp4" />
      </video>
      <p v-if="message.caption">{{ message.caption }}</p>
    </div>
    <ChatAvatar v-if="isOutgoing" :message="message" />
  </div>
</template>

<script>
import ChatAvatar from '../ChatAvatar.vue';

export default {
  name: 'VideoMessage',
  components: {
    ChatAvatar,
  },
  props: {
    message: Object,
    isOutgoing: Boolean,
  },
};
</script>

<style lang="scss">
.VideoMessage {
  display: flex;
  padding: 0.75rem 0;
  .image-container {
    display: flex;
    flex-direction: column;
    margin: 0 5px;
    border-radius: 10px;
    width: 140px;
    video {
      object-fit: cover;
      width: 140px;
      height: 120px;
      border-radius: 10px;
    }
    video:hover {
      opacity: 0.7;
    }
  }
}
</style>

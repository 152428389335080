import { useQuery } from '@tanstack/vue-query';
import { getInvoices } from '@/api/invoices';
import { queryKeys } from '@/queries/query-keys';
import { toValue } from 'vue';

/**
 *
 * @param {Ref<string | number>} accountId
 * @param {Object} [params]
 * @param {Ref<number>} [params.currentPage]
 * @param {Ref<number>} [params.itemsPerPage]
 * @param {Ref<string>} [params.orderBy]
 * @param {Ref<string>} [params.orderDir]
 */
export function useInvoices(
  accountId,
  { currentPage, itemsPerPage, orderBy, orderDir } = {}
) {
  return useQuery({
    queryKey: queryKeys.invoices(accountId, {
      currentPage,
      itemsPerPage,
      orderBy,
      orderDir,
    }),
    queryFn: ({ signal }) =>
      getInvoices(
        toValue(accountId),
        {
          currentPage: toValue(currentPage),
          itemsPerPage: toValue(itemsPerPage),
          orderBy: toValue(orderBy),
          orderDir: toValue(orderDir),
        },
        signal
      ),
  });
}

const mqReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

function collapseTransitionEnd(e) {
  // ignore transitioning children
  if (e.target !== e.currentTarget) {
    return;
  }
  e.currentTarget.removeEventListener('transitionend', collapseTransitionEnd);
  e.currentTarget.style.display = 'none';
}

export function collapseSection(element, setClosedCallback) {
  if (!mqReducedMotion || mqReducedMotion.matches) {
    element.style.transition = 'none';
    element.style.display = 'none';
    setClosedCallback();
    return;
  }

  const elementTransition = element.style.transition;
  element.style.removeProperty('transition');
  element.style.display = 'inherit';

  requestAnimationFrame(function () {
    element.style.height = element.scrollHeight + 'px';
    element.style.transition = elementTransition;
    setClosedCallback();

    requestAnimationFrame(function () {
      element.style.height = 0 + 'px';
      element.addEventListener('transitionend', collapseTransitionEnd);
    });
  });
}

function expandTransitionEnd(e) {
  // ignore transitioning children
  if (e.target !== e.currentTarget) {
    return;
  }
  e.currentTarget.removeEventListener('transitionend', expandTransitionEnd);
  e.currentTarget.style.display = 'inherit';
  e.currentTarget.style.height = null;
}

export function expandSection(element) {
  element.style.display = 'inherit';

  if (!mqReducedMotion || mqReducedMotion.matches) {
    element.style.transition = 'none';
    element.style.height = null;
    return;
  }

  element.style.removeProperty('transition');
  element.style.height = element.scrollHeight + 'px';
  element.addEventListener('transitionend', expandTransitionEnd);
}

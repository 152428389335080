import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { mapPaginationHeaders } from '@/lib/api-transforms';

const getNextPageParam = lastPage => {
  const pagination = lastPage?.pagination;

  if (!pagination) return null;
  if (pagination.currentPage >= pagination.totalPages) return null;

  return pagination.currentPage + 1;
};

const getPreviousPageParam = firstPage => {
  const pagination = firstPage?.pagination;

  if (!pagination) return null;
  if (pagination.currentPage <= 1) return null;

  return pagination.currentPage - 1;
};

/**
 *
 * @param {string | Ref<string>} [collectionId]
 * @param {Object} [params]
 * @param {number} [params.itemsPerPage]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useInfiniteReEditsCollectionDigitalAssets(
  collectionId,
  { itemsPerPage = 10, currentPage },
  queryOptions
) {
  return useInfiniteQuery({
    queryKey: queryKeys.collectionAssets(collectionId, {
      itemsPerPage,
      infinite: true,
    }),
    queryFn: async ({ signal, pageParam }) => {
      const params = {};

      if (pageParam) params['page'] = pageParam;
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);

      const response = await http.get(
        `/collections/${toValue(collectionId)}/re_edits_collection_digital_assets`,
        {
          params,
          signal,
        }
      );

      return {
        pagination: mapPaginationHeaders(response.headers),
        assets: response.data?.re_edits_collection_digital_assets || [],
      };
    },
    initialPageParam: currentPage,
    getNextPageParam,
    getPreviousPageParam,
    ...queryOptions,
  });
}

<script setup>
import { computed, toRefs } from 'vue';
import TitleBar from '@/components/shared/TitleBar.vue';
import { WhiteDefault } from '@/variables.module.scss';
import { useCatalogItem } from '@/queries/useCatalog';
import ProductNav from './ProductNav.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from 'src/composables/usePriorityError';

const props = defineProps({
  accountId: { type: String, required: true },
  catalogItemId: { type: String, required: true },
});

const { accountId, catalogItemId } = toRefs(props);

// product module
const { data: productData, error: productError } = useCatalogItem(
  accountId,
  catalogItemId
);

const product = computed(() => productData.value ?? { name: '' });

const disableBackButton = computed(() => document.referrer === '');
const priorityError = usePriorityError(productError);
</script>

<template>
  <div class="product-root">
    <TitleBar
      :title="product.name"
      :background-color="WhiteDefault"
      :border-color="WhiteDefault"
      :display-back-button="!disableBackButton"
      justify="left"
      heading-id="product-detail-heading"
    />
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <div class="product-root__content">
      <ProductNav :account-id="accountId" :catalog-item-id="catalogItemId" />
      <router-view v-slot="{ Component }">
        <component
          :is="Component"
          :account-id="accountId"
          :catalog-item-id="catalogItemId"
        />
      </router-view>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-root {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
</style>

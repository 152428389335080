<script setup>
import { computed } from 'vue';
import { useNotes } from 'src/queries/notes/useNotes';
import BeatLoader from 'src/components/shared/BeatLoader.vue';

const props = defineProps({
  reservationId: {
    required: true,
    type: [Number, String],
  },
});

const reservationId = computed(() => props.reservationId);
const { data, isSuccess } = useNotes('reservations', reservationId);
</script>
<template>
  <h2 class="u-headline--heavy">reservation notes</h2>
  <ul v-if="isSuccess">
    <li v-for="note in data.notes" :key="note.id">
      {{ note.content }} - {{ note.creator?.name }}
    </li>
  </ul>
  <BeatLoader v-else />
</template>

import { toValue } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { attachOrCreateRaw } from '@/api/digitalAssets';
import { queryKeys } from '@/queries/query-keys';

/**
 * @param { Ref<any> } reservationId
 * @returns {UseMutationReturnType<any, unknown, void, unknown>}
 */
export function useAttachOrCreateRaw(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => attachOrCreateRaw(toValue(reservationId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservationDigitalAssets(),
      });
    },
  });
}

<template>
  <div v-if="faqTabList.length > 0" class="soona-faq">
    <h3 class="soona-faq__title">
      <span class="soona-faq__lightbulb">💡</span> faq
    </h3>
    <div v-for="(tab, i) in faqTabList" :key="`${tabId}-${i}`">
      <h4 class="soona-faq__tab">
        <button
          :id="`${buttonId}-${i}`"
          class="soona-faq__tab-button"
          :aria-controls="`${panelId}-${i}`"
          :aria-label="`toggle ${tab.titleCopy} panel`"
          :aria-expanded="faqOpen[i] ? 'true' : 'false'"
          @click.prevent="
            faqOpen[i]
              ? collapseSection($refs[panelId][i], 'faqOpen', i)
              : trackFaqClicked(i, tab.titleCopy),
              expandSection($refs[panelId][i], 'faqOpen', i)
          "
        >
          {{ tab.titleCopy }}
          <NavCaret class="soona-faq__caret" aria-hidden="true" />
        </button>
      </h4>
      <div
        :id="`${panelId}-${i}`"
        :ref="panelId"
        class="u-drawer soona-faq__panel-wrapper"
        :class="{ 'u-drawer--open': faqOpen[i] }"
        role="region"
        :aria-labelledby="`${buttonId}-${i}`"
      >
        <div class="soona-faq__panel">
          <p class="soona-faq__panel-copy" v-html="tab.bodyCopy"></p>
          <ul v-if="tab.listItems" class="soona-faq__list">
            <li
              v-for="(listItem, j) in tab.listItems"
              :key="`${listItemId}-${j}`"
              class="soona-faq__list-item"
            >
              {{ listItem }}
            </li>
          </ul>
          <figure
            v-for="(image, j) in imagesToRender(tab.imageList)"
            :key="`${imageId}-${j}`"
            class="soona-faq__image-wrapper"
          >
            <img class="soona-faq__image" :src="image.src" :alt="image.alt" />
            <figcaption class="soona-faq__image-caption">
              {{ image.caption }}
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import drawerToggle from 'src/mixins/drawerToggleMixin';
import NavCaret from 'src/components/svgs/NavCaret.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

export default {
  components: {
    NavCaret,
  },
  mixins: [drawerToggle],
  props: {
    faqTabList: {
      default: () => [], // { titleCopy: '', bodyCopy: '', listItems: ['item one', 'item two'], imageList: [{ src: require('image/file.png'), caption: '', alt: ''}, { src: require('image/file.png'), caption: '', alt: ''} ],
      required: true,
      type: Array,
    },
    subContext: {
      required: false,
      type: String,
    },
  },
  setup() {
    const { buttonClicked } = useBaseEvents();

    return {
      buttonClicked,
    };
  },
  data() {
    const baseId = uniqueId();
    return {
      buttonId: `faq-tab-button-${baseId}`,
      imageId: `faq-panel-image-${baseId}`,
      listItemId: `faq-panel-list-item-${baseId}`,
      panelId: `faq-panel-${baseId}`,
      tabId: `faq-tab-${baseId}`,
      faqOpen: this.faqTabList.map(() => {
        return false;
      }),
    };
  },
  methods: {
    imagesToRender(list) {
      if (!list || list.length < 2) return undefined;
      return list.slice(0, 2);
    },
    trackFaqClicked(faqDrawerIndex, faqTitle) {
      const subContext = this.subContext ?? this.$route.meta.page_title;
      if (
        !this.faqOpen[faqDrawerIndex] &&
        this.$route.meta.context &&
        subContext
      ) {
        this.buttonClicked({
          context: this.$route.meta.context,
          subContext: subContext,
          buttonLabel: faqTitle,
          buttonAction: 'toggle open faq drawer',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-faq {
  border: 1px solid variables.$gray-30;
  border-radius: 0.625rem;
  color: variables.$black-default;
  height: max-content;
  margin-top: 5rem;
  max-width: 62.375rem;
  overflow: hidden;
  width: 100%;

  &__title {
    @include variables_fonts.u-button--small-caps;

    background-color: variables.$bubbletape-pink-05;
    padding: 1.25rem 1rem;

    @media (min-width: variables.$screen-md-min) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  &__lightbulb {
    font-size: 1.0625rem;
  }

  &__tab {
    border-top: 1px solid variables.$gray-30;
  }

  &__tab-button {
    @include variables_fonts.u-body--regular;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: variables.$bubbletape-pink-05;
    padding: 1.25rem 1rem;
    text-align: left;
    transition: 0.1s;
    width: 100%;

    &:hover {
      background-color: variables.$bubbletape-pink-02;

      &:hover {
        :deep(path) {
          stroke: variables.$black-default;
        }
      }
    }

    &[aria-expanded='true'] {
      background-color: variables.$bubbletape-pink-02;

      &:hover {
        background-color: variables.$bubbletape-pink-05;
      }

      .soona-faq__caret {
        transform: rotate(180deg);
      }
    }

    @media (min-width: variables.$screen-md-min) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  &__caret {
    flex: 0 0 2rem;
    height: 0.5625rem;
    margin-left: 0.5rem;
    transition: transform 0.25s;

    :deep(path) {
      stroke: variables.$gray-60;
      transition: 0.1s;
    }

    @media (min-width: variables.$screen-md-min) {
      right: 1.25rem;
    }

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  &__panel-wrapper {
    background-color: variables.$bubbletape-pink-02;
    padding: 0 1rem;

    @media (min-width: variables.$screen-md-min) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  &__panel {
    background-color: variables.$bubbletape-pink-02;
    border-top: 1px solid variables.$gray-30;
    padding: 1.25rem 0;
  }

  &__panel-copy {
    @include variables_fonts.u-body--regular;

    :deep(*) {
      padding-bottom: 0.625rem;
    }

    :deep(a) {
      color: variables.$periwink-blue-70;
      text-decoration: underline;
      transition: 0.2s;

      &:hover {
        color: variables.$periwink-blue-50;
        text-decoration: none;
      }
    }

    :deep(ul) {
      list-style: disc;
      padding-left: 1.25rem;

      li {
        padding-bottom: 0;
      }
    }
  }

  &__list {
    list-style-type: disc;
    padding-left: 1.25rem;
    padding-top: 0.5rem;
  }

  &__list-item {
    @include variables_fonts.u-body--regular;
  }

  &__image-wrapper {
    display: inline-block;
    padding-top: 1rem;
    padding-right: 1rem;
  }

  &__image {
    height: 10.5625rem;
    object-fit: cover;
    width: 13.3125rem;
    border-radius: 0.625rem;
  }

  &__image-caption {
    @include variables_fonts.u-label--regular;

    text-align: right;
  }
}
</style>

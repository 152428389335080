import { unref } from 'vue';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { updateAccountsOrder } from '@/api/accountsOrders';

export function useUpdateAccountsOrder(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body => updateAccountsOrder(unref(accountId), body),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.accountOrders(accountId),
      });
    },
  });
}

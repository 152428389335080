<script setup>
import { computed } from 'vue';
import GifDragAndDrop from '@/components/user/anytime/gifs/GifDragAndDrop.vue';
import GifHero from '@/components/user/anytime/gifs/preview/GifHero.vue';

const props = defineProps({
  dimension: {
    type: String,
    required: true,
    default: 'square',
  },
  playback: {
    type: String,
    required: true,
    default: 'loop',
  },
  selectedAssets: {
    type: Array,
    default: () => [],
    required: true,
  },
  speed: {
    type: Number,
    required: true,
    default: 666.66,
  },
});

const emit = defineEmits(['onUpdateSelectedAssets']);

const selectedAssets = computed(() => props.selectedAssets);

const updateOrder = e => {
  let newImageList = selectedAssets.value?.slice();
  const item = selectedAssets.value?.[e.oldIndex];

  newImageList.splice(e.oldIndex, 1);
  newImageList.splice(e.newIndex, 0, item);

  emit('onUpdateSelectedAssets', newImageList);
};

const removeFromList = id => {
  let newImageList = selectedAssets.value?.slice();
  let index = selectedAssets.value?.findIndex(image => image.id === id);
  newImageList.splice(index, 1);

  emit('onUpdateSelectedAssets', newImageList);
};
</script>

<template>
  <div class="gif-preview--container">
    <section>
      <GifHero
        :dimension="dimension"
        :playback="playback"
        :selected-assets="selectedAssets"
        :speed="speed"
      />
      <GifDragAndDrop
        :list="selectedAssets"
        @remove="id => removeFromList(id)"
        @reorder="e => updateOrder(e)"
      />
    </section>
  </div>
</template>

<style scoped lang="scss">
.gif-preview--container {
  section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
}
</style>

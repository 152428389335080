import { http } from '../config/vue-axios';

/**
 *
 * @param {number} reservationId
 * @param {Object} [collaborators]
 */
export async function shareDraftEmail(reservationId, collaborators) {
  const response = await http.put(
    `/reservations/${reservationId}/share_draft`,
    {
      collaborators: collaborators,
    }
  );

  return response.data;
}

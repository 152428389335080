<script setup>
import { computed } from 'vue';
const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  emoji: {
    type: String,
    required: false,
  },
  color: {
    type: String,
    required: false,
    default: '#ffffff',
  },
  hoverActive: {
    type: Boolean,
    required: false,
    default: false,
  },
  switchEmojiOnHover: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const rgbColor = computed(() => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(props.color);
  return result
    ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )}`
    : null;
});
</script>
<template>
  <div
    class="file-content-label"
    :class="[
      { 'file-content-label--switch-on-hover': switchEmojiOnHover },
      { 'file-content-label--hover-active': hoverActive },
    ]"
  >
    <span v-if="emoji" class="file-content-label__emoji">{{ emoji }}</span>
    <p class="file-content-label__title u-badge--big">
      {{ title }}
    </p>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.file-content-label {
  --background-color: rgba(v-bind(rgbColor), 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 3.125rem;
  background-color: var(--background-color);
  width: fit-content;
  height: fit-content;
  padding: 0.3125rem 0.75rem;

  .file-content-label__emoji {
    font-size: 1rem;
    line-height: 1;
  }

  .file-content-label__title {
    font-weight: 900;
  }

  &--switch-on-hover {
    .file-content-label__emoji {
      display: none;
    }

    @media (min-width: variables.$screen-xs-min) {
      .file-content-label__emoji {
        display: block;
      }

      .file-content-label__title {
        display: none;
      }
    }
  }

  &--hover-active {
    @media (min-width: variables.$screen-xs-min) {
      .file-content-label__emoji {
        display: none;
      }

      .file-content-label__title {
        display: block;
      }
    }
  }
}
</style>

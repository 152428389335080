<script setup>
import { ref, computed, onMounted, watch, watchEffect } from 'vue';
import CalendarDatePicker from './CalendarDatePicker.vue';
import { convertToYYYYMMDD } from 'src/lib/date-formatters';
import { useLocationAvailability } from 'src/queries/useLocation';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const props = defineProps({
  reservation: Object,
  currentAppointment: String,
  loading: {
    type: Boolean,
    default: false,
  },
  lastAvailableDate: String,
  restrictedDays: {
    type: Array,
    default: () => [0, 6],
  },
  restrictedDates: {
    type: Array,
    default: () => [],
  },
  enabledDates: {
    type: Array,
    default: () => [],
  },
  selectedTimeSlot: {
    type: String,
  },
  scheduledTime: {
    type: String,
  },
  isVertical: {
    type: Boolean,
    default: false,
  },
  isBusy: {
    type: Boolean,
    default: false,
  },
  showAllTimes: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['day-selected', 'time-selected']);

const { timeSelected } = useBaseEvents();

// navigation module
const _currentMonth = new Date();
_currentMonth.setDate(1);
_currentMonth.setHours(0, 0, 0, 0);
const currentMonth = ref(_currentMonth);

const currentDay = ref(null);

const locationId = computed(() => props.reservation.location.id);
const reservationId = computed(() => props.reservation.id);

// date selection module
const {
  data: locationAvailability,
  isLoading,
  error,
} = useLocationAvailability(locationId, {
  duration: computed(() => (props.showAllTimes ? 60 * 60 : null)),
  reservationId: reservationId,
  month: currentMonth,
  showAll: computed(() => props.showAllTimes),
});

// reset currentDay when location changes
watch(
  () => props.reservation.location,
  () => {
    currentDay.value = null;
  }
);

const monthTimeSlots = computed(
  () => locationAvailability.value?.all_slots || []
);

const availableDays = computed(() => {
  let result = [];
  Object.entries(monthTimeSlots.value).forEach(day => {
    if (day[1].length > 0) {
      result.push(day[0]);
    }
  });
  return result;
});

const dayNotSelectable = date => {
  if (isLoading.value === true) return true;
  let dateString = convertToYYYYMMDD(date);

  const isRestricted =
    props.restrictedDates.includes(dateString) ||
    (props.restrictedDays.includes(date.getDay()) &&
      !props.enabledDates.includes(dateString));

  const beforeCurrentAppointment = date < new Date(props.currentAppointment);

  const withinAvailableRange =
    props.lastAvailableDate && date > new Date(props.lastAvailableDate);

  const hasAvailability = availableDays.value.includes(dateString);

  return (
    isRestricted ||
    beforeCurrentAppointment ||
    withinAvailableRange ||
    !hasAvailability
  );
};

// skip to next month if there are no available slots when initialized
const skippedFirstMonth = ref(false);

// time selection module
const selectedDaySlots = computed(() => {
  return monthTimeSlots.value[convertToYYYYMMDD(currentDay.value)];
});
const selectedDaySlotsDisplay = ref(selectedDaySlots.value);
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const scheduledTimeDisplay = ref(props.scheduledTime);

watchEffect(() => {
  scheduledTimeDisplay.value = props.scheduledTime;
});

watchEffect(() => {
  if (
    availableDays.value?.length === 0 &&
    !isLoading.value &&
    !skippedFirstMonth.value &&
    currentMonth.value
  ) {
    skippedFirstMonth.value = true;
    const newMonth = new Date(currentMonth.value);
    newMonth.setMonth(newMonth.getMonth() + 1);
    currentMonth.value = newMonth;
  }
});

const handleDayClick = date => {
  if (
    convertToYYYYMMDD(props.reservation?.scheduled_reservation_date) ===
    convertToYYYYMMDD(date)
  ) {
    scheduledTimeDisplay.value = props.scheduledTime;
  } else {
    scheduledTimeDisplay.value = null;
  }
  currentDay.value = date;
  selectedDaySlotsDisplay.value = selectedDaySlots.value;
  timeSelected({
    context: 'booking',
    subContext: 'schedule',
    selectionType: 'date',
    UTC: convertToYYYYMMDD(date, 'UTC'),
  });
};

const handleTimeClick = timeSlot => {
  emit('time-selected', timeSlot);
};

onMounted(() => {
  if (props.selectedTimeSlot) {
    currentDay.value = new Date(props.selectedTimeSlot);
    selectedDaySlotsDisplay.value = selectedDaySlots.value;
  }
});

watch(selectedDaySlots, (newVal, oldVal) => {
  if (oldVal) return;
  selectedDaySlotsDisplay.value = newVal;
});
</script>
<template>
  <CalendarDatePicker
    v-model:current-month="currentMonth"
    :day-not-selectable="dayNotSelectable"
    :selected-day="currentDay"
    :selected-time-slot="selectedTimeSlot"
    :scheduled-time-display="scheduledTimeDisplay"
    :available-time-slots="selectedDaySlotsDisplay"
    :scheduled-time="scheduledTime"
    :is-loading="!locationAvailability || isBusy || isLoading"
    :is-error="error"
    :is-vertical="isVertical"
    @update:selected-day="handleDayClick"
    @update:selected-time-slot="handleTimeClick"
  />
</template>

<script setup>
import { computed, inject } from 'vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import PowImage from 'images/listing-actions/POW@2x.png';
import LifestyleImage from 'images/listing-actions/lifestyle@2x.png';
import OptimizeSizeImage from 'images/listing-actions/optimize-size@2x.png';
import MissingContentImage from 'images/listing-actions/missing-content@2x.png';
import PoorQualityImage from 'images/listing-actions/poor-quality@2x.png';
import ActionViewTemplateHeaderHelpDialog from './ActionViewTemplateHeaderHelpDialog.vue';

const pageContent = inject('pageContent');

const images = {
  'POW@2x.png': PowImage,
  'lifestyle@2x.png': LifestyleImage,
  'optimize-size@2x.png': OptimizeSizeImage,
  'missing-content@2x.png': MissingContentImage,
  'poor-quality@2x.png': PoorQualityImage,
};

const imageSrc = computed(() => {
  return images[pageContent.value.image] ?? PlaceholderImage;
});
</script>

<template>
  <div class="action-template-header">
    <div class="action-template-header__image">
      <img :src="imageSrc" alt="" />
    </div>
    <div class="action-template-header__content">
      <h2 class="u-subheader--heavy">{{ pageContent.header }}</h2>
      <p class="u-label--regular">{{ pageContent.description }}</p>
      <div class="action-template-header__actions">
        <slot name="actions" />
      </div>
      <ActionViewTemplateHeaderHelpDialog
        v-if="pageContent.dialogContent"
        :page-title="pageContent.pageTitle"
        :image="imageSrc"
        :dialog-content="pageContent.dialogContent"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.action-template-header {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 2rem;
  align-items: center;

  &__image {
    min-width: 16.875rem;
    height: 11.25rem;
    border-radius: 0.5rem;
    overflow: hidden;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    max-width: 38.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__actions {
    margin-top: 0.75rem;
  }

  @media (min-width: variables.$screen-xs-max) {
    flex-flow: nowrap;
  }
}
</style>

export const ADJUST_ACTION = Symbol('ADJUST');
export const BACKGROUND_COLOR_ACTION = Symbol('SHIFT_BACKGROUND_COLOR');
export const REMOVE_BACKGROUND_ACTION = Symbol('REMOVE_BACKGROUND');
export const BLUR_BACKGROUND_ACTION = Symbol('BLUR_BACKGROUND');
export const PREMIUM_EDITS_ACTION = Symbol('PREMIUM_EDITS');
export const RESIZE_ACTION = Symbol('RESIZE');
export const RE_EDITS_ACTION = Symbol('RE_EDITS');
export const ALBUMS_ACTION = Symbol('ALBUMS');
export const PRODUCTS_ACTION = Symbol('PRODUCTS');

export const NOTES_ACTION = Symbol('NOTES');

export const MOBILE_EDIT_ACTION = Symbol('edits');
export const SHADOWS_ACTION = Symbol('SHADOWS');
export const AI_SCENES_ACTION = Symbol('AI_SCENES_ACTION');
export const AI_PROPS_ACTION = Symbol('AI_PROPS_ACTION');
export const AI_EXPAND_ACTION = Symbol('AI_EXPAND_ACTION');

export const ROUTE_MAP = {
  'background-color': BACKGROUND_COLOR_ACTION,
  'remove-background': REMOVE_BACKGROUND_ACTION,
  blur: BLUR_BACKGROUND_ACTION,
  resize: RESIZE_ACTION,
  'premium-edits': PREMIUM_EDITS_ACTION,
  're-edits': RE_EDITS_ACTION,
  albums: ALBUMS_ACTION,
  products: PRODUCTS_ACTION,
  notes: NOTES_ACTION,
  shadows: SHADOWS_ACTION,
  'ai-studio': AI_SCENES_ACTION,
  'ai-scenes': AI_SCENES_ACTION,
  'ai-props': AI_PROPS_ACTION,
  'ai-expand': AI_EXPAND_ACTION,
};

<script setup>
import { computed } from 'vue';
import SoonaFlag from '../ui_library/SoonaFlag.vue';
import SoonaIcon from '../ui_library/soona_icon/SoonaIcon.vue';
import {
  Tangerine30,
  AvoToast30,
  Roses20,
  Gray20,
} from 'src/variables.module.scss';

const props = defineProps({
  availability: {
    type: String,
    required: false,
  },
});

const flagText = computed(() => {
  if (!props.availability) {
    return 'contact support';
  } else {
    return props.availability;
  }
});

const flagBackgroundColor = computed(() => {
  if (props.availability === 'high availability') {
    return AvoToast30;
  } else if (props.availability === 'medium availability') {
    return Tangerine30;
  } else if (props.availability === 'low availability') {
    return Roses20;
  } else {
    return Gray20;
  }
});
</script>

<template>
  <SoonaFlag
    :title="flagText"
    :background-color="flagBackgroundColor"
    type="pill"
  >
    <template #icon-left>
      <SoonaIcon name="calendar" size="small" :margin-right="4" />
    </template>
  </SoonaFlag>
</template>

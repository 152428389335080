import * as types from '../mutation-types';

const state = {
  messages: [],
};

const getters = {
  errorMessages: state =>
    state.messages.filter(message => message.type === 'error'),
  successMessages: state =>
    state.messages.filter(message => message.type === 'success'),
  infoMessages: state =>
    state.messages.filter(message => message.type === 'info'),
  warningMessages: state =>
    state.messages.filter(message => message.type === 'warning'),
};

const actions = {
  addFlashMessage({ commit }, payload) {
    commit(types.ADD_FLASH_MESSAGE, payload);
  },
  removeFlashMessage({ commit }, payload) {
    commit(types.REMOVE_FLASH_MESSAGE, payload);
  },
  clearFlashMessages({ commit }) {
    commit(types.CLEAR_FLASH_MESSAGES);
  },
};

const mutations = {
  [types.ADD_FLASH_MESSAGE](state, payload) {
    if (
      state.messages.find(function (message) {
        return (
          message.text === payload.text &&
          message.type === payload.type &&
          message.timeout === payload.timeout
        );
      }) == null
    ) {
      state.messages.push(payload);
    }
  },
  [types.REMOVE_FLASH_MESSAGE](state, payload) {
    state.messages = state.messages.filter(function (message) {
      return message !== payload;
    });
  },
  [types.CLEAR_FLASH_MESSAGES](state) {
    state.messages = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { useMutation, useQueryClient, useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param {string | number} accountId
 */
export function useGenerateEmbeddings(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.post(
        `/accounts/${accountId.value}/embeddings`
      );
      return response.data;
    },
    onSuccess: async () => {
      const promises = [
        queryClient.invalidateQueries({
          queryKey: queryKeys.digitalAssets(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.embeddingsMetrics(accountId),
        }),
      ];
      await Promise.all(promises);
    },
  });
}

/**
 *
 * @param {string | number} accountId
 */
export function useEmbeddingsMetrics(accountId) {
  return useQuery({
    queryKey: queryKeys.embeddingsMetrics(accountId),
    queryFn: async () => {
      const response = await http.get(
        `/accounts/${accountId.value}/embeddings/metrics`
      );
      return response.data;
    },
  });
}

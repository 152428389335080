import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { getPackConfigurations } from '@/api/packConfigurations';
import { unref } from 'vue';

/**
 *
 * @param {Object} [params]
 * @param {string | number} [params.account_id]
 * @param {boolean} [params.packBuilder]
 * @param {Array<string>} [params.status]
 * @param {string | null} [params.query]
 * @param {string | null} [params.filter]
 * @param {number} [params.limit]
 * @param {string} [params.orderBy]
 * @param {string} [params.direction]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {UseQueryOptions} queryOptions
 */
export function usePackConfigurations(
  {
    accountId,
    packBuilder,
    status = ['published'],
    query,
    filter,
    limit,
    orderBy,
    direction,
    itemsPerPage = 24,
    currentPage,
  } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.packConfigurations({
      accountId,
      packBuilder,
      status,
      query,
      filter,
      limit,
      orderBy,
      direction,
      itemsPerPage,
      currentPage,
    }),
    queryFn: ({ signal }) =>
      getPackConfigurations(
        {
          accountId: unref(accountId),
          packBuilder: unref(packBuilder),
          status: unref(status),
          query: unref(query),
          filter: unref(filter),
          limit: unref(limit),
          orderBy: unref(orderBy),
          direction: unref(direction),
          itemsPerPage: unref(itemsPerPage),
          currentPage: unref(currentPage),
        },
        signal
      ),
    ...queryOptions,
  });
}

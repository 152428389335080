import { ref, computed, readonly } from 'vue';

export const useMediaEditorIframe = () => {
  const iframeUrl = ref(null);
  const isIframeOpen = computed(() => !!iframeUrl.value);

  return {
    iframeUrl: readonly(iframeUrl),
    setIframeUrl: url => (iframeUrl.value = url),
    isIframeOpen: readonly(isIframeOpen),
    resetIframe: () => (iframeUrl.value = null),
  };
};

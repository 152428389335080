import { computed, toValue } from 'vue';
import { useTimeoutFn } from '@vueuse/core';
import { useFlag } from '@/composables/useFlag';
import { useRouter } from 'vue-router';
import { useAccount } from '@/composables/useAccount';

export const usePaymentInterstitialRedirect = (
  reservationId,
  accountId,
  routeQuery
) => {
  const router = useRouter();

  const customerQuestionnaireFlag = useFlag('toaster_customer_questionnaire');
  const { hasCompletedCustomerQuestionnaire } = useAccount(accountId);

  const redirectTo = computed(() => {
    if (
      customerQuestionnaireFlag.value &&
      !hasCompletedCustomerQuestionnaire.value &&
      accountId.value
    ) {
      return `/account/${accountId.value}/customer-questionnaire`;
    }

    return {
      name: 'info',
      params: { reservationId: reservationId.value },
      query: toValue(routeQuery),
    };
  });

  const { start: startInterstitialTimeout } = useTimeoutFn(
    () => {
      router.push(redirectTo.value);
    },
    6500,
    { immediate: false }
  );

  return {
    startInterstitialTimeout,
    redirectTo,
  };
};

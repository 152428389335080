import * as types from 'src/store/mutation-types';
import debounce from 'lodash/debounce';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from 'src/queries/query-client';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

let latestLoadCrewMembersSearch = '';

const loadCrewMemberSearchDebounced = debounce(
  function (commit, query, reservationId, startTime, endTime) {
    latestLoadCrewMembersSearch = query;
    return http
      .get(
        `reservations/${reservationId}/crew_assignments/search_for_crew.json`,
        {
          params: { query: query || '', startTime, endTime },
        }
      )
      .then(
        response => {
          // Ensures the latest search is shown,
          // regardless how long queries take to respond
          if (query === latestLoadCrewMembersSearch) {
            commit(types.SET_CREW_MEMBERS, response.data);
          }
        },
        error => {
          commit(`errors/${types.SET_ERRORS}`, error.response.data, {
            root: true,
          });
        }
      );
  },
  100,
  { leading: true, trailing: true }
);

const state = {
  crewAssignments: [],
  crewMembers: [],
};

const getters = {};

const mutations = {
  [types.SET_CREW_ASSIGNMENTS](state, crewAssignments) {
    state.crewAssignments = crewAssignments;
  },
  [types.ADD_CREW_ASSIGNMENT](state, crewAssignment) {
    state.crewAssignments.push(crewAssignment);
  },
  [types.REMOVE_CREW_ASSIGNMENT](state, crewAssignmentId) {
    let index = state.crewAssignments.findIndex(c => c.id === crewAssignmentId);
    state.crewAssignments.splice(index, 1);
  },
  [types.RESET_CREW_ASSIGNMENTS](state) {
    state.crewAssignments = [];
  },
  [types.SET_CREW_MEMBERS](state, crewMembers) {
    state.crewMembers = crewMembers;
  },
  [types.RESET_CREW_MEMBERS](state) {
    state.crewMembers = [];
  },
  [types.UPDATE_CREW_ASSIGNMENT](state, crewAssignment) {
    state.crewAssignments = state.crewAssignments.map(ca =>
      ca.id == crewAssignment.id ? crewAssignment : ca
    );
  },
};

const actions = {
  loadCrewAssignments({ commit }, reservationId) {
    commit(types.RESET_CREW_ASSIGNMENTS);
    return this.http
      .get(
        `reservations/${reservationId}/crew_assignments.json?without_declined=true`
      )
      .then(response => {
        if (response.data.length > 0) {
          commit(types.SET_CREW_ASSIGNMENTS, response.data);
        } else {
          commit(types.RESET_CREW_ASSIGNMENTS, response.data);
        }
      });
  },
  createCrewAssignment(
    { commit },
    {
      crewRole,
      proServiceProfileId,
      proServiceRequirementId,
      userId,
      reservationId,
      note,
      status,
    }
  ) {
    return this.http
      .post(`reservations/${reservationId}/crew_assignments.json`, {
        crew_role: crewRole,
        pro_service_profile_id: proServiceProfileId,
        pro_service_requirement_id: proServiceRequirementId,
        user_id: userId,
        note,
        status,
      })
      .then(response => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.crewAssignments(reservationId),
        });
        commit(types.ADD_CREW_ASSIGNMENT, response.data);
      })
      .catch(error => {
        console.error(error, 'failed to create crew assignment');
      });
  },
  removeCrewAssignment({ commit }, { crewAssignmentId, reservationId }) {
    return this.http
      .put(
        `reservations/${reservationId}/crew_assignments/${crewAssignmentId}.json`,
        { status: 'declined', status_detail: 'removed' }
      )
      .then(() => {
        commit(types.REMOVE_CREW_ASSIGNMENT, crewAssignmentId);
        queryClient.invalidateQueries({
          queryKey: queryKeys.assignedReservations(),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.crewAssignments(reservationId),
        });
      });
  },
  deleteCrewAssignment({ commit }, { crewAssignmentId, reservationId }) {
    return this.http
      .delete(
        `reservations/${reservationId}/crew_assignments/${crewAssignmentId}.json`
      )
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.crewAssignments(reservationId),
        });
        commit(types.REMOVE_CREW_ASSIGNMENT, crewAssignmentId);
      });
  },
  loadCrewMembers({ commit }, { query, reservationId, startTime, endTime }) {
    if (debounce) {
      loadCrewMemberSearchDebounced(
        commit,
        query,
        reservationId,
        startTime,
        endTime
      );
    }
  },
  updateCrewAssignment(
    { dispatch },
    { reservationId, crewAssignmentId, assignmentNote }
  ) {
    return this.http
      .put(
        `reservations/${reservationId}/crew_assignments/${crewAssignmentId}.json`,
        { note: assignmentNote }
      )
      .then(() => {
        dispatch('loadCrewAssignments', reservationId);
        queryClient.invalidateQueries({
          queryKey: queryKeys.assignedReservations(),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.crewAssignments(reservationId),
        });
      });
  },
  acceptAssignment({ commit }, { crewAssignmentId, reservationId }) {
    return this.http
      .put(
        `reservations/${reservationId}/crew_assignments/${crewAssignmentId}.json`,
        { status: 'accepted' }
      )
      .then(response => {
        commit(types.UPDATE_CREW_ASSIGNMENT, response.data);
        queryClient.invalidateQueries({
          queryKey: queryKeys.assignedReservations(),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.crewAssignments(reservationId),
        });
      });
  },
  declineAssignment({ commit }, { crewAssignmentId, reservationId }) {
    return this.http
      .put(
        `reservations/${reservationId}/crew_assignments/${crewAssignmentId}.json`,
        { status: 'declined' }
      )
      .then(() => {
        commit(types.REMOVE_CREW_ASSIGNMENT, crewAssignmentId);
        queryClient.invalidateQueries({
          queryKey: queryKeys.assignedReservations(),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.crewAssignments(reservationId),
        });
      });
  },
  resetCrewMembers({ commit }) {
    commit(types.RESET_CREW_MEMBERS);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getLastSupportedLocation } from 'src/api/reservation_locations';

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useLastSupportedLocation(reservationId) {
  return useQuery({
    queryKey: queryKeys.lastSupportedLocation(reservationId),
    queryFn: ({ signal }) =>
      getLastSupportedLocation(unref(reservationId), signal),
  });
}

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_BOOKABLE_SPACE_ID = 'SET_BOOKABLE_SPACE_ID';
export const UPDATE_DURATION = 'UPDATE_DURATION';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_START_TIME = 'SET_START_TIME';
export const REMOVE_RESERVATION_LINE_ITEM = 'REMOVE_RESERVATION_LINE_ITEM';
export const SET_AVAILABILITY = 'SET_AVAILABILITY';
export const SET_SCHEDULE_STATUS = 'SET_SCHEDULE_STATUS';
export const SET_SHIPPING_DETAILS = 'SET_SHIPPING_DETAILS';
export const SET_ADDITIONAL_CHARGE_ORDER = 'SET_ADDITIONAL_CHARGE_ORDER';
export const SET_ADDITIONAL_CHARGE_TAG_CATEGORY_PRODUCTS =
  'SET_ADDITIONAL_CHARGE_TAG_CATEGORY_PRODUCTS';
export const SET_ADDITIONAL_CHARGE_COUPON = 'SET_ADDITIONAL_CHARGE_COUPON';
export const SET_ADDITIONAL_CHARGE_ITEMS = 'SET_ADDITIONAL_CHARGE_ITEMS';
export const CLEAR_ADDITIONAL_CHARGE_ITEMS = 'CLEAR_ADDITIONAL_CHARGE_ITEMS';
export const SET_ADDITIONAL_CHARGE_MEMO = 'SET_ADDITIONAL_CHARGE_MEMO';
export const SET_ADDITIONAL_CHARGE_IMMEDIATELY =
  'SET_ADDITIONAL_CHARGE_IMMEDIATELY';
export const REMOVE_ADDITIONAL_CHARGE_ITEM = 'REMOVE_ADDITIONAL_CHARGE_ITEM';
export const RESET_SHIPPING_DETAILS = 'RESET_SHIPPING_DETAILS';
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';
export const SET_FREE_SHIPPING = 'SET_FREE_SHIPPING';
export const SET_RATE = 'SET_RATE';
export const SET_RATES = 'SET_RATES';
export const SET_FLAT_RATE = 'SET_FLAT_RATE';
export const SET_RETURN_SHIPMENT = 'SET_RETURN_SHIPMENT';
export const SET_PACKAGES_REMAINING = 'SET_PACKAGES_REMAINING';
export const SET_SELECTED_RATES = 'SET_SELECTED_RATES';
export const SET_SHIPPING_BUSY = 'SET_SHIPPING_BUSY';
export const SET_SHIPPING_MODE_OPTIONS = 'SET_SHIPPING_MODE_OPTIONS';
export const SET_SHIPPING_MODE_SHIPMENT = 'SET_SHIPPING_MODE_SHIPMENT';
export const SET_SHIPPING_MODE_CONFIRM = 'SET_SHIPPING_MODE_CONFIRM';
export const SET_SHIPPING_MODE_RATES = 'SET_SHIPPING_MODE_RATES';
export const SET_SHIPPING_MODE_PAYMENT = 'SET_SHIPPING_MODE_PAYMENT';
export const SET_SHIPPING_MODE_COMPLETED = 'SET_SHIPPING_MODE_COMPLETED';
export const SET_SHIPPING_MODE_RETURN_SHIPMENT =
  'SET_SHIPPING_MODE_RETURN_SHIPMENT';
export const SET_SHIPPING_MODE_RETURN_RATES = 'SET_SHIPPING_MODE_RETURN_RATES';
export const SET_SHIPPING_MODE_RETURN_PAYMENT =
  'SET_SHIPPING_MODE_RETURN_PAYMENT';
export const SET_SHIPPING_MODE_RETURN_COMPLETED =
  'SET_SHIPPING_MODE_RETURN_COMPLETED';
export const SET_SHIPPING_READY = 'SET_SHIPPING_READY';
export const SET_CURRENT_SHIPMENT = 'SET_CURRENT_SHIPMENT';
export const SET_CURRENT_SHIPPING_DETAIL = 'SET_CURRENT_SHIPPING_DETAIL';
export const ADD_RETURNED_SHIPPING_DETAIL = 'ADD_RETURNED_SHIPPING_DETAIL';
export const RESET_RETURNED_SHIPPING_DETAILS =
  'RESET_RETURNED_SHIPPING_DETAILS';
export const RESET_RATES = 'RESET_RATES';
export const RESET_RATE = 'RESET_RATE';
export const RESET_CURRENT_SHIPMENT = 'RESET_CURRENT_SHIPMENT';
export const RESET_CURRENT_SHIPPING_DETAIL = 'RESET_CURRENT_SHIPPING_DETAIL';
export const RESET_FLAT_RATE = 'RESET_FLAT_RATE';
export const RESET_START_TIME = 'RESET_START_TIME';
export const RESET_END_TIME = 'RESET_END_TIME';
export const SET_SUGGESTED_APPOINTMENTS = 'SET_SUGGESTED_APPOINTMENTS';
export const SET_SUGGESTED_APPOINTMENTS_LOADING =
  'SET_SUGGESTED_APPOINTMENTS_LOADING';
export const RESET_SUGGESTED_SLOTS = 'RESET_SUGGESTED_SLOTS';
export const ADD_CREW_ASSIGNMENT_TO_RESERVATION =
  'ADD_CREW_ASSIGNMENT_TO_RESERVATION';
export const REMOVE_CREW_ASSIGNMENT_FROM_RESERVATION =
  'REMOVE_CREW_ASSIGNMENT_FROM_RESERVATION';
export const RESET_RETURN_SHIPMENT = 'RESET_RETURN_SHIPMENT';
export const RESET_SELECTED_RATES = 'RESET_SELECTED_RATES';
export const SET_CREW_ASSIGNMENTS = 'SET_CREW_ASSIGNMENTS';
export const ADD_CREW_ASSIGNMENT = 'ADD_CREW_ASSIGNMENT';
export const REMOVE_CREW_ASSIGNMENT = 'REMOVE_CREW_ASSIGNMENT';
export const RESET_CREW_ASSIGNMENTS = 'RESET_CREW_ASSIGNMENTS';
export const UPDATE_CREW_ASSIGNMENT = 'UPDATE_CREW_ASSIGNMENT';
export const RESET_PAST_ASSIGNMENTS = 'RESET_PAST_ASSIGNMENTS';
export const ADD_PAST_ASSIGNMENTS = 'ADD_PAST_ASSIGNMENTS';
export const SET_CREW_EDITS_RESERVATIONS = 'SET_CREW_EDITS_RESERVATIONS';
export const SET_KPIS = 'SET_KPIS';
export const SET_CREW_MEMBERS = 'SET_CREW_MEMBERS';
export const RESET_CREW_MEMBERS = 'RESET_CREW_MEMBERS';
export const ADD_SHOOT_EXCEPTION = 'ADD_SHOOT_EXCEPTION';
export const REMOVE_SHOOT_EXCEPTION = 'REMOVE_SHOOT_EXCEPTION';

export const SET_PRO_SERVICE_AVAILABILITY = 'SET_PRO_SERVICE_AVAILABILITY';

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_CURRENT_LOCATION_PRODUCTS_AND_SPACES =
  'SET_CURRENT_LOCATION_PRODUCTS_AND_SPACES';
export const SET_LOCATION_LOADED = 'SET_LOCATION_LOADED';
export const SET_SUPPORTED_LOCATIONS = 'SET_SUPPORTED_LOCATIONS';

export const SET_CURRENT_LOCATION_CREW = 'SET_CURRENT_LOCATION_CREW';

export const SET_ERRORS = 'SET_ERRORS';
export const RESET_ERRORS = 'RESET_ERRORS';
export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const REMOVE_FLASH_MESSAGE = 'REMOVE_FLASH_MESSAGE';
export const CLEAR_FLASH_MESSAGES = 'CLEAR_FLASH_MESSAGES';

export const SET_RESERVATION = 'SET_RESERVATION';
export const RESET_RESERVATION = 'RESET_RESERVATION';
export const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
export const SET_RESCHEDULABLE_RESERVATION = 'SET_RESCHEDULABLE_RESERVATION';

export const SET_ORDER = 'SET_ORDER';
export const SET_ORDERS = 'SET_ORDERS';
export const RESET_ORDER = 'RESET_ORDER';
export const SET_ADD_ON_ORDER = 'SET_ADD_ON_ORDER';
export const ORDER_PROCESSED = 'ORDER_PROCESSED';
export const ORDER_FAILED = 'ORDER_FAILED';

/**
 * @deprecated
 * @description DO NOT directly use. this is synchronized automatically from
 * the useMe query in app.vue. Setting this directly will cause a mismatch of
 * data in the app. to update current user, reset, invalidate, or set data for
 * the "me" query
 * @type {string}
 */
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_PAYMENT_TOKEN = 'SET_PAYMENT_TOKEN';
export const SET_UNPAID_ADDITIONAL_CHARGES = 'SET_UNPAID_ADDITIONAL_CHARGES';

export const SET_RESERVATIONS = 'SET_RESERVATIONS';
export const ADD_LIVE_RESERVATIONS = 'ADD_LIVE_RESERVATIONS';
export const RESET_LIVE_RESERVATIONS = 'RESET_LIVE_RESERVATIONS';
export const ADD_COMPLETED_RESERVATIONS = 'ADD_COMPLETED_RESERVATIONS';
export const RESET_COMPLETED_RESERVATIONS = 'RESET_COMPLETED_RESERVATIONS';
export const ADD_PENDING_SELECTS_RESERVATIONS =
  'ADD_PENDING_SELECTS_RESERVATIONS';
export const RESET_PENDING_SELECTS_RESERVATIONS =
  'RESET_PENDING_SELECTS_RESERVATIONS';
export const ADD_PREVIOUS_RESERVATIONS = 'ADD_PREVIOUS_RESERVATIONS';
export const ADD_READY_TO_EDIT_RESERVATIONS = 'ADD_READY_TO_EDIT_RESERVATIONS';
export const RESET_READY_TO_EDIT_RESERVATIONS =
  'RESET_READY_TO_EDIT_RESERVATIONS';
export const SET_UNSCHEDULED_RESERVATIONS = 'SET_UNSCHEDULED_RESERVATIONS';
export const REMOVE_RESERVATION_FROM_SCHEDULE =
  'REMOVE_RESERVATION_FROM_SCHEDULE';
export const SET_UNSCHEDULED_RESERVATIONS_PAGINATION =
  'SET_UNSCHEDULED_RESERVATIONS_PAGINATION';
export const TOGGLE_UPDATE_RESERVATION_MODAL =
  'TOGGLE_UPDATE_RESERVATION_MODAL';
export const SET_CURRENT_RESERVATION = 'SET_CURRENT_RESERVATION';

export const SET_ALL_TAG_CATEGORIES = 'SET_ALL_TAG_CATEGORIES';
export const RESET_ALL_TAG_CATEGORIES = 'RESET_ALL_TAG_CATEGORIES';
export const SET_CURRENT_TAG_CATEGORY = 'SET_CURRENT_TAG_CATEGORY';
export const ADD_RESERVATION_TAG = 'ADD_RESERVATION_TAG';
export const REMOVE_RESERVATION_TAG = 'REMOVE_RESERVATION_TAG';
export const UPDATE_TAG_HISTORY = 'UPDATE_TAG_HISTORY';
export const SELECT_TAG = 'SELECT_TAG';
export const DESELECT_TAG = 'DESELECT_TAG';
export const LOAD_PREVIOUS_TAG_CATEGORY = 'LOAD_PREVIOUS_TAG_CATEGORY';
export const LOAD_NEXT_TAG_CATEGORY = 'LOAD_NEXT_TAG_CATEGORY';
export const TAGS_PRELOADED_COMPLETE = 'TAGS_PRELOADED_COMPLETE';
export const TAG_CATEGORY_PRELOADED = 'TAG_CATEGORY_PRELOADED';

export const ADD_RESERVATION_LINE_ITEMS = 'ADD_RESERVATION_LINE_ITEMS';
export const REMOVE_RESERVATION_LINE_ITEMS = 'REMOVE_RESERVATION_LINE_ITEMS';
export const RESET = 'RESET';

// Dashboard Store Mutations
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const RESET_ACCOUNTS = 'RESET_ACCOUNTS';

// Account credit orders
export const SET_ADD_CREDITS_PRODUCTS = 'SET_ADD_CREDITS_PRODUCTS';
export const SET_ADD_CREDITS_ITEMS = 'SET_ADD_CREDITS_ITEMS';

export const REMOVE_ADD_CREDITS_ITEM = 'REMOVE_ADD_CREDITS_ITEM';
export const CLEAR_ADD_CREDITS_ITEMS = 'CLEAR_ADD_CREDITS_ITEMS';
export const SET_ADD_CREDITS_MEMO = 'SET_ADD_CREDITS_MEMO';
export const SET_ADD_CREDITS_IMMEDIATELY = 'SET_ADD_CREDITS_IMMEDIATELY';
export const SET_COMPED_ORDER = 'SET_COMPED_ORDER';
export const SET_COMPED_CREDITS_REASON = 'SET_COMPED_CREDITS_REASON';

export const SET_COMPED_CREDITS_REASON_DETAILS =
  'SET_COMPED_CREDITS_REASON_DETAILS';

// User Store Mutations
export const SET_USER = 'SET_USER';

// Account Store Mutations
export const SET_DESIRED_ACCOUNT_ID = 'SET_DESIRED_ACCOUNT_ID';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';

// Style Quiz Mutations
export const SET_QUIZ_STYLE = 'SET_QUIZ_STYLE';
export const SET_QUIZ_RESULT = 'SET_QUIZ_RESULT';
export const SET_QUIZ_PHOTOS = 'SET_QUIZ_PHOTOS';
export const SET_QUIZ_WORDS = 'SET_QUIZ_WORDS';
export const SET_SHOOT_ROLES = 'SET_SHOOT_ROLES';
export const TOGGLE_QUIZ_WORD = 'TOGGLE_QUIZ_WORD';
export const TOGGLE_QUIZ_PHOTO = 'TOGGLE_QUIZ_PHOTO';
export const TOGGLE_SHOOT_ROLE = 'TOGGLE_SHOOT_ROLE';
export const TOGGLE_BRAND_CATEGORY = 'TOGGLE_BRAND_CATEGORY';

// InventoryPackages
export const SET_CURRENT_PACKAGE = 'SET_CURRENT_PACKAGE';
export const SET_PACKAGES = 'SET_PACKAGES';
export const SET_TRANSFER_PACKAGE_REQUIREMENTS =
  'SET_TRANSFER_PACKAGE_REQUIREMENTS';

// Stripe
export const SET_SAVE_CARD = 'SET_SAVE_CARD';
export const SET_DEFAULT_PAYMENT_INFO = 'SET_DEFAULT_PAYMENT_INFO';
export const SET_PICKABLE_PACKAGES = 'SET_PICKABLE_PACKAGES';
export const SET_PACKAGE_IDS_TO_RETURN = 'SET_PACKAGE_IDS_TO_RETURN';

// EmployeeTypes
export const SET_EMPLOYEE_ROLES = 'SET_EMPLOYEE_ROLES';
export const SET_CURRENT_EMPLOYEE_TYPE = 'SET_CURRENT_EMPLOYEE_TYPE';

// Events
export const SET_EVENTS = 'SET_EVENTS';

// ScheduleCalendar
export const SET_CALENDAR_TIMES = 'SET_CALENDAR_TIMES';

// ProService
export const SET_PRO_SERVICES = 'SET_PRO_SERVICES';
export const SET_CURRENT_TAG_CATEGORIES = 'SET_CURRENT_TAG_CATEGORIES';
export const SET_PRO_SERVICE_REQUIREMENTS = 'SET_PRO_SERVICE_REQUIREMENTS';
export const SET_CURRENT_PROFILE_TRAITS = 'SET_CURRENT_PROFILE_TRAITS';
export const SET_PRO_SERVICE_PROVIDER_DETAILS =
  'SET_PRO_SERVICE_PROVIDER_DETAILS';
export const ADD_NEW_PSP_CHOICE = 'ADD_NEW_PSP_CHOICE';
export const CLEAR_CURRENT_PSP_CHOICES = 'CLEAR_CURRENT_PSP_CHOICES';
export const RESET_CURRENT_PSP_CHOICES = 'RESET_CURRENT_PSP_CHOICES';
export const SELECT_CURRENT_SERVICE = 'SELECT_CURRENT_SERVICE';
export const REMOVE_SELECTED_PROVIDER = 'REMOVE_SELECTED_PROVIDER';
export const SET_CURRENT_PSP_CHOICE = 'SET_CURRENT_PSP_CHOICE';

// trendSetBuilder
export const SET_ALL_TREND_SETS = 'SET_ALL_TREND_SETS';
export const SET_ALL_TREND_SET_FILTERS = 'SET_ALL_TREND_SET_FILTERS';
export const SET_TREND_SET_CATEGORY_ID = 'SET_TREND_SET_CATEGORY_ID';

//genie
export const SET_GENIE_DATE = 'SET_GENIE_DATE';
export const SET_GENIE_TIME = 'SET_GENIE_TIME';
export const SET_GENIE_BAY = 'SET_GENIE_BAY';
export const SET_RESERVATION_START_AND_END = 'SET_RESERVATION_START_AND_END';
export const SET_GENIE_PHOTOGRAPHER = 'SET_GENIE_PHOTOGRAPHER';
export const RESET_SOONA_GENIE = 'RESET_SOONA_GENIE';

// flag
export const SET_ALL_FLAGS = 'SET_ALL_FLAGS';
export const SET_ACCOUNT_FLAGS = 'SET_ACCOUNT_FLAGS';
export const SET_ACCOUNT_ACCOUNTS_FLAGS = 'SET_ACCOUNT_ACCOUNTS_FLAGS';

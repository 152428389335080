import { useQuery } from '@tanstack/vue-query';
import { getAccount } from '@/api/accounts';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

/**
 *
 * @param {string | Ref<string>} accountId
 * @param {Object} [queryOptions]
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useAccount(accountId, queryOptions = null) {
  return useQuery({
    queryKey: queryKeys.account(accountId),
    queryFn: () => getAccount(unref(accountId)),
    ...queryOptions,
  });
}

<template>
  <div class="shipping">
    <span class="return-header">
      <h2 class="title">start a return</h2>
      <p class="shotlist-subtitle">
        here are our current options for your items.
      </p>
    </span>
    <div class="option-wrapper">
      <div class="return-lottie">
        <lottie-player
          autoplay
          loop
          src="@images/anim/lottie-return-shipping.lott"
          style="width: 180px; height: 180px; display: inline-block"
        />
      </div>
      <p class="u-title--heavy">upload your return label</p>
      <p class="is-size-7 is-lowercase mt-m mb-s sub-text">
        to get your items back - upload a return shipping label and our team
        will send it back to you.
      </p>
      <div class="provider-tracking-wrapper mt-m ml-m">
        <div>
          <SoonaSelect
            v-model:model-value="shippingDetail.selectedProvider"
            :options="filterDropOffProvider"
            placeholder="provider"
          >
            <template #label>
              <span> shipping provider </span>
            </template>
          </SoonaSelect>
          <SoonaTextfield
            v-if="shippingDetail.selectedProvider == 'other'"
            v-model:model-value="shippingDetail.customProvider"
            label="custom provider"
            class="custom-provider"
            type="text"
            placeholder="enter your provider"
          />
        </div>
        <div
          v-if="shippingDetail.selectedProvider !== 'drop off'"
          class="tracking-wrapper"
        >
          <SoonaTextfield
            v-model:model-value="shippingDetail.trackingNumber"
            label="tracking number"
            class="tracking-input"
            type="text"
            :maxlength="60"
            placeholder="e.g. 1233455"
          />
        </div>
      </div>
      <span v-if="shippingDetail.labelImage" class="has-label-icon">
        <span class="label-icon">🏷</span>
        <SoonaUpload
          class="upload-label-button"
          class-overide="upload-label-button"
          :upload-complete="blob => updateLabel(shippingDetail, blob)"
          :hide-icon="true"
          :is-multiple="false"
          label="REPLACE RETURN LABEL"
          accept=".jpeg, .jpg, .png, .gif"
        />
      </span>
      <span v-else class="has-label-icon">
        <SoonaUpload
          class="upload-label-button"
          class-overide="upload-label-button"
          :upload-complete="blob => updateLabel(shippingDetail, blob)"
          :hide-icon="true"
          :is-multiple="false"
          label="UPLOAD RETURN LABEL"
          accept=".jpeg, .jpg, .png, .gif"
        />
      </span>
      <hr class="return-hr" />
      <div class="return-options-buttons-wrapper">
        <SoonaButton variation="tertiary" @click="cancelReturn">
          cancel
        </SoonaButton>
        <SoonaButton :disabled="disableSubmit" @click="createSaveParams">
          save return details
        </SoonaButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';
import * as Sentry from '@sentry/vue';

export default {
  name: 'ShopifyUploadReturnLabel',
  components: {
    SoonaButton,
    SoonaSelect,
    SoonaTextfield,
    SoonaUpload,
  },
  data() {
    return {
      showUpload: false,
      shippingDetail: {
        id: '',
        labelImage: undefined, // we cannot pass an empty string to ActiveStorage
        labelImageUrl: '',
        trackingNumber: undefined,
        selectedProvider: undefined,
        customProvider: undefined,
      },
    };
  },
  computed: {
    ...mapState({
      currentPackage: state => state.inventoryPackages.currentPackage,
    }),
    ...mapGetters('reservation', ['getProviders']),
    disableSubmit() {
      return (
        (!this.shippingDetail.selectedProvider &&
          !this.shippingDetail.customProvider) ||
        !this.shippingDetail.trackingNumber ||
        !this.shippingDetail.labelImage
      );
    },
    filterDropOffProvider() {
      return this.getProviders
        .filter(provider => provider.name !== 'drop off')
        .map(provider => ({ label: provider.name, value: provider.name }));
    },
  },
  watch: {
    selectedProvider: function (newData) {
      if (newData !== 'other') {
        this.shippingDetail.customProvider = undefined;
      }
    },
  },
  mounted() {
    this.loadPackage({ packageId: this.$route.params.packageId });
  },
  methods: {
    ...mapActions('inventoryPackages', ['loadPackage', 'updatePackage']),
    updateLabel(shippingDetail, blob) {
      shippingDetail.labelImage = blob.signed_id;
      shippingDetail.labelImageUrl = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
    },
    createSaveParams() {
      const shippingDetailAttributes = {
        label_image: this.shippingDetail.labelImage,
        shipping_direction: 'outbound',
        shipping_provider: !this.shippingDetail.customProvider
          ? this.shippingDetail.selectedProvider
          : this.shippingDetail.customProvider,
        tracking_number: this.shippingDetail.trackingNumber,
        shipment_type: 'custom',
        reservation_id: this.currentPackage.latest_reservation.id,
      };
      this.handleSave({
        shipping_details_attributes: [shippingDetailAttributes],
      });
    },
    async handleSave(params) {
      try {
        this.updatePackage({
          packageId: this.currentPackage.id,
          packageAttributes: params,
        }).then(([, errors]) => {
          if (errors) {
            this.errors = errors;
          } else {
            this.$router.go(-1);
          }
        });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(new Error(`Updating package (id:  failed`), {
            extra: { error },
          });
        }
      }
    },
    cancelReturn() {
      this.$router.push(`/account/${this.currentPackage.account_id}/inventory`);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.shipping {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .return-header {
    .shotlist-subtitle {
      font-style: italic;
      margin-bottom: 35px;
    }
  }

  .option-wrapper {
    display: flex;
    flex-direction: column;
    border: 2px solid #3c3c3c;
    border-radius: 5px;
    max-width: 500px;
    margin: 1rem;
    padding: 30px 10px;

    .return-lottie {
      align-items: center;
    }

    .sub-text {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .provider-tracking-wrapper {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;

      .tracking-wrapper {
        padding-top: 0.125rem;
        margin-left: 1rem;

        .tracking-input {
          padding: 0;
        }
      }

      .custom-provider {
        margin-top: 1rem;
      }
    }

    .upload-label-button {
      margin-top: 20px;
      height: 35px;
      background-color: variables.$white-default;
      color: variables.$periwink-blue-60;
      border: 0.125rem solid variables.$periwink-blue-60;
      border-radius: 3px;
      font-weight: 800;
      font-size: 10px;
      letter-spacing: 3px;
      padding: 0.5rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .label-icon {
      font-size: 27px;
      padding: 0.5px;
      position: relative;
      top: 7px;
    }
  }

  .return-hr {
    background-color: variables.$gray-30;
  }

  .return-options-buttons-wrapper {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    .return-save-btn {
      height: 35px;
      border-radius: 3px;
      font-weight: 800;
      font-size: 10px;
      letter-spacing: 3px;
      padding: 0.5rem;
    }

    .cancel-return {
      background: none;
      text-decoration: underline;
      color: variables.$periwink-blue-60;
    }
  }
}

label {
  @include variables_fonts.u-label--heavy;

  color: variables.$black-default;
  text-align: left;
  margin-bottom: 0;
  padding-bottom: 0.4375rem;
  display: block;
}
</style>

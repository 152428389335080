<template>
  <div class="alert-notification">
    <div
      v-if="description"
      class="description-container"
      :class="{ 'small-container': smallContainer }"
    >
      <p class="text" v-html="description" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alertKey: String,
    smallContainer: Boolean,
  },
  data() {
    return {
      description: undefined,
    };
  },
  mounted: function () {
    this.getAlert();
  },
  methods: {
    getAlert() {
      return this.http
        .get(`alert_notifications/${this.alertKey}.json`)
        .then(response => {
          return (this.description = response.data.description);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-notification {
  .description-container {
    padding: 1.25rem 5.25rem;
    background-color: #d5e4ff;

    .text {
      font-family: Lato;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      letter-spacing: 0;
      text-align: center;

      b {
        font-family: Lato;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 800;
        line-height: 1.25rem;
        letter-spacing: 0;
        text-align: center;
      }
    }
  }

  .small-container {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
}
</style>

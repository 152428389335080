import { getReservation } from '../../api/reservation';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from 'src/queries/query-client';
import { queryKeys } from 'src/queries/query-keys';
import { nextIncompleteStep } from './processNavigation';

export async function prefetchReservation(reservationId) {
  await queryClient.prefetchQuery({
    queryKey: queryKeys.reservation(reservationId),
    queryFn: ({ signal }) => getReservation(reservationId, signal),
    gcTime: 60 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    retry: false,
  });
  return queryClient.getQueryData(queryKeys.reservation(reservationId));
}

export function isDraft(reservation) {
  if (!reservation) {
    return false;
  }
  return reservation.status === 'draft' || reservation.steps?.length > 0;
}

export function findIncompleteStep({ desiredSlug, reservation }) {
  const firstIncompleteStep = nextIncompleteStep({ reservation });
  const desiredStep = reservation?.steps.find(x => x.slug === desiredSlug);

  if (firstIncompleteStep && !desiredStep) {
    return firstIncompleteStep.slug;
  } else {
    return null;
  }
}

export async function crewBookingRouteGuard(to) {
  const toReservation = await prefetchReservation(to.params.reservationId);
  if (toReservation && !isDraft(toReservation)) {
    window.location.replace(
      `/user/anytime#/reservation/${to.params.reservationId}/info`
    );
    return false;
  }
  return true;
}

export async function reservationBookingGuard(to) {
  const toReservation = await prefetchReservation(to.params.reservationId);
  if (toReservation && isDraft(toReservation)) {
    window.location.replace(
      `/#/booking/${to.params.reservationId}/down-payment`
    );
    return false;
  }
  return true;
}

export async function reservationAvailabilityGuard(to) {
  const toReservation = await prefetchReservation(to.params.reservationId);
  if (toReservation && toReservation.has_scheduling_preference) {
    return true;
  }
  return false;
}

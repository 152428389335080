<script setup>
import { computed } from 'vue';
import RequirementForm from './RequirementForm.vue';
import { useUpdateAccountRequirement } from '@/queries/account-requirements/useUpdateAccountRequirement';
import { usePriorityErrors } from '@/composables/usePriorityErrors';

const props = defineProps({
  accountRequirement: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const accountId = computed(() => props.accountRequirement.account_id);
const requirementType = computed(
  () => props.accountRequirement.requirement_type
);

const {
  mutate: updateMutate,
  isPending: updateIsPending,
  error: updateError,
} = useUpdateAccountRequirement(accountId, requirementType);

const priorityErrors = usePriorityErrors(updateError);

function updateSubmit(e) {
  const data = new FormData(e.target);

  updateMutate(data, {
    onSuccess: () => {
      emit('close');
    },
  });
}
</script>

<template>
  <div class="requirement-edit">
    <RequirementForm
      :initial-account-requirement="accountRequirement"
      :priority-errors="priorityErrors"
      :loading="updateIsPending"
      :requirement-type="requirementType"
      @submit="updateSubmit"
      @cancel="$emit('close')"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.requirement-edit {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>

<script setup>
import SoonaSearchFilter from '@/components/ui_library/SoonaSearchFilter.vue';

defineProps({
  isLoading: { type: Boolean, default: false },
});

const searchText = defineModel('searchText', {
  type: String,
  required: true,
});
const selectedFilter = defineModel('selectedFilter', {
  type: String,
  default: null,
});
const sortBy = defineModel('sortBy', {
  type: Object,
  required: true,
});

const filterOptions = [
  {
    value: 'all',
    label: 'all',
  },
  {
    value: 'my watchlist',
    label: 'my watchlist',
  },
  {
    value: 'soona picks',
    label: 'soona picks',
  },
];

const sortOptions = [
  { value: 'created_at', label: 'date added' },
  { value: 'score', label: 'score' },
  { value: 'name', label: 'name' },
];
</script>

<template>
  <SoonaSearchFilter
    v-model:search-text="searchText"
    v-model:selected-filter="selectedFilter"
    v-model:sort-by="sortBy"
    search-placeholder="search listings"
    :filter-options="selectedFilter ? filterOptions : []"
    :sort-by-options="sortOptions"
    :is-loading="isLoading"
  >
    <template v-if="$slots['content-left']" #content-left>
      <slot name="content-left"></slot>
    </template>
  </SoonaSearchFilter>
</template>

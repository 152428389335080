<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUpdateReservation } from '@/queries/useUpdateReservation';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
  shotListNote: {
    type: String,
    required: false,
    default: null,
  },
});

const emit = defineEmits(['close-dialog']);

const { addToast } = useSoonaToast();

const reservationId = computed(() => props.reservationId);

const note = ref('');

watchEffect(() => (note.value = props.shotListNote));

const {
  mutate: updateReservation,
  error: updateError,
  isPending: updatePending,
} = useUpdateReservation(reservationId.value);

const saveNote = () => {
  updateReservation(
    {
      shot_list_note: note.value,
    },
    {
      onSuccess: () => {
        addToast('note saved', {
          variation: 'success',
        });
        emit('close-dialog');
      },
    }
  );
};

const priorityError = usePriorityError(updateError);
</script>
<template>
  <SoonaDialog
    class="shot-list-note"
    max-width="33rem"
    @close="emit('close-dialog')"
  >
    <template #heading>add note</template>
    <SoonaLoading v-if="updatePending" is-loading is-contained />
    <SoonaTextfield
      v-model:model-value="note"
      class="shot-list-note__text-field"
      element="textarea"
      label="note to your crew"
      placeholder="anything you need your photographer to know before your shoot?"
      rows="3"
    />
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton :disabled="updatePending" @on-click="saveNote">
        save
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.shot-list-note {
  &__text-field {
    padding-bottom: 0;
  }
}
</style>

import { useMutation } from '@tanstack/vue-query';
import { createUser } from '@/api/users';
import * as Sentry from '@sentry/vue';
import { useMe } from '@/composables/user/useMe';

/**
 */
export function useCreateUser() {
  const { resetMe } = useMe();

  return useMutation({
    mutationFn: attributes => createUser(attributes),
    onSuccess: async () => {
      // reload absolutely everything
      await resetMe();
    },
    onError: error =>
      Sentry.captureException(new Error('failed to sign up'), {
        extra: { error },
      }),
  });
}

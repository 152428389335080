<script setup>
import { computed, reactive, toRef, watchEffect } from 'vue';
import BookingLayout from '../BookingLayout.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useReservation } from 'src/queries/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import { usePriorityError } from 'src/composables/usePriorityError';

// props and data
const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const productSizeOptionList = [
  {
    value: 'large',
    label: 'bigger than a beach ball',
  },
  {
    value: 'small',
    label: 'smaller than a beach ball',
  },
  {
    value: 'beach_ball',
    label: 'it is a beach ball',
  },
  {
    value: 'not_applicable',
    label: 'not applicable',
  },
];
let details = reactive({
  goal: undefined,
  moodboard: undefined,
  productSize: undefined,
  title: undefined,
});

// api calls
const { data: reservation, error } = useReservation(
  toRef(props, 'reservationId')
);
const {
  mutate,
  isPending: isMutating,
  error: updateError,
} = useUpdateReservation(toRef(props, 'reservationId'));

const priorityError = usePriorityError(updateError, error);

// segment eventing
const { inputChanged } = useBaseEvents();

// computed
// const currentUser = computed(() => store.state.currentUser); // todo: uncomment when segment is filled in
const isStepValid = computed(() => {
  return !isMutating.value && !!details.title && !!details.goal;
});

const headingCopy = computed(() => {
  return reservation.value.isHeadshotsForAll
    ? 'shoot details'
    : 'let’s talk about your shoot goals';
});

watchEffect(() => {
  details.title = reservation.value.isHeadshotsForAll
    ? 'headshots for all'
    : reservation.value.name || undefined;
  details.goal = reservation.value.description || undefined;
  details.moodboard = reservation.value.moodboard || undefined;
  details.productSize = reservation.value.product_size || undefined;
});

// methods
function onBackClick() {
  props.onBack();
}

function onNextClick({ skipStep = false }) {
  if (skipStep) {
    return props.onNext();
  }

  let dataToSubmit = {
    name: details.title,
    description: details.goal,
    moodboard: details.moodboard,
    product_size: details.productSize,
  };

  mutate(dataToSubmit, {
    onSuccess: () => {
      props.onNext();
    },
  });
}
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
      :no-sidecart="reservation.isHeadshotsForAll"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="booking-details prebooking-transition__content">
        <SoonaLoading
          v-if="!reservation"
          :is-loading="!reservation"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading>{{ headingCopy }}</SoonaHeading>
        <form class="booking-details__form">
          <SoonaTextfield
            v-model="details.title"
            :is-chonky="true"
            label="shoot title *"
            :required="true"
            :disabled="reservation.isHeadshotsForAll"
            data-cypress="input-title"
            @on-blur="
              inputChanged({
                context: 'booking',
                subContext: 'details',
                inputLabel: 'shoot title',
                inputType: 'text',
              })
            "
          >
            <template #icon-label>✏️</template>
            <template #helper>(required)</template>
          </SoonaTextfield>
          <SoonaTextfield
            v-model="details.goal"
            element="textarea"
            :is-chonky="true"
            label="shoot goal or objective *"
            placeholder="a short sentence is fine! tell us what you want to accomplish."
            :required="true"
            data-cypress="input-goal"
            @on-blur="
              inputChanged({
                context: 'booking',
                subContext: 'details',
                inputLabel: 'shoot goal or objective *',
                inputType: 'textarea',
              })
            "
          >
            <template #icon-label>🏆</template>
            <template #helper>(required)</template>
          </SoonaTextfield>
          <template v-if="!reservation.isHeadshotsForAll">
            <hr class="booking-details__divider" />
            <div class="booking-details__subheading-wrapper">
              <h2 class="booking-details__subheading">
                want to share more about your shoot?
              </h2>
              <p class="booking-details__subcopy">
                you can always do this later
              </p>
            </div>
            <SoonaTextfield
              v-model="details.moodboard"
              :is-chonky="true"
              label="add a link to a moodboard or inspiration"
              data-cypress="input-moodboard"
              @on-blur="
                inputChanged({
                  context: 'booking',
                  subContext: 'details',
                  inputLabel: 'add a link to a moodboard or inspiration',
                  inputType: 'text',
                })
              "
            >
              <template #icon-label>🔮</template>
            </SoonaTextfield>
            <!-- TODO: pull in options from database -->
            <SoonaSelect
              v-model="details.productSize"
              class="booking-details__select"
              :options="productSizeOptionList"
              placeholder="select response"
              data-cypress="select-size"
              @update:model-value="
                inputChanged({
                  context: 'booking',
                  subContext: 'details',
                  inputLabel:
                    'is your product or item bigger than a beachball?',
                  inputType: 'select',
                  inputValue: details.productSize,
                })
              "
            >
              <template #label>
                <span class="booking-details__select-label">
                  <span class="booking-details__select-icon">☀️</span>
                  is your product or item bigger than a beachball?
                </span>
              </template>
            </SoonaSelect>
          </template>
        </form>
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.booking-details {
  :deep(.soona-heading) {
    margin-left: auto;
    margin-right: auto;
  }

  :deep(.soona-textfield) {
    margin-left: auto;
    margin-right: auto;
    max-width: 39.375rem;
  }

  :deep(.soona-textfield__helper) {
    align-self: center;
  }

  &__form {
    width: 100%;
  }

  &__divider {
    background-color: variables.$gray-30;
  }

  &__skip {
    margin-top: 2.25rem;
  }

  &__subheading-wrapper {
    color: variables.$black-default;
    padding-bottom: 1.5rem;
    padding-top: 0.75rem;
    text-align: center;
  }

  &__subheading {
    @include variables_fonts.u-subheader--heavy;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-title--heavy;
    }
  }

  &__subcopy {
    @include variables_fonts.u-body--regular;
  }

  &__select {
    margin-left: auto;
    margin-right: auto;
    max-width: 39.375rem;

    &-label {
      @media (min-width: variables.$screen-md-min) {
        @include variables_fonts.u-body--heavy;
      }
    }

    &-icon {
      font-size: 1.5rem;
      padding-right: 0.5rem;
    }

    :deep(.vs__search) {
      @include variables_fonts.u-subheader--regular;

      padding: 0.625rem 1rem;

      &::placeholder {
        @include variables_fonts.u-subheader--regular;
      }

      @media (min-width: variables.$screen-md-min) {
        @include variables_fonts.u-title--regular;

        padding-left: 1.25rem;
        padding-right: 1.25rem;

        &::placeholder {
          @include variables_fonts.u-title--regular;
        }
      }
    }

    :deep(.vs__selected) {
      @include variables_fonts.u-subheader--regular;

      @media (min-width: variables.$screen-md-min) {
        @include variables_fonts.u-title--regular;
      }
    }
  }

  // commented out to preserve for future iteration
  // &__upload-wrapper {
  //   margin-left: auto;
  //   margin-right: auto;
  //   max-width: 39.375rem;
  // }

  // &__upload-label-wrapper {
  //   display: flex;
  //   padding-bottom: 0.4375rem;
  // }

  // &__upload-label {
  //   @include variables_fonts.u-label--heavy;

  //   align-items: center;
  //   color: variables.$black-default;
  //   display: flex;

  //   @media (min-width: variables.$screen-md-min) {
  //     @include variables_fonts.u-body--heavy;
  //   }
  // }

  // &__upload-label-icon {
  //   font-size: 1.25rem;
  //   padding-right: 0.75rem;

  //   @media (min-width: variables.$screen-md-min) {
  //     font-size: 1.5rem;
  //   }
  // }

  // // TODO: move this to SoonaUpload eventually, taken from AdminPackBuilder
  // :deep(.soona-uploader) {
  //   background-color: variables.$white-default;
  //   border: 0.0625rem dashed variables.$gray-40;
  //   border-radius: 0.3125rem;
  //   height: 9.375rem;
  //   transition: 0.3s;
  //   width: 100%;

  //   &:hover {
  //     background-color: variables.$gray-10;
  //   }

  //   .booking-details__upload-btn {
  //     align-items: center;
  //     display: flex;
  //     flex-direction: column;
  //     height: 100%;
  //     justify-content: center;
  //     padding: 1.875rem 0;
  //     width: 100%;

  //     &::before {
  //       @include variables_fonts.u-label--heavy;

  //       content: 'browse computer';
  //       position: absolute;
  //       text-align: center;
  //       width: 12.5rem;
  //     }
  //   }

  //   &.booking-details__upload--complete {
  //     background-color: variables.$avo-toast-20;
  //     border-color: variables.$avo-toast-40;

  //     &:hover {
  //       background-color: variables.$avo-toast-20;
  //     }

  //     .booking-details__upload-btn {
  //       &::before {
  //         bottom: 36px;
  //         color: variables.$black-default;
  //         content: 'file upload complete!';
  //       }
  //     }

  //     .icon-wrapper {
  //       &::before {
  //         background-color: variables.$avo-toast-40 !important;
  //         border-radius: 50% !important;
  //         height: 2.125rem !important;
  //         left: 50% !important;
  //         top: 2.1875rem !important;
  //         transform: translate(-50%, -50%);
  //         width: 2.125rem !important;
  //       }

  //       &::after {
  //         background: transparent !important;
  //         border-right: 0.25rem solid variables.$white-default !important;
  //         border-bottom: 0.25rem solid variables.$white-default !important;
  //         border-radius: 0 !important;
  //         left: -0.5rem !important;
  //         height: 1.25rem !important;
  //         top: 2.1875rem !important;
  //         transform: rotate(45deg) translate(-50%, -50%) !important;
  //         width: 0.625rem !important;
  //       }
  //     }

  //     .file-icon {
  //       display: none;
  //     }

  //     .file-label {
  //       color: transparent;
  //       text-align: center;
  //     }
  //   }

  //   .file {
  //     display: block;
  //     height: 100%;
  //   }

  //   label {
  //     height: 100%;
  //   }

  //   &-btn {
  //     align-items: center;
  //     display: flex;
  //     flex-direction: column;
  //     height: 100%;
  //     justify-content: center;
  //     padding: 1.875rem 0;
  //     width: 100%;

  //     &::before {
  //       @include variables_fonts.u-label--heavy;

  //       content: 'browse computer';
  //       position: absolute;
  //       text-align: center;
  //       width: 12.5rem;
  //     }
  //   }

  //   .icon-wrapper {
  //     flex-grow: 1;
  //     position: relative;

  //     &::before,
  //     &::after {
  //       background-color: variables.$gray-40;
  //       border-radius: 6.25rem;
  //       content: '';
  //       position: absolute;
  //     }

  //     &::before {
  //       height: 1.25rem;
  //       left: -0.625rem;
  //       width: 2.5625rem;
  //     }

  //     &::after {
  //       height: 1.25rem;
  //       top: -0.5rem;
  //       width: 1.25rem;
  //     }
  //   }

  //   .file-icon {
  //     height: 1.3125rem;
  //     position: relative;
  //     width: auto;
  //     z-index: 1;
  //     path {
  //       fill: variables.$white-default;
  //     }
  //   }

  //   .file-label {
  //     @include variables_fonts.u-label--regular;
  //   }
  // }

  // &__uploaded-file {
  //   align-items: center;
  //   border: 0.125rem solid variables.$gray-30;
  //   border-radius: 0.25rem;
  //   display: flex;
  //   margin-top: 0.625rem;
  //   padding: 0.3125rem;
  //   position: relative;
  //   width: 100%;

  //   button {
  //     background: transparent;
  //     border: none;
  //     color: variables.$gray-60;
  //     font-size: 1.875rem;
  //     padding: 0 0.625rem;
  //     position: absolute;
  //     right: 0;
  //     transition: 0.3s;
  //     &:hover {
  //       color: variables.$periwink-blue-50;
  //     }
  //   }

  //   &-icon {
  //     margin-right: 0.625rem;
  //   }

  //   &-name {
  //     @include variables_fonts.u-body--heavy;

  //     padding-right: 0.625rem;
  //   }

  //   &-size {
  //     @include variables_fonts.u-label--regular;
  //   }
  // }
}
</style>

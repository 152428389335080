<script setup>
import { toRefs } from 'vue';
import SoonaExpansionPanel from '@/components/ui_library/SoonaExpansionPanel.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import { useIntegrationLogo } from './useIntegrationLogo';

const props = defineProps({
  listingAction: {
    type: Object,
    required: true,
  },
});

const { listingAction } = toRefs(props);

const { integrationLogo } = useIntegrationLogo(listingAction);
</script>

<template>
  <SoonaExpansionPanel
    v-if="listingAction.sibling_count > 0"
    class="sibling-actions-accordion"
    hide-box-shadow
  >
    <template #header>
      <i>
        this image shows on {{ listingAction.sibling_count }} other listing{{
          listingAction.sibling_count > 1 ? 's' : ''
        }}
      </i>
    </template>
    <template #panel>
      <div class="sibling-actions-accordion__siblings">
        <template v-for="sibling in listingAction.siblings" :key="sibling.id">
          <div
            class="sibling-actions-accordion__body--section sibling-actions-accordion__body--name"
          >
            <div>
              <SoonaIcon :name="integrationLogo" />
            </div>

            <SoonaLink
              variation="black"
              :to="{
                name: 'listing-optimize',
                params: {
                  integrationId: sibling.integration_id,
                  externalProductId: sibling.external_product_id,
                  catalogItemId: sibling.catalog_item_id,
                },
              }"
            >
              {{ sibling.catalog_item_name }}
            </SoonaLink>
          </div>
        </template>
      </div>
    </template>
  </SoonaExpansionPanel>
</template>

<style lang="scss" scoped>
.sibling-actions-accordion {
  margin-left: 1.5rem;

  &__siblings {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__body {
    order: 2;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--section {
      display: flex;
      gap: 0.5rem;
    }
  }
}
</style>

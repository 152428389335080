<script setup>
import { ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import northStarImage from 'images/subscriptions/north_star.svg';

defineProps({
  tier: {
    type: Object,
    required: true,
  },
  ctaCopy: {
    type: String,
    default: 'upgrade',
  },
});

const route = useRoute();
const { linkClicked } = useBaseEvents();

const showSubscriptionsDialog = ref(false);

const handleLinkClicked = (label, href) => {
  showSubscriptionsDialog.value = true;

  linkClicked({
    context: route.meta.context,
    subContext: 'listing insights undersubscribed banner',
    linkLabel: label,
    linkHref: href,
  });
};
</script>

<template>
  <aside class="undersubscribed-banner">
    <div class="undersubscribed-banner__text-content">
      <span class="undersubscribed-banner__supertitle u-badge--small">
        <slot name="supertitle" />
      </span>
      <h2 class="u-subheader--heavy undersubscribed-banner__headline">
        <slot name="headline" />
      </h2>
    </div>

    <div class="undersubscribed-banner__buttons-container">
      <SoonaButton
        variation="tertiary-white"
        @click="handleLinkClicked('learn more', '#')"
      >
        learn more
      </SoonaButton>
      <SoonaButton
        variation="solid-black"
        @click="handleLinkClicked(ctaCopy, '#')"
      >
        {{ ctaCopy }}
      </SoonaButton>
    </div>
    <img
      class="undersubscribed-banner__north-star-large"
      :src="northStarImage"
      alt=""
    />
    <img
      class="undersubscribed-banner__north-star-small"
      :src="northStarImage"
      alt=""
    />
  </aside>
  <SubscriptionsDialog
    v-if="showSubscriptionsDialog"
    :selected-tier-slug="tier.slug"
    @close="showSubscriptionsDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.undersubscribed-banner {
  background: variables.$gradient-periwink-error;
  border-radius: 0.5rem;
  padding: 1.5rem;
  position: relative;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  &__text-content {
    display: flex;
    flex-direction: column;
    color: variables.$white-default;
  }

  &__buttons-container {
    display: flex;
    gap: 1.25rem;

    button {
      z-index: 2;
    }
  }

  &__north-star-large {
    position: absolute;
    bottom: -2rem;
    right: 20.3125rem;
    height: 7.6875rem;
    width: 7.6875rem;
  }

  &__north-star-small {
    position: absolute;
    top: -8px;
    right: 12.5rem;
    height: 3.5rem;
    width: 3.5rem;
  }
}
</style>

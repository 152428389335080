<template>
  <CompleteProfileCard
    class="profile-pic"
    :disabled="disabled"
    @next="saveBrandAvatar"
    @skip="skipCard"
  >
    <template #heading>upload a brand hero image</template>
    <div class="upload-image-container">
      <img
        v-if="account.avatar_url"
        class="upload-image"
        :src="account.avatar_url || accountImage"
        @load="ready"
      />
      <div v-else class="brand-blue">📷</div>

      <div v-if="account.avatar_url" class="upload-overlay"></div>
      <div v-else class="upload-overlay upload-overlay--brand"></div>

      <div class="upload-icon">
        <SoonaUpload
          class-overide="u-headline--regular is-marginless"
          :upload-complete="handleAvatarUploadComplete"
          :hide-label="true"
          :hide-icon="false"
          :camera-icon="false"
          :icon-size-overide="true"
          :is-multiple="false"
          accept=".jpeg, .jpg, .png, .gif"
        />
      </div>
    </div>
  </CompleteProfileCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';
import accountImage from 'images/account-placeholder.svg';

export default {
  name: 'UploadProfilePic',
  components: { CompleteProfileCard, SoonaUpload },
  setup() {
    return { accountImage };
  },
  data() {
    return {
      avatarLoading: false,
    };
  },
  computed: {
    ...mapState({ account: state => state.account }),
    disabled() {
      return (
        this.account.avatar_url === undefined ||
        this.account.avatar_url === null
      );
    },
  },
  methods: {
    ...mapActions('account', ['createAvatarFromUpload', 'updateAccount']),
    handleAvatarUploadComplete: function (blob) {
      this.avatarLoading = true;
      this.createAvatarFromUpload({
        accountId: this.account.id,
        blob: blob,
      });
    },
    ready() {
      this.avatarLoading = false;
    },
    async saveBrandAvatar() {
      const info = {
        upload_profile_pic: {
          complete: true,
        },
      };
      await this.updateAccount({ accountParams: info });
    },
    async skipCard() {
      let info = {
        upload_profile_pic: {
          skip: true,
        },
      };
      await this.updateAccount({ accountParams: info });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.profile-pic {
  .upload-overlay {
    background: variables.$gray-90;
    border-radius: 50%;
    bottom: 0;
    display: none;
    height: 11.75rem;
    left: 0;
    opacity: 0.3;
    position: absolute;
    right: 0;
    top: 0;
    width: 11.75rem;

    &--brand {
      border-radius: 1.5rem;
    }
  }

  .upload-icon {
    color: variables.$white-default;
    display: none;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .upload-image-container {
    cursor: pointer;
    margin: 0 auto;
    position: relative;
    width: max-content;

    &:hover {
      .upload-icon,
      .upload-overlay {
        display: block;
      }
    }
  }

  .upload-image {
    border-radius: 50%;
    height: 11.75rem;
    object-fit: cover;
    width: 11.75rem;
  }

  .brand-blue {
    align-items: center;
    background-color: variables.$periwink-blue-20;
    border-radius: 1.5rem;
    display: flex;
    font-size: 5.25rem;
    height: 11.75rem;
    justify-content: center;
    width: 11.75rem;
  }
}
</style>

<template>
  <div>
    <table class="shipping-summary-table table is-striped u-small--regular">
      <thead>
        <tr>
          <th>
            account name
            <br />
            reservation
          </th>
          <th>
            date created
            <br />
            (reservation)
          </th>
          <th>transfer state</th>
          <th>
            date transfer is
            <br />
            initiated
          </th>
          <th>
            date transfer is
            <br />
            completed
          </th>
          <th>origin studio</th>
          <th>new studio</th>
          <th>shipping info</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="transfer in transferPackageRequirements" :key="transfer.id">
          <td>
            <router-link
              class="soona-link"
              :to="{ path: `/account/${transfer.account.id}` }"
            >
              <b>{{ transfer.account.name }}</b>
            </router-link>
            <br />
            <router-link
              class="soona-link"
              :to="{ path: `/reservation/${transfer.reservation_number}/info` }"
            >
              {{ transfer.reservation_number }}
            </router-link>
          </td>
          <td>
            {{ convertToMMDDYYYY(transfer.status_transitions.pending) }}
          </td>
          <td class="has-text-right">
            {{ transfer.status }}
          </td>
          <td>
            {{ convertToMMDDYYYY(transfer.status_transitions.pending) }}
          </td>
          <td v-if="transfer.status_transitions.hasOwnProperty('completed')">
            {{ convertToMMDDYYYY(transfer.status_transitions.completed) }}
          </td>
          <td v-else></td>
          <td>
            {{ transfer.origin.name }}
          </td>
          <td>
            {{ transfer.destination.name }}
          </td>
          <td v-if="!!transfer.tracking_number && !!transfer.shipping_provider">
            <a
              :href="getUrl(transfer)"
              class="soona-link"
              target="_blank"
              rel="noopener noreferrer"
              >{{ transfer.tracking_number }}
            </a>
            <br />
            {{ transfer.shipping_provider }}
          </td>
          <td v-else></td>
        </tr>
      </tbody>

      <tfoot>
        <a
          v-if="page > 1"
          class="button is-small mt-m ml-l"
          @click.prevent="previousPage"
          >previous
        </a>
        <a
          v-if="transferPackageRequirements.length >= 30"
          class="button is-small mt-m ml-l"
          @click.prevent="nextPage"
          >next
        </a>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { shippingProvider } from 'src/mixins/shippingProvider';
import dateTimeMixin from 'src/mixins/dateTimeMixin';

export default {
  mixins: [shippingProvider, dateTimeMixin],
  props: {
    searchValue: String,
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    ...mapState({
      transferPackageRequirements: state =>
        state.inventoryPackages.transferPackageRequirements,
    }),
  },
  watch: {
    searchValue() {
      if (this.searchValue.length > 3 || this.searchValue.length === 0) {
        this.loadPackages();
      }
    },
  },
  beforeMount() {
    this.loadPackages();
  },
  methods: {
    ...mapActions('inventoryPackages', ['loadTransferPackages']),
    nextPage() {
      this.page = this.page + 1;
      this.loadPackages();
    },
    previousPage() {
      this.page = this.page - 1;
      this.loadPackages();
    },
    loadPackages() {
      this.loadTransferPackages({
        page: this.page,
        search: this.searchValue,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  min-height: 300px;
}

.required-action {
  left: -100px !important;
}
</style>

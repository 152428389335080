<template>
  <CompleteProfileCard
    class="connect-one"
    :disabled="disabled"
    @next="saveConnectInfo"
    @skip="skipCard"
  >
    <template #heading>👋 let’s connect!</template>
    <fieldset class="connect-wrapper">
      <legend class="u-visually-hidden">connect with us</legend>
      <SoonaSelect v-model="referrer" :options="referrerOptions">
        <template #label>how did you hear about us?</template>
      </SoonaSelect>
      <SoonaTextfield
        v-if="referrer === 'other'"
        v-model="referrerCustom"
        class="connect-input"
        label="where?"
        name="where"
        placeholder="where you heard about us"
      />
      <SoonaTextfield
        v-model="facebook"
        class="connect-input"
        label="facebook"
        name="facebook"
        placeholder="facebook link"
      />
      <SoonaTextfield
        v-model="instagram"
        class="connect-input"
        label="instagram"
        name="instagram"
        placeholder="@"
      />
    </fieldset>
  </CompleteProfileCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'LetsConnect1',
  components: { CompleteProfileCard, SoonaSelect, SoonaTextfield },
  data() {
    return {
      facebook: '',
      instagram: '',
      referrer: '',
      referrerOptions: [
        'search engine',
        'Instagram ad',
        'social media post',
        'shopify',
        'an article/blog',
        'Facebook ad',
        'LinkedIn',
        'event/conference',
        'TikTok',
        'from a friend',
        'other',
      ],
      referrerCustom: '',
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    disabled() {
      return (
        this.facebook === '' && this.instagram === '' && this.referrer === ''
      );
    },
  },
  watch: {
    account: function () {
      this.prefillExistingData();
    },
  },
  mounted() {
    this.prefillExistingData();
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    prefillExistingData() {
      this.facebook =
        this.account.profile_data.lets_connect_one?.data?.facebook;
      this.instagram =
        this.account.profile_data.lets_connect_one?.data?.instagram;
      this.referrer =
        this.account.profile_data.lets_connect_one?.data?.how_did_you_hear_about_us;
    },
    async saveConnectInfo() {
      const referrerToShow =
        this.referrer === 'other' ? this.referrerCustom : this.referrer;
      const info = {
        lets_connect_one: {
          data: {
            facebook: this.facebook,
            instagram: this.instagram,
            how_did_you_hear_about_us: referrerToShow,
          },
          complete: true,
        },
      };
      try {
        await this.updateAccount({ accountParams: info });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(
            new Error('Updating account referral source failed'),
            {
              extra: { error },
            }
          );
        }
      }
    },
    async skipCard() {
      let info = {
        lets_connect_one: { skip: true },
      };
      await this.updateAccount({ accountParams: info });
    },
  },
};
</script>
<style lang="scss" scoped>
.connect-one {
  .connect-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1rem;
  }

  .connect-input {
    padding-bottom: 0;
  }
}
</style>

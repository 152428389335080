<template>
  <div class="inventory-tab">
    <div v-if="!hideThisPSO" class="inventory-tab">
      <div :class="psoWrapperClass">
        <p class="u-badge--small post-shoot-option-text-wrapper short">
          post shoot: {{ psoText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inventoryPackage: Object,
  },
  computed: {
    hideThisPSO() {
      return !this.inventoryPackage.post_shoot_option;
    },
    psoWrapperClass() {
      return `pso-wrapper short-style ${this.inventoryPackage.post_shoot_option}`;
    },
    psoText() {
      switch (this.inventoryPackage.post_shoot_option) {
        case 'store_at_soona':
          return 'store';
        case 'return_shipping':
          return 'return';
        case 'discard':
          return 'discard';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.pso-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  &.discard {
    background-color: variables.$green-apple-20;
  }

  &.return_shipping {
    background-color: variables.$bubbletape-pink-20;
  }

  &.store_at_soona {
    background-color: variables.$periwink-blue-20;
  }

  &.short-style {
    border-radius: 0.25rem;
    justify-content: center;
    padding: 0.5rem 1rem;
    min-width: 10rem;
  }
}
</style>

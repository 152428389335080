<template>
  <div class="pro-service-type-pill">
    <template v-if="emoji">
      {{ `${emoji} ` }}
    </template>
    {{ proServiceType }}
  </div>
</template>

<script>
export default {
  name: 'ProServiceTypePill',
  props: {
    proServiceType: String,
  },
  computed: {
    emoji() {
      switch (this.proServiceType) {
        case 'styling':
          return '🎨';
        case 'full body model':
          return '💁🏽';
        case 'hand model':
          return '💅';
        case 'foot model':
          return '👟';
        case 'pet model':
          return '🐕';
        case 'content creator':
          return '🧑‍💻';
        case 'hair and makeup':
          return '💄';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.pro-service-type-pill {
  @include variables_fonts.u-badge--big;
  display: inline;
  background-color: variables.$tangerine-20;
  padding: 0.25rem 0.75rem 0.25rem;
  border-radius: 999px;
  height: fit-content;
  width: max-content;
}
</style>

<script setup>
import { computed, reactive } from 'vue';
import { useCapability } from 'src/composables/useCapability';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useUpdateInventoryPackage } from '@/queries/inventory/useUpdateInventoryPackage';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import PostShootDisplayText from './PostShootDisplayText.vue';
import SelectReturnAddress from './return-address/SelectReturnAddress.vue';
import InventoryPackageHandlingAndReturnAddress from '@/components/user/anytime/inventory/InventoryPackageHandlingAndReturnAddress.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  inventoryPackage: {
    type: Object,
    required: true,
  },
});
const inventoryPackageId = computed(() => props.inventoryPackage.id);

const route = useRoute();
const store = useStore();

const accountId = computed(() => props.accountId);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const isEditable = computed(() => {
  if (props.inventoryPackage.outbound_shipping_detail) {
    return false;
  } else if (props.inventoryPackage.required_action && !isFtSoonaStaff.value) {
    return false;
  } else if (
    props.inventoryPackage.status === 'discarded' &&
    !isFtSoonaStaff.value
  ) {
    return false;
  } else return route.name !== 'edit-package';
});

const returnAddressId = computed(
  () => props.inventoryPackage.return_address?.id ?? undefined
);

const { mutate, isPending, error } =
  useUpdateInventoryPackage(inventoryPackageId);

function updateReturnAddress(newAddressId) {
  if (newAddressId === returnAddressId.value) {
    return;
  }

  mutate(
    { return_address_id: newAddressId },
    {
      onSuccess: async () => {
        await store.dispatch(
          'inventoryPackages/loadPackages',
          // kind of hacky, but by far the easiest solution
          // this at least falls back to more data instead of less
          route.params.reservationId
            ? { reservationId: route.params.reservationId }
            : { accountId: accountId.value }
        );
      },
    }
  );
}

// pso & return address
const dialogs = reactive({
  packageHandling: false,
  returnAddress: false,
});

const openReturnAddressClosePackageHandlingDialog = () => {
  dialogs.packageHandling = false;
  dialogs.returnAddress = true;
};

const closeReturnAddressOpenPackageHandlingDialog = () => {
  dialogs.packageHandling = true;
  dialogs.returnAddress = false;
};
</script>

<template>
  <section
    class="post-shoot-and-return-address"
    :data-postshootoption="inventoryPackage.post_shoot_option"
  >
    <header class="post-shoot-and-return-address__header">
      <h3 class="u-small--heavy post-shoot-and-return-address__header__heading">
        post shoot
      </h3>
      <SoonaButton
        v-if="isEditable"
        variation="tertiary"
        class="post-shoot-and-return-address__change-button"
        @on-click="dialogs.packageHandling = true"
      >
        {{
          !inventoryPackage.post_shoot_option
            ? 'choose processing option'
            : 'change'
        }}
      </SoonaButton>
    </header>

    <div class="u-body--regular post-shoot-and-return-address__body">
      <p>
        <PostShootDisplayText
          :inventory-package-pso="inventoryPackage.post_shoot_option"
        />
      </p>
      <SelectReturnAddress
        v-if="inventoryPackage.post_shoot_option === 'return_shipping'"
        class="post-shoot-and-return-address__body__dropdown"
        :model-value="returnAddressId"
        :account-id="accountId"
        :disabled="isPending"
        :package-to-update="inventoryPackage.id"
        @update:model-value="updateReturnAddress($event)"
      />
    </div>
    <SoonaError v-if="error">
      oops, something went wrong! please try again.
    </SoonaError>

    <InventoryPackageHandlingAndReturnAddress
      :account-id="accountId"
      :inventory-package="inventoryPackage"
      :show-package-handling-dialog="dialogs.packageHandling"
      :show-return-address-dialog="dialogs.returnAddress"
      @close-package-handling-dialog-only="dialogs.packageHandling = false"
      @close-return-address-dialog-only="dialogs.returnAddress = false"
      @close-return-address-open-package-handling-dialog="
        closeReturnAddressOpenPackageHandlingDialog
      "
      @open-return-address-close-package-handling-dialog="
        openReturnAddressClosePackageHandlingDialog
      "
    />
  </section>
</template>

<style lang="scss" scoped>
@use '@/variables';

.post-shoot-and-return-address {
  padding: 0.75rem 1rem;
  border-radius: 0.3125rem;
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$roses-60;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &[data-postshootoption='discard'] {
    background-color: variables.$green-apple-20;
    border: none;
  }
  &[data-postshootoption='return_shipping'] {
    background-color: #f9ecfd;
    border: none;
  }
  &[data-postshootoption='store_at_soona'] {
    background-color: variables.$periwink-blue-10;
    border: none;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;

    &__heading {
      color: variables.$gray-80;
      text-transform: uppercase;
    }
  }

  &__change-button {
    min-width: 0;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    color: variables.$black-default;
  }
}

:deep(.post-shoot-and-return-address__body__dropdown) {
  flex: 1 0 12rem;
  display: inline-block;
}
</style>

<template>
  <div class="soona-tag-option-card-modal-sub-copy">
    <p v-if="subtitle" class="soona-tag-option-card-modal-sub-copy__subtitle">
      {{ subtitle }}
    </p>
    <p v-if="subcopy" class="soona-tag-option-card-modal-sub-copy__subcopy">
      {{ subcopy }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    subcopy: {
      default: undefined,
      required: false,
      type: String,
    },
    subtitle: {
      default: undefined,
      required: false,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-tag-option-card-modal-sub-copy {
  margin: 0 auto;
  max-width: 33.125rem;
  text-align: center;

  &__subtitle {
    @include variables_fonts.u-button--large-caps;

    padding-bottom: 1.5rem;
  }

  &__subcopy {
    @include variables_fonts.u-body--regular;

    padding-bottom: 1.5rem;
  }
}
</style>

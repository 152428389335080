import { toValue } from 'vue';
import { useInfiniteQuery, useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {ref} currentPage
 * @param {ref} itemsPerPage
 * @param {ref} locationId
 * @param {string} slug
 *
 */
export function useGetShotTags({
  accountId,
  currentPage = 1,
  itemsPerPage = 25,
  locationId,
  slug,
} = {}) {
  return useQuery({
    queryKey: queryKeys.shotTags({
      accountId,
      currentPage,
      itemsPerPage,
      locationId,
      slug,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (accountId) params['account_id'] = toValue(accountId);
      if (currentPage) params['page'] = toValue(currentPage);
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);
      if (locationId) params['location_id'] = toValue(locationId);
      if (slug) params['tag_category_slug'] = toValue(slug);

      const response = await http.get('/tags/shot_tags', {
        params,
        signal,
      });

      return {
        pagination: mapPaginationHeaders(response.headers),
        data: response.data,
      };
    },
  });
}

/**
 *
 * @param {ref} itemsPerPage
 * @param {string} slug
 *
 */

export function useGetInfiniteShotTags({ itemsPerPage = 25, slug } = {}) {
  return useInfiniteQuery({
    queryKey: queryKeys.shotTags({ itemsPerPage, slug }),
    queryFn: async ({ signal, pageParam }) => {
      const params = {};

      if (pageParam.currentPage)
        params['page'] = toValue(pageParam.currentPage);
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);
      if (slug) params['tag_category_slug'] = toValue(slug);

      const response = await http.get('/tags/shot_tags', {
        params,
        signal,
      });

      return {
        pagination: mapPaginationHeaders(response.headers),
        data: response.data,
      };
    },
    initialPageParam: { currentPage: 1, itemsPerPage },
    getNextPageParam: lastPage => {
      if (
        lastPage.pagination.currentPage + 1 >
        lastPage.pagination.totalPages
      ) {
        return null;
      } else {
        return {
          currentPage: lastPage.pagination.currentPage + 1,
          itemsPerPage: lastPage.pagination.itemsPerPage,
        };
      }
    },
  });
}

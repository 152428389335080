<script setup>
import { ref, computed } from 'vue';
import CheckmarkPlain from 'src/components/svgs/CheckmarkPlain.vue';
import CsrModals from 'src/components/user/anytime/customer_shoot_readiness/CsrModals.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  slug: {
    default: null,
    type: String,
    required: true,
  },
  completed: {
    default: false,
    type: Boolean,
    required: false,
  },
  currentReservation: {
    default: undefined,
    type: Object,
    required: false,
  },
  isDisabled: {
    default: false,
    type: Boolean,
  },
});

const isModalOpen = ref(false);

const iconNames = {
  models: 'user-heart-alt-1-monochrome',
  'scheduling-preferences': 'calendar-duotone',
  'complete-shot-list': 'wand-magic-sparkles-monochrome',
  'review-pack-shot-list': 'wand-magic-sparkles-monochrome',
  'grocery-list': 'memo-pencil-monochrome',
  'ship-non-fast-pass': 'mailbox-alt-monochrome',
  'ship-fast-pass': 'mailbox-alt-monochrome',
  'complete-shoot-details': 'bullseye-arrow-monochrome',
  'choose-package-handling': 'package-monochrome',
  website: 'monitor-alt-1-monochrome',
};
const iconName = computed(() => iconNames[props.slug]);
</script>

<template>
  <li
    class="csr-card"
    :class="{
      'csr-card--completed': completed,
      'csr-card--disabled': isDisabled,
    }"
  >
    <div class="csr-card__inner">
      <div class="csr-card__left">
        <template v-if="completed">
          <CheckmarkPlain class="csr-card__icon" />
          <h3 class="csr-card__title csr-card__title--completed">
            <slot />
          </h3>
        </template>
        <template v-else>
          <div class="csr-card__icon" :class="`csr-card__icon--${iconName}`">
            <SoonaIcon :name="iconName" />
          </div>
          <div>
            <strong class="u-badge--small csr-card__status">
              <slot name="status" />
            </strong>
            <h2 class="csr-card__title">
              <slot />
            </h2>
            <button
              type="button"
              class="u-button-reset u-label--regular csr-card__view-details"
              :disabled="isDisabled ? isDisabled : undefined"
              @click="() => (isModalOpen = true)"
            >
              view details
            </button>
          </div>
        </template>
      </div>
      <div class="csr-card__right">
        <slot name="cta" />
      </div>
      <CsrModals
        v-if="!completed"
        :slug="slug"
        :is-open="isModalOpen"
        :current-reservation="currentReservation"
        @close-modal="() => (isModalOpen = false)"
      >
        <slot />
      </CsrModals>
    </div>
  </li>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

$wand: #f077f2;
$wand-border: #f9e4fc;
$wand-bg: #fef5ff;
$pencil: #aeb503;

.csr-card {
  list-style: none;

  &__inner {
    background-color: variables.$white-default;
    padding: 1rem;
    border: 0.0625rem solid variables.$gray-30;
    box-shadow: variables.$elevation-0;
    border-radius: 0.625rem;
    transition:
      background-color 0.1s ease-out,
      box-shadow 0.1s ease-out,
      border-color 0.1s ease-out;
    margin-bottom: 1rem;
  }

  &:last-child .csr-card__inner {
    margin-bottom: 0;
  }

  &--completed .csr-card__inner {
    background-color: variables.$gray-10;
    box-shadow: none;
    border-color: variables.$gray-20;
  }

  &--disabled {
    opacity: 0.5;
  }

  &__left {
    display: flex;
    gap: 0.75rem;
    padding: 0;
    margin-bottom: 0.75rem;
  }

  &__right {
    padding: 0;
    gap: 1rem;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;
    flex: 0 0 2.75rem;
    height: 2.75rem;
    width: 2.75rem;
    border-radius: 50%;
    border: 0.0625rem solid transparent;

    &--user-heart-alt-1-monochrome {
      color: variables.$periwink-blue-60;
      border-color: variables.$periwink-blue-20;
      background-color: variables.$periwink-blue-10;
    }

    &--wand-magic-sparkles-monochrome {
      color: $wand;
      border-color: $wand-border;
      background-color: $wand-bg;
    }

    &--memo-pencil-monochrome {
      color: $pencil;
      border-color: variables.$green-apple-20;
      background-color: variables.$green-apple-10;
    }

    &--mailbox-alt-monochrome {
      color: variables.$friendly-red-50;
      border-color: variables.$friendly-red-20;
      background-color: variables.$friendly-red-10;
    }

    &--monitor-alt-1-monochrome {
      color: variables.$tangerine-70;
      border-color: variables.$tangerine-20;
      background-color: variables.$tangerine-10;
    }

    &--bullseye-arrow-monochrome {
      color: variables.$periwink-blue-40;
      border-color: variables.$periwink-blue-20;
      background-color: variables.$periwink-blue-10;
    }

    &--package-monochrome {
      color: variables.$friendly-red-50;
      border-color: variables.$friendly-red-20;
      background-color: variables.$friendly-red-10;
    }

    &--calendar-duotone {
      color: variables.$green-apple-40;
      border-color: variables.$green-apple-20;
      background-color: variables.$green-apple-10;
    }

    svg {
      display: block;
    }
  }

  &__status {
    font-weight: 900;
    display: block;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    color: variables.$friendly-red-50;
  }

  &__title {
    @include variables_fonts.u-subheader--heavy;

    color: variables.$black-default;
    margin-bottom: 0.5rem;
    text-transform: lowercase;
    transition: color 0.1s ease-out;

    &--completed {
      color: variables.$gray-60;
      margin-bottom: 0;
      align-self: center;
    }
  }

  &__view-details {
    display: block;
    text-decoration: underline;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @container csr-action-items (min-width: 36rem) {
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem;
    }

    &__left {
      flex-grow: 1;
      margin-bottom: 0;
    }

    &__icon {
      margin: 0 0.75rem 0 0;
    }
  }
}
</style>

<template>
  <div>
    <div v-if="measurement.format === 'feet_inches'" class="feet-inches-input">
      <div class="select-container">
        <label class="select-label">
          <strong> {{ measurement.measurement_label }} </strong>
        </label>
        <div class="soona-select-container">
          <SoonaSelect
            v-model:model-value="primarySelection"
            placeholder="feet"
            :options="feetSelectionOptions"
            :searchable="false"
            :clearable="true"
          />
          <SoonaSelect
            v-model:model-value="secondarySelection"
            placeholder="inches"
            :options="inchesSelectionOptions"
            :searchable="false"
            :clearable="true"
          />
        </div>
      </div>
    </div>
    <div v-else class="measurement-input">
      <label class="select-label">
        <strong> {{ measurement.measurement_label }} </strong>
      </label>
      <SoonaTextfield
        v-model:model-value.number="primarySelection"
        :required="true"
        :placeholder="'input measurement'"
        :min="0"
        :max="measurement.range.max"
        :rules="['min', 'max', 'integer']"
        type="number"
      />
    </div>
  </div>
</template>

<script>
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'ProServiceProfileMeasurement',
  components: {
    SoonaTextfield,
    SoonaSelect,
  },
  props: {
    measurement: Object,
    modelValue: Array,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      primarySelection: undefined,
      secondarySelection: undefined,
      selection: 0,
      selectedMeasurementsProxy: [],
    };
  },
  computed: {
    feetSelectionOptions() {
      const options = Array.from({ length: 7 }, (_, index) => index + 1);
      return options.map(option => ({
        label: option,
        value: option,
      }));
    },
    inchesSelectionOptions() {
      const options = Array.from({ length: 12 }, (_, index) => index);
      return options.map(option => ({
        label: option,
        value: option,
      }));
    },
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        //update the local copy if a different measure changes
        this.selectedMeasurementsProxy = this.modelValue.map(a => {
          return { ...a };
        });

        // reset selection when selectedMeasurementsProxy has cleared out
        if (newValue.length === 0 && oldValue.length > 0) {
          (this.primarySelection = undefined),
            (this.secondarySelection = undefined),
            (this.selection = 0);
        }
      },
      deep: true,
    },
    async primarySelection(newValue) {
      if (this.measurement.format === 'feet_inches') {
        this.selection = await this.formatHeightInches({
          feet: newValue,
          inches: this.secondarySelection,
        });
      } else {
        this.selection = newValue;
      }
      await this.updateMeasurements();
    },
    async secondarySelection(newValue) {
      if (this.measurement.format === 'feet_inches') {
        this.selection = await this.formatHeightInches({
          feet: this.primarySelection,
          inches: newValue,
        });
      }
      await this.updateMeasurements();
    },
  },
  mounted() {
    //create a local copy on mount to avoid mutating a prop
    this.selectedMeasurementsProxy = this.modelValue.map(a => {
      return { ...a };
    });

    this.setInitialMeasurements();
  },
  methods: {
    formatHeightInches({ feet, inches = 0 }) {
      return feet * 12 + inches;
    },
    convertInchesToFeet(inches) {
      const whole = Math.floor(inches / 12);
      const remainder = inches % 12;

      return [whole, remainder];
    },
    updateMeasurements() {
      let indexNumber = this.selectedMeasurementsProxy.findIndex(
        measurement => measurement.key === this.measurement.measurement_key
      );

      if (this.selection > -1) {
        if (indexNumber === -1) {
          this.selectedMeasurementsProxy.push({
            key: this.measurement.measurement_key,
            value: this.selection,
          });
        } else if (this.selection === 0 || this.selection === '') {
          this.selectedMeasurementsProxy[indexNumber].value = '';
        } else {
          this.selectedMeasurementsProxy[indexNumber].value = this.selection;
        }
      }
      this.$emit('update:modelValue', this.selectedMeasurementsProxy);
    },
    setInitialMeasurements() {
      const measure = this.selectedMeasurementsProxy.find(
        meas => meas.key === this.measurement.measurement_key
      );
      this.primarySelection = measure ? measure.value : undefined;

      if (
        typeof this.primarySelection === 'number' &&
        this.measurement.format === 'feet_inches'
      ) {
        if (this.primarySelection === 0) return;

        let result = this.convertInchesToFeet(this.primarySelection);
        this.primarySelection = result[0];
        this.secondarySelection = result[1];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.measurement-input {
  width: 200px;
  margin: 10px 20px 10px 0;
}

.feet-inches-input {
  display: flex;
  flex-direction: column;
  margin: 10px 20px 10px 0;
  .select-container {
    display: flex;
    flex-direction: column;
    .select-label {
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 0.4375rem;
    }
    .soona-select-container {
      width: 220px;
      display: flex;
    }
    .soona-select-wrapper {
      width: 50%;
    }
    .soona-select-wrapper:first-of-type {
      margin-right: 1rem;
    }
  }
}

:deep(.soona-textfield__input) {
  &::placeholder {
    @include variables_fonts.u-body--regular;
    color: variables.$gray-50;
  }
}
:deep(.soona-textfield__label) {
  color: #4a4a4a; //default body color
  font-weight: 400;
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';
import { useMe } from '@/composables/user/useMe';
import { useAuth } from '@/composables/useAuth';

const { me } = useMe();
const { changeAccountInApp } = useAuth();

const accounts = computed(() => me.value?.accounts ?? []);
</script>

<template>
  <ul
    v-if="accounts.length > 0"
    class="account-picker-list"
    aria-label="available accounts"
  >
    <li v-for="account in accounts" :key="account.id">
      <button
        class="account-picker-list__account-button"
        :aria-pressed="account.current"
        :title="`change account to ${account.name}`"
        data-cypress="user-nav-account-list-item"
        @click="changeAccountInApp(account.id, '/')"
      >
        <SoonaAvatar
          :name="account.name"
          :src="account.avatar"
          size="1.5rem"
          type="account"
        />
        <span
          class="account-name"
          :class="{
            'u-small--heavy': account.current,
            'u-small--regular': !account.current,
          }"
        >
          {{ account.name }}
        </span>
        <SoonaIcon
          v-if="account.current"
          name="check"
          size="small"
          class="active-check"
        />
      </button>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@use '@/variables';

.account-picker-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  list-style: none;
  gap: 0.25rem;

  & > li {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__account-button {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    padding: 0.5rem 0.625rem;
    border-radius: 0.375rem;
    transition: background-color 0.1s ease-out;
    background-color: variables.$periwink-blue-10;

    &:focus-visible {
      outline: 0.125rem solid variables.$periwink-blue-60;
    }

    &:hover {
      background-color: variables.$periwink-blue-20;
    }

    &[aria-pressed='true'],
    &:active {
      background-color: variables.$periwink-blue-20;
    }

    .account-name {
      flex: 1 1 auto;
      text-align: left;
    }

    .active-check {
      color: variables.$periwink-blue-60;
      height: 1.5rem;
      width: 1.5rem;
      margin-left: auto;
    }
  }
}
</style>

<script setup>
import { computed, ref, watch } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import uniqueId from 'lodash/uniqueId';
import {
  GradientRedStart,
  GradientRedEnd,
  GradientGreenStart,
  GradientGreenEnd,
  GradientGoldStart,
  GradientGoldEnd,
  GradientPeriwinkStart,
  GradientPeriwinkEnd,
} from 'src/variables.module.scss';

const props = defineProps({
  accessibleTitle: {
    type: String,
    required: true,
  },
  accessibleDescription: {
    type: String,
    required: false,
    validator(value, props) {
      return value !== props.accessibleTitle;
    },
  },
  data: {
    type: Object,
    default: () => ({
      value: 0,
      label: '',
      minValue: 0,
      maxValue: 1,
      low: 0.33,
      high: 0.66,
    }),
    validator: value =>
      // check that value is in between min and max values
      value.value <= value.maxValue && value.value >= value.minValue,
  },
  isLoading: { type: Boolean, default: false },
});

const id = uniqueId('soona-gauge-');

const data = computed(() => props.data);

const colorLookupStart = {
  null: GradientPeriwinkStart,
  poor: GradientRedStart,
  okay: GradientGoldStart,
  great: GradientGreenStart,
};

const colorLookupEnd = {
  null: GradientPeriwinkEnd,
  poor: GradientRedEnd,
  okay: GradientGoldEnd,
  great: GradientGreenEnd,
};

const radius = 80;
const strokeWidth = radius * 0.25;
const innerRadius = radius - strokeWidth / 2;
const circumference = innerRadius * 2 * Math.PI;
const arc = circumference / 2;
const dashArray = `${arc} ${circumference}`;
const transform = `rotate(180, ${radius}, ${radius})`;

const percentageValue = computed(() => Math.round(data.value.value * 100));
const percentageValueAnimation = ref(percentageValue.value);

watch(percentageValue, () => {
  let start = percentageValueAnimation.value;
  const end = percentageValue.value;
  const duration = 500;
  const range = end - start;
  let current = start;
  const stepTime = Math.abs(Math.floor(duration / Math.abs(range)));
  const increment = range > 0 ? 1 : -1;

  const timer = setInterval(() => {
    current += increment;
    percentageValueAnimation.value = current;
    if ((range > 0 && current >= end) || (range < 0 && current <= end)) {
      clearInterval(timer);
    }
  }, stepTime);
});

const percentNormalized = computed(() =>
  Math.min(Math.max(percentageValue.value, 0), 100)
);
const offset = computed(() => arc - (percentNormalized.value / 100) * arc);
</script>

<template>
  <div class="soona-gauge__container">
    <figure class="soona-gauge">
      <svg
        role="meter"
        :aria-valuenow="data.value"
        :aria-valuemin="data.minValue"
        :aria-valuemax="data.maxValue"
        :aria-labelledby="`${id}-title`"
        :aria-describedy="accessibleDescription ? `${id}-desc` : null"
        :height="radius * 1.5"
        :width="radius * 2"
      >
        <title :id="`${id}-title`">{{ accessibleTitle }}</title>
        <desc v-if="accessibleDescription" :id="`${id}-desc`">
          {{ accessibleDescription }}
        </desc>

        <defs>
          <linearGradient
            :id="`gradient-${data.label}`"
            x1="0"
            x2="0"
            y1="1"
            y2="0"
          >
            <stop offset="0%" :stop-color="colorLookupStart[data.label]" />
            <stop offset="100%" :stop-color="colorLookupEnd[data.label]" />
          </linearGradient>
          <linearGradient id="gradient-background" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" :stop-color="GradientPeriwinkStart"></stop>
            <stop offset="100%" :stop-color="GradientPeriwinkEnd"></stop>
          </linearGradient>
        </defs>

        <circle
          class="soona-gauge_base"
          :cx="radius"
          :cy="radius"
          fill="transparent"
          :r="innerRadius"
          stroke="url(#gradient-background)"
          :stroke-width="strokeWidth"
          :stroke-dasharray="dashArray"
          :transform="transform"
          stroke-linecap="round"
        />

        <circle
          class="soona-gauge_percent"
          :cx="radius"
          :cy="radius"
          fill="transparent"
          :r="innerRadius"
          :stroke="`url(#gradient-${data.label})`"
          :stroke-dasharray="dashArray"
          :stroke-dashoffset="offset"
          stroke-linecap="round"
          :stroke-width="strokeWidth"
          :style="{
            transition: 'stroke-dashoffset 0.3s',
            transitionTimingFunction: 'ease-out',
          }"
          :transform="transform"
        />
      </svg>
      <figcaption class="soona-gauge__label" :title="accessibleTitle">
        <p class="soona-gauge__number u-display--heavy">
          {{ percentageValueAnimation }}<span class="u-visually-hidden">%</span>
        </p>
        <SoonaFlag
          v-if="data.label"
          :class="`rating--${data.label}`"
          type="pill"
        >
          <template #title>{{ data.label }}</template>
        </SoonaFlag>
      </figcaption>
    </figure>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-gauge {
  position: relative;

  &__label {
    position: absolute;
    top: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
  }
}

.rating {
  &--poor {
    background: linear-gradient(
      360deg,
      variables.$gradient-red-start 0%,
      variables.$gradient-red-end 100%
    );
  }

  &--okay {
    background: linear-gradient(
      360deg,
      variables.$gradient-gold-start 0%,
      variables.$gradient-gold-end 100%
    );
  }

  &--great {
    background: linear-gradient(
      360deg,
      variables.$gradient-green-start 0%,
      variables.$gradient-green-end 100%
    );
  }
}
</style>

<script setup>
import { toRefs } from 'vue';

import { useAiProducer } from './Composables/useAiProducer';

import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
  caption: {
    type: String,
    default: '',
  },
});

const { title, caption, disabled } = toRefs(props);

const { submitMessage } = useAiProducer();

const { linkClicked } = useBaseEvents();

function submit() {
  if (disabled.value) return;
  submitMessage(title.value);
  linkClicked({
    context: 'ai-producer',
    subContext: 'ai-pack-producer',
    linkLabel: `prompt ${caption.value}`,
    linkHref: null,
  });
}
</script>

<template>
  <div class="card" :aria-disabled="disabled" role="button" @click="submit">
    <p class="card__caption">
      {{ caption }}
    </p>

    <h3 class="u-label--heavy">
      {{ title }}

      <SoonaIcon size="small" name="arrow-right" class="card__arrow" />
    </h3>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.card {
  flex: 1;
  gap: 0.25rem;
  display: flex;
  cursor: pointer;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  color: variables.$black-default;
  background: variables.$white-default;
  flex-basis: calc((40rem - 100%) * 999);
  border: 0.0625rem solid variables.$periwink-blue-30;
  transition:
    border 0.05s ease-in-out,
    box-shadow 0.05s ease-in-out,
    opacity 0.2s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.2s cubic-bezier(0.16, 1, 0.3, 1);

  &[aria-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    border: 0.0625rem solid variables.$periwink-blue-70;
    box-shadow: variables.$elevation-1;
  }

  &__caption {
    font-weight: 500;
    font-size: 0.6875rem;
    color: variables.$black-default;
  }

  h3 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: variables.$screen-xs-min) {
    gap: 0.5rem;

    h3 {
      align-items: end;
      flex-direction: column;
    }
  }
}
</style>

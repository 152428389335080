import { unref } from 'vue';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import {
  getPromotionWidgetsByType,
  getPromotions,
  createPromotion,
  editPromotion,
  updatePromotion,
  deletePromotion,
} from '../api/promotions';

/**
 *
 * @param {string | Ref<string>} widgetType
 * @param {UseQueryOptions} queryOptions
 */

export function usePromotionWidgets(widgetType, queryOptions) {
  return useQuery({
    queryKey: queryKeys.promotionWidgets(widgetType),
    queryFn: ({ signal }) =>
      getPromotionWidgetsByType(unref(widgetType), signal),
    ...queryOptions,
  });
}

export function useGetPromotions(params = {}, queryOptions) {
  return useQuery({
    queryKey: queryKeys.promotions(params),
    queryFn: ({ signal }) =>
      getPromotions(
        {
          page: unref(params.page),
          itemsPerPage: unref(params.itemsPerPage),
          orderBy: unref(params.orderBy),
          direction: unref(params.direction),
          viewArchived: unref(params.viewArchived),
        },
        signal
      ),
    ...queryOptions,
  });
}

export function useCreatePromotion() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: params => createPromotion(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.promotions(),
      });
    },
  });
}

export function useEditPromotion(id) {
  return useQuery({
    queryKey: queryKeys.promotion(id),
    queryFn: ({ signal }) => editPromotion(unref(id), signal),
  });
}

export function useUpdatePromotion(id) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: params => updatePromotion(unref(id), params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.promotions(),
      });
    },
  });
}

export function useDeletePromotion(id) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: params => deletePromotion(unref(id), params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.promotions(),
      });
    },
  });
}

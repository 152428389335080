<script setup>
import { ref, computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCreateCompetitor } from '@/queries/useCreateCompetitor';
import IntegrateAccountsDialog from '@/components/shared/IntegrateAccountsDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import QuestHelper from '@/components/quest/QuestHelper.vue';

const props = defineProps({
  accountId: { type: String, required: true },
  hasAmazonIntegration: { type: Boolean, required: true },
});

const { buttonClicked } = useBaseEvents();

const accountId = computed(() => props.accountId);
const hasAmazonIntegration = computed(() => props.hasAmazonIntegration);
const connectDialogShow = ref(false);
const urlInput = ref('');

const { mutate, isPending, error, reset } = useCreateCompetitor(accountId);

const addToWatchlist = () => {
  mutate(
    { pageUrl: urlInput.value, externalProvider: 'amazon' },
    {
      onSuccess: () => {
        if (!hasAmazonIntegration.value) connectDialogShow.value = true;

        buttonClicked({
          context: 'competitive analysis',
          subContext: 'watchlist',
          buttonLabel: 'add to watchlist',
          buttonAction: 'addToWatchlist',
        });
        reset();
      },
    }
  );
};

const priorityErrors = usePriorityErrors(error);
</script>

<template>
  <div class="add-to-watchlist">
    <div class="add-to-watchlist__icon-section">
      <ul class="add-to-watchlist__icon-list">
        <li class="add-to-watchlist__list-item">
          <SoonaIcon name="compass" class="add-to-watchlist__icon" />
          <span class="add-to-watchlist__copy u-body--regular"
            >browse Amazon.com</span
          >
        </li>
        <li class="add-to-watchlist__list-item">
          <SoonaIcon name="clone" class="add-to-watchlist__icon" />
          <span class="add-to-watchlist__copy u-body--regular"
            >copy the URL of any product</span
          >
        </li>
        <li class="add-to-watchlist__list-item">
          <SoonaIcon name="plus-large" class="add-to-watchlist__icon" />
          <span class="add-to-watchlist__copy u-body--regular"
            >paste it here</span
          >
        </li>
        <li class="add-to-watchlist__list-item">
          <SoonaIcon
            name="wand-magic-sparkles"
            class="add-to-watchlist__icon"
          />
          <span class="add-to-watchlist__copy u-body--regular"
            >soona analyzes the content and saves to your watchlist</span
          >
        </li>
      </ul>
    </div>
    <div class="add-to-watchlist__input-section">
      <p class="u-subheader--heavy">analyze any Amazon product URL</p>
      <SoonaForm
        id="competitor-form"
        class="add-to-watchlist__form"
        @submit="addToWatchlist"
      >
        <QuestHelper quest-task-slug="competitive_analysis">
          <SoonaTextfield
            v-model:model-value="urlInput"
            :diabled="isPending"
            :has-error="!!priorityErrors"
            placeholder="https://www.amazon.com/dp/Z083ZRLL1H/"
            type="text"
            required
            class="add-to-watchlist__input"
          />
        </QuestHelper>
        <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
        <SoonaButton
          form="competitor-form"
          variation="solid-black"
          class="add-to-watchlist__cta"
          type="submit"
        >
          add to watchlist
        </SoonaButton>
      </SoonaForm>
    </div>
  </div>
  <IntegrateAccountsDialog
    v-if="connectDialogShow"
    amazon-only
    @close="connectDialogShow = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.add-to-watchlist {
  display: flex;
  flex-direction: row;
  box-shadow: variables.$elevation-1;
  background-color: variables.$white-default;
  border-radius: 0.625rem;
  margin: 0 1rem;
  position: relative;
  top: -6.25rem;
  padding: 2rem;
  gap: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;

  &__icon-section,
  &__input-section {
    display: flex;
    flex: 1 auto;
    flex-basis: 50%;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__icon-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__copy {
    vertical-align: middle;
    margin-left: 0.5rem;
  }

  &__icon {
    color: variables.$periwink-blue-40;
    vertical-align: middle;
  }

  &__input {
    padding-bottom: 0;
  }

  &__cta {
    width: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
  }

  .soona-error {
    margin: 0;
  }

  @media (min-width: 640px) {
    margin: 0 2rem;
    flex-wrap: nowrap;
  }
}
</style>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { putPackConfiguration } from '@/api/packConfigurations';
import { queryKeys } from '@/queries/query-keys';

export function useUpdatePackConfiguration() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: packConfiguration => putPackConfiguration(packConfiguration),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.packConfigurations(),
      });
    },
  });
}

<script setup>
import { computed, ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useGetHighlightedPacks } from 'src/queries/useGetHighlightedPacks';
import { useRoute } from 'vue-router';
import CarouselLoading from '@/components/platform-home/CarouselLoading.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaPackSlider from '@/components/ui_library/SoonaPackSlider.vue';
import TrendingPackDialog from './TrendingPackDialog.vue';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/composables/useAccount';

const { linkClicked } = useBaseEvents();
const route = useRoute();

const showDialog = ref(false);
const dialogData = ref(null);

const context = route.meta.context;
const subContext = 'trending packs';

const { currentAccountId } = useMe();
const { account: currentAccount } = useAccount(currentAccountId);

const { data: trendingPacks, isLoading } = useGetHighlightedPacks({
  industrySlug: computed(
    () => currentAccount.value?.industry?.value?.slug || null
  ),
  numberOfPacks: 12,
});

const handleCardClick = pack => {
  dialogData.value = pack;
  showDialog.value = true;

  linkClicked({
    context: context,
    subContext: subContext,
    linkLabel: pack.name,
    linkHref: null,
  });
};

const handleDialogClose = () => (showDialog.value = false);
</script>

<template>
  <section class="trending-packs">
    <h3
      id="dashboard-trending-packs"
      class="u-title--heavy"
      data-cypress="text-trending-heading"
    >
      trending packs
    </h3>
    <CarouselLoading v-if="isLoading" />
    <PageContainerOffset v-else>
      <SoonaPackSlider
        producer-pack-recommender
        aria-labelledby="dashboard-trending-packs"
        :packs="trendingPacks"
        @handle-card-click="handleCardClick"
      />
    </PageContainerOffset>
  </section>
  <TrendingPackDialog
    v-if="showDialog"
    :content-data="dialogData"
    @close="handleDialogClose"
  />
</template>

<style scoped lang="scss">
.trending-packs {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
</style>

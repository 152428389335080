// core-js polyfills are injected based on the browserslist at build time
import 'virtual:book-soona/polyfills';
import { createApp } from 'vue';
import App from '../src/app.vue';
import { store } from 'src/store/store';
import { router } from 'src/router';
import flashMixin from 'src/mixins/flashMixin';
import delightedMixIn from 'src/mixins/delightedMixIn';
import facebookMixIn from 'src/mixins/facebookMixIn';
import LazyLoadDirective from 'src/directives/LazyLoadDirective';
import { initLogRocket } from 'src/config/logrocket';
import { initSentry } from 'src/config/sentry';
import VueSocialSharing from 'vue-social-sharing';
import VueSocketManager from 'src/sockets';
import vSelect from 'vue-select';
import Capability from 'src/components/Capability.vue';
import segmentMixin from 'src/mixins/segmentMixin';
import OpenIndicator from 'src/components/shared/Caret.vue';
import Deselect from 'src/components/shared/Deselect.vue';
import VueAxios from 'vue-axios';
import BeatLoader from 'src/components/shared/BeatLoader.vue';
import VueViewer from 'v-viewer';
import mitt from 'mitt';
import { VueQueryPlugin } from '@tanstack/vue-query';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from 'src/queries/query-client';
import { http, nonAPIHttp } from 'src/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
import { getCapabilities } from '@/api/capabilities';
import { meQueryConfig } from '@/queries/users/me';
import { featureFlagsQueryConfig } from '@/queries/users/feature-flags';
import { createSoonaToaster } from '@/plugins/soonaToaster';
import { createPinia } from 'pinia';

const emitter = mitt();

if (import.meta.env.VITE_LOG_ROCKET_APP_ID) {
  initLogRocket(import.meta.env.VITE_LOG_ROCKET_APP_ID);
}

document.addEventListener('DOMContentLoaded', async () => {
  const element = document.querySelector('.vue-app');
  const data = JSON.parse(element.dataset.attrs);
  const pinia = createPinia();
  const app = createApp(App, { data: data });

  if (import.meta.env.VITE_SENTRY_DSN_JS) {
    initSentry({ app, dsn: import.meta.env.VITE_SENTRY_DSN_JS, router });
  }

  await Promise.all([
    queryClient.prefetchQuery(meQueryConfig),
    queryClient.prefetchQuery(featureFlagsQueryConfig),
  ]);

  // HTTP/1.1 doesn't allow for much in parallel, so let's prioritize this first
  // we can use the app without capabilities loaded, so this is NOT awaited
  queryClient.prefetchQuery({
    queryKey: queryKeys.capability({}),
    queryFn: ({ signal }) => getCapabilities({}, signal),
    gcTime: 60 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
  });

  app.use(store);
  app.use(router);
  app.use(pinia);

  app.component('VSelect', vSelect);
  app.component('Capability', Capability);
  app.component('BeatLoader', BeatLoader);
  vSelect.props.components.default = () => ({ OpenIndicator, Deselect });
  const soonaToaster = createSoonaToaster();
  app.use(soonaToaster);
  app.use(
    new VueSocketManager({
      store,
      queryClient,
      queryKeys,
      soonaToaster,
    })
  );
  app.use(VueSocialSharing);
  app.use(VueViewer);
  app.use(VueQueryPlugin, { queryClient });
  app.mixin(flashMixin);
  app.mixin(delightedMixIn);
  app.mixin(facebookMixIn);
  app.mixin(segmentMixin);
  app.config.globalProperties.$emitter = emitter;
  app.provide('$emitter', emitter);

  app.directive('lazyload', LazyLoadDirective);
  app.use(VueAxios, { http, nonAPIHttp });

  store.http = app.http;
  store.nonAPIHttp = app.nonAPIHttp;

  app.mount(element);
});

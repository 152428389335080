<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextField from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import MarketingEmailConsent from '@/components/shared/MarketingEmailConsent.vue';
import AccountPicker from '@/components/booking/sign_in/AccountPicker.vue';
import GoogleSSO from 'src/components/shared/GoogleSSO.vue';
import AuthTitles from '@/components/integrations/AuthTitles.vue';
import UseDifferentAccountButton from '@/components/shared/UseDifferentAccountButton.vue';
import { useAuth } from '@/composables/useAuth';
import * as Sentry from '@sentry/vue';

const route = useRoute();

const canvaUserToken = route.query.canva_user_token;
const user = route.query.user;
const state = route.query.state;
const nonce = route.query.nonce;

const {
  data,
  handleEmailCheck,
  handleUseDifferentAccount,
  isEmailStep,
  isGoogleSSOStep,
  isPasswordStep,
  isPickAccountStep,
  isSignUpStep,
} = useAuth();

let currentUser = ref(null);
let currentAccount = ref(null);
let accountList = ref([]);
let error = ref('');

const disabled = computed(() => {
  if (isEmailStep) return !data.email;
  if (isPasswordStep) return !data.password;
  if (isSignUpStep) return !data.firstName && !data.email && !data.password;

  return false;
});

async function setCurrentAccount(account) {
  currentAccount.value = account;
}

function switchForms(step) {
  error.value = '';
  data.authStep = step;
}

async function handleCanvaCallback() {
  try {
    const response = await fetch(`/canva/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        canva_user_token: canvaUserToken,
        user: user,
        state: state,
        soona_user_id: currentUser.value.id || currentUser.value.userID,
        soona_account_id: currentAccount.value.id,
        password: data.password,
        nonce: nonce,
      }),
    });

    if (response.status === 200) {
      window.location.href = `https://www.canva.com/apps/configured?success=true&state=${state}`;
    }
  } catch (error) {
    Sentry.captureException(new Error('failed to handle Canva callback'), {
      extra: { error },
    });
  }
}

async function signIn() {
  error.value = '';

  try {
    const response = await fetch(`/canva/sign_in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
    });

    const body = await response.json();

    if (body.error) {
      return (error.value = body.error);
    }

    currentUser.value = body.user;
    accountList.value = body.accounts;

    if (accountList.value.length === 1) {
      currentAccount.value = accountList.value[0];
      handleCanvaCallback();
    }

    if (accountList.value.length > 1) {
      data.authStep = 'pick_account';
    }
  } catch (error) {
    Sentry.captureException(new Error('failed to sign in during Canva flow'), {
      extra: { error },
    });
  }
}

async function signUp() {
  error.value = '';

  try {
    const response = await fetch(`/canva/sign_up`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          email: data.email,
          username: data.email,
          password: data.password,
          password_confirmation: data.password,
          name: data.firstName,
          marketing_email_consent: data.marketingEmailConsent,
        },
      }),
    });

    const body = await response.json();

    if (response.status === 422) {
      return (error.value = body.errors[0]);
    }

    currentUser.value = body.user;
    currentAccount.value = body.account;

    handleCanvaCallback();
  } catch (error) {
    Sentry.captureException(new Error('failed to sign up during Canva flow'), {
      extra: { error },
    });
  }
}
</script>
<template>
  <div class="canva-auth">
    <div class="canva-auth__container">
      <AuthTitles
        :auth-step="data.authStep"
        :first-name="data.firstName"
        :switch-forms="switchForms"
      />

      <!-- Google SSO step -->
      <div v-if="isGoogleSSOStep" class="canva-auth__google-sso">
        <p>you previously signed in with Google.</p>
        <GoogleSSO copy="continue with Google" />
        <div class="canva-auth__different-account">
          not you?
          <UseDifferentAccountButton @on-click="handleUseDifferentAccount" />
        </div>
      </div>

      <!-- Sign In Step -->
      <SoonaForm
        v-if="!isPickAccountStep && !isGoogleSSOStep"
        v-slot="{ hasErrors }"
      >
        <SoonaTextField
          v-if="isEmailStep"
          v-model="data.email"
          class="canva-auth__textfield"
          label="email"
          autocomplete="email"
          placeholder="email"
          type="email"
          required
        />
        <SoonaTextField
          v-if="isPasswordStep"
          v-model="data.password"
          class="canva-auth__textfield"
          label="password"
          type="password"
          autocomplete="current-password"
          placeholder="password"
          required
        />
        <SoonaButton
          v-if="isEmailStep"
          class="canva-auth__submit-btn"
          type="button"
          data-cypress="button-create"
          :disabled="disabled || hasErrors"
          :on-click="handleEmailCheck"
        >
          continue with email
        </SoonaButton>

        <div v-if="error">
          <SoonaError>
            {{ error }}
          </SoonaError>
        </div>

        <UseDifferentAccountButton
          v-if="isPasswordStep"
          @on-click="handleUseDifferentAccount"
        />

        <SoonaButton
          v-if="isPasswordStep"
          class="canva-auth__submit-btn"
          type="button"
          data-cypress="button-create"
          :disabled="disabled"
          :on-click="signIn"
        >
          sign in
        </SoonaButton>

        <span v-if="isEmailStep || isPasswordStep" class="canva-auth__divider">
          or
        </span>

        <GoogleSSO v-if="isEmailStep || isPasswordStep" />
      </SoonaForm>

      <!-- Sign Up Step -->
      <SoonaForm v-if="isSignUpStep" v-slot="{ hasErrors }">
        <GoogleSSO copy="sign up with Google" />
        <span class="canva-auth__divider"> or </span>
        <SoonaTextField
          v-model="data.firstName"
          class="canva-auth__textfield"
          label="name"
          type="text"
          placeholder="first & last"
          required
          :rules="['required', 'minlength']"
        />
        <SoonaTextField
          v-model="data.email"
          class="canva-auth__textfield"
          label="email"
          type="email"
          placeholder="name@company.com"
          required
          :rules="['required', 'email']"
        />
        <SoonaTextField
          v-model="data.password"
          class="canva-auth__textfield"
          label="password"
          type="password"
          autocomplete="new-password"
          placeholder="password"
          :rules="['required', 'minlength']"
          :show-password-icon="true"
        >
          <template #helper>8 characters minimum</template>
        </SoonaTextField>
        <MarketingEmailConsent
          v-model="data.marketingEmailConsent"
          class="canva-auth__marketing-consent"
          align="left"
        />
        <SoonaButton
          class="canva-auth__submit-btn"
          type="submit"
          data-cypress="button-create"
          :on-click="signUp"
          :disabled="disabled || hasErrors"
        >
          create account
        </SoonaButton>
      </SoonaForm>

      <!-- account picker step -->
      <div v-if="isPickAccountStep">
        <AccountPicker
          class="canva-auth__account-picker"
          :account-list="accountList"
          :selected-account-id="currentAccount?.id"
          padding-bottom="16px"
          @on-click="setCurrentAccount"
        />

        <SoonaButton
          class="canva-auth__submit-btn"
          type="button"
          data-cypress="button-create"
          :disabled="!currentAccount"
          :on-click="handleCanvaCallback"
        >
          connect account
        </SoonaButton>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.canva-auth {
  &__container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 1.25rem;
    text-align: center;
  }

  &__submit-btn {
    display: block;
    width: 100%;
  }

  &__divider {
    @include variables_fonts.u-small--heavy;

    display: inline-block;
    padding: 1rem 0;
    position: relative;
    text-align: center;
    width: 100%;

    &::before,
    &::after {
      background-color: variables.$black-default;
      content: '';
      height: 2px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(50% - 20px);
    }

    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }

  &__link-btn {
    background-color: transparent;
    color: variables.$black-default;
    padding: 0;
    text-decoration: underline;
  }

  &__submit-btn {
    margin-top: 1.25rem;
  }

  &__textfield {
    margin-bottom: 1.25rem;
    padding-bottom: 0;
  }

  &__marketing-consent {
    padding-bottom: 0;
  }

  &__account-picker {
    margin-bottom: 1.25rem;
  }

  &__google-sso {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  animationDelay: {
    type: String,
    default: '0s',
  },
  cypressName: {
    default: 'button-slider',
    type: String,
  },
  tagName: {
    type: String,
    default: 'li',
  },
  innerElement: {
    type: String,
    default: 'button',
  },
  size: {
    type: String,
    default: 'large',
    validator: value => {
      return ['medium', 'large'].includes(value);
    },
  },
});

defineOptions({
  inheritAttrs: false,
});

const size = computed(() => props.size);
</script>

<template>
  <component
    :is="tagName"
    class="slider-card"
    :class="`slider-card--${size}`"
    :style="`animation-delay: ${animationDelay}`"
  >
    <component
      :is="innerElement"
      v-bind="$attrs"
      class="u-button-reset slider-card__btn"
      :class="size === 'medium' ? 'u-body--regular' : 'u-body--heavy'"
      :data-cypress="cypressName"
    >
      <slot name="overlay" />
      <div class="slider-card__img">
        <slot />
        <div v-if="$slots['default']" class="slider-card__flag">
          <slot name="flag" />
        </div>
      </div>
      <slot name="caption" />
    </component>
  </component>
</template>

<style scoped lang="scss">
@use '@/variables';

.slider-card {
  flex: 0 0 13.75rem;
  position: relative;

  &__img {
    display: block;
    border-radius: 0.625rem;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 11.25rem;

    :slotted(> img) {
      display: block;
      transition: 0.3s transform cubic-bezier(0.34, 1.56, 0.64, 1);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &--medium {
    flex-basis: 10.25rem;

    .slider-card__img {
      height: 7.6875rem;
    }
  }

  &__flag {
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
  }

  &__btn {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    text-align: left;
    border-radius: 0.625rem;
    background-color: variables.$white-default;
    transition: 0.1s background-color ease-out;
    color: variables.$black-default;
    width: 100%;
    white-space: normal;
    word-break: break-word;

    &:not(a, button) {
      cursor: auto;
    }

    &:is(a, button) {
      &:hover,
      &:focus-visible {
        background-color: variables.$gray-10;

        @media (prefers-reduced-motion: no-preference) and (hover: hover) {
          .slider-card__img :slotted(> img) {
            transform: scale(1.05);
          }
        }
      }
    }

    &:focus-visible {
      outline: 0.125rem solid variables.$periwink-blue-50;
      outline-offset: -0.125rem;
    }
  }
}
</style>

<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  element: {
    default: 'li',
    type: String,
  },
  image: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['handle-remove']);

const imgSrc = computed(() => {
  if (props.image.signed_id) {
    return `/rails/active_storage/blobs/${props.image.signed_id}/${props.image.filename}`;
  }

  return props.image.image_url;
});

const imgTitle = computed(() => {
  if (props.image.filename) return props.image.filename;
  return props.image.title;
});
</script>

<template>
  <component :is="element" class="reference-image">
    <SoonaButton
      class="remove-btn"
      size="small"
      variation="icon-gray-outline"
      @on-click="emit('handle-remove', image.id)"
    >
      <SoonaIcon name="xmark" />
      <span class="u-visually-hidden">remove image</span>
    </SoonaButton>
    <figure>
      <span class="img-wrapper">
        <img :src="imgSrc" alt="" />
      </span>
      <figcaption>{{ imgTitle }}</figcaption>
    </figure>
  </component>
</template>

<style lang="scss" scoped>
@use '@/variables';

.reference-image {
  background: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  overflow: hidden;
  position: relative;
  width: 12.25rem;

  .remove-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .img-wrapper {
    align-items: center;
    background-color: variables.$gray-10;
    display: flex;
    justify-content: center;
    height: 12.25rem;
  }

  img {
    max-height: 100%;
  }

  figcaption {
    padding: 1rem;
  }
}
</style>

<script setup>
import { ref, computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaFaq from '@/components/ui_library/SoonaFaq.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import EmptyShotList from 'images/shot-list/empty-screen@2x.png';
import ProServicesDialog from '@/components/platform-home/ProServicesDialog.vue';
import { useReservation } from '@/composables/useReservation';
import { WhiteDefault } from '@/variables.module.scss';
import { useGetTags } from '@/queries/useGetTags';
import faqData from './faq.json';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const emit = defineEmits(['open-dialog', 'show-scenes']);
const showProducerDialog = ref(false);
const { isInProgress, isWrapped, reservationAccountId } = useReservation(
  computed(() => props.reservationId)
);
const { data: proServices } = useGetTags({
  tag_category_slug: 'pro-service-sm',
});
const producerTagId = computed(
  () => proServices.value?.find(tag => tag.title === 'producer')?.id
);
const linkHref = computed(
  () =>
    `/reservation/${props.reservationId}/shoot-upgrades?pro_service=producer`
);

const handleDialogOpen = () => {
  showProducerDialog.value = true;
};

const handleDialogClose = () => {
  showProducerDialog.value = false;
};
</script>

<template>
  <div v-if="isWrapped" class="shot-list-empty__wrapped">
    <div class="shot-list-empty__wrapped--icon">
      <SoonaIcon name="circle-check-solid" size="large" />
    </div>
    <h3 class="u-headline--heavy">your shoot has wrapped</h3>
    <p>nothing to see here!</p>
  </div>
  <div v-else-if="isInProgress" class="shot-list-empty__in-progress">
    <div class="shot-list-empty__in-progress--icon">
      <SoonaIcon name="circle-check-solid" size="large" />
    </div>
    <h3 class="u-headline--heavy">shoot in progress</h3>
    <SoonaButton element="router-link" to="./">go to gallery</SoonaButton>
  </div>
  <div v-else class="shot-list-empty__new">
    <SoonaNoResults
      :background-color="WhiteDefault"
      :image-source="EmptyShotList"
      mask="none"
    >
      <template #header>let’s plan your shoot</template>
      <!-- TODO: Override max-width -->
      <template #body>
        a few details about your vision will help our creative team prepare.
      </template>
      <template #button>
        <div class="shot-list-empty__new--action-btns">
          <SoonaButton
            variation="secondary-gray"
            @on-click="emit('open-dialog', true)"
          >
            attach a shot list
          </SoonaButton>
          <SoonaButton
            data-cypress="button-add-new-shot"
            @on-click="emit('show-scenes')"
          >
            make a shot list <SoonaIcon name="arrow-right" />
          </SoonaButton>
        </div>
      </template>
    </SoonaNoResults>
    <div class="shot-list-empty__book-producer">
      <span class="shot-list-empty__producer-lightbulb">
        <SoonaIcon name="lightbulb-alt-monochrome" size="small" />
      </span>
      <h3 class="u-body--heavy">Not sure what to make?</h3>
      <p>
        An experienced producer can help you plan a shoot that exceeds your
        brand’s goals.
      </p>
      <SoonaButton variation="tertiary" @on-click="handleDialogOpen"
        >book a producer</SoonaButton
      >
    </div>
    <SoonaFaq :faq-tab-list="faqData" />
    <ProServicesDialog
      v-if="showProducerDialog"
      :account-id="reservationAccountId"
      :tag-id="producerTagId"
      link-label="book a producer"
      :link-href="linkHref"
      @close="handleDialogClose"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
.shot-list-empty {
  &__wrapped,
  &__in-progress {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding: 2rem;
  }

  &__wrapped {
    &--icon {
      background-color: variables.$avo-toast-30;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      padding: 0.5rem;

      > svg {
        color: variables.$avo-toast-60;
      }
    }
  }

  &__in-progress {
    &--icon {
      background-color: variables.$friendly-red-30;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      padding: 0.5rem;

      > svg {
        color: variables.$friendly-red-50;
      }
    }
  }

  &__new {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    &--action-btns {
      display: flex;
      gap: 1rem;
      button {
      }
    }
  }

  &__book-producer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-top: 4rem;
    padding: 1rem;
    width: 100%;
  }

  &__producer-lightbulb {
    background-color: variables.$tangerine-10;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 0.5rem;

    > svg {
      color: variables.$tangerine-60;
    }
  }
}

.no-results {
  &__wrapper {
    border: variables.$gray-30;
    box-shadow: variables.$elevation-0;
  }

  &__text {
    max-width: none !important;
  }
}
</style>

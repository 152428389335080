<script setup>
import { computed } from 'vue';
import RecommendedTile from '@/components/user/anytime/reservation/gallery/RecommendedTile.vue';
import AddDefaultCollectionToBagTile from '@/components/user/anytime/reservation/gallery/AddDefaultCollectionToBagTile.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useStore } from 'vuex';
import { useReservation } from '@/composables/useReservation';
import { useGetUnBaggedStaffPicksTotal } from '@/queries/staff_picks_collection/useGetUnBaggedStaffPicksTotal';
import { useGetUnBaggedFavoritesTotal } from '@/queries/favorites_collection/useGetUnBaggedFavoritesTotal';

const props = defineProps({
  favoritesCollectionId: {
    type: [String, Number],
    default: null,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  selectedFilter: {
    type: String,
    required: true,
    default: 'all',
  },
  staffPicksCollectionId: {
    type: [String, Number],
    default: null,
  },
  staffPickCount: {
    type: Number,
    required: true,
    default: 0,
  },
});

const emit = defineEmits(['filterStaffPicks', 'toggleLoader']);

const favoritesCollectionId = computed(() => props.favoritesCollectionId);
const reservationId = computed(() => props.reservationId);
const staffPicksCollectionId = computed(() => props.staffPicksCollectionId);
const staffPickCount = computed(() => props.staffPickCount);
const selectedFilter = computed(() => props.selectedFilter);

const {
  reservation,
  recommendedTileVariation,
  isFirstBooking,
  isNoShow,
  isWrapped,
  photographer,
} = useReservation(reservationId);

const store = useStore();
const authedAccountId = computed(() => store.state.currentUser?.accountId);
const accountId = computed(() => reservation.value?.account_id);

// show recommended tile
const recommendedTileCommonCriteria = computed(() => {
  // right now allStaffPicksPurchased would be staffPickCount === 0
  return isWrapped.value && selectedFilter.value === 'all';
});

const showRecommendedTile = computed(() => {
  if (recommendedTileVariation.value === 'trophy') {
    return (
      recommendedTileCommonCriteria.value &&
      isFirstBooking.value &&
      isNoShow.value
    );
  }

  return recommendedTileCommonCriteria.value;
});

// staff picks
const getStaffPicksUnBaggedTotal = computed(() => {
  return (
    selectedFilter.value === 'expert_picks' &&
    authedAccountId.value === accountId.value &&
    !!staffPicksCollectionId.value
  );
});
const { data: staffPicksUnBaggedTotal } = useGetUnBaggedStaffPicksTotal(
  {
    accountId,
    staffPicksCollectionId,
    reservationId,
  },
  {
    enabled: getStaffPicksUnBaggedTotal,
  }
);
const showExpertPickTile = computed(() => {
  return (
    selectedFilter.value === 'expert_picks' &&
    authedAccountId.value === accountId.value &&
    staffPicksUnBaggedTotal.value > 0
  );
});

// favorites
const getFavoritesUnBaggedTotal = computed(() => {
  return (
    selectedFilter.value === 'favorites' &&
    authedAccountId.value === accountId.value &&
    !!favoritesCollectionId.value
  );
});
const { data: favoritesUnBaggedTotal } = useGetUnBaggedFavoritesTotal(
  {
    accountId,
    favoritesCollectionId,
    reservationId,
  },
  {
    enabled: getFavoritesUnBaggedTotal,
  }
);
const showFavoritesTile = computed(() => {
  return (
    selectedFilter.value === 'favorites' &&
    authedAccountId.value === accountId.value &&
    favoritesUnBaggedTotal.value > 0
  );
});

// emit toggle loader
const toggleLoader = val => {
  emit('toggleLoader', val);
};
</script>
<template>
  <div>
    <!-- show recommended tile -->
    <RecommendedTile
      v-if="staffPickCount >= 5 && showRecommendedTile"
      :photographer="photographer"
      :reservation-id="reservationId"
      @select-staff-picks="() => emit('filterStaffPicks')"
    />
    <!-- add default expert picks collection to bag -->
    <AddDefaultCollectionToBagTile
      v-if="showExpertPickTile"
      :account-id="accountId"
      :collection-id="staffPicksCollectionId"
      :reservation-id="reservationId"
      link-label="add expert picks to bag"
      sub-context="expert picks banner"
      @toggle-loader="val => toggleLoader(val)"
    >
      <template #title>
        <SoonaIcon
          class="add-collection-container__title--icon"
          name="trophy-duotone"
        />
        expert picks
      </template>
      <template #sub-title>our expert picks based on your goals</template>
      <template #button-text>add expert picks to bag</template>
    </AddDefaultCollectionToBagTile>
    <!-- add default favorites collection to bag -->
    <AddDefaultCollectionToBagTile
      v-if="showFavoritesTile"
      :account-id="accountId"
      :collection-id="favoritesCollectionId"
      :reservation-id="reservationId"
      link-label="add favorites to bag"
      sub-context="favorites banner"
      @toggle-loader="emit('toggleLoader')"
    >
      <template #title>
        <SoonaIcon
          class="add-collection-container__title--icon"
          name="heart-duotone"
        />
        favorites
      </template>
      <template #button-text>add favorites to bag</template>
    </AddDefaultCollectionToBagTile>
  </div>
</template>

<script setup>
import { inject, ref, computed, watchEffect, defineAsyncComponent } from 'vue';
import uniqueId from 'lodash/uniqueId';
import { usePhoneHelper } from 'src/composables/usePhoneHelper';
import SoonaError from './SoonaError.vue';

const props = defineProps({
  cypressName: {
    default: undefined,
    required: false,
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  hideLabel: {
    default: false,
    type: Boolean,
  },
  label: {
    default: undefined,
    required: false,
    type: String,
  },
  required: {
    default: undefined,
    required: false,
    type: Boolean,
  },
  modelValue: {
    default: '',
    required: false,
    type: [String],
  },
});
const emit = defineEmits(['update:modelValue', 'onBlur']);

const VueTelInput = defineAsyncComponent(
  async () => (await import('vue-tel-input')).VueTelInput
);
import('vue-tel-input/vue-tel-input.css');

const { numberWithoutCountryCode, isPhoneNumberValid } = usePhoneHelper();
const formattedPhoneNumber = ref('');
const isPrefilledPhoneInvalid = ref(false);
watchEffect(async () => {
  const value = props.modelValue;
  isPrefilledPhoneInvalid.value =
    !formattedPhoneNumber.value && value && !(await isPhoneNumberValid(value));
});

const $emitter = inject('$emitter');

const id = uniqueId('telephone-input-');
const uniqueComponentId = uniqueId('soona-telephone-field-');
const dropdownOptions = {
  disabled: false,
  showDialCodeInList: true,
  showDialCodeInSelection: true,
  showFlags: true,
  showSearchBox: false,
  tabindex: 0,
};
const inputOptions = computed(() => {
  return {
    autocomplete: 'on',
    autofocus: false,
    'aria-describedby': '',
    id: '',
    maxlength: 25,
    name: 'telephone',
    showDialCode: false,
    placeholder: 'enter a phone number',
    readonly: false,
    required: props.required,
    tabindex: 0,
    type: 'tel',
    styleClasses: '',
  };
});
const validationErrors = ref([]);

function addError(errorType, errorMessage) {
  const exists = validationErrors.value.find(error => error.type === errorType);

  if (exists) {
    // update the error message text if the error type already exists
    validationErrors.value = validationErrors.value.map(error =>
      error.type === errorType ? { ...error, message: errorMessage } : error
    );
  } else {
    // otherwise, immutably add a new error
    validationErrors.value = [
      ...validationErrors.value,
      { type: errorType, message: errorMessage },
    ];
  }

  // this is for the SoonaForm
  $emitter.emit('set-validation-errors', {
    childComponentId: uniqueComponentId.value,
    errors: validationErrors.value,
  });
}

function removeError(errorType) {
  validationErrors.value = validationErrors.value.filter(
    error => error.type !== errorType
  );

  $emitter.emit('set-validation-errors', {
    childComponentId: uniqueComponentId.value,
    errors: validationErrors.value,
  });
}

function validate(phoneObject) {
  // could be true or undefined for a valid state
  if (phoneObject.valid !== false) {
    removeError('phoneFormatting');
  } else {
    addError('phoneFormatting', 'enter a valid phone number');
  }
}

function onBlur() {
  emit('onBlur');
}

function onInput(_, phoneObject) {
  if (formattedPhoneNumber.value === phoneObject.formatted) return;
  formattedPhoneNumber.value = phoneObject.formatted;
  emit('update:modelValue', phoneObject.number);
  validate(phoneObject);
}

watchEffect(async () => {
  const formattedModelValue = await numberWithoutCountryCode(props.modelValue);
  if (
    formattedModelValue &&
    formattedPhoneNumber.value !== formattedModelValue
  ) {
    formattedPhoneNumber.value = formattedModelValue;
  }
});
</script>
<template>
  <div class="soona-telephone-field">
    <div
      v-if="label"
      class="soona-telephone-field__label-wrapper"
      :class="{ 'u-visually-hidden': hideLabel }"
    >
      <label class="soona-telephone-field__label" :for="id">
        {{ label }}
        <span v-if="$slots['tooltip']" class="soona-telephone-field__tooltip">
          <slot name="tooltip"></slot>
        </span>
      </label>
      <span v-if="$slots['helper']" class="soona-telephone-field__helper">
        <slot name="helper"></slot>
      </span>
    </div>
    <VueTelInput
      :id="id"
      :model-value="formattedPhoneNumber"
      :preferred-countries="['us', 'ca']"
      mode="national"
      :disabled="disabled"
      :auto-default-country="true"
      :data-cypress="cypressName"
      :valid-characters-only="true"
      :auto-format="true"
      :input-options="inputOptions"
      :dropdown-options="dropdownOptions"
      @blur="onBlur"
      @on-input="onInput"
    />
    <SoonaError v-if="isPrefilledPhoneInvalid">
      Invalid phone number of {{ modelValue }}
    </SoonaError>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';
@use '@/variables';

.soona-telephone-field {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.4375rem;
  }

  &__label {
    @include variables_fonts.u-label--heavy;

    align-items: center;
    color: variables.$black-default;
    display: flex;
  }

  &__tooltip {
    padding-left: 0.3125rem;
  }

  &__helper {
    @include variables_fonts.u-label--regular;

    color: variables.$gray-60;

    a {
      color: inherit;
      transition: 0.1s;

      &:hover {
        color: variables.$periwink-blue-70;
      }

      &:active {
        color: variables.$black-default;
      }
    }
  }
  :deep(.vue-tel-input) {
    border-radius: 0.25rem;
    display: flex;
    border: 0.0625rem solid variables.$gray-30;
    text-align: left;

    &:hover {
      border-color: variables.$gray-50;
    }

    &:focus-within {
      box-shadow: none;
      border-color: variables.$black-default;
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    }
    .vti__input {
      padding: 0.5rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.3125rem;
    }
    .vti__dropdown {
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }
}
</style>

<script setup></script>
<template>
  <div class="action-bar-base">
    <div class="action-bar-base__left">
      <slot name="actions-left" />
    </div>
    <div class="action-bar-base__right">
      <slot name="actions-right" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use 'src/variables';

.action-bar-base {
  background-color: variables.$periwink-blue-10;
  border-bottom: 0.0625rem solid variables.$gray-30;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  height: max-content;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  width: 100%;

  &__left {
    display: flex;
    gap: 0.25rem;

    .action-bar-base__left-btn:first-child {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }

    .action-bar-base__left-btn:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem;
    }
  }

  &__left-btn {
    background-color: variables.$gray-20;
    border: 0.0625rem solid variables.$gray-20;
    height: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-30;
    }

    &:active {
      background-color: variables.$gray-20;
    }
  }

  &__right {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }
}
</style>

import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';

/**
 *
 * @param {string | Ref<string>} [collectionId]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useAnimatedCollectionDigitalAssets(collectionId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.collectionAssets(collectionId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/collections/${toValue(
          collectionId
        )}/animated_collection_digital_assets`,
        {
          signal,
        }
      );
      return response.data?.animated_collection_digital_assets || [];
    },
    ...queryOptions,
  });
}

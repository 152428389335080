<script setup>
import { computed, ref, onMounted } from 'vue';
import { useTitle } from '@vueuse/core';
import AccountInfo from '@/components/user/anytime/dashboard/AccountInfo.vue';
import AddProductsDropdown from './AddProductsDropdown.vue';
import ProductIntegrationsHeader from './ProductIntegrationsHeader.vue';
import SoonaFeedback from 'src/components/ui_library/SoonaFeedback.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTab from 'src/components/ui_library/SoonaTab.vue';
import ManageIntegrationsButton from './ManageIntegrationsButton.vue';
import ManageIntegrationsDialog from './ManageIntegrationsDialog.vue';
import { useAccount } from '@/queries/account/useAccount';
import { useCapability } from '@/composables/useCapability';
import { useRoute } from 'vue-router';
import { useUnidentifiedAccountInventoryItemsCount } from '@/queries/inventory/useInventoryItems';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useIntegrations } from '@/composables/useIntegrations';
import RemoveAmazonAccountDialog from '@/components/user/anytime/products/RemoveAmazonAccountDialog.vue';

const props = defineProps({
  accountId: { type: String, required: true },
});

const { pageViewed, linkClicked } = useBaseEvents();
const route = useRoute();

useTitle(`${route.meta.page_title} | soona`);

const displayRemoveAmazonAccountDialog = ref(false);
const selectedIntegrationId = ref(null);

const accountId = computed(() => props.accountId);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const { data: account, isSuccess: accountIsSuccess } = useAccount(accountId, {
  enabled: isFtSoonaStaff,
});

const currentRouteName = computed(() => route.name);

const isProductCatalogTab = computed(() => {
  return currentRouteName.value === 'product-catalog';
});

const isProductInventoryTab = computed(() => {
  return currentRouteName.value === 'product-inventory';
});

const productQueryParams = ref({});
const setProductQueryParams = value => {
  productQueryParams.value = value;
};

const inventoryQueryParams = ref({});
const setInventoryQueryParams = value => {
  inventoryQueryParams.value = value;
};

const { data: unidentifiedItems } =
  useUnidentifiedAccountInventoryItemsCount(accountId);

const unidentifiedItemsCount = computed(() => unidentifiedItems.value ?? 0);

// integrations module
const { hasActiveAmazonOrShopifyIntegration } = useIntegrations(accountId);

const displayManageIntegrationsDialog = ref(false);
function toggleIntegrationsDialog() {
  displayManageIntegrationsDialog.value =
    !displayManageIntegrationsDialog.value;
}

function manageIntegrationsButtonClicked(extraInfo = {}) {
  if (extraInfo.buttonCopy) {
    linkClicked({
      context: route.meta.context,
      subContext: 'integrations header',
      linkLabel: extraInfo.buttonCopy,
      linkHref: null,
    });
  }
  toggleIntegrationsDialog();
}

onMounted(() => {
  pageViewed();
});

const openRemoveAmazonAccountDialogHandler = integrationId => {
  selectedIntegrationId.value = integrationId;
  displayManageIntegrationsDialog.value = false;
  displayRemoveAmazonAccountDialog.value = true;
};

const closeRemoveAmazonAccountDialog = () => {
  displayManageIntegrationsDialog.value = true;
  displayRemoveAmazonAccountDialog.value = false;
};
</script>

<template>
  <div class="products-page">
    <header class="products-page__header">
      <div class="products-page__header--row">
        <AccountInfo
          v-if="isFtSoonaStaff && accountIsSuccess"
          class="products-page__header--account-info"
          :account="account"
          :link-to-profile="true"
        />
        <div class="products-page__header--title">
          <h1 class="u-headline--heavy">my products</h1>
        </div>
        <div class="products-page__header--actions">
          <AddProductsDropdown :account-id="accountId" />
        </div>
      </div>
      <div class="products-page__integrations">
        <ProductIntegrationsHeader :account-id="accountId" />
        <ManageIntegrationsButton
          :account-id="accountId"
          :remove-amazon-data-dialog-open="displayRemoveAmazonAccountDialog"
          @on-click="manageIntegrationsButtonClicked"
        />

        <SoonaButton
          v-if="hasActiveAmazonOrShopifyIntegration && accountIsSuccess"
          copy="view insights"
          element="router-link"
          size="medium"
          :to="{ path: `/account/${account.id}/listing-insights` }"
          variation="tertiary"
        >
          <SoonaIcon name="chart-simple" size="medium" />
          view insights
          <SoonaIcon name="arrow-right" size="medium" />
        </SoonaButton>
      </div>
    </header>
    <div class="products-page__content">
      <div class="products-page__tabs">
        <SoonaTab
          copy="product catalog"
          :to="{
            path: `/account/${accountId}/products`,
            query: productQueryParams,
          }"
          :is-selected="isProductCatalogTab"
          :remove-bottom-border="true"
        />
        <SoonaTab
          :to="{
            path: `/account/${accountId}/products/inventory`,
            query: inventoryQueryParams,
          }"
          :is-selected="isProductInventoryTab"
          :remove-bottom-border="true"
        >
          <template #copy>
            <span class="unidentified-items">
              inventory
              <span
                v-if="unidentifiedItemsCount > 0"
                class="unidentified-items__count-badge"
              >
                {{ unidentifiedItemsCount }}
                <span class="u-visually-hidden">unidentified items</span>
              </span>
            </span>
          </template>
        </SoonaTab>
      </div>

      <router-view
        :account-id="accountId"
        @product-query-params="setProductQueryParams($event)"
        @inventory-query-params="setInventoryQueryParams($event)"
      ></router-view>

      <SoonaFeedback class="products-page__feedback" flag-name="beta">
        <template #body>
          <h3 class="u-body--heavy">help us improve this new feature!</h3>
          <a
            class="products-page__feedback--link"
            href="https://forms.gle/SuaZ9pT7Dj5qbGNH6"
            target="_blank"
          >
            share feedback
          </a>
        </template>
      </SoonaFeedback>
    </div>
    <ManageIntegrationsDialog
      v-if="displayManageIntegrationsDialog"
      class="products-page__manage-integrations-dialog"
      :account-id="accountId"
      @close="toggleIntegrationsDialog"
      @open-remove-amazon-data-dialog="openRemoveAmazonAccountDialogHandler"
    />
    <RemoveAmazonAccountDialog
      v-if="displayRemoveAmazonAccountDialog && selectedIntegrationId"
      :account-id="accountId"
      :integration-id="selectedIntegrationId"
      @close="closeRemoveAmazonAccountDialog"
    />
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.products-page {
  &__header {
    margin-bottom: 1rem;

    &--row {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;
      justify-content: space-between;
      padding-bottom: 1.5rem;
      color: variables.$black-default;
    }

    &--account-info {
      margin-bottom: 1.5rem;
    }

    &--title,
    &--actions,
    &--integrations {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;
    }

    &--actions {
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  &__integrations {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  &__feedback {
    &--link {
      text-decoration: underline;
    }
  }
}

.unidentified-items {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;

  &__count-badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 0.25rem;
    letter-spacing: 0.015625rem;
    min-width: 1rem;
    height: 1rem;
    background: variables.$friendly-red-50;
    color: variables.$white-default;
    border-radius: 10em;
  }
}
</style>

import { http } from '../config/vue-axios';

/**
 *
 * @param {number} reservationId
 * @param {string} tagCategorySlug
 * @param {string} tagSubcategory
 * @param {string} discountId
 * @param {AbortSignal} [signal]
 */
export async function getReservationTags(
  reservationId,
  tagCategorySlug,
  tagSubcategory,
  discountId,
  signal
) {
  let url = `/reservations/${reservationId}/tags/${tagCategorySlug}`;
  let params = {};

  if (tagSubcategory) {
    params['subcategory'] = tagSubcategory;
  }

  if (discountId) {
    params['discount_id'] = discountId;
  }

  const response = await http.get(url, { params, signal });

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {number} tagId
 * @param {number} quantity
 * @param {boolean} addRecommended
 */
export async function addReservationTag(
  reservationId,
  tagId,
  quantity = 1,
  addRecommended = false
) {
  const url = `/reservations/${reservationId}/tags`;
  const params = {
    tag_id: tagId,
    quantity: quantity,
    add_recommended: addRecommended,
  };

  const response = await http.post(url, params);

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {number} reservationTagId
 * @param {boolean} deleteObsoleted
 */
export async function removeReservationTag(
  reservationId,
  reservationTagId,
  deleteObsoleted
) {
  const url = `/reservations/${reservationId}/tags/${reservationTagId}`;
  const params = { delete_obsoleted: deleteObsoleted };

  const response = await http.delete(url, { params });

  return response.status === 204;
}

<script setup>
import { computed } from 'vue';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import faqData from './faq.json';
import useSubscriptionAndIntegration from '@/composables/subscription/useSubscriptionAndIntegration';
import { useAccount } from '@/composables/useAccount';
import PageContainerOffsetTop from '@/components/platform-layout/PageContainerOffsetTop.vue';
import AddToWatchlist from '@/components/user/anytime/competitive_analysis/AddToWatchlist.vue';
import FreemiumBanner from '@/components/shared/FreemiumBanner.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import ExampleCompetitorsCardList from '@/components/user/anytime/competitive_analysis/ExampleCompetitorsCardList.vue';
import CompetitorsCardList from '@/components/user/anytime/competitive_analysis/CompetitorsCardList.vue';
import { isAmazonIntegration } from '@/components/user/anytime/products/integration-utils';

const props = defineProps({
  accountId: { type: String, required: true },
});

const accountId = computed(() => props.accountId);

const { account, industryTitle } = useAccount(accountId);

const { hasTierThreeSubscription, hasLiveSubscription, integrationsData } =
  useSubscriptionAndIntegration(accountId);

const integrations = computed(() => integrationsData.value ?? []);

const hasAmazonIntegration = computed(() =>
  integrations.value.some(isAmazonIntegration)
);

const amazonIntegration = computed(() =>
  integrations.value.find(isAmazonIntegration)
);

const freemiumTitle = `keep an eye on the competition`;
const freemiumDescription = `identify competitors of your choice and the visual opportunities that give you an edge.`;

const displayFreemiumBanner = computed(
  () => account.value.total_competitors_count === 0
);
</script>

<template>
  <PageContainerOffsetTop>
    <PageContainerOffset>
      <FreemiumBanner
        v-if="displayFreemiumBanner"
        section="competitiveAnalysis"
      >
        <template #title>{{ freemiumTitle }}</template>
        <template #description>{{ freemiumDescription }}</template>
        <AddToWatchlist
          :account-id="accountId"
          :has-amazon-integration="hasAmazonIntegration"
        />
      </FreemiumBanner>

      <div class="watchlist">
        <header v-if="displayFreemiumBanner" class="watchlist__freemium-header">
          <h2 class="u-headline--heavy">competitive analysis</h2>
        </header>
        <CompetitorsCardList
          v-if="!displayFreemiumBanner"
          :account-id="accountId"
          :has-amazon-integration="hasAmazonIntegration"
          :amazon-integration="amazonIntegration"
          :industry-title="industryTitle"
          :has-live-subscription="hasLiveSubscription"
          :total-competitors-count="account.total_competitors_count"
          :max-competitors-count="account.max_competitors_count"
        />
        <ExampleCompetitorsCardList
          :account-id="accountId"
          :has-amazon-integration="hasAmazonIntegration"
          :industry-title="industryTitle"
        />
        <SoonaFaq
          v-if="!hasTierThreeSubscription"
          class="watchlist__faq"
          :faq-tab-list="faqData"
        />
      </div>
    </PageContainerOffset>
  </PageContainerOffsetTop>
</template>

<style lang="scss" scoped>
@use '@/variables';

.watchlist {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 1rem;

  &__title {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;
    margin: 1rem 0;

    &--actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 1rem;
    }
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__faq {
    margin-top: 2rem;
    min-width: 100%;
  }

  @media (min-width: variables.$screen-sm-min) {
    margin: 0 2rem;
  }
}
</style>

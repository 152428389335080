import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getIndustries } from '../api/industries';

export function useIndustries() {
  return useQuery({
    queryKey: queryKeys.industries(),
    queryFn: ({ signal }) => getIndustries(signal),
  });
}

<script setup>
import { computed } from 'vue';

const props = defineProps({
  color: {
    default: 'gray',
    type: String,
    validator: function (value) {
      // eslint-disable-next-line no-use-before-define
      return colors.concat(['gray', 'random']).includes(value);
    },
  },
  customColorBackground: {
    type: String,
    default: '',
  },
  customColorGradient: {
    type: String,
    default: '',
  },
  randomizeTiming: {
    default: false,
    type: Boolean,
  },
});

const animationDelay = computed(() =>
  props.randomizeTiming ? Math.floor(Math.random() * 501) : 0
);
const animationDuration = computed(() =>
  props.randomizeTiming
    ? Math.floor(Math.random() * (2200 - 1600) + 1600)
    : 2000
);

const color = computed(() => props.color);
const customColors = computed(
  () => !!props.customColorBackground && !!props.customColorGradient
);
const skeletonColor = computed(() => {
  if (customColors.value) return 'custom';
  if (color.value !== 'random') return color.value;
  // eslint-disable-next-line no-use-before-define
  return colors[Math.floor(Math.random() * colors.length)];
});
</script>

<template>
  <div
    class="skeleton"
    :class="`skeleton--${skeletonColor}`"
    :style="{
      animationDelay: `${animationDelay}ms`,
      animationDuration: `${animationDuration}ms`,
    }"
    aria-hidden="true"
  />
</template>

<script>
const colors = [
  'wasabi',
  'friendly-red',
  'bubbletape-pink',
  'golden-hour',
  'periwink-blue',
];

export default {};
</script>

<style lang="scss" scoped>
@use '@/variables';

@keyframes shimmer {
  60%,
  100% {
    background-position-x: 0;
  }
}

.skeleton {
  --skeleton-background-color: #{variables.$gray-20};
  --skeleton-shimmer-color: #{variables.$gray-10};
  display: block;
  user-select: none;
  border-radius: 0.1875rem;
  background: linear-gradient(
    90deg,
    var(--skeleton-background-color) 35%,
    var(--skeleton-shimmer-color) 50%,
    var(--skeleton-background-color) 65%
  );
  background-size: 300%;
  background-position-x: 100%;
  animation: shimmer 2s infinite ease-in;

  @media (prefers-reduced-motion) {
    animation: none;
    background-position-x: 0;
  }

  &--wasabi {
    --skeleton-background-color: #{variables.$green-apple-30};
    --skeleton-shimmer-color: #{variables.$green-apple-20};
  }

  &--friendly-red {
    --skeleton-background-color: #{variables.$friendly-red-50};
    --skeleton-shimmer-color: #{variables.$friendly-red-40};
  }

  &--bubbletape-pink {
    --skeleton-background-color: #{variables.$bubbletape-pink-30};
    --skeleton-shimmer-color: #{variables.$bubbletape-pink-20};
  }

  &--golden-hour {
    --skeleton-background-color: #{variables.$tangerine-40};
    --skeleton-shimmer-color: #{variables.$tangerine-30};
  }

  &--periwink-blue {
    --skeleton-background-color: #{variables.$periwink-blue-30};
    --skeleton-shimmer-color: #{variables.$periwink-blue-20};
  }

  &--custom {
    --skeleton-background-color: v-bind('customColorBackground');
    --skeleton-shimmer-color: v-bind('customColorGradient');
  }
}
</style>

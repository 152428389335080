<script setup>
import { computed } from 'vue';
import { useCapability } from '@/composables/useCapability';
import AssetAddOns from '@/components/checkout/AssetAddOns.vue';
import AssetHeading from '@/components/checkout/AssetHeading.vue';
import AssetImage from '@/components/checkout/AssetImage.vue';
import AssetNotes from '@/components/checkout/AssetNotes.vue';
import StandardEditCopy from '@/components/checkout/StandardEditCopy.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: {
    required: true,
    type: Number,
  },
  addOns: {
    default: () => [],
    type: Array,
  },
  asset: {
    required: true,
    type: Object,
  },
  isOrder: {
    default: false,
    required: true,
  },
  reservationId: {
    type: Number,
    required: true,
  },
});

const accountId = computed(() => props.accountId);
const asset = computed(() => props.asset);
const assetId = computed(() => asset.value.id);
const assetCompareFlag = useFlag('toaster_asset_compare');

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const assetTotal = computed(
  () =>
    +props.addOns.find(
      a => a.product.name.includes('Add On') || a.product.name === 'gif'
    )?.total || 0
);

const compareLink = computed(() => {
  if (!assetCompareFlag.value) return false;

  if (props.isOrder) {
    return {
      name: 'order-asset-compare',
      params: { assetId: assetId.value },
    };
  }

  return {
    name: 're-edit-asset-compare',
    params: { assetId: assetId.value },
  };
});
</script>

<template>
  <div class="checkout-asset" :data-cypress="`checkout-asset-${asset.id}`">
    <AssetImage
      :compare-link="compareLink"
      :content-type="asset.content_type"
      :watermark-url="
        asset.watermark_url || asset.preview_url || asset.preview?.url
      "
      :image-url="asset.preview_url || asset.preview?.url"
      :is-video="asset.media_type === 'video'"
      :is-gif="asset.media_type === 'animation'"
      :title="asset.title"
    />
    <div class="checkout-asset__right">
      <AssetHeading
        :asset-id="assetId"
        :reservation-id="reservationId"
        :show-link="isSoonaStaff"
        :show-price="isOrder"
        :title="asset.title"
        :total="assetTotal"
      />
      <AssetAddOns
        v-if="addOns.length"
        :account-id="accountId"
        :add-on-list="
          addOns.filter(
            a => !a.product.name.includes('Add On') && a.product.name !== 'gif'
          )
        "
        :is-photo="asset.media_type === 'photo'"
        read-only
        :reservation-id="reservationId"
      />
      <StandardEditCopy v-if="addOns.length" />
    </div>
    <div class="checkout-asset__bottom">
      <AssetNotes v-if="isSoonaStaff" :asset="asset" />
      <template v-else-if="asset.note">
        <p class="u-label--heavy">editing notes</p>
        <p class="u-body--regular">{{ asset.note.content }}</p>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.checkout-asset {
  background-color: variables.$white-default;
  border-bottom: 0.0625rem solid variables.$gray-30;
  display: grid;
  grid-template-columns: 6.25rem auto;
  grid-template-rows: auto min-content 1fr auto;
  padding: 1rem;

  &__right {
    grid-area: 2 / 2 / 2 / 2;
    margin-left: 0.75rem;
    padding-bottom: 1rem;

    > ul {
      padding-bottom: 0.5rem;
    }
  }

  &__bottom {
    grid-area: 3 / 1 / 3 / 3;

    + p {
      padding-bottom: 0.25rem;
    }
  }

  @media (min-width: variables.$screen-md-min) {
    grid-template-columns: 10.25rem auto;

    &__bottom {
      grid-area: 3 / 2 / 3 / 2;
      margin-left: 0.75rem;
    }
  }
}
</style>

import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getRecentlyWrappedReservations } from 'src/api/accounts';

export function useGetRecentlyWrappedReservations(accountId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.recentlyWrappedReservations(accountId),
    queryFn: ({ signal }) =>
      getRecentlyWrappedReservations(unref(accountId), signal),
    ...queryOptions,
  });
}

<script setup>
import { useRoute } from 'vue-router';

import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const route = useRoute();
const { linkClicked } = useBaseEvents();

const handleLinkClick = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'trending packs',
    linkLabel: 'create',
    linkHref: '/booking/producer',
  });
};
</script>

<template>
  <div class="producer-pack">
    <p class="u-label--regular producer-pack__title">
      tell us your goals, and we’ll craft a custom suggestion
    </p>
    <div class="producer-pack__img-container">
      <img
        alt=""
        src="@images/ai-producer/ai-producer-pack-recommendation.png"
      />
    </div>
    <SoonaButton
      element="router-link"
      variation="solid-black"
      :to="{
        name: 'ai-producer',
      }"
      @on-click="handleLinkClick()"
    >
      get recommendation
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.producer-pack {
  gap: 0.5rem;
  display: flex;
  padding: 0.75rem;
  flex: 0 0 13.75rem;
  text-align: center;
  align-items: center;
  flex-direction: column;

  &__img-container {
    width: 7.5rem;
  }
}
</style>

import { unref } from 'vue';
import { useQuery, useInfiniteQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import {
  getHeroItems,
  getInspiredItems,
  getBlogCategories,
  getBlogPosts,
  getCategoryBlogPosts,
} from '../api/discovery';

export function useHeroItems() {
  return useQuery({
    queryKey: queryKeys.discoveryHeroItems(),
    queryFn: () => getHeroItems(),
  });
}

export function useGetInspiredItems() {
  return useQuery({
    queryKey: queryKeys.discoveryGetInspiredItems(),
    queryFn: () => getInspiredItems(),
  });
}

export function useBlogCategories() {
  return useQuery({
    queryKey: queryKeys.discoveryBlogCategories(),
    queryFn: () => getBlogCategories(),
  });
}

export function useBlogPosts() {
  return useQuery({
    queryKey: queryKeys.discoveryBlogPosts(),
    queryFn: () => getBlogPosts(),
  });
}

/**
 * @param {Object} category
 * @param {Object} [params]
 * @param {string | number} [params.currentPage]
 */
export function useGetPostsByCategory(category) {
  return useInfiniteQuery({
    queryKey: queryKeys.discoveryBlogPostsByCategory(category),
    queryFn: ({ signal, pageParam }) =>
      getCategoryBlogPosts(unref(category), pageParam.currentPage, signal),
    initialPageParam: { currentPage: 1 },
    getNextPageParam: lastPage => {
      if (
        lastPage.pagination.currentPage + 1 >
        lastPage.pagination.totalPages
      ) {
        return null;
      } else {
        return {
          currentPage: lastPage.pagination.currentPage + 1,
        };
      }
    },
  });
}

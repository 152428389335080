<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useDeleteReEditsCollection } from '@/queries/re_edits_collections/useDeleteReEditsCollection';
import { computed } from 'vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  collectionId: {
    type: [Number, String],
    required: true,
  },
});

const emit = defineEmits(['close']);

const accountId = computed(() => props.accountId);
const collectionId = computed(() => props.collectionId);

const { mutate: cancelReEditRequest } = useDeleteReEditsCollection(accountId);

const cancelReEditsOrder = close => {
  cancelReEditRequest(
    {
      collectionId: collectionId,
    },
    {
      onSuccess: () => {
        close();
      },
    }
  );
};
</script>
<template>
  <SoonaDialog @close="() => emit('close')">
    <template #heading>
      are you sure you want to cancel this re-edit?
    </template>
    <template #default>
      <p>
        once you submit your re-edit request you will not be able to add more
        until we have completed this round.
      </p>
    </template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton variation="primary" @on-click="cancelReEditsOrder(close)">
        cancel re-edit
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue';

import { useCreateReEditsCollectionDigitalAsset } from '@/queries/re_edits_collection_digital_assets/useCreateReEditsCollectionDigitalAsset';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';

import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import TextArea from '@/components/shared/TextArea.vue';
import { useReservation } from '@/composables/useReservation';
import { useReEditsCollection } from '@/queries/re_edits_collections/useReEditsCollection';
import { useCreateNote } from '@/queries/notes/useCreateNote';
import { storeToRefs } from 'pinia';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const props = defineProps({
  reservationDigitalAsset: {
    type: Object,
    required: true,
  },
});

const { reservationDigitalAsset } = toRefs(props);
const { addToast } = useSoonaToast();

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId } = storeToRefs(mediaEditorStore);

const { reservationId, previewUrl } = useMediaEditorDigitalAsset(
  assetAccountId,
  assetId
);

const { activeReEditsCollectionId } = useReservation(reservationId);

const daHasReEditRequest = computed(() => {
  return reservationDigitalAsset.value?.digital_asset
    ?.re_edits_collection_digital_asset?.id;
});

const { data: reEditsCollection, isLoading: reEditsLoading } =
  useReEditsCollection(assetAccountId, activeReEditsCollectionId, {
    enabled: computed(() => !!activeReEditsCollectionId.value),
  });

const submittedReEdits = computed(() => {
  return reEditsCollection.value?.options?.status === 'submitted';
});

const tooltipText = computed(() =>
  submittedReEdits.value
    ? 'there is a re-edit request in progress. when complete you can submit additional re-edit requests'
    : 'this photo has been added to your re-edit request order.'
);

// modal
const showReEditDialog = ref(false);
const toggleReEditDialog = () => {
  showReEditDialog.value = !showReEditDialog.value;
};

const note = ref('');
const trimmedNote = computed(() => !note.value.trim());

const showValidationMsg = ref(false);

// submit re-edit
const {
  mutate: mutateCreateReEditsCollectionDigitalAsset,
  isPending: createPending,
} = useCreateReEditsCollectionDigitalAsset(
  assetAccountId,
  activeReEditsCollectionId,
  reservationId
);

const { mutate: createDigitalAssetNote, isPending: createNotePending } =
  useCreateNote('digital_assets', assetId);

const createNote = () => {
  createDigitalAssetNote(
    {
      content: note.value,
      note_type: 're_edit',
    },
    {
      onError: () => {
        addToast('error submitting re-edit note', { variation: 'error' });
      },
    }
  );
};

const createReEditsCollectionDigitalAsset = () => {
  mutateCreateReEditsCollectionDigitalAsset(
    { digital_asset_id: assetId.value },
    {
      onSuccess: () => {
        addToast('re-edit request submitted', {
          variation: 'success',
        });
        toggleReEditDialog();
      },
      onError: () => {
        addToast('error submitting re-edit request', { variation: 'error' });
      },
    }
  );
};

const submitReEdit = () => {
  if (trimmedNote.value) {
    showValidationMsg.value = true;
    return;
  }
  createNote();
  createReEditsCollectionDigitalAsset();
};

const loading = computed(
  () => reEditsLoading.value || createPending.value || createNotePending.value
);
</script>

<template>
  <div class="ReEdit">
    <SoonaTooltip>
      <template #default="{ setRef, mouseenter, focus, mouseleave, blur }">
        <SoonaButton
          :ref="el => setRef(el)"
          variation="tertiary"
          :disabled="
            !!daHasReEditRequest ||
            !!submittedReEdits ||
            loading ||
            (!reEditsCollection && activeReEditsCollectionId)
          "
          size="medium"
          @mouseenter="mouseenter"
          @focus="focus"
          @mouseleave="mouseleave"
          @blur="blur"
          @click="toggleReEditDialog()"
        >
          {{ daHasReEditRequest ? 're-edit requested' : 'request re-edit' }}
        </SoonaButton>
      </template>
      <template
        v-if="!!daHasReEditRequest || !!submittedReEdits"
        #tooltip-content
      >
        {{ tooltipText }}
      </template>
    </SoonaTooltip>
    <SoonaDialog
      v-if="showReEditDialog"
      max-width="72ch"
      @close="toggleReEditDialog"
    >
      <template #heading>need a re-edit?</template>
      <template #default>
        <SoonaForm id="reedit-form" @submit="submitReEdit">
          <div class="body">
            <p class="description">
              <span>
                please add the changes you’d like to see. if it was our mistake
                we’ll get started on the update. if this is a new request: we’ll
                send an invoice for the required charges. we’ll complete the
                request when we receive payment.
              </span>
              <span class="delivery-time">
                re-edits are delivered as soon as possible within 1-3 days.
              </span>
            </p>
            <div class="input-wrapper">
              <img :src="previewUrl" class="re-edit-image" />
              <div class="inner-input-wrapper">
                <b>re-edit notes</b>
                <TextArea
                  v-model="note"
                  placeholder="type notes for the editor…"
                  :rows="5"
                  :class="{
                    'error-textarea': showValidationMsg && trimmedNote,
                  }"
                  :disabled="loading"
                />
              </div>
            </div>
            <div class="info-container">
              <SoonaIcon
                name="circle-information-solid"
                size="medium"
                class="info-icon"
              />
              <p>
                submit your entire re-edit request order from the gallery.
                <br />
                requests will be automatically submitted after 6 hours.
              </p>
            </div>
          </div>
        </SoonaForm>
      </template>
      <template #footer>
        <SoonaError
          v-if="showValidationMsg && trimmedNote"
          class="dialog-error"
        >
          re-edit notes are required.
        </SoonaError>
        <SoonaButton
          type="button"
          variation="tertiary"
          @click="toggleReEditDialog"
        >
          cancel
        </SoonaButton>
        <SoonaButton form="reedit-form" type="submit" :loading="loading">
          add to re-edit request
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.body {
  .description {
    max-width: 70ch;
    font-weight: 500;
    margin-bottom: 1rem;

    .delivery-time {
      display: block;
      margin-top: 1rem;
    }
  }

  .re-edit-image {
    max-width: 250px;
    max-height: 250px;
    margin-right: 1rem;
    object-fit: contain;
  }

  .input-wrapper {
    display: flex;
    margin-bottom: 1rem;
    .inner-input-wrapper {
      width: 100%;

      .error-textarea {
        .textarea {
          border-color: variables.$roses-60;
          margin-bottom: 8px;
        }
        .textarea:focus {
          border-color: variables.$black-default;
        }
      }
    }
    .error-message {
      color: variables.$gray-60;
      font-size: 14px;
      .error-icon {
        color: variables.$roses-60;
      }
    }
  }
  .info-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .info-icon {
      color: variables.$tangerine-40;
      vertical-align: middle;
    }
  }
  .button-container {
    display: flex;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: variables.$mobile-max) {
    .re-edit-image {
      margin-right: 0;
      margin-bottom: 1rem;
    }
    .input-wrapper {
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;

      .error-message {
        font-size: 0.75rem;
      }
    }
  }
}

.dialog-error {
  margin: 0;
  width: 100%;
}
</style>

<template>
  <div class="ChatActions">
    <div class="chat-attachments-row">
      <ChatAttachmentsMenu
        :show-menu="showMenu"
        :attach-files="showFileThumbnails"
        :files="files"
      />
    </div>
    <div class="chat-input-row">
      <SoonaButton
        variation="icon-plain-gray"
        size="large"
        :disabled="disabled"
        title="attach files"
        @on-click="showMenu = !showMenu"
      >
        <SoonaIcon name="circle-plus-solid" />
      </SoonaButton>
      <div class="chat-input-container">
        <ChatTextArea
          :message="message"
          :files="files"
          :submit="submitMessage"
          :delete-file="deleteFile"
          :disabled="disabled"
          @update="onInput"
        />
        <SoonaButton
          class="emoji-picker-button"
          variation="icon-plain-gray"
          size="medium"
          :disabled="disabled"
          title="pick emoji"
          :aria-pressed="showPicker"
          @click.stop="togglePicker"
        >
          <SoonaIcon name="face-smile" />
        </SoonaButton>
      </div>

      <SoonaButton
        v-if="!disabled && isTyping"
        class="send-message-button"
        variation="icon-plain-gray"
        title="send message"
        @on-click="submitMessage"
      >
        <SoonaIcon name="send-alt-1" />
      </SoonaButton>

      <QuickThumbsUp
        v-if="!disabled && !isTyping"
        @send-quick-reaction="$emit('sendQuickReaction', $event)"
      />
    </div>

    <EmojiPicker
      v-if="showPicker"
      class="emoji-picker"
      :close-picker="closePicker"
      @emoji-selected="appendEmoji"
    />
  </div>
</template>

<script>
import { watch, ref, inject, computed } from 'vue';
import ChatAttachmentsMenu from './ChatAttachmentsMenu.vue';
import EmojiPicker from '@/components/shared/emojis/EmojiPicker.vue';
import QuickThumbsUp from './QuickThumbsUp.vue';
import ChatTextArea from './ChatTextArea.vue';
import sentMessageAudio from 'audio/sent-message.mp3';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { SOONA_UNSENT_RESERVATION_CHAT_PROVIDE_KEY } from '@/components/chat/soona-unsent-reservation-chat-provide-key';

export default {
  name: 'ChatActions',
  components: {
    SoonaIcon,
    SoonaButton,
    ChatAttachmentsMenu,
    EmojiPicker,
    QuickThumbsUp,
    ChatTextArea,
  },
  props: {
    currentUserId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    notificationsDisabled: Boolean,
    reservationId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: [
    'submit',
    'typing',
    'picker-open',
    'sendMessage',
    'sendMediaMessage',
    'sendQuickReaction',
  ],
  setup(props, { emit }) {
    const reservationId = computed(() => props.reservationId);
    const currentUserId = computed(() => props.currentUserId);
    const unsent = inject(SOONA_UNSENT_RESERVATION_CHAT_PROVIDE_KEY);

    let message = ref('');
    let files = ref([]);
    let isTyping = ref(false);

    function handleTyping() {
      // Tell parent the user is typing so we know to clear the unread-messages button
      emit('typing', true);

      if (message.value.length > 0 || files.value.length > 0) {
        isTyping.value = true;
      } else {
        isTyping.value = false;
      }
    }

    watch(
      () => message.value,
      messageText => {
        handleTyping();

        if (unsent) {
          unsent.addUnsentMessage(messageText, {
            currentUserId: currentUserId.value,
            currentReservationId: reservationId.value,
          });
        }
      }
    );

    watch(
      () => [...files.value],
      () => {
        handleTyping();
      }
    );

    if (unsent) {
      message.value = unsent.getUnsentMessage({
        currentUserId: currentUserId.value,
        currentReservationId: reservationId.value,
      });
    }

    return { message, files, isTyping, handleTyping };
  },
  data() {
    return {
      showMenu: false,
      showPicker: false,
    };
  },
  methods: {
    submitMessage(event) {
      this.isTyping = false;
      // allows user to create a new line with shift + enter without submitting the message
      if (event.shiftKey === true && event.key === 'Enter') {
        return;
      }
      // send text message
      if (this.message.trim() !== '' && this.files.length === 0) {
        event.preventDefault();
        this.$emit('sendMessage', this.message);
        this.playSentMessageSound();
        this.message = '';
      }

      this.$emit('submit', true);

      // send media message with caption
      if (this.files.length > 0) {
        this.$emit('sendMediaMessage', {
          files: this.files,
        });
        this.playSentMessageSound();
        this.message = '';
        this.files = [];
      }
    },
    playSentMessageSound() {
      if (!this.notificationsDisabled) {
        const audio = new Audio(sentMessageAudio);
        audio.play().catch(() => {
          /* empty */
        });
      }
    },
    togglePicker() {
      this.showPicker = !this.showPicker;
      this.emitIsPickerOpen();
    },
    closePicker() {
      this.showPicker = false;
      this.emitIsPickerOpen();
    },
    // Emit to parent Chat container to tell it to disable chat scrolling if the emoji picker is open
    emitIsPickerOpen() {
      if (this.showPicker) {
        this.$emit('picker-open', true);
      } else {
        this.$emit('picker-open', false);
      }
    },
    appendEmoji(emoji) {
      this.message += emoji.emoji;
    },
    showFileThumbnails(uploads) {
      uploads.map(file => {
        file.url = URL.createObjectURL(file);
      });

      this.files = uploads;
      this.showMenu = false;
    },
    onInput(value) {
      this.message = value;
      this.message.trim();
    },
    deleteFile(file) {
      this.files = this.files.filter(f => f !== file);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.ChatActions {
  display: flex;
  flex-direction: column;
  width: 100%;
  .chat-attachments-row {
    padding-left: 40px;
  }
  .chat-input-row {
    display: flex;
    gap: 0.25rem;
    width: 100%;
    align-items: flex-end;
  }
  .chat-input-container {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;
    position: relative;
    .chat-message-input {
      padding: 0.75rem 2rem 0.75rem 0.75rem;
      max-height: 104px;
      height: 38px;
      resize: none;
      flex: auto;
      border-radius: 5px;
      border: 1px solid #d6d8db;
      font-size: 14px;
      line-height: 14px;
    }
    .chat-message-input:active,
    .chat-message-input:focus {
      border: 1px solid variables.$gray-40;
    }
  }
  .send-message-button {
    color: variables.$friendly-red-50;

    &:hover {
      color: variables.$friendly-red-60;
    }
  }
  .emoji-picker-button {
    position: absolute;
    right: 0.25rem;
    bottom: 0.25rem;
  }
  .attachment-thumbnail {
    height: 50px;
    width: 40px;
    object-fit: cover;
    border-radius: 5px;
    margin: 3px;
  }
}
</style>

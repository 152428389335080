<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useEditPromotion } from 'src/queries/usePromotions';
import { useRouter } from 'vue-router';
import PromotionForm from 'src/components/user/anytime/admin/promotions/PromotionForm.vue';
import PromotionTitleAndTypeForm from 'src/components/user/anytime/admin/promotions/PromotionTitleAndTypeForm.vue';
import BannerDescriptionForm from 'src/components/user/anytime/admin/promotions/BannerDescriptionForm.vue';
import PlatformBannerDescriptionForm from 'src/components/user/anytime/admin/promotions/PlatformBannerDescriptionForm.vue';
import RealTimePreview from 'src/components/user/anytime/admin/promotions/RealTimePreview.vue';
import PromoCodeForm from 'src/components/user/anytime/admin/promotions/PromoCodeForm.vue';
import LinkForm from 'src/components/user/anytime/admin/promotions/LinkForm.vue';
import StylesForm from 'src/components/user/anytime/admin/promotions/StylesForm.vue';
import DateTimeForm from 'src/components/user/anytime/admin/promotions/DateTimeForm.vue';
import FormSubmission from 'src/components/user/anytime/admin/promotions/FormSubmission.vue';
import { useCreateOrUpdatePromotion } from 'src/composables/useCreateOrUpdatePromotion';

const props = defineProps({
  promoId: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const promoId = computed(() => props.promoId);
const { data: existingPromotion, isLoading: getPromotionLoading } =
  useEditPromotion(promoId);

const {
  formData,
  updatePromotion,
  setFormData,
  setPromotionWidgetsAttributes,
  setPlatformBannerAttributes,
  mapExistingPromotionToFormData,
  setStyles,
  setPlatformBannerStyles,
  isLoading,
  errors,
} = useCreateOrUpdatePromotion(promoId);

watchEffect(() => {
  if (existingPromotion) {
    mapExistingPromotionToFormData(existingPromotion.value);
  }
});

const handleCancelClicked = () => {
  router.push('/admin/promotions');
};

const bookingBanner = ref(null);
const platformBanner = ref(null);
watchEffect(() => {
  if (formData.value.promotion_widgets_attributes !== undefined) {
    bookingBanner.value = formData.value.promotion_widgets_attributes.find(
      pw => pw['type'] === 'BookingBanner'
    );

    platformBanner.value = formData.value.promotion_widgets_attributes.find(
      pw => pw['type'] === 'PlatformBanner'
    );
  }
});
</script>

<template>
  <PromotionForm
    v-if="existingPromotion"
    :page-name="`update promo`"
    :loading="isLoading || getPromotionLoading"
    :loading-text="`updating`"
  >
    <PromotionTitleAndTypeForm
      :title="formData.title"
      :promo-type="formData.promo_type"
      :existing-promotion="true"
      @update:model-value="setFormData"
    />
    <PromoCodeForm
      v-if="formData.promo_type === 'discount_code'"
      :promo-code="formData.promo_code"
      @update:model-value="setFormData"
    />
    <DateTimeForm
      :start-at="formData.start_at"
      :end-at="formData.end_at"
      @update:model-value="setFormData"
    />

    <div class="booking-banner">
      <RealTimePreview
        v-if="bookingBanner.enabled"
        :promotion-widget-attributes="bookingBanner"
      />
      <BannerDescriptionForm
        :booking-banner-form-enabled="bookingBanner.enabled"
        :description="bookingBanner.description"
        :copy-code-toggle="formData.promo_type === 'discount_code'"
        :copy-code-enabled="bookingBanner.copy_code_enabled"
        @update:model-value="setPromotionWidgetsAttributes"
      />
      <div v-if="bookingBanner.enabled">
        <LinkForm
          :include-hyperlink="true"
          :promo-type="formData.promo_type"
          :link-enabled="bookingBanner.link_enabled"
          :link-cta="bookingBanner.link_cta"
          :link-url="bookingBanner.link_url"
          :link-modal-enabled="bookingBanner.link_modal_enabled"
          :link-modal-description="bookingBanner.link_modal_description"
          :link-modal-title="bookingBanner.link_modal_title"
          :link-icon-enabled="bookingBanner.link_icon_enabled"
          :link-icon="bookingBanner.link_icon"
          @update:model-value="setPromotionWidgetsAttributes"
        />
        <StylesForm
          :current-background="bookingBanner.background_color"
          @update:model-value="setStyles"
        />
      </div>
    </div>

    <div class="platform-banner">
      <RealTimePreview
        v-if="platformBanner.enabled"
        :promotion-widget-attributes="platformBanner"
      />
      <PlatformBannerDescriptionForm
        :platform-banner-form-enabled="platformBanner.enabled"
        :description="platformBanner.description"
        :copy-code-toggle="formData.promo_type === 'discount_code'"
        :copy-code-enabled="platformBanner.copy_code_enabled"
        @update:model-value="setPlatformBannerAttributes"
      />
      <LinkForm
        v-if="platformBanner.enabled"
        :include-hyperlink="true"
        :promo-type="formData.promo_type"
        :link-enabled="platformBanner.link_enabled"
        :link-cta="platformBanner.link_cta"
        :link-url="platformBanner.link_url"
        :link-modal-enabled="platformBanner.link_modal_enabled"
        :link-modal-description="platformBanner.link_modal_description"
        :link-modal-title="platformBanner.link_modal_title"
        :link-icon-enabled="platformBanner.link_icon_enabled"
        :link-icon="platformBanner.link_icon"
        @update:model-value="setPlatformBannerAttributes"
      />
      <StylesForm
        v-if="platformBanner.enabled"
        :current-background="platformBanner.background_color"
        @update:model-value="setPlatformBannerStyles"
      />
    </div>

    <FormSubmission
      :submit-button-text="`save`"
      :errors="errors"
      @submit-clicked="updatePromotion"
      @cancel-clicked="handleCancelClicked"
    />
  </PromotionForm>
</template>

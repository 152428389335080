import { http } from '../config/vue-axios';

/**
 *
 * @param {Object} [params]
 * @param {string} [params.subjectType]
 * @param {string | number} [params.subjectId]
 * @param {AbortSignal} [signal]
 */
export async function getCapabilities({ subjectType, subjectId } = {}, signal) {
  const params = {};

  if (subjectType) params['subject_type'] = subjectType;
  if (subjectId) params['subject_id'] = subjectId;

  const response = await http.get('/capabilities.json', { params, signal });
  if (response.status !== 200) {
    return [];
  }
  return response.data;
}

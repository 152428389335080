<template>
  <div class="GroupMemberMessage">
    <p class="u-small--regular">
      <i>{{ message.actionBy.name.split(' ')[0] }} {{ message.action }}</i>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    message: Object,
    isOutgoing: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.GroupMemberMessage {
  padding: 0.5rem 0;
  text-align: center;
}
</style>

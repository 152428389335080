<template>
  <svg
    aria-hidden="true"
    width="24"
    height="24"
    viewBox="-3 -5.4 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.577 13.2a1.146 1.146 0 0 1-.82-.35L.305 7.182a1.083 1.083 0 0 1 .05-1.55 1.139 1.139 0 0 1 1.588.048l4.624 4.812L16.003.41a1.117 1.117 0 0 1 .786-.407 1.147 1.147 0 0 1 .842.28 1.097 1.097 0 0 1 .369.791 1.074 1.074 0 0 1-.336.804L7.407 12.838a1.122 1.122 0 0 1-.819.362h-.011Z"
      fill="#508b18"
    />
  </svg>
</template>

import { http } from 'src/config/vue-axios';

/**
 *
 * @param {number} reservationId
 * @param {number} reservationLineItemId
 * @returns {Promise<any>}
 */
export async function deleteReservationLineItem(
  reservationId,
  reservationLineItemId
) {
  const response = await http.delete(
    `reservations/${reservationId}/reservation_line_items/${reservationLineItemId}.json`
  );
  return response.data;
}

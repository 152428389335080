<script setup>
import { ref } from 'vue';

import { useRunMessage } from '../composables/useRunMessage';

import SoonaRadioButtonItem from '@/components/ui_library/soona_radio_buttons/SoonaRadioButtonItem.vue';
import SoonaRadioButtonGroup from '@/components/ui_library/soona_radio_buttons/SoonaRadioButtonGroup.vue';

defineProps({
  label: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
  functionName: {
    type: String,
    required: true,
  },
});

const { submitSuspendedActionResult } = useRunMessage();
const value = ref();
</script>

<template>
  <SoonaRadioButtonGroup
    v-model="value"
    name="select"
    label="select one"
    class="producer-select"
    :animate="false"
    :disabled="!!value"
    @update:model-value="
      val => submitSuspendedActionResult(functionName, { [label]: val })
    "
  >
    <SoonaRadioButtonItem
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :label="option.label"
    >
      <p class="u-body--heavy">{{ label }}</p>
    </SoonaRadioButtonItem>
  </SoonaRadioButtonGroup>
</template>

<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.producer-select {
  gap: 1rem;
  padding: 0;
  flex-wrap: wrap;
  background: unset;

  :deep(.radio-button) {
    flex: 1;
    flex-basis: calc((40rem - 100%) * 999);

    label {
      @include variables_fonts.u-headline--heavy;

      text-align: center;
      padding: 2rem 2.5rem;
      border-radius: 0.5rem;
      justify-content: center;
      color: variables.$black-default;
      background: variables.$white-default;
      border: 0.0625rem solid variables.$periwink-blue-30;

      .radio-button__content {
        padding: 0;
      }

      .radio-button__selected-background {
        border-radius: 0.4375rem;
        background: variables.$periwink-blue-20;
      }

      &:hover {
        background: variables.$periwink-blue-20;
        border: 0.0625rem solid variables.$periwink-blue-70;
      }
    }

    input:checked + label {
      border: 0.0625rem solid variables.$periwink-blue-70;
    }

    input:disabled:not(input:checked) + label {
      background: variables.$white-default;
      border: 0.0625rem solid variables.$periwink-blue-30;
    }

    input:disabled + label {
      cursor: not-allowed;
    }
  }
}
</style>

<script setup>
import { toRefs } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { usePlatformName } from '../usePlatformName';
import { useDisablePublishFlow } from '@/composables/useDisablePublishFlow';
import ListingActionUploadDialog from '@/components/user/anytime/listing_insights/listing_actions/actions/ListingActionUploadDialog.vue';
import { useOptimizeImageSizeActions } from '@/components/user/anytime/listing_insights/listing_actions/actions/image_size/useOptimizeImageSizeActions';

const props = defineProps({
  accountId: { type: [String, Number], required: true },
  externalProductId: { type: String, default: null },
  integrationId: { type: String, default: null },
  listingAction: { type: Object, required: true },
});

// any update to listing action needs to happen in parent.
// all errors must be surfaced in parent.
// this component is only responsible for displaying buttons & dialogs.
const emit = defineEmits([
  'undoChanges',
  'captureUploadNewEvent', // not used but will cause console warnings if not added here
  'handleUploadComplete',
  'handleOptimizeClick',
  'handleUpgradeClick',
  'handleResetListingAction',
  'handlePublish',
  'handleDownload',
]);

const { accountId, externalProductId, integrationId, listingAction } =
  toRefs(props);

const { disablePublishFlow } = useDisablePublishFlow({
  accountId: accountId,
});

const { platform } = usePlatformName(listingAction);

const {
  canTakeAction,
  optimizeButtonVariation,
  isBasicUserAndOutOfCredits,
  subscriptionActionPermissionsSuccess,
  showUploadDialog,
} = useOptimizeImageSizeActions(accountId, listingAction, {
  externalProductId,
  integrationId,
});
</script>

<template>
  <div class="listing-action-active__buttons">
    <template v-if="listingAction.status === 'active'">
      <SoonaButton
        v-if="isBasicUserAndOutOfCredits"
        variation="pizzazz"
        @click="emit('handleUpgradeClick')"
      >
        upgrade for more
      </SoonaButton>
      <template v-else-if="subscriptionActionPermissionsSuccess">
        <SoonaButton
          :variation="optimizeButtonVariation"
          size="medium"
          @click="emit('handleOptimizeClick')"
        >
          optimize
        </SoonaButton>
        <SoonaButton
          v-if="canTakeAction"
          variation="tertiary"
          size="medium"
          @click="showUploadDialog = true"
        >
          upload new
        </SoonaButton>
      </template>
    </template>
    <template v-else-if="listingAction.status === 'failed_to_publish'">
      <SoonaButton
        variation="primary"
        size="medium"
        @click="emit('handlePublish')"
      >
        retry
      </SoonaButton>
      <SoonaButton
        variation="tertiary"
        size="medium"
        @click="emit('handleResetListingAction')"
      >
        cancel
      </SoonaButton>
    </template>
    <template v-else-if="disablePublishFlow">
      <SoonaButton
        :variation="optimizeButtonVariation"
        size="medium"
        @click="emit('handleDownload')"
      >
        download
      </SoonaButton>
    </template>
    <template v-else>
      <SoonaButton
        variation="solid-black"
        size="medium"
        @click="emit('handlePublish')"
      >
        publish to {{ platform }}
      </SoonaButton>
      <SoonaButton
        variation="tertiary"
        size="medium"
        @click="emit('undoChanges')"
      >
        undo changes
      </SoonaButton>
    </template>
  </div>
  <ListingActionUploadDialog
    v-if="showUploadDialog"
    :account-id="accountId"
    :platform="platform"
    @upload-complete="emit('handleUploadComplete', $event)"
    @close="showUploadDialog = false"
  />
</template>

<style lang="scss" scoped>
.listing-action-active {
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
  }
}
</style>
